import { FC, useCallback } from 'react';

import useTranslations from 'modules/I18n/hooks/useTranslations';
import {
  useCloseModal,
  Dialog,
  changeFocusAfterDialogIsClosed,
} from 'modules/Modals';
import { Button } from 'modules/Ui';

import { InvalidTaxRegimeModalProps } from './InvalidTaxRegimeModalProps';
import messages from './messages';

const InvalidTaxRegimeModal: FC<InvalidTaxRegimeModalProps> = ({
  content,
  onClose,
  idToFocus,
}) => {
  const { t } = useTranslations();
  const handleClose = useCloseModal();
  const handleCloseModal = useCallback(() => {
    handleClose();
    onClose && onClose();
    idToFocus && changeFocusAfterDialogIsClosed(idToFocus);
  }, [handleClose]);
  return (
    <Dialog
      id="invalid-tax-regime-modal"
      title={t(messages.title)}
      onClose={handleCloseModal}
      buttonPrimary={
        <Button variant="secondary" onClick={handleCloseModal}>
          {t(messages.accept)}
        </Button>
      }
    >
      {content}
    </Dialog>
  );
};

export default InvalidTaxRegimeModal;
