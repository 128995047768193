import IconByPath, { IconByPathProps } from './Icon/IconType/IconByPath';

const IconFilter = (props: IconByPathProps) => (
  <IconByPath
    {...props}
    path="M22 5.55H9.46a2.5 2.5 0 0 0-4.91-.05H2v1h2.55a2.5 2.5 0 0 0 4.89.05H22v-1ZM5.5 6a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0Zm8.966 5.586H22v1h-7.57a2.5 2.5 0 0 1-4.88-.086H2v-1h7.55a2.5 2.5 0 0 1 4.916.086ZM12 10.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3Zm2.55 7a2.5 2.5 0 0 1 4.9 0H22v1h-2.55a2.5 2.5 0 0 1-4.9 0H2v-1h12.55Zm2.45-1a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3Z"
  />
);

export default IconFilter;
