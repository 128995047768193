import { getConfig } from 'modules/App/config';
import { requestBusinessToken } from 'modules/Auth/services/authBusiness';
import request from 'utils/request';

import { DefaultValuesItem } from '../models/default-values-item';
import { DefaultValuesOptions } from '../models/default-values-options';

export interface DefaultValuesResponse {
  items: DefaultValuesItem[];
  count: number;
}

const fetchAllDefaultValues = async (options?: DefaultValuesOptions) => {
  const config = await getConfig();
  const endpoint = config.apiDefaultValuesService;

  const token = options?.businessId
    ? await requestBusinessToken(options.businessId)
    : undefined;

  try {
    const response = await request<any>(
      endpoint,
      {
        method: 'GET',
      },
      {
        token,
        requireAuth: true,
        retries: 2,
      }
    );

    return response?.data?.data as DefaultValuesResponse;
  } catch (err) {
    return {
      count: 0,
      items: [],
    } as DefaultValuesResponse;
  }
};

export default fetchAllDefaultValues;
