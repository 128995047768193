export const bookkeeperRegexMap = {
  help: /^\/bookkeeper\/help/,
  notifications: /^\/notifications(\?.*)?/,
  clients: /^\/bookkeeper\/clients/,
  profile: /^\/bookkeeper\/profile/,
};

export const businessSectionRegexMap = {
  business: /^\/business/,
  users: /^\/users/,
  settings: /^\/settings/,
  subscriptions: /^\/subscriptions(?!\/plans)/,
  plans: /^\/subscriptions\/plans/,
  myBookkeeper: /^\/bookkeeper\/my-bookkeeper/,
  integrations: /^\/integrations/,
};

export const regexMap = {
  documents: /^\/documents(?!\/(expenses|quotes))/,
  quotes: /^\/(proformas|quotes|delivery-notes|documents\/quotes)/,
  expenses: /^\/documents\/expenses/,
  contacts: /^\/contacts/,
  products: /^\/products/,
  payments: /^\/payments/,
  business: /^\/(business|subscriptions|settings|users)/,
  help: /^\/help/,
  notifications: bookkeeperRegexMap.notifications,
  myAccount: /^\/bookkeeper\/my-account/,
};

export const paymentsSectionRegexMap = {
  payments: /^\/payments/,
  banks: /^\/banks/,
  remittances: /^\/remittances/,
};
