import { useEffect } from 'react';

import { Plan } from 'modules/Subscriptions/models';

import analyticsService from '../services/analyticsService';

export default function useProductImpression(plans: Plan[]) {
  useEffect(() => {
    if (plans.length) {
      analyticsService.productsList(plans);
    }
  }, [plans]);
}
