import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { showModal } from 'modules/Modals/actions';
import { ModalProps } from 'modules/Modals/constants';

function useOpenModal(): (props: ModalProps) => void;
function useOpenModal(extenalProps: ModalProps): () => void;
function useOpenModal(extenalProps?: ModalProps) {
  const dispatch = useDispatch();

  return useCallback(
    (props?: ModalProps) => {
      const showModalProps = extenalProps ?? props;
      dispatch(showModal(showModalProps as ModalProps));
    },
    [dispatch, extenalProps]
  );
}

export default useOpenModal;
