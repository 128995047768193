import { createAction } from 'typesafe-actions';
import { v4 } from 'uuid';

import { Entity } from 'modules/App/models';

import { UserNotification } from '../UserNotifications/models/models';
import ActionTypes from './constants';
import {
  BubbleNotification,
  CommercialNotification,
  Notification,
  NotificationType,
} from './models';

export const removeNotification = createAction(
  ActionTypes.REMOVE_NOTIFICATION,
  (id: string) => id
)();

export const removeBubbleNotification = createAction(
  ActionTypes.REMOVE_BUBBLE_NOTIFICATION,
  (id: string) => id
)();

export const createNotification = createAction(
  ActionTypes.ADD_NOTIFICATION,
  (notification: Omit<Notification, 'id'> & Partial<Entity>) => ({
    ttl: notification.type === NotificationType.ERROR ? 0 : 3000,
    ...notification,
    id: notification.id ?? v4(),
  })
)();

export const createBubbleNotification = createAction(
  ActionTypes.ADD_BUBBLE_NOTIFICATION,
  (notification: Omit<BubbleNotification, 'id'> & Partial<Entity>) => ({
    ttl: 6000,
    ...notification,
    id: notification.id ?? v4(),
  })
)();

export const clearNotifications = createAction(
  ActionTypes.CLEAR_NOTIFICATIONS
)();

export const createCommercialNotification = createAction(
  ActionTypes.ADD_COMMERCIAL_NOTIFICATION,
  (notification: Omit<CommercialNotification, 'id'> & Partial<Entity>) => ({
    ttl: 3000,
    ...notification,
    id: notification.id ?? v4(),
  })
)();

export const removeCommercialNotification = createAction(
  ActionTypes.REMOVE_COMMERCIAL_NOTIFICATION,
  (id: string) => id
)();

export const receiveServerNotification = createAction(
  ActionTypes.RECEIVE_SERVER_NOTIFICATION,
  (userNotification: UserNotification) => userNotification
)();
export const clearBubbleNotifications = createAction(
  ActionTypes.CLEAR_BUBBLE_NOTIFICATIONS
)();
