import { useInvalidateAndUpdate } from 'modules/App/hooks/useInvalidateAndUpdate';
import { DOCUMENTS_QUERY } from 'modules/App/queries/queries';
import { useBaiSubmissionNavigation } from 'modules/Documents/hooks/useBaiSubmissionNavigation';
import { Document } from 'modules/Documents/models/document';
import { notificationsTexts } from 'modules/Documents/Void/hooks/messages';
import voidDocument, {
  invalidateQueries,
} from 'modules/Documents/Void/services/voidDocument';
import useNotifications from 'modules/Notifications/hooks/useNotifications';
import { useBlockingMutation } from 'utils/useBlockingMutation';

export default function useDocumentVoid() {
  const notifications = useNotifications();
  const invalidateAndUpdate = useInvalidateAndUpdate();
  const {
    shouldNavigateToBai,
    navigateToBai,
    shouldNavigateToBaiResult,
    navigateToBaiResult,
  } = useBaiSubmissionNavigation();

  const { mutate } = useBlockingMutation(
    async (document: Document) => {
      if (shouldNavigateToBai(document.status)) {
        navigateToBai(document, document.contact, true);
      }
      return voidDocument(document.id);
    },
    {
      onSuccess: async (response) => {
        invalidateAndUpdate({
          data: response.data,
          setQueryData: [DOCUMENTS_QUERY],
          invalidateQueries,
        });

        if (shouldNavigateToBaiResult(!!response.data.digitalDocument)) {
          response.data && navigateToBaiResult(response.data, true);
        }
      },
      onError: () => {
        const message = notificationsTexts.error.id;
        notifications.error(message);
      },
    }
  );

  return { mutate };
}
