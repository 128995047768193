import { defineMessages } from 'react-intl';

import { DefineMessages } from 'modules/I18n/types';

const messages: DefineMessages = defineMessages({
  small: { id: 'gofre.Settings.Theme.Image.small' },
  medium: { id: 'gofre.Settings.Theme.Image.medium' },
  large: { id: 'gofre.Settings.Theme.Image.large' },
  deleteFile: { id: 'gofre.Settings.Theme.delete' },
  selectSize: { id: 'gofre.Settings.Templates.logo.size' },
  addedLogo: { id: 'gofre.Settings.Templates.logo.added' },
  'file-invalid-type': { id: 'gofre.Settings.Templates.logo.error' },
  'file-too-large': { id: 'gofre.Settings.Templates.logo.file-too-large' },
});

export const fileMessages: DefineMessages = defineMessages({
  fileRemove: { id: 'gofre.importer.Dropzone.file.remove' },
  'file-invalid-type': {
    id: 'gofre.importer.Dropzone.error.file-invalid-type',
  },
  'file-too-large': { id: 'gofre.importer.Dropzone.error.file-too-large' },
});

export default messages;
