import { FC } from 'react';

import {
  border,
  BorderProps,
  flexbox,
  FlexboxProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
} from 'styled-system';

import useTheme from 'modules/Theme/hooks/useTheme';
import { fadeInAnimation } from 'modules/Theme/mixins/fadeIn';
import styled, { css, keyframes } from 'modules/Theme/styled-components';
import { Box } from 'modules/Ui';

import HtmlProgress from '../../Html/Progress';
import progressBarReset from '../mixim/progressBarReset';

interface ProgressBarProps
  extends PositionProps,
    BorderProps,
    LayoutProps,
    FlexboxProps {
  animate?: boolean;
  duration?: number;
  fadeIn?: boolean;
  maxValue?: string;
  value?: string;
  valueColor?: string;
  isTest?: boolean;
}

const animationProgressBar = keyframes`
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
`;

const testBgColor = css`
  background-color: transparent;
  background-image: linear-gradient(90deg, ${(props) =>
    props.theme.colors.primary300}, ${(props) =>
  props.theme.colors.primary300}; 75%, transparent 75%, transparent 100%);
  background-size: 20px 3px, 100% 3px;
  border: none;
`;

const defaultbgColor = css`
  background-color: ${(props) => props.theme.colors.gray200};
`;

const StyledProgressBar = styled(HtmlProgress)<ProgressBarProps>``;
const StyledBarWrapper = styled(Box)<ProgressBarProps>`
  ${({ isTest }) => !isTest && fadeInAnimation};
  ${StyledProgressBar} {
    ${({ valueColor }) => valueColor && progressBarReset(valueColor, '4px')};
    ${flexbox}
    ${border}
    ${position}
    ${layout}
    &.animation {
      animation: ${animationProgressBar} ${(props) => props.duration}ms ease-in;
    }
    &::-webkit-progress-bar {
      ${({ isTest }) => (isTest ? testBgColor : defaultbgColor)};
    }
  }
`;

const ProgressBar: FC<ProgressBarProps> = ({
  animate,
  duration = 500,
  height = '4px',
  isTest = false,
  maxValue = '100',
  value,
  valueColor,
  width = '100%',
  ...rest
}) => {
  const theme = useTheme();
  return (
    <StyledBarWrapper
      backgroundColor={!isTest && 'gray200'}
      display="block"
      height="4px"
      overflow="hidden"
      valueColor={valueColor ?? `${theme.colors.accent500}`}
      {...{ isTest, animate, duration, width, ...rest }}
    >
      <StyledProgressBar
        border="none"
        display="block"
        max={maxValue}
        className={animate ? 'animation' : undefined}
        {...{
          animate,
          height,
          isTest,
          maxValue,
          value,
          valueColor,
          width,
          ...rest,
        }}
      />
    </StyledBarWrapper>
  );
};

export default ProgressBar;
