import { FC, Suspense } from 'react';
import { Hidden } from 'react-grid-system';

import { max } from 'modules/Theme/breakpoints';
import styled from 'modules/Theme/styled-components';
import { Box } from 'modules/Ui';

import { DialogBaseProps } from '../models/types';
import { CloseButton } from './CloseButton';
import { DialogButtons } from './DialogButtons';
import { DialogHeader } from './DialogHeader';
import { DialogSubtitle } from './DialogSubtitle';

export interface DialogFullScreenProps extends DialogBaseProps {}

const StyledWrapper = styled(Box)<DialogFullScreenProps>`
  @media ${max(767)} {
    &:after {
      background: linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
      bottom: 81px;
      content: ${({ withGradient }) => (withGradient ? '' : undefined)};
      display: block;
      height: 30px;
      left: 0;
      position: absolute;
      width: 100%;
    }
  }
`;

export const DialogFullScreen: FC<DialogFullScreenProps> = ({
  buttonPrimary,
  buttonSecondary,
  multipleActionsInMobile,
  children,
  content,
  id,
  mobileBorderHeader,
  withGradient,
  onClose,
  subtitle,
  title,
  type,
}) => {
  const dialogHasTitle = title
    ? { _: 'auto 1fr 72px', sm: 'initial' }
    : { _: '1fr auto', sm: 'initial' };
  return (
    <>
      <Hidden xs>
        <Box display="flex" justifyContent="flex-end">
          <CloseButton
            color="gray0"
            onClose={onClose}
            position={{ sm: 'absolute' }}
            right={{ sm: '0' }}
            top={{ sm: '-32px' }}
          />
        </Box>
      </Hidden>
      <Box
        backgroundColor="gray0"
        borderRadius={{ sm: '12px' }}
        boxSizing="border-box"
        contentHeight={{ _: '100%', sm: 'auto' }}
        display={{ _: 'grid', sm: 'block' }}
        gridTemplateRows={
          multipleActionsInMobile
            ? { _: 'auto 1fr 140px', sm: 'initial' }
            : dialogHasTitle
        }
        height={{ _: '100%', sm: 'initial' }}
        padding={{ _: '0 16px', sm: '24px 32px' }}
      >
        {title && (
          <DialogHeader
            id={id}
            mobileBorderHeader={mobileBorderHeader}
            onClose={onClose}
            title={title}
            type={type}
          />
        )}
        <StyledWrapper
          withGradient={withGradient}
          overflowX={{ _: 'hidden', sm: 'initial' }}
          padding={{ _: '32px 0 24px', sm: 'initial' }}
        >
          {subtitle && <DialogSubtitle id={id} text={subtitle} />}
          <Suspense fallback={null}>{children || content}</Suspense>
        </StyledWrapper>
        {(buttonPrimary || buttonSecondary) && (
          <DialogButtons
            type={type}
            buttonSecondary={buttonSecondary}
            buttonPrimary={buttonPrimary}
          />
        )}
      </Box>
    </>
  );
};
