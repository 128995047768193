import {
  addDays,
  endOfMonth,
  endOfQuarter,
  endOfWeek,
  startOfDay,
  startOfMonth,
  startOfQuarter,
  startOfWeek,
  subWeeks,
} from 'date-fns';

import { getConfig } from 'modules/App/config';
import buildBaseIdsQuery from 'modules/App/queries/buildBaseIdsQuery';
import Business from 'modules/Business/models/business';
import Limits from 'types/limits';
import { formatISO } from 'utils/dates';
import { buildTortillaBaseQuery } from 'utils/query/buildTortillaBaseQuery';
import request from 'utils/request';

import {
  BookkeeperCustomerFilters,
  BookkeeperCustomerSort,
  LastDocumentPeriod,
} from '../components/types';

interface WeekOptions {
  locale?: Locale;
  weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6;
}

const formatQuery = (start: Date, end: Date) =>
  `&query[bookkeepingData][lastDocument][$gte]=${formatISO(
    start
  )}&query[bookkeepingData][lastDocument][$lte]=${formatISO(end)}`;

const processLastDocumentPeriod = (period?: LastDocumentPeriod) => {
  if (!period) return null;
  const today = new Date();
  const todayLastWeek = subWeeks(today, 1);
  const weekOptions: WeekOptions = { weekStartsOn: 1 };
  switch (period) {
    case LastDocumentPeriod.THIS_WEEK:
      return formatQuery(
        startOfWeek(today, weekOptions),
        endOfWeek(today, weekOptions)
      );
    case LastDocumentPeriod.LAST_WEEK:
      return formatQuery(
        startOfWeek(todayLastWeek, weekOptions),
        endOfWeek(todayLastWeek, weekOptions)
      );
    case LastDocumentPeriod.THIS_MONTH:
      return formatQuery(startOfMonth(today), endOfMonth(today));
    case LastDocumentPeriod.THIS_QUARTER:
      return formatQuery(startOfQuarter(today), endOfQuarter(today));
    default:
      return null;
  }
};

export default async function fetchCustomers({
  page = 0,
  pageSize = Limits.SMALL,
  sortBy,
  filters,
  ids = [],
  excludeIds,
}: {
  page: number;
  pageSize: Limits;
  searchTerm?: string;
  filters?: BookkeeperCustomerFilters;
  sortBy?: BookkeeperCustomerSort;
  ids?: string[];
  excludeIds?: boolean;
}) {
  const endpoint = (await getConfig()).apiBusinessesService;

  let query = buildTortillaBaseQuery({
    page,
    pageSize,
    sort: [
      {
        field: sortBy?.field,
        order: sortBy?.order,
      },
    ],
    search: filters?.searchTerm,
  });

  query += '&query[users][role][name][$eq]=bookkeeper';

  if (ids.length) {
    query += buildBaseIdsQuery(ids, !!excludeIds);
  }

  if (filters?.newDocuments) {
    query += `&query[bookkeepingData][newDocuments][$gt]=0`;
  }

  const processedLastDocumentPeriod = processLastDocumentPeriod(
    filters?.lastDocumentDate as LastDocumentPeriod
  );
  if (processedLastDocumentPeriod) {
    query += processedLastDocumentPeriod;
  }

  if (filters?.lastExportFromDateTime) {
    query += `&query[bookkeepingData][lastExportStartPeriod][$gte]=${formatISO(
      startOfDay(new Date(filters.lastExportFromDateTime))
    )}`;
  }

  if (filters?.lastExportToDateTime) {
    query += `&query[bookkeepingData][lastExportEndPeriod][$lt]=${formatISO(
      startOfDay(addDays(new Date(filters.lastExportToDateTime), 1))
    )}`;
  }

  const response = await request<any>(`${endpoint}/me?${query}`, {
    method: 'GET',
  });

  return response?.data?.data as { items: Business[]; count: number };
}
