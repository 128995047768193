enum Limits {
  MINIMAL = 2,
  EXTRA_SMALL = 5,
  PRODUCT_TABLET = 6,
  MEDIUM_SMALL = 7,
  SMALL = 10,
  MEDIUM = 50,
  BIG = 100,
}

export default Limits;
