import { useSelector } from 'react-redux';

import { selectCurrentBusiness } from 'modules/Business/Current/selectors';
import { Coupon } from 'modules/Subscriptions/Create/types';
import { ChangePlanPreviewResponse } from 'modules/Subscriptions/services/changePlan/changePlanPreview';
import { taxOptions } from 'modules/Taxes';
import { getVatKey } from 'modules/Taxes/getVatKey';

export function useChangePlanCalcs(
  price: number,
  coupon?: Coupon,
  data?: ChangePlanPreviewResponse
) {
  const business = useSelector(selectCurrentBusiness);
  const province = business?.address?.province;
  const {
    amount: taxPercentage,
    label: taxLabel,
    type: taxType,
  } = taxOptions[getVatKey(province)];
  const taxLabelReversed = `${taxType} (${taxPercentage}%)`;
  const absoluteDiscount =
    coupon && coupon?.absoluteDiscount ? coupon.absoluteDiscount / 100 : 0;
  const discountPlusTax =
    coupon && coupon?.absoluteDiscount
      ? (coupon.absoluteDiscount / 100) * (1 + taxPercentage / 100)
      : 0;
  let tax = 0;
  let taxFullPrice = 0;
  let priceWithTax = 0;
  let fullPriceWithTax = 0;
  let balance = 0;
  let totalToPay = 0;
  let total = 0;
  let subtotal = 0;

  if (data) {
    taxFullPrice = ((price - absoluteDiscount) * taxPercentage) / 100;
    priceWithTax = price - absoluteDiscount + taxFullPrice;
    fullPriceWithTax = price + taxFullPrice;
    subtotal = data.subtotal - absoluteDiscount;
    tax = ((data.subtotal - absoluteDiscount) * taxPercentage) / 100;
    total = subtotal + tax;
    totalToPay =
      data.amountDue > 0 && data.amountDue >= absoluteDiscount
        ? data.amountDue - discountPlusTax
        : 0;
    balance = data.appliedBalance + discountPlusTax;
  }

  return {
    subtotal,
    totalToPay,
    priceWithTax,
    taxLabel,
    tax,
    taxLabelReversed,
    taxPercentage,
    fullPriceWithTax,
    balance,
    total,
  };
}
