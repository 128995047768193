/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { Hidden } from 'react-grid-system';

import dot from 'dot-object';

import breakpoints, { min } from 'modules/Theme/breakpoints';
import useTheme from 'modules/Theme/hooks/useTheme';
import { shadowHover, shadowHoverTrSafary } from 'modules/Theme/mixins';
import styled from 'modules/Theme/styled-components';
import { Checkbox } from 'modules/Ui/Form';

import Box from '../Box';
import { DataTableColumn } from './DataTable.models';

const TableRow = styled(Box)`
  @media ${min(breakpoints.sm)} {
    @supports not (-webkit-hyphens:none) {
      ${(props) =>
        shadowHover({
          offsetBlur: '0 3px 8px 0',
          color: props.theme.colors.gray300,
          opacity: 70,
        })};

      @media (hover: hover) {
        &:hover {
          td {
            position: relative;
            z-index: 1;
          }
        }
      }
    }

    @supports (-webkit-hyphens:none) {
      ${shadowHoverTrSafary}
    }
  }
`;

interface DataTableItemProps<T extends object, U extends keyof T> {
  className?: string;
  keyName: U;
  columns: DataTableColumn<T, U>[];
  data: T;
  index: number;
  withCheck?: boolean;
  checked?: boolean;
  onClick?: (data: T) => void;
  onCheck: (data: T) => void;
  showEmptySpace?: boolean;
}

function DataTableItemBase<T extends object, U extends keyof T>({
  className,
  keyName,
  data,
  index,
  columns,
  withCheck,
  checked,
  onClick,
  onCheck,
  showEmptySpace = true,
}: DataTableItemProps<T, U>) {
  const { colors } = useTheme();
  const { notSelectable } = data as any;
  return (
    <TableRow
      borderBottom={{ _: `1px solid ${colors.gray200}`, sm: 'none' }}
      className={className}
      color="gray600"
      backgroundColor={notSelectable ? colors.warningBackground : 'inherit'}
      display={{ _: 'grid', sm: 'table-row' }}
      gap={{ _: '6px 8px', sm: '0' }}
      onClick={() => onClick && onClick(data)}
      padding={{ _: '12px 16px', sm: 'initial' }}
      tag="tr"
      height={{ sm: '54px' }}
      position={{ sm: 'relative' }}
    >
      <Hidden xs>
        {showEmptySpace && (
          <Box
            backgroundColor={notSelectable ? colors.gray0 : 'inherit'}
            scope="col"
            tag="td"
            width={{ sm: '16px', md: '24px', lg: '32px' }}
          />
        )}
        <Box
          borderBottom={{ sm: `1px solid ${colors.gray200}` }}
          notSelectable={notSelectable}
          tag="td"
          width={withCheck ? { sm: '24px' } : undefined}
          display={!withCheck && 'none'}
          onClick={(event: any) => {
            // avoid item onSelect event
            event.preventDefault();
            event.stopPropagation();
            if (notSelectable || !withCheck) {
              return;
            }
            onCheck(data);
          }}
        >
          {!notSelectable && withCheck && (
            <Checkbox
              checked={checked}
              id={`check-${data[keyName]}`}
              label=""
              labelHidden
              size={22}
            />
          )}
        </Box>
      </Hidden>
      {columns.map((column) => (
        <Box
          {...{ ...column }}
          borderBottom={{ sm: `1px solid ${colors.gray200}` }}
          boxSizing="border-box"
          className={`data__table--${column.className}`}
          fontSize={{ _: column.fontSize || 12, sm: 14 }}
          key={`${String(column.field)}-${data[column.field]}`}
          lineHeight={{ _: column.lineHeight || 16, sm: 22 }}
          paddingLeft={{ sm: '8px', md: '16px' }}
          tag="td"
          style={{
            alignSelf: column.alignSelf,
            fontWeight: column.fontWeight,
            justifySelf: column.justifySelf,
          }}
        >
          {column.value
            ? column.value(data, index)
            : dot.pick(column.field as string, data) || ''}
        </Box>
      ))}
      <Hidden xs>
        {showEmptySpace && (
          <Box
            scope="col"
            tag="td"
            width={{ sm: '16px', md: '24px', lg: '32px' }}
            backgroundColor={notSelectable ? colors.gray0 : 'inherit'}
          />
        )}
      </Hidden>
    </TableRow>
  );
}

export default DataTableItemBase;
