import { Link } from 'react-router-dom';

import useTranslations from 'modules/I18n/hooks/useTranslations';
import { Text, Img } from 'modules/Ui';

import texts from '../messages';

interface Props {
  isBookkeeper?: boolean;
}

const DefaultClientLogo = ({ isBookkeeper }: Props) => {
  const businessLogo = '/assets/logos/billin-logo.svg';
  const bookkeeperLogo = '/assets/logos/billin-logo-bookkeeper.svg';
  const { t } = useTranslations();
  return (
    <>
      <Text hide tag="h1" textAlign="center">
        Billin
      </Text>
      <Text height="25px">
        <Link to="/" aria-label={t(texts.goToHome)}>
          <Img
            aria-hidden={false}
            alt="Billin"
            height={25}
            src={isBookkeeper ? bookkeeperLogo : businessLogo}
            width={80}
          />
        </Link>
      </Text>
    </>
  );
};

export default DefaultClientLogo;
