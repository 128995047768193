import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectIsMobile } from 'modules/App/store/selectors';
import { removeBannerBai } from 'modules/Documents/actions';
import useTranslations from 'modules/I18n/hooks/useTranslations';
import useTheme from 'modules/Theme/hooks/useTheme';
import { Box, Text, Button } from 'modules/Ui';
import { IconCross, IconInfo } from 'modules/Ui/Icons';

import bannerBai from './messages';
import useBannerBai from './useBannerBai';

const FormBannerBai: React.FC = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const { showBai, color, message } = useBannerBai();
  const { t } = useTranslations();
  const isMobile = useSelector(selectIsMobile);
  if (!showBai) {
    return null;
  }
  return (
    <Box
      backgroundColor={color}
      boxSizing="border-box"
      padding={{ _: '16px 18px', sm: '13px 16px' }}
      tag="aside"
    >
      <Box
        alignItems={{ sm: 'center' }}
        display="flex"
        maxWidth={{
          sm: theme.maxWidth.sm,
          md: theme.maxWidth.md,
          lg: theme.maxWidth.lg,
          xl: theme.maxWidth.xl,
        }}
        margin="0 auto"
      >
        <Box
          alignSelf="flex-start"
          height="24px"
          marginRight={{ _: '10px', sm: '12px' }}
          tag="span"
          width="24px"
        >
          <IconInfo size={24} color="gray800" />
        </Box>
        <Text
          color="gray800"
          flex="1"
          fontSize={14}
          lineHeight={16}
          marginRight={{ _: '12px', sm: '10px' }}
          marginTop={{ _: '3px', sm: '0' }}
        >
          {message}
        </Text>
        <Button
          aria-label={t(bannerBai.close)}
          onClick={() => {
            dispatch(removeBannerBai());
          }}
          height="24px"
          width="24px"
        >
          <IconCross size={isMobile ? 20 : 24} color="gray800" />
        </Button>
      </Box>
    </Box>
  );
};

export default FormBannerBai;
