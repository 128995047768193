import { Document, DocumentType } from 'modules/Documents/models/document';
import { TranslationFunction } from 'modules/I18n/hooks/useTranslations';

import { paymentMethodsMessages } from './messages';
import { PaymentTypeEnum, PaymentMethodEnum } from './models';

interface GetPaymentTypeParams {
  document?: Document;
}
export const getPaymentType = ({
  document,
}: GetPaymentTypeParams): PaymentTypeEnum => {
  const isDocExpense = document?.documentType === DocumentType.EXPENSE;
  const amount = document?.totals.total || 0;
  if (isDocExpense) {
    return amount < 0 ? PaymentTypeEnum.INCOME : PaymentTypeEnum.EXPENSE;
  }
  return amount < 0 ? PaymentTypeEnum.EXPENSE : PaymentTypeEnum.INCOME;
};

export const getPaymentMethodTypeTranslation = (
  type: PaymentMethodEnum,
  t: TranslationFunction
): string => {
  switch (type) {
    case PaymentMethodEnum.BIZUM:
      return t(paymentMethodsMessages.bizum);
    case PaymentMethodEnum.CASH:
      return t(paymentMethodsMessages.cash);
    case PaymentMethodEnum.CREDIT_CARD:
      return t(paymentMethodsMessages.creditCard);
    case PaymentMethodEnum.DIRECT_DEBIT:
      return t(paymentMethodsMessages.directDebit);
    case PaymentMethodEnum.PROMISSORY_NOTE:
      return t(paymentMethodsMessages.promisoryNote);
    case PaymentMethodEnum.TRANSFER:
      return t(paymentMethodsMessages.transfer);
    case PaymentMethodEnum.CONFIRMING:
      return t(paymentMethodsMessages.confirming);
    case PaymentMethodEnum.OTHER:
      return t(paymentMethodsMessages.other);
    case PaymentMethodEnum.NOT_CONFIRMED:
      return t(paymentMethodsMessages.notIndicated);
    default:
      return '';
  }
};

export const getPaymentOptions = (t: TranslationFunction) => [
  {
    value: PaymentMethodEnum.BIZUM,
    label: t(paymentMethodsMessages.bizum),
  },
  {
    value: PaymentMethodEnum.DIRECT_DEBIT,
    label: t(paymentMethodsMessages.directDebit),
  },
  { value: PaymentMethodEnum.CASH, label: t(paymentMethodsMessages.cash) },
  {
    value: PaymentMethodEnum.PROMISSORY_NOTE,
    label: t(paymentMethodsMessages.promisoryNote),
  },
  {
    value: PaymentMethodEnum.TRANSFER,
    label: t(paymentMethodsMessages.transfer),
  },
  {
    value: PaymentMethodEnum.CREDIT_CARD,
    label: t(paymentMethodsMessages.creditCard),
  },
  {
    value: PaymentMethodEnum.CONFIRMING,
    label: t(paymentMethodsMessages.confirming),
  },
  {
    value: PaymentMethodEnum.OTHER,
    label: t(paymentMethodsMessages.other),
  },
];
