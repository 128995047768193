import { getConfig } from 'modules/App/config';
import {
  REMITTANCES_DOCUMENTS_QUERY,
  REMITTANCES_QUERY,
} from 'modules/App/queries/queries';
import request from 'utils/request';

import { RemittanceDocumentDto } from '../types';

type Props = {
  remittanceId?: string;
  documentIds: string[];
  excludeIds: boolean;
};

export interface RemittanceDocumentsAnswerDto {
  data: {
    items: RemittanceDocumentDto[];
    count: number;
  };
}

function buildBaseDocumentsIdsQuery(ids: string[], excludeIds: boolean) {
  const operator = excludeIds ? '$nin' : '$in';

  return (ids.length ? ids : [''])
    .map((id, index) => `&query[document][id][${operator}][${index}]=${id}`)
    .join('&');
}

const deleteRemittanceDocuments = async ({
  remittanceId,
  documentIds,
  excludeIds,
}: Props): Promise<RemittanceDocumentsAnswerDto> => {
  const endpoint = (await getConfig()).apiRemittancesService;
  const query = buildBaseDocumentsIdsQuery(documentIds, excludeIds);

  const response = await request<RemittanceDocumentsAnswerDto>(
    `${endpoint}/${remittanceId}/documents?${query}`,
    {
      method: 'DELETE',
    }
  );
  return response.data;
};

export const invalidateQueries = [
  REMITTANCES_QUERY,
  REMITTANCES_DOCUMENTS_QUERY,
];

export default deleteRemittanceDocuments;
