import { FC } from 'react';

import { sentStatus } from 'modules/Documents/messages';
import { DocumentSendStatus } from 'modules/Documents/models/document';
import useTranslations from 'modules/I18n/hooks/useTranslations';
import {
  IconBlocked,
  IconCheck,
  IconEmail,
  IconReceived,
  IconSpam,
} from 'modules/Ui/Icons';

import SentStatusIcon from '../../components/SentStatusIcon';

interface Props {
  sendStatus: DocumentSendStatus;
}

const DocumentSendTrackingIcon: FC<Props> = ({ sendStatus }) => {
  const { t } = useTranslations();
  switch (sendStatus) {
    case DocumentSendStatus.READ: {
      return (
        <SentStatusIcon
          text={t(sentStatus.read)}
          icon={<IconReceived size={20} color="confirmation" />}
          showTooltip={false}
        />
      );
    }
    case DocumentSendStatus.RECEIVED: {
      return (
        <SentStatusIcon
          text={t(sentStatus.received)}
          icon={<IconReceived size={20} color="primary200" />}
          showTooltip={false}
        />
      );
    }
    case DocumentSendStatus.SENT: {
      return (
        <SentStatusIcon
          text={t(sentStatus.sent)}
          icon={<IconCheck size={20} color="primary200" />}
          showTooltip={false}
        />
      );
    }
    case DocumentSendStatus.SPAM:
      return (
        <SentStatusIcon
          text={t(sentStatus.spam)}
          icon={<IconSpam size={20} color="accent400" />}
          showTooltip={false}
        />
      );
    case DocumentSendStatus.BLOCKED:
      return (
        <SentStatusIcon
          icon={<IconBlocked size={20} />}
          text={t(sentStatus.blocked)}
          showTooltip={false}
        />
      );
    case DocumentSendStatus.BASIC_PLAN_SENT:
      return (
        <SentStatusIcon
          text={t(sentStatus.sent)}
          icon={<IconEmail size={20} />}
          showTooltip={false}
        />
      );
    default:
      return <></>;
  }
};

export default DocumentSendTrackingIcon;
