import { FC, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';

import useTranslations from 'modules/I18n/hooks/useTranslations';
import useTheme from 'modules/Theme/hooks/useTheme';
import styled, { css } from 'modules/Theme/styled-components';
import { Link, Box, Button } from 'modules/Ui';
import { IconCross, IconNotificationSimple } from 'modules/Ui/Icons';
import { UserNotificationPaylodType } from 'modules/UserNotifications/models/UserNotificationPaylodType';
import { ROUTES as ROUTES_NOTIFICATIONS } from 'modules/UserNotifications/routesMap';

import useBubbleNotifications from '../../hooks/useBubbleNotifications';
import messages from './messages';

interface BubbleNotificationProps {
  message: string;
  id: string;
  payload?: UserNotificationPaylodType;
  in?: boolean;
}

export const hoverEffect = css`
  @media (hover: hover) {
    &:hover {
      color: ${(props) => props.theme.colors.brand500};
    }
  }
  &:focus {
    color: ${(props) => props.theme.colors.brand500};
  }
`;

const StyledLink = styled(Link)``;
const StyledClose = styled(Button)``;
const StyledBubble = styled(CSSTransition)`
  &.alert {
    &-enter {
      opacity: 0;
      transform: scale(0.9) translateX(200px);
    }
    &-enter-active {
      opacity: 1;
      transform: translateX(0);
      transition: opacity 300ms, transform 600ms;
    }
    &-exit {
      opacity: 1;
    }
    &-exit-active {
      opacity: 0;
      transform: scale(0.9);
      transition: opacity 600ms, transform 600ms;
    }
  }
  ${StyledClose} {
    ${hoverEffect}
  }
  ${StyledLink} {
    ${hoverEffect}
  }
`;

const BubbleNotification: FC<BubbleNotificationProps> = (props) => {
  const { message, id, payload } = props;
  const nodeRef = useRef(null);
  const theme = useTheme();
  const { t } = useTranslations();
  const bubbleNotifications = useBubbleNotifications();
  const closeNotification = () => {
    bubbleNotifications.delete(id);
  };

  return (
    <StyledBubble
      classNames="alert"
      in={props.in}
      nodeRef={nodeRef}
      timeout={{
        appear: 100,
        enter: 600,
        exit: 600,
      }}
      unmountOnExit
    >
      <Box
        alignItems="start"
        backgroundColor="stateBg"
        borderRadius="8px"
        boxShadow={`0px 8px 12px 0px  ${theme.colors.shadow}`}
        columnGap="16px"
        display="grid"
        gridTemplateColumns="1fr 20px"
        paddingRight="12px"
        ref={nodeRef}
        tag="li"
      >
        <StyledLink
          alignItems="center"
          color={theme.colors.gray800}
          columnGap="12px"
          display="grid"
          fontSize={theme.fontSizes[14]}
          gridTemplateColumns="28px 1fr"
          lineHeight={theme.lineHeights[18]}
          padding="24px 0 24px 20px"
          to={ROUTES_NOTIFICATIONS.NOTIFICATIONS}
        >
          <IconNotificationSimple color="brand500" size={28} />
          <Box>
            {t(
              { id: message },
              {
                ...(payload ?? {}),
                b: (chunks) => <b style={{ fontWeight: 'bold' }}>{chunks}</b>,
              }
            )}
          </Box>
        </StyledLink>
        <StyledClose
          accessibleText={t(messages.close)}
          backgroundColor="transparent"
          marginTop="12px"
          onClick={closeNotification}
        >
          <IconCross size={20} />
        </StyledClose>
      </Box>
    </StyledBubble>
  );
};

export default BubbleNotification;
