import { FC, useMemo } from 'react';
import { Hidden } from 'react-grid-system';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Formik } from 'formik';

import { isDuplicatedVatNumberOnPostBusiness } from 'modules/Business/Create/services/createBusiness';
import { getBusiness } from 'modules/Business/Current/actions';
import { selectCurrentBusiness } from 'modules/Business/Current/selectors';
import useElectronicDocumentProvider from 'modules/Business/hooks/useElectronicDocumentProvider';
import businessMessages from 'modules/Business/Update/messages';
import updateBusiness from 'modules/Business/Update/services/updateBusiness';
import useTranslations from 'modules/I18n/hooks/useTranslations';
import { MODAL_WIDTH_DEFAULT, useCloseModal, Dialog } from 'modules/Modals';
import { createNotification } from 'modules/Notifications/actions';
import { NotificationType } from 'modules/Notifications/models';
import { Button, FormikField, Warning, Box } from 'modules/Ui';
import requestErrorHandler from 'utils/requestErrorHandler';

import { BaiFixVatNumberModalProps } from './BaiFixVatNumberModalProps';
import messages from './messages';
import getValidationSchema from './validation';

const BaiFixVatNumberModal: FC<BaiFixVatNumberModalProps> = (props) => {
  const { vatNumber, documentId } = props;
  const history = useHistory();
  const business = useSelector(selectCurrentBusiness);
  const dispatch = useDispatch();
  const { t } = useTranslations();
  const { isGipuzkoa, isBizkaia } = useElectronicDocumentProvider();
  const validationSchema = useMemo(() => getValidationSchema(t), [t]);
  const closeModal = useCloseModal();
  const initialValues = {
    vatNumber,
  };

  let warningMessage = messages.warningAL;
  if (isGipuzkoa) {
    warningMessage = messages.warningGU;
  }
  if (isBizkaia) {
    warningMessage = messages.warningBI;
  }

  return (
    <Dialog
      {...{
        type: 'fullScreen',
        dialogWidth: { md: MODAL_WIDTH_DEFAULT },
        content: (
          <>
            <Warning message={t(warningMessage)} />
            <Formik
              onSubmit={async (values, actions) => {
                try {
                  const { address, fiscalName, phoneNumber } = business;
                  const normalized = {
                    address,
                    fiscalName,
                    phoneNumber,
                    vatNumber: values.vatNumber,
                  };
                  await updateBusiness(normalized);
                  dispatch(getBusiness.request());
                  closeModal();
                  history.push(`/documents/issued/${documentId}/fix`);
                } catch (e: any) {
                  const errorHandled = requestErrorHandler(e);
                  const { isNetworkError, networkErrorTranslationKey } =
                    errorHandled;
                  const duplicatedVatNumberOnPostBusiness =
                    isDuplicatedVatNumberOnPostBusiness(errorHandled);
                  const message = duplicatedVatNumberOnPostBusiness
                    ? businessMessages.updateVatNumberError.id
                    : businessMessages.koMessage.id;

                  if (duplicatedVatNumberOnPostBusiness) {
                    actions.setFieldError(
                      'vatNumber',
                      t(messages.updateVatNumberErrorShort)
                    );
                  } else {
                    dispatch(
                      createNotification({
                        type: NotificationType.ERROR,
                        message: isNetworkError
                          ? networkErrorTranslationKey
                          : message,
                      })
                    );
                  }
                }
              }}
              validateOnChange={false}
              validateOnBlur={false}
              enableReinitialize
              initialValues={initialValues}
              validationSchema={validationSchema}
            >
              {(formik) => (
                <Box
                  noValidate
                  onSubmit={formik.handleSubmit}
                  tag="form"
                  id="fix-vat-number-form"
                >
                  <FormikField
                    required
                    id="vatNumber"
                    name="vatNumber"
                    label={t(messages.vatNumber)}
                    maxLength={50}
                    width={{ _: '100%', sm: '50%' }}
                    marginBottom="16px"
                    helpText={t(messages.vatNumberHelpText)}
                  />
                </Box>
              )}
            </Formik>
          </>
        ),
        id: 'document-vat-number-fix',
        title: t(messages.title),
        onClose: closeModal,
        buttonPrimary: (
          <Button
            data-testid="document-vat-number-fix-submit"
            variant="secondary"
            type="submit"
            form="fix-vat-number-form"
          >
            {t(messages.submit)}
          </Button>
        ),
        buttonSecondary: (
          <Hidden xs>
            <Button
              data-testid="document-vat-number-fix-cancel"
              variant="stroke"
              type="button"
              onClick={closeModal}
            >
              {t(messages.cancel)}
            </Button>
          </Hidden>
        ),
      }}
    />
  );
};

export default BaiFixVatNumberModal;
