import { FC } from 'react';

import useTranslations from 'modules/I18n/hooks/useTranslations';
import useCommercialNotifications from 'modules/Notifications/hooks/useCommercialNotifications';
import { Box } from 'modules/Ui';

import { CommercialNotification } from './CommercialNotification';

export const CommercialNotifications: FC = () => {
  const { commercialNotifications } = useCommercialNotifications();
  const { t } = useTranslations();

  return (
    <Box fadeIn>
      {commercialNotifications.length ? (
        <CommercialNotification
          body={t({ id: commercialNotifications[0].bodyKey })}
          category={commercialNotifications[0].category}
          imageUrl={commercialNotifications[0].imageUrl}
          title={t({ id: commercialNotifications[0].titleKey })}
          id={commercialNotifications[0].id}
        />
      ) : null}
    </Box>
  );
};
