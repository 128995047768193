import { FC, useCallback } from 'react';

import { useFormikContext } from 'formik';

import {
  AccountingAccount,
  AccountingAccountTypeEnum,
} from 'modules/AccountingAccounts/models';
import { DocumentTable } from 'modules/Documents/models/document';
import useTranslations from 'modules/I18n/hooks/useTranslations';
import { useOpenModal } from 'modules/Modals';
import { ModalTypes } from 'modules/Modals/constants';
import AccountingAccountInput from 'modules/Payments/components/AccountingAccountInput';
import { Box, FormikField } from 'modules/Ui';
import { Option } from 'modules/Ui/Form/SelectCommon/types';
import { DayPickerFormikField, SelectFormikField } from 'modules/Ui/Formik';

import { remittanceForm } from '../../../messages';
import { DirectDebitType, Remittance, RemittanceType } from '../../../types';

export enum RemittanceFormType {
  EDIT = 'EDIT',
  CREATE = 'CREATE',
}

interface Props {
  type: RemittanceFormType;
  preSelectedDocuments?: DocumentTable[];
  totalCount?: number;
  totalAmount?: number;
}

export const RemittanceForm: FC<Props> = ({
  type,
  preSelectedDocuments,
  totalAmount,
  totalCount,
}) => {
  const { t } = useTranslations();
  const openModal = useOpenModal();
  const modalCallback =
    type === RemittanceFormType.EDIT
      ? ModalTypes.EDIT_REMITTANCE_MODAL
      : ModalTypes.ADD_REMITTANCE_MODAL;
  const handleCreateAccount = useCallback((values) => {
    openModal({
      type: ModalTypes.ACCOUNTING_ACCOUNT,
      allowedTabs: [AccountingAccountTypeEnum.BANK_ACCOUNT],
      onCancel: () => {
        openModal({
          type: modalCallback,
          remittance: values,
          preSelectedDocuments,
          totalCount,
          totalAmount,
        });
      },
      onCreateAccountingAccount: (accountingAccount: AccountingAccount) => {
        openModal({
          type: modalCallback,
          accountingAccountId: accountingAccount.id,
          accountingAccountAlias: accountingAccount.alias,
          remittance: values,
          preSelectedDocuments,
          totalCount,
          totalAmount,
        });
      },
    });
  }, []);
  const formik = useFormikContext<Remittance>();
  const sepa = [
    {
      value: DirectDebitType.CORE,
      label: t(remittanceForm.core),
    },
    {
      value: DirectDebitType.B2B,
      label: t(remittanceForm.b2b),
    },
  ];
  const filterAccountingAccountOptions = (options?: Option[]) => {
    return (
      options?.filter(
        (option) =>
          option.additionalData?.type === AccountingAccountTypeEnum.BANK_ACCOUNT
      ) || []
    );
  };
  return (
    <>
      <FormikField
        id="remittanceConcept"
        label={t(remittanceForm.concept)}
        maxLength={120}
        required
        gridColumn="1 / 3"
      />
      <DayPickerFormikField
        id="remittanceExpiration"
        label={t(remittanceForm.expiration)}
        required
        gridColumn="1 / 2"
      />
      {formik.values.type === RemittanceType.DIRECT_DEBIT && (
        <SelectFormikField
          id="remittanceSepa"
          data-testid="remittance-sepa"
          label={t(remittanceForm.sepa)}
          options={sepa}
          required
        />
      )}
      <FormikField
        id="remittanceId"
        infoPlace="top"
        label={t(remittanceForm.id)}
        maxLength={120}
        required
        info={<Box width="212px">{t(remittanceForm.info)}</Box>}
        gridColumn="1 / 2"
      />
      <AccountingAccountInput
        onAddOption={() => handleCreateAccount(formik.values)}
        filterOptions={filterAccountingAccountOptions}
        required
        label={t(remittanceForm.account)}
      />
    </>
  );
};
