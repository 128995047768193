import { defineMessages } from 'react-intl';

export const texts = defineMessages({
  email: { id: 'gofre.Subscription.Detail.cancel-subscription.email' },
  emailPlaceholder: {
    id: 'gofre.Subscription.Detail.cancel-subscription.email.placeholder',
  },
  phone: { id: 'gofre.Subscription.Detail.cancel-subscription.phone' },
  phonePlaceholder: {
    id: 'gofre.Subscription.Detail.cancel-subscription.phone.placeholder',
  },
});
