import { Visible } from 'react-grid-system';

import { space, SpaceProps } from 'styled-system';

import useTranslations from 'modules/I18n/hooks/useTranslations';
import styled from 'modules/Theme/styled-components';
import { Box, Text } from 'modules/Ui';
import RadioButtonCardFormik from 'modules/Ui/Formik/RadioButtonCardFormik';

import messages from './messages';

interface HrProps extends SpaceProps {
  id: string;
  onChange?: (value: any) => void;
  price?: number;
  showYearlyPrice: boolean;
  text: string;
  value: string;
}

const RadioButton = styled(RadioButtonCardFormik)`
  ${space}
`;

const PlanPrice: React.FC<HrProps> = (props) => {
  const { onChange, price, value, text, id, showYearlyPrice, ...rest } = props;
  const { t } = useTranslations();
  return (
    <RadioButton
      {...{ text, value, id, ...rest }}
      flex="1"
      height={{ _: '56px', sm: '96px' }}
      name="periodicity"
      onChange={onChange}
    >
      <Visible md lg xl xxl>
        <Box alignItems="center" display="flex" height="77px" tag="span">
          <Text
            color="gray800"
            fontSize={32}
            lineHeight={32}
            fontWeight="600"
            tag="span"
          >
            {price}
          </Text>
          <Box tag="span">
            <Text
              color="gray800"
              display="block"
              fontSize={14}
              lineHeight={14}
              fontWeight="600"
              tag="span"
            >
              {showYearlyPrice
                ? t(messages.yearlyTaxes)
                : t(messages.monthlyTaxes)}
            </Text>
            <Text
              color="gray600"
              display="block"
              fontSize={11}
              lineHeight={11}
              tag="span"
            >
              + {t(messages.adCountryTax)}
            </Text>
          </Box>
        </Box>
      </Visible>
    </RadioButton>
  );
};

export default PlanPrice;
