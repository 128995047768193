import { FC } from 'react';

import useTranslations from 'modules/I18n/hooks/useTranslations';
import { Text } from 'modules/Ui';

import { shopifySuccess } from '../../messages';

interface ShopifyConfigurationSuccessBaiSubtitleProps {
  needsToConfigureBai: boolean;
  needsToConnectBai: boolean;
}

export const ShopifyConfigurationSuccessBaiSubtitle: FC<
  ShopifyConfigurationSuccessBaiSubtitleProps
> = (props) => {
  const { needsToConfigureBai, needsToConnectBai } = props;
  const shouldNotShowSubtitle = !needsToConnectBai && !needsToConfigureBai;
  const { t } = useTranslations();

  if (shouldNotShowSubtitle) {
    return null;
  }
  return (
    <Text
      color="primary500"
      fontSize={18}
      fontWeight="600"
      lineHeight={24}
      marginBottom={{ _: '16px', sm: '8px' }}
      tag="h2"
    >
      {needsToConfigureBai
        ? t(shopifySuccess.shopifyConfigSuccessSubTitleConfigure)
        : t(shopifySuccess.shopifyConfigSuccessSubTitleConnect)}
    </Text>
  );
};
