import { useEffect } from 'react';
import { Visible } from 'react-grid-system';

import useIsBookkeeperUser, {
  useIsBookkeeperLoggedInClient,
} from 'modules/Bookkeeper/hooks/useIsBookkeeperUser';
import useTranslations from 'modules/I18n/hooks/useTranslations';
import styled, { css } from 'modules/Theme/styled-components';
import { Button } from 'modules/Ui';
import AppBar from 'modules/Ui/AppBar';
import Drawer from 'modules/Ui/Drawer';
import { ButtonProps } from 'modules/Ui/Form/Button/Button';
import { IconMenu } from 'modules/Ui/Icons';
import Toolbar from 'modules/Ui/Toolbar';
import { useUserNotifications } from 'modules/UserNotifications/hooks/useUserNotifications';

import { actions } from '../messages';
import Logo from './Components/Logo';
import SubMenu from './Components/SubMenu';
import Menu from './Menu';
import useMenuMobile from './useMenuMobile';

interface ButtonMenuProps extends ButtonProps {
  isBookkeeper?: boolean;
}

const mobileBookkeeperFocusColor = css`
  svg {
    color: ${(props) => props.theme.colors.brand500};
  }
`;

const ButtonMenuOpen = styled(Button)<ButtonMenuProps>`
  &:focus {
    ${mobileBookkeeperFocusColor}
  }
`;

const MenuMobile = () => {
  const {
    isOpen,
    isSubMenuOpen,
    openSubMenu,
    closeSubmenu,
    closeMenu,
    toggleMenu,
    subMenuSection,
  } = useMenuMobile();
  const { t } = useTranslations();
  const isBookkeeperLoggedInClient = useIsBookkeeperLoggedInClient();
  const showBookkeeper = useIsBookkeeperUser();
  const {
    isLoading,
    data: notificationResponse,
    refetch,
  } = useUserNotifications();

  const showNotifications =
    !isLoading && notificationResponse && notificationResponse.unread > 0;
  useEffect(() => {
    refetch();
  }, [refetch]);

  const shouldBehaveLikeBookkeeper =
    showBookkeeper && !isBookkeeperLoggedInClient;

  return (
    <Visible xs>
      <AppBar>
        <Toolbar isBookkeeper={shouldBehaveLikeBookkeeper}>
          <ButtonMenuOpen
            accessibleText={t(actions.menuOpen)}
            aria-controls="menu"
            aria-haspopup="true"
            hide={isOpen}
            id="menuButton"
            isBookkeeper={shouldBehaveLikeBookkeeper}
            onClick={toggleMenu}
          >
            <IconMenu
              color={shouldBehaveLikeBookkeeper ? 'gray0' : 'primary500'}
              showBadge={showNotifications ?? false}
            />
          </ButtonMenuOpen>
          <Logo
            hide={isSubMenuOpen}
            isBookkeeper={shouldBehaveLikeBookkeeper}
          />
        </Toolbar>
      </AppBar>
      <Drawer>
        <Menu
          open={isOpen}
          isBookkeeper={shouldBehaveLikeBookkeeper}
          {...{
            closeMenu,
            closeSubmenu,
            isSubMenuOpen,
            openSubMenu,
          }}
        >
          <SubMenu
            closeMenu={closeMenu}
            open={isSubMenuOpen}
            section={subMenuSection}
          />
        </Menu>
      </Drawer>
    </Visible>
  );
};

export default MenuMobile;
