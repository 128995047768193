import React from 'react';

import useTranslations from 'modules/I18n/hooks/useTranslations';
import {
  leavingSettingsModal,
  confirmationDialog,
} from 'modules/Settings/messages';
import WarnBeforeNavigationDialog from 'modules/Ui/WarnBeforeNavigationDialog';

interface ConfirmNavigationDialogProps {
  text?: string;
  overrideSubmit: () => void;
}

const ConfirmNavigationDialog: React.FC<ConfirmNavigationDialogProps> = (
  props
) => {
  const { t } = useTranslations();
  const { text = t(confirmationDialog.question), overrideSubmit } = props;

  return (
    <WarnBeforeNavigationDialog
      title={t(leavingSettingsModal.title)}
      subtitle={t(leavingSettingsModal.subtitle)}
      confirm={t(leavingSettingsModal.confirmText)}
      cancel={t(leavingSettingsModal.cancelText)}
      question={text}
      overrideConfirm={overrideSubmit}
    />
  );
};

export default ConfirmNavigationDialog;
