import { FC } from 'react';

import { Box, Text } from 'modules/Ui';
import { IconCheck } from 'modules/Ui/Icons';

interface Props {
  text: string;
  description: string;
}

export const IncludedItem: FC<Props> = ({ text, description }) => {
  return (
    <Box tag="li" gap="8px" gridTemplateColumns="auto 1fr" display="grid">
      <IconCheck color="brand500" size={16} />
      <Text
        tag="span"
        fontSize={14}
        fontWeight="600"
        lineHeight={18}
        color="brand500"
      >
        {text}
      </Text>
      <Box
        color="gray600"
        fontSize={14}
        gridColumn="2 / 3"
        lineHeight={18}
        tag="span"
      >
        {description}
      </Box>
    </Box>
  );
};
