import { FC, ReactNode } from 'react';

import styled from 'styled-components';

import { bannerStock } from 'modules/Home/messages';
import useTranslations from 'modules/I18n/hooks/useTranslations';
import BasePill from 'modules/Ui/BasePill';
import Box from 'modules/Ui/Box';
import { IconArrowSecondary } from 'modules/Ui/Icons';
import Text from 'modules/Ui/Text';

interface ProductInfoBannerProps {
  actionText?: string;
  children?: ReactNode;
  description: string;
  image: string;
  isNew?: boolean;
  title?: string;
}

const StyledWrapper = styled(Box)<ProductInfoBannerProps>`
  a,
  .action__text--link {
    @media (hover: hover) {
      &:hover {
        color: ${(props) => props.theme.colors.brand700};
        cursor: pointer;
        text-decoration: underline;
      }
    }

    &:focus {
      color: ${(props) => props.theme.colors.brand700};
      cursor: pointer;
      text-decoration: underline;

    }
  }
`;

export const BannerContent: FC<ProductInfoBannerProps> = ({
  actionText,
  children,
  description,
  image,
  isNew,
  title,
}) => {
  const { t } = useTranslations();
  return (
    <StyledWrapper
      display="grid"
      gridTemplateColumns={{ sm: '1fr 1fr' }}
      gridTemplateRows={{ _: '120px auto', sm: 'initial' }}
      columnGap={{ sm: '16px' }}
      overflow="hidden"
      tag="span"
      height="100%"
    >
      <Box
        background={`url(${image}) no-repeat center center`}
        backgroundSize={{ _: 'cover', lg: 'contain' }}
        tag="span"
      />
      <Box
        display={{ lg: 'grid' }}
        gridTemplateRows={{ lg: '1fr auto' }}
        padding={{
          _: '24px 16px 16px',
          sm: '19px 12px 16px',
          lg: '24px 16px',
        }}
        tag="span"
      >
        <Box display="block" tag="span" position="relative">
          {title && (
            <>
              {isNew && (
                <BasePill
                  backgroundColor="brand500"
                  color="gray0"
                  fontSize={10}
                  height="16px"
                  minWidth="initial"
                  padding="0 6px"
                  position={{ _: 'absolute', lg: 'initial' }}
                  right={{ _: '0', lg: 'initial' }}
                  textTransform="uppercase"
                  top={{ _: '-10px', lg: 'initial' }}
                >
                  {t(bannerStock.new)}
                </BasePill>
              )}
              <Text
                color="gray800"
                display="block"
                fontSize={12}
                lineHeight={14}
                marginBottom={{ _: '6px', sm: '4px', lg: '8px' }}
                marginTop={isNew ? { lg: '8px' } : undefined}
                tag="span"
                textTransform="uppercase"
              >
                {title}
              </Text>
            </>
          )}
          {description && (
            <Text
              color="gray800"
              display="block"
              fontSize={{ _: 14, lg: 16 }}
              lineHeight={{ _: 18, lg: 20 }}
              marginBottom={{ _: '12px', lg: 'initials' }}
              tag="span"
            >
              {description}
            </Text>
          )}
        </Box>
        {actionText && (
          <Box
            alignItems="center"
            className="product-info-banner-action"
            columnGap="4px"
            display="grid"
            gridTemplateColumns="1fr 24px"
            tag="span"
            width="fit-content"
          >
            <Text
              className="action__text--link"
              color="brand500"
              fontSize={14}
              lineHeight={18}
              tag="span"
              textDecoration="underline"
            >
              {actionText}
            </Text>
            <IconArrowSecondary size={18} color="brand500" />
          </Box>
        )}
        {children}
      </Box>
    </StyledWrapper>
  );
};
