import { useState, useRef, useEffect } from 'react';

import sanitizeHtml from 'sanitize-html';
import styled from 'styled-components';

import useTranslations from 'modules/I18n/hooks/useTranslations';
import { multilineEllipsis } from 'modules/Theme/mixins';
import { Box, Button } from 'modules/Ui';
import { IconActionsDelete, IconAdd } from 'modules/Ui/Icons';

import messages from '../../Products/Detail/messages';

interface ExpandableDescriptionProps {
  description?: string;
  linesToShow?: number | string;
}

const StyledExpandableDescriptionParagraph = styled(Box)<{
  isOverflowing: boolean;
  linesToShow: number | string;
}>`
    ${(props) => multilineEllipsis(props.linesToShow)}

`;

const allowedTags = ['br'];
const allowedAttributes = {};

const ExpandableTextWithButton = ({
  description,
  linesToShow = 3,
}: ExpandableDescriptionProps) => {
  const { t } = useTranslations();
  const [isExpanded, setIsExpanded] = useState(false);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const descriptionRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (!descriptionRef.current) return;
    const descriptionEl = descriptionRef?.current as HTMLDivElement;
    setIsOverflowing(descriptionEl.scrollHeight > descriptionEl.clientHeight);
  }, []);

  return (
    <>
      <StyledExpandableDescriptionParagraph
        id="content-description"
        isOverflowing={isOverflowing}
        lineHeight={20}
        linesToShow={isExpanded ? 'unset' : linesToShow}
        marginTop={{ _: '16px', sm: 'initial' }}
        ref={descriptionRef}
        tag="p"
      >
        <span
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: sanitizeHtml(
              description?.replaceAll('\n', '<br />') || '',
              { allowedTags, allowedAttributes }
            ),
          }}
        />
      </StyledExpandableDescriptionParagraph>
      {isOverflowing && (
        <Button
          aria-controls="content-description"
          aria-expanded={isExpanded}
          border="none"
          height="40px"
          icon={isExpanded ? <IconActionsDelete /> : <IconAdd />}
          iconPosition="end"
          onClick={() => {
            setIsExpanded(!isExpanded);
          }}
          padding="0 14px"
          marginTop="8px"
          variant="ghost"
          width="fit-content"
        >
          {t(isExpanded ? messages.viewLess : messages.viewMore)}
        </Button>
      )}
    </>
  );
};

export default ExpandableTextWithButton;
