const buildLink = (file: Blob) => {
  const a = document.createElement('a');
  const url = window.URL.createObjectURL(file);
  a.href = url;
  document.body.appendChild(a);
  return { a, url };
};

const clickDownloadLink = (file: Blob, fileName: string) => {
  const { a, url } = buildLink(file);
  a.download = fileName;
  a.click();
  return { a, url };
};

const removeLink = (a: HTMLAnchorElement, url: string) => {
  document.body.removeChild(a);
  window.URL.revokeObjectURL(url);
};

const isInternetExplorer = () => {
  const { msSaveOrOpenBlob } = window.navigator as any;
  return !!msSaveOrOpenBlob;
};

const downloadInInternetExplorer = (file: Blob, fileName: string) => {
  const { msSaveOrOpenBlob } = window.navigator as any;
  msSaveOrOpenBlob(file, fileName);
};

const isChromeIOS = () => {
  const { userAgent } = window.navigator;

  return userAgent.match('CriOS');
};

const downloadChromeIOS = (file: Blob) => {
  const reader = new FileReader();
  reader.onloadend = () => window.location.assign(reader.result as string);
  reader.readAsDataURL(file);
};

export const isIOSPlatform = (): boolean => {
  const { platform } = window.navigator;
  return /(iPad|iPhone|iPod)/i.test(platform);
};

export const isIOS = () => {
  const { userAgent } = window.navigator;
  return /(iPad|iPhone|iPod)/i.test(userAgent);
};

const downloadFile = (file: Blob, fileName: string) => {
  if (isInternetExplorer()) {
    downloadInInternetExplorer(file, fileName);
  } else if (isChromeIOS()) {
    downloadChromeIOS(file);
  } else {
    const { a, url } = clickDownloadLink(file, fileName);
    removeLink(a, url);
  }
};

export default downloadFile;
