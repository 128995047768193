import { roundNumber } from 'utils/MathOperations';

import { TaxKey } from '../../../Taxes';
import {
  TotalsFromConcepts,
  TortillaConcept as Concept,
  TortillaTaxLine as TaxLine,
} from './types';

/*
  IF YOU CHANGE ANYTHING BELOW THIS COMMENT YOU NEED TO DO THE SAME CHANGE IN GOFRE / TORTILLA
  WE ROUND TO 3 DECIMAL PLACES SO WE DONT LOSE PRECISION ON THE SECOND DECIMAL PLACE
  THE OBJECTIVE OF IS THAT THE TOTALS MUST ADD PERFECTLY, SUBTOTAL + TAX = TOTAL
  WE ARE FINE LOOSING SOME TRAILING CENTS IN THE SUM OF THE CONCEPTS
 */

type TotalsByTaxKey = {
  taxKey: TaxKey;
  taxPercentage: number;
  salesEqTaxPercentage: number;
  salesEqTaxAmount?: number;
  totalAmount: number;
  discount: number;
  fixedTaxAmountByClient?: number;
};

type ConceptReadyForCalcs = {
  taxKey: TaxKey;
  taxPercentage: number;
  quantity: number;
  unitPrice: number;
  discountPercentage: number;
  subtotal: number;
  discount: number;
  taxBase: number;
  taxAmount: number;
  salesEqTaxPercentage: number;
  salesEqTax: number;
  totalAmount: number;
  discountFromRoundedTaxBase: number;
  taxesObjectKey: string;
};

type TotalsFromConceptsTaxBase = {
  subtotal: number;
  discount: number;
  taxBase: number;
  taxAmount: number;
  salesEqTax: number;
  totalAmount: number;
  taxes: { [key: string]: TaxLine };
};

export function calculateTotalsFromConceptsTaxbasesV2(
  concepts: Concept[]
): TotalsFromConcepts {
  const anyConceptHasFixedTaxAmountByClient = concepts.some(
    (concept) => concept.fixedTaxAmountByClient
  );
  if (anyConceptHasFixedTaxAmountByClient) {
    throw new Error(
      'Concepts with fixedTaxAmountByClient are not supported in this function'
    );
  }
  const conceptsReadyForCalcs = concepts.map(prepareConcept);

  const totalsByTaxes: { [key: string]: TotalsByTaxKey } =
    conceptsReadyForCalcs.reduce(totalsByTaxesReducer, {});

  const totals = Object.entries(totalsByTaxes).reduce(totalsReducer, {
    subtotal: 0,
    discount: 0,
    taxBase: 0,
    taxAmount: 0,
    salesEqTax: 0,
    totalAmount: 0,
    taxes: {},
  });

  return {
    subtotal: roundNumber(totals.subtotal),
    discount: roundNumber(totals.discount),
    taxBase: roundNumber(totals.taxBase),
    taxAmount: roundNumber(totals.taxAmount),
    salesEqTax: roundNumber(totals.salesEqTax),
    totalAmount: roundNumber(totals.totalAmount),
    taxes: totals.taxes,
  };
}

const prepareConcept = (concept: Concept): ConceptReadyForCalcs => {
  const { subtotal, discount, discountPercentage, taxPercentage } = concept;
  const taxBase = roundNumber(concept.taxBase ?? subtotal - discount, 2);
  const taxAmount = taxBase * (taxPercentage / 100);
  const salesEqTax = taxBase * (concept.salesEqTaxPercentage / 100);
  const totalAmount = taxBase + taxAmount + salesEqTax;
  const discountFromRoundedTaxBase =
    taxBase !== 0 ? taxBase / (1 - discountPercentage / 100) - taxBase : 0;

  return {
    taxKey: concept.taxKey,
    taxPercentage,
    quantity: concept.quantity,
    unitPrice: concept.unitPrice,
    discountPercentage,
    subtotal: concept.subtotal,
    discount: concept.discount,
    taxBase,
    taxAmount,
    salesEqTaxPercentage: concept.salesEqTaxPercentage,
    salesEqTax,
    totalAmount,
    discountFromRoundedTaxBase,
    taxesObjectKey: concept.taxKey,
  };
};

const totalsByTaxesReducer = (
  acc: { [key: string]: TotalsByTaxKey },
  currentConcept: ConceptReadyForCalcs
): { [key: string]: TotalsByTaxKey } => {
  const {
    totalAmount,
    discountFromRoundedTaxBase: discount,
    taxKey,
    taxPercentage = 0,
    salesEqTaxPercentage = 0,
    taxesObjectKey,
  } = currentConcept;

  const value = acc[taxKey] ?? {
    taxKey,
    taxPercentage,
    salesEqTaxPercentage,
    totalAmount: 0,
    discount: 0,
  };
  value.totalAmount += totalAmount ?? 0;
  value.discount += discount ?? 0;
  return {
    ...acc,
    [taxesObjectKey]: value,
  };
};

const calcTaxData = (
  totalByTaxKey: TotalsByTaxKey
): {
  taxBase: number;
  taxAmount: number;
  salesEqTax: number;
  totalAmount: number;
} => {
  const {
    totalAmount: total,
    taxPercentage,
    salesEqTaxPercentage,
  } = totalByTaxKey;
  const taxBase = roundNumber(
    total / (1 + (taxPercentage + salesEqTaxPercentage) / 100),
    2
  );
  const taxAmount = roundNumber(taxBase * (taxPercentage / 100), 2);
  const salesEqTax =
    salesEqTaxPercentage > 0 ? roundNumber(total - taxBase - taxAmount, 2) : 0;
  const totalAmount = taxBase + taxAmount + salesEqTax;
  return {
    taxBase,
    taxAmount,
    salesEqTax,
    totalAmount,
  };
};

const totalsReducer = (
  acc: TotalsFromConceptsTaxBase,
  [key, totalByTaxKey]: [string, TotalsByTaxKey]
): TotalsFromConceptsTaxBase => {
  const { taxKey, taxPercentage, salesEqTaxPercentage } = totalByTaxKey;
  const taxData = calcTaxData(totalByTaxKey);

  const taxLine: TaxLine = {
    taxKey,
    total: roundNumber(taxData.totalAmount, 2),
    taxPercentage: roundNumber(taxPercentage),
    taxBase: taxData.taxBase,
    taxAmount: taxData.taxAmount,
    salesEqTax: taxData.salesEqTax,
    salesEqTaxPercentage,
  };

  acc.subtotal += taxLine.taxBase + totalByTaxKey.discount;
  acc.discount += totalByTaxKey.discount;
  acc.taxBase += taxLine.taxBase;
  acc.taxAmount += taxLine.taxAmount;
  acc.salesEqTax += taxLine.salesEqTax;
  acc.totalAmount += taxData.totalAmount;

  acc.taxes[key] = taxLine;
  return acc;
};
