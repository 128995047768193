import * as Yup from 'yup';

import { TranslationFunction } from 'modules/I18n/hooks/useTranslations';

import { formText } from '../../messages';
import {
  ALIAS_MAX_LENGTH,
  ALIAS_MIN_LENGTH,
} from '../AccountingAccountModal/constants';

export const getValidationSchema = (t: TranslationFunction) =>
  Yup.object().shape({
    alias: Yup.string()
      .required(t(formText.mandatory))
      .min(ALIAS_MIN_LENGTH, t(formText.aliasFormatValidation))
      .max(ALIAS_MAX_LENGTH, t(formText.aliasFormatValidationMax)),
  });
