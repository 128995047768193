import { FC, ReactNode } from 'react';

import { Box, Link, Text } from 'modules/Ui';
import { IconByPathProps } from 'modules/Ui/Icons/Icon/IconType/IconByPath';

interface ModalContentProps {
  Icon?: FC<IconByPathProps>;
  iconColor?: string;
  iconStroke?: number;
  linkText?: string;
  subtitle: string;
  text: string | ReactNode;
  zip?: string;
  url?: string;
  onNavigate?: () => void;
}

const ModalContent: FC<ModalContentProps> = ({
  Icon,
  iconColor,
  iconStroke,
  linkText,
  subtitle,
  text,
  zip,
  url,
  onNavigate,
}) => {
  return (
    <Box Box textAlign="center" paddingTop="24px">
      {Icon && (
        <Icon
          color={iconColor}
          marginBottom="24px"
          size={64}
          strokeWidth={iconStroke}
        />
      )}
      <Text color="gray800" lineHeight={22} marginBottom="16px">
        <Text color="inherit" lineHeight="inherit" tag="strong">
          {subtitle}
        </Text>
      </Text>
      <Text fontSize={14} lineHeight={22} marginBottom="16px">
        {text}
        {zip && linkText && (
          <Link
            color="brand500"
            download
            hoverUnderline
            isExternal
            paddingLeft="6px"
            to={zip || ''}
            type="application/zip"
          >
            {linkText}
          </Link>
        )}
        {url && linkText && (
          <Link
            color="brand500"
            hoverUnderline
            paddingLeft="6px"
            to={url || ''}
            onClick={onNavigate}
          >
            {linkText}
          </Link>
        )}
      </Text>
    </Box>
  );
};

export default ModalContent;
