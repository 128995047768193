import { useSelector } from 'react-redux';

import { selectBrowser } from 'modules/App/store/selectors';
import styled, { css } from 'modules/Theme/styled-components';
import { zIndex } from 'modules/Ui/zIndex';

import Box from '../Box';
import Button from '../Form/Button';
import { DataTableAction } from './DataTable.models';

export const shadowPosition = css`
  content: '';
  height: 100%;
  position: absolute;
  top: 0;
  width: 24px;
  z-index: 2;
`;

const SyledActionList = styled(Box)``;

const Wrapper = styled(Box)`
  transform: translate(-50%, -50%);

  ${SyledActionList} {
    scrollbar-color: ${(props) => props.theme.colors.primary400} ${(props) =>
  props.theme.colors.primary400};

    &:before {
      background: linear-gradient(90deg, ${(props) =>
        props.theme.colors.primary400} 24.69%, rgba(65, 79, 98, 0) 100%);
      ${shadowPosition}
      left: 0;
    }

    &:after {
      background: linear-gradient(270deg, ${(props) =>
        props.theme.colors.primary400} 24.69%, rgba(65, 79, 98, 0) 100%);
      ${shadowPosition}
      right: 0;

    }
  }
`;

export default function DataTableSelectedActions<T extends object>({
  actions,
  selected,
  selectionInverted,
  onClickAction,
}: {
  actions: DataTableAction<T>[];
  selected: T[];
  selectionInverted?: boolean;
  onClickAction: (
    data: T[],
    inverted: boolean,
    action: DataTableAction<T>
  ) => void;
}) {
  const browser = useSelector(selectBrowser);
  const isTablet = browser.lessThan.large;
  const isActionHidden = (action: DataTableAction<T>) => {
    return (
      (action.disableMultiple && (selected.length > 1 || selectionInverted)) ||
      (action.onlyMultiple && selected.length === 1 && !selectionInverted) ||
      (action.shouldHideOnInverted && selectionInverted)
    );
  };

  function renderAction(action: DataTableAction<T>) {
    const buttonText = isTablet ? action.abbrname || action.name : action.name;
    return (
      <li key={action.name}>
        <Button
          data-testid={`datatable-action-${action.name}`}
          icon={action.icon}
          onClick={() => {
            onClickAction(selected, !!selectionInverted, action);
          }}
          variant="ghostDark"
        >
          {buttonText}
        </Button>
      </li>
    );
  }

  const availableActions = actions.filter(
    (action) => action.shouldActionRender && !isActionHidden(action)
  );

  if (!availableActions.length) return null;

  return (
    <Wrapper
      borderRadius="36px"
      bottom={{ sm: '0', md: '14px' }}
      display="flex"
      fadeIn
      id="data-table-selected-actions"
      justifyContent="center"
      left="50%"
      margin="0 auto"
      maxWidth="100%"
      overflow="hidden"
      position="fixed"
      role="alert"
      tag="aside"
      zIndex={zIndex.SElECTED_ACTIONS}
    >
      <SyledActionList
        alignItems="center"
        backgroundColor="primary400"
        borderRadius="36px"
        display="flex"
        height="100%"
        justifyContent="space-between"
        overflowY="auto"
        padding="16px"
        tag="ul"
      >
        {availableActions.map(renderAction)}
      </SyledActionList>
    </Wrapper>
  );
}
