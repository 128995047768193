import { Provinces } from 'modules/Address/models/provinces';
import { TaxKey } from 'modules/Taxes';

const specialProvinces: Provinces = {
  'ES-GC': TaxKey.IVA_0,
  'ES-TF': TaxKey.IVA_0,
  'ES-CE': TaxKey.IVA_0,
  'ES-ML': TaxKey.IVA_0,
};

export const getVatKey = (province: keyof Provinces) => {
  const tax = specialProvinces[province];
  if (!tax) {
    return TaxKey.IVA_21;
  }
  return tax;
};
