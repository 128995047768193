import { useEffect, useState, FC } from 'react';

import { useFormikContext } from 'formik';

import useFetchAccountingAccounts from 'modules/AccountingAccounts/hooks/useFetchAccountingAccounts';
import { AccountingAccount } from 'modules/AccountingAccounts/models';
import useTranslations from 'modules/I18n/hooks/useTranslations';
import { Text } from 'modules/Ui';
import { Option } from 'modules/Ui/Form/SelectCommon/types';
import { SelectFormikField } from 'modules/Ui/Formik';

import OptionLabel from './components/OptionLabel';
import messages from './messages';

interface Props {
  label?: string;
  onAddOption: () => void;
  required?: boolean;
  selectedOption?: Option | null;
  filterOptions?: (options?: Option[]) => Option[];
}

const AccountingAccountInput: FC<Props> = ({
  label,
  onAddOption,
  required,
  selectedOption,
  filterOptions = (options) => options,
}) => {
  const formik = useFormikContext();
  const [options, setOptions] = useState<Option[] | undefined>(undefined);
  const { t } = useTranslations();
  const { data: accountingAccountsResponse, isLoading } =
    useFetchAccountingAccounts();
  const accountingAccounts = accountingAccountsResponse?.items;
  useEffect(() => {
    const accountOptions: Option[] | undefined = accountingAccounts?.map(
      (accountingAccount: AccountingAccount) => {
        const option: Option = {
          value: accountingAccount.id ?? '',
          label: accountingAccount.alias,
          additionalData: accountingAccount,
        };
        return option;
      }
    );
    setOptions(filterOptions(accountOptions));
  }, [accountingAccounts]);

  const getNoOptionsMessageComponent = () => {
    const text = t(messages.noAccountingAccountCreated);
    return <Text>{text}</Text>;
  };

  const onChange = (option: string) => {
    if (option) {
      const selectedAccountingAccount = accountingAccounts?.find(
        (accountingAccount) => accountingAccount.id === option
      );

      if (selectedAccountingAccount) {
        formik.setFieldValue(
          'accountingAccountAlias',
          selectedAccountingAccount.alias
        );
      }
    } else {
      formik.setFieldValue('accountingAccountAlias', '');
    }
  };

  return (
    <SelectFormikField
      label={label || t(messages.accountingAccountLabel)}
      data-testid="accounting-account-select"
      canCreateOption
      formatOptionLabel={OptionLabel}
      id="accountingAccountId"
      noOptionsMessage={getNoOptionsMessageComponent}
      shouldRenderMobileSelect={false}
      defaultOptions
      options={options}
      onChange={onChange}
      onAddOption={onAddOption}
      placeholder={t(messages.chooseAccountingAccount)}
      loadingMessage={() => t(messages.loadingAccountingAccounts)}
      menuListText={t(messages.accountingAccountAddLabel)}
      selectedOption={selectedOption}
      isLoading={isLoading}
      required={required}
    />
  );
};

export default AccountingAccountInput;
