import HtmlComponent from './HtmlComponent';
import Props from './Props';

const validProps = [
  'autoFocus',
  'className',
  'disabled',
  'formAction',
  'formEnctype',
  'formMethod',
  'formTarget',
  'id',
  'formNovalidate',
  'name',
  'type',
  'value',
  'form',
  /^(aria-)/,
  /^(data-)/,
];

const Button = (props: Props) => (
  <HtmlComponent tag="button" {...{ validProps }} {...props} />
);

export default Button;
