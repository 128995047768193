import { FC } from 'react';

import { Text } from 'modules/Ui';
import { Option } from 'modules/Ui/Form/SelectCommon/types';

const OptionLabel: FC<Option> = ({ label }) => {
  return (
    <Text
      className="react-select__option__text"
      tag="span"
      color="inherit"
      width="70%"
      hasEllipsis
    >
      {label}
    </Text>
  );
};

export default OptionLabel;
