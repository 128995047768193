import { FC, useCallback, useMemo, useReducer, useState } from 'react';

import useTranslations from 'modules/I18n/hooks/useTranslations';
import { MODAL_WIDTH_SMALL, Dialog } from 'modules/Modals';
import { useCloseModal } from 'modules/Modals/hooks';
import useTheme from 'modules/Theme/hooks/useTheme';
import { IconStateDone, IconSpinner, IconStateWarning } from 'modules/Ui/Icons';

import useOpenDocumentZip from '../../hooks/useOpenDocumentZip';
import messages from './messages';
import ModalContent from './ModalContent';
import { MultipleZipsDownloadModalProps } from './types';

const reducer = (state: number, action: { type: string }) => {
  switch (action.type) {
    case 'increment':
      return state + 1;
    default:
      return state;
  }
};

const MultipleZipsDownloadModal: FC<MultipleZipsDownloadModalProps> = ({
  taskIds,
}) => {
  const { t } = useTranslations();
  const theme = useTheme();
  const [zipUrl, setZipUrl] = useState('');
  const [tasksCompleted, dispatch] = useReducer(reducer, 0);

  const [isTimedOut, setIsTimedOut] = useState(false);
  const title = t(messages.title);
  const onSuccess = (newZipUrl: string) => {
    dispatch({ type: 'increment' });
    setZipUrl(newZipUrl);
  };

  const onError = useCallback(() => {
    setIsTimedOut(true);
  }, [setIsTimedOut]);
  const closeModal = useCloseModal();
  const cancelRequests = useOpenDocumentZip({
    taskIds,
    onError,
    onSuccess,
  });

  const onClose = useCallback(() => {
    closeModal();
    cancelRequests();
  }, []);

  const content = useMemo(() => {
    if (isTimedOut) {
      return (
        <ModalContent
          Icon={IconStateWarning}
          iconColor={theme.colors.error}
          subtitle={t(messages.errorSubtitle)}
          text={t(messages.errorText)}
        />
      );
    }
    if (tasksCompleted >= 1) {
      return (
        <ModalContent
          Icon={IconStateDone}
          linkText={t(messages.finishedLink, { count: taskIds.length })}
          subtitle={t(messages.finishedSubtitle, {
            count: taskIds.length === tasksCompleted ? 1 : taskIds.length,
            current: tasksCompleted,
          })}
          text={t(messages.finishedText, { count: taskIds.length })}
          url={taskIds.length === 1 ? '' : '/notifications'}
          zip={taskIds.length === 1 ? zipUrl : ''}
          onNavigate={closeModal}
        />
      );
    }
    return (
      <ModalContent
        Icon={IconSpinner}
        iconColor={theme.colors.brand500}
        subtitle={t(messages.notFinishedSubtitle)}
        text={t(messages.notFinishedText)}
        iconStroke={6}
      />
    );
  }, [tasksCompleted, isTimedOut, taskIds.length, zipUrl]);

  return (
    <Dialog
      title={title}
      content={content}
      dialogWidth={MODAL_WIDTH_SMALL}
      onClose={onClose}
    />
  );
};

export default MultipleZipsDownloadModal;
