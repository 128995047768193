import { defineMessages } from 'react-intl';

import { TargetDocument } from './models';
import { TemplateType } from './Theme/types';

export default defineMessages({
  cancel: { id: 'gofre.Settings.Documents.cancel' },
  documentInfo: { id: 'gofre.Settings.Documents.document-info' },
  errorRetrieving: { id: 'gofre.Settings.Documents.error-retrieving' },
  errorUpdating: { id: 'gofre.Settings.Documents.error-updating' },
  paymentText: { id: 'gofre.Settings.Documents.Payments.text' },
  paymentTitle: { id: 'gofre.Settings.Documents.Payments.title' },
  referenceLabel: { id: 'gofre.Settings.Documents.reference-label' },
  referenceTitle: { id: 'gofre.Settings.Documents.reference-title' },
  refText: { id: 'gofre.Settings.Documents.ref.text' },
  refTitle: { id: 'gofre.Settings.Documents.ref.title' },
  removeSerie: { id: 'gofre.Settings.Documents.serie.remove' },
  salesTaxEqTitle: { id: 'gofre.Settings.Documents.sales-tax-eq-label' },
  serialCodeCTA: { id: 'gofre.Settings.Documents.Series.serial-code-cta' },
  serialCodeLabel: { id: 'gofre.Settings.Documents.Series.serial-code-label' },
  seriesCreated: { id: 'gofre.Settings.Documents.Series.series-created' },
  serieText01: { id: 'gofre.Settings.Documents.serie.text01' },
  serieText02: { id: 'gofre.Settings.Documents.serie.text02' },
  serieTitle: { id: 'gofre.Settings.Documents.serie.title' },
  settingsUpdated: { id: 'gofre.Settings.Documents.settings-updated' },
  baiSettingsUpdated: { id: 'gofre.Settings.Documents.bai-settings-updated' },
  submit: { id: 'gofre.Settings.Documents.submit' },
  title: { id: 'gofre.Settings.title' },
  documentTab: { id: 'gofre.Settings.Documents.title' },
  themeTab: { id: 'gofre.Settings.Theme.title' },
  paymentMethodTab: { id: 'gofre.Settings.Theme.paymentMethod' },
  logo: { id: 'gofre.Settings.Theme.logo' },
  zeroSeries: { id: 'gofre.Settings.Documents.Series.zero-series' },
  dropzoneTitle: { id: 'gofre.Settings.Theme.Dropzone.title' },
  description: { id: 'gofre.Settings.Theme.Dropzone.description' },
  content: { id: 'gofre.Settings.Theme.Dropzone.content' },
  templateType: { id: 'gofre.Settings.Theme.templateType' },
  templateTypeText: { id: 'gofre.Settings.Theme.templateTypeText' },
  confirmNavigationText: { id: 'gofre.Settings.Theme.confirmNavigationText' },
  template01: { id: 'gofre.Settings.Theme.template01' },
  template02: { id: 'gofre.Settings.Theme.template02' },
  template03: { id: 'gofre.Settings.Theme.template03' },
  template04: { id: 'gofre.Settings.Theme.template04' },
  templateName: { id: 'gofre.Settings.Theme.templates.name' },
  templateSelectTitle: { id: 'gofre.Settings.Theme.templates.title' },
  templateSelectDesc: { id: 'gofre.Settings.Theme.templates.desc' },
  templatePersonalization: {
    id: 'gofre.Settings.Theme.templatePersonalization',
  },
  templatePersonalizationText: {
    id: 'gofre.Settings.Theme.templatePersonalizationText',
  },
  preview: { id: 'gofre.Settings.Theme.preview' },
  includeReferenceTitle: {
    id: 'gofre.Settings.includeReferenceTitle',
  },
  referenceInInvoice: {
    id: 'gofre.Settings.referenceInInvoice',
  },
  referenceInQuote: {
    id: 'gofre.Settings.referenceInQuote',
  },
  referenceInProforma: {
    id: 'gofre.Settings.referenceInProforma',
  },
  referenceInDeliveryNotes: {
    id: 'gofre.Settings.referenceInDeliveryNotes',
  },
  salesTaxEqInInvoice: {
    id: 'gofre.Settings.salesTaxEqInInvoice',
  },
  salesTaxEqInQuote: {
    id: 'gofre.Settings.salesTaxEqInQuote',
  },
  salesTaxEqInProforma: {
    id: 'gofre.Settings.salesTaxEqInProforma',
  },
  salesTaxEqInDeliveryNotes: {
    id: 'gofre.Settings.salesTaxEqInDeliveryNotes',
  },
  documentFields: {
    id: 'gofre.Settings.documentFields',
  },
  hasRetentions: {
    id: 'gofre.Settings.hasRetentions',
  },
  showProductReference: {
    id: 'gofre.Settings.showProductsReferences',
  },
  retentionsPercentage: { id: 'gofre.Settings.retentionsPercentage' },
  bizkaia140Title: { id: 'gofre.Settings.bizkaia140Title' },
  bizkaia140NaturaPerson: { id: 'gofre.Settings.bizkaia140NaturaPerson' },
  naturalPersonAffirmative: { id: 'gofre.Settings.naturalPersonAffirmative' },
  naturalPersonNegative: { id: 'gofre.Settings.naturalPersonNegative' },
  descriptionPlaceholder: { id: 'gofre.Settings.descriptionPlaceholder' },
  commentsTitle: { id: 'gofre.Settings.commentsTitle' },
  newTemplateName: {
    id: 'gofre.Settings.Templates.template.name',
  },
  shopifyTab: {
    id: 'gofre.Settings.tabs.shopify',
  },
  otherTab: {
    id: 'gofre.Settings.tabs.other',
  },
  actiaveShopifyNotification: {
    id: 'gofre.Settings.Shopify.activate.notification',
  },
  deactiaveShopifyNotification: {
    id: 'gofre.Settings.Shopify.deactivate.notification',
  },
  buttonPrev: { id: 'gofre.theme.carousel.button.prev' },
  buttonNext: { id: 'gofre.theme.carousel.button.next' },
});

export const templatesLimitModal = defineMessages({
  title: {
    id: 'gofre.Settings.Templates.Modal.limit.title',
  },
  subtitle: {
    id: 'gofre.Settings.Templates.Modal.limit.subtitle',
  },
  text: {
    id: 'gofre.Settings.Templates.Modal.limit.text',
  },
  acceptButton: {
    id: 'gofre.Settings.Templates.Modal.limit.accept-button',
  },
});

export const newTemplateModal = defineMessages({
  title: {
    id: 'gofre.Settings.Templates.newTemplateModal.title',
  },
  subtitle: {
    id: 'gofre.Settings.Templates.newTemplateModal.subtitle',
  },
  text: {
    id: 'gofre.Settings.Templates.newTemplateModal.text',
  },
  textInUse: {
    id: 'gofre.Settings.Templates.newTemplateModal.textInUse',
  },
  confirmText: {
    id: 'gofre.Settings.Templates.newTemplateModal.confirm-text',
  },
  cancelText: {
    id: 'gofre.Settings.Templates.newTemplateModal.cancel-text',
  },
});

export const leavingSettingsModal = defineMessages({
  title: {
    id: 'gofre.Settings.Templates.leavingSettingsModal.title',
  },
  subtitle: {
    id: 'gofre.Settings.Templates.leavingSettingsModal.subtitle',
  },
  text: {
    id: 'gofre.Settings.Templates.leavingSettingsModal.text',
  },
  textInUse: {
    id: 'gofre.Settings.Templates.leavingSettingsModal.textInUse',
  },
  confirmText: {
    id: 'gofre.Settings.Templates.leavingSettingsModal.confirm-text',
  },
  cancelText: {
    id: 'gofre.Settings.Templates.leavingSettingsModal.cancel-text',
  },
});

export const updatingRecurrentTemplateModal = defineMessages({
  title: {
    id: 'gofre.Settings.Templates.updatingRecurrentTemplateModal.title',
  },
  subtitle: {
    id: 'gofre.Settings.Templates.updatingRecurrentTemplateModal.subtitle',
  },
  text: {
    id: 'gofre.Settings.Templates.updatingRecurrentTemplateModal.text',
  },
  confirmText: {
    id: 'gofre.Settings.Templates.updatingRecurrentTemplateModal.confirm-text',
  },
  cancelText: {
    id: 'gofre.Settings.Templates.updatingRecurrentTemplateModal.cancel-text',
  },
});

export const series = defineMessages({
  delete: {
    id: 'gofre.Settings.Series.delete',
  },
  [`targetDocument-${TargetDocument.INVOICE}`]: {
    id: `gofre.Settings.Series.targetDocument.${TargetDocument.INVOICE}`,
  },
  [`targetDocument-${TargetDocument.EXPENSE}`]: {
    id: `gofre.Settings.Series.targetDocument.${TargetDocument.EXPENSE}`,
  },
  [`targetDocument-${TargetDocument.QUOTE}`]: {
    id: `gofre.Settings.Series.targetDocument.${TargetDocument.QUOTE}`,
  },
  [`targetDocument-${TargetDocument.PROFORMA}`]: {
    id: `gofre.Settings.Series.targetDocument.${TargetDocument.PROFORMA}`,
  },
  [`targetDocument-${TargetDocument.CORRECTIVE}`]: {
    id: `gofre.Settings.Series.targetDocument.${TargetDocument.CORRECTIVE}`,
  },
  [`targetDocument-${TargetDocument.DELIVERY_NOTE}`]: {
    id: `gofre.Settings.Series.targetDocument.${TargetDocument.DELIVERY_NOTE}`,
  },
  [`targetDocument-${TargetDocument.TEST}`]: {
    id: `gofre.Settings.Series.targetDocument.${TargetDocument.TEST}`,
  },
  title: {
    id: 'gofre.Settings.Series.title',
  },
  subtitle: {
    id: 'gofre.Settings.Series.subtitle',
  },
  newSerie: {
    id: 'gofre.Settings.Series.newSerie',
  },
  createdSeries: {
    id: 'gofre.Settings.Series.createdSeries',
  },
  serialCode: {
    id: 'gofre.Settings.Series.serialCode',
  },
  lastNumber: {
    id: 'gofre.Settings.Series.lastNumber',
  },
  initialNumber: {
    id: 'gofre.Settings.Series.initialNumber',
  },
  targetDocument: {
    id: 'gofre.Settings.Series.targetDocument',
  },
  nextNumber: {
    id: 'gofre.Settings.Series.nextNumber',
  },
  deleteError: {
    id: 'gofre.Settings.Series.deleteError',
  },
});

export const templateColors = defineMessages({
  [`${TemplateType.DEFAULT}-primary`]: {
    id: `gofre.Settings.Theme.${TemplateType.DEFAULT}-primary`,
  },
  [`${TemplateType.SIMPLE}-primary`]: {
    id: `gofre.Settings.Theme.${TemplateType.SIMPLE}-primary`,
  },
  [`${TemplateType.SIMPLE}-secondary`]: {
    id: `gofre.Settings.Theme.${TemplateType.SIMPLE}-secondary`,
  },
  [`${TemplateType.SIMPLE}-tertiary`]: {
    id: `gofre.Settings.Theme.${TemplateType.SIMPLE}-tertiary`,
  },
  [`${TemplateType.ZEBRA}-primary`]: {
    id: `gofre.Settings.Theme.${TemplateType.ZEBRA}-primary`,
  },
  [`${TemplateType.ZEBRA}-secondary`]: {
    id: `gofre.Settings.Theme.${TemplateType.ZEBRA}-secondary`,
  },
  [`${TemplateType.ZEBRA}-tertiary`]: {
    id: `gofre.Settings.Theme.${TemplateType.ZEBRA}-tertiary`,
  },
  [`${TemplateType.CLASSIC}-primary`]: {
    id: `gofre.Settings.Theme.${TemplateType.CLASSIC}-primary`,
  },
  [`${TemplateType.CLASSIC}-secondary`]: {
    id: `gofre.Settings.Theme.${TemplateType.CLASSIC}-secondary`,
  },
});

export const paymentsMethod = defineMessages({
  addPaymentMethod: { id: 'gofre.Settings.paymentsMethod.addPaymentMethod' },
  description: { id: 'gofre.Settings.paymentsMethod.description' },
  tableTitle: { id: 'gofre.Settings.paymentsMethod.tableTitle' },
  title: { id: 'gofre.Settings.paymentsMethod.title' },
  zeroStateTitle: { id: 'gofre.Settings.paymentsMethod.zeroStateTitle' },
  tableHeadType: { id: 'gofre.Settings.paymentsMethod.tableHeadType' },
  tableHeadText: { id: 'gofre.Settings.paymentsMethod.tableHeadText' },
  tableHeadAlias: { id: 'gofre.Settings.paymentsMethod.tableHeadAlias' },
  tableHeadEdit: { id: 'gofre.Settings.paymentsMethod.tableHeadEdit' },
  tableHeadDelete: { id: 'gofre.Settings.paymentsMethod.tableHeadDelete' },
});

export const confirmationDialog = defineMessages({
  confirm: { id: 'gofre.ConfirmNavigationDialog.confirm' },
  cancel: { id: 'gofre.ConfirmNavigationDialog.cancel' },
  title: { id: 'gofre.ConfirmNavigationDialog.title' },
  question: { id: 'gofre.ConfirmNavigationDialog.question' },
});

export const templateForm = defineMessages({
  copy: { id: 'gofre.templateForm.clipboard.copy' },
});
