import { ffStrToObj } from '../helpers/featureFlags.helpers';
import { FeatureFlagsSource, Flags } from '../model';
import BaseSource from './BaseSource';

interface History {
  listen: (listen: Listen) => void;
}

type Listen = ({ search }: any) => void;

export default class ReactRouterDOMSource
  extends BaseSource
  implements FeatureFlagsSource
{
  root: Window;

  history: History;

  key: string;

  flags: Flags;

  constructor(root: Window, history: History, key = 'featureFlags') {
    super();
    this.root = root;
    this.history = history;
    this.key = key;
    this.flags = {};
  }

  init() {
    const initialSearch = this.getLocationSearch();
    this.updateSourceFlags(initialSearch);

    this.history.listen(({ search }: any) => {
      this.updateSourceFlags(search);
      this.update(this.flags);
    });
  }

  updateSourceFlags(search: string) {
    const ffStr = this.getQueryParam(search);
    if (!ffStr) return;

    this.flags = { ...this.flags, ...ffStrToObj(ffStr) };
  }

  getLocationSearch() {
    return this.root.location?.search;
  }

  getQueryParam(search: string) {
    const searchParams = new URLSearchParams(search);
    return searchParams.get(this.key);
  }

  getFlags() {
    return this.flags;
  }
}
