import { css } from 'modules/Theme/styled-components';

const expandableAnimation = () => css`
  @media (prefers-reduced-motion: no-preference) {
    transition: opacity 0.25s,
                height 0.30s ease,
                content-visibility 0.40s;
    /* stylelint-disable-next-line property-no-unknown */
    transition-behavior: allow-discrete;
  }

  @media (prefers-reduced-motion: reduce) {
    transition: none;
  }
`;

export default expandableAnimation;
