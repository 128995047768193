import { TemplateSettings, TemplateSettingsForm } from '../types';

function normalizeValues(
  formValues: TemplateSettingsForm,
  templateNamePlaceholder: string
): Omit<TemplateSettings, 'id'> {
  const { colors, templateId, logoUrl, isUsed, name, ...rest } = formValues;
  return {
    ...rest,
    name: name || templateNamePlaceholder,
    colors: colors[rest.templateType],
  };
}

export function normalizeValuesForPreview(
  formValues: TemplateSettingsForm
): Omit<TemplateSettings, 'id'> {
  const { colors, templateId, isUsed, ...rest } = formValues;
  return {
    ...rest,
    colors: colors[rest.templateType],
  };
}

export default normalizeValues;
