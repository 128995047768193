import useTheme from 'modules/Theme/hooks/useTheme';

import IconByString, { IconByStringProps } from './Icon/IconType/IconByString';

const IconStateError = (props: IconByStringProps) => {
  const { size, ...rest } = props;
  const theme = useTheme();
  return (
    <IconByString {...rest} size={size ?? 24} viewBox="0 0 13 13">
      <circle cx="6.5" cy="6.5" fill={theme.colors.error} r="6.5" />
      <path
        clipRule="evenodd"
        d="m9.328 4.379-.707-.707L6.5 5.792l-2.121-2.12-.707.707L5.792 6.5l-2.12 2.121.707.707L6.5 7.208l2.121 2.12.707-.707L7.208 6.5l2.12-2.121Z"
        fillRule="evenodd"
        fill={theme.colors.gray0}
      />
    </IconByString>
  );
};

export default IconStateError;
