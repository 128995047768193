import IconByPath, { IconByPathProps } from './Icon/IconType/IconByPath';

const IconArrow = (props: IconByPathProps) => (
  <IconByPath
    {...props}
    path="m7 8.77-.71.704L12 15.24l5.71-5.766L17 8.77l-5 5.05-5-5.05Z"
  />
);

export default IconArrow;
