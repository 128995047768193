import accesibleHide from 'modules/Theme/mixins/accessibleHidden';
import styled from 'modules/Theme/styled-components';
import Input from 'modules/Ui/Html/Input';

const RadioInput = styled(Input)`
  ${accesibleHide}

  ~  .radioButtonIcon {

    @media (hover: hover) {
      &:hover {
        cursor: pointer;
      }
    }

    .iconChecked {
      fill: ${(props) => props.theme.colors.brand500};
      opacity: 0;
    }
  }

  &:checked {
    ~  .radioButtonIcon {
      border-color: ${(props) => props.theme.colors.brand500};

      .radioButtonIcon--text {
          color: ${(props) => props.theme.colors.brand500};
      }

      .iconChecked {
        opacity: 1;
      }

      &:focus {
        border: ${(props) => `1px solid ${props.theme.colors.brand400}`};
      }
    }
  }
`;

export default RadioInput;
