import { FC, useContext, useEffect } from 'react';

import { Formik } from 'formik';

import analyticsService from 'modules/Analytics/services/analyticsService';
import useTranslations from 'modules/I18n/hooks/useTranslations';
import useNotifications from 'modules/Notifications/hooks/useNotifications';
import { Plan } from 'modules/Subscriptions/models';
import { checkCoupon } from 'modules/Subscriptions/services';
import { Fieldset, Text, Box } from 'modules/Ui';
import { IconCheck } from 'modules/Ui/Icons';
import { useBlockingMutation } from 'utils/useBlockingMutation';

import { Coupon } from '../types';
import { CampaingCouponContext } from './CampaingCouponContext';
import messages from './messages';
import PaymentFormCouponsCode from './PaymentFormCouponsCode';

interface Props {
  coupon?: Coupon;
  productId: string;
  selectedPlan?: Plan;
  setCoupon?: (result?: Coupon) => void;
}

const PaymentFormCoupons: FC<Props> = ({
  productId,
  coupon,
  selectedPlan,
  setCoupon,
}) => {
  const { t } = useTranslations();
  const notifications = useNotifications();
  const couponContext = useContext(CampaingCouponContext);

  const { mutateAsync, isLoading } = useBlockingMutation(checkCoupon, {
    onError: () => {
      notifications.error(messages.errorValidateCoupon.id);
    },
  });
  const handleValidation = async (values: any) => {
    const params = { code: values.code.trim(), productId };
    analyticsService.couponEntered(params.code);
    const data = await mutateAsync(params);
    if (setCoupon && data.valid) {
      analyticsService.couponApplied(data);
      setCoupon(data);
    }
    if (!data.valid) {
      analyticsService.couponDenied(params.code, messages.invalidCoupon.id);
      return { code: t(messages.invalidCoupon) };
    }
    return {};
  };
  const initialValues = { code: coupon?.promotionCode };

  useEffect(() => {
    const selectedPlanCampaign = selectedPlan?.campaign;
    const selectedPlanCoupon = selectedPlan?.coupon;
    if (
      selectedPlanCampaign &&
      selectedPlanCoupon &&
      !couponContext?.deletedCoupons.includes(selectedPlanCoupon) &&
      !coupon
    ) {
      handleValidation({ code: selectedPlanCoupon });
    }
  }, [selectedPlan]);

  return (
    <Formik
      onSubmit={(_, actions) => {
        actions.setSubmitting(false);
      }}
      {...{ initialValues }}
      validateOnBlur={false}
      validateOnChange={false}
      validate={handleValidation}
    >
      {(formik) => (
        <form
          data-testid="validate-coupon-form"
          id="check-coupon-form"
          noValidate
          onSubmit={formik.handleSubmit}
        >
          <Fieldset
            marginBottom={{ sm: '20px' }}
            alignItems={{ _: 'center', sm: 'initial' }}
            display={{ _: 'grid', sm: 'flex' }}
            gridTemplateColumns={{ _: '1fr auto', sm: 'initial' }}
            hiddenLegend
            id="check-coupon"
            legend={t(messages.promoCodeLabel)}
          >
            <PaymentFormCouponsCode coupon={coupon} isLoading={isLoading} />
            {!!coupon && (
              <Text
                color="confirmation"
                textSize="xs"
                margin={{
                  _: '0 0 24px',
                  sm: '28px 0 0 30px',
                }}
              >
                <Box display="flex" alignItems="center" tag="span">
                  <IconCheck /> <span>{t(messages.promoCodeValidated)}</span>
                </Box>
              </Text>
            )}
          </Fieldset>
        </form>
      )}
    </Formik>
  );
};

export default PaymentFormCoupons;
