import { Hidden } from 'react-grid-system';

import { UserPermissions } from 'modules/Auth/constants';
import useHasUserScope from 'modules/Auth/hooks/checkUsersPermissions';
import useTranslations from 'modules/I18n/hooks/useTranslations';
import { useOpenModal } from 'modules/Modals';
import { ModalTypes } from 'modules/Modals/constants';
import { PaymentTypeEnum } from 'modules/Payments/models';
import { Button } from 'modules/Ui';
import DataTableZeroState from 'modules/Ui/DataTable/DataTableZeroState';

import { zeroStatePayments } from '../../messages';

export const PaymentZeroState = () => {
  const { t } = useTranslations();
  const { hasUserScope } = useHasUserScope();
  const canCreate = hasUserScope(UserPermissions.SUBSCRIPTION_EDITOR);
  const openModal = useOpenModal();

  return (
    <DataTableZeroState
      img="/assets/zero-state-payments.svg"
      imgMarginBottom="8px"
      subtitle={t(zeroStatePayments.subtitle)}
      title={t(zeroStatePayments.title)}
    >
      <Hidden xs>
        {canCreate && (
          <Button
            data-testid="payment-create"
            marginTop="16px"
            variant="secondary"
            onClick={() =>
              openModal({
                type: ModalTypes.CREATE_PAYMENT,
                paymentType: PaymentTypeEnum.INCOME,
                onSuccess: () => {},
              })
            }
          >
            {t(zeroStatePayments.linkAdd)}
          </Button>
        )}
      </Hidden>
    </DataTableZeroState>
  );
};
