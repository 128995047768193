import theme from 'modules/Theme';

const styles = {
  '&multiLine': {
    control: {
      minHeight: '107px',
    },
    input: {
      minHeight: '107px',
    },
  },

  '&singleLine': {
    input: {
      maxWidth: '100%',
      minHeight: '44px',
    },
  },

  suggestions: {
    list: {
      backgroundColor: theme.colors.gray0,
      border: `1px solid ${theme.colors.primary200}`,
      borderRadius: '8px',
      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.05)',
      padding: '12px 0',
    },
    item: {
      borderBottom: `1px solid ${theme.colors.primary200}`,
      color: theme.colors.gray600,
      fontSize: theme.fontSizes[14],
      lineHeight: theme.lineHeights[16],
      padding: '8px 16px',
      '&focused': {
        backgroundColor: theme.colors.auxiliary100,
        color: theme.colors.gray800,
      },
      '&hover': {
        backgroundColor: theme.colors.auxiliary100,
        color: theme.colors.gray800,
      },
    },
  },
};

export default styles;
