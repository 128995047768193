import useTheme from 'modules/Theme/hooks/useTheme';

import IconByString, { IconByStringProps } from './Icon/IconType/IconByString';

const IconAddTestInvoice = (props: IconByStringProps) => {
  const { fillColor, innerPathColor, ...rest } = props;
  const { colors } = useTheme();
  return (
    <IconByString {...rest}>
      <path
        clipRule="evenodd"
        d="M18 23V1H2v22h16ZM3 2h14v20H3V2Z"
        fill="currentColor"
        fillRule="evenodd"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 8a3 3 0 1 0 6 0 3 3 0 0 0-6 0Zm3-4a4 4 0 1 0 0 8 4 4 0 0 0 0-8Z"
        fill="currentColor"
      />
      <path d="M6 15h8v1H6v-1ZM6 19h8v1H6v-1Z" fill="currentColor" />
      <circle
        cx="18"
        cy="18"
        r="6"
        fill={innerPathColor ? `${innerPathColor}` : colors.warning}
      />
      <path
        d="M16.615 18a1.385 1.385 0 1 1 2.77 0 1.385 1.385 0 0 1-2.77 0Z"
        fill={colors.gray0}
      />
    </IconByString>
  );
};

export default IconAddTestInvoice;
