import React from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';

import ActionBlocked from 'modules/Mobile/ActionBlocked';
import SubscriptionNotActiveLayout from 'modules/Mobile/SubscriptionNonActiveLayout';
import isNativeApp from 'utils/native/isNativeApp';

import { ROUTES } from '../routesMap';

interface Props {
  isLoading?: boolean;
  loadingMessage?: string;
  isBookkeeperLoggedInClient?: boolean;
}

const SubscriptionNotActiveRoutes: React.FC<Props> = ({
  isLoading,
  loadingMessage,
}) => {
  const Component = !isNativeApp()
    ? SubscriptionNotActiveLayout
    : ActionBlocked;
  return (
    <Switch>
      <Route
        path={ROUTES.SUBSCRIPTIONS_CANCELLED}
        component={() => <Component {...{ isLoading, loadingMessage }} />}
      />
      <Route
        path={ROUTES.SUBSCRIPTIONS_CANCELLED_PLANS}
        component={() => (
          <SubscriptionNotActiveLayout {...{ isLoading, loadingMessage }} />
        )}
      />
      <Redirect to={ROUTES.SUBSCRIPTIONS_CANCELLED} />
    </Switch>
  );
};

export default SubscriptionNotActiveRoutes;
