import { Box, DocumentTrackingRow } from 'modules/Ui';

import { DocumentTrackingData } from '../../../models/documentTracking';
import { useDocumentTracking } from '../../hooks/useDocumentTracking';

interface Props {
  items: DocumentTrackingData[];
}

const DocumentTrackingList: React.FC<Props> = ({ items }) => {
  const { voidActionIndex } = useDocumentTracking(items);
  return (
    <Box
      flexGrow="1"
      height="100%"
      marginBottom={{ sm: '28px' }}
      minHeight="0"
      overflow="auto"
      tag="ol"
      data-testid="document-tracking-list"
    >
      {items.map((item: DocumentTrackingData, index: number) => (
        <DocumentTrackingRow
          key={item.id}
          {...item}
          isMobile={true}
          isFirstItem={index === 0}
          isLastItem={index + 1 === items.length}
          renderVoidTag={index < voidActionIndex}
        />
      ))}
    </Box>
  );
};

export default DocumentTrackingList;
