import { ThemeInterface } from 'modules/Theme';
import useTheme from 'modules/Theme/hooks/useTheme';

import IconByString from './Icon/IconType/IconByString';

type Props = {
  showBadge: boolean;
  color: keyof ThemeInterface['colors'];
};

const IconMenu: React.FC<Props> = ({ showBadge, color }) => {
  const theme = useTheme();

  return (
    <IconByString>
      <path
        d="M2 5h20v1.2H2V5Zm0 6h16v1.2H2V11Zm12 6H2v1.2h12V17Z"
        fill={theme.colors[color]}
      />
      {showBadge && (
        <circle cx="19" cy="18" r="3" fill={theme.colors.accent500} />
      )}
    </IconByString>
  );
};
export default IconMenu;
