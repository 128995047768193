import {
  DetailedHTMLProps as Props,
  AnchorHTMLAttributes as Attributes,
} from 'react';
import { ReactNode } from 'react-markdown';

import useTheme from 'modules/Theme/hooks/useTheme';
import { IconInfo } from 'modules/Ui/Icons';

import Tooltip from '../../Tooltip';
import { FieldProps } from '../Field/Field';
import Check from './Check';
import CheckboxInput from './CheckboxInput';
import CheckboxLabel from './CheckboxLabel';
import LabelText from './LabelText';
import Wrapper from './Wrapper';

interface CheckboxProps extends FieldProps {
  checked?: boolean;
  onChange?: (value: any) => void;
  hasEllipsis?: boolean;
  size?: number;
  tooltip?: string | ReactNode;
}

const Checkbox = (
  props: CheckboxProps & Props<Attributes<HTMLInputElement>, HTMLInputElement>
) => {
  const {
    checked,
    disabled,
    hasEllipsis,
    id,
    label,
    labelHidden,
    name,
    onChange,
    required,
    size = 20,
    value,
    tooltip,
    ...rest
  } = props;
  const theme = useTheme();
  return (
    <CheckboxLabel
      htmlFor={`checkbox-${id}`}
      {...{ disabled, labelHidden, ...rest }}
    >
      <CheckboxInput
        type="checkbox"
        id={`checkbox-${id}`}
        {...{
          checked,
          disabled,
          required,
          name,
          value,
          onChange: (onChangeValue: any) => onChange?.(onChangeValue),
          readOnly: checked !== undefined && !onChange,
        }}
      />
      <Wrapper display="flex" tag="span" width="100%">
        <Check
          {...{ disabled, checked }}
          alignItems="center"
          backgroundColor={`var(--check-bg-color, ${theme.colors.gray0})`}
          borderColor={`var(--check-border-color, ${theme.colors.gray300})`}
          borderRadius="2px"
          borderStyle="solid"
          borderWidth="1px"
          boxSizing="border-box"
          display="flex"
          height={size}
          justifyContent="center"
          marginTop="4px"
          minWidth={size}
          tag="span"
          width={size}
        />
        <LabelText
          alignItems={tooltip && 'center'}
          disabled={disabled}
          display={tooltip && 'flex'}
          hasEllipsis={hasEllipsis}
          id={`checkbox-label-${id}`}
          labelHidden={labelHidden}
          tag="span"
        >
          {label}
          {required && <> *</>}
          {tooltip && (
            <Tooltip
              id={`${id}-tooltip}`}
              marginLeft="4px"
              place="right"
              tooltipButton={<IconInfo color="brand500" size={20} />}
              tooltipContent={tooltip}
            />
          )}
        </LabelText>
      </Wrapper>
    </CheckboxLabel>
  );
};

export default Checkbox;
