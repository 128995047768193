import HtmlComponent from './HtmlComponent';
import Props from './Props';

export const validProps = [
  'download',
  'href',
  'hrefLang',
  'rel',
  'referrerPolicy',
  'target',
  'type',
];

const A = (props: Props) => (
  <HtmlComponent tag="a" {...{ validProps }} {...props} />
);

export default A;
