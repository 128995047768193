import { FC } from 'react';
import { useSelector } from 'react-redux';

import { selectIsUltraWide } from 'modules/App/store/selectors';
import useTranslations from 'modules/I18n/hooks/useTranslations';
import useTheme from 'modules/Theme/hooks/useTheme';
import styled from 'modules/Theme/styled-components';

import Box from '../Box';
import Button from '../Form/Button';
import carrouselMessages from './messages';
import { CarouselProps } from './model';

interface CarouselDotsProps extends CarouselProps {}

const StyledButton = styled(Button)`
  @media (hover: hover) {
    &:hover {
      background-color: ${({ theme }) => theme.colors.brand500};
      cursor: pointer;
    }
  }
`;

export const CarouselDots: FC<CarouselDotsProps> = ({
  pages,
  selectedPage,
  setSelectedPage,
}) => {
  const theme = useTheme();
  const { t } = useTranslations();
  const isUltraWide = useSelector(selectIsUltraWide);
  const numberOfContacts = isUltraWide ? 4 : 3;
  return (
    <>
      {pages > 1 && (
        <Box
          display="flex"
          gap="10px"
          justifyContent="center"
          marginBottom="20px"
          tag="ul"
        >
          {Array.from({ length: pages }, (_, i) => i + 1).map((page) => (
            <Box key={page} tag="li">
              <StyledButton
                aria-current={selectedPage === page}
                accessibleText={`${page}/${pages}  ${t(
                  carrouselMessages.dotContact
                )} ${numberOfContacts * page - numberOfContacts + 1}  ${t(
                  carrouselMessages.dotTo
                )} ${numberOfContacts * page}, ${
                  selectedPage === page
                    ? `${t(carrouselMessages.dotSelected)}`
                    : `${t(carrouselMessages.dotNotSelected)}`
                }`}
                borderRadius="50%"
                backgroundColor={
                  selectedPage === page
                    ? `${theme.colors.brand500}`
                    : `${theme.colors.primary200}`
                }
                display="block"
                height="8px"
                onClick={() => setSelectedPage(page)}
                width="8px"
              />
            </Box>
          ))}
        </Box>
      )}
    </>
  );
};
