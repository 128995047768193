import { createElement } from 'react';

import { PaymentMethodFormValues } from 'modules/Documents/models/form';
import useTranslations from 'modules/I18n/hooks/useTranslations';
import {
  BizumType,
  OtherPaymentMethodType,
  PaymentMethodType,
  TransferType,
  CreatedPaymentMethod,
} from 'modules/PaymentMethods/models/paymentMethods';
import { Text } from 'modules/Ui';

import messages from './messages';

const usePaymentMethod = () => {
  const { t } = useTranslations();

  const bold = (chunk: unknown[]) =>
    createElement(Text, {
      color: 'gray800',
      fontWeight: '400',
      tag: 'strong',
      fontSize: 14,
      children: chunk,
    });

  function getDescription(
    paymentMethod: PaymentMethodFormValues | CreatedPaymentMethod,
    isMobile?: boolean
  ) {
    switch (paymentMethod.type) {
      case PaymentMethodType.TRANSFER: {
        const { swiftCode, IBAN } =
          paymentMethod.data && (paymentMethod.data as TransferType);
        let textDescription;
        if (isMobile) {
          textDescription = `${t(messages.bank_account_iban)}: ${IBAN}`;
        } else {
          textDescription = t(messages.transfer, {
            showSWIFT: !!swiftCode,
            SWIFT: swiftCode,
            IBAN,
            bold,
          });
        }

        return textDescription;
      }
      case PaymentMethodType.BIZUM: {
        let textBizum;
        if (isMobile) {
          textBizum = `${t(messages.phoneText)}: ${
            (paymentMethod.data as BizumType).phoneNumber
          }`;
        } else {
          textBizum = t(messages.bizum, {
            phoneNumber: (paymentMethod.data as BizumType).phoneNumber,
            bold,
          });
        }
        return textBizum;
      }
      case PaymentMethodType.CASH_ON_DELIVERY:
        return t(messages.cashOnDelivery);
      case PaymentMethodType.DIRECT_DEBIT:
        return t(messages.directDebit);
      case PaymentMethodType.OTHER:
        return (paymentMethod.data as OtherPaymentMethodType).description;
      default:
        return t(messages.cash);
    }
  }

  function getTypeText(
    paymentMethod: PaymentMethodFormValues | CreatedPaymentMethod
  ) {
    switch (paymentMethod.type) {
      case PaymentMethodType.TRANSFER:
        return t(messages.transferType);
      case PaymentMethodType.BIZUM:
        return t(messages.bizumType);
      case PaymentMethodType.CASH_ON_DELIVERY:
        return t(messages.cashOnDeliveryType);
      case PaymentMethodType.DIRECT_DEBIT:
        return t(messages.directDebitType);
      case PaymentMethodType.OTHER:
        return t(messages.other);
      default:
        return t(messages.cashType);
    }
  }

  function getAliasText(
    paymentMethod: PaymentMethodFormValues | CreatedPaymentMethod
  ) {
    switch (paymentMethod.type) {
      case PaymentMethodType.TRANSFER: {
        const { alias } =
          paymentMethod.data && (paymentMethod.data as TransferType);
        return alias;
      }
      default:
        return '';
    }
  }

  function checkButtonEdit(
    paymentMethod: PaymentMethodFormValues | CreatedPaymentMethod
  ) {
    return [
      PaymentMethodType.BIZUM,
      PaymentMethodType.TRANSFER,
      PaymentMethodType.OTHER,
    ].includes(paymentMethod.type);
  }

  return {
    getAliasText,
    getDescription,
    getTypeText,
    checkButtonEdit,
  };
};

export default usePaymentMethod;
