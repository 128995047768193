import { getLoadedConfig } from 'modules/App/config';
import featureFlags from 'modules/FeatureFlags';

import { ImageSizesEnum } from '../models';

export function buildImageUrl(productId?: string, businessId?: string) {
  if (!productId || !businessId) return '';

  const apiDomainFF = (
    (featureFlags.getFlag('apiDomain') as string) || ''
  ).trim();

  const apiDomain =
    getLoadedConfig().request?.apiDomain ?? getLoadedConfig().apiDomain;

  const { apiProductsService } = getLoadedConfig();
  return `${
    apiDomainFF || apiDomain
  }${apiProductsService}/${productId}/${businessId}/image`;
}

export function getProductImages(productId?: string, businessId?: string) {
  const mainUrl = buildImageUrl(productId, businessId);

  return {
    small: `${mainUrl}?size=${ImageSizesEnum.SMALL}`,
    medium: `${mainUrl}?size=${ImageSizesEnum.MEDIUM}`,
  };
}
