import { FC } from 'react';

import useTranslations from 'modules/I18n/hooks/useTranslations';
import useTheme from 'modules/Theme/hooks/useTheme';
import styled from 'modules/Theme/styled-components';
import { Box, Text, Button } from 'modules/Ui';
import { IconCheck } from 'modules/Ui/Icons';

export interface ItemMobile {
  id?: string;
  column1?: string;
  column2?: string | JSX.Element;
  column3?: number;
}

interface Props extends ItemMobile {
  checked: boolean;
  onClickRow(): void;
}

const StyledButton = styled(Button)`
  &:focus-within {
    box-shadow: 0 3px 8px 0 ${(props) => props.theme.colors.shadow};
  }
  @media (hover: hover) {
    &:hover {
      box-shadow: 0 3px 8px 0 ${(props) => props.theme.colors.shadow};
      cursor: pointer;
    }
  }
  &:last-child{
    border: none
  }
`;

const ListMobileItem: FC<Props> = ({
  checked,
  column1,
  column2,
  column3,
  onClickRow,
}) => {
  const { formatCurrency } = useTranslations();
  const showPrice = column3 && !checked;
  const theme = useTheme();
  return (
    <StyledButton
      aria-label={column1}
      display="flex"
      flexDirection="column"
      borderBottom={`1px solid ${theme.colors.gray200}`}
      aria-checked={checked}
      onClick={(event) => {
        event.stopPropagation();
        onClickRow();
      }}
      width="100%"
      padding="12px 6px 8px 0"
    >
      <Box
        {...{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <Text
          color={checked ? 'brand500' : 'gray800'}
          fontSize={14}
          hasEllipsis
          lineHeight={20}
          textAlign="left"
        >
          {column1}
        </Text>
        {showPrice && (
          <Text
            color="gray800"
            fontWeight="600"
            lineHeight={20}
            marginLeft="16px"
          >
            {formatCurrency(column3)}
          </Text>
        )}

        {checked && <IconCheck color="brand500" />}
      </Box>

      <Text fontSize={12} lineHeight={20} paddingTop="6px">
        {column2}
      </Text>
    </StyledButton>
  );
};

export default ListMobileItem;
