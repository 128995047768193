import { PaymentMethodType } from 'modules/PaymentMethods/models/paymentMethods';

import messages from './messages';
import {
  BizumSubForm,
  OtherSubForm,
  TransferSubForm,
  EmptySubForm,
  DirectDebitSubForm,
} from './SubForms';
import { PaymentMethodItem } from './types';

export const paymentMethodItems: PaymentMethodItem[] = [
  {
    type: PaymentMethodType.TRANSFER,
    label: messages.transfer,
    subform: TransferSubForm,
  },
  {
    type: PaymentMethodType.DIRECT_DEBIT,
    label: messages.direct_debit,
    subform: DirectDebitSubForm,
  },
  {
    type: PaymentMethodType.CASH,
    label: messages.cash,
    subform: EmptySubForm,
  },
  {
    type: PaymentMethodType.CASH_ON_DELIVERY,
    label: messages.cash_on_delivery,
    subform: EmptySubForm,
  },

  {
    type: PaymentMethodType.BIZUM,
    label: messages.bizum,
    subform: BizumSubForm,
  },
  {
    type: PaymentMethodType.OTHER,
    label: messages.other,
    subform: OtherSubForm,
  },
];

export function getSubForm(
  items: PaymentMethodItem[],
  selectedPaymentType?: PaymentMethodType
) {
  if (!selectedPaymentType) {
    return null;
  }
  const item = items.find(({ type }) => type === selectedPaymentType);
  return item && item.subform;
}
