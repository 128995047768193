import IconByPath, { IconByPathProps } from './Icon/IconType/IconByPath';

const IconSecurity = (props: IconByPathProps) => (
  <IconByPath
    {...props}
    fillRule="evenodd"
    path="M12 2a4 4 0 0 0-4 4v3h8V6a4 4 0 0 0-4-4ZM7 6v3H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h16a1 1 0 0 0 1-1V10a1 1 0 0 0-1-1h-3V6A5 5 0 0 0 7 6Zm-3 4h16v12H4V10Zm8 6a1 1 0 1 1 0-2 1 1 0 0 1 0 2Zm-2-1a2 2 0 1 1 4 0 2 2 0 0 1-4 0Z"
  />
);

export default IconSecurity;
