import { css } from 'modules/Theme/styled-components';

interface SlidingCenterUnderlineProps {
  $active?: boolean;
}

const hoverEffect = css`
  opacity: 1;
  transform: scaleX(1);
`;

const slidingCenterUnderline = (color: string, height: number = 2) =>
  css<SlidingCenterUnderlineProps>`
    position: relative;

    &:after {
      background-color: ${color};
      bottom: 0;
      content: "";
      display: block;
      height: ${height}px;
      left: 0;
      opacity: ${({ $active }) => ($active ? '1' : '0')};
      position: absolute;
      transform: scale(${({ $active }) => ($active ? '1' : '0')});
      transition: all 0.3s ease-in-out;
      will-change: transform;
      width: 100%;
    }

    @media (hover: hover) {
      &:hover {
        &:after {
          ${hoverEffect}
        }
      }
    }

    &:focus {
      &:after {
        ${hoverEffect}
      }
    }
  `;

export default slidingCenterUnderline;
