import { CustomExpensesBulkActionsProps } from 'modules/Documents/List/hooks/expenses/useExpensesBulkActions';
import { CustomDocumentBulkActionsProps } from 'modules/Documents/List/hooks/useDocumentBulkActions';
import { DocumentTable } from 'modules/Documents/models/document';
import useTranslations from 'modules/I18n/hooks/useTranslations';
import { Remittance } from 'modules/Remittances/types';
import { DataTableAction } from 'modules/Ui/DataTable/DataTable.models';
import { IconRemittances } from 'modules/Ui/Icons';

import { remittanceDocumentListMessage } from '../messages';
import { useDocumentsDuplicatedChecker } from './useDocumentsDuplicatedChecker';

type CustomBulkAction = (
  props: CustomDocumentBulkActionsProps | CustomExpensesBulkActionsProps
) => DataTableAction<DocumentTable>[];

interface RemittanceBulkActionsOptions {
  remittance?: Remittance;
  isDirectDebit: boolean;
  extraQuery: string;
}

export const useRemittanceBulkActions = ({
  extraQuery,
  isDirectDebit,
  remittance,
}: RemittanceBulkActionsOptions): CustomBulkAction => {
  const { t } = useTranslations();
  const documentDuplicatedchecker = useDocumentsDuplicatedChecker({
    remittance,
    isDirectDebit,
    extraQuery,
  });
  return (props) => {
    const { filters, status } = props;

    return [
      {
        name: t(remittanceDocumentListMessage.bulkActionLabel),
        icon: <IconRemittances />,
        shouldActionRender: true,
        onClick: async (
          selecteds: DocumentTable[],
          inverted: boolean,
          _resetSelection: () => void
        ) => {
          await documentDuplicatedchecker({
            selecteds,
            inverted,
            filters,
            status,
          });
        },
      },
    ];
  };
};
