import { ReactNode } from 'react';

import styled from 'styled-components';
import { ColorProps, space, SpaceProps, color } from 'styled-system';

import { Box } from 'modules/Ui';

export interface DataBoxProps extends ColorProps, SpaceProps {
  children?: ReactNode;
}

const Wrapper = styled(Box)`
  ${space}
  ${color}
`;

const DataBox = (props: DataBoxProps) => {
  const { children, ...rest } = props;
  return (
    <Wrapper fadeIn boxSizing="border-box" tag="article" {...rest}>
      {children}
    </Wrapper>
  );
};

DataBox.defaultProps = {
  /* eslint-disable react/default-props-match-prop-types */
  backgroundColor: 'auxiliary100',
  marginBottom: { _: '24px', sm: '16px' },
  padding: '12px 16px',
  /* eslint-enable react/default-props-match-prop-types */
};
export default DataBox;
