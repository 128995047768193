import useTranslations from 'modules/I18n/hooks/useTranslations';
import { Line } from 'modules/Subscriptions/services/changePlan/changePlanPreview';

import { resume as texts } from '../messages';
import PaymentResumeListItem from './PaymentResumeListIem';

interface Props {
  lines: Line[];
}

const PaymentResumeListItemProration: React.FC<Props> = ({ lines }) => {
  const { t, formatCurrency } = useTranslations();
  const [prorationReceivable, prorationPayable] = lines;
  return (
    <>
      {(!!prorationReceivable.amount || prorationReceivable.amount === 0) && (
        <PaymentResumeListItem
          borderBottomColor="primary200"
          concept={t(texts.prorationReceivable)}
          currency={`${formatCurrency(prorationReceivable.amount)}`}
        />
      )}
      {prorationPayable && (
        <PaymentResumeListItem
          borderBottomColor="primary200"
          concept={t(texts.prorationPayable)}
          currency={formatCurrency(prorationPayable.amount)}
        />
      )}
    </>
  );
};

export default PaymentResumeListItemProration;
