import IconByPath, { IconByPathProps } from './Icon/IconType/IconByPath';

const IconNotificationSimple = (props: IconByPathProps) => (
  <IconByPath
    {...props}
    fillRule="evenodd"
    path="M10 1h4v1h-4V1Zm8 13.878V10a6 6 0 0 0-12 0v4.878l-.667.236A2.001 2.001 0 0 0 4 17v1h16v-1c0-.87-.555-1.611-1.333-1.886L18 14.877ZM5 14.17A3.001 3.001 0 0 0 3 17v2h5a4 4 0 0 0 8 0h5v-2a3.001 3.001 0 0 0-2-2.83V10a7 7 0 1 0-14 0v4.17ZM15 19H9a3 3 0 1 0 6 0Z"
  />
);

export default IconNotificationSimple;
