import { FC } from 'react';

import CreatePlanWarningDialog from './CreatePlanWarningDialog';
import { CreatePlanWarningProps } from './CreatePlanWarningProps';

const CreatePlanWarningModal: FC<CreatePlanWarningProps> = ({
  warningScopes,
  onConfirm,
  onClose,
}) => {
  return (
    <CreatePlanWarningDialog
      {...{
        onClose,
        warningScopes,
        onConfirm,
      }}
    />
  );
};

export default CreatePlanWarningModal;
