import { useQuery } from 'react-query';

import { CONTACTS_QUERY } from 'modules/App/queries/queries';
import CacheTimes from 'utils/query/cacheTimes';

import fetchContacts from '../List/services/fetchContacts';
import { Contact } from '../models';

const useBusinessHasContacts = () => {
  const { data } = useQuery<{
    items: Contact[];
    count: number;
  }>(
    [CONTACTS_QUERY, 'has-created'],
    () => {
      return fetchContacts({
        page: 0,
        pageSize: 0,
        fields: ['id'],
      });
    },
    {
      staleTime: CacheTimes.FIFTEEN_MINUTES,
      refetchOnMount: false,
    }
  );
  return { hasCreatedContact: !!data?.count };
};

export default useBusinessHasContacts;
