import { defineMessages } from 'react-intl';

export default defineMessages({
  close: { id: 'gofre.Ui.Notification.close' },
  new: { id: 'gofre.Ui.ComercialNotification.new' },
  news: { id: 'gofre.Ui.ComercialNotification.news' },
  campaing: { id: 'gofre.Ui.ComercialNotification.campaing' },
  promo: { id: 'gofre.Ui.ComercialNotification.promo' },
  seeMore: { id: 'gofre.Ui.ComercialNotification.more' },
});
