import { FC, ReactNode } from 'react';

import useTranslations from 'modules/I18n/hooks/useTranslations';
import { Box, CheckFormikField } from 'modules/Ui';

import messages from '../messages';

interface Props {
  children: ReactNode;
  isEditing: boolean;
}

const SubFormBox: FC<Props> = ({ children, isEditing = false }) => {
  const { t } = useTranslations();

  return (
    <Box
      backgroundColor="auxiliary100"
      boxSizing="border-box"
      fadeIn
      id="fieldset-payment-methods"
      margin={{ _: '16px 0', sm: '16px 0' }}
      padding={{ _: '20px 16px 32px', sm: '20px 16px 21px' }}
    >
      {children}
      {!isEditing && (
        <CheckFormikField
          id="futureDocuments"
          label={t(messages.futureDocuments)}
          data-testid="futureDocuments-check"
        />
      )}
    </Box>
  );
};

export default SubFormBox;
