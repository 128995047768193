import IconByPath, { IconByPathProps } from './Icon/IconType/IconByPath';

const IconCalendar = (props: IconByPathProps) => (
  <IconByPath
    {...props}
    clipRule="evenodd"
    fillRule="evenodd"
    path="M7 1H6v2H4a2 2 0 0 0-2 2v15a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2h-2V1h-1v2H7V1Zm11 5h-1V4H7v2H6V4H4a1 1 0 0 0-1 1v3h18V5a1 1 0 0 0-1-1h-2v2Zm3 3H3v11a1 1 0 0 0 1 1h16a1 1 0 0 0 1-1V9Z"
  />
);

export default IconCalendar;
