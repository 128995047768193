import { useSelector } from 'react-redux';

import useTranslations from 'modules/I18n/hooks/useTranslations';
import {
  selectIsMySubsciptionActive,
  selectIsMySubsciptionTrial,
} from 'modules/Subscriptions/detail/selectors';
import { Text } from 'modules/Ui';

import messages, { subscriptionCancelled } from '../../../messages';

interface Props {
  onChangeBusinessClick(): void;
}

const PlansHeading: React.FC<Props> = () => {
  const { t } = useTranslations();
  const isMySubsciptionTrial = useSelector(selectIsMySubsciptionTrial);
  const isMySubsciptionActive = useSelector(selectIsMySubsciptionActive);
  let title = t(messages.choosePlanTitle);
  if (!isMySubsciptionActive) {
    title = isMySubsciptionTrial
      ? t(messages.trialEndTitle)
      : t(subscriptionCancelled.title);
  }

  return (
    <Text
      color="brand500"
      fontFamily="LyonMedium"
      fontSize={{ _: '36px', sm: '48px', md: '56px' }}
      fontWeight="normal"
      lineHeight={{ _: '38px', sm: '48px', md: '70px' }}
      marginBottom={{ _: '16px', sm: '35px' }}
      paddingTop={{ _: '8px', sm: '0' }}
      tag="h2"
    >
      {title}
    </Text>
  );
};

export default PlansHeading;
