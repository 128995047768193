import theme from 'modules/Theme';
import { css } from 'modules/Theme/styled-components';
import { BoxProps } from 'modules/Ui/Box/Box';

export interface InputWrapperStyleProps extends BoxProps {
  disabled?: boolean;
  error?: boolean;
  hasIcon?: boolean;
  iconColor?: string;
}

const disabledStyles = css<InputWrapperStyleProps>`
  --input-background-color: ${(props) => props.theme.colors.auxiliary100A30};
  --input-color: ${(props) => props.theme.colors.primary300};

  ${({ hasIcon }) =>
    hasIcon && `--input-icon-color: ${theme.colors.primary300};`};

  @media (hover: hover) {
    &:hover {
      --input-border-color: ${(props) => props.theme.colors.primary200};
      cursor: text;
      svg {
        cursor: text;
      }
    }
  }
`;

const errorStyles = css`
  --input-border-color: ${(props) => props.theme.colors.error};
`;

const inputWrapperStyles = css<InputWrapperStyleProps>`
  background-color: var(--input-background-color, ${(props) =>
    props.theme.colors.gray0});
  border: 1px solid var(--input-border-color, ${(props) =>
    props.theme.colors.primary200});
  border-radius: 2px;
  box-sizing: border-box;
  color: var(--input-color, ${(props) => props.theme.colors.gray800});
  transition: border 0.2s ease-in;

  @media (hover: hover) {
    &:hover {
      will-change: transition;
      svg {
        cursor: pointer;
      }
    }
  }

  &:focus-within {
    --input-border-color: ${(props) =>
      props.iconColor ?? props.theme.colors.brand500};
    svg {
      --input-icon-color: ${(props) =>
        props.iconColor ?? props.theme.colors.brand500};
    }
  }

  &:hover {
    --input-border-color: ${(props) =>
      props.iconColor ?? props.theme.colors.brand500};
  }

  input {
    cursor: text;
    &::placeholder {
      color: ${(props) => props.theme.colors.gray400};
    }
  }

  button {
    background-color: transparent;
    @media (hover: hover) {
      &:hover {
        cursor: pointer;
      }
    }
  }

  ${({ hasIcon, iconColor }) =>
    hasIcon &&
    ` svg {
        fill: var(--input-icon-color, ${iconColor ?? theme.colors.brand500});
      }
    `};

  ${({ error }) => error && errorStyles};
  ${({ disabled }) => disabled && disabledStyles}
`;

export default inputWrapperStyles;
