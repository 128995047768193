import { getNaturalPersonType } from 'modules/DefaultValues/helpers/naturalPerson';
import { AllDocumentDefaultValues } from 'modules/DefaultValues/models/all-document-default-values';

import { DocumentSettings } from '../models';

export const DEFAULT_RETENTION = 15;

export const mapToDocumentSettings = (
  defaultValues?: AllDocumentDefaultValues
): DocumentSettings => {
  if (!defaultValues) return {};

  const invoice = defaultValues['invoice-business'];
  const quote = defaultValues['quote-business'];
  const proforma = defaultValues['proforma-business'];
  const receipt = defaultValues['receipt-business'];
  const deliveryNote = defaultValues['delivery-note-business'];
  const neverSaved = !invoice;

  return {
    showDeliveryNoteReference: deliveryNote?.showReference ?? true,
    showReference: invoice?.showReference ?? true,
    showQuoteReference: quote?.showReference ?? true,
    showProformaReference: proforma?.showReference ?? true,
    hasSalesEqualizationTax: neverSaved
      ? false
      : invoice?.hasSalesEqualizationTax,
    hasRetentions:
      invoice?.retentionPercentage == null
        ? false
        : !!invoice?.retentionPercentage,
    showProductReference: invoice?.showProductReference ?? false,
    retentionPercentage: invoice?.retentionPercentage ?? DEFAULT_RETENTION,
    showInvoiceComments: invoice?.showComments ?? true,
    showQuoteComments: quote?.showComments ?? true,
    showProformaComments: proforma?.showComments ?? true,
    showDeliveryNoteComments: deliveryNote?.showComments ?? true,
    invoiceComments: invoice?.comments || '',
    quoteComments: quote?.comments || '',
    proformaComments: proforma?.comments || '',
    deliveryNoteComments: deliveryNote?.comments || '',
    taxRegime: invoice?.taxRegime,
    receiptTaxRegime: receipt?.taxRegime,
    operationDescription: invoice?.operationDescription,
    hasCustomIRPF: invoice?.hasCustomIRPF,
    chargingCriterionIRPF: invoice?.chargingCriterionIRPF,
    epigraphIAE: invoice?.epigraphIAE,
    lastEpigraphsIAE: invoice?.lastEpigraphsIAE,
    naturalPersonType: getNaturalPersonType(invoice?.naturalPerson),
    naturalPerson: invoice?.naturalPerson,
    showLinesWithoutTaxesInDeliveryNotes: deliveryNote?.showLinesWithoutTaxes,
    showLinesWithoutTaxesInInvoice: invoice?.showLinesWithoutTaxes,
    showLinesWithoutTaxesInQuotes: quote?.showLinesWithoutTaxes,
    showLinesWithoutTaxesInProformas: proforma?.showLinesWithoutTaxes,
  };
};
