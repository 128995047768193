import { getConfig } from 'modules/App/config';
import {
  CONTACTS_QUERY,
  DASHBOARD_QUERY,
  DOCUMENTS_QUERY,
  DOCUMENT_TRACKING_QUERY,
  PAYMENTS_QUERY,
} from 'modules/App/queries/queries';
import { EditPaymentDto, Payment } from 'modules/Payments/models';
import request from 'utils/request';

export interface EditPaymentResponse {
  data: Payment;
}

const editPayment = async ({
  id,
  ...payment
}: EditPaymentDto): Promise<EditPaymentResponse> => {
  const endpoint = (await getConfig()).apiPaymentsService;

  const response = await request<EditPaymentResponse>(`${endpoint}/${id}`, {
    method: 'PUT',
    data: payment,
  });
  return response.data;
};

export const invalidateQueries = [
  PAYMENTS_QUERY,
  DOCUMENTS_QUERY,
  DASHBOARD_QUERY,
  DOCUMENT_TRACKING_QUERY,
  CONTACTS_QUERY,
];

export default editPayment;
