import { useSelector } from 'react-redux';

import { selectCurrentBusiness } from 'modules/Business/Current/selectors';

import { VODAFONE_PARTNER } from '../constants/vodafone.constant';

function useIsVodafone() {
  const business = useSelector(selectCurrentBusiness);
  return business.partner === VODAFONE_PARTNER;
}

export default useIsVodafone;
