import { defineMessages } from 'react-intl';

export default defineMessages({
  title: { id: 'gofre.Documents.Recurrent.Create.Error.Modal.title' },
  subtitle: { id: 'gofre.Documents.Recurrent.Create.Error.Modal.subtitle' },
  text: { id: 'gofre.Documents.Recurrent.Create.Error.Modal.text' },
  plansButton: {
    id: 'gofre.Documents.Recurrent.Create.Error.Modal.plans-button',
  },
});
