import { v4 as uuidv4 } from 'uuid';

import { Address } from 'modules/Address';
import Business from 'modules/Business/models/business';
import { Coupon } from 'modules/Subscriptions/Create/types';
import { Plan } from 'modules/Subscriptions/models';
import { generateSubscriptionTaxes } from 'modules/Subscriptions/subscription.helpers';

import {
  AnalyticsAddress,
  AnalyticsCheckout,
  AnalyticsOrder,
  AnalyticsProduct,
} from '../models';

export function productMapper(plan: Plan) {
  return {
    product_id: plan.productId,
    sku: plan.productId,
    category: '',
    name: plan.marketName,
    brand: 'billin',
    variant: plan.duration,
    price: plan.price,
    quantity: 1,
    coupon: '',
    position: plan.order,
    url: '',
    image_url: '',
  } as AnalyticsProduct;
}

export function addressMapper(address: Address) {
  const { postalAddress, province, ...rest } = address;
  return {
    ...rest,
    state: province,
    street: postalAddress,
  } as AnalyticsAddress;
}

export function checkoutMapper(
  orderId: string,
  plan: Plan,
  business: Business,
  coupon?: Coupon
) {
  const taxes = generateSubscriptionTaxes(business, plan.price, coupon);

  return {
    order_id: orderId || uuidv4(),
    affiliation: '',
    value: plan.price + taxes.tax,
    revenue: plan.price,
    shipping: 0,
    tax: parseInt(taxes.taxLabel, 10),
    discount: (coupon?.absoluteDiscount || 0) / 100,
    coupon: coupon?.promotionCode || '',
    currency: 'EUR',
    products: [productMapper(plan)],
  } as AnalyticsCheckout;
}

export function orderMapper(
  orderId: string,
  plan: Plan,
  business: Business,
  coupon?: Coupon
) {
  const checkout = checkoutMapper(orderId, plan, business, coupon);
  return {
    ...checkout,
    total: checkout.value,
  } as AnalyticsOrder;
}
