import useTheme from 'modules/Theme/hooks/useTheme';

import IconByString, { IconByStringProps } from '../Icon/IconType/IconByString';

const IconMenuHome = (props: IconByStringProps) => {
  const { active, ...rest } = props;
  const theme = useTheme();
  return (
    <IconByString {...{ active, ...rest }}>
      <path
        clipRule="evenodd"
        d="M15 23v-6a3 3 0 1 0-6 0v6h6Z"
        fillRule="evenodd"
        fill={active ? theme.colors.brand500 : 'transparent'}
      />
      <path
        clipRule="evenodd"
        d="M12 2 2 9v14h20V9L12 2ZM3 22V9.52l9-6.3 9 6.3V22H3Z"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M16 17a4 4 0 0 0-8 0v5h1v-5a3 3 0 1 1 6 0v5h1v-5Z"
        fillRule="evenodd"
      />
    </IconByString>
  );
};

export default IconMenuHome;
