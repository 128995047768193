import IconByString, { IconByStringProps } from './Icon/IconType/IconByString';

export interface IconAddProps extends IconByStringProps {}

const IconBroadcast = (props: IconAddProps) => {
  const { ...rest } = props;
  return (
    <IconByString {...rest}>
      <circle cx="12.03" cy="8.415" r="1.931" stroke="currentColor" />
      <path
        fillRule="evenodd"
        d="M5.167 10.411c.285.631.7 1.194 1.22 1.65L9.6 8.412 6.397 4.75a4.863 4.863 0 0 0-1.23 5.66Zm1.107-.5c.214.473.526.895.916 1.238L9.598 8.41 7.197 5.665a3.647 3.647 0 0 0-.923 4.246ZM18.923 10.411a4.864 4.864 0 0 1-1.22 1.65l-3.212-3.65 3.202-3.66a4.864 4.864 0 0 1 1.23 5.66Zm-1.108-.5a3.647 3.647 0 0 1-.915 1.238L14.49 8.41l2.402-2.745a3.648 3.648 0 0 1 .922 4.246ZM11.422 9.626h1.216L16.285 23H15.07l-.662-2.428H9.653L8.991 23H7.775l3.647-13.373Zm-1.437 9.729h4.09l-.663-2.434h-2.764l-.663 2.434Zm2.045-7.5 1.05 3.85h-2.1l1.05-3.85Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        d="M1.85 11.932A8.51 8.51 0 0 0 4 14.823l5.598-6.409-.001-.002-4.798 5.492a7.295 7.295 0 0 1 .004-10.99L4.006 2a8.51 8.51 0 0 0-2.157 9.932ZM22.24 11.932a8.51 8.51 0 0 1-2.15 2.891l-5.6-6.409.002-.002 4.798 5.492a7.296 7.296 0 0 0-.004-10.99L20.083 2a8.511 8.511 0 0 1 2.156 9.932Z"
        fill="currentColor"
      />
    </IconByString>
  );
};

export default IconBroadcast;
