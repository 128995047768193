import ReactMarkDown, { TransformOptions } from 'react-markdown';

import {
  color,
  ColorProps,
  space,
  SpaceProps,
  layout,
  LayoutProps,
  typography,
  TypographyProps,
} from 'styled-system';

import styled from 'modules/Theme/styled-components';

interface TextMarkdownProps
  extends ColorProps,
    TypographyProps,
    LayoutProps,
    SpaceProps,
    TransformOptions {
  text: string;
  className?: string;
  allowDangerousHtml?: boolean;
  linkTarget?: string;
}

const MarkDown = styled(ReactMarkDown)`
  ${color}
  ${typography}
  ${space}
  ${layout}
  a {
    color: ${(props) => props.theme.colors.brand500};
    font-size: ${(props) => props.theme.fontSizes[16]};
    line-height: ${(props) => props.theme.lineHeights[26]};
    text-decoration: none;
  }
`;

const TextMarkdown = (props: TextMarkdownProps) => {
  const { className, text, ...rest } = props;
  return (
    <MarkDown
      className={className}
      color="inherit"
      fontSize="inherit"
      lineHeight="inherit"
      {...rest}
    >
      {text}
    </MarkDown>
  );
};

export default TextMarkdown;
