import deleteBusiness from 'modules/Business/Delete/services/deleteBusiness';
import useNotifications from 'modules/Notifications/hooks/useNotifications';
import { useBlockingMutation } from 'utils/useBlockingMutation';

import { messages } from './messages';

export default function useDeleteAccount(onClose: () => void) {
  const notifications = useNotifications();

  const { mutate } = useBlockingMutation(deleteBusiness, {
    onSuccess: () => {
      notifications.success(messages.notificationsSuccess.id);
      onClose && onClose();
    },
    onError: () => {
      notifications.error(messages.notificationsError.id);
    },
  });
  return mutate;
}
