import { ContactAddress } from 'modules/Address/models/address';
import Business from 'modules/Business/models/business';
import { VatNumberType } from 'modules/Contacts/models';
import { Document } from 'modules/Documents/models/document';
import { nifUtils } from 'utils/nif-validations';

import {
  BusinessData,
  ContactData,
  DataNeededFacturae,
} from '../../models/dataNeededFacturae';
import { AddressEmitForm, AddressClientForm, Dir3SubForm } from './SubForms';
import {
  DataElectronicItem,
  DataElectronicRequestType,
  SubForm,
} from './types';

export const dataElectronicItems: DataElectronicItem[] = [
  {
    type: DataElectronicRequestType.ADDRESSEMIT,
    subform: AddressEmitForm,
  },
  {
    type: DataElectronicRequestType.ADDRESSCLIENT,
    subform: AddressClientForm,
  },
  {
    type: DataElectronicRequestType.DIR3CLIENT,
    subform: Dir3SubForm,
  },
];

export function getSubForms(
  items: DataElectronicItem[],
  getDataNeeded: DataNeededFacturae
) {
  const subforms: React.FC<SubForm>[] = [];
  items.forEach((dataElectronicForm: DataElectronicItem) => {
    if (dataElectronicForm.type === DataElectronicRequestType.ADDRESSCLIENT) {
      if (getDataNeeded.contact) {
        subforms.push(dataElectronicForm.subform);
      }
    } else if (
      dataElectronicForm.type === DataElectronicRequestType.ADDRESSEMIT
    ) {
      if (getDataNeeded.business) {
        subforms.push(dataElectronicForm.subform);
      }
    } else {
      subforms.push(dataElectronicForm.subform);
    }
  });

  return subforms;
}

export function checkEmptyFieldsAddress(address: ContactAddress) {
  let addressToFill = false;

  const requiredProps: ContactAddress = {
    postalAddress: '',
    postalCode: '',
    province: '',
    country: '',
    city: '',
  };

  Object.keys(requiredProps).forEach((key) => {
    const addressField = address[key as keyof ContactAddress];
    if (!addressField || addressField === '') {
      addressToFill = true;
    }
  });

  return addressToFill;
}

function checkBusinessDataFilled(comproveData: BusinessData) {
  let requestForm = false;

  requestForm = !nifUtils.validateVatNumber(
    VatNumberType.NIF,
    comproveData.vatNumber
  );

  if (!requestForm) {
    requestForm = checkEmptyFieldsAddress(comproveData.address);
  }

  return requestForm;
}

function checkContactDataFilled(comproveData: ContactData) {
  let requestForm = false;

  const isVatNumberValidable =
    comproveData.vatNumberType === VatNumberType.NIF ||
    comproveData.vatNumberType === VatNumberType.NIF_IVA;

  if (isVatNumberValidable) {
    requestForm = !nifUtils.validateVatNumber(
      comproveData.vatNumberType,
      comproveData.vatNumber
    );
  } else if (comproveData.vatNumber === '') {
    requestForm = true;
  }

  if (!requestForm) {
    requestForm = checkEmptyFieldsAddress(comproveData.address);
  }

  return requestForm;
}

export function getDataNeededModel(document: Document, business: Business) {
  const dataNeededFacturae: DataNeededFacturae = {
    dir3: {
      accountingOffice: '',
      managementOrgan: '',
      processingUnit: '',
    },
  };

  const contact = {
    fiscalName: document.contact.fiscalName,
    vatNumberType: document.contact.vatNumberType ?? VatNumberType.NIF,
    vatNumber: document.contact.vatNumber ?? '',
    address: document.contact.address,
  };

  if (checkContactDataFilled(contact)) {
    dataNeededFacturae.contact = contact;
  }

  const businessData = {
    fiscalName: business.fiscalName,
    vatNumber: business.vatNumber,
    address: business.address,
  };

  if (checkBusinessDataFilled(businessData)) {
    dataNeededFacturae.business = businessData;
  }

  return dataNeededFacturae;
}
