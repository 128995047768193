import { useSelector } from 'react-redux';

import { selectIsMobile } from 'modules/App/store/selectors';

function useNotificationPadding() {
  const isMobile = useSelector(selectIsMobile);
  return isMobile ? 56 : 80;
}

export default useNotificationPadding;
