export const useHookConditionally = <T, P extends any[]>({
  condition,
  hook,
  fallback,
  props,
}: {
  condition: boolean;
  hook: ((...args: P) => T) | (() => T);
  fallback: T;
  props?: P;
}): T => {
  if (!condition) return fallback;

  return Array.isArray(props)
    ? (hook as (...args: P) => T)(...props)
    : (hook as () => T)();
};
