import IconByPath, { IconByPathProps } from './Icon/IconType/IconByPath';

const IconBizkaia = (props: IconByPathProps) => (
  <IconByPath
    {...props}
    path="M15.943 15.421c1.028-.978 2.284-2.244 2.673-2.766.299-.349.464-.756.35-1.133a.843.843 0 00-.517-.722c-.342-.164-.763-.087-1.225-.035-.418.069-.816.091-1.068-.036-.599-.292-.67-.822-.446-1.202.272-.462.817-.846 1.383-1.278.657-.496 1.305-1 1.659-1.645.4-.733-.006-1.034-.286-1.174-.202-.095-.457-.112-.705-.121-.189-.04-.4-.035-.519-.093-.326-.158-.364-.457-.148-.835.076-.128.234-.32.425-.56.205-.274.415-.556.554-.814.19-.343.149-1.177-.433-1.463-.589-.284-1.48.315-2.23.81-.64.39-1.158.725-1.474.566-.276-.139-.293-.36-.336-.54-.046-.185-.047-.345-.243-.442-.47-.23-1.219.306-1.817 1.22-.364.556-.519.951-.715 1.328-.142.3-.353.61-.59.96-.522.779-.765.965-1.168.738-.403-.228-.615-.564-.816-.833-.183-.208-.368-.415-.527-.505-.44-.245-1.097-.105-1.585.533-1.16 1.523-1.232 3.286-1.251 4.509-.039.61-.039 1.097-.204 1.305-.164.21-.476.205-.84-.054-.12-.087-.296-.267-.471-.445-.138-.153-.28-.364-.445-.477-.2-.147-.898-.39-1.196-.044-.466.53-.176 2.826.114 3.886.537 1.953 1.422 3.437 1.988 4.363.503.766.786 1.202.6 1.449-.203.253-.514.124-.802-.036-.376-.236-.776-.433-1.104-.026-.611.732 1.1 3.082 3.125 4.489.963.665 2.04 1.255 3.117 1.726 1.075.47 2.093.906 3.015 1.366C6.11 14.432 14.04 6.514 13.79 6.35c.285.056-7.445 8.857-.24 21.531.697 1.133 2.025 2.37 2.358 2.69l.01-.014c-.433-.63-2.442-4.23.171-9.396.539-1.106 1.124-2.087.91-2.61-.261-.649-.937-.47-1.638-.26-.449.167-.918.266-1.193.131a.685.685 0 01-.348-.393c-.046-.407.413-.88.727-1.215.176-.19.727-.734 1.396-1.393z"
    fillRule="evenodd"
    clipRule="evenodd"
    viewBox="0 0 21 32"
  />
);

export default IconBizkaia;
