import { createAsyncAction } from 'typesafe-actions';

import {
  GET_USER_REQUEST,
  GET_USER_SUCCESS,
  GET_USER_FAILURE,
} from './constants';
import { User } from './models/user';

export const getUser = createAsyncAction(
  GET_USER_REQUEST,
  [GET_USER_SUCCESS, (user: User) => user],
  [GET_USER_FAILURE, (error: Error) => error]
)();
