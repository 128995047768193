import useTranslations from 'modules/I18n/hooks/useTranslations';

import { apiConfigurationForm } from '../../messages';
import { IntegrationFormSeries } from '../IntegrationFormSeries';

export const ApiConfigurationFormSeries = () => {
  const { t } = useTranslations();
  return (
    <IntegrationFormSeries
      isTopLevel={false}
      seriesTitle={t(apiConfigurationForm.seriesTitle)}
      seriesTooltip={t(apiConfigurationForm.seriesTooltip)}
      seriesLabelText={t(apiConfigurationForm.seriesLabelText)}
      seriesLabelHide={t(apiConfigurationForm.seriesLabelHide)}
    />
  );
};
