import { FC, useCallback, useState } from 'react';
import { SketchPicker } from 'react-color';
import { useSelector } from 'react-redux';
import { Popover } from 'react-tiny-popover';

import { selectIsMobile } from 'modules/App/store/selectors';
import useTheme from 'modules/Theme/hooks/useTheme';
import styled from 'modules/Theme/styled-components';
import { Box, Text } from 'modules/Ui';
import { FormikFieldProps } from 'modules/Ui/Formik/FormikFieldProps';

interface ColorFieldProps extends FormikFieldProps {
  value: 'string';
}

export const Wrapper = styled(Box)`
  @media (hover: hover) {
    &:hover {
      cursor: pointer;
    }
  }
  &:focus {
    border-color: ${(props) => props.theme.colors.brand500};
  }
`;

const ColorField: FC<ColorFieldProps> = ({ id, onChange, label, value }) => {
  const theme = useTheme();
  const [showPicker, setShowPicker] = useState(false);
  const isMobile = useSelector(selectIsMobile);
  const togglePicker = useCallback(() => {
    setShowPicker(!showPicker);
  }, [setShowPicker, showPicker]);
  return (
    <Popover
      isOpen={showPicker}
      padding={10}
      positions={isMobile ? ['bottom'] : ['right']}
      align="start"
      onClickOutside={() => setShowPicker(false)}
      content={() => (
        <SketchPicker
          disableAlpha
          onChange={(colorResult: { hex: string }) => {
            onChange && onChange(colorResult.hex);
          }}
          color={value}
        />
      )}
    >
      <Box alignItems="center" display="flex">
        <Wrapper
          border={`solid 1px ${theme.colors.primary300}`}
          borderRadius="8px"
          height="44px"
          id={id}
          marginRight="11px"
          onClick={togglePicker}
          style={{ backgroundColor: value }}
          tabIndex="0"
          width="44px"
        />
        {label && <Text textSize="xs">{label}</Text>}
      </Box>
    </Popover>
  );
};

export default ColorField;
