import { FC, useCallback } from 'react';

import { useField } from 'formik';

import ColorField from 'modules/Ui/Form/ColorField';

interface ColorFormikFieldProps {
  name: string;
  label: string;
}

const ColorFormikField: FC<ColorFormikFieldProps> = (props) => {
  const { name, label } = props;

  const [field, , helpers] = useField(name);
  const { value } = field;
  const handleOnChange = useCallback(
    (color: string) => {
      helpers.setValue(color);
    },
    [helpers]
  );

  return (
    <ColorField
      value={value}
      id={name}
      label={label}
      onChange={handleOnChange}
    />
  );
};

export default ColorFormikField;
