import updateAccountingAccount, {
  UpdateAccountingAccountResponse,
  invalidateQueries,
} from 'modules/AccountingAccounts/services/updateAccountingAccount'; // invalidateQueries,
import { useInvalidateAndUpdate } from 'modules/App/hooks/useInvalidateAndUpdate';
import useNotifications from 'modules/Notifications/hooks/useNotifications';
import { ResponseError } from 'types/response-error.type';
import requestErrorHandler from 'utils/requestErrorHandler';
import { useBlockingMutation } from 'utils/useBlockingMutation';

import messages from './messages';

function useUpdateAccountingAccount(
  callback?: (response: UpdateAccountingAccountResponse) => void,
  showSuccessNotification: boolean = true
) {
  const notifications = useNotifications();
  const invalidateAndUpdate = useInvalidateAndUpdate();
  const { mutate, mutateAsync } = useBlockingMutation(updateAccountingAccount, {
    onSuccess: async (response): Promise<UpdateAccountingAccountResponse> => {
      if (showSuccessNotification) {
        notifications.success(messages.updateSuccess.id, {
          ttl: 5000,
        });
      }
      invalidateAndUpdate({ invalidateQueries, isAsync: true });
      callback?.(response);
      return response;
    },
    onError: (error: ResponseError) => {
      const { isNetworkError, networkErrorTranslationKey } =
        requestErrorHandler(error);
      return notifications.error(
        isNetworkError ? networkErrorTranslationKey : messages.updateError.id
      );
    },
  });
  return { mutate, mutateAsync };
}

export default useUpdateAccountingAccount;
