import { AxiosResponse } from 'axios';

import { getConfig } from 'modules/App/config';
import { TemplateSettings } from 'modules/Settings/Theme/types';
import request from 'utils/request';

const fetchTemplateConfigPreview = async (
  data: Omit<TemplateSettings, 'id'>,
  id: string = 'mock'
): Promise<string> => {
  const endpoint = (await getConfig()).apiPrinterService;

  const response = await request<AxiosResponse<string>>(
    `${endpoint}/${id}/preview`,
    {
      method: 'POST',
      data,
    }
  );

  return response.data.data;
};

export default fetchTemplateConfigPreview;
