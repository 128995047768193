import { FC } from 'react';

import {
  space,
  SpaceProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
} from 'styled-system';

import useTranslations from 'modules/I18n/hooks/useTranslations';
import styled from 'modules/Theme/styled-components';
import { Box, Button } from 'modules/Ui';
import { IconCross } from 'modules/Ui/Icons';

import { dialog } from '../messages';

interface Props extends SpaceProps, LayoutProps, PositionProps {
  onClose?(): void;
  color?: string;
}

const StyledWrapper = styled(Box)`
  ${space}
  ${layout}
  ${position}
`;

export const CloseButton: FC<Props> = ({ color, onClose, ...rest }) => {
  const { t } = useTranslations();

  return (
    <StyledWrapper
      boxSizing="border-box"
      display="flex"
      justifyContent="flex-end"
      {...rest}
    >
      <Button
        aria-label={t(dialog.btnClose)}
        data-testid="dialog-close"
        height="32px"
        onClick={onClose}
        type="button"
        width="32px"
      >
        <IconCross size={24} color={color || 'gray0'} />
      </Button>
    </StyledWrapper>
  );
};
