import { defineMessages } from 'react-intl';

export default defineMessages({
  edit: { id: 'gofre.Products.Detail.edit' },
  title: { id: 'gofre.Products.Detail.title' },
  error: { id: 'gofre.Products.Detail.error' },
  buyPrice: { id: 'gofre.Products.Detail.buyPrice' },
  margin: { id: 'gofre.Products.Detail.margin' },
  tax: { id: 'gofre.Products.Detail.tax' },
  sellPrice: { id: 'gofre.Products.Detail.sellPrice' },
  discount: { id: 'gofre.Products.Detail.discount' },
  pvp: { id: 'gofre.Products.Detail.pvp' },
  viewMore: { id: 'gofre.Products.Detail.viewMore' },
  viewLess: { id: 'gofre.Products.Detail.viewLess' },
  titleProduct: { id: 'gofre.productDetail.panel.product.title' },
  stockTitle: { id: 'gofre.productDetail.panel.stock.title' },
  stockSku: { id: 'gofre.productDetail.panel.stock.sku' },
  stockUnits: { id: 'gofre.productDetail.panel.stock.units' },
  stockAlarm: { id: 'gofre.productDetail.panel.mobile.stockAlarm' },
  stockAlarmActivated: {
    id: 'gofre.productDetail.panel.mobile.stockAlarmActivated',
  },
  stockAvailable: {
    id: 'gofre.productDetail.panel.mobile.stockAvailable',
  },
});
