import { useEffect, useState, useCallback } from 'react';
import { useAsync } from 'react-async-hook';
import { useSelector } from 'react-redux';

import axios from 'axios';
import { LayoutProps } from 'styled-system';

import { selectBrowser } from 'modules/App/store/selectors';
import useTranslations from 'modules/I18n/hooks/useTranslations';
import { Box, Img } from 'modules/Ui';
import { IconEnlarge, IconEnlargeInside } from 'modules/Ui/Icons';
import { isIOSPlatform } from 'utils/downloadFile';

import messages from './messages';

interface EmbedViewerProps {
  dataUri: string;
  fileType?: string;
  hideToolbar?: boolean;
  fullHeight?: boolean;
  noPadding?: boolean;
  showScan?: boolean;
  initiallyExpanded?: boolean;
  open?: boolean;
  onChange?(): void;
  isOcrPreview?: boolean;
}

const fetchFileHeaders = async (url: string) => {
  const { headers } = await axios.head(url);
  return headers;
};

const EmbedViewer: React.FC<EmbedViewerProps & LayoutProps> = ({
  dataUri,
  fileType = '',
  hideToolbar,
  fullHeight,
  noPadding,
  initiallyExpanded = false,
  isOcrPreview,
  onChange,
  open,
}) => {
  const { t } = useTranslations();
  const browser = useSelector(selectBrowser);
  const mobileDevice = browser.lessThan.large;
  const [showResult, setShowResult] = useState(false);
  const [stateExpanded, setExpanded] = useState(initiallyExpanded);

  const toggleExpandedState = useCallback(() => {
    setExpanded(!stateExpanded);
  }, [stateExpanded, setExpanded]);

  const onWrapperBoxClick = open === undefined ? toggleExpandedState : onChange;

  const { loading, result } = useAsync(() => {
    if (fileType || !dataUri) {
      return Promise.resolve({ 'content-type': fileType });
    }
    return fetchFileHeaders(dataUri);
  }, [dataUri, fileType]);

  const type = result?.['content-type'];
  const loadEmbed = type === 'application/pdf';

  useEffect(() => {
    if (!loading && !loadEmbed) {
      setShowResult(true);
    }
  }, [loading, loadEmbed, type]);

  if ((!dataUri && !fileType) || loading || !result) return null;

  const embedProps = isIOSPlatform()
    ? {
        type: 'application/pdf',
      }
    : {
        height: '100%',
      };

  let fullDataUri = dataUri;
  if (hideToolbar) {
    fullDataUri = `${dataUri}#scrollbar=0&toolbar=0&navpanes=0`;
  }
  const imageProps = fullHeight
    ? { maxHeight: '100vh', height: 'calc(100vh - 200px)' }
    : { maxHeight: { md: '100%' } };

  const imageExpandable = mobileDevice && isOcrPreview;

  const imageIsOpen = imageExpandable && stateExpanded;

  if (isOcrPreview) {
    return (
      <Box
        aria-expanded={!!imageIsOpen}
        aria-label={
          imageIsOpen ? t(messages.imageClose) : t(messages.imageOpen)
        }
        animationFillMode="forwards"
        backgroundColor="gray0"
        display={imageExpandable && 'block'}
        fadeIn={showResult}
        height={{
          _: stateExpanded ? 'auto' : '116px',
          sm: stateExpanded ? 'auto' : '180px',
          md: '1128px',
        }}
        onClick={imageExpandable ? onWrapperBoxClick : undefined}
        overflow="hidden"
        position={{ _: 'relative', sm: 'initial' }}
        tag={imageExpandable && 'button'}
        width="100%"
        zIndex={{ _: '2', sm: 'initial' }}
      >
        {loadEmbed ? (
          <embed
            onLoad={() => setShowResult(true)}
            src={`${dataUri}#toolbar=0&navpanes=0&scrollbar=0`}
            width="100%"
            {...embedProps}
          />
        ) : (
          <Box
            backgroundColor="primary100"
            boxSizing="border-box"
            display="block"
            height="100%"
            padding={noPadding ? '0' : { _: '0', md: '32px' }}
            position={imageExpandable && 'relative'}
            tag={imageExpandable && 'span'}
            width="100%"
          >
            <Img
              alt={t(messages.scannedDocument)}
              display="block"
              src={dataUri}
              width="100%"
              {...imageProps}
            />
            {imageExpandable && (
              <Box
                alignItems="center"
                backgroundColor="primary500"
                borderRadius="50%"
                bottom="8px"
                display="flex"
                height="44px"
                justifyContent="center"
                posiriotn="absolute"
                position="absolute"
                right="8px"
                tag="span"
                width="44px"
              >
                {imageIsOpen ? (
                  <IconEnlargeInside color="gray0" size={20} />
                ) : (
                  <IconEnlarge color="gray0" size={20} />
                )}
              </Box>
            )}
          </Box>
        )}
      </Box>
    );
  }

  return (
    <Box
      fadeIn={showResult}
      animationFillMode="forwards"
      height={fullHeight ? '100vh' : { _: '100%', md: 786, lg: 1014, xl: 1033 }}
      overflow="hidden"
      width="100%"
    >
      {loadEmbed ? (
        <embed
          onLoad={() => setShowResult(true)}
          src={fullDataUri}
          width="100%"
          {...embedProps}
        />
      ) : (
        <Box
          backgroundColor={{ md: 'primary100' }}
          boxSizing="border-box"
          height="100%"
          padding={noPadding ? '0' : { _: '0', md: '32px' }}
          width="100%"
        >
          <Img src={dataUri} alt="" width="100%" />
        </Box>
      )}
    </Box>
  );
};

export default EmbedViewer;
