import { getConfig } from 'modules/App/config';
import { Coupon } from 'modules/Subscriptions/Create/types';
import request from 'utils/request';

export interface Line {
  amount: number;
  periodEnd: Date;
  periodStart: Date;
  proration: true;
  quantity: number;
  type: string;
}

export interface ChangePlanPreviewResponse {
  amountDue: number;
  appliedBalance: number;
  coupon?: Coupon;
  issuedDate: Date;
  lines: Line[];
  subtotal: number;
  tax: number;
  total: number;
}

interface Params {
  productId: string;
}

export const changePlanPreview = async ({
  productId,
}: Params): Promise<ChangePlanPreviewResponse> => {
  const endpoint = (await getConfig()).apiSubscriptionsService;
  const fullEndpointUrl = `${endpoint}/change-plan/preview`;
  const response = await request<{ data: ChangePlanPreviewResponse }>(
    fullEndpointUrl,
    {
      method: 'POST',
      data: { productId },
    }
  );
  return response.data.data;
};
