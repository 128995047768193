import { roundNumber } from 'utils/MathOperations';

import { TortillaConcept as Concept } from './types';

/*
  IF YOU CHANGE ANYTHING BELOW THIS COMMENT YOU NEED TO DO THE SAME CHANGE IN GOFRE / TORTILLA
  WE ROUND TO 3 DECIMAL PLACES SO WE DONT LOSE PRECISION ON THE SECOND DECIMAL PLACE
  THE OBJECTIVE OF IS THAT THE TOTALS MUST ADD PERFECTLY, SUBTOTAL + TAX = TOTAL
  WE ARE FINE LOOSING SOME TRAILING CENTS IN THE SUM OF THE CONCEPTS
 */

type GetTaxDataArgs = {
  totalAmount: number;
  taxPercentage: number;
  salesEqTaxPercentage: number;
  salesEqTaxAmount?: number;
};

export const calculateTaxData = (args: GetTaxDataArgs) => {
  const { totalAmount, taxPercentage = 0, salesEqTaxPercentage = 0 } = args;

  const totalTaxesPercent = taxPercentage + salesEqTaxPercentage;
  const taxBase = roundNumber(totalAmount / (1 + totalTaxesPercent / 100));
  const taxAmount = roundNumber(
    salesEqTaxPercentage
      ? taxBase * (taxPercentage / 100)
      : totalAmount - taxBase
  );
  const saleEqualizationAmount = roundNumber(totalAmount - taxBase - taxAmount);

  return {
    salesEqTaxPercentage,
    salesEqTax: saleEqualizationAmount,
    taxBase,
    taxAmount,
  };
};

export function calculateConceptValuesFromTotal(concept: Concept) {
  const {
    totalAmount = 0,
    taxPercentage = 0,
    salesEqTaxPercentage = 0,
    discountPercentage = 0,
    quantity,
  } = concept;

  const taxData = calculateTaxData({
    totalAmount: totalAmount ?? 0,
    taxPercentage,
    salesEqTaxPercentage,
  });

  let subtotal;
  let discount;
  if (discountPercentage === 100) {
    subtotal = (concept.unitPrice || 0) * quantity;
    discount = subtotal;
  } else {
    const { taxBase: base } = taxData;
    subtotal = base / (1 - discountPercentage / 100);
    discount = subtotal - base;
  }
  const unitPrice = subtotal / quantity;

  return {
    ...taxData,
    discount,
    subtotal,
    unitPrice,
  };
}
