import { FC } from 'react';
import { Hidden, Visible } from 'react-grid-system';

import { Box, HeadingWithBack, PageLayout } from 'modules/Ui';
import { menuSizes } from 'modules/Ui/Menu/MenuMobile/menuSizes';

import { StepProps } from '../../types';
import PaymentResume from '../PaymentResume';
import PaymentStep from '../PaymentStep';
import PaymentForm from './PaymentForm';
import { PaymentFormActions } from './PaymentFormActions';

export const PaymentData: FC<StepProps> = (props) => {
  const { currentStep, onCancel, data, inProgress } = props;
  return (
    <PageLayout
      pageTitle={<HeadingWithBack title={data.marketName} onClick={onCancel} />}
      pageSteps={<PaymentStep {...{ currentStep }} />}
      asideVisibleInMobile={true}
      gridTemplateRows={{ _: '4px auto 1fr auto', sm: 'initial' }}
      height={{
        _: `calc(100vh - ${menuSizes.HEADER_HEIGHT_MOBILE}px)`,
        sm: 'initial',
      }}
      mainColumn={
        <>
          <Hidden xs sm md>
            <PaymentForm {...{ ...props, currentStep }} />
          </Hidden>
          <Visible xs sm md>
            <PaymentResume
              {...data}
              currentStep={currentStep}
              coupon={data.coupon}
            />
          </Visible>
        </>
      }
      asideColumn={
        <>
          <Hidden xs sm md>
            <PaymentResume
              {...data}
              currentStep={currentStep}
              coupon={data.coupon}
            />
          </Hidden>
          <Visible xs sm md>
            <Box
              backgroundColor="gray0"
              padding={{ _: '16px', sm: '40px 32px 32px', md: 'initial' }}
            >
              <PaymentForm {...{ ...props, currentStep }} />
            </Box>
          </Visible>
        </>
      }
      actions={<PaymentFormActions {...{ onCancel, inProgress }} />}
    />
  );
};
