import { FC } from 'react';
import { useSelector } from 'react-redux';

import { selectIsTablet } from 'modules/App/store/selectors';
import {
  MODAL_WIDTH_SMALL,
  MODAL_WIDTH_MEDIUM,
  Dialog,
  useCloseModal,
} from 'modules/Modals';

export interface DialogProps {
  video?: string;
}

const ExporterHelpModal: FC<DialogProps> = ({ video, ...rest }) => {
  const closeModal = useCloseModal();
  const isTablet = useSelector(selectIsTablet);
  return (
    <Dialog
      type="empty"
      id="exporter-video"
      onClose={closeModal}
      dialogWidth={{
        _: 'calc(100vw - 16px)',
        sm: MODAL_WIDTH_SMALL,
        md: MODAL_WIDTH_MEDIUM,
      }}
      {...{ rest }}
    >
      <iframe
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen={true}
        frameBorder="0"
        height={isTablet ? '322' : '455'}
        src={video}
        title="YouTube video player"
        width="100%"
      />
    </Dialog>
  );
};

export default ExporterHelpModal;
