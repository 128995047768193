/* eslint-disable no-console */
const currentUserKey = 'billin-user';
const ffIdentifierKey = 'billin-ff';

export function setCurrentUser(token: string) {
  try {
    localStorage.clear();
    localStorage.setItem(currentUserKey, token);
  } catch (e) {
    console.log('Error storing in localStorage');
    console.log(e);
  }
}

export function getCurrentUser() {
  let currentUser;
  try {
    currentUser = localStorage.getItem(currentUserKey) || '';
  } catch (e) {
    console.log('Error accessing localStorage');
    console.log(e);
  }
  return currentUser;
}

export function clearCurrentUser() {
  try {
    localStorage.removeItem(currentUserKey);
    localStorage.clear();
  } catch (e) {
    console.log('Error clearing localStorage');
    console.log(e);
  }
}

export const featureFlagSetEventId = `${ffIdentifierKey}-set`;

export function setFeatureFlagIdentifiers(token: string) {
  try {
    localStorage.setItem(ffIdentifierKey, token);
    dispatchEvent(featureFlagSetEventId);
  } catch (e) {
    console.log('Error storing in localStorage');
    console.log(e);
  }
}

export function getFeatureFlagIdentifiers() {
  let currentUser;
  try {
    currentUser = localStorage.getItem(ffIdentifierKey) || '';
  } catch (e) {
    console.log('Error accessing localStorage');
    console.log(e);
  }
  return currentUser;
}

export function clearFeatureFlagIdentifiers() {
  try {
    localStorage.removeItem(ffIdentifierKey);
    dispatchEvent(featureFlagSetEventId);
  } catch (e) {
    console.log('Error clearing localStorage');
    console.log(e);
  }
}

function dispatchEvent(key: string): void {
  const event = new Event(key);

  document.dispatchEvent(event);
}
