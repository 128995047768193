enum Concepts {
  INVOICE = 'INVOICE',
}

export enum Actions {
  CREATE_DRAFT_DOCUMENT = 'CREATE_DRAFT_DOCUMENT',
  CREATE_ISSUE_DOCUMENT = 'CREATE_ISSUE_DOCUMENT',
  CREATE_CORRECTIVE_DOCUMENT = 'CREATE_CORRECTIVE_DOCUMENT',
  CORRECT_DOCUMENT = 'CORRECT_DOCUMENT',
  DELETED_DOCUMENT = 'DELETED_DOCUMENT',
  DELETED_MULTIPLE_DOCUMENT = 'DELETED_MULTIPLE_DOCUMENT',
  EDIT_DOCUMENT = 'EDIT_DOCUMENT',
  EDIT_DRAFT_DOCUMENT = 'EDIT_DRAFT_DOCUMENT',
  ISSUE_DOCUMENT = 'ISSUE_DOCUMENT',
  MARK_AS_CORRECTED_DOCUMENT = 'MARK_AS_CORRECTED_DOCUMENT',
  MARK_AS_PAID_DOCUMENT = 'MARK_AS_PAID_DOCUMENT',
  MARK_AS_UNPAID_DOCUMENT = 'MARK_AS_UNPAID_DOCUMENT',
  SINGLE_DOCUMENT_SENT_READ = 'SINGLE_DOCUMENT_SENT_READ',
  SINGLE_DOCUMENT_SENT_RECEIVED = 'SINGLE_DOCUMENT_SENT_RECEIVED',
  SINGLE_DOCUMENT_SENT = 'SINGLE_DOCUMENT_SENT',
  SINGLE_DOCUMENT_SENT_BASIC = 'SINGLE_DOCUMENT_SENT_BASIC',
  SINGLE_DOCUMENT_SENT_SPAM = 'SINGLE_DOCUMENT_SENT_SPAM',
  SINGLE_DOCUMENT_SENT_BLOCKED = 'SINGLE_DOCUMENT_SENT_BLOCKED',
  DIGITAL_DOCUMENT_SUBMITTED = 'DIGITAL_DOCUMENT_SUBMITTED',
  DIGITAL_DOCUMENT_CONNECTION_FAILED = 'DIGITAL_DOCUMENT_CONNECTION_FAILED',
  DIGITAL_DOCUMENT_REJECTED = 'DIGITAL_DOCUMENT_REJECTED',
  DIGITAL_DOCUMENT_VOID = 'DIGITAL_DOCUMENT_VOID',
  VOID_DOCUMENT = 'VOID_DOCUMENT',
  DIGITAL_DOCUMENT_CONNECTION_FAILED_TO_VOID = 'DIGITAL_DOCUMENT_CONNECTION_FAILED_TO_VOID',
  DIGITAL_DOCUMENT_REJECTED_TO_VOID = 'DIGITAL_DOCUMENT_REJECTED_TO_VOID',
  ADD_FINAL_PAYMENT_TO_DOCUMENT = 'ADD_FINAL_PAYMENT_TO_DOCUMENT',
  DELETE_PAYMENT_FROM_DOCUMENT = 'DELETE_PAYMENT_FROM_DOCUMENT',
  DELETE_PAYMENT_PARTIAL_FROM_DOCUMENT = 'DELETE_PAYMENT_PARTIAL_FROM_DOCUMENT',
  ADD_PARCIAL_PAYMENT_TO_DOCUMENT = 'ADD_PARCIAL_PAYMENT_TO_DOCUMENT',
  UPDATE_FINAL_PAYMENT_TO_DOCUMENT = 'UPDATE_FINAL_PAYMENT_TO_DOCUMENT',
  UPDATE_PARTIAL_PAYMENT_TO_DOCUMENT = 'UPDATE_PARTIAL_PAYMENT_TO_DOCUMENT',
}

interface Author {
  email: string;
  name: string;
  surname: string;
}

export interface DocumentTrackingData {
  action: Actions;
  actionDate: string;
  author?: Author;
  displayableAuthor?: string;
  businessId: string;
  concept: Concepts;
  createdAt: string;
  entityId: string; // invoiceId
  result?: DocumentTrackingResult;
  id: string;
}

interface DocumentTrackingResult {
  to?: string;
}

export interface DocumentTrackingDataResponse {
  items: DocumentTrackingData[];
  count: number;
}

export interface DocumentTrackingResponse {
  data: DocumentTrackingDataResponse;
}
