import { ResponsiveValue } from 'styled-system';

export interface SortOrderValues {
  ascOrder: DataTableSortOrder;
  descOrder: DataTableSortOrder;
}

export interface DataTableColumn<T extends object, U extends keyof T> {
  alignSelf?: string;
  className?: string;
  color?: ResponsiveValue<string>;
  disableSort?: boolean;
  display?: ResponsiveValue<string>;
  field: U;
  fontWeight?: string | number;
  fontSize?: ResponsiveValue<string>;
  fontStyle?: string;
  gridArea?: ResponsiveValue<string>;
  headerTextAlign?: ResponsiveValue<string>;
  justifySelf?: string;
  key?: string;
  lineHeight?: ResponsiveValue<string>;
  maxWidth?: ResponsiveValue<string>;
  margin?: ResponsiveValue<string>;
  marginLeft?: ResponsiveValue<string>;
  minWidth?: ResponsiveValue<string>;
  name: string | JSX.Element;
  subname?: string | JSX.Element;
  sortField?: U;
  sortOrderValues?: SortOrderValues;
  textAlign?: ResponsiveValue<string>;
  value?: (data: T, index: number) => string | JSX.Element;
  width?: ResponsiveValue<string>;
  hidden?: boolean;
}

export interface DataTableActionOnClick<T> {
  (
    datafunction: T[],
    inverted: boolean,
    resetSelection: () => void,
    totalCount?: number
  ): void | Promise<void>;
}

export interface DataTableAction<T> {
  name: string;
  abbrname?: string;
  shouldActionRender?: boolean;
  icon?: React.Component | JSX.Element;
  disableMultiple?: boolean;
  onlyMultiple?: boolean;
  shouldHideOnInverted?: boolean;
  onClick: DataTableActionOnClick<T>;
}

export enum DataTableSortOrder {
  ASC = 'ASC',
  ASC_NULLS_LAST = 'ASC NULLS LAST',
  ASC_NULLS_FIRST = 'ASC NULLS FIRST',
  DESC = 'DESC',
  DESC_NULLS_LAST = 'DESC NULLS LAST',
  DESC_NULLS_FIRST = 'DESC NULLS FIRST',
}

export interface DataTableSort<T, U extends keyof T> {
  field: U;
  order: DataTableSortOrder;
}
