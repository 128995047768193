import { Formik, FormikHelpers } from 'formik';

import { provinces } from 'modules/Address';
import { Contact, ContactForm } from 'modules/Contacts/models';
import { getContactForInvoiceSchema } from 'modules/Documents/components/TwoStepsForm/validation/contactForInvoice';
import useTranslations from 'modules/I18n/hooks/useTranslations';
import { Box, ErrorListener, FormikField } from 'modules/Ui';
import { SelectFormikField } from 'modules/Ui/Formik';
import CountryFormikField from 'modules/Ui/Formik/CountryFormikField';

import { getInitialValues } from './getInitialValues';
import { form as messages } from './messages';

interface Props {
  contact: Contact;
  onSubmit: (values: ContactForm) => void;
}

const AddContactForm: React.FC<Props> = ({ contact, onSubmit }) => {
  const { t } = useTranslations();
  const onSubmitHandler = (
    values: ContactForm,
    actions: FormikHelpers<ContactForm>
  ) => {
    onSubmit(values);
    actions.setSubmitting(false);
  };
  const validationSchema = getContactForInvoiceSchema(t);
  const initialValues = getInitialValues(contact);
  return (
    <Formik
      onSubmit={onSubmitHandler}
      validateOnChange={false}
      validateOnBlur={false}
      {...{ validationSchema, initialValues }}
    >
      {(formik) => (
        <Box tag="section">
          <ErrorListener />
          <form
            id="add-contact"
            onSubmit={formik.handleSubmit}
            noValidate
            autoComplete="off"
          >
            <Box
              display={{ sm: 'flex' }}
              flexWrap={{ sm: 'wrap' }}
              justifyContent={{ sm: 'space-between' }}
            >
              <FormikField
                id="fiscalName"
                label={t(messages.fiscalName)}
                maxLength={120}
                required
                width={{ _: '100%', md: '65.9%' }}
              />
              <FormikField
                id="vatNumber"
                label={t(messages.vatNumber)}
                placeholder={t(messages.vatNumberPlaceholder)}
                required
                maxLength={9}
                width={{ _: '100%', md: '31.8%' }}
              />

              <FormikField
                maxLength={120}
                id="address.postalAddress"
                label={t(messages.postalAddress)}
                width={{ _: '100%', md: '65.9%' }}
                required
              />
              <FormikField
                id="address.postalCode"
                required
                label={t(messages.postalCode)}
                maxLength={20}
                width={{ _: '100%', md: '31.8%' }}
              />

              <FormikField
                maxLength={120}
                id="address.city"
                label={t(messages.city)}
                type="text"
                width={{ _: '100%', md: '31.8%' }}
              />
              <SelectFormikField
                id="address.province"
                data-testid="province"
                label={t(messages.province)}
                options={provinces}
                placeholder={t(messages.provincePlaceholder)}
                isSearchable
                width={{ _: '100%', md: '31.8%' }}
              />
              <CountryFormikField
                id="address.country"
                label={t(messages.country)}
                width={{ _: '100%', md: '31.8%' }}
              />
            </Box>
          </form>
        </Box>
      )}
    </Formik>
  );
};

export default AddContactForm;
