import { PaymentIntent, StripeError } from '@stripe/stripe-js';

import {
  ACTIVE,
  REQUIRES_ACTION,
  REQUIRES_PAYMENT_METHOD,
  SUCCEEDED,
} from './constants';

export function isSubscriptionActive(status: string) {
  return status === ACTIVE;
}

export function isPaymentSuccess(result: {
  paymentIntent?: PaymentIntent;
  error?: StripeError;
}) {
  return result?.paymentIntent?.status === SUCCEEDED;
}

export function requiresActionOrPaymentMenthod(
  paymentIntent: PaymentIntent,
  isRetry: boolean
) {
  return (
    paymentIntent.status === REQUIRES_ACTION ||
    (isRetry === true && paymentIntent.status === REQUIRES_PAYMENT_METHOD)
  );
}
