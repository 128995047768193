import { getConfig } from 'modules/App/config';
import buildBaseIdsQuery from 'modules/App/queries/buildBaseIdsQuery';
import {
  CONTACTS_QUERY,
  DASHBOARD_QUERY,
  DOCUMENTS_QUERY,
  DOCUMENT_TRACKING_QUERY,
  PAYMENTS_QUERY,
} from 'modules/App/queries/queries';
import {
  DocumentStatus,
  DocumentType,
} from 'modules/Documents/models/document';
import { CreateMultiplePaymentDto, Payment } from 'modules/Payments/models';
import request from 'utils/request';

export interface CreateMultiplePaymentResponse {
  data: {
    count: number;
    requestedCount: number;
    items: Payment[];
  };
}

const createMultiplePayment = async (
  paymentsData: CreateMultiplePaymentDto
): Promise<CreateMultiplePaymentResponse> => {
  const endpoint = (await getConfig()).apiPaymentsService;

  const {
    multipleDeleteParams = {
      ids: [],
      status: DocumentStatus.ISSUED,
      documentTypes: [DocumentType.INVOICE],
    },
  } = paymentsData;
  const { ids, status, documentTypes } = multipleDeleteParams;
  let query = buildBaseIdsQuery(ids, false);

  if (documentTypes?.length) {
    const documentTypeQuery = documentTypes
      .map(
        (documentType, index) =>
          `&query[documentType][$in][${index}]=${documentType}`
      )
      .join('&');
    query += documentTypeQuery;
  }

  if (status) {
    query += `&query[status][$eq]=${status}`;
  }

  const response = await request<CreateMultiplePaymentResponse>(
    `${endpoint}/multiple?${query}`,
    {
      method: 'POST',
      data: {
        method: paymentsData.method,
        operationDate: paymentsData.operationDate,
        accountingAccountId: paymentsData.accountingAccountId,
      },
    }
  );

  return response.data;
};

export const invalidateQueries = [
  PAYMENTS_QUERY,
  DOCUMENTS_QUERY,
  DASHBOARD_QUERY,
  DOCUMENT_TRACKING_QUERY,
  CONTACTS_QUERY,
];

export default createMultiplePayment;
