import { useDispatch } from 'react-redux';

import { showModal } from 'modules/Modals/actions';
import { ModalTypes } from 'modules/Modals/constants';

export function useShowLanguageSelector() {
  const dispatch = useDispatch();

  return () =>
    dispatch(
      showModal({
        type: ModalTypes.SELECT_LANGUAGE,
      })
    );
}
