import IconByPath, { IconByPathProps } from './Icon/IconType/IconByPath';

const IconBusiness = (props: IconByPathProps) => (
  <IconByPath
    fillRule="evenodd"
    clipRule="evenodd"
    path="M16 3H8V5H16V3ZM7 2V5H2H1V6V10V18C1 19.6569 2.34315 21 4 21H20C21.6569 21 23 19.6569 23 18V10V6V5H22H17V2H7ZM7 6H17H22V10C22 11.1046 21.1046 12 20 12H15H9H4C2.89543 12 2 11.1046 2 10V6H7ZM9 13H4C3.23165 13 2.53076 12.7111 2 12.2361V18C2 19.1046 2.89543 20 4 20H20C21.1046 20 22 19.1046 22 18V12.2361C21.4692 12.7111 20.7684 13 20 13H15V16H9V13ZM14 13H10V15H14V13Z"
    {...props}
  />
);

export default IconBusiness;
