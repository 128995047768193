import { css } from 'modules/Theme/styled-components';

const tooltipStyles = () => css`
  display: block;
  font-size: ${(props) => props.theme.fontSizes[12]};
  font-style: normal;
  font-weight: 600;
  line-height: ${(props) => props.theme.lineHeights[14]};
  margin-top: 0;
  opacity: 1;
  text-align: center;

  &.tooltip-button {
    margin-left: 0;
  }
`;

export default tooltipStyles;
