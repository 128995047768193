import { ReactNode, FC } from 'react';

import {
  GridTemplateColumnsProps,
  GridTemplateRowsProps,
  LayoutProps,
  FlexboxProps,
} from 'styled-system';

import {
  CarouselBase,
  CarouselInputProps,
  ContainerProps,
} from './CarouselBase';

export interface CarouselProps
  extends CarouselInputProps,
    GridTemplateColumnsProps,
    GridTemplateRowsProps,
    LayoutProps,
    FlexboxProps {
  container(props: ContainerProps): void;
  items: ReactNode[];
}

export const Carousel: FC<CarouselProps> = ({ items, ...props }) => {
  return <CarouselBase content={items} {...props} />;
};
