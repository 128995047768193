import { Hidden } from 'react-grid-system';

import { UserPermissions } from 'modules/Auth/constants';
import useHasUserScope from 'modules/Auth/hooks/checkUsersPermissions';
import { DocumentStatus } from 'modules/Documents/models/document';
import useTranslations from 'modules/I18n/hooks/useTranslations';
import { Link } from 'modules/Ui';
import DataTableZeroState from 'modules/Ui/DataTable/DataTableZeroState';
import { ROUTES } from 'pages/documents/routesMap';

import { zeroState, zeroStateDraft } from '../../messages';

interface DocumentsZeroStateProps {
  status?: DocumentStatus;
  subtitle?: string;
  title?: string;
  link?: string;
  showCTA?: boolean;
}

const DocumentsZeroState = (props: DocumentsZeroStateProps) => {
  const { status, subtitle, title, link, showCTA = true } = props;
  const { hasUserScope } = useHasUserScope();
  const canCreate =
    hasUserScope(UserPermissions.SUBSCRIPTION_EDITOR) && showCTA;
  const { t } = useTranslations();
  const isDraft = status === DocumentStatus.DRAFT;
  const messages = isDraft ? zeroStateDraft : zeroState;
  return (
    <DataTableZeroState
      img={isDraft ? '/assets/empty-draft.svg' : '/assets/empty-documents.svg'}
      subtitle={subtitle ?? t(messages.subtitle)}
      title={title ?? t(messages.title)}
    >
      <Hidden xs>
        {canCreate && (
          <Link
            data-testid="document create"
            marginTop="16px"
            variant="secondary"
            to={ROUTES.DOCUMENTS_CREATE}
          >
            {link ?? t(messages.link)}
          </Link>
        )}
      </Hidden>
    </DataTableZeroState>
  );
};

export default DocumentsZeroState;
