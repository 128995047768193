import { DEFAULT_COUNTRY } from '../../Address/models/countries';
import { Entity } from '../../App/models';

export interface Subject extends Partial<Entity> {
  fiscalName: string;
}

export const emptySubject = {
  id: '',
  fiscalName: '',
  address: {
    postalAddress: '',
    postalCode: '',
    province: '',
    country: DEFAULT_COUNTRY,
    city: '',
  },
  vatNumber: '',
};
