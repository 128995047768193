import { defineMessages } from 'react-intl';

export default defineMessages({
  errorMessage: { id: 'gofre.Documents.FileVisor.error-message' },
  errorMessageMobile: { id: 'gofre.Documents.FileVisor.error-message-mobile' },
  errorTitle: { id: 'gofre.Documents.FileVisor.error-title' },
  modalTitle: { id: 'gofre.Documents.FileVisor.modal-title' },
  removeDocument: { id: 'gofre.Documents.FileVisor.remove-document' },
  uploadDocument: { id: 'gofre.Documents.FileVisor.upload-document' },
  visorlTitle: { id: 'gofre.Documents.FileVisor.visor-title' },
  fileAttached: { id: 'gofre.Documents.FileVisor.file-attached' },
  imageOpen: { id: 'gofre.Documents.FileVisor.image-open' },
  imageClose: { id: 'gofre.Documents.FileVisor.image-close' },
  scannedDocument: { id: 'gofre.Documents.FileVisor.scanned-document' },
});
