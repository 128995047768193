import { lazy, Suspense } from 'react';

import { SelectProps } from 'modules/Ui/Form/SelectCommon/types';
import { platformIsMobile } from 'utils/browser';

const SelectMobile = lazy(() => import('./SelectFormikField.mobile'));
const SelectDesktop = lazy(() => import('./SelectFormikField'));

const SelectFormikField = ({
  shouldRenderMobileSelect = true,
  ...rest
}: SelectProps) => {
  return (
    <Suspense fallback={null}>
      {platformIsMobile && shouldRenderMobileSelect ? (
        <SelectMobile {...rest} />
      ) : (
        <SelectDesktop {...rest} />
      )}
    </Suspense>
  );
};
export default SelectFormikField;
