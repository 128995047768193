import useTranslations from 'modules/I18n/hooks/useTranslations';
import { Box } from 'modules/Ui';
import MaskedFormikField from 'modules/Ui/Formik/MaskedFormikField';

import messages from '../messages';
import SubFormBox from './SubFormBox';

interface Props {
  isEditing: boolean;
  forContact?: boolean;
}

const DirectDebitSubForm: React.FC<Props> = ({
  isEditing = false,
  forContact,
}) => {
  const { t } = useTranslations();
  return (
    <SubFormBox isEditing={isEditing}>
      {forContact && (
        <Box display={{ sm: 'flex' }} justifyContent="space-between">
          <MaskedFormikField
            autoTrim
            id="data.IBAN"
            label={t(messages.bank_account_iban_contact)}
            placeholder={t(messages.bank_account_iban_placeholder)}
            width={{ _: '100%' }}
            mask="AA11 1111 1111 1111 1111 1111"
            placeholderChar=" "
            data-testid="iban-input"
          />
        </Box>
      )}
    </SubFormBox>
  );
};

export default DirectDebitSubForm;
