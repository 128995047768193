import { FC, useCallback } from 'react';
import { Hidden } from 'react-grid-system';

import useTranslations from 'modules/I18n/hooks/useTranslations';
import {
  useCloseModal,
  Dialog,
  MODAL_WIDTH_SMALLER,
  useOpenModal,
} from 'modules/Modals';
import { ModalTypes } from 'modules/Modals/constants';
import { CreatePaymentDto, PaymentTypeEnum } from 'modules/Payments';
import useCreatePayment from 'modules/Payments/hooks/useCreatePayment';
import { Button } from 'modules/Ui';

import { FormPayment, PaymentForm } from '../PaymentForm';
import messages from './messages';
import { CreatePaymentModalProps } from './types';

const CreatePaymentModal: FC<CreatePaymentModalProps> = (props) => {
  const {
    amount,
    onSuccess,
    operationDate,
    description,
    contactId,
    accountingAccountId,
    paymentType,
  } = props;
  const { t } = useTranslations();
  const closeModal = useCloseModal();
  const openModal = useOpenModal();
  const { mutateAsync: createPaymentMutation } = useCreatePayment({
    fromPaymentList: true,
  });

  const onCreateAccount = useCallback(
    (values: FormPayment) => {
      const currentProps = {
        ...props,
        amount: values.amount ? Number(values.amount) : 0,
        operationDate: values.operationDate,
        accountingAccountId: values.accountingAccountId,
        contactId: values.contactId,
        createContactSelect: values.contactId,
        description: values.description,
        paymentType: values.type,
      };
      openModal({
        type: ModalTypes.ACCOUNTING_ACCOUNT,
        onCancel: () => {
          openModal({
            type: ModalTypes.CREATE_PAYMENT,
            ...currentProps,
          });
        },
        onCreateAccountingAccount: (accountingAccount) => {
          openModal({
            type: ModalTypes.CREATE_PAYMENT,
            ...currentProps,
            accountingAccountId: accountingAccount.id,
          });
        },
      });
    },
    [props]
  );

  const cancelButton = (
    <Hidden xs>
      <Button
        data-testid="mark-as-paid-cancel"
        marginBottom={{ _: '16px', sm: '0' }}
        onClick={closeModal}
        variant="stroke"
      >
        {t(messages.cancel)}
      </Button>
    </Hidden>
  );

  const createButton = (
    <Button
      data-testid="create-payment-submit"
      variant="secondary"
      type="submit"
      form="update-payment-method"
    >
      {t(messages.confirmText)}
    </Button>
  );

  return (
    <Dialog
      id="create-payment-modal"
      dialogWidth={{ sm: MODAL_WIDTH_SMALLER }}
      type="fullScreen"
      title={t(messages.titleFromList)}
      onClose={closeModal}
      buttonPrimary={createButton}
      buttonSecondary={cancelButton}
      content={
        <PaymentForm
          {...{
            handleSubmit: async ({ ...values }) => {
              await createPaymentMutation({
                accountingAccountId: values.accountingAccountId,
                amount: values.amount ?? 0,
                contactId: values.contactId,
                description: values.description,
                operationDate: values.operationDate,
                type: values.type,
                method: values.method,
              } as CreatePaymentDto);
              closeModal();
              onSuccess();
            },
            onCreateAccount,
            maxAmount: amount,
            initialValues: {
              amount,
              operationDate: operationDate ?? new Date(),
              type: paymentType ?? PaymentTypeEnum.INCOME,
              description: description ?? '',
              contactId,
              createContactSelect: contactId,
              accountingAccountId,
            },
          }}
        />
      }
    />
  );
};

export default CreatePaymentModal;
