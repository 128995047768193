import IconByPath, { IconByPathProps } from './Icon/IconType/IconByPath';

const IconAlava = (props: IconByPathProps) => (
  <IconByPath
    {...props}
    path="M32.718 0h6.505v6.505h-6.505V0Zm-3.243 9.747H22.97v6.506h6.506V9.747Zm-9.768 19.495h-6.505v6.506h6.505v-6.506Zm3.263 0h6.506v6.506H22.97v-6.506Zm6.506 9.768H22.97v6.506h6.506V39.01Zm9.747-29.263h-6.505v6.506h6.505V9.747Zm-6.505 9.748h6.505V26h-6.505v-6.505Zm6.505 9.747h-6.505v6.506h6.505v-6.506Zm-6.505 9.768h6.505v6.506h-6.505V39.01ZM48.97 19.495h-6.505V26h6.505v-6.505Zm-6.505 9.747h6.505v6.506h-6.505v-6.506Zm6.505 9.768h-6.505v6.506h6.505V39.01Zm-6.505 9.748h6.505v6.505h-6.505v-6.505Zm16.253-39.01h-6.505v6.505h6.505V9.747Zm-6.505 9.747h6.505V26h-6.505v-6.505Zm6.505 9.747h-6.505v6.506h6.505v-6.506Zm-6.505 19.516h6.505v6.505h-6.505v-6.505Zm6.505 9.747h-6.505v6.505h6.505v-6.505Zm3.242-39.01h6.505V26H61.96v-6.505Zm6.505 9.747H61.96v6.506h6.505v-6.506ZM61.96 39.01h6.505v6.506H61.96V39.01Zm6.505 9.748H61.96v6.505h6.505v-6.505Zm-6.505 9.747h6.505v6.505H61.96v-6.505Zm16.253-29.263h-6.506v6.506h6.506v-6.506Zm-6.506 9.768h6.506v6.506h-6.506V39.01Zm-71.494 0v9.748h6.505l9.706-9.748H10l-.041-9.768L.213 39.01Zm29.283 29.243v-9.748l9.748-9.747.04 9.747h6.424L36 68.253h-6.505ZM9.96 48.758v9.747h16.252l9.727-9.747H19.75l-.042-9.748-9.747 9.748ZM39.223 78v-9.747l9.747-9.748.021 9.748h16.19L55.456 78H39.223Z"
    fillRule="evenodd"
    clipRule="evenodd"
    viewBox="0 0 82 82"
  />
);

export default IconAlava;
