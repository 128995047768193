import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import analyticsService from 'modules/Analytics/services/analyticsService';
import { selectAppDataState } from 'modules/App/selectors';
import { selectCurrentBusiness } from 'modules/Business/Current/selectors';
import { CompleteValidVatNumbersContext } from 'modules/Documents/components/CompleteValidVatNumbers/types';
import useTranslations from 'modules/I18n/hooks/useTranslations';
import { showModal } from 'modules/Modals/actions';
import { ModalTypes } from 'modules/Modals/constants';
import { useOpenModal } from 'modules/Modals/hooks';
import { createNotification } from 'modules/Notifications/actions';
import { NotificationType } from 'modules/Notifications/models';
import { PaymentData } from 'modules/Subscriptions/Create/components/PaymentData/PaymentData';
import PaymentOptions from 'modules/Subscriptions/Create/components/PaymentOptions';
import messages, {
  payment as texts,
  dialogs,
} from 'modules/Subscriptions/Create/messages';
import {
  Coupon,
  Step,
  CreateSubscriptionForm,
} from 'modules/Subscriptions/Create/types';
import { MSG_GENERIC_ERROR } from 'modules/Subscriptions/hooks/useCreateStripeSubscription/constants';
import { useCreateStripeSubscription } from 'modules/Subscriptions/hooks/useCreateStripeSubscription/useCreateStripeSubscription';
import withScrollTop from 'modules/Ui/hocs/withScrollTop';
import NavigationBar from 'modules/Ui/NavigationBar';
import { nifUtils } from 'utils/nif-validations';

import { Plan } from '../models';
import { getPeriodicity } from '../subscription.helpers';
import { CampaingCouponContext } from './components/CampaingCouponContext';

interface Props {
  selectedPlan: Plan;
  onCancelPurchase(): void;
}
// Scroll to top (needed if we arrive here outside the standard navigation, i.e. Subscription not active)
const Create: React.FC<Props> = withScrollTop(
  ({ selectedPlan: originalSelectedPlan, onCancelPurchase }) => {
    const [createSubscriptionInProgress, setCreateSubscriptionInProgress] =
      useState(false);

    const { t } = useTranslations();
    const dispatch = useDispatch();
    const handleSubmit = useCreateStripeSubscription();
    const openModal = useOpenModal();
    const [selectedPlan, setSelectedPlan] = useState(originalSelectedPlan);
    const { productId, marketName, price, duration } = selectedPlan;
    const { step = 0 } = useSelector(selectAppDataState);
    const business = useSelector(selectCurrentBusiness);

    // State
    const [currentStep, setCurrentStep] = useState(step);
    const [subscriptionFormData, setSubscriptionFormData] =
      useState<CreateSubscriptionForm>({
        productId,
        marketName,
        price,
        periodicity: getPeriodicity(duration),
      });
    const [deletedCoupons, setDeletedCoupons] = useState<string[]>([]);

    const goToNextStep = () => {
      analyticsService.checkoutStepCompleted(currentStep);
      setCurrentStep(currentStep + 1);
    };
    const goToPrevStep = () => {
      setCurrentStep(currentStep - 1);
    };

    const createSubscription = async (data?: CreateSubscriptionForm) => {
      if (createSubscriptionInProgress) {
        return;
      }
      setCreateSubscriptionInProgress(true);
      try {
        const billingEmail = data?.billingEmail;
        const { productId: selectedProductId, coupon } = subscriptionFormData;
        await handleSubmit(selectedProductId, billingEmail as string, coupon);
      } catch (error: any) {
        dispatch(
          createNotification({
            type: NotificationType.ERROR,
            message: error.message || t(texts[MSG_GENERIC_ERROR]),
          })
        );
        analyticsService.formError({
          formName: 'payment',
          field: error.stripeError?.code || 'payment',
          error:
            error.stripeError?.message ||
            error.message ||
            t(texts[MSG_GENERIC_ERROR]),
        });
        const stripeCardNumber = document.getElementById('card-number-stripe');
        stripeCardNumber?.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      } finally {
        setCreateSubscriptionInProgress(false);
      }
    };

    // Steps configuration
    const STEPS: Step[] = [
      {
        component: PaymentOptions,
        onCancel: onCancelPurchase,
        onSubmit: (data?: CreateSubscriptionForm, coupon?: Coupon) => {
          const formData = {
            ...{ ...subscriptionFormData, ...data },
            coupon,
          };
          setSubscriptionFormData(formData);
          goToNextStep();
        },
      },
      {
        component: PaymentData,
        onSubmit: async (data?: CreateSubscriptionForm) => {
          if (!nifUtils.validateNif(business.vatNumber)) {
            dispatch(
              showModal({
                type: ModalTypes.COMPLETE_VALID_VAT_NUMBERS,
                business,
                mustShowClientForm: false,
                mustShowBusinessForm: true,
                setContact: () => {},
                onAccept: async () => createSubscription(data),
                context: CompleteValidVatNumbersContext.Subscription,
              })
            );
          } else {
            await createSubscription(data);
          }
        },
        onCancel: () => {
          goToPrevStep();
        },
      },
    ];

    useEffect(() => {
      analyticsService.checkoutStart(selectedPlan, subscriptionFormData.coupon);
    }, []);

    useEffect(() => {
      analyticsService.checkoutStepViewed(currentStep);
    }, [currentStep]);

    const { component: StepComponent, onSubmit, onCancel } = STEPS[currentStep];
    return (
      <CampaingCouponContext.Provider
        value={{ deletedCoupons, setDeletedCoupons }}
      >
        <NavigationBar
          title={t(messages.navigation)}
          onClickBack={() => {
            currentStep ? goToPrevStep() : onCancelPurchase();
          }}
          onClickClose={() => {
            openModal({
              type: ModalTypes.CONFIRMATION_DIALOG,
              title: t(dialogs.title),
              subtitle: t(dialogs.subtitle),
              text: t(dialogs.text),
              cancelText: t(dialogs.cancel),
              confirmText: t(dialogs.confirm),
              onConfirm: (close: () => void) => {
                onCancelPurchase();
                close();
              },
            });
          }}
        />
        <StepComponent
          {...{
            currentStep,
            onSubmit,
            onCancel,
            data: subscriptionFormData,
            setSelectedPlan,
            selectedPlan,
            inProgress: createSubscriptionInProgress,
          }}
        />
      </CampaingCouponContext.Provider>
    );
  }
);

export default Create;
