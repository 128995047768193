import styled from 'modules/Theme/styled-components';
import { Box, Text } from 'modules/Ui';
import Button from 'modules/Ui/Form/Button';
import { ButtonProps } from 'modules/Ui/Form/Button/Button';

import menuLinkStyles from '../../styles/menuLink';

export interface MenuButtonProps extends ButtonProps {
  active?: boolean;
  $style?: 'dark' | 'light';
  text?: string;
}

const StyledButton = styled(Button)<MenuButtonProps>`
  ${(props) => menuLinkStyles(props)}
`;

const MenuButton = (props: MenuButtonProps) => {
  const { active, className, icon, onClick, text } = props;

  return (
    <Box
      backgroundColor="transparent"
      className={className}
      overflow="hidden"
      padding="0 16px 0 8px"
    >
      <StyledButton {...{ active, onClick }} id={`button-${text}`} width="100%">
        <Box tag="span" display="flex" alignItems="center" flex="1">
          {icon}
          <Text color="inherit" marginLeft="16px" tag="span">
            {text}
          </Text>
        </Box>
      </StyledButton>
    </Box>
  );
};

export default MenuButton;
