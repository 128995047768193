import { useCallback } from 'react';

import useElectronicDocumentProvider from 'modules/Business/hooks/useElectronicDocumentProvider';
import { Document } from 'modules/Documents/models/document';

const useBaiVatNumberErrors = (): ((document: Document) => boolean) => {
  const { isGipuzkoa, isAlava, isBizkaia } = useElectronicDocumentProvider();

  return useCallback((document: Document): boolean => {
    const validations = document.digitalDocument?.taxAuthorityValidations;
    if (!validations) return false;

    let baiVatNumberErrors: string[] = [];
    if (isAlava) baiVatNumberErrors = ['015'];
    if (isGipuzkoa) baiVatNumberErrors = ['4104'];
    if (isBizkaia) baiVatNumberErrors = ['B4_2000002'];
    if (baiVatNumberErrors.length === 0) return false;

    const hasNIFValidation = validations.some((validation) =>
      baiVatNumberErrors.includes(validation.code)
    );

    const otherValidations = validations.filter((validation) => {
      return !baiVatNumberErrors.includes(validation.code);
    });

    return hasNIFValidation && otherValidations.length === 0;
  }, []);
};

export default useBaiVatNumberErrors;
