import { defineMessages } from 'react-intl';

export const dataTableMessages = defineMessages({
  selected: { id: 'gofre.Ui.DataTable.selected' },
  selectAllItems: { id: 'gofre.Ui.DataTable.selectAllItems' },
  checkSelectAllItems: { id: 'gofre.Ui.DataTable.checkSelectAllItems' },
  filterItemsDesc: { id: 'gofre.Ui.DataTable.filterDesc' },
  filterItemsAsc: { id: 'gofre.Ui.DataTable.filterAsc' },
  unSelectAllItems: { id: 'gofre.Ui.DataTable.unSelectAllItems' },
  filtersButton: { id: 'gofre.Ui.DataTable.filtersButton' },
  filtersButtonMobile: { id: 'gofre.Ui.DataTable.filtersButton.mobile' },
});
