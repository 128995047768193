import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { getConfig } from 'modules/App/config';
import { E2E_RUNNING } from 'modules/App/constants';
import { selectIsMobile, selectRouter } from 'modules/App/store/selectors';
import { getCurrentUser } from 'modules/Auth/currentUserUtil';
import { selectCurrentBusiness } from 'modules/Business/Current/selectors';
import {
  getSubscription,
  selectHasBasicPlan,
} from 'modules/Subscriptions/detail/selectors';
import useTheme from 'modules/Theme/hooks/useTheme';
import { zIndex } from 'modules/Ui/zIndex';

import Window from '../models';
import getChatToken from '../services/getChatToken';

function useZendeskChat() {
  const { isDummy } = useSelector(selectCurrentBusiness);
  const routerState = useSelector(selectRouter);
  const isMobile = useSelector(selectIsMobile);
  const mobileBlakcListedPathnames = ['/settings/theme'];
  const { pathname } = routerState.location;
  const { active: hasSubscription } = useSelector(getSubscription);
  const hasBasicPlan = useSelector(selectHasBasicPlan);
  const currentUser = getCurrentUser();
  const appendZendeskScript = async () => {
    if (E2E_RUNNING) return;
    const apiKey = (await getConfig()).zendeskApiKey;
    const script = document.createElement('script');
    script.id = 'ze-snippet';
    script.src = `https://static.zdassets.com/ekr/snippet.js?key=${apiKey}&rand=${Date.now()}`;
    script.async = true;
    script.defer = true;
    script.crossOrigin = 'anonymous';
    script.nonce = Number(new Date().getTime()).toString();
    script.onload = () => authenticateUser();
    document.body.append(script);
  };

  const businessId = currentUser?.businessId;

  useEffect(() => {
    if (hasSubscription && !hasBasicPlan) {
      appendZendeskScript();
    }
  }, [hasBasicPlan, hasSubscription]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      const context = window as Window;
      if (context.zE && businessId) {
        context.zE(
          'messenger',
          'loginUser',
          async (callback: (token: string) => void) => {
            const { chatToken } = await getChatToken();
            callback(chatToken);
          }
        );
      }
    }, 3000);
    return () => clearTimeout(timeoutId);
  }, [businessId]);

  useEffect(() => {
    const context = window as Window;
    if (context.zE) {
      if (
        hasBasicPlan ||
        isDummy ||
        (mobileBlakcListedPathnames.includes(pathname) && isMobile)
      ) {
        context.zE('messenger', 'hide');
      } else {
        context.zE('messenger', 'show');
      }
    }
  }, [hasBasicPlan, isDummy, pathname, isMobile]);

  const theme = useTheme();

  const authenticateUser = () => {
    (window as Window).zESettings = {
      webWidget: {
        offset: {
          mobile: {
            horizontal: '-4px', // it has margin of 20px and we want to position it at 16px
            vertical: '68px',
          },
        },
        zIndex: zIndex.CHAT,
        color: {
          theme: `${theme.colors.brand500}`,
          resultLists: `${theme.colors.brand500}`,
        },
      },
    };
  };
}
export default useZendeskChat;
