import { FC } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import useTranslations from 'modules/I18n/hooks/useTranslations';
import { Dialog, useCloseModal } from 'modules/Modals';
import { selectHasUnlimitedPlan } from 'modules/Subscriptions/detail/selectors';
import { ROUTES } from 'modules/Subscriptions/routesMap';
import { Box, Button, Link, SupportContactInfo, Text } from 'modules/Ui';
import isNativeApp from 'utils/native/isNativeApp';

import { scanModals } from '../messages';

const ScanLimitModal: FC = () => {
  const history = useHistory();
  const hasUnlimitedPlan = useSelector(selectHasUnlimitedPlan);
  const { t } = useTranslations();

  const closeModal = useCloseModal();

  const showNativeVersion = isNativeApp();

  let text = t(scanModals.limitText);
  if (showNativeVersion) {
    text = t(scanModals.limitTextNative);
  }
  if (hasUnlimitedPlan) {
    if (showNativeVersion) {
      text = t(scanModals.limitTextUnlimitedNative, {
        email: (chunks) => (
          <Link
            isExternal
            to={`mailto:${t(scanModals.mailTo)}`}
            fontSize="inherit"
            lineHeight="inherit"
          >
            {chunks}
          </Link>
        ),
        phone: (chunks) => (
          <Link
            isExternal
            to={`tel:${t(scanModals.phoneNumber)}`}
            fontSize="inherit"
            lineHeight="inherit"
          >
            {chunks}
          </Link>
        ),
      });
    } else {
      text = t(scanModals.limitTextUnlimited);
    }
  }
  const cta =
    showNativeVersion || hasUnlimitedPlan
      ? t(scanModals.limitCtaSecondary)
      : t(scanModals.limitCta);

  const showOneButton = showNativeVersion || hasUnlimitedPlan;

  const ctaButton = (
    <Button
      marginBottom={{ _: '16px', sm: 'initial' }}
      onClick={() => {
        closeModal();
        if (!(showNativeVersion || hasUnlimitedPlan)) {
          history.push(ROUTES.SUBSCRIPTIONS_PLANS);
        }
      }}
      variant="secondary"
    >
      {cta}
    </Button>
  );

  return (
    <Dialog
      id="scan-limit-modal"
      title={t(scanModals.limitTitle)}
      underlayMarginTop="10px"
      buttonPrimary={
        showOneButton ? (
          ctaButton
        ) : (
          <Button
            marginBottom={{ _: '16px', sm: 'initial' }}
            onClick={() => {
              closeModal();
            }}
            variant="stroke"
          >
            {t(scanModals.limitCancel)}
          </Button>
        )
      }
      buttonSecondary={showOneButton ? null : ctaButton}
      onClose={closeModal}
    >
      <Box
        display="flex"
        flexDirection="column"
        flexWrap="wrap"
        alignItems="center"
      >
        <Text width="100%" color="gray800" textSize="s" display="block">
          {text}
        </Text>
        {hasUnlimitedPlan && <SupportContactInfo />}
      </Box>
    </Dialog>
  );
};

export default ScanLimitModal;
