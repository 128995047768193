import { FC, ReactNode } from 'react';

import accesibleHide from 'modules/Theme/mixins/accessibleHidden';
import styled from 'modules/Theme/styled-components';
import Box from 'modules/Ui/Box';

export interface LabelProps {
  info?: ReactNode;
  id: string;
  label: string;
}

const StyledLabel = styled(Box)`
  ${accesibleHide}
`;

const LabelHide: FC<LabelProps> = ({ info, id, label }) => {
  return (
    <>
      {info ? (
        <StyledLabel tag="label" htmlFor={id}>
          {label}
        </StyledLabel>
      ) : (
        <></>
      )}
    </>
  );
};

export default LabelHide;
