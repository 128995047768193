import { TranslationFunction } from 'modules/I18n/hooks/useTranslations';
import { TemplateLanguages } from 'modules/I18n/types/templateLanguages';

import messages from './messages';

export const baiLanguages = (t: TranslationFunction) => [
  {
    value: TemplateLanguages.EUES,
    label: t(messages[TemplateLanguages.EUES]),
  },
  {
    value: TemplateLanguages.CA,
    label: t(messages[TemplateLanguages.CA]),
  },
  {
    value: TemplateLanguages.EU,
    label: t(messages[TemplateLanguages.EU]),
  },
  {
    value: TemplateLanguages.ES,
    label: t(messages[TemplateLanguages.ES]),
  },
  {
    value: TemplateLanguages.EN,
    label: t(messages[TemplateLanguages.EN]),
  },
];

export const noBaiLanguages = (t: TranslationFunction) => [
  {
    value: TemplateLanguages.ES,
    label: t(messages[TemplateLanguages.ES]),
  },
  {
    value: TemplateLanguages.CA,
    label: t(messages[TemplateLanguages.CA]),
  },
  {
    value: TemplateLanguages.EN,
    label: t(messages[TemplateLanguages.EN]),
  },
  {
    value: TemplateLanguages.EU,
    label: t(messages[TemplateLanguages.EU]),
  },
  {
    value: TemplateLanguages.EUES,
    label: t(messages[TemplateLanguages.EUES]),
  },
];
