import IconByPath, { IconByPathProps } from './Icon/IconType/IconByPath';

const IconPlus = (props: IconByPathProps) => (
  <IconByPath
    {...props}
    path="M12.55 7.6h-1.1v3.85H7.6v1.1h3.85v3.85h1.1v-3.85h3.85v-1.1h-3.85V7.6Z"
  />
);

export default IconPlus;
