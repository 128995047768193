import { FC } from 'react';

import { space, SpaceProps } from 'styled-system';

import useTheme from 'modules/Theme/hooks/useTheme';
import styled, { css } from 'modules/Theme/styled-components';
import { Box } from 'modules/Ui';

import HtmlProgress from '../../Html/Progress';
import progressBarReset from '../mixim/progressBarReset';

interface DividedProgressBarProps extends SpaceProps {
  maxValue: number;
  value: number;
  valueColor?: string;
}

const divider = css`
  background-color: ${(props) => props.theme.colors.gray0};
  content: '';
  height: 2px;
  width: 4px;
  position: absolute;
  top: 0;
`;

const firstStep = css<DividedProgressBarProps>`
  &:before {
    ${divider}
    left: ${({ maxValue }) => (maxValue === 3 ? '33%' : '50%')}
  }
`;

const secondStep = css`
  &:after {
    ${divider}
    left: 66%
  }
`;

const progressThreeSteps = css`
  ${firstStep}
  ${secondStep}
`;

const StyledProgressBar = styled(HtmlProgress)<DividedProgressBarProps>``;
const StyledBarWrapper = styled(Box)<DividedProgressBarProps>`
${({ maxValue }) => (maxValue === 3 ? progressThreeSteps : firstStep)}
${StyledProgressBar} {
  ${({ valueColor }) => valueColor && progressBarReset(valueColor, '2px')};
    &::-webkit-progress-bar {
      background-color: ${(props) => props.theme.colors.primary100};
    }
  ${space}
  }
`;

const DividedProgressBar: FC<DividedProgressBarProps> = ({
  maxValue,
  value,
  valueColor,
  ...rest
}) => {
  const theme = useTheme();
  return (
    <StyledBarWrapper
      display="block"
      height="2px"
      maxValue={maxValue}
      overflow="hidden"
      position="relative"
      valueColor={valueColor}
      {...rest}
    >
      <StyledProgressBar
        border="none"
        display="block"
        max={maxValue}
        value={value}
        valueColor={valueColor ?? `${theme.colors.brand500}`}
      />
    </StyledBarWrapper>
  );
};

export default DividedProgressBar;
