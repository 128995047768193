import { FC } from 'react';
import { Hidden } from 'react-grid-system';

import useTranslations from 'modules/I18n/hooks/useTranslations';
import { ShopConfig } from 'modules/Integrations/models';
import { Box, Text } from 'modules/Ui';

import { shopifyForm } from '../../messages';
import ShopifyIntegrationModule from './ShopifyIntegrationModule';
import ShopifyIntegrationSteps from './ShopifyIntegrationSteps';

interface Props {
  data?: ShopConfig;
}

const ShopifyIntegrationAside: FC<Props> = ({ data }) => {
  const { t } = useTranslations();
  return (
    <Box
      backgroundColor="auxiliary100A30"
      isActive={data?.isActive}
      marginBottom={{ sm: '16px', md: 'initial' }}
      padding={{
        _: '32px 24px 32px',
        sm: '20px 16px 8px',
        md: '32px 24px 20px',
      }}
    >
      <Text
        color="primary500"
        fontSize={18}
        fontWeight="600"
        lineHeight={22}
        marginBottom={{ _: '16px', sm: '8px', md: '16px' }}
        tag="h3"
      >
        {data?.isActive
          ? t(shopifyForm.ShopifyIntegrationTitleActive)
          : t(shopifyForm.ShopifyIntegrationTitleNoActive)}
      </Text>
      <Hidden xs>
        <Text fontSize={14} lineHeight={18} marginBottom="16px">
          {data?.isActive
            ? t(shopifyForm.ShopifyIntegrationInfoActive)
            : t(shopifyForm.ShopifyIntegrationInfoNoActive)}
        </Text>
      </Hidden>
      <ShopifyIntegrationModule />
      <ShopifyIntegrationSteps />
    </Box>
  );
};

export default ShopifyIntegrationAside;
