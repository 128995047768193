import React from 'react';

import SHA256 from 'crypto-js/sha256';
import { isNil } from 'lodash';
import { space, SpaceProps } from 'styled-system';

import styled from 'modules/Theme/styled-components';

import Box from '../Box';
import Text from '../Text';

const colors = [
  '#12209A',
  '#99CCFF',
  '#FFC12B',
  '#FF3A00',
  '#01C67A',
  '#FF9900',
  '#6599FF',
  '#523CBA',
  '#065B56',
  '#40CEBE',
  '#AB5C01',
  '#B90E79',
  '#1746D0',
  '#FF6B00',
  '#D2A167',
  '#C82200',
  '#8C90A2',
  '#414F62',
];

interface Props extends SpaceProps {
  nameFontSize?: number;
  nameLineHeight?: number;
  index?: number;
  name: string;
  size?: number;
}

const StyledAvatar = styled(Box)`
  ${space}
`;

const Avatar: React.FC<Props> = ({
  name,
  nameFontSize,
  nameLineHeight,
  index,
  size,
  ...rest
}) => {
  const color = React.useMemo(() => {
    const idx = isNil(index) ? parseInt(SHA256(name).toString(), 16) : index;
    return idx % colors.length;
  }, [name, index]);

  const short = React.useMemo(() => {
    if (!name) return '';
    const initials = name.split(' ').map((word) => word[0]);
    return ''.concat(initials.shift() || '', initials.pop() || '');
  }, [name]).toUpperCase();

  return (
    <StyledAvatar
      alignItems="center"
      backgroundColor={colors[color]}
      borderRadius="50%"
      display="flex"
      height={size || 40}
      justifyContent="center"
      tag="span"
      width={size || 40}
      {...{ ...rest }}
    >
      <Text
        color="gray0"
        fontSize={nameFontSize || 14}
        lineHeight={nameLineHeight || 16}
        fontWeight="600"
        tag="span"
      >
        {short.length ? `${short}` : '--'}
      </Text>
    </StyledAvatar>
  );
};

StyledAvatar.defaultProps = {
  margin: { sm: '4px 0' },
};

export default Avatar;
