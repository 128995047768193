declare global {
  interface Window {
    ReactNativeWebView: { postMessage: (url: string) => void };
  }
}

export default function isNativeApp() {
  return !!sessionStorage.getItem('isNativeApp');
}

type Device = {
  token?: string;
  systemName: string;
  manufacturer: string;
  device: string;
  uniqueId: string;
  deviceId: string;
  deviceName: string;
  fingerprint: string;
};

export function getDeviceInfo() {
  const raw = sessionStorage.getItem('isNativeApp');
  if (!raw) return null;

  try {
    const device = JSON.parse(raw);
    return (device as Device) || null;
  } catch (error) {
    return null;
  }
}

export function getNativeAppVersion() {
  return sessionStorage.getItem('nativeAppVersion');
}
