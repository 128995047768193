import { FC } from 'react';

import useCountries from 'modules/Address/hooks/useCountries';
import { SelectProps } from 'modules/Ui/Form/SelectCommon/types';

import SelectFormikField from './SelectFormikField';

interface SelectCountryProps extends Omit<SelectProps, 'options'> {
  enabled?: boolean;
  onChange?: (country: string) => void;
}

const CountryFormikField: FC<SelectCountryProps> = ({
  id,
  label,
  name,
  placeholder,
  enabled,
  onChange,
  ...rest
}) => {
  const { countries } = useCountries();

  const options =
    countries?.map((country) => ({
      value: country.value,
      label: country.name,
    })) || [];

  return (
    <SelectFormikField
      isDisabled={!enabled}
      isSearchable
      {...{
        id,
        label,
        name,
        placeholder,
        ...rest,
        onChange,
        options,
      }}
    />
  );
};

export default CountryFormikField;
