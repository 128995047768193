export enum AutosaveKey {
  DOCUMENTS_CREATE = 'DOCUMENTS_CREATE',
  RECURRENTS_CREATE = 'RECURRENTS_CREATE',
  QUOTE_CREATE = 'QUOTE_CREATE',
  PROFORMA_CREATE = 'PROFORMA_CREATE',
  RECEIPT_CREATE = 'RECEIPT_CREATE',
  DELIVERY_NOTE_CREATE = 'DELIVERY_NOTE_CREATE',
  UPLOAD_DOCUMENT = 'UPLOAD_DOCUMENT',
  UPLOAD_EXPENSE = 'UPLOAD_EXPENSE',
  CREATE_RECURRENT_EXPENSE = 'CREATE_RECURRENT_EXPENSE',
}
