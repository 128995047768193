import { FC } from 'react';
import { Hidden } from 'react-grid-system';

import styled from 'styled-components';
import {
  flexbox,
  FlexboxProps,
  grid,
  GridProps,
  layout,
  LayoutProps,
  system,
} from 'styled-system';

import useTranslations from 'modules/I18n/hooks/useTranslations';
import { Box, Button } from 'modules/Ui';

import { filter } from '../../../messages';

interface FiltersFooterProps extends GridProps, FlexboxProps, LayoutProps {
  gridColumnStart?: {} | string;
  gridColumnEnd?: {} | string;
  buttonWidth?: {} | string;
}

const StyledButton = styled(Button)`
  ${layout}
`;

const StyledSubmit = styled(Box)`
    ${grid}
    ${flexbox}
    ${system({
      gridColumnStart: {
        property: 'gridColumnStart',
        transform: (value) => `${value}`,
      },
      gridColumnEnd: {
        property: 'gridColumnEnd',
        transform: (value) => `${value}`,
      },
    })}
`;

const FiltersFooter: FC<FiltersFooterProps> = ({ buttonWidth, ...rest }) => {
  const { t } = useTranslations();
  return (
    <Hidden xs>
      <StyledSubmit gridColumnStart {...rest}>
        <StyledButton
          data-testid="filter-submit"
          type="submit"
          variant="primary"
          width={buttonWidth || '100%'}
        >
          {t(filter.button)}
        </StyledButton>
      </StyledSubmit>
    </Hidden>
  );
};

export default FiltersFooter;
