import { useSelector } from 'react-redux';

import { UserPermissions } from 'modules/Auth/constants';
import useHasUserScope from 'modules/Auth/hooks/checkUsersPermissions';
import useIsBookkeeperUser, {
  useIsBookkeeperLoggedInClient,
} from 'modules/Bookkeeper/hooks/useIsBookkeeperUser';
import {
  selectCurrentBusiness,
  selectHasBookkeeperUsers,
} from 'modules/Business/Current/selectors';
import {
  selectHasExternalPlan,
  selectHasSubscription,
} from 'modules/Subscriptions/detail/selectors';
import useIsReader from 'modules/Users/hooks/useIsReader';
import isNativeApp from 'utils/native/isNativeApp';

export const useShouldRenderSection = () => {
  const { isDummy } = useSelector(selectCurrentBusiness);
  const { hasUserScope } = useHasUserScope();
  const hasSubscription = useSelector(selectHasSubscription);
  const isBookkeeper = useIsBookkeeperUser();
  const isUserAdmin = hasUserScope(UserPermissions.SUBSCRIPTION_ADMIN);
  const isBookkeeperLoggedInClient = useIsBookkeeperLoggedInClient();
  const hasBookkeeperUsers = useSelector(selectHasBookkeeperUsers);
  const isSubscriptionExternal = useSelector(selectHasExternalPlan);

  const isUserReader = useIsReader();

  const shouldRenderBusinessSection =
    !isBookkeeperLoggedInClient && !isBookkeeper;
  const shouldRenderSettingsSection = isUserAdmin && !isBookkeeper;
  const shouldRenderMyBookkeeperSection =
    !hasBookkeeperUsers && !isBookkeeper && !isUserReader;

  const shouldRenderPlansSection =
    !isNativeApp() &&
    isUserAdmin &&
    !isDummy &&
    !isBookkeeper &&
    !isSubscriptionExternal;

  const shouldRenderSubscriptionsSection =
    (isSubscriptionExternal || hasSubscription) &&
    !isNativeApp() &&
    !isBookkeeperLoggedInClient &&
    !isBookkeeper &&
    !isUserReader;

  return {
    shouldRenderMyBookkeeperSection,
    shouldRenderPlansSection,
    shouldRenderSettingsSection,
    shouldRenderBusinessSection,
    shouldRenderSubscriptionsSection,
  };
};
