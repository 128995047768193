import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { Formik } from 'formik';
import { ResponsiveValue } from 'styled-system';

import { hasElectronicDocumentProvider } from 'modules/Business/businessUtils';
import { selectCurrentBusiness } from 'modules/Business/Current/selectors';
import useElectronicDocumentProvider from 'modules/Business/hooks/useElectronicDocumentProvider';
import {
  DigitalDocumentStatus,
  DocumentFilters,
  DocumentKind,
  DocumentType,
  DueDate,
} from 'modules/Documents/models/document';
import { StatusInfoUI } from 'modules/Documents/models/statusInfo';
import useTranslations from 'modules/I18n/hooks/useTranslations';
import { SelectFormikField } from 'modules/Ui/Formik';
import ErrorListener from 'modules/Ui/Formik/ErrorListener';

import { filter } from '../../../messages';
import AmountFilters from './components/AmountFilters';
import DayPickerFilters from './components/DayPickerFilters';
import FilterWrapper from './components/FilterWrapper';
import FiltersFooter from './FiltersFooter';
import getValidationSchema from './validation';

interface FormProps extends DocumentFilters {
  onSubmit: (filters: DocumentFilters) => void;
  onCancel: () => void;
  documentType: DocumentType;
  currentTab?: DocumentKind;
  disableStatus?: boolean;
}

const DocumentsFilterForm: React.FC<FormProps> = ({
  dueDate,
  issuedDateFrom,
  issuedDateTo,
  minAmount,
  maxAmount,
  onSubmit,
  statusInfo,
  currentTab = '',
  digitalDocumentStatus,
  updatedDateTo,
  updatedDateFrom,
  documentType,
  disableStatus = false,
}) => {
  const history = useHistory();

  const isRemittanceDocumentList =
    history.location.pathname.includes('remittances');

  const business = useSelector(selectCurrentBusiness);
  const isBai = hasElectronicDocumentProvider(business);
  const { t } = useTranslations();
  const { canSendElectronicDocument } = useElectronicDocumentProvider();
  const isDocumentNormal = currentTab === DocumentKind.NORMAL;
  const isDraft = currentTab === DocumentKind.DRAFT;
  const showDigitalDocumentStatusFilter =
    canSendElectronicDocument && isDocumentNormal;
  const initialValues = {
    statusInfo,
    issuedDateFrom,
    issuedDateTo,
    dueDate,
    digitalDocumentStatus,
    minAmount,
    maxAmount,
    updatedDateTo,
    updatedDateFrom,
  };

  const documentStatusOptions = useMemo(() => {
    let options: { value: string; label: string }[] = [];

    if (documentType === DocumentType.EXPENSE) {
      options = [
        { value: StatusInfoUI.PAID, label: t(filter.statusPaidExpense) },
        {
          value: StatusInfoUI.PARTIALLY_PAID,
          label: t(filter.statusPartiallyPaidExpense),
        },
        { value: StatusInfoUI.NOT_PAID, label: t(filter.statusNoPaidExpense) },
        { value: StatusInfoUI.ISSUED, label: t(filter.statusIssuedExpense) },
      ];
    }
    if (documentType === DocumentType.INVOICE) {
      options = [
        { value: StatusInfoUI.PAID, label: t(filter.statusPaid) },
        {
          value: StatusInfoUI.PARTIALLY_PAID,
          label: t(filter.statusPartiallyPaid),
        },
        { value: StatusInfoUI.NOT_PAID, label: t(filter.statusNoPaid) },
        { value: StatusInfoUI.ISSUED, label: t(filter.statusIssued) },
      ];
      options.push({ value: StatusInfoUI.DUE, label: t(filter.statusDue) });
      options.push({
        value: StatusInfoUI.REPLACED,
        label: t(filter.statusReplaced),
      });

      if (isBai) {
        options.push({
          value: StatusInfoUI.CORRECTIVE,
          label: t(filter.statusCorrective),
        });
        options.push({
          value: StatusInfoUI.CORRECTED,
          label: t(filter.statusCorrected),
        });
      }
      if (canSendElectronicDocument) {
        options.push({
          value: StatusInfoUI.VOID,
          label: t(filter.statusVoid),
        });
      }
    }

    if (isRemittanceDocumentList) {
      return options.filter((option) => {
        return (
          option.value !== StatusInfoUI.PAID &&
          option.value !== StatusInfoUI.VOID &&
          option.value !== StatusInfoUI.CORRECTED
        );
      });
    }

    return options;
  }, [t, documentType]);

  const dynamicStyles = useMemo(() => {
    let dayPickerColumn: ResponsiveValue<string> = {
      sm: '1 / 3',
    };
    let columnsLayout: ResponsiveValue<string> = {
      sm: 'repeat(3, 1fr)',
      md: 'repeat(5, 1fr)',
    };

    let footerColumn: ResponsiveValue<string> = {
      sm: '3 / 4',
      md: '5 / 6',
    };

    if (isDocumentNormal) {
      columnsLayout = {
        sm: 'repeat(2, 1fr)',
        md: 'repeat(3, 1fr)',
        lg: 'repeat(4, 1fr)',
      };
      footerColumn = {
        sm: '2 / 3',
        md: '3 / 4',
        lg: '4 / 5',
      };
      dayPickerColumn = {
        lg: '1 / 3',
      };
      if (isBai) {
        columnsLayout.lg = 'repeat(5, 1fr)';
        footerColumn.lg = '5 / 6';
      }
    }

    if (documentType === DocumentType.EXPENSE) {
      columnsLayout = {
        sm: 'repeat(3, 1fr)',
        md: 'repeat(4, 1fr)',
        lg: '1fr 1fr 341px 341px',
      };

      footerColumn = {
        sm: '3 / 4',
        md: '4 / 5',
      };
    }
    return {
      columnsLayout,
      footerColumn,
      dayPickerColumn,
    };
  }, [t, currentTab, documentType, isBai]);

  const digitalDocumentStatuses = useMemo(() => {
    return [
      {
        value: DigitalDocumentStatus.PRESENTED,
        label: t(filter.digitalDocumentStatusPresented),
      },
      {
        value: DigitalDocumentStatus.PRESENTED_WITH_ERRORS,
        label: t(filter.digitalDocumentStatusPresentedWithErrors),
      },
      {
        value: DigitalDocumentStatus.IN_PROGRESS,
        label: t(filter.digitalDocumentStatusInProgress),
      },
      {
        value: DigitalDocumentStatus.REJECTED,
        label: t(filter.digitalDocumentStatusRejected),
      },
    ];
  }, [t]);

  const dueDateOptions = useMemo(
    () => [
      { value: DueDate.TODAY, label: t(filter.dueDateOptionToday) },
      { value: DueDate.THIS_WEEK, label: t(filter.dueDateOptionThisWeek) },
      { value: DueDate.THIS_MONTH, label: t(filter.dueDateOptionThisMonth) },
      { value: DueDate.EXPIRED, label: t(filter.dueDateOptionExpired) },
    ],
    [t]
  );

  return (
    <Formik
      onSubmit={(values, actions) => {
        onSubmit(values);
        actions.setSubmitting(false);
      }}
      validationSchema={getValidationSchema(t, { isRemittanceDocumentList })}
      initialValues={initialValues}
      enableReinitialize
      validateOnChange={false}
      validateOnBlur={false}
    >
      {(formik) => (
        <form
          autoComplete="off"
          id="filter-form"
          noValidate
          onSubmit={formik.handleSubmit}
        >
          <ErrorListener />
          <FilterWrapper
            gridTemplateColumns={dynamicStyles.columnsLayout}
            id="documentFilters"
          >
            <DayPickerFilters
              gridColumn={dynamicStyles.dayPickerColumn}
              id={`${isDraft ? 'updatedDate' : 'issuedDate'}`}
            />
            <SelectFormikField
              id="dueDate"
              label={t(filter.dueDateLabel)}
              options={dueDateOptions}
              placeholder={t(filter.dueDateLabelPlaceholder)}
            />
            {!disableStatus && (
              <SelectFormikField
                id="statusInfo"
                data-testid="status-select"
                label={t(filter.documentStatus)}
                placeholder={t(filter.documentStatusPlaceholder)}
                options={documentStatusOptions}
                value={initialValues.statusInfo}
              />
            )}
            {showDigitalDocumentStatusFilter && (
              <SelectFormikField
                id="digitalDocumentStatus"
                data-testid="digital-document-status-select"
                label={t(filter.digitalDocumentStatus)}
                placeholder={t(filter.digitalDocumentStatusPlaceholder)}
                options={digitalDocumentStatuses}
                value={initialValues.digitalDocumentStatus}
              />
            )}
            <AmountFilters />
            <FiltersFooter
              alignSelf="center"
              gridColumn={dynamicStyles.footerColumn}
            />
          </FilterWrapper>
        </form>
      )}
    </Formik>
  );
};

export default DocumentsFilterForm;
