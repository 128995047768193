import { getConfig } from 'modules/App/config';
import { requestBusinessToken } from 'modules/Auth/services/authBusiness';
import request from 'utils/request';

import { DefaultValuesResource } from '../enums';
import { DefaultValuesOptions } from '../models/default-values-options';

const fetchDefaultValues = async <T>(
  resource: DefaultValuesResource,
  options?: DefaultValuesOptions
): Promise<T | undefined> => {
  const config = await getConfig();
  const endpoint = config.apiDefaultValuesService;
  const token = options?.businessId
    ? await requestBusinessToken(options.businessId)
    : undefined;

  try {
    const response = await request<any>(
      `${endpoint}/${resource}`,
      {
        method: 'GET',
      },
      {
        token,
        requireAuth: true,
        retries: 2,
      }
    );
    return {
      ...response.data.data.data,
    };
  } catch (err) {
    return undefined;
  }
};

export default fetchDefaultValues;
