import fadein from 'modules/Theme/mixins/fadeIn';
import { css } from 'modules/Theme/styled-components';
import { zIndex } from 'modules/Ui/zIndex';

const backdrop = (menuWidth: string) => css`
  animation: ${fadein} 0.5s ease-in;
  background-color: ${(props) => props.theme.colors.backdropDark};
  contain: content;
  content: "";
  display: block;
  height: 10vh;
  pointer-events: none;
  position: absolute;
  transform-origin: left top;
  transform: translateX(${menuWidth}) scale(10);
  width: 10vw;
  will-change: opacity, transform;
  z-index: ${zIndex.MENU_BACKDROP};
`;

export default backdrop;
