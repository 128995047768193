import { isNil } from 'lodash';

import { getAuthData } from 'modules/Auth/request-auth-data';

function useIsTenant() {
  const { tenant } = getAuthData();
  return !isNil(tenant);
}

export default useIsTenant;
