import IconByString, { IconByStringProps } from './Icon/IconType/IconByString';

const IconCreateInvoice = (props: IconByStringProps) => (
  <IconByString {...props}>
    <path d="M20 23V1H4v22h16ZM5 2h14v20H5V2Z" stroke="currentColor" />
    <path
      d="M9 8a3 3 0 1 0 6 0 3 3 0 0 0-6 0Zm3-4a4 4 0 1 0 0 8 4 4 0 0 0 0-8Z"
      fill="currentColor"
      fillRule="evenodd"
      shapeRendering="geometricPrecision"
    />
    <path d="M8 15h8v1H8v-1ZM8 19h8v1H8v-1Z" stroke="currentColor" />
  </IconByString>
);

export default IconCreateInvoice;
