import { getConfig } from 'modules/App/config';
import request from 'utils/request';

import { Subscription } from '../../models';

interface Params {
  expand?: boolean;
}
const fetchSubscription = async ({
  expand,
}: Params = {}): Promise<Subscription> => {
  const endpoint = (await getConfig()).apiSubscriptionsService;
  const fullEndpoint = expand
    ? `${endpoint}/me?expanded=true`
    : `${endpoint}/me`;
  const response = await request<any>(fullEndpoint, {
    method: 'GET',
  });
  return response.data.data;
};
export default fetchSubscription;
