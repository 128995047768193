import { FC, useEffect, useRef, CSSProperties } from 'react';
import AriaModal from 'react-aria-modal';
import { useSelector } from 'react-redux';

import {
  LayoutProps,
  SpaceProps,
  BorderProps,
  FlexboxProps,
} from 'styled-system';

import analyticsService from 'modules/Analytics/services/analyticsService';
import { selectIsMobile } from 'modules/App/store/selectors';
import useOuterClick from 'modules/Modals/hooks/useOuterClick';
import { Box } from 'modules/Ui';
import { zIndex } from 'modules/Ui/zIndex';

import { MODAL_WIDTH_MEDIUM, MODAL_WIDTH_SMALL } from '../models';
import { DialogBaseProps } from '../models/types';
import { DialogDefault } from './DialogDefault';
import { DialogEmpty } from './DialogEmpty';
import { DialogFullScreen } from './DialogFullScreen';

export interface DialogProps
  extends DialogBaseProps,
    LayoutProps,
    SpaceProps,
    BorderProps,
    FlexboxProps {}

const getApplicationNode = (): Element =>
  document.getElementById('root') || document.createElement('div');

export const Dialog: FC<DialogProps> = ({
  buttonPrimary,
  buttonSecondary,
  children,
  content,
  dialogWidth,
  withGradient = true,
  height,
  id = 'default-dialog',
  backdropIsScrollable = true,
  mobileBorderHeader,
  onClose,
  subtitle,
  title,
  type = 'default',
  underlayMarginTop,
  multipleActionsInMobile = false,
  ...rest
}) => {
  const ref = useRef<HTMLDivElement>(null);
  useOuterClick(ref, handleClose);

  function handleClose() {
    analyticsService.page();
    onClose && onClose();
  }

  useEffect(() => {
    analyticsService.page({ name: id });
  }, []);

  const isMobile = useSelector(selectIsMobile);

  const fullScreenStyles: CSSProperties = {
    alignItems: 'center',
    boxSizing: !isMobile ? 'border-box' : undefined,
    display: isMobile ? undefined : 'flex',
    flexDirection: isMobile ? undefined : 'column',
    height: '100%',
    minHeight: isMobile ? undefined : '100vh',
    overflowY: backdropIsScrollable && !isMobile ? 'auto' : undefined,
    paddingBottom: !isMobile ? '40px' : undefined,
    width: '100vw',
  };

  const defaultStyles: CSSProperties = {
    alignItems: 'center',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    paddingBottom: '40px',
  };

  return (
    <AriaModal
      aria-labelledby={`title-${id}`}
      data-testid={id}
      dialogId={id}
      dialogStyle={{
        ...(type === 'fullScreen' && fullScreenStyles),
        ...(type === 'default' && defaultStyles),
      }}
      focusDialog
      initialFocus="dialog-wrapper"
      getApplicationNode={getApplicationNode}
      includeDefaultStyles={false}
      onExit={handleClose}
      // This is a dirty lie.  If the content of the dialog exceeds the height of the scroll, the scroll returns!!!. It doesn't matter if you force a maximum height
      scrollDisabled
      titleId={id}
      underlayClass="dialog"
      underlayStyle={{
        zIndex: zIndex.MODALS,
      }}
      {...{ rest }}
    >
      <>
        {type === 'fullScreen' && (
          <Box
            boxSizing="border-box"
            fadeIn
            height={{ _: '100%', sm: 'auto' }}
            id="dialog-wrapper"
            marginTop={{
              sm: underlayMarginTop || '66px',
              md: underlayMarginTop || '112px',
              lg: underlayMarginTop || '142px',
            }}
            maxHeight={{ _: '100%', sm: 'initial' }}
            position={{ sm: 'relative' }}
            tabIndex="0"
            width={
              dialogWidth || {
                _: '100vw',
                sm: 'calc(100% - 72px)',
                md: MODAL_WIDTH_MEDIUM,
              }
            }
            ref={ref}
          >
            <DialogFullScreen
              buttonPrimary={buttonPrimary}
              buttonSecondary={buttonSecondary}
              children={children}
              content={content}
              id={id}
              mobileBorderHeader={mobileBorderHeader}
              multipleActionsInMobile={multipleActionsInMobile}
              onClose={handleClose}
              subtitle={subtitle}
              title={title}
              type={type}
              withGradient={withGradient}
            />
          </Box>
        )}
        {type === 'default' && (
          <Box
            id="dialog-wrapper"
            fadeIn
            marginTop={{
              _: '42px',
              sm: underlayMarginTop || '66px',
              md: underlayMarginTop || '112px',
              lg: underlayMarginTop || '142px',
            }}
            position="relative"
            ref={ref}
            tabIndex="0"
          >
            <DialogDefault
              buttonPrimary={buttonPrimary}
              buttonSecondary={buttonSecondary}
              children={children}
              content={content}
              dialogWidth={dialogWidth}
              id={id}
              onClose={handleClose}
              subtitle={subtitle}
              title={title}
              type={type}
              underlayMarginTop={underlayMarginTop}
            />
          </Box>
        )}
        {type === 'empty' && (
          <Box
            fadeIn
            id="dialog-wrapper"
            margin={{ _: '42px auto 0', sm: '142px auto 0' }}
            position="relative"
            tabIndex="0"
            width={
              dialogWidth || { _: 'calc(100vw - 16px)', sm: MODAL_WIDTH_SMALL }
            }
          >
            <DialogEmpty
              children={children}
              dialogWidth={dialogWidth}
              onClose={onClose}
              type={type}
            />
          </Box>
        )}
      </>
    </AriaModal>
  );
};
