import { css } from 'modules/Theme/styled-components';

const dialogPosition = () => css`
  height: 100%;
  left: 0px;
  position: fixed;
  top: 0px;
  width: 100%;
`;

export default dialogPosition;
