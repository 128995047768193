import { FC } from 'react';

import { useInvalidateAndUpdate } from 'modules/App/hooks/useInvalidateAndUpdate';
import useTranslations from 'modules/I18n/hooks/useTranslations';
import { useCloseModal, DialogConfirm } from 'modules/Modals';
import useNotifications from 'modules/Notifications/hooks/useNotifications';
import changeRemittanceStatus, {
  invalidateQueries,
} from 'modules/Remittances/services/changeRemittanceStatus';
import { RemittanceStatus } from 'modules/Remittances/types';
import { useBlockingMutation } from 'utils/useBlockingMutation';

import {
  markAsCompletedRemittanceModal,
  markAsPendingRemittanceModal,
} from '../../messages';
import { SetRemittanceStatusModalProps } from './types';

const SetRemittanceStatusModal: FC<SetRemittanceStatusModalProps> = ({
  id,
  status,
  onSuccess,
}) => {
  const { t } = useTranslations();
  const notifications = useNotifications();
  const closeModal = useCloseModal();
  const invalidateAndUpdate = useInvalidateAndUpdate();

  const dialog =
    status === RemittanceStatus.COMPLETED
      ? markAsCompletedRemittanceModal
      : markAsPendingRemittanceModal;

  const { mutate } = useBlockingMutation(changeRemittanceStatus, {
    onSuccess: () => {
      closeModal();
      invalidateAndUpdate({ invalidateQueries });
      notifications.success(dialog.notificationDone.id);
      onSuccess?.();
    },
    onError: () => {
      notifications.error(dialog.error.id);
    },
  });

  const handleSetStatus = () => {
    mutate({
      id,
      status,
    });
  };

  return (
    <DialogConfirm
      cancelText={t(dialog.cancel)}
      onConfirm={handleSetStatus}
      title={t(dialog.title)}
      text={t(dialog.text)}
      confirmText={t(dialog.submit)}
    />
  );
};

export default SetRemittanceStatusModal;
