import { MessageDescriptor } from 'react-intl';

import { SpaceProps, LayoutProps } from 'styled-system';

import { DocumentStatus as Status } from 'modules/Documents/models/document';
import { StatusInfo, StatusInfoUI } from 'modules/Documents/models/statusInfo';
import useTranslations from 'modules/I18n/hooks/useTranslations';
import theme from 'modules/Theme';

import BasePill from '../BasePill';
import messages from './messages';

interface Props extends SpaceProps, LayoutProps {
  state: StatusInfo | StatusInfoUI | Status;
  gender?: 'm' | 'f';
  old?: boolean;
}

const labels: Record<StatusInfoUI, MessageDescriptor> = {
  // Quote
  [StatusInfoUI.ACCEPTED]: messages.accepted,
  [StatusInfoUI.REJECTED]: messages.rejected,
  [StatusInfoUI.PENDING]: messages.pending,
  [StatusInfoUI.INVOICED]: messages.invoiced,
  [StatusInfoUI.CANCELLED]: messages.canceled,
  [StatusInfoUI.DELIVERED]: messages.delivered,
  [StatusInfoUI.NOINVOICED]: messages.noInvoiced,
  // Invoice
  [StatusInfoUI.NOT_PAID]: messages.noPaid,
  [StatusInfoUI.PAID]: messages.paid,
  [StatusInfoUI.PARTIALLY_PAID]: messages.partiallyPaid,
  [StatusInfoUI.DUE]: messages.due,
  [StatusInfoUI.DRAFT]: messages.draft,
  [StatusInfoUI.ISSUED]: messages.issued,
  [StatusInfoUI.CORRECTED]: messages.corrected,
  [StatusInfoUI.VOID]: messages.void,
  [StatusInfoUI.TEST]: messages.test,
  [StatusInfoUI.CORRECTIVE]: messages.issued,
  // Expense
  [StatusInfoUI.PAID_EXPENSE]: messages.paidExpense,
  [StatusInfoUI.PARTIALLY_PAID_EXPENSE]: messages.partiallyPaidExpense,
  [StatusInfoUI.RECEIVED]: messages.received,
  [StatusInfoUI.TO_REVIEW]: messages.toReview,
  // Receipt
  [StatusInfoUI.REPLACED]: messages.replaced,
};

const colors: Partial<Record<StatusInfoUI, string>> = {
  // Quote
  [StatusInfoUI.ACCEPTED]: theme.colors.brand400,
  [StatusInfoUI.REJECTED]: theme.colors.error,
  [StatusInfoUI.INVOICED]: theme.colors.confirmation,
  [StatusInfoUI.CANCELLED]: theme.colors.error,
  [StatusInfoUI.DELIVERED]: theme.colors.brand500,
  [StatusInfoUI.PENDING]: theme.colors.pending,
  [StatusInfoUI.NOINVOICED]: theme.colors.confirmation,
  // Invoice
  [StatusInfoUI.PAID]: theme.colors.confirmation,
  [StatusInfoUI.PARTIALLY_PAID]: theme.colors.pending,
  [StatusInfoUI.DUE]: theme.colors.error,
  [StatusInfoUI.DRAFT]: theme.colors.primary300,
  [StatusInfoUI.ISSUED]: theme.colors.brand500,
  [StatusInfoUI.VOID]: theme.colors.primary400,
  [StatusInfoUI.TEST]: theme.colors.accent500,
  [StatusInfoUI.CORRECTIVE]: theme.colors.brand500,
  [StatusInfoUI.CORRECTED]: theme.colors.brand700,
  // Expense
  [StatusInfoUI.PAID_EXPENSE]: theme.colors.confirmation,
  [StatusInfoUI.PARTIALLY_PAID_EXPENSE]: theme.colors.pending,
  [StatusInfoUI.RECEIVED]: theme.colors.brand500,
  [StatusInfoUI.TO_REVIEW]: theme.colors.pending,

  // Receipt
  [StatusInfoUI.REPLACED]: theme.colors.brand700,
};

const backgroundColors: Partial<Record<StatusInfoUI, string>> = {
  [StatusInfoUI.ACCEPTED]: theme.colors.stateBg,
  [StatusInfoUI.REJECTED]: theme.colors.errorBackground,
  [StatusInfoUI.INVOICED]: theme.colors.tagsConfirmationA10,
  [StatusInfoUI.CANCELLED]: theme.colors.errorBackground,
  [StatusInfoUI.DELIVERED]: theme.colors.stateBg, // #E8ECFA
  [StatusInfoUI.PENDING]: theme.colors.warningBackground,
  [StatusInfoUI.NOINVOICED]: theme.colors.tagsConfirmationA10,
  [StatusInfoUI.PAID]: theme.colors.tagsConfirmationA10,
  [StatusInfoUI.PARTIALLY_PAID]: theme.colors.warningBackground,
  [StatusInfoUI.DUE]: theme.colors.error100A10,
  [StatusInfoUI.DRAFT]: theme.colors.auxiliary100A30,
  [StatusInfoUI.ISSUED]: theme.colors.stateBg,
  [StatusInfoUI.CORRECTIVE]: theme.colors.stateBg,
  [StatusInfoUI.VOID]: theme.colors.primary100,
  [StatusInfoUI.TEST]: theme.colors.accent400A30,
  [StatusInfoUI.CORRECTED]: theme.colors.brand700A10,
  [StatusInfoUI.PAID_EXPENSE]: theme.colors.tagsConfirmationA10,
  [StatusInfoUI.PARTIALLY_PAID_EXPENSE]: theme.colors.warningBackground,
  [StatusInfoUI.RECEIVED]: theme.colors.stateBg,
  [StatusInfoUI.TO_REVIEW]: theme.colors.stateNewBg,
  [StatusInfoUI.REPLACED]: theme.colors.brand700A10,
};

const borderColors: Partial<Record<StatusInfoUI, string>> = {
  // Expense
  [StatusInfoUI.TO_REVIEW]: theme.colors.accent500,
};

const DocumentStatus: React.FC<Props> = ({ state, gender = 'm', ...rest }) => {
  const { t } = useTranslations();
  const backgroundColor = backgroundColors[state];

  return (
    <BasePill
      backgroundColor={backgroundColor}
      borderColor={borderColors[state]}
      color={colors[state]}
      {...rest}
    >
      {t(labels[state], { gender })}
    </BasePill>
  );
};

export default DocumentStatus;
