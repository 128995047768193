import { defineMessages } from 'react-intl';

export default defineMessages({
  title: { id: 'gofre.product.deactivateImagesOnProducts.title' },
  subtitle: {
    id: 'gofre.product.deactivateImagesOnProducts.subtitle',
  },
  text: {
    id: 'gofre.product.deactivateImagesOnProducts.text',
  },
  confirmText: {
    id: 'gofre.product.deactivateImagesOnProducts.confirmText',
  },
});
