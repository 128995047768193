import { FC } from 'react';

import { Actions } from 'modules/Documents/models/documentTracking';
import { TranslationFunction } from 'modules/I18n/hooks/useTranslations';
import DocumentStatus from 'modules/Ui/DocumentStatus';

import DigitalStatusTag from '../BaiSubmission/components/Resume/DigitalStatusTag';
import DocumentSendStatusIcon from '../Detail/components/DocumentSendTrackingIcon';
import {
  tracking as messages,
  quotesTracking as quotesMessages,
} from '../messages';
import {
  DigitalDocumentStatus,
  DocumentSendStatus,
  DocumentType,
} from '../models/document';
import { StatusInfoUI } from '../models/statusInfo';

const getDocumentStatus = (state: StatusInfoUI): [FC, string] => [
  () => <DocumentStatus state={state} width="auto" />,
  state,
];

const getSendStatusIcon = (sendStatus: DocumentSendStatus): [FC, string] => [
  () => <DocumentSendStatusIcon sendStatus={sendStatus} />,
  sendStatus,
];

const getDigitalStatusTag = (status: DigitalDocumentStatus): [FC, string] => [
  () => <DigitalStatusTag status={status} />,
  status,
];

const actionToConvert: Record<Actions, [{ id: string }, FC, string]> = {
  [Actions.CREATE_DRAFT_DOCUMENT]: [
    messages.create_draft,
    ...getDocumentStatus(StatusInfoUI.DRAFT),
  ],
  [Actions.CREATE_ISSUE_DOCUMENT]: [
    messages.create,
    ...getDocumentStatus(StatusInfoUI.ISSUED),
  ],
  [Actions.CREATE_CORRECTIVE_DOCUMENT]: [
    messages.create_corrective,
    ...getDocumentStatus(StatusInfoUI.ISSUED),
  ],
  [Actions.CORRECT_DOCUMENT]: [
    messages.corrected,
    ...getDocumentStatus(StatusInfoUI.ISSUED),
  ],
  [Actions.DELETED_DOCUMENT]: [
    messages.deleted,
    ...getDocumentStatus(StatusInfoUI.ISSUED),
  ],
  [Actions.DELETED_MULTIPLE_DOCUMENT]: [
    messages.deleted,
    ...getDocumentStatus(StatusInfoUI.ISSUED),
  ],
  [Actions.EDIT_DOCUMENT]: [
    messages.edited,
    ...getDocumentStatus(StatusInfoUI.ISSUED),
  ],
  [Actions.EDIT_DRAFT_DOCUMENT]: [
    messages.edited,
    ...getDocumentStatus(StatusInfoUI.DRAFT),
  ],
  [Actions.ISSUE_DOCUMENT]: [
    messages.issued,
    ...getDocumentStatus(StatusInfoUI.ISSUED),
  ],
  [Actions.MARK_AS_CORRECTED_DOCUMENT]: [
    messages.mark_as_corrected,
    ...getDocumentStatus(StatusInfoUI.CORRECTED),
  ],
  [Actions.MARK_AS_PAID_DOCUMENT]: [
    messages.mark_as_paid,
    ...getDocumentStatus(StatusInfoUI.PAID),
  ],
  [Actions.MARK_AS_UNPAID_DOCUMENT]: [
    messages.mark_as_unpaid,
    ...getDocumentStatus(StatusInfoUI.ISSUED),
  ],
  [Actions.ADD_FINAL_PAYMENT_TO_DOCUMENT]: [
    messages.addPaymentToDocument,
    ...getDocumentStatus(StatusInfoUI.PAID),
  ],
  [Actions.DELETE_PAYMENT_PARTIAL_FROM_DOCUMENT]: [
    messages.deletePaymentFromDocument,
    ...getDocumentStatus(StatusInfoUI.PARTIALLY_PAID),
  ],
  [Actions.DELETE_PAYMENT_FROM_DOCUMENT]: [
    messages.deletePaymentFromDocument,
    ...getDocumentStatus(StatusInfoUI.ISSUED),
  ],
  [Actions.SINGLE_DOCUMENT_SENT]: [
    messages.sent,
    ...getSendStatusIcon(DocumentSendStatus.SENT),
  ],
  [Actions.SINGLE_DOCUMENT_SENT_BASIC]: [
    messages.sent,
    ...getSendStatusIcon(DocumentSendStatus.BASIC_PLAN_SENT),
  ],
  [Actions.SINGLE_DOCUMENT_SENT_READ]: [
    messages.read,
    ...getSendStatusIcon(DocumentSendStatus.READ),
  ],
  [Actions.SINGLE_DOCUMENT_SENT_RECEIVED]: [
    messages.received,
    ...getSendStatusIcon(DocumentSendStatus.RECEIVED),
  ],
  [Actions.SINGLE_DOCUMENT_SENT_SPAM]: [
    messages.spam,
    ...getSendStatusIcon(DocumentSendStatus.SPAM),
  ],
  [Actions.SINGLE_DOCUMENT_SENT_BLOCKED]: [
    messages.blocked,
    ...getSendStatusIcon(DocumentSendStatus.BLOCKED),
  ],
  [Actions.DIGITAL_DOCUMENT_SUBMITTED]: [
    messages.submitted,
    ...getDigitalStatusTag(DigitalDocumentStatus.PRESENTED),
  ],
  [Actions.DIGITAL_DOCUMENT_CONNECTION_FAILED]: [
    messages.failToContact,
    ...getDigitalStatusTag(DigitalDocumentStatus.FAIL_TO_CONTACT),
  ],
  [Actions.DIGITAL_DOCUMENT_REJECTED]: [
    messages.rejected,
    ...getDigitalStatusTag(DigitalDocumentStatus.REJECTED),
  ],
  [Actions.DIGITAL_DOCUMENT_VOID]: [
    messages.voidDigital,
    ...getDigitalStatusTag(DigitalDocumentStatus.PRESENTED),
  ],
  [Actions.VOID_DOCUMENT]: [
    messages.void,
    ...getDocumentStatus(StatusInfoUI.VOID),
  ],
  [Actions.DIGITAL_DOCUMENT_CONNECTION_FAILED_TO_VOID]: [
    messages.failToContact,
    ...getDigitalStatusTag(DigitalDocumentStatus.FAIL_TO_CONTACT),
  ],
  [Actions.DIGITAL_DOCUMENT_REJECTED_TO_VOID]: [
    messages.rejected,
    ...getDigitalStatusTag(DigitalDocumentStatus.REJECTED),
  ],
  [Actions.ADD_PARCIAL_PAYMENT_TO_DOCUMENT]: [
    messages.addPaymentToDocument,
    ...getDocumentStatus(StatusInfoUI.PARTIALLY_PAID),
  ],
  [Actions.UPDATE_FINAL_PAYMENT_TO_DOCUMENT]: [
    messages.updatePaymentFromDocument,
    ...getDocumentStatus(StatusInfoUI.PAID),
  ],
  [Actions.UPDATE_PARTIAL_PAYMENT_TO_DOCUMENT]: [
    messages.updatePaymentFromDocument,
    ...getDocumentStatus(StatusInfoUI.PARTIALLY_PAID),
  ],
};

type QuotesActions = Extract<
  Actions,
  | Actions.SINGLE_DOCUMENT_SENT
  | Actions.SINGLE_DOCUMENT_SENT_BASIC
  | Actions.SINGLE_DOCUMENT_SENT_BLOCKED
  | Actions.SINGLE_DOCUMENT_SENT_READ
  | Actions.SINGLE_DOCUMENT_SENT_RECEIVED
  | Actions.SINGLE_DOCUMENT_SENT_SPAM
>;

const quotesActionToConvert: Record<
  QuotesActions,
  [{ id: string }, React.FC, string]
> = {
  [Actions.SINGLE_DOCUMENT_SENT]: [
    quotesMessages.sent,
    ...getSendStatusIcon(DocumentSendStatus.SENT),
  ],
  [Actions.SINGLE_DOCUMENT_SENT_BASIC]: [
    quotesMessages.sent,
    ...getSendStatusIcon(DocumentSendStatus.BASIC_PLAN_SENT),
  ],
  [Actions.SINGLE_DOCUMENT_SENT_READ]: [
    quotesMessages.read,
    ...getSendStatusIcon(DocumentSendStatus.READ),
  ],
  [Actions.SINGLE_DOCUMENT_SENT_RECEIVED]: [
    quotesMessages.received,
    ...getSendStatusIcon(DocumentSendStatus.RECEIVED),
  ],
  [Actions.SINGLE_DOCUMENT_SENT_SPAM]: [
    quotesMessages.spam,
    ...getSendStatusIcon(DocumentSendStatus.SPAM),
  ],
  [Actions.SINGLE_DOCUMENT_SENT_BLOCKED]: [
    quotesMessages.blocked,
    ...getSendStatusIcon(DocumentSendStatus.BLOCKED),
  ],
};

const getAction = (
  action: Actions,
  documentType?: DocumentType
): [{ id: string }, React.FC, string] => {
  if (documentType === DocumentType.QUOTE) {
    return quotesActionToConvert[action as QuotesActions];
  }

  return actionToConvert[action];
};

const getActionStatus = (
  action: Actions,
  renderVoidTag: boolean,
  t: TranslationFunction,
  translationValues?: Record<string, string>,
  documentType?: DocumentType
): {
  actionPerformed: string | null;
  StatusLabelRenderer: FC;
  status: string;
} => {
  const [actionPerformed, StatusLabelRenderer, status] = getAction(
    action,
    documentType
  ) ?? [null, ...getDocumentStatus(StatusInfoUI.ISSUED)];

  const shouldRenderVoidTag = ![
    Actions.DIGITAL_DOCUMENT_VOID,
    Actions.SINGLE_DOCUMENT_SENT_READ,
    Actions.SINGLE_DOCUMENT_SENT_RECEIVED,
    Actions.SINGLE_DOCUMENT_SENT,
    Actions.SINGLE_DOCUMENT_SENT_BASIC,
    Actions.SINGLE_DOCUMENT_SENT_SPAM,
    Actions.SINGLE_DOCUMENT_SENT_BLOCKED,
  ].includes(action);

  return {
    actionPerformed: actionPerformed
      ? t(actionPerformed, translationValues)
      : null,
    StatusLabelRenderer:
      renderVoidTag && shouldRenderVoidTag
        ? getDocumentStatus(StatusInfoUI.VOID)[0]
        : StatusLabelRenderer,
    status,
  };
};

export default getActionStatus;
