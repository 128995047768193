import IconByPath, { IconByPathProps } from './Icon/IconType/IconByPath';

const IconCopy = (props: IconByPathProps) => (
  <IconByPath
    {...props}
    path="M13.5 1 6 8.6V20h15V1h-7.5ZM7.002 9H14V2h6v17H7V9.002L7.002 9ZM3 12h1v10h14v1H3V12Zm10-9v5H8l5-5Z"
  />
);

export default IconCopy;
