import IconByPath, { IconByPathProps } from './Icon/IconType/IconByPath';

const IconDone = (props: IconByPathProps) => (
  <IconByPath
    {...props}
    path="M12 24c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12s5.373 12 12 12Zm0-1C5.925 23 1 18.075 1 12S5.925 1 12 1s11 4.925 11 11-4.925 11-11 11ZM6.525 12.268l.707-.707 3.122 3.121 6.242-6.243.707.707-6.242 6.243-.707.707-3.829-3.828Z"
    shapeRendering="geometricPrecision"
  />
);

export default IconDone;
