import { FC, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';

import { menuSizes } from '../Menu/MenuMobile/menuSizes';

const container = document.getElementById('root') as HTMLElement;

const AppBar: FC = ({ children }) => {
  const elRef = useRef(document.createElement('header'));

  useEffect(() => {
    elRef.current.style.cssText = `height: ${menuSizes.HEADER_HEIGHT_MOBILE}px`;
    container.insertBefore(elRef.current, container.firstChild);
    return () => {
      container.removeChild(elRef.current);
    };
  }, []);

  return createPortal(children, elRef.current);
};

export default AppBar;
