import { FC, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { Formik } from 'formik';

import { selectBusiness } from 'modules/Business/Update/selectors';
import useTranslations from 'modules/I18n/hooks/useTranslations';
import theme from 'modules/Theme';
import { FormikField, Fieldset, Hr, Box } from 'modules/Ui';
import ErrorListener from 'modules/Ui/Formik/ErrorListener';
import { Roles } from 'modules/Users/models/role';

import { StepProps } from '../../types';
import CardExpiryStripe from './Components/CardExpiryStripe';
import CardNumberStripe from './Components/CardNumberStripe';
import CvcStripe from './Components/CvcStripe';
import { LoadingStripeIndicator } from './LoadingStirpeIndicator';
import messages from './messages';
import PaymentCards from './PaymentCards';
import getValidationSchema from './validation';

const styleOptions = {
  base: {
    color: theme.colors.gray800,
    fontFamily: 'Inter, Arial, sans-serif',
    '::placeholder': {
      color: theme.colors.gray400,
    },
  },
  invalid: {
    color: theme.colors.error,
  },
};

const getOptions = (text: string) => ({
  placeholder: text,
  style: { ...styleOptions },
});

const PaymentForm: FC<StepProps> = ({ onSubmit, data }) => {
  const { t } = useTranslations();
  const validationSchema = useMemo(() => getValidationSchema(t), [t]);
  const business = useSelector(selectBusiness);
  const ownerEmail = business.users?.find(
    (user) => user.role.name === Roles.OWNER
  )?.user.email;
  const initialValues = { email: business.billingEmail || ownerEmail || '' };

  const handleSubmit = useCallback(
    (email: string) => {
      onSubmit({ ...data, billingEmail: email });
    },
    [onSubmit]
  );
  return (
    <Formik
      enableReinitialize
      onSubmit={(values, actions) => {
        handleSubmit(values.email);
        actions.setSubmitting(false);
      }}
      validateOnBlur={false}
      validateOnChange={false}
      {...{ validationSchema, initialValues }}
    >
      {(formik) => (
        <form onSubmit={formik.handleSubmit} id="payment-form" noValidate>
          <ErrorListener />
          <Fieldset
            id="business-form"
            display={{ sm: 'grid' }}
            gridTemplateColumns={{
              sm: '54% 1fr',
              md: '65% 1fr',
              lg: '48% 1fr',
            }}
            columnGap="16px"
            legend={t(messages.enterEmail)}
            legendGridColumnStart={{ sm: '1' }}
            legendGridColumnEnd={{ sm: '3' }}
          >
            <FormikField
              helpText={t(messages.emailFormatHelp)}
              id="email"
              label={t(messages.emailLabel)}
              placeholder={t(messages.emailPlaceholder)}
              required
              type="email"
              marginBottom="8px"
            />
            <Hr
              marginBottom="24px"
              gridColumnStart={{ sm: '1' }}
              gridColumnEnd={{ sm: '3' }}
            />
            <Box>
              <LoadingStripeIndicator />
              <Box
                display="grid"
                gridTemplateColumns="1fr 1fr"
                columnGap="16px"
              >
                <CardNumberStripe
                  options={getOptions(t(messages.numberPlaceholder))}
                />
                <CardExpiryStripe
                  options={getOptions(t(messages.expiresPlaceholder))}
                />
                <CvcStripe options={getOptions(t(messages.cvv))} />
              </Box>
            </Box>
            <PaymentCards />
          </Fieldset>
        </form>
      )}
    </Formik>
  );
};

export default PaymentForm;
