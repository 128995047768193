import { DefaultValuesItem } from '../models/default-values-item';

export const defaultValuesToDocumentSettings = (
  items?: DefaultValuesItem[]
) => {
  return items
    ?.map((a) => ({ [a.resource]: a.data }))
    .reduce((acc, cur) => {
      const key = Object.keys(cur)[0];
      acc[key] = cur[key];
      return acc;
    }, {});
};
