import { omit } from 'lodash';

import {
  ApiConfiguration,
  ParamsApiConfiguration,
} from 'modules/Integrations/models';

export const normalizeValues = (
  values: ApiConfiguration
): ParamsApiConfiguration => {
  return omit(values, ['isActive', 'isSavedForFirstTime', 'name']);
};
