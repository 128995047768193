import IconByPath, { IconByPathProps } from './Icon/IconType/IconByPath';

const IconAdd = (props: IconByPathProps) => (
  <IconByPath
    {...props}
    clipRule="evenodd"
    fillRule="evenodd"
    path="M22 12c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10Zm1 0c0 6.075-4.925 11-11 11S1 18.075 1 12 5.925 1 12 1s11 4.925 11 11ZM11.45 7.6h1.1v3.85h3.85v1.1h-3.85v3.85h-1.1v-3.85H7.6v-1.1h3.85V7.6Z"
    shapeRendering="geometricPrecision"
  />
);

export default IconAdd;
