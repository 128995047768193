import { FC } from 'react';

import useTranslations from 'modules/I18n/hooks/useTranslations';
import { DialogConfirm, useCloseModal } from 'modules/Modals';
import { Text } from 'modules/Ui';

import messages from './messages';
import { DeactivateImagesOnProductsModalProps } from './type';

const DeactivateImagesOnProductsModal: FC<
  DeactivateImagesOnProductsModalProps
> = ({ onConfirm, onCancel }) => {
  const { t } = useTranslations();
  const closeModal = useCloseModal();
  const handleConfirm = () => {
    onConfirm();
    closeModal();
  };

  const handleCancel = () => {
    onCancel();
    closeModal();
  };

  return (
    <DialogConfirm
      onConfirm={handleConfirm}
      onCancel={handleCancel}
      id="detaching-images-on-product-modal"
      title={t(messages.title)}
      confirmText={t(messages.confirmText)}
      subtitle={t(messages.subtitle)}
    >
      <Text>{t(messages.text)}</Text>
    </DialogConfirm>
  );
};

export default DeactivateImagesOnProductsModal;
