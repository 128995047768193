import { FC, Suspense } from 'react';

import { Box } from 'modules/Ui';

import { MODAL_WIDTH_SMALL } from '../models';
import { DialogBaseProps } from '../models/types';
import { CloseButton } from './CloseButton';
import { DialogButtons } from './DialogButtons';
import { DialogHeader } from './DialogHeader';
import { DialogSubtitle } from './DialogSubtitle';

export interface DefaultDialogProps extends DialogBaseProps {}

export const DialogDefault: FC<DefaultDialogProps> = ({
  buttonPrimary,
  buttonSecondary,
  children,
  content,
  dialogWidth,
  id,
  onClose,
  subtitle,
  title,
  type,
}) => {
  return (
    <Box
      width={dialogWidth || { _: 'calc(100vw - 16px)', sm: MODAL_WIDTH_SMALL }}
    >
      <CloseButton
        onClose={onClose}
        position="absolute"
        right="0"
        top="-32px"
      />
      <Box
        backgroundColor="gray0"
        borderRadius="12px"
        boxSizing="border-box"
        display="grid"
        gap={{ _: '28px', sm: '20px' }}
        padding={{ _: '24px', sm: '24px 32px' }}
        position="relative"
      >
        {title && (
          <DialogHeader type={type} id={id} onClose={onClose} title={title} />
        )}
        <Box>
          {subtitle && <DialogSubtitle id={id} text={subtitle} />}
          <Suspense fallback={null}>{content || children}</Suspense>
        </Box>
        {(buttonPrimary || buttonSecondary) && (
          <DialogButtons
            buttonPrimary={buttonPrimary}
            buttonSecondary={buttonSecondary}
          />
        )}
      </Box>
    </Box>
  );
};
