import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';
import { Route } from 'react-router-dom';

import { Elements } from '@stripe/react-stripe-js';
import { Stripe } from '@stripe/stripe-js';
import { ConnectedRouter } from 'connected-react-router';
import { ReactQueryDevtools } from 'react-query/devtools';
import { ThemeProvider } from 'styled-components';
import { QueryParamProvider } from 'use-query-params';

import { store } from 'modules/App/store';
import theme from 'modules/Theme';
import 'modules/Theme/Fonts.css';
import Reset from 'modules/Theme/Reset';
import { AnalyticsRouter, useAnalytics } from 'modules/Analytics';
import history from 'utils/history';

import I18nProvider from '../I18n/I18nProvider';
import Modals from '../Modals/Modals';
import { Bootstrap } from './components/Bootstrap';
import { AppConfig } from './config';
import { ConfigContext } from './config/useConfig';
import RoutesMap from './RoutesMap';
import '../Forms/yup-extended';
import { ServerNotificationsProvider } from '../Notifications/components/ServerNotificationsProvider';

const queryCache = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const App: React.FC<{
  config: AppConfig;
  stripe: Promise<Stripe | null>;
}> = ({ config, stripe }) => {
  // For testing purposes
  window.store = store;

  useAnalytics(config.segmentApiKey);
  return (
    <ConfigContext.Provider value={config}>
      <Elements stripe={stripe}>
        <Provider store={store}>
          <I18nProvider>
            <ThemeProvider theme={theme}>
              <Reset />
              <QueryClientProvider client={queryCache}>
                <ReactQueryDevtools />
                <ConnectedRouter history={history}>
                  <QueryParamProvider ReactRouterRoute={Route}>
                    <ServerNotificationsProvider>
                      <Modals />
                      <RoutesMap />
                      <AnalyticsRouter />
                      <Bootstrap />
                    </ServerNotificationsProvider>
                  </QueryParamProvider>
                </ConnectedRouter>
              </QueryClientProvider>
            </ThemeProvider>
          </I18nProvider>
        </Provider>
      </Elements>
    </ConfigContext.Provider>
  );
};
export default App;
