// to make RGB hexadecimal notatiom -> https://developer.mozilla.org/es/docs/Web/CSS/color_value#rgb()
// Generates Hexadecimal color with transparency -> https://gist.github.com/lopspower/03fb1cc0ac9f32ef38f4;
const colorOpacity = (color: string, percent: number) => {
  const makeOpacity = percentToHex(percent);
  return `${color}${makeOpacity}`;
};

const percentToHex = (p: number) => {
  const percent = Math.max(0, Math.min(100, p)); // bound percent from 0 to 100
  const intValue = Math.round((percent / 100) * 255); // map percent to nearest integer (0 - 255)
  const hexValue = intValue.toString(16); // get hexadecimal representation
  return hexValue.padStart(2, '0').toUpperCase(); // format with leading 0 and upper case characters
};

export default colorOpacity;
