export const ROUTES = {
  SUBSCRIPTIONS: '/subscriptions',
  SUBSCRIPTIONS_ERROR: '/subscriptions/error',
  SUBSCRIPTIONS_END: '/subscriptions/end-of-trial',
  SUBSCRIPTIONS_PLANS: '/subscriptions/plans',
  SUBSCRIPTIONS_CREATE: '/subscriptions/create',
  SUBSCRIPTIONS_PAYMENT_RESULT: '/subscriptions/payment-result',
  SUBSCRIPTIONS_CHANGE: '/subscriptions/change',
  SUBSCRIPTIONS_CANCELLED: '/subscription-cancelled',
  SUBSCRIPTIONS_CANCELLED_PLANS: '/subscription-cancelled-plans',
};
