import React from 'react';

import { Box } from 'modules/Ui';

export interface SectionProps {
  open: boolean;
  children: React.ReactNode;
}

const Section = (props: SectionProps) => {
  const { open, children } = props;
  return (
    <Box tag="nav" {...{ open }} id="subMenuContent">
      <Box tag="ul">{children}</Box>
    </Box>
  );
};

export default Section;
