import IconByString, { IconByStringProps } from './Icon/IconType/IconByString';

const IconPdf = (props: IconByStringProps) => {
  const { ...rest } = props;
  return (
    <IconByString {...rest}>
      <path
        clipRule="evenodd"
        d="m3 8 7-7h11v22H3V8Zm1.414 0L4 8.414V22h16V2h-9.586L10 2.414V8H4.414Zm1-1L9 3.414V7H5.414Z"
        fill="fillColor"
        fillRule="evenodd"
      />
      <path
        d="M8.201 14c.462 0 .834.168 1.116.504.288.33.432.765.432 1.305 0 .528-.138.954-.414 1.278-.276.324-.639.486-1.089.486H7.4c-.024 0-.036.012-.036.036v2.61c0 .06-.03.09-.09.09H6.59c-.06 0-.09-.03-.09-.09V14.09c0-.06.03-.09.09-.09h1.611Zm-.135 2.88a.726.726 0 0 0 .594-.279c.15-.192.225-.45.225-.774 0-.33-.075-.591-.225-.783a.71.71 0 0 0-.594-.297H7.4c-.024 0-.036.012-.036.036v2.061c0 .024.012.036.036.036h.666ZM10.86 20.309c-.06 0-.09-.03-.09-.09v-6.12c0-.06.03-.09.09-.09h1.476c.498 0 .893.153 1.188.459.294.306.44.72.44 1.242v2.898c0 .522-.146.936-.44 1.242-.294.306-.69.459-1.188.459H10.86Zm.774-.783c0 .024.011.036.035.036l.657-.009a.666.666 0 0 0 .55-.261c.138-.18.21-.423.216-.729v-2.808c0-.306-.07-.549-.208-.729a.667.667 0 0 0-.558-.27h-.657c-.024 0-.036.012-.036.036v4.734ZM18.142 14.666c0 .06-.03.09-.09.09h-2.016c-.024 0-.036.012-.036.036v1.953c0 .024.012.036.036.036h1.233c.06 0 .09.03.09.09v.567c0 .06-.03.09-.09.09h-1.233c-.024 0-.036.012-.036.036v2.655c0 .06-.03.09-.09.09h-.684c-.06 0-.09-.03-.09-.09v-6.12c0-.06.03-.09.09-.09h2.826c.06 0 .09.03.09.09v.567Z"
        fill="fillColor"
      />
    </IconByString>
  );
};

export default IconPdf;
