import IconByPath, { IconByPathProps } from './Icon/IconType/IconByPath';

const IconActionsSend = (props: IconByPathProps) => (
  <IconByPath
    {...props}
    fillRule="evenodd"
    path="M4 8V7l6-6h10v7h2a1 1 0 0 1 1 1v13a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V9a1 1 0 0 1 1-1h2Zm0 1H2.981L4 9.776V9Zm1 1.538 6.09 4.64a1.5 1.5 0 0 0 1.82 0l6.09-4.64V2h-9v5H5v3.538Zm15-.762L21.019 9H20v.776ZM9 6H6.414L9 3.414V6Zm13 3.51-8.485 6.464a2.5 2.5 0 0 1-3.03 0L2 9.51V22h20V9.51Z"
  />
);

export default IconActionsSend;
