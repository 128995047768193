import { FC } from 'react';
import { Visible } from 'react-grid-system';

import useIsBookkeeperUser from 'modules/Bookkeeper/hooks/useIsBookkeeperUser';
import useHasCustomerSupport from 'modules/Help/hooks/useHasCustomerSupport';
import useTranslations from 'modules/I18n/hooks/useTranslations';
import useTheme from 'modules/Theme/hooks/useTheme';
import { Box, Text, Link } from 'modules/Ui';
import { IconMobile } from 'modules/Ui/Icons';

import messages from '../messages';

const Help: FC = () => {
  const { hasPhoneSupport } = useHasCustomerSupport();
  const isBookkeeper = useIsBookkeeperUser();
  const showPhoneSupport = hasPhoneSupport || isBookkeeper;
  const { t } = useTranslations();
  const theme = useTheme();
  return (
    <>
      {showPhoneSupport && (
        <Box
          borderBottom={`1px solid ${theme.colors.primary100}`}
          display="grid"
          gap={{ _: '28px', md: '24px' }}
          gridTemplateColumns="24px 1fr"
          marginBottom="16px"
          paddingBottom="16px"
        >
          <IconMobile color="brand500" />
          <Box>
            <Text
              color="gray800"
              display="grid"
              fontSize={14}
              gap="4px"
              lineHeight={18}
              marginBottom="4px"
            >
              {t(messages.helpCallUs)}
              <Link
                color="inherit"
                display="block"
                fontSize="inherit"
                fontWeight="600"
                hoverUnderline
                isExternal
                lineHeight="inherit"
                target="blank"
                to={`tel:${t(messages.helpPhoneLabel)}`}
              >
                {t(messages.helpPhoneLabel)}
              </Link>
            </Text>
            <Box
              alignItems={{ sm: 'center', md: 'initial' }}
              display={{ sm: 'flex', md: 'initial' }}
            >
              <Text fontSize={12} lineHeight={18}>
                {t(messages.helpSchedule01)}
              </Text>
              <Visible sm md>
                <Box
                  backgroundColor="gray600"
                  borderRadius="50%"
                  height="3px"
                  margin={{ sm: '0 6px', md: 'initial' }}
                  tag="span"
                  width="3px"
                />
              </Visible>
              <Text fontSize={12} lineHeight={18} display={{ md: 'block' }}>
                {t(messages.helpSchedule02)}
              </Text>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

export default Help;
