import { getConfig } from 'modules/App/config';
import { Contact } from 'modules/Contacts/models/models';
import request from 'utils/request';

const fetchContact = async (id: string): Promise<Contact> => {
  const endpoint = (await getConfig()).apiContactsService;

  const response = await request<any>(`${endpoint}/${id}`, {
    method: 'GET',
  });

  return {
    ...response.data.data,
  };
};

export default fetchContact;
