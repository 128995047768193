import { css } from 'modules/Theme/styled-components';

const stripeStyles = css`
  padding: 14px 10px;
  margin-bottom: 24px;
  &.StripeElement {
    &--invalid {
      --input-border-color: ${(props) => props.theme.colors.error};
    }
    &--focus {
      --input-border-color: ${(props) => props.theme.colors.brand500};
    }
  }
`;

export default stripeStyles;
