import {
  canEdit,
  canDelete,
  ActionPermissionParams,
  canCopy,
  canReplaceToInvoice,
  canRectificate,
  canVoidDocument,
  canDownloadExcel,
  canDownloadDocument,
  canDownloadZip,
  canSendDocument,
  canMarkPaid,
  canEmitAsInvoice,
  canCreateRemmittance,
  canEmitAsTicket,
} from 'modules/Documents/helpers';
import {
  DocumentFilters,
  DocumentStatus,
  DocumentTable,
} from 'modules/Documents/models/document';
import { TranslationFunction } from 'modules/I18n/hooks/useTranslations';
import { DataTableAction } from 'modules/Ui/DataTable/DataTable.models';
import {
  IconTrash,
  IconEdit,
  IconCreateInvoice,
  IconCopy,
  IconIssue,
  IconPaid,
  IconEmail,
  IconRectificate,
  IconExcel,
  IconPdf,
  IconZip,
  IconAdd,
  IconRemittances,
  IconDocument,
} from 'modules/Ui/Icons';

import { actions } from '../messages';

export interface CustomDocumentBulkActionsProps
  extends Omit<UseDocumentBulkActionsProps, 'customBulkActions'> {}

interface UseDocumentBulkActionsProps {
  t: TranslationFunction;
  filters: DocumentFilters;
  status?: DocumentStatus;
  isEditor: boolean;
  isUserReader: boolean;
  hasElectronicProvider: boolean;
  canSendElectronicDocument: boolean;
  isNotPresented: boolean;
  isSomeDraftSelected: boolean;
  isSomeReceiptSelected: boolean;
  isSomeFreezedSelected: boolean;
  isSomeCorrectiveSelected: boolean;
  isSomeReplacedSelected: boolean;
  isSomeCorrectedSelected: boolean;
  isSomeVoidSelected: boolean;
  isSomePaidSelected: boolean;
  isSomeHasBaiVatNumberErrors: boolean;
  isSomeIsUploadFile: boolean;
  onlyTestInvoicesSelected: boolean;
  isSingleVoidableSelected: boolean;
  isSomeNegativeSelected: boolean;
  selectionInverted: boolean;
  setSingleVoidableSelected: (value: boolean) => void;
  setIsSomeDraftSelected: (value: boolean) => void;
  setSelectionInverted: (value: boolean) => void;
  deleteDocuments: (
    selectedData: DocumentTable[],
    inverted: boolean,
    resetSelection: () => void
  ) => void;
  editDocument: (
    selecteds: DocumentTable[],
    _inverted: boolean,
    resetSelection: () => void
  ) => void;
  emitDraft: (
    selecteds: DocumentTable[],
    _inverted: boolean,
    resetSelection: () => void
  ) => void;
  copyDocument: (
    selecteds: DocumentTable[],
    _inverted: boolean,
    resetSelection: () => void
  ) => void;
  replaceToInvoice: (
    selecteds: DocumentTable[],
    _inverted: boolean,
    resetSelection: () => void
  ) => void;
  downloadZip: (
    selecteds: DocumentTable[],
    _inverted: boolean,
    resetSelection: () => void
  ) => void;
  voidSelectedDocument: (
    selecteds: DocumentTable[],
    _inverted: boolean,
    resetSelection: () => void
  ) => void;
  rectificateDocument: (
    selectedData: DocumentTable[],
    isRectification: boolean
  ) => void;
  createPayment: (
    selecteds: DocumentTable[],
    _inverted: boolean,
    resetSelection: () => void
  ) => Promise<void>;
  sendDocument: (
    selecteds: DocumentTable[],
    _inverted: boolean,
    resetSelection: () => void
  ) => void;
  downloadExcel: (
    selecteds: DocumentTable[],
    _inverted: boolean,
    resetSelection: () => void
  ) => Promise<void>;
  downloadDocument: (
    selecteds: DocumentTable[],
    _inverted: boolean,
    resetSelection: () => void
  ) => Promise<void>;
  createRemittance: (
    selecteds: DocumentTable[],
    _inverted: boolean,
    resetSelection: () => void
  ) => void;
  customBulkActions?: (
    props: CustomDocumentBulkActionsProps
  ) => DataTableAction<DocumentTable>[];
}

export const useDocumentBulkActions = (props: UseDocumentBulkActionsProps) => {
  const {
    t,
    setSingleVoidableSelected,
    setIsSomeDraftSelected,
    setSelectionInverted,
    deleteDocuments,
    editDocument,
    emitDraft,
    copyDocument,
    replaceToInvoice,
    downloadZip,
    voidSelectedDocument,
    rectificateDocument,
    createPayment,
    sendDocument,
    downloadExcel,
    downloadDocument,
    createRemittance,
    customBulkActions,
  } = props;
  const params: ActionPermissionParams = {
    business: {
      hasElectronicProvider: props.hasElectronicProvider,
      canSendElectronicDocument: props.canSendElectronicDocument,
    },
    user: { isEditor: props.isEditor, isReader: props.isUserReader },
    document: {
      canBeVoided: props.isSingleVoidableSelected,
      hasBaiVatNumberErrors: props.isSomeHasBaiVatNumberErrors,
      isCorrected: props.isSomeCorrectedSelected,
      isCorrective: props.isSomeCorrectiveSelected,
      isDraft: props.isSomeDraftSelected,
      isExpense: false,
      isFreezed: props.isSomeFreezedSelected,
      isPaid: props.isSomePaidSelected,
      isPresented: !props.isNotPresented,
      isReceipt: props.isSomeReceiptSelected,
      isReplaced: props.isSomeReplacedSelected,
      isTest: props.onlyTestInvoicesSelected,
      isUploadFile: props.isSomeIsUploadFile,
      isVoid: props.isSomeVoidSelected,
      isNegative: props.isSomeNegativeSelected,
    },
  };

  const bulkActions: DataTableAction<DocumentTable>[] = customBulkActions
    ? customBulkActions(props)
    : [
        {
          name: t(actions.delete),
          icon: <IconTrash />,
          onClick: (selectedData, inverted, resetSelection) => {
            const reset = () => {
              setSingleVoidableSelected(false);
              setIsSomeDraftSelected(false);
              setSelectionInverted(false);
              resetSelection?.();
            };
            deleteDocuments(selectedData, inverted, reset);
          },
          shouldActionRender: canDelete(params),
        },
        {
          name: t(actions.edit),
          icon: <IconEdit />,
          disableMultiple: true,
          onClick: editDocument,
          shouldActionRender: canEdit(params),
        },
        {
          name: t(actions.replaceToInvoice),
          icon: <IconCreateInvoice />,
          disableMultiple: true,
          onClick: emitDraft,
          shouldActionRender: canEmitAsInvoice(params),
        },
        {
          name: t(actions.emitReceipt),
          icon: <IconDocument />,
          disableMultiple: true,
          onClick: emitDraft,
          shouldActionRender: canEmitAsTicket(params),
        },
        {
          name: t(actions.copy),
          icon: <IconCopy />,
          disableMultiple: true,
          onClick: copyDocument,
          shouldActionRender: canCopy(params),
        },
        {
          name: t(actions.replace),
          icon: <IconIssue />,
          disableMultiple: true,
          onClick: replaceToInvoice,
          shouldActionRender: canReplaceToInvoice(params),
        },
        {
          name: t(actions.createRemittance),
          abbrname: t(actions.abbrCreateRemittance),
          icon: <IconRemittances />,
          onClick: createRemittance,
          shouldActionRender:
            canCreateRemmittance(params) && !props.selectionInverted,
        },
        {
          name: t(actions.createPayment),
          abbrname: t(actions.abbrCreatePayment),
          icon: <IconPaid />,
          onClick: createPayment,
          disableMultiple: true,
          shouldActionRender: canMarkPaid(params) && !props.selectionInverted,
        },
        {
          name: t(actions.createPayment),
          abbrname: t(actions.abbrCreatePayment),
          icon: <IconPaid />,
          onClick: createPayment,
          onlyMultiple: true,
          shouldActionRender: canMarkPaid(params) && !props.selectionInverted,
        },
        {
          name: t(actions.send),
          icon: <IconEmail />,
          disableMultiple: true,
          onClick: sendDocument,
          shouldActionRender: canSendDocument(params),
        },
        {
          name: t(actions.rectificate),
          icon: <IconRectificate />,
          disableMultiple: true,
          onClick: (selecteds) => rectificateDocument(selecteds, true),
          shouldActionRender: canRectificate(params),
        },
        {
          name: t(actions.downloadExcel),
          abbrname: t(actions.abbrDownloadExcel),
          icon: <IconExcel />,
          onClick: downloadExcel,
          shouldActionRender: canDownloadExcel(params),
        },
        {
          name: t(actions.download),
          abbrname: t(actions.abbrDownload),
          icon: <IconPdf />,
          disableMultiple: true,
          onClick: downloadDocument,
          shouldActionRender: canDownloadDocument(params),
        },

        {
          name: t(actions.downloadZip),
          abbrname: t(actions.abbrDownloadZip),
          icon: <IconZip />,
          onlyMultiple: true,
          onClick: downloadZip,
          shouldActionRender: canDownloadZip(params),
        },
        {
          name: t(actions.void),
          icon: <IconAdd transform="rotate(45deg)" />,
          onClick: voidSelectedDocument,
          shouldActionRender: canVoidDocument(params),
        },
      ];

  return bulkActions;
};
