import theme from 'modules/Theme';
import { css } from 'modules/Theme/styled-components';

import {
  disabledPrimaryStyles,
  disabledSecondaryStyles,
  disabledStrokeStyles,
  disabledLinkStyles,
  disabledGhostStyles,
} from './buttonDisabled';

export type Props = {
  active?: boolean;
};

export const variantPrimary = css`
  --button-background: ${(props) => props.theme.colors.brand500};
  --button-border: ${(props) => props.theme.colors.brand500};
  --button-color: ${(props) => props.theme.colors.gray0};
  --button-focus-color: ${(props) => props.theme.colors.gray0};
  --button-focus-background:  ${(props) => props.theme.colors.brand500};
  --button-focus-border:  ${(props) => props.theme.colors.brand600};
  --button-hover-background: ${(props) => props.theme.colors.brand700};
  --button-hover-border: ${(props) => props.theme.colors.brand700};
  --button-hover-color: ${(props) => props.theme.colors.gray0};
  ${disabledPrimaryStyles}
`;

export const variantSecondary = css`
  --button-background: ${(props) => props.theme.colors.accent400};
  --button-border: ${(props) => props.theme.colors.accent400};
  --button-color: ${(props) => props.theme.colors.gray800};
  --button-focus-color: ${(props) => props.theme.colors.gray800};
  --button-focus-border:  ${(props) => props.theme.colors.pending};
  --button-focus-background:  ${(props) => props.theme.colors.accent500};
  --button-hover-background: ${(props) => props.theme.colors.accent500};
  --button-hover-border: ${(props) => props.theme.colors.accent500};
  --button-hover-color: ${(props) => props.theme.colors.primary500};
  ${disabledSecondaryStyles}
`;

export const variantStroke = css`
  --button-background: ${(props) => props.theme.colors.gray0};
  --button-border: ${(props) => props.theme.colors.brand500};
  --button-color: ${(props) => props.theme.colors.brand500};
  --button-focus-border: ${(props) => props.theme.colors.brand500};
  --button-focus-background: ${(props) => props.theme.colors.gray0};
  --button-focus-color: ${(props) => props.theme.colors.gray0};
  --button-hover-background: ${(props) => props.theme.colors.brand500};
  --button-hover-color: ${(props) => props.theme.colors.gray0};
  --button-hover-border: ${(props) => props.theme.colors.brand500}
  ${disabledStrokeStyles}
`;

export const variantStrokeTransparent = css`
  ${variantStroke}
  --button-background: transparent;
`;

export const variantGhost = css<Props>`
  --button-background : ${({ active }) =>
    active ? `${theme.colors.auxiliary100}` : 'transparent'};
  --button-border: transparent;
  --button-color: ${(props) => props.theme.colors.brand500};
  --button-focus-border: ${(props) => props.theme.colors.brand500};
  --button-focus-color: ${(props) => props.theme.colors.brand500};
  --button-hover-background: ${(props) => props.theme.colors.auxiliary100};
  --button-hover-color: ${(props) => props.theme.colors.brand500};
  --button-hover-border: transparent;
  ${disabledGhostStyles}
`;

export const variantGhostDark = css`
  --button-background: transparent;
  --button-border: transparent;
  --button-color: ${(props) => props.theme.colors.gray0};
  --button-focus-border: transparent;
  --button-focus-color: ${(props) => props.theme.colors.gray0};
  --button-focus-background: transparent;
  --button-hover-background: ${(props) => props.theme.colors.primary300};
  --button-hover-color: ${(props) => props.theme.colors.gray0};
  --button-hover-border: transparent;
  ${disabledGhostStyles}
`;

export const variantLink = css`
  --button-background: transparent;
  --button-color: ${(props) => props.theme.colors.brand500};
  --button-focus-color: ${(props) => props.theme.colors.brand500};
  --button-hover-background: transparent;
  --button-hover-color: ${(props) => props.theme.colors.brand500};
  ${disabledLinkStyles}
`;

export const variantLight = css`
  --button-background: ${(props) => props.theme.colors.gray0};
  --button-border: ${(props) => props.theme.colors.gray200};
  --button-color: ${(props) => props.theme.colors.brand500};
  --button-hover-background: ${(props) => props.theme.colors.gray0};
  --button-hover-color: ${(props) => props.theme.colors.brand500};
  --button-hover-border: ${(props) => props.theme.colors.brand500};
`;

export const variantCustomIconText = css`
  --button-background: transparent;
  --button-color: ${(props) => props.theme.colors.brand500};
`;

// prettier-ignore
const button = css``;

export default button;
