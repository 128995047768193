import { FC } from 'react';
import { Visible } from 'react-grid-system';
import { useSelector } from 'react-redux';

import { selectIsMobile } from 'modules/App/store/selectors';
import useTranslations from 'modules/I18n/hooks/useTranslations';
import {
  useCloseModal,
  Dialog,
  changeFocusAfterDialogIsClosed,
} from 'modules/Modals';
import { Button, Text } from 'modules/Ui';

import { taxRegimeDialog } from '../../messages';
import { TaxRegimeWarningModalProps } from './types';

const TaxRegimeWarningModal: FC<TaxRegimeWarningModalProps> = ({
  onCancel,
  onConfirm,
  idToFocus,
}) => {
  const { t } = useTranslations();
  const closeModal = useCloseModal();
  const handleClose = (callback: () => void) => {
    closeModal();
    callback();
  };
  const isMobile = useSelector(selectIsMobile);
  return (
    <Dialog
      id="tax-regime-dialog"
      onClose={() => {
        handleClose(onCancel);
      }}
      title={t(taxRegimeDialog.title)}
      underlayMarginTop={isMobile ? '64px' : '110px'}
      buttonSecondary={
        <Visible sm md lg xl xxl>
          <Button
            variant="stroke"
            onClick={() => {
              handleClose(onCancel);
              idToFocus && changeFocusAfterDialogIsClosed(idToFocus);
            }}
          >
            {t(taxRegimeDialog.cancel)}
          </Button>
        </Visible>
      }
      buttonPrimary={
        <Button
          data-testid="tax-regime-dialog-button"
          onClick={() => {
            handleClose(onConfirm);
            idToFocus && changeFocusAfterDialogIsClosed(idToFocus);
          }}
          variant="secondary"
        >
          {t(taxRegimeDialog.save)}
        </Button>
      }
    >
      <Text color="gray800" fontSize={16} lineHeight={22} marginBottom="16px">
        {t(taxRegimeDialog.text01)}
      </Text>
      <Text
        color="gray800"
        fontSize={16}
        lineHeight={22}
        paddingBottom={{ sm: '20px' }}
      >
        {t(taxRegimeDialog.text02)}
      </Text>
    </Dialog>
  );
};

export default TaxRegimeWarningModal;
