import { FC } from 'react';
import { Hidden } from 'react-grid-system';

import ListDesktop, {
  ItemDesktop,
} from 'modules/Contacts/SearchContactDialog/components/ContactList/ListDesktop';
import useTranslations from 'modules/I18n/hooks/useTranslations';
import { MODAL_WIDTH_SMALL, Dialog, useCloseModal } from 'modules/Modals';
import { Button, Text, Box } from 'modules/Ui';

import useExpenseLedger, { Option } from './hooks/useExpenseLedger';
import messages from './messages';

export interface SortOutExpensesModalProps {
  onConfirm: () => Promise<void>;
  setOptionSelected: (option: Option | undefined) => void;
  optionSelected: Option | undefined;
  isRecurrent?: boolean;
}

function mapExpensensDesktop(options: Option[]): ItemDesktop[] {
  return options.map(({ id, label }) => ({
    id: id as string,
    column1: label,
  }));
}

const SortOutExpensesModal: FC<SortOutExpensesModalProps> = ({
  onConfirm,
  setOptionSelected,
  optionSelected,
  isRecurrent,
}) => {
  const { t } = useTranslations();
  const closeModal = useCloseModal();

  const options = useExpenseLedger({ simple: false });

  const handleCancel = () => {
    closeModal();
  };

  return (
    <Dialog
      type="fullScreen"
      title={isRecurrent ? t(messages.titleRecurrent) : t(messages.title)}
      buttonPrimary={
        <Button
          data-testid="sort-out-expenses-submit"
          variant="secondary"
          onClick={onConfirm}
        >
          {t(messages.confirmText)}
        </Button>
      }
      buttonSecondary={
        <Hidden xs>
          <Button
            data-testid="sort-out-expenses-cancel"
            onClick={handleCancel}
            variant="stroke"
          >
            {t(messages.cancel)}
          </Button>
        </Hidden>
      }
      content={
        <Box
          aria-labelledby="expenses-title"
          display={{ _: 'grid', sm: 'initial' }}
          gridTemplateRows={{ _: 'auto 1fr', sm: 'initial' }}
          height={{ _: '100%', sm: 'initial' }}
          role="radiogroup"
          rowGap={{ _: '24px', sm: 'initial' }}
        >
          <Text
            color="primary500"
            fontSize={16}
            fontWeight={600}
            id="expenses-title"
            lineHeight={22}
            marginBottom={{ sm: '24px' }}
          >
            {isRecurrent ? t(messages.subtitleRecurrent) : t(messages.subtitle)}
          </Text>
          <Box
            marginBottom={{ sm: '24px' }}
            maxHeight={{ sm: '295px' }}
            overflowY="auto"
          >
            <ListDesktop
              {...{
                items: mapExpensensDesktop(options),
                onChange: (id: string) => {
                  const selectedOption = options.find(
                    (option) => option.id === id
                  );
                  setOptionSelected(selectedOption);
                },
                selectedItemId: optionSelected?.id,
              }}
            />
          </Box>
        </Box>
      }
      id="sort-out-expenses"
      onClose={handleCancel}
      dialogWidth={{ md: MODAL_WIDTH_SMALL }}
    />
  );
};

export default SortOutExpensesModal;
