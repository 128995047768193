import * as Yup from 'yup';

import { TranslationFunction } from 'modules/I18n/hooks/useTranslations';

import { filter } from '../../../messages';

interface ValidationSchemaOptionsProps {
  isRemittanceDocumentList?: boolean;
}

const getValidationSchema = (
  t: TranslationFunction,
  { isRemittanceDocumentList = false }: ValidationSchemaOptionsProps = {}
) => {
  return Yup.object().shape({
    searchTerm: Yup.string().min(2, t({ ...filter.searchTermMinLength })),
    issuedDateTo: Yup.date().when(
      'issuedDateFrom',
      (issuedDateFrom: Date, yup: any) => {
        if (issuedDateFrom) {
          return yup.min(
            issuedDateFrom,
            t({ ...filter.issueDateToBeforeFromDate })
          );
        }
        return yup.nullable();
      }
    ),
    minAmount: Yup.number().when('maxAmount', {
      is: (maxAmount: number) => maxAmount !== undefined,
      then: Yup.number().max(
        Yup.ref('maxAmount'),
        t({ ...filter.minAmountBiggerThanMaxAmount })
      ),
      otherwise: () =>
        isRemittanceDocumentList
          ? Yup.number().min(0, t({ ...filter.minAmountBiggerThanZero }))
          : Yup.number().nullable(),
    }),
  });
};

export default getValidationSchema;
