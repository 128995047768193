import IconByPath, { IconByPathProps } from './Icon/IconType/IconByPath';

const IconConfig = (props: IconByPathProps) => (
  <IconByPath
    {...props}
    fillRule="evenodd"
    path="M17.124 10.923 14.5 12.438V23h-5V12.366L7 10.923A2 2 0 0 1 6 9.19V4.5a2 2 0 0 1 1-1.732l2.062-1.19 1-.578v4.845a2 2 0 1 0 4 0V1l1 .577 2.062 1.191a2 2 0 0 1 1 1.732v4.69a2 2 0 0 1-1 1.733ZM13.5 12.438V22h-3V11.789L10 11.5l-2.5-1.443A1 1 0 0 1 7 9.19V4.5a1 1 0 0 1 .5-.866l1.562-.902v3.113a3 3 0 1 0 6 0V2.732l1.562.902a1 1 0 0 1 .5.866v4.69a1 1 0 0 1-.5.867L14 11.572l-.5.289v.577ZM12 21a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1Z"
  />
);

export default IconConfig;
