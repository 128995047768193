import { FC, ReactNode } from 'react';

import { LayoutProps } from 'styled-system';

import styled from 'modules/Theme/styled-components';
import { Box, Text } from 'modules/Ui';

interface PillProps extends LayoutProps {
  icon: ReactNode;
  title: string;
  text: string | ReactNode;
}

const StyledPill = styled(Box)``;

const Pill: FC<PillProps> = (props) => {
  const { icon, title, text, ...rest } = props;

  return (
    <StyledPill
      alignItems={{ _: 'flex-start' }}
      backgroundColor="auxiliary100"
      boxSizing="border-box"
      display={{ _: 'flex', sm: 'block' }}
      padding={{ _: '14px 20px' }}
      marginBottom={{ _: '8px', sm: '0' }}
      tag="li"
      textAlign={{ sm: 'center' }}
      {...rest}
    >
      {icon}
      <Box tag="span" marginLeft={{ _: '24px', sm: '0' }}>
        <Text
          color="gray800"
          display="block"
          marginBottom="2px"
          tag="span"
          fontSize={14}
          lineHeight="normal"
        >
          {title}
        </Text>
        <Text
          color="gray800"
          display="block"
          fontWeight="600"
          tag="span"
          fontSize={14}
          lineHeight="normal"
        >
          {text}
        </Text>
      </Box>
    </StyledPill>
  );
};

StyledPill.defaultProps = {
  width: { _: '100%', sm: 'calc(50% - 8px)' },
};

export default Pill;
