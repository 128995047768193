import * as Yup from 'yup';

import { formText } from 'modules/Forms/messages';
import { TranslationFunction } from 'modules/I18n/hooks/useTranslations';

export const getContactForInvoiceSchema = (
  t: TranslationFunction,
  hasElectronicProvider?: boolean
) => {
  return Yup.object().shape({
    address: Yup.object().shape({
      postalAddress: Yup.string().trim().required(t(formText.mandatory)),
      postalCode: Yup.string().trim().required(t(formText.mandatory)),
      country: Yup.string().trim().required(t(formText.mandatory)),
    }),
    fiscalName: Yup.string().trim().required(t(formText.mandatory)),
    vatNumberType: hasElectronicProvider
      ? Yup.string().trim().required(t(formText.mandatory))
      : Yup.string().nullable(),
    vatNumber: Yup.string().trim().required(t(formText.mandatory)),
  });
};
