import request from 'utils/request';

import { Provinces } from '../models/provinces';

const fetchProvinces = async (country?: string) => {
  const response = await request<{ data: Provinces }>(
    `/countries/${country || ''}/provinces`,
    {
      method: 'GET',
    },
    {
      requireAuth: false,
    }
  );

  return response.data.data as Provinces;
};

export default fetchProvinces;
