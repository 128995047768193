enum DeleteErrorCodes {
  SERIES_IN_USE = 'series-in-use',
}

type ResponseErrorSerieInUse = {
  response: { data: { code: DeleteErrorCodes } };
};

export const isResponseErrorSerieInUse = (
  e: any
): e is ResponseErrorSerieInUse => {
  return e.response?.data?.code === DeleteErrorCodes.SERIES_IN_USE;
};
