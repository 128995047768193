import { FC } from 'react';
import { Visible } from 'react-grid-system';
import { useHistory } from 'react-router';

import useTranslations from 'modules/I18n/hooks/useTranslations';
import { ModalTypes } from 'modules/Modals/constants';
import useOpenModal from 'modules/Modals/hooks/useOpenModal';
import useTheme from 'modules/Theme/hooks/useTheme';
import { Button } from 'modules/Ui';
import { IconPassword } from 'modules/Ui/Icons';

import messages from '../../messages';
import { TemplateSettingsForm } from '../types';

interface Props {
  isSubmitting?: boolean;
  values: TemplateSettingsForm;
}

const TemplateActions: FC<Props> = ({ isSubmitting, values }) => {
  const { t } = useTranslations();
  const theme = useTheme();
  const history = useHistory();
  const handleOpenPreviewModal = useOpenModal();
  return (
    <>
      <Visible xs>
        <Button
          onClick={() => history.push('/')}
          type="button"
          variant="stroke"
          flex="1"
        >
          {t(messages.cancel)}
        </Button>
        <Button
          border={`1px solid ${theme.colors.brand500}`}
          borderRadius="50%"
          color={theme.colors.brand500}
          size="48px"
          marginLeft="8px"
          onClick={() => {
            handleOpenPreviewModal({
              type: ModalTypes.CUSTOMIZE_TEMPLATE_PREVIEW,
              values,
            });
          }}
        >
          <IconPassword color="inherit" />
        </Button>
      </Visible>
      <Visible sm md lg xl xxl>
        <Button
          onClick={() => history.push('/')}
          type="button"
          variant="stroke"
        >
          {t(messages.cancel)}
        </Button>
      </Visible>
      <Button
        data-testid="acceptButton"
        disabled={isSubmitting}
        form="templates-form"
        marginTop={{ _: '8px', sm: '0' }}
        id="acceptButton"
        type="submit"
        variant="secondary"
        width={{ _: '100%', sm: 'auto' }}
      >
        {t(messages.submit)}
      </Button>
    </>
  );
};

export default TemplateActions;
