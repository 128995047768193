import { css } from 'modules/Theme/styled-components';

import {
  buttonStyles,
  buttonLinkStyles,
  buttonCustomIconTextStyles,
} from './buttonStyles';
import {
  variantPrimary,
  variantSecondary,
  variantStroke,
  variantGhost,
  variantGhostDark,
  variantLink,
  variantCustomIconText,
  variantStrokeTransparent,
} from './buttonVariants';

export const buttonPrimaryStyles = css`
  ${buttonStyles}
  ${variantPrimary}
`;

export const buttonSecondaryStyles = css`
  ${buttonStyles}
  ${variantSecondary}
`;

export const buttonStrokeStyles = css`
  ${buttonStyles}
  ${variantStroke}
`;

export const buttonStrokeTransparentStyles = css`
  ${buttonStyles}
  ${variantStrokeTransparent}
`;

export const buttonGostStyles = css`
  ${buttonStyles}
  ${variantGhost}
`;

export const buttonGostDarkStyles = css`
  ${buttonStyles}
  ${variantGhostDark}
`;

export const buttonLinkStyle = css`
  ${buttonLinkStyles}
  ${variantLink}
`;

export const buttonCustomIconTextStyle = css`
  ${buttonCustomIconTextStyles}
  ${variantCustomIconText}
`;
