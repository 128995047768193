import { DocumentKind } from 'modules/Documents/models/document';

export const ROUTES = {
  DOCUMENTS: '/documents',
  DOCUMENTS_RECURRENT: '/documents/recurrents/',
  DOCUMENTS_RECURRENT_DETAIL: '/documents/recurrents/:id',
  DOCUMENTS_RECURRENT_EDIT: '/documents/recurrents/:id/edit',
  DOCUMENTS_EXPENSES_RECURRENT_EDIT: '/documents/expenses/recurrents/:id/edit',
  DOCUMENTS_EXPENSES_RECURRENT_COPY: '/documents/expenses/recurrents/:id/copy',
  DOCUMENTS_RECURRENT_COPY: '/documents/recurrents/:id/copy',
  DOCUMENTS_UPLOAD: '/documents/:type/upload',
  DOCUMENTS_EXPENSES_UPLOAD: '/documents/expenses/upload',
  DOCUMENTS_EXPENSES_RECURRENT_CREATE: '/documents/expenses/recurrents/create',
  DOCUMENTS_EXPENSES_RECURRENT: '/documents/expenses/recurrents/',
  DOCUMENTS_EXPENSES_RECURRENT_DETAIL: '/documents/expenses/recurrents/:id',
  DOCUMENTS_UPLOAD_RESET: '/documents/upload/reset',
  DOCUMENTS_UPLOAD_EDIT: '/documents/:type/:id/edit-upload',
  DOCUMENTS_EDIT: '/documents/issued/:id/edit',
  DOCUMENTS_FIX: '/documents/issued/:id/fix',
  DOCUMENTS_COPY: '/documents/:type/:id/copy',
  DOCUMENTS_SEND: '/documents/:type/:id/send',
  DOCUMENTS_EXPENSES_EDIT: '/documents/expenses/:id/edit',
  DOCUMENTS_UPLOAD_COPY: '/documents/:type/:id/copy-upload',
  DOCUMENTS_DETAIL: '/documents/issued/:id',
  DOCUMENTS_RECTIFY: '/documents/issued/:id/rectify-substitute',
  DOCUMENTS_RECEIPTS_RECTIFY:
    '/documents/issued/:id/tickets-rectify-substitute',
  DOCUMENTS_RECTIFY_TOTAL: '/documents/issued/:id/rectify',
  DOCUMENTS_EXPENSES_DETAIL: '/documents/expenses/:id',
  DOCUMENTS_CREATE: '/documents/create',
  DOCUMENTS_CREATE_RECURRENT:
    '/documents/create/document-data?documentKind=RECURRENT',
  DOCUMENTS_ISSUED: '/documents/issued',
  DOCUMENTS_ISSUED_UPLOAD: '/documents/issued/upload',
  DOCUMENTS_EXPENSES: '/documents/expenses',
  RECEIPTS_CREATE: '/documents/tickets/create',
  RECEIPTS_EDIT: '/documents/tickets/:id/edit',
  RECEIPTS_REPLACE: '/documents/tickets/:id/replace',
  DOCUMENTS_BAI_SUBMISSION: '/documents/bai-submission',
  DOCUMENTS_BAI_RECEIPT_SUBMISSION: '/documents/bai-receipt-submission',
  DOCUMENTS_BAI_SUBMISSION_TEST: '/documents/bai-submission-test',
  DOCUMENT_TO_REVIEW: '/documents/to-review/:type/:id',
  DOCUMENTS_CREATE_TEST: `/documents/create?documentKind=${DocumentKind.TEST}`,
  DOCUMENTS_KIND: (documentKind: DocumentKind, isExpense = false) =>
    isExpense
      ? `/documents/expenses?documentKind=${documentKind}`
      : `/documents?documentKind=${documentKind}`,
  CONTACTS: '/contacts',
  PRODUCTS: '/products',
} as const;

export function buildDocumentUri(
  key: 'DOCUMENTS_DETAIL'
): (id: string) => string;
export function buildDocumentUri(
  key: keyof typeof ROUTES
): ((id: string) => string) | (() => string) {
  switch (key) {
    case 'DOCUMENTS_DETAIL':
      return (id: string): string => ROUTES.DOCUMENTS_DETAIL.replace(':id', id);
    default:
      return () => ROUTES[key] as string;
  }
}
