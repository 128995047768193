import useTranslations from 'modules/I18n/hooks/useTranslations';
import { FormikField } from 'modules/Ui';

import messages from '../messages';
import SubFormBox from './SubFormBox';

interface Props {
  isEditing: boolean;
  forContact?: boolean;
}

const OtherSubForm: React.FC<Props> = ({ isEditing = false }) => {
  const { t } = useTranslations();
  return (
    <SubFormBox isEditing={isEditing}>
      <FormikField
        maxLength={110}
        id="data.description"
        label={t(messages.other_label)}
        data-testid="description-input"
      />
    </SubFormBox>
  );
};

export default OtherSubForm;
