import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { hideModal } from 'modules/Modals/actions';

type Callback = () => void;

function useCloseModal(callback?: Callback) {
  const dispatch = useDispatch();
  return useCallback(() => {
    dispatch(hideModal());
    callback && callback();
  }, [dispatch, callback]);
}

export default useCloseModal;
