import { useQuery } from 'react-query';

import { DEFAULT_VALUES_QUERY } from 'modules/App/queries/queries';
import { getCurrentBusinessId } from 'modules/Auth/currentBusinessUtil';
import { defaultValuesToDocumentSettings } from 'modules/DefaultValues/helpers/default-values-to-settings';
import fetchAllDefaultValues from 'modules/DefaultValues/services/fetchAllDefaultValues';

import { mapToDocumentSettings } from '../services/mapDefaultValuesToSettings';

export default function useDocumentSettings() {
  const businessId = getCurrentBusinessId();
  const { data, isFetching } = useQuery(
    [DEFAULT_VALUES_QUERY, businessId],
    () => fetchAllDefaultValues({ businessId }),
    {
      staleTime: 60 * 60 * 1000,
      refetchOnMount: false,
    }
  );
  const defaultValues = defaultValuesToDocumentSettings(data?.items);
  return {
    isFetching,
    data: mapToDocumentSettings(defaultValues),
  };
}
