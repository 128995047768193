import { useCallback, useMemo, useRef } from 'react';
import { DateUtils } from 'react-day-picker';

import {
  format as dateFnsFormat,
  parse as dateFnsParse,
  Locale,
} from 'date-fns';
import * as esLocale from 'date-fns/locale/es';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';

import styled from 'modules/Theme/styled-components';
import { zIndex } from 'modules/Ui/zIndex';

import Span from '../../../Html/Span';
import { MASK, PLACEHOLDER, FORMAT, LOCALE } from './constants';
import getLocalization from './getLocalization';
import MaskedInput from './MaskedInput';

type Locales = {
  [key: string]: Locale;
};

const locales: Locales = {
  es: esLocale.default,
};

function parseDate(str: string, format: string, locale: string) {
  const parsed = dateFnsParse(str, format, new Date(), {
    locale: locales[locale],
  });
  if (DateUtils.isDate(parsed)) {
    return parsed;
  }
  return undefined;
}

function formatDate(date: Date, format: string, locale: string) {
  const tmpDate = new Date();
  date.setHours(tmpDate.getHours());
  date.setMinutes(tmpDate.getMinutes());
  date.setSeconds(tmpDate.getSeconds());
  date.setMilliseconds(tmpDate.getMilliseconds());
  return dateFnsFormat(date, format, { locale: locales[locale] });
}

export interface DayPickerProps {
  disabled?: boolean;
  id: string;
  onChange?: (value: any) => void;
  required?: boolean;
  error?: string;
  helpText?: string;
  value?: string;
  disabledDays?: (date: Date) => boolean;
}

export const Wrapper = styled(Span)`
  width: 100%;

  .DayPickerInput {
    width: 100%;

    .DayPickerInput-OverlayWrapper {
      transform: translate(-8px, -2px);
      z-index: ${zIndex.DAYPICKER};

      .DayPickerInput-Overlay {
        border: 1px solid ${(props) => props.theme.colors.gray300};
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.15);
      }

      .DayPicker-Day {
        color: ${(props) => props.theme.colors.gray600};
        font-size: ${(props) => props.theme.fontSizes[16]};
        font-weight: normal;
        line-height: 34px;
        padding: 0 4px;
      }

      .DayPicker:not(.DayPicker--interactionDisabled)
        .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover,
      .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
        background-color: ${(props) => props.theme.colors.brand500};
        border-radius: 0;
        color: ${(props) => props.theme.colors.gray0};
      }

      .DayPicker-Day--today {
        color: ${(props) => props.theme.colors.brand500};
        font-weight: normal;
      }

      .DayPicker-Day--disabled {
        color: ${(props) => props.theme.colors.gray300};
      }

      .DayPicker-Caption > div {
        color: ${(props) => props.theme.colors.gray800};
        font-size: ${(props) => props.theme.fontSizes[16]};
        font-weight: normal;
        line-height: ${(props) => props.theme.lineHeights[26]};
      }
    }
  }
`;

const DayPickerField = (props: DayPickerProps) => {
  const {
    disabled,
    id,
    onChange = () => {},
    required,
    error,
    helpText,
    disabledDays,
  } = props;

  const inputRef = useRef(null);

  const onDayChange = useCallback(
    (day) => {
      onChange(day || '');
    },
    [onChange]
  );

  const dayPickerProps = useMemo(
    () => ({
      enableOutsideDays: true,
      locale: LOCALE,
      ...getLocalization(LOCALE),
      disabledDays,
    }),
    [disabledDays]
  );

  const inputProps = useMemo(
    () => ({
      disabled,
      id,
      placeholder: PLACEHOLDER,
      required,
      type: 'text',
      mask: MASK,
      'aria-describedby': error || helpText ? `description-${id}` : undefined,
      'aria-invalid': error ? true : undefined,
      autoComplete: 'off',
    }),
    [disabled, id, error, required, helpText]
  );

  return (
    <Wrapper>
      <DayPickerInput
        formatDate={formatDate}
        parseDate={parseDate}
        {...props}
        component={MaskedInput}
        {...{
          dayPickerProps,
          id,
          inputProps,
          onDayChange,
        }}
        format={FORMAT}
        hideOnDayClick
        keepFocus={false}
        ref={inputRef}
      />
    </Wrapper>
  );
};

export default DayPickerField;
