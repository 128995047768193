import IconByPath, { IconByPathProps } from './Icon/IconType/IconByPath';

const IconBox = (props: IconByPathProps) => (
  <IconByPath
    {...props}
    clipRule="evenodd"
    fillRule="evenodd"
    path="M8.453 4.891 12 3.118l.447-.894-.447.894 8.554 4.277-3.408 1.614-8.693-4.118Zm-1.136.569-3.87 1.935 8.339 3.95a.5.5 0 0 0 .428 0l3.764-1.783L7.317 5.46Zm14.13 1.264-9-4.5a1 1 0 0 0-.894 0l-9 4.5A1 1 0 0 0 2 7.618v9.764a1 1 0 0 0 .553.894l9 4.5a1 1 0 0 0 .894 0l9-4.5a1 1 0 0 0 .553-.894V7.618a1 1 0 0 0-.553-.894ZM3 8.29v9.092l8.5 4.25v-9.324a1.492 1.492 0 0 1-.142-.059L3 8.29Zm18 9.092-8.5 4.25v-9.324a1.49 1.49 0 0 0 .142-.059L21 8.29v9.092Z"
  />
);

export default IconBox;
