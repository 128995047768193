import { isBefore, startOfDay } from 'date-fns';

import {
  DocumentPeriodType,
  DocumentStatus,
  DocumentType,
} from 'modules/Documents/models/document';
import { Currency } from 'types/currency';
import { formatISO as formatDateTime } from 'utils/dates';
import { parseToDate } from 'utils/parseToDate';

import { NewDocumentForm } from '../models/form';
import {
  CorrectiveCause,
  CorrectiveType,
} from '../Rectify/models/correctiveCause';
import { processBizkaiaData } from './processBizkaiaData';
import { processConcepts } from './processConcepts';
import { processContact } from './processContact';
import { processExpenses } from './processExpenses';
import { processPaymentMethods } from './processPaymentMethods';

const electronicProviderValues = (
  values: NewDocumentForm,
  isNotBillable: boolean,
  hasElectronicProvider: boolean
) => {
  const { operationDateTime, taxRegime, operationDescription, dueDatePeriod } =
    values;
  const getProcessedOperationDateTime = () => {
    const date = operationDateTime && parseToDate(operationDateTime);
    return date ? formatDateTime(new Date(date)) : null;
  };

  return (
    hasElectronicProvider && {
      dueDatePeriod:
        dueDatePeriod === DocumentPeriodType.NONE
          ? undefined
          : values.dueDatePeriod,
      operationDescription,
      operationDateTime: getProcessedOperationDateTime(),
      taxRegime,
      ...(!isNotBillable && processBizkaiaData(values)),
    }
  );
};

const replacingValues = (values: NewDocumentForm, isReplacing: boolean) =>
  isReplacing && {
    documentType: DocumentType.INVOICE,
    correctedDocuments: values.correctedDocuments,
    correctiveCause: CorrectiveCause.R5,
    correctiveType: CorrectiveType.S,
    isReplacementFromReceipt: true,
  };
const corretiveValues = (values: NewDocumentForm, isCorrective: boolean) =>
  isCorrective && {
    documentType: values.documentType,
    correctedDocuments: values.correctedDocuments,
    correctiveCause: values.correctiveCause,
    correctiveType: CorrectiveType.I,
  };

const noReceiptValues = ({
  isReceipt,
  values,
  hasElectronicProvider,
}: {
  isReceipt: boolean;
  values: NewDocumentForm;
  hasElectronicProvider: boolean;
}) => {
  if (isReceipt) {
    return {};
  }

  const processedExpenses = processExpenses(
    values.hasAdditionalExpenses,
    values.additionalExpenses
  );

  const dueDateTime = normalizeDueDate(values, hasElectronicProvider);
  return {
    retentionPercentage: values.hasRetentions
      ? values.retentionPercentage ?? 0
      : 0,
    hasSalesEqualizationTax: values.hasSalesEqualizationTax ?? false,
    additionalExpenses: processedExpenses,
    contact: processContact(values.contact) ?? null,
    dueDateTime,
    paymentMethodsAccepted: processPaymentMethods(values.paymentMethods),
    showLinesWithoutTaxes: values.showLinesWithoutTaxes,
  };
};

function normalizeDueDate(
  values: NewDocumentForm,
  hasElectronicProvider: boolean
): string | null {
  if (!values.dueDate) {
    return null;
  }
  const creationDate = hasElectronicProvider
    ? startOfDay(new Date())
    : startOfDay(values.creationDate ?? new Date());
  const isDueDateBeforeCreationDate = isBefore(values.dueDate, creationDate);
  const dueDate = isDueDateBeforeCreationDate ? new Date() : values.dueDate;
  return formatDateTime(dueDate) || null;
}

export function normalizeRegularValues({
  values,
  hasElectronicProvider,
  isDraft,
  isTest,
  isCorrective = false,
  isReceipt = false,
  forceFixedDate,
  isReplacing = false,
  isNotBillable = false,
}: {
  values: NewDocumentForm;
  hasElectronicProvider?: boolean;
  isDraft?: boolean;
  isTest?: boolean;
  isCorrective?: boolean;
  isReceipt?: boolean;
  forceFixedDate?: boolean;
  isReplacing?: boolean;
  isNotBillable?: boolean;
}) {
  const {
    code,
    comments,
    concepts,
    creationDate,
    hasPaidAmount,
    paidAmount,
    reference,
    serialCode,
    serieId,
    shouldRedirectAfterDraftCreation,
    showComments,
    showProductReference,
    status,
    templateId,
    currency,
  } = values;

  const issuedDateTime = formatDateTime(creationDate) || null;
  const processedConcepts = concepts
    ? processConcepts(concepts, {
        hasElectronicProvider,
      })
    : [];

  const sendCodeAndDate =
    (!hasElectronicProvider || isNotBillable || isTest) ?? forceFixedDate;

  const hasFile = isNotBillable && values.file;
  return {
    shouldRedirectAfterDraftCreation,
    serieId,
    currency: currency ?? Currency.EUR,
    paidAmount: hasPaidAmount ? paidAmount : 0,
    serialCode: serialCode ?? null,
    comments: showComments && comments ? comments : null,
    status: isTest ? DocumentStatus.TEST : status ?? null,
    reference: reference ?? null,
    id: values.id,
    isFileAttached: !!values.isFileAttached,
    hasFile: !!values.hasFile,
    concepts: processedConcepts,
    isDefaultDraft: !!isDraft,
    showProductReference,
    templateId,
    ...(isReceipt && {
      isReceipt: true,
    }),
    ...(isTest && {
      isTest: true,
    }),
    ...noReceiptValues({
      isReceipt,
      values,
      hasElectronicProvider: !!hasElectronicProvider,
    }),
    ...(hasElectronicProvider &&
      electronicProviderValues(values, isNotBillable, hasElectronicProvider)),
    ...(sendCodeAndDate && {
      code: code ?? null,
      issuedDateTime,
    }),
    ...(hasFile && {
      file: values.file,
    }),
    ...corretiveValues(values, isCorrective),
    ...replacingValues(values, isReplacing),
  };
}
