import { FC, useState } from 'react';

import useIsBookkeeperUser from 'modules/Bookkeeper/hooks/useIsBookkeeperUser';
import useTranslations from 'modules/I18n/hooks/useTranslations';
import useTheme from 'modules/Theme/hooks/useTheme';
import { Box } from 'modules/Ui';
import {
  RegularFaqs,
  BaiFaqs,
  BookkeeperFaqs,
} from 'modules/Ui/FaqsContainer/Faqs';
import Tabs from 'modules/Ui/Tabs';
import Tab from 'modules/Ui/Tabs/Components/Tab';
import TabPanel from 'modules/Ui/Tabs/Components/TabPanel';

import messages from './messages';

interface FAQContainerProps {
  showBai?: boolean;
}

const FaqsContainer: FC<FAQContainerProps> = ({ showBai = false }) => {
  const [currentTab, setCurrentTab] = useState(0);
  const theme = useTheme();
  const { t } = useTranslations();
  const showBookkeeper = useIsBookkeeperUser();
  const FaqComponent = showBookkeeper ? BookkeeperFaqs : RegularFaqs;
  return (
    <Box backgroundColor="gray0" border={`1px solid ${theme.colors.gray200}`}>
      {showBai ? (
        <>
          <Box
            borderBottom={`1px solid ${theme.colors.gray200}`}
            padding={{
              _: '24px 8px 12px',
              sm: '32px 40px 20px',
              md: '32px 40px 20px',
            }}
          >
            <Tabs
              value={currentTab}
              onChange={(_, newTab) => {
                setCurrentTab(newTab);
              }}
            >
              <Tab label={t(messages.tab02)} />
              <Tab label={t(messages.tab01)} />
            </Tabs>
          </Box>
          <TabPanel value={currentTab} index={0}>
            <BaiFaqs />
          </TabPanel>
          <TabPanel value={currentTab} index={1}>
            <FaqComponent />
          </TabPanel>
        </>
      ) : (
        <FaqComponent />
      )}
    </Box>
  );
};

export default FaqsContainer;
