import { useInvalidateAndUpdate } from 'modules/App/hooks/useInvalidateAndUpdate';
import useNotifications from 'modules/Notifications/hooks/useNotifications';
import { useBlockingMutation } from 'utils/useBlockingMutation';

import deleteDocumentToReview, {
  invalidateQueries,
} from '../../services/deleteDocumentToReview';
import messages from './messages';

export default function useDeleteDocumentToReview(
  silenceNotifications: boolean = false
) {
  const invalidateAndUpdate = useInvalidateAndUpdate();
  const notifications = useNotifications();

  const { mutate } = useBlockingMutation(deleteDocumentToReview, {
    onSuccess: () => {
      if (!silenceNotifications) {
        notifications.success(messages.deleted.id);
      }
      invalidateAndUpdate({ invalidateQueries });
    },
    onError: () => {
      notifications.error(messages.errorDeleting.id);
    },
  });
  return mutate;
}
