import { useSelector } from 'react-redux';

import { useInvalidateAndUpdate } from 'modules/App/hooks/useInvalidateAndUpdate';
import {
  hasElectronicDocumentProvider,
  shouldSendElectronicDocument,
} from 'modules/Business/businessUtils';
import { selectCurrentBusiness } from 'modules/Business/Current/selectors';
import useNotifications from 'modules/Notifications/hooks/useNotifications';
import history from 'utils/history';
import { useBlockingMutation } from 'utils/useBlockingMutation';

import { errors } from '../messages';
import { SHOPIFY_CONFIGURATION_UPDATE } from '../paths';
import updateShopifyConfig, {
  invalidateQueries,
} from '../services/shopify/updateShopifyConfig';
import { useShopifyConfiguration } from './useShopifyConfiguration';

export const useUpdateShopifyConfiguration = () => {
  const invalidateAndUpdate = useInvalidateAndUpdate();
  const notifications = useNotifications();
  const business = useSelector(selectCurrentBusiness);
  const isBai = business && hasElectronicDocumentProvider(business);
  const sendsElectronicDocument = shouldSendElectronicDocument(business);

  const showBaiConnectionWarning = isBai && !sendsElectronicDocument;

  const { data } = useShopifyConfiguration();
  const { mutate } = useBlockingMutation(updateShopifyConfig, {
    onSuccess: async (values) => {
      invalidateAndUpdate({ invalidateQueries });
      if (!data?.isSavedForFirstTime && values.isSavedForFirstTime) {
        return history.push(SHOPIFY_CONFIGURATION_UPDATE);
      }
      if (values.isActive) {
        if (showBaiConnectionWarning) {
          return notifications.warning(
            errors.ShopifyConfigBaiNotConnectedWarning.id
          );
        }
        return notifications.success(errors.ShopifyConfigSuccess.id);
      }
      return notifications.warning(errors.ShopifyConfigNotConnected.id);
    },
    onError: () => {
      return notifications.error(errors.ShopifyConfigError.id);
    },
  });
  return mutate;
};
