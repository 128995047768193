import IconByPath, { IconByPathProps } from './Icon/IconType/IconByPath';

const IconTransfer = (props: IconByPathProps) => (
  <IconByPath
    {...props}
    path="M20.336 7.85 16.8 4.315l-.707.707L19.07 8H10v1h9.186l-3.093 3.093.707.707 4.243-4.243-.707-.707Zm-16.629 9.3 3.536 3.535.707-.707L4.972 17H14v-1H4.857l3.093-3.093-.707-.707L3 16.443l.707.707Z"
  />
);

export default IconTransfer;
