import React from 'react';

import useTranslations from 'modules/I18n/hooks/useTranslations';
import WarnBeforeNavigationDialog from 'modules/Ui/WarnBeforeNavigationDialog';

import { ApiConfigurationDialog } from '../../messages';

const ApiConfigurationConfirmNavigationDialog: React.FC = () => {
  const { t } = useTranslations();

  return (
    <WarnBeforeNavigationDialog
      title={t(ApiConfigurationDialog.title)}
      subtitle={t(ApiConfigurationDialog.subtitle)}
      confirm={t(ApiConfigurationDialog.save)}
      cancel={t(ApiConfigurationDialog.cancel)}
      question={t(ApiConfigurationDialog.text)}
    />
  );
};

export default ApiConfigurationConfirmNavigationDialog;
