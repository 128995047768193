import IconByPath, { IconByPathProps } from './Icon/IconType/IconByPath';

const IconInbox = (props: IconByPathProps) => {
  return (
    <IconByPath
      {...props}
      clipRule="evenodd"
      fillRule="evenodd"
      path="M2.34 10.5 4.7 6h14.6l2.36 4.5h-6.196a3.502 3.502 0 0 1-6.93 0H2.341Zm5.416 1H2v-.354V17h20v-5.5h-5.756a4.502 4.502 0 0 1-8.488 0ZM4.096 5h15.809L23 10.9V18H1v-7.1L4.095 5Z"
    />
  );
};
export default IconInbox;
