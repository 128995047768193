import { useCallback, useState } from 'react';

import omit from 'lodash/omit';

import { FormikFieldProps } from 'modules/Ui/Formik/FormikFieldProps';

import IconPassword from '../../Icons/IconPassword';
import IconPasswordHidden from '../../Icons/IconPasswordHidden';
import Button from '../Button';
import Description from '../Commons/Description';
import Input from '../Commons/Input';
import InputWrapper from '../Commons/InputWrapper';
import Label from '../Commons/Label';
import LabelText from '../Commons/LabelText';

export interface PasswordProps extends FormikFieldProps {}

const PasswordField = (props: PasswordProps) => {
  const {
    className,
    disabled,
    error,
    helpText,
    id,
    initiallyShown = false,
    label,
    required,
    value,
    ...rest
  } = props;
  const [statePasswordShown, setPasswordShown] = useState(initiallyShown);
  const togglePasswordShownState = useCallback(() => {
    setPasswordShown(!statePasswordShown);
  }, [statePasswordShown]);
  return (
    <Label {...{ id, ...omit(rest, ['data-testid']) }}>
      <LabelText {...{ required, disabled }}>{label}</LabelText>
      <InputWrapper
        hasIcon
        {...{
          error,
          disabled,
        }}
      >
        <Input
          {...{
            disabled,
            error,
            id,
            required,
            value,
            ...rest,
          }}
          aria-describedby={`password-description-${id}`}
          type={statePasswordShown ? 'text' : 'password'}
        />
        <Button aria-hidden="true" onClick={togglePasswordShownState}>
          {statePasswordShown ? (
            <IconPassword color="brand500" />
          ) : (
            <IconPasswordHidden color="brand500" />
          )}
        </Button>
      </InputWrapper>
      <Description id={`description-${id}`} hasError={!!error}>
        {error || helpText}
      </Description>
    </Label>
  );
};

export default PasswordField;
