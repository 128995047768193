import { isEmpty } from 'lodash';
import * as yup from 'yup';

import { nifUtils } from 'utils/nif-validations';
import removeWhitespaces from 'utils/removeWhitespaces';

const IBAN_FORMAT_REGEX = /^[a-zA-Z]{2}\d{22}/; // two letters and 22 numbers
const SWIFT_FORMAT_REGEX =
  /^[A-Z]{6,6}[A-Z2-9][A-NP-Z0-9]([A-Z0-9]{3,3}){0,1}$/;

type NullableString = string | null | undefined;

yup.addMethod<yup.StringSchema>(
  yup.string,
  'ibanFormat',
  function test(this: yup.StringSchema, message: string) {
    // this: yup.StringSchema is a fake parameter to specify the type of this inside the function
    return this.test(`iban-format`, message, (value: NullableString) => {
      const iban = removeWhitespaces(value ?? '');
      if (isEmpty(iban)) {
        return true;
      }
      return IBAN_FORMAT_REGEX.test(iban);
    });
  }
);

yup.addMethod<yup.StringSchema>(
  yup.string,
  'swiftFormat',
  function test(message: string) {
    return this.test(`swift-format`, message, (value: NullableString) => {
      const swift = removeWhitespaces(value ?? '');
      if (isEmpty(swift)) {
        return false;
      }
      return SWIFT_FORMAT_REGEX.test(swift);
    });
  }
);

yup.addMethod<yup.StringSchema>(
  yup.string,
  'validateNif',
  function test(message: string, onlyRegex?: boolean) {
    return this.test('validate-nif', message, (value: NullableString) =>
      nifUtils.validateNif(value, onlyRegex)
    );
  }
);

yup.addMethod<yup.StringSchema>(
  yup.string,
  'validateNifIva',
  function test(message: string) {
    return this.test('validate-nif-iva', message, (value: NullableString) =>
      nifUtils.validateNifIva(value)
    );
  }
);

yup.addMethod<yup.StringSchema>(
  yup.string,
  'validateNifRules51to53',
  function test(message: string, onlyRegex?: boolean) {
    return this.test(
      'validate-nif-rules-51-to-53',
      message,
      (value: NullableString) =>
        nifUtils.validateNifRules51to53(value, onlyRegex)
    );
  }
);

declare module 'yup' {
  interface StringSchema<
    T extends string | null | undefined = string | undefined,
    C = object
  > extends yup.Schema<T, C> {
    validateNif(message: string, onlyRegex?: boolean): StringSchema<T, C>;
    validateNifIva(message: string): StringSchema<T, C>;
    validateNifRules51to53(
      message: string,
      onlyRegex?: boolean
    ): StringSchema<T, C>;
    ibanFormat(message: string): StringSchema<T, C>;
    swiftFormat(message: string): StringSchema<T, C>;
  }
}

export default yup;
