import { FC, ReactNode } from 'react';
import { Visible } from 'react-grid-system';
import { useHistory } from 'react-router-dom';

import { History } from 'history';

import FormBannerBai from 'modules/Documents/components/Form/BannerBAI/FormBannerBAI';
import useTranslations from 'modules/I18n/hooks/useTranslations';
import useTheme from 'modules/Theme/hooks/useTheme';
import { Button, Text } from 'modules/Ui';
import AppBar from 'modules/Ui/AppBar';
import { IconArrowSecondary, IconCross } from 'modules/Ui/Icons';
import Toolbar from 'modules/Ui/Toolbar';

import Box from '../Box';
import messages from './messages';

type Props = {
  title: string;
  hiddenBackButton?: boolean;
  onClickBack?: (history: History) => void;
  onClickClose?: (history: History) => void;
  variant?: 'business' | 'bookkeeper';
  secondaryButton?: ReactNode; // top right button
};

const NavigationBar: FC<Props> = ({
  hiddenBackButton = false,
  onClickBack = (hist) => hist.goBack(),
  onClickClose,
  title,
  variant = 'business',
  secondaryButton,
}) => {
  const history = useHistory();
  const { colors } = useTheme();
  const { primary500, gray0 } = colors;
  const { t } = useTranslations();

  const infoSecondaryButton = secondaryButton || (
    <Button
      aria-label={t(messages.close)}
      color={variant === 'business' ? primary500 : gray0}
      onClick={(event) => {
        event.stopPropagation();
        onClickClose && onClickClose(history);
      }}
      type="button"
      height="24px"
    >
      <IconCross />
    </Button>
  );

  const PrimaryAction = () =>
    hiddenBackButton ? null : (
      <Button
        aria-label={t(messages.back)}
        onClick={() => onClickBack(history)}
      >
        <IconArrowSecondary
          color={variant === 'business' ? primary500 : gray0}
          transform="rotate(-180deg)"
        />
      </Button>
    );

  return (
    <Visible xs sm>
      <AppBar>
        <Toolbar isBookkeeper={variant === 'bookkeeper'}>
          <Box height="24px">
            <PrimaryAction />
          </Box>
          <Text
            color={variant === 'business' ? 'gray800' : 'gray0'}
            hasEllipsis
            marginRight={onClickClose ? '0' : '24px'}
            tag="h1"
            textAlign="center"
            fontSize={18}
            lineHeight={22}
          >
            {title}
          </Text>
          {onClickClose && infoSecondaryButton}
        </Toolbar>
      </AppBar>
      <FormBannerBai />
    </Visible>
  );
};

export default NavigationBar;
