import { useCallback } from 'react';
import { MessageDescriptor } from 'react-intl';

import { filter, tags } from 'modules/Documents/List/messages';
import {
  DigitalDocumentStatus,
  DocumentFilters,
  DocumentStatus,
  DocumentType,
  DueDate,
} from 'modules/Documents/models/document';
import { StatusInfoUI } from 'modules/Documents/models/statusInfo';
import useTranslations from 'modules/I18n/hooks/useTranslations';
import { ValidGenders } from 'modules/I18n/types/validGenders';
import TagListUI from 'modules/Ui/TagList/TagListUI';
import formatDate from 'utils/dates/formatDate';

interface TagsProps {
  filters: DocumentFilters;
  onChangeSearch: (filters: DocumentFilters) => void;
  documentGender: ValidGenders;
  documentType?: DocumentType;
}

interface TagElement {
  filterKey: keyof DocumentFilters;
  formattedValue?: string;
}

const TagList: React.FC<TagsProps> = ({
  onChangeSearch,
  filters,
  documentGender,
  documentType = DocumentType.INVOICE,
}) => {
  const isExpense = documentType === DocumentType.EXPENSE;
  const onClearTermClick = useCallback(
    (key: string) => {
      const newFilters = {
        ...filters,
        [key]: undefined,
      };
      onChangeSearch(newFilters);
    },
    [onChangeSearch, filters]
  );
  const { t } = useTranslations();

  function formatStatusInfo(statusInfo?: StatusInfoUI): TagElement | null {
    if (!statusInfo) return null;
    const values: Partial<Record<StatusInfoUI, string>> = {
      [StatusInfoUI.PAID]: t(isExpense ? tags.paidExpense : tags.paid),
      [StatusInfoUI.PARTIALLY_PAID]: t(
        isExpense ? tags.partiallyPaidExpense : tags.partiallyPaid
      ),
      [StatusInfoUI.NOT_PAID]: t(isExpense ? tags.noPaidExpense : tags.noPaid),
      [StatusInfoUI.ISSUED]: t(isExpense ? tags.issuedExpense : tags.issued),
      [StatusInfoUI.DUE]: t(tags.due),
      [StatusInfoUI.DRAFT]: t(tags.draft),
      [StatusInfoUI.PENDING]: t(tags.pending),
      [StatusInfoUI.ACCEPTED]: t(tags.accepted),
      [StatusInfoUI.REJECTED]: t(tags.rejected),
      [StatusInfoUI.VOID]: t(tags.void),
      [StatusInfoUI.CORRECTIVE]: t(tags.corrective),
      [StatusInfoUI.CORRECTED]: t(tags.corrected),
      [StatusInfoUI.REPLACED]: t(tags.replaced),
      [StatusInfoUI.INVOICED]: t(tags.invoiced),
      [StatusInfoUI.NOINVOICED]: t(tags.noiInvoiced),
      [StatusInfoUI.CANCELLED]: t(tags.canceled),
      [StatusInfoUI.DELIVERED]: t(tags.delivered),
    };
    return {
      filterKey: 'statusInfo',
      formattedValue: values[statusInfo],
    };
  }

  function formatStatus(status?: DocumentStatus): TagElement | null {
    if (!status) return null;
    if (status === DocumentStatus.DRAFT) {
      return {
        filterKey: 'status',
        formattedValue: t(tags.draft),
      };
    }
    if (status === DocumentStatus.ISSUED) {
      return {
        filterKey: 'status',
        formattedValue: t(tags.issued),
      };
    }
    if (status === DocumentStatus.VOID) {
      return {
        filterKey: 'status',
        formattedValue: t(tags.void),
      };
    }
    return null;
  }

  function formatIssuedDate(
    filterKey: keyof DocumentFilters,
    translationKey: MessageDescriptor,
    date?: string
  ): TagElement | null {
    if (!date) return null;
    return {
      filterKey,
      formattedValue: t(translationKey, { issuedDate: formatDate(date) }),
    };
  }

  function formatAmount(
    filterKey: keyof DocumentFilters,
    translationKey: MessageDescriptor,
    amount?: number | string
  ): TagElement | null {
    if (amount === undefined || amount === null || amount === '') {
      return null;
    }
    return {
      filterKey,
      formattedValue: t(translationKey, { amount }),
    };
  }

  function formatTag(
    gender: ValidGenders,
    tag?: StatusInfoUI
  ): TagElement | null {
    switch (tag) {
      case StatusInfoUI.ACCEPTED:
        return {
          filterKey: 'tag',
          formattedValue: t(filter.documentTagAcepted, { gender }),
        };
      case StatusInfoUI.PENDING:
        return {
          filterKey: 'tag',
          formattedValue: t(filter.documentTagPending, { gender }),
        };
      case StatusInfoUI.REJECTED:
        return {
          filterKey: 'tag',
          formattedValue: t(filter.documentTagRejected, { gender }),
        };
      case StatusInfoUI.INVOICED:
        return {
          filterKey: 'tag',
          formattedValue: t(filter.documentTagInoviced, { gender }),
        };
      case StatusInfoUI.NOINVOICED:
        return {
          filterKey: 'tag',
          formattedValue: t(filter.documentTagNotInoviced, { gender }),
        };
      case StatusInfoUI.CANCELLED:
        return {
          filterKey: 'tag',
          formattedValue: t(filter.documentTagCanceled, { gender }),
        };
      case StatusInfoUI.DELIVERED:
        return {
          filterKey: 'tag',
          formattedValue: t(filter.documentTagDelivered, { gender }),
        };
      default:
        return null;
    }
  }

  function formatDueDate(due?: string): TagElement | null {
    let formattedDueDate = null;
    if (due === DueDate.TODAY) {
      formattedDueDate = t(tags.dueToday);
    } else if (due === DueDate.THIS_WEEK) {
      formattedDueDate = t(tags.dueWeek);
    } else if (due === DueDate.THIS_MONTH) {
      formattedDueDate = t(tags.dueMonth);
    } else if (due === DueDate.EXPIRED) {
      formattedDueDate =
        documentGender === ValidGenders.M
          ? t(tags.dueExpiredQuotes)
          : t(tags.dueExpired);
    }

    return formattedDueDate
      ? {
          filterKey: 'dueDate',
          formattedValue: formattedDueDate,
        }
      : null;
  }

  function formatDigitalDocumentStatus(status?: DigitalDocumentStatus) {
    return status
      ? {
          filterKey: 'digitalDocumentStatus',
          formattedValue: t(tags[`digitalDocumentStatus${status}`]),
        }
      : null;
  }

  const formattedFilters: TagElement[] = [
    formatStatus(filters.status),
    formatStatusInfo(filters.statusInfo),
    formatTag(documentGender, filters.tag),
    formatIssuedDate(
      'issuedDateFrom',
      tags.issuedDateFrom,
      filters.issuedDateFrom
    ),
    formatIssuedDate('issuedDateTo', tags.issuedDateTo, filters.issuedDateTo),
    formatIssuedDate(
      'updatedDateFrom',
      tags.issuedDateFrom,
      filters.updatedDateFrom
    ),
    formatIssuedDate('updatedDateTo', tags.issuedDateTo, filters.updatedDateTo),
    formatDueDate(filters.dueDate),
    formatDigitalDocumentStatus(filters.digitalDocumentStatus),
    formatAmount('minAmount', tags.minAmount, filters.minAmount),
    formatAmount('maxAmount', tags.maxAmount, filters.maxAmount),
  ].filter((e): e is TagElement => e !== null && e !== undefined);

  const isEmptyFilters = formattedFilters.length === 0;

  return isEmptyFilters ? null : (
    <TagListUI
      {...{
        formattedFilters,
        onClearTermClick,
        tags,
      }}
    />
  );
};

export default TagList;
