import { getConfig } from 'modules/App/config';
import { ACCOUNTING_ACCOUNTS_QUERY } from 'modules/App/queries/queries';
import request from 'utils/request';

import { AccountingAccount, UpdateAccountingAccountDto } from '../models';

export interface UpdateAccountingAccountResponse {
  data: AccountingAccount;
}

const updateAccountingAccount = async ({
  id,
  ...data
}: UpdateAccountingAccountDto): Promise<UpdateAccountingAccountResponse> => {
  const endpoint = (await getConfig()).apiAccountingAccountsService;

  const response = await request<UpdateAccountingAccountResponse>(
    `${endpoint}/${id}`,
    {
      method: 'PUT',
      data,
    }
  );
  return response.data;
};

export const invalidateQueries = [ACCOUNTING_ACCOUNTS_QUERY];

export default updateAccountingAccount;
