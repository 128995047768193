import { useSelector } from 'react-redux';

import { Scope } from 'modules/Auth/models/scope';
import { Scopes } from 'modules/Auth/models/scopes';
import { ApplicationRootState } from 'types';

import { selectHasEditorScope } from '../selectors';

const selectUserScopes = (state: ApplicationRootState) => state.auth.scopes;

const useHasUserScope = () => {
  const scopes = useSelector(selectUserScopes);
  const hasUserScope = (scopeToEvaluate: Scopes): boolean | undefined =>
    scopes?.some((scope: Scope) => scope.id === scopeToEvaluate) || undefined;

  const isEditor = useSelector(selectHasEditorScope);

  return {
    hasUserScope,
    isEditor,
    loaded: scopes !== undefined,
  };
};

export default useHasUserScope;
