import isEqual from 'lodash/isEqual';

import { Flags, Source, SourcesFlags } from '../model';

export const resolveFlags = (
  sourceFlags: SourcesFlags,
  priorityOrder: Source[]
): Flags => {
  return priorityOrder.reduce((acc: Flags, current: Source) => {
    return {
      ...sourceFlags[current],
      ...acc,
    };
  }, {});
};

export const flagsAreEqual = (flags1: Flags, flags2: Flags) => {
  return isEqual(flags1, flags2);
};

export const ffStrToObj = (ffStr: string) => {
  if (!ffStr) return {};
  const flags = ffStr.split(',');
  return flags.reduce((acc: Flags, current: string) => {
    const flag = current.split('=');
    return { ...acc, [flag[0]]: getFlagValue(flag[1]) };
  }, {});
};

export const getFlagValue = (value: string) => {
  if (value === undefined || value === 'true') {
    return true;
  }
  return value === 'false' ? false : value;
};
