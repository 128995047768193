import { FC } from 'react';
import { Hidden } from 'react-grid-system';

import useTranslations from 'modules/I18n/hooks/useTranslations';
import { Dialog, useCloseModal } from 'modules/Modals';
import { Button, Warning } from 'modules/Ui';

import { update } from './messages';
import { UpdatePaymentMethodModalProps } from './types';
import UpdatePaymentMethodForm from './UpdatePaymentMethodForm/UpdatePaymentMethodForm';

const UpdatePaymentMethodModal: FC<UpdatePaymentMethodModalProps> = ({
  onSuccess,
  paymentMethod,
  showCheckForNewDocuments,
  forContact,
}) => {
  const { t } = useTranslations();

  const closeModal = useCloseModal();

  return (
    <Dialog
      id="updatePaymentMethodModal"
      onClose={closeModal}
      title={t(forContact ? update.titleForContact : update.title)}
      type="fullScreen"
      buttonSecondary={
        <Hidden xs>
          <Button onClick={closeModal} variant="stroke">
            {t(update.cancel)}
          </Button>
        </Hidden>
      }
      buttonPrimary={
        <Button
          data-testid="update-payment-method-modal"
          form="update-payment-method-modal-form"
          type="submit"
          variant="secondary"
        >
          {t(update.confirm)}
        </Button>
      }
    >
      {!showCheckForNewDocuments && <Warning message={t(update.infoText)} />}
      <UpdatePaymentMethodForm
        {...{
          paymentMethod,
          onSubmit: onSuccess,
          forContact,
          showCheckForNewDocuments,
        }}
      />
    </Dialog>
  );
};

export default UpdatePaymentMethodModal;
