import { FC, useMemo } from 'react';
import { Hidden } from 'react-grid-system';

import { Formik } from 'formik';

import Business from 'modules/Business/models/business';
import useTranslations from 'modules/I18n/hooks/useTranslations';
import { MODAL_WIDTH_SMALL, Dialog, useCloseModal } from 'modules/Modals';
import styled from 'modules/Theme/styled-components';
import { MarkDown, Button, FormikField } from 'modules/Ui';

import { salesCodeDialog } from '../../messages';
import useUpdateSalesCode from './hooks/useUpdateSalesCode';
import getValidationSchema from './validation';

const Text = styled(MarkDown)`
  strong {
    font-weight: 600;
  }
`;

export interface Props {
  business: Business;
  onSalesCodeAdded?: () => void;
}

const AddSalesCodeModal: FC<Props> = ({ business, onSalesCodeAdded }) => {
  const { fiscalName } = business;
  const { t } = useTranslations();
  const { mutate } = useUpdateSalesCode(onSalesCodeAdded);

  const closeModal = useCloseModal();
  const validationSchema = useMemo(() => getValidationSchema(t), [t]);
  const initialValues = { salesCode: '' };

  return (
    <Dialog
      id="addSalesCode"
      type="fullScreen"
      dialogWidth={{ sm: 'calc(100% - 72px)', md: MODAL_WIDTH_SMALL }}
      onClose={closeModal}
      title={t(salesCodeDialog.title)}
      buttonSecondary={
        <Hidden xs>
          <Button
            onClick={() => {
              closeModal();
            }}
            variant="stroke"
          >
            {t(salesCodeDialog.cancel)}
          </Button>
        </Hidden>
      }
      buttonPrimary={
        <Button
          variant="secondary"
          form="add-sales-code-modal-form"
          type="submit"
        >
          {t(salesCodeDialog.confirm)}
        </Button>
      }
    >
      <Text
        marginBottom="24px"
        color="gray600"
        text={t(salesCodeDialog.text, {
          fiscalName,
        })}
      />
      <Formik
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={(values, actions) => {
          actions.setSubmitting(false);
          closeModal();
          const { salesCode } = values;
          const { id } = business;
          const newBusiness = {
            bookkeepingData: {
              salesCode,
            },
          };
          mutate({ business: newBusiness, businessId: id });
        }}
        {...{ validationSchema, initialValues }}
      >
        {(formik) => (
          <form
            data-testid="add-sales-code-modal-form"
            id="add-sales-code-modal-form"
            noValidate
            onSubmit={formik.handleSubmit}
          >
            <FormikField
              data-testid="salesCode"
              id="salesCode"
              name="salesCode"
              label={t(salesCodeDialog.fieldLabel)}
              marginBottom="26px"
              required
              width={{ _: '100%', sm: '280px' }}
            />
          </form>
        )}
      </Formik>
    </Dialog>
  );
};

export default AddSalesCodeModal;
