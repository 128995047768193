import { IBrowser } from 'redux-responsive/types';
import { SagaIterator } from 'redux-saga';
import { call, takeLatest, put, select } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';

import { AnalyticsEvent } from 'modules/Analytics';
import analyticsService from 'modules/Analytics/services/analyticsService';
import * as selectors from 'modules/App/store/selectors';
import { isDuplicatedVatNumberOnPostBusiness } from 'modules/Business/Create/services/createBusiness';
import messages from 'modules/Business/Update/messages';
import { HOME_ROUTES } from 'modules/Home/routes';
import { createNotification } from 'modules/Notifications/actions';
import { NotificationType } from 'modules/Notifications/models';
import { ROUTES as BOOKKEEPER_ROUTES } from 'pages/bookkeepers/routesMap';
import { ROUTES as LEADS_ROUTES } from 'pages/leads/routesMap';
import history from 'utils/history';
import isNativeApp from 'utils/native/isNativeApp';
import requestErrorHandler from 'utils/requestErrorHandler';

import { texts } from '../Login/messages';
import * as actions from './actions';
import ActionTypes from './constants';
import { createLead, login, registerFromLead } from './services';
import { setAuthDataInLocal } from './services/authenticate';

export function* createLeadSaga(
  action: ActionType<typeof actions.createLead.request>
): SagaIterator {
  try {
    const { isBookkeeper, email: payloadEmail } = action.payload;
    yield call([analyticsService, analyticsService.leadFormSubmitted], {
      isBookkeeper,
      isNative: isNativeApp(),
      email: payloadEmail,
    });
    const { email, convertToUserUrl } = yield call(createLead, action.payload);
    yield put(actions.createLead.success());
    if (isBookkeeper) {
      yield call(
        history.push,
        `${
          LEADS_ROUTES.REGISTER_BOOKKEEPER_QUESTION
        }?email=${encodeURIComponent(email)}`
      );
    } else {
      const url = new URL(convertToUserUrl);
      const path = url.pathname + url.search;
      window.location.href = `${window.location.origin}${path}`;
    }
  } catch (e: any) {
    const { isNetworkError, networkErrorTranslationKey, responseStatus } =
      requestErrorHandler(e);

    if (isNetworkError) {
      yield put(
        createNotification({
          type: NotificationType.ERROR,
          message: networkErrorTranslationKey,
        })
      );
    } else {
      yield put(
        actions.createLead.failure({
          code: responseStatus ?? 500,
          data: e?.response?.data,
        })
      );
    }
  }
}

export function* loginSaga(
  action: ActionType<typeof actions.login.request>
): SagaIterator {
  const { errorMessage, redirectRoute, ...values } = action.payload;
  try {
    const auth = yield call(login, values);
    if (auth === null) {
      return;
    }
    yield put(actions.setScopes(auth.scopes));
    yield put(actions.login.success());

    if (redirectRoute) {
      yield call(history.push, redirectRoute);
    }
  } catch (e: any) {
    yield put(actions.login.failure(e));

    const { responseStatus, isNetworkError, networkErrorTranslationKey } =
      requestErrorHandler(e);
    const message =
      responseStatus === 401
        ? texts.emailPasswordError.id
        : action.payload.errorMessage;
    yield put(
      createNotification({
        type: NotificationType.ERROR,
        message: (isNetworkError ? networkErrorTranslationKey : message) || '',
      })
    );
  }
}

export function* loginUsingTokenSaga(
  action: ActionType<typeof actions.loginUsingToken>
): SagaIterator {
  const { auth, redirectRoute, email } = action.payload;
  if (!auth) {
    return;
  }
  yield call(setAuthDataInLocal, auth, email);
  yield put(actions.setScopes(auth.scopes));
  yield put(actions.login.success());
  if (redirectRoute) {
    yield call(history.push, redirectRoute);
  }
}

export function* registerFromLeadSaga(
  action: ActionType<typeof actions.registerFromLead.request>
): SagaIterator {
  try {
    const browser: IBrowser = yield select(selectors.selectBrowser);
    const auth = yield call(registerFromLead, { ...action.payload, browser });
    yield put(actions.setScopes(auth.scopes));
    yield put(actions.registerFromLead.success());
    yield call(
      [analyticsService, analyticsService.identify],
      auth.businessId,
      auth.userId
    );
    yield call(
      [analyticsService, analyticsService.track],
      AnalyticsEvent.SINGUP
    );
    const { isBookkeeper, redirectRoute = HOME_ROUTES.MAIN_PATH } =
      action.payload;
    yield call(
      history.replace,
      isBookkeeper ? BOOKKEEPER_ROUTES.BOOKKEEPER : redirectRoute
    );
  } catch (error: any) {
    const errorHandle = requestErrorHandler(error);
    const {
      isNetworkError,
      networkErrorTranslationKey,
      responseStatus,
      responseMessage,
    } = errorHandle;

    if (isNetworkError) {
      yield put(
        createNotification({
          type: NotificationType.ERROR,
          message: networkErrorTranslationKey,
        })
      );
    } else {
      yield put(
        actions.registerFromLead.failure({
          code: responseStatus ?? 500,
          data: error?.response?.data,
        })
      );
      const duplicatedVatNumberOnPostBusiness =
        isDuplicatedVatNumberOnPostBusiness(errorHandle);
      const message: string | undefined = duplicatedVatNumberOnPostBusiness
        ? messages.updateVatNumberError.id
        : responseMessage;
      yield put(
        createNotification({
          type: NotificationType.ERROR,
          message: isNetworkError ? networkErrorTranslationKey : message,
        })
      );
    }
  }
}

export default function* authSaga() {
  yield takeLatest(ActionTypes.CREATE_LEAD_REQUEST, createLeadSaga);
  yield takeLatest(ActionTypes.LOGIN_REQUEST, loginSaga);
  yield takeLatest(
    ActionTypes.REGISTER_FROM_LEAD_REQUEST,
    registerFromLeadSaga
  );
  yield takeLatest(ActionTypes.LOGIN_USING_TOKEN, loginUsingTokenSaga);
}
