import useTheme from 'modules/Theme/hooks/useTheme';

import IconByString, { IconByStringProps } from './Icon/IconType/IconByString';

export interface IconRecurrentExpenseProps extends IconByStringProps {
  innerPathColor?: string;
  watchColor?: string;
}

const IconRecurrentExpense = (props: IconRecurrentExpenseProps) => {
  const { innerPathColor, watchColor, ...rest } = props;
  const theme = useTheme();
  return (
    <IconByString {...rest}>
      <>
        <path
          clipRule="evenodd"
          d="M3.176 7H2V4h1.5L5 1h12l1.5 3H20v3h-1.177L16 23H6L3.176 7ZM3 5h16v1H3V5Zm1.192 2 2.647 15h8.322l2.647-15H4.192Zm13.19-3H4.618l1-2h10.764l1 2Z"
          fill="currentColor"
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M9 13a2 2 0 1 0 4 0 2 2 0 0 0-4 0Zm2-3a3 3 0 1 0 0 6 3 3 0 0 0 0-6Z"
          fill="currentColor"
          fillRule="evenodd"
          shapeRendering="geometricPrecision"
        />
        <circle
          cx="18"
          cy="18"
          fill={innerPathColor ?? 'currentColor'}
          r="6"
          shapeRendering="geometricPrecision"
        />
        <path
          clipRule="evenodd"
          d="M18.462 14.77h-.924v3.692h3.693v-.924h-2.769V14.77Z"
          fill={watchColor ?? theme.colors.gray0}
          fillRule="evenodd"
          shapeRendering="geometricPrecision"
        />
      </>
    </IconByString>
  );
};

export default IconRecurrentExpense;
