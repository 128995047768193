import { useState } from 'react';

import Limits from 'types/limits';

export default function useDataTablePagination(
  initialSize: number = Limits.SMALL
) {
  const [pagination, setPagination] = useState({
    page: 0,
    pageSize: initialSize,
  });

  function resetPagination() {
    setPagination({ page: 0, pageSize: pagination.pageSize });
  }

  function onPageSizeChange(pageSize: number) {
    setPagination({ page: 0, pageSize });
  }

  function onPageChange(page: number) {
    setPagination({ ...pagination, page });
  }

  return {
    pagination,
    setPagination,
    resetPagination,
    onPageSizeChange,
    onPageChange,
  };
}
