import { isBefore, startOfToday } from 'date-fns';
import { isEmpty } from 'lodash';

import { Contact, emptyContact } from 'modules/Contacts/models';
import { DocumentDefaultValues } from 'modules/DefaultValues/models/document-default-values';
import { createAdditionalExpense } from 'modules/Documents/helpers/default-additionalExpense';
import { createDefaultConcept } from 'modules/Documents/helpers/default-concept';
import {
  DocumentPeriodType,
  Document,
} from 'modules/Documents/models/document';
import { NewDocumentForm } from 'modules/Documents/models/form';
import { PersonType } from 'modules/Documents/models/personType';
import { CorrectiveCause } from 'modules/Documents/Rectify/models/correctiveCause';
import { CreatedPaymentMethod } from 'modules/PaymentMethods/models/paymentMethods';
import { DocumentSerie } from 'modules/Settings/models';

import {
  injectContactWithPaymentMethods,
  processPaymentMethodsFromDocument,
} from './helpers';
import processConcepts from './processConcepts';

interface Params {
  document: Document;
  initialSerieValue?: string | null;
  initialCode?: number;
  initialSerialCode?: string;
  initialSerie?: DocumentSerie;
  isDraft?: boolean;
  isCopying?: boolean;
  paymentMethods?: CreatedPaymentMethod[];
  savedDefaultValues?: DocumentDefaultValues | null;
  isReceipt?: boolean;
  isPartialCorrective?: boolean;
  updatedContact?: Contact;
  isBai?: boolean;
}

const getOperationDateTime = ({
  document,
  isPartialCorrective,
  isCopying,
}: {
  document: Document;
  isPartialCorrective?: boolean;
  isCopying?: boolean;
}): Date | undefined => {
  if (isPartialCorrective) {
    return document.operationDateTime
      ? new Date(document.operationDateTime)
      : (document.issuedDateTime && new Date(document.issuedDateTime)) ||
          undefined;
  }
  if (isEmpty(document.operationDateTime) || isCopying) {
    return undefined;
  }

  return (
    (document.operationDateTime && new Date(document.operationDateTime)) ||
    undefined
  );
};

const getUpdatingDueDate = ({
  document,
  isCopying,
  isBai,
}: Params): Date | undefined => {
  if (!document.dueDate || isCopying) {
    return undefined;
  }
  const dueDate = new Date(document.dueDate);
  if (isBai && isBefore(dueDate, startOfToday())) {
    return undefined;
  }

  return new Date(document.dueDate);
};

const updateDocumentValues = ({
  document,
  initialSerieValue,
  initialCode,
  initialSerialCode,
  initialSerie,
  isDraft,
  isCopying,
  paymentMethods,
  savedDefaultValues,
  isReceipt,
  isBai,
  isPartialCorrective,
  updatedContact,
}: Params): NewDocumentForm => {
  const showComments = savedDefaultValues?.showComments ?? true;
  const canUseSameCodeNumber =
    !!document.code &&
    initialSerie &&
    initialSerie.lastNumber === Number(document.code);

  const showInitialCode = (isDraft && !canUseSameCodeNumber) || isCopying;
  const operationDateTime = getOperationDateTime({
    document,
    isPartialCorrective,
    isCopying,
  });
  const serialCode =
    document.serie?.serialCode && !isPartialCorrective
      ? document.serie?.serialCode
      : initialSerialCode;
  const showInitialSerieId =
    (!!isDraft || !!isPartialCorrective) &&
    document.serie?.id !== initialSerieValue;
  const showProductReference = savedDefaultValues?.showProductReference;

  const documentContactWithUpdatedPaymentMethods =
    injectContactWithPaymentMethods({
      updatedContact,
      documentContact: document.contact,
      documentPaymentMethods: document.paymentMethodsAccepted,
    });

  return {
    showLinesWithoutTaxes: savedDefaultValues?.showLinesWithoutTaxes,
    hasAdditionalExpenses: !isEmpty(document.additionalExpenses),
    additionalExpenses: !isEmpty(document.additionalExpenses)
      ? document.additionalExpenses
      : [createAdditionalExpense()],
    creationDate:
      document.issuedDateTime && !isCopying
        ? new Date(document.issuedDateTime)
        : new Date(),
    dueDate: getUpdatingDueDate({ isBai, document, isCopying }),
    hasFile: !!document.hasFile,
    code: showInitialCode ? initialCode : document.code,
    initDateTime:
      document.issuedDateTime && !isCopying
        ? new Date(document.issuedDateTime)
        : new Date(),
    newDocumentPeriod: DocumentPeriodType.MONTHLY,
    dueDocumentPeriod: DocumentPeriodType.MONTHLY,
    concepts: !isEmpty(document.concepts)
      ? processConcepts(document.concepts)
      : [createDefaultConcept(document.taxRegime || '')],
    comments: document.comments || '',
    showComments: !!document.comments || showComments,
    contact: isCopying
      ? { ...emptyContact }
      : documentContactWithUpdatedPaymentMethods,
    id: document.id,
    hasRetentions: !!document.totals?.retentionPercentage,
    hasSalesEqualizationTax: document.hasSalesEqualizationTax,
    reference: document.reference || '',
    retentionPercentage: document.totals?.retentionPercentage || 15,
    hasPaidAmount: !!document.totals?.paidAmount,
    paidAmount: document.totals?.paidAmount || 0,
    serialCode,
    serieId: showInitialSerieId ? initialSerieValue : document.serie?.id,
    isDefaultDraft: !!isDraft,
    status: document.status,
    // Bai
    operationDescription: document.operationDescription || '',
    operationDateTime,
    naturalPerson: document.naturalPerson,
    naturalPersonType: document.naturalPerson
      ? PersonType.NATURAL
      : PersonType.LEGAL,
    epigraphIAE: document.epigraphIAE,
    chargingCriterionIRPF: document.chargingCriterionIRPF,
    hasCustomIRPF: !!document.customIRPF,
    customIRPF: document.customIRPF,
    templateId: document.templateId,
    taxRegime: document.taxRegime,
    currency: document.currency,
    paymentMethods: processPaymentMethodsFromDocument({
      documentMethods: document.paymentMethodsAccepted,
      defaultMethods: paymentMethods,
      preserveContactMethods: true,
    }),
    ...(isPartialCorrective && {
      documentType: document.documentType,
      correctedDocuments: isDraft
        ? document?.correctedDocuments?.map((data) => data.id)
        : [document.id],
      correctiveCause: isReceipt ? CorrectiveCause.R5 : CorrectiveCause.R1,
    }),
    showProductReference,
  };
};

export default updateDocumentValues;
