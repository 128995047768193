import * as React from 'react';

import { space, layout, border, position } from 'styled-system';

import breakpoints, { max } from 'modules/Theme/breakpoints';
import styled, { css } from 'modules/Theme/styled-components';
import Box from 'modules/Ui/Box';

import { BoxProps } from '../Box/Box';

interface Props extends BoxProps {
  /**
   * The value of the currently selected `Tab`.
   * If you don't want any selected `Tab`, you can set this prop to `false`.
   */
  value?: any;
  children: React.ReactNode;
  onChange: (event: React.FormEvent<HTMLDivElement>, value: any) => void;
  mobileTransparency?: boolean;
}

const opacityBlock = css`
  content: "";
  display: block;
  height: 100%;
  position: absolute;
  top: 0;
  width: 16px;
  z-index: 1;
`;

const opacityLeftToRight = css`
  ${opacityBlock}
  background: linear-gradient(90deg, ${(props) =>
    props.theme.colors.gray0} 0%, transparent 100%);
  left: 0;
`;

const opacityRightToLeft = css`
  ${opacityBlock}
  background: linear-gradient(90deg, transparent 0%, ${(props) =>
    props.theme.colors.gray0} 100%);
  right: -1px;
`;

const StyledWrapper = styled(Box)<Props>`
  ${border}
  ${layout}
  ${space}
  ${position}

  @media ${max(breakpoints.sm)} {
    &:before {
      ${({ mobileTransparency }) => mobileTransparency && opacityLeftToRight}
    }
    &:after {
      ${({ mobileTransparency }) => mobileTransparency && opacityRightToLeft}
    }
  }
`;

const Tabs = React.forwardRef(
  (props: Props, ref: React.Ref<HTMLDivElement>) => {
    const {
      mobileTransparency,
      value,
      children: childrenProp,
      onChange,
      ...customs
    } = props;

    let childIndex = 0;

    const children = React.Children.map(childrenProp, (child) => {
      if (!React.isValidElement(child)) {
        return null;
      }
      const childValue =
        child.props.value === undefined ? childIndex : child.props.value;
      const selected = childValue === value;

      childIndex += 1;
      return React.cloneElement<any>(child, {
        selected,
        onChange,
        value: childValue,
        ...(childIndex === 1 && value === false && !child.props.tabIndex
          ? { tabIndex: 0 }
          : {}),
      });
    });
    // The tablist isn't interactive but the tabs are
    return (
      <StyledWrapper
        mobileTransparency={mobileTransparency}
        position={mobileTransparency && 'relative'}
        maxWidth={{ _: '100%', sm: 'initial' }}
      >
        <Box
          aria-orientation="horizontal"
          display="flex"
          ref={ref}
          role="tablist"
          tag="ul"
          {...customs}
        >
          {children}
        </Box>
      </StyledWrapper>
    );
  }
);

export default Tabs;
