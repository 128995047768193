import HtmlComponent from './HtmlComponent';
import Props from './Props';

const validProps = [
  'alt',
  'crossorigin',
  'height',
  'ismap',
  'loading',
  'longdesc',
  'sizes',
  'src',
  'srcSet',
  'usemap',
  'width',
  'fetchpriority',
  'decoding',
  /^(aria-)/,
];

const Img = (props: Props) => (
  <HtmlComponent tag="img" {...{ validProps }} {...props} />
);

export default Img;
