import { FC, ReactNode } from 'react';
import { Visible } from 'react-grid-system';

import { UserPermissions } from 'modules/Auth/constants';
import useHasUserScope from 'modules/Auth/hooks/checkUsersPermissions';
import useTranslations from 'modules/I18n/hooks/useTranslations';

import MobileActions from '../MobileActions';
import messages from './messages';

interface MainActionProps {
  children: ReactNode;
  onBackdropClick?: () => void;
  open: boolean;
  title?: string;
}

export const PageListMainActionMobile: FC<MainActionProps> = ({
  children,
  onBackdropClick,
  open,
  title,
}) => {
  const { hasUserScope } = useHasUserScope();
  const { t } = useTranslations();
  const canCreate = hasUserScope(UserPermissions.SUBSCRIPTION_EDITOR);
  return (
    <>
      {canCreate && (
        <Visible xs>
          <MobileActions
            backdropColor="backdropDark"
            id="actions-mobile-controls"
            onBackdropClick={onBackdropClick}
            open={open}
            title={title || t(messages.titleMobile)}
          >
            {children}
          </MobileActions>
        </Visible>
      )}
    </>
  );
};
