import { css } from 'modules/Theme/styled-components';

const hoverStyle = () => css`
  @media (hover: hover) {
    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }

  &:focus {
    text-decoration: underline;
  }
`;

export default hoverStyle;
