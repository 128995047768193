import { omit } from 'lodash';

import { getConfig } from 'modules/App/config';
import { SHOPIFY_CONFIG } from 'modules/App/queries/queries';
import { ParamsShopConfig, ShopConfig } from 'modules/Integrations/models';
import request from 'utils/request';

const updateShopifyConfig = async (
  values: ParamsShopConfig
): Promise<ShopConfig> => {
  const endpoint = (await getConfig()).apiIntegrationShopifyConfig;

  const response = await request<any>(endpoint, {
    method: 'PUT',
    data: omit(values, ['name', 'isActive']),
  });

  return response.data.data;
};

export const invalidateQueries = [SHOPIFY_CONFIG];

export default updateShopifyConfig;
