import * as Yup from 'yup';

import { formText } from 'modules/Forms/messages';
import { TranslationFunction } from 'modules/I18n/hooks/useTranslations';
import { RemittanceType } from 'modules/Payments';

const getValidationSchema = (t: TranslationFunction) =>
  Yup.object().shape({
    remittanceConcept: Yup.string().required(t(formText.required)),
    remittanceExpiration: Yup.date().required(t(formText.required)),
    remittanceId: Yup.string().required(t(formText.required)),
    accountingAccountId: Yup.string().required(t(formText.required)),
    remittanceSepa: Yup.string().when('type', (val) => {
      if (val === RemittanceType.DIRECT_DEBIT) {
        return Yup.string().required(t(formText.required));
      }
      return Yup.string().nullable();
    }),
  });

export default getValidationSchema;
