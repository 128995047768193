import { FC } from 'react';

import { Text } from 'modules/Ui';

interface DialogSubtitleProps {
  text: string;
  id?: string;
}

export const DialogSubtitle: FC<DialogSubtitleProps> = ({ id, text }) => {
  return (
    <Text
      color="gray800"
      fontSize={16}
      fontWeight="600"
      id={`subtitle-${id}`}
      lineHeight={22}
      marginBottom="16px"
    >
      {text}
    </Text>
  );
};
