import { getConfig } from 'modules/App/config';
import { REMITTANCES_DOCUMENTS_QUERY } from 'modules/App/queries/queries';
import Limits from 'types/limits';
import { buildTortillaBaseQuery } from 'utils/query/buildTortillaBaseQuery';
import request from 'utils/request';

import { RemittanceDocumentDto, RemittanceDocumentSort } from '../types';

type Props = {
  remittanceId?: string;
  searchTerm?: string;
  page?: number;
  pageSize?: Limits;
  sortBy?: RemittanceDocumentSort;
  documentIds?: string[];
};

export interface RemittanceDocumentsAnswerDto {
  data: {
    items: RemittanceDocumentDto[];
    count: number;
  };
}

export const baseRemittanceDocumentSort = (sortBy?: RemittanceDocumentSort) => [
  {
    field: sortBy?.field,
    order: sortBy?.order,
  },
  {
    field: 'updatedAt',
    order: sortBy?.order,
  },
];

const fetchRemittanceDocuments = async ({
  page,
  pageSize,
  searchTerm,
  sortBy,
  documentIds,
}: Props): Promise<RemittanceDocumentsAnswerDto> => {
  const endpoint = (await getConfig()).apiRemittancesDocumentsService;

  let query = buildTortillaBaseQuery({
    page,
    pageSize,
    sort: baseRemittanceDocumentSort(sortBy),
    search: searchTerm,
  });

  if (documentIds) {
    documentIds.forEach((documentId, x) => {
      query += `&query[document][id][$in][${x}]=${documentId}`;
    });
  }

  const response = await request<RemittanceDocumentsAnswerDto>(
    `${endpoint}/?${query}`,
    {
      method: 'GET',
    }
  );
  return response.data;
};

export const invalidateQueries = [REMITTANCES_DOCUMENTS_QUERY];

export default fetchRemittanceDocuments;
