import IconByPath, { IconByPathProps } from './Icon/IconType/IconByPath';

const IconQuestion = (props: IconByPathProps) => (
  <IconByPath
    {...props}
    fillRule="evenodd"
    path="M22 12c0 2.038-.61 3.934-1.657 5.515l-3.687-3.688A4.987 4.987 0 0 0 17 12c0-.645-.122-1.261-.344-1.827l3.687-3.688A9.954 9.954 0 0 1 22 12Zm-3.671 7.743c.517-.423.99-.897 1.414-1.414l-3.571-3.572a5.027 5.027 0 0 1-1.415 1.415l3.572 3.571Zm1.414-14.072-3.571 3.572a5.028 5.028 0 0 0-1.415-1.415l3.572-3.57c.517.422.99.896 1.414 1.413ZM12 22c2.038 0 3.934-.61 5.515-1.657l-3.688-3.687A4.988 4.988 0 0 1 12 17a4.987 4.987 0 0 1-1.827-.344l-3.688 3.687A9.954 9.954 0 0 0 12 22Zm-6.329-2.257a10.057 10.057 0 0 1-1.414-1.414l3.571-3.572a5.028 5.028 0 0 0 1.415 1.415l-3.572 3.57Zm1.673-5.916A4.988 4.988 0 0 1 7 12c0-.645.122-1.261.344-1.827L3.657 6.485A9.953 9.953 0 0 0 2 12c0 2.038.61 3.934 1.657 5.515l3.687-3.688ZM4.257 5.671l3.571 3.572a5.027 5.027 0 0 1 1.415-1.415L5.67 4.257c-.517.423-.99.897-1.414 1.414Zm2.228-2.014A9.953 9.953 0 0 1 12 2c2.038 0 3.934.61 5.515 1.657l-3.688 3.687A4.987 4.987 0 0 0 12 7c-.645 0-1.261.122-1.827.344L6.485 3.657ZM23 12c0 6.075-4.925 11-11 11S1 18.075 1 12 5.925 1 12 1s11 4.925 11 11Zm-7 0a4 4 0 1 1-8 0 4 4 0 0 1 8 0Z"
    shapeRendering="geometricPrecision"
  />
);

export default IconQuestion;
