import { getConfig } from 'modules/App/config';
import { BUSINESS_ME_QUERY } from 'modules/App/queries/queries';
import { BookkeeperCustomerUpdate } from 'modules/Bookkeeper/components/types';
import {
  BusinessUpdate,
  CreatedBusiness,
} from 'modules/Business/models/business';
import cleanVatNumber from 'utils/cleanVatNumber';
import request from 'utils/request';

const updateBusiness = async (
  business: BusinessUpdate
): Promise<CreatedBusiness> => {
  const normalized = {
    ...business,
    vatNumber: cleanVatNumber(business.vatNumber),
  };
  return updateBusinessBase({ business: normalized });
};

export interface UpdateBusinessParams {
  business: BusinessUpdate | BookkeeperCustomerUpdate;
  businessId?: string;
}

interface Response {
  data: CreatedBusiness;
}

export const updateBusinessBase = async ({
  business,
  businessId,
}: UpdateBusinessParams): Promise<CreatedBusiness> => {
  const endpoint = (await getConfig()).apiBusinessesService;
  const dest = businessId || 'current';
  const response = await request<Response>(`${endpoint}/${dest}`, {
    method: 'PUT',
    data: business,
  });

  return response.data.data;
};

export const invalidateQueries = [BUSINESS_ME_QUERY];

export default updateBusiness;
