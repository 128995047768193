import { ActionType } from 'typesafe-actions';

import * as actions from './actions';
import ActionTypes from './constants';

export interface State {
  isOpen: boolean;
}

export const initialState: State = {
  isOpen: true,
};

const reducer = (
  state: State = initialState,
  action: ActionType<typeof actions>
): State => {
  switch (action.type) {
    case ActionTypes.SET_IS_OPEN_BANNER:
      return {
        ...state,
        isOpen: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
