import { getConfig } from 'modules/App/config';
import request from 'utils/request';

import { User } from '../models/user';

const fetchMe = async () => {
  const endpoint = (await getConfig()).apiUsersService;

  const response = await request<{ data: User }>(`${endpoint}/me`, {
    method: 'GET',
  });
  return response.data.data;
};
export default fetchMe;
