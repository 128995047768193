import React, { ReactNode, useMemo } from 'react';

import useTranslations from 'modules/I18n/hooks/useTranslations';
import { PAYMENT_PERIODS } from 'modules/Subscriptions/models';
import useTheme from 'modules/Theme/hooks/useTheme';
import { shadowHover } from 'modules/Theme/mixins';
import styled from 'modules/Theme/styled-components';
import { Button, Box, Text } from 'modules/Ui';
import { roundNumber } from 'utils/MathOperations';

import { plans } from '../../../messages';
import DescItems from './DescItems';
import PlanPopular from './PlanPopular';

interface Props {
  children: ReactNode;
  isActive?: boolean;
  onClick?: () => void;
  onMouseEnter?: () => void;
  onSelectPlan(): void;
  periodicity?: string;
  planName: string;
  planType?: 'popular' | 'basic';
  price: number;
  productId: string;
  campaign?: string;
  campaignPopularPlan?: boolean;
}

const StyledPlan = styled(Box)`
  ${(props) =>
    shadowHover({
      color: props.theme.colors.gray300,
      opacity: 80,
      shadowPosition: 'before',
    })};
`;

const PlanWrapper: React.FC<Props> = ({
  children,
  onClick,
  isActive,
  price,
  planName,
  productId,
  planType,
  onSelectPlan,
  periodicity,
  onMouseEnter,
  campaign,
  campaignPopularPlan,
}) => {
  const { t, formatNumber } = useTranslations();
  const theme = useTheme();
  const isPopular = planType === 'popular';
  const monthlyPrice = useMemo(
    () =>
      periodicity === PAYMENT_PERIODS.YEARLY
        ? roundNumber(price / 12, 2)
        : price,
    [price, periodicity]
  );
  const message = campaignPopularPlan
    ? t(plans.campaign, { campaign })
    : t(plans.popular);
  const popularPillIsVisible = isPopular || campaignPopularPlan;
  const popularActivePlan = isPopular && isActive;
  return (
    <>
      <StyledPlan
        backgroundColor="gray0"
        border={{ sm: `1px solid ${theme.colors.gray200}` }}
        data-testid={planName}
        display="grid"
        gap={!popularActivePlan ? { sm: '40px' } : ''}
        gridTemplateRows={isPopular ? '0 1fr auto' : '1fr auto'}
        isActive={isActive}
        margin={{ _: '0 0 32px', sm: !isPopular ? '16px 0' : '0' }}
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        paddingBottom={isPopular ? '48px' : '32px'}
        planName={planName}
        position="relative"
        price={price}
        tabIndex="0"
        tag="article"
        textAlign="left"
      >
        {popularPillIsVisible && <PlanPopular message={message} />}
        <Box marginBottom={{ _: '30px', sm: '0' }}>
          <Box
            backgroundColor={isActive && 'brand500'}
            marginBottom="20px"
            padding={
              isPopular
                ? {
                    _: '48px 24px 0',
                    sm: '8px 16px 0',
                    md: '8px 40px 0',
                    lg: '8px 48px 0',
                  }
                : {
                    _: '32px 24px 0',
                    sm: '32px 16px 0',
                    md: '32px 40px 0',
                    lg: '32px 48px 0',
                  }
            }
          >
            <Text
              color={isActive ? 'gray0' : 'brand500'}
              fontSize={{ _: 24, md: 28 }}
              fontWeight="700"
              lineHeight={34}
              marginBottom="4px"
              paddingTop={popularActivePlan ? '40px' : undefined}
              tag="h3"
            >
              {planName}
            </Text>
            <DescItems isActive={isActive} productId={productId} />
            <Box
              borderBottom={`1px solid ${theme.colors.gray200}`}
              display="grid"
              gridTemplateColumns="auto 1fr"
              tag="p"
            >
              <Text
                color={isActive ? 'gray0' : 'gray800'}
                fontSize={{ _: 64, md: 80 }}
                fontWeight="800"
                lineHeight={97}
                tag="strong"
              >
                {formatNumber(monthlyPrice)}
              </Text>
              <Text
                color={isActive ? 'gray0' : 'gray800'}
                fontSize={18}
                fontWeight="600"
                lineHeight={22}
                margin={{ _: '20px 0 0 4px', sm: '20px 0 0 8px' }}
                tag="strong"
              >
                {t(plans.monthlyTaxes)}
                <Text
                  color={isActive ? 'gray0' : 'gray500'}
                  display="block"
                  fontSize={12}
                  lineHeight={14}
                  tag="span"
                >
                  + {t(plans.addCountryTax)}
                </Text>
              </Text>
            </Box>
          </Box>
          {children}
        </Box>
        <Button
          data-testid="select"
          onClick={onSelectPlan}
          margin={{
            _: '0 28px',
            sm: '0 16px',
            md: '0 40px',
            lg: '0 48px',
          }}
          variant={isActive ? 'stroke' : 'primary'}
        >
          {isActive ? t(plans.buttonActive) : t(plans.button)}
        </Button>
      </StyledPlan>
    </>
  );
};

export default PlanWrapper;
