import { useSelector } from 'react-redux';

import { selectIsMobile } from 'modules/App/store/selectors';
import useTranslations, {
  TranslationFunction,
} from 'modules/I18n/hooks/useTranslations';
import {
  RemittanceStatus,
  RemittanceTable,
  RemittanceType,
} from 'modules/Payments/models';
import { ThemeInterface } from 'modules/Theme';
import useTheme from 'modules/Theme/hooks/useTheme';
import { BasePill } from 'modules/Ui';
import {
  DataTableAction,
  DataTableColumn,
} from 'modules/Ui/DataTable/DataTable.models';
import {
  IconAdd,
  IconDone,
  IconDownload,
  IconEdit,
  IconTrash,
} from 'modules/Ui/Icons';
import { formatDate } from 'utils/dates';

import { remittancesTable, remittancesTableBulkActions } from '../messages';
import { RemittanceListActions } from './useRemittanceListActions';

function getStatusTag({
  status,
  t,
  theme,
}: {
  status: RemittanceStatus;
  t: TranslationFunction;
  theme: ThemeInterface;
}) {
  const color =
    status === RemittanceStatus.PENDING
      ? theme.colors.pending
      : theme.colors.tagsConfirmationAccesible;
  const backgroundColor =
    status === RemittanceStatus.PENDING
      ? theme.colors.warningBackground
      : theme.colors.confirmationBackground;
  const message =
    status === RemittanceStatus.PENDING
      ? remittancesTable.pending
      : remittancesTable.completed;

  return (
    <BasePill color={color} backgroundColor={backgroundColor}>
      {t(message)}
    </BasePill>
  );
}

export const useRemittanceListConfig = (options: {
  selection: RemittanceTable[];
  actions: RemittanceListActions;
}) => {
  const isMobile = useSelector(selectIsMobile);
  const { actions, selection } = options;
  const { t, formatCurrency } = useTranslations();
  const theme = useTheme();

  const bulkActions: DataTableAction<RemittanceTable>[] = [
    {
      name: t(remittancesTable.edit),
      icon: <IconEdit />,
      onClick: actions.edit,
      disableMultiple: true,
      shouldActionRender: selection[0]?.status === RemittanceStatus.PENDING,
    },
    {
      name: t(remittancesTable.markAsPending),
      onClick: actions.markAsPending,
      icon: <IconAdd transform="rotate(45deg)" />,
      disableMultiple: true,
      shouldActionRender: selection[0]?.status === RemittanceStatus.COMPLETED,
    },
    {
      name: t(remittancesTable.markAsComplete),
      onClick: actions.markAsCompleted,
      icon: <IconDone />,
      disableMultiple: true,
      shouldActionRender: selection[0]?.status === RemittanceStatus.PENDING,
    },
    {
      name: t(remittancesTableBulkActions.downloadXML),
      shouldActionRender: true,
      icon: <IconDownload />,
      onClick: actions.downloadXML,
      disableMultiple: true,
    },
    {
      name: t(remittancesTableBulkActions.downloadZIP),
      shouldActionRender: true,
      icon: <IconDownload />,
      onClick: actions.downloadZIP,
      onlyMultiple: true,
    },
    {
      name: t(remittancesTableBulkActions.delete),
      shouldActionRender: true,
      icon: <IconTrash />,
      onClick: actions.delete,
    },
  ];
  const columns: DataTableColumn<RemittanceTable, keyof RemittanceTable>[] = [
    {
      name: t(remittancesTable.createdAt),
      field: 'createdAt',
      textAlign: { _: 'right', sm: 'initial' },
      sortField: 'createdAt',
      value: ({ createdAt }) => formatDate(createdAt),
      width: { sm: '124px', md: '132px' },
      className: 'createdAt',
    },
    {
      color: !isMobile ? theme.colors.gray800 : undefined,
      name: t(remittancesTable.type),
      field: 'type',
      sortField: 'type',
      textAlign: { _: 'right', sm: 'initial' },
      value: ({ type }) => {
        return type === RemittanceType.CREDIT_TRANSFER
          ? t(remittancesTable.creditTransfer)
          : t(remittancesTable.directDebit);
      },
      width: { md: '90px' },
      className: 'type',
    },
    {
      name: t(remittancesTable.name),
      field: 'name',
      sortField: 'name',
      maxWidth: '299px',
      color: theme.colors.gray800,
      className: 'name',
      fontSize: `${theme.fontSizes[16]}`,
      lineHeight: `${theme.lineHeights[20]}`,
      value: ({ name }) => name,
    },
    {
      color: !isMobile ? theme.colors.gray800 : undefined,
      name: t(remittancesTable.businessBankAlias),
      field: 'businessAccountingAccountAlias',
      maxWidth: { sm: '110px', md: '184px' },
      value: ({ businessAccountingAccountAlias }) =>
        businessAccountingAccountAlias,
      className: 'businessAccountingAccountAlias',
      fontSize: `${theme.fontSizes[12]}`,
      lineHeight: `${theme.lineHeights[16]}`,
    },
    {
      name: t(remittancesTable.dueDate),
      field: 'dueDate',
      color: theme.colors.gray800,
      value: ({ dueDate }) => formatDate(dueDate),
      width: { sm: '67px', md: '120px' },
      className: 'dueDate',
      fontSize: `${theme.fontSizes[14]}`,
      lineHeight: `${theme.lineHeights[22]}`,
      textAlign: 'right',
      headerTextAlign: 'right',
    },
    {
      name: t(remittancesTable.remittanceDocumentsCount),
      color: theme.colors.gray800,
      textAlign: 'right',
      headerTextAlign: 'right',
      field: 'remittanceDocumentsCount',
      sortField: 'remittanceDocumentsCount',
      value: ({ remittanceDocumentsCount }) => `${remittanceDocumentsCount}`,
      width: { sm: '78px', md: '96px' },
      className: 'remittanceDocumentsCount',
    },
    {
      color: theme.colors.gray800,
      name: t(remittancesTable.amount),
      field: 'amount',
      fontWeight: 600,
      sortField: 'amount',
      textAlign: { sm: 'right' },
      headerTextAlign: 'right',
      value: ({ amount }) => formatCurrency(amount),
      width: { sm: '78px', md: '96px' },
      className: 'amount',
      fontSize: `${theme.fontSizes[16]}`,
      lineHeight: `${theme.lineHeights[20]}`,
    },
    {
      name: t(remittancesTable.status),
      field: 'status',
      sortField: 'status',
      textAlign: { sm: 'right' },
      headerTextAlign: 'right',
      value: ({ status }) => getStatusTag({ status, t, theme }),
      width: { sm: '78px', md: '96px' },
      className: 'status',
    },
  ];

  return {
    columns: columns.filter((xs) => !xs.hidden),
    bulkActions,
  };
};
