import { getConfig } from 'modules/App/config';
import { buildTortillaBaseQuery } from 'utils/query/buildTortillaBaseQuery';
import request from 'utils/request';

import { Remittance, RemittanceSort } from '../../Payments/models';

interface FetchRemittancesResponse {
  items: Remittance[];
  count: number;
}

export const remitancesSort = (sortBy?: RemittanceSort) =>
  sortBy?.field === 'dueDate'
    ? [
        {
          field: 'dueDate',
          order: sortBy.order,
        },
        {
          field: 'dueDateLastChange',
          order: sortBy.order,
        },
      ]
    : [
        {
          field: sortBy?.field,
          order: sortBy?.order,
        },
      ];

export default async function fetchRemittances({
  page,
  pageSize,
  sortBy,
}: {
  page?: number;
  pageSize?: number;
  sortBy?: RemittanceSort;
}): Promise<FetchRemittancesResponse> {
  const endpoint = (await getConfig()).apiRemittancesService;

  const query = buildTortillaBaseQuery({
    page,
    pageSize,
    sort: remitancesSort(sortBy),
  });

  const response = await request<{ data: FetchRemittancesResponse }>(
    `${endpoint}?${query}`,
    {
      method: 'GET',
    }
  );
  return response?.data?.data;
}
