import { FC, ReactNode } from 'react';

import {
  space,
  position,
  PositionProps,
  SpaceProps,
  layout,
  LayoutProps,
  flexbox,
  FlexboxProps,
  border,
  BorderProps,
} from 'styled-system';

import useTheme from 'modules/Theme/hooks/useTheme';
import styled from 'modules/Theme/styled-components';
import Label from 'modules/Ui/Html/Label';

import Radio from './commons/Radio';
import RadioInput from './commons/RadioInput';
import RadioLabelText from './commons/RadioLabelText';

export interface RadioButtonProps
  extends SpaceProps,
    LayoutProps,
    FlexboxProps,
    BorderProps,
    PositionProps {
  checked?: boolean;
  children?: ReactNode | string;
  className?: string;
  disabled?: boolean;
  hideCheck?: boolean;
  id: string;
  margin?: string;
  name?: string;
  height?: string;
  onChange?: (value: any) => void;
  onClick?: () => void;
  onFocus?: () => void;
  required?: boolean;
  value: any;
  width?: string;
  minWidth?: string;
  error?: string;
}

const StyledLabel = styled(Label)`
  ${space}
  ${layout}
  ${flexbox}
  ${border}
  ${position}
  @media(hover : hover) {
    &:hover {
      cursor: ${({ disabled }) => (disabled ? 'initial' : 'pointer')};
    }
  }
`;

const RadioButton: FC<RadioButtonProps> = ({
  checked,
  children,
  className,
  disabled,
  error,
  height,
  hideCheck,
  id,
  margin,
  minWidth,
  name,
  onChange,
  onClick,
  required,
  value,
  width,
  ...rest
}) => {
  const theme = useTheme();
  return (
    <StyledLabel
      alignItems="center"
      className={className}
      display="flex"
      htmlFor={id}
      disabled={disabled}
      {...{ margin, width, onClick, ...rest }}
    >
      <RadioInput
        type="radio"
        aria-labelledby={`${id}-label`}
        data-testid={id}
        disabled={disabled}
        required={required}
        error={error}
        className={error ? 'error' : undefined}
        {...{ checked, id, name, onChange, value, ...rest }}
      />
      <Radio
        checked={checked}
        alignItems="center"
        backgroundColor="gray0"
        border={`1px solid  ${theme.colors.gray300}`}
        borderRadius="50%"
        disabled={disabled}
        display={hideCheck ? 'none' : 'flex'}
        flex="0 1 auto"
        height={height || `${theme.spacing(3)}`}
        justifyContent="center"
        minWidth={minWidth || `${theme.spacing(3)}`}
        tag="span"
        width={minWidth || `${theme.spacing(3)}`}
      />
      <RadioLabelText
        aria-hidden="true"
        className="radio__content"
        color="gray600"
        flex="1"
        id={`${id}-label`}
        marginLeft={!hideCheck ? '8px' : undefined}
        position="relative"
        tag="span"
      >
        {children}
      </RadioLabelText>
    </StyledLabel>
  );
};

export default RadioButton;
