import { FC } from 'react';

import useTranslations from 'modules/I18n/hooks/useTranslations';
import { Text, Box } from 'modules/Ui';
import { RegularFaqs } from 'modules/Ui/FaqsContainer/Faqs';

import messages from '../../../messages';

const Faqs: FC = () => {
  const { t } = useTranslations();
  return (
    <>
      <Text
        borderBottomColor="gray200"
        borderBottomStyle="solid"
        borderBottomWidth="1px"
        color="primary300"
        fontSize={16}
        fontWeight="normal"
        lineHeight={22}
        padding={{ _: '0 20px 18px', sm: '0 22px 18px', md: '0 30px 18px' }}
        tag="h3"
        textTransform="uppercase"
        width="auto"
      >
        {t(messages.faq)}
      </Text>
      <Box>
        <RegularFaqs />
      </Box>
    </>
  );
};
export default Faqs;
