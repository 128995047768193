import { ReactNode } from 'react';
import { useSelector } from 'react-redux';

import { useField } from 'formik';
import { SpaceProps, FlexboxProps, LayoutProps } from 'styled-system';

import { selectIsMobile } from 'modules/App/store/selectors';
import { SuggestionsData } from 'modules/Ui/Form/Commons/SuggestionsInput/SuggestionsInput';
import TextAreaMention from 'modules/Ui/Form/TextAreaSuggestions';

import { HTMLInputElementLayoutProps } from '../Html/Input';

interface Rows {
  mobile: number;
  desktop: number;
}

interface SuggestionsFormikFieldProps
  extends HTMLInputElementLayoutProps,
    SpaceProps,
    FlexboxProps,
    LayoutProps {
  autoTrim?: boolean;
  disabled?: boolean;
  helpText?: string;
  hidden?: boolean;
  labelHidden?: boolean;
  icon?: string | ReactNode;
  id: string;
  label: string;
  maxLength?: number;
  name?: string;
  onBlur?: (any: any) => void;
  placeholder?: string;
  required?: boolean;
  rows?: Rows;
  data: SuggestionsData[];
  singleLine?: boolean;
}

const SuggestionsFormikField = (props: SuggestionsFormikFieldProps) => {
  const {
    helpText,
    hidden,
    labelHidden,
    icon,
    id,
    label,
    name,
    margin,
    maxLength,
    onBlur,
    placeholder,
    order,
    required,
    width,
    rows,
    data,
    singleLine,
    ...rest
  } = props;

  const [field, meta] = useField(name || id);
  const isMobile = useSelector(selectIsMobile);
  return hidden ? null : (
    <TextAreaMention
      {...{
        helpText,
        icon,
        id,
        label,
        labelHidden,
        margin,
        maxLength,
        order,
        placeholder,
        required,
        width,
        rows: isMobile ? rows?.mobile : rows?.desktop,
        ...rest,
        ...field,
        error: meta.touched && meta.error ? meta.error : undefined,
        name: name || id,
        data,
        singleLine,
      }}
    />
  );
};

export default SuggestionsFormikField;
