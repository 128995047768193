import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import { isEmpty } from 'lodash';

import { useInvalidateAndUpdate } from 'modules/App/hooks/useInvalidateAndUpdate';
import { API_CREDENTIALS } from 'modules/App/queries/queries';
import useTranslations from 'modules/I18n/hooks/useTranslations';
import { useOpenModal } from 'modules/Modals';
import { ModalTypes } from 'modules/Modals/constants';
import useNotifications from 'modules/Notifications/hooks/useNotifications';
import CacheTimes from 'utils/query/cacheTimes';
import requestErrorHandler from 'utils/requestErrorHandler';
import { useBlockingMutation } from 'utils/useBlockingMutation';

import { apiConfigurationForm } from '../messages';
import createCredentials from '../services/api/createApiCredentials';
import deleteApiCredential from '../services/api/deleteApiCredential';
import fetchApiCredentials from '../services/api/fetchApiCredentials';
import { invalidateQueries as invalidateQueriesConfiguration } from '../services/api/updateApiConfiguration';
import updateApiCredential, {
  invalidateQueries,
} from '../services/api/updateApiCredential';

export const useApiCredentials = () => {
  const { t } = useTranslations();
  const invalidateAndUpdate = useInvalidateAndUpdate();
  const notifications = useNotifications();
  const openModal = useOpenModal();

  const invalidateAllQueries = () => {
    invalidateAndUpdate({
      invalidateQueries: invalidateQueries.concat(
        invalidateQueriesConfiguration
      ),
    });
  };

  const [hasApiConfig, setHasApiConfig] = useState(false);
  const { data, isLoading } = useQuery(
    [API_CREDENTIALS],
    () => fetchApiCredentials(),
    {
      staleTime: CacheTimes.INFINITY,
      retry: false,
    }
  );
  useEffect(() => {
    if (isEmpty(data)) {
      setHasApiConfig(false);
    } else {
      setHasApiConfig(true);
    }
  }, [data?.length]);

  const { mutate: mutateCreate } = useBlockingMutation(createCredentials, {
    onSuccess: async (credential) => {
      openModal({
        next: (data?.length || 0) + 1,
        ...credential,
        type: ModalTypes.GENERATE_CREDENTIALS,
        onSubmit: (id: string, name: string) => {
          mutateUpdate({
            id,
            name,
          });
        },
        onCancel: async (id: string) => {
          await deleteApiCredential({ id });
          invalidateAllQueries();
        },
      });
      return credential;
    },
    onError: (error) => {
      const { responseCode } = requestErrorHandler(error);
      if (responseCode) {
        notifications.error(t(apiConfigurationForm.credentialLimitReached));
      } else {
        notifications.error(t(apiConfigurationForm.credentialCreatedError));
      }
    },
  });

  const { mutate: mutateUpdate } = useBlockingMutation(updateApiCredential, {
    onSuccess: async (values) => {
      invalidateAllQueries();
      return values;
    },
    onError: () => {
      return notifications.error(
        t(apiConfigurationForm.credentialLimitReached, { ttl: 3000 })
      );
    },
  });

  const { mutate: mutateDelete } = useBlockingMutation(deleteApiCredential, {
    onSuccess: async () => {
      invalidateAllQueries();
      return notifications.success(
        t(apiConfigurationForm.credentialDeleted, { ttl: 3000 })
      );
    },
    onError: () => {
      return notifications.error(
        t(apiConfigurationForm.credentialDeletedError, { ttl: 3000 })
      );
    },
  });

  return {
    data,
    isLoading,
    hasApiConfig,
    mutateCreate,
    mutateUpdate,
    mutateDelete,
  };
};
