import { useRef } from 'react';
import { useLocation } from 'react-router';
import { useClickAway } from 'react-use';

import { HOME_ROUTES } from 'modules/Bookkeeper/Home/routes';
import { ROUTES as ROUTES_HELP } from 'modules/Help/routesMap';
import { SHOW_LANGUAGE_SELECTOR_FF } from 'modules/Home/hooks/useFeatureFlagLanguageSelector';
import { useShowLanguageSelector } from 'modules/Home/hooks/useShowLanguageSelector';
import useTranslations from 'modules/I18n/hooks/useTranslations';
import { IconMenuBalance } from 'modules/Ui/Icons';
import {
  IconMenuBookkeeper,
  IconMenuExpenses,
  IconMenuHelp,
  IconMenuHome,
  IconMenuInvoice,
  IconMenuProducts,
  IconMenuQuote,
  IconMenuUserContact,
} from 'modules/Ui/Icons/Menu';
import { SubmenuSections } from 'modules/Ui/Menu/constants';
import {
  regexMap,
  bookkeeperRegexMap,
  paymentsSectionRegexMap,
} from 'modules/Ui/Menu/regex';
import { ROUTES as BOOKKEEPER_ROUTES } from 'pages/bookkeepers/routesMap';
import { ROUTES } from 'pages/documents/routesMap';
import { ROUTES as ROUTES_QUOTES } from 'pages/quotes/routesMap';

import MenuLink from '../Components/MenuLink';
import { menu, menuBookkeeper } from '../messages';

interface MenuLinksProps {
  closeMenu: () => void;
  isBookkeeper?: boolean;
  open?: boolean;
  openSubMenu: (section: SubmenuSections) => () => void;
}

const MenuLinks = ({
  open = false,
  openSubMenu,
  closeMenu,
  isBookkeeper,
}: MenuLinksProps) => {
  const showLanguageSelector = useShowLanguageSelector();
  const location = useLocation();
  const { t } = useTranslations();
  const { pathname } = location;
  const ref = useRef(null);
  useClickAway(ref, () => {
    open && closeMenu?.();
  });

  const handleShowLanguages = () => {
    showLanguageSelector();
    closeMenu?.();
  };
  const isPaymentsActive =
    paymentsSectionRegexMap.payments.test(pathname) ||
    paymentsSectionRegexMap.banks.test(pathname) ||
    paymentsSectionRegexMap.remittances.test(pathname);

  return (
    <>
      {isBookkeeper ? (
        <>
          <MenuLink
            active={pathname === BOOKKEEPER_ROUTES.BOOKKEEPER}
            icon={
              <IconMenuHome
                active={pathname === BOOKKEEPER_ROUTES.BOOKKEEPER}
              />
            }
            text={t(menuBookkeeper.home)}
            to={BOOKKEEPER_ROUTES.HOME}
            data-testid="navbar-bookkeeper-home"
            onClick={closeMenu}
          />
          <MenuLink
            active={bookkeeperRegexMap.clients.test(pathname)}
            icon={
              <IconMenuUserContact
                active={bookkeeperRegexMap.clients.test(pathname)}
              />
            }
            text={t(menuBookkeeper.clients)}
            to={BOOKKEEPER_ROUTES.BOOKKEEPER_CLIENTS}
            data-testid="navbar-bookkeeper-clients"
            onClick={closeMenu}
          />
          <MenuLink
            active={bookkeeperRegexMap.profile.test(pathname)}
            icon={
              <IconMenuBookkeeper
                active={bookkeeperRegexMap.profile.test(pathname)}
              />
            }
            text={t(menuBookkeeper.profile)}
            to={BOOKKEEPER_ROUTES.BOOKKEEPER_PROFILE}
            data-testid="navbar-bookkeeper-profile"
            onClick={closeMenu}
          />
          <MenuLink
            active={bookkeeperRegexMap.help.test(pathname)}
            to={ROUTES_HELP.BOOKKEEPER_PATH}
            text={t(menu.help)}
            onClick={closeMenu}
            rel="help"
            icon={
              <IconMenuHelp active={bookkeeperRegexMap.help.test(pathname)} />
            }
          />
        </>
      ) : (
        <>
          <MenuLink
            active={pathname === HOME_ROUTES.MAIN_PATH}
            icon={<IconMenuHome active={pathname === HOME_ROUTES.MAIN_PATH} />}
            text={t(menu.home)}
            to={HOME_ROUTES.MAIN_PATH}
            data-testid="navbar-home"
          />
          <MenuLink
            active={regexMap.documents.test(pathname)}
            icon={
              <IconMenuInvoice active={regexMap.documents.test(pathname)} />
            }
            onClick={closeMenu}
            to={ROUTES.DOCUMENTS}
            text={t(menu.documents)}
          />
          <MenuLink
            active={regexMap.quotes.test(pathname)}
            icon={<IconMenuQuote active={regexMap.quotes.test(pathname)} />}
            onClick={closeMenu}
            text={t(menu.quotes)}
            to={ROUTES_QUOTES.MAIN_PATH}
          />
          <MenuLink
            active={regexMap.expenses.test(pathname)}
            icon={
              <IconMenuExpenses active={regexMap.expenses.test(pathname)} />
            }
            onClick={closeMenu}
            text={t(menu.expenses)}
            to={ROUTES.DOCUMENTS_EXPENSES}
          />
          <MenuLink
            active={regexMap.contacts.test(pathname)}
            icon={
              <IconMenuUserContact active={regexMap.contacts.test(pathname)} />
            }
            onClick={closeMenu}
            text={t(menu.contacts)}
            to={ROUTES.CONTACTS}
          />
          <MenuLink
            active={regexMap.products.test(pathname)}
            onClick={closeMenu}
            icon={
              <IconMenuProducts active={regexMap.products.test(pathname)} />
            }
            text={t(menu.products)}
            to={ROUTES.PRODUCTS}
          />

          <MenuLink
            active={isPaymentsActive}
            data-testid="navbar-products"
            icon={<IconMenuBalance active={isPaymentsActive} />}
            isLinkToSubmenu={true}
            onClick={openSubMenu(SubmenuSections.PAYMENTS)}
            text={t(menu.payments)}
          />

          <MenuLink
            text={t(menu.languages)}
            onClick={handleShowLanguages}
            requiredFeatureFlag={SHOW_LANGUAGE_SELECTOR_FF}
            icon={<IconMenuHelp active={regexMap.help.test(pathname)} />}
          />
          <MenuLink
            active={regexMap.help.test(pathname)}
            to={ROUTES_HELP.MAIN_PATH}
            text={t(menu.help)}
            onClick={closeMenu}
            icon={<IconMenuHelp active={regexMap.help.test(pathname)} />}
          />
        </>
      )}
    </>
  );
};

export default MenuLinks;
