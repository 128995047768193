import { FC } from 'react';

import {
  border,
  BorderProps,
  layout,
  LayoutProps,
  background,
  BackgroundProps,
  space,
  SpaceProps,
} from 'styled-system';

import styled from 'modules/Theme/styled-components';

import Box from '../Box';

interface FadeOutProps
  extends BorderProps,
    LayoutProps,
    BackgroundProps,
    SpaceProps {
  initialHeight?: string;
  time?: string;
}
const StyledFadeOut = styled(Box)`
  ${layout}
  ${space}
  ${background}
  ${border}

  @keyframes fadeOut{
    from {
      ${({ initialHeight }) => initialHeight && `height: ${initialHeight};`}
      opacity: 1;
      transform: scale(1);
    }
    to {
      height: 0;
      opacity: 0;
      transform: scale(0);
    }
  }

  @media (prefers-reduced-motion: no-preference) {
    animation: ${({ time }) => time && `${time}s`} fadeOut ease-out;
      ${({ animationFillMode }) =>
        animationFillMode && `animation-fill-mode: ${animationFillMode};`}
    transform-origin: center center;
  }

  @media (prefers-reduced-motion: reduce) {
    display: none;
  }
`;

const FadeOutContainer: FC<FadeOutProps> = ({
  initialHeight,
  time = '0.6',
  ...rest
}) => {
  return <StyledFadeOut initialHeight={initialHeight} time={time} {...rest} />;
};

export default FadeOutContainer;
