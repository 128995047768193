import {
  DetailedHTMLProps as Props,
  ButtonHTMLAttributes as Attributes,
  ReactNode,
} from 'react';

import { flex, FlexboxProps } from 'styled-system';

import theme from 'modules/Theme';
import styled, { css } from 'modules/Theme/styled-components';
import HtmlButton from 'modules/Ui/Html/Button';

export interface ButtonProps extends FlexboxProps {
  ariaLabel?: string;
  backgroundHoverColor?: string;
  dataTestid?: string;
  expanded?: boolean;
  icon: ReactNode;
  iconHoverColor?: string;
  id?: string;
  onClick?: () => void;
  size?: string | number;
  title?: string;
  variant?: 'light' | 'dark' | 'gray';
}

const buttonDarkStyles = css`
  --button-background: ${(props) => props.theme.colors.auxiliary100};
  --button-focus: ${(props) => props.theme.colors.gray0};
  --icon-color: ${(props) => props.theme.colors.brand500};
  --icon-color-disabled: ${(props) => props.theme.colors.gray400};
`;

const buttonLightStyles = css`
  --button-background: ${(props) => props.theme.colors.gray0};
  --button-focus: ${(props) => props.theme.colors.auxiliary100};
  --icon-color: ${(props) => props.theme.colors.brand500};
  --icon-color-disabled: ${(props) => props.theme.colors.gray400};
`;

const buttonGrayStyles = css`
  --button-background: ${(props) => props.theme.colors.gray0};
  --button-focus: ${(props) => props.theme.colors.auxiliary100};
  --icon-color: ${(props) => props.theme.colors.gray400};
  --icon-color-disabled: ${(props) => props.theme.colors.gray400};
`;

type StyledLinkProps = Omit<
  Props<Attributes<HTMLButtonElement>, HTMLButtonElement>,
  'onClick'
>;

const beforeHoverEffect = css`
  opacity: 1;
  transform: scale(1);
`;

const iconHoverEffect = css`
  transform: scale(0.9);
`;

export const StyledButton = styled(HtmlButton)<StyledLinkProps>`
  align-items: center;
  background-color: initial;
  display: flex;
  height: ${({ size }) => size && `${size}px`};
  justify-content: center;
  position: relative;
  width: ${({ size }) => size && `${size}px`};
  ${({ variant }) => variant === 'dark' && buttonDarkStyles}
  ${({ variant }) => variant === 'light' && buttonLightStyles}
  ${({ variant }) => variant === 'gray' && buttonGrayStyles}
  ${flex}

  svg {
    color: var(--icon-color);
    position: relative;
    transform-origin: center;
    transition: transform 0.2s ease-in;
  }

  &:disabled {
    svg {
      color: var(--icon-color-disabled);
    }
  }

  &:not([disabled]) {
    &:before {
      background-color: ${({ backgroundHoverColor }) =>
        backgroundHoverColor
          ? `${backgroundHoverColor}`
          : `var(--button-background)`};
      border-radius: 50%;
      content: '';
      display: block;
      height: ${({ size }) => size && `${size}px`};
      left: 0;
      opacity: 0;
      position: absolute;
      top: 0;
      transform: scale(0);
      transition-duration: 0.2s;
      transition-property: transform, opacity;
      transition-timing-function: cubic-bezier(0.4,0,0.2,1);
      width: ${({ size }) => size && `${size}px`};
    }

    @media (hover: hover) {
      cursor: pointer;
      &:hover {
        &:before {
          ${beforeHoverEffect}
        }
        svg {
          color: ${({ iconHoverColor }) =>
            iconHoverColor ? `${iconHoverColor}` : theme.colors.brand500};
          ${iconHoverEffect};
        }
      }
    }
  }

  @media (hover:none) {
    &:focus {
      &:before {
        outline :none;
        border: 1px solid ${(props) => props.theme.colors.brand500};
        background-color: var(--button-focus);
        ${beforeHoverEffect}
      }
      svg {
        color: ${({ iconHoverColor }) =>
          iconHoverColor ? `${iconHoverColor}` : theme.colors.brand500};
        ${iconHoverEffect};
      }

      &:disabled {
        svg {
          color: var(--icon-color-disabled);
        }
      }
    }
  }
`;

const OnlyIconButton = (
  props: ButtonProps & Props<Attributes<HTMLButtonElement>, HTMLButtonElement>
) => {
  const {
    ariaLabel,
    backgroundHoverColor,
    dataTestid,
    disabled,
    expanded,
    icon,
    iconHoverColor,
    id,
    onClick,
    size = 48,
    title,
    variant = 'dark',
    ...rest
  } = props;
  return (
    <StyledButton
      aria-controls={id && `${id}-controls`}
      aria-label={ariaLabel}
      aria-expanded={expanded}
      backgroundHoverColor={backgroundHoverColor}
      data-testid={dataTestid || id}
      iconHoverColor={iconHoverColor}
      id={id && `${id}-controls-content`}
      onClick={onClick}
      size={size}
      title={title}
      type="button"
      variant={variant}
      disabled={disabled}
      {...rest}
    >
      {icon}
    </StyledButton>
  );
};

export default OnlyIconButton;
