import { ActionType } from 'typesafe-actions';

import * as actions from './actions';
import { ActionTypes } from './constants';

export interface State {
  isLoading: boolean;
  loadingMessage?: string;
  step?: number;
}

export const initialState: State = {
  isLoading: false,
  step: 0,
};

const reducer = (
  state: State = initialState,
  action: ActionType<typeof actions>
): State => {
  switch (action.type) {
    case ActionTypes.SET_IS_LOADING:
      return {
        ...action.payload,
      };
    case ActionTypes.FETCH_APP_DATA_REQUEST: {
      const isSilent = action.payload?.silent;

      return {
        ...initialState,
        isLoading: isSilent ? state.isLoading : true,
        loadingMessage: isSilent ? state.loadingMessage : undefined,
        step: 1,
      };
    }
    case ActionTypes.FETCH_APP_DATA_FAILURE:
    case ActionTypes.FETCH_APP_DATA_SUCCESS:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
