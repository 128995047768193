import buildFiltersQuery, {
  baseDocumentSort,
  buildDocumentTypeStatusQuery,
  queryStatus,
} from 'modules/Documents/helpers/buildFiltersQuery';
import {
  Document,
  DocumentFilters,
  DocumentSort,
  DocumentStatus,
  DocumentType,
} from 'modules/Documents/models/document';
import Limits from 'types/limits';
import addFieldsQuery from 'utils/query/addFieldsQuery';
import { buildTortillaBaseQuery } from 'utils/query/buildTortillaBaseQuery';
import request from 'utils/request';

import { getFetchEndpoint } from '../helpers';

export default async function fetchDocuments({
  page = 0,
  pageSize = Limits.SMALL,
  sortBy,
  filters,
  status,
  documentType,
  canVoidDocument,
  fields,
  extraQuery,
  excludeTestInvoices = false,
}: {
  page?: number;
  pageSize?: Limits;
  sortBy?: DocumentSort;
  filters?: DocumentFilters;
  status?: DocumentStatus;
  documentType?: DocumentType;
  canVoidDocument?: boolean;
  fields?: string[];
  extraQuery?: string;
  excludeTestInvoices?: boolean;
}) {
  const endpoint = await getFetchEndpoint(documentType);
  const isInvoicesList = documentType === DocumentType.INVOICE;

  let query = buildTortillaBaseQuery({
    page,
    pageSize,
    sort: baseDocumentSort(sortBy),
  });

  query = buildDocumentTypeStatusQuery(query, {
    documentType,
    status: queryStatus(filters, status),
    canVoidDocument,
    filters,
    excludeTestInvoices: excludeTestInvoices || !isInvoicesList,
  });

  query += buildFiltersQuery(filters);

  if (fields?.length) {
    query += addFieldsQuery(fields);
  }

  if (extraQuery) {
    query += extraQuery;
  }

  const response = await request<any>(`${endpoint}?${query}`, {
    method: 'GET',
  });
  return response?.data?.data as { items: Document[]; count: number };
}
