export enum Roles {
  OWNER = 'owner',
  ADMIN = 'admin',
  EDITOR = 'editor',
  READER = 'reader',
  BOOKKEEPER = 'bookkeeper',
}

export interface Role {
  id: string;
  name: Roles;
}
