import { FC, useState } from 'react';
import { Hidden } from 'react-grid-system';

import useCreateAccountingAccount from 'modules/AccountingAccounts/hooks/useCreateAccountingAccount';
import useTranslations from 'modules/I18n/hooks/useTranslations';
import { useCloseModal, Dialog, MODAL_WIDTH_SMALL } from 'modules/Modals';
import { Button } from 'modules/Ui';

import { messages } from '../../messages';
import { AccountingAccountTypeEnum } from '../../models';
import { AddAccountTabs } from './components';
import { AccountingAccountModalProps } from './types';

export const AccountingAccountModal: FC<AccountingAccountModalProps> = ({
  onCreateAccountingAccount,
  onCancel,
  allowedTabs,
}) => {
  const { t } = useTranslations();
  const closeModal = useCloseModal();
  const [value, setValue] = useState<AccountingAccountTypeEnum>(
    AccountingAccountTypeEnum.BANK_ACCOUNT
  );
  const handleChange = (_event: any, newValue: AccountingAccountTypeEnum) => {
    setValue(newValue);
  };
  const { mutate: createAccountingAccount } = useCreateAccountingAccount(
    (data) => {
      if (onCreateAccountingAccount) {
        onCreateAccountingAccount(data);
      } else {
        closeModal();
      }
    }
  );
  return (
    <Dialog
      buttonPrimary={
        <Button
          data-testid="accountingAccount-submit"
          form={value}
          type="submit"
          variant="secondary"
        >
          {t(messages.confirm)}
        </Button>
      }
      buttonSecondary={
        <Hidden xs>
          <Button
            data-testid="accountingAccount-cancel"
            onClick={onCancel ?? closeModal}
            variant="stroke"
          >
            {t(messages.cancel)}
          </Button>
        </Hidden>
      }
      content={
        <AddAccountTabs
          allowedTabs={allowedTabs}
          onChange={handleChange}
          onSubmit={createAccountingAccount}
          value={value}
        />
      }
      dialogWidth={{ sm: MODAL_WIDTH_SMALL }}
      id="accountingAccountDialog"
      onClose={onCancel ?? closeModal}
      title={t(messages.title)}
      type="fullScreen"
    />
  );
};
