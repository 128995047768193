import React, { useMemo } from 'react';

import useTranslations from 'modules/I18n/hooks/useTranslations';
import { StepsRow, Box } from 'modules/Ui';

import messages from '../messages';

interface Props {
  currentStep: number;
}

const PaymentStep: React.FC<Props> = ({ currentStep }) => {
  const { t } = useTranslations();

  const steps1 = useMemo(
    () => [
      { text: t(messages.step1), active: currentStep === 0, percentage: '50' },
      { text: t(messages.step2), active: currentStep === 1, percentage: '100' },
    ],
    [t, currentStep]
  );

  const activeStep = steps1.find((step) => step?.active);
  return (
    <>
      <Box display={{ sm: 'flex' }} justifyContent={{ sm: 'flex-end' }}>
        <StepsRow {...{ activeStep }} />
      </Box>
    </>
  );
};

export default PaymentStep;
