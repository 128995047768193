import {
  createContext,
  FC,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
  useEffect,
} from 'react';

import { DocumentSerie } from 'modules/Settings/models';

const SeriesContext = createContext<SeriesContextValues | undefined>(undefined);

interface SeriesContextValues {
  series: DocumentSerie[];
  addSerie: (newSeries: DocumentSerie) => void;
}

interface SeriesProviderProps {
  children: ReactNode;
  series?: DocumentSerie[];
}

export const SeriesProvider: FC<SeriesProviderProps> = (props) => {
  const { children, series = [] } = props;
  const [state, setState] = useState(series);
  const addSerie = useCallback(
    (newSerie: DocumentSerie) => setState(state.concat(newSerie)),
    [setState, state]
  );

  useEffect(() => {
    setState(series);
  }, [series]);

  const value = useMemo(() => ({ series: state, addSerie }), [state, addSerie]);

  return (
    <SeriesContext.Provider value={value}>{children}</SeriesContext.Provider>
  );
};

export const useSeries = () => {
  const context = useContext(SeriesContext);
  if (context === undefined) {
    throw new Error('useSeries must be used within a SeriesProvider');
  }
  return context;
};
