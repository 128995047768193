import { defineMessages } from 'react-intl';

export const form = defineMessages({
  continue: { id: 'gofre.Register.Form.button.continue' },
  email: { id: 'gofre.Register.Form.email' },
  emailPlaceholder: { id: 'gofre.Register.Form.email.placeholder' },
  legend: { id: 'gofre.Register.Form.legend' },
  password: { id: 'gofre.Register.Form.password' },
  passwordHelpText: { id: 'gofre.Register.Form.password.helpText' },
});

export const formErrors = defineMessages({
  emailInvalid: { id: 'gofre.Register.Form.email.invalid' },
  emailRepeated: { id: 'gofre.Register.Form.email.repeated' },
  passwordRequired: { id: 'gofre.Register.Form.password.required' },
  passwordInvalid: { id: 'gofre.Register.Form.password.invalid' },
});

export const texts = defineMessages({
  login: { id: 'gofre.Register.login' },
  loginDesc: { id: 'gofre.Register.login.description' },
  logo: { id: 'gofre.Register.logo' },
  subtitle: { id: 'gofre.Register.subtitle' },
  title: { id: 'gofre.Register.title' },
  subtitleBookkeeper: { id: 'gofre.Register.subtitle-bookkeeper' },
  titleBookkeeper: { id: 'gofre.Register.title-bookkeeper' },
  error: { id: 'gofre.Register.error' },
  registerAsBusiness: { id: 'gofre.Register.business' },
  registerAsBookkeeper: { id: 'gofre.Register.bookkeeper' },
  serverError: { id: 'gofre.server.error' },
});

export const terms = defineMessages({
  terms: { id: 'gofre.Register.terms' },
});

export const registerFunnel = defineMessages({
  step01title: { id: 'gofre.Register.funnel.step01.title' },
  step01subtitle: { id: 'gofre.Register.funnel.step01.subtitle' },
  step01question: { id: 'gofre.Register.funnel.step01.question' },
  step01LinkDocuments: { id: 'gofre.Register.funnel.step01.linkDocuments' },
  step01LinkQuotes: { id: 'gofre.Register.funnel.step01.linkQuotes' },
  step01LinkHaveALook: { id: 'gofre.Register.funnel.step01.haveAlook' },
  step01SomethingDifferent: {
    id: 'gofre.Register.funnel.step01.somethingDifferent',
  },
  step02LinkHaveALooktitle: {
    id: 'gofre.Register.funnel.step02.LinkHaveALook.title',
  },
  step02LinkHaveALookSubtitle: {
    id: 'gofre.Register.funnel.step02.LinkHaveALook.subtitle',
  },
  step02LinkHaveALookQuestion: {
    id: 'gofre.Register.funnel.step02.LinkHaveALook.question',
  },
  step02LinkNoDocuments: {
    id: 'gofre.Register.funnel.step02.LinkHaveALook.link.noDocuments',
  },
  step02LinkOneToTenDocuments: {
    id: 'gofre.Register.funnel.step02.LinkHaveALook.link.oneToTen',
  },
  step02LinkTenOrMoreDocuments: {
    id: 'gofre.Register.funnel.step02.LinkHaveALook.link.tenOrMore',
  },
  step02LookingForQuestion: {
    id: 'gofre.Register.funnel.step02.lookingFor.subtitle',
  },
  step02LinkManageMyExpenses: {
    id: 'gofre.Register.funnel.step02.lookingFor.link.manageMyExpenses',
  },
  step02LinkBookkeeper: {
    id: 'gofre.Register.funnel.step02.lookingFor.link.bookkeeper',
  },
  step02LinkTemplates: {
    id: 'gofre.Register.funnel.step02.lookingFor.link.templates',
  },
  step02SomethingDifferent: {
    id: 'gofre.Register.funnel.step02.lookingFor.link.somethingDifferent',
  },
});
