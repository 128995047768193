import { FC, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useBaiAssetsInfo } from 'hooks/bai/useBaiAssetsInfo';
import { selectIsMobile } from 'modules/App/store/selectors';
import { ValidationError } from 'modules/Documents/models/document';
import useTranslations from 'modules/I18n/hooks/useTranslations';
import { useCloseModal, MODAL_WIDTH_DEFAULT, Dialog } from 'modules/Modals';
import { Box, Button, DocumentBaiError, MarkDown, Text } from 'modules/Ui';

import { BaiErrorsModalProps } from './BaiErrorsModalProps';
import messages from './messages';

const BaiErrorsModal: FC<BaiErrorsModalProps> = ({
  validationsErrors,
  isRecurrent,
  onPrimaryCta,
  onSecondaryCta,
  primaryCtaText,
  descMessage,
  title,
}) => {
  const { t } = useTranslations();
  const dispatch = useDispatch();
  const { logo: Icon, color } = useBaiAssetsInfo();

  const handleClose = useCloseModal();

  const handlePrimaryClick = useCallback(() => {
    onPrimaryCta({ closeModal: () => handleClose() });
  }, [dispatch]);

  const handleSecondaryClick = useCallback(() => {
    onSecondaryCta && onSecondaryCta({ closeModal: () => handleClose() });
  }, [dispatch]);

  const isMobile = useSelector(selectIsMobile);

  return (
    <Dialog
      id="document-tracking"
      title={title || t(messages.title)}
      onClose={handleClose}
      dialogWidth={{ sm: 'calc(100% - 72px)', md: MODAL_WIDTH_DEFAULT }}
      type="fullScreen"
      content={
        <>
          <Box
            alignItems="flex-start"
            backgroundColor="auxiliary"
            display="flex"
            margin={{ _: '0 0 20px', sm: '28px 0' }}
            padding="16px 16px 20px"
          >
            {Icon && (
              <Box
                tag="span"
                size={{ _: '32px', sm: '40px' }}
                marginRight="16px"
              >
                {Icon && <Icon size={isMobile ? 32 : 40} color={color} />}
              </Box>
            )}
            <MarkDown
              color="gray800"
              fontSize={14}
              lineHeight={18}
              text={descMessage || t(messages.text)}
            />
          </Box>
          <Text
            color="gray800"
            fontSize={14}
            fontWeight="600"
            lineHeight={22}
            marginBottom="4px"
            textTransform="uppercase"
          >
            {t(messages.errors)}
          </Text>
          <Box
            maxHeight={{ sm: '194px' }}
            marginBottom={{ _: '32px', sm: '28px' }}
            overflowY={{ sm: 'auto' }}
          >
            <Box tag="ul">
              {validationsErrors?.map((error: ValidationError) => (
                <DocumentBaiError
                  key={error.code}
                  message={`${error.code}: ${error.description.ES}`}
                />
              ))}
            </Box>
          </Box>
        </>
      }
      buttonSecondary={
        onSecondaryCta && (
          <Button
            form="create-contact-form"
            type="button"
            variant="stroke"
            onClick={handleSecondaryClick}
          >
            {t(
              isRecurrent
                ? messages.buttonSecondaryRecurrent
                : messages.buttonSecondary
            )}
          </Button>
        )
      }
      buttonPrimary={
        <Button
          data-testid="bai-errors-dialog-button"
          onClick={handlePrimaryClick}
          type="button"
          variant="secondary"
        >
          {primaryCtaText || t(messages.buttonPrimary)}
        </Button>
      }
    />
  );
};

export default BaiErrorsModal;
