import { useSelector } from 'react-redux';

import { space, SpaceProps } from 'styled-system';

import { selectIsMobile } from 'modules/App/store/selectors';
import styled from 'modules/Theme/styled-components';
import { Hr } from 'modules/Ui';

export interface SeparatorProps extends SpaceProps {
  color?: string;
}

const StyledSeparator = styled(Hr)<SeparatorProps>`
  ${space}
`;

const Separator: React.FC<SeparatorProps> = ({ color, ...rest }) => {
  const isMobile = useSelector(selectIsMobile);
  return (
    <StyledSeparator
      color={color}
      transform={!isMobile ? 'translateX(-32px)' : undefined}
      width={{ sm: 'calc(100% + 64px)' }}
      {...rest}
    />
  );
};

Separator.defaultProps = {
  marginBottom: { _: '16px', sm: '32px' },
};

export default Separator;
