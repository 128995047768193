import { css } from 'modules/Theme/styled-components';

export interface InputStylesProps {
  disabled?: boolean;
  hasIcon?: boolean;
  gridArea?: string;
}

const disabledStyles = css`
  -webkit-text-fill-color: ${(props) => props.theme.colors.primary300};
  -webkit-opacity:1;
  pointer-events: none;

  &::placeholder {
    opacity: 1;
  }
`;

const inputStyles = css<InputStylesProps>`
  background-color: transparent;
  box-sizing: border-box;
  border: none;
  color: inherit;
  font-family: inherit;
  font-size: ${(props) => props.theme.fontSizes[16]};
  height: 100%;
  line-height: ${(props) => props.theme.lineHeights[26]};
  margin: 0;
  outline: none;
  padding: 0;
  width: ${({ hasIcon }) => (hasIcon ? 'calc(100% - 24px)' : '100%')};
  ${({ gridArea }) => gridArea && `grid-area: ${gridArea};`}
  &::placeholder {
    color: ${(props) => props.theme.colors.gray400};
  }

  &:focus {
    outline: none;
  }

  ${({ disabled }) => disabled && disabledStyles}
`;

export default inputStyles;
