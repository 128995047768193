import { space, SpaceProps, position, PositionProps } from 'styled-system';

import useTranslations from 'modules/I18n/hooks/useTranslations';
import styled from 'modules/Theme/styled-components';
import { zIndex } from 'modules/Ui/zIndex';

import Box from '../Box';
import IconSpinner from '../Icons/IconSpinner/IconSpinner';
import messages from '../LoadingIndicator/messages';
import Text from '../Text';
import { Sizes } from '../Text/Text';

interface BackdropProps extends SpaceProps, PositionProps {
  /** Expressed in milliseconds */
  animationDuration?: number;
  backdropColor?: string;
  color?: string;
  /** Explain what content will load. Remember, Accessibility Matters */
  hiddenText?: string;
  iconCenter?: boolean;
  size?: number;
  /** Defines the width of this stroke. Strokes are drawn centered around the path */
  strokeWidth?: number;
  tag?: 'div' | 'span';
  text?: string;
  textColor?: string;
  fullScreen?: boolean;
  textSize?: Sizes;
}

const Backdrop = styled(Box)`
  backdrop-filter: blur(0.2px);
  pointer-events: none;
  ${position}
  ${space}
`;

Backdrop.defaultProps = {
  position: 'absolute',
};

const Spinner = (props: BackdropProps) => {
  const { t } = useTranslations();
  const {
    animationDuration = 3200,
    backdropColor = 'backdropLight',
    color = 'brand500',
    fullScreen = false,
    hiddenText,
    iconCenter = true,
    size = 86,
    strokeWidth = 5,
    tag = 'div',
    text,
    textColor = 'gray800',
    textSize = 'xxxl',
    ...rest
  } = props;

  const textNode = text ? (
    <Text
      {...{
        color: textColor,
        mt: '16px',
        tag: 'span',
        textSize,
        zIndex: zIndex.SPINNER,
        textAlign: 'center',
      }}
    >
      {text}
    </Text>
  ) : (
    <Text
      {...{
        hide: true,
        tag: 'span',
      }}
    >
      {hiddenText || t(messages.checkText)}
    </Text>
  );

  const content = (
    <>
      <IconSpinner
        animationDuration={animationDuration}
        color={color}
        size={size}
        strokeWidth={strokeWidth}
        {...{
          ...rest,
        }}
      />
      {textNode}
    </>
  );

  if (fullScreen) {
    return (
      <Backdrop
        {...props}
        iconCenter={iconCenter}
        tag={tag}
        color={color}
        alignItems={iconCenter && 'center'}
        backgroundColor={backdropColor}
        contain="paint"
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
        left="0"
        position="fixed"
        role="alert"
        top="0"
        width="100%"
        zIndex={zIndex.SPINNER}
      >
        {content}
      </Backdrop>
    );
  }

  return (
    <Backdrop
      {...props}
      iconCenter={iconCenter}
      tag={tag}
      color={color}
      role="alert"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems={iconCenter && 'center'}
      height="100%"
      width="100%"
    >
      {content}
    </Backdrop>
  );
};

export default Spinner;
