import { useCallback, FC } from 'react';
import { Visible } from 'react-grid-system';
import { useHistory } from 'react-router-dom';

import useTranslations from 'modules/I18n/hooks/useTranslations';
import { useCloseModal, CloseButton, Dialog } from 'modules/Modals';
import useTheme from 'modules/Theme/hooks/useTheme';
import { Box, Button, Text } from 'modules/Ui';
import {
  IconTotalRectification,
  IconSubstitutionRectification,
} from 'modules/Ui/Icons';
import { menuSizes } from 'modules/Ui/Menu/MenuMobile/menuSizes';

import { documentRectifyModalMessages as messages } from '../messages';
import { DocumentRectifyModalProps, RectificationType } from './types';

const getPathName = (
  type: RectificationType,
  path: string,
  isReceipt: boolean
) => {
  const isPartialCorrective = type === RectificationType.PARTIAL;
  if (isReceipt && isPartialCorrective) {
    return `${path}/tickets-rectify-substitute/document-data`;
  }
  if (!isReceipt && isPartialCorrective) {
    return `${path}/rectify-substitute/document-data`;
  }
  return `${path}/rectify`;
};

const DocumentRectifyModal: FC<DocumentRectifyModalProps> = ({
  path,
  isReceipt,
}) => {
  const { t } = useTranslations();
  const history = useHistory();

  const handleCancel = useCloseModal();

  const handleClick = useCallback(
    (type: RectificationType) => {
      const pathname = getPathName(type, path, isReceipt);
      handleCancel();
      history.push({
        pathname,
      });
    },
    [history]
  );

  const theme = useTheme();

  return (
    <Dialog
      dialogWidth={{ md: '612px' }}
      id="DocumentRectifyModal"
      onClose={handleCancel}
      type="fullScreen"
    >
      <Visible xs sm>
        <Box
          alignItems="center"
          backgroundColor="gray0"
          display="flex"
          height="56px"
          justifyContent="flex-end"
          marginBottom="18px"
          position="sticky"
          top="0"
        >
          <CloseButton onClose={handleCancel} color="primary500" />
        </Box>
      </Visible>
      <Box
        height={{
          _: `calc(100vh - ${menuSizes.HEADER_HEIGHT_MOBILE}px)`,
          sm: 'initial',
        }}
        overflowY={{ _: 'auto', sm: 'initial' }}
      >
        <Text
          color="primary500"
          fontSize={21}
          fontWeight="600"
          lineHeight={24}
          marginBottom={{ _: '32px', sm: '40px' }}
          marginTop={{ sm: '12px' }}
        >
          {t(messages.title)}
        </Text>
        <Box
          display={{ sm: 'flex' }}
          justifyContent={{ sm: 'space-between' }}
          marginBottom={{ _: '40px', sm: 'initial' }}
        >
          <Box
            border={`1px solid ${theme.colors.primary200}`}
            borderRadius="8px"
            boxSizing="border-box"
            marginBottom={{ _: '24px', sm: 'initial' }}
            minHeight="256px"
            padding="24px"
            width={{ sm: 'calc(50% - 12px)' }}
            display="flex"
            flexDirection="column"
          >
            <Box flex="1">
              <IconTotalRectification
                color="brand500"
                marginBottom="20px"
                size={32}
              />
              <Text
                color="gray800"
                fontSize={16}
                lineHeight={18}
                marginBottom="8px"
              >
                {t(messages.totalTitle)}
              </Text>
              <Text fontSize={14} lineHeight={18}>
                {t(messages.totaldescription)}
              </Text>
            </Box>
            <Button
              variant="primary"
              data-testid="rectification-total"
              onClick={() => handleClick(RectificationType.TOTAL)}
              width="100%"
            >
              {t(messages.emitRectification)}
            </Button>
          </Box>
          <Box
            border={`1px solid ${theme.colors.primary200}`}
            borderRadius="8px"
            boxSizing="border-box"
            display="flex"
            flexDirection="column"
            minHeight="256px"
            padding="24px"
            width={{ sm: 'calc(50% - 12px)' }}
          >
            <Box flex="1">
              <IconSubstitutionRectification
                color="brand500"
                marginBottom="20px"
                size={32}
              />
              <Text
                color="gray800"
                fontSize={16}
                lineHeight={18}
                marginBottom="8px"
              >
                {t(messages.partialTitle)}
              </Text>
              <Text fontSize={14} lineHeight={18}>
                {t(messages.partialDescription)}
              </Text>
            </Box>
            <Button
              variant="primary"
              width="100%"
              data-testid="rectification-partial"
              onClick={() => handleClick(RectificationType.PARTIAL)}
            >
              {t(messages.rectifyDocument)}
            </Button>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

export default DocumentRectifyModal;
