import { useEffect, useState, FC } from 'react';

import { useField, useFormikContext } from 'formik';
import { debounce } from 'lodash';

import { useInvalidateAndUpdate } from 'modules/App/hooks/useInvalidateAndUpdate';
import createContact, {
  invalidateQueries,
} from 'modules/Contacts/Create/services/createContact';
import texts from 'modules/Contacts/CreateContactDialog/messages';
import fetchContact from 'modules/Contacts/Detail/services/fetchContact';
import useBusinessHasContacts from 'modules/Contacts/hooks/useBusinessHasContacts';
import useLastUsedContacts from 'modules/Contacts/hooks/useLastUsedContacts';
import { fetchContactsLimit } from 'modules/Contacts/List/services/fetchContactsLimit';
import { Contact, ContactType, emptyContact } from 'modules/Contacts/models';
import { useDocumentToggles } from 'modules/Documents/components/DocumentTogglesProvider/DocumentTogglesProvider';
import useMilestones, {
  useSetQueryMilestones,
} from 'modules/Home/hooks/useMilestones';
import useTranslations from 'modules/I18n/hooks/useTranslations';
import { ModalTypes } from 'modules/Modals/constants';
import useOpenModal from 'modules/Modals/hooks/useOpenModal';
import useNotifications from 'modules/Notifications/hooks/useNotifications';
import { Option } from 'modules/Ui/Form/SelectCommon/types';
import { SelectFormikField } from 'modules/Ui/Formik';
import requestErrorHandler from 'utils/requestErrorHandler';
import { useBlockingMutation } from 'utils/useBlockingMutation';

import Text from '../Text';
import OptionLabel from './components/OptionLabel';
import messages from './messages';

interface Props {
  helpText?: string;
  allowIncompleteContacts?: boolean;
  menuListText?: string;
  selectedOption?: Option | null;
  setContact: (contact: Contact) => void;
  setSelectedOption: (option?: Option | null) => void;
  label?: string;
  placeholder?: string;
  required?: boolean;
  fromPayments?: boolean;
  handleAfterSubmitOrCancelFromPayments?: (createdContact?: Contact) => void;
}

const SearchContactInput: FC<Props> = ({
  helpText,
  allowIncompleteContacts,
  menuListText,
  selectedOption,
  setContact,
  setSelectedOption,
  label,
  placeholder,
  required = true,
  fromPayments,
  handleAfterSubmitOrCancelFromPayments,
}) => {
  const formik = useFormikContext<any>();
  const [taxRegimeField] = useField('taxRegime');
  const { isTest } = useDocumentToggles();
  const [optionsSelect, setOptions] = useState<Option[] | undefined>(undefined);
  const [currentSearchTerm, setSearchTerm] = useState<string>('');
  const { t } = useTranslations();
  const { milestones } = useMilestones();
  const { setQueryData } = useSetQueryMilestones();
  const invalidateAndUpdate = useInvalidateAndUpdate();
  const { hasCreatedContact } = useBusinessHasContacts();
  const { contacts, isLoading } = useLastUsedContacts(
    currentSearchTerm.length > 1 ? currentSearchTerm : ''
  );

  useEffect(() => {
    const options: Option[] | undefined = contacts?.map((contact: Contact) => {
      const option: Option = {
        value: contact.id || '',
        label: contact.fiscalName,
        additionalData: contact,
      };
      return option;
    });
    setOptions(options);
  }, [contacts]);

  const handleContactReload = (updatedContact: Contact) => {
    const option: Option = {
      value: updatedContact.id || '',
      label: updatedContact.fiscalName,
      additionalData: updatedContact,
    };
    setSelectedOption && setSelectedOption(option);
    setContact && setContact(updatedContact);
  };

  // Update component when contact has been updated
  useEffect(() => {
    if (formik.values.contact?.id) {
      const updatedContact = formik.values.contact;
      handleContactReload(updatedContact);
      // we have a problem when navigating back from /review-data to /document-data.
      // We are reloading this component and hence setting the contact again which means RE checkbox returns to contact setting.
      // This means that if our contact has RE it will appear checked, if it doesn't it will appear unchecked
      // Here we are setting hasSalesEqualizationTax as it was previously
      formik.setFieldValue(
        'hasSalesEqualizationTax',
        formik.values.hasSalesEqualizationTax
      );
    }
  }, [formik.values.contact]);

  const getNoOptionsMessageComponent = () => {
    const text = hasCreatedContact
      ? t(messages.noMatches)
      : t(messages.noContactCreated);
    return <Text textAlign="left">{text}</Text>;
  };

  const { mutateAsync } = useBlockingMutation(createContact, {
    onSuccess: () => {
      invalidateAndUpdate({ invalidateQueries });
      setQueryData({
        customDocument: !!milestones?.customDocument,
        firstDocument: !!milestones?.firstDocument,
        businessInfo: !!milestones?.businessInfo,
        firstContact: true,
      });
    },
  });

  const handleOnSelect = async (id: string) => {
    if (id === null) {
      setSelectedOption(null);
      setContact({ ...emptyContact });
    } else if (isTest && selectedOption) {
      setContact(selectedOption?.additionalData);
    } else {
      const contact = await fetchContact(id);
      const option: Option = {
        value: contact.id || '',
        label: contact.fiscalName,
        additionalData: contact,
      };
      setSelectedOption(option);
      setContact(contact);
    }
  };

  const openLimitContactsModal = useOpenModal({
    type: ModalTypes.LIMIT_CONTACTS,
    navigation: 'do-nothing',
  });

  const notification = useNotifications();

  const handleSubmit = async (contact: Contact) => {
    const { data: createdContact } = await mutateAsync({ ...contact, isTest });
    if (fromPayments) {
      handleAfterSubmitOrCancelFromPayments?.(createdContact);
    } else {
      handleContactReload(createdContact);
    }
  };

  const handleOnInputChange = (newSearchTerm: string) => {
    setSearchTerm(newSearchTerm);
  };

  const debouncedOnInputChange = debounce(
    (nextValue) => handleOnInputChange(nextValue),
    500,
    { leading: false }
  );

  const openCreateContactModal = async () => {
    try {
      const result = await fetchContactsLimit();
      if (!result?.data?.enoughAssets) {
        openLimitContactsModal();
        return;
      }
      openCreateContact();
    } catch (e) {
      const {
        isNetworkError,
        networkErrorTranslationKey,
        genericErrorTranslationKey,
      } = requestErrorHandler(e);
      if (isNetworkError) {
        notification.error(networkErrorTranslationKey);
      } else {
        notification.error(genericErrorTranslationKey);
      }
    }
  };
  const openCreateContact = useOpenModal({
    type: ModalTypes.CREATE_CONTACT,
    clientType: ContactType.CUSTOMER,
    titleText: t(texts.newClient),
    onAcceptText: t(texts.buttonCreate),
    allowIncompleteContacts,
    taxRegime: taxRegimeField.value,
    concepts: formik.values.concepts,
    searchTerm: currentSearchTerm || '',
    onSubmit: (contact: Contact) => {
      handleSubmit(contact);
    },
    onCancel: () => {
      handleAfterSubmitOrCancelFromPayments?.();
    },
  });

  return (
    <SelectFormikField
      label={label ?? t(messages.clientLabel)}
      required={required}
      data-testid="create-contact-select"
      canCreateOption
      isSearchable
      isClearable
      formatOptionLabel={(option) => {
        return <OptionLabel {...{ option }} />;
      }}
      id="createContactSelect"
      noOptionsMessage={getNoOptionsMessageComponent}
      shouldRenderMobileSelect={false}
      defaultOptions
      options={optionsSelect}
      onAddOption={openCreateContactModal}
      placeholder={placeholder ?? t(messages.chooseContact)}
      onChange={handleOnSelect}
      loadingMessage={() => t(messages.loadingContacts)}
      menuListText={menuListText}
      selectedOption={selectedOption}
      helpText={helpText}
      onInputChange={debouncedOnInputChange}
      isLoading={isLoading}
      filterOption={() => true}
    />
  );
};

export default SearchContactInput;
