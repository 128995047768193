import styled from 'modules/Theme/styled-components';
import Box from 'modules/Ui/Box';

const Radio = styled(Box)`
  background-color: ${({ disabled, theme }) =>
    disabled ? theme.colors.gray200 : theme.colors.gray0};
  border-color: ${({ error, theme }) =>
    error ? theme.colors.error : theme.colors.gray300};

  &:after {
    background-color: ${(props) => props.theme.colors.brand500};
    opacity: 0;
    border-radius: 50%;
    content: '';
    display: block;
    height: 14px;
    transition: opacity 0.1s linear;
    width: 14px;
  }
`;

export default Radio;
