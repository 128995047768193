import { useInvalidateAndUpdate } from 'modules/App/hooks/useInvalidateAndUpdate';
import useNotifications from 'modules/Notifications/hooks/useNotifications';
import { ResponseError } from 'types/response-error.type';
import requestErrorHandler from 'utils/requestErrorHandler';
import { useBlockingMutation } from 'utils/useBlockingMutation';

import { remittanceNotifications } from '../messages';
import editRemittance, {
  EditRemittanceResponse,
  invalidateQueries,
} from '../services/editRemittance';

function useEditRemittance() {
  const notifications = useNotifications();
  const invalidateAndUpdate = useInvalidateAndUpdate();

  const { mutate, mutateAsync } = useBlockingMutation(editRemittance, {
    onSuccess: async (response): Promise<EditRemittanceResponse> => {
      const message = remittanceNotifications.editSuccess.id;
      notifications.success(message, { ttl: 5000 });
      invalidateAndUpdate({ invalidateQueries, isAsync: true });
      return response;
    },

    onError: async (error: ResponseError) => {
      const { isNetworkError, networkErrorTranslationKey } =
        requestErrorHandler(error);
      notifications.error(
        isNetworkError
          ? networkErrorTranslationKey
          : remittanceNotifications.editError.id
      );
    },
  });
  return { mutate, mutateAsync };
}

export default useEditRemittance;
