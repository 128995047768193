import { FC, ReactNode } from 'react';
import { Hidden } from 'react-grid-system';

import { Box, Text } from 'modules/Ui';

interface Props {
  children: ReactNode;
  title: string;
}

const IntegrationSteps: FC<Props> = ({ children, title }) => {
  return (
    <>
      <Hidden sm md>
        <Text
          color="primary300"
          fontSize={14}
          fontWeight="normal"
          lineHeight={19}
          marginBottom="20px"
          tag="h3"
          textTransform="uppercase"
        >
          {title}
        </Text>
      </Hidden>
      <Box
        display="grid"
        gap={{ _: '12px', sm: '8px', md: '12px' }}
        gridTemplateColumns={{ sm: 'repeat(3, 1fr)', md: 'initial' }}
        tag="ol"
      >
        {children}
      </Box>
    </>
  );
};

export default IntegrationSteps;
