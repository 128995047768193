import { useEffect } from 'react';

import { useField } from 'formik';

import useTranslations from 'modules/I18n/hooks/useTranslations';
import { Button, FormikField } from 'modules/Ui';
import { IconCheck } from 'modules/Ui/Icons';

import { Coupon } from '../types';
import messages from './messages';

interface Props {
  coupon?: Coupon;
  isLoading: boolean;
}

const PaymentFormCouponsCode: React.FC<Props> = ({ coupon, isLoading }) => {
  const { t } = useTranslations();
  const [, , helper] = useField('code');

  useEffect(() => {
    helper.setValue(coupon?.promotionCode ?? '');
  }, [coupon]);

  return (
    <>
      <FormikField
        id="code"
        name="code"
        width={{ sm: '50%' }}
        label={t(messages.promoCodeLabel)}
        disabled={!!coupon}
      />
      {!coupon && (
        <Button
          icon={<IconCheck />}
          margin={{
            sm: '20px 0 0 16px',
          }}
          disabled={isLoading || !!coupon}
          variant="ghost"
          type="submit"
        >
          {t(messages.promoCodeValidate)}
        </Button>
      )}
    </>
  );
};

export default PaymentFormCouponsCode;
