import { getConfig } from 'modules/App/config';
import request from 'utils/request';

import { AsyncTaskStatus } from '../enums';

type AsyncTaskStatusResponse<T> = {
  id: string;
  createdAt: string;
  updatedAt: string;
  status: AsyncTaskStatus;
  result: T;
};

export const fetchAsyncTaskStatus = async <T>(
  taskId: string
): Promise<AsyncTaskStatusResponse<T>> => {
  const endpoint = (await getConfig()).apiAsyncTaskService;

  const response = await request<{ data: AsyncTaskStatusResponse<T> }>(
    `${endpoint}/${taskId}`,
    {
      method: 'GET',
    }
  );

  return response.data.data;
};
