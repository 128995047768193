import IconByPath, { IconByPathProps } from './Icon/IconType/IconByPath';

const IconSortOut = (props: IconByPathProps) => (
  <IconByPath
    {...props}
    fillRule="evenodd"
    path="M16.914 2a1.5 1.5 0 0 0-1.06.44L14.793 3.5H3v3H2V8l-.7.933a1.5 1.5 0 0 0-.3.9V20.5A1.5 1.5 0 0 0 2.5 22h19a1.5 1.5 0 0 0 1.5-1.5V9.833a1.5 1.5 0 0 0-.3-.9L22 8V5h-1V2h-4.086ZM3 8.5v-1h13.207l1.354-1.354A.5.5 0 0 1 17.914 6H21v3H3v-.5ZM2 10v10.5a.5.5 0 0 0 .5.5h19a.5.5 0 0 0 .5-.5V10H2Zm18-5V3h-3.086a.5.5 0 0 0-.353.146L15.207 4.5H4v2h11.793l1.06-1.06A1.5 1.5 0 0 1 17.915 5H20Z"
  />
);

export default IconSortOut;
