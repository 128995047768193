import { useSelector } from 'react-redux';

import {
  hasElectronicDocumentProvider,
  shouldSendElectronicDocument,
} from 'modules/Business/businessUtils';
import { selectCurrentBusiness } from 'modules/Business/Current/selectors';
import useTranslations from 'modules/I18n/hooks/useTranslations';
import { useShopifyConfiguration } from 'modules/Integrations/hooks';
import useDocumentSettings from 'modules/Settings/hooks/useDocumentSettings';

import { shopifyIntefrationSteps } from '../../messages';
import IntegrationSteps from '../IntegrationSteps/IntegrationSteps';
import IntegrationStepItem from '../IntegrationSteps/IntegrationStepsItem';

const ShopifyIntegrationSteps = () => {
  const { t } = useTranslations();
  const { data } = useShopifyConfiguration();
  const business = useSelector(selectCurrentBusiness);
  const isBai = hasElectronicDocumentProvider(business);
  const ticketBaiConfiguration = data?.isActive;
  const isBaiConnected = business && shouldSendElectronicDocument(business);
  const { isFetching, data: settings } = useDocumentSettings();
  const isBaiConfigured = isBai && !!settings.operationDescription;
  const hideComponent = !isBai || isFetching || !data;
  if (hideComponent) {
    return null;
  }
  return (
    <IntegrationSteps title={t(shopifyIntefrationSteps.title)}>
      <IntegrationStepItem
        isActive={ticketBaiConfiguration}
        text={t(shopifyIntefrationSteps.step01)}
      />
      <IntegrationStepItem
        baiConnection
        isActive={isBaiConnected}
        text={t(shopifyIntefrationSteps.step03)}
      />
      <IntegrationStepItem
        isActive={isBaiConfigured}
        text={t(shopifyIntefrationSteps.step02)}
      />
    </IntegrationSteps>
  );
};

export default ShopifyIntegrationSteps;
