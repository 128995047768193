import { css } from 'modules/Theme/styled-components';

const shadowHoverTrSafary = () => {
  return css`
    @media (hover: hover) {
      &:hover {
        cursor: pointer;
        background: linear-gradient(to bottom, ${(props) =>
          props.theme.colors.gray0} 90%,
          ${(props) => props.theme.colors.gray200} 100%);
      }
    }

    &:focus {
      outline: none;
    }

    &:focus-within {
      background: linear-gradient(to bottom, ${(props) =>
        props.theme.colors.gray0} 90%,
        ${(props) => props.theme.colors.gray200} 100%);
    }
  `;
};

export default shadowHoverTrSafary;
