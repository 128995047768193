import { FC, useCallback, useState } from 'react';
import TagsManager from 'react-tagsinput';

// TagsManager can't use hooks and break the app
import theme from 'modules/Theme';
import styled from 'modules/Theme/styled-components';
import AutosizeInput from 'modules/Ui/AutosizeInput/AutosizeInput';
import { emailRegex } from 'modules/Ui/Email/validation/emailRegex';
import Tag from 'modules/Ui/Tag';

import './react-tagsinput.css';

const StyledTag = styled(Tag)`
  @media (hover: hover) {
    &:hover {
      border: 1px solid ${(props) => props.theme.colors.gray500};
    }
  }

  &:focus {
    border: 1px solid ${(props) => props.theme.colors.gray500};
  }
`;

const RenderTag: FC<any> = (props) => {
  const {
    tag,
    key,
    onRemove,
    classNameRemove,
    getTagDisplayValue,
    deleteBtnText,
    ...other
  } = props;

  return (
    <StyledTag
      key={key}
      {...other}
      accessibleText={`${getTagDisplayValue(tag)}`}
      border={`1px solid ${theme.colors.gray300}`}
      borderRadius="16px"
      ellipsis
      iconColor={theme.colors.gray600}
      iconSize={24}
      marginTop="6px"
      maxWidth={{ _: '100%', sm: '324px' }}
      onClick={(event: React.SyntheticEvent) => {
        onRemove(key);
        event.preventDefault();
        event.stopPropagation();
      }}
      padding="0 11px 0 16px"
    >
      {getTagDisplayValue(tag)}
    </StyledTag>
  );
};

const TagsInput: React.FC<TagsManager.ReactTagsInputProps<string>> = ({
  validationRegex = emailRegex,
  maxTags = 3,
  onChangeInput,
  ...props
}) => {
  const [inputValue, setInputValue] = useState('');

  const onInputChange = useCallback(
    (newInputValue) => {
      if (props.value.length < maxTags) {
        setInputValue(newInputValue);
        if (onChangeInput) {
          onChangeInput(newInputValue);
        }
      }
    },
    [props.value.length]
  );

  return (
    <>
      <TagsManager
        {...props}
        addKeys={[32, 13, 186, 188, 9]}
        removeKeys={[8]}
        renderInput={AutosizeInput}
        renderTag={RenderTag}
        validationRegex={validationRegex}
        maxTags={maxTags}
        onChangeInput={onInputChange}
        inputValue={inputValue}
      />
    </>
  );
};

export default TagsInput;
