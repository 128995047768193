import { FC } from 'react';
import { useHistory } from 'react-router';

import { useInvalidateAndUpdate } from 'modules/App/hooks/useInvalidateAndUpdate';
import deleteClients, {
  invalidateQueries,
} from 'modules/Bookkeeper/services/deleteClients';
import useTranslations from 'modules/I18n/hooks/useTranslations';
import { DialogConfirm, useCloseModal } from 'modules/Modals';
import useNotifications from 'modules/Notifications/hooks/useNotifications';
import { ROUTES } from 'pages/bookkeepers/routesMap';
import { useBlockingMutation } from 'utils/useBlockingMutation';

import messages from './messages';
import { DeleteClientModalProps } from './types';

const DeleteClientModal: FC<DeleteClientModalProps> = ({ id }) => {
  const invalidate = useInvalidateAndUpdate();
  const { t } = useTranslations();
  const history = useHistory();
  const notifications = useNotifications();
  const { mutate } = useBlockingMutation(deleteClients, {
    onSuccess: () => {
      history.push(ROUTES.BOOKKEEPER_CLIENTS);
      invalidate({ invalidateQueries });
      notifications.success(messages.success.id);
    },
    onError: () => {
      notifications.error(messages.error.id);
    },
  });

  const closeModal = useCloseModal();

  const handleConfirm = async () => {
    mutate({ ids: [id], excludeIds: false });
    closeModal();
  };

  return (
    <DialogConfirm
      id="deleteClientBookkeeper"
      title={t(messages.title)}
      subtitle={t(messages.subtitle)}
      text={t(messages.text, {
        br: () => <br />,
      })}
      confirmText={t(messages.confirm)}
      onConfirm={handleConfirm}
      onCancel={closeModal}
    />
  );
};

export default DeleteClientModal;
