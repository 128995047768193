import { FC } from 'react';

import useTranslations from 'modules/I18n/hooks/useTranslations';
import { DialogConfirm, useCloseModal } from 'modules/Modals';
import { Text } from 'modules/Ui';

import { RemittanceDocumentDto } from '../types';
import { messages as defaultMessages } from './messages';
import { DocumentsAlreadyInARemittanceModalProps } from './types';

const DocumentsAlreadyInARemittanceModal: FC<
  DocumentsAlreadyInARemittanceModalProps
> = (props) => {
  const { remittanceDocuments, onConfirm, messages } = props;
  const closeModal = useCloseModal();
  const { t } = useTranslations();

  const documentsIdentifiers = remittanceDocuments.map(
    (remittanceDocument: RemittanceDocumentDto) =>
      remittanceDocument.documentIdentifier
  );

  const uniqueDocumentsIdentifiers = Array.from(new Set(documentsIdentifiers));

  return (
    <DialogConfirm
      onConfirm={() => {
        closeModal();
        onConfirm();
      }}
      title={t(messages?.title || defaultMessages.title)}
      content={
        <>
          <Text marginBottom="16px">{t(defaultMessages.subtitle)}</Text>
          {uniqueDocumentsIdentifiers.map((documentIdentifier) => (
            <Text fontWeight="600" key={documentIdentifier}>
              {documentIdentifier}
            </Text>
          ))}
          <Text marginTop="16px">{t(defaultMessages.finalText)}</Text>
        </>
      }
    />
  );
};

export default DocumentsAlreadyInARemittanceModal;
