import { DocumentSendInfo } from 'modules/Documents/models/document';

interface Params {
  sendInfo?: DocumentSendInfo;
  ccInput?: string;
  bccInput?: string;
  recaptcha?: string;
}

export function processSendInfo({
  sendInfo,
  ccInput,
  bccInput,
  recaptcha,
}: Params) {
  if (!sendInfo) return null;

  return {
    to: sendInfo.to,
    cc: normalizeMails(sendInfo.cc, ccInput),
    bcc: normalizeMails(sendInfo.bcc, bccInput),
    subject: sendInfo.subject,
    content: sendInfo.content,
    showDetails: sendInfo.showDetails,
    recaptcha,
  };
}

const normalizeMails = (
  list: string[] = [],
  input: string | undefined
): string[] | null => {
  if (input && !list.includes(input)) {
    list.push(input);
  }
  return list.length ? list : null;
};
