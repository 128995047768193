import { FC } from 'react';

import { background, color, layout, space, typography } from 'styled-system';

import styled from 'modules/Theme/styled-components';
import { TextProps } from 'modules/Ui/Text/Text';

import Text from '../Text';

export interface BasePillProps extends TextProps {
  borderColor?: string;
  fontColor?: string;
}

const StyledPill = styled(Text)<BasePillProps>`
  ${background}
  ${color}
  ${layout}
  ${space}
  ${typography}
  ${({ borderColor }) => borderColor && `border: 1px solid ${borderColor};`}
`;

const BasePill: FC<BasePillProps> = ({
  backgroundColor,
  borderColor,
  children,
  fontColor,
  fontSize,
  hasEllipsis = true,
  lineHeight,
  ...rest
}) => {
  return (
    <StyledPill
      backgroundColor={backgroundColor || 'neutral'}
      borderColor={borderColor}
      boxSizing="border-box"
      color={fontColor || 'primary500'}
      fontSize={fontSize || 13}
      hasEllipsis={hasEllipsis}
      lineHeight={lineHeight || 16}
      tag="span"
      textAlign="center"
      {...rest}
    >
      {children}
    </StyledPill>
  );
};

StyledPill.defaultProps = {
  alignItems: 'center',
  borderRadius: '2px',
  display: 'inline-flex',
  fontWeight: '600',
  height: '24px',
  justifyContent: 'center',
  padding: '3px 4px 4px',
  minWidth: '85px',
};

export default BasePill;
