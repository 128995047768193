import useTheme from 'modules/Theme/hooks/useTheme';

import IconByString, { IconByStringProps } from './Icon/IconType/IconByString';

export interface IconAddProps extends IconByStringProps {
  iconCrossColor?: string;
}

const IconAddQuote = (props: IconAddProps) => {
  const { iconCrossColor, innerPathColor, ...rest } = props;
  const theme = useTheme();
  return (
    <IconByString {...rest}>
      <path
        d="M14 3a2 2 0 0 1-2 2H8a2 2 0 0 1-2-2H3v19h14V3h-3Zm0-1h4v21H2V2h4V1h8v1Zm-1 0H7v1a1 1 0 0 0 .883.993L8 4h4a1 1 0 0 0 .993-.883L13 3V2ZM6 15h8v1H6v-1Zm8 4H6v1h8v-1ZM13 8H7v3h6V8ZM7 7H6v5h8V7H7Z"
        fill="currentColor"
        fillRule="evenodd"
      />
      <circle
        cx="18"
        cy="18"
        fill={innerPathColor ? `${innerPathColor}` : theme.colors.brand500}
        r="6"
        shapeRendering="geometricPrecision"
      />
      <path
        d="M18.514 14.513 17.5 14.5v3h-3v1h3v3h1v-3h3v-1h-3v-3l.014.013z"
        fill={iconCrossColor ? `${iconCrossColor}` : theme.colors.gray0}
        shapeRendering="crispEdges"
      />
    </IconByString>
  );
};

export default IconAddQuote;
