import { getConfig } from 'modules/App/config';
import { API_CREDENTIALS } from 'modules/App/queries/queries';
import request from 'utils/request';

interface Props {
  id: string;
}
const deleteApiCredential = async (params: Props): Promise<void> => {
  const endpoint = (await getConfig()).apiIntegrationApiCredentials;

  const response = await request<void>(`${endpoint}/${params.id}`, {
    method: 'DELETE',
  });

  return response.data;
};
export const invalidateQueries = [API_CREDENTIALS];

export default deleteApiCredential;
