import IconByPath, { IconByPathProps } from './Icon/IconType/IconByPath';

const IconRectificate = (props: IconByPathProps) => {
  return (
    <IconByPath
      {...props}
      fillRule="evenodd"
      path="M10 1 3 8v15h18V1H10ZM5.414 7 9 3.414V7H5.414Zm-1 1H10V2.414L10.414 2H20v20H4V8.414L4.414 8ZM18 4v5h-1V5.71L13.74 9l-.709-.704L16.296 5H13V4h5Zm-7.444 15H9.5v-7h2.495c1.624 0 2.424.906 2.424 2.194 0 .94-.428 1.655-1.286 1.972L14.685 19h-1.203l-1.422-2.649h-1.504V19Zm0-6.094v2.536h1.34c1.029 0 1.46-.458 1.46-1.248 0-.793-.431-1.288-1.474-1.288h-1.326Z"
    />
  );
};

export default IconRectificate;
