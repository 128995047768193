import { useEffect } from 'react';

export function scrollToTop() {
  window.scrollTo({ top: 0, behavior: 'smooth' });
}

function useScrollToTop() {
  useEffect(() => {
    scrollToTop();
  }, []);
}

export default useScrollToTop;
