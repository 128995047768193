import {
  PaymentMethodType,
  PaymentMethodDataType,
} from 'modules/PaymentMethods/models/paymentMethods';

export function getDefaultData(
  type?: PaymentMethodType,
  defaultPhone?: string
): Partial<PaymentMethodDataType> {
  switch (type) {
    case PaymentMethodType.BIZUM:
      return { phoneNumber: defaultPhone };
    case PaymentMethodType.TRANSFER:
      return { IBAN: '' };
    case PaymentMethodType.OTHER:
      return { description: '' };
    default:
      return {};
  }
}
