import * as Yup from 'yup';

import { formText } from 'modules/Forms/messages';
import { TranslationFunction } from 'modules/I18n/hooks/useTranslations';
import { PaymentMethodType } from 'modules/PaymentMethods/models/paymentMethods';

import messages from './messages';

function getValidationSchema(t: TranslationFunction) {
  return Yup.object().shape({
    data: Yup.object()
      .when('type', {
        is: PaymentMethodType.BIZUM,
        then: Yup.object().shape({
          phoneNumber: Yup.string().required(t(formText.mandatory)),
        }),
      })
      .when('type', {
        is: PaymentMethodType.TRANSFER,
        then: Yup.object().shape({
          alias: Yup.string(),
          IBAN: Yup.string()
            .ibanFormat(t(messages.format))
            .required(t(formText.mandatory)),
          swiftCode: Yup.string(),
        }),
      })
      .when('type', {
        is: PaymentMethodType.OTHER,
        then: Yup.object().shape({
          description: Yup.string().max(110).required(t(formText.mandatory)),
        }),
      })
      .when('type', {
        is: PaymentMethodType.DIRECT_DEBIT,
        then: Yup.object().shape({
          IBAN: Yup.string().ibanFormat(t(messages.format)),
        }),
      }),
  });
}

export default getValidationSchema;
