/* stylelint-disable */
import theme from 'modules/Theme';
import colorOpacity from 'modules/Theme/mixins/colorOpacity';
import { css } from 'modules/Theme/styled-components';

interface ShadowHoverProps {
  color?: string;
  opacity?: number;
  offsetBlur?: string;
  shadowPosition?: 'before' | 'after';
}

const shadowHover = (props?: ShadowHoverProps) => {
  const {
    offsetBlur = '0 4px 8px 0',
    color = theme.colors.primary300,
    opacity = 20,
    shadowPosition = 'after',
  } = props || {};
  return css`
    &:${shadowPosition} {
      box-shadow: ${offsetBlur} ${colorOpacity(`${color}`, opacity)};
      content: '';
      display: block;
      height: 100%;
      left: 0;
      opacity: 0;
      position: absolute;
      top: 0;
      transition: opacity 0.3s ease-in-out;
      width: 100%;
    }

    @media (hover: hover) {
      &:hover {
        cursor: pointer;
        &:${shadowPosition} {
          opacity: 1;
        }
      }
    }

    &:focus {
      outline: none;
      &:${shadowPosition} {
        opacity: 1;
      }
    }

    &:focus-within {
      &:${shadowPosition} {
        opacity: 1;
      }
    }
  `;
};

export default shadowHover;
