import { FC, ReactNode } from 'react';

import useTheme from 'modules/Theme/hooks/useTheme';

import Box from '../Box';
import { Button } from '../Form';
import Link from '../Link';

interface ActionMobileProps {
  id: string;
  isOpen?: boolean;
  onClick?: () => void;
  text: string;
  to?: string;
  variant?: 'secondary' | 'light' | 'stroke';
  isOnlyLink?: boolean;
  children?: ReactNode;
}

export const ActionMobile: FC<ActionMobileProps> = ({
  id,
  isOpen,
  onClick,
  text,
  to,
  variant = 'secondary',
  isOnlyLink,
  children,
}) => {
  const theme = useTheme();
  return (
    <Box
      aria-hidden={!!isOpen}
      backgroundColor="gray0"
      borderTop={`1px solid ${theme.colors.gray200}`}
      bottom="0"
      padding="12px 16px"
      position="sticky"
    >
      {children}
      {!!onClick && !isOnlyLink && (
        <Button
          aria-controls="actions-mobile-controls"
          aria-label={`${text} abrir acciones principales`}
          data-testid={id}
          id="actions-mobile"
          onClick={onClick}
          variant={variant}
          width="100%"
        >
          {text}
        </Button>
      )}
      {to && isOnlyLink && (
        <Link variant={variant} id="actions-mobile" to={to} width="100%">
          {text}
        </Link>
      )}
    </Box>
  );
};
