import { FC } from 'react';

import { isNil } from 'lodash';

import DocumentList from 'modules/Documents/List/components/DocumentList';
import ExpensesList from 'modules/Documents/List/components/ExpensesList/ExpensesList';
import FilterFieldButton from 'modules/Documents/List/components/Filter/FilterFieldButton';
import PanelList from 'modules/Documents/List/components/PanelList';
import useExpenseListConfig from 'modules/Documents/List/hooks/expenses/useExpenseListConfig';
import { CustomExpensesBulkActionsProps } from 'modules/Documents/List/hooks/expenses/useExpensesBulkActions';
import { CustomDocumentBulkActionsProps } from 'modules/Documents/List/hooks/useDocumentBulkActions';
import useDocumentListConfig from 'modules/Documents/List/hooks/useDocumentListConfig';
import { filterInput } from 'modules/Documents/List/messages';
import {
  DocumentKindQueryParamsState,
  ExpensesQueryParamsState,
  isDocumentQueryParamsState,
  isExpenseQueryParamsState,
} from 'modules/Documents/List/types';
import {
  DocumentFilters,
  DocumentKind,
  DocumentTable,
} from 'modules/Documents/models/document';
import useTranslations from 'modules/I18n/hooks/useTranslations';
import RemittancesHeader from 'modules/Remittances/CreateRemittanceAddDocuments/components/RemittancesHeader/RemittancesHeader';
import { Remittance } from 'modules/Remittances/types';
import { Box } from 'modules/Ui';
import {
  DataTableAction,
  DataTableColumn,
} from 'modules/Ui/DataTable/DataTable.models';

import { tablesColumns } from '../messages';
import { RemittanceDocumentsZeroState } from './RemittanceDocumentsZeroState';
import { RemittanceExpensesZeroState } from './RemittanceExpensesZeroState';

interface RemittanceAddDocumentsListProps {
  amount?: number;
  closeFilters: () => void;
  customBulkActions: (
    props: CustomDocumentBulkActionsProps | CustomExpensesBulkActionsProps
  ) => DataTableAction<DocumentTable>[];
  customHandleSelectionChange?: (newSelected: DocumentTable[]) => void;
  extraQuery?: string;
  filtersActive: boolean;
  filtersDisabled: boolean;
  isDirectDebit: boolean;
  isCreditTransfer: boolean;
  onClickFilterButton: () => void;
  queryParamsState: DocumentKindQueryParamsState | ExpensesQueryParamsState;
  remittance: Remittance;
  setSearchTerm: (searchTerm: string) => void;
  setFiltersDisabled: (disabled: boolean) => void;
  updateQueryParams: (event: DocumentFilters) => void;
  searchTerm: string;
}

export const RemittanceAddDocumentsList: FC<
  RemittanceAddDocumentsListProps
> = ({
  amount,
  closeFilters,
  customBulkActions,
  customHandleSelectionChange,
  isCreditTransfer,
  isDirectDebit,
  extraQuery,
  filtersActive,
  filtersDisabled,
  onClickFilterButton,
  remittance,
  searchTerm,
  setSearchTerm,
  setFiltersDisabled,
  updateQueryParams,
  queryParamsState,
}) => {
  const { t, formatCurrency } = useTranslations();

  const { columns } = useDocumentListConfig({
    documentKind: DocumentKind.NORMAL,
    isRemittanceList: true,
  });
  const { columns: expensesColumns } = useExpenseListConfig();

  const columnsForRemittance: DataTableColumn<
    DocumentTable,
    keyof DocumentTable
  >[] = columns.map(
    (column: DataTableColumn<DocumentTable, keyof DocumentTable>) => {
      if ((column.field as string) !== 'totals.total') {
        return column;
      }
      return {
        ...column,
        width: { sm: '120px' },
        value: ({ totals, totalAmountFromPayments, currency }) =>
          formatCurrency(totals.total - totalAmountFromPayments, currency),
        name: t(tablesColumns.totalToBePaid),
      };
    }
  );

  const expensesColumnsForRemittance: DataTableColumn<
    DocumentTable,
    keyof DocumentTable
  >[] = expensesColumns.map(
    (column: DataTableColumn<DocumentTable, keyof DocumentTable>) => {
      if ((column.field as string) !== 'totals.total') {
        return column;
      }
      return {
        ...column,
        width: { sm: '120px', lg: '120px' },
        value: ({ totals, totalAmountFromPayments, currency }) =>
          formatCurrency(totals.total - totalAmountFromPayments, currency),
        name: t(tablesColumns.totalToPay),
      };
    }
  );

  const { preSelectedDocuments } = remittance;

  return (
    <PanelList>
      <Box
        padding={{
          _: '18px 16px 20px',
          sm: '24px 24px 16px',
          lg: '24px 32px 16px',
        }}
      >
        {!isNil(amount) && (
          <RemittancesHeader remittance={remittance} amount={amount} />
        )}

        <FilterFieldButton
          gridTemplateColumns="456px 100px"
          justifyContent="space-between"
          columnGap="16px"
          fieldPlaceholder={t(filterInput.label)}
          fieldPlaceholderMobile={t(filterInput.placeholderMobile)}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          onClickFilterButton={onClickFilterButton}
          filtersDisabled={filtersDisabled}
          filtersActive={filtersActive}
          id="documentsFilters-button"
        />
      </Box>
      {isDirectDebit && isDocumentQueryParamsState(queryParamsState) && (
        <DocumentList
          allowSelectAll={false}
          filtersActive={filtersActive}
          closeFilters={closeFilters}
          setFiltersDisabled={setFiltersDisabled}
          updateQueryParams={updateQueryParams}
          queryParamsState={queryParamsState}
          searchTerm={searchTerm}
          customBulkActions={customBulkActions}
          customHandleSelectionChange={customHandleSelectionChange}
          extraQuery={extraQuery}
          excludeTestInvoices
          customOnSelect={() => {}}
          CustomZeroState={<RemittanceDocumentsZeroState />}
          customColumns={columnsForRemittance}
          preSelectedDocuments={preSelectedDocuments}
        />
      )}
      {isCreditTransfer && isExpenseQueryParamsState(queryParamsState) && (
        <ExpensesList
          allowSelectAll={false}
          filtersActive={filtersActive}
          closeFilters={closeFilters}
          setFiltersDisabled={setFiltersDisabled}
          updateQueryParams={updateQueryParams}
          queryParamsState={queryParamsState}
          searchTerm={searchTerm}
          customBulkActions={customBulkActions}
          customHandleSelectionChange={customHandleSelectionChange}
          extraQuery={extraQuery}
          customOnSelect={() => {}}
          CustomZeroState={<RemittanceExpensesZeroState />}
          customColumns={expensesColumnsForRemittance}
          preSelectedDocuments={preSelectedDocuments}
        />
      )}
    </PanelList>
  );
};
