import { FC } from 'react';
import { useSelector } from 'react-redux';

import { selectCurrentBusiness } from 'modules/Business/Current/selectors';
import useTranslations from 'modules/I18n/hooks/useTranslations';
import { getProductImages } from 'modules/Products/helpers/getProductImages';
import { Product } from 'modules/Products/models';

import Box from '../Box';
import { IconCamera } from '../Icons';
import Img from '../Img';
import messages from './messages';

interface Props {
  iconSize?: number;
  imageHeight?: string;
  product: Product;
  size: 'small' | 'medium';
  fetchPriority?: 'high' | 'low' | 'auto';
  decoding?: 'sync' | 'async' | 'auto';
}

export const ProductImage: FC<Props> = ({
  iconSize,
  imageHeight = '100%',
  fetchPriority,
  product,
  size,
  decoding,
}) => {
  const business = useSelector(selectCurrentBusiness);
  const images = getProductImages(product.id, business.id);
  const imageUrl = images[size];
  const handleOnError = (e: { currentTarget: { src: string } }) => {
    if (!e.currentTarget.src.includes('image-error')) {
      e.currentTarget.src = '/assets/image-error.png';
    }
  };
  const { t } = useTranslations();
  return (
    <>
      {product.hasImage ? (
        <Box
          backgroundColor="auxiliary100"
          borderRadius="4px"
          display="block"
          height={imageHeight}
          overflow="hidden"
          tag="span"
        >
          <Img
            ariaHidden={false}
            alt={`${t(messages.image)} ${product.name}`}
            height="100%"
            objectFit="cover"
            src={`${imageUrl}&version=${product.imageVersion}`}
            width="100%"
            onError={handleOnError}
            fetchPriority={fetchPriority}
            decoding={decoding}
          />
        </Box>
      ) : (
        <Box
          aria-label={t(messages.noImage)}
          backgroundColor="auxiliary100"
          borderRadius="4px"
          display="flex"
          height={imageHeight}
          justifyContent="center"
          placeItems="center"
          tag="span"
        >
          <IconCamera size={iconSize} color="primary300" />
        </Box>
      )}
    </>
  );
};
