import { Hidden } from 'react-grid-system';

import { Plan } from 'modules/Subscriptions/models';
import { ChangePlanPreviewResponse } from 'modules/Subscriptions/services/changePlan/changePlanPreview';
import useTheme from 'modules/Theme/hooks/useTheme';
import { Box, Text } from 'modules/Ui';

import { Coupon } from '../types';
import PaymentResumeInfo from './PaymentResumeInfo';
import PaymentResumeList from './PaymentResumeList';

interface Props {
  coupon?: Coupon;
  currentStep: number;
  data?: ChangePlanPreviewResponse;
  isReactivate?: boolean;
  marketName: string;
  periodicity: string;
  price: number;
  setCoupon?: (result?: Coupon) => void;
  title: string;
  priceWithTax: number;
  taxLabelReversed: string;
  tax: number;
  totalToPay: number;
  balance?: number;
  basePrice?: number;
  subtotal?: number;
  total?: number;
  selectedPlan?: Plan;
}

const ExpandedPaymentResume: React.FC<Props> = ({
  coupon,
  currentStep,
  data,
  isReactivate = false,
  marketName,
  periodicity,
  price,
  setCoupon,
  title,
  priceWithTax,
  taxLabelReversed,
  tax,
  totalToPay,
  balance,
  subtotal,
  basePrice,
  total,
  selectedPlan,
}) => {
  const theme = useTheme();
  return (
    <>
      <Hidden xs>
        <Text marginBottom="16px" variant="subheading" tag="h3" width="100%">
          {title}
        </Text>
      </Hidden>
      <Box flex={{ sm: '1' }}>
        <Text
          borderBottom={`4px solid ${theme.colors.brand500}`}
          color="brand500"
          fontWeight="600"
          tag="h4"
          textSize="l"
        >
          {marketName}
        </Text>
        <PaymentResumeList
          {...{
            taxLabelReversed,
            data,
            price,
            tax,
            marketName,
            priceWithTax,
            periodicity,
            coupon,
            setCoupon,
            currentStep,
            totalToPay,
            balance,
            subtotal,
            total,
            selectedPlan,
            basePrice,
          }}
        />
      </Box>
      <PaymentResumeInfo {...{ periodicity, price, isReactivate, coupon }} />
    </>
  );
};

export default ExpandedPaymentResume;
