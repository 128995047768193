import { FC } from 'react';

import { space, SpaceProps, border, BorderProps } from 'styled-system';

import useTheme from 'modules/Theme/hooks/useTheme';
import styled from 'modules/Theme/styled-components';

import Box from '../Box';
import IconSearch from '../Icons/IconSearch';
import Text from '../Text';

interface ZeroResultsProps extends SpaceProps, BorderProps {
  subtitle?: string;
  title: string;
}

export const StyledWrapper = styled(Box)`
  border-top: 1px solid ${(props) => props.theme.colors.primary500};
  ${space}
  ${border}
`;

export const ZeroResults: FC<ZeroResultsProps> = ({
  title,
  subtitle,
  ...rest
}) => {
  const theme = useTheme();
  return (
    <StyledWrapper
      alignItems="center"
      boxSizing="border-box"
      display="flex"
      fadeIn
      flexDirection="column"
      flexWrap="wrap"
      {...rest}
    >
      <IconSearch size={31} color={theme.colors.gray600} />
      <Text
        color="primary500"
        fontSize={14}
        lineHeight={22}
        margin="8px 0"
        textAlign="center"
      >
        {title}
      </Text>
      <Text textSize="xs" textAlign="center">
        {subtitle}
      </Text>
    </StyledWrapper>
  );
};

StyledWrapper.defaultProps = {
  margin: { sm: '0 16px', md: '0 24px', lg: '0 32px' },
  paddingTop: { _: '40px', sm: '88px' },
};
