import { getConfig } from 'modules/App/config';
import { UserNotification } from 'modules/UserNotifications/models/models';
import request from 'utils/request';

const updateNotification = async (notification: {
  id: string;
  read: boolean;
  sent?: boolean;
  showPreview?: boolean;
}): Promise<void> => {
  const endpoint = (await getConfig()).apiUserNotificationsService;
  const { id, ...data } = notification;

  await request<{ data: { items: UserNotification[]; count: number } }>(
    `${endpoint}/${id}`,
    {
      method: 'PUT',
      data,
    }
  );
};

export default updateNotification;
