import { RefObject, useEffect, useRef } from 'react';

export default function useOuterClick(
  innerRef: RefObject<Element>,
  callback: (event: MouseEvent) => void
) {
  // This loaded avoid catch outer click that open a modal (click -> open -> catch outer -> close)
  const loaded = useRef(false);
  const callbackRef = useRef(callback);
  callbackRef.current = callback;

  useEffect(() => {
    function handleClick(e: MouseEvent) {
      if (
        loaded.current &&
        innerRef.current &&
        callbackRef.current &&
        !innerRef.current.contains(e.target as Node)
      ) {
        callbackRef.current(e);
      }
    }
    setTimeout(() => {
      loaded.current = true;
    });
    document.addEventListener('mousedown', handleClick);
    return () => document.removeEventListener('mousedown', handleClick);
  }, []); // no dependencies -> stable click listener
}
