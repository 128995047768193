/**
 * Takes list of ids and a boolean that indicates if the
 * user has inverted selection, returns the corresponding
 * tortilla query
 * @param ids current page
 * @param excludeIds items per page
 * @returns
 */
function buildBaseIdsQuery(ids: string[], excludeIds: boolean) {
  const operator = excludeIds ? '$nin' : '$in';

  return (ids.length ? ids : [''])
    .map((id, index) => `&query[id][${operator}][${index}]=${id}`)
    .join('&');
}
export default buildBaseIdsQuery;
