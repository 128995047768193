import { Contact } from 'modules/Contacts/models';

export function processContact(contact?: Contact | null) {
  if (!contact) return null;

  return {
    ...contact,
    address: {
      ...contact.address,
      ...(contact.address.postalCode && {
        postalCode: contact.address.postalCode.toString(),
      }),
    },
  };
}
