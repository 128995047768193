import { FC } from 'react';

import { useInvalidateAndUpdate } from 'modules/App/hooks/useInvalidateAndUpdate';
import useTranslations from 'modules/I18n/hooks/useTranslations';
import { DialogConfirm, useCloseModal } from 'modules/Modals';
import useNotifications from 'modules/Notifications/hooks/useNotifications';
import cancelUserInvitation, {
  invalidateQueries,
} from 'modules/UserInvitations/services/cancelUserInvitation';
import { useBlockingMutation } from 'utils/useBlockingMutation';

import messages from './messages';
import { CancelInvitationModalProps } from './types';

const CancelInvitationModal: FC<CancelInvitationModalProps> = ({
  ids,
  excludeIds,
  onConfirm,
}) => {
  const invalidate = useInvalidateAndUpdate();
  const { t } = useTranslations();
  const closeModal = useCloseModal();
  const notifications = useNotifications();
  const { mutate } = useBlockingMutation(cancelUserInvitation, {
    onSuccess: () => {
      notifications.success(messages.success.id);
      invalidate({ invalidateQueries });
      onConfirm();
    },
    onError: () => {
      notifications.error(messages.error.id);
    },
  });

  const handleConfirm = async () => {
    mutate({ ids, excludeIds });
    closeModal();
  };

  return (
    <DialogConfirm
      id="cancelInvitation"
      title={t(messages.title, { count: ids.length })}
      text={t(messages.text, { count: ids.length })}
      confirmText={t(messages.confirm, { count: ids.length })}
      onConfirm={handleConfirm}
    />
  );
};

export default CancelInvitationModal;
