import React from 'react';

import { GridProps } from 'styled-system';

import useTranslations from 'modules/I18n/hooks/useTranslations';
import { DayPickerFormikField } from 'modules/Ui/Formik';

import { filter } from '../../../../messages';

interface FormProps extends GridProps {
  id: string;
}

const DayPickerFilters: React.FC<FormProps> = ({ id }) => {
  const { t } = useTranslations();
  return (
    <>
      <DayPickerFormikField id={`${id}From`} label={t(filter.dateFromLegend)} />
      <DayPickerFormikField label={t(filter.dateToLegend)} id={`${id}To`} />
    </>
  );
};

export default DayPickerFilters;
