import IconByPath, { IconByPathProps } from './Icon/IconType/IconByPath';

const IconIssue = (props: IconByPathProps) => (
  <IconByPath
    {...props}
    fillRule="evenodd"
    path="m3 8 7-7h11v22H3V8Zm1.414 0L4 8.414V22h16V2h-9.586L10 2.414V8H4.414Zm1-1L9 3.414V7H5.414ZM18 9h-1V5.71L13.74 9l-.709-.704L16.296 5H13V4h5v5Z"
  />
);

export default IconIssue;
