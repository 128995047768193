import IconByPath, { IconByPathProps } from './Icon/IconType/IconByPath';

const IconBank = (props: IconByPathProps) => (
  <IconByPath
    {...props}
    clipRule="evenodd"
    fillRule="evenodd"
    path="M11.988 2 22 6.215V8.55h-2.002v10.012h-1.002V8.55h-2.002v10.012h-1.001V8.55h-2.002v10.012h-1.002V8.55h-2.002v10.012h-1V8.55H7.983v10.012H6.982V8.55H4.98v10.012H3.979V8.55H1.977V6.215L11.988 2Zm-9.01 4.88v.669h18.02V6.88l-9.01-3.794-9.01 3.794Zm0 12.683v1.001h18.02v-1.001H2.979Zm0-1.001c-.553 0-1.001.448-1.001 1.001v2.002H22v-2.002c0-.553-.448-1.001-1.001-1.001H2.978Zm9.01-12.014a.75.75 0 1 0 0-1.502.75.75 0 0 0 0 1.502Z"
  />
);

export default IconBank;
