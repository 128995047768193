import { useInvalidateAndUpdate } from 'modules/App/hooks/useInvalidateAndUpdate';
import useNotifications from 'modules/Notifications/hooks/useNotifications';
import { ResponseError } from 'types/response-error.type';
import downloadFile from 'utils/downloadFile';
import { getFilenameFromContentDisposition } from 'utils/getFilenameFromContentDisposition';
import requestErrorHandler from 'utils/requestErrorHandler';
import { useBlockingMutation } from 'utils/useBlockingMutation';

import { remittanceNotifications } from '../messages';
import downloadRemittancesZip, {
  invalidateQueries,
} from '../services/downloadRemittancesZip';

function useDownloadRemittanceZip() {
  const notifications = useNotifications();
  const invalidateAndUpdate = useInvalidateAndUpdate();
  const { mutate, mutateAsync } = useBlockingMutation(downloadRemittancesZip, {
    onSuccess: (response) => {
      downloadFile(
        response.data,
        getFilenameFromContentDisposition(response.headers, 'exportado.zip')
      );
      invalidateAndUpdate({ invalidateQueries, isAsync: true });
    },
    onError: (error: ResponseError) => {
      const { responseCode } = requestErrorHandler(error);

      return notifications.error(
        responseCode === 'remittance-limit-reached'
          ? remittanceNotifications.limitZipExceeded.id
          : remittanceNotifications.submitError.id
      );
    },
  });
  return { mutate, mutateAsync };
}

export default useDownloadRemittanceZip;
