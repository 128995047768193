import { defineMessages } from 'react-intl';

export default defineMessages({
  chooseAccountingAccount: {
    id: 'gofre.Payments.SearchAccountingAccountInput.choose-accounting-account',
  },
  noAccountingAccountCreated: {
    id: 'gofre.Payments.SearchAccountingAccountInput.no-account-created',
  },
  loadingAccountingAccounts: {
    id: 'gofre.Payments.SearchAccountingAccountInput.loading',
  },
  accountingAccountLabel: {
    id: 'gofre.Payments.SearchAccountingAccountInput.label',
  },
  accountingAccountAddLabel: {
    id: 'gofre.Payments.SearchAccountingAccountInput.add-label',
  },
});
