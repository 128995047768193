import { FC } from 'react';
import { Hidden, Visible } from 'react-grid-system';
import { useSelector } from 'react-redux';

import styled from 'styled-components';
import {
  layout,
  gridArea,
  gridAutoRows,
  gridTemplateAreas,
  gridTemplateColumns,
  gridTemplateRows,
  system,
  gridColumn,
  GridAreaProps,
  GridColumnProps,
  GridTemplateAreasProps,
  GridTemplateColumnsProps,
  GridTemplateRowsProps,
} from 'styled-system';

import { selectIsMobile, selectIsDesktop } from 'modules/App/store/selectors';
import { filterInput } from 'modules/Documents/List/messages';
import useTranslations from 'modules/I18n/hooks/useTranslations';
import { Box, Button, FilterField, OnlyIconButton } from 'modules/Ui';
import { dataTableMessages } from 'modules/Ui/DataTable/messages';
import { IconFilter } from 'modules/Ui/Icons';

export interface ListFiltersProp
  extends GridAreaProps,
    GridColumnProps,
    GridTemplateAreasProps,
    GridTemplateColumnsProps,
    GridTemplateRowsProps {
  fieldPlaceholder: string;
  fieldPlaceholderMobile?: string;
  filtersActive?: boolean;
  filtersDisabled?: boolean;
  id: string;
  onClickFilterButton: () => void;
  searchTerm: string;
  setSearchTerm: (newSearchTerm: string) => void;
  withTabs?: boolean;
  showText?: boolean;
  columnGap?: {} | string;
  justifyContent?: string;
}

const StyledWrapper = styled(Box)`
${gridArea}
${gridAutoRows}
${gridColumn}
${gridTemplateAreas}
${gridTemplateColumns}
${gridTemplateRows}
${layout}
${system({
  gap: {
    property: 'gap',
    transform: (value) => `${value}`,
  },
  rowGap: {
    property: 'rowGap',
    transform: (value) => `${value}`,
  },
  columnGap: {
    property: 'columnGap',
    transform: (value) => `${value}`,
  },
})}
`;

const FilterFieldButton: FC<ListFiltersProp> = ({
  fieldPlaceholder,
  fieldPlaceholderMobile,
  filtersActive,
  filtersDisabled,
  id,
  onClickFilterButton,
  searchTerm,
  setSearchTerm,
  withTabs = true,
  showText = true,
  ...rest
}) => {
  const { t } = useTranslations();
  const isMobile = useSelector(selectIsMobile);
  const isDesktop = useSelector(selectIsDesktop);
  const label = isDesktop ? t(filterInput.labelRef) : t(filterInput.label);
  return (
    <StyledWrapper {...rest}>
      {showText && (
        <FilterField
          disabled={filtersDisabled}
          id={id ? `${id}-field` : 'filter'}
          label={label}
          labelHidden
          noDescription
          placeholder={
            isMobile
              ? fieldPlaceholderMobile ?? fieldPlaceholder
              : fieldPlaceholder
          }
          setValue={setSearchTerm}
          value={searchTerm}
          maxLength={100}
        />
      )}
      <Visible xs sm>
        <Box display="flex" justifyContent="center">
          <OnlyIconButton
            aria-controls="filter-expandable"
            data-testid="filters"
            disabled={filtersDisabled}
            icon={<IconFilter />}
            id={id}
            onClick={onClickFilterButton}
            // we type ref as any so we can forward references https://github.com/DefinitelyTyped/DefinitelyTyped/issues/28884#issuecomment-435485972
            type="button"
            variant="dark"
          />
        </Box>
      </Visible>
      <Hidden xs sm>
        <Box display="flex" justifySelf="flex-end">
          <Button
            accessibleText={
              isMobile ? t(dataTableMessages.filtersButton) : undefined
            }
            active={filtersActive}
            aria-label={t(dataTableMessages.filtersButton)}
            aria-controls="filter-expandable"
            aria-expanded={filtersActive ? 'true' : 'false'}
            data-testid="filters"
            disabled={filtersDisabled}
            icon={<IconFilter />}
            id={id}
            justifySelf={!withTabs ? 'flex-end' : undefined}
            onClick={onClickFilterButton}
            variant="ghost"
          >
            <Hidden xs>{t(dataTableMessages.filtersButton)}</Hidden>
          </Button>
        </Box>
      </Hidden>
    </StyledWrapper>
  );
};

StyledWrapper.defaultProps = {
  alignItems: 'center',
  columnGap: '16px',
  display: 'grid',
  gridTemplateColumns: {
    _: '1fr 48px',
    sm: '1fr auto',
    md: '380px 1fr',
    lg: '456px 1fr',
  },
  width: '100%',
};

export default FilterFieldButton;
