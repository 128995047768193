import { useField } from 'formik';

import Toggle, { ToggleProps } from 'modules/Ui/Toggle/Toggle';

const ToggleField = (props: ToggleProps) => {
  const { id, name, children, ...rest } = props;
  const [field] = useField({ name: name || id });

  const { onChange, ...fieldRest } = field;

  const handleOnChange = (event: any) => {
    field.onChange(event);
    props.onChange && props.onChange(event);
  };

  return (
    <Toggle {...{ id, name, ...rest }} {...fieldRest} onChange={handleOnChange}>
      {children}
    </Toggle>
  );
};

export default ToggleField;
