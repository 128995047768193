import { FC } from 'react';

import styled, { css } from 'styled-components';

import useTheme from 'modules/Theme/hooks/useTheme';
import { accessibleHidden } from 'modules/Theme/mixins';
import { Box } from 'modules/Ui';
import { IconUpload } from 'modules/Ui/Icons';

interface Props {
  content: string | JSX.Element;
  innerPadding?: string;
  isActive?: boolean;
}

export const hoverEffect = css`
  background-color: ${(props) => props.theme.colors.auxiliary100A30};
  border: 2px solid ${(props) => props.theme.colors.brand500};
  color: ${(props) => props.theme.colors.brand500};
  outline: none;
`;

const StyledDropView = styled(Box)`
  &:focus-within {
    ${hoverEffect}
  }

  @media (hover: hover) {
    &:hover {
        cursor: pointer;
        ${hoverEffect}
    }
  }

  input {
    display: block !important;
    ${accessibleHidden}
  }
`;

const DropView: FC<Props> = ({ innerPadding = '32px', content, isActive }) => {
  const theme = useTheme();
  return (
    <StyledDropView
      alignItems="center"
      borderRadius="8px"
      backgroundColor={isActive ? theme.colors.primary200 : 'transparent'}
      border={
        isActive
          ? `2px solid ${theme.colors.brand500}`
          : `2px dashed ${theme.colors.primary200}`
      }
      boxSizing="border-box"
      color="primary300"
      display="flex"
      flexDirection="column"
      height="100%"
      justifyContent="center"
      padding={innerPadding}
      tag="label"
      width="100%"
    >
      <IconUpload color="inherit" />
      <Box fontSize={14} padding="0 32px" tag="span" textAlign="center">
        {content}
      </Box>
    </StyledDropView>
  );
};

export default DropView;
