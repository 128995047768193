import { useQuery } from 'react-query';

import { PAYMENTS_QUERY } from 'modules/App/queries/queries';
import useNotifications from 'modules/Notifications/hooks/useNotifications';
import { DataTableSortOrder } from 'modules/Ui/DataTable/DataTable.models';

import fetchPayments from '../services/fetchPayments';
import messages from './messages';

function useFetchPayments({ documentId }: { documentId?: string }) {
  const notifications = useNotifications();
  const { data, refetch, isLoading, isError } = useQuery(
    PAYMENTS_QUERY,
    () =>
      fetchPayments({
        filters: { documentId },
        sortBy: { field: 'createdAt', order: DataTableSortOrder.DESC },
      }),
    {
      enabled: !!documentId,
      onError: () => {
        notifications.error(messages.fetchingError.id);
      },
    }
  );
  return { data, refetch, isLoading, isError };
}

export default useFetchPayments;
