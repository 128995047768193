import React from 'react';
import { Route, Switch } from 'react-router-dom';

import AuthenticatedRoute from 'modules/Auth/components/AuthenticatedRoute';
import GuestRoute from 'modules/Auth/components/GuestRoute';
import { ROUTES as ROUTES_HELP } from 'modules/Help/routesMap';
import useSetScrollableBody from 'modules/Ui/hooks/useSetScrollableBody';
import LoadingIndicator from 'modules/Ui/LoadingIndicator';
import { ROUTES as USER_INVTATIONS_ROUTES } from 'modules/UserInvitations/routes';
import Logout from 'pages/auth/logout/Logout';
import { ROUTES } from 'pages/auth/routesMap';
import { ROUTES as ROUTES_BUSINESS } from 'pages/business/routesMap';
import { ROUTES as ROUTES_LEAD } from 'pages/leads/routesMap';
import { ROUTES as ROUTES_URL_ACTIONS } from 'pages/url-actions/routesMap';
import loadable from 'utils/loadable';

import AppRoutes from './AppRoutes';
import useScollToTopOnNavigate from './hooks/useScollToTopOnNavigate';

const RoutesMap: React.FC = () => {
  useScollToTopOnNavigate();
  useSetScrollableBody();

  return (
    <Switch>
      <GuestRoute
        path={[ROUTES.REGISTER, ROUTES.BOOKKEEPER]}
        component={loadable(() => import('pages/auth/register/Register'), {
          fallback: <LoadingIndicator />,
        })}
      />
      <GuestRoute
        path={ROUTES.LOGIN}
        component={loadable(() => import('pages/auth/login/Login'), {
          fallback: <LoadingIndicator />,
        })}
      />
      <GuestRoute
        path={ROUTES.RESET}
        component={loadable(() => import('pages/auth/reset/Reset'), {
          fallback: <LoadingIndicator />,
        })}
      />
      <GuestRoute
        path={ROUTES.RECOVER_PASSWORD}
        component={loadable(
          () => import('pages/auth/recover-password/recoverPassword'),
          {
            fallback: <LoadingIndicator />,
          }
        )}
      />
      <GuestRoute
        path={ROUTES.VODAFONE_PASSWORD}
        component={loadable(
          () => import('pages/auth/vodafone-password/vodafonePassword'),
          {
            fallback: <LoadingIndicator />,
          }
        )}
      />
      <GuestRoute
        path={ROUTES.API_PASSWORD}
        component={loadable(
          () => import('pages/auth/api-password/apiPassword'),
          {
            fallback: <LoadingIndicator />,
          }
        )}
      />
      <GuestRoute
        path={ROUTES.API_REGISTER}
        component={loadable(
          () => import('pages/auth/api-register/apiRegister'),
          {
            fallback: <LoadingIndicator />,
          }
        )}
      />
      <AuthenticatedRoute
        path={ROUTES.SHOPIFY_CALLBACK}
        component={loadable(
          () => import('pages/auth/shopify/ShopifyCallback'),
          {
            fallback: <LoadingIndicator />,
          }
        )}
      />
      <Route
        path={ROUTES.SHOPIFY}
        component={loadable(() => import('pages/auth/shopify/Shopify'), {
          fallback: <LoadingIndicator />,
        })}
      />
      <Route
        path={ROUTES.SHOPIFY_CALLBACK}
        component={loadable(
          () => import('pages/auth/shopify/ShopifyCallback'),
          {
            fallback: <LoadingIndicator />,
          }
        )}
      />
      <Route
        path={ROUTES.SHOPIFY}
        component={loadable(() => import('pages/auth/shopify/Shopify'), {
          fallback: <LoadingIndicator />,
        })}
      />
      <Route
        path={ROUTES_URL_ACTIONS.ACTIONS}
        component={loadable(() => import('pages/url-actions'), {
          fallback: <LoadingIndicator />,
        })}
      />
      <Route
        path={USER_INVTATIONS_ROUTES.USER_INVITATION_ACCEPT}
        component={loadable(
          () => import('pages/user-invitations/accept/AcceptInvitation'),
          {
            fallback: <LoadingIndicator />,
          }
        )}
      />
      <Route
        path={ROUTES_HELP.BAI_PATH}
        component={loadable(() => import('pages/help/HelpBai'), {
          fallback: <LoadingIndicator />,
        })}
      />
      <GuestRoute
        path={ROUTES_BUSINESS.REDEEM_INVITATION_REGISTER}
        component={loadable(
          () =>
            import('pages/business/redeem-invitation/RedeemInvitationRegister'),
          {
            fallback: <LoadingIndicator />,
          }
        )}
      />
      <GuestRoute
        path={ROUTES_LEAD.REGISTER}
        component={loadable(() => import('pages/leads/register/LeadRegister'), {
          fallback: <LoadingIndicator />,
        })}
      />
      <GuestRoute
        path={ROUTES_LEAD.REGISTER_BOOKKEEPER}
        component={loadable(
          () => import('pages/leads/register/LeadBookkeeperRegister'),
          {
            fallback: <LoadingIndicator />,
          }
        )}
      />
      <GuestRoute
        path={ROUTES_LEAD.REGISTER_BOOKKEEPER_QUESTION}
        component={loadable(
          () => import('pages/leads/register/LeadBookkeeperQuestion'),
          {
            fallback: <LoadingIndicator />,
          }
        )}
      />
      <GuestRoute
        path={ROUTES_LEAD.REGISTER_QUESTION}
        component={loadable(
          () => import('pages/leads/register/LeadRegisterQuestions'),
          {
            fallback: <LoadingIndicator />,
          }
        )}
      />
      <GuestRoute
        path={ROUTES_LEAD.REGISTER_QUESTION_LOOK_AROUND}
        component={loadable(
          () => import('pages/leads/register/LeadRegisterQuestionLookAround'),
          {
            fallback: <LoadingIndicator />,
          }
        )}
      />
      <GuestRoute
        path={ROUTES_LEAD.REGISTER_QUESTION_ACTIVITY}
        component={loadable(
          () => import('pages/leads/register/LeadRegisterQuestionActivity'),
          {
            fallback: <LoadingIndicator />,
          }
        )}
      />
      <Route path={ROUTES.LOGOUT} component={Logout} />
      <AuthenticatedRoute businessRequired={true} component={AppRoutes} />
    </Switch>
  );
};
export default RoutesMap;
