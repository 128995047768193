import styled, { keyframes } from 'modules/Theme/styled-components';

import Path from '../../Html/Path';

interface PathProps {
  animationDuration?: number;
}

const animationFade = keyframes`
    0% {
      stroke-opacity: 1;
    }
    96% {
      stroke-opacity: 1;
    }
    100% {
      stroke-opacity: 0;
    }
`;

const animationStep1 = keyframes`
  25% {
    stroke-dashoffset: 190;
  }
  55% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: 0;
  }
`;

// prettier-ignore
export const StyledPath = styled(Path)<PathProps>`
  animation: ${animationStep1}
  ${({ animationDuration }) => animationDuration && `${animationDuration}ms`} ease 0ms infinite, ${animationFade}
  ${({ animationDuration }) => animationDuration && `${animationDuration}ms`} linear 0ms infinite;
  stroke-dasharray: 189 191;
  stroke-dashoffset: 190;
`;

const FirstAnimation = (props: PathProps) => {
  const { animationDuration } = props;
  return (
    <>
      <StyledPath
        {...{ animationDuration }}
        d="M43,83 C65.09139,83 83,65.09139 83,43 C83,20.90861 65.09139,3 43,3 C20.90861,3 3,20.90861 3,43"
        transform="translate(43, 43) scale(-1, 1) rotate(267) translate(-43, -43)"
        shapeRendering="geometricPrecision"
      />
    </>
  );
};

export default FirstAnimation;
