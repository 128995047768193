import React from 'react';

import useHasCustomerSupport from 'modules/Help/hooks/useHasCustomerSupport';
import useTranslations from 'modules/I18n/hooks/useTranslations';
import { Box, Link } from 'modules/Ui';
import { IconMobile, IconEmail } from 'modules/Ui/Icons';

import Pill from './Components/Pill';
import { texts } from './messages';

const SupportContactInfo: React.FC = () => {
  const { t } = useTranslations();
  const { hasPhoneSupport, emailSupport } = useHasCustomerSupport();
  return (
    <>
      <Box
        display={hasPhoneSupport && { sm: 'flex' }}
        justifyContent={hasPhoneSupport && { sm: 'space-between' }}
        margin={
          hasPhoneSupport ? { _: '16px 0', sm: '16px auto 24px' } : '16px 0 0'
        }
        tag="ul"
      >
        {hasPhoneSupport && (
          <Pill
            title={t(texts.phonePlaceholder)}
            text={
              <Link
                color="inherit"
                fontSize="inherit"
                hoverUnderline
                isExternal
                lineHeight="inherit"
                to={`tel:${t(texts.phone)}`}
              >
                {t(texts.phone)}
              </Link>
            }
            icon={<IconMobile color="brand500" />}
          />
        )}
        <Pill
          icon={<IconEmail color="brand500" />}
          text={
            <Link
              color="inherit"
              fontSize="inherit"
              hoverUnderline
              isExternal
              lineHeight="inherit"
              target="blank"
              to={`mailto:${emailSupport}`}
            >
              {emailSupport}
            </Link>
          }
          title={t(texts.emailPlaceholder)}
          width={!hasPhoneSupport ? '100%' : undefined}
        />
      </Box>
    </>
  );
};

export default SupportContactInfo;
