import { useParams } from 'react-router-dom';

import useElectronicDocumentProvider from 'modules/Business/hooks/useElectronicDocumentProvider';
import useDocumentSeries from 'modules/Settings/hooks/useDocumentSeries';
import useDocumentSettings from 'modules/Settings/hooks/useDocumentSettings';
import { TargetDocument } from 'modules/Settings/models';

import { UploadTypes } from '../models/document';

interface Params {
  id: string;
  type: string;
}

function useUploadDocumentData(
  targetDocument: TargetDocument = TargetDocument.INVOICE
) {
  const { data: documentSettings } = useDocumentSettings();
  const { data: documentSeries } = useDocumentSeries(targetDocument);
  const series = documentSeries || [];
  const isFetching = !documentSettings || !documentSeries;

  const { id, type } = useParams<Params>();
  const isExpense = type === UploadTypes.EXPENSE;

  const { hasElectronicProvider } = useElectronicDocumentProvider();

  return { id, isExpense, isFetching, series, hasElectronicProvider };
}

export default useUploadDocumentData;
