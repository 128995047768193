export const PAYMENT_TYPE_CARD = 'card';
export const MSG_GENERIC_ERROR = 'genericError';
export const REQUIRES_PAYMENT_METHOD = 'requires_payment_method';
export const REQUIRES_ACTION = 'requires_action';
export const SUCCEEDED = 'succeeded';
export const ACTIVE = 'active';
export const ERROR_CODE_SUBSCRIPTIONS_EXIST = 'stripe-subscriptions-exist';
export const ERROR_CODE_PAYMENT_METHOD_FAILED =
  'stripe-attach-payment-method-failed';
export const ERROR_CODE_CREATE_CUSTOMER_FAILED =
  'stripe-create-customer-failed';
export const ERROR_FIND_COUPON_FAILED = 'stripe-find-coupon-failed';
export const ERROR_DESCRIPTOR_CREATE_CUSTOMER_FAILED =
  'Invalid value for eu_vat.';
export const OPERATION_NOT_ALLOWED = 'operation-not-allowed';
