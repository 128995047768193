import BaseFaqs from '../BaseFaqs';
import messages from './messages';

const NUMBER_OF_FAQS = 4;

const BookkeeperFaqs: React.FC = () => {
  return (
    <BaseFaqs
      {...{
        numberOfFaqs: NUMBER_OF_FAQS,
        messages,
        minHeight: { md: '423px' },
      }}
    />
  );
};
export default BookkeeperFaqs;
