import { ReactNode } from 'react';

import { color, ColorProps, layout, LayoutProps } from 'styled-system';

import { min } from 'modules/Theme/breakpoints';
import { circleHover, tooltipStyles } from 'modules/Theme/mixins';
import styled from 'modules/Theme/styled-components';

import { Box, Dot } from '..';
import Tooltip from '../Tooltip';

interface Props extends ColorProps, LayoutProps {
  dotColor?: string;
  dotSize?: number;
  id: string;
  backgroundColor?: string;
  tooltipContent: string | ReactNode;
  tooltipTextColor?: string;
}

const columnWidth = '36px';

const StyledDotWithTooltip = styled(Box)`
  ${color}
  ${layout}

  @media ${min(768)} {
    ${circleHover(`${columnWidth}`)}

  }
`;

const StyledTooltipContent = styled(Box)`
  ${tooltipStyles}

`;

const DotWithTooltip: React.FC<Props> = ({ backgroundColor, ...rest }) => (
  <Tooltip
    id={rest.id}
    marginLeft="0"
    noBullet
    place="bottom"
    tooltipBackgroundColor={backgroundColor}
    tooltipContentPadding="4px 10px 6px"
    tooltipTextColor={rest.tooltipTextColor}
    tooltipButton={
      <StyledDotWithTooltip
        alignItems="center"
        display="flex"
        height={{ sm: columnWidth }}
        justifyContent={{ _: 'flex-start', sm: 'center' }}
        tag="span"
        width={{ _: '12px', sm: columnWidth }}
      >
        <Dot
          {...rest}
          backgroundColor={rest.dotColor}
          display="inline-block"
          position="relative"
          size={rest.dotSize || '8px'}
        />
      </StyledDotWithTooltip>
    }
    tooltipContent={
      <StyledTooltipContent
        justifyContent={{ _: 'flex-start', sm: 'center' }}
        width={{ _: '12px', sm: '120px' }}
      >
        {rest.tooltipContent}
      </StyledTooltipContent>
    }
  />
);

export default DotWithTooltip;
