import { getConfig } from 'modules/App/config';
import buildBaseIdsQuery from 'modules/App/queries/buildBaseIdsQuery';
import { REMITTANCES_QUERY } from 'modules/App/queries/queries';
import { RemittanceSort } from 'modules/Payments';
import { buildTortillasSortQuery } from 'utils/query/buildTortillaBaseQuery';
import request from 'utils/request';

import { remitancesSort } from './fetchRemittances';

interface DownloadZipRemittances {
  ids: string[];
  excludeIds: boolean;
  sortBy?: RemittanceSort;
}

const downloadZipRemittances = async ({
  excludeIds,
  ids = [],
  sortBy,
}: DownloadZipRemittances) => {
  const endpoint = (await getConfig()).apiRemittancesService;

  let query = buildTortillasSortQuery(remitancesSort(sortBy));
  query += buildBaseIdsQuery(ids, excludeIds);

  const downloadRequest = async (responseType?: 'blob' | 'json') => {
    return request<Blob>(`${endpoint}/zip?${query}`, {
      method: 'GET',
      responseType,
      headers: {
        Accept:
          responseType === 'blob' ? 'application/zip' : 'application/json',
      },
    });
  };

  await downloadRequest();
  const response = await downloadRequest('blob');

  return response;
};

export const invalidateQueries = [REMITTANCES_QUERY];

export default downloadZipRemittances;
