import useTheme from 'modules/Theme/hooks/useTheme';

import IconByString, { IconByStringProps } from '../Icon/IconType/IconByString';

export interface IconHelpProps extends IconByStringProps {
  type?: 'light' | 'dark';
}

const IconMenuHelp = (props: IconHelpProps) => {
  const { active, type = 'dark', ...rest } = props;
  const theme = useTheme();
  const actveColor = (typeColor: string) => {
    if (typeColor === 'dark') {
      return `${theme.colors.brand500}`;
    }
    return `${theme.colors.gray0}`;
  };
  return (
    <IconByString {...{ active, type, ...rest }}>
      <path
        className="icon-hover-effect"
        d="M4 9C2.34315 9 1 10.3431 1 12V15C1 16.6569 2.34315 18 4 18H5V9H4Z"
        fill={active ? actveColor(type) : 'transparent'}
        shapeRendering="geometricPrecision"
      />
      <path
        className="icon-hover-effect"
        d="M20 9H19V18H20C21.6569 18 23 16.6569 23 15V12C23 10.3431 21.6569 9 20 9Z"
        fill={active ? actveColor(type) : 'transparent'}
      />
      <path
        className="icon-hover-effect"
        d="M11 20C10.4477 20 10 20.4477 10 21V22C10 22.5523 10.4477 23 11 23H13C13.5523 23 14 22.5523 14 22V21C14 20.4477 13.5523 20 13 20H11Z"
        fill={active ? actveColor(type) : 'transparent'}
      />
      <path
        d="M19 11V9C19 5.13401 15.866 2 12 2C8.13401 2 5 5.13401 5 9V11H4V9C4 4.58172 7.58172 1 12 1C16.4183 1 20 4.58172 20 9V11H19Z"
        fill="currentColor"
      />
      <path
        d="M9.5 22.5C6.46243 22.5 4 20.0376 4 17H5C5 19.4853 7.01472 21.5 9.5 21.5H10.5V22.5H9.5Z"
        fill="currentColor"
      />
      <path
        d="M13 21H11V22H13V21ZM11 20C10.4477 20 10 20.4477 10 21V22C10 22.5523 10.4477 23 11 23H13C13.5523 23 14 22.5523 14 22V21C14 20.4477 13.5523 20 13 20H11Z"
        fillRule="evenodd"
        fill="currentColor"
      />
      <path
        d="M20 10V17C21.1046 17 22 16.1046 22 15V12C22 10.8954 21.1046 10 20 10ZM20 9H19V18H20C21.6569 18 23 16.6569 23 15V12C23 10.3431 21.6569 9 20 9Z"
        fill="currentColor"
        fillRule="evenodd"
      />
      <path
        d="M4 10V17C2.89543 17 2 16.1046 2 15V12C2 10.8954 2.89543 10 4 10ZM4 9H5V18H4C2.34315 18 1 16.6569 1 15V12C1 10.3431 2.34315 9 4 9Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </IconByString>
  );
};

export default IconMenuHelp;
