import { getConfig } from 'modules/App/config';
import Business from 'modules/Business/models/business';
import request from 'utils/request';

const fetchBusiness = async (): Promise<Business> => {
  const endpoint = (await getConfig()).apiBusinessesService;
  const response = await request<any>(`${endpoint}/current`, {
    method: 'GET',
  });
  return response.data.data;
};

export default fetchBusiness;
