import Types from 'modules/Auth/constants';
import { Actions as AuthActions } from 'modules/Auth/types';

import ActionTypes from './constants';
import {
  BubbleNotification,
  CommercialNotification,
  Notification,
  NotificationType,
} from './models';
import { Actions, State } from './types';

export const initialState: State = {
  notifications: [] as Notification[],
  bubbleNotifications: [] as BubbleNotification[],
  commercialNotifications: [] as CommercialNotification[],
};

const reducer = (
  state: State = initialState,
  action: Actions | AuthActions
): State => {
  switch (action.type) {
    case ActionTypes.ADD_NOTIFICATION:
      return {
        ...state,
        notifications: [
          ...state.notifications.filter(
            (notification) => notification.id !== action.payload.id
          ),
          action.payload,
        ],
      };
    case ActionTypes.ADD_BUBBLE_NOTIFICATION:
      return {
        ...state,
        bubbleNotifications: [
          ...state.bubbleNotifications.filter(
            (notification) => notification.id !== action.payload.id
          ),
          action.payload,
        ],
      };
    case ActionTypes.REMOVE_NOTIFICATION:
      return {
        ...state,
        notifications: state.notifications.filter(
          (notification) => notification.id !== action.payload
        ),
      };
    case ActionTypes.REMOVE_BUBBLE_NOTIFICATION:
      return {
        ...state,
        bubbleNotifications: state.bubbleNotifications.filter(
          (notification) => notification.id !== action.payload
        ),
      };
    case ActionTypes.CLEAR_NOTIFICATIONS:
      return {
        ...state,
        notifications: state.notifications.filter(
          (notification) =>
            notification.haunter || notification.type !== NotificationType.ERROR
        ),
      };
    case ActionTypes.CLEAR_BUBBLE_NOTIFICATIONS:
      return {
        ...state,
        bubbleNotifications: [],
      };

    case ActionTypes.ADD_COMMERCIAL_NOTIFICATION:
      return {
        ...state,
        commercialNotifications: [
          ...state.commercialNotifications.filter(
            (notification) => notification.id !== action.payload.id
          ),
          action.payload,
        ],
      };
    case ActionTypes.REMOVE_COMMERCIAL_NOTIFICATION:
      return {
        ...state,
        commercialNotifications: state.commercialNotifications.filter(
          (notification) => notification.id !== action.payload
        ),
      };
    case Types.LOGOUT:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
