import { TargetDocument } from 'modules/Settings/models';

export enum DefaultValuesResource {
  Invoice = 'invoice-business',
  Expense = 'expense-business',
  Quote = 'quote-business',
  Receipt = 'receipt-business',
  Modal = 'modal',
  Proforma = 'proforma-business',
  DeliveryNote = 'delivery-note-business',
  Product = 'product-business',
}

export type DefaultDocumentValuesResource = Exclude<
  DefaultValuesResource,
  DefaultValuesResource.Product
>;

export type DefaultProductValuesResource = Extract<
  DefaultValuesResource,
  DefaultValuesResource.Product
>;

export const targetDocumentToResource = (
  target?: TargetDocument
): DefaultDocumentValuesResource => {
  switch (target) {
    case TargetDocument.EXPENSE:
      return DefaultValuesResource.Expense;
    case TargetDocument.QUOTE:
      return DefaultValuesResource.Quote;
    case TargetDocument.PROFORMA:
      return DefaultValuesResource.Proforma;
    case TargetDocument.DELIVERY_NOTE:
      return DefaultValuesResource.DeliveryNote;
    default:
      return DefaultValuesResource.Invoice;
  }
};
