import { MessageDescriptor } from 'react-intl';

import {
  DefaultDocumentValuesResource,
  DefaultValuesResource,
} from 'modules/DefaultValues/enums';
import { DocumentType } from 'modules/Documents/models/document';
import { TranslationFunction } from 'modules/I18n/hooks/useTranslations';
import { DocumentSerie } from 'modules/Settings/models';
import { ROUTES } from 'pages/documents/routesMap';
import { ROUTES as QUOTES_ROUTES } from 'pages/quotes/routesMap';

import { SuggestionMap } from '../hooks/useSuggestionsData';

const { DOCUMENTS } = ROUTES;
const {
  MAIN_PATH: QUOTES,
  PROFORMAS_PATH: PROFORMA,
  DELIVERY_NOTE_PATH: DELIVERY_NOTE,
} = QUOTES_ROUTES;

export function geFullInvoiceNumber(code: number, serie?: DocumentSerie) {
  return `${serie?.serialCode}${code}`;
}
interface ProcessMailMessageParams {
  t: TranslationFunction;
  message: MessageDescriptor;
  mergedParams: any;
  replaceNewLine?: boolean;
}

export function processMailMessage({
  t,
  message,
  mergedParams,
  replaceNewLine = false,
}: ProcessMailMessageParams) {
  const translation = t(message, mergedParams);
  return replaceNewLine ? translation.replaceAll('\\n', '\n') : translation;
}

export function replaceAllSuggestions(text: string) {
  return text.replace(/##\w+##{([^}#]+)}/g, '$1');
}

export function processMailDefaultValue(defaultValue?: string) {
  let processedValue = defaultValue ?? '';
  if (defaultValue) {
    Object.keys(SuggestionMap).forEach((key) => {
      const regex = new RegExp(`##${key}##{[^}]*}`, 'g');
      processedValue = processedValue.replace(regex, `{${SuggestionMap[key]}}`);
    });
  }
  return processedValue;
}

export function processEmailContent(content?: string) {
  return replaceAllSuggestions(
    `<span>${content?.replaceAll('\n', '<br>')}</span>`
  );
}

export function processHtmlEmailContent(content?: string) {
  return `${content
    ?.replaceAll('<br>', '\n')
    .replaceAll('<span>', '')
    .replaceAll('</span>', '')}`;
}

export function getDefaultValueResource(
  type: DocumentType
): DefaultDocumentValuesResource {
  if (type === DocumentType.QUOTE) {
    return DefaultValuesResource.Quote;
  }
  if (type === DocumentType.PROFORMA) {
    return DefaultValuesResource.Proforma;
  }
  if (type === DocumentType.DELIVERY_NOTE) {
    return DefaultValuesResource.DeliveryNote;
  }
  return DefaultValuesResource.Invoice;
}

export function getReturnPath(type: DocumentType): string {
  if (type === DocumentType.QUOTE) {
    return QUOTES;
  }
  if (type === DocumentType.PROFORMA) {
    return PROFORMA;
  }
  if (type === DocumentType.DELIVERY_NOTE) {
    return DELIVERY_NOTE;
  }
  return DOCUMENTS;
}
