import { getConfig } from 'modules/App/config';
import { DataTableSortOrder } from 'modules/Ui/DataTable/DataTable.models';
import { buildTortillaBaseQuery } from 'utils/query/buildTortillaBaseQuery';
import request from 'utils/request';

import { AccountingAccount } from '../models';

interface Response {
  items: AccountingAccount[];
  count: number;
}

export default async function fetchAccountingAccounts(): Promise<Response> {
  const endpoint = (await getConfig()).apiAccountingAccountsService;

  const query = buildTortillaBaseQuery({
    page: 0,
    pageSize: 50,
    sort: [
      {
        field: 'alias',
        order: DataTableSortOrder.ASC,
      },
    ],
  });

  const response = await request<{
    data: Response;
  }>(`${endpoint}?${query}`, {
    method: 'GET',
  });
  return response?.data?.data;
}
