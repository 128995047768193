import { FC } from 'react';

import { UserPermissions } from 'modules/Auth/constants';
import useTranslations from 'modules/I18n/hooks/useTranslations';
import { Dialog } from 'modules/Modals';
import { ScopeTypes } from 'modules/Subscriptions/services/changePlan/changePlan';
import { Button, Text } from 'modules/Ui';

import messages from '../messages';

export interface Props {
  onClose(): void;
  onConfirm(): void;
  warningScopes?: ScopeTypes[];
}

function warningMessageKeys(warningScopes?: ScopeTypes[]): {
  subtitle?: { id: string };
  text: { id: string };
} {
  const remittanceCreationWarning =
    warningScopes?.length === 1 &&
    warningScopes.includes(UserPermissions.REMITTANCES_CREATE);
  if (remittanceCreationWarning) {
    return {
      subtitle: messages.remittanceCreationWarningSubtitle,
      text: messages.remittanceCreationWarningText,
    };
  }
  return {
    text: messages.genericScopeWarningText,
  };
}
const CreatePlanErrorDialog: FC<Props> = ({
  warningScopes,
  onConfirm,
  onClose,
}) => {
  const { t } = useTranslations();
  const warningMessages = warningMessageKeys(warningScopes);
  const subtitle = warningMessages.subtitle
    ? t(warningMessages.subtitle)
    : undefined;
  const text = t(warningMessages.text);

  return (
    <Dialog
      id="CreatePlanWarningModal"
      onClose={onClose}
      title={t(messages.title)}
      subtitle={subtitle}
      buttonPrimary={
        <Button
          data-testid="CreatePlanWarningModal-submit"
          marginBottom={{ _: '16px', sm: '0' }}
          onClick={onClose}
          variant="secondary"
        >
          {t(messages.cancel)}
        </Button>
      }
      buttonSecondary={
        <Button
          data-testid="CreatePlanWarningModal-cancel"
          onClick={() => {
            onConfirm();
            onClose();
          }}
          variant="stroke"
        >
          {t(messages.continue)}
        </Button>
      }
    >
      <Text lineHeight={22}>{text}</Text>
    </Dialog>
  );
};

export default CreatePlanErrorDialog;
