import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: { id: 'gofre.Payments.create.title' },
  titleFromList: { id: 'gofre.Payments.create.fromList.title' },
  confirmText: { id: 'gofre.Payments.create.confirmText' },
  cancel: { id: 'gofre.Modals.cancel' },
});

export default messages;
