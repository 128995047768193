import { Formik, validateYupSchema, yupToFormErrors } from 'formik';

import Business from 'modules/Business/models/business';
import { VatNumberType } from 'modules/Contacts/models';
import { Document } from 'modules/Documents/models/document';
import useTranslations from 'modules/I18n/hooks/useTranslations';
import Warning from 'modules/Ui/Warning/Warning';
import { nifUtils } from 'utils/nif-validations';

import { CreateFacturaeMethodParams } from '../../models/createFacturaeMethod';
import { DataNeededFacturae } from '../../models/dataNeededFacturae';
import {
  dataElectronicItems,
  getSubForms,
  getDataNeededModel,
  checkEmptyFieldsAddress,
} from './dataElectronicItems';
import getValidationSchema from './getValidationSchema';
import messages from './messages';

interface Props {
  document: Document;
  business: Business;
  onSubmit(values: CreateFacturaeMethodParams): void;
  handleClose: () => void;
}

const DataElectronicForm: React.FC<Props> = ({
  document,
  business,
  onSubmit,
  handleClose,
}) => {
  const { t } = useTranslations();
  const validationSchema = getValidationSchema(t);

  const getDataNeeded: DataNeededFacturae = getDataNeededModel(
    document,
    business
  );

  let validNifClient = false;
  let validAddressClient = false;
  if (getDataNeeded.contact) {
    const isVatNumberValidable =
      getDataNeeded.contact.vatNumberType === VatNumberType.NIF ||
      getDataNeeded.contact.vatNumberType === VatNumberType.NIF_IVA ||
      getDataNeeded.contact.vatNumberType === undefined;

    if (isVatNumberValidable) {
      validNifClient = nifUtils.validateVatNumber(
        getDataNeeded.contact.vatNumberType ?? VatNumberType.NIF,
        getDataNeeded.contact.vatNumber
      );
    } else if (getDataNeeded.contact.vatNumber !== '') {
      validNifClient = true;
    }

    validAddressClient = !checkEmptyFieldsAddress(
      getDataNeeded.contact.address
    );
  }

  let validNif = false;
  let validAddressEmit = false;
  if (getDataNeeded.business) {
    validNif = nifUtils.validateNif(getDataNeeded.business.vatNumber);
    validAddressEmit = !checkEmptyFieldsAddress(getDataNeeded.business.address);
  }

  const subforms = getSubForms(dataElectronicItems, getDataNeeded);

  return (
    <Formik
      onSubmit={(values, actions) => {
        const paramsMethod = {
          idDocument: document.id,
          dataNeedFacturae: {
            ...values,
            dir3: {
              ...values.dir3,
              publicAdministration: values.dir3.publicAdministration === '1',
            },
          },
        };
        onSubmit(paramsMethod);
        actions.setSubmitting(false);
        handleClose();
      }}
      validateOnChange={false}
      validateOnBlur={false}
      validate={(values: any) => {
        try {
          validateYupSchema(values, validationSchema, true, {
            publicAdministration: values.dir3.publicAdministration,
            vatNumberType:
              values.contact && values.contact.vatNumberType
                ? values.contact.vatNumberType
                : '',
            checkBusiness: values.business !== undefined,
            checkContact: values.contact !== undefined,
          });
        } catch (err) {
          return yupToFormErrors(err);
        }

        return {};
      }}
      {...{ validationSchema, initialValues: getDataNeeded }}
    >
      {(formik) => {
        return (
          <form
            data-testid="download-electronic-method-modal-form"
            id="download-electronic-method-modal-form"
            noValidate
            onSubmit={formik.handleSubmit}
          >
            <>
              <Warning
                marginTop={{ sm: '16px' }}
                message={t(messages.subtitle)}
              />
              {subforms.map(
                (Subform, index) =>
                  Subform && (
                    <Subform
                      validNif={validNif}
                      validNifClient={validNifClient}
                      validAddressEmit={validAddressEmit}
                      validAddressClient={validAddressClient}
                      // eslint-disable-next-line react/no-array-index-key
                      key={`${index}-subform`}
                    />
                  )
              )}
            </>
          </form>
        );
      }}
    </Formik>
  );
};

export default DataElectronicForm;
