import {
  setFeatureFlagIdentifiers,
  getFeatureFlagIdentifiers as getFFIdentifiersStorage,
} from 'utils/storage';

export const storeFeatureFlagIdentifiers = (ffIdentifiers: {
  businessId: string;
  createdAt: number;
}) => {
  setFeatureFlagIdentifiers(JSON.stringify(ffIdentifiers));
};

export const getFeatureFlagIdentifiers = (): {
  businessId: string;
  createdAt: number;
} | null => {
  const ffIdentifiers = getFFIdentifiersStorage();
  if (!ffIdentifiers) return null;
  return JSON.parse(ffIdentifiers);
};
