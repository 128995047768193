import IconByPath, { IconByPathProps } from './Icon/IconType/IconByPath';

const IconExitDoor = (props: IconByPathProps) => (
  <IconByPath
    {...props}
    path="M22 2v20H8v-9.5H2v-1h6V2h14Zm-1 1H9v18h12V3Zm-7.243 4.757 3.536 3.536L18 12l-4.243 4.243-.707-.707 3.036-3.036H11v-1h5.086L13.05 8.464l.707-.707Z"
  />
);

export default IconExitDoor;
