import useNotifications from 'modules/Notifications/hooks/useNotifications';
import { Payment, PaymentFilters, PaymentSort } from 'modules/Payments/models';
import downloadExcelService from 'modules/Payments/services/downloadExcel';
import { useBlockingMutation } from 'utils/useBlockingMutation';

import { notificationsMessages } from '../../messages';

export const useDownloadPaymentExcelAction = ({
  filters,
  sortBy,
}: {
  filters?: PaymentFilters;
  sortBy?: PaymentSort;
}) => {
  const notifications = useNotifications();

  const { mutate: onDownloadExcel } = useBlockingMutation(
    downloadExcelService,
    {
      onError: () => {
        notifications.error(notificationsMessages.downloadExcelError.id);
      },
    }
  );

  return async function downloadExcel(
    selecteds: Payment[],
    inverted: boolean,
    resetSelection: () => void
  ) {
    await onDownloadExcel({
      ids: selecteds.map(({ id }) => id as string),
      excludeIds: inverted,
      filters,
      sortBy,
    });
    resetSelection();
  };
};
