export function addEqualsQuery(fieldName: string, value: string) {
  return addQuery(fieldName, value, '$eq');
}

export function addGteQuery(fieldName: string, value: number | string) {
  return addQuery(fieldName, value, '$gte');
}

export function addLteQuery(fieldName: string, value: number | string) {
  return addQuery(fieldName, value, '$lte');
}

function addQuery(fieldName: string, value: number | string, operator: string) {
  return `&query${fieldName
    .split('.')
    .map((field) => `[${field}]`)
    .join('')}[${operator}]=${value}`;
}

export default addQuery;
