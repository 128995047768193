import { isNil } from 'lodash';

import { roundNumber } from 'utils/MathOperations';

import { getTaxPercentages } from '../gofre-calcs';
import { TortillaConcept as Concept } from './types';

/*
  IF YOU CHANGE ANYTHING BELOW THIS COMMENT YOU NEED TO DO THE SAME CHANGE IN GOFRE / TORTILLA
  WE ROUND TO 3 DECIMAL PLACES SO WE DONT LOSE PRECISION ON THE SECOND DECIMAL PLACE
  THE OBJECTIVE OF IS THAT THE TOTALS MUST ADD PERFECTLY, SUBTOTAL + TAX = TOTAL
  WE ARE FINE LOOSING SOME TRAILING CENTS IN THE SUM OF THE CONCEPTS
 */

const calculateDiscount = (subtotal = 0, discountPercentage = 0) =>
  roundNumber(subtotal * (discountPercentage / 100), 2);

const calculateSubtotal = (quantity = 0, unitPrice = 0) =>
  roundNumber(quantity * unitPrice, 2);

const calculateTaxes = (taxBase = 0, taxPercentage = 0) => {
  return roundNumber(taxBase * (taxPercentage / 100), 2);
};

interface GetTaxArgs {
  taxBase: number;
  taxAmount: number;
  saleEqualizationAmount: number;
  concept: Concept;
}

const getTax = (args: GetTaxArgs) => {
  const { taxAmount, taxBase, saleEqualizationAmount, concept } = args;

  const { taxPercentage, salesEqTaxPercentage } = getTaxPercentages({
    ...concept,
    totalAmount: concept.totalAmount ?? undefined,
  });
  const salesEq = salesEqTaxPercentage ? { salesEqTaxPercentage } : {};
  return {
    taxKey: concept.taxKey,
    taxBase,
    taxAmount,
    taxPercentage,
    salesEqTax: saleEqualizationAmount,
    ...salesEq,
  };
};

export const calculateTotalFromUniqueConcept = (
  currentConcept: Concept,
  forcedUnitPrice?: number
) => {
  const { salesEqTaxPercentage, taxPercentage } = getTaxPercentages({
    ...currentConcept,
    totalAmount: currentConcept.totalAmount ?? undefined,
  });

  const quantity = currentConcept.quantity || 0;
  const discountPercentage = currentConcept.discountPercentage || 0;
  const unitPrice = (forcedUnitPrice ?? currentConcept.unitPrice) || 0;
  const currentSubtotal = calculateSubtotal(quantity, unitPrice);
  const currentDiscount = calculateDiscount(
    currentSubtotal,
    discountPercentage
  );
  const currentTaxBase = currentSubtotal - currentDiscount;
  const currentTaxAmount = isNil(currentConcept.fixedTaxAmountByClient)
    ? calculateTaxes(currentTaxBase, taxPercentage)
    : currentConcept.fixedTaxAmountByClient;

  const currentSalesEqTax =
    currentConcept.salesEqTaxPercentage > 0
      ? roundNumber(currentTaxBase * (salesEqTaxPercentage / 100))
      : 0;

  const currentTax = getTax({
    concept: currentConcept,
    saleEqualizationAmount: currentSalesEqTax,
    taxAmount: currentTaxAmount,
    taxBase: currentTaxBase,
  });

  return {
    currentSubtotal,
    currentDiscount,
    currentTaxBase,
    currentTaxAmount,
    taxPercentage,
    taxKey: currentConcept.taxKey,
    currentSalesEqTax,
    currentTax,
  };
};
