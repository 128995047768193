import { useHistory } from 'react-router-dom';

import { uniqBy } from 'lodash';

import {
  DocumentFilters,
  DocumentStatus,
  DocumentTable,
  DocumentType,
} from 'modules/Documents/models/document';
import { useOpenModal } from 'modules/Modals';
import { ModalTypes } from 'modules/Modals/constants';
import { normalizeRemittanceValues } from 'modules/Remittances/helpers/normalizeRemittanceValues';
import useCreateRemittance from 'modules/Remittances/hooks/useCreateRemittance';
import useDownloadRemittanceXML from 'modules/Remittances/hooks/useDownloadXMLRemittance';
import fetchRemittanceDocuments from 'modules/Remittances/services/fetchRemittanceDocuments';
import { Remittance, RemittanceDocumentDto } from 'modules/Remittances/types';
import { PAYMENT_ROUTES } from 'pages/payments/routesMap';

const checkIfDocumentsAreDuplicated = async (
  remittance: Remittance,
  selecteds: DocumentTable[]
): Promise<RemittanceDocumentDto[]> => {
  const response = await fetchRemittanceDocuments({
    remittanceId: remittance?.remittanceId,
    documentIds: selecteds.map((s) => s.id),
  });

  const uniqueDocuments: RemittanceDocumentDto[] = uniqBy(
    response.data.items,
    'documentIdentifier'
  );

  return uniqueDocuments;
};

export function useDocumentsDuplicatedChecker({
  remittance,
  isDirectDebit,
  extraQuery,
}: {
  remittance?: Remittance;
  isDirectDebit: boolean;
  extraQuery: string;
}) {
  const openModal = useOpenModal();
  const history = useHistory();

  const { mutate: downloadXMLRemittance } = useDownloadRemittanceXML({
    defaultFileName: 'remesa.xml',
  });

  const { mutate } = useCreateRemittance({
    callback: (createRemittanceResponse) => {
      downloadXMLRemittance(createRemittanceResponse.data.id);
      history.push({
        pathname: PAYMENT_ROUTES.REMITTANCES,
        state: remittance,
      });
    },
    showSuccessNotification: true,
  });

  return async ({
    selecteds,
    inverted,
    filters,
    status,
  }: {
    selecteds: DocumentTable[];
    inverted: boolean;
    filters?: DocumentFilters;
    status?: DocumentStatus;
  }): Promise<void> => {
    if (!remittance) {
      return;
    }
    const usedDocuments = await checkIfDocumentsAreDuplicated(
      remittance,
      selecteds
    );
    if (usedDocuments.length) {
      openModal({
        type: ModalTypes.DOCUMENTS_ALREADY_IN_REMITTANCE,
        remittanceDocuments: usedDocuments,
        onConfirm: () => {
          mutate({
            remittance: {
              ...normalizeRemittanceValues(remittance),
            },
            queryOptions: {
              selectedIds: selecteds.map((s) => s.id),
              excludeIds: Boolean(inverted),
              filters,
              status,
              documentType: isDirectDebit
                ? DocumentType.INVOICE
                : DocumentType.EXPENSE,
              extraQuery,
            },
          });
        },
      });
    } else {
      mutate({
        remittance: {
          ...normalizeRemittanceValues(remittance),
        },
        queryOptions: {
          selectedIds: selecteds.map((s) => s.id),
          excludeIds: Boolean(inverted),
          filters,
          status,
          documentType: isDirectDebit
            ? DocumentType.INVOICE
            : DocumentType.EXPENSE,
          extraQuery,
        },
      });
    }
  };
}
