import { FC } from 'react';

import useTranslations from 'modules/I18n/hooks/useTranslations';
import { Box, TagsFormikField } from 'modules/Ui';

import { messages } from './messages';

const CarbonCopyFields: FC = () => {
  const { t } = useTranslations();
  return (
    <Box
      fadeIn
      backgroundColor="auxiliary"
      margin={{ _: '0 -16px 24px', sm: '0  0 24px' }}
      padding={{ _: '24px 16px 4px', sm: '24px 24px 8px' }}
    >
      <TagsFormikField
        id="cc"
        label={t(messages.cc)}
        helpText={t(messages.helpText)}
      />
      <TagsFormikField marginTop="8px" id="bcc" label={t(messages.bcc)} />
    </Box>
  );
};

export default CarbonCopyFields;
