import {
  notificationsMessages,
  actions,
} from 'modules/Documents/List/messages';
import useTranslations from 'modules/I18n/hooks/useTranslations';
import { useOpenModal } from 'modules/Modals';
import { ModalTypes } from 'modules/Modals/constants';
import useNotifications from 'modules/Notifications/hooks/useNotifications';
import requestErrorHandler from 'utils/requestErrorHandler';

import fetchContact from '../Detail/services/fetchContact';
import messages from '../messages';
import { Contact } from '../models';
import {
  DeletedContactNotificationOpts,
  DeletedContactMiddlewareOpts,
} from './types';
import useResurrectContact from './useResurrectContact';

export const useDeletedContactMiddleware = (): {
  deletedContactMiddleware: (
    opts: DeletedContactMiddlewareOpts
  ) => Promise<void>;
} => {
  const { t } = useTranslations();
  const openModal = useOpenModal();
  const notifications = useNotifications();
  const { resurrectContact } = useResurrectContact();

  const showDeletedContactNotification = (
    opts: DeletedContactNotificationOpts
  ): void => {
    const { contactId, message, messageKey, nextStep } = opts;
    notifications.warning(message, {
      ttl: 5000,
      ctaTranslationKey: messages.create.id,
      messageKey,
      onCtaClick: () =>
        openModal({
          type: ModalTypes.CREATE_CONTACT,
          onSubmit: (contactFormValues: Contact) => {
            resurrectContact(
              {
                id: contactId,
                ...contactFormValues,
              },
              nextStep
            );
          },
          onAcceptText: t(messages.create),
        }),
    });
  };

  const deletedContactMiddleware = async (
    opts: DeletedContactMiddlewareOpts
  ): Promise<void> => {
    const { contactId, messageNotification, messageKey, nextStep } = opts;
    try {
      if (contactId) {
        await fetchContact(contactId);
      }
      nextStep?.();
    } catch (error) {
      const { responseStatus } = requestErrorHandler(error);
      if (responseStatus === 400) {
        showDeletedContactNotification({
          contactId,
          message: messageNotification ?? notificationsMessages.noContact.id,
          messageKey,
          nextStep: nextStep ?? undefined,
        });
      } else {
        notifications.error(actions.fetchingContactError.id);
      }
    }
  };

  return { deletedContactMiddleware };
};
