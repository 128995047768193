import { defineMessages } from 'react-intl';

export default defineMessages({
  expensesTitle: { id: 'gofre.DownloadInvoicesModal.title' },
  title: { id: 'gofre.DownloadInvoicesModal.title' },
  finishedSubtitle: { id: 'gofre.DownloadInvoicesModal.finished.subtitle' },
  finishedText: { id: 'gofre.DownloadInvoicesModal.finished.text' },
  finishedLink: { id: 'gofre.DownloadInvoicesModal.finished.link' },
  notFinishedSubtitle: {
    id: 'gofre.DownloadInvoicesModal.notFinished.subtitle',
  },
  notFinishedText: { id: 'gofre.DownloadInvoicesModal.notFinished.text' },
  errorText: { id: 'gofre.DownloadInvoicesModal.error.text' },
  errorSubtitle: { id: 'gofre.DownloadInvoicesModal.error.subtitle' },
});
