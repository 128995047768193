import Bowser, { Parser } from 'bowser';

const browser = Bowser.parse(window.navigator.userAgent);
export const platformIsMobile =
  browser.platform.type === 'mobile' || browser.platform.type === 'tablet';

declare global {
  interface Window {
    browser: Parser.ParsedResult;
  }
}

window.browser = browser;
