import useTheme from 'modules/Theme/hooks/useTheme';

import IconByString, { IconByStringProps } from '../Icon/IconType/IconByString';

const IconMenuContactBookkeepper = (props: IconByStringProps) => {
  const { color: strokeColor, fillColor, active, ...rest } = props;
  const theme = useTheme();
  return (
    <IconByString {...{ active, ...rest }}>
      <path
        d="M19 23V1H3v22h16ZM4 2h14v20H4V2Z"
        fillRule="evenodd"
        fill="currentColor"
      />
      <path fill="currentColor" d="M21 3h1v20h-1z" />
      <path
        d="M3 19v4h16v-4l-1.172-1.172a9.657 9.657 0 0 0-13.656 0L3 19Zm8-3c2.296 0 4.498.912 6.121 2.535l.879.88V22H4v-2.586l.879-.879A8.657 8.657 0 0 1 11 16Z"
        fillRule="evenodd"
        fill="currentColor"
      />
      <path
        className="icon-hover-effect--blue"
        d="M14 8.625C14 7.175 12.657 6 11 6S8 7.175 8 8.625v1.75C8 11.825 9.343 13 11 13s3-1.175 3-2.625v-1.75Zm-5 .042C9 7.747 9.895 7 11 7s2 .746 2 1.667v1.666c0 .92-.895 1.667-2 1.667s-2-.746-2-1.667V8.667Z"
        fillRule="evenodd"
        fill={active ? theme.colors.brand500 : 'currentColor'}
      />
      <path
        d="M18 2H4v16l.172-.172a9.657 9.657 0 0 1 13.656 0L18 18V2ZM8 8.333C8 7.045 9.343 6 11 6s3 1.045 3 2.333v2.334C14 11.955 12.657 13 11 13s-3-1.045-3-2.333V8.333Z"
        fillRule="evenodd"
        fill={active ? theme.colors.gray0 : 'transparent'}
        className="icon-hover-effect"
      />
      <path
        className="icon-hover-effect--blue"
        d="m18 19.414-.879-.879a8.657 8.657 0 0 0-12.242 0l-.879.88V22h14v-2.586ZM9 8.667C9 7.747 9.895 7 11 7s2 .746 2 1.667v1.666c0 .92-.895 1.667-2 1.667s-2-.746-2-1.667V8.667Z"
        fillRule="evenodd"
        fill={active ? theme.colors.brand500 : 'transparent'}
      />
      <path
        className="icon-hover-effect--blue"
        d="m4 18 .172-.172a9.657 9.657 0 0 1 13.656 0L18 18v1.414l-.879-.879a8.657 8.657 0 0 0-12.242 0l-.879.88V18Z"
        fillRule="evenodd"
        fill={active ? theme.colors.brand500 : 'currentColor'}
      />
    </IconByString>
  );
};

export default IconMenuContactBookkeepper;
