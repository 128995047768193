import { useInfiniteQuery } from 'react-query';

import { BOOKKEEPER_CUSTOMERS_QUERY } from 'modules/App/queries/queries';
import { DataTableSortOrder } from 'modules/Ui/DataTable/DataTable.models';
import Limits from 'types/limits';

import { BookkeeperCustomerFilters } from '../components/types';
import fetchCustomers from '../services/fetchCustomers';

function useFetchCustomersInfinite(
  filters?: BookkeeperCustomerFilters,
  pageSize = Limits.EXTRA_SMALL
) {
  return useInfiniteQuery(
    [
      BOOKKEEPER_CUSTOMERS_QUERY,
      {
        filters,
      },
    ],
    ({ pageParam = 0 }) =>
      fetchCustomers({
        filters,
        page: pageParam,
        pageSize,
        sortBy: {
          field: 'vatNumber' as any,
          order: DataTableSortOrder.DESC,
        },
      }),
    {
      getNextPageParam: (lastPage, pages) => {
        const fetchedElementsCount = pages.reduce((acc, { items }) => {
          return acc + items.length;
        }, 0);
        const nextPage = pages.length;
        const isLastPage = fetchedElementsCount === lastPage.count;
        return !isLastPage ? nextPage : undefined;
      },
    }
  );
}

export default useFetchCustomersInfinite;
