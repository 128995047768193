import { Formik } from 'formik';

import useTranslations from 'modules/I18n/hooks/useTranslations';
import {
  CreatedPaymentMethod,
  PaymentMethod,
  PaymentMethodType,
} from 'modules/PaymentMethods/models/paymentMethods';
import { Box, Text } from 'modules/Ui';

import { getDefaultData } from './getInitialValues';
import getValidationSchema from './getValidationSchema';
import messages from './messages';
import { paymentMethodItems } from './paymentMethodItems';
import RadioButtonRow from './RadioButtonRow';

interface Props {
  onSubmit(values: PaymentMethod): void;
  defaultPhone?: string;
  paymentMethods: CreatedPaymentMethod[];
  forContact?: boolean;
}

const AddPaymentMethodForm: React.FC<Props> = ({
  paymentMethods,
  onSubmit,
  defaultPhone,
  forContact,
}) => {
  const { t } = useTranslations();

  const validationSchema = getValidationSchema(t);
  const initialValues: Partial<PaymentMethod> = {
    type: undefined,
    data: { phoneNumber: defaultPhone },
    futureDocuments: true,
  };

  const { hasBizum, hasCash, hasCashOnDelivery, hasDirect, hasOther } =
    paymentMethods.reduce(
      (current, entry) => {
        switch (entry.type) {
          case PaymentMethodType.BIZUM:
            return { ...current, hasBizum: false };
          case PaymentMethodType.CASH:
            return { ...current, hasCash: true };
          case PaymentMethodType.CASH_ON_DELIVERY:
            return { ...current, hasCashOnDelivery: true };
          case PaymentMethodType.DIRECT_DEBIT:
            return { ...current, hasDirect: true };
          case PaymentMethodType.OTHER:
            return { ...current, hasOther: false };
          default:
            return { ...current };
        }
      },
      {
        hasBizum: false,
        hasCash: false,
        hasCashOnDelivery: false,
        hasDirect: false,
        hasOther: false,
      }
    );

  return (
    <Formik
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={(values, actions) => {
        actions.setSubmitting(false);
        onSubmit(values as PaymentMethod);
      }}
      {...{ validationSchema, initialValues }}
    >
      {(formik) => {
        const {
          values: { type },
        } = formik;

        const onTypeChange = (value: PaymentMethodType) => {
          formik.setFieldValue('type', value);
          formik.setFieldValue('data', getDefaultData(value, defaultPhone));
        };
        return (
          <form
            data-testid="add-payment-method-modal-form"
            id="add-payment-method-modal-form"
            noValidate
            onSubmit={formik.handleSubmit}
          >
            <Box aria-labelledby="add-payment-fieldset" role="group">
              <Text id="add-payment-fieldset" marginBottom="14px">
                {t(messages.subtitle)}
              </Text>
              <RadioButtonRow
                {...{
                  hasBizum,
                  hasCash,
                  hasCashOnDelivery,
                  hasDirect,
                  hasOther,
                  items: paymentMethodItems.slice(0, 3),
                  selectedPaymentType: type,
                  onChange: onTypeChange,
                  forContact,
                }}
              />
              <RadioButtonRow
                {...{
                  hasBizum,
                  hasCash,
                  hasCashOnDelivery,
                  hasDirect,
                  hasOther,
                  items: paymentMethodItems.slice(3),
                  selectedPaymentType: type,
                  onChange: onTypeChange,
                }}
              />
            </Box>
          </form>
        );
      }}
    </Formik>
  );
};

export default AddPaymentMethodForm;
