import { createSelector } from 'reselect';

import { getCurrentBusinessId } from 'modules/Auth/currentBusinessUtil';
import { BusinessType } from 'modules/Business/models/business';
import { ApplicationRootState } from 'types/index';

import { Roles } from './models/role';

const getUser = (state: ApplicationRootState) => state.user.user;

export const selectUser = createSelector(
  getUser,
  getCurrentBusinessId,
  (
    {
      email,
      name,
      surname,
      userId,
      businesses: businessesResponse,
      language,
      role,
    },
    currentBusinessId
  ) => {
    const businesses = businessesResponse || [];

    return {
      businesses,
      email,
      name,
      userId,
      surname,
      language,
      role: (
        businesses.find(
          (business) => business.business.id === currentBusinessId
        ) || businesses[0]
      )?.role,
      ownsBusinesses:
        !!businesses && businesses.some((b) => b.role?.name === Roles.OWNER),
      isBookkeeper:
        (!!businesses &&
          businesses.some(
            (b) =>
              b.role?.name === Roles.BOOKKEEPER ||
              b.business.type === BusinessType.BOOKKEEPING
          )) ||
        role === Roles.BOOKKEEPER,
    };
  }
);

export const selectUserIsLoading = (state: ApplicationRootState) =>
  state.user.loading;
