import { AxiosRequestConfig } from 'axios';

const HTTP_METHOD_OVERRIDE_WITH_URL_SIZE_BIGGER_THAN = Number(
  process.env.HTTP_METHOD_OVERRIDE_WITH_URL_SIZE_BIGGER_THAN ?? '2048'
);

export const requestMethodOverride = (
  config: AxiosRequestConfig
): AxiosRequestConfig => {
  if (
    config.url &&
    config.url.length > HTTP_METHOD_OVERRIDE_WITH_URL_SIZE_BIGGER_THAN
  ) {
    const configMedhodOverride = { ...config };
    configMedhodOverride.headers = {
      ...config.headers,
      'X-HTTP-Method-Override': config.method,
    };
    configMedhodOverride.method = 'POST';
    const [urlWithoutQuery, query] = config.url.split('?');
    configMedhodOverride.url = urlWithoutQuery;
    configMedhodOverride.data = {
      query,
      ...config.data,
    };
    return configMedhodOverride;
  }
  return config;
};
