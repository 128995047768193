import { getConfig } from 'modules/App/config';
import { CONTACT_QUERY } from 'modules/App/queries/queries';
import { setAuthData } from 'modules/Auth/request-auth-data';
import { TokenResponse } from 'modules/Auth/token-response';
import request from 'utils/request';

import { User, UserChangePassword } from '../../models/user';

export const updatePassword = async ({
  password,
  oldPassword,
  token,
}: {
  password: string;
  oldPassword?: string;
  token?: string;
}): Promise<{}> => {
  const endpoint = (await getConfig()).apiAuthService;

  const response = await request<TokenResponse>(
    `${endpoint}/password`,
    {
      method: 'PUT',
      data: { password, oldPassword },
    },
    { token, requireAuth: false }
  );

  const { auth } = response.data.data;
  if (auth) {
    setAuthData({
      currentToken: auth.accessToken,
      expireOn: auth.expireIn + Date.now(),
      businessId: auth.businessId,
      userId: auth.userId,
      tenant: auth.tenant,
    });
  }

  return {
    ...response.data.data,
  };
};

export const updateUser = async ({ userId, ...data }: User): Promise<User> => {
  const endpoint = (await getConfig()).apiUsersService;

  const response = await request<any>(`${endpoint}/${userId}`, {
    method: 'PUT',
    data,
  });

  return {
    ...response.data.data,
  };
};

const updateMe = async (
  data: Partial<User> | UserChangePassword
): Promise<User> => updateUser({ ...data, userId: 'me' } as User);

export const invalidateQueries = [CONTACT_QUERY];

export default updateMe;
