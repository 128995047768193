import { FC } from 'react';

import useTranslations from 'modules/I18n/hooks/useTranslations';
import { useCloseModal, Dialog } from 'modules/Modals';
import { Button } from 'modules/Ui';

import DocumentTrackingFailedState from '../DocumentTrackingFailedState';
import { modal as messages } from '../messages';
import DocumentTrackingList from './DocumentTrackingList';
import { DocumentTrackingMobileModalProps } from './types';

const DocumentTrackingMobile: FC<DocumentTrackingMobileModalProps> = ({
  items,
}) => {
  const { t } = useTranslations();
  const handleCancel = useCloseModal();

  return (
    <Dialog
      {...{
        content: (
          <>
            {items.length > 0 ? (
              <DocumentTrackingList {...{ items }} />
            ) : (
              <DocumentTrackingFailedState />
            )}
          </>
        ),
        id: 'document-tracking',
        title: t(messages.title),
        onClose: handleCancel,
        buttonPrimary: (
          <Button
            data-testid="document-tracking-submit"
            variant="secondary"
            onClick={handleCancel}
          >
            {t(messages.accept)}
          </Button>
        ),
        type: 'fullScreen',
      }}
    />
  );
};

export default DocumentTrackingMobile;
