import { FC } from 'react';

import useTranslations from 'modules/I18n/hooks/useTranslations';
import { Text } from 'modules/Ui';

import messages from './messages';

const PillBeta: FC = () => {
  const { t } = useTranslations();
  return (
    <Text
      aria-hidden="true"
      backgroundColor="brand500"
      borderRadius="4px"
      color="gray0"
      fontSize={10}
      lineHeight={16}
      padding="0 4px"
      tag="span"
      textTransform="uppercase"
    >
      {t(messages.text)}
    </Text>
  );
};

export default PillBeta;
