import { Visible } from 'react-grid-system';

import useTranslations from 'modules/I18n/hooks/useTranslations';
import { MarkDown, Box } from 'modules/Ui';
import formatDate, { formatDateinXMonths } from 'utils/dates/formatDate';

import { useTotalSubscriptionCalcs } from '../../hooks';
import { COUPON_DATE_FORMAT, PAYMENT_PERIODS } from '../../models';
import { resume as texts } from '../messages';
import { Coupon } from '../types';

interface Props {
  coupon?: Coupon;
  periodicity: string;
  price: number;
  isReactivate: boolean;
}

const PaymentResumeInfo: React.FC<Props> = ({
  periodicity,
  price,
  coupon,
  isReactivate,
}) => {
  const { t, formatCurrency } = useTranslations();
  const explanationText =
    periodicity === PAYMENT_PERIODS.YEARLY
      ? texts.paymentResumeExplanationYear
      : texts.paymentResumeExplanationMonth;
  const { fullPriceWithTax, priceWithTax } = useTotalSubscriptionCalcs(
    price,
    coupon
  );

  return (
    <Box
      flex={{ sm: '1' }}
      marginLeft={{
        sm: '16px',
        md: isReactivate ? '16px' : '0px',
        lg: isReactivate ? '24px' : '0',
      }}
      marginTop={{ sm: '33px', md: isReactivate ? '32px' : '0' }}
    >
      <MarkDown
        color="brand500"
        fontSize={{
          _: 14,
          sm: 12,
          lg: 14,
        }}
        lineHeight={{
          _: 20,
          sm: 18,
          lg: 20,
        }}
        text={t(texts.paymentResumeExplanation, {
          startDate: formatDate(new Date()),
        })}
      />
      <Visible md lg xl xxl>
        <MarkDown
          color="gray600"
          fontSize={{ _: 12, lg: 14 }}
          lineHeight={{ _: 18, lg: 20 }}
          text={t(explanationText, {
            startDateFullPrice: formatDateinXMonths(
              new Date(),
              coupon?.durationInMonths,
              COUPON_DATE_FORMAT
            ),
            discountedPrice: formatCurrency(priceWithTax),
            fullPrice: formatCurrency(fullPriceWithTax),
            duration: coupon?.duration || 'none',
            startDate: formatDate(new Date()),
          })}
        />
      </Visible>
    </Box>
  );
};

export default PaymentResumeInfo;
