import React from 'react';

import { pickValidKeys } from './cleanProps';
import Props from './Props';

export const commonProps = [
  'accessKey',
  'autoCapitalize',
  'children',
  'className',
  'dir',
  'draggable',
  'form',
  'for',
  'hidden',
  'htmlFor',
  'id',
  'key',
  'lang',
  'ref',
  'role',
  'scope',
  'style',
  'tabIndex',
  'title',
  'noValidate',
  'name',
  /^(aria-)/,
  /^(data-)/,
  /^(on)/,
];

const filterProps = (props: Props): Props => {
  const { validProps = [] } = props;

  return pickValidKeys([...commonProps, ...validProps])(props);
};

const HtmlComponent = React.forwardRef((props: Props, ref) => {
  const Tag = props.tag || 'div';

  return <Tag ref={ref} {...filterProps(props)} />;
});

export default HtmlComponent;
