import styled from 'modules/Theme/styled-components';
import Text from 'modules/Ui/Text';

const RadioLabelText = styled(Text)`
  :root {
    --text-font-color: '';
  }
`;

export default RadioLabelText;
