import { FC, useMemo, useState } from 'react';
import { Visible } from 'react-grid-system';
import { useHistory } from 'react-router';

import { Formik } from 'formik';

import { useIsBookkeeping } from 'modules/Bookkeeper/hooks/useIsBookkeeperUser';
import useTranslations from 'modules/I18n/hooks/useTranslations';
import { MODAL_WIDTH_SMALLEST, Dialog, useCloseModal } from 'modules/Modals';
import { Button } from 'modules/Ui';
import { ROUTES } from 'pages/bookkeepers/routesMap';
import { useBlockingMutation } from 'utils/useBlockingMutation';

import { USERS } from '../routesMap';
import { updatePassword } from '../Update/services/updateUser';
import ChangePasswordForm from './ChangePasswordForm';
import { messages, formErrors } from './messages';
import { ChangePasswordModalProps } from './types';
import getValidationSchema from './validation';

const defaultInitialValues = {
  currentPassword: '',
  newPassword: '',
  newPasswordConfirmation: '',
};

const ChangePasswordModal: FC<ChangePasswordModalProps> = ({ onSuccess }) => {
  const history = useHistory();
  const { t } = useTranslations();
  const validationSchema = useMemo(() => getValidationSchema(t), [t]);
  const [errors, setErrors] = useState({ field: '', message: '' });
  const closeModal = useCloseModal();
  const isBookkeeping = useIsBookkeeping();
  const { mutate } = useBlockingMutation(updatePassword, {
    onSuccess: () => {
      history.push(isBookkeeping ? ROUTES.BOOKKEEPER_MYACCOUNT : USERS);
      closeModal();
      onSuccess();
    },
    onError: () => {
      setErrors({
        field: 'currentPassword',
        message: t(formErrors.changePasswordError),
      });
    },
  });

  const handleSubmit = ({
    currentPassword,
    newPassword,
  }: {
    currentPassword: string;
    newPassword: string;
  }) => {
    mutate({ oldPassword: currentPassword, password: newPassword });
  };

  return (
    <Formik
      validateOnChange={false}
      initialValues={defaultInitialValues}
      validateOnBlur={false}
      onSubmit={(values, actions) => {
        handleSubmit(values);
        actions.setSubmitting(false);
      }}
      {...{ validationSchema }}
    >
      {(formik) => (
        <Dialog
          type="fullScreen"
          dialogWidth={{ sm: MODAL_WIDTH_SMALLEST }}
          buttonPrimary={
            <Button form="changePassWordForm" type="submit" variant="secondary">
              {t(messages.submit)}
            </Button>
          }
          buttonSecondary={
            <Visible sm md lg xl xxl>
              <Button variant="stroke" onClick={closeModal}>
                {t(messages.cancel)}
              </Button>
            </Visible>
          }
          content={<ChangePasswordForm formik={formik} errors={errors} />}
          id="change-password-modal"
          onClose={closeModal}
          title={t(messages.title)}
        />
      )}
    </Formik>
  );
};

export default ChangePasswordModal;
