import { useCallback, FC } from 'react';
import { useDispatch } from 'react-redux';

import { ContactForm } from 'modules/Contacts/models';
import useTranslations from 'modules/I18n/hooks/useTranslations';
import { MODAL_WIDTH_MEDIUM, Dialog, useCloseModal } from 'modules/Modals';
import { Link, Warning, Button } from 'modules/Ui';
import { ROUTES } from 'pages/contacts/routesMap';

import AddContactForm from './AddContactForm';
import useAddContactMutation from './hooks/useAddContactMutation';
import messages from './messages';
import normalizeValues from './normalizeValues';
import { AddContactModalProps } from './types';

const AddContactModal: FC<AddContactModalProps> = ({ quoteId, contact }) => {
  const dispatch = useDispatch();
  const { t } = useTranslations();

  const duplicatedError = t<Element, JSX.Element>(messages.duplicated, {
    link: (chunks) => <Link to={ROUTES.CONTACTS}>{chunks}</Link>,
  });

  const mutate = useAddContactMutation(quoteId, duplicatedError);

  const closeModal = useCloseModal();

  const handleConfirm = useCallback(
    (values: ContactForm) => {
      mutate(normalizeValues(contact.id as string, values));
      closeModal();
    },
    [dispatch]
  );

  return (
    <Dialog
      id="add-contact-modal"
      title={t(messages.title)}
      onClose={closeModal}
      width={{ _: 'calc(92%)', sm: MODAL_WIDTH_MEDIUM }}
      buttonPrimary={
        <Button
          data-testid="add-contact-modal-submit"
          variant="secondary"
          form="add-contact"
          type="submit"
        >
          {t(messages.submit)}
        </Button>
      }
      buttonSecondary={
        <Button
          data-testid="add-contact-modal-cancel"
          onClick={closeModal}
          variant="stroke"
        >
          {t(messages.cancel)}
        </Button>
      }
    >
      <Warning message={t(messages.warning)} />
      <AddContactForm onSubmit={handleConfirm} contact={contact} />
    </Dialog>
  );
};

export default AddContactModal;
