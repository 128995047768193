import { useSelector } from 'react-redux';

import useIsBookkeeperUser from 'modules/Bookkeeper/hooks/useIsBookkeeperUser';
import useTranslations from 'modules/I18n/hooks/useTranslations';
import {
  selectHasUnlimitedPlan,
  selectHasProPlan,
  selectIsMySubsciptionTrial,
} from 'modules/Subscriptions/detail/selectors';

import { messages } from '../messages';

const getCode = (isBookkeeper?: boolean) => {
  if (isBookkeeper) {
    return 'BOOKKEEPER';
  }
  return 'REGULAR';
};

const useHasCustomerSupport = () => {
  const { t } = useTranslations();
  const hasProPlan = useSelector(selectHasProPlan);
  const hasUnlimitedPlan = useSelector(selectHasUnlimitedPlan);
  const isTrial = useSelector(selectIsMySubsciptionTrial);
  const isBookkeeper = useIsBookkeeperUser();
  if (isBookkeeper === undefined) {
    // while feature flag is loading this variable is undefined
    return {
      hasChatSupport: false,
      hasPhoneSupport: false,
      code: '',
      emailSupport: '',
    };
  }
  const code = getCode(isBookkeeper);
  const emailSupport = t(messages[`email${code}`] || messages.email);

  return {
    hasChatSupport: hasProPlan || isTrial,
    hasPhoneSupport: hasUnlimitedPlan || isTrial,
    code,
    emailSupport,
  };
};

export default useHasCustomerSupport;
