import { Visible } from 'react-grid-system';
import { useSelector } from 'react-redux';

import { selectCurrentBusiness } from 'modules/Business/Current/selectors';
import { Box, Text, Button, Avatar } from 'modules/Ui';
import { IconArrow } from 'modules/Ui/Icons';

import { MENU_BUTTON_EXPANDABLE } from './contants';

interface ExpandableButtonProps {
  active?: boolean;
  id?: string;
  isBookkeeper?: boolean;
  onClick: () => void;
  open?: boolean;
}

enum VARIANT {
  DARK = 'dark',
  LIGHT = 'light',
}

const getStyles = (isBookkeeper?: boolean) => {
  if (isBookkeeper) {
    return {
      color: 'gray0',
      variant: VARIANT.LIGHT,
    };
  }
  return {
    color: 'primary500',
    variant: VARIANT.DARK,
  };
};

const ExpandableButton = (props: ExpandableButtonProps) => {
  const { isBookkeeper, active, onClick, open, id } = props;
  const { fiscalName: businessName } = useSelector(selectCurrentBusiness);
  const { color } = getStyles(isBookkeeper);
  return (
    <Button
      {...{ onClick }}
      aria-controls={id}
      aria-expanded={open}
      boxSizing="content-box"
      data-testid={MENU_BUTTON_EXPANDABLE}
      height="32px"
      id={MENU_BUTTON_EXPANDABLE}
    >
      <Box display="flex" alignItems="center" tag="span">
        <Visible xl xxl>
          <Text
            color={color}
            fontWeight={active ? '600' : 'normal'}
            hasEllipsis
            marginRight="6px"
            maxWidth={{ lg: '150px' }}
            tag="span"
          >
            {businessName}
          </Text>
        </Visible>
        <Box borderRadius="50%" marginRight="4px" size="32px" tag="span">
          {businessName && <Avatar name={businessName} margin="0" size={32} />}
        </Box>
        <IconArrow
          color={color}
          transform={open ? 'rotate(-180deg)' : undefined}
          transition
        />
      </Box>
    </Button>
  );
};

export default ExpandableButton;
