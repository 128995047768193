import { getConfig } from 'modules/App/config';
import {
  DOCUMENTS_RECURRENTS_QUERY,
  DOCUMENT_SERIES_QUERY,
} from 'modules/App/queries/queries';
import request from 'utils/request';

import { DocumentSerie, TargetDocument } from '../models';

const createDocumentSerie = async ({
  serialCode,
  targetDocument = TargetDocument.INVOICE,
  lastNumber,
  shouldBeAddedToRecurrents = false,
}: {
  serialCode: string;
  targetDocument?: TargetDocument;
  lastNumber?: number;
  shouldBeAddedToRecurrents?: boolean;
}): Promise<DocumentSerie> => {
  const endpoint = (await getConfig()).apiDocumentSeriesService;

  const response = await request<any>(endpoint, {
    method: 'POST',
    data: {
      serialCode,
      targetDocument,
      lastNumber,
      initialNumber: (lastNumber ?? 0) + 1,
      shouldBeAddedToRecurrents,
    },
  });

  return {
    ...response.data.data,
  };
};

export const invalidateQueries = [
  DOCUMENT_SERIES_QUERY,
  DOCUMENTS_RECURRENTS_QUERY,
];

export default createDocumentSerie;
