import { Switch } from 'react-router-dom';

import {
  LazyComponentsSwitch,
  RouteDefinition,
} from 'modules/App/components/LazyComponentsSwitch';
import { PAYMENT_ROUTES } from 'pages/payments/routesMap';

const PaymentsSection = () => import('./Sections/PaymentsSection');

const routes: RouteDefinition[] = [
  {
    path: PAYMENT_ROUTES.LIST,
    lazyComponent: PaymentsSection,
  },
  {
    path: PAYMENT_ROUTES.BANKS,
    lazyComponent: PaymentsSection,
  },
  {
    path: PAYMENT_ROUTES.REMITTANCES,
    lazyComponent: PaymentsSection,
  },
];

export const SubmenuPayments = () => {
  return (
    <Switch>
      <LazyComponentsSwitch routes={routes} />
    </Switch>
  );
};
