import IconByString, { IconByStringProps } from './Icon/IconType/IconByString';

const IconBalance = (props: IconByStringProps) => {
  const { ...rest } = props;
  return (
    <IconByString {...rest}>
      <path
        d="M5.899 3h12.202A5.004 5.004 0 0 0 22 6.899v4.202A5.003 5.003 0 0 0 18.101 15H5.899A5 5 0 0 0 2 11.101V6.899A5 5 0 0 0 5.899 3ZM1 11V2h22v14H1v-5Zm3.873 4A4 4 0 0 0 2 12.127V15h2.873Zm16.596-2.695c.173-.072.35-.131.531-.178V15h-2.873a4.006 4.006 0 0 1 2.342-2.695ZM19.304 3.53A3.992 3.992 0 0 1 19.128 3H22v2.873a4 4 0 0 1-2.695-2.342ZM4.874 3H2v2.873A4 4 0 0 0 4.873 3ZM15 9a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm1 0a4 4 0 1 1-8 0 4 4 0 0 1 8 0Z"
        fill="currentColor"
        fillRule="evenodd"
      />
      <path fill="currentColor" d="M1 18h22v1H1zM1 21h22v1H1z" />
    </IconByString>
  );
};

export default IconBalance;
