import React from 'react';

import useProvinces from 'modules/Address/hooks/useProvinces';
import { SelectProps } from 'modules/Ui/Form/SelectCommon/types';

import SelectFormikField from './SelectFormikField';

interface SelectCountryProps extends Omit<SelectProps, 'options'> {
  enabled?: boolean;
  onChange?: (country: string) => void;
  country: string;
}

const ProvinceFormikField: React.FC<SelectCountryProps> = ({
  id,
  label,
  name,
  placeholder,
  enabled,
  onChange,
  country,
  ...rest
}) => {
  const { provinces } = useProvinces(country);

  const options = provinces
    ? Object.keys(provinces).map((key) => ({
        value: key,
        label: provinces[key],
      }))
    : [];

  return (
    <SelectFormikField
      isDisabled={!enabled}
      isSearchable
      key={country}
      id={id}
      label={label}
      name={name}
      placeholder={placeholder}
      {...rest}
      onChange={onChange}
      options={options}
    />
  );
};

export default ProvinceFormikField;
