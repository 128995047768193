import AuthActionTypes from 'modules/Auth/constants';
import { Actions as AuthActions } from 'modules/Auth/types';

import Business from '../models/business';
import ActionTypes from './constants';
import { Actions, State } from './types';

const emptyBusiness = {} as Business;

export const initialState: State = {
  loading: false,
  business: emptyBusiness,
};

const reducer = (
  state: State = initialState,
  action: Actions | AuthActions
): State => {
  switch (action.type) {
    case AuthActionTypes.LOGIN_BUSINESS_SUCCESS:
      return {
        ...state,
        business: emptyBusiness,
      };
    case ActionTypes.FETCH_BUSINESS_REQUEST:
      if (Object.keys(state.business ?? {}).length > 0) {
        return state;
      }
      return {
        loading: true,
        business: emptyBusiness,
      };
    case ActionTypes.FETCH_BUSINESS_FAILURE:
      return {
        loading: false,
        business: emptyBusiness,
      };
    case ActionTypes.FETCH_BUSINESS_SUCCESS:
      return {
        loading: false,
        business: action.payload,
      };
    case ActionTypes.UPDATE_BUSINESS_VAT_NUMBER:
      return {
        ...state,
        business: {
          ...state.business,
          vatNumber: action.payload,
        },
      };
    default:
      return state;
  }
};

export default reducer;
