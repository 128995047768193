import { useSelector } from 'react-redux';

import { selectIsMobile } from 'modules/App/store/selectors';
import useIsBookkeeperUser, {
  useIsBookkeeperLoggedInClient,
} from 'modules/Bookkeeper/hooks/useIsBookkeeperUser';
import { selectCurrentBusiness } from 'modules/Business/Current/selectors';
import FormBannerBai from 'modules/Documents/components/Form/BannerBAI/FormBannerBAI';
import BubbleNotifications from 'modules/Notifications/components/BubbleNotifications/BubbleNotifications';
import {
  selectIfMustShowReactivationBanner,
  selectIsTrialNearToExpire,
} from 'modules/Subscriptions/detail/selectors';
import { Box, BannerEndTrial } from 'modules/Ui';
import BusinessMenu from 'modules/Ui/Menu/MenuDesktop';
import BookkeeperMenu from 'modules/Ui/Menu/MenuDesktop/BookkeeperMenu';
import {
  SubMenuBusiness,
  SubmenuPayments,
} from 'modules/Ui/Menu/SubMenuDekstop';
import { zIndex } from 'modules/Ui/zIndex';

const Header = () => {
  const showBookkeeper = useIsBookkeeperUser();
  const isBookkeeperLoogedInClient = useIsBookkeeperLoggedInClient();
  const shouldRenderBookkeeperMenu =
    showBookkeeper && !isBookkeeperLoogedInClient;
  const mustShowBanner = useSelector(selectIfMustShowReactivationBanner);
  const { isDummy } = useSelector(selectCurrentBusiness);
  const isTrialNearToExpire = useSelector(selectIsTrialNearToExpire);
  const isMobile = useSelector(selectIsMobile);

  const isDummyOrBookeeper = isDummy || isBookkeeperLoogedInClient;
  return (
    <>
      {mustShowBanner && !isDummyOrBookeeper && (
        <BannerEndTrial subsciptionNearToBeCancelled={true} />
      )}
      {isTrialNearToExpire && !isDummyOrBookeeper && (
        <BannerEndTrial subsciptionNearToBeCancelled={false} />
      )}
      {!isMobile && (
        <Box
          marginBottom={{ sm: '16px' }}
          position="sticky"
          top="0"
          zIndex={zIndex.HEADER}
        >
          {shouldRenderBookkeeperMenu !== undefined && (
            <>
              {shouldRenderBookkeeperMenu && <BookkeeperMenu />}
              {!shouldRenderBookkeeperMenu && (
                <>
                  <BusinessMenu />
                  <SubMenuBusiness />
                  <SubmenuPayments />
                  <BubbleNotifications />
                  <FormBannerBai />
                </>
              )}
            </>
          )}
        </Box>
      )}
    </>
  );
};

export default Header;
