import { Visible } from 'react-grid-system';

import history from 'utils/history';

import Button from '../Form/Button';

interface Props {
  form: string;
  cancelText: string;
  addText: string;
}

const FormFooter: React.FC<Props> = ({ form, cancelText, addText }) => {
  return (
    <>
      <Visible md lg xl xxl>
        <li>
          <Button
            marginBottom={{ _: '16px', sm: '0' }}
            onClick={history.goBack}
            variant="stroke"
          >
            {cancelText}
          </Button>
        </li>
      </Visible>
      <Button
        data-testid="submit"
        form={form}
        type="submit"
        variant="secondary"
        width={{ _: '100%', sm: 'auto' }}
      >
        {addText}
      </Button>
    </>
  );
};

export default FormFooter;
