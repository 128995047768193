import { FC } from 'react';

import useTranslations from 'modules/I18n/hooks/useTranslations';
import { fadeOut, fadeIn } from 'modules/Theme/mixins';
import styled from 'modules/Theme/styled-components';
import { Img, Box } from 'modules/Ui';
import { zIndex } from 'modules/Ui/zIndex';

import messages from '../../../messages';

const StyledImg = styled(Img)`
  animation: 0.5s ${fadeOut} ease-in;
  left: 50%;
  opacity:0;
  position: absolute;
  top:0;
  transform: translate(-50%, 0);
  z-index: ${zIndex.SUBSCRIPTION_PICTURES};
  &.visible{
    animation: 0.5s ${fadeIn} ease-in;
    opacity: 1;
  }
`;

interface Props {
  activePlan: string;
}

const PlansIlustations: FC<Props> = ({ activePlan }) => {
  const planVariant = activePlan.split('_')[1] || 'pro';
  const { t } = useTranslations();
  return (
    <Box
      height={{ sm: '205px', md: '212px' }}
      marginBottom={{ sm: '16px', lg: '20px' }}
      position="relative"
    >
      <StyledImg
        alt={t(messages.basic)}
        aria-hidden="true"
        className={planVariant === 'basic' ? 'visible' : ''}
        height="100%"
        src="/assets/plans1.svg"
      />
      <StyledImg
        alt={t(messages.pro)}
        aria-hidden="true"
        className={planVariant === 'pro' ? 'visible' : ''}
        height="100%"
        src="/assets/plans2.svg"
      />
      <StyledImg
        alt={t(messages.unlimited)}
        aria-hidden="true"
        className={planVariant === 'unlimited' ? 'visible' : ''}
        height="100%"
        src="/assets/plans3.svg"
      />
    </Box>
  );
};

export default PlansIlustations;
