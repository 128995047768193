import { ReactNode, forwardRef, Ref } from 'react';

import {
  flexbox,
  FlexboxProps,
  gridTemplateColumns,
  GridTemplateColumnsProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
  space,
  SpaceProps,
  system,
} from 'styled-system';

import useTranslations from 'modules/I18n/hooks/useTranslations';
import theme from 'modules/Theme';
import { slidingCenterUnderline } from 'modules/Theme/mixins';
import styled from 'modules/Theme/styled-components';
import { Text, Box } from 'modules/Ui';
import HtmlButton from 'modules/Ui/Html/Button';

import { messages } from './messages';

interface Props
  extends React.ComponentPropsWithoutRef<'button'>,
    LayoutProps,
    SpaceProps,
    PositionProps,
    FlexboxProps,
    GridTemplateColumnsProps {
  $active?: boolean;
  color?: string;
  columnGap?: {} | string;
  icon?: ReactNode;
  label?: ReactNode;
  notification?: number;
  notificationBgColor?: string;
  notificationColor?: string;
  onClick?: (value: any) => void;
  selected?: boolean;
  /**
   * You can provide your own value. Otherwise, we fallback to the child position index.
   */
  value?: any;
  onChange?: (event: any, value?: any) => void;
}

const StyledButton = styled(HtmlButton)<Props>`
  ${flexbox}
  ${gridTemplateColumns}
  ${layout}
  ${position}
  ${space}
  ${slidingCenterUnderline(theme.colors.accent400)}
  ${system({
    columnGap: {
      property: 'columnGap',
      transform: (value) => `${value}`,
    },
  })}
  ${({ color }) => color && `color: ${color};`}

  box-sizing: border-box;

  @media (hover: hover) {
    &:hover {
      color: ${(props) => props.theme.colors.gray800};
      cursor: ${({ $active }) => $active && 'text'};
    }
  }

  &:focus {
    color: ${(props) => props.theme.colors.gray800};
  }

  span {
    &::before {
      display: block;
      content: attr(data-title-for-hover);
      font-weight: bold;
      height: 0;
      overflow: hidden;
      visibility: hidden;
    }
  }
`;

const handleGridTemplateColumns = (
  withNotification?: number,
  withIcon?: ReactNode
) => {
  if (withNotification && withIcon) {
    return '20px 1fr auto';
  }
  if (withNotification) {
    return '1fr auto';
  }
  if (withIcon) {
    return '20px 1fr';
  }
  return '';
};

const Tab = forwardRef((props: Props, ref: Ref<HTMLButtonElement>) => {
  const {
    $active,
    color,
    icon,
    label,
    notification,
    notificationColor,
    notificationBgColor,
    onChange,
    onClick,
    selected,
    value,
    ...other
  } = props;

  const handleClick = (event: any) => {
    if (!selected && onChange) {
      onChange(event, value);
    }
    if (onClick) {
      onClick(event);
    }
  };

  const { t } = useTranslations();
  const textNotification = notification
    ? ` ${notification} ${t(messages.notifications)}`
    : '';

  return (
    <Box marginRight="14px" tag="li">
      <StyledButton
        $active={!!selected}
        alignItems={notification || icon ? 'center' : ''}
        columnGap={notification || icon ? '6px' : ''}
        aria-controls={`tabpanel-${value}`}
        aria-label={`${label}${textNotification}`}
        aria-selected={selected}
        color={selected ? theme.colors.gray800 : theme.colors.primary300}
        data-testid={`settings-${value}-page`}
        display={notification || icon ? 'grid' : ''}
        gridTemplateColumns={handleGridTemplateColumns(notification, icon)}
        height="50px"
        id={`tab-${value}`}
        onClick={handleClick}
        padding={!icon ? { _: '16px 8px 13px', sm: '16px 8px 13px' } : ''}
        position="relative"
        ref={ref}
        role="tab"
        selected={!!selected}
        type="button"
        {...other}
      >
        {icon}
        <Text
          className="tab--label"
          color="inherit"
          data-title-for-hover={label}
          display={!notification ? 'block' : undefined}
          fontSize={14}
          fontWeight={selected ? '600' : undefined}
          hasEllipsis
          lineHeight={18}
          tag="span"
          {...{ selected }}
        >
          {label}
        </Text>
        {notification ? (
          <Text
            backgroundColor={notificationBgColor || 'stateNewBg'}
            borderRadius="100px"
            color={notificationColor || 'stateNew'}
            fontSize={12}
            lineHeight={16}
            padding="0 6px"
            tag="span"
          >
            {notification}
          </Text>
        ) : null}
      </StyledButton>
    </Box>
  );
});

export default Tab;
