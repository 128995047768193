import { useCallback, useState } from 'react';
import { useHistory } from 'react-router';

import { ContactFilters } from 'modules/Contacts/models';
import { DocumentFilters } from 'modules/Documents/models/document';
import { PaymentFilters } from 'modules/Payments';
import { useSaveQueryParamsFilters } from 'utils/router/useSaveQueryParamsFilters';

import { CurrentTabType, TabQueryParamsState } from '../types';

type UseFilterParams<T> = {
  currentTab: CurrentTabType;
  queryParamsState: TabQueryParamsState<T>;
  setQueryParamsState: (state: TabQueryParamsState<T>) => void;
};

export const useFilters = <
  T extends DocumentFilters | PaymentFilters | ContactFilters
>({
  currentTab,
  queryParamsState,
  setQueryParamsState,
}: UseFilterParams<DocumentFilters | PaymentFilters | ContactFilters>) => {
  const history = useHistory();
  const saveFiltersToQueryParams = useSaveQueryParamsFilters(history);

  const [filtersActive, setFiltersActive] = useState(false);
  const [filtersDisabled, setFiltersDisabled] = useState(false);

  const closeFilters = useCallback(() => {
    setFiltersActive(false);
  }, [setFiltersActive]);

  const [searchTerm, setSearchTerm] = useState(
    (queryParamsState[currentTab] as T)?.searchTerm ?? ''
  );

  const toggleFilters = useCallback(() => {
    setFiltersActive(!filtersActive);
  }, [setFiltersActive, filtersActive]);

  const updateQueryParams = (event: T) => {
    saveFiltersToQueryParams(currentTab, {
      event,
    });
    setQueryParamsState({
      ...queryParamsState,
      [currentTab]: event,
    });
  };

  return {
    filtersActive,
    filtersDisabled,
    closeFilters,
    toggleFilters,
    updateQueryParams,
    searchTerm,
    setSearchTerm,
    setFiltersDisabled,
    setFiltersActive,
    saveFiltersToQueryParams,
  };
};
