import { ReactNode } from 'react';

import { space, SpaceProps } from 'styled-system';

import useTheme from 'modules/Theme/hooks/useTheme';
import styled from 'modules/Theme/styled-components';

import Box from '../Box';
import TooltipButton from './Components/TooltipButton';
import TooltipContent from './Components/TooltipContent';

interface TooltipButtonProps extends SpaceProps {
  delayHide?: number;
  disable?: boolean;
  id?: string;
  label?: string;
  noBullet?: boolean;
  onClick?: (value: any) => void;
  place?: 'top' | 'right' | 'bottom' | 'left';
  tooltipBackgroundColor?: string;
  tooltipButton: ReactNode;
  tooltipContent: string | ReactNode;
  tooltipContentPadding?: string;
  tooltipTextColor?: string;
}

const StyledButtonTooltip = styled(TooltipButton)``;

const StyledWrapper = styled(Box)<TooltipButtonProps>`
  .react-tooltip {
    ${({ tooltipContentPadding }) =>
      tooltipContentPadding && `padding: ${tooltipContentPadding};`}

      &.place-bottom {
        ${({ noBullet }) => noBullet && 'margin-top: 0;'}
      }

      &::after, &::before {
        ${({ noBullet }) => noBullet && 'display: none;'}
      }
      white-space: pre-wrap;
      overflow: hidden;
      max-width: 400px;
  }

  ${StyledButtonTooltip} {
    ${space}

    &:focus {
      outline: none;
    }

    &:focus + .react-tooltip {
      opacity: 1;
      visibility: visible;
    }
  }
`;

const Tooltip = (props: TooltipButtonProps) => {
  const theme = useTheme();
  const {
    delayHide,
    disable,
    id,
    label,
    noBullet,
    onClick,
    place,
    tooltipBackgroundColor,
    tooltipButton,
    tooltipContent,
    tooltipContentPadding = '16px',
    tooltipTextColor = theme.colors.gray600,
    ...rest
  } = props;
  return (
    <StyledWrapper
      noBullet={noBullet}
      tag="span"
      tooltipContentPadding={tooltipContentPadding}
      textAlign="initial"
    >
      <StyledButtonTooltip label={label} id={id} onClick={onClick} {...rest}>
        {tooltipButton}
      </StyledButtonTooltip>
      <StyledButtonTooltip label={label} isClickable id={id} onClick={onClick}>
        {tooltipButton}
      </StyledButtonTooltip>
      <TooltipContent
        backgroundColor={tooltipBackgroundColor ?? theme.colors.auxiliary}
        delayHide={delayHide}
        disable={disable}
        id={`${id}-tooltip`}
        place={place}
        textColor={tooltipTextColor}
      >
        {tooltipContent}
      </TooltipContent>
    </StyledWrapper>
  );
};

StyledButtonTooltip.defaultProps = {
  marginLeft: '4px',
};

export default Tooltip;
