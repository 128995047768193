import { endOfMonth, endOfWeek, startOfMonth, startOfWeek } from 'date-fns';

import formatDate from 'utils/dates/formatDate';
import { addGteQuery, addLteQuery } from 'utils/query/addQuery';

import {
  DigitalDocumentStatus,
  DocumentFilters,
  DocumentSort,
  DocumentStatus,
  DocumentType,
  DueDate,
  ExpenseToReviewSort,
  RecurrentDocumentFilters,
} from '../models/document';
import { ReceiptStatus } from '../models/receiptStatus';
import { StatusInfoUI } from '../models/statusInfo';

function format(date?: string | Date | undefined) {
  return formatDate(date, 'yyyy-MM-dd');
}

function buildDueDateQuery(dueDate: string) {
  if (dueDate === DueDate.TODAY) {
    return `&query[dueDate]=${format(new Date())}`;
  }
  if (dueDate === DueDate.THIS_WEEK) {
    const start = startOfWeek(new Date(), { weekStartsOn: 1 });
    const end = endOfWeek(new Date(), { weekStartsOn: 1 });

    const query = addGteQuery('dueDate', format(start));
    return query + addLteQuery('dueDate', format(end));
  }
  if (dueDate === DueDate.THIS_MONTH) {
    const start = startOfMonth(new Date());
    const end = endOfMonth(new Date());
    return `&query[dueDate][$gte]=${format(
      start
    )}&query[dueDate][$lte]=${format(end)}`;
  }
  return `&query[dueDate][$lt]=${format(new Date())}`;
}

export function buildRecurrentFiltersQuery(filters?: RecurrentDocumentFilters) {
  const {
    searchTerm,
    nextInvoiceDateFrom,
    nextInvoiceDateTo,
    periodicity,
    minAmount,
    maxAmount,
    counterparty,
    category,
  } = filters ?? {};

  const queryParams = [];

  if (searchTerm?.length && searchTerm.length > 1) {
    queryParams.push(`search=${searchTerm}`);
  }
  if (nextInvoiceDateFrom) {
    queryParams.push(addGteQuery('nextDate', format(nextInvoiceDateFrom)));
  }
  if (nextInvoiceDateTo) {
    queryParams.push(addLteQuery('nextDate', format(nextInvoiceDateTo)));
  }
  if (periodicity) {
    queryParams.push(`query[newDocumentPeriod]=${periodicity}`);
  }
  if (minAmount !== undefined && minAmount !== '') {
    queryParams.push(addGteQuery('documentTemplate.totals.total', minAmount));
  }
  if (maxAmount !== undefined && maxAmount !== '') {
    queryParams.push(addLteQuery('documentTemplate.totals.total', maxAmount));
  }
  if (counterparty) {
    queryParams.push(`query[contact]=${counterparty}`);
  }
  if (category) {
    queryParams.push(`query[documentTemplate][category]=${category}`);
  }

  return queryParams.join('&');
}

function buildStatusInfoQuery(statusInfo: StatusInfoUI) {
  switch (statusInfo) {
    case StatusInfoUI.PAID: {
      return `&query[paidStatus][$eq]=${StatusInfoUI.PAID}`;
    }
    case StatusInfoUI.NOT_PAID: {
      return `&query[paidStatus][$in][0]=${StatusInfoUI.NOT_PAID}&query[paidStatus][$in][1]=${StatusInfoUI.PARTIALLY_PAID}`;
    }
    case StatusInfoUI.PARTIALLY_PAID: {
      return `&query[paidStatus][$eq]=${StatusInfoUI.PARTIALLY_PAID}`;
    }
    case StatusInfoUI.DUE: {
      const today = format(new Date());
      return `&query[paidStatus][$ne]=${StatusInfoUI.PAID}&query[dueDate][$lte]=${today}`;
    }
    default:
      return '';
  }
}

export function buildDocumentTypeStatusQuery(
  query: string,
  {
    status,
    documentType,
    canVoidDocument,
    excludeTestInvoices,
    filters,
  }: {
    status?: DocumentStatus;
    documentType?: DocumentType;
    canVoidDocument?: boolean;
    excludeTestInvoices?: boolean;
    filters?: DocumentFilters;
  }
): string {
  const eqStatus: string[] = [];

  if (status) {
    eqStatus.push(status);
  } else if (filters?.statusInfo === StatusInfoUI.CORRECTED) {
    eqStatus.push(DocumentStatus.CORRECTED);
  } else if (filters?.statusInfo === StatusInfoUI.REPLACED) {
    eqStatus.push(DocumentStatus.REPLACED);
  } else if (documentType === DocumentType.INVOICE) {
    eqStatus.push(DocumentStatus.ISSUED);
    eqStatus.push(DocumentStatus.CORRECTED);
    eqStatus.push(DocumentStatus.REPLACED);

    if (canVoidDocument) {
      eqStatus.push(DocumentStatus.VOID);
    }
  }

  const eqQueries = eqStatus
    .map((s, i) => `query[status][$in][${i}]=${s}`)
    .join('&');

  const docTypeValue =
    filters?.statusInfo === StatusInfoUI.CORRECTIVE
      ? 'CORRECTIVE'
      : documentType ?? '';

  const docTypeValues = excludeTestInvoices
    ? [docTypeValue]
    : [docTypeValue, 'TEST_INVOICE'];

  if (documentType === DocumentType.INVOICE) {
    docTypeValues.push('CORRECTIVE');
  }

  const docQueries = docTypeValues
    .map((s, i) => `query[documentType][$in][${i}]=${s}`)
    .join('&');

  return `${query}&${eqQueries}&${docQueries}`;
}

function buildDigitalDocumentStatusQuery(
  digitalDocumentStatus: DigitalDocumentStatus
): string {
  switch (digitalDocumentStatus) {
    case DigitalDocumentStatus.PRESENTED_WITH_ERRORS:
      return `&query[digitalDocument][status]=${DigitalDocumentStatus.PRESENTED_WITH_ERRORS}`;
    case DigitalDocumentStatus.PRESENTED:
      return `&query[digitalDocument][status]=${
        DigitalDocumentStatus.PRESENTED
      }&${addLteQuery('digitalDocument.taxAuthorityValidations', '0')}`;
    case DigitalDocumentStatus.IN_PROGRESS:
    case DigitalDocumentStatus.FAIL_TO_CONTACT:
      return `&query[digitalDocument][status]=${DigitalDocumentStatus.IN_PROGRESS}&query[digitalDocument][status]=${DigitalDocumentStatus.FAIL_TO_CONTACT}`;
    default:
      return `&query[digitalDocument][status]=${digitalDocumentStatus}`;
  }
}

export default function buildFiltersQuery(filters?: DocumentFilters) {
  if (!filters) {
    return '';
  }

  const {
    statusInfo,
    issuedDateFrom,
    issuedDateTo,
    operationDateFrom,
    operationDateTo,
    dueDate,
    digitalDocumentStatus,
    updatedDateTo,
    updatedDateFrom,
    receiptStatus,
  } = filters;
  const searchQuery = buildSearchTermQuery(filters);
  const counterpartyQuery = buildDefaultFilterQuery(filters, 'counterparty');
  const statusInfoQuery = statusInfo ? buildStatusInfoQuery(statusInfo) : '';
  const codeQuery = buildDefaultFilterQuery(filters, 'code');
  const serialCodeQuery = buildDefaultFilterQuery(filters, 'serialCode');
  const updatedDateFromQuery = updatedDateFrom
    ? addGteQuery('updatedAt', format(updatedDateFrom))
    : '';
  const updatedDateToQuery = updatedDateTo
    ? addLteQuery('updatedAt', format(updatedDateTo))
    : '';
  const issuedDateFromQuery = issuedDateFrom
    ? addGteQuery('issuedDate', format(issuedDateFrom))
    : '';
  const issuedDateToQuery = issuedDateTo
    ? addLteQuery('issuedDate', format(issuedDateTo))
    : '';
  const operationDateFromQuery = operationDateFrom
    ? addGteQuery('operationDate', format(operationDateFrom))
    : '';
  const operationDateToQuery = operationDateTo
    ? addLteQuery('operationDate', format(operationDateTo))
    : '';
  const dueDateQuery = dueDate ? buildDueDateQuery(dueDate) : '';
  const tagQuery = buildTagQuery(filters);
  const minAmountQuery = buildMinAmountQuery(filters);
  const maxAmountQuery = buildMaxAmountQuery(filters);
  const digitalDocumentStatusQuery = digitalDocumentStatus
    ? buildDigitalDocumentStatusQuery(digitalDocumentStatus)
    : '';

  const receiptStatusQuery = buildReceiptStatusQuery(receiptStatus);

  return [
    searchQuery,
    counterpartyQuery,
    statusInfoQuery,
    codeQuery,
    serialCodeQuery,
    updatedDateFromQuery,
    updatedDateToQuery,
    issuedDateFromQuery,
    issuedDateToQuery,
    operationDateFromQuery,
    operationDateToQuery,
    dueDateQuery,
    tagQuery,
    minAmountQuery,
    maxAmountQuery,
    digitalDocumentStatusQuery,
    receiptStatusQuery,
  ].join('');
}

const buildReceiptStatusQuery = (
  receiptStatus?: ReceiptStatus | null
): string => {
  if (receiptStatus === null) {
    return `&query[receiptStatus]`;
  }
  return receiptStatus ? `&query[receiptStatus]=${receiptStatus}` : '';
};

export const baseDocumentToReviewSort = (sortBy?: ExpenseToReviewSort) =>
  sortBy?.field === 'arrivalDate'
    ? [
        {
          field: 'arrivalDate',
          order: sortBy.order,
        },
      ]
    : [
        {
          field: sortBy?.field,
          order: sortBy?.order,
        },
      ];

export const baseDocumentSort = (sortBy?: DocumentSort) => {
  if (sortBy?.field === 'code') {
    return [
      {
        field: 'serialCode',
        order: sortBy.order,
      },
      {
        field: 'code',
        order: sortBy.order,
      },
    ];
  }
  if (sortBy?.field === 'issuedDateTime') {
    return [
      {
        field: 'issuedDate',
        order: sortBy.order,
      },
      {
        field: 'issuedDateTime',
        order: sortBy.order,
      },
    ];
  }

  return [
    {
      field: sortBy?.field,
      order: sortBy?.order,
    },
    {
      field: 'serialCode',
      order: sortBy?.order,
    },
    {
      field: 'code',
      order: sortBy?.order,
    },
  ];
};

export const queryStatus = (
  filters?: DocumentFilters,
  status?: DocumentStatus
) => {
  if (filters?.statusInfo === StatusInfoUI.ISSUED) {
    return DocumentStatus.ISSUED;
  }
  if (filters?.statusInfo === StatusInfoUI.VOID) {
    return DocumentStatus.VOID;
  }
  return status;
};

const buildSearchTermQuery = (filters: DocumentFilters): string => {
  const { searchTerm } = filters;
  return searchTerm && searchTerm.length > 1 ? `&search=${searchTerm}` : '';
};

const buildTagQuery = (filters: DocumentFilters): string => {
  const { tag } = filters;
  if (!tag) {
    return '';
  }
  return tag === StatusInfoUI.NOINVOICED
    ? '&query[tags][$overlap][0]=PENDING&query[tags][$overlap][1]=DELIVERED'
    : `&query[tags][$overlap][0]=${tag}`;
};

const buildMinAmountQuery = (filters: DocumentFilters): string => {
  const { minAmount } = filters;
  return minAmount !== undefined && minAmount !== ''
    ? addGteQuery('totals.total', minAmount)
    : '';
};

const buildMaxAmountQuery = (filters: DocumentFilters): string => {
  const { maxAmount } = filters;
  return maxAmount !== undefined && maxAmount !== ''
    ? addLteQuery('totals.total', maxAmount)
    : '';
};

const buildDefaultFilterQuery = (
  filters: DocumentFilters,
  field: keyof DocumentFilters
): string => {
  const value = filters[field];

  return value ? `&query[${field}]=${value}` : '';
};
