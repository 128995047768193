import { defaultTaxOption, taxOptions } from '../../Taxes';
import { Concept } from '../models/document';
import { getSalesEqualizationTax } from './sales-equalization-taxes';

interface GetTaxArgs {
  taxBase: number;
  taxAmount: number;
  saleEqualizationAmount: number;
  concept: Concept;
}

export const getTax = (args: GetTaxArgs) => {
  const { taxAmount, taxBase, saleEqualizationAmount, concept } = args;

  const tax =
    concept.tax || taxOptions[concept.taxKey || defaultTaxOption.value];

  return {
    tax,
    taxAmount,
    base: taxBase,
    saleEqualizationAmount,
    saleEqualization: getSalesEqualizationTax(concept.taxKey),
  };
};

export const getTaxPercentages = (concept: Concept) => {
  const tax =
    concept.tax || taxOptions[concept.taxKey || defaultTaxOption.value];

  const taxPercentage = tax?.amount || 0;
  const salesEqTaxPercentage = getSalesEqualizationTax(concept.taxKey);

  return { taxPercentage, salesEqTaxPercentage };
};

export const generateTaxesObjectKey = (concept: Concept): string =>
  concept.tax?.value || defaultTaxOption.value;
