import { FC } from 'react';
import { useSelector } from 'react-redux';

import { selectIsMobile } from 'modules/App/store/selectors';
import { hasElectronicDocumentProvider } from 'modules/Business/businessUtils';
import { selectCurrentBusiness } from 'modules/Business/Current/selectors';
import useTranslations from 'modules/I18n/hooks/useTranslations';
import { ShopifyIlustration } from 'modules/Integrations/components';
import { SHOPIFY_CONFIGURATION } from 'modules/Integrations/paths';
import { MODAL_WIDTH_SMALL, Dialog, useCloseModal } from 'modules/Modals';
import { ModalFlag } from 'modules/Modals/hooks/useModalFlags';
import useModalFlagState from 'modules/Modals/hooks/useModalFlagState';
import { Box, Link, Text } from 'modules/Ui';

import messages from './messages';

const ConfigureShopifyModal: FC = () => {
  const isMobile = useSelector(selectIsMobile);
  const { t } = useTranslations();
  const { setFlagToTrue } = useModalFlagState(
    ModalFlag.HIDE_SHOPIFY_CONFIG_MODAL
  );
  const business = useSelector(selectCurrentBusiness);
  const isBai = business && hasElectronicDocumentProvider(business);

  const closeModal = useCloseModal(setFlagToTrue);

  return (
    <Dialog
      type="fullScreen"
      dialogWidth={{ sm: 'calc(100% - 72px)', md: MODAL_WIDTH_SMALL }}
      onClose={closeModal}
      underlayMarginTop={!isMobile ? '114px' : undefined}
      content={
        <Box
          boxSizing="border-box"
          display={{ _: 'grid', sm: 'block' }}
          gridTemplateRows={{ _: '1fr auto', sm: 'initial' }}
          height={{ _: '100vh', sm: 'auto' }}
          padding={{ _: '64px 0 24px', sm: '16px 8px 0' }}
          textAlign={{ sm: 'center' }}
        >
          <Box textAlign="center">
            <ShopifyIlustration
              height={isMobile ? 200 : 256}
              ilustraWidth={isMobile ? 200 : 256}
              margin="0 auto 30px"
              width={isMobile ? 280 : 360}
              withConfetti
            />
            <Text
              color="brand500"
              fontSize={24}
              fontWeight="600"
              lineHeight={30}
              marginBottom="16px"
            >
              {t(messages.subtitle)}
            </Text>
            <Text marginBottom={{ sm: '32px' }} fontSize={14} lineHeight={18}>
              {t(isBai ? messages.textBai : messages.text)}
            </Text>
          </Box>
          <Link
            onClick={closeModal}
            to={SHOPIFY_CONFIGURATION}
            variant="primary"
            width={isMobile ? '100%' : '268px'}
          >
            {t(messages.cta)}
          </Link>
        </Box>
      }
    />
  );
};

export default ConfigureShopifyModal;
