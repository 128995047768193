import IconByPath, { IconByPathProps } from './Icon/IconType/IconByPath';

const IconTotalRectification = (props: IconByPathProps) => (
  <IconByPath
    {...props}
    fillRule="evenodd"
    path="M13 2v5h-1V2.71L7.773 6.968l-.71-.705L11.297 2H7V1h6v1Zm10 5V1h-6v1h4.296l-4.232 4.263.71.705L22 2.71V7h1ZM5.899 9h12.202A5.004 5.004 0 0 0 22 12.899v4.202A5.003 5.003 0 0 0 18.101 21H5.899A5 5 0 0 0 2 17.101v-4.202A5.004 5.004 0 0 0 5.899 9ZM1 17V8h22v14H1v-5Zm3.873 4A4 4 0 0 0 2 18.127V21h2.873Zm16.596-2.695c.173-.072.35-.131.531-.178V21h-2.873a4.006 4.006 0 0 1 2.342-2.695ZM19.304 9.53A3.992 3.992 0 0 1 19.128 9H22v2.873a4.006 4.006 0 0 1-2.695-2.342ZM4.874 9H2v2.873a4.006 4.006 0 0 0 2.696-2.342A4 4 0 0 0 4.873 9ZM15 15a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm1 0a4 4 0 1 1-8 0 4 4 0 0 1 8 0Z"
    shapeRendering="geometricPrecision"
  />
);

export default IconTotalRectification;
