import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';

import { selectCurrentBusiness } from 'modules/Business/Current/selectors';
import {
  ActionType,
  UserNotification,
} from 'modules/UserNotifications/models/models';
import { UserNotificationTypes } from 'modules/UserNotifications/models/UserNotificationPaylodType';

import { AutosaveKey } from '../models';
import messages from './messages';
import { usePersistValues } from './usePersistValues';

const keyMap: {
  [key in AutosaveKey]: {
    action:
      | ActionType.NAVIGATE_TO_CREATE_INVOICE
      | ActionType.NAVIGATE_TO_CREATE_RECURRENT_INVOICE
      | ActionType.NAVIGATE_TO_CREATE_QUOTE
      | ActionType.NAVIGATE_TO_CREATE_PROFORMA
      | ActionType.NAVIGATE_TO_UPLOAD_DOCUMENT
      | ActionType.NAVIGATE_TO_CREATE_RECEIPT
      | ActionType.NAVIGATE_TO_UPLOAD_EXPENSE
      | ActionType.NAVIGATE_TO_CREATE_RECURRENT_EXPENSE
      | ActionType.NAVIGATE_TO_CREATE_DELIVERY_NOTE;
    bodyKey: string;
  };
} = {
  [AutosaveKey.CREATE_RECURRENT_EXPENSE]: {
    action: ActionType.NAVIGATE_TO_CREATE_RECURRENT_EXPENSE,
    bodyKey: messages.recurrentFormInProgress.id,
  },
  [AutosaveKey.DELIVERY_NOTE_CREATE]: {
    action: ActionType.NAVIGATE_TO_CREATE_DELIVERY_NOTE,
    bodyKey: messages.deliveryNoteFormInProgress.id,
  },
  [AutosaveKey.DOCUMENTS_CREATE]: {
    action: ActionType.NAVIGATE_TO_CREATE_INVOICE,
    bodyKey: messages.documentFormInProgress.id,
  },
  [AutosaveKey.PROFORMA_CREATE]: {
    action: ActionType.NAVIGATE_TO_CREATE_PROFORMA,
    bodyKey: messages.proformaFormInProgress.id,
  },
  [AutosaveKey.QUOTE_CREATE]: {
    action: ActionType.NAVIGATE_TO_CREATE_QUOTE,
    bodyKey: messages.quoteFormInProgress.id,
  },
  [AutosaveKey.RECURRENTS_CREATE]: {
    action: ActionType.NAVIGATE_TO_CREATE_RECURRENT_INVOICE,
    bodyKey: messages.recurrentFormInProgress.id,
  },
  [AutosaveKey.UPLOAD_DOCUMENT]: {
    action: ActionType.NAVIGATE_TO_UPLOAD_DOCUMENT,
    bodyKey: messages.uploadDocumentFormInProgress.id,
  },
  [AutosaveKey.UPLOAD_EXPENSE]: {
    action: ActionType.NAVIGATE_TO_UPLOAD_EXPENSE,
    bodyKey: messages.uploadExpenseFormInProgress.id,
  },
  [AutosaveKey.RECEIPT_CREATE]: {
    action: ActionType.NAVIGATE_TO_CREATE_RECEIPT,
    bodyKey: messages.receiptFormInProgress.id,
  },
};

export const useAutosaveNotifications = () => {
  const { fiscalName } = useSelector(selectCurrentBusiness);

  const createAutosaveNotification = (key: AutosaveKey): UserNotification => {
    const mappedKey = keyMap[key];
    return {
      id: `${key}-notification`,
      createdAt: new Date(),
      updatedAt: new Date(),
      ctaKeys: [
        {
          cta: messages.goToForm.id,
          action: mappedKey.action,
        },
      ],
      payload: {
        type: UserNotificationTypes.documentAutosaved,
      },
      type: UserNotificationTypes.documentAutosaved,
      read: false,
      bodyKey: mappedKey.bodyKey,
      avatarName: fiscalName,
    };
  };
  const { persistedString: autosavedUploadExpense } = usePersistValues({
    name: AutosaveKey.UPLOAD_EXPENSE,
  });
  const { persistedString: autosavedUploadDocument } = usePersistValues({
    name: AutosaveKey.UPLOAD_DOCUMENT,
  });
  const { persistedString: autosavedDocumentCreate } = usePersistValues({
    name: AutosaveKey.DOCUMENTS_CREATE,
  });
  const { persistedString: autosavedQuoteCreate } = usePersistValues({
    name: AutosaveKey.QUOTE_CREATE,
  });
  const { persistedString: autosavedProformaCreate } = usePersistValues({
    name: AutosaveKey.PROFORMA_CREATE,
  });
  const { persistedString: autosavedDeliveryNoteCreate } = usePersistValues({
    name: AutosaveKey.DELIVERY_NOTE_CREATE,
  });
  const { persistedString: autosavedRecurrentExpense } = usePersistValues({
    name: AutosaveKey.CREATE_RECURRENT_EXPENSE,
  });
  const { persistedString: autosavedRecurrentCreate } = usePersistValues({
    name: AutosaveKey.RECURRENTS_CREATE,
  });
  const { persistedString: autosavedReceiptCreate } = usePersistValues({
    name: AutosaveKey.RECEIPT_CREATE,
  });

  const location = useLocation();

  const autosaveNotifications = [
    {
      active: !!autosavedDocumentCreate,
      notification: createAutosaveNotification(AutosaveKey.DOCUMENTS_CREATE),
      hidden: [
        '/documents/create/document-data',
        '/documents/create/review-data',
      ].includes(location.pathname),
    },
    {
      active: !!autosavedRecurrentCreate,
      notification: createAutosaveNotification(AutosaveKey.RECURRENTS_CREATE),
      hidden: [
        '/documents/create/document-data',
        '/documents/create/review-data',
      ].includes(location.pathname),
    },
    {
      active: !!autosavedReceiptCreate,
      notification: createAutosaveNotification(AutosaveKey.RECEIPT_CREATE),
      hidden: ['/documents/tickets/create'].includes(location.pathname),
    },
    {
      active: !!autosavedUploadDocument,
      notification: createAutosaveNotification(AutosaveKey.UPLOAD_DOCUMENT),
      hidden: ['/documents/issued/upload'].includes(location.pathname),
    },
    {
      active: !!autosavedQuoteCreate,
      notification: createAutosaveNotification(AutosaveKey.QUOTE_CREATE),
      hidden: [
        '/quotes/create/document-data',
        '/quotes/create/review-data',
      ].includes(location.pathname),
    },
    {
      active: !!autosavedProformaCreate,
      notification: createAutosaveNotification(AutosaveKey.PROFORMA_CREATE),
      hidden: [
        '/proformas/create/document-data',
        '/proformas/create/review-data',
      ].includes(location.pathname),
    },
    {
      active: !!autosavedDeliveryNoteCreate,
      notification: createAutosaveNotification(
        AutosaveKey.DELIVERY_NOTE_CREATE
      ),
      hidden: [
        '/delivery-notes/create/document-data',
        '/delivery-notes/create/review-data',
      ].includes(location.pathname),
    },
    {
      active: !!autosavedRecurrentExpense,
      notification: createAutosaveNotification(
        AutosaveKey.CREATE_RECURRENT_EXPENSE
      ),
      hidden: ['/documents/expenses/recurrents/create'].includes(
        location.pathname
      ),
    },
    {
      active: !!autosavedUploadExpense,
      notification: createAutosaveNotification(AutosaveKey.UPLOAD_EXPENSE),
      hidden: ['/documents/expenses/upload'].includes(location.pathname),
    },
  ];

  return autosaveNotifications
    .filter(({ active, hidden }) => active && !hidden)
    .map(({ notification }) => notification);
};
