export enum PaymentMethodType {
  BIZUM = 'BIZUM',
  CASH = 'CASH',
  CASH_ON_DELIVERY = 'CASH_ON_DELIVERY',
  DIRECT_DEBIT = 'DIRECT_DEBIT',
  OTHER = 'OTHER',
  TRANSFER = 'TRANSFER',
}

export type TransferType = {
  alias?: string;
  IBAN: string;
  swiftCode?: string;
};

export type BizumType = {
  phoneNumber: string;
};

export type OtherPaymentMethodType = {
  description: string;
};

export const isOtherPaymentMethodType = (
  paymentMethodType: PaymentMethodDataType
): paymentMethodType is OtherPaymentMethodType => {
  return (
    paymentMethodType &&
    'description' in paymentMethodType &&
    typeof paymentMethodType.description === 'string'
  );
};

export type DirectDebitType = {
  IBAN?: string;
};

export type PaymentMethodDataType =
  | TransferType
  | BizumType
  | OtherPaymentMethodType
  | DirectDebitType
  | {};

export interface PaymentMethod {
  type: PaymentMethodType;
  data: PaymentMethodDataType;
  futureDocuments: boolean;
  enable?: boolean;
  contactId?: string;
}

export interface CreatedPaymentMethod extends PaymentMethod {
  id: string;
  forceDisabled?: boolean;
}

export interface CreateContactPaymentMethod
  extends Omit<CreatedPaymentMethod, 'futureDocuments'> {
  id: string;
}

export function isCreatedPaymentMethod(
  pm: PaymentMethod
): pm is CreatedPaymentMethod {
  return (pm as CreatedPaymentMethod).id !== undefined;
}
