export const dniControlLetters = 'TRWAGMYFPDXBNJZSQVHLCKE';
export const companyNifLetters = [
  'J',
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
];
export const nieNumbers = {
  X: 0,
  Y: 1,
  Z: 2,
};
