import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { shouldSendElectronicDocument } from 'modules/Business/businessUtils';
import { selectCurrentBusiness } from 'modules/Business/Current/selectors';
import { Contact } from 'modules/Contacts/models';
import { ROUTES } from 'pages/documents/routesMap';

import { CreateDocumentPayload } from '../models/createDocumentPayload';
import { Document, DocumentKind, DocumentStatus } from '../models/document';
import { CreateReceiptPayload } from '../Receipts/services/createReceipt';
import { UpdateDocumentPayload } from '../Update/services/updateDocument';

export const useBaiSubmissionNavigation = (documentKind?: DocumentKind) => {
  const history = useHistory();
  const business = useSelector(selectCurrentBusiness);
  const shouldSendBai = shouldSendElectronicDocument(business);
  const isTest = documentKind === DocumentKind.TEST;

  const shouldNavigateToBai = (status?: DocumentStatus | string) => {
    if (isTest) {
      return true;
    }
    return shouldSendBai && status === DocumentStatus.ISSUED;
  };

  const actionQuery = ({
    isVoid,
    isFix,
  }: {
    isVoid: boolean;
    isFix: boolean;
  }): string => {
    let action = '';
    if (isVoid) {
      action = `?action=${DocumentStatus.VOID}`;
    }
    if (isFix) {
      action = `?action=FIX`;
    }
    return action;
  };

  const navigateToBai = (
    document:
      | CreateDocumentPayload
      | UpdateDocumentPayload
      | CreateReceiptPayload,
    contact?: Contact,
    isVoid: boolean = false,
    isFix: boolean = false
  ) => {
    const route = isTest
      ? ROUTES.DOCUMENTS_BAI_SUBMISSION_TEST
      : `${ROUTES.DOCUMENTS_BAI_SUBMISSION}${actionQuery({ isVoid, isFix })}`;

    history.push(route, {
      document,
      contact,
    });
  };

  const navigateToBaiReceipt = (document: CreateReceiptPayload) => {
    const route = ROUTES.DOCUMENTS_BAI_RECEIPT_SUBMISSION;

    history.push(route, {
      document,
    });
  };

  const navigateToBaiReceiptResult = (document: Document) => {
    const route = ROUTES.DOCUMENTS_BAI_RECEIPT_SUBMISSION;

    history.replace(route, {
      document,
    });
  };

  const shouldNavigateToBaiResult = (hasDigitalDocument: boolean) => {
    if (isTest) {
      return true;
    }
    return shouldSendBai && hasDigitalDocument;
  };

  const navigateToBaiResult = (
    document: Document,
    isVoid: boolean = false,
    contact?: Contact,
    isFix: boolean = false
  ) => {
    const route = isTest
      ? ROUTES.DOCUMENTS_BAI_SUBMISSION_TEST
      : `${ROUTES.DOCUMENTS_BAI_SUBMISSION}${actionQuery({ isVoid, isFix })}`;

    history.replace(route, {
      document,
      contact,
    });
  };

  return {
    shouldNavigateToBai,
    navigateToBai,
    shouldNavigateToBaiResult,
    navigateToBaiResult,
    navigateToBaiReceipt,
    navigateToBaiReceiptResult,
  };
};
