import useTranslations from 'modules/I18n/hooks/useTranslations';
import { Box, Text } from 'modules/Ui';
import formatDate, { formatDateinXMonths } from 'utils/dates/formatDate';

import { COUPON_DATE_FORMAT } from '../../models';
import { couponTexts } from '../messages';
import { CouponDuration } from '../types';

interface Props {
  duration?: CouponDuration;
  durationInMonths?: number;
  percentOff?: number;
  amountOff?: number;
}

const messagesMap = {
  forever: couponTexts.couponForever,
  once: couponTexts.couponOnce,
  repeating: couponTexts.couponRepeating,
};

const CouponDiscountText: React.FC<Props> = ({
  duration = CouponDuration.once,
  durationInMonths = 0,
  percentOff = 0,
  amountOff = 0,
}) => {
  const { t, currencySymbol } = useTranslations();
  const message = messagesMap[duration];
  const startDate = formatDate(new Date(), COUPON_DATE_FORMAT);
  const endDate =
    durationInMonths &&
    formatDateinXMonths(new Date(), durationInMonths, COUPON_DATE_FORMAT);
  return (
    <Box color="gray800" tag="dt">
      <Text fontSize={12} lineHeight={16} tag="span">
        {t(message, {
          startDate,
          endDate,
          percentOff,
          amountOff: amountOff / 100,
          currencySymbol,
          isPercentage: !!percentOff,
        })}
      </Text>
    </Box>
  );
};

export default CouponDiscountText;
