import { defineMessages } from 'react-intl';

const messages = defineMessages({
  buttonPrimary: { id: 'gofre.Bai.errors.dialog.button.primary' },
  buttonSecondary: { id: 'gofre.Bai.errors.dialog.button.secondary' },
  buttonSecondaryRecurrent: {
    id: 'gofre.Bai.errors.dialog.button.secondary-recurrent',
  },
  errors: { id: 'gofre.Bai.errors.dialog.errors' },
  text: { id: 'gofre.Bai.errors.dialog.text' },
  title: { id: 'gofre.Bai.errors.dialog.title' },
});

export default messages;
