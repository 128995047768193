import { createAsyncAction } from 'typesafe-actions';

import { ActionTypes } from './constants';

export interface FetchAppDataParams {
  silent?: boolean;
  redirectUrl?: string;
  data?: object;
}

export const fetchAppData = createAsyncAction(
  [ActionTypes.FETCH_APP_DATA_REQUEST, (data?: FetchAppDataParams) => data],
  ActionTypes.FETCH_APP_DATA_SUCCESS,
  [ActionTypes.FETCH_APP_DATA_FAILURE, (error: Error) => error]
)();

export const setIsLoading = (payload: {
  isLoading: boolean;
  loadingMessage?: string;
  silent?: boolean;
  step?: number;
  data?: object;
}) => ({
  type: ActionTypes.SET_IS_LOADING,
  payload,
});
