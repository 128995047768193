import useTheme from 'modules/Theme/hooks/useTheme';

import IconByString, { IconByStringProps } from './Icon/IconType/IconByString';

const IconStateDone = (props: IconByStringProps) => {
  const { size, ...rest } = props;
  const theme = useTheme();
  return (
    <IconByString {...rest} size={size || 42} viewBox="0 0 42 42">
      <circle cx="21" cy="21" r="21" fill={theme.colors.confirmation} />
      <path
        clipRule="evenodd"
        d="M28.875 17.523 27.15 15.75l-7.617 7.828-3.808-3.914L14 21.437l5.534 5.688 9.341-9.602Z"
        fill={theme.colors.gray0}
        fillRule="evenodd"
      />
    </IconByString>
  );
};

export default IconStateDone;
