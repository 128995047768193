import IconByPath, { IconByPathProps } from './Icon/IconType/IconByPath';

const IconDocument = (props: IconByPathProps) => (
  <IconByPath
    {...props}
    path="M7.667 21.77 5 23V1l2.667 1.23 2.166-1 2.167 1 2.167-1 2.166 1L19 1v22l-2.667-1.23-2.166 1-2.167-1-2.167 1-2.166-1ZM6 2.562v18.874l1.667-.77 2.166 1 2.167-1 2.167 1 2.166-1 1.667.77V2.563l-1.667.77-2.166-1-2.167 1-2.167-1-2.166 1L6 2.562ZM9 9V8h6v1H9Zm6 3v-1H9v1h6Zm0 3v-1H9v1h6Z"
  />
);

export default IconDocument;
