import { FC, useCallback } from 'react';
import { Hidden } from 'react-grid-system';

import { AxiosResponse } from 'axios';

import { useInvalidateAndUpdate } from 'modules/App/hooks/useInvalidateAndUpdate';
import useTranslations from 'modules/I18n/hooks/useTranslations';
import { useCloseModal, Dialog, MODAL_WIDTH_SMALL } from 'modules/Modals';
import useNotifications from 'modules/Notifications/hooks/useNotifications';
import { useDeletePayments } from 'modules/Payments/hooks/useDeletePayments';
import { invalidateQueries } from 'modules/Payments/services/deletePayments';
import { Button, Text } from 'modules/Ui';
import { useBlockingMutation } from 'utils/useBlockingMutation';

import { messages } from './messages';
import { DeletePaymentsModalProps } from './types';

const DeletePaymentsModal: FC<DeletePaymentsModalProps> = ({
  ids,
  excludeIds,
  onClose,
  onDelete,
}) => {
  const { t } = useTranslations();
  const invalidate = useInvalidateAndUpdate();
  const closeModal = useCloseModal();
  const notifications = useNotifications();
  const deletePayments = useDeletePayments();
  const count = ids.length;
  const { mutate } = useBlockingMutation(deletePayments, {
    onSuccess: () => {
      invalidate({ invalidateQueries, isAsync: true });
      onDelete?.();
      notifications.success(t(messages.success, { count }));
    },
    onError: (error?: {
      response?: AxiosResponse<{
        code?: string;
      }>;
    }) => {
      const message =
        error?.response?.data?.code === 'limit-exceeded'
          ? messages.limitExceeded
          : messages.error;
      notifications.error(t(message, { count }));
    },
  });
  const handleClose = useCallback(() => {
    closeModal();
    onClose?.();
  }, []);

  const cancelButton = (
    <Hidden xs>
      <Button
        data-testid="delete-payments-cancel"
        marginBottom={{ _: '16px', sm: 'initial' }}
        onClick={handleClose}
        variant="stroke"
      >
        {t(messages.cancel)}
      </Button>
    </Hidden>
  );

  const createButton = (
    <Button
      data-testid="delete-payments-submit"
      variant="secondary"
      type="submit"
      onClick={() => {
        mutate({ ids, excludeIds });
        closeModal();
      }}
    >
      {t(messages.confirmText)}
    </Button>
  );

  return (
    <Dialog
      id="delete-payments-modal"
      dialogWidth={{ sm: MODAL_WIDTH_SMALL }}
      title={t(messages.title, { count })}
      subtitle={t(messages.subtitle, { count })}
      onClose={closeModal}
      buttonPrimary={createButton}
      buttonSecondary={cancelButton}
      content={
        <Text lineHeight={22} marginBottom={28}>
          {t(messages.text, { count })}
        </Text>
      }
    />
  );
};

export default DeletePaymentsModal;
