import { getConfig } from 'modules/App/config';
import buildBaseIdsQuery from 'modules/App/queries/buildBaseIdsQuery';
import buildFiltersQuery from 'modules/Documents/helpers/buildFiltersQuery';
import downloadFile from 'utils/downloadFile';
import { getFilenameFromContentDisposition } from 'utils/getFilenameFromContentDisposition';
import { buildTortillasSortQuery } from 'utils/query/buildTortillaBaseQuery';
import request from 'utils/request';

import { PaymentFilters, PaymentSort } from '../models';

interface DownloadExcelParams {
  ids: string[];
  excludeIds: boolean;
  filters?: PaymentFilters;
  sortBy?: PaymentSort;
}

const downloadExcel = async ({
  excludeIds,
  ids = [],
  filters,
  sortBy,
}: DownloadExcelParams) => {
  const endpoint = (await getConfig()).apiPaymentsService;

  let query = buildTortillasSortQuery([
    {
      field: sortBy?.field,
      order: sortBy?.order,
    },
  ]);
  query += buildBaseIdsQuery(ids, excludeIds);

  if (excludeIds && filters) {
    query += buildFiltersQuery({
      ...filters,
      operationDateFrom: filters.dateFrom,
      operationDateTo: filters.dateTo,
    });
  }

  const response = await request<Blob>(`${endpoint}?${query}`, {
    method: 'GET',
    responseType: 'blob',
    headers: {
      Accept:
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
  });

  if (response.status < 200 || response.status >= 400) {
    throw new Error('Error descargando excel');
  }
  downloadFile(
    response.data,
    getFilenameFromContentDisposition(response.headers, 'exportado.xlsx')
  );
};

export default downloadExcel;
