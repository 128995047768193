import { useAsync } from 'react-use';

import { getConfig } from 'modules/App/config';
import { getApiDomain } from 'utils/apiDomain';
import downloadFile from 'utils/downloadFile';
import { getFilenameFromContentDisposition } from 'utils/getFilenameFromContentDisposition';
import request from 'utils/request';

interface Response {
  data: string;
  contentType: string;
  contentLength?: string;
  fileName?: string;
}

const buildPath = async ({
  id,
  hasFile,
  embeddableMode,
  templateName = 'basic',
  updateAt,
  isRecurrent,
}: {
  id: string;
  hasFile?: boolean;
  embeddableMode?: boolean;
  templateName?: string;
  updateAt?: string;
  isRecurrent?: boolean;
}): Promise<string> => {
  const config = await getConfig();
  const printerEndpoint = config.apiPrinterService;
  const documentEndpoint = config.apiDocumentsService;
  const pdfTitle = 'PDF';
  const updateQueryParams = updateAt ? `?updateAt=${updateAt}` : '';
  switch (true) {
    case hasFile:
      return `${documentEndpoint}/${id}/file`;
    case isRecurrent && embeddableMode:
      return `${printerEndpoint}/recurrent/${id}/embedded-pdf/${templateName}/${pdfTitle}${updateQueryParams}`;
    case embeddableMode:
      return `${printerEndpoint}/${id}/embedded-pdf/${templateName}/${pdfTitle}${updateQueryParams}`;
    default:
      return `${printerEndpoint}/${id}/pdf/${templateName}`;
  }
};

export const buildDocumentUrl = async (
  id: string,
  options?: { hasFile?: boolean; updateAt?: string; isRecurrent?: boolean }
): Promise<string> => {
  const { hasFile, updateAt, isRecurrent } = options || {};
  const appConfig = await getConfig();
  const apiDomain = getApiDomain(appConfig);
  return (
    apiDomain +
    (await buildPath({
      id,
      hasFile,
      embeddableMode: true,
      updateAt,
      isRecurrent,
    }))
  );
};

const downloadDocument = async (options: {
  id: string;
  hasFile?: boolean;
  headersOnly?: boolean;
  downloadAttachedFile?: boolean;
}): Promise<Response> => {
  const { id, hasFile, headersOnly, downloadAttachedFile } = options;
  if (!id) {
    return Promise.reject();
  }
  const endpoint = await buildPath({
    id,
    hasFile: downloadAttachedFile && hasFile,
  });
  const response = await request<any>(endpoint, {
    responseType: 'arraybuffer',
    headers: {
      Accept: '*/*',
    },
    ...(headersOnly && { method: 'HEAD' }),
  });

  if (response.status < 200 || response.status >= 400) {
    throw new Error('TODO');
  }

  return {
    data: response.data,
    contentType: response.headers['content-type'],
    contentLength: response.headers['content-length'],
    fileName: getFilenameFromContentDisposition(response.headers, 'attachment'),
  };
};

export const downloadAttachedFile = (id: string) =>
  downloadDocument({
    id,
    hasFile: true,
    headersOnly: false,
    downloadAttachedFile: true,
  }).then(({ data, fileName = 'attachment.pdf', contentType }) => {
    const file = new Blob([data], { type: contentType });
    downloadFile(file, fileName);
  });

export const useAttachedFileMetadata = (id: string) => {
  return useAsync(
    () =>
      downloadDocument({
        id,
        hasFile: true,
        headersOnly: true,
        downloadAttachedFile: true,
      }),
    [id]
  );
};

export default downloadDocument;
