import useTheme from 'modules/Theme/hooks/useTheme';

import IconByString, { IconByStringProps } from './Icon/IconType/IconByString';

export interface IconAddProps extends IconByStringProps {
  iconCrossColor?: string;
}

const IconAddProforma = (props: IconAddProps) => {
  const { iconCrossColor, innerPathColor, ...rest } = props;
  const theme = useTheme();
  return (
    <IconByString {...rest}>
      <path
        d="M14 3a2 2 0 0 1-2 2H8a2 2 0 0 1-2-2H3v19h14V3h-3Zm0-1V1H6v1H2v21h16V2h-4Zm-1 0H7v1a1 1 0 0 0 .883.993L8 4h4a1 1 0 0 0 .993-.883L13 3V2Zm1 13H6v1h8v-1Zm-8 4h8v1H6v-1Z"
        fill="currentColor"
        fillRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M7 9a3 3 0 1 0 6 0 3 3 0 0 0-6 0Zm3-4a4 4 0 1 0 0 8 4 4 0 0 0 0-8Z"
        fill="currentColor"
        shapeRendering="geometricPrecision"
      />
      <circle
        cx="18"
        cy="18"
        fill={innerPathColor ? `${innerPathColor}` : theme.colors.brand500}
        r="6"
        shapeRendering="geometricPrecision"
      />
      <path
        d="M18.514 14.513 17.5 14.5v3h-3v1h3v3h1v-3h3v-1h-3v-3l.014.013z"
        fill={iconCrossColor ? `${iconCrossColor}` : theme.colors.gray0}
        shapeRendering="crispEdges"
      />
    </IconByString>
  );
};

export default IconAddProforma;
