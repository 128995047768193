import getActionStatus from 'modules/Documents/helpers/getActionStatus';
import {
  DocumentSource,
  DocumentType,
  SpecificDocumentSource,
} from 'modules/Documents/models/document';
import {
  DocumentTrackingData,
  Actions,
} from 'modules/Documents/models/documentTracking';
import useTranslations from 'modules/I18n/hooks/useTranslations';
import { Box, Text } from 'modules/Ui';
import { Source } from 'types/source';
import formatDate from 'utils/dates/formatDate';

import messages from './messages';

interface Props extends DocumentTrackingData {
  isLastItem: boolean;
  isMobile: boolean;
  isFirstItem: boolean;
  renderVoidTag: boolean;
  documentType?: DocumentType;
  documentSource?: DocumentSource;
}

const DocumentTrackingRow: React.FC<Props> = ({
  id,
  action,
  actionDate,
  author,
  displayableAuthor,
  isLastItem,
  isMobile,
  isFirstItem,
  renderVoidTag,
  result: actionResult,
  documentType,
  documentSource,
}) => {
  const { t } = useTranslations();
  const actionIsSent =
    action === Actions.SINGLE_DOCUMENT_SENT ||
    action === Actions.SINGLE_DOCUMENT_SENT_BASIC;
  const translationValues: Record<string, string> | undefined =
    actionIsSent && actionResult?.to ? { mail: actionResult.to } : undefined;
  const { actionPerformed, StatusLabelRenderer, status } = getActionStatus(
    action,
    renderVoidTag,
    t,
    translationValues,
    documentType
  );
  const actionAuthorName = [author?.name, author?.surname]
    .filter(Boolean)
    .join(' ');

  const actionAutomaticAuthor =
    documentSource &&
    [SpecificDocumentSource.Shopify, Source.Api].includes(documentSource)
      ? t(messages.automatic)
      : t(messages.scheduled);

  const actionAuthor =
    displayableAuthor ??
    (actionAuthorName === '' ? author?.email : actionAuthorName) ??
    actionAutomaticAuthor;
  const documentDate = formatDate(actionDate);
  return (
    <Box
      aria-label={t(messages.text, {
        action,
        actionPerformed,
        actionAuthor,
        documentDate,
        status,
      })}
      alignItems="center"
      borderBottom={isLastItem ? undefined : '1px solid'}
      borderBottomColor="primary200"
      borderTop={isFirstItem && isMobile ? '1px solid' : undefined}
      borderTopColor="primary200"
      boxSizing="border-box"
      display="grid"
      gridTemplateColumns="1fr auto"
      gap="4px 16px"
      minHeight="75px"
      key={id}
      padding="12px 0"
      tag="li"
    >
      <Text
        color="gray800"
        fontSize={16}
        lineHeight={20}
        tag="span"
        ellipsisLines={2}
      >
        {actionPerformed}
      </Text>
      <Text
        color="gray600"
        fontSize={14}
        lineHeight={22}
        tag="span"
        alignSelf="start"
      >
        {documentDate}
      </Text>
      <Text
        color="gray600"
        fontSize={14}
        hasEllipsis
        lineHeight={16}
        tag="span"
      >
        {actionAuthor}
      </Text>
      <Box tag="span" justifySelf="end">
        <StatusLabelRenderer />
      </Box>
    </Box>
  );
};

export default DocumentTrackingRow;
