import IconByPath, { IconByPathProps } from './Icon/IconType/IconByPath';

const IconQuote = (props: IconByPathProps) => (
  <IconByPath
    {...props}
    fillRule="evenodd"
    path="M15 3a2 2 0 0 1-2 2H9a2 2 0 0 1-2-2H4v19h14V3h-3Zm0-1V1H7v1H3v21h16V2h-4Zm-1 0H8v1a1 1 0 0 0 .883.993L9 4h4a1 1 0 0 0 .993-.883L14 3V2Zm1 13H7v1h8v-1Zm-8 4h8v1H7v-1ZM22 4h-1v19h1V4Zm-8 4H8v3h6V8ZM8 7H7v5h8V7H8Z"
  />
);

export default IconQuote;
