import { History } from 'history';
import { stringify } from 'qs';

import { ContactFilters } from 'modules/Contacts/models';
import { DocumentFilters } from 'modules/Documents/models/document';
import { CurrentTabType } from 'modules/Filters/types';
import { PaymentFilters } from 'modules/Payments';
import { ProductFilters } from 'modules/Products/models';

export function useSaveQueryParamsFilters(history: History) {
  return (
    type: CurrentTabType | null,
    props: {
      event?:
        | DocumentFilters
        | ContactFilters
        | PaymentFilters
        | ProductFilters;
    }
  ) => {
    const { event } = props;
    const documentKind = type && {
      documentKind: type,
    };
    const searchParams = {
      ...documentKind,
      ...event,
    };
    history.push({
      search: stringify(searchParams),
    });
  };
}
