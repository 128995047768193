import {
  DetailedHTMLProps as Props,
  ImgHTMLAttributes as Attributes,
} from 'react';

import {
  background,
  flexbox,
  gridArea,
  space,
  border,
  position,
} from 'styled-system';

import accesibleHide from 'modules/Theme/mixins/accessibleHidden';
import styled from 'modules/Theme/styled-components';
import { BoxProps } from 'modules/Ui/Box/Box';

import HtmlImg from '../Html/Img';

interface ImgProps extends Omit<BoxProps, 'width'> {
  alt?: string;
  ariaHidden?: boolean;
  height?: number | string;
  hide?: boolean;
  loading?: 'auto' | 'eager' | 'lazy';
  objectFit?: 'fill' | 'contain' | 'cover' | 'none' | 'scale-down';
  fetchPriority?: 'high' | 'low' | 'auto';
  decoding?: 'sync' | 'async' | 'auto';
  srcSet?: string;
  width?: number | string;
}

const StyledImage = styled(HtmlImg)<ImgProps>`
  ${background}
  ${border}
  ${flexbox}
  ${gridArea}
  ${position}
  ${space}
  ${({ objectFit }) => objectFit && `object-fit: ${objectFit};`}
  ${({ hide }) => hide && accesibleHide}
`;

const Img = (
  props: ImgProps & Props<Attributes<HTMLImageElement>, HTMLImageElement>
) => {
  const {
    alt = '',
    ariaHidden = true,
    decoding,
    fetchPriority,
    height,
    loading = 'lazy',
    srcSet,
    width,
    ...rest
  } = props;
  return (
    <StyledImage
      alt={alt}
      aria-hidden={ariaHidden}
      decoding={decoding}
      fetchpriority={fetchPriority}
      height={height}
      loading={loading}
      srcSet={srcSet}
      width={width}
      {...rest}
    />
  );
};

export default Img;
