import { ContactAddress } from 'modules/Address/models/address';
import { CreatedPaymentMethod } from 'modules/PaymentMethods/models/paymentMethods';
import { DataTableSort } from 'modules/Ui/DataTable/DataTable.models';
import { Source } from 'types/source';

import { emptySubject, Subject } from '../../Subject/models';
import { TaxKey } from '../../Taxes';

export enum VatNumberType {
  NIF = 'NIF',
  NIF_IVA = 'NIF-IVA',
  PASSPORT = 'PASSPORT',
  ORIGIN = 'ORIGIN',
  CERTIFICATE = 'CERTIFICATE',
  OTHER = 'OTHER',
}

export enum ContactType {
  CUSTOMER = 'CUSTOMER',
  PROVIDER = 'PROVIDER',
  BOTH = 'BOTH',
}

export enum TabContactTypes {
  CUSTOMER = 'CUSTOMER',
  PROVIDER = 'PROVIDER',
}

export type ContactSource = Source;
export interface Contact extends Subject {
  vatNumber?: string;
  address: ContactAddress;
  vatNumberType?: VatNumberType;
  isCustomer?: boolean;
  isProvider?: boolean;
  email?: string;
  phone?: string;
  // paymentMethod: string;
  readyToInvoice?: boolean;
  salesEqTaxCheck?: boolean;
  taxKey?: TaxKey;
  // fake field just for sorting types support, avoid in create/update
  contactType?: unknown;
  // usage fields, only read, avoid in create/update
  lastUsageAt?: Date;
  usages?: number;
  isTest?: boolean;
  lastDocumentIssuedDate?: string;
  owedAmountAsCustomer?: number;
  owedAmountAsProvider?: number;
  billedAmountAsCustomer?: number;
  billedAmountAsProvider?: number;
  billableDocumentsAsCustomer?: number;
  billableDocumentsAsProvider?: number;
  paymentMethods?: CreatedPaymentMethod[];
  source?: ContactSource;
  lastSource?: ContactSource;
}

export type CreateContact = Omit<
  Contact,
  'contactType' | 'lastUsageAt' | 'usages'
>;

export interface ContactCreated extends Contact {
  id: string;
}

export interface ContactForm extends Subject {
  vatNumber?: string;
  vatNumberType: VatNumberType;
  address: ContactAddress;
}

export const emptyContact: Contact = {
  vatNumberType: VatNumberType.NIF,
  taxKey: TaxKey.IVA_21,
  isCustomer: true,
  ...emptySubject,
};

export const emptyProvider: Contact = {
  vatNumberType: VatNumberType.NIF,
  taxKey: TaxKey.IVA_21,
  isProvider: true,
  isCustomer: false,
  ...emptySubject,
};

export interface ContactFilters {
  searchTerm?: string;
  clientType?: ContactType;
  from?: Date;
  to?: Date;
  minAmount?: number | string;
  maxAmount?: number | string;
  onlyDebtors?: boolean;
}

export type ContactsQueryParamsState = {
  [key in TabContactTypes]?: ContactFilters;
};

export type ContactSort = DataTableSort<Contact, keyof Contact>;

export interface ContactReportItem {
  billedAmount: number;
  owedAmount: number;
  billableDocuments: number;
  billableDocumentsNotPaid: number;
  summary?: { category: string; amount: number }[];
  year: number;
  month: number;
}
