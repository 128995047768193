import { AxiosResponse } from 'axios';

import { getConfig } from 'modules/App/config';
import request from 'utils/request';

import { CreatedPaymentMethod } from '../models/paymentMethods';

interface FetchPaymentMethodsResponse {
  items: CreatedPaymentMethod[];
}

const fetchPaymentMethods = async () => {
  const endpoint = (await getConfig()).apiPaymentMethodsService;

  const response = await request<AxiosResponse<FetchPaymentMethodsResponse>>(
    `${endpoint}?limit=100&query[contact]=null`
  );
  return response.data.data;
};

export default fetchPaymentMethods;
