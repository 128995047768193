import { useLocation } from 'react-router';

import { ROUTES as ROUTES_HELP } from 'modules/Help/routesMap';
import useTranslations from 'modules/I18n/hooks/useTranslations';
import { CommercialNotifications } from 'modules/Notifications/components/CommercialNotifications';
import useTheme from 'modules/Theme/hooks/useTheme';
import styled, { css } from 'modules/Theme/styled-components';
import { Box } from 'modules/Ui';
import { IconMenuBalance } from 'modules/Ui/Icons';
import {
  IconMenuExpenses,
  IconMenuHelp,
  IconMenuInvoice,
  IconMenuProducts,
  IconMenuQuote,
  IconMenuUserContact,
  IconNotificationsBadge,
} from 'modules/Ui/Icons/Menu';
import { regexMap, paymentsSectionRegexMap } from 'modules/Ui/Menu/regex';
import { useUserNotifications } from 'modules/UserNotifications/hooks/useUserNotifications';
import { ROUTES as ROUTES_NOTIFICATIONS } from 'modules/UserNotifications/routesMap';
import { ROUTES } from 'pages/documents/routesMap';
import { PAYMENT_ROUTES } from 'pages/payments/routesMap';
import { ROUTES as ROUTES_QUOTES } from 'pages/quotes/routesMap';

import MenuLink from '../../Components/MenuLink';
import { menu } from '../../messages';
import Expandable from '../Components/Expandable';
import MenuWrapper from '../MenuWrapper';

const bookkeeperBand = css`
  &:before {
    background-color: ${(props) => props.theme.colors.brand500};
    content: "";
    height: 4px;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
`;

export const StyledMenu = styled(Box)`
  ${({ showBookkeeper }) => showBookkeeper && bookkeeperBand}
`;

const Menu = () => {
  const location = useLocation();
  const { pathname } = location;
  const { data: notificationResponse } = useUserNotifications();
  const notificationLength = notificationResponse?.unread ?? 0;
  const { t } = useTranslations();
  const theme = useTheme();
  const isPaymentsActive =
    paymentsSectionRegexMap.payments.test(pathname) ||
    paymentsSectionRegexMap.banks.test(pathname) ||
    paymentsSectionRegexMap.remittances.test(pathname);
  const url = location.pathname;
  const isHomePage = url === '/';
  return (
    <MenuWrapper
      links={
        <>
          <MenuLink
            active={regexMap.documents.test(pathname)}
            icon={
              <IconMenuInvoice active={regexMap.documents.test(pathname)} />
            }
            text={t(menu.documents)}
            to={ROUTES.DOCUMENTS}
            data-testid="navbar-documents"
          />
          <MenuLink
            active={regexMap.quotes.test(pathname)}
            icon={<IconMenuQuote active={regexMap.quotes.test(pathname)} />}
            text={t(menu.quotes)}
            to={ROUTES_QUOTES.MAIN_PATH}
            data-testid="navbar-quotes"
          />
          <MenuLink
            active={regexMap.expenses.test(pathname)}
            icon={
              <IconMenuExpenses active={regexMap.expenses.test(pathname)} />
            }
            text={t(menu.expenses)}
            to={ROUTES.DOCUMENTS_EXPENSES}
            data-testid="navbar-documents-expenses"
          />
          <MenuLink
            active={regexMap.contacts.test(pathname)}
            icon={
              <IconMenuUserContact active={regexMap.contacts.test(pathname)} />
            }
            text={t(menu.contacts)}
            to={ROUTES.CONTACTS}
            data-testid="navbar-contacts"
          />
          <MenuLink
            active={regexMap.products.test(pathname)}
            icon={
              <IconMenuProducts active={regexMap.products.test(pathname)} />
            }
            text={t(menu.products)}
            to={ROUTES.PRODUCTS}
            data-testid="navbar-products"
          />

          <MenuLink
            active={isPaymentsActive}
            data-testid="navbar-payments"
            icon={<IconMenuBalance active={isPaymentsActive} />}
            text={t(menu.payments)}
            to={PAYMENT_ROUTES.LIST}
          />
        </>
      }
      notificationLength={notificationLength}
      iconedLinks={
        <>
          <MenuLink
            active={regexMap.help.test(pathname)}
            color={theme.colors.brand500}
            icon={<IconMenuHelp active={regexMap.help.test(pathname)} />}
            onlyIcon={true}
            text={t(menu.help)}
            to={ROUTES_HELP.MAIN_PATH}
            rel="help"
          />
          <MenuLink
            active={regexMap.notifications.test(pathname)}
            color={theme.colors.brand500}
            icon={
              <IconNotificationsBadge
                number={notificationLength}
                active={regexMap.notifications.test(pathname)}
              />
            }
            onlyIcon={true}
            text={t(menu.notifications)}
            to={ROUTES_NOTIFICATIONS.NOTIFICATIONS}
            commercialNotification={isHomePage && <CommercialNotifications />}
          />
          <Expandable />
        </>
      }
    />
  );
};

export default Menu;
