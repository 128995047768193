export const dniRegex = /^(\d{8})([A-Z])$/i;
export const nifRegex =
  // eslint-disable-next-line max-len
  /^(([a-z|A-Z]{1}\d{7}[a-z|A-Z]{1})|(\d{8}[a-z|A-Z]{1})|([a-z|A-Z]{1}\d{8}))$/i;
export const companyRegex = /^([ABCDEFGHJKLMNPQRSUVW])(\d{7})([0-9A-J])$/i;
export const notPersonalCompanyRegex =
  /^([ABCDFGHKLMNPQRSUVW])(\d{7})([0-9A-J])$/i;
export const publicEntityOrOtherRegex = /^([PQSV])(\d{7})([0-9A-J])$/i;
export const nieRegex = /^[XYZ]\d{7}[A-Z]$/i;
export const civilSocietyRegex = /^(J)(\d{7})([0-9A-J])$/i;
export const goodsCommunityRegex = /^(E)(\d{7})([0-9A-J])$/i;
