import { FC } from 'react';

import useTranslations from 'modules/I18n/hooks/useTranslations';
import { Dialog, useCloseModal } from 'modules/Modals';
import { Button } from 'modules/Ui';

import messages from './messages';
import { InfoModalProps } from './types';

const InfoModal: FC<InfoModalProps> = ({
  confirmText,
  id,
  subtitle,
  text,
  title,
}) => {
  const { t } = useTranslations();
  const closeModal = useCloseModal();

  return (
    <Dialog
      title={title}
      onClose={closeModal}
      id={id}
      subtitle={subtitle}
      buttonPrimary={
        <Button
          data-testid={`${id}-submit`}
          variant="secondary"
          onClick={closeModal}
        >
          {confirmText ?? t(messages.confirm)}
        </Button>
      }
    >
      {text}
    </Dialog>
  );
};

export default InfoModal;
