import { FC } from 'react';

import useTranslations from 'modules/I18n/hooks/useTranslations';
import { Dialog, useCloseModal } from 'modules/Modals';
import { zeroState } from 'modules/Products/AddProductDialog/messages';
import { Box, Img, Button, Text } from 'modules/Ui';

export const ProductsZeroStateDialog: FC = () => {
  const { t } = useTranslations();
  const closeModal = useCloseModal();
  return (
    <Dialog
      id="products-zero-state-dialog"
      onClose={closeModal}
      buttonPrimary={
        <Button
          data-testid="products-zero-state-dialog-test"
          variant="secondary"
          onClick={closeModal}
        >
          {t(zeroState.button)}
        </Button>
      }
    >
      <Box textAlign="center">
        <Img
          ariaHidden
          height={130}
          loading="lazy"
          marginBottom="24px"
          src="/assets/empty-products.svg"
          width={120}
        />
        <Text marginBottom="16px">{t(zeroState.text01)}</Text>
        <Text>{t(zeroState.text02)}</Text>
      </Box>
    </Dialog>
  );
};
