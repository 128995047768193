import { getConfig } from 'modules/App/config';
import buildBaseIdsQuery from 'modules/App/queries/buildBaseIdsQuery';
import UserInvitation from 'modules/UserInvitations/models/UserInvitation';
import request from 'utils/request';

interface Response {
  data: UserInvitation;
}

interface ResendBookkeeperInvitationParams {
  ids: string[];
  excludeIds: boolean;
}

const resendBookkeeperInvitation = async (
  params: ResendBookkeeperInvitationParams
) => {
  const config = await getConfig();
  const { excludeIds, ids } = params;

  const query = buildBaseIdsQuery(ids, excludeIds);

  const endpoint = `${config.apiUserInvitationsService}/resend`;
  const response = await request<Response>(`${endpoint}?${query}`, {
    method: 'POST',
  });
  return response.data.data;
};

export default resendBookkeeperInvitation;
