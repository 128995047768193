import { useQuery, useQueryClient } from 'react-query';

import { DEFAULT_VALUES_QUERY } from 'modules/App/queries/queries';
import { getCurrentBusinessId } from 'modules/Auth/currentBusinessUtil';
import {
  DefaultDocumentValuesResource,
  DefaultValuesResource,
} from 'modules/DefaultValues/enums';
import { DefaultValuesItem } from 'modules/DefaultValues/models/default-values-item';
import { DocumentDefaultValues } from 'modules/DefaultValues/models/document-default-values';
import { ProductStockDefaultValues } from 'modules/DefaultValues/models/product-stock-default-values';
import fetchAllDefaultValues from 'modules/DefaultValues/services/fetchAllDefaultValues';
import CacheTimes from 'utils/query/cacheTimes';

export default function useDefaultValues(businessId?: string) {
  const currentBusinessId = getCurrentBusinessId();
  const relevantBusinessId = businessId || currentBusinessId;
  const { data, isFetching } = useQuery(
    [DEFAULT_VALUES_QUERY, relevantBusinessId],
    () =>
      fetchAllDefaultValues({
        businessId: relevantBusinessId,
      }),
    {
      staleTime: CacheTimes.ONE_HOUR,
      refetchOnMount: false,
    }
  );

  const resourceDefaultValues = (
    resource: DefaultDocumentValuesResource
  ): DocumentDefaultValues | undefined => {
    const defaultValues = data?.items.find(
      (item) => item.resource === resource
    );
    if (
      defaultValues &&
      defaultValues.resource !== DefaultValuesResource.Product
    ) {
      return defaultValues.data;
    }
    return undefined;
  };

  const productsDefaultValues = (): ProductStockDefaultValues | undefined => {
    const defaultValues = data?.items.find(
      (item) => item.resource === DefaultValuesResource.Product
    );
    if (
      defaultValues &&
      defaultValues.resource === DefaultValuesResource.Product
    ) {
      return defaultValues.data;
    }
    return undefined;
  };

  const queryClient = useQueryClient();
  const setQueryData = (values: DefaultValuesItem[]) => {
    const safeData = data || { items: [] };
    const oldResources = safeData.items.map((xs) => xs.resource);

    const updatedItems = safeData.items.map((item) => {
      const newDefaultValues = values.find(
        (newValue) => newValue.resource === item.resource
      );
      return newDefaultValues || item;
    });

    const newItems = values.filter((xs) => !oldResources.includes(xs.resource));
    const items = [...newItems, ...updatedItems];
    queryClient.setQueryData([DEFAULT_VALUES_QUERY, relevantBusinessId], {
      count: items.length,
      items,
    });
  };

  const getCorrectResource = ({
    isProforma,
    isQuote,
    isReceipt,
    isDeliveryNote,
  }: {
    isProforma?: boolean;
    isQuote?: boolean;
    isReceipt?: boolean;
    isDeliveryNote?: boolean;
  }): DefaultDocumentValuesResource => {
    if (isProforma) {
      return DefaultValuesResource.Proforma;
    }
    if (isQuote) {
      return DefaultValuesResource.Quote;
    }
    if (isReceipt) {
      return DefaultValuesResource.Receipt;
    }
    if (isDeliveryNote) {
      return DefaultValuesResource.DeliveryNote;
    }

    return DefaultValuesResource.Invoice;
  };
  return {
    data: data?.items,
    isFetching,
    resourceDefaultValues,
    setQueryData,
    getCorrectResource,
    productsDefaultValues,
  };
}
