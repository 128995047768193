import { FC, ReactNode, useState } from 'react';

import { space, SpaceProps, background, BackgroundProps } from 'styled-system';

import { AnalyticsEvent } from 'modules/Analytics';
import analyticsService from 'modules/Analytics/services/analyticsService';
import useTranslations from 'modules/I18n/hooks/useTranslations';
import useTheme from 'modules/Theme/hooks/useTheme';
import styled from 'modules/Theme/styled-components';
import { Box, Link, Button } from 'modules/Ui';
import { IconCross } from 'modules/Ui/Icons';

import FadeOutContainer from '../../FadeOutContainer';
import BannerContent from './components/BannerContent';
import messages from './messages';

interface NewFeatureBannerProps extends SpaceProps, BackgroundProps {
  children?: ReactNode;
  image?: string;
  isNew?: boolean;
  onClick?: () => void;
  onClose?: () => void;
  to?: string;
  event?: AnalyticsEvent;
}

const StyledLink = styled(Link)`
  ${space}
  ${background}
  @media (hover: hover) {
    &:hover {
      .link__subtitle {
        text-decoration: underline;
      }
    }
  }
  &:focus {
    .link__subtitle {
      text-decoration: underline;
    }
  }
`;

const StyledWrapper = styled(Box)`
  ${space}
  ${background}
`;

const NewFeatureBanner: FC<NewFeatureBannerProps> = ({
  children,
  image,
  isNew,
  onClick,
  onClose,
  to,
  event,
  ...rest
}) => {
  const [hideElement, setHideElement] = useState(false);
  const theme = useTheme();
  const { t } = useTranslations();
  const onClickHandler = () => {
    if (onClick) {
      onClick();
    }
    if (event) {
      analyticsService.track(event);
    }
  };
  const hideOnClick = () => {
    setHideElement(true);
  };
  if (hideElement) {
    return (
      <FadeOutContainer
        background={theme.colors.auxiliary100}
        border={`1px solid ${theme.colors.gray0}`}
        initialHeight="100px"
      />
    );
  }
  return (
    <>
      {to ? (
        <StyledWrapper position="relative" {...rest}>
          <StyledLink
            display="grid"
            gap="4px 12px"
            gridTemplateColumns="minmax(83px, auto) 1fr"
            onClick={onClickHandler}
            padding="8px 8px 8px 4px"
            to={to}
          >
            <BannerContent
              to={to}
              image={image}
              isNew={isNew}
              onClose={onClose}
            >
              {children}
            </BannerContent>
          </StyledLink>
          <Button
            accessibleText={t(messages.close)}
            backgroundColor="transparent"
            onClick={hideOnClick}
            position="absolute"
            right="8px"
            top="8px"
          >
            <IconCross size={20} />
          </Button>
        </StyledWrapper>
      ) : (
        <StyledWrapper
          display="grid"
          gap="4px 12px"
          gridTemplateColumns="minmax(83px, auto) 1fr"
          padding="8px 8px 8px 4px"
          {...rest}
        >
          <BannerContent image={image} isNew={isNew} onClose={hideOnClick}>
            {children}
          </BannerContent>
        </StyledWrapper>
      )}
    </>
  );
};

StyledWrapper.defaultProps = {
  backgroundColor: 'auxiliary100',
};

export default NewFeatureBanner;
