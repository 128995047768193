import useTranslations from 'modules/I18n/hooks/useTranslations';
import { Box } from 'modules/Ui';

import { advantages as messages } from '../../../messages';
import { AdvantageItem } from './AdvantageItem';

export const Advantages = () => {
  const { t } = useTranslations();
  return (
    <Box
      display="grid"
      gap={{ _: '40px 0', md: '0 48px' }}
      gridTemplateColumns={{ md: 'repeat(3, 1fr)' }}
      margin={{ _: '0 16px 86px', sm: '0 0 48px', md: '0 0 80px' }}
      tag="ul"
    >
      <AdvantageItem
        description={t(messages.saveTimeDesc)}
        imgageSrc="/assets/plansInfoTime.svg"
        title={t(messages.saveTime)}
      />
      <AdvantageItem
        description={t(messages.flexibleDesc)}
        imgageSrc="/assets/plansInfoFlexible.svg"
        title={t(messages.flexible)}
      />
      <AdvantageItem
        description={t(messages.supportDesc)}
        imgageSrc="/assets/plansInfoSupport.svg"
        title={t(messages.support)}
      />
    </Box>
  );
};
