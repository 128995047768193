import { defineMessages } from 'react-intl';

export const recurrentExpenses = defineMessages({
  title: { id: 'gofre.recurrentExpenses.title' },
  ctaAction: { id: 'gofre.recurrentExpenses.ctaAction' },
});

export const updateRecurrentExpenses = defineMessages({
  title: { id: 'gofre.recurrentExpenses.update.title' },
  ctaAction: { id: 'gofre.recurrentExpenses.update.ctaAction' },
});

export const copyRecurrentExpenses = defineMessages({
  title: { id: 'gofre.recurrentExpenses.copy.title' },
});

export const createRecurrent = defineMessages({
  mandatory: { id: 'gofre.createRecurrent.mandatory' },
});

export const createRecurrentSeries = defineMessages({
  assignment: { id: 'gofre.createRecurrent.assignment.label' },
  assignmentPlaceholder: { id: 'gofre.createRecurrent.assignment.placeholder' },
  serie: { id: 'gofre.createRecurrent.serie.label' },
  serieInfo: { id: 'gofre.createRecurrent.serie.info' },
  toogle: { id: 'gofre.createRecurrent.toogle.label' },
  warning01: { id: 'gofre.createRecurrent.warning.text01' },
  warning02: { id: 'gofre.createRecurrent.warning.text02' },
});

export const dialogErrorPlan = defineMessages({
  title: { id: 'gofre.createRecurrent.dialog.errorPlan.title' },
  text: { id: 'gofre.createRecurrent.dialog.errorPlan.text' },
  button: { id: 'gofre.createRecurrent.dialog.errorPlan.button' },
});
