import DocumentsZeroState from 'modules/Documents/List/components/DocumentsZeroState';
import useTranslations from 'modules/I18n/hooks/useTranslations';

import { zeroStateDocuments } from '../messages';

export const RemittanceDocumentsZeroState = () => {
  const { t } = useTranslations();
  return (
    <DocumentsZeroState
      subtitle={t(zeroStateDocuments.subtitle)}
      title={t(zeroStateDocuments.title)}
      link={t(zeroStateDocuments.link)}
      showCTA={false}
    />
  );
};
