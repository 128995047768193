import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import {
  useStripe,
  useElements,
  CardNumberElement,
} from '@stripe/react-stripe-js';
import { Stripe, StripeCardNumberElement } from '@stripe/stripe-js';

import analyticsService from 'modules/Analytics/services/analyticsService';
import { fetchAppData, setIsLoading } from 'modules/App/actions';
import { setScopes } from 'modules/Auth/actions';
import authBusiness from 'modules/Auth/services/authBusiness';
import { selectCurrentBusiness } from 'modules/Business/Current/selectors';
import useTranslations from 'modules/I18n/hooks/useTranslations';
import { createNotification } from 'modules/Notifications/actions';
import { NotificationType } from 'modules/Notifications/models';
import { payment as texts } from 'modules/Subscriptions/Create/messages';
import createSubscription, {
  CreateSubscriptionData,
} from 'modules/Subscriptions/Create/services/createSubscription';
import fetchSubscriptionStatus, {
  SubscriptionStatus,
} from 'modules/Subscriptions/Create/services/fetchSubscriptionStatus';
import { Coupon } from 'modules/Subscriptions/Create/types';
import { StripeFormError } from 'modules/Subscriptions/models/subscription';
import { ROUTES } from 'modules/Subscriptions/routesMap';
import requestErrorHandler from 'utils/requestErrorHandler';
import { requestPoll } from 'utils/requestPoll';

import {
  ERROR_CODE_SUBSCRIPTIONS_EXIST,
  ERROR_CODE_PAYMENT_METHOD_FAILED,
  PAYMENT_TYPE_CARD,
  ERROR_CODE_CREATE_CUSTOMER_FAILED,
  ERROR_DESCRIPTOR_CREATE_CUSTOMER_FAILED,
  ERROR_FIND_COUPON_FAILED,
  OPERATION_NOT_ALLOWED,
} from './constants';
import {
  isPaymentSuccess,
  isSubscriptionActive,
  requiresActionOrPaymentMenthod,
} from './helpers';
import { StripeInvoice, StripeSubscription } from './types';

let stripe: Stripe | null;

export const handlePaymentThatRequiresCustomerAction = async ({
  subscription,
  invoice,
  paymentMethodId,
  isRetry = false,
}: {
  subscription: StripeSubscription;
  invoice: StripeInvoice;
  paymentMethodId: string;
  isRetry: boolean;
}) => {
  if (isSubscriptionActive(subscription.status)) {
    // Subscription is active, no customer actions required.
    return { subscription, paymentMethodId };
  }

  const paymentIntent = invoice.payment_intent;

  if (requiresActionOrPaymentMenthod(paymentIntent, isRetry)) {
    if (!paymentIntent.client_secret) throw new Error();
    // Call stripe sdk to require customer action
    const result = await (stripe as Stripe).confirmCardPayment(
      paymentIntent.client_secret,
      {
        payment_method: paymentMethodId,
      }
    );

    if (isPaymentSuccess(result)) {
      // Show a success message to your customer.
      // There's a risk of the customer closing the window before the callback.
      // We recommend setting up webhook endpoints later in this guide.
      return {
        subscription,
        invoice,
        paymentMethodId,
      };
    }

    throw new Error(result?.error?.message);
  } else {
    // No customer action needed.
    return { subscription, paymentMethodId };
  }
};

const onSubscriptionComplete = async (stripeSubscriptionId: string) => {
  async function validateAsyncCondition() {
    const { status } = await fetchSubscriptionStatus({ stripeSubscriptionId });
    return status === SubscriptionStatus.OK;
  }
  // End of the create subscription logic
  // Wait for subscription status and proceed to payment success screen
  await requestPoll({ validateAsyncCondition });
};

const createBillinSubscription = async (
  paymentMethodId: string,
  subscriptionProductId: string,
  billingEmail: string,
  coupon?: Coupon
) => {
  const subscription = (await createSubscription({
    paymentMethodId,
    subscriptionProductId,
    billingEmail,
    coupon: coupon?.promotionCode,
  })) as CreateSubscriptionData;

  const { latest_invoice: invoice, id } = subscription;
  const result = {
    // Use the Stripe 'object' property on the
    // returned result to understand what object is returned.
    invoice,
    paymentMethodId,
    subscription,
    isRetry: true,
  };

  await handlePaymentThatRequiresCustomerAction(result);

  await onSubscriptionComplete(id);

  analyticsService.orderComplete(subscriptionProductId, id, coupon);
};

export const useCreateStripeSubscription = () => {
  stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslations();
  const { id: currentBusinessId } = useSelector(selectCurrentBusiness);

  const handleSubmit = async (
    subscriptionProductId: string,
    billingEmail: string,
    coupon?: Coupon
  ) => {
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return false;
    }
    // Get card data from stripe form
    const card: StripeCardNumberElement | null =
      elements.getElement(CardNumberElement);
    if (!card) return false;

    // Calls stripe to create payment method
    const { error: stripeError, paymentMethod } =
      await stripe.createPaymentMethod({
        type: PAYMENT_TYPE_CARD,
        card,
      });

    if (stripeError) {
      throw new StripeFormError(stripeError);
    }
    if (!paymentMethod?.id) {
      throw new Error();
    }
    try {
      dispatch(
        setIsLoading({
          isLoading: true, // it will be set to false when app data is fetched
          loadingMessage: t(texts.processing),
        })
      );
      await createBillinSubscription(
        paymentMethod.id,
        subscriptionProductId,
        billingEmail,
        coupon
      );
      // Get new assets
      const auth = await authBusiness(currentBusinessId as string);
      dispatch(setScopes(auth.scopes));
      dispatch(
        fetchAppData.request({
          silent: true,
          redirectUrl: ROUTES.SUBSCRIPTIONS_PAYMENT_RESULT,
        })
      );
      return true;
    } catch (e: any) {
      const {
        responseCode,
        responseDeveloperDescriptor,
        isNetworkError,
        networkErrorTranslationKey,
      } = requestErrorHandler(e);

      // If the user has any active subscription in Stripe we don't let
      // the user that make a new payment. Refresh my business and subscriptions
      // data and go to my subscription
      dispatch(
        setIsLoading({
          step: 1,
          isLoading: false,
        })
      );
      if (responseCode === ERROR_CODE_SUBSCRIPTIONS_EXIST) {
        dispatch(fetchAppData.request());
        history.push(ROUTES.SUBSCRIPTIONS);
        dispatch(
          createNotification({
            type: NotificationType.ERROR,
            message: texts.subscriptionsExist.id,
          })
        );
        throw new Error(t(texts.subscriptionsExist));
      } else if (responseCode === ERROR_CODE_PAYMENT_METHOD_FAILED) {
        dispatch(
          createNotification({
            type: NotificationType.ERROR,
            message: texts.cardError.id,
          })
        );
        throw new Error(t(texts.cardError));
      } else if (
        responseCode === ERROR_CODE_CREATE_CUSTOMER_FAILED &&
        responseDeveloperDescriptor === ERROR_DESCRIPTOR_CREATE_CUSTOMER_FAILED
      ) {
        dispatch(
          createNotification({
            type: NotificationType.ERROR,
            message: texts.vatNumberError.id,
          })
        );
        throw new Error(t(texts.vatNumberError));
      } else if (responseCode === ERROR_FIND_COUPON_FAILED) {
        dispatch(
          createNotification({
            type: NotificationType.ERROR,
            message: texts.findCouponError.id,
          })
        );
        throw new Error(t(texts.findCouponError));
      } else if (responseCode === OPERATION_NOT_ALLOWED) {
        dispatch(
          createNotification({
            type: NotificationType.ERROR,
            message: texts.planNotAllowed.id,
          })
        );
        throw new Error(t(texts.planNotAllowed));
      } else {
        dispatch(
          createNotification({
            type: NotificationType.ERROR,
            message: isNetworkError
              ? networkErrorTranslationKey
              : texts.genericError.id,
          })
        );
        throw new Error(t(texts.genericError));
      }
    }
  };

  return handleSubmit;
};
