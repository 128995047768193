import { FC } from 'react';
import { Visible } from 'react-grid-system';

import { breakpointGutters } from 'modules/Theme/breakpoints';
import useTheme from 'modules/Theme/hooks/useTheme';
import { Box, Hr } from 'modules/Ui';

interface Props {
  actions?: React.ReactNode;
  actionsNoStyle?: boolean;
  mainColumn: React.ReactNode;
  mainColumnBackground?: object;
  mainColumnBorder?: string;
  mainWithoutPadding?: boolean;
  pageSteps?: React.ReactNode;
}

const PageLayoutMain: FC<Props> = (props: Props) => {
  const {
    actions,
    actionsNoStyle,
    mainColumn,
    mainColumnBackground,
    mainColumnBorder,
    mainWithoutPadding,
    pageSteps,
  } = props;
  const theme = useTheme();
  return (
    <>
      <Box
        backgroundColor={mainColumnBackground || { sm: 'gray0' }}
        borderTop={{ _: mainColumnBorder, sm: 'none' }}
        border={{
          sm: mainColumnBorder || `1px solid ${theme.colors.primary100}`,
        }}
        gridArea={pageSteps && { sm: '2 / 1 / 3 / 3', md: 'initial' }}
        padding={
          !mainWithoutPadding
            ? {
                _: `24px ${breakpointGutters.xs}px`,
                sm: `40px ${breakpointGutters.xl}px 32px`,
              }
            : undefined
        }
      >
        {mainColumn}
        {actions ? (
          <Visible sm md lg xl xxl>
            <Hr
              color={theme.colors.primary200}
              margin={{ _: '0 -16px 12px', sm: '0 -32px 16px' }}
              width={{ _: 'calc(100% + 32px)', sm: 'calc(100% + 64px)' }}
            />
            <Box
              alignItems={!actionsNoStyle && 'center'}
              contain={{ _: 'content', sm: 'initial' }}
              display={!actionsNoStyle && 'flex'}
              flexDirection={!actionsNoStyle && 'row'}
              flexWrap={!actionsNoStyle && 'wrap'}
              justifyContent={!actionsNoStyle && 'space-between'}
            >
              {actions}
            </Box>
          </Visible>
        ) : undefined}
      </Box>
    </>
  );
};

export default PageLayoutMain;
