import { FC, useCallback } from 'react';

import { useInvalidateAndUpdate } from 'modules/App/hooks/useInvalidateAndUpdate';
import useTranslations from 'modules/I18n/hooks/useTranslations';
import { useCloseModal, Dialog } from 'modules/Modals';
import useNotifications from 'modules/Notifications/hooks/useNotifications';
import { Button, Text } from 'modules/Ui';
import { useBlockingMutation } from 'utils/useBlockingMutation';

import deletePendingDocuments, {
  invalidateQueries,
} from '../../../List/services/deletePendingDocuments';
import messages from './messages';
import { DeleteMultiplePendingDocumentsModalProps as Props } from './type';

const DeleteMultiplePendingDocumentsModal: FC<Props> = ({
  ids,
  inverted,
  resetSelection,
}) => {
  const closeModal = useCloseModal();
  const { t } = useTranslations();
  const notifications = useNotifications();
  const invalidateAndUpdate = useInvalidateAndUpdate();

  const { mutate } = useBlockingMutation(deletePendingDocuments, {
    onSuccess: () => {
      invalidateAndUpdate({ invalidateQueries });
      closeModal();
    },
    onError: () => {
      notifications.error(t(messages.errorDeleting));
      closeModal();
    },
  });

  const onDelete = useCallback(() => {
    mutate({ excludeIds: inverted, ids });
    resetSelection();
    closeModal();
  }, [ids, inverted, resetSelection, closeModal]);

  return (
    <Dialog
      id="delete-pending-document"
      title={t(messages.title)}
      subtitle={t(messages.subtitle)}
      onClose={closeModal}
      buttonPrimary={
        <Button variant="secondary" onClick={onDelete}>
          {t(messages.title)}
        </Button>
      }
      buttonSecondary={
        <Button variant="stroke" type="button" onClick={closeModal}>
          {t(messages.cancel)}
        </Button>
      }
    >
      <Text fontSize={16} lineHeight={22} color="gray800">
        {t(messages.text)}
      </Text>
    </Dialog>
  );
};

export default DeleteMultiplePendingDocumentsModal;
