import { IBrowser } from 'redux-responsive/types';

import { Address } from 'modules/Address';
import { getConfig } from 'modules/App/config';
import { getRegisteredFrom } from 'modules/Business/businessUtils';
import Business from 'modules/Business/models/business';
import cleanVatNumber from 'utils/cleanVatNumber';
import request from 'utils/request';
import { RequestErrorHandled } from 'utils/requestErrorHandler';

interface Params {
  payload: CreateBusinessArguments;
  browser: IBrowser;
}

export interface CreateBusinessArguments {
  fiscalName: string;
  vatNumber: string;
  phoneNumber?: string;
  address: Address;
}

const createBusiness = async ({
  payload,
  browser,
}: Params): Promise<Business> => {
  const device = getRegisteredFrom(browser);
  const args = {
    ...payload,
    vatNumber: cleanVatNumber(payload.vatNumber),
    registeredFrom: device,
  };
  const endpoint = (await getConfig()).apiBusinessesService;
  const response = await request<any>(endpoint, {
    method: 'POST',
    data: args,
  });

  return response.data.data;
};

export function isDuplicatedVatNumberOnPostBusiness({
  errorMessage,
  responseCode,
}: RequestErrorHandled): boolean {
  return (
    errorMessage === 'duplicatedVatNumberError' ||
    responseCode === 'duplicate-vat-number'
  );
}

export default createBusiness;
