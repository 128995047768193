import IconByPath, { IconByPathProps } from './Icon/IconType/IconByPath';

const IconPrint = (props: IconByPathProps) => (
  <IconByPath
    {...props}
    clipRule="evenodd"
    fillRule="evenodd"
    path="M17 2H7v7h10V2Zm1 7V1H6v8a5 5 0 0 0-5 5v6h4v2h14v-2h4v-6a5 5 0 0 0-5-5Zm1 10h3v-5a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v5h3v-4h14v4Zm-1-3H6v5h12v-5Z"
  />
);

export default IconPrint;
