import { ReactNode, FormEvent, ChangeEvent } from 'react';

import { useField } from 'formik';
import {
  FlexboxProps,
  GridAreaProps,
  LayoutProps,
  SpaceProps,
} from 'styled-system';

import Checkbox from '../Form/Checkbox';
import useFocusHandler from './useFocusHandler';

interface CheckFormikFieldProps
  extends LayoutProps,
    SpaceProps,
    FlexboxProps,
    GridAreaProps {
  checked?: boolean;
  disabled?: boolean;
  id: string;
  info?: ReactNode;
  label: string;
  labelHidden?: boolean;
  name?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onClick?: (e: FormEvent<HTMLInputElement>) => void;
  required?: boolean;
  size?: number;
  value?: string;
  hasEllipsis?: boolean;
  tooltip?: string | ReactNode;
}

const CheckFormikField = (props: CheckFormikFieldProps) => {
  const { tooltip, size, id, name, ...rest } = props;
  const [field, , helpers] = useField({ name: name || id, type: 'checkbox' });
  const onFocusHandler = useFocusHandler(name || id);

  const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    field.onChange(e);
    if (props.onChange) {
      props.onChange(e);
    }
    helpers.setTouched(true);
  };
  return (
    <Checkbox
      tooltip={tooltip}
      {...{ size, id, name, ...rest }}
      {...field}
      onFocus={onFocusHandler}
      onChange={onChangeHandler}
    />
  );
};

export default CheckFormikField;
