import { parseNumber } from 'utils/MathOperations';

import { Concept } from '../models/document';
import { normalizeConcept } from './normalize-concept';
import {
  calculateConceptValuesFromTotal,
  calculateTotalFromUniqueConcept,
  translateConceptToTortilla,
} from './shared-calcs';

export const calculateTotal = (
  concept: Concept,
  hasSalesEqualizationTax: boolean
): number => {
  const { totalAmount } = concept;
  const tortillaConcept = translateConceptToTortilla(
    concept,
    hasSalesEqualizationTax
  );
  if (totalAmount) {
    const { subtotal, taxAmount } =
      calculateConceptValuesFromTotal(tortillaConcept);
    return parseNumber(subtotal + taxAmount);
  }
  const { currentSubtotal: subtotal, currentTaxAmount: taxAmount } =
    calculateTotalFromUniqueConcept(tortillaConcept);

  return parseNumber(subtotal + taxAmount);
};

export const calculateTotalFromUnitPrice = (
  concept: Concept,
  unitPrice?: number,
  hasSalesEqualizationTax = false
): number => {
  const tortillaConcept = translateConceptToTortilla(
    concept,
    hasSalesEqualizationTax
  );
  const {
    currentSubtotal,
    currentDiscount: discount,
    currentTaxAmount: taxAmount,
    currentSalesEqTax,
  } = calculateTotalFromUniqueConcept(tortillaConcept, unitPrice);

  const subtotal = parseNumber(currentSubtotal);
  const taxBase = parseNumber(subtotal - discount);
  const taxes = parseNumber(
    hasSalesEqualizationTax ? currentSalesEqTax + taxAmount : taxAmount
  );
  return parseNumber(taxBase + taxes);
};

export const calculateSubTotalFromUnitPrice = (
  concept: Concept,
  unitPrice?: number
): number => {
  const tortillaConcept = translateConceptToTortilla(concept, false);
  const { currentSubtotal, currentDiscount: discount } =
    calculateTotalFromUniqueConcept(tortillaConcept, unitPrice);

  const subtotal = parseNumber(currentSubtotal);
  const taxBase = parseNumber(subtotal - discount);
  return parseNumber(taxBase);
};

export const calculateUnitPriceFromTotal = (
  concept: Concept,
  total: number,
  hasSalesEqualizationTax = false
) => {
  // THIS IS NOT COMMENTED CODE, THIS IS THE FORMULA
  // total = quantity * taxes * (unitPrice - unitPrice * discountPercentage / 100);
  // total / (taxes * quanty) = (100 * unitPrice - unitPrice * discountPercentage) / 100
  // total / (taxes * quanty) * 100 = (100- discountPercentage) * unitPrice
  // unitPrice = total / (taxes * quanty) * 100 / (100- discountPercentage)
  // unitPrice = total / (taxes * quanty)  / (1 - discountPercentage / 100)
  const normalizedConcept = normalizeConcept(concept);
  const quantity = concept.quantity || 0;
  const { tax: taxOption } = normalizedConcept;
  const taxAmount = hasSalesEqualizationTax
    ? taxOption.amount + (taxOption.salesEqTaxPercentage ?? 0)
    : taxOption.amount;
  const taxes = 1 + taxAmount * 0.01;
  const discountPercentage = concept.discountPercentage || 0;
  return total / (taxes * quantity) / (1 - discountPercentage / 100);
};

export const calculateUnitPriceFromSubTotal = (
  concept: Concept,
  total: number
) => {
  // THIS IS NOT COMMENTED CODE, THIS IS THE FORMULA
  // total = quantity * (unitPrice - unitPrice * discountPercentage / 100);
  // total / (quanty) = (100 * unitPrice - unitPrice * discountPercentage) / 100
  // total / (quanty) * 100 = (100- discountPercentage) * unitPrice
  // unitPrice = total / (quanty) * 100 / (100- discountPercentage)
  // unitPrice = total / (quanty)  / (1 - discountPercentage / 100)
  const quantity = concept.quantity || 0;
  const discountPercentage = concept.discountPercentage || 0;
  return total / quantity / (1 - discountPercentage / 100);
};
