import {
  space,
  SpaceProps,
  border,
  BorderProps,
  PositionProps,
  position,
  LayoutProps,
  layout,
  system,
} from 'styled-system';

import theme from 'modules/Theme';
import styled from 'modules/Theme/styled-components';

import HtmlHr from '../Html/Hr';

interface HrProps extends SpaceProps, BorderProps, PositionProps, LayoutProps {
  hrHeight?: number;
  borderStyle?: string;
  color?: string;
  transform?: string;
  gridColumnEnd?: {} | string;
  gridColumnStart?: {} | string;
}

const HorizontalRule = styled(HtmlHr)<HrProps>`
  width: 100%;
  ${border}
  ${space}
  ${position}
  ${layout}
  ${system({
    gridColumnStart: {
      property: 'gridColumnStart',
      transform: (value) => `${value}`,
    },
    gridColumnEnd: {
      property: 'gridColumnEnd',
      transform: (value) => `${value}`,
    },
    gridRow: {
      property: 'gridRow',
      transform: (value) => `${value}`,
    },
  })}
  ${({ transform }) => transform && `transform: ${transform};`}
`;
const Hr: React.FC<HrProps> = ({
  hrHeight = 1,
  borderStyle = 'solid',
  color = theme.colors.gray300,
  transform,
  ...props
}) => {
  return (
    <HorizontalRule
      padding="0"
      margin="0"
      border="none"
      borderBottom={`${hrHeight}px ${borderStyle} ${color}`}
      transform={transform}
      {...props}
    />
  );
};

export default Hr;
