import { useState, useEffect, FC } from 'react';
import { useSelector } from 'react-redux';

import styled from 'styled-components';

import {
  hasElectronicDocumentProvider,
  shouldSendElectronicDocument,
} from 'modules/Business/businessUtils';
import { selectCurrentBusiness } from 'modules/Business/Current/selectors';
import useTranslations from 'modules/I18n/hooks/useTranslations';
import { useApiConfigurationForm } from 'modules/Integrations/hooks';
import useDocumentSettings from 'modules/Settings/hooks/useDocumentSettings';
import { TICKET_BAI_PATH } from 'modules/Settings/paths';
import { Box, Link, Text, Warning } from 'modules/Ui';

import { apiConfigurationForm } from '../../messages';
import { IntegrationFormToogle } from '../IntegrationFormToogle';

interface Props {
  isMarked?: boolean;
}

const StyledLink = styled(Link)`
  text-decoration: underline;
  color: ${({ theme }) => theme.colors.gray800};
`;

export const ApiConfigurationFormOptionBAI: FC<Props> = ({
  isMarked = false,
}) => {
  const { t } = useTranslations();

  const [sendAutomatically, setSendAutomatically] = useState(isMarked);
  useEffect(() => {
    setSendAutomatically(isMarked);
  }, [isMarked]);

  const business = useSelector(selectCurrentBusiness);
  const isBaiBusiness = business && hasElectronicDocumentProvider(business);
  const isBaiConnected = business && shouldSendElectronicDocument(business);
  const { isFetching } = useDocumentSettings();

  const { handleSendAutomaticallyChange } = useApiConfigurationForm({
    isBaiConnected,
    sendAutomatically,
    setSendAutomatically,
  });

  if (isFetching || !isBaiBusiness) {
    return null;
  }

  return (
    <Box
      border="none"
      marginBottom={{ _: '64px', sm: '48px' }}
      padding="0"
      tag="fieldset"
    >
      <Text
        color="primary300"
        fontSize={12}
        fontWeight="500"
        id="exportDateForm"
        lineHeight={22}
        textTransform="uppercase"
        tag="h4"
      >
        {t(apiConfigurationForm.baiOptionLegend)}
      </Text>
      {!isBaiConnected && (
        <Warning
          message={t(apiConfigurationForm.baiOptionWarning, {
            link: (chunks) => (
              <StyledLink to={TICKET_BAI_PATH}>{chunks}</StyledLink>
            ),
          })}
        />
      )}
      <IntegrationFormToogle
        id="sendAutomatically"
        marginBottom={{ _: '32px', sm: '24px' }}
        name="sendAutomatically"
        onChange={handleSendAutomaticallyChange}
        text={t(apiConfigurationForm.baiOptionText)}
        textDescription={t(apiConfigurationForm.baiOptionDescription)}
        value={sendAutomatically}
        isRecommended
      />
    </Box>
  );
};
