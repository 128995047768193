import { css } from 'modules/Theme/styled-components';

export const disabledPrimaryStyles = css`
  &:disabled {
    --button-background: ${(props) => props.theme.colors.gray300};
    --button-color: ${(props) => props.theme.colors.gray0};
    --button-border: ${(props) => props.theme.colors.gray300};
    --button-hover-background: ${(props) => props.theme.colors.gray300};
    --button-hover-border: ${(props) => props.theme.colors.gray300};
    --button-hover-color: ${(props) => props.theme.colors.gray0};
  }
`;

export const disabledSecondaryStyles = css`
  &:disabled {
    --button-color: ${(props) => props.theme.colors.gray600};
    --button-background: ${(props) => props.theme.colors.gray200};
    --button-border: ${(props) => props.theme.colors.gray200};
    --button-hover-background: ${(props) => props.theme.colors.gray200};
    --button-hover-border: ${(props) => props.theme.colors.gray200};
    --button-hover-color: ${(props) => props.theme.colors.gray600};
  }
`;

export const disabledStrokeStyles = css`
  display: flex;
  &:disabled {
    --button-border: ${(props) => props.theme.colors.gray400};
    --button-color: ${(props) => props.theme.colors.gray400};
    --button-hover-background: ${(props) => props.theme.colors.gray0};
    --button-hover-color: ${(props) => props.theme.colors.gray400};
  }
`;

export const disabledLinkStyles = css`
  &:disabled {
    --button-color: ${(props) => props.theme.colors.gray400};
    --button-hover-color: ${(props) => props.theme.colors.gray400};
  }
`;

export const disabledGhostStyles = css`
  &:disabled {
    --button-border: transparent;
    --button-hover-color: ${(props) => props.theme.colors.gray400};
    --button-color: ${(props) => props.theme.colors.gray400};
    --button-hover-background: transparent;
  }
`;
