export const getFilenameFromContentDisposition = (
  headers: { [key: string]: string | undefined },
  defaultName: string = ''
): string => {
  const contentDisposition = headers['content-disposition'];
  if (!contentDisposition) return '';
  const fileName = contentDisposition.split('filename="');
  if (!fileName) return '';
  return decodeURIComponent(fileName[1].split('"')?.[0]) || defaultName;
};
