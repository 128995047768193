import { FC } from 'react';

import useTranslations from 'modules/I18n/hooks/useTranslations';
import { Dialog, MODAL_WIDTH_SMALL, useCloseModal } from 'modules/Modals';
import { Button } from 'modules/Ui';

import { formErrors } from '../messages';

const NoDocumentForScanModal: FC = () => {
  const { t } = useTranslations();

  const closeModal = useCloseModal();

  return (
    <Dialog
      id="no-document-for-scan"
      title={t(formErrors.noDocument)}
      dialogWidth={{ _: 'calc(100% - 16px)', md: MODAL_WIDTH_SMALL }}
      buttonPrimary={
        <Button
          onClick={() => {
            closeModal();
          }}
          variant="secondary"
        >
          {t(formErrors.noDocumentCta)}
        </Button>
      }
      onClose={closeModal}
    >
      {t(formErrors.noDocumentText)}
    </Dialog>
  );
};

export default NoDocumentForScanModal;
