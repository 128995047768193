import { ReactNode, FC } from 'react';

import {
  flexbox,
  FlexboxProps,
  gridArea,
  layout,
  LayoutProps,
  space,
  SpaceProps,
  GridAreaProps,
} from 'styled-system';

import useTheme from 'modules/Theme/hooks/useTheme';
import accesibleHide from 'modules/Theme/mixins/accessibleHidden';
import styled from 'modules/Theme/styled-components';

import Box from '../Box';
import Label from '../Form/Commons/Label';
import Input from '../Html/Input';
import Text from '../Text';
import ToggleInput from './Components/ToggleInput';

export interface ToggleProps
  extends SpaceProps,
    FlexboxProps,
    LayoutProps,
    GridAreaProps {
  checked?: boolean;
  checkedColor?: string;
  children?: ReactNode;
  id: string;
  inputHeight?: string;
  inputWidth?: string;
  isChecked?: boolean;
  isDisabled?: boolean;
  name: string;
  onChange?: (event: any) => void;
  sliderWidth?: string;
  text?: string;
  textColor?: string;
  textPosition?: 'start' | 'end';
  toogleAlinement?: 'center' | 'start' | 'end';
  uncheckedColor?: string;
  value?: boolean;
}

const StyledInput = styled(Input)<ToggleProps>`
  ${flexbox}
  ${layout}
  ${space}
  ${accesibleHide}
  ${gridArea}

  &:checked + ${Box} .toogle__switcher {
    justify-content: flex-end;
    ${({ checkedColor }) =>
      checkedColor && `background-color: ${checkedColor};`}
  }

  &:disabled + ${Box} {
    @media (hover: hover) {
      cursor: auto;
    }
    .toogle__switcher {
      background-color: ${(props) => props.theme.colors.gray500};
    }
  }
`;

const Toggle: FC<ToggleProps> = (props) => {
  const theme = useTheme();
  const {
    checkedColor = `${theme.colors.brand500}`,
    children,
    id,
    inputHeight,
    inputWidth,
    isDisabled,
    name,
    onChange,
    sliderWidth,
    text,
    textColor = `${theme.colors.gray800}`,
    textPosition = 'start',
    toogleAlinement = 'center',
    uncheckedColor = `${theme.colors.neutral}`,
    value,
    ...rest
  } = props;

  const onChangeToggle = (event: any) => {
    onChange && onChange(event);
  };

  return (
    <Label htmlFor={id} {...{ ...rest }}>
      <StyledInput
        checked={value}
        checkedColor={checkedColor}
        disabled={isDisabled}
        id={id}
        name={name}
        onChange={onChangeToggle}
        type="checkbox"
        value={value}
      />
      <Box
        alignItems={toogleAlinement}
        className="toogle__content"
        display="flex"
        flexDirection={textPosition === 'end' ? 'row-reverse' : undefined}
        justifyContent="space-between"
        tag="span"
        textPosition={textPosition}
        width="100%"
      >
        {text && (
          <Text
            marginLeft={textPosition === 'end' ? '8px' : undefined}
            marginRight={textPosition === 'start' ? '8px' : undefined}
            tag="span"
            color={textColor}
            id={`${id}-label`}
          >
            {text}
          </Text>
        )}
        {children}
        <ToggleInput
          checked={value}
          checkedColor={checkedColor}
          inputHeight={inputHeight}
          inputWidth={inputWidth}
          isDisabled={isDisabled}
          sliderWidth={sliderWidth}
          uncheckedColor={uncheckedColor}
        />
      </Box>
    </Label>
  );
};

export default Toggle;
