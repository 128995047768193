import { defaultTaxOption, taxOptions, TaxKey, TaxOption } from 'modules/Taxes';

import { Concept } from '../models/document';

// TODO: why we need this?
export interface NormalizedConcept extends Concept {
  tax: TaxOption;
  discountPercentage: number;
}

export const normalizeConcept = (concept: Concept) => {
  const taxKey: TaxKey = concept.taxKey || defaultTaxOption.value;

  return {
    ...concept,
    unitPrice: concept.unitPrice || 0,
    discountPercentage: concept.discountPercentage || 0,
    tax: taxOptions[taxKey],
  };
};
