import IconByPath, { IconByPathProps } from './Icon/IconType/IconByPath';

const IconEmail = (props: IconByPathProps) => (
  <IconByPath
    {...props}
    path="M22 5Zm-.8 0H2.8l8.263 6.61a1.5 1.5 0 0 0 1.874 0L21.2 5ZM2 5Zm0 .64V19h20V5.64l-8.438 6.751a2.5 2.5 0 0 1-3.124 0L2 5.641ZM1 5a1 1 0 0 1 1-1h20a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V5Z"
  />
);

export default IconEmail;
