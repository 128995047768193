import { ReactNode, FC } from 'react';

import useTheme from 'modules/Theme/hooks/useTheme';
import Box from 'modules/Ui/Box';
import Text from 'modules/Ui/Text';

interface AsideDefinitionListProps {
  title: string;
  children: ReactNode;
}

const AsideDefinitionList: FC<AsideDefinitionListProps> = ({
  title,
  children,
}) => {
  const theme = useTheme();
  return (
    <Box
      border={`1px solid ${theme.colors.primary100}`}
      marginTop={{ sm: '24px', md: 'initial' }}
      padding="24px"
    >
      <Text
        color="gray800"
        fontSize={18}
        fontWeight="600"
        lineHeight={22}
        marginBottom="24px"
        tag="h3"
      >
        {title}
      </Text>
      <Box display="grid" rowGap="24px" tag="dl">
        {children}
      </Box>
    </Box>
  );
};

export default AsideDefinitionList;
