import {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { useField } from 'formik';
import {
  SpaceProps,
  FlexboxProps,
  LayoutProps,
  GridAreaProps,
} from 'styled-system';

import TextArea from 'modules/Ui/Form/TextArea';

import { HTMLInputElementLayoutProps } from '../Html/Input';
import useFocusHandler from './useFocusHandler';

interface Rows {
  mobile: number;
  desktop: number;
}

interface TextAreaFormikFieldProps
  extends HTMLInputElementLayoutProps,
    SpaceProps,
    FlexboxProps,
    GridAreaProps,
    LayoutProps {
  autoSize?: boolean;
  autogrowing?: boolean;
  autoTrim?: boolean;
  disabled?: boolean;
  gridArea?: {} | string;
  gridColumnEnd?: {} | string;
  gridColumnStart?: {} | string;
  gridRow?: {} | string;
  helpText?: string;
  hidden?: boolean;
  icon?: string | ReactNode;
  id: string;
  label: string;
  labelHidden?: boolean;
  descriptionHidden?: boolean;
  maxLength?: number;
  name?: string;
  onBlur?: (any: any) => void;
  placeholder?: string;
  required?: boolean;
  rows?: Rows;
  maxHeight?: string;
}

const TextAreaFormikField = (props: TextAreaFormikFieldProps) => {
  const {
    autoSize,
    autogrowing,
    autoTrim = true,
    gridArea,
    gridColumnEnd,
    gridColumnStart,
    gridRow,
    helpText,
    hidden,
    icon,
    id,
    label,
    labelHidden,
    margin,
    maxLength,
    name,
    onBlur,
    onChange,
    order,
    placeholder,
    required,
    rows,
    maxHeight,
    width,

    ...rest
  } = props;

  const [field, meta, helpers] = useField(name ?? id);
  const [innerValue, setInnerValue] = useState(props.value);
  const onChangeDebounceRef = useRef<ReturnType<typeof setTimeout>>();
  useEffect(() => {
    setInnerValue(field.value);
  }, [field.value, setInnerValue]);

  const onBlurHandler = useCallback(
    (event) => {
      if (autoTrim) {
        helpers.setValue(event.target.value.trim());
      }
      field.onBlur(event);
      onBlur && onBlur(event);
    },
    [autoTrim, onBlur, field, helpers]
  );

  const onFocusHandler = useFocusHandler(name ?? id);
  const onChangeHandler = useCallback(
    (event) => {
      if (onChangeDebounceRef.current) {
        clearTimeout(onChangeDebounceRef.current);
      }
      const { value } = event.target;

      setInnerValue(value);
      onChangeDebounceRef.current = setTimeout(() => {
        onChange && onChange(event);
        helpers.setValue(value);
      }, 100);
    },
    [setInnerValue, helpers.setValue]
  );

  const memoized = useMemo(() => {
    return hidden ? null : (
      <TextArea
        {...{
          autoSize,
          autogrowing,
          gridArea,
          gridColumnEnd,
          gridColumnStart,
          gridRow,
          helpText,
          icon,
          id,
          label,
          labelHidden,
          margin,
          maxLength,
          order,
          placeholder,
          required,
          rows,
          width,
          maxHeight,
          ...rest,
          ...field,
          error: meta.touched && meta.error ? meta.error : undefined,
          onBlur: onBlurHandler,
          onFocus: onFocusHandler,
          name: name ?? id,
          value: innerValue,
          onChange: onChangeHandler,
        }}
      />
    );
  }, [innerValue, meta.touched, meta.error, name, id]);
  return memoized;
};

export default TextAreaFormikField;
