import { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { selectCurrentBusiness } from 'modules/Business/Current/selectors';
import Notifications from 'modules/Notifications/components/Notifications';
import useNotificationPadding from 'modules/Theme/hooks/useNotificationPadding';
import { Box } from 'modules/Ui';
import Layout from 'modules/Ui/Layout';
import LoadingIndicator from 'modules/Ui/LoadingIndicator';
import useGoBackToBookkeeper from 'modules/Ui/Menu/hooks/useGoBackToBookkeeper';
import CreatePlan from 'pages/subscriptions/create-plan/CreatePlan';

interface Props {
  isLoading?: boolean;
  loadingMessage?: string;
}

const SubscriptionNotActiveLayout: FC<Props> = ({
  isLoading,
  loadingMessage,
}) => {
  const { isDummy } = useSelector(selectCurrentBusiness);
  const handleGoBackToBookkeeper = useGoBackToBookkeeper();
  useEffect(() => {
    if (isDummy) {
      handleGoBackToBookkeeper();
    }
  }, [isDummy]);

  const notificationsPadding = useNotificationPadding();

  if (isDummy) {
    return null;
  }

  return (
    <Layout>
      <Box data-testid="invalid-subscription">
        <Notifications top={notificationsPadding} />
        {isLoading ? (
          <LoadingIndicator {...{ text: loadingMessage, variant: 'dark' }} />
        ) : (
          <CreatePlan />
        )}
      </Box>
    </Layout>
  );
};

export default SubscriptionNotActiveLayout;
