import { FC } from 'react';

import {
  space,
  SpaceProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
} from 'styled-system';

import styled from 'modules/Theme/styled-components';

import OnlyIconButton from '../Form/OnlyIconButton';
import IconBack from '../Icons/IconBack';

interface ButtonBackProps extends SpaceProps, LayoutProps, PositionProps {
  id?: string;
  dataTestid?: string;
  accessibleText?: string;
  onClick?: () => void;
  size?: string;
  variant?: 'light' | 'dark';
}

const StyledButton = styled(OnlyIconButton)`
  ${space}
  ${layout}
  ${position}
`;

const ButtonBack: FC<ButtonBackProps> = ({
  variant = 'light',
  onClick,
  accessibleText,
  dataTestid,
  id,
  ...rest
}) => {
  return (
    <StyledButton
      onClick={onClick}
      title={accessibleText}
      icon={<IconBack />}
      variant={variant}
      id={id}
      dataTestid={dataTestid}
      {...rest}
    />
  );
};

export default ButtonBack;
