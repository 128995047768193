import { css } from 'modules/Theme/styled-components';

const progressBarReset = (valueColor: string, height?: string) => css`
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  height: ${height};
  display: block;
  width: 100%;

  &::-webkit-progress-value {
    background-color: ${valueColor};
  }
`;

export default progressBarReset;
