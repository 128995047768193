import IconByString, { IconByStringProps } from './Icon/IconType/IconByString';

const IconPaid = (props: IconByStringProps) => {
  const { ...rest } = props;
  return (
    <IconByString {...rest}>
      <path
        fillRule="evenodd"
        d="M19.127 10a3.992 3.992 0 0 0 1.045 1.828A3.998 3.998 0 0 0 22 12.873V10h-2.873ZM22 13.899A5.003 5.003 0 0 1 18.101 10H5.899A5 5 0 0 1 2 13.899v4.202A5.004 5.004 0 0 1 5.899 22h12.202A5.003 5.003 0 0 1 22 18.101v-4.202ZM2 19.127V22h2.873A4 4 0 0 0 2 19.127ZM1 14v9h22V9H1v5Zm21 5.127V22h-2.873a3.992 3.992 0 0 1 1.045-1.828A3.997 3.997 0 0 1 22 19.127ZM4.873 10H2v2.873a3.992 3.992 0 0 0 1.828-1.045A4 4 0 0 0 4.873 10ZM15 16a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm1 0a4 4 0 1 1-8 0 4 4 0 0 1 8 0Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        d="M10 1h5c.8 0 1 .667 1 1 0 .614-.471.793-.805.92A2.15 2.15 0 0 0 15 3c-.368.132-.67 1-.67 1.5s.242 1.328.67 1.5c.073.03.147.056.219.082.416.148.781.279.781.918 0 .5-.5 1-1 1h-2v2h-1V8h-2c-.5 0-1-.5-1-1 0-.64.365-.77.781-.918.072-.026.146-.052.219-.082.428-.172.67-1 .67-1.5S10.367 3.132 10 3c-.054-.027-.121-.053-.195-.08C9.47 2.792 9 2.613 9 2c0-.333.2-1 1-1Z"
        fill="currentColor"
      />
    </IconByString>
  );
};

export default IconPaid;
