import styled from 'modules/Theme/styled-components';

const ButtonPage = styled.button`
  &:hover,
  &:focus {
    text-decoration: underline;
  }
  &:disabled {
    &:hover,
    &:focus {
      cursor: text;
      text-decoration: none;
    }
  }
`;

export default ButtonPage;
