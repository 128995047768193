import { createSelector } from 'reselect';

import { ApplicationRootState } from 'types';

import { UserPermissions } from './constants';
import { State } from './types';

export const getAuth = (state: ApplicationRootState) => state.auth;

const getScope = (auth: State, scope: UserPermissions): boolean | undefined => {
  const scopes = auth?.scopes;
  if (!scopes) return scopes;
  return Boolean(scopes.find(({ id }) => id === scope));
};

const getEditorScope = (auth: State): boolean | undefined =>
  getScope(auth, UserPermissions.SUBSCRIPTION_EDITOR);

const getBaseScope = (auth: State): boolean | undefined =>
  getScope(auth, UserPermissions.SUBSCRIPTION_BASE);

const getIsLoading = (auth: State): boolean | undefined => auth.loading;

export const selectHasSubscriptionScope = createSelector(getAuth, getBaseScope);

export const selectHasEditorScope = createSelector(getAuth, getEditorScope);

export const selectIsLoading = createSelector(getAuth, getIsLoading);
