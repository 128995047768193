import { FC, ReactNode } from 'react';

import styled from 'styled-components';
import {
  space,
  SpaceProps,
  gridTemplateColumns,
  GridTemplateColumnsProps,
  system,
} from 'styled-system';

import Box from '../Box';

interface Props extends SpaceProps, GridTemplateColumnsProps {
  children: ReactNode;
  columnGap?: {} | string;
  rowGap?: {} | string;
}

const HeaderResumeStyles = styled(Box)`
  ${gridTemplateColumns}
  ${space}
  ${system({
    gap: {
      property: 'gap',
      transform: (value) => `${value}`,
    },
    rowGap: {
      property: 'rowGap',
      transform: (value) => `${value}`,
    },
    columnGap: {
      property: 'columnGap',
      transform: (value) => `${value}`,
    },
  })}
  `;

export const HeaderResume: FC<Props> = ({ children, ...rest }) => {
  return (
    <HeaderResumeStyles
      backgroundColor="auxiliary100A30"
      display="grid"
      marginBottom="16px"
      padding="16px"
      role="alert"
      tag="ul"
      {...rest}
    >
      {children}
    </HeaderResumeStyles>
  );
};
