/*
  IF YOU CHANGE ANYTHING BELOW THIS COMMENT YOU NEED TO DO THE SAME CHANGE IN GOFRE / TORTILLA
 */
export function parseNumber(value: number): number {
  return parseFloat((Number(value) || 0).toPrecision(12));
}

export function roundNumber(value: number, decimals = 2): number {
  const isNegative = value < 0;
  const absolute = Math.abs(value);

  const power = 10 ** decimals;

  let result = Math.round(Number((absolute * power).toPrecision(15))) / power;

  if (isNegative) {
    result = -result;
  }

  return result;
}
