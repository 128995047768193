import { useState } from 'react';

import { useOpenModal, useCloseModal } from 'modules/Modals';
import { ModalTypes } from 'modules/Modals/constants';
import { Plan } from 'modules/Subscriptions/models';
import { changePlan } from 'modules/Subscriptions/services';

export const useCreateSubscriptionModal = (callback?: Function) => {
  // State
  const [selectedPlan, setSelectedPlan] = useState<Plan | null>(null);
  // Behavior
  const openModal = useOpenModal();
  const closeModal = useCloseModal();
  const onCloseModal = () => {
    closeModal();
  };

  async function onSelectPlan(plan: Plan) {
    const { isAllowed, scopes, reason, warningScopes } = await changePlan({
      productId: plan.productId,
    });

    if (!isAllowed) {
      openModal({
        error: reason as string,
        type: ModalTypes.CREATE_PLAN_ERROR,
        productId: plan.productId,
        failedScopes: scopes,
        warningScopes,
      });
    } else if (warningScopes?.length) {
      openModal({
        type: ModalTypes.CREATE_PLAN_WARNING,
        warningScopes,
        onConfirm: () => {
          setSelectedPlan(plan);
          callback?.(plan);
        },
        onClose: onCloseModal,
      });
    } else {
      setSelectedPlan(plan);
      callback?.(plan);
    }
  }
  return { onSelectPlan, selectedPlan, setSelectedPlan };
};
