interface Resolve {
  (result: any): void;
}

interface Reject {
  (error: Error): void;
}

export type RequestPollParams<T> = {
  validateAsyncCondition: () => Promise<T>;
  cancelRequestOnDemand?: AbortController;
  timeout?: number;
  interval?: number;
};

type SetupCheckConditionParams<T> = Omit<RequestPollParams<T>, 'timeout'>;

export function requestPoll<T>({
  validateAsyncCondition,
  timeout = 15000,
  interval = 1000,
  cancelRequestOnDemand,
}: RequestPollParams<T>) {
  const tasks = [
    new Promise(
      setupCheckCondition({
        validateAsyncCondition,
        interval,
        cancelRequestOnDemand,
      })
    ),
    setupCancelRequestByTimeout(timeout, cancelRequestOnDemand?.signal),
  ];

  if (cancelRequestOnDemand) {
    tasks.push(
      setupCancelRequestOnDemandPromise(cancelRequestOnDemand?.signal)
    );
  }

  return Promise.race(tasks);
}

const setupCheckCondition = <T>({
  validateAsyncCondition,
  interval,
  cancelRequestOnDemand,
}: SetupCheckConditionParams<T>) =>
  async function checkCondition(resolve: Resolve) {
    const result = await validateAsyncCondition();
    if (result) {
      resolve(result);
    } else if (!cancelRequestOnDemand?.signal.aborted) {
      setTimeout(checkCondition, interval, resolve);
    }
  };

const setupCancelRequestByTimeout = (
  timeout: number,
  abortSignal?: AbortSignal
) =>
  new Promise((_, reject) => {
    const timeoutId = setTimeout(() => {
      reject(new Error('timedout'));
    }, timeout);

    abortSignal?.addEventListener('abort', onAbort(reject, timeoutId), {
      once: true,
    });
  });

const setupCancelRequestOnDemandPromise = (abortSignal: AbortSignal) =>
  new Promise((_, reject) => {
    abortSignal.addEventListener('abort', onAbort(reject), { once: true });
  });

const onAbort = (reject: Reject, timeoutId?: NodeJS.Timeout) => () => {
  if (timeoutId) {
    clearTimeout(timeoutId);
  }

  reject(new Error('Request cancelled'));
};
