import { getConfig } from 'modules/App/config';
import { ACCOUNTING_ACCOUNTS_QUERY } from 'modules/App/queries/queries';
import request from 'utils/request';

import { DeleteAccountingAccountDto } from '../models';

export interface DeleeteAccountingAccountResponse {
  data: { count: number };
}

const deleteAccountingAccount = async ({
  id,
}: DeleteAccountingAccountDto): Promise<DeleeteAccountingAccountResponse> => {
  const endpoint = (await getConfig()).apiAccountingAccountsService;

  const response = await request<DeleeteAccountingAccountResponse>(
    `${endpoint}/${id}`,
    {
      method: 'DELETE',
    }
  );
  return response.data;
};

export const invalidateQueries = [ACCOUNTING_ACCOUNTS_QUERY];

export default deleteAccountingAccount;
