import IconByString, { IconByStringProps } from './Icon/IconType/IconByString';

const IconInvoice = (props: IconByStringProps) => {
  const { ...rest } = props;
  return (
    <IconByString fill="none" {...rest}>
      <path
        clipRule="evenodd"
        d="M19 23V1H3v22h16ZM4 2h14v20H4V2Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        d="M8 8a3 3 0 1 0 6 0 3 3 0 0 0-6 0Zm3-4a4 4 0 1 0 0 8 4 4 0 0 0 0-8Z"
        fill="currentColor"
      />
      <path fill="currentColor" d="M7 15h8v1H7zM7 19h8v1H7zM21 3h1v20h-1z" />
    </IconByString>
  );
};

export default IconInvoice;
