import { FC } from 'react';

import { space, SpaceProps } from 'styled-system';

import useTranslations from 'modules/I18n/hooks/useTranslations';
import styled from 'modules/Theme/styled-components';

import Text from '../Text';
import messages from './messages';

export interface PillProps extends SpaceProps {}

const StyledPill = styled(Text)`
  ${space}
`;

const PillRecommended: FC<PillProps> = ({ ...rest }) => {
  const { t } = useTranslations();
  return (
    <StyledPill
      backgroundColor="stateBg"
      borderRadius="8px"
      color="brand500"
      fontSize={14}
      lineHeight={16}
      padding="4px 8px"
      tag="span"
      {...rest}
    >
      {t(messages.text)}
    </StyledPill>
  );
};

export default PillRecommended;
