import { FC } from 'react';
import { useSelector } from 'react-redux';

import { selectIsMobile } from 'modules/App/store/selectors';

import { FormikFieldProps } from '../FormikFieldProps';
import DayPickerFormikFieldDesktop from './DayPickerFormikField';
import DayPickerFormikFieldMobile from './DayPickerFormikField.mobile';

const DayPickerFormikField: FC<FormikFieldProps> = (
  props: FormikFieldProps
) => {
  const isMobile = useSelector(selectIsMobile);
  const DatePickerComponent = isMobile
    ? DayPickerFormikFieldMobile
    : DayPickerFormikFieldDesktop;

  return <DatePickerComponent {...props} />;
};

export default DayPickerFormikField;
