import { FC } from 'react';
import { useSelector } from 'react-redux';

import { hasElectronicDocumentProvider } from 'modules/Business/businessUtils';
import { selectCurrentBusiness } from 'modules/Business/Current/selectors';
import useTranslations from 'modules/I18n/hooks/useTranslations';
import { FormikField } from 'modules/Ui/Formik';

import { filter } from '../../../../messages';

const AmountFilters: FC = () => {
  const business = useSelector(selectCurrentBusiness);
  const isBai = hasElectronicDocumentProvider(business);

  const { t } = useTranslations();
  const minAmount = isBai
    ? filter.minAmountEUR
    : filter.minAmountWithoutCurrency;
  const maxAmount = isBai
    ? filter.maxAmountEUR
    : filter.maxAmountWithoutCurrency;
  return (
    <>
      <FormikField
        id="minAmount"
        label={t(minAmount)}
        name="minAmount"
        placeholder={t(filter.amountPlaceholder)}
        type="number"
      />
      <FormikField
        id="maxAmount"
        label={t(maxAmount)}
        name="maxAmount"
        placeholder={t(filter.amountPlaceholder)}
        type="number"
      />
    </>
  );
};

export default AmountFilters;
