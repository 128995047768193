import useExpenseLedger, {
  Option,
} from 'modules/Documents/List/components/SortOutExpensesModal/hooks/useExpenseLedger';
import { expenseLedgerTexts } from 'modules/Documents/List/components/SortOutExpensesModal/messages';
import { listDocumentHeader } from 'modules/Documents/List/messages';
import { Document } from 'modules/Documents/models/document';
import useTranslations, {
  TranslationFunction,
} from 'modules/I18n/hooks/useTranslations';
import useTheme from 'modules/Theme/hooks/useTheme';
import { Box } from 'modules/Ui';
import {
  DataTableColumn,
  DataTableSortOrder,
} from 'modules/Ui/DataTable/DataTable.models';
import DocumentStatus from 'modules/Ui/DocumentStatus';
import formatDate from 'utils/dates/formatDate';

function getExpenseStatusTag(document: Document) {
  return (
    <Box display={{ sm: 'flex' }} alignItems={{ sm: 'center' }}>
      <DocumentStatus state={document.statusInfo} />
    </Box>
  );
}

function getExpenseCategory(
  document: Document,
  options: Option[],
  t: TranslationFunction
) {
  const label = options.find(
    (option: Option) => option.id === document.category
  )?.label;
  return <>{label || t(expenseLedgerTexts.noCategory)}</>;
}

export default function useExpenseListConfig() {
  const { t, formatCurrency } = useTranslations();
  const options = useExpenseLedger({ simple: true });
  const theme = useTheme();
  const columns: DataTableColumn<Document, keyof Document>[] = [
    {
      name: t(listDocumentHeader.code),
      field: 'identifier',
      className: 'code',
      width: { sm: '136px', md: '144px' },
      sortOrderValues: {
        ascOrder: DataTableSortOrder.ASC_NULLS_LAST,
        descOrder: DataTableSortOrder.DESC_NULLS_FIRST,
      },
    },
    {
      name: t(listDocumentHeader.provider),
      // Virtual field with custom accessor
      field: 'contact.fiscalName' as any,
      color: theme.colors.gray800,
      className: 'fiscalName',
      maxWidth: { sm: '224px', md: '208px', lg: '268px' },
      fontSize: theme.fontSizes[16],
      lineHeight: theme.lineHeights[20],
    },
    {
      alignSelf: 'center',
      className: 'category',
      color: theme.colors.gray600,
      field: 'category',
      maxWidth: { md: '208px', lg: '268px' },
      width: { md: '208px', lg: '268px' },
      name: t(listDocumentHeader.category),
      value: (d) => getExpenseCategory(d, options, t),
    },
    {
      name: t(listDocumentHeader.issuedDate),
      className: 'issuedDate',
      field: 'issuedDate',
      sortField: 'issuedDateTime',
      value: (d) => formatDate(d.issuedDate),
      width: { sm: '94px', md: '112px', lg: '155px' },
    },
    {
      name: t(listDocumentHeader.dueDate),
      field: 'dueDate',
      sortField: 'dueDateTime',
      value: (d) => formatDate(d.dueDate),
      className: 'dueDate',
      width: { sm: '104px', md: '112px', lg: '155px' },
    },
    {
      name: (
        <Box tag="span" paddingRight={{ sm: '16px' }}>
          {t(listDocumentHeader.total)}
        </Box>
      ),
      // Virtual field with custom accessor
      field: 'totals.total' as any,
      fontWeight: 600,
      headerTextAlign: 'right',
      textAlign: 'right',
      color: theme.colors.gray800,
      value: ({ totals, currency }) => formatCurrency(totals.total, currency),
      className: 'total',
      width: { sm: '120px', md: '128px', lg: '154px' },
      fontSize: theme.fontSizes[16],
      lineHeight: theme.lineHeights[20],
    },
    {
      name: (
        <Box tag="span" paddingRight={{ sm: '16px' }}>
          {t(listDocumentHeader.status)}
        </Box>
      ),
      field: 'status',
      alignSelf: 'flex-end',
      textAlign: 'right',
      headerTextAlign: 'right',
      value: (d) => (
        <Box display={{ sm: 'flex' }} justifyContent={{ sm: 'flex-end' }}>
          {getExpenseStatusTag(d)}
        </Box>
      ),
      className: 'status',
      width: { sm: '80px' },
    },
  ];

  return {
    columns,
  };
}
