import { FC, useCallback } from 'react';

import { Formik } from 'formik';

import useTranslations from 'modules/I18n/hooks/useTranslations';
import { MODAL_WIDTH_DEFAULT, Dialog, useCloseModal } from 'modules/Modals';
import { normalizeRemittanceValues } from 'modules/Remittances/helpers/normalizeRemittanceValues';
import useEditRemittance from 'modules/Remittances/hooks/useEditRemittance';
import { Box, Button } from 'modules/Ui';

import { remittanceModal } from '../../../messages';
import { EditRemittanceModalProps, Remittance } from '../../../types';
import { RemittanceForm, RemittanceFormType } from '../RemitanceForm';
import getValidationSchema from '../RemitanceForm/getValidationSchema';

const EditRemittanceModal: FC<EditRemittanceModalProps> = ({
  accountingAccountId,
  accountingAccountAlias,
  remittance,
}) => {
  const { t } = useTranslations();
  const closeModal = useCloseModal();
  const validationSchema = getValidationSchema(t);
  const { mutate } = useEditRemittance();
  const onSubmit = useCallback(
    (remittanceValues: Remittance) => {
      mutate({
        id: remittanceValues.id,
        remittanceDto: {
          ...normalizeRemittanceValues(remittanceValues),
        },
      });
      closeModal();
    },
    [mutate, closeModal]
  );

  return (
    <Dialog
      id="edit-remittance-modal"
      title={t(remittanceModal.editTitle)}
      onClose={closeModal}
      width={MODAL_WIDTH_DEFAULT}
      buttonPrimary={
        <Button
          data-testid="edit-remittance-modal-submit"
          variant="secondary"
          form="edit-remittance"
          type="submit"
        >
          {t(remittanceModal.editSave)}
        </Button>
      }
      buttonSecondary={
        <Button
          data-testid="edit-remittance-modal-cancel"
          onClick={closeModal}
          variant="stroke"
        >
          {t(remittanceModal.cancel)}
        </Button>
      }
    >
      <Formik
        onSubmit={onSubmit}
        validateOnChange={false}
        validateOnBlur={false}
        validationSchema={validationSchema}
        initialValues={{
          ...remittance,
          remittanceExpiration: new Date(remittance.remittanceExpiration),
          accountingAccountAlias:
            accountingAccountAlias ?? remittance.accountingAccountAlias,
          accountingAccountId:
            accountingAccountId ?? remittance.accountingAccountId,
        }}
      >
        {(formik) => (
          <Box
            autoComplete="off"
            columnGap="16px"
            display="grid"
            gridTemplateColumns="1fr 1fr"
            id="edit-remittance"
            noValidate
            onSubmit={formik.handleSubmit}
            tag="form"
          >
            <RemittanceForm type={RemittanceFormType.EDIT} />
          </Box>
        )}
      </Formik>
    </Dialog>
  );
};

export default EditRemittanceModal;
