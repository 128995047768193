import { flex, space, layout, color, typography } from 'styled-system';

import styled from 'modules/Theme/styled-components';
import Label from 'modules/Ui/Html/Label';

const Wrapper = styled(Label)`
  contain: layout;
  ${color}
  ${flex}
  ${layout}
  ${space}
  ${typography}

  @media(hover : hover) {
    &:hover {
      cursor: pointer;
    }
  }
`;

Wrapper.defaultProps = {
  fontSize: 16,
  fontWeight: '600',
  height: '72px',
  lineHeight: 26,
};

export default Wrapper;
