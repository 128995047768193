import { SagaIterator } from 'redux-saga';
import { call, takeLatest, put } from 'redux-saga/effects';

import { createNotification } from 'modules/Notifications/actions';
import { NotificationType } from 'modules/Notifications/models';
import requestErrorHandler from 'utils/requestErrorHandler';

import Business from '../models/business';
import * as actions from './actions';
import ActionTypes from './constants';
import fetchBusiness from './services/fetchBusiness';

export function* getBusinessSaga(): SagaIterator {
  try {
    const result: Business = yield call(fetchBusiness);
    yield put(actions.getBusiness.success(result));
  } catch (e: any) {
    yield put(actions.getBusiness.failure(e));
    const { responseMessage, isNetworkError, networkErrorTranslationKey } =
      requestErrorHandler(e);

    yield put(
      createNotification({
        type: NotificationType.ERROR,
        message: isNetworkError ? networkErrorTranslationKey : responseMessage,
      })
    );
  }
}

export default function* businessSaga() {
  yield takeLatest(ActionTypes.FETCH_BUSINESS_REQUEST, getBusinessSaga);
}
