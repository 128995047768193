import { useEffect, useMemo } from 'react';
import { Visible, Hidden } from 'react-grid-system';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router';

import {
  LazyComponentsSwitch,
  RouteDefinition,
} from 'modules/App/components/LazyComponentsSwitch';
import { selectHasSubscriptionScope } from 'modules/Auth/selectors';
import useBookkeeperWidgetLocation from 'modules/Bookkeeper/hooks/useBookkeeperWidgetLocation';
import useIsBookkeeperUser, {
  useIsBookkeeperLoggedInClient,
} from 'modules/Bookkeeper/hooks/useIsBookkeeperUser';
import {
  selectCurrentBusinessLoaded,
  selectIsCurrentBusinessBookkeeping,
} from 'modules/Business/Current/selectors';
import { ROUTES as BUSINESS_USERS_ROUTES } from 'modules/BusinessUsers/routes';
import { ROUTES as ROUTES_HELP } from 'modules/Help/routesMap';
import { SHOPIFY_CONFIGURATION_UPDATE } from 'modules/Integrations/paths';
import { ROUTES as ROUTES_INTEGRATIONS } from 'modules/Integrations/routesMap';
import Notifications from 'modules/Notifications/components/Notifications';
import { selectIsMySubsciptionActive } from 'modules/Subscriptions/detail/selectors';
import SubscriptionNotActiveRoutes from 'modules/Subscriptions/notActive/Routes';
import { ROUTES as SUBSCRIPTIONS_ROUTES } from 'modules/Subscriptions/routesMap';
import { BookkeeperBussinesSearch } from 'modules/Ui';
import Layout from 'modules/Ui/Layout';
import LoadingIndicator from 'modules/Ui/LoadingIndicator';
import { ROUTES as USER_INVITATIONS_ROUTES } from 'modules/UserInvitations/routes';
import { ROUTES as ROUTES_NOTIFICATIONS } from 'modules/UserNotifications/routesMap';
import useRequestUser from 'modules/Users/hooks/useRequestUser';
import useZendeskChat from 'modules/Zendesk/hooks/useZendeskChat';
import { ROUTES } from 'pages/bookkeepers/routesMap';
import { ROUTES as BUSINESS_ROUTES } from 'pages/business/routesMap';
import { PAYMENT_ROUTES } from 'pages/payments/routesMap';
import { PRODUCTS_ROUTES } from 'pages/products/routesMap';
import { ROUTES as ROUTES_QUOTES } from 'pages/quotes/routesMap';
import isNativeApp, { getDeviceInfo } from 'utils/native/isNativeApp';
import request from 'utils/request';

import { fetchAppData } from './actions';
import { selectAppDataState } from './selectors';

const getBasicRoutes = (isBookkeeperLoggedInClient: boolean) => {
  const routes: RouteDefinition[] = [
    {
      exact: true,
      path: '/',
      lazyComponent: () => import('pages/home'),
    },
    {
      path: '/documents',
      lazyComponent: () => import('pages/documents'),
    },
    {
      path: PRODUCTS_ROUTES.BASE,
      lazyComponent: () => import('pages/products'),
    },
    {
      path: '/users', // CONSTANTS
      requiresEditor: isBookkeeperLoggedInClient,
      lazyComponent: () => import('pages/user'),
    },
    {
      path: BUSINESS_USERS_ROUTES.BUSINESS_USERS,
      lazyComponent: () => import('pages/business-user'),
    },
    {
      path: BUSINESS_ROUTES.BUSINESS,
      requiresEditor: isBookkeeperLoggedInClient,
      lazyComponent: () => import('pages/business'),
    },
    {
      path: '/contacts',
      lazyComponent: () => import('pages/contacts'),
    },
    {
      path: PAYMENT_ROUTES.LIST,
      lazyComponent: () => import('pages/payments'),
    },
    {
      path: SUBSCRIPTIONS_ROUTES.SUBSCRIPTIONS,
      lazyComponent: () => import('pages/subscriptions'),
    },
    {
      path: ROUTES_INTEGRATIONS.INTEGRATIONS,
      lazyComponent: () => import('pages/integrations'),
    },
    {
      exact: true,
      path: SHOPIFY_CONFIGURATION_UPDATE,
      lazyComponent: () =>
        import('pages/integrations/ShopifyConfigurationSuccess'),
    },
    {
      path: '/settings/:topic?',
      lazyComponent: () => import('pages/settings'),
    },
    {
      path: USER_INVITATIONS_ROUTES.MAIN_PATH,
      lazyComponent: () => import('pages/user-invitations'),
    },
    {
      path: ROUTES_HELP.MAIN_PATH,
      lazyComponent: () => import('pages/help'),
    },
    {
      path: ROUTES_NOTIFICATIONS.MAIN_PATH,
      lazyComponent: () => import('pages/user-notifications/'),
    },
    {
      path: ROUTES_QUOTES.MAIN_PATH,
      lazyComponent: () => import('pages/quotes'),
    },
    {
      path: ROUTES_QUOTES.PROFORMAS_PATH,
      lazyComponent: () => import('pages/quotes'),
    },
    {
      path: ROUTES_QUOTES.DELIVERY_NOTE_PATH,
      lazyComponent: () => import('pages/quotes'),
    },
    {
      exact: true,
      path: ROUTES.MY_BOOKKEEPER,
      lazyComponent: () =>
        import('pages/bookkeepers/my-bookkeeper/MyBookkeeper'),
    },
    {
      to: '/',
    },
  ];
  return routes;
};

const bookkeeperRoutes: RouteDefinition[] = [
  {
    exact: true,
    path: '/',
    lazyComponent: () => import('pages/home'),
  },
  {
    path: ROUTES.BOOKKEEPER,
    lazyComponent: () => import('pages/bookkeepers'),
  },
  {
    path: USER_INVITATIONS_ROUTES.MAIN_PATH,
    lazyComponent: () => import('pages/user-invitations'),
  },
  {
    path: ROUTES_NOTIFICATIONS.MAIN_PATH,
    lazyComponent: () => import('pages/user-notifications'),
  },
  {
    to: '/',
  },
];

const getRoutes = (
  isBookkeeperLoggedInClient: boolean,
  showBookkeeper?: boolean
) => {
  return showBookkeeper && !isBookkeeperLoggedInClient
    ? bookkeeperRoutes
    : getBasicRoutes(isBookkeeperLoggedInClient);
};

export default function AppRoutes() {
  const dispatch = useDispatch();
  const isMySubsciptionActive = useSelector(selectIsMySubsciptionActive);
  const { isLoading, loadingMessage } = useSelector(selectAppDataState);
  const hasSubscriptionScope = useSelector(selectHasSubscriptionScope);
  const isCurrentBusinessLoaded = useSelector(selectCurrentBusinessLoaded);
  const isCurrentBookkeeping = useSelector(selectIsCurrentBusinessBookkeeping);
  const showBookkeeper = useIsBookkeeperUser();
  const isBookkeeperLoggedInClient = useIsBookkeeperLoggedInClient();
  const appRoutes = useMemo(
    () => getRoutes(isBookkeeperLoggedInClient, showBookkeeper),
    [isBookkeeperLoggedInClient, showBookkeeper]
  );
  const isBookkeeperWidgettVisible = useBookkeeperWidgetLocation();

  useEffect(() => {
    dispatch(fetchAppData.request());
  }, [dispatch]);

  useEffect(() => {
    if (isNativeApp()) {
      const device = getDeviceInfo();
      if (device?.token) {
        const { token, uniqueId, fingerprint, deviceName, deviceId } = device;
        request('/users/me/devices', {
          method: 'PATCH',
          data: { token, uniqueId, fingerprint, deviceName, deviceId },
        });
      }
    }
  }, []);

  useRequestUser();
  useZendeskChat();

  if (
    isCurrentBusinessLoaded &&
    !isCurrentBookkeeping &&
    (isMySubsciptionActive === false || hasSubscriptionScope === false)
  ) {
    return <SubscriptionNotActiveRoutes {...{ isLoading, loadingMessage }} />;
  }

  if (isLoading || showBookkeeper === undefined) {
    return (
      <Layout>
        <Notifications />
        <LoadingIndicator {...{ text: loadingMessage, variant: 'dark' }} />
      </Layout>
    );
  }
  return (
    <Layout>
      <Visible xs>
        {isBookkeeperWidgettVisible && showBookkeeper && (
          <BookkeeperBussinesSearch />
        )}
      </Visible>
      <Notifications />
      {/*
        it is not been possible to have a canceled subscripton at this point and the
        route `/subscription-cancelled` can not be found, so redirect to home.
        */}

      <Route path={SUBSCRIPTIONS_ROUTES.SUBSCRIPTIONS_CANCELLED} exact>
        <Redirect to="/" />
      </Route>
      <Route path={SUBSCRIPTIONS_ROUTES.SUBSCRIPTIONS_CANCELLED_PLANS} exact>
        <Redirect to="/" />
      </Route>
      <LazyComponentsSwitch routes={appRoutes} />
      <Hidden xs>
        {isBookkeeperWidgettVisible && showBookkeeper && (
          <BookkeeperBussinesSearch />
        )}
      </Hidden>
    </Layout>
  );
}
