import useTranslations from 'modules/I18n/hooks/useTranslations';
import { Box, FormikField } from 'modules/Ui';
import MaskedFormikField from 'modules/Ui/Formik/MaskedFormikField';

import messages from '../messages';
import SubFormBox from './SubFormBox';

interface Props {
  isEditing: boolean;
  forContact?: boolean;
}

const TransferSubForm: React.FC<Props> = ({ isEditing = false }) => {
  const { t } = useTranslations();
  return (
    <SubFormBox isEditing={isEditing}>
      <FormikField
        id="data.alias"
        helpText={t(messages.transferHelp)}
        label={t(messages.bank_account_alias)}
        width={{ _: '100%', sm: '64%' }}
        data-testid="alias-input"
      />
      <Box display={{ sm: 'flex' }} justifyContent="space-between">
        <MaskedFormikField
          autoTrim
          id="data.IBAN"
          label={t(messages.bank_account_iban)}
          placeholder={t(messages.bank_account_iban_placeholder)}
          required
          width={{ _: '100%', sm: '64%' }}
          mask="AA11 1111 1111 1111 1111 1111"
          placeholderChar=" "
          data-testid="iban-input"
        />

        <FormikField
          id="data.swiftCode"
          label={t(messages.bank_account_bic)}
          width={{ _: '100%', sm: '34%' }}
          data-testid="swiftCode-input"
        />
      </Box>
    </SubFormBox>
  );
};

export default TransferSubForm;
