import React, { ReactNode } from 'react';

import styled from 'styled-components';
import { grid, GridProps, space, SpaceProps } from 'styled-system';

import { filter } from 'modules/Documents/List/messages';
import useTranslations from 'modules/I18n/hooks/useTranslations';
import { Fieldset } from 'modules/Ui';

interface FormProps extends GridProps, SpaceProps {
  children?: ReactNode;
  id: string;
}

const StyledWrapper = styled(Fieldset)`
  ${grid}
  ${space}
`;

const FilterWrapper: React.FC<FormProps> = ({ children, id, ...rest }) => {
  const { t } = useTranslations();
  return (
    <StyledWrapper
      backgroundColor={{ sm: 'auxiliary100' }}
      display={{ _: 'flex', sm: 'grid' }}
      flexDirection={{ _: 'column', sm: 'initial' }}
      gridGap={{ sm: '0 8px', md: '0 16px' }}
      legend={t(filter.legend)}
      hiddenLegend
      id={id}
      {...rest}
    >
      {children}
    </StyledWrapper>
  );
};

StyledWrapper.defaultProps = {
  padding: { sm: '24px 16px 16px', lg: '24px 32px 8px' },
};

export default FilterWrapper;
