import { useOpenModal } from 'modules/Modals';
import { ModalTypes } from 'modules/Modals/constants';
import { Payment } from 'modules/Payments/models';

export const useDeletePaymentsAction = () => {
  const openModal = useOpenModal();

  return (
    selecteds: Payment[],
    inverted: boolean,
    resetSelection: () => void
  ) => {
    openModal({
      type: ModalTypes.DELETE_PAYMENTS,
      ids: selecteds.map((c) => c.id).filter(Boolean) as string[],
      excludeIds: Boolean(inverted),
    });
    resetSelection?.();
  };
};
