import { FC } from 'react';

import { Box, Text } from 'modules/Ui';

interface Props {
  message: string;
}

const PlanPopular: FC<Props> = ({ message }) => {
  return (
    <Box textAlign="center" tag="p">
      <Text
        backgroundColor="brand200"
        borderRadius="16px"
        color="brand500"
        display="inline-flex"
        fontSize={16}
        lineHeight={32}
        padding={{ _: '0 30px', md: '0 50px', lg: '0 58px' }}
        tag="span"
        textAlign={message ? 'center' : undefined}
        transform="translateY(-10px)"
      >
        {message}
      </Text>
    </Box>
  );
};

export default PlanPopular;
