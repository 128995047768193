import { Box, Text } from 'modules/Ui';
import { Option } from 'modules/Ui/Form/SelectCommon/types';

interface Props {
  option: Option;
}

const OptionLabel: React.FC<Props> = ({ option }) => {
  return (
    <Box display="flex" justifyContent="space-between">
      <Text
        className="react-select__option__text"
        tag="span"
        color="inherit"
        width="70%"
        hasEllipsis
      >
        {option?.label}
      </Text>
    </Box>
  );
};

export default OptionLabel;
