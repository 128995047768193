import { BaiProviders } from 'modules/Business/models/business';
import { nifUtils } from 'utils/nif-validations';

export function isTaxRegimeSalesEq(taxRegimeValue?: string) {
  return !!taxRegimeValue && taxRegimeValue === '01';
}

export function isTaxDisabledByTaxRegime(taxRegimeValue?: string) {
  return ['11', '12', '13', '53', '02'].includes(taxRegimeValue || '');
}

export const shouldFill140Document = (
  vatNumber: string,
  provider?: BaiProviders
) => {
  const isBizkaia = provider === BaiProviders.BI;
  return (
    isBizkaia &&
    (nifUtils.isGoodsCommunity(vatNumber) ||
      nifUtils.isSelfEmployed(vatNumber) ||
      nifUtils.isCivilSociety(vatNumber))
  );
};
