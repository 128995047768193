import { FC, useCallback } from 'react';
import { Hidden } from 'react-grid-system';

import useTranslations from 'modules/I18n/hooks/useTranslations';
import {
  useCloseModal,
  Dialog,
  MODAL_WIDTH_SMALLER,
  useOpenModal,
} from 'modules/Modals';
import { ModalTypes } from 'modules/Modals/constants';
import { getPaymentType } from 'modules/Payments/helpers';
import useCreateMultiplePayment from 'modules/Payments/hooks/useCreateMultiplePayment';
import { Button } from 'modules/Ui';

import useCreatePayment from '../../hooks/useCreatePayment';
import CreatePaymentForm, {
  FormPayment,
} from '../DocumentPaymentForm/DocumentPaymentForm';
import messages from './messages';
import { CreateDocumentPaymentModalProps } from './types';

const CreateDocumentPaymentModal: FC<CreateDocumentPaymentModalProps> = (
  props
) => {
  const {
    amount,
    paymentMethod,
    document,
    onSuccess,
    onError,
    operationDate,
    accountingAccountId,
    multiple,
    multipleDeleteParams,
  } = props;
  const { t } = useTranslations();
  const closeModal = useCloseModal();
  const { mutateAsync: createPaymentMutation } = useCreatePayment({});
  const { mutate: createMultiplePaymentMutation } = useCreateMultiplePayment();
  const openModal = useOpenModal();

  const onCreateAccount = useCallback(
    (values: FormPayment) => {
      const currentProps = {
        ...props,
        amount: values.amount ? Number(values.amount) : 0,
        operationDate: values.operationDate,
        paymentMethod: values.method,
        accountingAccountId: values.accountingAccountId,
      };
      openModal({
        type: ModalTypes.ACCOUNTING_ACCOUNT,
        onCancel: () => {
          openModal({
            type: ModalTypes.CREATE_DOCUMENT_PAYMENT,
            ...currentProps,
          });
        },
        onCreateAccountingAccount: (accountingAccount) => {
          openModal({
            type: ModalTypes.CREATE_DOCUMENT_PAYMENT,
            ...currentProps,
            accountingAccountId: accountingAccount.id,
            accountingAccountAlias: accountingAccount.alias,
          });
        },
      });
    },
    [props]
  );

  const cancelButton = (
    <Hidden xs>
      <Button
        data-testid="mark-as-paid-cancel"
        marginBottom={{ _: '16px', sm: '0' }}
        onClick={closeModal}
        variant="stroke"
      >
        {t(messages.cancel)}
      </Button>
    </Hidden>
  );

  const createButton = (
    <Button
      data-testid="create-payment-submit"
      variant="secondary"
      type="submit"
      form="update-payment-method"
    >
      {t(messages.confirmText)}
    </Button>
  );

  return (
    <Dialog
      id="create-payment-modal"
      dialogWidth={{ sm: MODAL_WIDTH_SMALLER }}
      type="fullScreen"
      title={t(messages.title)}
      onClose={closeModal}
      buttonPrimary={createButton}
      buttonSecondary={cancelButton}
      content={
        <CreatePaymentForm
          {...{
            handleSubmit: async ({
              method,
              accountingAccountAlias,
              ...values
            }: FormPayment) => {
              try {
                if (multiple) {
                  await createMultiplePaymentMutation({
                    ...values,
                    method,
                    multipleDeleteParams,
                  });
                  closeModal();
                  onSuccess({ ...values, paymentMethod: method });
                  return;
                }

                if (document) {
                  await createPaymentMutation({
                    ...values,
                    amount: values.amount ?? 0,
                    method,
                    documentsIds: [document?.id],
                  });
                }

                closeModal();
                onSuccess({
                  ...values,
                  paymentMethod: method,
                  accountingAccountAlias,
                });
              } catch (e) {
                closeModal();
                onError(e);
              }
            },
            onCreateAccount,
            maxAmount: amount,
            initialValues: {
              amount,
              method: paymentMethod,
              operationDate: operationDate ?? new Date(),
              accountingAccountId,
              accountingAccountAlias: props.accountingAccountAlias,
              type: getPaymentType({ document }),
            },
            multiple,
          }}
        />
      }
    />
  );
};

export default CreateDocumentPaymentModal;
