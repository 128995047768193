import { getConfig } from 'modules/App/config';
import buildBaseIdsQuery from 'modules/App/queries/buildBaseIdsQuery';
import { REMITTANCES_QUERY } from 'modules/App/queries/queries';
import buildFiltersQuery, {
  buildDocumentTypeStatusQuery,
  queryStatus,
} from 'modules/Documents/helpers/buildFiltersQuery';
import {
  DocumentFilters,
  DocumentStatus,
  DocumentType,
} from 'modules/Documents/models/document';
import request from 'utils/request';

import { CreateRemittanceDto, RemittanceAnswerDto } from '../types';

export interface CreateRemittanceResponse {
  data: RemittanceAnswerDto;
}

interface QueryOptions {
  selectedIds: string[];
  excludeIds: boolean;
  filters?: DocumentFilters;
  status?: DocumentStatus;
  documentType?: DocumentType;
  canVoidDocument?: boolean;
  extraQuery?: string;
}

const createRemittance = async (options: {
  remittance: CreateRemittanceDto;
  queryOptions: QueryOptions;
}): Promise<CreateRemittanceResponse> => {
  const { remittance, queryOptions } = options;

  const query = createQuery(queryOptions);
  const endpoint = (await getConfig()).apiRemittancesService;
  const url = query ? `${endpoint}?${query}` : endpoint;

  const response = await request<CreateRemittanceResponse>(url, {
    method: 'POST',
    data: remittance,
  });
  return response.data;
};

const createQuery = (queryOptions: QueryOptions): string => {
  let query = '';
  const {
    selectedIds,
    excludeIds,
    filters,
    status,
    documentType,
    canVoidDocument,
    extraQuery,
  } = queryOptions;
  query = buildBaseIdsQuery(selectedIds, excludeIds);

  if (filters) {
    query += buildFiltersQuery(filters);
  }

  query = buildDocumentTypeStatusQuery(query, {
    documentType,
    status: queryStatus(filters, status),
    canVoidDocument,
    excludeTestInvoices: true,
  });
  if (extraQuery) {
    query += extraQuery;
  }
  return query;
};

export const invalidateQueries = [REMITTANCES_QUERY];

export default createRemittance;
