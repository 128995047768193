import { FC } from 'react';

import useTranslations from 'modules/I18n/hooks/useTranslations';
import { templateForm } from 'modules/Settings/messages';
import { Box } from 'modules/Ui';
import { ClipboardCopyWithPikito } from 'modules/Ui/ClipboardCopyWithPikito/ClipboardCopyWithPikito';

interface TemplatesIdPillProps {
  id: string;
}

export const TemplateIdPill: FC<TemplatesIdPillProps> = ({ id }) => {
  const { t } = useTranslations();
  return (
    <Box
      alignItems="center"
      backgroundColor="gray200"
      borderRadius="19px"
      color="primary400"
      columnGap="4px"
      display="grid"
      fadeIn
      fontSize={14}
      gridTemplateColumns="1fr 24px"
      lineHeight={16}
      marginBottom="16px"
      padding="4px 8px"
      position="relative"
      width="fit-content"
    >
      {id}
      <ClipboardCopyWithPikito
        value={id}
        accessibleText={t(templateForm.copy)}
      />
    </Box>
  );
};
