import { getConfig } from 'modules/App/config';
import { ACCOUNTING_ACCOUNTS_QUERY } from 'modules/App/queries/queries';
import cleanObject from 'utils/object/cleanObject';
import request from 'utils/request';

import { AccountingAccount, CreateAccountingAccountDto } from '../models';

interface AccountingAccountResponse {
  data: AccountingAccount;
}

const createAccountingAccount = async (
  accountingAccount: CreateAccountingAccountDto
): Promise<AccountingAccountResponse> => {
  const endpoint = (await getConfig()).apiAccountingAccountsService;

  const response = await request<AccountingAccountResponse>(endpoint, {
    method: 'POST',
    data: cleanObject(accountingAccount),
  });
  return response.data;
};

export const invalidateQueries = [ACCOUNTING_ACCOUNTS_QUERY];

export default createAccountingAccount;
