import { defineMessages } from 'react-intl';

export default defineMessages({
  cancel: {
    id: 'gofre.Bai.ChangeVatNumberModal.cancel',
  },
  submit: {
    id: 'gofre.Bai.ChangeVatNumberModal.submit',
  },
  title: {
    id: 'gofre.Bai.ChangeVatNumberModal.title',
  },
  updateVatNumberErrorShort: {
    id: 'gofre.Bai.ChangeVatNumberModal.updateVatNumberErrorShort',
  },
  vatNumber: {
    id: 'gofre.Bai.ChangeVatNumberModal.vatNumber',
  },
  warning: {
    id: 'gofre.Bai.ChangeVatNumberModal.warning',
  },
  successShouldFill140: {
    id: 'gofre.Bai.ChangeVatNumberModal.success.should-fill-140',
  },
  successShouldNotFill140: {
    id: 'gofre.Bai.ChangeVatNumberModal.success.should-not-fill-140',
  },
});
