import useTranslations from 'modules/I18n/hooks/useTranslations';
import { Box, Tag } from 'modules/Ui';

export interface TagElement {
  filterKey: string;
  formattedValue?: string | number;
}

interface TagsProps {
  formattedFilters: TagElement[];
  onClearTermClick: (key: string) => void;
  tags: { [key: string]: { id: string } };
}

const TagListUI = (props: TagsProps) => {
  const { onClearTermClick, formattedFilters, tags } = props;
  const { t } = useTranslations();
  const definedFilters = formattedFilters.filter(
    (tag) =>
      tag.formattedValue !== undefined ||
      tag.formattedValue !== null ||
      tag.formattedValue !== ''
  );

  const isEmpty = definedFilters.length === 0;

  return isEmpty ? null : (
    <Box
      display="flex"
      fadeIn
      flexWrap="wrap"
      padding={{
        _: '24px 16px 16px',
        md: '24px 24px 16px',
        lg: '24px 32px 16px',
      }}
      role="alert"
      tag="ul"
    >
      {formattedFilters.map((tag) => {
        const key = tag.filterKey;
        return (
          <Box tag="li" key={key} marginBottom="8px" marginRight="16px">
            <Tag
              accessibleText={t(tags.delete, {
                searchTerm: tag.formattedValue,
              })}
              onClick={() => {
                onClearTermClick(key);
              }}
            >
              {tag.formattedValue}
            </Tag>
          </Box>
        );
      })}
    </Box>
  );
};

export default TagListUI;
