import { useCallback } from 'react';
import { useHistory } from 'react-router';

import { useOpenModal } from 'modules/Modals';
import { ModalTypes } from 'modules/Modals/constants';
import { RemittanceStatus, RemittanceTable } from 'modules/Payments';
import useDownloadRemittanceXML from 'modules/Remittances/hooks/useDownloadXMLRemittance';
import useDownloadRemittanceZip from 'modules/Remittances/hooks/useDownloadZipRemittance';
import { DataTableActionOnClick } from 'modules/Ui/DataTable/DataTable.models';

interface RemittanceAction extends DataTableActionOnClick<RemittanceTable> {}

export interface RemittanceListActions {
  delete: RemittanceAction;
  downloadXML: RemittanceAction;
  downloadZIP: RemittanceAction;
  edit: RemittanceAction;
  markAsCompleted: RemittanceAction;
  markAsPending: RemittanceAction;
}

export const useRemittanceListActions = (): RemittanceListActions => {
  const history = useHistory();
  const openModal = useOpenModal();
  const { mutate: downloadXMLRemittance } = useDownloadRemittanceXML({
    defaultFileName: 'remesa.xml',
  });
  const { mutate: downloadZipRemittance } = useDownloadRemittanceZip();

  const deleteRemittance = useCallback(
    (
      selected: RemittanceTable[],
      inverted: boolean,
      resetSelection: () => void
    ) => {
      openModal({
        type: ModalTypes.DELETE_REMITTANCES,
        ids: selected.map((x) => x.id),
        inverted,
        hasCompletedRemittances: selected.some(
          (xs) => xs.status === RemittanceStatus.COMPLETED
        ),
        callback: resetSelection,
      });
    },
    []
  );

  const downloadXML = useCallback((selected: RemittanceTable[]) => {
    const remittance = selected[0];
    if (!remittance?.id) return;
    downloadXMLRemittance(remittance.id);
  }, []);

  const downloadZIP = useCallback(
    (selected: RemittanceTable[], inverted: boolean) => {
      downloadZipRemittance({
        ids: selected.map((x) => x.id),
        excludeIds: inverted,
      });
    },
    []
  );

  const edit = useCallback((selected: RemittanceTable[]) => {
    history.push(`/payments/remittances/${selected[0].id}/detail`);
  }, []);

  const markAsPending = useCallback(
    (
      selected: RemittanceTable[],
      _inverted: boolean,
      resetSelection: () => void
    ) => {
      const remittance = selected[0];
      openModal({
        type: ModalTypes.SET_REMITTANCE_STATUS_MODAL,
        id: remittance.id,
        status: RemittanceStatus.PENDING,
        onSuccess: resetSelection,
      });
    },
    []
  );
  const markAsCompleted = useCallback(
    (
      selected: RemittanceTable[],
      _inverted: boolean,
      resetSelection: () => void
    ) => {
      const remittance = selected[0];
      openModal({
        type: ModalTypes.SET_REMITTANCE_STATUS_MODAL,
        id: remittance.id,
        status: RemittanceStatus.COMPLETED,
        onSuccess: resetSelection,
      });
    },
    []
  );
  return {
    delete: deleteRemittance,
    downloadXML,
    downloadZIP,
    edit,
    markAsPending,
    markAsCompleted,
  };
};
