import { getConfig } from 'modules/App/config';
import { ApiCrendential } from 'modules/Integrations/models';
import request from 'utils/request';

const fetchApiCredentials = async (): Promise<ApiCrendential[]> => {
  const endpoint = (await getConfig()).apiIntegrationApiCredentials;

  const response = await request<{ data: ApiCrendential[] }>(endpoint, {
    method: 'GET',
  });

  return response.data.data;
};

export default fetchApiCredentials;
