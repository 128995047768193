import { useRouteMatch } from 'react-router';

import ExporterHelpBanner from 'modules/Bookkeeper/Home/components/ExporterHelpBanner';
import BubbleNotifications from 'modules/Notifications/components/BubbleNotifications/BubbleNotifications';
import useTheme from 'modules/Theme/hooks/useTheme';
import { Box } from 'modules/Ui';
import { menuSizes } from 'modules/Ui/Menu/MenuMobile/menuSizes';
import { ROUTES } from 'pages/bookkeepers/routesMap';

import Logo from '../Components/Logo';
import Menu from './Menu';

const MenuDesktop = () => {
  const theme = useTheme();
  const match = useRouteMatch({
    path: ROUTES.BOOKKEEPER,
    exact: true,
    strict: false,
  });
  const isBookkeeperHome = !!match;
  return (
    <>
      <Box
        backgroundColor="brand500"
        height={`${menuSizes.HEADER_HEIGHT_DESKTOP}px`}
        position="relative"
        tag="header"
      >
        <Box
          alignItems="center"
          columnGap={{ sm: '16px', md: '20px', lg: '32px' }}
          display="grid"
          gridTemplateColumns="auto 1fr"
          height="100%"
          id="menu"
          margin="0 auto"
          maxWidth={{ md: theme.maxWidth.md, lg: theme.maxWidth.lg }}
          tag="nav"
          width={{ sm: 'calc(100% - 30px)', lg: 'initial' }}
        >
          <Logo isBookkeeper />
          <Menu />
        </Box>
      </Box>
      <BubbleNotifications />
      {isBookkeeperHome && <ExporterHelpBanner />}
    </>
  );
};

export default MenuDesktop;
