import useTheme from 'modules/Theme/hooks/useTheme';

import IconByString, { IconByStringProps } from '../Icon/IconType/IconByString';

const IconMenuBalance = (props: IconByStringProps) => {
  const { active, ...rest } = props;
  const theme = useTheme();
  return (
    <IconByString {...rest}>
      <path
        d="M18.101 3H5.899A5 5 0 0 1 2 6.899v4.202A5.004 5.004 0 0 1 5.899 15h12.202A5.003 5.003 0 0 1 22 11.101V6.899A5 5 0 0 1 18.101 3ZM6 16H1V2h22v14H6Zm-1.127-1A4 4 0 0 0 2 12.127V15h2.873ZM22 15h-2.873A4.006 4.006 0 0 1 22 12.127V15ZM19.127 3a3.992 3.992 0 0 0 1.045 1.828A4 4 0 0 0 22 5.873V3h-2.873ZM2 3h2.873A4 4 0 0 1 2 5.873V3Z"
        fillRule="evenodd"
        fill="currentColor"
      />
      <path
        className="icon-hover-effect"
        d="M12 12a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm0 1a4 4 0 1 0 0-8 4 4 0 0 0 0 8Z"
        fillRule="evenodd"
        fill={active ? theme.colors.brand500 : ' currentColor'}
        shapeRendering="geometricPrecision"
      />
      <path fill="currentColor" d="M1 18h22v1H1zM1 21h22v1H1z" />
      <circle
        className="icon-hover-effect"
        cx="12"
        cy="9"
        fill={active ? theme.colors.brand500 : 'transparent'}
        r="3"
        shapeRendering="geometricPrecision"
      />
    </IconByString>
  );
};

export default IconMenuBalance;
