import Business, { BusinessType } from 'modules/Business/models/business';
import { DataTableSort } from 'modules/Ui/DataTable/DataTable.models';
import { AddClientInvitation } from 'modules/UserInvitations/models/UserInvitation';

export interface FormValues {
  fiscalName: string;
  vatNumber: string;
  phoneNumber: string;
  address: {
    province: string;
    country: string;
  };
  companySize?: string;
  software?: string;
  softwareOther?: string;
  type: BusinessType;
}

export interface BookkeeperCustomerFilters {
  searchTerm?: string;
  lastExportFromDateTime?: string;
  lastExportToDateTime?: string;
  lastDocumentDate?: string;
  newDocuments?: boolean;
  lastAccessDate?: Date;
}

export type BookkeeperCustomerSort = DataTableSort<Business, keyof Business>;

export type BookkeeperInvitationsSort = DataTableSort<
  AddClientInvitation,
  keyof AddClientInvitation
>;

export enum LastDocumentPeriod {
  THIS_WEEK = 'THISWEEK',
  LAST_WEEK = 'LASTWEEK',
  THIS_MONTH = 'THISMONTH',
  THIS_QUARTER = 'THISQUARTER',
}

export interface BookkeeperCustomerUpdate {
  bookkeepingData: {
    salesCode: string | null;
    clientCode?: string | null;
  };
}
