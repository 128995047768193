import IconByString, { IconByStringProps } from './Icon/IconType/IconByString';

const IconComputer = (props: IconByStringProps) => {
  return (
    <IconByString {...props}>
      <path
        clipRule="evenodd"
        d="M3 4.126h18c.552 0 1 .504 1 1.126v9.36H2v-9.36c0-.622.448-1.126 1-1.126Zm-1 11.54v.915c0 .622.448 1.126 1 1.126h18c.552 0 1-.504 1-1.126v-.914H2ZM1 5.253C1 4.008 1.895 3 3 3h18c1.105 0 2 1.008 2 2.252v11.33c0 1.243-.895 2.251-2 2.251h-6v2.111h2V22H7v-1.056h2v-2.11H3c-1.105 0-2-1.009-2-2.253V5.251Zm13 13.581v2.111h-4v-2.11h4Z"
        fillRule="evenodd"
      />
    </IconByString>
  );
};

export default IconComputer;
