import { SagaIterator } from 'redux-saga';
import { call, delay, put, takeEvery } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';

import updateNotification from 'modules/UserNotifications/List/services/updateNotification';

import {
  UserNotificationTypes,
  DownloadZipPayload,
  UserCommercialNotification,
} from '../UserNotifications/models/UserNotificationPaylodType';
import * as actions from './actions';
import messages from './components/BubbleNotification/messages';
import ActionTypes from './constants';

export function* addNotificationSaga(
  action: ActionType<typeof actions.createNotification>
): SagaIterator {
  const { ttl, id } = action.payload;
  if (ttl) {
    yield delay(ttl);
    yield put(actions.removeNotification(id));
  }
}
export function* addBubbleNotificationSaga(
  action: ActionType<typeof actions.createBubbleNotification>
): SagaIterator {
  const { ttl, id } = action.payload;
  if (ttl) {
    yield delay(ttl);
    yield put(actions.removeBubbleNotification(id));
  }
}

export function* removeBubbleNotificationSaga(
  action: ActionType<typeof actions.removeBubbleNotification>
): SagaIterator {
  const id = action.payload;
  yield call(updateNotification, {
    id,
    read: false,
    sent: true,
    showPreview: false,
  });
}

export function* receiveServerNotificationSaga(
  action: ActionType<typeof actions.receiveServerNotification>
): SagaIterator {
  const { type } = action.payload;
  if (
    [
      UserNotificationTypes.userDownloadZip,
      UserNotificationTypes.bookkeeperDownloadZip,
    ].includes(type)
  ) {
    if (!(action.payload.payload as DownloadZipPayload).downloaded) {
      yield put(
        actions.createBubbleNotification({
          message: action.payload.bodyKey ?? messages.zipDownloadReady.id,
          payload: action.payload.payload,
          ttl: 6 * 1000,
        })
      );
    }
  } else if (type === UserNotificationTypes.commercialBeta) {
    const payload = action.payload.payload as UserCommercialNotification;
    yield put(
      actions.createCommercialNotification({
        id: action.payload.id,
        titleKey: payload.titleKey,
        ctaTextKey: payload.ctaTextKey,
        imageUrl: payload.imageUrl,
        bodyKey: payload.bodyKey,
        category: payload.category,
        ttl: 6 * 1000,
      })
    );
  }

  yield delay(100);
}

export function* removeCommercialNotificationSaga(
  action: ActionType<typeof actions.removeCommercialNotification>
): SagaIterator {
  const id = action.payload;
  yield call(updateNotification, {
    id,
    read: false,
    sent: true,
    showPreview: false,
  });
}

export default function* rootSaga() {
  yield takeEvery(
    ActionTypes.RECEIVE_SERVER_NOTIFICATION,
    receiveServerNotificationSaga
  );
  yield takeEvery(ActionTypes.ADD_NOTIFICATION, addNotificationSaga);
  yield takeEvery(
    ActionTypes.ADD_BUBBLE_NOTIFICATION,
    addBubbleNotificationSaga
  );
  yield takeEvery(
    ActionTypes.REMOVE_BUBBLE_NOTIFICATION,
    removeBubbleNotificationSaga
  );
  yield takeEvery(
    ActionTypes.REMOVE_COMMERCIAL_NOTIFICATION,
    removeCommercialNotificationSaga
  );
}
