import { useQuery } from 'react-query';

import { ACCOUNTING_ACCOUNTS_QUERY } from 'modules/App/queries/queries';
import useNotifications from 'modules/Notifications/hooks/useNotifications';

import fetchAccountingAccounts from '../services/fetchAccountingAccounts';
import messages from './messages';

function useFetchAccountingAccounts() {
  const notifications = useNotifications();
  const { data, refetch, isLoading, isError } = useQuery(
    ACCOUNTING_ACCOUNTS_QUERY,
    () => fetchAccountingAccounts(),
    {
      onError: () => {
        notifications.error(messages.fetchingError.id);
      },
    }
  );
  return { data, refetch, isLoading, isError };
}

export default useFetchAccountingAccounts;
