import { getConfig } from 'modules/App/config';
import { ApiConfiguration } from 'modules/Integrations/models';
import { API_CONFIGURATION } from 'modules/Integrations/paths';
import request from 'utils/request';

const updateApiConfiguration = async (
  params: ApiConfiguration
): Promise<ApiConfiguration> => {
  const endpoint = (await getConfig()).apiIntegrationApiConfig;

  const { id, ...rest } = params;
  const response = await request<{ data: ApiConfiguration }>(`${endpoint}`, {
    method: 'PUT',
    data: {
      ...rest,
    },
  });

  return response.data.data;
};
export const invalidateQueries = [API_CONFIGURATION];

export default updateApiConfiguration;
