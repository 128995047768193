import { nifIvaPerCountry } from './regex';

export const validateNifIva = (
  vatNumber: string,
  countryCode: string
): boolean => {
  return nifIvaPerCountry[countryCode as string].regex.test(vatNumber);
};

export const isNifIva = (value: string): boolean => {
  return Object.values(nifIvaPerCountry).some((elem) => elem.regex.test(value));
};

export const isNifIvaCountryValid = (value: string | null | undefined) => {
  if (!value) {
    return false;
  }
  return !!nifIvaPerCountry[value];
};
