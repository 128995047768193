import { useInvalidateAndUpdate } from 'modules/App/hooks/useInvalidateAndUpdate';
import useTranslations from 'modules/I18n/hooks/useTranslations';
import { useCloseModal } from 'modules/Modals';
import useNotifications from 'modules/Notifications/hooks/useNotifications';
import deleteRemittanceDocuments, {
  invalidateQueries,
} from 'modules/Remittances/services/deleteRemittanceDocuments';
import { useBlockingMutation } from 'utils/useBlockingMutation';

import messages from '../components/RemittanceDocumentsDeleteModal/messages';
import { DeleteRemittanceDocumentResponse } from '../components/RemittanceDocumentsDeleteModal/types';

export const useDeleteRemittanceDocuments = (callback: () => void) => {
  const { t } = useTranslations();
  const notifications = useNotifications();
  const invalidateAndUpdate = useInvalidateAndUpdate();
  const closeModal = useCloseModal();

  const { mutate } = useBlockingMutation(deleteRemittanceDocuments, {
    onSuccess: (data: DeleteRemittanceDocumentResponse) => {
      const {
        data: { count },
      } = data;
      notifications.success(t(messages.success, { count }), {
        messageKey: messages.success.id,
      });

      callback?.();
      invalidateAndUpdate({ invalidateQueries });
      closeModal();
    },
    onError: () => {
      notifications.error(messages.error.id);
      closeModal();
    },
  });

  return mutate;
};
