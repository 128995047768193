import { FC } from 'react';
import { useHistory } from 'react-router-dom';

import { DocumentKind } from 'modules/Documents/models/document';
import useTranslations from 'modules/I18n/hooks/useTranslations';
import { Box, Button, Link } from 'modules/Ui';
import { IconPhone } from 'modules/Ui/Icons';

import { shopifySuccess } from '../../messages';

interface ShopifyConfigurationSuccessActionsProps {
  needsToConfigureBai: boolean;
  needsToConnectBai: boolean;
}

export const ShopifyConfigurationSuccessActions: FC<
  ShopifyConfigurationSuccessActionsProps
> = (props) => {
  const { t } = useTranslations();

  const { needsToConfigureBai, needsToConnectBai } = props;
  const history = useHistory();

  const navigateReplacingHandler = (location: string) => () =>
    history.replace(location);

  if (needsToConfigureBai) {
    return (
      <Box
        bottom={{ _: '16px', sm: 'auto' }}
        padding={{ _: '16px 0', sm: '0' }}
        position={{ _: 'fixed', sm: 'initial' }}
        tag="nav"
        width={{ _: 'calc(100% - 32px)', sm: '264px' }}
        margin="0 auto"
        display="flex"
        flexDirection="column"
      >
        <Button
          onClick={navigateReplacingHandler('/settings/ticket-bai')}
          variant="primary"
          marginBottom="16px"
        >
          {t(shopifySuccess.shopifyConfigSuccessConfigureCta)}
        </Button>
        <Button onClick={navigateReplacingHandler('/')} variant="stroke">
          {t(shopifySuccess.shopifyConfigSuccessConfigureLater)}
        </Button>
      </Box>
    );
  }

  if (needsToConnectBai) {
    return (
      <Box
        bottom={{ _: '16px', sm: 'auto' }}
        padding={{ _: '16px 0', sm: '0' }}
        position={{ _: 'fixed', sm: 'initial' }}
        tag="nav"
        width={{ _: 'calc(100% - 32px)', sm: '264px' }}
        margin="0 auto"
        display="flex"
        flexDirection="column"
      >
        <Link
          icon={<IconPhone />}
          isExternal
          to="tel:611470055"
          variant="primary"
          marginBottom="16px"
        >
          {t(shopifySuccess.shopifyConfigSuccessConnectCta)}
        </Link>
        <Button
          onClick={navigateReplacingHandler(
            `/documents?documentKind=${DocumentKind.DRAFT}`
          )}
          variant="stroke"
        >
          {t(shopifySuccess.shopifyConfigSuccessConnectLater)}
        </Button>
      </Box>
    );
  }
  return (
    <Box
      bottom={{ _: '16px', sm: 'auto' }}
      padding={{ _: '16px 0', sm: '0' }}
      position={{ _: 'fixed', sm: 'initial' }}
      tag="nav"
      width={{ _: 'calc(100% - 32px)', sm: 'auto' }}
    >
      <Button
        onClick={navigateReplacingHandler('/documents')}
        variant="primary"
        width={{ _: '100%', sm: 'auto' }}
      >
        {t(shopifySuccess.shopifyConfigSuccessButton)}
      </Button>
    </Box>
  );
};
