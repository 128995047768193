import {
  ReactNode,
  DetailedHTMLProps as Props,
  LabelHTMLAttributes as Attributes,
} from 'react';

import {
  color,
  gridArea,
  gridColumn,
  GridColumnProps,
  GridAreaProps,
  ColorProps,
  space,
  SpaceProps,
  layout,
  LayoutProps,
  flexbox,
  FlexboxProps,
  position,
  PositionProps,
  typography,
  TypographyProps,
  system,
} from 'styled-system';

import styled from 'modules/Theme/styled-components';

import Box from '../../../Box';

export interface LabelProps
  extends SpaceProps,
    LayoutProps,
    FlexboxProps,
    PositionProps,
    ColorProps,
    GridAreaProps,
    TypographyProps,
    GridColumnProps {
  children: string | ReactNode;
  className?: string;
  gridColumnEnd?: {} | string;
  gridColumnStart?: {} | string;
  gridRow?: {} | string;
  info?: ReactNode;
}

const StyledLabel = styled(Box)<LabelProps>`
  ${flexbox}
  ${space}
  ${gridArea}
  ${gridColumn}
  ${layout}
  ${position}
  ${color}
  ${typography}
  ${system({
    gridColumnStart: {
      property: 'gridColumnStart',
      transform: (value) => `${value}`,
    },
    gridColumnEnd: {
      property: 'gridColumnEnd',
      transform: (value) => `${value}`,
    },
    gridRow: {
      property: 'gridRow',
      transform: (value) => `${value}`,
    },
  })}
`;

const Label = (
  props: LabelProps & Props<Attributes<HTMLLabelElement>, HTMLLabelElement>
) => {
  const {
    gridColumnStart,
    gridColumnEnd,
    gridRow,
    info,
    children,
    className,
    id,
    ...rest
  } = props;
  return (
    <StyledLabel
      gridColumnStart={gridColumnStart ?? ''}
      gridColumnEnd={gridColumnEnd ?? ''}
      gridArea={gridArea}
      gridRow={gridRow ?? ''}
      htmlFor={info ? undefined : id}
      tag="label"
      {...{ className, ...rest }}
    >
      {children}
    </StyledLabel>
  );
};

StyledLabel.defaultProps = {
  display: 'block',
};

export default Label;
