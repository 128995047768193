import { css } from 'modules/Theme/styled-components';

const tooltipVisibleContent = () => css`
  pointer-events: auto;

  @media (hover: hover) {
    &:hover {
      opacity: 1;
      visibility: visible;
    }
  }

  &:focus {
    opacity: 1;
    visibility: visible;
  }
`;

export default tooltipVisibleContent;
