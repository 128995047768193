import { useState, useEffect, FC } from 'react';

import useTranslations from 'modules/I18n/hooks/useTranslations';
import { Box } from 'modules/Ui';

import { shopifyForm } from '../../messages';
import { IntegrationFormToogle } from '../IntegrationFormToogle';

interface Props {
  isMarked?: boolean;
}

export const ShopifyFormNotificationErrors: FC<Props> = ({
  isMarked = false,
}) => {
  const { t } = useTranslations();
  const [draftNotification, setDraftNotification] = useState(isMarked);
  useEffect(() => {
    setDraftNotification(isMarked);
  }, [isMarked]);
  return (
    <Box
      border="none"
      marginBottom={{ _: '100px', sm: '60px', md: 'initial' }}
      padding="0"
      tag="fieldset"
    >
      <Box
        color="primary300"
        fontSize={12}
        lineHeight={22}
        marginBottom="8px"
        tag="legend"
        textTransform="uppercase"
      >
        {t(shopifyForm.seriesDraftNotificationLegend)}
      </Box>
      <IntegrationFormToogle
        id="sendErrorAlerts"
        name="sendErrorAlerts"
        onChange={() => {
          setDraftNotification(!draftNotification);
        }}
        text={t(shopifyForm.seriesToogle03label)}
        textDescription={t(shopifyForm.seriesToogle03desc)}
        value={draftNotification}
      />
    </Box>
  );
};
