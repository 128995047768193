import { Entity } from 'modules/App/models';

export enum AccountingAccountTypeEnum {
  BANK_ACCOUNT = 'BANK_ACCOUNT',
  CASH_REGISTER = 'CASH_REGISTER',
  CREDIT_CARD = 'CREDIT_CARD',
}

export interface CreateAccountingAccountDto {
  readonly type: AccountingAccountTypeEnum;
  readonly alias: string;
  readonly IBAN?: string;
  readonly swiftCode?: string;
  readonly creditCardNumber?: string;
  readonly creditCardExpireDate?: string;
}
// At backend we use the same interface for update
export interface UpdateAccountingAccountDto {
  readonly id: string;
  readonly type?: AccountingAccountTypeEnum;
  readonly alias?: string;
  readonly IBAN?: string;
  readonly swiftCode?: string;
  readonly creditCardNumber?: string;
  readonly creditCardExpireDate?: string;
}

export interface DeleteAccountingAccountDto {
  readonly id: string;
}

export interface AccountingAccount extends Partial<Entity> {
  readonly type: AccountingAccountTypeEnum;
  readonly alias: string;
  readonly IBAN: string | null;
  readonly swiftCode: string | null;
  readonly creditCardNumber: string | null;
  readonly creditCardExpireDate: string | null;
}

export enum AccountingAccountBankEntity {
  ABANCA = '2080',
  BANKINTER = '0128',
  BBVA = '0182',
  CAIXABANK = '2100',
  CAJAMAR = '3058',
  EVOBANK = '0239',
  IBERCAJA = '2085',
  ING = '1465',
  KUTXA = '2095',
  MARCH = '0061',
  SABADELL = '0081',
  SANTANDER = '0049',
}

export enum AccountingAccountBankLogo {
  ABANCA = 'abanca',
  BANCO = 'banco',
  BANKINTER = 'bankinter',
  BBVA = 'bbva',
  CAIXABANK = 'caixabank',
  CAJAMAR = 'cajamar',
  CARD = 'tarjeta',
  CASH = 'caja',
  EVOBANK = 'evobank',
  IBERCAJA = 'ibercaja',
  ING = 'ing',
  KUTXA = 'kutxabank',
  MARCH = 'march',
  SABADELL = 'sabadell',
  SANTANDER = 'santander',
}
