import { defineMessages } from 'react-intl';

export default defineMessages({
  creationDate: {
    id: 'gofre.Documents.DocumentDataDisplay.creation-date',
  },
  code: { id: 'gofre.Documents.twoStepsForm.Form.code' },
  vatNumber: { id: 'gofre.Documents.twoStepsForm.Form.vat-number' },
  automaticAssignment: {
    id: 'gofre.Documents.twoStepsForm.Form.automatic-assignment',
  },
  continueButton: {
    id: 'gofre.Documents.twoStepsForm.continue-button',
  },
  removeClient: {
    id: 'gofre.Documents.twoStepsForm.remove-client',
  },
  optionLabelAddContact: {
    id: 'gofre.Documents.twoStepsForm.option-label.add-contact',
  },
  selectContactHelpText: {
    id: 'gofre.Documents.twoStepsForm.help-text',
  },
  advancedOptions: {
    id: 'gofre.Document.twoStepsForm.AdvancedOptions',
  },
  advancedOptionsQuotes: {
    id: 'gofre.Quotes.twoStepsForm.AdvancedOptions',
  },
  advancedOptionsDeliveryNotes: {
    id: 'gofre.DeliveryNotes.twoStepsForm.AdvancedOptions',
  },
  ticketBaiOptions: {
    id: 'gofre.Document.twoStepsForm.TicketBaiOptions',
  },
});

export const documentDetailTexts = defineMessages({
  concept: {
    id: 'gofre.Documents.ConceptsStep.Concepts.orderConcept.name',
  },
  exit: {
    id: 'gofre.Documents.ConceptsStep.Concepts.orderConcept.exit',
  },
  getIn: {
    id: 'gofre.Documents.ConceptsStep.Concepts.orderConcept.getIn',
  },
  concepts: { id: 'gofre.Documents.twoStepsForm.Concepts' },
  conceptAdd: { id: 'gofre.Documents.twoStepsForm.Concepts.add' },
  conceptButtonSearch: {
    id: 'gofre.Documents.twoStepsForm.Concepts.search-concept',
  },
  conceptButtonDelete: {
    id: 'gofre.Documents.twoStepsForm.Concepts.delete-concept',
  },
  conceptDescription: {
    id: 'gofre.Documents.twoStepsForm.Concepts.description',
  },
  conceptDescriptionPlaceholder: {
    id: 'gofre.Documents.twoStepsForm.Concepts.description-placeholder',
  },
  conceptDiscountPercentage: {
    id: 'gofre.Documents.twoStepsForm.Concepts.discount-percentageFull',
  },
  conceptSalesEqTaxHelpText: {
    id: 'gofre.Documents.twoStepsForm.Concepts.salesEqTaxHelpText',
  },
  conceptName: { id: 'gofre.Documents.twoStepsForm.Concepts.name' },
  conceptHelpText: { id: 'gofre.Documents.twoStepsForm.Concepts.helpText' },
  conceptBtnLabel: { id: 'gofre.Documents.twoStepsForm.Concepts.btnLabel' },
  conceptNamePlaceholder: {
    id: 'gofre.Documents.twoStepsForm.Concepts.name-placeholder',
  },
  conceptQuantity: {
    id: 'gofre.Documents.twoStepsForm.Concepts.quantityFull',
  },
  conceptRemove: { id: 'gofre.Documents.twoStepsForm.Concepts.remove' },
  conceptTaxPercentage: {
    id: 'gofre.Documents.twoStepsForm.Concepts.tax-percentage',
  },
  taxBaseTotal: { id: 'gofre.Documents.twoStepsForm.Concepts.tax-base' },
  taxBaseSubTotal: { id: 'gofre.Documents.twoStepsForm.Concepts.subtotal' },
  taxBaseSubTotalInfo: {
    id: 'gofre.Documents.twoStepsForm.Concepts.subtotal.info',
  },
  conceptUnitPrice: {
    id: 'gofre.Documents.twoStepsForm.Concepts.unit-price',
  },
  comments: { id: 'gofre.Documents.twoStepsForm.comments' },
  commentsPlaceholder: {
    id: 'gofre.Documents.twoStepsForm.comments-placeholder',
  },
  documentSettings: {
    id: 'gofre.Documents.twoStepsForm.documentSettings',
  },
  documentSettingsModalTitle: {
    id: 'gofre.Documents.twoStepsForm.documentSettings.Modal.title',
  },
  documentSettingsModalSubtitle: {
    id: 'gofre.Documents.twoStepsForm.documentSettings.Modal.subtitle',
  },
  documentSettingsModalSubtitleDocument: {
    id: 'gofre.Documents.twoStepsForm.documentSettings.Modal.subtitle-document',
  },
  documentSettingsModalText: {
    id: 'gofre.Documents.twoStepsForm.documentSettings.Modal.text',
  },
  documentSettingsModalAcceptText: {
    id: 'gofre.Documents.twoStepsForm.documentSettings.Modal.accept-text',
  },
  documentSettingsModalCancelText: {
    id: 'gofre.Documents.twoStepsForm.documentSettings.Modal.cancel-text',
  },
  documentTestSettingsModalTitle: {
    id: 'gofre.Documents.twoStepsForm.documentSettings.Test.Modal.title',
  },
  documentTestSettingsModalText: {
    id: 'gofre.Documents.twoStepsForm.documentSettings.Test.Modal.text',
  },
  documentTestSettingsModalAcceptText: {
    id: 'gofre.Documents.twoStepsForm.documentSettings.Test.Modal.accept-text',
  },
  documentTestSettingsModalCancelText: {
    id: 'gofre.Documents.twoStepsForm.documentSettings.Test.Modal.cancel-text',
  },
  documentRecurrentSettingsModalTitle: {
    id: 'gofre.Documents.twoStepsForm.documentSettings.Recurrent.Modal.title',
  },
  documentRecurrentSettingsModalText: {
    id: 'gofre.Documents.twoStepsForm.documentSettings.Recurrent.Modal.text',
  },
  documentRecurrentSettingsModalAcceptText: {
    id: 'gofre.Documents.twoStepsForm.documentSettings.Recurrent.Modal.accept-text',
  },
  documentRecurrentSettingsModalCancelText: {
    id: 'gofre.Documents.twoStepsForm.documentSettings.Recurrent.Modal.cancel-text',
  },
  documentEditSettingsModalTitle: {
    id: 'gofre.Documents.twoStepsForm.documentSettings.Edit.Modal.title',
  },
  documentEditSettingsModalText: {
    id: 'gofre.Documents.twoStepsForm.documentSettings.Edit.Modal.text',
  },
  documentEditSettingsModalAcceptText: {
    id: 'gofre.Documents.twoStepsForm.documentSettings.Edit.Modal.accept-text',
  },
  documentEditSettingsModalCancelText: {
    id: 'gofre.Documents.twoStepsForm.documentSettings.Edit.Modal.cancel-text',
  },
  hasComments: { id: 'gofre.Documents.twoStepsForm.Options.hasComments' },
  hasCommentsMobile: {
    id: 'gofre.Documents.twoStepsForm.Options.hasComments.mobile',
  },
  quoteHasComments: { id: 'gofre.Quotes.twoStepsForm.Options.hasComments' },
  proformaHasComments: {
    id: 'gofre.Proformas.twoStepsForm.Options.hasComments',
  },
  deliveryNoteHasComments: {
    id: 'gofre.DeliveryNotes.twoStepsForm.Options.hasComments',
  },
  paidAmountCheck: {
    id: 'gofre.twoStepsForm.Documents.Ticket.paidAmountCheck',
  },
  paidAmount: {
    id: 'gofre.twoStepsForm.Documents.Ticket.paidAmount',
  },
  documentOptions: {
    id: 'gofre.Documents.twoStepsForm.document-options',
  },
  invoiceData: { id: 'gofre.Documents.twoStepsForm.invoice-data' },
  retention: { id: 'gofre.Documents.twoStepsForm.retention' },
  retentionsPercentage: {
    id: 'gofre.Documents.twoStepsForm.retentionsPercentage',
  },
  salesEqualizationTax: {
    id: 'gofre.Documents.twoStepsForm.sales-equalization-tax',
  },
  vatNumber: { id: 'gofre.Documents.twoStepsForm.Form.vat-number' },
  continueButton: { id: 'gofre.Documents.twoStepsForm.continue-button' },
  continueButtonMobile: {
    id: 'gofre.Documents.twoStepsForm.continue-button-mobile',
  },
  saveDraft: { id: 'gofre.Documents.Form.save-draft' },
  description: { id: 'gofre.Documents.twoStepsForm.desc' },
  descriptionPlaceholder: {
    id: 'gofre.Documents.twoStepsForm.desc.placeholder',
  },
  BaiHelpText: {
    id: 'gofre.Documents.twoStepsForm.bai.help-text',
  },
  operationDate: { id: 'gofre.Documents.twoStepsForm.operationDate' },
  operationDatePlaceholder: {
    id: 'gofre.Documents.twoStepsForm.operationDate.placeholder',
  },
  taxRegime: { id: 'gofre.Documents.twoStepsForm.taxRegime' },
  operationDateTooltip: {
    id: 'gofre.Documents.twoStepsForm.operationDateTooltip',
  },
  operationDateRectifyDisabledTooltip: {
    id: 'gofre.Documents.twoStepsForm.rectify.operationDateTooltip',
  },
  operationReplacedDisabledTooltip: {
    id: 'gofre.Documents.twoStepsForm.rectify.operationReplacedDisabledTooltip',
  },
  operationDateDisabledTooltip: {
    id: 'gofre.Documents.twoStepsForm.operationDateDisabledTooltip',
  },
  customIRPF: {
    id: 'gofre.Documents.twoStepsForm.customIRPF',
  },
  ticketBaiInfo: {
    id: 'gofre.Documents.twoStepsForm.client.Bai.title',
  },
  descriptionTootip: {
    id: 'gofre.Documents.twoStepsForm.ticketBAi.tooltip',
  },
  warning: {
    id: 'gofre.Documents.twoStepsForm.ticketBAi.warning',
  },
  taxRegimePlaceholder: {
    id: 'gofre.Documents.twoStepsForm.ticketBAi.taxRegime.placeholder',
  },
  taxRegimeIva51Tooltip: {
    id: 'gofre.Documents.twoStepsForm.ticketBAi.taxRegime.iva51Tooltip',
  },
  taxRegimeIva01Tooltip: {
    id: 'gofre.Documents.twoStepsForm.ticketBAi.taxRegime.iva01Tooltip',
  },
  taxRegimeIvaEqualization: {
    id: 'gofre.Documents.twoStepsForm.ticketBAi.taxRegime.taxRegimeIvaEqualization',
  },
  editRecurrentWarning: {
    id: 'gofre.Documents.twoStepsForm.recurrent.edit.warning',
  },
  loadingProducts: {
    id: 'gofre.Documents.twoStepsForm.loadingProducts',
  },
  noProductsFound: {
    id: 'gofre.Documents.twoStepsForm.noProductsFound',
  },
});

export const quotes = defineMessages({
  code: { id: 'gofre.Quotes.twoStepsForm.Form.code' },
});

export const proformas = defineMessages({
  code: { id: 'gofre.Proformas.twoStepsForm.Form.code' },
});

export const deliveryNotes = defineMessages({
  code: { id: 'gofre.deliveryNotes.twoStepsForm.Form.code' },
});

export const taxRegimeDialog = defineMessages({
  title: {
    id: 'gofre.Documents.twoStepsForm.taxRegimeDialog.title',
  },
  text01: {
    id: 'gofre.Documents.twoStepsForm.taxRegimeDialog.text01',
  },
  text02: {
    id: 'gofre.Documents.twoStepsForm.taxRegimeDialog.text02',
  },
  cancel: {
    id: 'gofre.Documents.twoStepsForm.taxRegimeDialog.cancel',
  },
  save: {
    id: 'gofre.Documents.twoStepsForm.taxRegimeDialog.save',
  },
});

export const contactTaxExemptionDialog = defineMessages({
  title: {
    id: 'gofre.Documents.twoStepsForm.contactTaxExemptionDialog.title',
  },
  text: {
    id: 'gofre.Documents.twoStepsForm.contactTaxExemptionDialog.text',
  },
  cancel: {
    id: 'gofre.Documents.twoStepsForm.contactTaxExemptionDialog.cancel',
  },
  save: {
    id: 'gofre.Documents.twoStepsForm.contactTaxExemptionDialog.save',
  },
});

export const taxRegimeMessages = defineMessages({
  taxRegime_11_12_13: {
    id: 'gofre.Documents.twoStepsForm.taxRegime.taxRegime_11_12_13',
  },
});

export const ticketBaiOptions = defineMessages({
  text: {
    id: 'gofre.Documents.twoStepsForm.ticketBaiOptions.text',
  },
});

export const changeCurrency = defineMessages({
  title: {
    id: 'gofre.Documents.twoStepsForm.currency.title',
  },
  label: {
    id: 'gofre.Documents.twoStepsForm.currency.label',
  },
  info: {
    id: 'gofre.Documents.twoStepsForm.currency.info',
  },
});
