import { useEffect, useState, forwardRef } from 'react';
import GReCAPTCHA from 'react-google-recaptcha';

import { useField } from 'formik';
import { SpaceProps, space } from 'styled-system';

import { getConfig } from 'modules/App/config';
import styled from 'modules/Theme/styled-components';

import Description from '../Form/Commons/Description';

const Wrapper = styled.div<Props>`
  .grecaptcha-badge {
    visibility: hidden;
  }
  width: 304px;

  ${space}
  ${({ center }) => center && 'margin: 0 auto;'}
`;

interface Props extends SpaceProps {
  center?: boolean;
}

const ReCaptcha = forwardRef<GReCAPTCHA, Props>(({ center }, ref) => {
  const [key, setKey] = useState('');

  const [, meta, helpers] = useField('recaptcha');

  useEffect(() => {
    const fetchKey = async () => {
      const { recaptchaKey } = await getConfig();
      setKey(recaptchaKey);
    };
    // eslint-disable-next-line no-console
    fetchKey().catch(console.error);
  }, []);

  if (!key) {
    return null;
  }

  const onChange = (recaptcha: string | null) => {
    helpers.setError('');
    helpers.setValue(recaptcha);
  };

  const { error } = meta;

  return (
    <Wrapper center={center}>
      <GReCAPTCHA ref={ref} size="normal" sitekey={key} onChange={onChange} />
      <Description
        hasError={!!error}
        id="description-recaptcha"
        data-testid="description-recaptcha"
      >
        {error}
      </Description>
    </Wrapper>
  );
});

export default ReCaptcha;
