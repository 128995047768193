import { ReactNode } from 'react';

import {
  space,
  SpaceProps,
  flexbox,
  FlexboxProps,
  position,
  PositionProps,
} from 'styled-system';

import styled from 'modules/Theme/styled-components';

import Icon from '../Icon';

export interface IconByStringProps
  extends SpaceProps,
    FlexboxProps,
    PositionProps {
  active?: boolean;
  children?: string | ReactNode;
  className?: string;
  color?: string;
  fill?: string;
  /** Defines the color of the icon's internal path or rect */
  fillColor?: string;
  height?: string | number;
  size?: string | number;
  stroke?: string;
  /**  Is a presentation attribute defining the width of the stroke to be applied to the shape. */
  strokeWidth?: string | number;
  /**  Defines the position and dimension, in user space, of an SVG viewport */
  viewBox?: string;
  onClick?: () => void;
  /**  Defines color of the svg internal path */
  innerPathColor?: string;
  transform?: string;
}

const StyledIcon = styled(Icon)<IconByStringProps>`
  fill: ${({ fill }) => (fill ? `${fill}` : 'currentColor')};
  stroke: ${({ stroke }) => (stroke ? `${stroke}` : 'currentColor')};
  ${flexbox}
  ${space}
  ${position}
  ${({ transform }) => transform && `transform: ${transform};`}
  ${({ transition }) => transition && 'transition: transform 0.3s ease-in;'}
`;

const IconByString = (props: IconByStringProps) => {
  const {
    active,
    children,
    className,
    color,
    fill,
    fillColor,
    height,
    innerPathColor,
    onClick,
    size,
    stroke,
    strokeWidth = 0,
    transform,
    viewBox,
    ...rest
  } = props;

  return (
    <StyledIcon
      active={active}
      className={className}
      color={color}
      fill={fill}
      fillColor={fillColor}
      height={height}
      innerPathColor={innerPathColor}
      onClick={onClick}
      size={size}
      stroke={stroke}
      strokeWidth={strokeWidth}
      transform={transform}
      viewBox={viewBox}
      {...{
        ...rest,
      }}
    >
      {children}
    </StyledIcon>
  );
};

export default IconByString;
