import { getConfig } from 'modules/App/config';
import {
  CONTACTS_LIMIT_REACHED,
  CONTACTS_QUERY,
} from 'modules/App/queries/queries';
import { getSource } from 'utils/getSource';
import request from 'utils/request';

import { Contact, ContactCreated } from '../../models';
import normalizePayload from '../helpers/normalizePayload';

const createContact = async (
  contact: Contact
): Promise<{ data: ContactCreated }> => {
  const endpoint = (await getConfig()).apiContactsService;
  const source = getSource();
  const payload = normalizePayload({
    ...contact,
    source,
    lastSource: source,
  });
  const response = await request<{ data: ContactCreated }>(endpoint, {
    method: 'POST',
    data: { isCustomer: true, ...payload },
  });

  return response.data;
};

export const invalidateQueries = [CONTACTS_QUERY, CONTACTS_LIMIT_REACHED];

export enum Errors {
  CREATE_NOT_AVAILABLE = 'contacts::create not available',
}

export default createContact;
