import React, { ReactNode } from 'react';

import {
  color,
  ColorProps,
  flexbox,
  FlexboxProps,
  layout,
  LayoutProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
} from 'styled-system';

import useTheme from 'modules/Theme/hooks/useTheme';
import styled from 'modules/Theme/styled-components';
import Box from 'modules/Ui/Box';

interface Item {
  id?: string;
  itemChildren?: ReactNode;
  text?: string;
}

interface Props
  extends LayoutProps,
    FlexboxProps,
    SpaceProps,
    ColorProps,
    TypographyProps {
  itemsSize?: 'small' | 'default';
  itemsAlign?: string | {};
  items: Item[];
}

const Wrapper = styled(Box)`
  ${color}
  ${layout}
  ${flexbox}
  ${space}
  ${typography}
`;

const List = ({ items, itemsSize, itemsAlign, fontSize, ...props }: Props) => {
  const theme = useTheme();
  return (
    <Wrapper tag="ul" {...{ ...props }}>
      {items.map(({ text, itemChildren }) => {
        return (
          <React.Fragment key={text}>
            {text && (
              <Box
                textAlign={itemsAlign}
                marginBottom="4px"
                tag="li"
                fontSize={itemsSize === 'small' ? '12px' : fontSize}
              >
                <span aria-hidden="true">{'-> '}</span> {text}
              </Box>
            )}
            {itemChildren && (
              <Box
                borderBottom={`1px solid ${theme.colors.gray300}`}
                padding="16px"
                tag="li"
              >
                {itemChildren}
              </Box>
            )}
          </React.Fragment>
        );
      })}
    </Wrapper>
  );
};

export default List;
