import { FC } from 'react';
import { useSelector } from 'react-redux';

import { selectIsDesktop } from 'modules/App/store/selectors';
import { Box, MarkDown, Text, Img } from 'modules/Ui';

interface ItemProps {
  title: string;
  description: string;
  imgageSrc: string;
}

export const AdvantageItem: FC<ItemProps> = ({
  title,
  description,
  imgageSrc,
}) => {
  const isDesktop = useSelector(selectIsDesktop);
  return (
    <Box
      display={{ sm: 'flex', md: 'block' }}
      gap={{ sm: '16px', md: '0' }}
      tag="li"
    >
      <Img
        height={isDesktop ? 178 : 112}
        loading="eager"
        marginBottom={{ _: '16px', sm: 'initial', md: '24px' }}
        src={imgageSrc}
        width={isDesktop ? 277 : 177}
      />
      <Box tag="span">
        <Text
          color="primary500"
          display="block"
          fontSize={24}
          fontWeight="600"
          lineHeight={30}
          marginBottom={{ _: '12px', md: '24px' }}
          tag="span"
        >
          {title}
        </Text>
        <Box
          color="gray600"
          fontSize={{ _: 14, md: 18 }}
          lineHeight={{ _: 20, md: 26 }}
          tag="span"
        >
          <MarkDown text={description} />
        </Box>
      </Box>
    </Box>
  );
};
