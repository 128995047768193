import { getConfig } from 'modules/App/config';
import { CustomerServiceResponse } from 'modules/Auth/types';
import request from 'utils/request';

const getChatToken = async () => {
  const config = await getConfig();

  const endpoint = `${config.apiAuthService}/customer-service`;
  const response = await request<CustomerServiceResponse>(endpoint, {
    method: 'GET',
  });
  return response.data.data;
};

export default getChatToken;
