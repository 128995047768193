import useHasSeveralBusinesses from 'modules/Business/hooks/useHasSeveralBusinesses';
import useTranslations from 'modules/I18n/hooks/useTranslations';
import { ModalTypes } from 'modules/Modals/constants';
import useOpenModal from 'modules/Modals/hooks/useOpenModal';
import IconUpdate from 'modules/Ui/Icons/IconUpdate';
import isNativeApp from 'utils/native/isNativeApp';

import { SubmenuSections } from '../constants';
import useIsBusinessBlocked from '../hooks/useIsBusinessBlocked';
import { menu } from '../messages';
import MenuButton from './Components/MenuButton';
import MenuLinks from './MenuLinks';

interface Props {
  closeMenu: () => void;
  isBookkeeper?: boolean;
  openSubMenu: (section: SubmenuSections) => () => void;
}

const LinksSelector: React.FC<Props> = ({
  openSubMenu,
  closeMenu,
  isBookkeeper,
}) => {
  const isBusinessBlocked = useIsBusinessBlocked();
  const hasSeveralBusinesses = useHasSeveralBusinesses();
  const { t } = useTranslations();
  const onChangeBusinessClick = useOpenModal({
    type: ModalTypes.CHANGE_BUSINESS,
  });

  const openChangeBussinesModal = () => {
    closeMenu();
    onChangeBusinessClick();
  };

  if (isNativeApp()) {
    return (
      <MenuLinks
        openSubMenu={openSubMenu}
        closeMenu={closeMenu}
        {...{ isBookkeeper }}
      />
    );
  }
  return (
    <>
      {!isBusinessBlocked ? (
        <MenuLinks
          openSubMenu={openSubMenu}
          closeMenu={closeMenu}
          {...{ isBookkeeper }}
        />
      ) : (
        <>
          {hasSeveralBusinesses && (
            <MenuButton
              onClick={openChangeBussinesModal}
              icon={<IconUpdate />}
              text={t(menu.changeBusiness)}
            />
          )}
        </>
      )}
    </>
  );
};

export default LinksSelector;
