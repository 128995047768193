import { AxiosResponse } from 'axios';

import { getConfig } from 'modules/App/config';
import { REMITTANCES_QUERY } from 'modules/App/queries/queries';
import request from 'utils/request';

const downloadRemittance = async (id: string): Promise<AxiosResponse<Blob>> => {
  const endpoint = (await getConfig()).apiRemittancesService;

  const response = await request<Blob>(`${endpoint}/${id}/xml`, {
    method: 'GET',
    responseType: 'blob',
  });
  if (response.status < 200 || response.status >= 400) {
    throw new Error('Error descargando XML');
  }

  return response;
};

export const invalidateQueries = [REMITTANCES_QUERY];

export default downloadRemittance;
