import {
  useCallback,
  useMemo,
  useRef,
  useState,
  ChangeEvent,
  useEffect,
} from 'react';

import { useField } from 'formik';

import { DigitLimits } from 'modules/App/constants';
import Field from 'modules/Ui/Form/Field';

import { FormikFieldProps } from './FormikFieldProps';
import useFocusHandler from './useFocusHandler';

interface FieldProps extends FormikFieldProps {
  hasIcon?: boolean;
  bypassDigitsLimit?: boolean;
  integerDigits?: number;
  decimalDigits?: number;
  onlyPositives?: boolean;
  onlyIntegers?: boolean;
  noSpecialCharacters?: boolean;
  noSpecialCharactersRegexp?: RegExp;
  minNumber?: number;
}

const FormikField = (props: FieldProps) => {
  const {
    autoTrim = true,
    helpText,
    hidden,
    icon,
    iconAriaLabel,
    iconOnClick,
    id,
    label,
    labelHidden,
    maxLength,
    name,
    onBlur,
    placeholder,
    required,
    requiredWithoutAsterisk,
    type = 'text',
    onChange,
    bypassDigitsLimit = false,
    integerDigits = DigitLimits.INTEGER_DIGITS,
    decimalDigits = DigitLimits.DECIMAL_DIGITS,
    hasIcon,
    onlyPositives,
    onlyIntegers,
    minNumber,
    noSpecialCharacters = false,
    noSpecialCharactersRegexp = new RegExp(/^$|^[a-z0-9]+$/i),
    disabled,
    gridArea,
    gridColumn,
    gridColumnStart,
    gridColumnEnd,
    borderColor,
    infoDelay,
    ...rest
  } = props;

  const [innerValue, setInnerValue] = useState(props.value);
  const onChangeDebounceRef = useRef<ReturnType<typeof setTimeout>>();

  const [field, meta, helpers] = useField(name ?? id);

  useEffect(() => {
    setInnerValue(field.value);
  }, [field.value, setInnerValue]);

  const onBlurHandler = useCallback(
    (event) => {
      event.stopPropagation();
      let { value } = event.target;
      if ((autoTrim && type === 'text') || type === 'number') {
        if (value) {
          value = value.trim();
        }
      }
      if (type === 'number') {
        if (value !== '') {
          value = Number(value);
          if (typeof minNumber === 'number' && Number(value) < minNumber) {
            value = minNumber;
          }
        }
      }
      helpers.setValue(value);

      setTimeout(() => {
        field.onBlur(event);
        onBlur?.(event);
        onChange?.(event);
      });
    },
    [autoTrim, onBlur, field, helpers]
  );

  const onFocusHandler = useFocusHandler(name ?? id);
  const shouldLimit = type === 'number' && !bypassDigitsLimit;

  // positive and negative numbers with integer and decimal digit limitation
  const regexp = new RegExp(
    `^-?[0-9]{0,${integerDigits}}([\\.,][0-9]{0,${decimalDigits}})?$`
  );

  const onChangeLimitHandler = useCallback(
    (event) => {
      const { value } = event.target;
      const trimmedValue = value.trim();
      if (maxLength && value.length > maxLength) return;
      if (trimmedValue !== '' && isNaN(value)) return;
      if (!regexp.test(value)) {
        // eslint-disable-next-line no-param-reassign
        event.target.value = innerValue;
        return;
      }
      const valueNumber = parseFloat(value);

      if (onChangeDebounceRef.current) {
        clearTimeout(onChangeDebounceRef.current);
      }

      if (trimmedValue !== '') {
        setInnerValue(valueNumber);
        onChangeDebounceRef.current = setTimeout(() => {
          onChange?.(event);
          helpers.setValue(valueNumber);
          helpers.setTouched(true);
        }, 100);
      } else {
        helpers.setValue('');
        setInnerValue('');
        onChangeDebounceRef.current = setTimeout(() => {
          onChange?.(event);
          helpers.setValue('');
          helpers.setTouched(true);
        }, 100);
      }
    },
    [helpers.setValue, regexp, maxLength, setInnerValue]
  );

  const onChangeHandler = useCallback(
    (event: ChangeEvent<{ value: string }>) => {
      if (onChangeDebounceRef.current) {
        clearTimeout(onChangeDebounceRef.current);
      }
      const { value } = event.target;
      setInnerValue(value);
      onChangeDebounceRef.current = setTimeout(() => {
        onChange?.(event);
        helpers.setValue(value);
        helpers.setTouched(true);
      }, 100);
    },
    [setInnerValue, helpers.setValue]
  );

  const onChangeNoSpecialCharacters = useCallback(
    (event) => {
      if (onChangeDebounceRef.current) {
        clearTimeout(onChangeDebounceRef.current);
      }
      const { value } = event.target;
      if (!noSpecialCharactersRegexp.test(value)) return;
      setInnerValue(value);
      onChangeDebounceRef.current = setTimeout(() => {
        onChange?.(event);
        helpers.setValue(value);
        helpers.setTouched(true);
      }, 100);
    },
    [setInnerValue, helpers.setValue, noSpecialCharactersRegexp]
  );

  const getOnChangeHandler = useCallback(() => {
    if (noSpecialCharacters) {
      return onChangeNoSpecialCharacters;
    }
    if (shouldLimit) {
      return onChangeLimitHandler;
    }
    return onChangeHandler;
  }, [
    shouldLimit,
    noSpecialCharacters,
    onChangeHandler,
    onChangeLimitHandler,
    onChangeNoSpecialCharacters,
  ]);

  const handleOnKeyDown = useCallback(
    (event) => {
      if (type !== 'number') return;

      const keys = ['e', 'E'];
      if (event.key === '-' && onlyPositives) {
        event.preventDefault();
      }

      if (onlyIntegers) {
        keys.push('.');
        keys.push(',');
      }

      if (keys.includes(event.key)) {
        event.preventDefault();
      }
    },
    [type]
  );

  const handleOnPaste = useCallback(
    (event) => {
      if (type !== 'number') return;
      const pasted = event.clipboardData.getData('text').toLowerCase();
      const { value } = event.target;
      const totalLength = value.length + pasted.length;
      const num = Number(pasted.slice(0, maxLength).replace(',', '.'));

      if ((num < 0 || Number.isNaN(num)) && onlyPositives) {
        event.preventDefault();
      }

      if (onlyIntegers && !/^\d*$/.test(pasted)) {
        event.preventDefault();
      }

      if (
        pasted.includes('e') ||
        (maxLength && totalLength > maxLength) ||
        !regexp.test(pasted)
      ) {
        if (value.trim() === '' && pasted.length > 0) {
          if (!Number.isNaN(num)) {
            helpers.setValue(num);
            helpers.setTouched(true);
          }
        }
        event.preventDefault();
      }
    },
    [maxLength]
  );

  const onChangeField = getOnChangeHandler();
  const { multiple, checked } = field;

  const memoized = useMemo(() => {
    return hidden ? null : (
      <Field
        autoComplete="off"
        {...{
          borderColor,
          helpText,
          icon,
          id,
          iconOnClick,
          iconAriaLabel,
          label,
          labelHidden,
          maxLength,
          placeholder,
          required,
          requiredWithoutAsterisk,
          type,
          hasIcon,
          multiple,
          checked,
          disabled,
          gridArea,
          gridColumnStart,
          gridColumnEnd,
          gridColumn,
          infoDelay,
          ...rest,
        }}
        onChange={onChangeField}
        onFocus={onFocusHandler}
        error={meta.touched && meta.error ? meta.error : undefined}
        onBlur={onBlurHandler}
        name={name ?? id}
        onKeyDown={handleOnKeyDown}
        onPaste={handleOnPaste}
        value={innerValue}
      />
    );
  }, [
    innerValue,
    meta.touched,
    meta.error,
    multiple,
    checked,
    helpText,
    disabled,
    onChange,
    icon,
    required,
    name,
    id,
  ]);
  return memoized;
};

export default FormikField;
