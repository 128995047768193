import { getConfig } from 'modules/App/config';
import request from 'utils/request';

import { CreateLeadResponse } from '../types';

export interface CreateLeadArguments {
  email: string;
  password?: string;
  isBookkeeper?: boolean;
  utmCampaign?: string;
  utmMedium?: string;
  utmSource?: string;
  landing?: string;
  campaign?: string;
  country?: string;
}

const createLead = async (args: CreateLeadArguments) => {
  const endpoint = (await getConfig()).apiLeadsService;
  const {
    email,
    password,
    isBookkeeper,
    utmCampaign,
    utmMedium,
    utmSource,
    landing,
    campaign,
    country,
  } = args;
  const payload = {
    email,
    password,
    isBookkeeper,
    utmCampaign,
    utmMedium,
    utmSource,
    landing,
    campaign,
    country,
  };
  const response = await request<CreateLeadResponse>(
    endpoint,
    {
      method: 'POST',
      data: payload,
    },
    { requireAuth: false }
  );

  return response.data.data;
};
export default createLead;
