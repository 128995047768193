import { defineMessages } from 'react-intl';

export const actionBlocked = defineMessages({
  header: {
    id: 'gofre.NativeApp.ActionBlocked.header',
  },
  message: {
    id: 'gofre.NativeApp.ActionBlocked.message',
  },
  primaryAction: {
    id: 'gofre.NativeApp.ActionBlocked.primaryAction',
  },
  secondaryAction: {
    id: 'gofre.NativeApp.ActionBlocked.secondaryAction',
  },
  tertiaryAction: {
    id: 'gofre.NativeApp.ActionBlocked.tertiaryAction',
  },
  phone: {
    id: 'gofre.NativeApp.ActionBlocked.phone',
  },
  email: {
    id: 'gofre.NativeApp.ActionBlocked.email',
  },
});

export const trialEnds = defineMessages({
  header: {
    id: 'gofre.NativeApp.TrialEnds.header',
    defaultMessage: '¡Vaya! Tu periodo de prueba ha finalizado.',
  },
  message: {
    id: 'gofre.NativeApp.TrialEnds.message',
  },
  primaryAction: {
    id: 'gofre.NativeApp.TrialEnds.primaryAction',
  },
  secondaryAction: {
    id: 'gofre.NativeApp.TrialEnds.secondaryAction',
  },
  tertiaryAction: {
    id: 'gofre.NativeApp.TrialEnds.tertiaryAction',
  },
  phone: {
    id: 'gofre.NativeApp.TrialEnds.phone',
  },
  email: {
    id: 'gofre.NativeApp.TrialEnds.email',
  },
});

export const addContactBlocked = defineMessages({
  header: {
    id: 'gofre.NativeApp.AddContactBlocked.header',
    defaultMessage: '¡Vaya! No puedes crear más contactos con tu suscripción.',
  },
  message: {
    id: 'gofre.NativeApp.AddContactBlocked.message',
    defaultMessage: 'Para solucionarlo actualiza tu cuenta desde la web.',
  },
});

export const addProductBlocked = defineMessages({
  header: {
    id: 'gofre.NativeApp.AddProductBlocked.header',
    defaultMessage: '¡Vaya! No puedes crear más productos con tu suscripción.',
  },
  message: {
    id: 'gofre.NativeApp.AddProductBlocked.message',
    defaultMessage: 'Para solucionarlo actualiza tu cuenta desde la web.',
  },
});

export const warning = defineMessages({
  title: {
    id: 'gofre.NativeApp.UpdateWarning.title',
    defaultMessage: 'Actualiza tu plan',
  },
  header: {
    id: 'gofre.NativeApp.UpdateWarning.header',
    defaultMessage:
      '¡Vaya! No puedes crear facturas recurrentes con tu suscripción',
  },
  message: {
    id: 'gofre.NativeApp.UpdateWarning.message',
    defaultMessage: 'Para solucionarlo actualiza tu cuenta desde la web',
  },
});
