import ReactMaskedInput from 'react-maskedinput';

import styled from 'modules/Theme/styled-components';

import inputStyles from '../../Commons/styles/inputStyles';

const MaskedInput = styled(ReactMaskedInput)`
  ${inputStyles}
`;

export default MaskedInput;
