import { FC } from 'react';

import useTranslations from 'modules/I18n/hooks/useTranslations';
import { Dialog, useCloseModal } from 'modules/Modals';
import { Text, Link, Button } from 'modules/Ui';
import { IconPhone } from 'modules/Ui/Icons';

import { dialogMessages } from './messages';

const BaiConnectionModal: FC = () => {
  const { t } = useTranslations();
  const closeModal = useCloseModal();
  return (
    <Dialog
      title={t(dialogMessages.title)}
      onClose={closeModal}
      id="bai-connection"
      buttonSecondary={
        <Button
          data-testid="bai-connection-request"
          marginBottom={{ _: '8px', sm: '0' }}
          onClick={closeModal}
          variant="stroke"
        >
          {t(dialogMessages.buttonSecondary)}
        </Button>
      }
      buttonPrimary={
        <Link
          data-testid="bai-connection-request"
          height={{ md: '40px' }}
          icon={<IconPhone size={22} marginRight="8px" />}
          isExternal
          to={`tel:${t(dialogMessages.phone)}`}
          variant="primary"
        >
          {t(dialogMessages.buttonPrimary)}
        </Link>
      }
    >
      <Text fontSize={16} lineHeight={22} marginBottom="46px">
        {t(dialogMessages.text)}
      </Text>
    </Dialog>
  );
};

export default BaiConnectionModal;
