enum ActionTypes {
  RECEIVE_SERVER_NOTIFICATION = 'gofre/notifications/RECEIVE_SERVER_NOTIFICATION',
  ADD_NOTIFICATION = 'gofre/notifications/ADD_NOTIFICATION',
  REMOVE_NOTIFICATION = 'gofre/notifications/REMOVE_NOTIFICATION',
  CLEAR_NOTIFICATIONS = 'gofre/notifications/CLEAR_NOTIFICATIONS',
  ADD_BUBBLE_NOTIFICATION = 'gofre/notifications/ADD_BUBBLE__NOTIFICATION',
  REMOVE_BUBBLE_NOTIFICATION = 'gofre/notifications/REMOVE_BUBBLE__NOTIFICATION',
  CLEAR_BUBBLE_NOTIFICATIONS = 'gofre/notifications/CLEAR_BUBBLE__NOTIFICATIONS',
  ADD_COMMERCIAL_NOTIFICATION = 'gofre/notifications/ADD_COMMERCIAL_NOTIFICATION',
  REMOVE_COMMERCIAL_NOTIFICATION = 'gofre/notifications/REMOVE_COMMERCIAL_NOTIFICATION',
}

export default ActionTypes;
