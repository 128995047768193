import {
  FC,
  ReactNode,
  DetailedHTMLProps as Props,
  ButtonHTMLAttributes as Attributes,
} from 'react';

import {
  BackgroundColorProps,
  border,
  BorderProps,
  display,
  DisplayProps,
  flexbox,
  FlexboxProps,
  grid,
  GridProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
  system,
} from 'styled-system';

import {
  buttonPrimaryStyles,
  buttonSecondaryStyles,
  buttonStrokeStyles,
  buttonGostStyles,
  buttonGostDarkStyles,
  buttonLinkStyle,
  buttonCustomIconTextStyle,
} from 'modules/Theme/mixins/buttonImports';
import styled from 'modules/Theme/styled-components';

import Box from '../../Box';
import HtmlButton from '../../Html/Button';

export interface ButtonProps
  extends BorderProps,
    DisplayProps,
    FlexboxProps,
    GridProps,
    LayoutProps,
    PositionProps,
    SpaceProps,
    TypographyProps,
    Pick<BackgroundColorProps, 'backgroundColor'>,
    Omit<Props<Attributes<HTMLButtonElement>, HTMLButtonElement>, 'onClick'> {
  accessibleText?: string;
  active?: boolean;
  boxSizing?: 'content-box' | 'border-box';
  children?: string | ReactNode;
  className?: string;
  color?: string;
  columnGap?: {} | string;
  disabled?: boolean;
  gap?: {} | string;
  hide?: boolean;
  icon?: ReactNode;
  iconPosition?: 'start' | 'end';
  onClick?: (value: any) => void;
  placeItems?: {} | string;
  textDecoration?: string;
  type?: 'button' | 'reset' | 'submit';
  variant?:
    | 'primary'
    | 'secondary'
    | 'stroke'
    | 'link'
    | 'ghost'
    | 'ghostDark'
    | 'light';
}

export const StyledButton = styled(HtmlButton)`
  ${({ variant }) => variant === 'primary' && buttonPrimaryStyles}
  ${({ variant }) => variant === 'secondary' && buttonSecondaryStyles}
  ${({ variant }) => variant === 'stroke' && buttonStrokeStyles}
  ${({ variant }) => variant === 'link' && buttonLinkStyle}
  ${({ variant }) => variant === 'ghost' && buttonGostStyles}
  ${({ variant }) => variant === 'ghostDark' && buttonGostDarkStyles}
  ${({ variant, icon }) => !variant && icon && buttonCustomIconTextStyle}
  ${({ boxSizing }) => boxSizing && `box-sizing: ${boxSizing};`}
  ${({ color }) => color && `color: ${color};`}
  ${({ backgroundColor }) =>
    backgroundColor && `background-color: ${backgroundColor};`}
  ${({ textDecoration }) =>
    textDecoration && `text-decoration: ${textDecoration};`}
  ${border}
  ${grid}
  ${display}
  ${flexbox}
  ${layout}
  ${position}
  ${space}
  ${typography}
  ${system({
    gap: {
      property: 'gap',
      transform: (value) => `${value}`,
    },
    columnGap: {
      property: 'columnGap',
      transform: (value) => `${value}`,
    },
    placeItems: {
      property: 'placeItems',
      scale: 'placeItems',
    },
  })}
  `;

const Button: FC<ButtonProps> = ({
  accessibleText,
  active,
  backgroundColor,
  boxSizing,
  children,
  className,
  color,
  columnGap,
  disabled,
  fontWeight,
  gap,
  hide,
  icon,
  iconPosition = 'start',
  onClick,
  placeItems,
  textDecoration,
  type = 'button',
  variant,
  ...rest
}) => {
  const calculateIconPosition = () => {
    if (icon) {
      if (iconPosition === 'start') {
        return '24px 1fr';
      }
      if (iconPosition === 'end') {
        return '1fr 24px';
      }
    }
    return undefined;
  };

  return (
    <StyledButton
      active={active}
      aria-label={accessibleText}
      backgroundColor={backgroundColor}
      boxSizing={boxSizing}
      className={className}
      color={color}
      columnGap={icon ? '14px' : ''}
      display={icon && 'grid'}
      fontWeight={fontWeight || 'normal'}
      gridTemplateColumns={calculateIconPosition()}
      icon={icon}
      onClick={onClick}
      type={type}
      variant={variant}
      disabled={disabled}
      {...{ ...rest }}
    >
      {icon && iconPosition === 'start' && <>{icon}</>}
      {variant ? (
        <Box
          color="inherit"
          fontSize="inherit"
          position="relative"
          tag="span"
          textAlign="left"
          whiteSpace="nowrap"
        >
          {children}
        </Box>
      ) : (
        <>{children}</>
      )}
      {icon && iconPosition === 'end' && <>{icon}</>}
    </StyledButton>
  );
};

export default Button;
