import { Concept } from 'modules/Documents/models/document';

const processConcepts = (concepts: Concept[]) => {
  return concepts.map((concept) => ({
    ...concept,
    total: concept.totalAmount,
    ...(!!concept.product && {
      productValues: {
        name: concept.name,
        sellingPrice: concept.unitPrice,
        taxKey: concept.taxKey,
        description: concept.description,
      },
    }),
    groupName: concept.groupName || '',
    id: Math.random().toString(),
  }));
};

export default processConcepts;
