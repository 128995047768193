import { getConfig } from 'modules/App/config';
import request from 'utils/request';

import { Plan } from '../models';

export const fetchSubscriptionsPlans = async (): Promise<Plan[]> => {
  const endpoint = (await getConfig()).apiSubscriptionProductsService;
  const fullEndopoint = `${endpoint}?query[kind]=plan&query[active]=true`;
  const response = await request<{ data: { items: Plan[] } }>(fullEndopoint, {
    method: 'GET',
  });
  return response.data.data.items;
};
