export enum TemplateType {
  DEFAULT = 'default',
  SIMPLE = 'simple',
  ZEBRA = 'zebra',
  CLASSIC = 'classic',
}

export enum LogoSize {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
}
interface TemplateColors {
  primary: string;
  secondary?: string;
  terciary?: string;
}

export interface BaseTemplateSettings {
  name?: string;
  logoSize: LogoSize;
  templateType: TemplateType;
  typography?: string;
  logoUrl?: string;
  isUsed?: boolean;
}

export interface TemplateSettings extends BaseTemplateSettings {
  id: string;
  colors?: TemplateColors;
  hasLogo?: boolean;
  logoVersion?: string;
  language?: string;
}

export interface TemplateSettingsForm extends BaseTemplateSettings {
  templateId: string;
  colors: {
    [TemplateType.DEFAULT]: TemplateColors;
    [TemplateType.SIMPLE]: TemplateColors;
    [TemplateType.ZEBRA]: TemplateColors;
    [TemplateType.CLASSIC]: TemplateColors;
  };
}
