import { SpaceProps, LayoutProps } from 'styled-system';

import useTranslations from 'modules/I18n/hooks/useTranslations';

import BasePill from '../BasePill';
import usePaymentStatusMapping from './usePaymentStatusMapping';

interface Props extends SpaceProps, LayoutProps {
  status: string;
  gender?: 'm' | 'f';
}

const PaymentStatus: React.FC<Props> = ({ status, gender = 'm', ...rest }) => {
  const { t } = useTranslations();
  const { backgroundColor, message, color } = usePaymentStatusMapping(status);

  if (!message) {
    return null;
  }

  return (
    <BasePill backgroundColor={backgroundColor} color={color} {...rest}>
      {t(message, { gender })}
    </BasePill>
  );
};

export default PaymentStatus;
