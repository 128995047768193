import { differenceBy } from 'lodash';

import { Contact, emptyContact } from 'modules/Contacts/models';
import { PaymentMethodFormValues } from 'modules/Documents/models/form';
import { CreatedPaymentMethod } from 'modules/PaymentMethods/models/paymentMethods';

export function mapPaymentMethods(
  paymentMethods: CreatedPaymentMethod[] = [],
  enable = true
) {
  return paymentMethods.map((paymentMethod) => {
    const enableWithOverride = paymentMethod.forceDisabled ? false : enable;
    return {
      ...paymentMethod,
      enable: enableWithOverride,
    };
  });
}

function mixAndActivatePaymentMethods(
  allPaymentMethods: CreatedPaymentMethod[] = [],
  activePaymentMethods: CreatedPaymentMethod[] = []
): PaymentMethodFormValues[] {
  const diff = differenceBy(allPaymentMethods, activePaymentMethods, 'id');

  const disabledMethodsToAdd = mapPaymentMethods(diff, false);
  const activeMethodsToAdd = mapPaymentMethods(activePaymentMethods);

  return activeMethodsToAdd.concat(disabledMethodsToAdd);
}

export function processPaymentMethodsFromDocument({
  documentMethods = [],
  defaultMethods = [],
  preserveContactMethods = false,
  updatedContact,
}: {
  documentMethods?: CreatedPaymentMethod[];
  defaultMethods?: CreatedPaymentMethod[];
  preserveContactMethods?: boolean;
  updatedContact?: Contact;
}): PaymentMethodFormValues[] {
  if (documentMethods.some((method) => method.contactId)) {
    if (preserveContactMethods) {
      return mixAndActivatePaymentMethods(
        updatedContact?.paymentMethods,
        documentMethods
      );
    }
    return mixAndActivatePaymentMethods(defaultMethods, defaultMethods);
  }
  return mixAndActivatePaymentMethods(defaultMethods, documentMethods);
}

export function injectContactWithPaymentMethods({
  updatedContact,
  documentPaymentMethods = [],
  documentContact = emptyContact,
}: {
  updatedContact?: Contact;
  documentPaymentMethods?: CreatedPaymentMethod[];
  documentContact?: Contact;
}): Contact {
  const updatedContactPaymentMethods = updatedContact?.paymentMethods || [];
  const updatedContactPaymentMethodsWithOverride =
    addForceDisabledFlagToInactivePaymentMethods({
      activePaymentMethods: documentPaymentMethods.filter(
        (pm) => pm.contactId === updatedContact?.id
      ),
      allPaymentMethods: updatedContactPaymentMethods,
    });

  const documentContactWithUpdatedPaymentMethods = {
    ...documentContact,
    paymentMethods: updatedContactPaymentMethodsWithOverride,
  };

  return documentContactWithUpdatedPaymentMethods;
}

export function addForceDisabledFlagToInactivePaymentMethods({
  activePaymentMethods = [],
  allPaymentMethods = [],
}: {
  activePaymentMethods?: CreatedPaymentMethod[];
  allPaymentMethods?: CreatedPaymentMethod[];
}) {
  const disabledMethodsToAdd = differenceBy(
    allPaymentMethods,
    activePaymentMethods,
    'id'
  ).map((method) => {
    return { ...method, forceDisabled: true };
  });

  return activePaymentMethods.concat(disabledMethodsToAdd);
}
