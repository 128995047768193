import { getConfig } from 'modules/App/config';
import { SUBSCRIPTION } from 'modules/App/queries/queries';
import request from 'utils/request';

export const reactivateSubscription = async (): Promise<string> => {
  const endpoint = (await getConfig()).apiSubscriptionsService;

  const response = await request<string>(`${endpoint}/reactivate`, {
    method: 'POST',
  });
  return response.data;
};

export const invalidateQueries = [SUBSCRIPTION];
