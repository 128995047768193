import { FC, useCallback } from 'react';
import { matchPath, useHistory, useLocation } from 'react-router';

import useTranslations from 'modules/I18n/hooks/useTranslations';
import { MODAL_WIDTH_DEFAULT, Dialog, useCloseModal } from 'modules/Modals';
import { Button } from 'modules/Ui';
import { PAYMENT_ROUTES } from 'pages/payments/routesMap';

import { remittanceModal } from '../../../messages';
import { AddNewRemittanceModalProps, RemittanceType } from '../../../types';
import { AddNewRemittanceForm } from './AddNewRemittanceForm';

const AddNewRemittanceModal: FC<AddNewRemittanceModalProps> = ({
  accountingAccountId,
  accountingAccountAlias,
  remittance,
  preSelectedDocuments,
  totalCount,
  totalAmount,
  remittanceType = RemittanceType.DIRECT_DEBIT,
}) => {
  const { t } = useTranslations();
  const history = useHistory();
  const closeModal = useCloseModal();
  const location = useLocation();
  const handleConfirm = useCallback(
    (values) => {
      history.push({
        pathname: PAYMENT_ROUTES.REMITTANCES_ADD,
        state: values,
      });
      closeModal();
    },
    [closeModal, history]
  );
  const isRemittanceLocation = !!matchPath(location.pathname, [
    PAYMENT_ROUTES.REMITTANCES,
  ]);
  const noRemittanceLocationTitle =
    remittanceType === RemittanceType.DIRECT_DEBIT
      ? t(remittanceModal.titleDocument)
      : t(remittanceModal.titleExpense);

  return (
    <Dialog
      id="add-new-remittance-modal"
      title={
        isRemittanceLocation
          ? t(remittanceModal.title)
          : noRemittanceLocationTitle
      }
      onClose={closeModal}
      dialogWidth={MODAL_WIDTH_DEFAULT}
      buttonPrimary={
        <Button
          data-testid="add-new-remittance-modal-submit"
          variant="secondary"
          form="add-contact"
          type="submit"
        >
          {t(remittanceModal.submit)}
        </Button>
      }
      buttonSecondary={
        <Button
          data-testid="add-new-remittance-modal-cancel"
          onClick={closeModal}
          variant="stroke"
        >
          {t(remittanceModal.cancel)}
        </Button>
      }
    >
      <AddNewRemittanceForm
        onSubmit={handleConfirm}
        initialValues={{
          remittanceExpiration: new Date(),
          type: remittanceType,
          ...remittance,
          preSelectedDocuments,
          totalCount,
          totalAmount,
          accountingAccountAlias,
          accountingAccountId,
          radioIncome: remittanceType === RemittanceType.DIRECT_DEBIT,
          radioExpense: remittanceType === RemittanceType.CREDIT_TRANSFER,
        }}
      />
    </Dialog>
  );
};

export default AddNewRemittanceModal;
