import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: { id: 'gofre.Documents.DataElectronic.title' },
  subtitle: { id: 'gofre.Documents.DataElectronic.subtitle' },
  confirm: { id: 'gofre.Documents.DataElectronic.confirm' },
  cancel: { id: 'gofre.Modals.cancel' },
  dataClient: { id: 'gofre.Documents.DataElectronic.dataClient' },
  infoDataClient: { id: 'gofre.Documents.DataElectronic.infoDataClient' },
  dataEmit: { id: 'gofre.Documents.DataElectronic.dataEmit' },
  infoDataEmit: { id: 'gofre.Documents.DataElectronic.infoDataEmit' },
  dataDir3: { id: 'gofre.Documents.DataElectronic.dataDir3' },
  dir3ExternalUrl: { id: 'gofre.Documents.DataElectronic.dir3ExternalUrl' },
  infoDataDir3: { id: 'gofre.Documents.DataElectronic.infoDir3Emit' },
  accountingOffice: { id: 'gofre.Documents.DataElectronic.accountingOffice' },
  managementOrgan: { id: 'gofre.Documents.DataElectronic.managementOrgan' },
  processingUnit: { id: 'gofre.Documents.DataElectronic.processingUnit' },
  publicAdministration: {
    id: 'gofre.Documents.DataElectronic.publicAdministration',
  },
  publicAdministrationAffirmative: {
    id: 'gofre.Settings.naturalPersonAffirmative',
  },
  publicAdministrationNegative: { id: 'gofre.Settings.naturalPersonNegative' },
  vatNumberType: { id: 'gofre.Contacts.Form.vat-number-type' },
  country: { id: 'gofre.Manager.Create.Form.country' },
  countryPlaceholder: { id: 'gofre.Manager.Create.Form.country.placeholder' },
  nif: { id: 'gofre.Manager.Create.Form.vat-number' },
  nifPlaceholder: { id: 'gofre.Manager.Create.Form.vat-number.placeholder' },
  phone: { id: 'gofre.Manager.Create.Form.phone' },
  phonePlaceholder: { id: 'gofre.Manager.Create.Form.phone.placeholder' },
  province: { id: 'gofre.Manager.Create.Form.province' },
  provincePlaceholder: {
    id: 'gofre.Manager.Create.Form.province.placeholder',
  },
  fiscalDirection: { id: 'gofre.Manager.Form.fiscal-direction' },
  postalAddress: { id: 'gofre.Manager.Form.postal-direction' },
  postalCode: { id: 'gofre.Manager.Form.postalCode' },
  city: { id: 'gofre.Manager.Form.city' },
});

export default messages;
