import { FC } from 'react';

import { Box, Text } from 'modules/Ui';
import { IconExcel } from 'modules/Ui/Icons';

interface Props {
  name: string;
}

const FileLoaded: FC<Props> = ({ name }) => {
  return (
    <Box
      alignItems="center"
      backgroundColor="auxiliary"
      display="flex"
      fadeIn
      flexDirection="column"
      justifyContent="center"
      width="100%"
    >
      <IconExcel color="brand500" marginBottom="4px" />
      <Text color="gray800" fontWeight="600" textSize="xs" tag="span">
        {name}
      </Text>
    </Box>
  );
};

export default FileLoaded;
