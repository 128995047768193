import { FC, ReactNode } from 'react';

import { Box } from 'modules/Ui';

interface MenuWrapperProps {
  iconedLinks: ReactNode;
  links: ReactNode;
  linksNumber?: number;
  notificationLength: number;
}

const MenuWrapper: FC<MenuWrapperProps> = ({
  iconedLinks,
  links,
  linksNumber = 6,
  notificationLength,
}) => {
  const notificationIconSize = '24px';
  const notificationLinkWidth = () => {
    switch (true) {
      case notificationLength < 10:
        return `calc(${notificationIconSize} + 18px / 2)`;
      case notificationLength <= 99:
        return `calc(${notificationIconSize} + 24px / 2)`;
      default:
        return `calc(${notificationIconSize} + 30px / 2)`;
    }
  };
  return (
    <Box
      alignItems="center"
      columnGap={{ sm: '16px', lg: '32px' }}
      display="grid"
      gridTemplateColumns={{ sm: `repeat(${linksNumber}, auto) 1fr` }}
      height="100%"
      role="menu"
      tag="ul"
    >
      {links}
      <Box tag="li" display="grid" justifyContent="flex-end" height="100%">
        <Box
          alignItems="center"
          columnGap={{ sm: '8px', md: '12px', lg: '16px' }}
          display="grid"
          justifyContent="flex-end"
          tag="ul"
          gridTemplateColumns={`24px ${
            notificationLength !== 0 ? notificationLinkWidth() : 'auto'
          } 1fr`}
        >
          {iconedLinks}
        </Box>
      </Box>
    </Box>
  );
};

export default MenuWrapper;
