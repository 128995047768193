import { FC, useCallback } from 'react';

import { ContactFilters } from 'modules/Contacts/models';
import { PaymentFilters } from 'modules/Payments';
import { ProductFilters } from 'modules/Products/models';
import { isNumber } from 'utils/strings/isNumber';

import Box from '../Box';
import Tag from '../Tag';

interface TagListProps<T> {
  filters: T;
  onChangeSearch: (filters: T) => void;
  tags: TagObject[];
}

export interface TagObject {
  accessibleText: string;
  id: string;
  exists: boolean;
  text?: string;
}

const getTagsList = (
  tags: TagObject[],
  onClearTermClick: (id: string) => void
) => {
  return tags
    .filter((tag: TagObject) => tag.exists)
    .map((tag) => {
      return (
        <Box tag="li" marginRight="16px" marginBottom="8px" key={tag.id}>
          <Tag
            accessibleText={tag.accessibleText}
            onClick={() => onClearTermClick(tag.id)}
            padding="8px"
          >
            {tag.text}
          </Tag>
        </Box>
      );
    });
};

const TagList: FC<
  TagListProps<ContactFilters | ProductFilters | PaymentFilters>
> = ({ filters, tags, onChangeSearch }) => {
  const isAnyFilterFilled = Object.values(filters).some(
    (value) => !!value || isNumber(value)
  );
  const onClearTermClick = useCallback(
    (id: string) => {
      return onChangeSearch({
        ...filters,
        [id]: '',
      });
    },
    [onChangeSearch, filters]
  );
  const tagsList = getTagsList(tags, onClearTermClick);
  return isAnyFilterFilled ? (
    <Box
      tag="ul"
      display="flex"
      flexWrap="wrap"
      padding={{
        _: '24px 16px 24px',
        md: '24px 24px 24px',
        lg: '24px 32px 24px',
      }}
    >
      {tagsList}
    </Box>
  ) : null;
};

export default TagList;
