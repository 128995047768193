import { useInvalidateAndUpdate } from 'modules/App/hooks/useInvalidateAndUpdate';
import useNotifications from 'modules/Notifications/hooks/useNotifications';
import createPayment, {
  CreatePaymentResponse,
  invalidateQueries,
} from 'modules/Payments/services/createPayment'; // invalidateQueries,
import { ResponseError } from 'types/response-error.type';
import requestErrorHandler from 'utils/requestErrorHandler';
import { useBlockingMutation } from 'utils/useBlockingMutation';

import messages from './messages';

function useCreatePayment({
  callback,
  showSuccessNotification = true,
  fromPaymentList = false,
}: {
  callback?: (response: CreatePaymentResponse) => void;
  showSuccessNotification?: boolean;
  fromPaymentList?: boolean;
}) {
  const notifications = useNotifications();
  const invalidateAndUpdate = useInvalidateAndUpdate();
  const { mutate, mutateAsync } = useBlockingMutation(createPayment, {
    onSuccess: async (response): Promise<CreatePaymentResponse> => {
      if (showSuccessNotification) {
        const message = fromPaymentList
          ? messages.createFromListSuccess.id
          : messages.createSuccess.id;
        notifications.success(message, { ttl: 5000 });
      }
      invalidateAndUpdate({ invalidateQueries, isAsync: true });
      callback?.(response);
      return response;
    },
    onError: (error: ResponseError) => {
      const { isNetworkError, networkErrorTranslationKey } =
        requestErrorHandler(error);
      return notifications.error(
        isNetworkError ? networkErrorTranslationKey : messages.createError.id
      );
    },
  });
  return { mutate, mutateAsync };
}

export default useCreatePayment;
