import { AxiosResponse } from 'axios';
import { IBrowser } from 'redux-responsive/types';

import { Address } from 'modules/Address';
import analyticsService from 'modules/Analytics/services/analyticsService';
import { getConfig } from 'modules/App/config';
import { getRegisteredFrom } from 'modules/Business/businessUtils';
import { BusinessType, RegisteredFrom } from 'modules/Business/models/business';
import request from 'utils/request';

import { storeCurrentUser } from '../currentUserUtil';
import { setAuthData } from '../request-auth-data';
import { TokenResponse } from '../token-response';
import { LoginResponse } from '../types';

type AuthenticationType = 'signup' | 'login' | 'signup-lead';

type AuthRequest =
  | {
      email: string;
      password?: string;
      recaptcha?: string | null;
      isBookkeeper?: boolean;
      utmCampaign?: string;
      utmMedium?: string;
      utmSource?: string;
      business?: {
        fiscalName: string;
        vatNumber: string;
        phoneNumber?: string;
        address: Address;
      };
      bookkeeper?: {
        fiscalName: string;
        vatNumber: string;
        phoneNumber: string;
        address: Address;
        electronicDocumentProvider?: string;
        companySize?: string;
        software?: string;
        type: BusinessType;
      };
      browser?: IBrowser;
    }
  | { token: string };

let responsePromise: Promise<AxiosResponse<LoginResponse>> | undefined;

const loginByToken = (url: string) =>
  request<LoginResponse>(
    url,
    {
      method: 'GET',
      withCredentials: true,
    },
    { requireAuth: false }
  );

// Loadash memoize does not work well with promise errors
const memoizeLoginByToken = () => {
  const memo: Record<string, any> = {};

  return (url: string) => {
    if (memo[url]) {
      return memo[url];
    }

    memo[url] = loginByToken(url);
    memo[url].catch(() => {
      memo[url] = undefined;
    });
    return memo[url];
  };
};

const loginByTokenMemo = memoizeLoginByToken();

export const setAuthDataInLocal = (
  auth: TokenResponse['data']['auth'],
  email?: string
): void => {
  setAuthData({
    currentToken: auth.accessToken,
    expireOn: auth.expireIn + Date.now(),
    businessId: auth.businessId,
    userId: auth.userId,
    tenant: auth.tenant,
  });

  if (email) {
    analyticsService.identify(auth.businessId, auth.userId, email);
  }

  storeCurrentUser({
    userId: auth.userId,
    businessId: auth.businessId,
  });
};

const authenticate =
  (type: AuthenticationType) =>
  async (req: AuthRequest): Promise<TokenResponse['data']['auth'] | null> => {
    responsePromise = responsePromise ?? authenticateRequest(type, req);
    let response;
    try {
      response = await responsePromise;
    } finally {
      responsePromise = undefined;
    }

    if (!response.data?.data?.auth) {
      return null;
    }

    const { auth } = response.data.data;

    setAuthDataInLocal(auth, 'email' in req ? req.email : undefined);

    return auth;
  };

const authenticateRequest = async (
  type: AuthenticationType,
  req: AuthRequest
) => {
  const endpoint = (await getConfig()).apiAuthService;

  if ('email' in req) {
    const {
      email,
      password,
      isBookkeeper,
      utmCampaign,
      utmMedium,
      utmSource,
      recaptcha,
    } = req;
    return request<LoginResponse>(
      `${endpoint}/${type}`,
      {
        method: 'POST',
        data: {
          email,
          password,
          recaptcha,
          ...(['signup', 'signup-lead'].includes(type) && {
            isBookkeeper,
            utmCampaign,
            utmMedium,
            utmSource,
          }),
          ...(type === 'signup-lead' && prepareSignupLead(req)),
        },
        withCredentials: true,
      },
      { requireAuth: false }
    );
  }

  return loginByTokenMemo(`${endpoint}/login-token/${req.token}`);
};

const prepareSignupLead = (req: AuthRequest) => {
  const registeredFrom = getRegisteredFromRequest(req);

  if ('email' in req) {
    if (req.isBookkeeper) {
      return { bookkeeper: { ...req.bookkeeper, registeredFrom } };
    }
    return { business: { ...req.business, registeredFrom } };
  }
  return {};
};

const getRegisteredFromRequest = (
  req: AuthRequest
): RegisteredFrom | undefined => {
  return 'browser' in req && req.browser
    ? getRegisteredFrom(req.browser)
    : undefined;
};

export default authenticate;
