import { useState } from 'react';

import useModalFlags, { ModalFlag } from './useModalFlags';

function useModalFlagState(flag: ModalFlag) {
  const { setFlag } = useModalFlags();
  const [checked, setChecked] = useState(false);

  function setFlagToTrue() {
    setFlag({
      flag,
      status: true,
    });
  }
  function handleCloseModal() {
    if (checked) {
      setFlagToTrue();
    }
  }
  return { handleCloseModal, setFlagToTrue, checked, setChecked };
}

export default useModalFlagState;
