import { FC, ReactNode } from 'react';

import { SpaceProps } from 'styled-system';

import useTheme from 'modules/Theme/hooks/useTheme';

import Box from '../../Box';
import Text from '../../Text';

export enum DetailSummaryLineKindEnum {
  REGULAR = 'regular',
  ACCENT = 'accent',
  SOFT = 'soft',
}

interface Props extends SpaceProps {
  id: string;
  hiddenSummaryText?: string;
  lines: {
    icon?: ReactNode;
    key: string;
    dtContent: string | ReactNode;
    ddContent: string | ReactNode;
    kind: DetailSummaryLineKindEnum;
    color?: string;
    fontWeight?: string;
    justifySelf?: string;
  }[];
}

export const DetailSummaryPreviewLayout: FC<Props> = ({
  id,
  hiddenSummaryText,
  lines,
  margin,
}) => {
  const theme = useTheme();
  return (
    <Box
      tag="dl"
      borderBottom={`1px solid ${theme.colors.primary100}`}
      display="grid"
      gap="14px"
      gridTemplateColumns="1fr"
      lineHeight={16}
      paddingBottom="14px"
      margin={margin}
    >
      {lines.map((line) => (
        <Box
          key={line.key}
          data-testid={`document-totals-line-${id}`}
          display="grid"
          gridTemplateColumns="1fr auto"
          alignItems="center"
        >
          <Box display="flex" alignItems="center" tag="dt">
            {line.icon && <Box marginRight="6px">{line.icon}</Box>}
            <Text
              fontSize={16}
              lineHeight={16}
              color={
                line.kind === DetailSummaryLineKindEnum.SOFT
                  ? 'gray600'
                  : 'gray800'
              }
            >
              {line.dtContent}
            </Text>
          </Box>
          <Text
            tag="dd"
            fontSize={16}
            lineHeight={16}
            color="gray800"
            fontWeight={
              line.kind === DetailSummaryLineKindEnum.ACCENT ? '600' : ''
            }
          >
            {line.ddContent}
          </Text>
        </Box>
      ))}
      {hiddenSummaryText ? (
        <Text hide id={`${id}-summary-text`}>
          {hiddenSummaryText}
        </Text>
      ) : null}
    </Box>
  );
};
