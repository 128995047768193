import { FC } from 'react';
import NavigationPrompt from 'react-router-navigation-prompt';

import { useFormikContext } from 'formik';

import { Dialog } from 'modules/Modals';
import { Button, Text } from 'modules/Ui';

export interface WarnBeforeNavigationDialogProps {
  title: string;
  subtitle: string;
  question: string;
  confirm: string;
  cancel: string;
  overrideCancel?: () => void;
  overrideConfirm?: () => void;
}

const WarnBeforeNavigationDialog: FC<WarnBeforeNavigationDialogProps> = ({
  overrideConfirm,
  overrideCancel,
  title,
  subtitle,
  question,
  confirm,
  cancel,
}) => {
  const { dirty, resetForm, submitForm } = useFormikContext();

  return (
    <NavigationPrompt when={() => dirty}>
      {({ isActive, onCancel, onConfirm }) => {
        const handleAccept = () => {
          if (overrideConfirm) {
            overrideConfirm();
          } else {
            submitForm();
          }
          onConfirm();
        };

        const handleCancel = () => {
          if (overrideCancel) {
            overrideCancel();
          } else {
            resetForm();
          }
          onConfirm();
        };

        const handleClose = () => {
          onCancel();
        };

        if (isActive) {
          return (
            <Dialog
              id="confirm-navigation-modal"
              title={title}
              onClose={handleClose}
              buttonPrimary={
                <Button onClick={handleAccept} variant="secondary">
                  {confirm}
                </Button>
              }
              buttonSecondary={
                <Button onClick={handleCancel} variant="stroke">
                  {cancel}
                </Button>
              }
              subtitle={subtitle}
            >
              <Text>{question}</Text>
            </Dialog>
          );
        }
        return null;
      }}
    </NavigationPrompt>
  );
};

export default WarnBeforeNavigationDialog;
