import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, matchPath } from 'react-router';

import Notification from 'modules/Notifications/components/Notification/Notification';
import { selectNotifications } from 'modules/Notifications/selectors';
import { SETTINGS_PATH } from 'modules/Settings/paths';
import { ROUTES as SUBSCRIPTION_ROUTES } from 'modules/Subscriptions/routesMap';
import { Box } from 'modules/Ui';
import { zIndex } from 'modules/Ui/zIndex';
import { USER_DETAIL } from 'modules/Users/routesMap';
import { ROUTES as BUSINESS_ROUTES } from 'pages/business/routesMap';
import { PAYMENT_ROUTES } from 'pages/payments/routesMap';

import { clearNotifications } from '../../actions';

interface NotificationsProps {
  top?: number;
}

const routesWithSubMenu = [
  USER_DETAIL,
  BUSINESS_ROUTES.BUSINESS,
  SUBSCRIPTION_ROUTES.SUBSCRIPTIONS,
  SETTINGS_PATH,
  PAYMENT_ROUTES.LIST,
  PAYMENT_ROUTES.BANKS,
];

const Notifications: React.FC<NotificationsProps> = ({ top: topProp }) => {
  const [avoidInitialLoadBlink, setAvoidInitialLoadBlink] = useState(true);
  const notifications = useSelector(selectNotifications);
  const location = useLocation();
  const dispatch = useDispatch();
  const topWithoutSubmenu = {
    _: '56px',
    sm: '80px',
  };

  const topWithSubmenu = {
    _: '56px',
    sm: '120px',
  };

  const routeHasSubmenu = routesWithSubMenu.some((route) =>
    matchPath(location.pathname, { path: route, exact: false, strict: false })
  );

  const defaultTop = routeHasSubmenu ? topWithSubmenu : topWithoutSubmenu;

  useEffect(() => {
    dispatch(clearNotifications());
  }, [location]);

  useEffect(() => {
    const timeout = setTimeout(() => setAvoidInitialLoadBlink(false), 1000);
    return () => clearTimeout(timeout);
  }, []);

  const top = topProp ? `${topProp}px` : defaultTop;

  if (avoidInitialLoadBlink) {
    return null;
  }

  return (
    <Box
      fadeIn
      left="0"
      margin="0 auto"
      maxWidth="1276px"
      position="fixed"
      right="0"
      top={top}
      width="100%"
      zIndex={zIndex.NOTIFICATIONS}
    >
      {notifications.map((n) => (
        <Notification key={n.id} {...n} />
      ))}
    </Box>
  );
};

export default Notifications;
