export enum BookkeeperSoftwareTypes {
  APLIFISA = 'aplifisa',
  DIAMACON = 'diamacon',
  A3 = 'a3',
  SAGE_CONTAPLUS = 'sage_contaplus',
  SAGE_DESPACHOS = 'sage_despachos',
  CONTASOL = 'contasol',
  EXCEL = 'excel',
  OTHER = 'other',
}
