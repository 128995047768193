import { isNil } from 'lodash';

import { roundNumber } from 'utils/MathOperations';

import { TaxKey } from '../../../Taxes';
import {
  calculateConceptValuesFromTotal,
  calculateTaxData,
} from './calculate-concept-values-from-total';
import {
  TotalsFromConcepts,
  TortillaConcept as Concept,
  TortillaTaxLine as TaxLine,
} from './types';

/*
  IF YOU CHANGE ANYTHING BELOW THIS COMMENT YOU NEED TO DO THE SAME CHANGE IN GOFRE / TORTILLA
  WE ROUND TO 3 DECIMAL PLACES SO WE DONT LOSE PRECISION ON THE SECOND DECIMAL PLACE
  THE OBJECTIVE OF IS THAT THE TOTALS MUST ADD PERFECTLY, SUBTOTAL + TAX = TOTAL
  WE ARE FINE LOOSING SOME TRAILING CENTS IN THE SUM OF THE CONCEPTS
 */

type TotalsByTaxKey = {
  taxKey: TaxKey;
  taxPercentage: number;
  salesEqTaxPercentage: number;
  salesEqTaxAmount: number;
  totalAmount: number;
  discount: number;
  fixedTaxAmountByClient?: number;
};

export function calculateTotalsFromConceptsV2(
  concepts: Concept[]
): TotalsFromConcepts {
  const taxes: { [key: string]: TaxLine } = {};

  const totalsByTaxes: { [key: string]: TotalsByTaxKey } = concepts.reduce(
    (acc: { [key: string]: TotalsByTaxKey }, currentConcept: Concept) => {
      const {
        totalAmount,
        taxKey,
        taxPercentage = 0,
        salesEqTaxPercentage = 0,
        salesEqTax,
        fixedTaxAmountByClient,
      } = currentConcept;
      const { discount } = calculateConceptValuesFromTotal(currentConcept);

      const taxesObjectKey = currentConcept.taxKey;
      const value = acc[taxesObjectKey] ?? {
        taxKey,
        taxPercentage,
        salesEqTaxPercentage,
        salesEqTaxAmount: 0,
        totalAmount: 0,
        discount: 0,
      };
      if (!isNil(fixedTaxAmountByClient)) {
        if (isNil(value.fixedTaxAmountByClient)) {
          value.fixedTaxAmountByClient = fixedTaxAmountByClient;
        } else {
          value.fixedTaxAmountByClient += fixedTaxAmountByClient;
        }
      }
      value.totalAmount += totalAmount ?? 0;
      value.discount += discount ?? 0;
      value.salesEqTaxAmount += salesEqTax ?? 0;
      return {
        ...acc,
        [taxesObjectKey]: value,
      };
    },
    {}
  );

  let subtotal = 0;
  let discount = 0;
  let taxBase = 0;
  let taxAmount = 0;
  let salesEqTax = 0;
  let totalAmount = 0;

  Object.entries(totalsByTaxes).forEach(([key, value]) => {
    const {
      totalAmount: total,
      taxKey,
      taxPercentage,
      salesEqTaxPercentage,
      salesEqTaxAmount,
      fixedTaxAmountByClient,
    } = value;

    const taxLineTaxData = calculateTaxData({
      totalAmount: total,
      taxPercentage,
      salesEqTaxPercentage,
      salesEqTaxAmount,
    });

    const taxLine: TaxLine = {
      taxKey,
      total: roundNumber(total),
      taxPercentage: roundNumber(taxPercentage),
      ...taxLineTaxData,
      salesEqTax: roundNumber(taxLineTaxData.salesEqTax),
    };

    subtotal += taxLine.taxBase + value.discount;
    discount += value.discount;
    taxBase += taxLine.taxBase;
    if (isNil(fixedTaxAmountByClient)) {
      taxAmount += taxLine.taxAmount;
    } else {
      taxAmount += fixedTaxAmountByClient;
    }
    salesEqTax += taxLine.salesEqTax;
    totalAmount += total;

    taxes[key] = taxLine;
  });

  return {
    subtotal: roundNumber(subtotal),
    discount: roundNumber(discount),
    taxBase: roundNumber(taxBase),
    taxAmount: roundNumber(taxAmount),
    salesEqTax: roundNumber(salesEqTax),
    totalAmount: roundNumber(totalAmount),
    taxes,
  };
}
