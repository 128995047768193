import * as Yup from 'yup';

import { formText } from 'modules/Forms/messages';
import { TranslationFunction } from 'modules/I18n/hooks/useTranslations';

import { changeEmailModal as messages } from './messages';

const getValidationSchema = (t: TranslationFunction) =>
  Yup.object().shape({
    billingEmail: Yup.string()
      .email(t(messages.emailFormatError))
      .required(t(formText.required)),
  });

export default getValidationSchema;
