import { getConfig } from 'modules/App/config';
import buildBaseIdsQuery from 'modules/App/queries/buildBaseIdsQuery';
import buildFiltersQuery, {
  buildDocumentTypeStatusQuery,
  queryStatus,
} from 'modules/Documents/helpers/buildFiltersQuery';
import request from 'utils/request';

import {
  DocumentStatus,
  DocumentFilters,
  DocumentType,
} from '../../models/document';

interface DownloadZipServiceParams {
  ids: string[];
  excludeIds: boolean;
  filters?: DocumentFilters;
  callback?: () => void;
  status?: DocumentStatus;
  documentType?: DocumentType;
  canVoidDocument?: boolean;
}
const downloadZipService = async ({
  excludeIds,
  ids,
  filters,
  status,
  documentType,
  canVoidDocument,
}: DownloadZipServiceParams): Promise<{ taskId: string }> => {
  const endpoint = (await getConfig()).apiDocumentDownloadZipService;

  let query = buildBaseIdsQuery(ids, excludeIds);

  if (excludeIds && filters) {
    query += buildFiltersQuery(filters);
  }

  query = buildDocumentTypeStatusQuery(query, {
    documentType,
    status: queryStatus(filters, status),
    canVoidDocument,
    excludeTestInvoices: true,
  });

  const response = await request<any>(`${endpoint}?${query}`, {
    method: 'POST',
  });

  if (response.status < 200 || response.status >= 400) {
    throw new Error('Error al generar el zip');
  }

  return response.data?.data;
};

export default downloadZipService;
