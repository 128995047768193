/* stylelint-disable no-descending-specificity */
import React, { useCallback, useMemo, useState, FC } from 'react';
import { useSelector } from 'react-redux';
import ReactSelect, { createFilter } from 'react-select';

import { isEmpty, find } from 'lodash';
import AsyncSelect from 'react-select/async';
import { flexbox, layout } from 'styled-system';

import { selectIsMobile } from 'modules/App/store/selectors';
import useTranslations from 'modules/I18n/hooks/useTranslations';
import useTheme from 'modules/Theme/hooks/useTheme';
import { reactSelectStyles } from 'modules/Theme/mixins';
import styled from 'modules/Theme/styled-components';

import Box from '../../Box';
import { IconArrow, IconCross } from '../../Icons';
import Description from '../Commons/Description';
import LabelText from '../Commons/LabelText';
import { SelectProps, Option } from '../SelectCommon/types';
import { Input } from './components/Input';
import { MenuList } from './components/MenuList';
import messages from './messages';

export const StyledSelect = styled(ReactSelect)<SelectProps>`
  ${reactSelectStyles}
`;

export const StyledAsyncSelect = styled(AsyncSelect)<SelectProps>`
  ${reactSelectStyles}
`;

export const StyledSelectWrapper = styled(Box)`
  ${flexbox}
  ${layout}
`;

const DefaultFilterOption = createFilter({
  ignoreCase: true,
  ignoreAccents: true,
  trim: true,
  matchFrom: 'start',
});

const Select: FC<SelectProps> = ({
  canCreateOption = false,
  defaultValue,
  error,
  filterConfig,
  helpText,
  id,
  info,
  isAsync,
  isDisabled,
  small = false,
  isSearchable = false,
  label,
  labelAlignLeft,
  labelHide,
  loadingMessage,
  loadOptions,
  menuListText,
  noDescription,
  onChange,
  onInputChange: externalOnInputChange,
  onKeyDown,
  onAddOption,
  options,
  required,
  selectedOption,
  value,
  ...rest
}) => {
  const theme = useTheme();
  const handleOnChange = useCallback(
    (option: Option) => {
      if (onChange) {
        onChange(option?.value ?? null);
      }
    },
    [onChange]
  );

  const [inputValue, setInputValue] = useState('');

  const onInputChange = (text: string) => {
    setInputValue(text);
    externalOnInputChange && externalOnInputChange(text);
  };

  const selected = useMemo(() => {
    if (selectedOption) {
      return selectedOption;
    }
    return find(options, { value: value || defaultValue }) || null;
  }, [value, options, defaultValue, selectedOption]);

  const customFilterOption = useMemo(() => {
    if (!filterConfig) {
      return null;
    }
    return createFilter(filterConfig);
  }, [filterConfig]);

  const filterOption = customFilterOption ?? DefaultFilterOption;
  const isMobile = useSelector(selectIsMobile);
  const handleDropdownClick = (e: React.MouseEvent<Element, MouseEvent>) => {
    e.stopPropagation();
  };
  const onFocus = isMobile ? handleDropdownClick : undefined;
  const onClick = !isMobile ? handleDropdownClick : undefined;

  const CurrentSelect = isAsync ? StyledAsyncSelect : StyledSelect;

  const handleKeyDown = (event: React.KeyboardEvent<HTMLElement>) => {
    if (event.key === 'Enter') {
      if (inputValue.length && !options?.length) {
        onAddOption && onAddOption();
      }
    }
  };

  const { t } = useTranslations();

  return (
    <StyledSelectWrapper
      error={error}
      helpText={helpText}
      id={id}
      isDisabled={isDisabled}
      isSearchable={isSearchable}
      label={label}
      required={required}
      display={labelAlignLeft && { sm: 'flex' }}
      alignItems={labelAlignLeft ? 'center' : 'initial'}
      boxSizing="border-box"
      onFocus={onFocus}
      onClick={onClick}
      small={small}
      {...rest}
    >
      {label && (
        <LabelText
          id={`select-label-${id}`}
          hidden={labelHide}
          disabled={isDisabled}
          required={required}
          info={info}
          className="react-select__label"
          marginRight={labelAlignLeft ? { sm: '8px' } : undefined}
        >
          {label}
        </LabelText>
      )}
      <CurrentSelect
        error={error}
        isDisabled={isDisabled}
        small={small}
        loadingMessage={loadingMessage}
        {...(isAsync
          ? {
              isSearchable,
              loadOptions,
              isClearable: true,
            }
          : {
              isSearchable,
              filterOption: isSearchable ? filterOption : undefined,
              options,
            })}
        {...rest}
        className="react-select-container"
        classNamePrefix="react-select"
        components={{
          Input,
          ...(canCreateOption
            ? {
                MenuList: (props: any) => (
                  <MenuList
                    {...props}
                    label={menuListText}
                    onAddOption={onAddOption}
                    contextualText={inputValue}
                    createOptionBackground={
                      isEmpty(options) && !!inputValue
                        ? theme.colors.stateBg
                        : undefined
                    }
                  />
                ),
              }
            : {}),
          DropdownIndicator: () => (
            <Box
              className="react-select__indicator react-select__dropdown-indicator"
              marginLeft="4px"
              padding="0"
              tag="span"
            >
              <IconArrow size={20} />
            </Box>
          ),
          ClearIndicator: () => (
            <Box
              aria-label={t(messages.clearInditator)}
              className="react-select__indicator"
              onClick={handleOnChange}
              padding="0"
              position="relative"
              role="button"
              tabIndex="0"
              tag="span"
            >
              <IconCross size={16} color="primary300" />
            </Box>
          ),
          IndicatorSeparator: () => null,
        }}
        id={`select-${id}`}
        inputId={`select-input-${id}`}
        onInputChange={onInputChange}
        maxMenuHeight="192px"
        aria-labelledby={`select-label-${id}`}
        onChange={handleOnChange}
        onKeyDown={handleKeyDown}
        value={selected}
        autoComplete="none"
        labelAlignLeft={labelAlignLeft}
      />
      <Description
        id={`description-${id}`}
        hasError={!!error}
        hide={noDescription}
      >
        {error || helpText}
      </Description>
    </StyledSelectWrapper>
  );
};

StyledSelectWrapper.defaultProps = {
  display: { sm: 'initial' },
};

export default Select;
