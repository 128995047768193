import styled from 'modules/Theme/styled-components';

import Box from '../../Box';

const Wrapper = styled(Box)`
  :root {
    --check-label-color: '';
    --check-border-color: '';
  }
`;

export default Wrapper;
