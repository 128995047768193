import { useSelector } from 'react-redux';

import { useIsBookkeeperLoggedInClient } from 'modules/Bookkeeper/hooks/useIsBookkeeperUser';
import { selectCurrentBusiness } from 'modules/Business/Current/selectors';
import { Box, Text, Avatar, Button } from 'modules/Ui';
import { IconArrow } from 'modules/Ui/Icons';
import { NotificationBadge } from 'modules/Ui/Menu/Components/NotificationBadge';
import { SubmenuSections } from 'modules/Ui/Menu/constants';
import { selectUser } from 'modules/Users/selectors';
import { getFullName } from 'modules/Users/userUtils';

import UserRole from '../../../Components/UserRole';
import BusinessName from './BusinessName';

export interface LinkBusinessProps {
  active?: boolean;
  disabled?: boolean;
  badgeCount?: number;
  openSubMenu: (section: SubmenuSections) => () => void;
}

const BusinessButton = (props: LinkBusinessProps) => {
  const { badgeCount, openSubMenu, active, disabled } = props;
  const { fiscalName: businessName } = useSelector(selectCurrentBusiness);
  const isBookkeeperLoggedInClient = useIsBookkeeperLoggedInClient();
  const userData = useSelector(selectUser);
  const fullName = getFullName(userData);
  return (
    <Box padding="16px 16px 8px 16px">
      <Button
        {...{ active, disabled }}
        aria-controls="submenu"
        aria-haspopup="true"
        display="flex"
        onClick={
          !isBookkeeperLoggedInClient
            ? openSubMenu(SubmenuSections.BUSINESS)
            : undefined
        }
        width="100%"
      >
        <Box borderRadius="50%" marginRight="8px" size="40px" tag="span">
          <Avatar name={businessName} />
        </Box>
        <Box
          display="flex"
          flex="1"
          flexDirection="column"
          overflow="hidden"
          tag="span"
        >
          <Box flex="1" tag="span" textAlign="left">
            <Box tag="span" display="flex">
              <Box tag="span" overflow="hidden">
                <BusinessName {...{ active }}>{businessName}</BusinessName>
                {fullName && (
                  <Text
                    color="primary300"
                    fontWeight="600"
                    display="block"
                    hasEllipsis
                    marginBottom="2px"
                    tag="span"
                    textSize="xs"
                  >
                    {fullName}
                  </Text>
                )}
              </Box>
              <NotificationBadge
                margin="2px 0 0 7px"
                number={badgeCount}
                position="relative"
              />
            </Box>
          </Box>
          <Box flex="1" tag="span" textAlign="left" overflow="hidden">
            {userData.role && <UserRole />}
          </Box>
        </Box>
        <Box tag="span" width="24px">
          <IconArrow transform="rotate(-90deg)" size={24} color="primary500" />
        </Box>
      </Button>
    </Box>
  );
};

export default BusinessButton;
