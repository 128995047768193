import useTranslations from 'modules/I18n/hooks/useTranslations';
import { Box, Text } from 'modules/Ui';
import { Option } from 'modules/Ui/Form/SelectCommon/types';

import messages from './messages';

interface Props {
  option: Option;
}

const OptionLabel: React.FC<Props> = ({ option }) => {
  const { t } = useTranslations();
  return (
    <Box display="grid" gridTemplateColumns="1fr auto" columnGap="2px">
      <Text tag="span" color="inherit" hasEllipsis>
        {option?.label}
      </Text>
      {option.additionalData && (
        <>
          {option?.additionalData.vatNumber ? (
            <Text color="inherit" tag="span">
              {option.additionalData.vatNumber}
            </Text>
          ) : (
            <Text color="error" tag="span">
              {t(messages.noVatNumber)}
            </Text>
          )}
        </>
      )}
    </Box>
  );
};

export default OptionLabel;
