type LocaleTranslation = {
  weekdaysShort: string[];
  weekdaysLong: string[];
  months: string[];
  firstDayOfWeek: number;
  labels: {
    nextMonth: string;
    previousMonth: string;
  };
};

const es: LocaleTranslation = {
  weekdaysShort: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa'],
  weekdaysLong: [
    'Domingo',
    'Lunes',
    'Martes',
    'Miércoles',
    'Jueves',
    'Viernes',
    'Sábado',
  ],
  months: [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre',
  ],
  firstDayOfWeek: 1,
  labels: {
    nextMonth: 'Próximo mes',
    previousMonth: 'Mes anterior',
  },
};

type Locales = {
  [key: string]: LocaleTranslation;
};

const getLocalization = (locale: string) => {
  const locales: Locales = { es };

  return locales[locale] || {};
};

export default getLocalization;
