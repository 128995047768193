import IconByString, { IconByStringProps } from './Icon/IconType/IconByString';

export interface IconAddProps extends IconByStringProps {}

const IconTag = (props: IconAddProps) => {
  const { ...rest } = props;
  return (
    <IconByString fill="none" strokeWidth="initial" {...rest}>
      <circle
        cx="17.536"
        cy="7.235"
        r="1.257"
        stroke="currentColor"
        transform="rotate(8.845 17.536 7.235)"
      />
      <path
        d="M2.742 14.096a1.5 1.5 0 0 1 .05-2.121l9.944-9.479a1.5 1.5 0 0 1 1.133-.41l6.902.45a1.5 1.5 0 0 1 1.403 1.513l-.078 6.661a1.5 1.5 0 0 1-.465 1.069l-9.968 9.502a1.5 1.5 0 0 1-2.12-.051l-6.801-7.134Z"
        stroke="currentColor"
      />
    </IconByString>
  );
};

export default IconTag;
