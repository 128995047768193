// "I will not condone a course of action that will lead us to war" -Queen Amidala
export const menuSizes = {
  BANNER_END_TRIAL_DESKTOP: 48,
  BANNER_END_TRIAL_MOBILE: 96,
  HEADER_HEIGHT_DESKTOP: 72,
  HEADER_HEIGHT_MOBILE: 56,
  BUTTON_ACTION_HEIGHT: 72,
  MENU_WIDTH: 272,
  SUBMENU_DESKTOP: 40,
};
