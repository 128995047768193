import { useQuery } from 'react-query';

import { BUSINESSES_ME_QUERY } from 'modules/App/queries/queries';

import { fetchBusinesses } from '../Change/services';

const useHasSeveralBusinesses = () => {
  const { data } = useQuery([BUSINESSES_ME_QUERY], () => fetchBusinesses());
  return data && data?.count > 1;
};

export default useHasSeveralBusinesses;
