import accessibleHidden from 'modules/Theme/mixins/accessibleHidden';
import ellipsis from 'modules/Theme/mixins/ellipsis';
import styled, { css } from 'modules/Theme/styled-components';

import Box from '../../Box/Box';

const visible = css`
  color: var(--check-label-color, ${(props) => props.theme.colors.gray600});
  font-size: ${(props) => props.theme.fontSizes[16]};
  line-height: ${(props) => props.theme.lineHeights[26]};
  margin-left: 12px;
  width: 100%;
`;

const infoStyles = css`
  align-items: center;
  display: flex;
  margin-bottom: 0;
  min-height: 20px;
  position: relative;
`;

const hide = css`
  ${accessibleHidden};
`;

const LabelText = styled(Box)`
  ${({ labelHidden }) => (labelHidden ? hide : visible)}
  ${({ info }) => info && infoStyles}
  ${({ hasEllipsis }) => hasEllipsis && ellipsis}
`;

export default LabelText;
