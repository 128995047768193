import useTheme from 'modules/Theme/hooks/useTheme';

import IconByString, { IconByStringProps } from './Icon/IconType/IconByString';

const IconStateWarning = (props: IconByStringProps) => {
  const theme = useTheme();
  const { size = 24, color = theme.colors.warning, ...rest } = props;
  return (
    <IconByString fill="none" size={size} viewBox="0 0 64 64" {...rest}>
      <circle cx="32" cy="32" r="32" fill={color} />
      <path
        fill={theme.colors.gray0}
        d="M34.528 20.727h-5.056l.454 16.296h4.16l.442-16.296Zm-2.522 23.569c1.454 0 2.716-1.216 2.727-2.728-.011-1.488-1.273-2.704-2.727-2.704-1.5 0-2.739 1.216-2.728 2.704-.011 1.512 1.228 2.728 2.728 2.728Z"
      />
    </IconByString>
  );
};

export default IconStateWarning;
