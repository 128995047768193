import IconByPath, { IconByPathProps } from './Icon/IconType/IconByPath';

const IconEnlarge = (props: IconByPathProps) => (
  <IconByPath
    {...props}
    path="M3 3.707V8H2V2h6v1H3.707l4.657 4.657-.707.707L3 3.707Zm13.347 4.657 4.657-4.657V8h1V2h-6v1h4.293L15.64 7.657l.707.707Zm0 7.276 4.657 4.657v-4.293h1v6h-6v-1h4.293l-4.657-4.657.707-.707ZM3 16.004v4.293l4.657-4.657.707.707-4.657 4.657H8v1H2v-6h1Z"
  />
);

export default IconEnlarge;
