import IconByPath, { IconByPathProps } from './Icon/IconType/IconByPath';

const IconAirplane = (props: IconByPathProps) => (
  <IconByPath
    {...props}
    path="M19.634 20.538 21.826 3 1 11.22l8.226 4.114v5.44a.5.5 0 0 0 .876.33l3.245-3.71 6.287 3.144Zm-7.21-3.605-2.198-1.1v3.611l2.197-2.511Zm-1.688-1.962 8.081 4.04L20.55 5.16l-9.813 9.812Zm-.942-.471 9.375-9.376-15.718 6.204L9.794 14.5Z"
    fillRule="evenodd"
    clipRule="evenodd"
  />
);

export default IconAirplane;
