import { useMemo } from 'react';

import { VatNumberType } from 'modules/Contacts/models';
import useTranslations from 'modules/I18n/hooks/useTranslations';

import messages from '../messages';

const useVatNumberTypeOptions = (blockedToSpanishContacts?: boolean) => {
  const { t } = useTranslations();
  return useMemo(
    () =>
      [
        { label: t(messages.vatNumberTypeNif), value: VatNumberType.NIF },
        {
          label: t(messages.vatNumberTypeNifIva),
          value: VatNumberType.NIF_IVA,
          hidden: blockedToSpanishContacts,
        },
        {
          label: t(messages.vatNumberTypePassport),
          value: VatNumberType.PASSPORT,
          hidden: blockedToSpanishContacts,
        },
        {
          label: t(messages.vatNumberTypeOrigin),
          value: VatNumberType.ORIGIN,
          hidden: blockedToSpanishContacts,
        },
        {
          label: t(messages.vatNumberTypeCertificate),
          value: VatNumberType.CERTIFICATE,
          hidden: blockedToSpanishContacts,
        },
        {
          label: t(messages.vatNumberTypeOther),
          value: VatNumberType.OTHER,
          hidden: blockedToSpanishContacts,
        },
      ]
        .filter((xs) => !xs.hidden)
        .map(({ value, label }) => ({ value, label })),
    [t, blockedToSpanishContacts]
  );
};

export default useVatNumberTypeOptions;
