import { getConfig } from 'modules/App/config';
import { DOCUMENT_SERIES_QUERY } from 'modules/App/queries/queries';
import request from 'utils/request';

import { DocumentSerie } from '../models';

type DocumentSerieParams = {
  id: string;
  dry?: boolean;
};
const deleteDocumentSerie = async (
  params: DocumentSerieParams
): Promise<DocumentSerie> => {
  const endpoint = (await getConfig()).apiDocumentSeriesService;

  const route = `${endpoint}/${params.id}${params.dry ? '?dry=true' : ''}`;
  const response = await request<any>(route, {
    method: 'DELETE',
  });

  return response.data;
};

export const invalidateQueries = [DOCUMENT_SERIES_QUERY];

export default deleteDocumentSerie;
