import { css } from 'modules/Theme/styled-components';

const hoverEffect = css`
  cursor: pointer;
  opacity: 1;
  outline: none;
  will-change: opacity;
`;

const circleHover = (size?: string, iconHoverColor?: string) => css`
  &:before {
    background-color: ${(props) => props.theme.colors.auxiliary};
    border-radius: 50%;
    content: '';
    display: block;
    height: ${size};
    opacity: 0;
    position: absolute;
    transition: opacity 0.2s ease-in;
    width: ${size};
  }

  &:focus {
    &:before {
      outline :none;
      ${hoverEffect}
    }
    svg {
      color: ${iconHoverColor};
    }
  }

  @media (hover: hover) {
    &:hover {
      &:before {
       ${hoverEffect}
      }
      svg {
        color: ${iconHoverColor};
      }
    }
  }
`;

export default circleHover;
