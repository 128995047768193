import { isEmpty } from 'lodash';
import * as Yup from 'yup';

import { TranslationFunction } from 'modules/I18n/hooks/useTranslations';
import { emailRegex } from 'modules/Ui/Email/validation/emailRegex';

import { formErrors } from '../messages';

/**
 * Returns validation schema for list of already entered emails + text entered in the input
 * @param t TranslationFunction
 * @param inputId id of text input component
 * @returns
 */
const getEmailListValidationSchema = (
  t: TranslationFunction,
  inputId: string
) => {
  return Yup.array()
    .of(Yup.string().email(t(formErrors.emailInvalid)))
    .test('emailCrossValidation', t(formErrors.emailInvalid), function test() {
      return (
        isEmpty(this.parent[inputId]) || !!emailRegex.test(this.parent[inputId])
      );
    });
};

const getValidationSchema = (t: TranslationFunction, hasRecaptcha: boolean) => {
  return Yup.object().shape({
    to: Yup.string()
      .required(t(formErrors.emailRequired))
      .matches(emailRegex, t(formErrors.emailInvalid))
      .nullable(),
    cc: getEmailListValidationSchema(t, 'ccInput'),
    bcc: getEmailListValidationSchema(t, 'bccInput'),
    recaptcha: hasRecaptcha
      ? Yup.string().nullable().required(t(formErrors.recaptchaRequired))
      : Yup.string().nullable(),
  });
};

export default getValidationSchema;
