import { defineMessages } from 'react-intl';

export const changeEmailModal = defineMessages({
  acceptButton: { id: 'gofre.Subscription.Detail.change-email.accept' },
  cancelButton: { id: 'gofre.Subscription.Detail.change-email.cancel' },
  updating: { id: 'gofre.Subscription.Detail.change-email.updating' },
  billingEmailLabel: {
    id: 'gofre.Subscription.Detail.change-email.label',
  },
  billingEmailPlaceholder: {
    id: 'gofre.Subscription.Detail.change-email.placeholder',
  },
  title: { id: 'gofre.Subscription.Detail.change-email.title' },
  emailFormatError: {
    id: 'gofre.Subscription.Detail.change-email.field.invalid',
  },
});

export const changeEmail = defineMessages({
  changeEmail: { id: 'gofre.Subscription.changeEmail.text' },
  editEmail: { id: 'gofre.Subscription.changeEmail.editEmail' },
  emailDialogInfo: { id: 'gofre.Subscription.changeEmail.emailDialogInfo' },
  emailDialogText: { id: 'gofre.Subscription.changeEmail.emailDialogText' },
});
