import { getConfig } from 'modules/App/config';
import { DocumentType } from 'modules/Documents/models/document';

export async function getFetchEndpoint(documentType?: string): Promise<string> {
  const config = await getConfig();
  if (documentType === DocumentType.DELIVERY_NOTE) {
    return config.apiDeliveryNotes;
  }
  if (documentType === DocumentType.QUOTE) {
    return config.apiQuotes;
  }
  if (documentType === DocumentType.PROFORMA) {
    return config.apiProformas;
  }
  return config.apiDocumentsService;
}
