import { FC, useState } from 'react';

import deleteAccountingAccount, {
  invalidateQueries,
} from 'modules/AccountingAccounts/services/deleteAccountingAccount';
import { useInvalidateAndUpdate } from 'modules/App/hooks/useInvalidateAndUpdate';
import useTranslations from 'modules/I18n/hooks/useTranslations';
import { DialogConfirm, useCloseModal } from 'modules/Modals';
import useNotifications from 'modules/Notifications/hooks/useNotifications';
import { useBlockingMutation } from 'utils/useBlockingMutation';

import { deleteButton } from '../../../messages';
import { AccountDeleteModalProps } from '../types';

export const AccountDeleteModal: FC<AccountDeleteModalProps> = ({ id }) => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const { t } = useTranslations();
  const invalidate = useInvalidateAndUpdate();
  const closeModal = useCloseModal();
  const notifications = useNotifications();

  const { mutate } = useBlockingMutation(deleteAccountingAccount, {
    onSuccess: () => {
      invalidate({ invalidateQueries });
      notifications.success(t(deleteButton.success));
    },
    onError: () => {
      notifications.error(deleteButton.error.id);
      setIsSubmitting(false);
    },
  });

  const handleDelete = async () => {
    if (!isSubmitting) {
      setIsSubmitting(true);
      mutate({
        id,
      });
      closeModal();
    }
  };

  return (
    <DialogConfirm
      id="account-delete"
      onConfirm={handleDelete}
      title={t(deleteButton.title)}
      subtitle={t(deleteButton.subtitle)}
      text={t(deleteButton.text)}
      confirmText={t(deleteButton.confirmText)}
      cancelText={t(deleteButton.cancel)}
    />
  );
};
