import { useEffect } from 'react';
import { Route, RouteProps, Redirect } from 'react-router-dom';

import useIsBookkeeperUser from 'modules/Bookkeeper/hooks/useIsBookkeeperUser';
import featureFlags from 'modules/FeatureFlags';
import useModalFlags from 'modules/Modals/hooks/useModalFlags';
import { ROUTES as ROUTES_AUTH } from 'pages/auth/routesMap';
import useQueryParams from 'utils/router/useQueryParams';
import { useRouteAnchorState } from 'utils/router/useRouteAnchorState';

import { UserPermissions } from '../constants';
import { getCurrentUser } from '../currentUserUtil';
import useHasUserScope from '../hooks/checkUsersPermissions';

interface AuthenticatedRouteProps extends RouteProps {
  businessRequired?: boolean;
  businessUnique?: boolean;
  requiresEditor?: boolean;
  requiresAdmin?: boolean;
  requiredFeatureFlag?: string;
}

const AuthenticatedRoute = ({
  businessRequired,
  businessUnique,
  requiresEditor,
  requiresAdmin,
  requiredFeatureFlag,
  ...props
}: AuthenticatedRouteProps) => {
  const currentUser = getCurrentUser();
  const query = useQueryParams();
  const isBookkeeper = useIsBookkeeperUser();
  const routeToRedirect = query.get('redirect') || '/';
  const routeAnchor = useRouteAnchorState();
  const { hasUserScope, loaded } = useHasUserScope();
  const isUserEditor = hasUserScope(UserPermissions.SUBSCRIPTION_EDITOR);
  const isUserAdmin = hasUserScope(UserPermissions.SUBSCRIPTION_ADMIN);
  useModalFlags();

  const ffEnabled = requiredFeatureFlag
    ? featureFlags.getFlag(requiredFeatureFlag)
    : true;

  useEffect(() => {
    if (!currentUser) {
      routeAnchor.navigateStoringAnchorState(ROUTES_AUTH.LOGIN);
    }
  }, [currentUser?.businessId]);

  // WARNING: conflict when refresh in register business and refresh inside document create when you need scopes
  if (
    (!currentUser || (!loaded && currentUser?.businessId)) &&
    isBookkeeper === undefined
  ) {
    return null;
  }

  if (
    businessRequired &&
    isBookkeeper !== undefined &&
    !currentUser?.businessId
  ) {
    return <Redirect to={ROUTES_AUTH.SIGNUP} />;
  }

  const editorNotMatched = requiresEditor && !isUserEditor;
  const adminNotMatched = requiresAdmin && !isUserAdmin;
  const featureFlagNotMatched = requiredFeatureFlag && !ffEnabled;

  if (
    (businessUnique && currentUser?.businessId) ||
    editorNotMatched ||
    adminNotMatched ||
    featureFlagNotMatched
  ) {
    return <Redirect to={routeToRedirect} />;
  }
  return <Route {...props} />;
};

export default AuthenticatedRoute;
