import theme from 'modules/Theme';
import breakpoints, { min, max } from 'modules/Theme/breakpoints';
import { slidingCenterUnderline } from 'modules/Theme/mixins';
import { css } from 'modules/Theme/styled-components';

export interface LinkProps {
  $active?: boolean;
  $menuStyle?: 'dark' | 'light';
  $onlyIcon?: boolean;
  color?: string;
  underlineHeight?: number;
  $isBoldUnderLine?: boolean;
  isBookkeeper?: boolean;
  isLinkToSubmenu?: boolean;
}

const hoverStyles = () => css<LinkProps>`
  span {
    font-weight: 600;
  }

  .icon-hover-effect {
    fill: ${({ isBookkeeper }) =>
      isBookkeeper ? `${theme.colors.gray0}` : `${theme.colors.brand500}`};
    &--blue {
      fill: ${({ isBookkeeper }) => isBookkeeper && `${theme.colors.brand500}`};
    }
    &--transparent {
      fill: ${({ isBookkeeper }) => isBookkeeper && 'transparent'};
    }
  }
`;

const menuLinkStyles = ({ underlineHeight }: LinkProps) => css<LinkProps>`
  align-items: center;
  background-color: ${({ $active }) =>
    $active ? `${theme.colors.auxiliary100A30}` : undefined};
  color: ${({ color }) => (color ? `${color}` : `${theme.colors.primary500}`)};
  column-gap: 16px;
  display: ${({ $onlyIcon }) => ($onlyIcon ? 'flex' : 'grid')};
  font-weight: ${({ $active }) => ($active ? '600' : 'normal')};
  grid-template-columns: ${({ isLinkToSubmenu }) =>
    isLinkToSubmenu ? '24px 1fr 24px' : '24px 1fr'};
  text-decoration: none;

  @media ${min(breakpoints.sm)} {
    column-gap: initial;
    display: flex;
    grid-template-columns: initial;
  }


  @media ${min(breakpoints.md)} {
    background-color: transparent;
    column-gap: 8px;
    display: grid;
    grid-template-columns: ${({ isLinkToSubmenu }) =>
      isLinkToSubmenu ? '24px 1fr 24px' : '24px 1fr'};
  }

  svg {
    @media ${min(breakpoints.sm)} {
      display: ${({ $onlyIcon }) => ($onlyIcon ? 'block' : 'none')};
    }
    @media ${min(breakpoints.md)} {
      display: block;
    }
  }

  span{
    &::before {
      display: block;
      content: attr(data-title-for-width);
      font-weight: 600;
      height: 0;
      overflow: hidden;
      visibility: hidden;
    }
  }

  @media ${max(breakpoints.md)} {
    padding: 10px 16px 10px 20px;
    &:focus {
      background-color: ${(props) => props.theme.colors.gray200};
    }
  }

  @media ${min(breakpoints.sm)} {
    height: 100%;
    padding: 0;
    ${({ $menuStyle }) =>
      slidingCenterUnderline(
        $menuStyle === 'dark' ? theme.colors.brand500 : theme.colors.brand300,
        underlineHeight
      )}

    @media (hover: hover) {
      &:hover {
        ${hoverStyles}
      }
    }

    &:focus {
      ${hoverStyles}
    }
  }

  @media ${min(breakpoints.md)} {
    padding: ${({ $onlyIcon }) => ($onlyIcon ? undefined : '0 4px')};
  }
`;

export default menuLinkStyles;
