import { IBrowser } from 'redux-responsive/types';

import { ApplicationRootState } from 'types';

export const selectBrowser = (state: ApplicationRootState): IBrowser =>
  state.browser;
export const selectIsMobile = (state: ApplicationRootState): boolean =>
  state.browser.lessThan.medium;
export const selectIsTablet = (state: ApplicationRootState): boolean =>
  state.browser.greaterThan.small;
export const selectIsDesktop = (state: ApplicationRootState): boolean =>
  state.browser.greaterThan.medium;
export const selectIsUltraWide = (state: ApplicationRootState): boolean =>
  state.browser.greaterThan.large;
export const selectRouter = (state: ApplicationRootState) => state.router;
