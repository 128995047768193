import { FC } from 'react';

import { DialogBaseProps } from '../models/types';
import { CloseButton } from './CloseButton';

export interface DialogEmptyProps extends DialogBaseProps {}

export const DialogEmpty: FC<DialogEmptyProps> = ({ children, onClose }) => {
  return (
    <>
      <CloseButton
        onClose={onClose}
        position="absolute"
        right="0"
        top="-32px"
      />
      {children}
    </>
  );
};
