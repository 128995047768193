import IconByPath, { IconByPathProps } from './Icon/IconType/IconByPath';

const IconPasswordHidden = (props: IconByPathProps) => (
  <IconByPath
    {...props}
    fillRule="evenodd"
    path="M5.904 17.466 3.4 19.971l.707.707 2.75-2.75A12.957 12.957 0 0 0 12.036 19c5.015 0 9.367-2.84 11.535-7a13.06 13.06 0 0 0-5.35-5.437l2.857-2.856L20.37 3l-3.098 3.098A12.955 12.955 0 0 0 12.036 5C7.02 5 2.668 7.84.5 12c1.21 2.32 3.1 4.231 5.404 5.466Zm1.718-.303a11.97 11.97 0 0 0 4.414.837c4.44 0 8.318-2.411 10.394-6a12.055 12.055 0 0 0-4.949-4.696l-1.93 1.93a4.5 4.5 0 0 1-6.316 6.316l-1.613 1.613Zm.897-2.311-1.873 1.873A12.054 12.054 0 0 1 1.641 12c2.076-3.589 5.954-6 10.395-6 1.58 0 3.09.306 4.473.861L14.852 8.52a4.5 4.5 0 0 0-6.333 6.333Zm1.43-.016a3.5 3.5 0 0 0 4.888-4.887l-4.888 4.887ZM14.14 9.23l-4.91 4.91a3.5 3.5 0 0 1 4.91-4.91Z"
  />
);

export default IconPasswordHidden;
