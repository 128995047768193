import { Link } from 'react-router-dom';

import useTranslations from 'modules/I18n/hooks/useTranslations';
import { Img, Text } from 'modules/Ui';
import texts from 'modules/Ui/Menu/MenuDesktop/messages';

interface LogoProps {
  hide?: boolean;
  isBookkeeper?: boolean;
}

const Logo = (props: LogoProps) => {
  const { isBookkeeper, hide } = props;
  const { t } = useTranslations();
  const businessLogo = '/assets/logos/billin-logo.svg';
  const bookkeeperLogo = '/assets/logos/billin-logo-bookkeeper.svg';
  return (
    <>
      {!hide && (
        <>
          <Text hide tag="h1" textAlign="center">
            Billin
          </Text>
          <Text height="20px" textAlign="center">
            <Link to="/" aria-label={t(texts.goToHome)}>
              <Img
                aria-hidden={false}
                alt="Billin"
                height={20}
                src={isBookkeeper ? bookkeeperLogo : businessLogo}
                width={65}
              />
            </Link>
          </Text>
        </>
      )}
    </>
  );
};

export default Logo;
