import IconByPath, { IconByPathProps } from './Icon/IconType/IconByPath';

const IconTracking = (props: IconByPathProps) => (
  <IconByPath
    {...props}
    path="M12.7 2.5a9.6 9.6 0 1 1-4.603 18.027l-.297-.17.614-1.031A8.4 8.4 0 1 0 4.3 12.1c0 1.221.26 2.402.753 3.482l1.02-2.091 1.078.526-2.017 4.134L1 16.134l.526-1.078 2.535 1.237A9.566 9.566 0 0 1 3.1 12.1a9.6 9.6 0 0 1 9.6-9.6Zm.4 9.347V7.084h-1.2v5.269l3.303 3.228.84-.858-2.943-2.876Z"
    shapeRendering="geometricPrecision"
  />
);

export default IconTracking;
