import { AxiosResponse } from 'axios';

import analyticsService from 'modules/Analytics/services/analyticsService';
import { getConfig } from 'modules/App/config';
import request from 'utils/request';

import { storeCurrentUser } from '../currentUserUtil';
import { Scope } from '../models/scope';
import { setAuthData } from '../request-auth-data';
import { TokenResponse } from '../token-response';

interface AuthBusinessResponse {
  accessToken: string;
  scopes: Scope[];
}

export const requestAuthBusiness = async (
  requestBusinessId: string,
  omitCookie: boolean = false
): Promise<AxiosResponse<TokenResponse>> => {
  const endpoint = (await getConfig()).apiAuthService;

  return request<TokenResponse>(
    `${endpoint}/${requestBusinessId}${omitCookie ? '?omitCookie=true' : ''}`
  );
};

export const requestBusinessToken = async (
  requestBusinessId: string
): Promise<string> => {
  return (await requestAuthBusiness(requestBusinessId, true)).data.data.auth
    .accessToken;
};

const authBusiness = async (
  requestBusinessId: string
): Promise<AuthBusinessResponse> => {
  const { auth } = (await requestAuthBusiness(requestBusinessId)).data.data;

  // Javscript memory
  setAuthData({
    currentToken: auth.accessToken,
    expireOn: auth.expireIn + Date.now(),
    businessId: auth.businessId,
    userId: auth.userId,
    tenant: auth.tenant,
  });

  analyticsService.identify(auth.businessId, auth.userId);

  // Local storage
  storeCurrentUser({
    userId: auth.userId,
    businessId: auth.businessId,
  });

  return {
    ...auth,
  };
};

export default authBusiness;
