import { FC } from 'react';
import { useSelector } from 'react-redux';

import { selectIsMobile } from 'modules/App/store/selectors';
import useTranslations from 'modules/I18n/hooks/useTranslations';
import { Dialog } from 'modules/Modals';
import { Box, Text } from 'modules/Ui';
import { IconStateDone, IconScan } from 'modules/Ui/Icons';

import { scanDocument } from './messages';
import { ScanningDocumentModalProps } from './type';

const ScanningDocumentModal: FC<ScanningDocumentModalProps> = ({
  finished,
}) => {
  const { t } = useTranslations();
  const isMobile = useSelector(selectIsMobile);
  return (
    <Dialog
      id="scanning-document"
      dialogWidth={{ _: 'calc(100vw - 16px)', md: '354px' }}
      height="162px"
      underlayMarginTop={isMobile ? '176px' : '246px'}
    >
      <Box display="flex" flexDirection="column" alignItems="center">
        {finished ? (
          <>
            <IconStateDone marginBottom="11px" />
            <Text color="primary300" fontSize={16} lineHeight={28}>
              {t(scanDocument.scannedDocument)}
            </Text>
          </>
        ) : (
          <>
            <IconScan isAnimate marginBottom="11px" size={40} />
            <Text color="primary300" fontSize={16} lineHeight={28}>
              {t(scanDocument.scanningDocument)}
            </Text>
          </>
        )}
      </Box>
    </Dialog>
  );
};

export default ScanningDocumentModal;
