import { defineMessages } from 'react-intl';

import { DefineMessages } from 'modules/I18n/types';

const messages: DefineMessages = defineMessages({
  faq1: { id: 'gofre.help.Bookkeeper.faq.1' },
  faq1Answer: { id: 'gofre.help.Bookkeeper.faq.1.answer' },
  faq2: { id: 'gofre.help.Bookkeeper.faq.2' },
  faq2Answer: { id: 'gofre.help.Bookkeeper.faq.2.answer' },
  faq3: { id: 'gofre.help.Bookkeeper.faq.3' },
  faq3Answer: { id: 'gofre.help.Bookkeeper.faq.3.answer' },
  faq4: { id: 'gofre.help.Bookkeeper.faq.4' },
  faq4Answer: { id: 'gofre.help.Bookkeeper.faq.4.answer' },
});
export default messages;
