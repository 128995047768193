import { ReactNode, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import styled from 'styled-components';
import {
  gridTemplateColumns,
  GridTemplateColumnsProps,
  gridTemplateRows,
  GridTemplateRowsProps,
  layout,
  LayoutProps,
  flexbox,
  FlexboxProps,
} from 'styled-system';

import { selectIsUltraWide } from 'modules/App/store/selectors';

import Box from '../Box';

export interface CarouselInputProps {
  className?: string;
  id: string;
  initialItemsPerPage?: number;
  slidesPercentageMovement?: number;
  initialSelectedPage?: number;
}

export interface ContainerProps extends CarouselInputProps {
  items: ReactNode[];
  itemsPerPage: number;
  pages: number;
  selectedPage: number;
  setItemsPerPage(prop: number): void;
  setSelectedPage(prop: number): void;
}

export interface CarouseContainerlWrapperProps<T>
  extends CarouselInputProps,
    GridTemplateColumnsProps,
    GridTemplateRowsProps,
    LayoutProps,
    FlexboxProps {
  container(props: ContainerProps): void;
  elements?: T[];
  render?: (item: T, firstPage: boolean) => ReactNode;
  content?: ReactNode[];
}

const StyledWrapper = styled(Box)`
  ${gridTemplateColumns}
  ${gridTemplateRows}
  ${layout}
  ${flexbox}
`;

export function CarouselBase<T>({
  className,
  container,
  id,
  initialItemsPerPage = 4,
  elements,
  content,
  slidesPercentageMovement,
  initialSelectedPage = 1,
  render,
  ...rest
}: CarouseContainerlWrapperProps<T>) {
  const isWideScreen = useSelector(selectIsUltraWide);
  const [itemsPerPage, setItemsPerPage] = useState(initialItemsPerPage);
  const [selectedPage, setSelectedPage] = useState(initialSelectedPage);

  const memoItems = useMemo(() => {
    return elements?.map((xs, idx) => render?.(xs, idx < initialItemsPerPage));
  }, [elements, itemsPerPage]);

  const items = content ?? (memoItems || []);

  const pages = useMemo(() => {
    return Math.ceil((items?.length || 0) / itemsPerPage);
  }, [itemsPerPage, items?.length]);
  useEffect(() => {
    if (isWideScreen) {
      setSelectedPage(initialSelectedPage);
      setItemsPerPage(initialItemsPerPage);
    } else {
      setSelectedPage(initialSelectedPage);
      setItemsPerPage(3);
    }
  }, [isWideScreen]);

  return (
    <StyledWrapper
      className={className}
      id={id}
      pages={pages}
      selectedPage={selectedPage}
      tag="ul"
      {...rest}
    >
      {container({
        id,
        initialItemsPerPage,
        itemsPerPage,
        items,
        pages,
        selectedPage,
        setItemsPerPage,
        setSelectedPage,
        slidesPercentageMovement,
      })}
    </StyledWrapper>
  );
}
