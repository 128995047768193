import useTranslations from 'modules/I18n/hooks/useTranslations';

import Box from '../Box';
import Spinner from '../Spinner';
import { Sizes } from '../Text/Text';
import { zIndex } from '../zIndex';
import messages from './messages';

interface LoadingIndicatorProps {
  backdropColor?: string;
  color?: string;
  text?: string;
  textColor?: string;
  full?: boolean;
  textSize?: Sizes;
}

const LoadingIndicator: React.FC<LoadingIndicatorProps> = ({
  full,
  backdropColor = 'backdropDark',
  color = 'gray0',
  text,
  textColor = 'auxiliary',
  textSize,
}) => {
  const { t } = useTranslations();
  return (
    <Box
      height="100%"
      left="0"
      top="0"
      width="100%"
      position={full ? 'fixed' : 'absolute'}
      zIndex={full ? zIndex.HEADER : undefined}
    >
      <Spinner
        {...{
          color,
          text,
          backdropColor,
          fullScreen: true,
          hiddenText: text ? undefined : t(messages.checkText),
          size: 80,
          textColor,
          textSize,
        }}
      />
    </Box>
  );
};

export default LoadingIndicator;
