import { FC, MouseEvent } from 'react';

import useTheme from 'modules/Theme/hooks/useTheme';
import styled, { keyframes } from 'modules/Theme/styled-components';

import Box from '../Box/Box';
import Text from '../Text/Text';
import { zIndex } from '../zIndex';

interface Props {
  backdropColor: string;
  id: string;
  onBackdropClick?: () => void;
  open: boolean;
  title?: string;
  wrapperTag?: 'li' | 'div';
}

const wrapperTransition = keyframes`
  from {
    transform: translateY(305px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

const Wrapper = styled(Box)<Props>`
  animation: ${wrapperTransition}  0.5s ease-out;
  .actionItem__List {
    .actionItem {
      &:last-child {
        border-bottom: none;
      }
    }
  }
`;

const Backdrop = styled(Box)<Props>`
  transform-origin: left top;
  transform: scale(10);
`;

const MobileActions: FC<Props> = ({
  backdropColor,
  children,
  id,
  onBackdropClick,
  open,
  title,
  wrapperTag = 'ul',
}) => {
  const theme = useTheme();
  return (
    <Box
      aria-hidden={!open}
      aria-labelledby={title && `${id}-content`}
      aria-modal={open ? 'true' : 'false'}
      id={id}
      role="dialog"
      tabIndex={open ? '0' : '-1'}
    >
      {open && (
        <>
          <Backdrop
            open
            backgroundColor={backdropColor}
            fadeIn
            height="10vh"
            left="0"
            position="fixed"
            top="0"
            width="10vw"
            zIndex={`${zIndex.MENU_BACKDROP}`}
            onClick={(ev: MouseEvent<HTMLElement>) => {
              ev.stopPropagation();
              if (onBackdropClick) {
                onBackdropClick();
              }
            }}
          />
          <Wrapper
            backgroundColor="gray0"
            borderRadius="15px 15px 0 0"
            bottom="0"
            left="0"
            open
            padding="18px 0 10px"
            position="fixed"
            width="100vw"
            zIndex={`${zIndex.MENU_MOBILE_OPEN}`}
          >
            {title && (
              <Text
                borderBottom={`1px solid ${theme.colors.gray200}`}
                fontSize={14}
                id={`${id}-content`}
                lineHeight={28}
                padding="0 48px 16px"
              >
                {title}
              </Text>
            )}

            <Box margin="8px 16px 14px" tag={wrapperTag}>
              {children}
            </Box>
          </Wrapper>
        </>
      )}
    </Box>
  );
};

export default MobileActions;
