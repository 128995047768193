import { FC } from 'react';
import { Hidden, Visible } from 'react-grid-system';
import { useSelector } from 'react-redux';

import { selectIsMobile } from 'modules/App/store/selectors';
import useTranslations from 'modules/I18n/hooks/useTranslations';
import { useOpenModal } from 'modules/Modals';
import { ModalTypes } from 'modules/Modals/constants';
import useTheme from 'modules/Theme/hooks/useTheme';
import { Box, HeaderResume, ResumeItem } from 'modules/Ui';
import { OnlyIconButton } from 'modules/Ui/Form';
import { IconEdit } from 'modules/Ui/Icons';
import useIsReader from 'modules/Users/hooks/useIsReader';
import { formatDate } from 'utils/dates';

import { Remittance, RemittanceStatus } from '../../../types';
import { messages } from './messages';

export interface Props {
  remittance: Remittance;
  amount: number;
  status?: RemittanceStatus;
  isEditable?: boolean;
}

const RemittancesHeader: FC<Props> = ({
  remittance,
  amount,
  status,
  isEditable,
  ...rest
}) => {
  const { t, formatCurrency } = useTranslations();
  const theme = useTheme();
  const openModal = useOpenModal();
  const {
    remittanceConcept,
    remittanceExpiration,
    remittanceId,
    remittanceSepa,
    accountingAccountAlias,
  } = remittance;
  const isMobile = useSelector(selectIsMobile);
  const isReader = useIsReader();
  const hasRemittanceSepa = remittanceSepa?.length;
  const canEdit =
    amount !== 0 &&
    status !== RemittanceStatus.COMPLETED &&
    isEditable &&
    !isReader;
  const editableGrid = hasRemittanceSepa
    ? 'repeat(6, auto) 40px'
    : 'repeat(5, auto) 40px';
  const defaultGrid = `repeat(${hasRemittanceSepa ? '6' : '5'}, auto)`;
  return (
    <HeaderResume
      columnGap={{ _: '8px', sm: '16px', md: '32px' }}
      gridTemplateColumns={{
        _: '1fr auto',
        sm: '1fr 1fr auto',
        md: canEdit ? editableGrid : defaultGrid,
      }}
      rowGap={{ _: '8px', sm: '16px', md: 'initial' }}
      {...rest}
    >
      <ResumeItem
        description={remittanceConcept}
        fontWeight={isMobile ? '600' : undefined}
        title={t(messages.concept)}
      />
      <ResumeItem
        description={formatDate(remittanceExpiration)}
        title={t(messages.expiration)}
      />
      <Visible xs>
        <ResumeItem
          description={accountingAccountAlias}
          title={t(messages.account)}
        />
      </Visible>
      {hasRemittanceSepa && (
        <ResumeItem
          description={remittanceSepa}
          textAlign={isMobile ? 'right' : undefined}
          title={t(messages.type)}
        />
      )}
      <Hidden xs>
        <ResumeItem
          description={accountingAccountAlias}
          title={t(messages.account)}
          gridColumn={{
            sm: '1',
            md: 'auto',
          }}
        />
      </Hidden>
      <ResumeItem
        description={remittanceId}
        title={t(messages.id)}
        gridColumn={{
          _: '1',
          sm: 'auto',
          md: 'auto',
        }}
      />
      <ResumeItem
        description={formatCurrency(amount)}
        fontWeight="600"
        textAlign="right"
        title={t(messages.amount)}
      />
      <Hidden xs>
        {canEdit && (
          <Box
            gridColumnStart={{ _: '4', md: 'initial' }}
            gridRowEnd={{ _: '3', md: 'initial' }}
            gridRowStart={{ _: '1', md: 'initial' }}
            justifySelf={{ sm: 'center' }}
            tag="li"
          >
            <OnlyIconButton
              ariaLabel={`${t(messages.edit)} ${remittanceId}`}
              title={`${t(messages.edit)} ${remittanceId}`}
              onClick={() => {
                openModal({
                  type: ModalTypes.EDIT_REMITTANCE_MODAL,
                  remittance,
                });
              }}
              icon={<IconEdit color="brand500" />}
              backgroundHoverColor={theme.colors.gray0}
            />
          </Box>
        )}
      </Hidden>
    </HeaderResume>
  );
};

export default RemittancesHeader;
