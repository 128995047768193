import { useInvalidateAndUpdate } from 'modules/App/hooks/useInvalidateAndUpdate';
import useNotifications from 'modules/Notifications/hooks/useNotifications';
import { useBlockingMutation } from 'utils/useBlockingMutation';

import { remittanceNotifications } from '../messages';
import createRemittance, {
  CreateRemittanceResponse,
  invalidateQueries,
} from '../services/createRemittance';
import { useRemittanceErrorHandler } from './useRemittanceErrorHandler';

function useCreateRemittance({
  callback,
  showSuccessNotification = true,
}: {
  callback?: (response: CreateRemittanceResponse) => void;
  showSuccessNotification?: boolean;
  fromPaymentList?: boolean;
}) {
  const notifications = useNotifications();
  const invalidateAndUpdate = useInvalidateAndUpdate();
  const { handleErrors } = useRemittanceErrorHandler();

  const { mutate, mutateAsync } = useBlockingMutation(createRemittance, {
    onSuccess: async (response): Promise<CreateRemittanceResponse> => {
      if (showSuccessNotification) {
        const message = remittanceNotifications.createSuccess.id;
        notifications.success(message, { ttl: 5000 });
      }
      invalidateAndUpdate({ invalidateQueries, isAsync: true });
      callback?.(response);
      return response;
    },

    onError: handleErrors,
  });
  return { mutate, mutateAsync };
}

export default useCreateRemittance;
