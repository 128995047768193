import { FC } from 'react';
import { Link } from 'react-router-dom';

import styled from 'modules/Theme/styled-components';
import { Button } from 'modules/Ui';
import { validProps } from 'modules/Ui/Html/A';
import { isValidProp } from 'modules/Ui/Html/cleanProps';
import { commonProps } from 'modules/Ui/Html/HtmlComponent';

import menuLinkStyles, { LinkProps } from '../../styles/menuLink';

type ConditionalToLinkProps = LinkProps & {
  onClick?: () => void;
  onlyIcon?: boolean;
  active?: boolean;
  isBoldUnderLine?: boolean;
  isLinkToSubmenu?: boolean;
  text?: string;
  to?: string;
  ['data-testid']?: string;
  isBookkeeper?: boolean;
  rel?: string;
};

const StyledLink = styled(Link).withConfig({
  shouldForwardProp: (prop) => {
    return isValidProp([...commonProps, ...validProps, 'to'], prop);
  },
})<Omit<ConditionalToLinkProps, 'closeMenu' | 'onlyIcon'>>`
  ${({ active, isLinkToSubmenu, isBoldUnderLine, isBookkeeper, ...rest }) =>
    menuLinkStyles({
      ...rest,
      underlineHeight: isBoldUnderLine ? 3 : undefined,
    })}
`;

const StyledButton = styled(Button)<
  Omit<ConditionalToLinkProps, 'closeMenu' | 'onlyIcon'>
>`
  ${(props) => menuLinkStyles(props)}
`;

export const ConditionalToLink: FC<ConditionalToLinkProps> = ({
  active,
  onClick,
  color,
  onlyIcon,
  $menuStyle = 'dark',
  text,
  isLinkToSubmenu = false,
  to,
  children,
  isBoldUnderLine,
  isBookkeeper,
  rel,
  ...rest
}) => {
  const defaultProps = {
    $active: active,
    onClick,
    color,
    $onlyIcon: onlyIcon,
    isLinkToSubmenu,
    $menuStyle,
    'aria-label': onlyIcon ? `${text}` : undefined,
    'aria-selected': active ? true : undefined,
  };
  if (to) {
    return (
      <StyledLink
        {...defaultProps}
        to={to}
        isBoldUnderLine={isBoldUnderLine}
        isBookkeeper={isBookkeeper}
        isLinkToSubmenu={isLinkToSubmenu}
        rel={rel}
        {...rest}
      >
        {children}
      </StyledLink>
    );
  }
  return (
    <StyledButton
      active={active}
      aria-controls={isLinkToSubmenu ? 'submenu' : undefined}
      aria-haspopup={isLinkToSubmenu ? 'true' : undefined}
      isLinkToSubmenu={isLinkToSubmenu}
      onClick={onClick}
      width="100%"
      {...commonProps}
    >
      {children}
    </StyledButton>
  );
};
