import { FC } from 'react';

import { Box, Img } from 'modules/Ui';

const PaymentCards: FC = () => {
  return (
    <Box
      alignItems="center"
      alingSelf="end"
      columnGap={{ _: '32px', sm: '24px', md: '14px', lg: '30px' }}
      display="grid"
      gridTemplateColumns="repeat(4, auto)"
      marginBottom={{ _: '32px', sm: 'initial' }}
      paddingBottom={{ sm: '24px', md: '30px', lg: '24px' }}
      tag="ul"
    >
      <Box height={{ _: '24px', sm: '31px', md: '22px', lg: '32px' }} tag="li">
        <Img
          alt="Mastercard"
          height="100%"
          src="/assets/logos/mastercard.svg"
        />
      </Box>
      <Box height={{ _: '14px', sm: '19px', md: '14px', lg: '19px' }} tag="li">
        <Img alt="Visa" src="/assets/logos/visa.svg" height="100%" />
      </Box>
      <Box height={{ _: '23px', sm: '29px', md: '21px', lg: '29px' }} tag="li">
        <Img
          alt="American Express"
          height="100%"
          src="/assets/logos/american-express.svg"
        />
      </Box>
      <Box tag="li" height={{ _: '23px', sm: '29px', md: '21px', lg: '29px' }}>
        <Img alt="Stripe" src="/assets/logos/stripe.svg" height="100%" />
      </Box>
    </Box>
  );
};

export default PaymentCards;
