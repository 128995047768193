import { FC } from 'react';

import useIsBookkeeperUser from 'modules/Bookkeeper/hooks/useIsBookkeeperUser';
import useHasCustomerSupport from 'modules/Help/hooks/useHasCustomerSupport';
import useTranslations from 'modules/I18n/hooks/useTranslations';
import useTheme from 'modules/Theme/hooks/useTheme';
import { Box, Text } from 'modules/Ui';

import HelpEmail from './Components/HelpMail';
import HelpPhone from './Components/HelpPhone';
import messages from './messages';

const HelpModule: FC = () => {
  const { t } = useTranslations();
  const { code, hasPhoneSupport } = useHasCustomerSupport();
  const isBookkeeper = useIsBookkeeperUser();
  const theme = useTheme();
  const phoneIsVisible = hasPhoneSupport || isBookkeeper;
  return (
    <Box
      border={`1px solid ${theme.colors.primary100}`}
      borderRadius="8px"
      marginTop={{ _: '24px', md: 'initial' }}
      padding={{
        _: '24px 32px 40px',
        md: '24px 24px 32px',
        lg: '24px 24px 48px',
      }}
    >
      <Text
        color="gray800"
        fontSize={18}
        lineHeight={22}
        marginBottom="32px"
        tag="h3"
      >
        {t(messages.helpTitle)}
      </Text>
      {phoneIsVisible && <HelpPhone />}
      <HelpEmail
        text={t(messages[`helpWriteUs${code}`] || messages.helpWriteUs)}
        email={t(messages[`helpEmail${code}`] || messages.helpEmail)}
      />
    </Box>
  );
};

export default HelpModule;
