import { FC } from 'react';
import { Hidden, Visible } from 'react-grid-system';
import { useSelector } from 'react-redux';

import { selectIsUltraWide } from 'modules/App/store/selectors';
import useTranslations from 'modules/I18n/hooks/useTranslations';
import useCommercialNotifications from 'modules/Notifications/hooks/useCommercialNotifications';
import useTheme from 'modules/Theme/hooks/useTheme';
import { Box, OnlyIconButton, PikitoContainer } from 'modules/Ui';
import { IconCross } from 'modules/Ui/Icons';
import { CommercialNotificationCategoryType } from 'modules/UserNotifications/models/UserNotificationPaylodType';

import messages from './messages';
import { NotificationsContent } from './NotificationsContent';

interface CommercialNotificationsProps {
  id: string;
  body?: string;
  category: CommercialNotificationCategoryType;
  imageUrl?: string;
  title: string;
}

export const CommercialNotification: FC<CommercialNotificationsProps> = ({
  body,
  category,
  imageUrl,
  title,
  id,
}) => {
  const { remove } = useCommercialNotifications();
  const menuIconWidth = 34 / 2;
  const pikitoWidth = 468;
  const rightPosition = pikitoWidth / 2 - menuIconWidth;
  const isUltraWide = useSelector(selectIsUltraWide);
  const theme = useTheme();
  const { t } = useTranslations();
  const handleOnClose = () => remove(id);
  return (
    <>
      <Hidden xs>
        <PikitoContainer
          backgroundColor={theme.colors.auxiliary100}
          borderColor={theme.colors.primary100}
          boxShadow="0px 8px 18px 0px #00000033"
          boxSizing="border-box"
          direction="top"
          hArrowPosition={isUltraWide ? '50%' : '80%'}
          onClose={handleOnClose}
          padding="12px"
          position="absolute"
          right={{ _: '-72px', lg: `-${rightPosition}px` }}
          top="70px"
          width={pikitoWidth}
          zIndex={2}
        >
          <Box
            columnGap="12px"
            display="grid"
            gridTemplateColumns={imageUrl ? '90px 1fr' : '1fr'}
          >
            <NotificationsContent
              onClose={handleOnClose}
              body={body}
              category={category}
              imageUrl={imageUrl}
              title={title}
            />
          </Box>
        </PikitoContainer>
      </Hidden>
      <Visible xs>
        <Box
          backgroundColor="auxiliary100"
          border={`1px solid ${theme.colors.primary100}`}
          borderRadius="4px"
          boxShadow="0px 8px 18px 0px #00000033"
          boxSizing="border-box"
          columnGap="10px"
          display="grid"
          gridTemplateColumns="40px 1fr 32px"
          left="2px"
          padding="8px 12px 12px"
          position="absolute"
          role="alert"
          top="58px"
          width="calc(100vw - 6px)"
          zIndex="2"
        >
          <NotificationsContent
            body={body}
            category={category}
            title={title}
            onClose={handleOnClose}
          />
          <OnlyIconButton
            ariaLabel={t(messages.close)}
            icon={<IconCross />}
            onClick={handleOnClose}
            size={32}
            variant="gray"
          />
        </Box>
      </Visible>
    </>
  );
};
