import { defineMessages } from 'react-intl';

export const totals = defineMessages({
  createInfo: { id: 'gofre.Home.actions.createInfo' },
  numberCharged: { id: 'gofre.Home.Totals.numberCharged' },
  totalIssuedTitle: { id: 'gofre.Home.Totals.totalIssuedTitle' },
  totalIssuedSubtitle: { id: 'gofre.Home.Totals.totalIssuedSubtitle' },
  totalInvoicesTitle: { id: 'gofre.Home.Totals.totalInvoicesTitle' },
  totalInvoicesSubtitle: { id: 'gofre.Home.Totals.totalInvoicesSubtitle' },
  totalsCharged: { id: 'gofre.Home.Totals.totalsCharged' },
});

export const bannerApp = defineMessages({
  title: { id: 'gofre.Home.Banner.BannerApp.title' },
  description: { id: 'gofre.Home.Banner.BannerApp.description' },
  linkIos: { id: 'gofre.Home.Banner.BannerApp.link.Ios' },
  linkAndroid: { id: 'gofre.Home.Banner.BannerApp.link.linkAndroid' },
});

export const bannerSettings = defineMessages({
  title: { id: 'gofre.Home.Banner.BannerSettings.title' },
  description: { id: 'gofre.Home.Banner.BannerSettings.description' },
  link: { id: 'gofre.Home.Banner.BannerSettings.link' },
});

export const bannerStock = defineMessages({
  description: { id: 'gofre.Home.Banner.BannerStock.description' },
  link: { id: 'gofre.Home.Banner.BannerStock.link' },
  new: { id: 'gofre.pillNew.text' },
  title: { id: 'gofre.Home.Banner.BannerStock.title' },
});

export const shopifyBanner = defineMessages({
  title: { id: 'gofre.Home.Shopify.Banner.title' },
  description: { id: 'gofre.Home.Shopify.Banner.description' },
  link: { id: 'gofre.Home.Shopify.Banner.link' },
});

export const expirationCardNoticeBanner = defineMessages({
  titleExpireSoon: {
    id: 'gofre.Home.expirationCardNotice.Banner.expireSoontitle',
  },
  titleHasExpired: {
    id: 'gofre.Home.expirationCardNotice.Banner.hasExpiredtitle',
  },
  description: { id: 'gofre.Home.expirationCardNotice.Banner.description' },
  link: { id: 'gofre.Home.expirationCardNotice.Banner.link' },
});
