import { VatNumberType } from 'modules/Contacts/models';

import {
  isSelfEmployed,
  isCivilSociety,
  isGoodsCommunity,
  isPublicEntityOrOther,
} from './shared/nif/validations';
import { isNifIvaCountryValid } from './shared/nifiva/validations';
import { validateNif, validateNifRules51to53 } from './specific/nif';
import { getSanitizedCountryCode, validateNifIva } from './specific/nifiva';

const validateVatNumber = (type: VatNumberType, value: string | undefined) => {
  if (type === VatNumberType.NIF_IVA) {
    return validateNifIva(value);
  }
  return validateNif(value);
};

/**
 * Here we have the collection of nif validators that the application uses.
 * The implementations comes from shared and specific folders:
 * shared: Basic nif validators. This one is shared with tortilla, so any changes here should be reflected there too.
 * specific: Extra logic you want to implement on top of basic validations. Specific to the current project
 */
export const nifUtils = {
  /**
   * Returns true if the value matches the NIF, NIE, CIF spanish regex and matches the digit control algorithm
   */
  validateNif,
  /**
   * Return true if validates the tax regime 51 to 53 rules. The nif should be either goods of community or civil society
   */
  validateNifRules51to53,
  /**
   * Returns true if the value matches the spanish GoodsComunity CIF regex.
   */
  isGoodsCommunity,
  /**
   * Returns true if the value matches the spanish CivilSociety CIF regex.
   */
  isCivilSociety,
  /**
   * Returns true if the value matches the spanish NIE or DNI regex.
   */
  isSelfEmployed,
  /**
   * Returns true if the value matches the spanish Public Entities or Other Special Entities CIF.
   */
  isPublicEntityOrOther,
  /**
   * Returns true if the value matches the NIF-IVA regex.
   */
  validateNifIva,
  /**
   * Returns true if the nif-iva country code is valid
   */
  isNifIvaCountryValid,
  /**
   * Returns the country code parsed
   */
  getSanitizedCountryCode,
  /**
   * Passes the specific vat number validation depending on vat number type param
   */
  validateVatNumber,
};
