import { FC } from 'react';

import useTranslations from 'modules/I18n/hooks/useTranslations';
import { Dialog, useCloseModal } from 'modules/Modals';
import { SupportContactInfo, Text, Button } from 'modules/Ui';

import { deactivateShopifyModal } from '../../messages';

export const DeactivateShopifyWarningModal: FC = () => {
  const { t } = useTranslations();
  const handleClose = useCloseModal();

  return (
    <Dialog
      id="deactivate-shopify-modal-warning"
      title={t(deactivateShopifyModal.title)}
      buttonPrimary={
        <Button onClick={handleClose} variant="secondary">
          {t(deactivateShopifyModal.cta)}
        </Button>
      }
      onClose={handleClose}
    >
      <Text fontSize={14} lineHeight={16}>
        {t(deactivateShopifyModal.text)}
      </Text>
      <SupportContactInfo />
    </Dialog>
  );
};
