import { useState } from 'react';
import { MentionsInput, Mention } from 'react-mentions';

import { useFormikContext } from 'formik';
import {
  space,
  layout,
  flexbox,
  LayoutProps,
  FlexboxProps,
  SpaceProps,
} from 'styled-system';

import styled from 'modules/Theme/styled-components';

import Box from '../../../Box';
import textAreaStyles from '../styles/textAreaStyles';
import defaultStyles from './defaultStyles';

export interface SuggestionsData extends LayoutProps, FlexboxProps, SpaceProps {
  id: string;
  display: string;
}

interface SuggestionsInputProps {
  text: any;
  data: SuggestionsData[];
  singleLine?: boolean;
  id: string;
  maxLength?: number;
}

const StyledMentionsInput = styled(MentionsInput)`
  ${layout}
  ${flexbox}
  ${space}
  ${textAreaStyles}

  &:focus-within {
    --textarea-border: ${(props) => props.theme.colors.brand500};
  }

  .mention {
    &:before {
      background-color:  ${(props) => props.theme.colors.brand200A60};
      border-radius: 3px;
      content: "";
      height: calc(100% + 8px);
      left: -4px;
      position: absolute;
      top: -3px;
      width: calc(100% + 8px);
    }
  }

  textarea[class*="__input"],
  input[class*="__input"] {
    border-radius: 0;
    border:none;
    color: ${(props) => props.theme.colors.gray800};
    font-size: ${(props) => props.theme.fontSizes[16]};
    height: 100%;
    line-height: ${(props) => props.theme.lineHeights[26]};
    padding: 8px 16px;

    &:hover,
    &:focus {
      border-radius: 0;
      outline: none;
    }
  }

  ul {
    li:last-child {
      border-bottom: none !important;
    }
  }

  div[class$="control"] {
    ${({ singleLine }) => !singleLine && 'min-height: initial !important'}
  }

  ${({ singleLine }) => !singleLine && 'min-height: 50px'}

`;

const SuggestionsInput = (props: SuggestionsInputProps) => {
  const { id, text, data, singleLine = true, maxLength = 200 } = props;
  const formikContext = useFormikContext();
  const [value, setValue] = useState(text);
  return (
    <StyledMentionsInput
      id={id}
      maxLength={maxLength}
      onChange={(_, newValue) => {
        formikContext && formikContext.setFieldValue(id, newValue);
        setValue(newValue);
      }}
      singleLine={singleLine}
      style={defaultStyles}
      value={value}
    >
      <Mention
        className="mention"
        trigger="#"
        data={data}
        markup="##__display__##{__id__}"
        appendSpaceOnAdd
        displayTransform={(idData) => idData}
        renderSuggestion={(_suggestion, _search, highlightedDisplay) => {
          return <Box tag="span">{highlightedDisplay}</Box>;
        }}
        style={{
          position: 'relative',
        }}
      />
    </StyledMentionsInput>
  );
};

export default SuggestionsInput;
