import { PersonType } from 'modules/Documents/models/personType';

export const getNaturalPerson = (naturalPersonType?: PersonType) => {
  if (!naturalPersonType) {
    return naturalPersonType;
  }

  return naturalPersonType === PersonType.NATURAL;
};

export const getNaturalPersonType = (naturalPerson?: boolean) => {
  if (naturalPerson !== undefined) {
    return naturalPerson ? PersonType.NATURAL : PersonType.LEGAL;
  }

  return undefined;
};
