import { FC, useState } from 'react';
import { Hidden, Visible } from 'react-grid-system';
import { useSelector } from 'react-redux';

import { selectIsMobile } from 'modules/App/store/selectors';
import useTranslations from 'modules/I18n/hooks/useTranslations';
import { StepProps } from 'modules/Subscriptions/Create/types';
import { PageLayout, HeadingWithBack, Box } from 'modules/Ui';
import { menuSizes } from 'modules/Ui/Menu/MenuMobile/menuSizes';

import messages from '../messages';
import PaymentFormFooter from './PaymentFormFooter';
import PaymentOptionsForm from './PaymentOptionsForm';
import PaymentResume from './PaymentResume';
import PaymentStep from './PaymentStep';

const PaymentOptions: FC<StepProps> = (props) => {
  const { t } = useTranslations();
  const {
    currentStep,
    onCancel,
    data: { marketName },
    selectedPlan,
    setSelectedPlan,
  } = props;
  const [paymentState, setPaymentState] = useState(props.data);
  const [coupon, setCoupon] = useState(props.data.coupon);
  const isMobile = useSelector(selectIsMobile);
  return (
    <PageLayout
      asideVisibleInMobile={true}
      mainWithoutPadding={isMobile}
      pageTitle={
        <HeadingWithBack
          title={t(messages.title, { plan: marketName })}
          onClick={onCancel}
        />
      }
      gridTemplateRows={{ _: '4px auto 1fr auto', sm: 'initial' }}
      pageSteps={<PaymentStep {...{ currentStep }} />}
      actions={<PaymentFormFooter {...{ onCancel }} />}
      height={{
        _: `calc(100vh - ${menuSizes.HEADER_HEIGHT_MOBILE}px)`,
        sm: 'initial',
      }}
      mainColumn={
        <>
          <Visible xs>
            <PaymentResume
              {...paymentState}
              {...{
                setCoupon,
                currentStep,
                coupon,
                selectedPlan,
              }}
            />
          </Visible>
          <Hidden xs>
            <PaymentOptionsForm
              {...{
                ...props,
                paymentState,
                setPaymentState,
                setCoupon,
                coupon,
                setSelectedPlan,
                selectedPlan,
              }}
            />
          </Hidden>
        </>
      }
      asideColumn={
        <>
          <Hidden xs>
            <PaymentResume
              {...paymentState}
              {...{
                setCoupon,
                currentStep,
                coupon,
                selectedPlan,
              }}
            />
          </Hidden>
          <Visible xs>
            <Box backgroundColor="gray0" padding="16px">
              <PaymentOptionsForm
                {...{
                  ...props,
                  paymentState,
                  setPaymentState,
                  setCoupon,
                  coupon,
                  setSelectedPlan,
                  selectedPlan,
                }}
              />
            </Box>
          </Visible>
        </>
      }
    />
  );
};

export default PaymentOptions;
