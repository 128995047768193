import { IBrowser } from 'redux-responsive/types';

import isNativeApp from 'utils/native/isNativeApp';

import Business, { RegisteredFrom } from './models/business';

// at some point in the future this condition can be not for BAI only
export const hasElectronicDocumentProvider = (business: Business): boolean =>
  !!business.electronicDocumentProvider?.toLowerCase().startsWith('bai');

export const shouldSendElectronicDocument = (business: Business): boolean =>
  hasElectronicDocumentProvider(business) && !!business.sendElectronicDocument;

export const getRegisteredFrom = (browser: IBrowser): RegisteredFrom => {
  if (isNativeApp()) {
    return RegisteredFrom.app;
  }
  if (browser.is.small) {
    return RegisteredFrom.mobileBrowser;
  }
  return RegisteredFrom.desktopBrowser;
};
