import { useQueryClient } from 'react-query';

import { AxiosResponse } from 'axios';

import { PAYMENT_METHODS } from 'modules/App/queries/queries';
import useTranslations from 'modules/I18n/hooks/useTranslations';
import useCloseModal from 'modules/Modals/hooks/useCloseModal';
import useNotifications from 'modules/Notifications/hooks/useNotifications';
import { useBlockingMutation } from 'utils/useBlockingMutation';

import { ERRORS } from '../errors';
import { messages } from '../messages';
import { PaymentMethod } from '../models/paymentMethods';
import createPaymentMethod from '../services/createPaymentMethod';

type UseCreatePaymentMethodProps = {
  onSuccess?: (paymentMethod: PaymentMethod) => void;
};

const useCreatePaymentMethod = (props: UseCreatePaymentMethodProps) => {
  const { onSuccess } = props;
  const { t } = useTranslations();
  const notifications = useNotifications();
  const queryClient = useQueryClient();
  const closeModal = useCloseModal();
  const { mutate } = useBlockingMutation(createPaymentMethod, {
    onSuccess: (response) => {
      queryClient.invalidateQueries(PAYMENT_METHODS);
      onSuccess && onSuccess(response);
      notifications.success(t(messages.createSuccess), { ttl: 3000 });
      closeModal();
    },
    onError: (error: {
      response?: AxiosResponse<{
        code?: string;
        message?: string;
      }>;
    }) => {
      if (error?.response?.data?.code === ERRORS.BLACKLIST_FOUND) {
        notifications.error(t(messages.blacklistFound));
        closeModal();
      }
    },
  });

  return { mutate };
};

export default useCreatePaymentMethod;
