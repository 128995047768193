import { Hidden } from 'react-grid-system';

import clsx from 'clsx';

import useTranslations from 'modules/I18n/hooks/useTranslations';
import useTheme from 'modules/Theme/hooks/useTheme';
import styled from 'modules/Theme/styled-components';
import Checkbox from 'modules/Ui/Form/Checkbox';

import Box from '../Box';
import IconArrowSecondary from '../Icons/IconArrowSecondary';
import Text from '../Text';
import {
  DataTableColumn,
  DataTableSort,
  DataTableSortOrder,
  SortOrderValues,
} from './DataTable.models';
import { dataTableMessages } from './messages';

const DataTableHeader = styled(DataTableHeaderBase)`
  th {
    .filter__button {
      @media (hover: hover) {
        &:hover {
          color: ${(props) => props.theme.colors.brand500};
          cursor: pointer;
        }
      }

      &:focus {
        color: ${(props) => props.theme.colors.brand500};
      }
    }

    &.sorted .filter__button  {
      color: ${(props) => props.theme.colors.brand500};
    }
  }
`;

const defaultOrderValues: SortOrderValues = {
  ascOrder: DataTableSortOrder.ASC,
  descOrder: DataTableSortOrder.DESC,
};

function handleChangeSort<T extends object, U extends keyof T>(
  column: DataTableColumn<T, U>,
  sortBy: DataTableSort<T, U> | undefined,
  onSortChange: (sortBy?: DataTableSort<T, U>) => void,
  sortOrderValues: SortOrderValues
) {
  const sortField = column.sortField ?? column.field;
  if (
    sortField === sortBy?.field &&
    sortBy?.order === sortOrderValues.ascOrder
  ) {
    onSortChange({
      field: sortField,
      order: sortOrderValues.descOrder,
    });
  } else {
    onSortChange({
      field: sortField,
      order: sortOrderValues.ascOrder,
    });
  }
}

function keyfor<T extends object, U extends keyof T>(
  column: DataTableColumn<T, U>,
  index: number
) {
  return (
    column.key ??
    (typeof column.name === 'string'
      ? (column.name as string)
      : `datatable-header-key-${index}`)
  );
}

interface DataTableHeaderProps<T extends object, U extends keyof T> {
  className?: string;
  columns: DataTableColumn<T, U>[];
  withCheck?: boolean;
  checked?: boolean;
  sortBy?: DataTableSort<T, U>;
  onCheck?: () => void;
  onSortChange?: (sortBy?: DataTableSort<T, U>) => void;
  showEmptySpace?: boolean;
}

function DataTableHeaderBase<T extends object, U extends keyof T>({
  className,
  columns,
  withCheck,
  checked,
  sortBy,
  onCheck,
  onSortChange,
  showEmptySpace = true,
}: DataTableHeaderProps<T, U>) {
  const theme = useTheme();
  const { t } = useTranslations();
  const sortByValue = sortBy && (
    <IconArrowSecondary
      transform={
        sortBy.order === DataTableSortOrder.ASC ||
        sortBy.order === DataTableSortOrder.ASC_NULLS_FIRST ||
        sortBy.order === DataTableSortOrder.ASC_NULLS_LAST
          ? 'rotate(-90deg)'
          : 'rotate(90deg)'
      }
      size={14}
      fadeIn
    />
  );
  return (
    <thead className={className}>
      <tr>
        {showEmptySpace && (
          <Hidden xs sm>
            <Box
              borderBottom="none"
              paddingLeft="0"
              tag="th"
              width={{ sm: '16px', md: '24px', lg: '32px' }}
            />
          </Hidden>
        )}
        <Box
          borderBottom={`1px solid ${theme.colors.primary500}`}
          data-testid="datatable-header-check"
          display={!withCheck && 'none'}
          paddingLeft="0"
          tag="th"
          width={withCheck ? { sm: '24px' } : undefined}
        >
          {withCheck && (
            <Checkbox
              checked={checked}
              id="check-all"
              onChange={() => {
                if (onCheck) {
                  onCheck();
                }
              }}
              label={t(dataTableMessages.checkSelectAllItems)}
              labelHidden
              size={22}
            />
          )}
        </Box>
        {columns.map((column, index) => {
          const sortField = column.sortField ?? column.field;
          const { sortOrderValues } = column;
          const isSorted = sortBy?.field === sortField;
          return (
            <Box
              boxSizing="border-box"
              borderBottom={`1px solid ${theme.colors.primary500}`}
              className={`${clsx({
                sorted: isSorted,
                'disable-sort': column.disableSort,
              })} data__table--${column.className}`}
              data-testid={`datatable-header-${index}`}
              display={column.display}
              fontFamily="Inter"
              fontSize={12}
              fontWeight="600"
              height={{ sm: '41px' }}
              key={keyfor(column, index)}
              line-height="normal"
              maxWidth={column.maxWidth}
              minWidth={column.minWidth}
              paddingLeft={{ sm: '8px', md: '16px' }}
              paddingBottom={{ sm: '8px' }}
              scope="col"
              tag="th"
              textAlign={column.headerTextAlign ?? 'left'}
              textTransform="uppercase"
              width={column.width}
            >
              <Box
                aria-label={(() => {
                  if (!isSorted) {
                    return undefined;
                  }
                  return [
                    DataTableSortOrder.ASC,
                    DataTableSortOrder.ASC_NULLS_FIRST,
                    DataTableSortOrder.ASC_NULLS_LAST,
                  ].includes(sortBy.order)
                    ? `${column.name} ${t(dataTableMessages.filterItemsDesc)}`
                    : `${column.name} ${t(dataTableMessages.filterItemsAsc)}`;
                })()}
                color="primary500"
                className={
                  !column.disableSort && onSortChange && 'filter__button'
                }
                display={isSorted ? 'grid' : 'block'}
                fontSize={11}
                gridTemplateColumns={
                  column.headerTextAlign ? '1fr 14px' : 'auto 1fr'
                }
                gap={isSorted ? '4px' : ''}
                lineHeight={13}
                data-testid={`datatable-header-button-${index}`}
                onClick={
                  !column.disableSort && onSortChange
                    ? () =>
                        handleChangeSort(
                          column,
                          sortBy,
                          onSortChange,
                          sortOrderValues ?? defaultOrderValues
                        )
                    : undefined
                }
                role={
                  !column.disableSort && onSortChange ? 'button' : undefined
                }
                tabIndex={!column.disableSort && onSortChange ? '0' : undefined}
                tag="span"
                textAlign={column.headerTextAlign ?? 'left'}
                textTransform="uppercase"
              >
                <Box tag="span">
                  {column.name}
                  {column.subname && (
                    <Text display="block" tag="span" textTransform="capitalize">
                      {column.subname}
                    </Text>
                  )}
                </Box>
                {sortField === sortBy?.field && sortByValue}
              </Box>
            </Box>
          );
        })}
        <Hidden xs sm>
          {showEmptySpace && (
            <Box
              borderBottom="none"
              paddingLeft="0"
              tag="th"
              width={{ sm: '16px', md: '24px', lg: '32px' }}
            />
          )}
        </Hidden>
      </tr>
    </thead>
  );
}

export default DataTableHeader as typeof DataTableHeaderBase;
