import { useCallback, FC } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { selectIsMobile } from 'modules/App/store/selectors';
import useTranslations from 'modules/I18n/hooks/useTranslations';
import { Dialog, useCloseModal } from 'modules/Modals';
import { ROUTES } from 'modules/Subscriptions/routesMap';
import { Box, Text, Button, Img } from 'modules/Ui';
import { ROUTES as DOCROUTES } from 'pages/documents/routesMap';

import messages from './messages';

const CreateRecurrentErrorModal: FC = () => {
  const { t } = useTranslations();
  const history = useHistory();

  const closeModal = useCloseModal();
  const onCancel = useCallback(() => {
    closeModal();
    history.push(DOCROUTES.DOCUMENTS);
  }, []);

  const img = '/assets/hero-planes.svg';
  const isMobile = useSelector(selectIsMobile);

  return (
    <Dialog
      id="recurrent-error-modal"
      title={t(messages.title)}
      type="fullScreen"
      buttonPrimary={
        <Button
          data-testid="modal-delete-submit"
          variant="secondary"
          onClick={() => {
            history.push(ROUTES.SUBSCRIPTIONS_PLANS);
            closeModal();
          }}
        >
          {t(messages.plansButton)}
        </Button>
      }
      onClose={onCancel}
      content={
        <Box
          columnsGap={{ sm: '32px' }}
          display="grid"
          gridTemplateColumns={{ sm: '1fr auto' }}
          rowGap={{ _: '12px', sm: '24px' }}
        >
          <Img
            gridArea={{ sm: '1 / 2 / 2 / 3' }}
            src={img}
            width={isMobile ? '100%' : '180px'}
          />
          <Box>
            <Text color="gray800" fontWeight="600" marginBottom="16px">
              {t(messages.subtitle)}
            </Text>
            <Text>
              {t<Element, JSX.Element>(messages.text, {
                br: () => <br />,
              })}
            </Text>
          </Box>
        </Box>
      }
    />
  );
};

export default CreateRecurrentErrorModal;
