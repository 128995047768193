import ActionTypes from './constants';
import { Actions, State } from './types';

export const initialState = {
  props: {
    type: undefined,
  },
};

const reducer = (state: State = initialState, action: Actions): State => {
  switch (action.type) {
    case ActionTypes.SHOW_MODAL:
      return {
        props: action.payload.props,
      };
    case ActionTypes.HIDE_MODAL:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
