import { FC } from 'react';

import {
  background,
  border,
  boxShadow,
  flexbox,
  layout,
  position,
  ResponsiveValue,
  space,
  Theme,
  TLengthStyledSystem,
  typography,
} from 'styled-system';

import { variantLight } from 'modules/Theme/mixins/buttonVariants';
import styled, { css } from 'modules/Theme/styled-components';
import { Text, Link } from 'modules/Ui';
import { LinkProps } from 'modules/Ui/Link/Link';
import { TextFullProps } from 'modules/Ui/Text/Text';

// prettier-ignore
export interface ActionProps extends LinkProps {
  borderRadius?: string;
  boxSizing?: 'content-box' | 'border-box';
  isDisabled?: boolean;
  iconSize?: number;
  onClick?: () => void;
  role?: string;
  testId?: string;
  text: string;
  textColorHover?: string;
  textProps?: Partial<TextFullProps>;
  isEllipsable?: boolean;
  variant?: 'secondary' | 'light' | 'stroke';
  width?: string;
  color?:
    | string &
        ResponsiveValue<
          string | number | symbol,
          Required<Theme<TLengthStyledSystem>>
        >;
}

export const disabledStyles = css<ActionProps>`
    --button-background: ${(props) => props.theme.colors.auxiliary100A30};
    --button-border: ${(props) => props.theme.colors.primary200};
    --button-color: ${(props) => props.theme.colors.gray500};
    pointer-events: none;
    cursor: not-allowed;
`;

export const StyledAction = styled(Link)<ActionProps>`
  background-color: var(--button-background, ${(props) =>
    props.theme.colors.auxiliary});
  border-color: var(--button-border);
  ${({ color }) => color && ` var(--button-color, ${color});`}
  ${({ boxSizing }) => boxSizing && `box-sizing: ${boxSizing};`}
  ${({ width }) => width && `width: ${width};`}
  ${({ borderRadius }) => borderRadius && `border-radius: ${borderRadius};`}

  svg {
    display: block;
    position: relative;
  }

  &:before {
    ${({ borderRadius }) => borderRadius && `border-radius: ${borderRadius};`}
  }

  &:focus {
    ${({ textColorHover }) => textColorHover && `color: ${textColorHover};`}
    background-color: var(--button-hover-background);
    border-color: var(--button-hover-border);
    border-width: 1px;
  }

  @media (hover: hover) {
    &:hover {
      ${({ textColorHover }) => textColorHover && `color: ${textColorHover};`}
      background-color: var(--button-hover-background);
      border-color: var(--button-hover-border);
    }
  }

  ${({ variant }) => variant === 'light' && variantLight}
  ${({ isDisabled }) => isDisabled && disabledStyles}

  ${background}
  ${border}
  ${boxShadow}
  ${flexbox}
  ${layout}
  ${position}
  ${space}
  ${typography}
`;

const ButtonAction: FC<ActionProps> = ({
  borderRadius,
  color,
  icon,
  iconColor,
  isDisabled,
  iconSize,
  minWidth,
  padding,
  role,
  target,
  testId,
  text,
  textProps,
  isEllipsable,
  variant = 'light',
  width,
  ...rest
}) => {
  return (
    <StyledAction
      alignItems="center"
      borderRadius={borderRadius}
      display={icon ? 'grid' : undefined}
      gridTemplateColumns={icon ? '24px 1fr' : undefined}
      columnGap={icon ? '12px' : undefined}
      borderStyle="solid"
      borderWidth="1px"
      boxSizing="border-box"
      color={color}
      data-testid={testId}
      icon={icon}
      iconColor={iconColor}
      isDisabled={isDisabled}
      iconSize={iconSize}
      minWidth={minWidth}
      padding={padding}
      position={variant === 'secondary' ? 'relative' : undefined}
      role={role}
      target={target}
      text={text}
      variant={variant}
      width={width}
      {...{
        ...rest,
      }}
    >
      <Text
        color="inherit"
        display="block"
        fontSize={16}
        lineHeight={20}
        position="relative"
        tag="span"
        hasEllipsis={isEllipsable}
        {...textProps}
      >
        {text}
      </Text>
    </StyledAction>
  );
};

StyledAction.defaultProps = {
  borderRadius: '8px',
  display: 'flex',
  height: '64px',
  padding: { _: '0 12px', sm: '0 16px' },
  variant: 'light',
};

export default ButtonAction;
