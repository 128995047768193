import { useState, useEffect, FC } from 'react';

import useTranslations from 'modules/I18n/hooks/useTranslations';
import { Box } from 'modules/Ui';

import { apiConfigurationForm } from '../../messages';
import { IntegrationFormToogle } from '../IntegrationFormToogle';
import { ApiConfigurationFormOptionBAI } from './ApiConfigurationFormOptionBAI';

interface Props {
  isMarked?: boolean;
}

export const ApiConfigurationFormOptions: FC<Props> = ({
  isMarked = false,
}) => {
  const { t } = useTranslations();
  const [includeReference, setIncludeReference] = useState(isMarked);
  useEffect(() => {
    setIncludeReference(isMarked);
  }, [isMarked]);
  return (
    <>
      <Box
        border="none"
        marginBottom={{ _: '64px', sm: '48px' }}
        padding="0"
        tag="fieldset"
      >
        <Box
          color="primary300"
          fontSize={12}
          lineHeight={22}
          marginBottom="8px"
          tag="legend"
          textTransform="uppercase"
        >
          {t(apiConfigurationForm.seriesOptionLegend)}
        </Box>
        <IntegrationFormToogle
          id="includeOrderReference"
          marginBottom={{ _: '32px', sm: '24px' }}
          name="includeOrderReference"
          onChange={() => {
            setIncludeReference(!includeReference);
          }}
          text={t(apiConfigurationForm.seriesToogle01label)}
          textDescription={t(apiConfigurationForm.seriesToogle01Desc)}
          value={includeReference}
        />
      </Box>
      <ApiConfigurationFormOptionBAI />
    </>
  );
};
