import { requestPoll, RequestPollParams } from '../../../utils/requestPoll';
import { AsyncTaskStatus } from '../enums';
import { fetchAsyncTaskStatus } from './fetchAsyncTaskStatus';

type PollParams<T> = Omit<RequestPollParams<T>, 'validateAsyncCondition'>;

const getAsyncTaskStatus = async <T>(
  taskId: string,
  params?: PollParams<T>
) => {
  const fiveMinutes = 5 * 60 * 1000;
  const {
    timeout = fiveMinutes,
    interval = 3000,
    cancelRequestOnDemand,
  } = params ?? {};

  async function validateAsyncCondition() {
    const result = await fetchAsyncTaskStatus(taskId);
    return hasFinished(result.status) ? result : false;
  }

  let result;
  try {
    result = await requestPoll({
      validateAsyncCondition,
      timeout,
      interval,
      cancelRequestOnDemand,
    });
  } finally {
    if (!cancelRequestOnDemand?.signal.aborted) {
      cancelRequestOnDemand?.abort();
    }
  }

  return result;
};

function hasFinished(status: AsyncTaskStatus): boolean {
  return [AsyncTaskStatus.FAILED, AsyncTaskStatus.FINISHED].includes(status);
}

export default getAsyncTaskStatus;
