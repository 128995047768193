import { useState } from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';

import { BUSINESS_ME_QUERY } from 'modules/App/queries/queries';
import { selectCurrentBusiness } from 'modules/Business/Current/selectors';
import { useCloseModal } from 'modules/Modals';
import useNotifications from 'modules/Notifications/hooks/useNotifications';
import { LoadingIndicator } from 'modules/Ui';
import requestErrorHandler from 'utils/requestErrorHandler';

import ChangeBusinessModal from '../components/ChangeBusinessModal';
import { useAuthBusiness } from './hooks/useAuthBusiness';
import { fetchBusinesses } from './services';

export default function ChangeBusiness() {
  const authBusiness = useAuthBusiness();
  const closeModal = useCloseModal();

  const { id: currentBusinessId } = useSelector(selectCurrentBusiness);
  const [selectedBusiness, setSelectedBusiness] = useState(
    currentBusinessId || ''
  );
  const notifications = useNotifications();

  const onClickChangeBusiness = async (id?: string) => {
    try {
      await authBusiness(id || selectedBusiness);
      closeModal();
    } catch (e: any) {
      const { responseCode, isNetworkError, networkErrorTranslationKey } =
        requestErrorHandler(e);
      notifications.error(
        isNetworkError ? networkErrorTranslationKey : responseCode
      );
    }
  };

  const { isLoading, data } = useQuery([BUSINESS_ME_QUERY], () =>
    fetchBusinesses()
  );

  function onSelectBusiness(id: string) {
    setSelectedBusiness(id);
  }

  if (isLoading || !data) {
    return <LoadingIndicator />;
  }

  return (
    <ChangeBusinessModal
      businesses={data.items}
      closeModal={closeModal}
      onClickChangeBusiness={onClickChangeBusiness}
      onSelectBusiness={onSelectBusiness}
      selectedBusiness={selectedBusiness}
    />
  );
}
