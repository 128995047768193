import { useCallback } from 'react';

import { useField } from 'formik';

export default function useFocusHandler(name: string) {
  const [, , helpers] = useField(name);

  return useCallback(() => {
    helpers.setError(undefined);
  }, [helpers]);
}
