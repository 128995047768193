import Limits from 'types/limits';

interface CalculatePagesArgs {
  offset: number;
  limit: Limits;
  count: number;
}

interface CalculatePagesResult {
  activePage: number;
  pages: number;
}

const calculatePages = ({
  offset,
  limit,
  count,
}: CalculatePagesArgs): CalculatePagesResult => {
  const pages = Math.max(Math.ceil(count / limit), 1);
  const activePage = offset / limit + 1;

  return {
    activePage,
    pages,
  };
};

export default calculatePages;
