export function reloadHeadScript(selector: string) {
  const script: HTMLScriptElement | null = document.querySelector(selector);
  if (!script) {
    return;
  }
  const head = document.querySelector('head');
  if (!head) {
    return;
  }
  head.removeChild(script);
  const nscript = document.createElement('script');
  nscript.src = script.src;
  setTimeout(() => head.appendChild(nscript), 100);
}
