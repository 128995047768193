import { FC } from 'react';

import { Formik } from 'formik';

import { DocumentTable } from 'modules/Documents/models/document';
import useTranslations from 'modules/I18n/hooks/useTranslations';
import { Box, ResumeItem, HeaderResume } from 'modules/Ui';
import RadioButtonCardFormik from 'modules/Ui/Formik/RadioButtonCardFormik';

import { remittanceForm } from '../../../messages';
import { Remittance, DirectDebitType, RemittanceType } from '../../../types';
import { addNewRemittanceFormModal } from '../../messages';
import { RemittanceForm, RemittanceFormType } from '../RemitanceForm';
import getValidationSchema from '../RemitanceForm/getValidationSchema';

interface Props {
  onSubmit: (values: Remittance) => void;
  initialValues?: Partial<Remittance> & {
    preSelectedDocuments?: DocumentTable[];
    totalCount?: number;
    totalAmount?: number;
    radioIncome?: boolean;
    radioExpense?: boolean;
  };
}
export const AddNewRemittanceForm: FC<Props> = ({
  onSubmit,
  initialValues,
}) => {
  const { t } = useTranslations();
  const validationSchema = getValidationSchema(t);
  const sepa = [
    {
      value: DirectDebitType.CORE,
      label: t(remittanceForm.core),
    },
    {
      value: DirectDebitType.B2B,
      label: t(remittanceForm.b2b),
    },
  ];
  const { preSelectedDocuments, totalAmount, totalCount } = initialValues || {};
  return (
    <>
      {preSelectedDocuments && (
        <HeaderResume gridTemplateColumns="auto auto">
          {totalCount && (
            <ResumeItem
              title={t(addNewRemittanceFormModal.titleTotalCount)}
              description={totalCount}
            />
          )}
          <ResumeItem
            title={t(addNewRemittanceFormModal.titleTotalAmount)}
            description={`${totalAmount}€`}
            descriptionFontWeight="600"
            textAlign="right"
          />
        </HeaderResume>
      )}
      <Formik
        onSubmit={onSubmit}
        validateOnChange={false}
        validateOnBlur={false}
        validationSchema={validationSchema}
        initialValues={{
          remittanceConcept: '',
          remittanceExpiration: new Date(),
          remittanceId: '',
          remittanceSepa: sepa[0].value,
          type: RemittanceType.DIRECT_DEBIT,
          accountingAccountId: '',
          accountingAccountAlias: '',
          ...initialValues,
        }}
      >
        {(formik) => (
          <Box
            autoComplete="off"
            columnGap="16px"
            display="grid"
            gridTemplateColumns="1fr 1fr"
            id="add-contact"
            noValidate
            onSubmit={formik.handleSubmit}
            tag="form"
          >
            {!preSelectedDocuments && (
              <Box
                columnGap="8px"
                display="grid"
                gridColumnEnd="3"
                gridColumnStart="1"
                gridTemplateColumns="138px 138px"
                id="radio-group"
                marginBottom="24px"
                role="radiogroup"
              >
                <RadioButtonCardFormik
                  height={50}
                  id="radioIncome"
                  name="type"
                  text={t(remittanceForm.income)}
                  value={RemittanceType.DIRECT_DEBIT}
                />
                <RadioButtonCardFormik
                  height={50}
                  id="radioExpense"
                  name="type"
                  text={t(remittanceForm.expense)}
                  value={RemittanceType.CREDIT_TRANSFER}
                />
              </Box>
            )}
            <RemittanceForm
              type={RemittanceFormType.CREATE}
              preSelectedDocuments={preSelectedDocuments}
              totalCount={totalCount}
              totalAmount={totalAmount}
            />
          </Box>
        )}
      </Formik>
    </>
  );
};
