import IconByPath, { IconByPathProps } from './Icon/IconType/IconByPath';

const IconInputPercentage = (props: IconByPathProps) => (
  <IconByPath
    {...props}
    path="M4 6a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6Zm2-1a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V6a1 1 0 0 0-1-1H6Zm10 3.8-.8-.8L8 15.2l.8.8L16 8.8Zm-6.828-.76a1.131 1.131 0 1 0 0 2.263 1.131 1.131 0 0 0 0-2.263Zm4.525 6.788a1.131 1.131 0 1 1 2.263 0 1.131 1.131 0 0 1-2.263 0Z"
  />
);

export default IconInputPercentage;
