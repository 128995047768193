import cleanVatNumber from 'utils/cleanVatNumber';

import {
  validateNif as sharedValidateNif,
  isNif,
  isNaturalPerson,
} from '../shared/nif/validations';

export const validateNif = (
  value: string | null | undefined,
  onlyRegex?: boolean
): boolean => {
  if (!value) {
    return false;
  }

  const cleaned = cleanVatNumber(value);
  if (onlyRegex) {
    return isNif(cleaned);
  }

  return sharedValidateNif(cleaned);
};

export const validateNifRules51to53 = (
  value: string | null | undefined,
  onlyRegex?: boolean
): boolean => {
  if (!validateNif(value, onlyRegex)) {
    return false;
  }
  const cleaned = cleanVatNumber(value as string);

  return isNaturalPerson(cleaned);
};
