import { FC } from 'react';

import useTheme from 'modules/Theme/hooks/useTheme';

import Box from '../Box';
import Text from '../Text';

interface Props {
  addded?: number;
  notAdded?: number;
  textAdded: string;
  textNotAdded: string;
}

const ImporterAddedResume: FC<Props> = ({
  addded,
  notAdded,
  textAdded,
  textNotAdded,
}) => {
  const theme = useTheme();
  return (
    <>
      <Box
        border={`1px solid ${theme.colors.gray200}`}
        borderRadius="8px"
        display="flex"
        marginBottom="24px"
        tag="dl"
      >
        <Box
          flex="1"
          borderRight={`1px solid ${theme.colors.gray200}`}
          padding="12px 24px"
        >
          <Text
            color="primary300"
            textSize="xs"
            marginBottom="6px"
            tag="dt"
            textTransform="uppercase"
          >
            {textAdded}
          </Text>
          <Text
            color="gray800"
            fontSize={30}
            fontWeight="600"
            lineHeight={28}
            tag="dd"
          >
            {addded}
          </Text>
        </Box>
        <Box flex="1" padding="12px 24px">
          <Text
            color="primary300"
            textSize="xs"
            marginBottom="6px"
            tag="dt"
            textTransform="uppercase"
          >
            {textNotAdded}
          </Text>
          <Box
            color="gray800"
            fontSize={30}
            fontWeight="600"
            lineHeight={28}
            tag="dd"
          >
            {notAdded}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ImporterAddedResume;
