import { useEffect } from 'react';

import dot from 'dot-object';
import { useFormikContext } from 'formik';

import analyticsService from '../services/analyticsService';

export default function useFormikSendErrors(formName: string) {
  const formik = useFormikContext();

  const { isSubmitting, errors } = formik;

  useEffect(() => {
    if (isSubmitting) {
      const plainErrors = {};
      dot.dot(errors, plainErrors);
      const errorKeys = Object.keys(plainErrors);
      if (errorKeys.length) {
        errorKeys.forEach((errorKey) => {
          analyticsService.formError({
            formName,
            field: errorKey,
            error: `${(plainErrors as any)[errorKey]}`,
          });
        });
      }
    }
  }, [isSubmitting, errors]);
}
