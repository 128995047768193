const calculateShownPages = (pages: number, activePage: number): number[] => {
  const buttons: number[] = [];

  if (pages < 1) return buttons;

  // First page button
  buttons.push(1);

  // Three points
  if (activePage > 3) {
    buttons.push(-1);
  }

  const previousPage = activePage - 1;
  if (previousPage > 1) {
    buttons.push(previousPage);
  }

  // Selected page button
  if (activePage > 1 && activePage < pages) {
    buttons.push(activePage);
  }

  const nextPage = activePage + 1;

  if (nextPage < pages) {
    buttons.push(nextPage);
  }

  // Three points
  if (activePage < pages - 2) {
    buttons.push(-2);
  }

  // Last page button
  if (pages > 1) {
    buttons.push(pages);
  }

  return buttons;
};

export default calculateShownPages;
