import { FC } from 'react';
import { Visible } from 'react-grid-system';
import { useSelector } from 'react-redux';

import { selectIsMobile } from 'modules/App/store/selectors';
import { selectBusiness } from 'modules/Business/Update/selectors';
import useTranslations from 'modules/I18n/hooks/useTranslations';
import { MODAL_WIDTH_SMALL, useCloseModal } from 'modules/Modals';
import { Dialog } from 'modules/Modals/components/Dialog';
import { ModalTypes } from 'modules/Modals/constants';
import { deleteAccountDialog } from 'modules/Modals/messages';
import { Text, Button } from 'modules/Ui';
import useDeleteAccount from 'modules/Users/hooks/useDeleteAccount';

interface DeleteModalProps {
  confirmTextButton: string;
  contentSubtitle: string;
  contentText01: string;
  contentText02: string;
  titleMobile?: string;
  onClick?: (value: any) => void;
}

const DeleteAccountDialog: FC<DeleteModalProps> = ({
  titleMobile,
  confirmTextButton,
  contentSubtitle,
  contentText01,
  contentText02,
  onClick,
}) => {
  const closeModal = useCloseModal();
  const business = useSelector(selectBusiness);
  const deleteAccount = useDeleteAccount(closeModal);
  const handleDeleteBusiness = async () => {
    deleteAccount({ businessId: business.id });
  };
  const isMobile = useSelector(selectIsMobile);
  const { t } = useTranslations();
  const titleMobileText = titleMobile || t(deleteAccountDialog.titleMobile);
  return (
    <Dialog
      dialogWidth={{ sm: MODAL_WIDTH_SMALL }}
      type="fullScreen"
      title={isMobile ? titleMobileText : t(deleteAccountDialog.title)}
      buttonPrimary={
        <Button variant="primary" onClick={onClick || handleDeleteBusiness}>
          {confirmTextButton}
        </Button>
      }
      multipleActionsInMobile={true}
      buttonSecondary={
        <Button
          onClick={closeModal}
          marginBottom={{ _: '16px', sm: '0' }}
          variant="stroke"
        >
          {t(deleteAccountDialog.btnClose)}
        </Button>
      }
      content={
        <>
          <Visible xs sm>
            <Text
              color="gray800"
              fontSize={28}
              fontWeight={600}
              lineHeight={34}
              marginBottom="24px"
              marginTop="20px"
            >
              {t(deleteAccountDialog.title)}
            </Text>
          </Visible>
          <Text
            color="gray800"
            fontSize={16}
            fontWeight="600"
            lineHeight={19}
            marginBottom={{ _: '24px', sm: '16px' }}
          >
            {contentSubtitle}
          </Text>
          <Text
            fontSize={16}
            lineHeight={22}
            marginBottom={{ _: '24px', sm: '16px' }}
          >
            {contentText01}
          </Text>
          <Text
            fontSize={16}
            lineHeight={22}
            marginBottom={{ _: '24px', sm: '48px' }}
          >
            {contentText02}
          </Text>
        </>
      }
      mobileBorderHeader
      onClose={closeModal}
      id={ModalTypes.DELETE_BUSINESS}
    />
  );
};

export default DeleteAccountDialog;
