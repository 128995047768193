import { useMemo, ReactNode } from 'react';

import useTranslations from 'modules/I18n/hooks/useTranslations';
import {
  IconBillinFavicon,
  IconBroadcast,
  IconMegaphone,
  IconTag,
} from 'modules/Ui/Icons';
import { CommercialNotificationCategoryType } from 'modules/UserNotifications/models/UserNotificationPaylodType';

import messages from './messages';

type VisualsOfType = {
  bgColor: string;
  color: string;
  icon: ReactNode;
  label: string;
};

export const useVisualsOfType = (
  category: CommercialNotificationCategoryType
): VisualsOfType => {
  const { t } = useTranslations();
  const visualsOfType = useMemo(() => {
    switch (category) {
      case 'new-functionality':
        return {
          bgColor: 'brand500',
          color: 'gray0',
          icon: <IconBillinFavicon color="gray0" transform="rotate(180deg)" />,
          label: t(messages.new),
        };
      case 'news':
        return {
          bgColor: 'brand400',
          color: 'gray0',
          icon: <IconBroadcast color="gray0" />,
          label: t(messages.news),
        };
      case 'campaign':
        return {
          bgColor: 'accent500',
          color: 'gray0',
          icon: <IconMegaphone color="gray0" />,
          label: t(messages.campaing),
        };
      default:
        return {
          bgColor: 'warning',
          color: 'gray700',
          icon: <IconTag color="primary500" />,
          label: t(messages.promo),
        };
    }
  }, [category]);
  return visualsOfType;
};
