import { FC, ReactNode } from 'react';
import { Hidden, Visible } from 'react-grid-system';

import { UserPermissions } from 'modules/Auth/constants';
import useHasUserScope from 'modules/Auth/hooks/checkUsersPermissions';

import { DropdownButton, DropdownSubmenuAction } from '../DropdownButton';
import { ButtonAction } from '../Form';
import { ActionMobile } from './ActionMobile';

export type ActionProps = {
  icon: ReactNode;
  id: string;
  isOpen?: boolean;
  onClick?: () => void;
  testId?: string;
  text: string;
  to?: string;
  variant?: 'secondary' | 'light' | 'stroke';
  isOnlyLink?: boolean;
  children?: ReactNode;
};
interface MainActionProps {
  primaryAction: ActionProps;
  restActions?: ActionProps[];
  toggleIsOpenMobileMenu?: () => void;
  isOpen?: boolean;
  to?: string;
}

export const PageListMainAction: FC<MainActionProps> = ({
  primaryAction,
  restActions,
  toggleIsOpenMobileMenu = () => undefined,
  isOpen,
  children,
}) => {
  const { hasUserScope } = useHasUserScope();
  const canCreate = hasUserScope(UserPermissions.SUBSCRIPTION_EDITOR);

  const onClickHandler = () => {
    toggleIsOpenMobileMenu();
    primaryAction?.onClick && primaryAction.onClick();
  };

  return (
    <>
      {canCreate && (
        <>
          <Visible xs>
            <ActionMobile
              isOpen={isOpen}
              id={primaryAction.id}
              onClick={onClickHandler}
              text={primaryAction.text}
              to={primaryAction.to}
              variant={primaryAction.variant}
              data-testid={primaryAction.testId}
              isOnlyLink={primaryAction.isOnlyLink}
              children={children}
            />
          </Visible>
          <Hidden xs>
            {restActions ? (
              <DropdownButton
                actionUrl={primaryAction.to ?? ''}
                actionText={primaryAction.text}
                icon={primaryAction.icon}
                data-testid={primaryAction.testId}
                id={primaryAction.id}
              >
                {restActions.map((action) => (
                  <DropdownSubmenuAction
                    key={action.id}
                    url={action.to ?? ''}
                    icon={action.icon}
                    text={action.text}
                    data-testid={action.id}
                    id={action.id}
                  />
                ))}
              </DropdownButton>
            ) : (
              <ButtonAction
                data-testid={primaryAction.id}
                icon={primaryAction.icon}
                onClick={primaryAction.onClick}
                padding={{ sm: '16px 24px 16px 20px' }}
                role={primaryAction.onClick && 'button'}
                text={primaryAction.text}
                to={primaryAction.to || '#'}
                variant={primaryAction.variant}
                testId={primaryAction.id}
              />
            )}
            {children}
          </Hidden>
        </>
      )}
    </>
  );
};
