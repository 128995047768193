import { FC, useCallback } from 'react';
import { Visible } from 'react-grid-system';

import useTranslations from 'modules/I18n/hooks/useTranslations';
import { DocumentSerie } from 'modules/Settings/models';
import breakpoints, { min } from 'modules/Theme/breakpoints';
import styled from 'modules/Theme/styled-components';
import { Box } from 'modules/Ui';
import {
  DataTableSort,
  DataTableSortOrder,
} from 'modules/Ui/DataTable/DataTable.models';
import { IconArrowSecondary } from 'modules/Ui/Icons';

import { series as messages } from '../../messages';

const Thead = styled.thead`
  th {
    font-family: "Inter", sans-serif;
    font-size: ${(props) => props.theme.fontSizes[13]};
    font-weight: 600;
    line-height: ${(props) => props.theme.lineHeights[22]};
    text-align: left;
    text-transform: uppercase;

    @media ${min(breakpoints.sm)} {
      padding: 14px 2px 14px 0;
      border-bottom: 1px solid ${(props) => props.theme.colors.gray200};
      border-top: 1px solid ${(props) => props.theme.colors.gray200};
    }

    &:not(.disable-sort) {
      cursor: pointer;
    }

    &:hover {
      color: ${(props) => props.theme.colors.brand500};
    }
  }

  & .sorted {
    color: ${(props) => props.theme.colors.brand500};
    text-decoration: underline;
  }
`;

interface SeriesHeaderProps {
  sortBy: DataTableSort<DocumentSerie, keyof DocumentSerie>;
  setSortBy: (sort: DataTableSort<DocumentSerie, keyof DocumentSerie>) => void;
}

export const SeriesHeader: FC<SeriesHeaderProps> = (props) => {
  const { sortBy, setSortBy } = props;
  const { t } = useTranslations();
  const sortByValue = sortBy && (
    <IconArrowSecondary
      transform={
        sortBy.order === DataTableSortOrder.ASC
          ? 'rotate(-90deg)'
          : 'rotate(90deg)'
      }
      size={14}
    />
  );

  const handleChangeSort = useCallback(
    (field: keyof DocumentSerie) => () => {
      if (field === sortBy?.field && sortBy?.order === DataTableSortOrder.ASC) {
        setSortBy({
          field,
          order: DataTableSortOrder.DESC,
        });
      } else {
        setSortBy({
          field,
          order: DataTableSortOrder.ASC,
        });
      }
    },
    [setSortBy, sortBy]
  );

  return (
    <Visible md lg xl xxl>
      <Thead>
        <tr>
          <Box
            className={sortBy?.field === 'serialCode' ? 'sorted' : ''}
            tag="th"
            onClick={handleChangeSort('serialCode')}
          >
            {t(messages.serialCode)}{' '}
            {sortBy?.field === 'serialCode' && sortByValue}
          </Box>
          <Box
            className={sortBy?.field === 'lastNumber' ? 'sorted' : ''}
            tag="th"
            onClick={handleChangeSort('lastNumber')}
          >
            {t(messages.nextNumber)}{' '}
            {sortBy?.field === 'lastNumber' && sortByValue}
          </Box>
          <Box
            className={sortBy?.field === 'targetDocument' ? 'sorted' : ''}
            tag="th"
            onClick={handleChangeSort('targetDocument')}
          >
            {t(messages.targetDocument)}{' '}
            {sortBy?.field === 'targetDocument' && sortByValue}
          </Box>
          <Box tag="th" className="disable-sort" />
        </tr>
      </Thead>
    </Visible>
  );
};
