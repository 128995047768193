import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import analyticsService from '../services/analyticsService';

export default function AnalyticsRouter() {
  const location = useLocation();

  useEffect(() => {
    analyticsService.page({
      url: `${location.pathname}${location.search}${location.hash}`,
      path: location.pathname,
      referrer: document.referrer,
      title: document.title,
      search: location.search,
    });
  }, [`${location.pathname}${location.search}${location.hash}`]);

  return null;
}
