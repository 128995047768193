/* stylelint-disable no-descending-specificity */
import { FC } from 'react';
import ReactSelect, { components } from 'react-select';

import AsyncSelect from 'react-select/async';

import useTheme from 'modules/Theme/hooks/useTheme';
import { reactSelectStyles } from 'modules/Theme/mixins';
import styled from 'modules/Theme/styled-components';
import { Box, Text } from 'modules/Ui';

import { IconPlus } from '../../../Icons';
import Button from '../../Button';
import { SelectProps } from '../../SelectCommon/types';

export const StyledSelect = styled(ReactSelect)<SelectProps>`
  ${reactSelectStyles}
`;

export const StyledAsyncSelect = styled(AsyncSelect)<SelectProps>`
  ${reactSelectStyles}
`;

export const StyledButton = styled(Button)`
  @media (hover: hover) {
    &:hover {
      background-color: ${(props) => props.theme.colors.auxiliary100};
    }
  }

  &:focus {
    background-color: ${(props) => props.theme.colors.auxiliary100};
  }
`;

interface MenuListProps {
  [propName: string]: any;
  contextualText?: string;
  createOptionBackground?: string;
  label?: string;
}

export const MenuList: FC<MenuListProps> = ({
  contextualText,
  createOptionBackground,
  label,
  ...props
}) => {
  const theme = useTheme();
  return (
    <components.MenuList {...(props as any)}>
      {props.children}
      <Box borderTop={`1px solid ${theme.colors.primary200}`}>
        {/* TODO: Extract this button to a prop */}
        <StyledButton
          alignItems="center"
          backgroundColor={createOptionBackground}
          color={theme.colors.brand500}
          columnGap="4px"
          data-testid="add-option-select"
          display="grid"
          fontSize={16}
          gridTemplateColumns="22px auto 1fr"
          height="47px"
          onClick={(e) => {
            e.preventDefault();
            props.onAddOption && props.onAddOption();
          }}
          padding="0 12px"
          width="100%"
        >
          <IconPlus size={22} color="brand500" /> {label}
          <Text
            color={theme.colors.gray800}
            hasEllipsis
            tag="span"
            textAlign="left"
          >
            {contextualText}
          </Text>
        </StyledButton>
      </Box>
    </components.MenuList>
  );
};
