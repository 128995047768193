import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  submit: { id: 'gofre.User.Form.submit' },
  title: { id: 'gofre.User.Form.title' },
  name: { id: 'gofre.User.Form.name' },
  namePlaceholder: { id: 'gofre.User.Form.namePlaceholder' },
  surname: { id: 'gofre.User.Form.surname' },
  surnamePlaceholder: { id: 'gofre.User.Form.surnamePlaceholder' },
  email: { id: 'gofre.User.Form.email' },
  emailPlaceholder: { id: 'gofre.User.Form.emailPlaceholder' },
});

export const formErrors = defineMessages({
  emailInvalid: { id: 'gofre.User.Form.email.invalid' },
  emailRepeated: { id: 'gofre.User.Form.email.repeated' },
  genericError: { id: 'gofre.User.Form.error.generic' },
});

export const userDetail = defineMessages({
  title: { id: 'gofre.Users.Detail.title' },
  userData: { id: 'gofre.Users.Detail.info' },
  name: { id: 'gofre.Users.Detail.name' },
  noName: { id: 'gofre.Users.Detail.no-name' },
  email: { id: 'gofre.Users.Detail.email' },
  role: { id: 'gofre.Users.Detail.role' },
  success: { id: 'gofre.Users.Update.success' },
  error: { id: 'gofre.Users.Update.error' },
  updateEmailError: { id: 'gofre.Users.Update.email-exists.error' },
  detailError: { id: 'gofre.Users.Detail.error' },
  // Footer
  delete: { id: 'gofre.Users.Detail.delete' },
  changePassword: { id: 'gofre.Users.Detail.change-password' },
  changePasswordSuccess: { id: 'gofre.Users.Detail.change-password.success' },
  edit: { id: 'gofre.Users.Detail.edit' },
  bookkeeper: { id: 'gofre.Roles.manager' },
  owner: { id: 'gofre.Roles.owner' },
  admin: { id: 'gofre.Roles.admin' },
  editor: { id: 'gofre.Roles.editor' },
  reader: { id: 'gofre.Roles.reader' },
});
