import { FC } from 'react';

import useTranslations from 'modules/I18n/hooks/useTranslations';
import { buttonGostStyles } from 'modules/Theme/mixins/buttonImports';
import styled from 'modules/Theme/styled-components';
import { Box, CustomExpandable } from 'modules/Ui';
import { IconAdd } from 'modules/Ui/Icons';

import CarbonCopyFields from './CarbonCopyFields';
import { messages } from './messages';

const StyledSummaryButton = styled(Box)`
  ${buttonGostStyles}
`;

export const CarbonCopyBlock: FC = () => {
  const { t } = useTranslations();
  return (
    <CustomExpandable
      titleWidth="fit-content"
      id="carbon-copy-fields"
      title={
        <StyledSummaryButton
          alignItems="center"
          columnGap="14px"
          display="grid"
          gridTemplateColumns="24px 1fr"
          height="100%"
          id="open-cc-block"
          marginBottom="12px"
          position="relative"
          tag="span"
        >
          <IconAdd />
          <Box tag="span" position="relative">
            {t(messages.ccButton)}
          </Box>
        </StyledSummaryButton>
      }
      content={<CarbonCopyFields />}
    />
  );
};
