import useTranslations from 'modules/I18n/hooks/useTranslations';
import { SelectFormikField as Select } from 'modules/Ui';

import messages from './messages';

export type SizeValue = 'small' | 'medium' | 'large';

interface Props {
  id: string;
  onChange?: (newValue: SizeValue) => void;
}

const SizeSelect: React.FC<Props> = ({ id, onChange }) => {
  const { t } = useTranslations();

  const options = [
    { value: 'large', label: t(messages.large) },
    { value: 'medium', label: t(messages.medium) },
    { value: 'small', label: t(messages.small) },
  ];

  return (
    <Select
      id={id}
      options={options}
      placeholder={t(messages.selectSize)}
      onChange={onChange}
    />
  );
};

export default SizeSelect;
