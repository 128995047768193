import useTheme from 'modules/Theme/hooks/useTheme';
import { Box } from 'modules/Ui';
import { menuSizes } from 'modules/Ui/Menu/MenuMobile/menuSizes';

import useIsBusinessBlocked from '../../hooks/useIsBusinessBlocked';
import Logo from '../Components/Logo';
import MenuDesktopEndTrial from './EndTrialMen';
import MenuBusiness from './Menu';

const MenuDesktop = () => {
  const theme = useTheme();
  const isBusinessBlocked = useIsBusinessBlocked();
  return (
    <Box
      backgroundColor="gray0"
      boxShadow={!isBusinessBlocked && `0 1px 0 ${theme.colors.primary100}`}
      height={`${menuSizes.HEADER_HEIGHT_DESKTOP}px`}
      position="relative"
      tag="header"
    >
      {!isBusinessBlocked ? (
        <Box
          alignItems="center"
          columnGap={{ sm: '16px', md: '20px', lg: '32px' }}
          display="grid"
          gridTemplateColumns="auto 1fr"
          height="100%"
          id="menu"
          margin="0 auto"
          maxWidth={{ md: theme.maxWidth.md, lg: theme.maxWidth.lg }}
          tag="nav"
          width={{ sm: 'calc(100% - 30px)', lg: 'initial' }}
        >
          <Logo />
          <MenuBusiness />
        </Box>
      ) : (
        <MenuDesktopEndTrial />
      )}
    </Box>
  );
};

export default MenuDesktop;
