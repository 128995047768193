import { FC } from 'react';

import { space, SpaceProps } from 'styled-system';

import styled from 'modules/Theme/styled-components';
import { Box, Img, Text } from 'modules/Ui';

interface Props extends SpaceProps {
  id?: string;
  subtitle: string;
  title: string;
}

const StyledWrapper = styled(Box)<Props>`
  ${space}
`;

export const ShopifyFormTitle: FC<Props> = ({
  id,
  title,
  subtitle,
  ...rest
}) => {
  return (
    <StyledWrapper {...rest}>
      <Text id={id} marginBottom="8px" tag="h2">
        <Img
          alt="Shopify logo"
          height="44px"
          marginBottom={{ _: '12px', sm: '8px' }}
          src="/assets/logos/shopify-logo.svg"
          width="44px"
        />
        <Text
          color="primary500"
          display="block"
          fontSize={14}
          lineHeight={22}
          tag="span"
          textTransform="uppercase"
        >
          {title}
        </Text>
      </Text>
      <Text color="gray600" fontSize={14} lineHeight={18}>
        {subtitle}
      </Text>
    </StyledWrapper>
  );
};

StyledWrapper.defaultProps = {
  marginBottom: { _: '24px', sm: '48px' },
};
