import { FC } from 'react';

import DeleteAccountDialog from 'modules/Business/components/DeleteAccountDialog';
import useTranslations from 'modules/I18n/hooks/useTranslations';

import { cancelSubscriptionDialog } from './messages';

const CancelSubscriptionModal: FC = () => {
  const { t } = useTranslations();
  return (
    <DeleteAccountDialog
      confirmTextButton={t(cancelSubscriptionDialog.confirmText)}
      contentSubtitle={t(cancelSubscriptionDialog.subtitle)}
      contentText01={t(cancelSubscriptionDialog.contentText01)}
      contentText02={t(cancelSubscriptionDialog.contentText02)}
    />
  );
};

export default CancelSubscriptionModal;
