import { FC } from 'react';
import { useSelector } from 'react-redux';

import { AnalyticsEvent } from 'modules/Analytics';
import useTranslations from 'modules/I18n/hooks/useTranslations';
import {
  selectHasBasicPlan,
  selectIsMySubsciptionTrial,
} from 'modules/Subscriptions/detail/selectors';
import { ROUTES as SUBSCRIPTIONS_ROUTES } from 'modules/Subscriptions/routesMap';

import Text from '../../../Text';
import { detaislNewFeatureBanner } from '../messages';
import NewFeatureBanner from '../NewFeatureBanner';

const DetailsNewFeatureBanner: FC = () => {
  const hasBasicPlan = useSelector(selectHasBasicPlan);
  const isTrial = useSelector(selectIsMySubsciptionTrial);
  const showUpgradeForTracking = hasBasicPlan || isTrial;
  const { t } = useTranslations();
  return (
    <NewFeatureBanner
      marginBottom={{ sm: '16px' }}
      marginTop={{ _: '8px', sm: 'initial' }}
      to={
        showUpgradeForTracking ? SUBSCRIPTIONS_ROUTES.SUBSCRIPTIONS_PLANS : ''
      }
      isNew
      image="/assets/ilustration-send.svg"
      event={
        showUpgradeForTracking ? AnalyticsEvent.ACTIVATE_TRACKING : undefined
      }
    >
      <Text fontSize={14} lineHeight={20} tag="span">
        {t(detaislNewFeatureBanner.title)}
      </Text>
      {showUpgradeForTracking && (
        <Text
          className="link__subtitle"
          color="brand500"
          display="block"
          fontSize={14}
          lineHeight={20}
          tag="span"
        >
          {t(detaislNewFeatureBanner.subtitle)}
        </Text>
      )}
    </NewFeatureBanner>
  );
};

export default DetailsNewFeatureBanner;
