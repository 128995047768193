import { FC } from 'react';
import { useHistory } from 'react-router-dom';

import useTranslations from 'modules/I18n/hooks/useTranslations';
import { Dialog, MODAL_WIDTH_SMALL, useCloseModal } from 'modules/Modals';
import { ROUTES } from 'modules/Subscriptions/routesMap';
import { Box, Text, Button, Img } from 'modules/Ui';
import isNativeApp from 'utils/native/isNativeApp';

import { scanModals } from '../messages';

const UpgradeForMoreScansModal: FC = () => {
  const history = useHistory();
  const { t } = useTranslations();

  const closeModal = useCloseModal();

  const showNativeVersion = isNativeApp();

  return (
    <Dialog
      id="upgrade-for-more-scans"
      title={t(scanModals.upgradeTitle)}
      dialogWidth={{ sm: MODAL_WIDTH_SMALL }}
      type="fullScreen"
      buttonPrimary={
        <Button
          onClick={() => {
            closeModal();
            if (!showNativeVersion) {
              history.push(ROUTES.SUBSCRIPTIONS_PLANS);
            }
          }}
          variant="secondary"
        >
          {t(
            showNativeVersion
              ? scanModals.upgradeCtaSecondary
              : scanModals.upgradeCta
          )}
        </Button>
      }
      onClose={closeModal}
    >
      <Box
        columnGap={{ sm: '24px' }}
        display={{ sm: 'grid' }}
        gridTemplateColumns={{ sm: '1fr auto' }}
        marginBottom={{ sm: '32px' }}
      >
        <Box
          gridArea={{ sm: '1 / 2 / 2 / 3' }}
          marginBottom={{ _: '24px', sm: 'initial' }}
          textAlign={{ _: 'center', sm: 'initial' }}
        >
          <Img height={108} src="/assets/buy-scans.svg" />
        </Box>
        <Text lineHeight={22}>
          <Text
            color="gray800"
            display="block"
            fontWeight="600"
            lineHeight="inherit"
            marginBottom="16px"
            tag="span"
          >
            {t(scanModals.upgradeSubTitle)}
          </Text>
          {t(
            showNativeVersion
              ? scanModals.upgradeTextNative
              : scanModals.upgradeText
          )}
        </Text>
      </Box>
    </Dialog>
  );
};

export default UpgradeForMoreScansModal;
