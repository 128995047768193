import { FC } from 'react';

import { TemplatePreview } from 'modules/Settings/components';

import BaseViewer from './BaseViewer';
import { TemplateViewerProps } from './types';

const TemplateViewer: FC<TemplateViewerProps> = ({ values, ...rest }) => {
  return (
    <BaseViewer {...rest}>
      <TemplatePreview {...{ templateSettings: values }} />
    </BaseViewer>
  );
};

export default TemplateViewer;
