import { ReactNode, FC } from 'react';
import { Hidden, Visible } from 'react-grid-system';

import useTheme from 'modules/Theme/hooks/useTheme';
import { Box, Text, Tooltip } from 'modules/Ui';

interface SentStatusIconProps {
  text: string;
  icon: ReactNode;
  id?: string;
  showTooltip?: boolean;
}

const SentStatusIcon: FC<SentStatusIconProps> = ({
  id,
  icon,
  text,
  showTooltip = true,
}) => {
  const theme = useTheme();
  return (
    <>
      {showTooltip ? (
        <>
          <Hidden xs sm>
            <Tooltip
              id={id}
              noBullet
              place="bottom"
              tooltipBackgroundColor={theme.colors.primary400}
              tooltipButton={icon}
              tooltipContent={text}
              tooltipContentPadding="8px 12px"
              tooltipTextColor={theme.colors.gray0}
            />
          </Hidden>
          <Visible xs sm>
            {icon}
            <Text hide tag="span">
              {text}
            </Text>
          </Visible>
        </>
      ) : (
        <>
          <Box marginRight="4px" tag="span">
            {icon}
          </Box>
          <Text hide tag="span">
            {text}
          </Text>
        </>
      )}
    </>
  );
};

export default SentStatusIcon;
