import { BusinessUpdate } from 'modules/Business/models/business';
import { Contact } from 'modules/Contacts/models';

export enum CompleteValidVatNumbersContext {
  Document,
  Subscription,
}

export interface CompleteValidVatNumbersProps {
  onAccept?: (values: any) => void;
  onCancel?: () => void;
  client?: Contact;
  business: BusinessUpdate;
  mustShowBusinessForm?: boolean;
  mustShowClientForm?: boolean;
  setContact?: (contact: Contact) => void;
  context?: CompleteValidVatNumbersContext;
}
