import { FC, ReactNode } from 'react';

import { expandableAnimation } from 'modules/Theme/mixins';
import styled from 'modules/Theme/styled-components';

import Box from '../../Box';

interface FilterExpandableProps {
  children?: ReactNode;
  open: boolean;
  id: string;
  buttonId: string;
}

const Wrapper = styled(Box)`
  ${expandableAnimation}
`;

const FilterExpandable: FC<FilterExpandableProps> = ({
  buttonId,
  children,
  id,
  open,
}) => {
  return (
    <Wrapper
      aria-labelledby={buttonId}
      contentVisibility={open ? 'initial' : 'hidden'}
      data-testid={id}
      height={open ? 'auto' : '0'}
      id={id}
      opacity={open ? '1' : '0'}
      open={open}
      role="region"
    >
      {children}
    </Wrapper>
  );
};

export default FilterExpandable;
