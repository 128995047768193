import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  cancel: { id: 'gofre.Payments.DeletePaymentsModal.cancel' },
  confirmText: { id: 'gofre.Payments.DeletePaymentsModal.confirmText' },
  title: { id: 'gofre.Payments.DeletePaymentsModal.title' },
  subtitle: { id: 'gofre.Payments.DeletePaymentsModal.subtitle' },
  text: { id: 'gofre.Payments.DeletePaymentsModal.text' },
  success: { id: 'gofre.Payments.DeletePaymentsModal.success' },
  error: { id: 'gofre.Payments.DeletePaymentsModal.error' },
  limitExceeded: { id: 'gofre.Payments.DeletePaymentsModal.limitExceeded' },
});
