import { FC, useMemo } from 'react';

import { Formik } from 'formik';

import { DigitLimits } from 'modules/App/constants';
import { Contact } from 'modules/Contacts/models';
import useTranslations from 'modules/I18n/hooks/useTranslations';
import { useOpenModal } from 'modules/Modals';
import { ModalTypes } from 'modules/Modals/constants';
import { getPaymentOptions } from 'modules/Payments/helpers';
import {
  Box,
  DayPickerFormikField,
  FormikField,
  SearchContactInput,
  SelectFormikField,
} from 'modules/Ui';
import RadioButtonCardFormik from 'modules/Ui/Formik/RadioButtonCardFormik';
import { IconPlus, IconLess } from 'modules/Ui/Icons';

import { PaymentMethodEnum, PaymentTypeEnum } from '../../models';
import AccountingAccountInput from '../AccountingAccountInput/AccountingAccountInput';
import messages from './messages';
import { getValidationSchema } from './validation';

interface PaymentFormProps {
  initialValues: FormPayment;
  handleSubmit(values: FormPayment): Promise<void>;
  onCreateAccount(values: FormPayment): void;
}

export interface FormPayment {
  amount?: number;
  operationDate: Date;
  accountingAccountId?: string;
  contactId?: string;
  createContactSelect?: string;
  description?: string;
  type: PaymentTypeEnum;
  method?: PaymentMethodEnum;
}

export const PaymentForm: FC<PaymentFormProps> = ({
  initialValues,
  handleSubmit,
  onCreateAccount,
}) => {
  const { t } = useTranslations();
  const validationSchema = getValidationSchema({ t });
  const openModal = useOpenModal();
  const paymentOptions = useMemo(() => getPaymentOptions(t), [t]);
  return (
    <Formik
      enableReinitialize={false}
      onSubmit={async (values, actions) => {
        await handleSubmit(values);
        actions.setSubmitting(false);
      }}
      validationSchema={validationSchema}
      validateOnChange={false}
      validateOnBlur={false}
      initialValues={initialValues}
    >
      {(formik) => {
        return (
          <Box
            tag="form"
            onSubmit={formik.handleSubmit}
            id="update-payment-method"
            noValidate
            columnGap={{ sm: '16px' }}
            display="grid"
            gridTemplateColumns={{ sm: 'repeat(2, 1fr)' }}
          >
            <Box
              gridColumn={{ sm: '1 / 3' }}
              id="radio-group"
              role="radiogroup"
            >
              <Box
                columnGap={{ _: '16px', md: '24px' }}
                display="grid"
                gridTemplateColumns={{ _: '1fr 1fr', sm: 'repeat(3, 1fr)' }}
                marginBottom="24px"
              >
                <RadioButtonCardFormik
                  id="radio-income"
                  height={50}
                  name="type"
                  text={t(messages.typeIncome)}
                  value={PaymentTypeEnum.INCOME}
                >
                  <IconPlus />
                </RadioButtonCardFormik>
                <RadioButtonCardFormik
                  id="radio-expense"
                  height={50}
                  name="type"
                  text={t(messages.typeExpense)}
                  value={PaymentTypeEnum.EXPENSE}
                >
                  <IconLess />
                </RadioButtonCardFormik>
              </Box>
            </Box>
            <DayPickerFormikField
              id="operationDate"
              label={t(messages.operationDate)}
              name="operationDate"
              required
            />
            <FormikField
              decimalDigits={DigitLimits.DECIMAL_DIGITS}
              icon={
                formik.values.type === PaymentTypeEnum.INCOME ? (
                  <IconPlus />
                ) : (
                  <IconLess />
                )
              }
              iconPosition="start"
              id="amount"
              data-testid="amount"
              label={t(messages.amount)}
              name="amount"
              placeholder="0,00"
              required
              textAlign="right"
              type="number"
            />
            <SelectFormikField
              id="method"
              data-testid="method"
              isSearchable={false}
              label={t(messages.method)}
              name="method"
              placeholder={t(messages.methodPlaceholder)}
              options={paymentOptions}
            />

            <AccountingAccountInput
              onAddOption={() => {
                const values = formik.values ?? initialValues;
                onCreateAccount(values);
              }}
            />
            <SearchContactInput
              label={t(messages.contact)}
              placeholder={t(messages.select)}
              required={false}
              menuListText={t(messages.contactAdd)}
              setContact={(contact: Contact) => {
                formik.setFieldValue('contactId', contact.id);
                formik.setFieldValue('createContactSelect', contact.id);
              }}
              allowIncompleteContacts={true}
              setSelectedOption={() => {}}
              fromPayments={true}
              handleAfterSubmitOrCancelFromPayments={(
                createdContact?: Contact
              ) => {
                const { values } = formik;
                openModal({
                  ...values,
                  paymentType: values.type,
                  type: ModalTypes.CREATE_PAYMENT,
                  contactId: createdContact?.id,
                  onSuccess: () => {},
                });
              }}
            />
            <FormikField
              gridColumn={{ sm: '1 / 3' }}
              id="description"
              label={t(messages.description)}
              maxLength={250}
              name="description"
              placeholder={t(messages.descriptionPlaceholder)}
            />
          </Box>
        );
      }}
    </Formik>
  );
};
