import { defineMessages } from 'react-intl';

export default defineMessages({
  text: { id: 'gofre.RemittanceDocuments.Delete.Modal.text' },
  textAll: { id: 'gofre.RemittanceDocuments.Delete.Modal.text_all' },
  title: { id: 'gofre.RemittanceDocuments.Delete.Modal.title' },
  success: { id: 'gofre.RemittanceDocuments.Delete.Modal.success' },
  error: { id: 'gofre.RemittanceDocuments.Delete.Modal.error' },
  subtitle: {
    id: 'gofre.RemittanceDocuments.Delete.Modal.subtitle',
  },
  confirm: { id: 'gofre.RemittanceDocuments.Delete.Modal.confirm' },
});
