import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: { id: 'gofre.Documents.SortOutExpensesModal.title' },
  titleRecurrent: { id: 'gofre.Documents.SortOutExpensesModal.titleRecurrent' },
  subtitle: { id: 'gofre.Documents.SortOutExpensesModal.subtitle' },
  subtitleRecurrent: {
    id: 'gofre.Documents.SortOutExpensesModal.subtitleRecurrent',
  },
  confirmText: { id: 'gofre.Documents.SortOutExpensesModal.confirmText' },
  cancel: { id: 'gofre.Modals.cancel' },
  success: { id: 'gofre.Documents.SortOutExpensesModal.notifications.success' },
  error: { id: 'gofre.Documents.SortOutExpensesModal.notifications.error' },
});

export const expenseLedgerTexts = defineMessages({
  ware: { id: 'gofre.Documents.SortOutExpensesModal.expenseLedger.ware' },
  supplies: {
    id: 'gofre.Documents.SortOutExpensesModal.expenseLedger.supplies',
  },
  otherCompanies: {
    id: 'gofre.Documents.SortOutExpensesModal.expenseLedger.otherCompanies',
  },
  returns: { id: 'gofre.Documents.SortOutExpensesModal.expenseLedger.returns' },
  renting: { id: 'gofre.Documents.SortOutExpensesModal.expenseLedger.renting' },
  retentionServices: {
    id: 'gofre.Documents.SortOutExpensesModal.expenseLedger.retentionServices',
  },
  logistics: {
    id: 'gofre.Documents.SortOutExpensesModal.expenseLedger.logistics',
  },
  insurance: {
    id: 'gofre.Documents.SortOutExpensesModal.expenseLedger.insurance',
  },
  bankingExpenses: {
    id: 'gofre.Documents.SortOutExpensesModal.expenseLedger.bankingExpenses',
  },
  advertising: {
    id: 'gofre.Documents.SortOutExpensesModal.expenseLedger.advertising',
  },
  generalSupplies: {
    id: 'gofre.Documents.SortOutExpensesModal.expenseLedger.generalSupplies',
  },
  otherServices: {
    id: 'gofre.Documents.SortOutExpensesModal.expenseLedger.otherServices',
  },
  salary: { id: 'gofre.Documents.SortOutExpensesModal.expenseLedger.salary' },
  socialTaxes: {
    id: 'gofre.Documents.SortOutExpensesModal.expenseLedger.socialSecurityTaxes',
  },
  socialExpenses: {
    id: 'gofre.Documents.SortOutExpensesModal.expenseLedger.socialExpenses',
  },
  bankDebtInterest: {
    id: 'gofre.Documents.SortOutExpensesModal.expenseLedger.bankDebtInterest',
  },
  tangibleFixedEquipment: {
    id: 'gofre.Documents.SortOutExpensesModal.expenseLedger.tangibleFixedEquipment',
  },
  wareSimple: {
    id: 'gofre.Documents.SortOutExpensesModal.expenseLedger.simple.ware',
  },
  suppliesSimple: {
    id: 'gofre.Documents.SortOutExpensesModal.expenseLedger.simple.supplies',
  },
  otherCompaniesSimple: {
    id: 'gofre.Documents.SortOutExpensesModal.expenseLedger.simple.otherCompanies',
  },
  returnsSimple: {
    id: 'gofre.Documents.SortOutExpensesModal.expenseLedger.simple.returns',
  },
  rentingSimple: {
    id: 'gofre.Documents.SortOutExpensesModal.expenseLedger.simple.renting',
  },
  retentionServicesSimple: {
    id: 'gofre.Documents.SortOutExpensesModal.expenseLedger.simple.retentionServices',
  },
  logisticsSimple: {
    id: 'gofre.Documents.SortOutExpensesModal.expenseLedger.simple.logistics',
  },
  insuranceSimple: {
    id: 'gofre.Documents.SortOutExpensesModal.expenseLedger.simple.insurance',
  },
  bankingExpensesSimple: {
    id: 'gofre.Documents.SortOutExpensesModal.expenseLedger.simple.bankingExpenses',
  },
  advertisingSimple: {
    id: 'gofre.Documents.SortOutExpensesModal.expenseLedger.simple.advertising',
  },
  generalSuppliesSimple: {
    id: 'gofre.Documents.SortOutExpensesModal.expenseLedger.simple.generalSupplies',
  },
  otherServicesSimple: {
    id: 'gofre.Documents.SortOutExpensesModal.expenseLedger.simple.otherServices',
  },
  otherExpensesSimple: {
    id: 'gofre.Documents.SortOutExpensesModal.expenseLedger.simple.otherExpenses',
  },
  salarySimple: {
    id: 'gofre.Documents.SortOutExpensesModal.expenseLedger.simple.salary',
  },
  socialTaxesSimple: {
    id: 'gofre.Documents.SortOutExpensesModal.expenseLedger.simple.socialSecurityTaxes',
  },
  socialExpensesSimple: {
    id: 'gofre.Documents.SortOutExpensesModal.expenseLedger.simple.socialExpenses',
  },
  bankDebtInterestSimple: {
    id: 'gofre.Documents.SortOutExpensesModal.expenseLedger.simple.bankDebtInterest',
  },
  tangibleFixedEquipmentSimple: {
    id: 'gofre.Documents.SortOutExpensesModal.expenseLedger.simple.tangibleFixedEquipment',
  },
  noCategory: {
    id: 'gofre.Documents.SortOutExpensesModal.expenseLedger.noCategory',
  },
  noClasification: {
    id: 'gofre.Documents.SortOutExpensesModal.expenseLedger.noClasification',
  },
  owed: {
    id: 'gofre.Documents.SortOutExpensesModal.expenseLedger.owed',
  },
});

export default messages;
