import { FC } from 'react';

import { Advantages } from './Advantages';
import { Included } from './Included';

interface Props {
  plural: boolean;
}

export const PlansInfo: FC<Props> = ({ plural }) => {
  return (
    <>
      <Included {...{ plural }} />
      <Advantages />
    </>
  );
};
