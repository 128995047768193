interface Params {
  isReceipt?: boolean;
  retentionPercentage?: number;
}

const hasRetentions = ({ isReceipt, retentionPercentage }: Params): boolean => {
  if (isReceipt) {
    return false;
  }
  return !!retentionPercentage;
};

export default hasRetentions;
