const getLink = (index: number) => {
  if (index === 8) {
    return 'https://billin.zendesk.com/hc/es/articles/4409681539991-Facturas-de-prueba-TICKETBAI';
  }
  if (index === 9) {
    return 'https://billin.zendesk.com/hc/es/articles/360063420693-C%C3%B3mo-crear-nuevos-n%C3%BAmeros-de-serie';
  }
  if (index === 15) {
    return 'https://billin.zendesk.com/hc/es/articles/5241799663895-Facturas-rectificativas-TicketBAI';
  }
  if (index === 17) {
    return 'https://billin.zendesk.com/hc/es/articles/5008838989975-C%C3%B3mo-crear-facturas-electr%C3%B3nicas';
  }
  return '';
};

export default getLink;
