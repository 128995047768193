import {
  space,
  SpaceProps,
  layout,
  LayoutProps,
  flexbox,
  FlexboxProps,
  position,
  PositionProps,
} from 'styled-system';

import styled from 'modules/Theme/styled-components';
import { PillBeta, Box, Img } from 'modules/Ui';

export interface ApiBannerProps
  extends SpaceProps,
    LayoutProps,
    FlexboxProps,
    PositionProps {
  height?: number;
  ilustraWidth?: number;
  isBeta?: boolean;
  width?: number;
}

const StyledWrapper = styled(Box)<ApiBannerProps>`
  ${flexbox}
  ${layout}
  ${position}
  ${space}
`;

const ApiPublicIlustration = (props: ApiBannerProps) => {
  const { height, ilustraWidth, isBeta, width, ...rest } = props;
  return (
    <StyledWrapper
      height={height}
      position={isBeta ? 'relative' : undefined}
      width={width}
      {...rest}
    >
      {isBeta && (
        <Box
          alignItems="center"
          display="flex"
          justifyContent="center"
          position="absolute"
          tag="span"
        >
          <PillBeta />
        </Box>
      )}
      <Img
        alt=""
        aria-hidden="true"
        height={height}
        loading="eager"
        src="/assets/apiPublic/api-public-ilustration.svg"
        width={ilustraWidth}
      />
    </StyledWrapper>
  );
};

export default ApiPublicIlustration;
