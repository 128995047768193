import { defineMessages } from 'react-intl';

export default defineMessages({
  'en-EN': {
    id: 'gofre.Settings.Theme.templates.en-EN',
  },
  'es-ES': {
    id: 'gofre.Settings.Theme.templates.es-ES',
  },
  'eu-EU': {
    id: 'gofre.Settings.Theme.templates.eu-EU',
  },
  'ca-CA': {
    id: 'gofre.Settings.Theme.templates.ca-CA',
  },
  'euses-EUSES': {
    id: 'gofre.Settings.Theme.templates.euses-EUSES',
  },
  templateSelectLanguage: {
    id: 'gofre.Settings.Theme.templates.selectLanguage',
  },
  templateSelectLanguageDesc: {
    id: 'gofre.Settings.Theme.templates.selectLanguageDesc',
  },
});
