import { ExpenseCategories } from 'modules/Documents/models/document';
import useTranslations from 'modules/I18n/hooks/useTranslations';

import { expenseLedgerTexts } from '../messages';

export interface Option {
  label: string;
  id: ExpenseCategories;
}

export default function useExpenseLedger({
  simple = true,
}: {
  simple: boolean;
}) {
  const { t } = useTranslations();
  const options: Option[] = [
    {
      label: simple
        ? t(expenseLedgerTexts.wareSimple)
        : t(expenseLedgerTexts.ware),
      id: ExpenseCategories.Ware,
    },
    {
      label: simple
        ? t(expenseLedgerTexts.suppliesSimple)
        : t(expenseLedgerTexts.supplies),
      id: ExpenseCategories.Supplies,
    },
    {
      label: simple
        ? t(expenseLedgerTexts.otherCompaniesSimple)
        : t(expenseLedgerTexts.otherCompanies),
      id: ExpenseCategories.OtherCompanies,
    },
    {
      label: simple
        ? t(expenseLedgerTexts.returnsSimple)
        : t(expenseLedgerTexts.returns),
      id: ExpenseCategories.Returns,
    },
    {
      label: simple
        ? t(expenseLedgerTexts.rentingSimple)
        : t(expenseLedgerTexts.renting),
      id: ExpenseCategories.Renting,
    },
    {
      label: simple
        ? t(expenseLedgerTexts.retentionServicesSimple)
        : t(expenseLedgerTexts.retentionServices),
      id: ExpenseCategories.RetentionServices,
    },
    {
      label: simple
        ? t(expenseLedgerTexts.logisticsSimple)
        : t(expenseLedgerTexts.logistics),
      id: ExpenseCategories.Logistics,
    },
    {
      label: simple
        ? t(expenseLedgerTexts.insuranceSimple)
        : t(expenseLedgerTexts.insurance),
      id: ExpenseCategories.Insurance,
    },
    {
      label: simple
        ? t(expenseLedgerTexts.bankingExpensesSimple)
        : t(expenseLedgerTexts.bankingExpenses),
      id: ExpenseCategories.BankingExpenses,
    },
    {
      label: simple
        ? t(expenseLedgerTexts.advertisingSimple)
        : t(expenseLedgerTexts.advertising),
      id: ExpenseCategories.Advertising,
    },
    {
      label: simple
        ? t(expenseLedgerTexts.generalSuppliesSimple)
        : t(expenseLedgerTexts.generalSupplies),
      id: ExpenseCategories.GeneralSupplies,
    },
    {
      label: simple
        ? t(expenseLedgerTexts.otherServicesSimple)
        : t(expenseLedgerTexts.otherServices),
      id: ExpenseCategories.OtherServices,
    },
    {
      label: simple
        ? t(expenseLedgerTexts.salarySimple)
        : t(expenseLedgerTexts.salary),
      id: ExpenseCategories.Salary,
    },
    {
      label: simple
        ? t(expenseLedgerTexts.socialTaxesSimple)
        : t(expenseLedgerTexts.socialTaxes),
      id: ExpenseCategories.SocialTaxes,
    },
    {
      label: simple
        ? t(expenseLedgerTexts.socialExpensesSimple)
        : t(expenseLedgerTexts.socialExpenses),
      id: ExpenseCategories.SocialExpenses,
    },
    {
      label: simple
        ? t(expenseLedgerTexts.bankDebtInterestSimple)
        : t(expenseLedgerTexts.bankDebtInterest),
      id: ExpenseCategories.BankDebtInterest,
    },
    {
      label: simple
        ? t(expenseLedgerTexts.tangibleFixedEquipmentSimple)
        : t(expenseLedgerTexts.tangibleFixedEquipment),
      id: ExpenseCategories.TangibleFixedEquipment,
    },
  ];
  return options;
}
