import { parseNumber } from 'utils/MathOperations';

import { generateTaxesObjectKey } from '../gofre-calcs';
import { calculateTotalFromUniqueConcept } from './calculate-total-from-unique-concept';
import {
  TotalsFromConcepts,
  TortillaConcept as Concept,
  TortillaTaxLine as TaxLine,
} from './types';

/*
  IF YOU CHANGE ANYTHING BELOW THIS COMMENT YOU NEED TO DO THE SAME CHANGE IN GOFRE / TORTILLA
  WE ROUND TO 3 DECIMAL PLACES SO WE DONT LOSE PRECISION ON THE SECOND DECIMAL PLACE
  THE OBJECTIVE OF IS THAT THE TOTALS MUST ADD PERFECTLY, SUBTOTAL + TAX = TOTAL
  WE ARE FINE LOOSING SOME TRAILING CENTS IN THE SUM OF THE CONCEPTS
 */

export function calculateTotalsFromConceptsV1(
  concepts: Concept[]
): TotalsFromConcepts {
  let subtotal = 0;
  let discount = 0;
  let taxBase = 0;
  let taxAmount = 0;
  let salesEqTax = 0;
  const taxes: { [key: string]: TaxLine } = {};

  concepts.forEach((currentConcept) => {
    const {
      currentSubtotal,
      currentDiscount,
      currentSalesEqTax,
      currentTaxAmount,
      currentTaxBase,
      currentTax,
    } = calculateTotalFromUniqueConcept(currentConcept);

    subtotal += currentSubtotal;
    discount += currentDiscount;
    taxBase += currentTaxBase;
    taxAmount += currentTaxAmount;
    salesEqTax += currentSalesEqTax ?? 0;

    const taxesObjectKey = generateTaxesObjectKey({
      ...currentConcept,
      totalAmount: currentConcept.totalAmount ?? undefined,
    });

    if (!taxes[taxesObjectKey]) {
      taxes[taxesObjectKey] = currentTax;
    } else {
      taxes[taxesObjectKey].taxBase += currentTaxBase;
      taxes[taxesObjectKey].taxAmount += currentTaxAmount as number;
      taxes[taxesObjectKey].salesEqTax += currentSalesEqTax;
    }
  });

  return {
    subtotal: parseNumber(subtotal),
    discount: parseNumber(discount),
    taxBase: parseNumber(taxBase),
    taxAmount: parseNumber(taxAmount),
    salesEqTax: parseNumber(salesEqTax),
    taxes,
  };
}
