import { useCallback, useEffect } from 'react';

function isNode(obj: unknown): obj is Node {
  if (!obj) return false;
  if ((obj as Node).nodeName) return true;
  return false;
}

function useCallbackOnClickOutside(ref: React.RefObject<null>, cb: () => void) {
  const handleClickOutside = useCallback(
    (event: Event) => {
      if (!isNode(ref.current) || !isNode(event.target)) return;
      const current = ref.current! as Node;
      const target = event.target as Node;

      if (!current.contains(target)) {
        cb();
      }
    },
    [cb]
  );

  useEffect(() => {
    ['mousedown', 'touchstart'].map((e) =>
      document.addEventListener(e, handleClickOutside)
    );
    return () => {
      document.removeEventListener('mousedown touchstart', handleClickOutside);
    };
  }, [handleClickOutside]);
}

export default useCallbackOnClickOutside;
