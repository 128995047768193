import useTheme from 'modules/Theme/hooks/useTheme';

import IconByString, { IconByStringProps } from './Icon/IconType/IconByString';

export interface IconAddProps extends IconByStringProps {
  iconCrossColor?: string;
  innerPathColor?: string;
}

const IconError = (props: IconAddProps) => {
  const theme = useTheme();
  const { iconCrossColor, innerPathColor, ...rest } = props;
  return (
    <IconByString viewBox="0 0 13 13" {...rest}>
      <circle
        cx="6.5"
        cy="6.5"
        r="6.5"
        fill={innerPathColor ?? theme.colors.error}
      />
      <path
        clipRule="evenodd"
        d="m9.328 4.379-.707-.707L6.5 5.792l-2.121-2.12-.707.707L5.792 6.5l-2.12 2.121.707.707L6.5 7.208l2.121 2.12.707-.707L7.208 6.5l2.12-2.121Z"
        fillRule="evenodd"
        fill={iconCrossColor ?? theme.colors.gray0}
      />
    </IconByString>
  );
};

export default IconError;
