import { useQueryClient } from 'react-query';

export interface Params {
  data?: any;
  setQueryData?: string[];
  invalidateQueries?: string[];
  isAsync?: boolean;
}

export const useInvalidateAndUpdate = () => {
  const queryCache = useQueryClient();

  function invalidateAndUpdate(params: Params) {
    const { invalidateQueries, data, setQueryData } = params;
    data &&
      setQueryData?.forEach((param) =>
        queryCache.setQueryData([param, { id: data?.id }], data)
      );

    invalidateQueries?.forEach((param) =>
      queryCache.invalidateQueries(param, {
        refetchInactive: true,
      })
    );

    if (params.isAsync) {
      setTimeout(() => {
        invalidateQueries?.forEach((param) =>
          queryCache.invalidateQueries(param, {
            refetchInactive: true,
          })
        );
      }, 1500);
    }
  }
  return invalidateAndUpdate;
};
