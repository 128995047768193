import { useMemo } from 'react';

import { Formik } from 'formik';

import useTranslations from 'modules/I18n/hooks/useTranslations';
import { FormikField } from 'modules/Ui';
import ErrorListener from 'modules/Ui/Formik/ErrorListener';

import getValidationSchema from './change-email.validation';
import { changeEmailModal as messages } from './messages';

interface FormProps {
  billingEmail: string;
  onSubmit: (billingEmail: string) => void;
}

const ChangeBillinEmailForm = (props: FormProps) => {
  const { billingEmail, onSubmit } = props;
  const { t } = useTranslations();

  const validationSchema = useMemo(() => getValidationSchema(t), [t]);
  const initialValues = { billingEmail };

  return (
    <Formik
      enableReinitialize
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={(values, actions) => {
        onSubmit(values.billingEmail);
        actions.setSubmitting(false);
      }}
      {...{ validationSchema, initialValues }}
    >
      {(formik) => (
        <form onSubmit={formik.handleSubmit} noValidate id="changeBillingEmail">
          <ErrorListener />
          <FormikField
            label={t(messages.billingEmailLabel)}
            placeholder={t(messages.billingEmailPlaceholder)}
            id="billingEmail"
            name="billingEmail"
            required
          />
        </form>
      )}
    </Formik>
  );
};

export default ChangeBillinEmailForm;
