import IconByPath, { IconByPathProps } from './Icon/IconType/IconByPath';

const IconMegaphone = (props: IconByPathProps) => (
  <IconByPath
    {...props}
    fillRule="evenodd"
    clipRule="evenodd"
    path="M21.77 2.08a.5.5 0 0 1 .23.42v17a.5.5 0 0 1-.707.455L10.391 15H9.286L8 21H4v-6a1 1 0 0 1-1-1v-1H1V9h2V8a1 1 0 0 1 1-1h6.392l10.901-4.955a.5.5 0 0 1 .478.035ZM4 13V8h6v6H4v-1Zm-1-3H2v2h1v-2Zm8-2.178v6.356l10 4.546V3.277L11 7.822ZM5 15h3.263l-1.071 5H5v-5Z"
  />
);

export default IconMegaphone;
