import ActionTypes from './constants';
import { Actions, State } from './types';

export const initialState: State = {
  isBodyScrollable: true,
};

const reducer = (state: State = initialState, action: Actions): State => {
  switch (action.type) {
    case ActionTypes.SET_IS_BODY_SCROLLABLE:
      return {
        isBodyScrollable: action.payload,
      };

    default:
      return state;
  }
};

export default reducer;
