import { FC, useCallback } from 'react';
import { Visible } from 'react-grid-system';

import { useInvalidateAndUpdate } from 'modules/App/hooks/useInvalidateAndUpdate';
import useTranslations from 'modules/I18n/hooks/useTranslations';
import { ModalTypes } from 'modules/Modals/constants';
import useOpenModal from 'modules/Modals/hooks/useOpenModal';
import useNotifications from 'modules/Notifications/hooks/useNotifications';
import useTheme from 'modules/Theme/hooks/useTheme';
import { Button, Box } from 'modules/Ui';
import { IconCross } from 'modules/Ui/Icons';
import { useBlockingMutation } from 'utils/useBlockingMutation';

import { isResponseErrorSerieInUse } from '../helpers';
import { series as messages } from '../messages';
import { DocumentSerie, TargetDocument } from '../models';
import { deleteDocumentSerie } from '../services';
import { invalidateQueries } from '../services/createDocumentSerie';

interface SerieProps {
  serie: DocumentSerie;
  canDeleteSerie: (targetDocument: TargetDocument) => boolean;
  last: boolean;
}
const Serie: FC<SerieProps> = (props) => {
  const theme = useTheme();
  const {
    serie: { lastNumber, serialCode, targetDocument, id },
    canDeleteSerie,
    last,
  } = props;
  const { t } = useTranslations();
  const invalidateAndUpdate = useInvalidateAndUpdate();
  const openModal = useOpenModal();
  const notifications = useNotifications();
  const { mutate } = useBlockingMutation(deleteDocumentSerie, {
    onSuccess: async () => {
      invalidateAndUpdate({ invalidateQueries });
      openModal({
        type: ModalTypes.DELETE_DOCUMENT_SERIE,
        id,
        serialCode,
        callback: () => invalidateAndUpdate({ invalidateQueries }),
        targetDocument,
      });
    },
    onError: async (e) => {
      if (isResponseErrorSerieInUse(e)) {
        openModal({
          type: ModalTypes.DELETE_DOCUMENT_SERIE_ERROR,
          code: serialCode,
          targetDocument,
        });
      } else {
        notifications.error(messages.deleteError.id);
      }
    },
  });

  const onDelete = useCallback(() => {
    mutate({ id, dry: true });
  }, [openModal, id, serialCode, invalidateAndUpdate, targetDocument]);
  const number = (lastNumber || 0) + 1;
  return (
    <Box
      borderBottom={!last ? `1px solid ${theme.colors.gray200}` : undefined}
      borderTop={`1px solid ${theme.colors.gray200}`}
      color="gray600"
      display="table-row"
      padding="0"
      height="55px"
      tag="tr"
    >
      <Box color="gray800" tag="td" paddingRight="8px">
        {serialCode}
      </Box>
      <Box color="gray800" tag="td" minWidth="55px">
        {number || 1}
      </Box>
      <Visible md lg xl xxl>
        <Box color="gray800" tag="td">
          {t(messages[`targetDocument-${targetDocument}`])}
        </Box>
      </Visible>
      <Box tag="td" width={{ _: '55px', sm: 'initial' }}>
        {canDeleteSerie(targetDocument) && (
          <>
            <Visible md lg xl xxl>
              <Button variant="link" onClick={onDelete}>
                {t(messages.delete)}
              </Button>
            </Visible>
            <Visible xs sm>
              <Box onClick={onDelete}>
                <IconCross
                  color="brand500"
                  position="relative"
                  size={32}
                  top="6px"
                />
              </Box>
            </Visible>
          </>
        )}
      </Box>
    </Box>
  );
};

export default Serie;
