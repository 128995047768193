import IconByPath, { IconByPathProps } from './Icon/IconType/IconByPath';

const IconCheck = (props: IconByPathProps) => (
  <IconByPath
    {...props}
    path="M21 8.027 19.028 6l-8.705 8.946-4.351-4.473L4 12.5l6.324 6.5L21 8.027Z"
  />
);

export default IconCheck;
