import IconByPath, { IconByPathProps } from './Icon/IconType/IconByPath';

const IconZip = (props: IconByPathProps) => (
  <IconByPath
    {...props}
    fillRule="evenodd"
    path="M20 2h-8v2h-2V2H4v20h16V2Zm-8 4V4h2v2h-2Zm0 2h-2V6h2v2Zm0 2V8h2v2h-2Zm0 0v2h-2v-2h2ZM4 1H3v22h18V1H4Zm12.46 13.6a1.334 1.334 0 0 0-.745-.208h-1.504a.1.1 0 0 0-.072.032.086.086 0 0 0-.024.064v5.416c0 .027.008.05.024.072a.117.117 0 0 0 .072.024h.736a.087.087 0 0 0 .064-.024.1.1 0 0 0 .032-.072V17.68c0-.027.014-.04.04-.04h.68c.416 0 .75-.147 1-.44.256-.293.384-.68.384-1.16 0-.32-.061-.605-.184-.856a1.351 1.351 0 0 0-.504-.584Zm-.425 2.088a.57.57 0 0 1-.472.224h-.48c-.026 0-.04-.013-.04-.04v-1.64c0-.027.014-.04.04-.04h.48c.198 0 .355.08.472.24.123.155.184.365.184.632 0 .261-.061.47-.184.624ZM6.948 20a.117.117 0 0 1-.072-.024.117.117 0 0 1-.024-.072v-.64a.3.3 0 0 1 .024-.12l1.792-3.896a.034.034 0 0 0 0-.032c-.005-.01-.013-.016-.024-.016H6.948a.117.117 0 0 1-.072-.024.117.117 0 0 1-.024-.072v-.608c0-.027.008-.048.024-.064a.1.1 0 0 1 .072-.032h2.624c.027 0 .048.01.064.032a.076.076 0 0 1 .032.064v.64a.343.343 0 0 1-.024.12l-1.792 3.896a.034.034 0 0 0 0 .032c.006.01.014.016.024.016h1.696c.027 0 .048.01.064.032a.076.076 0 0 1 .032.064v.608a.1.1 0 0 1-.032.072.087.087 0 0 1-.064.024H6.948Zm4.482 0a.117.117 0 0 1-.072-.024.117.117 0 0 1-.024-.072v-5.408c0-.027.008-.048.024-.064a.1.1 0 0 1 .072-.032h.736c.027 0 .048.01.064.032a.076.076 0 0 1 .032.064v5.408a.1.1 0 0 1-.032.072.086.086 0 0 1-.064.024h-.736Z"
  />
);

export default IconZip;
