import { FC, ReactNode } from 'react';

import { useFeatureFlag } from 'modules/FeatureFlags';
import useTheme from 'modules/Theme/hooks/useTheme';
import { Box, Link, PillBeta } from 'modules/Ui';

export interface SunMenuLinkLinkProps {
  ['data-testid']?: string;
  active?: boolean;
  isBeta?: boolean;
  isBookkeeper?: boolean;
  notification?: string | ReactNode;
  onClick?: () => void;
  requiredFeatureFlag?: string;
  text?: string;
  to: string;
}

const SunMenuLink: FC<SunMenuLinkLinkProps> = (props) => {
  const {
    active,
    isBeta,
    notification,
    onClick,
    requiredFeatureFlag,
    text,
    to,
    ...rest
  } = props;
  const theme = useTheme();
  const ffEnabled = !!useFeatureFlag(requiredFeatureFlag);
  const notOnlyText = notification || isBeta;
  if (!ffEnabled) return null;
  return (
    <Box
      backgroundColor={active ? `${theme.colors.auxiliary100A30}` : ''}
      data-testid={props['data-testid']}
      role="menuitem"
      tag="li"
    >
      <Link
        aria-selected="true"
        padding="10px 16px 10px 36px"
        color={theme.colors.gray700}
        columnGap={notOnlyText ? '4px' : ''}
        display={notOnlyText ? 'grid' : 'block'}
        fontSize={theme.fontSizes[15]}
        fontWeight={active ? '600' : ''}
        gridTemplateColumns={notOnlyText ? 'auto auto 1fr' : ''}
        lineHeight={theme.lineHeights[16]}
        onClick={onClick}
        to={to}
        {...rest}
      >
        {text}
        {notification}
        {isBeta && <PillBeta />}
      </Link>
    </Box>
  );
};

export default SunMenuLink;
