import { FC } from 'react';
import { useSelector } from 'react-redux';

import { useField } from 'formik';

import { hasElectronicDocumentProvider } from 'modules/Business/businessUtils';
import { selectCurrentBusiness } from 'modules/Business/Current/selectors';
import useTranslations from 'modules/I18n/hooks/useTranslations';
import { TemplateLanguages } from 'modules/I18n/types/templateLanguages';
import { TemplateType } from 'modules/Settings/Theme/types';
import { SelectFormikField } from 'modules/Ui';

import { baiLanguages, noBaiLanguages } from './languagesOrder';
import messages from './messages';

interface Props {
  id: string;
  onChange?: (newValue: TemplateLanguages) => void;
}

const SelectLanguage: FC<Props> = ({ id, onChange }) => {
  const { t } = useTranslations();
  const [field] = useField('templateType');
  const business = useSelector(selectCurrentBusiness);
  const isBai = business && hasElectronicDocumentProvider(business);
  const languages = isBai ? baiLanguages(t) : noBaiLanguages(t);
  return (
    <SelectFormikField
      id={id}
      options={languages.filter(
        (lang) =>
          field.value !== TemplateType.CLASSIC ||
          lang.value !== TemplateLanguages.EUES
      )}
      label={t(messages.templateSelectLanguage)}
      onChange={onChange}
      marginTop="16px"
    />
  );
};

export default SelectLanguage;
