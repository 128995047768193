import HtmlComponent from './HtmlComponent';
import Props from './Props';

const validProps = [
  'baseProfile',
  'className',
  'contentScriptType',
  'contentStyleType',
  'height',
  'preserveAspectRatio',
  'strokeWidth',
  'stroke',
  'style',
  'version',
  'viewBox',
  'width',
  'x',
  'xmlns',
  'y',
];

const Svg = (props: Props) => (
  <HtmlComponent tag="svg" {...{ validProps }} {...props} />
);

export default Svg;
