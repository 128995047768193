import { FC } from 'react';

import useTranslations from 'modules/I18n/hooks/useTranslations';
import useTheme from 'modules/Theme/hooks/useTheme';
import styled from 'modules/Theme/styled-components';
import { Box, Button } from 'modules/Ui';
import { IconArrowSecondary } from 'modules/Ui/Icons';
import { actions } from 'modules/Ui/Menu/messages';

import { menuSizes } from '../../menuSizes';
import menuAnimation from '../../styles/menuAnimation';

interface MenuProps {
  open?: boolean;
  onClick: () => void;
}

const StyledHeader = styled(Box)<MenuProps>`
  ${menuAnimation}
`;

const SubMenuHeader: FC<MenuProps> = ({ open, onClick }) => {
  const { t } = useTranslations();
  const theme = useTheme();
  return (
    <StyledHeader
      {...{ open }}
      aria-hidden={open ? 'false' : 'true'}
      fadeIn
      aria-haspopup="true"
      id="submenuBack"
      alignItems="center"
      backgroundColor="gray0"
      display="flex"
      height={`${menuSizes.HEADER_HEIGHT_MOBILE}px`}
      justifyContent="flex-start"
      position="fixed"
      top="0"
      left="0"
      tag="header"
      width={`${menuSizes.MENU_WIDTH}px`}
    >
      <Button
        {...{ onClick }}
        accessibleText={t(actions.submenuClose)}
        aria-controls="menu"
        aria-haspopup="true"
        color={theme.colors.primary500}
        marginLeft="16px"
        padding="8px"
      >
        <IconArrowSecondary transform="rotate(-180deg)" size={24} />
      </Button>
    </StyledHeader>
  );
};

export default SubMenuHeader;
