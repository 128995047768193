import { Text } from 'modules/Ui';

export interface BusinessNameProps {
  active?: boolean;
  children: React.ReactFragment;
}

const BusinessName = (props: BusinessNameProps) => {
  const { children, active } = props;
  return (
    <Text
      {...{ active }}
      className="businessName"
      color="primary500"
      display="block"
      fontSize={16}
      fontWeight="600"
      lineHeight={22}
      marginBottom="2px"
      tag="span"
      wordBreak="break-word"
    >
      {children}
    </Text>
  );
};

export default BusinessName;
