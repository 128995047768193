import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  fetchingError: { id: 'gofre.UserInvitation.fetchingError' },
  noActiveSubscriptionTitle: {
    id: 'gofre.UserInvitation.noActiveSubscriptionTitle',
  },
  noActiveSubscription: { id: 'gofre.UserInvitation.noActiveSubscription' },
  noActiveSubscriptionDummyTitle: {
    id: 'gofre.UserInvitation.noActiveSubscriptionDummyTitle',
  },
  noActiveSubscriptionDummy: {
    id: 'gofre.UserInvitation.noActiveSubscriptionDummy',
  },
});
