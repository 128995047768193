import { useSelector } from 'react-redux';

import { Formik } from 'formik';

import { selectIsMobile } from 'modules/App/store/selectors';
import useTranslations from 'modules/I18n/hooks/useTranslations';
import { useFetchPlans } from 'modules/Subscriptions/hooks';
import { Plan, PAYMENT_PERIODS } from 'modules/Subscriptions/models';
import { getPeriodicity } from 'modules/Subscriptions/subscription.helpers';
import useTheme from 'modules/Theme/hooks/useTheme';
import styled from 'modules/Theme/styled-components';
import { Fieldset, Box, MarkDown, Hr } from 'modules/Ui';
import ErrorListener from 'modules/Ui/Formik/ErrorListener';
import { roundNumber } from 'utils/MathOperations';

import messages from '../messages';
import { StepProps } from '../types';
import PaymentFormCoupons from './PaymentFormCoupons';
import PlanPrice from './PlanPrice';

function calculateNewPlan(
  periodicity: string,
  marketName: string,
  plans?: Plan[]
) {
  if (!plans) return null;

  return plans.find(
    ({ duration: planDuration, marketName: planMarketName }) =>
      periodicity === getPeriodicity(planDuration) &&
      marketName === planMarketName
  );
}

const FormOfferText = styled(MarkDown)`
  strong {
    color: ${(props) => props.theme.colors.brand500};
  }
`;

const PaymentOptionsForm: React.FC<StepProps> = ({
  setPaymentState,
  setCoupon,
  coupon,
  onSubmit,
  data,
  setSelectedPlan,
  selectedPlan,
}) => {
  const { t } = useTranslations();
  const theme = useTheme();
  const { data: plans } = useFetchPlans();
  const alternativePeriod =
    data.periodicity === PAYMENT_PERIODS.MONTHLY
      ? PAYMENT_PERIODS.YEARLY
      : PAYMENT_PERIODS.MONTHLY;
  const alternativePlan = calculateNewPlan(
    alternativePeriod,
    data.marketName,
    plans
  );
  const monthlyPlan =
    alternativePeriod === PAYMENT_PERIODS.MONTHLY ? alternativePlan : data;
  const yearlyPlan =
    alternativePeriod === PAYMENT_PERIODS.YEARLY ? alternativePlan : data;
  const { marketName } = data;
  const isMobile = useSelector(selectIsMobile);
  return (
    <>
      <Formik
        onSubmit={(values, actions) => {
          onSubmit(values, coupon);
          actions.setSubmitting(false);
        }}
        validateOnChange={false}
        validateOnBlur={false}
        {...{ initialValues: data }}
      >
        {(formik) => {
          function onChange(periodicity: string) {
            const newPlan = calculateNewPlan(periodicity, marketName, plans);
            if (newPlan && setPaymentState && setCoupon) {
              setSelectedPlan && setSelectedPlan(newPlan);
              setCoupon(undefined);
              setPaymentState({
                periodicity,
                productId: newPlan.productId,
                marketName: newPlan.marketName,
                price: newPlan.price,
              });
              formik.setFieldValue('productId', newPlan.productId);
              formik.setFieldValue('marketName', newPlan.marketName);
              formik.setFieldValue('price', newPlan.price);
            }
          }

          return (
            <form
              onSubmit={formik.handleSubmit}
              noValidate
              id="payment-options-form"
              data-testid="payment-options-form"
            >
              <ErrorListener />
              <Fieldset
                display="flex"
                flexWrap="wrap"
                hiddenLegend={!!isMobile}
                id="payment-periodicity"
                justifyContent="space-betwwen"
                legend={t(messages.step1FormTitle)}
                legendMarginBottom={{ sm: '24px' }}
                marginBottom={{ _: '12px', sm: '8px', lg: '10px' }}
                role="radiogroup"
              >
                <PlanPrice
                  id="periodicity-monthly"
                  onChange={onChange}
                  price={monthlyPlan?.price}
                  text={t(messages.step1FormRadioMonthly)}
                  value={PAYMENT_PERIODS.MONTHLY}
                  showYearlyPrice={false}
                />
                <PlanPrice
                  id="periodicity-yearly"
                  marginLeft={{ _: '16px', lg: '24px', xl: '32px' }}
                  onChange={onChange}
                  price={
                    yearlyPlan?.price && roundNumber(yearlyPlan.price / 12, 2)
                  }
                  text={t(messages.step1FormRadioYearly)}
                  value={PAYMENT_PERIODS.YEARLY}
                  showYearlyPrice={false}
                />
              </Fieldset>
              <Box
                display={{ sm: 'flex' }}
                justifyContent={{ sm: 'flex-end' }}
                marginBottom={{ _: '24px', sm: '18px' }}
                marginLeft={{ sm: '16px', lg: '24px', xl: '32px' }}
              >
                <FormOfferText
                  color="gray600"
                  fontSize={12}
                  lineHeight={16}
                  text={t(messages.step1FormOffer)}
                  width={{ sm: '50%' }}
                />
              </Box>
            </form>
          );
        }}
      </Formik>
      <Hr color={theme.colors.primary200} marginBottom="24px" />
      <PaymentFormCoupons
        {...{
          productId: selectedPlan.productId,
          coupon,
          setCoupon,
          selectedPlan,
        }}
      />
    </>
  );
};

export default PaymentOptionsForm;
