import styled from 'modules/Theme/styled-components';
import Box from 'modules/Ui/Box';

const Card = styled(Box)`
  background-color: ${(props) =>
    props.disabled ? props.theme.colors.auxiliary : 'inherit'};

  &:before {
    background-color: ${(props) => props.theme.colors.gray0};
    content: "";
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    transition: opacity 0.1s linear;
  }
`;

export default Card;
