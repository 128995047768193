import { css } from 'modules/Theme/styled-components';

const accessibleHidden = css`
  clip: rect(1px, 1px, 1px, 1px);
  height: 2px;
  overflow: hidden;
  position: absolute;
  width: 2px !important;
`;

export default accessibleHidden;
