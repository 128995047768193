import { keyframes, css } from 'modules/Theme/styled-components';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const fadeInAnimation = css`
  animation: ${fadeIn} 0.2s ease-in;
`;

export default fadeIn;
