/**
 * Transform dot.field.notation => [dot][field][notation]
 * @param field field expresed in dot notation
 */
export function dotFieldToQuery(field = '') {
  return field
    .split('.')
    .map((d) => `[${d}]`)
    .join('');
}
