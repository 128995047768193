import { useSelector } from 'react-redux';

import { selectIsMobile } from 'modules/App/store/selectors';
import useTheme from 'modules/Theme/hooks/useTheme';

import IconByString, { IconByStringProps } from '../Icon/IconType/IconByString';

const IconMenuBookkeeper = (props: IconByStringProps) => {
  const { active, ...rest } = props;
  const isMobile = useSelector(selectIsMobile);
  const theme = useTheme();
  const activePathColor = (color: string, tabletColor?: string) => {
    if (isMobile) {
      return color;
    }
    return tabletColor || theme.colors.brand500;
  };
  return (
    <IconByString {...{ active, ...rest }}>
      <path
        className="icon-hover-effect"
        d="M2 6h20v4a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6Z"
        fill={
          active
            ? activePathColor('transparent', 'currentColor')
            : 'transparent'
        }
      />
      <rect
        className="icon-hover-effect--blue"
        fill={active ? theme.colors.brand500 : 'transparent'}
        height="2"
        width="4"
        x="10"
        y="13"
      />
      <path
        className="icon-hover-effect"
        d="M20 13c.768 0 1.47-.289 2-.764V18a2.03 2.03 0 0 1-.096.616A2 2 0 0 1 20 20H4a2 2 0 0 1-2-2v-5.764A2.99 2.99 0 0 0 4 13h5v3h6v-3h5ZM16 3H8v2h8V3ZM7 2v4h10V2H7Z"
        fillRule="evenodd"
        fill={
          active
            ? activePathColor('transparent', 'currentColor')
            : 'transparent'
        }
      />
      <path
        className="icon-hover-effect"
        d="M16 3H8V5H16V3ZM7 2V6H17V2H7Z"
        fillRule="evenodd"
        fill="currentColor"
      />
      <path
        className="icon-hover-effect--blue"
        d="M14 13h-4v2h4v-2Zm-5 0v3h6v-3H9Z"
        fillRule="evenodd"
        fill={active ? activePathColor(theme.colors.brand500) : 'currentColor'}
      />
      <path
        className="icon-hover-effect--blue"
        d="M4 12a2 2 0 0 1-2-2v2.236A2.99 2.99 0 0 0 4 13h16c.768 0 1.47-.289 2-.764V10a2 2 0 0 1-2 2H4Z"
        fillRule="evenodd"
        fill={active ? activePathColor('currentColor') : 'currentColor'}
        shapeRendering="geometricPrecision"
      />
      <path
        className="icon-hover-effect--transparent"
        d="M22 6H2v12a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V6ZM1 5v13a3 3 0 0 0 3 3h16a3 3 0 0 0 3-3V5H1Z"
        fillRule="evenodd"
        fill={
          active
            ? activePathColor('currentColor', 'transparent')
            : 'currentColor'
        }
      />
    </IconByString>
  );
};

export default IconMenuBookkeeper;
