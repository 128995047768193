import * as Yup from 'yup';

import { formText } from 'modules/Forms/messages';
import { TranslationFunction } from 'modules/I18n/hooks/useTranslations';

const getValidationSchema = (t: TranslationFunction) =>
  Yup.object().shape({
    salesCode: Yup.string().required(t(formText.required)),
  });

export default getValidationSchema;
