import { ReactNode } from 'react';

import {
  gridTemplateColumns,
  GridTemplateColumnsProps,
  layout,
  LayoutProps,
  space,
  SpaceProps,
  alignItems,
  AlignItemsProps,
} from 'styled-system';

import styled from 'modules/Theme/styled-components';
import useIsReader from 'modules/Users/hooks/useIsReader';

import Box from '../Box';

interface Props
  extends AlignItemsProps,
    GridTemplateColumnsProps,
    LayoutProps,
    SpaceProps {
  children: ReactNode | string;
}

const StyledWrapper = styled(Box)<Props>`
  ${space}
  ${gridTemplateColumns}
  ${layout}
  ${alignItems}
`;

const SectionTitleWrapper: React.FC<Props> = ({ children, ...rest }) => {
  const isUserReader = useIsReader();
  return (
    <StyledWrapper
      backgroundColor={{ _: 'auxiliary', sm: 'initial' }}
      padding={isUserReader ? '0 16px' : { _: '0 16px 24px', sm: '0 0 16px' }}
      {...rest}
    >
      {children}
    </StyledWrapper>
  );
};

export default SectionTitleWrapper;
