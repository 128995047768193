import { Formik } from 'formik';

import useTranslations from 'modules/I18n/hooks/useTranslations';
import { CreatedPaymentMethod } from 'modules/PaymentMethods/models/paymentMethods';
import { Text } from 'modules/Ui';

import getValidationSchema from '../getValidationSchema';
import { update } from '../messages';
import { getSubForm, paymentMethodItems } from '../paymentMethodItems';
import normalizeValues from './normalizeValues';

interface Props {
  paymentMethod: CreatedPaymentMethod;
  onSubmit(paymentMEthod: CreatedPaymentMethod): void;
  showCheckForNewDocuments?: boolean;
  forContact?: boolean;
}

const UpdatePaymentMethodForm: React.FC<Props> = ({
  paymentMethod,
  onSubmit,
  showCheckForNewDocuments,
  forContact,
}) => {
  const { t } = useTranslations();

  const validationSchema = getValidationSchema(t);

  const Subform = getSubForm(paymentMethodItems, paymentMethod.type);
  const subtitle = update[paymentMethod.type.toLowerCase()];

  return (
    <Formik
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={(values, actions) => {
        actions.setSubmitting(false);
        onSubmit(normalizeValues(values));
      }}
      {...{ validationSchema, initialValues: paymentMethod }}
    >
      {(formik) => {
        return (
          <form
            data-testid="update-payment-method-modal-form"
            id="update-payment-method-modal-form"
            noValidate
            onSubmit={formik.handleSubmit}
          >
            <Text marginBottom="12px">{t(subtitle)}</Text>
            {Subform && (
              <Subform
                isEditing={!showCheckForNewDocuments}
                forContact={forContact}
              />
            )}
          </form>
        );
      }}
    </Formik>
  );
};

export default UpdatePaymentMethodForm;
