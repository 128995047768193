import { getConfig } from 'modules/App/config';
import { PAYMENT_METHODS } from 'modules/App/queries/queries';
import { CreatedPaymentMethod } from 'modules/PaymentMethods/models/paymentMethods';
import request from 'utils/request';

const deletePaymentMethod = async (
  id: string
): Promise<CreatedPaymentMethod> => {
  const endpoint = (await getConfig()).apiPaymentMethodsService;

  const response = await request<any>(`${endpoint}/${id}`, {
    method: 'DELETE',
  });

  return response.data;
};

export const invalidateQueries = [PAYMENT_METHODS];

export default deletePaymentMethod;
