import HtmlComponent from './HtmlComponent';
import Props from './Props';

const validProps = ['value', 'max'];

const Progress: React.FC<Props> = (props) => (
  <HtmlComponent tag="progress" {...{ validProps }} {...props} />
);

export default Progress;
