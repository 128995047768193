import { useSelector } from 'react-redux';

import { selectCurrentBusiness } from 'modules/Business/Current/selectors';

import { TrustedCampaignCodes } from '../detail/constants';

function useIsTrustedBusinessCampaign(): {
  isTrustedCampaign: boolean;
  trustedCampaignCode: string | undefined;
} {
  const business = useSelector(selectCurrentBusiness);
  let isTrustedCampaign = false;
  let trustedCampaignCode;
  if (business.campaign) {
    isTrustedCampaign = TrustedCampaignCodes.includes(business.campaign?.code);
    if (isTrustedCampaign) {
      trustedCampaignCode = business.campaign?.code;
    }
  }
  return {
    isTrustedCampaign,
    trustedCampaignCode,
  };
}

export default useIsTrustedBusinessCampaign;
