import { useDispatch } from 'react-redux';

import { Entity } from 'modules/App/models';

import { createBubbleNotification, removeBubbleNotification } from '../actions';
import { BubbleNotification } from '../models';

const useBubbleNotifications = () => {
  const dispatch = useDispatch();
  const dispatchBubbleNotification = (
    message: string,
    options?: Omit<BubbleNotification, 'id' | 'message' | 'type'> &
      Partial<Entity>
  ) => {
    dispatch(
      createBubbleNotification({
        message,
        ...options,
      })
    );
  };

  const deleteBubbleNotification = (id: string) =>
    dispatch(removeBubbleNotification(id));
  return {
    delete: deleteBubbleNotification,
    create: dispatchBubbleNotification,
  };
};

export default useBubbleNotifications;
