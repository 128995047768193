import { FC } from 'react';

import useTranslations from 'modules/I18n/hooks/useTranslations';

import { shopifyForm } from '../../messages';
import { IntegrationActions } from '../IntegrationActions';

interface Props {
  id?: string;
}

export const ShopifyActions: FC<Props> = (props: Props) => {
  const { id } = props;

  const { t } = useTranslations();
  return (
    <IntegrationActions
      id={id}
      cancel={t(shopifyForm.seriesCancel)}
      submit={t(shopifyForm.seriesSave)}
    />
  );
};
