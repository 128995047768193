import { getConfig } from 'modules/App/config';
import { StatusInfoUI } from 'modules/Documents/models/statusInfo';
import { formatDate } from 'utils/dates';
import { addGteQuery, addLteQuery } from 'utils/query/addQuery';
import { buildTortillaBaseQuery } from 'utils/query/buildTortillaBaseQuery';
import request from 'utils/request';

import {
  Payment,
  PaymentFilters,
  PaymentSort,
  PaymentStatusEnum,
} from '../models';

interface FetchPaymentsResponse {
  items: Payment[];
  count: number;
}

function format(date?: string | Date | undefined) {
  return formatDate(date, 'yyyy-MM-dd');
}

export default async function fetchPayments({
  page,
  pageSize,
  sortBy,
  filters,
}: {
  page?: number;
  pageSize?: number;
  sortBy?: PaymentSort;
  searchTerm?: string;
  filters?: PaymentFilters;
}): Promise<FetchPaymentsResponse> {
  const endpoint = (await getConfig()).apiPaymentsService;

  let query = buildTortillaBaseQuery({
    page,
    pageSize,
    sort: [
      {
        field: sortBy?.field,
        order: sortBy?.order,
      },
    ],
  });

  if (filters?.dateFrom) {
    query += addGteQuery('operationDate', format(filters.dateFrom));
  }
  if (filters?.dateTo) {
    query += addLteQuery('operationDate', format(filters.dateTo));
  }
  if (filters?.minAmount || filters?.minAmount === 0) {
    query += `&query[signedAmount][$gte]=${filters.minAmount}`;
  }
  if (filters?.maxAmount || filters?.maxAmount === 0) {
    query += `&query[signedAmount][$lte]=${filters.maxAmount}`;
  }
  if (filters?.searchTerm) {
    query += `&search=${filters.searchTerm}`;
  }
  if (filters?.documentId) {
    query += `&query[documents][$in][0]=${filters?.documentId}`;
  }

  query += queryLinkAndStatus(filters?.linked, filters?.paidStatus);

  const response = await request<{ data: FetchPaymentsResponse }>(
    `${endpoint}?${query}`,
    {
      method: 'GET',
    }
  );
  return response?.data?.data;
}

const queryLinkAndStatus = (
  linked?: PaymentStatusEnum,
  paidStatus?: StatusInfoUI
): string => {
  let inOptions: string[] = [];
  let ninOptions: string[] = [];

  if (linked && linked === PaymentStatusEnum.LINKED) {
    inOptions.push(PaymentStatusEnum.LINKED);
    inOptions.push(PaymentStatusEnum.PARTIALLY_LINKED);
    ninOptions.push(PaymentStatusEnum.NOT_LINKED);
  }
  if (linked && linked === PaymentStatusEnum.NOT_LINKED) {
    inOptions.push(PaymentStatusEnum.NOT_LINKED);
    ninOptions.push(PaymentStatusEnum.LINKED);
    ninOptions.push(PaymentStatusEnum.PARTIALLY_LINKED);
  }
  if (paidStatus && paidStatus === StatusInfoUI.PAID) {
    inOptions.push(PaymentStatusEnum.LINKED);
    inOptions.push(PaymentStatusEnum.NOT_LINKED);
    ninOptions.push(PaymentStatusEnum.PARTIALLY_LINKED);
  }
  if (paidStatus && paidStatus === StatusInfoUI.PARTIALLY_PAID) {
    inOptions.push(PaymentStatusEnum.PARTIALLY_LINKED);
    ninOptions.push(PaymentStatusEnum.LINKED);
    ninOptions.push(PaymentStatusEnum.NOT_LINKED);
  }

  inOptions = inOptions.filter((item, index) => {
    return inOptions.indexOf(item) === index;
  });
  ninOptions = ninOptions.filter((item, index) => {
    return ninOptions.indexOf(item) === index;
  });

  return buildStatusInfoQuery(inOptions, ninOptions);
};

const buildStatusInfoQuery = (inOptions: string[], ninOptions: string[]) => {
  let query = '';
  inOptions.forEach((item, idx) => {
    query += `&query[status][$in][${idx}]=${item}`;
  });
  ninOptions.forEach((item, idx) => {
    query += `&query[status][$nin][${idx}]=${item}`;
  });
  return query;
};
