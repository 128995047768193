import { useQuery } from 'react-query';

import { DOCUMENT_SERIES_QUERY } from 'modules/App/queries/queries';

import { DocumentSerie, DocumentSerieSort, TargetDocument } from '../models';
import { createDocumentSerie } from '../services';
import fetchDocumentSeries from '../services/fetchDocumentSeries';

const TEST_INVOICE_SERIALCODE = 'Prueba';

export default function useDocumentSeries(
  targetDocument?: TargetDocument,
  sortBy?: DocumentSerieSort
) {
  return useQuery(
    [DOCUMENT_SERIES_QUERY, { sortBy, targetDocument }],
    async (): Promise<DocumentSerie[]> => {
      const data = await fetchDocumentSeries({ targetDocument, sortBy });
      if (!data.length && targetDocument === TargetDocument.TEST) {
        const newSerie = await createDocumentSerie({
          serialCode: TEST_INVOICE_SERIALCODE,
          targetDocument: TargetDocument.TEST,
        });
        return [newSerie];
      }
      return data;
    }
  );
}
