import currencies from './currencies_es.json';

export const PRINCIPAL_CURRENCIES = ['EUR', 'USD', 'GBP'];

export const getCurrencySymbol = (code: string) => {
  const tmpCurrency = currencies.find(
    (currency) => currency.code === code.toUpperCase()
  );
  if (!tmpCurrency) {
    return code;
  }
  return tmpCurrency.symbol || tmpCurrency.code;
};

export const getPrioritizedCurrencies = (principal: string[]) => {
  const first = currencies.filter((currency) =>
    principal.includes(currency.code)
  );
  const last = currencies
    .filter((currency) => !principal.includes(currency.code))
    .sort((a, b) => a.description.localeCompare(b.description));
  return [...first, ...last];
};

export const getCurrencyLocale = (code: string) => {
  const tmpCurrency = currencies.find(
    (currency) => currency.code === code.toUpperCase()
  );
  if (!tmpCurrency) {
    return code;
  }
  return tmpCurrency.locale || 'de-DE';
};

export const getCurrencyBySymbol = (symbol: string) => {
  const tmpCurrency = currencies.find(
    (currency) => currency.symbol === symbol.toUpperCase()
  );
  if (!tmpCurrency) {
    return getCurrencyByCode(symbol)?.code;
  }
  return tmpCurrency.code;
};

export const getCurrencyByCode = (code: string) => {
  const tmpCurrency = currencies.find(
    (currency) => currency.code === code.toUpperCase()
  );
  if (!tmpCurrency) {
    return undefined;
  }
  return tmpCurrency;
};
