import { useCallback, createElement } from 'react';
import { MessageDescriptor } from 'react-intl';

import { useDeletedContactMiddleware } from 'modules/Contacts/hooks/useDeletedContactMiddleware';
import useTranslations from 'modules/I18n/hooks/useTranslations';
import useNotifications from 'modules/Notifications/hooks/useNotifications';
import { Box } from 'modules/Ui';
import { ResponseError } from 'types/response-error.type';
import requestErrorHandler, {
  requestErrorHandlerExtra,
} from 'utils/requestErrorHandler';

import { remittanceNotifications } from '../messages';
import { useUpdateContactPaymentMethod } from './useUpdateContactPaymentMethod';

const MAX_DOCUMENTS_IN_REMITTANCE = 50;
const UPDATE_CONTACT_PAYMENT_METHOD_ERRORS = [
  'invalid-contact-payment-methods',
  'no-iban-in-payment-method',
];

type RemittanceResponseErrorDeletedContact = Array<{
  contactId: string;
  fiscalName: string;
}>;

export const useRemittanceErrorHandler = (options?: {
  messages?: {
    limitExceeded: MessageDescriptor;
    deletedContact: MessageDescriptor;
    submitError: MessageDescriptor;
    invalidContactPaymentMethods: MessageDescriptor;
    contactModalSubtitle: MessageDescriptor;
  };
}): {
  handleErrors: (error: ResponseError) => Promise<void>;
} => {
  const { messages = remittanceNotifications } = options || {};
  const { t } = useTranslations();
  const notifications = useNotifications();
  const { deletedContactMiddleware } = useDeletedContactMiddleware();
  const updateContactPaymentMethod = useUpdateContactPaymentMethod(options);

  const handleErrors = useCallback(async (error) => {
    const { isNetworkError, networkErrorTranslationKey, responseCode } =
      requestErrorHandler(error);

    if (responseCode === 'documents-limit-reached') {
      notifications.error(
        t(messages.limitExceeded, {
          count: MAX_DOCUMENTS_IN_REMITTANCE,
        })
      );
      return;
    }

    if (responseCode === 'deleted-contact') {
      const extra: RemittanceResponseErrorDeletedContact =
        requestErrorHandlerExtra(error);
      const contactData = extra[0];
      const message = t(messages.deletedContact, {
        fiscalName: contactData.fiscalName,
        strong: (chunk) =>
          createElement(Box, {
            fontWeight: '600',
            tag: 'strong',
            children: chunk,
          }),
      });
      await deletedContactMiddleware({
        contactId: contactData.contactId,
        messageNotification: message,
        messageKey: 'deleted-contact',
      });
      return;
    }

    const hasToUpdateContactPaymentMethod =
      responseCode &&
      UPDATE_CONTACT_PAYMENT_METHOD_ERRORS.includes(responseCode);

    if (hasToUpdateContactPaymentMethod) {
      updateContactPaymentMethod(error);
      return;
    }

    notifications.error(
      isNetworkError ? networkErrorTranslationKey : messages.submitError.id
    );
  }, []);
  return { handleErrors };
};
