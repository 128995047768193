import { ReactNode } from 'react';

import FileUpload from '../FileUpload';

interface Props {
  file?: File | null;
  onSubmit: (formData: any) => void;
  previewUrl?: string;
  setFile: (file: File | null) => void;
  setPreviewUrl: (value?: string) => void;
  dropzoneTexts: DropzoneTexts;
  mandatoryFields: ReactNode;
  id: string;
  acceptedFiles: string;
}

interface DropzoneTexts {
  content: string;
  description: string | JSX.Element;
  title: string;
}

const ImportForm: React.FC<Props> = ({
  file,
  onSubmit,
  previewUrl,
  setFile,
  setPreviewUrl,
  dropzoneTexts,
  mandatoryFields,
  id,
  acceptedFiles,
}) => {
  const submit = () => {
    const formData = new FormData();
    formData.append('file', file as Blob);
    onSubmit(formData);
  };
  return (
    <>
      <FileUpload
        {...{ submit, setFile, previewUrl, setPreviewUrl, file }}
        acceptedFiles={acceptedFiles}
        dropzoneTexts={dropzoneTexts}
        id={id}
      />
      {mandatoryFields}
    </>
  );
};

export default ImportForm;
