import { useSelector } from 'react-redux';

import { selectHasSubscriptionScope } from 'modules/Auth/selectors';
import { selectIsMySubsciptionActive } from 'modules/Subscriptions/detail/selectors';

export default function useIsBusinessBlocked() {
  const isMySubsciptionActive = useSelector(selectIsMySubsciptionActive);
  const hasSubscriptionScope = useSelector(selectHasSubscriptionScope);
  return isMySubsciptionActive === false || hasSubscriptionScope === false;
}
