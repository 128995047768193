import { FC, useCallback } from 'react';
import { useSelector } from 'react-redux';

import { useInvalidateAndUpdate } from 'modules/App/hooks/useInvalidateAndUpdate';
import { hasElectronicDocumentProvider } from 'modules/Business/businessUtils';
import { selectCurrentBusiness } from 'modules/Business/Current/selectors';
import useTranslations from 'modules/I18n/hooks/useTranslations';
import { ModalTypes } from 'modules/Modals/constants';
import useOpenModal from 'modules/Modals/hooks/useOpenModal';
import useTheme from 'modules/Theme/hooks/useTheme';
import { Box, Text, Button } from 'modules/Ui';

import { series as messages } from '../../messages';
import { DocumentSerie, DocumentSerieSort, TargetDocument } from '../../models';
import { invalidateQueries } from '../../services/createDocumentSerie';
import Separator from '../Separator';
import Serie from '../Serie';
import { SeriesHeader } from './SeriesHeader';

interface DocumentSeriesProps {
  series?: DocumentSerie[];
  sortBy: DocumentSerieSort;
  setSortBy: (sort: DocumentSerieSort) => void;
  targetDocumentType?: TargetDocument;
}

export const DocumentSeries: FC<DocumentSeriesProps> = ({
  series,
  setSortBy,
  sortBy,
  targetDocumentType,
}) => {
  const { t } = useTranslations();
  const invalidateAndUpdate = useInvalidateAndUpdate();
  const business = useSelector(selectCurrentBusiness);
  const showRectificationSeries = hasElectronicDocumentProvider(business);
  const openModal = useOpenModal();
  const theme = useTheme();
  const canDeleteSerie = useCallback(
    (targetDocument: TargetDocument) =>
      series
        ? series.filter((s) => s.targetDocument === targetDocument).length > 1
        : false,
    [series]
  );
  return (
    <>
      <Text
        marginBottom={{ _: '8px', sm: '16px' }}
        tag="h3"
        variant="subheading"
      >
        {t(messages.title)}
      </Text>
      <Text textSize="xs" marginBottom={{ _: '16px', sm: '32px' }}>
        {t(messages.subtitle)}
      </Text>
      <Button
        data-testid="acceptButton"
        marginBottom={{ _: '36px', sm: '40px' }}
        onClick={() =>
          openModal({
            type: ModalTypes.CREATE_SERIE_MODAL,
            initialSelectValue: TargetDocument.INVOICE,
            onAccept: () => invalidateAndUpdate({ invalidateQueries }),
            allowTargetChange: true,
            targetDocumentType,
          })
        }
        type="button"
        variant="primary"
        width={{ _: '100%', sm: 'initial' }}
      >
        {t(messages.newSerie)}
      </Button>
      <Text
        color="gray800"
        fontSize={18}
        fontWeight={600}
        lineHeight={20}
        marginBottom="10px"
        tag="h3"
      >
        {t(messages.createdSeries)}
      </Text>
      <Box
        data-testid="list-items"
        marginBottom={{ _: '60px', sm: '24px' }}
        position="relative"
        tag="table"
        width="100%"
      >
        <SeriesHeader sortBy={sortBy} setSortBy={setSortBy} />
        <tbody>
          {series?.length &&
            series
              .filter((serie) => {
                if (serie.targetDocument === TargetDocument.CORRECTIVE) {
                  return showRectificationSeries;
                }
                return true;
              })
              .map((serie) => (
                <Serie
                  last={!!(series.length - 1)}
                  serie={serie}
                  key={serie.id}
                  canDeleteSerie={canDeleteSerie}
                />
              ))}
        </tbody>
      </Box>
      <Separator color={theme.colors.primary100} />
    </>
  );
};
