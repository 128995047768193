import { createAction, createAsyncAction } from 'typesafe-actions';

import Business from '../models/business';
import ActionTypes from './constants';

export const getBusiness = createAsyncAction(
  ActionTypes.FETCH_BUSINESS_REQUEST,
  [ActionTypes.FETCH_BUSINESS_SUCCESS, (payload: Business) => payload],
  [ActionTypes.FETCH_BUSINESS_FAILURE, (error: Error) => error]
)();

export const updateBusinessVatNumber = createAction(
  ActionTypes.UPDATE_BUSINESS_VAT_NUMBER,
  (payload: string) => payload
)();
