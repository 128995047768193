import { Formik } from 'formik';

import { DocumentTogglesProvider } from 'modules/Documents/components/DocumentTogglesProvider/DocumentTogglesProvider';
import { SeriesProvider } from 'modules/Documents/components/SeriesProvider/SeriesProvider';
import useUploadDocumentData from 'modules/Documents/hooks/useUploadDocumentData';
import useTranslations from 'modules/I18n/hooks/useTranslations';
import { ParamsShopConfig, ShopConfig } from 'modules/Integrations/models';

import { shopifyForm } from '../../messages';
import { normalizeValues } from './normalizeValues';
import { ShopifyFormNotificationErrors } from './ShopifyFormNotificationErrors';
import { ShopifyFormOptions } from './ShopifyFormOptions';
import { ShopifyFormSeries } from './ShopifyFormSeries';
import { ShopifyFormTitle } from './ShopifyFormTitle';
import { validationSchema } from './validationSchema';

interface Props {
  configuration: ShopConfig;
  onSubmit: (data: ParamsShopConfig) => void;
  formId: string;
}

export const ShopifyForm = ({ formId, configuration, onSubmit }: Props) => {
  const { series } = useUploadDocumentData();
  const { t } = useTranslations();
  return (
    <>
      <ShopifyFormTitle
        title={t(shopifyForm.title)}
        subtitle={t(shopifyForm.subtitle)}
      />
      <SeriesProvider series={series}>
        <DocumentTogglesProvider>
          <Formik
            validateOnBlur={false}
            validateOnChange={false}
            onSubmit={(values) => {
              onSubmit(normalizeValues(values));
            }}
            initialValues={configuration}
            validationSchema={validationSchema}
          >
            {(formik) => {
              return (
                <form
                  data-testid={`${formId}-test`}
                  id={formId}
                  noValidate
                  onSubmit={formik.handleSubmit}
                >
                  <ShopifyFormSeries />
                  <ShopifyFormOptions />
                  <ShopifyFormNotificationErrors />
                </form>
              );
            }}
          </Formik>
        </DocumentTogglesProvider>
      </SeriesProvider>
    </>
  );
};
