import { useCallback, useMemo } from 'react';
import { RouteProps } from 'react-router-dom';

import { last, indexOf } from 'lodash';

import history from 'utils/history';

export enum Steps {
  DOCUMENT_DATA = 'document-data',
  REVIEW_DATA = 'review-data',
}

export const REVIEW_DATA_BAI = `${Steps.REVIEW_DATA}-bai`;

export const stepsPercentage = {
  'document-data': '50',
  'review-data': '100',
};

export const steps = [Steps.DOCUMENT_DATA, Steps.REVIEW_DATA];
const visitedSteps = [Steps.DOCUMENT_DATA];

interface UseFormStepResponse {
  canGoToPreviousStep: boolean;
  isLastStep: boolean;
  nextStep: () => void;
  previousStep: () => void;
  activeStep: string;
  redirectIfMissedStep: (step: string) => void;
  stepTranslationKey: (step: string, hasElectronicProvider?: boolean) => string;
}

const goToNextStep = (stepIndex: number) => {
  const mainPath = getMainPath();
  const newStep = steps[stepIndex + 1];
  history.push(`${mainPath}${newStep}`);
  visitedSteps.push(newStep);
};

const redirectIfMissedStep = (activeStep: string) => {
  const mainPath = getMainPath();
  if (indexOf(visitedSteps, activeStep) === -1) {
    history.replace(`${mainPath}${Steps.DOCUMENT_DATA}`);
  }
};

const getMainPath = () => {
  const { pathname } = history.location;
  // Cut the step (/document-data, /concepts) from the current url
  // we use this function at goToNextStep && redirectIfMissedStep
  const split = pathname.split('/').slice(0, -1);

  return `${split.join('/')}/`;
};

const stepTranslationKey = (step: string, hasElectronicProvider?: boolean) => {
  return hasElectronicProvider && step === Steps.REVIEW_DATA
    ? REVIEW_DATA_BAI
    : step;
};

interface Params {
  props: RouteProps;
  isEditing?: boolean;
  isCopying?: boolean;
  isQuote?: boolean;
  isProforma?: boolean;
  isDeliveryNote?: boolean;
  isCorrective?: boolean;
  isPartialCorrective?: boolean;
}

export const useFormStep = (params: Params): UseFormStepResponse => {
  const {
    props,
    isProforma,
    isDeliveryNote,
    isQuote,
    isCopying,
    isEditing,
    isCorrective,
    isPartialCorrective,
  } = params;
  const { location } = props;
  const activeStep = useMemo(
    () => last(location?.pathname?.split('/')) || Steps.DOCUMENT_DATA,
    [location]
  );
  const stepIndex = indexOf(steps, activeStep);

  return {
    activeStep,
    previousStep: history.goBack,
    isLastStep:
      activeStep === Steps.REVIEW_DATA ||
      (!!isCorrective && !isPartialCorrective),
    canGoToPreviousStep: activeStep !== Steps.DOCUMENT_DATA,
    nextStep: useCallback(
      () => goToNextStep(stepIndex),
      [
        stepIndex,
        isEditing,
        isCopying,
        isQuote,
        isProforma,
        isDeliveryNote,
        isCorrective,
        isPartialCorrective,
      ]
    ),
    redirectIfMissedStep: useCallback(
      () => redirectIfMissedStep(activeStep),
      [activeStep, isEditing]
    ),
    stepTranslationKey,
  };
};
