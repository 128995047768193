import { getConfig } from 'modules/App/config';
import request from 'utils/request';

export interface MilestonesResponse {
  businessInfo: boolean;
  firstContact: boolean;
  firstDocument: boolean;
  customDocument: boolean;
}

const fetchMilestones = async (): Promise<MilestonesResponse> => {
  const endpoint = (await getConfig()).apiBusinessesService;

  const response = await request<{ data: MilestonesResponse }>(
    `${endpoint}/current/milestones`,
    {
      method: 'GET',
    }
  );

  return response.data.data;
};

export default fetchMilestones;
