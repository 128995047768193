import { Text, Box } from 'modules/Ui';

interface Props {
  text: string;
  pill?: string;
}

const DiscountBanner: React.FC<Props> = ({ pill, text }) => {
  return (
    <Box
      alignItems={{ sm: 'center' }}
      backgroundColor="brand200"
      color="brand500"
      display={{ sm: 'grid' }}
      gap={{ sm: '8px' }}
      gridTemplateColumns={{ sm: '1fr auto' }}
      marginBottom={{ _: '20px', md: '20px' }}
      padding={{ _: '16px', md: '13px 16px' }}
    >
      <Text
        color="inherit"
        fontSize={{ _: 14, sm: 18 }}
        lineHeight={{ _: 16, sm: 21 }}
        marginBottom={{ _: '4px', sm: '0' }}
      >
        {text}
      </Text>
      {pill && (
        <Text
          backgroundColor="gray0"
          borderRadius={{ _: '48px', sm: '40px' }}
          boxSizing="border-box"
          color="inherit"
          fontSize={14}
          fontWeight="700"
          lineHeight={{ _: 32, sm: 38 }}
          padding={{ sm: '0 16px' }}
          textAlign="center"
        >
          {pill}
        </Text>
      )}
    </Box>
  );
};

export default DiscountBanner;
