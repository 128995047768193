import { getConfig } from 'modules/App/config';
import request from 'utils/request';

const deleteBusiness = async ({
  businessId,
}: {
  businessId?: string;
}): Promise<void> => {
  const endpoint = (await getConfig()).apiBusinessesService;
  const response = await request<any>(
    `${endpoint}/notify-removal/${businessId}`,
    {
      method: 'POST',
    }
  );

  return response.data.data;
};

export default deleteBusiness;
