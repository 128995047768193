import { FC, MutableRefObject, useEffect } from 'react';
import ReactTooltip, { TooltipProps } from 'react-tooltip';

import useTheme from 'modules/Theme/hooks/useTheme';

const offsetMapping = {
  right: { right: 8 },
  left: { left: 8 },
  top: { top: 8 },
  bottom: { bottom: 4 },
};

interface Props {
  tipRef?: MutableRefObject<any>;
  id?: string;
}

const TooltipContent: FC<TooltipProps & Props> = (props) => {
  const { id, place = 'right', tipRef, type = 'light' } = props;
  const theme = useTheme();
  const offset = offsetMapping[place];
  const defaultBackground = theme.colors.auxiliary100A30;

  function hideTooltipOnScroll() {
    // Forgive me for this, react-tooltip typing expects a string (but wants an DOM node)
    tipRef?.current && ReactTooltip.hide(tipRef?.current as unknown as any);
  }

  // We cannot rely on react-tooltip hiding the tooltip on scroll
  // beacuse we have some css overrides that cover the window scroll events
  useEffect(() => {
    const body = document.querySelector('body') as HTMLBodyElement;
    body.addEventListener('scroll', hideTooltipOnScroll);
    return () => {
      body.removeEventListener('scroll', hideTooltipOnScroll);
    };
  });
  return (
    <ReactTooltip
      backgroundColor={props.backgroundColor ?? defaultBackground}
      border
      borderColor={props.borderColor ?? theme.colors.primary100}
      className="react-tooltip"
      clickable
      effect="solid"
      globalEventOff="click"
      id={`${id}-tooltip`}
      role="tooltip"
      textColor={props.textColor ?? theme.colors.gray600}
      type={type}
      {...props}
      {...{ place, offset }}
    />
  );
};

export default TooltipContent;
