import HtmlComponent from './HtmlComponent';
import Props from './Props';

const validProps = [
  'className',
  'clip-path',
  'clip-rule',
  'color-interpolation',
  'color-rendering',
  'color',
  'cursor',
  'd',
  'display',
  'fill-opacity',
  'fill-rule',
  'fill',
  'filter',
  'id',
  'mask',
  'opacity',
  'pathLength',
  'pointer-events',
  'shape-rendering',
  'stroke-dasharray',
  'stroke-dashoffset',
  'stroke-linecap',
  'stroke-linejoin',
  'stroke-miterlimit',
  'stroke-opacity',
  'stroke-width',
  'stroke',
  'style',
  'tabindex',
  'transform',
  'vector-effect',
  'visibility',
];

const Path = (props: Props) => (
  <HtmlComponent tag="path" {...{ validProps }} {...props} />
);

export default Path;
