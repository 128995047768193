import { UserNotification } from '../../models/models';
import { fetchNewNotifications } from './fetchNewNotifications';

export const fetchNotificationsCounter = async (): Promise<{
  unread: number;
  notifications: UserNotification[];
}> => {
  const responseFetchOnlyUnreadNotifications = await fetchNewNotifications();
  return {
    unread: responseFetchOnlyUnreadNotifications.count,
    notifications: responseFetchOnlyUnreadNotifications.items,
  };
};
