import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: { id: 'gofre.Bookkeeper.Banner.Exporter.title' },
  subtitle: { id: 'gofre.Bookkeeper.Banner.Exporter.subtitle' },
  cta: { id: 'gofre.Bookkeeper.Banner.Exporter.cta' },
});

export const actionBarMessages = defineMessages({
  title: { id: 'gofre.Bookkeeper.actionBar.title' },
  customerCardTitle: {
    id: 'gofre.Bookkeeper.actionBar.customerCardTitle',
  },
  customerCardSubTitle: {
    id: 'gofre.Bookkeeper.actionBar.customerCardSubTitle',
  },
  customerCardBanner: {
    id: 'gofre.Bookkeeper.actionBar.customerCardBanner',
  },
  exportCardTitle: {
    id: 'gofre.Bookkeeper.actionBar.exportCardTitle',
  },
  exportCardSubTitle: {
    id: 'gofre.Bookkeeper.actionBar.exportCardSubTitle',
  },
  exportCardSubTitleGeneric: {
    id: 'gofre.Bookkeeper.actionBar.exportCardSubTitleGeneric',
  },
  exportCardCta: {
    id: 'gofre.Bookkeeper.actionBar.exportCardCta',
  },
  exportCardCtaTablet: {
    id: 'gofre.Bookkeeper.actionBar.exportCardCtaTablet',
  },
  exportCardModalTitle: {
    id: 'gofre.Bookkeeper.actionBar.exportCardModalTitle',
  },
  exportCardModalConfirmText: {
    id: 'gofre.Bookkeeper.actionBar.exportCardModalConfirmText',
  },
  AccessCardTitle: {
    id: 'gofre.Bookkeeper.actionBar.AccessCardTitle',
  },
  AccessCardSubtitle: {
    id: 'gofre.Bookkeeper.actionBar.AccessCardSubtitle',
  },
  AccessCardModalTitle: {
    id: 'gofre.Bookkeeper.actionBar.AccessCardModalTitle',
  },
  AccessCardModalConfirmText: {
    id: 'gofre.Bookkeeper.actionBar.AccessCardModalConfirmText',
  },
  AccessCardZeroStateText01: {
    id: 'gofre.Bookkeeper.actionBar.AccessCard.ZeroState.text01',
  },
  AccessCardZeroStateText02: {
    id: 'gofre.Bookkeeper.actionBar.AccessCard.ZeroState.text02',
  },
});

export default messages;
