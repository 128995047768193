import { isEmpty } from 'lodash';

import { Contact } from 'modules/Contacts/models';
import { DocumentDefaultValues } from 'modules/DefaultValues/models/document-default-values';
import { createAdditionalExpense } from 'modules/Documents/helpers/default-additionalExpense';
import { RecurrentDocument } from 'modules/Documents/models/document';
import { NewDocumentForm } from 'modules/Documents/models/form';
import { PersonType } from 'modules/Documents/models/personType';
import { CreatedPaymentMethod } from 'modules/PaymentMethods/models/paymentMethods';

import {
  injectContactWithPaymentMethods,
  processPaymentMethodsFromDocument,
} from './helpers';
import processConcepts from './processConcepts';

const recurrentDocumentValues = ({
  document,
  paymentMethods,
  isCopying,
  savedDefaultValues,
  updatedContact,
  initialCode,
  initialSerialCode,
  initialSerieValue,
}: {
  document: RecurrentDocument;
  paymentMethods?: CreatedPaymentMethod[];
  isCopying?: boolean;
  savedDefaultValues?: DocumentDefaultValues | null;
  updatedContact?: Contact;
  initialSerieValue: string | null;
  initialCode: number;
  initialSerialCode: string;
}): NewDocumentForm => {
  const { documentTemplate, autoSending, sendInfo } = document;

  const documentContactWithUpdatedPaymentMethods =
    injectContactWithPaymentMethods({
      updatedContact,
      documentContact: documentTemplate.contact,
      documentPaymentMethods: document.paymentMethodsAccepted,
    });
  return {
    hasAdditionalExpenses: !isEmpty(documentTemplate.additionalExpenses),
    additionalExpenses: !isEmpty(documentTemplate.additionalExpenses)
      ? documentTemplate.additionalExpenses
      : [createAdditionalExpense()],
    code: documentTemplate.serie?.lastNumber ?? initialCode,
    creationDate: new Date(document.initDateTime),
    dueDate: undefined,
    nextDateTime: new Date(document.nextDateTime),
    initDateTime: isCopying ? new Date() : new Date(document.initDateTime),
    newDocumentPeriod: document.newDocumentPeriod,
    dueDocumentPeriod: document.dueDocumentPeriod,
    concepts: documentTemplate.concepts
      ? processConcepts(documentTemplate.concepts)
      : undefined,
    comments: documentTemplate.comments,
    contact: documentContactWithUpdatedPaymentMethods as Contact,
    hasRetentions: !!documentTemplate.totals?.retentionPercentage,
    showComments: !!documentTemplate.comments,
    hasSalesEqualizationTax: documentTemplate.hasSalesEqualizationTax,
    reference: documentTemplate.reference,
    retentionPercentage: documentTemplate.totals?.retentionPercentage,
    taxAmount: documentTemplate.totals?.taxAmount,
    retentions: documentTemplate.totals?.retentions,
    total: documentTemplate.totals?.total,
    hasPaidAmount: !!documentTemplate.totals?.paidAmount,
    paidAmount: documentTemplate.totals?.paidAmount,
    serialCode: documentTemplate.serie?.serialCode ?? initialSerialCode,
    serieId: documentTemplate.serie?.id ?? initialSerieValue,
    status: undefined,
    isDefaultDraft: false,
    autoSending: sendInfo?.to ? autoSending : false,
    sendInfo: sendInfo?.to ? sendInfo : undefined,
    templateId: document.templateId || savedDefaultValues?.templateId,
    showLinesWithoutTaxes: savedDefaultValues?.showLinesWithoutTaxes,
    // Bai
    operationDescription: documentTemplate.operationDescription || '',
    taxRegime: documentTemplate.taxRegime,
    currency: documentTemplate.currency,
    paymentMethods: processPaymentMethodsFromDocument({
      documentMethods: document.paymentMethodsAccepted,
      defaultMethods: paymentMethods,
      preserveContactMethods: true,
      updatedContact,
    }),
    naturalPerson: savedDefaultValues?.naturalPerson,
    naturalPersonType: savedDefaultValues?.naturalPerson
      ? PersonType.NATURAL
      : PersonType.LEGAL,
    epigraphIAE: savedDefaultValues?.epigraphIAE,
    lastEpigraphsIAE: savedDefaultValues?.lastEpigraphsIAE ?? [],
    showProductReference: savedDefaultValues?.showProductReference,
    category: documentTemplate.category,
    identifierName: document.identifierName,
  };
};
export default recurrentDocumentValues;
