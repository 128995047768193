import { isEmpty } from 'lodash';

const toDateObject = (key: string, date: string) =>
  date ? { [key]: new Date(date) } : {};

export function useQueryParamsFilters(qsParams: URLSearchParams): any {
  const {
    documentKind,
    to,
    from,
    issuedDateTo,
    issuedDateFrom,
    nextInvoiceDateFrom,
    nextInvoiceDateTo,
    ...rest
  } = Object.fromEntries(qsParams);
  const toDate = toDateObject('to', to);
  const fromDate = toDateObject('from', from);
  const issuedDateToDate = toDateObject('issuedDateTo', issuedDateTo);
  const issuedDateFromDate = toDateObject('issuedDateFrom', issuedDateFrom);
  const nextInvoiceDateFromDate = toDateObject(
    'nextInvoiceDateFrom',
    nextInvoiceDateFrom
  );
  const nextInvoiceDateToDate = toDateObject(
    'nextInvoiceDateTo',
    nextInvoiceDateTo
  );

  const filters = {
    ...rest,
    ...toDate,
    ...fromDate,
    ...issuedDateFromDate,
    ...issuedDateToDate,
    ...nextInvoiceDateFromDate,
    ...nextInvoiceDateToDate,
  };
  return !isEmpty(filters) ? filters : undefined;
}
