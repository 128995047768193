import { PersonType } from 'modules/Documents/models/personType';

import { Entity } from '../App/models';
import { DataTableSort } from '../Ui/DataTable/DataTable.models';

export enum TargetDocument {
  EXPENSE = 'EXPENSE',
  QUOTE = 'QUOTE',
  INVOICE = 'INVOICE',
  TEST = 'TEST_INVOICE',
  PROFORMA = 'PROFORMA_INVOICE',
  CORRECTIVE = 'CORRECTIVE',
  DELIVERY_NOTE = 'DELIVERY_NOTE',
}

export interface DocumentSettings {
  showReference?: boolean;
  showQuoteReference?: boolean;
  showProformaReference?: boolean;
  showDeliveryNoteReference?: boolean;
  hasRetentions?: boolean;
  showProductReference?: boolean;
  retentionPercentage?: number;
  showInvoiceComments?: boolean;
  invoiceComments?: string;
  showQuoteComments?: boolean;
  quoteComments?: string;
  showProformaComments?: boolean;
  proformaComments?: string;
  showDeliveryNoteComments?: boolean;
  deliveryNoteComments?: string;
  epigraphIAE?: string;
  lastEpigraphsIAE?: string[];
  taxRegime?: string;
  receiptTaxRegime?: string;
  operationDescription?: string;
  naturalPersonType?: PersonType;
  naturalPerson?: boolean;
  hasCustomIRPF?: boolean;
  chargingCriterionIRPF?: boolean;
  hasSalesEqualizationTax?: boolean;
  showLinesWithoutTaxesInInvoice?: boolean;
  showLinesWithoutTaxesInQuotes?: boolean;
  showLinesWithoutTaxesInProformas?: boolean;
  showLinesWithoutTaxesInDeliveryNotes?: boolean;
}

export interface DocumentSerie extends Entity {
  serialCode: string;
  lastNumber: number;
  initialNumber?: number;
  targetDocument: TargetDocument;
}

export type DocumentSerieSort = DataTableSort<
  DocumentSerie,
  keyof DocumentSerie
>;
