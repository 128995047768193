import { FC, useCallback } from 'react';

import useTranslations from 'modules/I18n/hooks/useTranslations';
import { useCloseModal } from 'modules/Modals/hooks';
import { Button, Text } from 'modules/Ui';

import { dialogConfim } from '../messages';
import { DialogBaseProps } from '../models/types';
import { Dialog } from './Dialog';

interface DialogConfirmProps extends DialogBaseProps {
  onConfirm(close: () => void): void;
  onCancel?(): void;
  cancelText?: string;
  confirmText?: string;
  onCloseClick?(): void;
  text?: string;
  hideSecondary?: boolean;
}

export const DialogConfirm: FC<DialogConfirmProps> = ({
  cancelText,
  children,
  confirmText,
  id,
  onCancel,
  onCloseClick,
  onConfirm,
  subtitle,
  text,
  title,
  hideSecondary,
  ...rest
}) => {
  const closeModal = useCloseModal();
  const { t } = useTranslations();

  const handleCancel = useCallback(() => {
    onCancel?.();
    closeModal();
  }, [closeModal, onCancel]);

  const handleConfirm = useCallback(() => {
    onConfirm(() => {
      closeModal();
    });
  }, [closeModal, onConfirm]);

  const handleOnClose = useCallback(() => {
    onCloseClick?.();
    closeModal();
  }, [closeModal, onCloseClick]);

  return (
    <Dialog
      onClose={handleOnClose}
      {...rest}
      {...{
        id,
        title,
        subtitle,
        text,
        buttonPrimary: (
          <Button
            data-testid={`${id}-submit`}
            variant="secondary"
            onClick={handleConfirm}
          >
            {confirmText || t(dialogConfim.confirm)}
          </Button>
        ),
        buttonSecondary: !hideSecondary && (
          <Button
            data-testid={`${id}-cancel`}
            onClick={handleCancel}
            variant="stroke"
          >
            {cancelText || t(dialogConfim.cancel)}
          </Button>
        ),
      }}
    >
      {text && (
        <Text fontSize={16} lineHeight={22}>
          {text}
        </Text>
      )}
      {children}
    </Dialog>
  );
};
