import ReactAutosizeInput from 'react-input-autosize';

import { typography } from 'styled-system';

import styled from 'modules/Theme/styled-components';
import inputStyles from 'modules/Ui/Form/Commons/styles/inputStyles';

const StyledAutosizeInput = styled(ReactAutosizeInput)`
  ${typography}
  ${inputStyles}
  &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0px 1000px ${(props) => props.theme.colors.gray0}
        inset !important;
    }
    ::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
`;

const AutosizeInput: React.FC<any> = (props) => {
  const { id, onChange, value, addTag, placeholder, ...other } = props;
  return (
    <StyledAutosizeInput
      {...{
        id,
        value,
        placeholder,
        onChange,
        type: 'email',
        ...other,
      }}
    />
  );
};

export default AutosizeInput;
