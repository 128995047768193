import { createElement } from 'react';
import { MessageDescriptor } from 'react-intl';

import useTranslations, {
  TranslationFunction,
} from 'modules/I18n/hooks/useTranslations';
import { useOpenModal } from 'modules/Modals';
import { ModalTypes } from 'modules/Modals/constants';
import useNotifications from 'modules/Notifications/hooks/useNotifications';
import useCreatePaymentMethod from 'modules/PaymentMethods/hooks/useCreatePaymentMethod';
import useUpdatePaymentMethod from 'modules/PaymentMethods/hooks/useUpdatePaymentMethod';
import { PaymentMethodType } from 'modules/PaymentMethods/models/paymentMethods';
import { Box } from 'modules/Ui';
import { ResponseError } from 'types/response-error.type';
import { requestErrorHandlerExtra } from 'utils/requestErrorHandler';

import { remittanceNotifications } from '../messages';

type RemittanceResponseErrorContactInvalidPaymentMethod = Array<{
  contactId: string;
  fiscalName: string;
  documentId: string;
  paymentMethodId: string;
}>;

export const useUpdateContactPaymentMethod = (options?: {
  messages?: {
    invalidContactPaymentMethods: MessageDescriptor;
    contactModalSubtitle: MessageDescriptor;
  };
}) => {
  const { messages } = options || {};
  const {
    t,
    formatHelpers: { br },
  } = useTranslations();
  const openModal = useOpenModal();
  const notifications = useNotifications();
  const onSuccess = () => {
    notifications.success(
      remittanceNotifications.invalidContactPaymentMethodsUpdateCreateSuccess.id
    );
  };
  const { mutate: updateContactPaymentMethod } = useUpdatePaymentMethod({
    onSuccess,
  });
  const { mutate: createContactPaymentMethod } = useCreatePaymentMethod({
    onSuccess,
  });

  return (error: ResponseError): void => {
    const extra: RemittanceResponseErrorContactInvalidPaymentMethod =
      requestErrorHandlerExtra(error);
    if (extra?.length === 1) {
      const contact = extra[0];
      const notificationMessage =
        messages?.invalidContactPaymentMethods ||
        remittanceNotifications.invalidContactPaymentMethods;
      notifications.warning(notificationMessage.id, {
        ttl: 5000,
        title: t(remittanceNotifications.invalidContactPaymentMethodsTitle, {
          name: contact.fiscalName,
        }),
        ctaTranslationKey:
          remittanceNotifications.invalidContactPaymentMethodsCTA.id,
        onCtaClick: () =>
          openModal({
            type: ModalTypes.REMITTANCE_CONTACT_MODAL,
            messages: messages?.contactModalSubtitle
              ? { subtitle: messages.contactModalSubtitle }
              : undefined,
            onSubmit: async (contactIBAN: string) => {
              const newPaymentMethod = {
                contactId: contact.contactId,
                type: PaymentMethodType.DIRECT_DEBIT,
                futureDocuments: true,
                data: {
                  IBAN: contactIBAN,
                },
              };
              if (contact?.paymentMethodId) {
                await updateContactPaymentMethod({
                  ...newPaymentMethod,
                  id: contact.paymentMethodId,
                });
              } else {
                await createContactPaymentMethod(newPaymentMethod);
              }
            },
          }),
      });
    }
    if (extra.length > 1) {
      const fiscalNames = getFiscalNamesFromExtras(t, extra);
      const message = t(remittanceNotifications.invalidContactsPaymentMethods, {
        names: fiscalNames,
        strong: (chunk) =>
          createElement(Box, {
            fontWeight: '600',
            tag: 'strong',
            children: chunk,
          }),
        br,
      });

      notifications.warning(message, {
        ttl: 5000,
        messageKey: 'invalid-contact-paymement-method',
      });
    }
  };
};

const getFiscalNamesFromExtras = (
  t: TranslationFunction,
  extras: RemittanceResponseErrorContactInvalidPaymentMethod
) => {
  if (extras.length === 1) {
    return extras[0].fiscalName;
  }

  const lastContact = extras[extras.length - 1];
  const fiscalNames = extras
    .slice(0, extras.length - 1)
    .map((contact) => contact.fiscalName);

  let response = '';
  if (fiscalNames.length < 3) {
    response += fiscalNames.join(', ');
    response = response.concat(
      t(remittanceNotifications.invalidContactsPaymentMethodsEndWithName, {
        name: lastContact?.fiscalName,
      })
    );
  } else {
    response += fiscalNames.slice(0, 2).join(', ');
    response = response.concat(
      t(remittanceNotifications.invalidContactsPaymentMethodsEndWithNumber, {
        number: extras?.length - 2,
      })
    );
  }

  return response;
};
