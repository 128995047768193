const provinces = [
  {
    value: 'ES-C',
    label: 'A Coruña',
  },
  {
    value: 'ES-VI',
    label: 'Álava',
  },
  {
    value: 'ES-AB',
    label: 'Albacete',
  },
  {
    value: 'ES-A',
    label: 'Alicante',
  },
  {
    value: 'ES-AL',
    label: 'Almería',
  },
  {
    value: 'ES-O',
    label: 'Asturias',
  },
  {
    value: 'ES-AV',
    label: 'Ávila',
  },
  {
    value: 'ES-BA',
    label: 'Badajoz',
  },
  {
    value: 'ES-PM',
    label: 'Balears, Illes',
  },
  {
    value: 'ES-B',
    label: 'Barcelona',
  },
  {
    value: 'ES-BI',
    label: 'Bizkaia',
  },
  {
    value: 'ES-BU',
    label: 'Burgos',
  },
  {
    value: 'ES-CC',
    label: 'Cáceres',
  },
  {
    value: 'ES-CA',
    label: 'Cádiz',
  },
  {
    value: 'ES-S',
    label: 'Cantabria',
  },
  {
    value: 'ES-CS',
    label: 'Castellón',
  },
  {
    value: 'ES-CE',
    label: 'Ceuta',
  },
  {
    value: 'ES-CR',
    label: 'Ciudad Real',
  },
  {
    value: 'ES-CO',
    label: 'Córdoba',
  },
  {
    value: 'ES-CU',
    label: 'Cuenca',
  },
  {
    value: 'ES-SS',
    label: 'Gipuzkoa',
  },
  {
    value: 'ES-GI',
    label: 'Girona',
  },
  {
    value: 'ES-GR',
    label: 'Granada',
  },
  {
    value: 'ES-GU',
    label: 'Guadalajara',
  },
  {
    value: 'ES-H',
    label: 'Huelva',
  },
  {
    value: 'ES-HU',
    label: 'Huesca',
  },
  {
    value: 'ES-J',
    label: 'Jaén',
  },
  {
    value: 'ES-LO',
    label: 'La Rioja',
  },
  {
    value: 'ES-GC',
    label: 'Las Palmas',
  },
  {
    value: 'ES-LE',
    label: 'León',
  },
  {
    value: 'ES-L',
    label: 'Lleida',
  },
  {
    value: 'ES-LU',
    label: 'Lugo',
  },
  {
    value: 'ES-M',
    label: 'Madrid',
  },
  {
    value: 'ES-MA',
    label: 'Málaga',
  },
  {
    value: 'ES-ML',
    label: 'Melilla',
  },
  {
    value: 'ES-MU',
    label: 'Murcia',
  },
  {
    value: 'ES-NA',
    label: 'Navarra',
  },
  {
    value: 'ES-OR',
    label: 'Ourense',
  },
  {
    value: 'ES-P',
    label: 'Palencia',
  },
  {
    value: 'ES-PO',
    label: 'Pontevedra',
  },
  {
    value: 'ES-SA',
    label: 'Salamanca',
  },
  {
    value: 'ES-TF',
    label: 'Santa Cruz de Tenerife',
  },
  {
    value: 'ES-SG',
    label: 'Segovia',
  },
  {
    value: 'ES-SE',
    label: 'Sevilla',
  },
  {
    value: 'ES-SO',
    label: 'Soria',
  },
  {
    value: 'ES-T',
    label: 'Tarragona',
  },
  {
    value: 'ES-TE',
    label: 'Teruel',
  },
  {
    value: 'ES-TO',
    label: 'Toledo',
  },
  {
    value: 'ES-V',
    label: 'Valencia',
  },
  {
    value: 'ES-VA',
    label: 'Valladolid',
  },
  {
    value: 'ES-ZA',
    label: 'Zamora',
  },
  {
    value: 'ES-Z',
    label: 'Zaragoza',
  },
];

export interface Provinces {
  [key: string]: string;
}

export default provinces;
