import { useCallback, useRef, useState } from 'react';

import useCallbackOnClickOutside from './useCallbackOnClickOutside';

const useClosesDropDownOnClickOutside = (initiallyOpen = false) => {
  const ref = useRef(null);
  const [open, setOpen] = useState(initiallyOpen);
  const openDropDown = useCallback(() => setOpen(true), [setOpen]);
  const closeDropDown = useCallback(() => setOpen(false), [setOpen]);
  const toggleDropDown = useCallback(() => setOpen(!open), [setOpen, open]);
  useCallbackOnClickOutside(ref, closeDropDown);

  return {
    openDropDown,
    closeDropDown,
    open,
    ref,
    toggleDropDown,
  };
};

export default useClosesDropDownOnClickOutside;
