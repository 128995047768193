import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  blacklistFound: {
    id: 'gofre.PaymentMethods.create.blacklistFound',
  },
  updateSuccess: {
    id: 'gofre.PaymentMethods.update.success',
  },
  createSuccess: {
    id: 'gofre.PaymentMethods.create.success',
  },
});
