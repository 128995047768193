import {
  space,
  layout,
  flexbox,
  LayoutProps,
  FlexboxProps,
  SpaceProps,
  GridAreaProps,
} from 'styled-system';

import styled from 'modules/Theme/styled-components';
import Box from 'modules/Ui/Box';
import { HTMLInputElementLayoutProps } from 'modules/Ui/Html/Input';

import Textarea from '../../Html/Textarea';
import Description from '../Commons/Description';
import Label from '../Commons/Label';
import LabelText from '../Commons/LabelText';
import textAreaStyles from '../Commons/styles/textAreaStyles';

interface TextAreaProps
  extends HTMLInputElementLayoutProps,
    LayoutProps,
    FlexboxProps,
    GridAreaProps,
    SpaceProps {
  // autogrowing requires maxHeight prop
  autogrowing?: boolean;
  autoSize?: boolean;
  error?: string;
  gridArea?: {} | string;
  gridColumnEnd?: {} | string;
  gridColumnStart?: {} | string;
  gridRow?: {} | string;
  helpText?: string;
  label: string;
  labelHidden?: boolean;
  descriptionHidden?: boolean;
  maxLength?: number;
  maxHeight?: string;
}
const Autogrowing = styled(Box)``;

const TextAreaInput = styled(Textarea)<TextAreaProps>`
  ${layout}
  ${flexbox}
  ${space}
  ${textAreaStyles}
`;

const TextArea = (props: TextAreaProps) => {
  const {
    autoSize,
    autogrowing,
    disabled,
    display,
    error,
    gridArea,
    gridColumnEnd,
    gridColumnStart,
    gridRow,
    helpText,
    id,
    label,
    labelHidden,
    descriptionHidden,
    margin,
    maxLength,
    order,
    required,
    width,
    maxHeight,
  } = props;

  const autosize = () => {
    const autoSizeElement = document.getElementById(props.id || '');
    if (autoSizeElement) {
      autoSizeElement.style.cssText = 'height:64px; padding:0';
      autoSizeElement.style.cssText = `height:${autoSizeElement.scrollHeight}px`;
    }
  };

  return (
    <Label
      display={display}
      gridArea={gridArea}
      gridColumnEnd={gridColumnEnd}
      gridColumnStart={gridColumnStart}
      gridRow={gridRow}
      id={id}
      margin={margin}
      order={order}
      width={width}
    >
      <LabelText
        hidden={labelHidden}
        {...{ id: `label-${id}`, required, disabled }}
      >
        {label}
      </LabelText>
      <Autogrowing tag="span" display="block">
        <TextAreaInput
          aria-invalid={error && 'true'}
          autoSize={autoSize}
          display="block"
          maxLength={maxLength}
          maxHeight={maxHeight}
          onKeyDown={autogrowing && autosize()}
          {...{
            autogrowing,
            disabled,
            error,
            ...props,
          }}
          width="100%"
        />
      </Autogrowing>
      {!descriptionHidden && (
        <Description id={`description-${id}`} hasError={!!error}>
          {error ?? helpText}
        </Description>
      )}
    </Label>
  );
};

export default TextArea;
