import { defineMessages } from 'react-intl';

export const scanDocument = defineMessages({
  scanningDocument: {
    id: 'gofre.Document.Scan.scanning-document',
  },
  scannedDocument: {
    id: 'gofre.Document.Scan.scanned-document',
  },
});
