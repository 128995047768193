import { useEffect, useState } from 'react';

import downloadDocument, {
  buildDocumentUrl,
} from '../List/services/downloadDocument';
import { Document, DocumentType } from '../models/document';

interface Result {
  url: string;
  loading: boolean;
  contentType: string;
  data: string;
  contentLength?: string;
  fileName?: string;
}

const initData: Result = {
  url: '',
  loading: false,
  contentType: '',
  data: '',
};

/**
 * Download file attached to existing document
 * @param document
 * @returns
 */
const useDocumentBlobFileUrl = (
  document?: Document,
  useHeadersOnly: boolean = false
): Result => {
  const [result, setResult] = useState<Result>(initData);
  const downloadAttachedFile =
    document?.documentType !== DocumentType.QUOTE &&
    document?.documentType !== DocumentType.PROFORMA &&
    document?.documentType !== DocumentType.DELIVERY_NOTE;

  useEffect(() => {
    async function fetchDocumentFile() {
      if (document) {
        try {
          setResult({
            ...result,
            loading: true,
          });
          const { data, contentType, contentLength, fileName } =
            await downloadDocument({
              id: document?.id,
              hasFile: document?.hasFile,
              headersOnly: useHeadersOnly,
              downloadAttachedFile,
            });
          const file = new Blob([data], { type: contentType });
          const objectUrl = document?.hasFile
            ? URL.createObjectURL(file)
            : await buildDocumentUrl(document?.id, {
                hasFile: document?.hasFile,
                updateAt: document?.updateAt,
              });
          setResult({
            url: objectUrl,
            loading: false,
            contentType,
            data,
            contentLength,
            fileName,
          });
        } catch (e) {
          setResult(initData);
        }
      }
    }

    fetchDocumentFile();
  }, [document?.id, document?.hasFile, document?.updateAt]);

  return result;
};

export default useDocumentBlobFileUrl;
