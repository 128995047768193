import { Address } from 'modules/Address';
import { Provinces } from 'modules/Address/models/provinces';
import { CompanySize } from 'modules/Bookkeeper/models/company-size';
import { BookkeeperSoftwareTypes } from 'modules/Bookkeeper/models/sofware-type';
import { Subject } from 'modules/Subject/models/models';
import { Role } from 'modules/Users/models/role';

import { Categories, Sectors } from '../Create/models/types';

export interface UserData {
  id: string;
  fiscalName?: string;
  name?: string;
  surname?: string;
  email: string;
}

export enum RegisteredFrom {
  mobileBrowser = 'browser mobile',
  desktopBrowser = 'browser desktop',
  app = 'app',
}

export interface User {
  role: Role;
  business: {
    id: string;
    fiscalName: string;
    vatNumber: string;
    type?: string;
  };
  user: UserData;
}

export enum BusinessType {
  BOOKKEEPING = 'bookkeeping',
}

interface Business extends Subject {
  vatNumber: string;
  address: Address;
  campaign?: BusinessCampaign;
  createdAt?: string;
  role?: Role;
  isDummy?: boolean;
  users?: User[];
  electronicDocumentProvider?: ElectronicDocumentProviders;
  billingEmail?: string;
  type?: BusinessType;
  bookkeepingData?: BookkeepingData;
  sendElectronicDocument?: boolean;
  subscription?: BusinessSubscription;
  companySize?: CompanySize;
  software?: BookkeeperSoftwareTypes;
  shortDescription?: string;
  email?: string;
  phoneNumber?: string;
  showWelcome?: boolean;
  webPage?: string;
  uploadEmail?: string;
  partner?: string;
  sector?: Sectors;
  category?: Categories;
}

export interface BookkeepingData {
  lastDocument?: string;
  lastExportStartPeriod?: string;
  lastExportEndPeriod?: string;
  salesCode?: string;
  newDocuments?: number;
  clientCode?: string;
}

export interface BusinessCampaign {
  active: boolean;
  code: string;
  description?: string;
}

export interface BusinessSubscription {
  active: boolean;
  endDate: string;
  nextPaymentDate: string;
  provider: string;
  marketName: string;
}

interface BusinessUpdateAddress {
  postalAddress: string | null;
  postalCode: string | null;
  province: keyof Provinces;
  country: string;
  city: string | null;
}

export interface BusinessUpdate extends Subject {
  vatNumber: string;
  address: BusinessUpdateAddress;
  phoneNumber?: string | null;
  email?: string | null;
  electronicDocumentProvider?: ElectronicDocumentProviders | null;
  showWelcome?: boolean;
  sector?: string;
  category?: string;
}

export enum BaiProviders {
  BI = 'BAI-ES-BI',
  SS = 'BAI-ES-SS',
  VI = 'BAI-ES-VI',
}

export type ElectronicDocumentProviders =
  | BaiProviders.BI
  | BaiProviders.SS
  | BaiProviders.VI;

export interface CreatedBusiness extends Business {
  id: string;
}

export default Business;
