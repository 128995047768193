import { useCallback } from 'react';
import { MessageDescriptor } from 'react-intl';
import { useSelector } from 'react-redux';

import { hasElectronicDocumentProvider } from 'modules/Business/businessUtils';
import { selectCurrentBusiness } from 'modules/Business/Current/selectors';
import { StatusInfoUI } from 'modules/Documents/models/statusInfo';
import useTranslations from 'modules/I18n/hooks/useTranslations';
import { PaymentFilters, PaymentStatusEnum } from 'modules/Payments/models';
import TagListUI from 'modules/Ui/TagList/TagListUI';
import formatDate from 'utils/dates/formatDate';

import { tags } from '../../../messages';

interface TagsProps {
  filters: PaymentFilters;
  onChangeSearch: (filters: PaymentFilters) => void;
}

interface TagElement {
  filterKey: keyof PaymentFilters;
  formattedValue?: string;
}

const TagList: React.FC<TagsProps> = ({ onChangeSearch, filters }) => {
  const business = useSelector(selectCurrentBusiness);
  const isBai = hasElectronicDocumentProvider(business);

  const onClearTermClick = useCallback(
    (key: string) => {
      const newFilters = {
        ...filters,
        [key]: undefined,
      };
      onChangeSearch(newFilters);
    },
    [onChangeSearch, filters]
  );
  const { t } = useTranslations();

  function formatStatusInfo(statusInfo?: StatusInfoUI): TagElement | null {
    if (!statusInfo) return null;
    const values: Partial<Record<StatusInfoUI, string>> = {
      [StatusInfoUI.PAID]: t(tags.paid),
      [StatusInfoUI.PARTIALLY_PAID]: t(tags.partiallyPaid),
    };
    return {
      filterKey: 'paidStatus',
      formattedValue: values[statusInfo],
    };
  }

  function formatLinked(
    paymentStatusInfo?: PaymentStatusEnum
  ): TagElement | null {
    if (!paymentStatusInfo) return null;
    const values: Partial<Record<PaymentStatusEnum, string>> = {
      [PaymentStatusEnum.LINKED]: t(tags.linked),
      [PaymentStatusEnum.NOT_LINKED]: t(tags.notLinked),
    };
    return {
      filterKey: 'linked',
      formattedValue: values[paymentStatusInfo],
    };
  }

  function formatFromToDate(
    filterKey: keyof PaymentFilters,
    translationKey: MessageDescriptor,
    date?: string
  ): TagElement | null {
    if (!date) return null;
    return {
      filterKey,
      formattedValue: t(translationKey, { date: formatDate(date) }),
    };
  }

  function formatAmount(
    filterKey: keyof PaymentFilters,
    translationKey: MessageDescriptor,
    amount?: number | string
  ): TagElement | null {
    if (amount === undefined || amount === '' || amount === undefined) {
      return null;
    }
    return {
      filterKey,
      formattedValue: t(translationKey, { amount }),
    };
  }

  const minAmountTag = isBai ? tags.minAmountBai : tags.minAmount;
  const maxAmountTag = isBai ? tags.maxAmountBai : tags.maxAmount;

  const formattedFilters: TagElement[] = [
    formatLinked(filters.linked),
    formatStatusInfo(filters.paidStatus),
    formatFromToDate('dateFrom', tags.dateFrom, filters.dateFrom),
    formatFromToDate('dateTo', tags.dateTo, filters.dateTo),
    formatAmount('minAmount', minAmountTag, filters.minAmount),
    formatAmount('maxAmount', maxAmountTag, filters.maxAmount),
  ].filter((e): e is TagElement => e !== null && e !== undefined);

  const isEmptyFilters = formattedFilters.length === 0;

  return isEmptyFilters ? null : (
    <TagListUI
      {...{
        formattedFilters,
        onClearTermClick,
        tags,
      }}
    />
  );
};

export default TagList;
