import { useElements, useStripe } from '@stripe/react-stripe-js';

import { LoadingIndicator } from 'modules/Ui';

export const LoadingStripeIndicator: React.FC = () => {
  const stripe = useStripe();
  const elements = useElements();

  if (!stripe || !elements) {
    return <LoadingIndicator />;
  }

  return null;
};
