import { IConfigCatClient } from 'configcat-common';
import * as configCat from 'configcat-js';

import { getFeatureFlagIdentifiers } from 'modules/App/featureFlagUtils';
import { getCurrentBusinessId } from 'modules/Auth/currentBusinessUtil';
import { featureFlagSetEventId } from 'utils/storage';

import { getFlagValue } from '../helpers/featureFlags.helpers';
import { FeatureFlagsSource, Flags } from '../model';
import BaseSource from './BaseSource';

interface ConfigCatSourceProps {
  options: ConfigCatInitProps;
}

interface ConfigCatInitProps {
  SDKKey: string;
  defaultFlags?: Flags;
  pollIntervalSeconds?: number;
}

interface CacheValue {
  key: string;
  value: boolean | string | undefined;
}

export default class ConfigCatSource
  extends BaseSource
  implements FeatureFlagsSource
{
  configCatClient?: IConfigCatClient;

  SDKKey: string;

  defaultFlags: Flags;

  pollIntervalSeconds: number;

  flags: Flags;

  constructor(props: ConfigCatSourceProps) {
    super();
    const { options } = props;
    const { SDKKey, defaultFlags, pollIntervalSeconds } = options;

    this.SDKKey = SDKKey;
    this.defaultFlags = defaultFlags || {};
    this.pollIntervalSeconds = pollIntervalSeconds || 3600;
    this.flags = {};
    document.addEventListener(
      featureFlagSetEventId,
      this.updateSourceFlags.bind(this),
      false
    );
  }

  init() {
    this.initClient();
  }

  initClient(client?: IConfigCatClient) {
    this.configCatClient =
      client ||
      configCat.createClientWithAutoPoll(this.SDKKey, {
        pollIntervalSeconds: this.pollIntervalSeconds,
        configChanged: () => {
          this.updateSourceFlags();
        },
      });

    this.updateSourceFlags();
  }

  async getCacheValues(): Promise<CacheValue[]> {
    const keys: string[] =
      (await this.configCatClient?.getAllKeysAsync()) || [];
    return Promise.all(
      keys.map(async (key) => {
        const value = await this.configCatClient?.getValueAsync(
          key,
          this.defaultFlags[key],
          ConfigCatSource.prepareUserObject()
        );
        return { key, value };
      })
    );
  }

  static prepareUserObject() {
    const ffIdentifiers = getFeatureFlagIdentifiers();
    const businessId = ffIdentifiers?.businessId ?? getCurrentBusinessId();
    return {
      identifier: businessId || '',
      custom: {
        createdAt: (ffIdentifiers?.createdAt ?? Date.now()).toString(),
      },
    };
  }

  async buildFlags(): Promise<Flags> {
    const cacheValues = await this.getCacheValues();
    return cacheValues.reduce((flags: Flags, cacheValue: CacheValue) => {
      const { key, value } = cacheValue;
      return {
        ...flags,
        [key]: typeof value === 'string' ? getFlagValue(value) : !!value,
      };
    }, {});
  }

  async updateSourceFlags() {
    this.flags = await this.buildFlags();
    this.update(this.flags);
  }

  async getFlags() {
    return this.flags;
  }
}
