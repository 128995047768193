import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import {
  DocumentKindQueryParamsState,
  ExpensesQueryParamsState,
} from 'modules/Documents/List/types';
import {
  DocumentFilters,
  DocumentKind,
  DocumentStatus,
} from 'modules/Documents/models/document';
import { ExpenseTab } from 'modules/Documents/models/expenses';
import { StatusInfoUI } from 'modules/Documents/models/statusInfo';
import { useFilters } from 'modules/Filters/hooks/useFilters';
import { TabQueryParamsState } from 'modules/Filters/types';
import { Remittance } from 'modules/Remittances/types';
import { PAYMENT_ROUTES } from 'pages/payments/routesMap';
import useQueryParams from 'utils/router/useQueryParams';
import { useQueryParamsFilters } from 'utils/router/useQueryParamsFilters';

interface RemittanceDocumentsFilters {
  closeFilters: () => void;
  extraQuery: string;
  filtersActive: boolean;
  filtersDisabled: boolean;
  onClickFilterButton: () => void;
  queryParamsState: DocumentKindQueryParamsState | ExpensesQueryParamsState;
  setSearchTerm: (searchTerm: string) => void;
  setFiltersDisabled: (disabled: boolean) => void;
  updateQueryParams: (event: DocumentFilters) => void;
  searchTerm: string;
}

export const useRemittanceDocumentsFilters = (options: {
  isDirectDebit: boolean;
  isRemittanceInState?: boolean;
  includedDocumentsIds?: string[];
}): RemittanceDocumentsFilters => {
  const {
    isDirectDebit,
    includedDocumentsIds,
    isRemittanceInState = true,
  } = options;
  const location = useLocation<Remittance>();
  const history = useHistory();
  const query = useQueryParams();
  const queryParamsFromUrl = useQueryParamsFilters(query);
  const hasMinAmount = queryParamsFromUrl?.minAmount;

  const {
    currentTab: creditTab,
    queryParamsState: creditQueryParamsState,
    setQueryParamsState: setCreditQueryParamsState,
    extraQuery: extraCreditQuery,
  } = useGetCreditFiltersOptions({
    hasMinAmount,
    queryParamsFromUrl,
    includedDocumentsIds,
  });

  const {
    currentTab: debitTab,
    queryParamsState: debitQueryParamsState,
    setQueryParamsState: setDebitQueryParamsState,
    extraQuery: extraDebitQuery,
  } = useGetDebitFiltersOptions({
    hasMinAmount,
    queryParamsFromUrl,
    includedDocumentsIds,
  });

  const {
    filtersActive,
    filtersDisabled,
    closeFilters,
    toggleFilters,
    searchTerm,
    setSearchTerm,
    updateQueryParams,
    setFiltersDisabled,
    saveFiltersToQueryParams,
  } = useFilters({
    currentTab: isDirectDebit ? debitTab : creditTab,
    queryParamsState: isDirectDebit
      ? debitQueryParamsState
      : creditQueryParamsState,
    setQueryParamsState: isDirectDebit
      ? setDebitQueryParamsState
      : setCreditQueryParamsState,
  });

  useEffect(() => {
    if (isDirectDebit) {
      saveFiltersToQueryParams(debitTab, {
        event: debitQueryParamsState[debitTab],
      });
    } else {
      saveFiltersToQueryParams(creditTab, {
        event: creditQueryParamsState[creditTab],
      });
    }

    if (!location.state && isRemittanceInState) {
      history.push(PAYMENT_ROUTES.REMITTANCES);
    }
  }, []);

  return {
    closeFilters,
    extraQuery: isDirectDebit ? extraDebitQuery : extraCreditQuery,
    filtersActive,
    filtersDisabled,
    onClickFilterButton: toggleFilters,
    queryParamsState: isDirectDebit
      ? debitQueryParamsState
      : creditQueryParamsState,
    searchTerm,
    setSearchTerm,
    setFiltersDisabled,
    updateQueryParams,
  };
};

export const useGetCreditFiltersOptions = (options: {
  hasMinAmount: boolean;
  queryParamsFromUrl: unknown;
  includedDocumentsIds?: string[];
}): {
  currentTab: ExpenseTab.UPLOADED;
  queryParamsState: ExpensesQueryParamsState;
  setQueryParamsState: (state: TabQueryParamsState<DocumentFilters>) => void;
  extraQuery: string;
} => {
  const { hasMinAmount, queryParamsFromUrl } = options;
  const creditTab = ExpenseTab.UPLOADED;
  let extraCreditQuery = !hasMinAmount ? `&query[totals][total][$gte]=0` : ''; // Sean en positivo.
  extraCreditQuery += `&query[paidStatus][$nin][0]=${StatusInfoUI.PAID}`;
  if (options.includedDocumentsIds?.length) {
    extraCreditQuery += translateIdsToNotInQuery(options.includedDocumentsIds);
  }

  const initialCreditQueryParamsState = queryParamsFromUrl
    ? { [creditTab]: queryParamsFromUrl }
    : { [ExpenseTab.UPLOADED]: {} };

  const [creditQueryParamsState, setCreditQueryParamsState] =
    useState<ExpensesQueryParamsState>(initialCreditQueryParamsState);
  return {
    currentTab: creditTab,
    queryParamsState: creditQueryParamsState,
    setQueryParamsState: setCreditQueryParamsState,
    extraQuery: extraCreditQuery,
  };
};

export const useGetDebitFiltersOptions = (options: {
  hasMinAmount: boolean;
  queryParamsFromUrl: unknown;
  includedDocumentsIds?: string[];
}): {
  currentTab: DocumentKind.NORMAL;
  queryParamsState: DocumentKindQueryParamsState;
  setQueryParamsState: (state: TabQueryParamsState<DocumentFilters>) => void;
  extraQuery: string;
} => {
  const { hasMinAmount, queryParamsFromUrl } = options;

  const debitTab = DocumentKind.NORMAL;
  let extraDebitQuery = !hasMinAmount ? `&query[totals][total][$gte]=0` : ''; // Sean en positivo.
  extraDebitQuery += `&query[paidStatus][$nin][0]=${StatusInfoUI.PAID}`;
  extraDebitQuery += `&query[status][$nin][0]=${DocumentStatus.VOID}&query[status][$nin][1]=${DocumentStatus.CORRECTED}&query[status][$nin][2]=${DocumentStatus.TEST}`;
  extraDebitQuery += `&query[receiptStatus]`;
  if (options.includedDocumentsIds?.length) {
    extraDebitQuery += translateIdsToNotInQuery(options.includedDocumentsIds);
  }

  const initialDebitQueryParamsState = queryParamsFromUrl
    ? { [debitTab]: queryParamsFromUrl }
    : { [DocumentKind.NORMAL]: {} };

  const [debitQueryParamsState, setDebitQueryParamsState] =
    useState<DocumentKindQueryParamsState>(initialDebitQueryParamsState);
  return {
    currentTab: debitTab,
    queryParamsState: debitQueryParamsState,
    setQueryParamsState: setDebitQueryParamsState,
    extraQuery: extraDebitQuery,
  };
};

function translateIdsToNotInQuery(ids: string[]): string {
  return ids.reduce((acc, id, i) => `${acc}&query[id][$nin][${i}]=${id}`, '');
}
