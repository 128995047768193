import { FC } from 'react';

import useTheme from 'modules/Theme/hooks/useTheme';
import { Box } from 'modules/Ui';

interface LabelTextProps {
  hasError?: boolean;
  isSuccess?: boolean;
  hide?: boolean;
  id?: string;
  textAlign?: string;
}

const color = (hasError: boolean, isSuccess: boolean) => {
  if (hasError) return 'error';
  if (isSuccess) return 'confirmation';
  return 'primary300';
};

const Description: FC<LabelTextProps> = ({
  children,
  hasError,
  hide,
  id,
  isSuccess,
  textAlign,
}) => {
  const theme = useTheme();
  return (
    <Box
      id={id}
      hasError={hasError}
      aria-hidden="true"
      boxSizing="border-box"
      color={color(!!hasError, !!isSuccess)}
      display="block"
      fadeIn
      fontSize={children ? theme.fontSizes[12] : undefined}
      lineHeight={children ? theme.lineHeights[16] : undefined}
      minHeight={hide ? undefined : '24px'}
      paddingTop={children && '2px'}
      tag="span"
      textAlign={textAlign}
      data-testid={id}
    >
      {children}
    </Box>
  );
};

export default Description;
