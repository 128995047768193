import * as Yup from 'yup';

import { TranslationFunction } from 'modules/I18n/hooks/useTranslations';
import creditCardExpiration from 'utils/regex/creditCardExpiration';

import { formText } from '../../messages';
import {
  ALIAS_MIN_LENGTH,
  ALIAS_MAX_LENGTH,
  CREDIT_CARD_EXPIRE_DATE,
  CREDIT_CARD_NUMBER_LENGTH,
} from '../AccountingAccountModal/constants';

export const getValidationSchema = (t: TranslationFunction) =>
  Yup.object().shape({
    alias: Yup.string()
      .required(t(formText.mandatory))
      .min(ALIAS_MIN_LENGTH, t(formText.aliasFormatValidation))
      .max(ALIAS_MAX_LENGTH, t(formText.aliasFormatValidationMax)),
    creditCardNumber: Yup.string().length(
      CREDIT_CARD_NUMBER_LENGTH,
      t(formText.creditCardNumberLength)
    ),
    creditCardExpireDate: Yup.string()
      .max(CREDIT_CARD_EXPIRE_DATE, t(formText.creditCardValidation))
      .matches(creditCardExpiration, t(formText.creditCardValidation))
      .test(
        'credit-card-expiration-date',
        t(formText.creditCardValidation),
        (expirationDate) => {
          if (!expirationDate) {
            return true;
          }
          const [expMonth] = expirationDate.split('/');

          return Number(expMonth) <= 12;
        }
      ),
  });
