import { FC } from 'react';

import {
  layout,
  border,
  BorderProps,
  borderColor,
  gridTemplateColumns,
  GridTemplateColumnsProps,
} from 'styled-system';

import styled from 'modules/Theme/styled-components';
import Box, { BoxProps } from 'modules/Ui/Box/Box';

import inputWrapperStyles from '../styles/inputWrapperStyles';

interface InputWrapperProps
  extends BoxProps,
    BorderProps,
    GridTemplateColumnsProps {
  disabled?: boolean;
  error?: string;
  hasIcon?: boolean;
  iconPosition?: 'start' | 'end';
}

export const StyledInputWrapper = styled(Box)`
  ${inputWrapperStyles}
  ${gridTemplateColumns}
  ${layout}
  ${border}
  ${gridTemplateColumns}
  svg {
    height: 20px;
    width: 20px;
  }
`;

export const InputWrapper: FC<InputWrapperProps> = ({
  children,
  className,
  disabled,
  error,
  hasIcon,
  iconPosition = 'end',
  ...rest
}) => {
  return (
    <StyledInputWrapper
      alignItems={hasIcon ? 'center' : undefined}
      borderColor={borderColor}
      className={className}
      disabled={disabled}
      display={hasIcon ? 'grid' : 'block'}
      gridTemplateColumns={iconPosition === 'start' ? '20px 1fr' : '1fr 20px'}
      padding={iconPosition ? '6px 8px 6px 12px' : '6px 8px 6px'}
      error={error}
      columnGap={hasIcon ? '6px' : undefined}
      tag="span"
      {...rest}
    >
      {children}
    </StyledInputWrapper>
  );
};

StyledInputWrapper.defaultProps = {
  height: '44px',
  padding: '8px 8px 8px 12px',
};

export default InputWrapper;
