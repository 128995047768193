import { calculateTotalsFromConceptsTaxbasesV2 } from './calculate-totals-from-concepts-taxbases-v2';
import { calculateTotalsFromConceptsV1 } from './calculate-totals-from-concepts-v1';
import { calculateTotalsFromConceptsV2 } from './calculate-totals-from-concepts-v2';
import {
  CalculateTotalsFromConceptsOpts,
  TotalsFromConcepts,
  TortillaConcept as Concept,
} from './types';

/*
  IF YOU CHANGE ANYTHING BELOW THIS COMMENT YOU NEED TO DO THE SAME CHANGE IN GOFRE / TORTILLA
  WE ROUND TO 3 DECIMAL PLACES SO WE DONT LOSE PRECISION ON THE SECOND DECIMAL PLACE
  THE OBJECTIVE OF IS THAT THE TOTALS MUST ADD PERFECTLY, SUBTOTAL + TAX = TOTAL
  WE ARE FINE LOOSING SOME TRAILING CENTS IN THE SUM OF THE CONCEPTS
 */

export function calculateTotalsFromConcepts(
  concepts: Concept[],
  options?: CalculateTotalsFromConceptsOpts
): TotalsFromConcepts {
  const { showLinesWithoutTaxes = false } = options ?? {};
  if (showLinesWithoutTaxes) {
    return calculateTotalsFromConceptsTaxbasesV2(concepts);
  }
  return canUseV2Calcs(concepts[0])
    ? calculateTotalsFromConceptsV2(concepts)
    : calculateTotalsFromConceptsV1(concepts);
}

function canUseV2Calcs(concept: Concept) {
  return concept?.totalAmount != null;
}
