import IconByString, { IconByStringProps } from './Icon/IconType/IconByString';

const IconCamera = (props: IconByStringProps) => {
  const { ...rest } = props;
  return (
    <IconByString fill="none" {...rest}>
      <path
        clipRule="evenodd"
        d="M8.28 7H4a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h16a1 1 0 0 0 1-1V8a1 1 0 0 0-1-1h-4.28l-.5-2H8.78l-.5 2Zm-.47-2.243A1 1 0 0 1 8.78 4h6.44a1 1 0 0 1 .97.757L16.5 6H20a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h3.5l.31-1.243Z"
        fill="currentColor"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M12 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7Zm0 1a4.5 4.5 0 1 0 0-9 4.5 4.5 0 0 0 0 9Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </IconByString>
  );
};

export default IconCamera;
