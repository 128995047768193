import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import { isEmpty } from 'lodash';

import useDebouncedValue from 'hooks/useDebouncedValue';
import { PAYMENTS_QUERY } from 'modules/App/queries/queries';
import useNotifications from 'modules/Notifications/hooks/useNotifications';
import { PaymentSort, PaymentFilters } from 'modules/Payments/models';
import fetchPayments from 'modules/Payments/services/fetchPayments';
import { DataTableSortOrder } from 'modules/Ui/DataTable/DataTable.models';
import useDataTablePagination from 'modules/Ui/DataTable/useDataTablePagination';

import { notificationsMessages } from '../messages';

export function usePaymentListDataTable({
  queryParams,
  searchTerm,
  updateQueryParams,
}: {
  queryParams?: PaymentFilters;
  searchTerm: string;
  updateQueryParams: (event: PaymentFilters) => void;
}) {
  const notifications = useNotifications();

  const { pagination, resetPagination, onPageSizeChange, onPageChange } =
    useDataTablePagination();

  const [sortBy, setSortBy] = useState<PaymentSort | undefined>({
    field: 'operationDate' as any,
    order: DataTableSortOrder.DESC,
  });

  const initialFilters = !isEmpty(queryParams)
    ? queryParams
    : {
        searchTerm,
        dateFrom: '',
        dateTo: '',
        documentId: '',
      };

  const [filters, setFilters] = useState<PaymentFilters>(initialFilters ?? {});

  const response = useQuery(
    [
      PAYMENTS_QUERY,
      {
        ...pagination,
        sortBy,
      },
    ],
    () =>
      fetchPayments({
        ...pagination,
        filters,
        sortBy,
      }),
    {
      onError: () => {
        notifications.error(notificationsMessages.error.id);
      },
    }
  );

  const { refetch } = response;

  const debouncedSearch = useDebouncedValue(searchTerm, 250);

  useEffect(() => {
    refetch();
  }, [refetch, pagination, sortBy, filters]);

  useEffect(() => {
    resetPagination();
    updateQueryParams(filters);
  }, [filters]);

  useEffect(() => {
    resetPagination();
    setFilters({ ...filters, searchTerm: debouncedSearch });
  }, [debouncedSearch]);

  const showZeroState =
    !response.data?.items.length &&
    !response.isFetching &&
    Object.keys(filters).every((key) => !filters[key as keyof PaymentFilters]);

  return {
    ...response,
    pagination,
    sortBy,
    filters,
    showZeroState,
    setSortBy,
    onPageSizeChange,
    onPageChange,
    setFilters,
  };
}
