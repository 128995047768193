import { Visible } from 'react-grid-system';

import useTheme from 'modules/Theme/hooks/useTheme';
import { Box, ProgressStep, Text } from 'modules/Ui';
import { BoxProps } from 'modules/Ui/Box/Box';
import { zIndex } from 'modules/Ui/zIndex';

import { StepItem } from './StepItem';

interface StepsRowProps extends BoxProps {
  maxValue?: string;
  activeStep?: StepItem;
  differentStyles?: boolean;
}

const StepsRow = (props: StepsRowProps) => {
  const { maxValue, differentStyles = false, activeStep, ...rest } = props;
  const theme = useTheme();
  return (
    <Box
      fadeIn
      height={{ _: '4px', sm: 'auto' }}
      left={{ _: '0', sm: 'initial' }}
      marginTop={{ sm: '9px' }}
      minWidth={{
        _: '100%',
        sm: '284px',
      }}
      position={{ _: 'fixed', sm: 'initial' }}
      top={{ _: '56px', sm: 'initial' }}
      width={{ _: '100%', sm: 'auto' }}
      zIndex={{ _: zIndex.PROGRESS_BAR, sm: 'initial' }}
      {...{ ...rest }}
    >
      {differentStyles ? (
        <Visible md lg xl xxl>
          <Text lineHeight={theme.lineHeights[22]} marginBottom="2px">
            <Text
              color="accent500"
              fontWeight={600}
              tag="span"
              fontSize={theme.fontSizes[14]}
              textTransform="uppercase"
            >
              {activeStep?.text[0]}{' '}
            </Text>
            <Text color="gray600" fontSize={theme.fontSizes[14]} tag="span">
              {activeStep?.text[1]}
            </Text>
          </Text>
        </Visible>
      ) : (
        <Visible md lg xl xxl>
          <Text
            color="accent500"
            fontWeight={600}
            lineHeight={theme.lineHeights[22]}
            marginBottom="9px"
          >
            {activeStep?.text}
          </Text>
        </Visible>
      )}

      <ProgressStep {...{ maxValue, activeStep }} />
    </Box>
  );
};

export default StepsRow;
