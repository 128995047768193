import { useContext } from 'react';

import useTranslations from 'modules/I18n/hooks/useTranslations';
import { Plan, PAYMENT_PERIODS } from 'modules/Subscriptions/models';
import { ChangePlanPreviewResponse } from 'modules/Subscriptions/services/changePlan/changePlanPreview';
import { Box, Button } from 'modules/Ui';

import { resume as texts } from '../messages';
import { Coupon } from '../types';
import { CampaingCouponContext } from './CampaingCouponContext';
import CouponDiscountText from './CouponDiscountText';
import PaymentResumeListItem from './PaymentResumeListIem';
import PaymentResumeListItemProration from './PaymentResumeListItemProration';

interface Props {
  balance?: number;
  coupon?: Coupon;
  currentStep: number;
  data?: ChangePlanPreviewResponse;
  periodicity: string;
  price: number;
  basePrice?: number;
  selectedPlan?: Plan;
  setCoupon?: (result?: Coupon) => void;
  subtotal?: number;
  tax?: number;
  taxLabelReversed: string;
  total?: number;
  totalToPay?: number;
}

const PaymentResumeList: React.FC<Props> = ({
  balance,
  basePrice,
  coupon,
  currentStep,
  data,
  periodicity,
  price,
  selectedPlan,
  setCoupon,
  subtotal,
  tax,
  taxLabelReversed,
  total,
  totalToPay,
}) => {
  const couponContext = useContext(CampaingCouponContext);
  const mandatory = selectedPlan?.couponMandatory;
  const { t, formatCurrency } = useTranslations();
  const periodicityLabel =
    periodicity === PAYMENT_PERIODS.YEARLY ? t(texts.annual) : t(texts.monthly);
  const totalToPayLabel =
    periodicity === PAYMENT_PERIODS.YEARLY
      ? t(texts.totalToPayAnnualy)
      : t(texts.totalToPayMonthly);
  const showCoupon = Boolean(coupon && coupon.valid && coupon.duration);
  const canDeleteCoupon = currentStep === 0 && !mandatory;

  return (
    <Box tag="dl" marginBottom={{ _: '12px', md: '24px' }}>
      {!data && (
        <PaymentResumeListItem
          borderBottomColor="primary200"
          concept={periodicityLabel}
          currency={formatCurrency(basePrice || price)}
        />
      )}
      {data?.lines && <PaymentResumeListItemProration lines={data.lines} />}
      {showCoupon && coupon && (
        <PaymentResumeListItem
          borderBottomColor="primary200"
          concept={t(texts.couponName, { name: coupon?.promotionCode })}
          currency={`- ${formatCurrency(
            (coupon?.absoluteDiscount as number) / 100
          )}`}
          fadeIn
          additionalText={
            <Box
              alignItems="center"
              display="flex"
              justifyContent="space-between"
              width="100%"
            >
              <CouponDiscountText
                duration={coupon.duration}
                durationInMonths={coupon.durationInMonths}
                percentOff={coupon.percentOff}
                amountOff={coupon.amountOff}
              />
              {canDeleteCoupon && (
                <Box flex="1" textAlign="right" tag="dd">
                  <Button
                    variant="link"
                    height="auto"
                    onClick={() => {
                      setCoupon && setCoupon(undefined);
                      couponContext?.setDeletedCoupons([
                        ...couponContext.deletedCoupons,
                        coupon.promotionCode,
                      ]);
                    }}
                  >
                    {t(texts.deleteCoupon)}
                  </Button>
                </Box>
              )}
            </Box>
          }
        />
      )}
      {!!data?.subtotal && (
        <PaymentResumeListItem
          borderBottomColor="primary200"
          concept="Subtotal"
          currency={formatCurrency(subtotal)}
        />
      )}
      <PaymentResumeListItem
        borderBottomColor={!data ? 'primary600' : 'primary200'}
        concept={taxLabelReversed}
        conceptColor="gray600"
        currency={formatCurrency(tax)}
        currencyColor="gray600"
        currencyFontWeight="normal"
      />
      {!!data?.total && (
        <PaymentResumeListItem
          borderBottomColor="primary200"
          concept="Total"
          currency={formatCurrency(total)}
        />
      )}
      {!!data?.appliedBalance && (
        <PaymentResumeListItem
          borderBottomColor="primary600"
          concept={t(texts.prorationTotal)}
          conceptColor="brand500"
          currency={formatCurrency(balance)}
          currencyColor="brand500"
        />
      )}
      <PaymentResumeListItem
        concept={!data ? totalToPayLabel : t(texts.totalToPay)}
        conceptFontWeight="600"
        currency={formatCurrency(totalToPay)}
      />
    </Box>
  );
};

export default PaymentResumeList;
