import ActionTypes from './constants';
import { Actions, State } from './types';

export const initialState: State = {
  error: false,
  errorCode: undefined,
  errorData: {},
  loading: false,
  loginSuccess: false,
};

const reducer = (state: State = initialState, action: Actions): State => {
  switch (action.type) {
    case ActionTypes.LOGIN_REQUEST:
      return {
        ...state,
        loading: true,
        loginSuccess: false,
        error: false,
      };
    case ActionTypes.LOGIN_BUSINESS_REQUEST:
    case ActionTypes.REGISTER_FROM_LEAD_REQUEST:
    case ActionTypes.CREATE_LEAD_REQUEST:
      return {
        ...state,
        errorCode: undefined,
        error: false,
        loading: true,
      };
    case ActionTypes.CREATE_LEAD_FAILURE:
      return {
        ...state,
        loading: false,
        errorCode: action.payload.code,
        errorData: action.payload.data || {},
        error: true,
      };
    case ActionTypes.LOGIN_FAILURE:
    case ActionTypes.LOGIN_BUSINESS_FAILURE:
    case ActionTypes.REGISTER_FROM_LEAD_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case ActionTypes.LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        loginSuccess: true,
      };
    case ActionTypes.CREATE_LEAD_SUCCESS:
      return {
        ...state,
        errorCode: undefined,
        loading: false,
      };
    case ActionTypes.LOGIN_BUSINESS_SUCCESS:
    case ActionTypes.REGISTER_FROM_LEAD_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case ActionTypes.LOGOUT: {
      return initialState;
    }
    case ActionTypes.CLEAR_REGISTER_ERROR: {
      return {
        ...state,
        error: false,
        errorCode: undefined,
      };
    }
    case ActionTypes.SET_SCOPES: {
      return {
        ...state,
        scopes: action.payload,
      };
    }
    default:
      return state;
  }
};

export default reducer;
