import { useInvalidateAndUpdate } from 'modules/App/hooks/useInvalidateAndUpdate';
import { salesCodeDialog } from 'modules/Bookkeeper/Exporter/messages';
import {
  invalidateQueries,
  updateBusinessBase as updateBusiness,
} from 'modules/Business/Update/services/updateBusiness';
import useNotifications from 'modules/Notifications/hooks/useNotifications';
import { useBlockingMutation } from 'utils/useBlockingMutation';

const useUpdateSalesCode = (onSalesCodeAdded?: () => void) => {
  const invalidateAndUpdate = useInvalidateAndUpdate();
  const notifications = useNotifications();

  const { mutate } = useBlockingMutation(updateBusiness, {
    onSuccess: () => {
      invalidateAndUpdate({ invalidateQueries });
      onSalesCodeAdded && onSalesCodeAdded();
      notifications.success(salesCodeDialog.success.id);
    },
    onError: () => {
      notifications.error(salesCodeDialog.error.id);
    },
  });

  return { mutate };
};

export default useUpdateSalesCode;
