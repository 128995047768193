import { createSelector } from 'reselect';

import { ApplicationRootState } from 'types';

const selectBusinessState = (state: ApplicationRootState) => state.business;

export const selectBusiness = createSelector(
  selectBusinessState,
  (state) => state.business
);
