import { getConfig } from 'modules/App/config';
import request from 'utils/request';

import { Subscription } from '../../models';

interface Params {
  billingEmail?: string;
}
const updateBillingEmail = async ({
  billingEmail,
}: Params = {}): Promise<Subscription> => {
  const endpoint = (await getConfig()).apiSubscriptionsService;
  const response = await request<any>(`${endpoint}/me`, {
    method: 'PUT',
    data: {
      billingEmail,
    },
  });

  return response.data.data;
};
export default updateBillingEmail;
