import { fork } from 'redux-saga/effects';

import app from 'modules/App/saga';
import auth from 'modules/Auth/saga';
import businessCurrent from 'modules/Business/Current/saga';
import notifications from 'modules/Notifications/saga';
import user from 'modules/Users/saga';

export function* rootSaga() {
  yield fork(app);
  yield fork(auth);
  yield fork(businessCurrent);
  yield fork(notifications);
  yield fork(user);
}
