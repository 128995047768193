import useTheme from 'modules/Theme/hooks/useTheme';

import IconByString, { IconByStringProps } from '../Icon/IconType/IconByString';

const IconMenuUserContact = (props: IconByStringProps) => {
  const { color: strokeColor, fillColor, active, ...rest } = props;
  const theme = useTheme();
  return (
    <IconByString {...{ active, ...rest }}>
      <path
        clipRule="evenodd"
        d="M19 1v22H3V1h16Zm-1 1H4v20h14V2Zm4 1h-1v20h1V3Z"
        fill="currentColor"
      />
      <path
        className="icon-hover-effect"
        d="M11 6C9.343 6 8 7.045 8 8.333v2.334C8 11.955 9.343 13 11 13s3-1.045 3-2.333V8.333C14 7.045 12.657 6 11 6Z"
        fill={active ? theme.colors.brand500 : 'transparent'}
      />
      <path
        clipRule="evenodd"
        d="M14 8.625C14 7.175 12.657 6 11 6S8 7.175 8 8.625v1.75C8 11.825 9.343 13 11 13s3-1.175 3-2.625v-1.75Zm-5 .042C9 7.747 9.895 7 11 7s2 .746 2 1.667v1.666c0 .92-.895 1.667-2 1.667s-2-.746-2-1.667V8.667Z"
        fill={active ? theme.colors.brand500 : 'currentColor'}
      />
      <path
        className="icon-hover-effect"
        clipRule="evenodd"
        d="M18 22v-4l-.172-.172a9.657 9.657 0 0 0-13.656 0L4 18v4h14Z"
        fill={active ? theme.colors.brand500 : 'transparent'}
      />
      <path
        clipRule="evenodd"
        d="m18 18-.172-.172a9.657 9.657 0 0 0-13.656 0L4 18v1.414l.879-.879a8.657 8.657 0 0 1 12.242 0l.879.88V18Z"
        fill={active ? theme.colors.brand500 : 'currentColor'}
      />
    </IconByString>
  );
};

export default IconMenuUserContact;
