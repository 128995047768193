import { isValid } from 'date-fns';
import { SagaIterator } from 'redux-saga';
import { call, takeLatest, put } from 'redux-saga/effects';

import AuthActionTypes from 'modules/Auth/constants';
import CreatedBusiness from 'modules/Business/Create/models/business';
import { getBusiness } from 'modules/Business/Current/actions';
import fetchBusiness from 'modules/Business/Current/services/fetchBusiness';
import { fetchSubscriptions } from 'modules/Subscriptions/detail/actions';
import fetchSubscription from 'modules/Subscriptions/detail/services/fetchSubscription';
import { Subscription } from 'modules/Subscriptions/models';
import { ROUTES } from 'modules/Subscriptions/routesMap';
import history from 'utils/history';

import * as actions from './actions';
import { ActionTypes } from './constants';
import { storeFeatureFlagIdentifiers } from './featureFlagUtils';

export function* fetchAppDataSaga({
  payload,
}: {
  payload?: actions.FetchAppDataParams;
} = {}): SagaIterator {
  const redirectUrl = payload?.redirectUrl;
  yield put(fetchSubscriptions.success({} as Subscription));
  try {
    const result: CreatedBusiness = yield call(fetchBusiness);
    yield put(getBusiness.success(result));
    const createdAt = new Date(result.createdAt);
    yield call(storeFeatureFlagIdentifiers, {
      businessId: result.id,
      createdAt: isValid(createdAt) ? createdAt.getTime() : Date.now(),
    });

    if (result.type !== 'bookkeeping') {
      const subscriptions: Subscription = yield call(fetchSubscription);
      yield put(fetchSubscriptions.success(subscriptions));
    } else {
      yield put(fetchSubscriptions.success({} as Subscription));
    }

    if (redirectUrl) {
      history.push(redirectUrl);
    }
    yield put(actions.fetchAppData.success());
  } catch (e: any) {
    yield put(actions.fetchAppData.failure(e));
    yield call(history.push, ROUTES.SUBSCRIPTIONS_ERROR);
  }
}

export default function* appDataSaga() {
  yield takeLatest(ActionTypes.FETCH_APP_DATA_REQUEST, fetchAppDataSaga);
  yield takeLatest(AuthActionTypes.LOGIN_BUSINESS_SUCCESS, fetchAppDataSaga);
}
