import { useEffect, FC } from 'react';

import analyticsService from 'modules/Analytics/services/analyticsService';
import { Dialog, useCloseModal } from 'modules/Modals';

import Box from '../Box';
import { BaseViewerProps } from './types';

const BaseViewer: FC<BaseViewerProps> = ({ id, children }) => {
  const closeModal = useCloseModal();

  useEffect(() => {
    analyticsService.page({ name: id });
  }, []);

  return (
    <Dialog
      dialogWidth="calc(100% - 32px)"
      id="exporter-video"
      onClose={closeModal}
      type="empty"
    >
      <Box height={{ _: 'calc( 100vh - 80px)' }} overflow="hidden">
        {children}
      </Box>
    </Dialog>
  );
};

export default BaseViewer;
