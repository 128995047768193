import { defineMessages } from 'react-intl';

export default defineMessages({
  optionLabelAddContact: {
    id: 'gofre.Documents.CreateContactDialog.option-label.add-contact',
  },
  clearInditator: {
    id: 'gofre.Select.clearInditator',
  },
});
