import styled from 'modules/Theme/styled-components';
import { Box } from 'modules/Ui';

const Check = styled(Box)`
  transition: border-color 0.2s ease-in-out;

  &:after {
    border-color: ${(props) => props.theme.colors.gray0};
    border-style: solid;
    border-width: 0 2px 2px 0;
    content: '';
    height: 11px;
    opacity: 0;
    position: relative;
    top: -1px;
    transform: rotate(45deg) translateY(-1px);
    transition: opacity 0.2s ease-in-out;
    width: 7px;
  }
`;

export default Check;
