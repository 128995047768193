import IconByPath, { IconByPathProps } from './Icon/IconType/IconByPath';

const IconDeliveryNote = (props: IconByPathProps) => {
  return (
    <IconByPath
      {...props}
      path="M14 3a2 2 0 0 1-2 2H8a2 2 0 0 1-2-2H3v19h6v1H2V2h4V1h8v1h4v9h-1V3h-3Zm-1-1H7v1a1 1 0 0 0 .883.993L8 4h4a1 1 0 0 0 .993-.883L13 3V2Zm1 5H6v1h8V7Zm0 3H6v1h8v-1Zm-8 3h3v1H6v-1Zm9 1h-3v8h9v-8h-3v3h-3v-3Zm0-1h7v10H11V13h4Zm1 1v2h1v-2h-1Z"
    />
  );
};

export default IconDeliveryNote;
