import { defineMessages } from 'react-intl';

export const form = defineMessages({
  continue: { id: 'gofre.Login.Form.button.continue' },
  email: { id: 'gofre.Login.Form.email' },
  emailPlaceholder: { id: 'gofre.Login.Form.email.placeholder' },
  legend: { id: 'gofre.Login.Form.legend' },
  password: { id: 'gofre.Login.Form.password' },
  passwordPlaceholder: { id: 'gofre.Login.Form.password.placeholder' },
  forgottenPassword: { id: 'gofre.Login.password-forgotten' },
});

export const formErrors = defineMessages({
  emailInvalid: { id: 'gofre.Login.Form.email.invalid' },
  passwordRequired: { id: 'gofre.Login.Form.password.required' },
  recaptchaRequired: { id: 'gofre.Login.Form.recaptcha.required' },
});

export const texts = defineMessages({
  logo: { id: 'gofre.Login.logo' },
  registerDesc: { id: 'gofre.Login.login.description' },
  subtitle: { id: 'gofre.Login.subtitle' },
  title: { id: 'gofre.Login.title' },
  emailPasswordError: { id: 'gofre.Login.email-password-error' },
  error: { id: 'gofre.Login.error' },
});
