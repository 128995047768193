import useTheme from 'modules/Theme/hooks/useTheme';

import IconByString, { IconByStringProps } from '../Icon/IconType/IconByString';

export interface IconHelpProps extends IconByStringProps {
  type?: 'light' | 'dark';
}

const IconNotifications = (props: IconHelpProps) => {
  const theme = useTheme();
  const {
    color: strokeColor,
    fillColor,
    active,
    type = 'dark',
    ...rest
  } = props;
  const actveColor = (typeColor: string) => {
    if (typeColor === 'dark') {
      return `${theme.colors.brand500}`;
    }
    return `${theme.colors.gray0}`;
  };

  return (
    <IconByString {...{ active, type, ...rest }}>
      <path
        d="M14 1h-4v1h4V1Zm4 9v4.878l.667.236A2.001 2.001 0 0 1 20 17v1H4v-1c0-.87.555-1.611 1.333-1.886L6 14.877V10a6 6 0 1 1 12 0ZM3 17c0-1.306.835-2.418 2-2.83V10a7 7 0 0 1 14 0v4.17c1.165.412 2 1.524 2 2.83v2H3v-2Z"
        fillRule="evenodd"
        shapeRendering="geometricPrecision"
      />
      <path
        d="M12 23a4 4 0 0 1-4-4h8a4 4 0 0 1-4 4Z"
        fill={active ? actveColor(type) : 'transparent'}
        className="icon-hover-effect"
      />
      <path
        d="M8 19a4 4 0 0 0 8 0h-1a3 3 0 1 1-6 0H8Z"
        fill={active ? actveColor(type) : 'currentColor'}
        stroke={active ? `${actveColor(type)}` : 'transparent'}
      />
    </IconByString>
  );
};

export default IconNotifications;
