import { FC, ReactNode } from 'react';

import {
  layout,
  LayoutProps,
  flexbox,
  FlexboxProps,
  PositionProps,
  position,
  color,
  ColorProps,
  space,
  SpaceProps,
} from 'styled-system';

import fadeInMixin from 'modules/Theme/mixins/fadeIn';
import styled, { css } from 'modules/Theme/styled-components';

import HtmlSvg from '../../Html/Svg';

export interface IconProps
  extends ColorProps,
    LayoutProps,
    SpaceProps,
    FlexboxProps,
    PositionProps {
  children: ReactNode;
  className?: string;
  fadeIn?: boolean;
  fill?: string;
  /** Defines height and width of an SVG */
  size?: string | number;
  /** Provides hints to the renderer about what tradeoffs to make when rendering shapes like paths, circles, or rectangles. */
  shapeRendering?:
    | 'auto'
    | 'optimizeSpeed'
    | 'crispEdges'
    | 'geometricPrecision';
  stroke?: string;
  strokeWidth?: string | number;
  transform?: string;
  transition?: boolean;
  /**  Defines the position and dimension, in user space, of an SVG viewport */
  viewBox?: string;
}

const fadeInAnimation = css`
  animation: ${fadeInMixin} 0.2s ease-in;
`;

const Svg = styled(HtmlSvg)<IconProps>`
  color: inherit;
  ${({ transform }) => transform && `transform: ${transform};`}
  ${({ transition }) =>
    transition ? 'transition: transform 0.3s ease-in;' : undefined}
  ${({ fadeIn }) => fadeIn && fadeInAnimation}
  ${color}
  ${layout}
  ${space}
  ${flexbox}
  ${position}
  ${layout}
`;

const Icon: FC<IconProps> = ({
  children,
  className,
  fadeIn,
  fill,
  height,
  shapeRendering,
  size = 24,
  stroke,
  strokeWidth,
  transform,
  transition,
  viewBox = '0 0 24 24',
  width,
  ...rest
}) => {
  return (
    <Svg
      aria-hidden="true"
      className={className}
      fill={fill}
      height={height || size}
      stroke={stroke}
      strokeWidth={strokeWidth}
      transform={transform}
      transition={transition}
      viewBox={viewBox}
      width={width || size}
      shapeRendering={shapeRendering}
      xmlns="http://www.w3.org/2000/svg"
      {...{
        ...rest,
      }}
    >
      {children}
    </Svg>
  );
};

export default Icon;
