import ExpensesZeroState from 'modules/Documents/List/components/DocumentsZeroState/ExpensesZeroState';
import useTranslations from 'modules/I18n/hooks/useTranslations';

import { zeroStateExpenses } from '../messages';

export const RemittanceExpensesZeroState = () => {
  const { t } = useTranslations();
  return (
    <ExpensesZeroState
      subtitle={t(zeroStateExpenses.subtitle)}
      title={t(zeroStateExpenses.title)}
      link={t(zeroStateExpenses.link)}
      showCTA={false}
    />
  );
};
