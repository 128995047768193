import HtmlComponent from './HtmlComponent';
import Props from './Props';

const validProps = [
  'autoFocus',
  'cx',
  'cy',
  'fill',
  'r',
  'stroke',
  'strokeDasharray',
  'strokeLinecap',
  'strokeWidth',
];

const Circle = (props: Props) => (
  <HtmlComponent tag="circle" {...{ validProps }} {...props} />
);

export default Circle;
