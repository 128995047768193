import { getConfig } from 'modules/App/config';
import buildBusinessIdsQuery from 'modules/App/queries/buildBusinessQuery';
import { BOOKKEEPER_CUSTOMERS_QUERY } from 'modules/App/queries/queries';
import request from 'utils/request';

interface Data {
  ids: string[];
  excludeIds: boolean;
}

type DeleteResponse = {
  data: { count: number };
};

type DeleteClientResponse = {
  count: number;
};

const deleteClients = async ({
  ids,
  excludeIds,
}: Data): Promise<DeleteClientResponse> => {
  const endpoint = (await getConfig()).apiBusinessUsersService;
  const query = buildBusinessIdsQuery(ids, excludeIds);

  const response = await request<DeleteResponse>(`${endpoint}?${query}`, {
    method: 'DELETE',
  });

  return {
    count: response.data.data.count,
  };
};

export default deleteClients;

export const invalidateQueries = [BOOKKEEPER_CUSTOMERS_QUERY];
