import { FC, useEffect, useRef } from 'react';
import { animated, useSpring } from 'react-spring';

type Props = {
  fly: boolean;
  toId: string;
};

function calculatePosition(html: HTMLElement | undefined | null): {
  top: number;
  left: number;
  width: number;
  height: number;
} {
  const boundingClientRect = html?.getBoundingClientRect();
  return {
    top: boundingClientRect?.top ?? 0,
    left: boundingClientRect?.left ?? 0,
    width: boundingClientRect?.width ?? 0,
    height: boundingClientRect?.height ?? 0,
  };
}

export const VanishingFlight: FC<Props> = ({ children, fly, toId }) => {
  const containerRef = useRef<HTMLDivElement>();
  const [styles, setAnimatedProps] = useSpring(() => {
    return {
      opacity: 1,
      x: 0,
      y: 0,
      transform: 'scale(1)',
    };
  });

  useEffect(() => {
    if (!fly) {
      return;
    }

    const {
      top: destTop,
      left: destLeft,
      width: destWidth,
      height: destHeight,
    } = calculatePosition(document.getElementById(toId));

    const {
      top: originTop,
      left: originLeft,
      width: originWidth,
      height: originHeight,
    } = calculatePosition(containerRef.current);

    setAnimatedProps({
      opacity: 0,
      x: destLeft + destWidth / 2 - (originLeft + originWidth / 2),
      y: destTop + destHeight / 2 - (originTop + originHeight / 2),
      transform: 'scale(0.1)',
    });
  }, [fly]);

  return (
    <animated.div
      ref={(ref: HTMLDivElement): void => {
        containerRef.current = ref;
      }}
      style={styles}
    >
      {children}
    </animated.div>
  );
};
