import {
  useStripe,
  useElements,
  CardNumberElement,
} from '@stripe/react-stripe-js';
import { StripeCardNumberElement } from '@stripe/stripe-js';

import useTranslations from 'modules/I18n/hooks/useTranslations';

import requestErrorHandler from '../../../utils/requestErrorHandler';
import { payment as texts } from '../Create/messages';
import { StripeFormError } from '../models/subscription';
import { fetchClientSecret, updatePaymentMethod } from '../services';
import { ERROR_CODE_PAYMENT_METHOD_FAILED } from './useCreateStripeSubscription/constants';

export const useUpdatePaymentMethod = () => {
  const stripe = useStripe();
  const elements = useElements();
  const { t } = useTranslations();
  const handleSubmit = async () => {
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return false;
    }
    // Get card data from stripe form
    const card: StripeCardNumberElement | null =
      elements.getElement(CardNumberElement);
    if (!card) return false;

    // Calls stripe to create payment method

    const clientSecret = await fetchClientSecret();
    const { error: stripeError, setupIntent } = await stripe.confirmCardSetup(
      clientSecret,
      {
        payment_method: {
          card,
        },
      }
    );

    const paymentMethod = setupIntent?.payment_method;

    if (stripeError) {
      throw new StripeFormError(stripeError);
    }
    if (!paymentMethod) {
      throw new Error();
    }

    try {
      await updatePaymentMethod(`${paymentMethod}`);
    } catch (e) {
      const { responseCode } = requestErrorHandler(e);
      if (responseCode === ERROR_CODE_PAYMENT_METHOD_FAILED) {
        throw new Error(t(texts.cardError));
      } else {
        throw new Error(t(texts.genericError));
      }
    }
    return true;
  };

  return handleSubmit;
};
