import { FC } from 'react';

import useTranslations from 'modules/I18n/hooks/useTranslations';
import { ApiCrendential } from 'modules/Integrations/models';
import breakpoints, { min } from 'modules/Theme/breakpoints';
import styled, { css } from 'modules/Theme/styled-components';
import { Box, Button, Text } from 'modules/Ui';
import { IconTrash } from 'modules/Ui/Icons';

import { apiConfigurationForm } from '../../messages';

interface Props {
  credentials: ApiCrendential[];
  onDelete: (credential: ApiCrendential) => void;
}

const hoverEffect = css`
  border-color: ${(props) => props.theme.colors.brand500};
  svg {
    color: ${(props) => props.theme.colors.brand500};
  }
`;

const StyledDeleteButton = styled(Button)`
  @media ${min(breakpoints.sm)} {
    @media(hover : hover) {
      &:hover {
       ${hoverEffect}
      }
    }
    &:focus  {
      ${hoverEffect}
    }
  }
`;

const ApiConfigurationGeneratedCredentials: FC<Props> = ({
  credentials,
  onDelete,
}) => {
  const { t } = useTranslations();
  if (!credentials.length) {
    return null;
  }

  return (
    <>
      <Text tag="h4" fontWeight="normal" fontSize={14} lineHeight={22}>
        {t(apiConfigurationForm.sectionCredentialsGenerated)}
      </Text>
      <Box display="grid" gap="4px" tag="ul">
        {credentials.map((item) => (
          <Box
            backgroundColor={{
              _: 'auxiliary100A30',
              sm: 'gray0',
            }}
            display="grid"
            gap={{ sm: '6px' }}
            gridTemplateColumns={{ _: '1fr 24px', sm: '1fr 48px' }}
            key={item.id}
            padding={{ _: '12px 16px', sm: 'initial' }}
            tag="li"
          >
            <Box
              backgroundColor="auxiliary100A30"
              display={{ _: 'block', sm: 'grid' }}
              gap={{ sm: '4px' }}
              gridTemplateColumns={{ sm: '164px 1fr' }}
              padding={{ sm: '16px' }}
              tag="span"
            >
              <Text
                display={{ _: 'block', sm: 'initial' }}
                fontSize={14}
                lineHeight={16}
                marginBottom={{ _: '8px', sm: 'initial' }}
                tag="span"
                hasEllipsis
              >
                {item.name}
              </Text>
              <Text
                display={{ _: 'block', sm: 'initial' }}
                color="gray800"
                fontSize={14}
                lineHeight={16}
                tag="span"
              >
                {item.clientId}
              </Text>
            </Box>
            <StyledDeleteButton
              accessibleText={`${apiConfigurationForm.delete}: ${item.name}`}
              borderColor={{ sm: 'primary100' }}
              borderStyle={{ sm: 'solid' }}
              borderWidth={{ sm: 1 }}
              onClick={() => {
                onDelete(item);
              }}
              width={{ _: 24, sm: 48 }}
            >
              <IconTrash color="gray600" />
            </StyledDeleteButton>
          </Box>
        ))}
      </Box>
    </>
  );
};

export default ApiConfigurationGeneratedCredentials;
