import ActionTypes from './constants';
import { Actions, State } from './types';

const initialState: State = {
  showBanner: true,
};

const reducer = (state: State = initialState, action: Actions): State => {
  switch (action.type) {
    case ActionTypes.REMOVE_BANNER_BAI:
      return {
        ...state,
        showBanner: false,
      };
    default:
      return state;
  }
};

export default reducer;
