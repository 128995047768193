import { getConfig } from 'modules/App/config';
import buildBaseIdsQuery from 'modules/App/queries/buildBaseIdsQuery';
import { DOCUMENTS_TO_REVIEW_QUERY } from 'modules/App/queries/queries';
import request from 'utils/request';

interface DeletePendingDocumentsParams {
  ids: string[];
  excludeIds: boolean;
}

interface DeletePendingDocumentResponse {
  data: {
    count: number;
  };
}

const deletePendingDocuments = async (params: DeletePendingDocumentsParams) => {
  const endpoint = (await getConfig()).apiDocumentsPendingService;

  const { excludeIds, ids } = params;
  const query = buildBaseIdsQuery(ids, excludeIds);

  const response = await request<DeletePendingDocumentResponse>(
    `${endpoint}?${query}`,
    {
      method: 'DELETE',
    }
  );

  if (response.status < 200 || response.status >= 400) {
    throw new Error('Error borrando documentos');
  }

  return response.data;
};

export const invalidateQueries = [DOCUMENTS_TO_REVIEW_QUERY];

export default deletePendingDocuments;
