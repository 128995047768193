import useTranslations from 'modules/I18n/hooks/useTranslations';

import { shopifyForm } from '../../messages';
import { IntegrationFormSeries } from '../IntegrationFormSeries';

export const ShopifyFormSeries = () => {
  const { t } = useTranslations();
  return (
    <IntegrationFormSeries
      seriesTitle={t(shopifyForm.seriesTitle)}
      seriesTooltip={t(shopifyForm.seriesTooltip)}
      seriesLabelText={t(shopifyForm.seriesLabelText)}
      seriesLabelHide={t(shopifyForm.seriesLabelHide)}
      seriesLabelText02={t(shopifyForm.seriesLabelText02)}
    />
  );
};
