import HtmlComponent from './HtmlComponent';
import Props from './Props';

const validProps = [
  'autoComplete',
  'disabled',
  'form',
  'formnovalidate',
  'max',
  'maxLength',
  'min',
  'minlength',
  'name',
  'pattern',
  'placeholder',
  'readonly',
  'required',
  'type',
  'value',
  'rows',
];

const Textarea = (props: Props) => (
  <HtmlComponent tag="textarea" {...{ ...props, validProps }} />
);

export default Textarea;
