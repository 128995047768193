import IconInfo from './IconInfo';

export interface IconSpamProps {
  color?: string;
  size?: number;
}

const IconSpam = (props: IconSpamProps) => {
  const { size, color, ...rest } = props;
  return (
    <IconInfo color={color} transform="rotate(180deg)" size={size} {...rest} />
  );
};

export default IconSpam;
