import { createSelector } from 'reselect';

import { ApplicationRootState } from 'types';

const selectUiState = (state: ApplicationRootState) => state?.ui;

export const selectIsBodyScrollable = createSelector(
  selectUiState,
  (state) => state?.isBodyScrollable
);
