import * as Yup from 'yup';

import { DEFAULT_COUNTRY } from 'modules/Address/models/countries';
import { formErrors } from 'modules/Contacts/components/Form/messages';
import { VatNumberType } from 'modules/Contacts/models';
import { formText } from 'modules/Forms/messages';
import { TranslationFunction } from 'modules/I18n/hooks/useTranslations';
import { nifUtils } from 'utils/nif-validations';

// Falsy messages won't make the Field red-colored
const EMPTY_MESSAGE = ' ';

const getValidationSchema = (t: TranslationFunction) => {
  const validations = {
    contact: Yup.object().when('$checkContact', {
      is: true,
      then: Yup.object().shape({
        vatNumberType: Yup.string().when('contact.address.country', {
          is: (val) => val && val !== DEFAULT_COUNTRY,
          then: Yup.string().notOneOf(
            [VatNumberType.NIF],
            t(formErrors.vatNumberType)
          ),
        }),
        vatNumber: Yup.string().when('$vatNumberType', {
          is: (val) => val && val === VatNumberType.NIF_IVA,
          then: Yup.string().test({
            name: 'nif-iva-validation',
            message: t(formErrors.vatNumberInvalid),
            test: (value) => value && nifUtils.validateNifIva(value),
          }),
          otherwise: Yup.string().when('$vatNumberType', {
            is: (val) => val && val === VatNumberType.NIF,
            then: Yup.string().test({
              name: 'nif-iva-validation',
              message: t(formErrors.vatNumberInvalid),
              test: (value) => value && nifUtils.validateNif(value),
            }),
            otherwise: Yup.string().required(t(formText.mandatory)),
          }),
        }),
        address: Yup.object().shape({
          postalAddress: Yup.string().required(t(formText.mandatory)),
          postalCode: Yup.string().required(t(formText.mandatory)),
          city: Yup.string().required(t(formText.mandatory)),
          province: Yup.string().required(t(formText.mandatory)),
          country: Yup.string().when('$vatNumberType', {
            is: VatNumberType.NIF,
            then: Yup.string().oneOf<string>([DEFAULT_COUNTRY], EMPTY_MESSAGE),
          }),
        }),
      }),
    }),
    business: Yup.object().when('$checkBusiness', {
      is: true,
      then: Yup.object().shape({
        vatNumber: Yup.string().test({
          name: 'nif-validation',
          message: t(formErrors.vatNumberInvalid),
          test: (value) => value && nifUtils.validateNif(value),
        }),
        address: Yup.object().shape({
          postalAddress: Yup.string().required(t(formText.mandatory)),
          postalCode: Yup.string().required(t(formText.mandatory)),
          city: Yup.string().required(t(formText.mandatory)),
          province: Yup.string(),
          country: Yup.string().oneOf<string>([DEFAULT_COUNTRY], EMPTY_MESSAGE),
        }),
      }),
    }),
    dir3: Yup.object().shape({
      publicAdministration: Yup.string().required(t(formText.mandatory)),
      accountingOffice: Yup.string().when('$publicAdministration', {
        is: (val) => val && (val === true || val === '1'),
        then: Yup.string().test({
          name: 'dir3-field-validation',
          message: t(formErrors.dir3FieldInvalid),
          test: () => true,
        }),
      }),
      managementOrgan: Yup.string().when('$publicAdministration', {
        is: (val) => val && (val === true || val === '1'),
        then: Yup.string().test({
          name: 'dir3-field-validation',
          message: t(formErrors.dir3FieldInvalid),
          test: () => true,
        }),
      }),
      processingUnit: Yup.string().when('$publicAdministration', {
        is: (val) => val && (val === true || val === '1'),
        then: Yup.string().test({
          name: 'dir3-field-validation',
          message: t(formErrors.dir3FieldInvalid),
          test: () => true,
        }),
      }),
    }),
  };

  return Yup.object().shape(validations);
};

export default getValidationSchema;
