import useTheme from 'modules/Theme/hooks/useTheme';

import IconByString, { IconByStringProps } from './Icon/IconType/IconByString';

export interface IconAddProps extends IconByStringProps {
  iconCrossColor?: string;
}

const IconAddInvoice = (props: IconAddProps) => {
  const { iconCrossColor, fillColor, innerPathColor, ...rest } = props;
  const theme = useTheme();
  return (
    <IconByString {...rest}>
      <path
        clipRule="evenodd"
        d="M18 23V1H2v22h16ZM3 2h14v20H3V2Z"
        fill={fillColor ? `${fillColor}` : 'currentColor'}
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M7 8a3 3 0 1 0 6 0 3 3 0 0 0-6 0Zm3-4a4 4 0 1 0 0 8 4 4 0 0 0 0-8Z"
        fill={fillColor ? `${fillColor}` : 'currentColor'}
        fillRule="evenodd"
        shapeRendering="geometricPrecision"
      />
      <path
        d="M6 15h8v1H6v-1ZM6 19h8v1H6v-1Z"
        fill={fillColor ? `${fillColor}` : 'currentColor'}
      />
      <circle
        cx="18"
        cy="18"
        fill={innerPathColor ? `${innerPathColor}` : theme.colors.brand500}
        r="6"
        shapeRendering="geometricPrecision"
      />
      <path
        d="M18.514 14.513 17.5 14.5v3h-3v1h3v3h1v-3h3v-1h-3v-3l.014.013z"
        fill={iconCrossColor ? `${iconCrossColor}` : theme.colors.gray0}
        shapeRendering="crispEdges"
      />
    </IconByString>
  );
};

export default IconAddInvoice;
