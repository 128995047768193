import { FC } from 'react';
import { Visible } from 'react-grid-system';
import { useSelector } from 'react-redux';

import { selectIsMobile } from 'modules/App/store/selectors';
import useTheme from 'modules/Theme/hooks/useTheme';
import { Text, Box, Hr } from 'modules/Ui';
import { menuSizes } from 'modules/Ui/Menu/MenuMobile/menuSizes';

import { CloseButton } from './CloseButton';

interface Props {
  id?: string;
  mobileBorderHeader?: boolean;
  onClose?(): void;
  title?: string;
  type?: 'empty' | 'fullScreen' | 'default';
}

export const DialogHeader: FC<Props> = ({
  id,
  onClose,
  title,
  mobileBorderHeader,
  type,
}) => {
  const isMobile = useSelector(selectIsMobile);
  const isFullScreen = isMobile && type === 'fullScreen';
  const theme = useTheme();
  return (
    <Box
      alignItems={isFullScreen && 'center'}
      display={isFullScreen && 'grid'}
      gridTemplateRows={isFullScreen && `${menuSizes.HEADER_HEIGHT_MOBILE}px`}
      gridTemplateColumns={isFullScreen && '1fr auto'}
      position={
        type === 'fullScreen' &&
        mobileBorderHeader && {
          _: 'relative',
          sm: 'initial',
        }
      }
      tag="header"
    >
      {title && (
        <Text
          borderBottom={
            !isFullScreen ? `1px solid  ${theme.colors.brand500}` : undefined
          }
          borderBottomWidth="1px"
          color={isFullScreen ? 'primary500' : 'brand500'}
          flex={isFullScreen ? '1' : undefined}
          fontSize={{ _: isFullScreen ? 18 : 20, sm: 28 }}
          fontWeight="600"
          id={`title-${id}`}
          lineHeight={{ _: isFullScreen ? 20 : 24, sm: 32 }}
          marginBottom={!isMobile && type === 'fullScreen' ? '24px' : undefined}
          paddingBottom={!isFullScreen ? '4px' : undefined}
          tag="h3"
          textAlign={isFullScreen ? 'center' : undefined}
        >
          {title}
        </Text>
      )}
      {type === 'fullScreen' && (
        <>
          <Visible xs>
            <CloseButton onClose={onClose} color="primary500" />
          </Visible>
          {mobileBorderHeader && (
            <Visible xs sm>
              <Hr
                bottom="0"
                color={theme.colors.gray200}
                margin={{ _: '0 -16px' }}
                position="absolute"
                width="calc(100% + 32px)"
              />
            </Visible>
          )}
        </>
      )}
    </Box>
  );
};
