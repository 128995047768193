import {
  DocumentFilters,
  DocumentStatus,
  DocumentTable,
} from 'modules/Documents/models/document';
import { TranslationFunction } from 'modules/I18n/hooks/useTranslations';
import { DataTableAction } from 'modules/Ui/DataTable/DataTable.models';
import {
  IconCopy,
  IconEdit,
  IconSortOut,
  IconPaid,
  IconExcel,
  IconPdf,
  IconZip,
  IconTrash,
  IconRemittances,
} from 'modules/Ui/Icons';

import { actions } from '../../messages';

export interface CustomExpensesBulkActionsProps
  extends Omit<UseExpensesBulkActionsProps, 'customBulkActions'> {
  status?: DocumentStatus; // actually this is not used in expenses list, but needed to be compatible with other list actions types
}

interface UseExpensesBulkActionsProps {
  t: TranslationFunction;
  filters: DocumentFilters;
  isUserEditor: boolean;
  isUserReader: boolean;
  isSomeFreezedSelected: boolean;
  isSomePaidSelected: boolean;
  selectionInverted: boolean;
  isSomeNegativeSelected: boolean;
  deleteDocuments: (
    selectedData: DocumentTable[],
    inverted: boolean,
    resetSelection: () => void
  ) => void;
  editDocument: (
    selecteds: DocumentTable[],
    _inverted: boolean,
    resetSelection: () => void
  ) => void;
  copyDocument: (
    selecteds: DocumentTable[],
    _inverted: boolean,
    resetSelection: () => void
  ) => void;
  downloadZip: (
    selecteds: DocumentTable[],
    _inverted: boolean,
    resetSelection: () => void
  ) => void;
  createPayment: (
    selecteds: DocumentTable[],
    _inverted: boolean,
    resetSelection: () => void
  ) => void;
  sortOutDocument: (
    selecteds: DocumentTable[],
    _inverted: boolean,
    resetSelection: () => void
  ) => void;
  downloadExcel: (
    selecteds: DocumentTable[],
    _inverted: boolean,
    resetSelection: () => void
  ) => void;
  downloadDocument: (
    selecteds: DocumentTable[],
    _inverted: boolean,
    resetSelection: () => void
  ) => void;
  createRemittance: (
    selecteds: DocumentTable[],
    _inverted: boolean,
    resetSelection: () => void
  ) => void;
  customBulkActions?: (
    props: CustomExpensesBulkActionsProps
  ) => DataTableAction<DocumentTable>[];
}

export const useExpensesBulkActions = (props: UseExpensesBulkActionsProps) => {
  const {
    t,
    filters,
    isUserEditor,
    isUserReader,
    isSomeFreezedSelected,
    isSomePaidSelected,
    isSomeNegativeSelected,
    selectionInverted,
    deleteDocuments,
    editDocument,
    copyDocument,
    downloadZip,
    createPayment,
    downloadExcel,
    downloadDocument,
    sortOutDocument,
    customBulkActions,
    createRemittance,
  } = props;
  const bulkActions: DataTableAction<DocumentTable>[] = customBulkActions
    ? customBulkActions({
        t,
        filters,
        isUserEditor,
        isUserReader,
        isSomeFreezedSelected,
        isSomePaidSelected,
        isSomeNegativeSelected,
        selectionInverted,
        deleteDocuments,
        editDocument,
        copyDocument,
        downloadZip,
        createPayment,
        createRemittance,
        downloadExcel,
        downloadDocument,
        sortOutDocument,
      })
    : [
        {
          name: t(actions.createRemittance),
          abbrname: t(actions.abbrCreateRemittance),
          icon: <IconRemittances />,
          onClick: createRemittance,
          shouldActionRender:
            getCanCreateRemmittance(props) && !props.selectionInverted,
        },
        {
          name: t(actions.copy),
          icon: <IconCopy />,
          disableMultiple: true,
          onClick: copyDocument,
          shouldActionRender: isUserEditor && !isSomeFreezedSelected,
        },
        {
          name: t(actions.edit),
          icon: <IconEdit />,
          disableMultiple: true,
          onClick: editDocument,
          shouldActionRender: isUserEditor && !isSomeFreezedSelected,
        },
        {
          name: t(actions.sortout),
          icon: <IconSortOut />,
          onClick: sortOutDocument,
          shouldActionRender: isUserEditor,
        },
        {
          name: t(actions.createPayment),
          abbrname: t(actions.abbrCreatePayment),
          icon: <IconPaid />,
          onClick: createPayment,
          disableMultiple: true,
          shouldActionRender:
            isUserEditor && !isSomePaidSelected && !isSomeFreezedSelected,
        },
        {
          name: t(actions.createPayment),
          abbrname: t(actions.abbrCreatePayment),
          icon: <IconPaid />,
          onClick: createPayment,
          onlyMultiple: true,
          shouldActionRender:
            isUserEditor && !isSomePaidSelected && !selectionInverted,
        },
        {
          name: t(actions.downloadExcel),
          icon: <IconExcel />,
          onClick: downloadExcel,
          shouldActionRender: isUserReader,
        },
        {
          name: t(actions.download),
          icon: <IconPdf />,
          disableMultiple: true,
          onClick: downloadDocument,
          shouldActionRender: isUserReader,
        },
        {
          name: t(actions.downloadZip),
          abbrname: t(actions.abbrDownloadZip),
          icon: <IconZip />,
          onlyMultiple: true,
          onClick: downloadZip,
          shouldActionRender: isUserReader,
        },
        {
          name: t(actions.delete),
          icon: <IconTrash />,
          onClick: deleteDocuments,
          shouldActionRender: isUserEditor,
        },
      ];

  return bulkActions;
};

const getCanCreateRemmittance = (
  props: UseExpensesBulkActionsProps
): boolean => {
  const { isSomePaidSelected, isSomeNegativeSelected } = props;
  return !isSomeNegativeSelected && !isSomePaidSelected;
};
