import request from 'utils/request';

import { Country } from '../models/countries';

const fetchCountries = async () => {
  const response = await request<{ data: Country[] }>(
    '/countries',
    {
      method: 'GET',
    },
    {
      requireAuth: false,
    }
  );

  return response.data.data;
};

export default fetchCountries;
