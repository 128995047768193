import fetchDocument from 'modules/Documents/Detail/services/fetchDocument';
import { useOpenModal } from 'modules/Modals';
import { ModalTypes } from 'modules/Modals/constants';
import { Payment } from 'modules/Payments/models';

export const useEditPaymentAction = () => {
  const openModal = useOpenModal();

  return async (
    selecteds: Payment[],
    _inverted: boolean,
    resetSelection: () => void
  ): Promise<void> => {
    const [payment] = selecteds;
    const documentData =
      payment.documents.length &&
      (await fetchDocument(payment.documents[0].id));
    const pendingAmount = documentData
      ? Math.abs(documentData.totals.total) -
        Math.abs(documentData.totalAmountFromPayments)
      : 0;
    openModal({
      type: ModalTypes.EDIT_PAYMENT,
      payment: {
        ...payment,
        id: payment.id ?? '', // this is never going to happen, but the typing used in the payment model requires it
      },
      pendingAmount,
    });
    resetSelection?.();
  };
};
