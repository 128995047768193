import { FC, useState } from 'react';
import { Hidden } from 'react-grid-system';
import { useHistory } from 'react-router';

import useTranslations from 'modules/I18n/hooks/useTranslations';
import { Dialog, useCloseModal } from 'modules/Modals';
import useModalFlags, { ModalFlag } from 'modules/Modals/hooks/useModalFlags';
import { Button, Checkbox, Text } from 'modules/Ui';
import { ROUTES } from 'pages/documents/routesMap';

import messages from './messages';

const TestInvoiceModal: FC = () => {
  const { t } = useTranslations();
  const history = useHistory();
  const { setFlag } = useModalFlags();
  const [checked, setChecked] = useState(false);
  const closeModal = useCloseModal();
  const onConfirm = async () => {
    if (checked) {
      setFlag({
        flag: ModalFlag.HIDE_TEST_INVOICE_WARNING,
        status: true,
      });
    }
    closeModal();
  };

  const handleCancel = () => {
    history.push(ROUTES.DOCUMENTS);
    closeModal();
  };

  const handleClose = () => {
    closeModal();
  };
  return (
    <Dialog
      id="test-invoice-modal"
      title={t(messages.title)}
      onClose={handleClose}
      buttonPrimary={
        <Button
          data-testid="test-invoice-modal-submit"
          variant="secondary"
          onClick={onConfirm}
        >
          {t(messages.confirmText)}
        </Button>
      }
      buttonSecondary={
        <Hidden xs>
          <Button
            data-testid="test-invoice-modal-cancel"
            onClick={handleCancel}
            variant="stroke"
          >
            {t(messages.cancel)}
          </Button>
        </Hidden>
      }
    >
      <Text
        color="primary500"
        fontWeight="600"
        lineHeight={20}
        marginBottom={16}
      >
        {t(messages.subtitle)}
      </Text>
      <Text color="primary500" marginBottom="24px" lineHeight={20}>
        {t(messages.text)}.
      </Text>
      <Checkbox
        checked={checked}
        id="hide-modal-check"
        label={t(messages.checkBox)}
        onChange={(e) => {
          setChecked(e.target.checked);
        }}
      />
    </Dialog>
  );
};

export default TestInvoiceModal;
