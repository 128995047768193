import { getAuthData } from 'modules/Auth/request-auth-data';

import { Tenants } from '../models';

export const useTenant = (): Tenants => {
  const { tenant } = getAuthData();

  const foundTenant = Object.values(Tenants).find((enumValue) =>
    tenant?.startsWith(enumValue)
  );

  return (foundTenant || tenant) as Tenants;
};
