import { DetailedHTMLProps, InputHTMLAttributes } from 'react';
import ReactMaskedInput from 'react-maskedinput';

import styled from 'modules/Theme/styled-components';

import inputStyles from '../styles/inputStyles';

interface MaskedInputProps
  extends DetailedHTMLProps<
    InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  error?: string;
  helpText?: string;
  maxLength?: number;
  mask: string;
  placeholderChar?: string;
}

const StyledInput = styled(ReactMaskedInput)`
    ${inputStyles}
`;

const MaskedInput = (props: MaskedInputProps) => {
  const {
    disabled,
    error,
    helpText,
    id,
    required,
    maxLength,
    mask,
    ref,
    placeholderChar = '_',
    ...rest
  } = props;

  return (
    <StyledInput
      {...{
        disabled,
        helpText,
        error,
        id,
        required,
        maxLength,
        mask,
        placeholderChar,
        ...rest,
      }}
      aria-describedby={error || helpText ? `description-${id}` : undefined}
      aria-invalid={error ? true : undefined}
      type="text"
    />
  );
};

export default MaskedInput;
