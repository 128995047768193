import { useQuery } from 'react-query';

import { SUBSCRIPTION } from 'modules/App/queries/queries';
import useNotifications from 'modules/Notifications/hooks/useNotifications';
import fetchSubscription from 'modules/Subscriptions/detail/services/fetchSubscription';
import { Subscription } from 'modules/Subscriptions/models';

import { texts } from './messages';

export function useFetchSubscriptionDetail(options?: {
  staleTime?: number;
  refetchOnMount?: boolean;
  disableFetch?: boolean;
}) {
  const notifications = useNotifications();
  const { isLoading, data } = useQuery(
    options?.disableFetch ? [SUBSCRIPTION, 'empty'] : SUBSCRIPTION,
    () =>
      options?.disableFetch
        ? Promise.resolve({} as Subscription)
        : fetchSubscription({ expand: true }),
    {
      staleTime: options?.staleTime,
      refetchOnMount: options?.refetchOnMount,
      onError: () => {
        notifications.error(texts.error.id);
      },
    }
  );

  return { isLoading, data };
}
