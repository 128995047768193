export const BUSINESS_ME_QUERY = 'business-me';
export const BUSINESSES_ME_QUERY = 'businesses-me';
export const BUSINESS_USERS_QUERY = 'business-users';
export const CHANGE_PLAN_PREVIEW_QUERY = 'change-plan-preview';
export const CONTACT_QUERY = 'contact';
export const CONTACTS_LIMIT_REACHED = 'is-contacts-limit-reached';
export const CONTACTS_QUERY = 'contacts';
export const DEFAULT_VALUES_QUERY = 'default-values';
export const DOCUMENT_SERIES_QUERY = 'document-series';
export const DOCUMENTS_QUERY = 'documents';
export const DOCUMENTS_RECURRENTS_QUERY = 'documents-recurrent';
export const DOCUMENT_SETTINGS_QUERY = 'document-settings';
export const PDF_PREVIEW = 'pdf-preview';
export const QUOTES_QUERY = 'quotes-query';
export const QUOTES_SERIES_QUERY = 'quotes-series-query';
export const PROFORMAS_QUERY = 'proformas-query';
export const PROFORMAS_SERIES_QUERY = 'proformas-series-query';
export const PLANS_QUERY = 'plans';
export const PRODUCT_QUERY = 'product';
export const PRODUCTS_QUERY = 'products';
export const PRODUCTS_LIMIT_REACHED = 'is-products-limit-reached';
export const SUBSCRIPTION = 'subscription';
export const SUMMARY_QUERY = 'summary';
export const TEMPLATES_CONFIG_QUERY = 'fetch-templates-config';
export const USER_INVITATION_QUERY = 'user-invitation';
export const USER_INVITATIONS_QUERY = 'user-invitations';
export const ZENDESK_TOKEN_QUERY = 'zendesk-chat-token';
export const BOOKKEEPER_CUSTOMERS_QUERY = 'bookkeeper-customers';
export const DASHBOARD_QUERY = 'dashboard';
export const DOCUMENT_TRACKING_QUERY = 'document-tracking';
export const PAYMENT_METHODS = 'payment-methods';
export const PAYMENTS_QUERY = 'payments';
export const ACCOUNTING_ACCOUNTS_QUERY = 'accounting-accounts';
export const DOCUMENTS_FACE = 'documents-face';
export const COUNTRIES = 'countries';
export const PROVINCES = 'provinces';
export const GET_REMAINING_SCANS = 'get-remaining-scans';
export const NOTIFICATIONS_LIST = 'notifications-list';
export const NOTIFICATIONS_LIST_COUNTER = 'notifications-list::counter';
export const DOCUMENTS_TO_REVIEW_QUERY = 'documents-to-review-query';
export const DOCUMENTS_TO_REVIEW_EMAIL = 'documents-to-review-email';
export const MILESTONES = 'milestones';
export const SHOPIFY_CONFIG = 'shopify-config';
export const API_CREDENTIALS = 'api-credentials';
export const REMITTANCES_QUERY = 'remittances';
export const REMITTANCES_DOCUMENTS_QUERY = 'remittances-documents';
