import { ProgressBar } from 'modules/Ui';

interface StepItem {
  active: boolean;
  text: string | string[];
  percentage: string;
}

interface ProgressBarProps {
  activeStep?: StepItem;
  maxValue?: string;
}

const ProgressStep: React.FC<ProgressBarProps> = (props) => {
  const { activeStep, maxValue = '100', ...rest } = props;
  return (
    <ProgressBar value={activeStep?.percentage} {...{ maxValue, ...rest }} />
  );
};

export default ProgressStep;
