import { ReactNode } from 'react';

import { SpaceProps, BackgroundProps } from 'styled-system';

import useTranslations from 'modules/I18n/hooks/useTranslations';
import { Box, Button, BasePill } from 'modules/Ui';
import { IconCross } from 'modules/Ui/Icons';

import messages from '../messages';

interface BannnerProps extends SpaceProps, BackgroundProps {
  image?: string;
  isNew?: boolean;
  children?: ReactNode;
  onClose?: () => void;
  to?: string;
}

const BannerContent = (props: BannnerProps) => {
  const { image, isNew, children, onClose, to } = props;
  const { t } = useTranslations();
  return (
    <>
      <Box
        backgroundImage={`url('${image}')`}
        backgroundPosition="top left"
        backgroundRepeat="no-repeat"
        backgroundSize="contain"
        tag="span"
      />
      <Box tag="span" display="grid" rowGap="4px">
        <Box
          display="grid"
          gridTemplateColumns="1fr auto"
          griTemplateRows={20}
          tag="span"
        >
          {isNew && (
            <BasePill
              backgroundColor="confirmation"
              color="gray0"
              height={16}
              maxWidth={48}
            >
              {t(messages.new)}
            </BasePill>
          )}
          {!to && (
            <Button
              aria-label={t(messages.close)}
              backgroundColor="transparent"
              onClick={onClose}
            >
              <IconCross size={20} />
            </Button>
          )}
        </Box>
        <Box tag="span" fontSize={14} lineHeight={20}>
          {children}
        </Box>
      </Box>
    </>
  );
};

export default BannerContent;
