import { FC } from 'react';

import { color, ColorProps, typography, TypographyProps } from 'styled-system';

import useTheme from 'modules/Theme/hooks/useTheme';
import styled from 'modules/Theme/styled-components';
import { Box } from 'modules/Ui';

interface Props extends ColorProps, TypographyProps {
  label: string;
  value: string | number;
}

const StyledValue = styled(Box)`
  ${color}
  ${typography}
`;

export const ChartResumeItem: FC<Props> = ({ label, value, ...rest }) => {
  const theme = useTheme();
  return (
    <Box
      alignItems="center"
      borderBottom={`1px solid  ${theme.colors.primary200}`}
      borderTop={{ sm: `1px solid  ${theme.colors.primary200}`, md: 'initial' }}
      display="grid"
      gap="12px"
      gridTemplateColumns={{ _: 'auto 1fr', md: 'minmax(auto, 130px) 1fr' }}
      minHeight="56px"
      tag="li"
    >
      <Box
        color="primary400"
        fontSize={12}
        lineHeight={14}
        tag="span"
        textTransform="uppercase"
      >
        {label}
      </Box>
      <StyledValue
        fontWeight="700"
        justifySelf="flex-end"
        tag="span"
        textAlign="right"
        {...rest}
      >
        {value}
      </StyledValue>
    </Box>
  );
};

StyledValue.defaultProps = {
  color: 'primary500',
  fontsize: 20,
  lineHeight: 24,
};
