import { defineMessages } from 'react-intl';

const messages = defineMessages({
  account: { id: 'gofre.AccountingAccount.bank.account' },
  abanca: { id: 'gofre.AccountingAccount.bank.avanca' },
  bankinter: { id: 'gofre.AccountingAccount.bank.bankinter' },
  bbva: { id: 'gofre.AccountingAccount.bank.bbva' },
  caixabank: { id: 'gofre.AccountingAccount.bank.Caixabank' },
  cajamar: { id: 'gofre.AccountingAccount.bank.cajamar' },
  card: { id: 'gofre.AccountingAccount.card' },
  cashregister: { id: 'gofre.AccountingAccount.cashregister' },
  createError: { id: 'gofre.AccountingAccount.Create.create-error' },
  evo: { id: 'gofre.AccountingAccount.bank.evo' },
  fetchingError: { id: 'gofre.AccountingAccount.Fetch.fetch-error' },
  ibercaja: { id: 'gofre.AccountingAccount.bank.ibercaja' },
  ing: { id: 'gofre.AccountingAccount.bank.ing' },
  kutxa: { id: 'gofre.AccountingAccount.bank.kutxabank' },
  march: { id: 'gofre.AccountingAccount.bank.march' },
  sabadell: { id: 'gofre.AccountingAccount.bank.sabadell' },
  santander: { id: 'gofre.AccountingAccount.bank.santander' },
  updateError: { id: 'gofre.AccountingAccount.Update.update-error' },
  updateSuccess: {
    id: 'gofre.AccountingAccount.Update.update-success',
  },
});

export default messages;
