import { useCallback, FC } from 'react';
import { Visible } from 'react-grid-system';
import { useSelector } from 'react-redux';

import { useInvalidateAndUpdate } from 'modules/App/hooks/useInvalidateAndUpdate';
import { selectIsMobile } from 'modules/App/store/selectors';
import useTranslations from 'modules/I18n/hooks/useTranslations';
import { ModalTypes } from 'modules/Modals/constants';
import { useOpenModal } from 'modules/Modals/hooks';
import useUpdatePaymentMethod from 'modules/PaymentMethods/hooks/useUpdatePaymentMethod';
import { CreatedPaymentMethod } from 'modules/PaymentMethods/models/paymentMethods';
import useTheme from 'modules/Theme/hooks/useTheme';
import { Button, Box } from 'modules/Ui';
import { IconTrash, IconEdit } from 'modules/Ui/Icons';

import { paymentsMethod as messages } from '../../messages';
import { invalidateQueries } from '../../services/deletePaymentMethod';
import usePaymentMethod from './usePaymentMethod';

interface PaymentMethodRowProps {
  paymentMethod: CreatedPaymentMethod;
}

const PaymentMethodRow: FC<PaymentMethodRowProps> = ({ paymentMethod }) => {
  const { t } = useTranslations();
  const theme = useTheme();
  const { getTypeText, getAliasText, getDescription, checkButtonEdit } =
    usePaymentMethod();
  const openModal = useOpenModal();
  const invalidateAndUpdate = useInvalidateAndUpdate();
  const isMobile = useSelector(selectIsMobile);
  const onDelete = useCallback(() => {
    openModal({
      type: ModalTypes.DELETE_PAYMENT_METHOD,
      paymentMethod,
      callback: () => invalidateAndUpdate({ invalidateQueries }),
    });
  }, [openModal, paymentMethod.id]);
  const { mutate } = useUpdatePaymentMethod({});

  return (
    <Box
      borderBottom={`1px solid ${theme.colors.gray200}`}
      padding={{ _: '8px 0', sm: '0' }}
      tag="tr"
      data-testid={paymentMethod.type}
    >
      <Box
        className="data__table--type"
        color="gray800"
        fontSize={{ _: 14 }}
        lineHeight={{ _: 20, sm: 22 }}
        marginBottom={{ _: '7px', sm: '0' }}
        minWidth={{ sm: '116px' }}
        paddingRight={{ sm: '10px', lg: '20px' }}
        tag="td"
      >
        {getTypeText(paymentMethod)}
      </Box>
      <Box
        className="data__table--text"
        color={{ _: 'gray600', sm: 'gray800' }}
        fontSize={{ _: 12, sm: 14 }}
        lineHeight={{ _: 28, sm: 22 }}
        minWidth={{ sm: '312px' }}
        padding={{ sm: '18px 10px 18px 0', lg: '18px 20px 18px 0' }}
        tag="td"
      >
        {getDescription(paymentMethod, isMobile)}
      </Box>
      <Box
        className="data__table--alias"
        color={{ sm: 'gray800' }}
        fontSize={{ sm: 14 }}
        lineHeight={{ sm: 22 }}
        minWidth={{ sm: '100px' }}
        paddingRight={{ sm: '10px', lg: '20px' }}
        tag="td"
      >
        {getAliasText(paymentMethod)}
      </Box>
      <Box
        className="data__table--edit"
        paddingRight={{ sm: '20px', lg: '48px' }}
        tag="td"
      >
        <Visible md lg xl xxl>
          {checkButtonEdit(paymentMethod) && (
            <Button
              variant="link"
              onClick={() => {
                openModal({
                  paymentMethod,
                  type: ModalTypes.UPDATE_PAYMENT_METHOD,
                  onSuccess: mutate,
                });
              }}
            >
              {t(messages.tableHeadEdit)}
            </Button>
          )}
        </Visible>
        <Visible xs sm>
          {checkButtonEdit(paymentMethod) && (
            <Button
              variant="link"
              marginRight="24px"
              aria-label={t(messages.tableHeadEdit)}
              onClick={() => {
                openModal({
                  paymentMethod,
                  type: ModalTypes.UPDATE_PAYMENT_METHOD,
                  onSuccess: mutate,
                });
              }}
            >
              <IconEdit />
            </Button>
          )}
          <Button
            variant="link"
            aria-label={t(messages.tableHeadDelete)}
            onClick={onDelete}
          >
            <IconTrash />
          </Button>
        </Visible>
      </Box>
      <Visible sm md lg xl>
        <Box className="data__table--delete" tag="td">
          <Visible md lg xl xxl>
            <Button variant="link" onClick={onDelete}>
              {t(messages.tableHeadDelete)}
            </Button>
          </Visible>
          <Visible xs sm>
            <Button
              variant="link"
              aria-label={t(messages.tableHeadDelete)}
              onClick={onDelete}
            >
              <IconTrash />
            </Button>
          </Visible>
        </Box>
      </Visible>
    </Box>
  );
};

export default PaymentMethodRow;
