import { FC } from 'react';

import styled from 'modules/Theme/styled-components';
import { Box, Spinner } from 'modules/Ui';
import { Pagination } from 'modules/Ui/Pagination';

import ListMobileItem, { ItemMobile } from './ListMobileItem';

interface Props {
  items: ItemMobile[];
  onSelectRow(selectedItemId: string): void;
  selectedItemId?: string;
  margin?: string;
  showPagination?: boolean;
  totalItems?: number;
  onPageChange?: (page: number) => void;
  pagination?: {
    page: number;
    pageSize: number;
  };
  loading?: boolean;
}

const StyledList = styled(Box)`
  button:first-child {
    padding: 2px 6px 6px 0;
  }
`;

const ListMobile: FC<Props> = ({
  items,
  selectedItemId,
  onSelectRow,
  margin,
  showPagination,
  totalItems = 0,
  onPageChange = () => {},
  pagination = {
    page: 0,
    pageSize: 10,
  },
  loading,
}) => {
  return (
    <StyledList
      {...{
        display: 'flex',
        flexDirection: 'column',
        margin: margin || '0 0 20px',
      }}
    >
      {loading ? (
        <Spinner iconCenter={false} padding="20px 0 0" size={80} tag="span" />
      ) : (
        items.map(({ id, ...rest }) => {
          return (
            <ListMobileItem
              key={id}
              {...{
                id,
                checked: selectedItemId === id,
                ...rest,
                onClickRow: () => {
                  onSelectRow(id as string);
                },
              }}
            />
          );
        })
      )}
      {showPagination && (
        <Pagination
          changeOffset={(x) => {
            onPageChange(+x / pagination.pageSize);
          }}
          count={totalItems}
          limit={pagination.pageSize}
          margin="0 0 24px"
          offset={pagination.page * pagination.pageSize}
          totalCount={totalItems}
          selectSmall
          disallowChangeLimit
        />
      )}
    </StyledList>
  );
};

export default ListMobile;
