import { FC, ReactNode } from 'react';
import { Hidden, Visible } from 'react-grid-system';

import {
  color,
  ColorProps,
  grid,
  background,
  BackgroundProps,
  GridProps,
  layout,
  LayoutProps,
  space,
  SpaceProps,
} from 'styled-system';

import { DocumentKindQueryParamsState } from 'modules/Documents/List/types';
import theme from 'modules/Theme';
import styled from 'modules/Theme/styled-components';
import { Box, HeadingWithBack } from 'modules/Ui';

import NavigationBar from '../NavigationBar';

interface Props
  extends ColorProps,
    BackgroundProps,
    GridProps,
    SpaceProps,
    LayoutProps {
  backRoute?: string;
  detail: ReactNode;
  preview: ReactNode;
  secondaryButton?: ReactNode;
  title?: string;
  onClickBack?: () => void;
  action?: ReactNode;
  hiddenBackButton?: boolean;
  state?: DocumentKindQueryParamsState;
}

const StyledWrapper = styled(Box)`
  ${background}
  ${layout}
  ${grid}
  ${space}
  ${color}
`;

const PreviewLayout: FC<Props> = ({
  action,
  backRoute = '',
  detail,
  onClickBack,
  preview,
  secondaryButton,
  title,
  hiddenBackButton,
  state,
  ...rest
}) => {
  return (
    <>
      {title && (
        <NavigationBar
          title={title}
          secondaryButton={secondaryButton}
          onClickBack={onClickBack}
          onClickClose={(hist) => hist.replace(backRoute, state)}
          hiddenBackButton={hiddenBackButton}
        />
      )}
      <Box height={{ _: `calc(100vh - ${theme.mobileHeader})`, sm: 'auto' }}>
        {title && (
          <Hidden xs>
            <HeadingWithBack
              title={title}
              marginBottom="20px"
              onClick={() => onClickBack && onClickBack()}
              hiddenBackButton={hiddenBackButton}
            />
          </Hidden>
        )}
        <StyledWrapper fadeIn tag="section" {...rest}>
          {preview && (
            <Visible sm md lg xl xxl>
              <Box
                backgroundColor="primary200"
                fadeIn
                gridArea="aside"
                tag="aside"
              >
                {preview}
              </Box>
            </Visible>
          )}
          <Box backgroundColor={{ sm: 'gray0' }} gridArea="main" tag="article">
            {detail}
          </Box>
          {action && (
            <Visible xs sm>
              <Box
                backgroundColor="gray0"
                borderTop="1px solid"
                borderTopColor="gray200"
                bottom="0"
                boxSizing="border-box"
                gridArea="actions"
                left="0"
                padding="16px"
                position="sticky"
                tag="nav"
              >
                {action}
              </Box>
            </Visible>
          )}
        </StyledWrapper>
      </Box>
    </>
  );
};

StyledWrapper.defaultProps = {
  border: { sm: `1px solid ${theme.colors.primary100}` },
  display: 'grid',
  gridTemplateAreas: {
    _: "'main' 'actions'",
    md: "'aside main'",
  },
  gridTemplateColumns: {
    _: '100%',
    md: '1fr 41%;',
    lg: '1fr 33%',
  },
  gridTemplateRows: {
    _: '1fr auto',
    md: 'auto 1fr',
  },
  height: { _: `calc(100vh - ${theme.mobileHeader})`, sm: 'auto' },
  overflow: { sm: 'hidden' },
};

export default PreviewLayout;
