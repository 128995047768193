import { useState, FC } from 'react';

import { useInvalidateAndUpdate } from 'modules/App/hooks/useInvalidateAndUpdate';
import {
  ExpenseCategories,
  RecurrentDocument,
} from 'modules/Documents/models/document';
import useTranslations from 'modules/I18n/hooks/useTranslations';
import { useCloseModal } from 'modules/Modals';
import useNotifications from 'modules/Notifications/hooks/useNotifications';
import { useBlockingMutation } from 'utils/useBlockingMutation';

import updateCategoryDocumentsRecurrents, {
  invalidateQueries,
} from '../../services/updateCategoryDocumentsRecurrents';
import { Option } from './hooks/useExpenseLedger';
import messages from './messages';
import SortOutExpensesModal from './SortOutExpensesModal';
import { SortOutExpensesRecurrentsModalProps } from './types';

const SortOutExpensesRecurrentsModal: FC<
  SortOutExpensesRecurrentsModalProps
> = ({ ids, excludeIds, documents, filters }) => {
  const { t } = useTranslations();
  const closeModal = useCloseModal();
  const notifications = useNotifications();
  const invalidateAndUpdate = useInvalidateAndUpdate();
  const selectedDocuments =
    documents &&
    ids
      .map((id: string) =>
        documents?.filter((document: RecurrentDocument) => document.id === id)
      )
      .flat();
  const hasSameCategory = selectedDocuments?.every(
    (val: any, _: number, arr: Array<RecurrentDocument>) =>
      val.documentTemplate.category === arr[0].documentTemplate.category
  );
  const selectedCategory: Option | undefined =
    (!excludeIds &&
      selectedDocuments &&
      hasSameCategory && {
        id: selectedDocuments[0].documentTemplate.category as ExpenseCategories,
        label: '',
      }) ||
    undefined;
  const [optionSelected, setOptionSelected] = useState<Option | undefined>(
    selectedCategory
  );

  const { mutate } = useBlockingMutation(updateCategoryDocumentsRecurrents, {
    onSuccess: ({ data }) => {
      notifications.success(
        t(messages.success, {
          b: (chunks) => <b>{chunks}</b>,
          count: data.count,
          expenseType: optionSelected?.label,
        }),
        { messageKey: messages.success.id }
      );
      invalidateAndUpdate({ invalidateQueries });
      closeModal();
    },
    onError: () => {
      notifications.error(messages.error.id);
      closeModal();
    },
  });

  const onConfirm = async () => {
    if (!optionSelected?.id) {
      closeModal();
    } else {
      mutate({
        ids,
        excludeIds,
        category: optionSelected.id,
        filters,
      });
      closeModal();
    }
  };

  return (
    <SortOutExpensesModal
      onConfirm={onConfirm}
      setOptionSelected={setOptionSelected}
      optionSelected={optionSelected}
      isRecurrent={true}
    />
  );
};

export default SortOutExpensesRecurrentsModal;
