import {
  pickBy,
  isNil,
  negate,
  isEmpty,
  Dictionary,
  PartialShallow,
} from 'lodash';

export const isNotNil = negate(isNil);
export const isNotEmpty = negate(isEmpty);

const checkString = <T>(value: PartialShallow<T>) =>
  typeof value === 'string' ? isNotEmpty(value) : true;

export const isNotNilOrEmpty = <T>(value: PartialShallow<T>) =>
  isNotNil(value) && checkString<T>(value);

const cleanObject = (obj: Dictionary<any>) => pickBy(obj, isNotNilOrEmpty);

export default cleanObject;
