import { createAsyncAction } from 'typesafe-actions';

import { Subscription } from '../models';
import { ActionTypes } from './constants';

export const fetchSubscriptions = createAsyncAction(
  ActionTypes.FETCH_SUBSCRIPTIONS_REQUEST,
  [ActionTypes.FETCH_SUBSCRIPTIONS_SUCCESS, (payload: Subscription) => payload],
  [ActionTypes.FETCH_SUBSCRIPTIONS_FAILURE, (error: Error) => error]
)();
