import { useCallback, useEffect } from 'react';

import { getConfig } from 'modules/App/config';
import getAsyncTaskStatus from 'modules/AsyncTask/services/getAsyncTaskStatus';
import { getApiDomain } from 'utils/apiDomain';

type BuildZipParams = {
  zipId: string;
  businessId: string;
};

interface UseDocumentZipParams {
  taskIds: string[];
  onSuccess?: (url: string) => void;
  onError?: () => void;
}
function useOpenDocumentZip(params: UseDocumentZipParams) {
  const { taskIds, onSuccess, onError } = params;

  const tasks = taskIds.map((taskId) => ({
    taskId,
    abortController: new AbortController(),
  }));

  const abortRequests = useCallback(() => {
    tasks.forEach((task) => task.abortController.abort());
  }, []);

  useEffect(() => {
    const openZipUrl = async (
      taskId: string,
      abortController: AbortController
    ) => {
      const appConfig = await getConfig();
      const apiDomain = getApiDomain(appConfig);
      const path = appConfig.apiDocumentDownloadZipService;
      try {
        const {
          result: { zipId, businessId },
        } = (await getAsyncTaskStatus(taskId, {
          cancelRequestOnDemand: abortController,
          interval: Number(appConfig.zipPollingInterval) * tasks.length,
          timeout: Number(appConfig.zipPollingTimeout) * tasks.length,
        })) as { result: BuildZipParams };
        const url = `${apiDomain}${path}/${zipId}/${businessId}`;
        window.open(url, '_self');
        onSuccess && onSuccess(url);
      } catch (error: any) {
        if (error.message === 'timedout') {
          abortController.abort();
        }
        onError && onError();
      }
    };

    tasks.reduce(async (previousPromise, task) => {
      await previousPromise;
      openZipUrl(task.taskId, task.abortController);
      const appConfig = await getConfig();
      await new Promise((cb) =>
        setTimeout(cb, Number(appConfig.zipPollingInterval))
      );
      return Promise.resolve();
    }, Promise.resolve());
  }, []);

  return abortRequests;
}

export default useOpenDocumentZip;
