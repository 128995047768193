import { getConfig } from 'modules/App/config';
import { API_CREDENTIALS } from 'modules/App/queries/queries';
import { ApiCrendential } from 'modules/Integrations/models';
import request from 'utils/request';

interface Props {
  id: string;
  name: string;
}

const updateApiCredential = async (params: Props): Promise<ApiCrendential> => {
  const endpoint = (await getConfig()).apiIntegrationApiCredentials;

  const response = await request<{ data: ApiCrendential }>(
    `${endpoint}/${params.id}`,
    {
      method: 'PUT',
      data: {
        name: params.name,
      },
    }
  );

  return response.data.data;
};
export const invalidateQueries = [API_CREDENTIALS];

export default updateApiCredential;
