import { createElement, useMemo } from 'react';
import { Hidden, Visible } from 'react-grid-system';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { selectIsMobile } from 'modules/App/store/selectors';
import { hasElectronicDocumentProvider } from 'modules/Business/businessUtils';
import { selectCurrentBusiness } from 'modules/Business/Current/selectors';
import useTranslations, {
  TranslationFunction,
} from 'modules/I18n/hooks/useTranslations';
import {
  selectHasUnlimitedPlan,
  selectIsMySubsciptionTrial,
} from 'modules/Subscriptions/detail/selectors';
import { PLANS } from 'modules/Subscriptions/models';
import { ROUTES } from 'modules/Subscriptions/routesMap';
import { HelpModule, Box, Text, Link, HeadingWithBack } from 'modules/Ui';
import NavigationBar from 'modules/Ui/NavigationBar';

import { shopifyZeroState } from '../../messages';
import { ShopifyIlustration } from '../ShopifyIlustration';

const SHOPIFY_URL = 'https://apps.shopify.com/billin?locale=es';

const getTexts = (
  isBai: boolean,
  hasAValidPlan: boolean,
  t: TranslationFunction
) => {
  if (!hasAValidPlan) {
    return {
      subtitle: t<Element, JSX.Element>(
        shopifyZeroState.zeroStateTextNotAuthorized,
        {
          bold: (chunk) =>
            createElement(Text, {
              color: 'gray800',
              fontWeight: '600',
              textSize: 'xs',
              tag: 'strong',
              children: chunk,
            }),
        }
      ),
      button: t(shopifyZeroState.zeroStateUpgradeButton),
      redirectUrl: `${ROUTES.SUBSCRIPTIONS_CHANGE}/${PLANS.UNLIMITED[1]}`,
    };
  }
  if (isBai) {
    return {
      subtitle: t(shopifyZeroState.baiZeroStateText),
      button: t(shopifyZeroState.zeroStateStartButton),
      redirectUrl: SHOPIFY_URL,
    };
  }

  return {
    subtitle: t(shopifyZeroState.zeroStateText),
    button: t(shopifyZeroState.zeroStateStartButton),
    redirectUrl: SHOPIFY_URL,
  };
};

export const ShopifyZeroState = () => {
  const { t } = useTranslations();
  const isMobile = useSelector(selectIsMobile);
  const history = useHistory();
  const business = useSelector(selectCurrentBusiness);
  const isSubscriptionTrial = useSelector(selectIsMySubsciptionTrial);
  const isSubscriptionUnlimited = useSelector(selectHasUnlimitedPlan);
  const isBai = business && hasElectronicDocumentProvider(business);
  const hasAValidPlan = isSubscriptionTrial || isSubscriptionUnlimited;

  const { subtitle, button, redirectUrl } = useMemo(
    () => getTexts(isBai, hasAValidPlan, t),
    [isBai, hasAValidPlan]
  );

  return (
    <>
      <NavigationBar
        title="Shopify"
        onClickBack={() => history.push('/integrations')}
      />
      <Hidden xs>
        <HeadingWithBack
          title="Shopify"
          marginBottom="20px"
          onClick={() => history.push('/integrations')}
        />
      </Hidden>
      <Box
        boxSizing={isMobile ? 'border-box' : undefined}
        display="grid"
        gap={{ md: '28px 16px', lg: '28px 80px' }}
        gridTemplateColumns={{ md: '1fr 288px', lg: '1fr 366px' }}
        gridTemplateRows={{ _: '1fr 80px', sm: 'initial' }}
        height={isMobile && 'calc(100% - 76px)'}
        padding={{ _: '32px 16px', sm: '50px 32px', md: '50px 32px' }}
        tag="section"
        backgroundColor="gray0"
      >
        <Box textAlign="center" overflowY={{ _: 'scroll', sm: 'initial' }}>
          <ShopifyIlustration
            height={170}
            isBeta
            margin={{ _: '0 auto 30px', sm: '0 auto 24px' }}
            width={171}
          />
          <Text
            color="brand500"
            fontSize={24}
            lineHeight={30}
            marginBottom={{ _: '16px', sm: '12px' }}
            tag="h3"
          >
            {t(shopifyZeroState.zeroStateTitle)}
          </Text>
          <Text
            color="gray600"
            fontSize={{ _: 16, sm: 14 }}
            lineHeight={{ _: 22, sm: 18 }}
            marginBottom={{ sm: '16px' }}
          >
            {subtitle}
          </Text>
          <Hidden xs>
            <Link
              isExternal={hasAValidPlan}
              target={hasAValidPlan ? 'blank' : undefined}
              to={redirectUrl}
              variant="stroke"
              width="255px"
            >
              {button}
            </Link>
          </Hidden>
        </Box>
        <Box
          backgprundColor={{ _: 'gray0', sm: 'initial' }}
          bottom={{ _: '16px', sm: 'auto' }}
          position={{ _: 'fixed', sm: 'initial' }}
          tag="aside"
          width={{ _: 'calc(100% - 32px)', sm: 'auto' }}
        >
          <Visible xs>
            <Link
              isExternal={hasAValidPlan}
              target={hasAValidPlan ? 'blank' : undefined}
              to={redirectUrl}
              variant="stroke"
              width="100%"
            >
              {button}
            </Link>
          </Visible>
          <Hidden xs sm md>
            <HelpModule />
          </Hidden>
        </Box>
      </Box>
    </>
  );
};
