import { FC } from 'react';
import { Visible } from 'react-grid-system';

import SelectSerieFormikField from 'modules/Documents/components/SelectSerieFormikField';
import { useSeries } from 'modules/Documents/components/SeriesProvider/SeriesProvider';
import useTheme from 'modules/Theme/hooks/useTheme';
import { Box, Hr, Tooltip, Text } from 'modules/Ui';
import { IconInfo } from 'modules/Ui/Icons';

interface Props {
  isTopLevel?: boolean;
  seriesTitle: string;
  seriesTooltip: string;
  seriesLabelText: string;
  seriesLabelHide: string;
  seriesLabelText02?: string;
}

export const IntegrationFormSeries: FC<Props> = ({
  isTopLevel = true,
  seriesTitle,
  seriesTooltip,
  seriesLabelText,
  seriesLabelHide,
  seriesLabelText02,
}) => {
  const { series } = useSeries();
  const options = series.map((serie) => ({
    value: serie.id,
    label: serie.serialCode,
  }));
  const theme = useTheme();
  return (
    <Box
      marginBottom={{ _: '24px', sm: '48px' }}
      border="none"
      padding="0"
      tag="fieldset"
    >
      <Box
        alignItems="center"
        aria-labelledby="exportDateForm"
        display="flex"
        marginBottom="8px"
        role="group"
      >
        <Text
          color={isTopLevel ? 'gray800' : 'primary300'}
          fontSize={isTopLevel ? 14 : 12}
          fontWeight={isTopLevel ? '600' : '500'}
          id="exportDateForm"
          lineHeight={isTopLevel ? 18 : 22}
          textTransform="uppercase"
          tag="h4"
        >
          {seriesTitle}
        </Text>
        <Tooltip
          id="shopify-series-tooltip"
          marginLeft="4px"
          tooltipButton={<IconInfo color="brand500" size={16} />}
          tooltipContent={seriesTooltip}
        />
      </Box>
      <Text color="gray600" fontSize={14} lineHeight={20} marginBottom="8px">
        {seriesLabelText}
      </Text>
      <SelectSerieFormikField
        data-testid="serialCode"
        display={{ md: 'block' }}
        id="serie"
        label={seriesLabelHide}
        labelHide
        marginBottom="8px"
        name="serie"
        noDescription
        options={options}
        width={{ sm: '288px' }}
      />
      {seriesLabelText02 && (
        <Text
          color="gray600"
          fontSize={14}
          lineHeight={20}
          marginBottom={{ _: '32px', sm: 'initial' }}
        >
          {seriesLabelText02}
        </Text>
      )}
      <Visible xs>
        <Hr
          color={theme.colors.gray200}
          marginBottom="24px"
          transform="translateX(-16px)"
          width="calc(100% + 32px)"
        />
      </Visible>
    </Box>
  );
};
