import { PaymentMethodFormValues } from '../models/form';

export function processPaymentMethods(
  paymentMethods?: PaymentMethodFormValues[]
) {
  if (!paymentMethods) return undefined;
  const enabled = paymentMethods.filter((method) => method.enable);
  return enabled.map((paymentMethod) => ({
    id: paymentMethod.id,
    paymentMethodData: {
      type: paymentMethod.type,
      data: paymentMethod.data,
      contactId: paymentMethod.contactId,
    },
  }));
}
