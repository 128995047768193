import { useEffect, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';

import { isEmpty } from 'lodash';

import { SHOPIFY_CONFIG } from 'modules/App/queries/queries';
import { shouldSendElectronicDocument } from 'modules/Business/businessUtils';
import { selectCurrentBusiness } from 'modules/Business/Current/selectors';
import activateShopify from 'modules/Integrations/services/shopify/activateShopify';
import deactivateShopify from 'modules/Integrations/services/shopify/deactivateShopify';
import { ModalTypes } from 'modules/Modals/constants';
import { useOpenModal } from 'modules/Modals/hooks';
import useNotifications from 'modules/Notifications/hooks/useNotifications';
import CacheTimes from 'utils/query/cacheTimes';
import { useBlockingMutation } from 'utils/useBlockingMutation';

import messages from '../messages';
import fetchShopifyConfig from '../services/shopify/fetchShopifyConfig';

export const useShopifyConfiguration = () => {
  const [hasShopifyConfig, setHasShopifyConfig] = useState(false);
  const business = useSelector(selectCurrentBusiness);
  const shouldSendBai = shouldSendElectronicDocument(business);
  const openModal = useOpenModal();
  const notifications = useNotifications();
  const queryClient = useQueryClient();
  const setQueryData = (queryData: any) => {
    queryClient.setQueryData(SHOPIFY_CONFIG, queryData);
  };
  const { data, isLoading } = useQuery(
    [SHOPIFY_CONFIG],
    () => fetchShopifyConfig(),
    {
      staleTime: CacheTimes.INFINITY,
      retry: false,
    }
  );
  useEffect(() => {
    if (isEmpty(data)) {
      setHasShopifyConfig(false);
    } else {
      setHasShopifyConfig(true);
    }
  }, [data?.id]);

  const { mutate: activateShopifyMutation, isLoading: isActivating } =
    useBlockingMutation(activateShopify, {
      onSuccess: () => {
        setQueryData({ ...data, isActive: true });
        notifications.success(messages.actiaveShopifyNotification.id);
      },
    });
  const { mutate: deactivateShopifyMutation, isLoading: isDeactivating } =
    useBlockingMutation(deactivateShopify, {
      onSuccess: () => {
        setQueryData({ ...data, isActive: false });
        notifications.success(messages.deactiaveShopifyNotification.id);
      },
    });

  const deactivateShopifyCallBack = shouldSendBai
    ? () => openModal({ type: ModalTypes.DEACTIVATE_SHOPIFY_WARNING })
    : deactivateShopifyMutation;
  return {
    data,
    isLoading,
    hasShopifyConfig,
    isActivating,
    isDeactivating,
    activateShopify: activateShopifyMutation,
    deactivateShopify: deactivateShopifyCallBack,
  };
};
