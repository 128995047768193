import { createContext, useContext } from 'react';

import { AppConfig } from './models';

function createConfigContext() {
  const ConfigContext = createContext<AppConfig | null>(null);
  ConfigContext.displayName = 'ConfigContext';
  return ConfigContext;
}

export const ConfigContext = createConfigContext();

export default function useConfig() {
  const context = useContext(ConfigContext);
  if (!context) {
    throw new Error('ConfigContext is not provided.');
  }
  return context;
}
