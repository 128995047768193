import { useState, useEffect, useCallback } from 'react';

import featureFlags from '../featureFlags';

// TODO: Add unit test to hook useFeatureFlag
export default function useFeatureFlagLoaded(
  key?: string,
  initialValue?: boolean | string
): [boolean, boolean | string | undefined] {
  // If we dont pass a key to useFeatureFlag, we want it to return true
  const [value, setValue] = useState(key ? initialValue : true);
  const [loaded, setLoaded] = useState(false);

  const updateValue = useCallback(() => {
    const flagValue = featureFlags.getFlag(key as string);
    setValue(flagValue);
    setLoaded(featureFlags.isInitialized);
  }, []);

  useEffect(() => {
    if (!key) return undefined;
    updateValue();
    // subscribe return unsubscribe function
    return featureFlags.subscribe(updateValue);
  }, []);

  return [loaded, value];
}
