import { AxiosResponse } from 'axios';

import { getConfig } from 'modules/App/config';
import request from 'utils/request';

import { CreatedPaymentMethod, PaymentMethod } from '../models/paymentMethods';

const createPaymentMethod = async (paymentMethod: PaymentMethod) => {
  const endpoint = (await getConfig()).apiPaymentMethodsService;

  const response = await request<AxiosResponse<CreatedPaymentMethod>>(
    endpoint,
    {
      method: 'POST',
      data: paymentMethod,
    }
  );
  return response.data.data;
};

export const invalidateQueries = [];

export default createPaymentMethod;
