import { createElement, FC, Fragment } from 'react';
import { Visible } from 'react-grid-system';

import { FormikProps } from 'formik';

import useTranslations from 'modules/I18n/hooks/useTranslations';
import { ModalTypes } from 'modules/Modals/constants';
import useOpenModal from 'modules/Modals/hooks/useOpenModal';
import messages, { leavingSettingsModal } from 'modules/Settings/messages';
import {
  TemplateColors,
  TemplateTypes,
} from 'modules/Settings/Theme/components';
import TemplateFonts from 'modules/Settings/Theme/components/TemplateFonts/TemplateFonts';
import { NEW_TEMPLATE } from 'modules/Settings/Theme/helpers/getInitialValues';
import {
  TemplateSettings,
  TemplateSettingsForm,
} from 'modules/Settings/Theme/types';
import { Button, Box, FormikField, Text, SelectLanguage } from 'modules/Ui';
import Dropzone, { ImageFile } from 'modules/Ui/Dropzone/Dropzone';
import SizeSelect from 'modules/Ui/Dropzone/SizeSelect';
import { IconPassword } from 'modules/Ui/Icons';
import SearchTemplateInput from 'modules/Ui/SearchTemplateInput';

import ConfirmNavigationDialog from './ConfirmNavigationDialog';
import { TemplateIdPill } from './TemplateIdPill';

interface TemplatesFormProps {
  onSubmit: (
    formValues: TemplateSettingsForm,
    file?: Blob | null
  ) => Promise<string>;
  templates: TemplateSettings[];
  handleCreateTemplateOption: (formik: FormikProps<any>) => void;
  handleOnTemplateSelect: (formik: FormikProps<any>) => (id: string) => void;
  file: ImageFile | undefined | null;
  setFile: (file: ImageFile | undefined | null) => void;
  previewUrl: string | undefined;
  setPreviewUrl: (url: string | undefined) => void;
  templateNamePlaceholder: string;
  formik: FormikProps<any>;
  hasApiCredentials?: boolean;
}

export const TemplatesForm: FC<TemplatesFormProps> = (props) => {
  const {
    file,
    formik,
    handleCreateTemplateOption,
    handleOnTemplateSelect,
    onSubmit,
    previewUrl,
    setFile,
    setPreviewUrl,
    templateNamePlaceholder,
    templates,
    hasApiCredentials = false,
  } = props;
  const { t } = useTranslations();
  const openModal = useOpenModal();
  const {
    values: { templateType },
  } = formik;

  const handleOnClick = () => {
    openModal({
      type: ModalTypes.CUSTOMIZE_TEMPLATE_PREVIEW,
      values: formik.values,
    });
  };

  return (
    <>
      <ConfirmNavigationDialog
        text={t(
          formik.values.isUsed
            ? leavingSettingsModal.textInUse
            : leavingSettingsModal.text,
          {
            templateName: formik.values.name,
            br: () =>
              createElement(Fragment, null, Array(2).fill(createElement('br'))),
          }
        )}
        overrideSubmit={() => {
          onSubmit({ ...formik.values, isUsed: false }, file);
        }}
      />
      <Box
        display={{ sm: 'flex', md: 'block' }}
        padding={{ _: '0 0 32px', sm: '0' }}
      >
        <Box
          id="templates-form"
          noValidate
          onSubmit={formik.handleSubmit}
          tag="form"
        >
          <Text
            fontSize={14}
            color="primary500"
            fontWeight="600"
            lineHeight={22}
            marginBottom="4px"
            tag="h3"
            textTransform="uppercase"
          >
            {t(messages.templateSelectTitle)}
          </Text>
          <Text
            color="gray600"
            fontSize={14}
            lineHeight={18}
            marginBottom="18px"
          >
            {t(messages.templateSelectDesc)}
          </Text>
          <SearchTemplateInput
            labelHide
            templates={templates}
            onCreateOption={() => handleCreateTemplateOption(formik)}
            onSelect={handleOnTemplateSelect(formik)}
          />
          {hasApiCredentials && formik.values.templateId !== NEW_TEMPLATE && (
            <TemplateIdPill id={formik.initialValues.templateId} />
          )}
          <FormikField
            id="name"
            label={t(messages.templateName)}
            marginBottom="16px"
            name="name"
            noDescription
            placeholder={templateNamePlaceholder}
          />
          <SelectLanguage id="language" />
          <Dropzone
            {...{
              previewUrl,
              setPreviewUrl,
              onChange: (newFile?: ImageFile | null) => {
                setFile(newFile);
                formik.setFieldValue('logoUrl', newFile?.previewUrl);
              },
              title: t(messages.dropzoneTitle),
              description: t(messages.description),
              content: t(messages.content),
            }}
          />
          <SizeSelect id="logoSize" />
          <TemplateTypes templateType={templateType} />
          <TemplateColors />
          <TemplateFonts templateType={templateType} />
        </Box>
        <Visible md>
          <Box display="flex" flex="1" justifyContent="flex-end">
            <Button
              icon={<IconPassword color="inherit" />}
              onClick={handleOnClick}
            >
              {t(messages.preview)}
            </Button>
          </Box>
        </Visible>
      </Box>
    </>
  );
};
