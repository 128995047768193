import { useSelector } from 'react-redux';

import { selectPlanType } from 'modules/Subscriptions/detail/selectors';

import BaseFaqs from '../BaseFaqs';
import messages from './messages';

const NUMBER_OF_FAQS = 6;

const Faqs: React.FC = () => {
  const plan = useSelector(selectPlanType);

  return (
    <BaseFaqs
      {...{
        numberOfFaqs: NUMBER_OF_FAQS,
        messages,
        plan,
      }}
    />
  );
};
export default Faqs;
