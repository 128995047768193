import { FC } from 'react';

import useTheme from 'modules/Theme/hooks/useTheme';
import styled from 'modules/Theme/styled-components';
import { Box } from 'modules/Ui';

import { SubmenuSections } from '../../../constants';
import { menuSizes } from '../../menuSizes';
import menuAnimation from '../../styles/menuAnimation';
import SubMenuContent from './SubmenuContent';

export interface SubMenuProps {
  closeMenu: () => void;
  open: boolean;
  section: SubmenuSections | null;
}

const MenuWrapper = styled(Box)<SubMenuProps>`
  ${menuAnimation}
`;

const SubMenu: FC<SubMenuProps> = ({ section, open, closeMenu }) => {
  const theme = useTheme();
  return (
    <MenuWrapper
      {...{ open }}
      aria-hidden={open ? undefined : true}
      id="submenu"
      backgroundColor="gray0"
      borderBottom={`1px solid ${theme.colors.gray200}`}
      borderTop={
        open
          ? `1px solid ${theme.colors.gray0}`
          : `1px solid ${theme.colors.gray200}`
      }
      height={`calc(100vh - (${menuSizes.HEADER_HEIGHT_MOBILE}px * 2))`}
      left="0"
      position="fixed"
      role="dialog"
      tag="section"
      top={`${menuSizes.HEADER_HEIGHT_MOBILE}px`}
      width={`${menuSizes.MENU_WIDTH}px`}
      zIndex={open && '-1'}
    >
      <SubMenuContent {...{ section, open, closeMenu }} />
    </MenuWrapper>
  );
};

export default SubMenu;
