import omit from 'lodash/omit';

import useTranslations from 'modules/I18n/hooks/useTranslations';
import { FormikFieldProps } from 'modules/Ui/Formik/FormikFieldProps';

import IconCross from '../../Icons/IconCross';
import IconSearch from '../../Icons/IconSearch';
import Button from '../Button';
import Description from '../Commons/Description';
import Input from '../Commons/Input';
import InputWrapper from '../Commons/InputWrapper';
import Label from '../Commons/Label';
import LabelText from '../Commons/LabelText';
import messages from './messages';

export interface FieldProps extends FormikFieldProps {
  setValue: (newValue: string) => void;
  noDescription?: boolean;
}

const FilterField = (props: FieldProps) => {
  const {
    className,
    disabled,
    error,
    helpText,
    id = 'filter',
    label,
    labelHidden,
    noDescription,
    required,
    value,
    setValue,
    ...rest
  } = props;
  const { t } = useTranslations();
  return (
    <Label id={id} {...omit(rest, ['data-testid'])}>
      <LabelText hidden={labelHidden} required={required} disabled={disabled}>
        {label}
      </LabelText>
      <InputWrapper
        gridTemplateColumns={value ? '24px 1fr 24px' : '24px 1fr'}
        disabled={disabled}
        error={error}
        hasIcon
        role="alert"
      >
        <IconSearch color="gray300" />
        <Input
          aria-describedby={`filter-description-${id}`}
          disabled={disabled}
          error={error}
          id={id}
          required={required}
          type="search"
          value={value}
          autoComplete="off"
          onChange={(e) => {
            setValue(e.target.value);
          }}
          {...rest}
        />
        {value && (
          <Button aria-label={t(messages.button)} onClick={() => setValue('')}>
            <IconCross color="brand500" size={20} />
          </Button>
        )}
      </InputWrapper>
      <Description
        hide={noDescription}
        id={`filter-description-${id}`}
        hasError={!!error}
      >
        {error || helpText}
      </Description>
    </Label>
  );
};

export default FilterField;
