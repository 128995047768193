import { find } from 'lodash/fp';

import useTranslations from 'modules/I18n/hooks/useTranslations';
import styled from 'modules/Theme/styled-components';
import Select from 'modules/Ui/Form/Select';
import Limits from 'types/limits';

import messages from './messages';

interface ItemsPerPageProps {
  id?: string;
  onChangeLimit(limit: Limits): void;
  limit: Limits;
  selectSmall?: boolean;
}

const ItemsPerPageSelect = styled(Select)`
  align-items: center;
  display: flex;
  max-width: 250px;

  > span {
    color: ${(props) => props.theme.colors.gray600};
    font-family: "Inter", sans-serif;
    font-weight: normal;
    margin-right: 8px;
    font-size: 12px;
  }

  > div {
    flex: 0;
    width: 70px;
  }

  .react-select__control {
    flex: 1;
    width: 70px;
  }
`;

const ItemsPerPage = (props: ItemsPerPageProps) => {
  const { selectSmall, id, limit, onChangeLimit } = props;
  const { t } = useTranslations();

  const handleChange = (option: any) => {
    onChangeLimit(option);
  };

  const options = [
    { value: Limits.SMALL, label: `${Limits.SMALL}` },
    { value: Limits.MEDIUM, label: `${Limits.MEDIUM}` },
    { value: Limits.BIG, label: `${Limits.BIG}` },
  ];

  const value = find(['value', limit], options);
  return (
    <ItemsPerPageSelect
      {...{ ...value }}
      id={`ItemsPerPage-${id}`}
      onChange={handleChange}
      label={t(messages.elements)}
      options={options}
      isSearchable={false}
      placeholder="10"
      small={selectSmall}
    />
  );
};

export default ItemsPerPage;
