import { createSelector } from 'reselect';

import { ApplicationRootState } from 'types';

import { State } from './types';

const selectNotificationsState = (rootState: ApplicationRootState) =>
  rootState.notifications;

export const selectNotifications = createSelector(
  selectNotificationsState,
  (state: State) => state.notifications
);
export const selectBubbleNotifications = createSelector(
  selectNotificationsState,
  (state: State) => state.bubbleNotifications
);
export const selectCommercialNotifications = createSelector(
  selectNotificationsState,
  (state: State) => state.commercialNotifications
);
