import IconByString, { IconByStringProps } from './Icon/IconType/IconByString';

const IconFile = (props: IconByStringProps) => (
  <IconByString {...props}>
    <>
      <path
        d="M1 8v11.5A1.5 1.5 0 0 0 2.5 21h19a1.5 1.5 0 0 0 1.5-1.5V8H1Zm21 1H2v10.5a.5.5 0 0 0 .5.5h19a.5.5 0 0 0 .5-.5V9Z"
        fill="currentColor"
        fillRule="evenodd"
        clipRule="evenodd"
      />
      <path
        d="M1 4h5.298a2.5 2.5 0 0 1 1.562.548L9.675 6H23v2h-1V7H9.325l-2.09-1.671A1.5 1.5 0 0 0 6.298 5H2v3.5H1V4Z"
        fill="currentColor"
      />
    </>
  </IconByString>
);

export default IconFile;
