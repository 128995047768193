import { useCallback } from 'react';

import { FormikValues } from 'formik';

const SESSION_STORAGE_PREFIX = 'PersistFormikValues_';

export const usePersistValues = (props: {
  name: string;
}): {
  persistedString: string | null;
  persistValues: (values: FormikValues) => void;
  clearValues: () => void;
} => {
  const { name } = props;

  const storage = window.localStorage;

  const persistedString = storage
    ? storage.getItem(`${SESSION_STORAGE_PREFIX}${name}`)
    : null;

  const persistValues = useCallback(
    (values: FormikValues) => {
      if (storage) {
        const { file, ...rest } = values;
        storage.setItem(
          `${SESSION_STORAGE_PREFIX}${name}`,
          JSON.stringify(rest)
        );
      }
    },
    [storage]
  );

  const clearValues = useCallback(() => {
    if (storage) {
      storage.removeItem(`${SESSION_STORAGE_PREFIX}${name}`);
    }
  }, [storage]);

  return { persistedString, persistValues, clearValues };
};
