import { defineMessages } from 'react-intl';

export default defineMessages({
  cancelButton: { id: 'gofre.Business.Update.cancel-business-button' },
  okMessage: { id: 'gofre.Business.Update.okMessage' },
  koMessage: { id: 'gofre.Business.Update.koMessage' },
  title: { id: 'gofre.Business.Update.title' },
  updateBusinessButton: { id: 'gofre.Business.Update.update-business-button' },
  updateVatNumberError: { id: 'gofre.Business.Update.duplicate-nif-error' },
});
