import useTranslations from 'modules/I18n/hooks/useTranslations';
import { Box, Text } from 'modules/Ui';
import { IconInfo } from 'modules/Ui/Icons';

import { tracking as messages } from './messages';

const DocumentTrackingFailedState: React.FC = () => {
  const { t } = useTranslations();
  return (
    <Box marginTop="25px" display="flex" alignItems="center">
      <IconInfo color="gray600" />
      <Text
        fontSize={16}
        lineHeight={16}
        color="gray600"
        tag="span"
        marginLeft="8px"
      >
        {t(messages.error)}
      </Text>
    </Box>
  );
};

export default DocumentTrackingFailedState;
