import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { getUser } from '../actions';

const useRequestUser = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUser.request());
  }, []);
};

export default useRequestUser;
