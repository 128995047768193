import { useHistory } from 'react-router';

import { useInvalidateAndUpdate } from 'modules/App/hooks/useInvalidateAndUpdate';
import { CONTACTS_QUERY } from 'modules/App/queries/queries';
import { Errors } from 'modules/Contacts/Create/services/createContact';
import fetchContacts from 'modules/Contacts/List/services/fetchContacts';
import updateContact, {
  invalidateQueries,
} from 'modules/Contacts/Update/services/updateContact/updateContact';
import { ModalTypes } from 'modules/Modals/constants';
import useOpenModal from 'modules/Modals/hooks/useOpenModal';
import useNotifications from 'modules/Notifications/hooks/useNotifications';
import Limits from 'types/limits';
import { ResponseError } from 'types/response-error.type';
import requestErrorHandler from 'utils/requestErrorHandler';
import { useBlockingMutation } from 'utils/useBlockingMutation';

import messages from './messages';

function useAddContactMutation(id: string, duplicatedMessage: string) {
  const invalidate = useInvalidateAndUpdate();
  const openModal = useOpenModal({ type: ModalTypes.LIMIT_CONTACTS });
  const notifications = useNotifications();
  const history = useHistory();
  const { mutate } = useBlockingMutation(updateContact, {
    onSuccess: async (response) => {
      const contacts = await fetchContacts({
        page: 0,
        pageSize: Limits.SMALL,
        searchTerm: response.vatNumber,
        fields: ['id', 'vatNumber'],
      });

      if (contacts.count > 1) {
        notifications.warning(duplicatedMessage, {
          messageKey: messages.duplicated.id,
          haunter: true,
          ttl: 0,
          hideIcon: true,
        });
      }

      invalidate({
        data: response,
        setQueryData: [CONTACTS_QUERY],
        invalidateQueries,
      });
      history.push(`/quotes/${id}/issue`);
      notifications.success(messages.success.id);
    },
    onError: (error: ResponseError) => {
      const { responseMessage, isNetworkError, networkErrorTranslationKey } =
        requestErrorHandler(error);
      if (responseMessage === Errors.CREATE_NOT_AVAILABLE) {
        return openModal();
      }
      return notifications.error(
        isNetworkError ? networkErrorTranslationKey : messages.error.id
      );
    },
  });

  return mutate;
}

export default useAddContactMutation;
