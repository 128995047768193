import { useMemo, FC } from 'react';
import { Hidden } from 'react-grid-system';

import { Formik } from 'formik';

import FilterWrapper from 'modules/Documents/List/components/Filter/DocumentsFilterForm/components/FilterWrapper';
import { StatusInfoUI } from 'modules/Documents/models/statusInfo';
import useTranslations from 'modules/I18n/hooks/useTranslations';
import { filter } from 'modules/Payments/components/List/messages';
import { PaymentFilters, PaymentStatusEnum } from 'modules/Payments/models';
import { Box, Button } from 'modules/Ui';
import { DayPickerFormikField, SelectFormikField } from 'modules/Ui/Formik';
import ErrorListener from 'modules/Ui/Formik/ErrorListener';

import PaymentAmountFilters from './components/PaymentAmountFilters';
import getValidationSchema from './validation';

interface FormProps extends PaymentFilters {
  onSubmit: (filters: PaymentFilters) => void;
  onCancel: () => void;
}

const PaymentsFilterForm: FC<FormProps> = ({
  dateFrom,
  dateTo,
  minAmount,
  maxAmount,
  linked,
  paidStatus,
  onSubmit,
  onCancel,
}) => {
  const { t } = useTranslations();
  const initialValues = {
    minAmount,
    maxAmount,
    dateFrom,
    dateTo,
    linked,
    paidStatus,
    onCancel,
  };

  const paymentLinkedOptions = useMemo(() => {
    const options = [
      { value: PaymentStatusEnum.LINKED, label: t(filter.linked) },
      {
        value: PaymentStatusEnum.NOT_LINKED,
        label: t(filter.notLinked),
      },
    ];
    return options;
  }, [t]);

  const paymentStatusOptions = useMemo(() => {
    const options = [
      { value: StatusInfoUI.PAID, label: t(filter.completed) },
      {
        value: StatusInfoUI.PARTIALLY_PAID,
        label: t(filter.partial),
      },
    ];
    return options;
  }, [t]);

  return (
    <Formik
      onSubmit={(values, actions) => {
        onSubmit(values);
        actions.setSubmitting(false);
      }}
      validationSchema={getValidationSchema(t)}
      initialValues={initialValues}
      enableReinitialize
      validateOnChange={false}
      validateOnBlur={false}
    >
      {(formik) => (
        <form
          autoComplete="off"
          id="filter-form"
          noValidate
          onSubmit={formik.handleSubmit}
        >
          <ErrorListener />
          <FilterWrapper
            gridTemplateColumns={{
              sm: '1fr 1fr 2fr 2fr',
            }}
            id="paymentsFilters"
          >
            <DayPickerFormikField
              id="dateFrom"
              label={t(filter.dateFromLegend)}
            />
            <DayPickerFormikField label={t(filter.dateToLegend)} id="dateTo" />
            <SelectFormikField
              id="linked"
              name="linked"
              label={t(filter.linkedLabel)}
              options={paymentLinkedOptions}
              placeholder={t(filter.selectPaidPlaceholder)}
            />
            <SelectFormikField
              id="paidStatus"
              name="paidStatus"
              label={t(filter.typeLabel)}
              options={paymentStatusOptions}
              placeholder={t(filter.selectPaidPlaceholder)}
            />
            <PaymentAmountFilters />
            <Hidden xs>
              <Box
                gridColumn={{ sm: '4 / 5' }}
                alignSelf="center"
                justifySelf={{ lg: 'end' }}
              >
                <Button
                  data-testid="filter-submit"
                  type="submit"
                  variant="primary"
                  width={{ _: '100%', lg: '208px' }}
                >
                  {t(filter.button)}
                </Button>
              </Box>
            </Hidden>
          </FilterWrapper>
        </form>
      )}
    </Formik>
  );
};

export default PaymentsFilterForm;
