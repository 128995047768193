import IconByPath, { IconByPathProps } from './Icon/IconType/IconByPath';

const IconMobile = (props: IconByPathProps) => (
  <IconByPath
    {...props}
    fillRule="evenodd"
    path="M7 2h10a1 1 0 0 1 1 1v18a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V3a1 1 0 0 1 1-1ZM5 3a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2v18a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V3Zm5.5 0h3v1h-3V3ZM9 20.5h6v1H9v-1Z"
  />
);

export default IconMobile;
