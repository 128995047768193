import IconByPath, { IconByPathProps } from './Icon/IconType/IconByPath';

const IconTrash = (props: IconByPathProps) => (
  <IconByPath
    {...props}
    fillRule="evenodd"
    path="M14 3h-4v1h4V3ZM9 2v2H5a1 1 0 0 0-1 1v3h2v13a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V8h2V5a1 1 0 0 0-1-1h-4V2H9Zm0 3h10v2H5V5h4ZM7 8v13h10V8H7Zm2.5 10v-8h-1v8h1Zm6 0v-8h-1v8h1Zm-4-8h1v8h-1v-8Z"
  />
);

export default IconTrash;
