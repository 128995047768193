import { FC, useCallback } from 'react';

import { Formik } from 'formik';

import {
  AccountingAccount,
  AccountingAccountTypeEnum,
} from 'modules/AccountingAccounts/models';
import useTranslations from 'modules/I18n/hooks/useTranslations';
import { Box, FormikField } from 'modules/Ui';
import MaskedFormikField from 'modules/Ui/Formik/MaskedFormikField';

import { formText } from '../../messages';
import {
  ALIAS_MAX_LENGTH,
  CREDIT_CARD_EXPIRE_DATE,
  CREDIT_CARD_NUMBER_LENGTH,
} from '../AccountingAccountModal/constants';
import { getValidationSchema } from './validation';

export interface AccountingAccountCreditCardForm {
  type: AccountingAccountTypeEnum.CREDIT_CARD;
  alias: string;
  creditCardNumber: string;
  creditCardExpireDate?: string;
}

interface CreditCardFormProps {
  handleSubmit: (values: AccountingAccountCreditCardForm) => void;
  account?: AccountingAccount;
  isEditing?: boolean;
}

export const CreditCardForm: FC<CreditCardFormProps> = ({
  handleSubmit,
  account,
  isEditing = false,
}) => {
  const { t } = useTranslations();
  const validationSchema = getValidationSchema(t);
  const onSubmit = useCallback(
    (
      values: Pick<
        AccountingAccountCreditCardForm,
        'alias' | 'creditCardNumber' | 'creditCardExpireDate'
      >
    ) => {
      handleSubmit({ type: AccountingAccountTypeEnum.CREDIT_CARD, ...values });
    },
    []
  );
  return (
    <Formik
      enableReinitialize
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      initialValues={{
        alias: account?.alias || '',
        creditCardNumber: account?.creditCardNumber || '',
        creditCardExpireDate: account?.creditCardExpireDate || '',
      }}
      validateOnBlur={false}
      validateOnChange={false}
    >
      {(formik) => (
        <Box
          autoComplete="off"
          columnGap={{ sm: '24px' }}
          display={{ sm: 'grid' }}
          gridTemplateColumns={{ sm: '42% 30% 1fr' }}
          gridTemplateRows={{ sm: 'auto auto' }}
          id={AccountingAccountTypeEnum.CREDIT_CARD}
          marginBottom={!isEditing && { sm: '24px' }}
          noValidate
          onSubmit={formik.handleSubmit}
          tag="form"
        >
          <FormikField
            gridArea={{ sm: '1 / 1 / 3 / 4' }}
            id="alias"
            label={t(formText.creditCardAlias)}
            maxLength={ALIAS_MAX_LENGTH}
            name="alias"
            placeholder={t(formText.creditCardAliasPlaceHolder)}
            required
          />
          <FormikField
            autoTrim
            data-testid="creditCardNumber-input"
            id="creditCardNumber"
            label={t(formText.creditCardNumber)}
            maxLength={CREDIT_CARD_NUMBER_LENGTH}
            placeholder="0000"
          />
          <MaskedFormikField
            autoTrim
            data-testid="creditCardExpireDate-input"
            id="creditCardExpireDate"
            label={t(formText.creditCardExpireDate)}
            mask="11/11"
            maxLength={CREDIT_CARD_EXPIRE_DATE}
            placeholder="mm / aa"
            placeholderChar=" "
            type="number"
          />
        </Box>
      )}
    </Formik>
  );
};
