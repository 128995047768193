import { FC } from 'react';
import { useHistory } from 'react-router';

import { AnalyticsEvent } from 'modules/Analytics';
import analyticsService from 'modules/Analytics/services/analyticsService';
import useTranslations from 'modules/I18n/hooks/useTranslations';
import { Dialog, useCloseModal } from 'modules/Modals';
import { PLANS } from 'modules/Subscriptions/models';
import { ROUTES } from 'modules/Subscriptions/routesMap';
import { Box, Img, Link, Text } from 'modules/Ui';
import { ROUTES as DOCUMENT_ROUTES } from 'pages/documents/routesMap';

import { dialogErrorPlan } from '../../messages';

const CreateRecurrentPlanErrorModal: FC = () => {
  const { t } = useTranslations();
  const closeModal = useCloseModal();
  const history = useHistory();
  const handleClose = () => {
    history.push(
      `${DOCUMENT_ROUTES.DOCUMENTS_EXPENSES}?documentKind=RECURRENT`
    );
    closeModal();
  };
  const handleConfirm = () => {
    analyticsService.track(AnalyticsEvent.RECURRENT_CREATE_UPDATE_PLAN);
    closeModal();
  };
  return (
    <Dialog
      id="create-recurrent-planError-Modal"
      onClose={handleClose}
      buttonPrimary={
        <Link
          data-testid="createPlanErrorModal-accept"
          to={`${ROUTES.SUBSCRIPTIONS_CHANGE}/${PLANS.UNLIMITED[1]}`}
          variant="secondary"
          onClick={handleConfirm}
        >
          {t(dialogErrorPlan.button)}
        </Link>
      }
    >
      <Box textAlign="center">
        <Img
          ariaHidden
          height={130}
          loading="lazy"
          marginBottom="24px"
          src="/assets/zero-state-recurrent-expenses.svg"
          width={120}
        />
        <Text
          color="gray800"
          fontSize={18}
          fontWeight="normal"
          lineHeight={28}
          marginBottom="16px"
          tag="h3"
        >
          {t(dialogErrorPlan.title)}
        </Text>
        <Text fontSize={14} lineHeight={20}>
          {t(dialogErrorPlan.text)}
        </Text>
      </Box>
    </Dialog>
  );
};

export default CreateRecurrentPlanErrorModal;
