import { MarkDown, Text } from 'modules/Ui';

export type TextRendererProps = {
  message: string;
  messageFontSize?: number;
  messageLineHeight?: number;
  messageMargin?: string;
  useMarkdown: boolean;
};

export const TextRenderer: React.FC<TextRendererProps> = ({
  message,
  messageFontSize,
  messageLineHeight,
  messageMargin,
  useMarkdown,
}) => {
  if (useMarkdown) {
    return (
      <MarkDown
        fontSize={{ _: 14, sm: 16 }}
        lineHeight={{ _: 19, sm: 22 }}
        color="gray800"
        marginTop="4px"
        text={message}
      />
    );
  }
  return (
    <Text
      color="gray800"
      fontSize={messageFontSize || { _: 14, sm: 16 }}
      lineHeight={messageLineHeight || { _: 19, sm: 22 }}
      margin={messageMargin || '4px 0 0'}
    >
      {message}
    </Text>
  );
};
