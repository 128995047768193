import { useInvalidateAndUpdate } from 'modules/App/hooks/useInvalidateAndUpdate';
import useNotifications from 'modules/Notifications/hooks/useNotifications';
import { ResponseError } from 'types/response-error.type';
import downloadFile from 'utils/downloadFile';
import { getFilenameFromContentDisposition } from 'utils/getFilenameFromContentDisposition';
import requestErrorHandler from 'utils/requestErrorHandler';
import { useBlockingMutation } from 'utils/useBlockingMutation';

import { remittanceNotifications } from '../messages';
import downloadRemittance, {
  invalidateQueries,
} from '../services/dowloadRemittance';

function useDownloadRemittanceXML({
  defaultFileName,
  onSuccess,
}: {
  onSuccess?: () => void;
  defaultFileName: string;
}) {
  const notifications = useNotifications();
  const invalidateAndUpdate = useInvalidateAndUpdate();
  const { mutate, mutateAsync } = useBlockingMutation(downloadRemittance, {
    onSuccess: ({ data, headers }) => {
      onSuccess?.();
      const filename = getFilenameFromContentDisposition(
        headers,
        defaultFileName
      );
      downloadFile(data, filename);
      invalidateAndUpdate({ invalidateQueries, isAsync: true });
    },
    onError: (error: ResponseError) => {
      const { isNetworkError, networkErrorTranslationKey } =
        requestErrorHandler(error);
      return notifications.error(
        isNetworkError
          ? networkErrorTranslationKey
          : remittanceNotifications.submitError.id
      );
    },
  });
  return { mutate, mutateAsync };
}

export default useDownloadRemittanceXML;
