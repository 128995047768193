import { defineMessages } from 'react-intl';

export default defineMessages({
  title: { id: 'gofre.Subscriptions.Create.modal.title' },
  planNotAllowed: { id: 'gofre.Subscriptions.Create.modal.notAllowed' },
  planProductsNotAllowed: {
    id: 'gofre.Subscriptions.Create.Products.modal.notAllowed',
  },
  planNotAllowedPro: { id: 'gofre.Subscriptions.Create.modal.notAllowedPro' },
  planNotAllowedBasic: {
    id: 'gofre.Subscriptions.Create.modal.notAllowedBasic',
  },
  accept: { id: 'gofre.Subscriptions.Create.modal.accept' },
  monthlyTaxes: { id: 'gofre.Subscriptions.monthlyTaxes' },
  yearlyTaxes: { id: 'gofre.Subscriptions.yearlyTaxes' },
  adCountryTax: { id: 'gofre.Subscriptions.adCountryTax' },
  recurrents: { id: 'gofre.Plans.Change.recurrents-not-allowed-in-plan' },
  recurrentExpenses: {
    id: 'gofre.Plans.Change.recurrent-expenses-not-allowed-in-plan',
  },
  recurrents01: { id: 'gofre.Plans.Change.recurrents-not-allowed-in-plan-01' },
  reactivateTitle: { id: 'gofre.Subscriptions.Reactivate.resumeTitle' },
  promoCodeLabel: { id: 'gofre.Plans.Create.promo-code.label' },
  promoCodeValidate: { id: 'gofre.Plans.Create.promo-code.validate' },
  promoCodeValidated: { id: 'gofre.Plans.Create.promo-code.validated' },
  errorValidateCoupon: { id: 'gofre.Subscription.Create.check.promo-code' },
  invalidCoupon: { id: 'gofre.Subscription.Create.promo-code.invalid' },
  shopifyTitle: { id: 'gofre.Plans.Change.wrong-shopify-plan-compatibility' },
  shopifySubtitle: {
    id: 'gofre.Plans.Change.wrong-shopify-plan-compatibility-01',
  },
  apiPublicTitle: {
    id: 'gofre.Plans.Change.wrong-api-public-plan-compatibility',
  },
  apiPublicSubtitle: {
    id: 'gofre.Plans.Change.wrong-api-public-plan-compatibility-01',
  },
  genericScopeError: { id: 'gofre.Plans.Change.generic-scope-error' },
  cancel: { id: 'gofre.Modals.cancel' },
  continue: { id: 'gofre.Modals.continue' },
  remittanceCreationWarningText: {
    id: 'gofre.Subscriptions.CreatePlan.RemittanceCreationWarning.text',
  },
  remittanceCreationWarningSubtitle: {
    id: 'gofre.Subscriptions.CreatePlan.RemittanceCreationWarning.subtitle',
  },
  genericScopeWarningText: {
    id: 'gofre.Subscriptions.CreatePlan.GenericScopeWarning.text',
  },
  productStockEnabled: {
    id: 'gofre.Plans.Change.productStockEnabled.subtitle',
  },
});
