import { getConfig } from 'modules/App/config';
import request from 'utils/request';

import { DocumentSerie, DocumentSerieSort, TargetDocument } from '../models';

interface Payload {
  targetDocument?: TargetDocument;
  sortBy?: DocumentSerieSort;
}
const fetchDocumentSeries = async ({
  targetDocument,
  sortBy,
}: Payload): Promise<DocumentSerie[]> => {
  const endpoint = (await getConfig()).apiDocumentSeriesService;

  let query = 'limit=100';

  if (targetDocument) {
    query += `&query[targetDocument]=${targetDocument}`;
  } else {
    query += `&query[targetDocument][$ne]=${TargetDocument.TEST}`;
  }

  if (sortBy && sortBy.order) {
    query += `&sort[${sortBy.field}]=${sortBy.order}`;
  } else {
    query += `&sort[updatedAt]=DESC`;
  }

  const response = await request<{
    data: {
      items: DocumentSerie[];
      count: number;
    };
  }>(`${endpoint}?${query}`, {
    method: 'GET',
  });

  return response.data.data.items;
};

export default fetchDocumentSeries;
