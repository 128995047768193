import { FC } from 'react';

import styled from 'styled-components';

import { min } from 'modules/Theme/breakpoints';
import useTheme from 'modules/Theme/hooks/useTheme';
import { Box } from 'modules/Ui';
import LoadingIndicator from 'modules/Ui/LoadingIndicator';
import { menuSizes } from 'modules/Ui/Menu/MenuMobile/menuSizes';

import Header from './Header';

const StyledWrapper = styled(Box)`
  height: calc(100vh - ${menuSizes.HEADER_HEIGHT_MOBILE}px);
  /* stylelint-disable-next-line unit-no-unknown */
  height: calc(100dvh - ${menuSizes.HEADER_HEIGHT_MOBILE}px);

  @media ${min(768)} {
    height: auto;
  }
`;

interface LayoutBaseProps {
  loading?: boolean;
  isPublic?: boolean;
}
const LayoutBase: FC<LayoutBaseProps> = ({
  loading,
  children,
  isPublic = false,
}) => {
  const theme = useTheme();
  return (
    <>
      {!loading && !isPublic && <Header />}
      <StyledWrapper
        boxSizing="border-box"
        display={{ sm: 'flex' }}
        justifyContent={{ sm: 'center' }}
        tag="main"
        width="100%"
      >
        <Box
          height={{ _: '100%', sm: 'initial' }}
          margin={{ sm: '0 16px' }}
          maxWidth={{ md: theme.maxWidth.md, lg: theme.maxWidth.lg }}
          overflow={{ sm: 'hidden' }}
          paddingBottom={{ sm: '80px' }}
          width={{ sm: '100%' }}
        >
          {loading ? <LoadingIndicator /> : <>{children}</>}
        </Box>
      </StyledWrapper>
    </>
  );
};

export default LayoutBase;
