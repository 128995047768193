import { ReactNode, FC } from 'react';
import { Visible } from 'react-grid-system';

import useTheme from 'modules/Theme/hooks/useTheme';
import { Box, Hr } from 'modules/Ui';

interface DialogButtonsProps {
  buttonPrimary: ReactNode;
  buttonSecondary?: ReactNode;
  type?: 'empty' | 'fullScreen' | 'default';
}

export const DialogButtons: FC<DialogButtonsProps> = ({
  buttonPrimary,
  buttonSecondary,
  type,
}) => {
  const theme = useTheme();
  return (
    <Box
      display="flex"
      gap={type === 'fullScreen' ? '0' : { _: '16px', sm: '0' }}
      justifyContent={{
        sm: buttonSecondary ? 'space-between' : 'center',
      }}
      flexDirection={{ _: 'column', sm: 'initial' }}
    >
      {type === 'fullScreen' && (
        <Visible xs>
          <Hr
            color={theme.colors.gray200}
            marginBottom="12px"
            transform="translateX(-16px)"
            width="calc(100% + 32px)"
          />
        </Visible>
      )}
      {buttonSecondary}
      {buttonPrimary}
    </Box>
  );
};
