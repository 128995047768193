import { createSelector } from 'reselect';

import { ApplicationRootState } from 'types';

const selectModalsState = (state: ApplicationRootState) => state.modals;

export const selectProps = createSelector(
  selectModalsState,
  (modals) => modals.props
);
