import { getConfig } from 'modules/App/config';
import { DOCUMENTS_TO_REVIEW_QUERY } from 'modules/App/queries/queries';
import request from 'utils/request';

export default async function deleteDocumentToReview(id: string) {
  const endpoint = (await getConfig()).apiDocumentsPendingService;

  const response = await request<any>(`${endpoint}/${id}`, {
    method: 'DELETE',
  });
  return response?.data?.data;
}

export const invalidateQueries = [DOCUMENTS_TO_REVIEW_QUERY];
