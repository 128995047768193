import IconByPath, { IconByPathProps } from './Icon/IconType/IconByPath';

const IconReceived = (props: IconByPathProps) => (
  <IconByPath
    path="m3.414 12.64 3.46 3.461 8.2-10.043 1.55 1.265-9.6 11.756L2 14.055l1.414-1.415ZM21.203 6l1.55 1.265-9.607 11.765-2.21-2.256 1.43-1.4.645.66L21.203 6Z"
    {...props}
  />
);

export default IconReceived;
