import { ReactNode } from 'react';

import {
  space,
  layout,
  LayoutProps,
  flexbox,
  FlexboxProps,
  SpaceProps,
  MarginProps,
} from 'styled-system';

import useTheme from 'modules/Theme/hooks/useTheme';
import styled from 'modules/Theme/styled-components';
import Box from 'modules/Ui/Box';

export interface Item {
  key: string;
  description: string | ReactNode;
  descriptionFontSize?: string;
  descriptionLineHeight?: string;
  itemMargin?: MarginProps['margin'];
  itemTextAlign?: string | {};
  itemHeight?: string | {};
  itemOrder?: string;
  itemWidth?: string | {};
  term: string | ReactNode;
  itemClassName?: string;
  hasSeparator?: string;
  padding?: string;
  dtWidth?: string;
  ddWidth?: string | {};
  ddMinHeight?: string | {};
  flexOrder?: string | {};
}

interface Props extends LayoutProps, FlexboxProps, SpaceProps {
  horizontal?: boolean | {};
  gutter?: {};
  items: Item[];
}

const Wrapper = styled(Box)`
  ${layout}
  ${flexbox}
  ${space}
`;

const DefinitionTerm = ({
  gutter,
  horizontal,
  padding,
  items,
  ...props
}: Props) => {
  const theme = useTheme();

  return (
    <Wrapper
      {...{
        tag: 'dl',
        margin: {
          sm: gutter && '0 -12px',
          lg: gutter && '0 -16px',
          xl: gutter && '0 -20px',
        },
      }}
      {...{ items, ...props }}
    >
      {items.map(
        ({
          description,
          descriptionFontSize,
          descriptionLineHeight,
          itemMargin,
          itemTextAlign,
          itemWidth,
          itemHeight,
          term,
          itemClassName,
          hasSeparator,
          dtWidth,
          ddWidth,
          ddMinHeight,
          key,
          flexOrder,
        }) => {
          return (
            <Box
              key={key}
              data-testid={`${key}-definition`}
              {...{
                alignItems: horizontal ? 'center' : undefined,
                className: itemClassName || 'definitionTerm__wrapper',
                boxSizing: gutter ? 'border-box' : undefined,
                display: horizontal ? 'flex' : undefined,
                marginBottom: horizontal ? undefined : { _: '16px', sm: '0' },
                justifyContent: horizontal ? 'inherit' : undefined,
                margin: itemMargin,
                padding: {
                  sm: gutter ? '0 12px' : undefined,
                  lg: gutter ? '0 16px' : undefined,
                  xl: gutter ? '0 20px' : undefined,
                },
                textAlign: itemTextAlign,
                width: itemWidth,
                height: itemHeight,
                borderBottom: hasSeparator,
                order: flexOrder,
              }}
            >
              <Box
                {...{
                  color: theme.colors.gray600,
                  fontSize: theme.fontSizes[14],
                  lineHeight: theme.lineHeights[22],
                  tag: 'dt',
                  width: dtWidth,
                }}
              >
                {term}
              </Box>
              <Box
                {...{
                  color: theme.colors.gray800,
                  fontSize: descriptionFontSize || theme.fontSizes[18],
                  lineHeight: descriptionLineHeight || theme.lineHeights[28],
                  margin: horizontal ? '0' : '4px 0',
                  padding: padding || (horizontal ? undefined : '0 0 0 16px'),
                  tag: 'dd',
                  width: ddWidth,
                  minHeight: ddMinHeight,
                }}
              >
                {description}
              </Box>
            </Box>
          );
        }
      )}
    </Wrapper>
  );
};

export default DefinitionTerm;
