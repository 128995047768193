import { defineMessages } from 'react-intl';

import { DefineMessages } from 'modules/I18n/types';

export default defineMessages({
  subtitle: { id: 'gofre.Subscriptions.Create.subtitle' },
  info: { id: 'gofre.Subscriptions.Create.info' },
  number: { id: 'gofre.Subscriptions.Create.number' },
  numberPlaceholder: { id: 'gofre.Subscriptions.Create.number.placeholder' },
  step1: { id: 'gofre.Subscriptions.Create.step1' },
  step1Cancel: { id: 'gofre.Subscriptions.Create.step1.cancel' },
  step1Continue: { id: 'gofre.Subscriptions.Create.step1.continue' },
  step1FormOffer: { id: 'gofre.Subscriptions.Create.step1.form-offer' },
  step1FormRadioMonthly: {
    id: 'gofre.Subscriptions.Create.step1.form-period-monthly',
  },
  step1FormRadioYearly: {
    id: 'gofre.Subscriptions.Create.step1.form-period-yearly',
  },
  step1FormTitle: { id: 'gofre.Subscriptions.Create.step1.form-title' },
  step2: { id: 'gofre.Subscriptions.Create.step2' },
  sumup: { id: 'gofre.Subscriptions.Create.sumup' },
  title: { id: 'gofre.Subscriptions.Create.title' },
  basicPlan: { id: 'gofre.Subscriptions.Plan.basic' },
  navigation: { id: 'gofre.Subscriptions.Create.Navigation.title' },
});

export const resume = defineMessages({
  paymentResumeTitle: { id: 'gofre.Subscriptions.Create.payment.title' },
  deleteCoupon: { id: 'gofre.Subscriptions.Create.Coupon.delete' },
  paymentResumeExplanationMonth: {
    id: 'gofre.Subscriptions.Create.payment.explanation.month',
  },
  paymentResumeExplanationYear: {
    id: 'gofre.Subscriptions.Create.payment.explanation.year',
  },
  paymentResumeExplanation: {
    id: 'gofre.Subscriptions.Create.payment.explanation',
  },
  totalToPayAnnualy: { id: 'gofre.Subscriptions.Create.totalToPayAnnualy' },
  totalToPayMonthly: { id: 'gofre.Subscriptions.Create.totalToPayMonthly' },
  annual: { id: 'gofre.Subscriptions.Plan.period.annual' },
  monthly: { id: 'gofre.Subscriptions.Plan.period.monthly' },
  prorationReceivable: { id: 'gofre.Subscriptions.Resume.prorationReceivable' },
  prorationPayable: { id: 'gofre.Subscriptions.Resume.prorationPayable' },
  creditBalance: { id: 'gofre.Subscriptions.Resume.creditBalance' },
  total: { id: 'gofre.Subscriptions.Resume.total' },
  subtotal: { id: 'gofre.Subscriptions.Resume.subtotal' },
  monthlyAmount: { id: 'gofre.Ui.SubscriptionPlan.monthlyTaxes' },
  yearlyAmount: { id: 'gofre.Ui.SubscriptionPlan.yearlyTaxes' },
  totalToPay: { id: 'gofre.Subscriptions.Resume.totalToPay' },
  prorationTotal: { id: 'gofre.Subscriptions.Resume.prorationTotal' },
  couponName: { id: 'gofre.Subscriptions.Create.Coupon.Description.name' },
});

export const payment: DefineMessages = defineMessages({
  cardError: {
    id: 'gofre.Subscriptions.Create.payment.card-error',
  },
  vatNumberError: {
    id: 'gofre.Subscriptions.Create.payment.vat-number-error',
  },
  findCouponError: {
    id: 'gofre.Subscriptions.Create.payment.find-coupon-error',
  },
  genericError: { id: 'gofre.Subscriptions.Create.payment.error' },
  processing: { id: 'gofre.Subscriptions.Create.payment.processing' },
  subscriptionsExist: {
    id: 'gofre.Subscriptions.Create.payment.subscriptions-exist',
  },
  planNotAllowed: { id: 'gofre.Subscriptions.Create.payment.plan-not-allowed' },
});

export const dialogs = defineMessages({
  title: { id: 'gofre.Subscriptions.Create.Dialog.title' },
  subtitle: { id: 'gofre.Subscriptions.Create.Dialog.subtitle' },
  text: { id: 'gofre.Subscriptions.Create.Dialog.text' },
  cancel: { id: 'gofre.Subscriptions.Create.Dialog.cancel' },
  confirm: { id: 'gofre.Subscriptions.Create.Dialog.confirm' },
});

export const couponTexts = defineMessages({
  couponRepeating: {
    id: 'gofre.Subscriptions.Create.Coupon.Description.repeating',
  },
  couponOnce: { id: 'gofre.Subscriptions.Create.Coupon.Description.once' },
  couponForever: {
    id: 'gofre.Subscriptions.Create.Coupon.Description.forever',
  },
});
