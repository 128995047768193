import { useQuery, useQueryClient } from 'react-query';

import { MILESTONES } from 'modules/App/queries/queries';
import CacheTimes from 'utils/query/cacheTimes';

import fetchMilestones, {
  MilestonesResponse,
} from '../services/fetchMilestones/fetchMilestones';

export default function useMilestones() {
  const { data: milestones } = useQuery(MILESTONES, fetchMilestones, {
    staleTime: CacheTimes.ONE_HOUR,
    refetchOnMount: false,
  });
  return { milestones };
}

export function useSetQueryMilestones() {
  const queryClient = useQueryClient();
  const setQueryData = (data: MilestonesResponse) => {
    queryClient.setQueryData(MILESTONES, data);
  };

  return { setQueryData };
}
