import { useCallback, useState } from 'react';

import { DigitLimits } from 'modules/App/constants';
import { useDeletedContactMiddleware } from 'modules/Contacts/hooks/useDeletedContactMiddleware';
import {
  Document,
  DocumentPaidStatus,
  DocumentStatus,
  DocumentType,
} from 'modules/Documents/models/document';
import { useOpenModal } from 'modules/Modals';
import { ModalTypes } from 'modules/Modals/constants';
import { PaymentMethodEnum } from 'modules/Payments';
import { roundNumber } from 'utils/MathOperations';

import messages from '../components/DocumentPayments/messages';

interface PaymentData {
  amount?: number;
  paymentMethod?: PaymentMethodEnum;
  operationDate?: Date;
  accountingAccountId?: string;
  accountingAccountAlias?: string;
}

type Options = {
  multiple?: boolean;
  documentData?: Document;
  onSuccess?: (paymentData: PaymentData) => void;
  multipleDeleteParams?: {
    documentTypes?: DocumentType[];
    ids: string[];
    status?: DocumentStatus;
  };
};

function useDocumentCreatePayment(
  options: Options
): [(options: Options) => Promise<void>, boolean] {
  const openModal = useOpenModal();
  const { deletedContactMiddleware } = useDeletedContactMiddleware();
  const [isLoading, setIsLoading] = useState(false);

  const handleCreatePayment = useCallback(
    async (callbackOptions: Options): Promise<void> => {
      const documentData = callbackOptions.documentData ?? options.documentData;
      const multiple = callbackOptions.multiple ?? options.multiple;
      const onSuccess = (paymentData: PaymentData): void => {
        setIsLoading(false);
        callbackOptions.onSuccess?.(paymentData) ??
          options.onSuccess?.(paymentData);
      };
      const multipleDeleteParams =
        callbackOptions.multipleDeleteParams ?? options.multipleDeleteParams;
      if (multiple) {
        openModal({
          type: ModalTypes.CREATE_DOCUMENT_PAYMENT,
          multiple: true,
          onSuccess: onSuccess ?? (() => {}),
          multipleDeleteParams,
          onError: () => {
            setIsLoading(false);
          },
        });
        return;
      }
      if (
        !documentData ||
        documentData.paidStatus === DocumentPaidStatus.PAID
      ) {
        return;
      }

      await deletedContactMiddleware({
        nextStep: () => {
          const amount =
            Math.abs(documentData.totals.total) -
            (documentData.totalAmountFromPayments ?? 0);
          openModal({
            type: ModalTypes.CREATE_DOCUMENT_PAYMENT,
            amount: roundNumber(amount, DigitLimits.DECIMAL_DIGITS),
            document: documentData,
            onSuccess: onSuccess ?? (() => {}),
            onError: () => {
              setIsLoading(false);
            },
          });
        },

        contactId: documentData.contact?.id,
        messageNotification: messages.noContact.id,
      });
    },
    [options.documentData]
  );

  return [handleCreatePayment, isLoading];
}

export default useDocumentCreatePayment;
