import { getConfig } from 'modules/App/config';
import request from 'utils/request';

export const fetchClientSecret = async (): Promise<string> => {
  const endpoint = (await getConfig()).apiStripeService;

  const response = await request<{ data: string }>(`${endpoint}/secret`, {
    method: 'GET',
  });

  return response.data.data;
};
