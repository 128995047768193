import { getConfig } from 'modules/App/config';
import { UserNotification } from 'modules/UserNotifications/models/models';
import request from 'utils/request';

export const fetchNewNotifications = async () => {
  const endpoint = (await getConfig()).apiUserNotificationsService;
  const {
    data: { data: response },
  } = await request<{
    data: { items: UserNotification[]; count: number; countUnread: number };
  }>(`${endpoint}/not-sent`, {
    method: 'GET',
  });

  return response;
};
