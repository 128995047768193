import { useQuery } from 'react-query';

import { PLANS_QUERY } from 'modules/App/queries/queries';
import useNotifications from 'modules/Notifications/hooks/useNotifications';

import texts from '../messages';
import { fetchSubscriptionsPlans } from '../services';

export const useFetchPlans = () => {
  const notifications = useNotifications();

  const { isLoading, data } = useQuery(
    PLANS_QUERY,
    () => fetchSubscriptionsPlans(),
    {
      onError: () => {
        notifications.error(texts.error.id);
      },
    }
  );
  return { isLoading, data };
};
