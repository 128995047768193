import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  deleteError: { id: 'gofre.User.Delete.deleteError' },
  notificationsSuccess: {
    id: 'gofre.dialog.Delete.Modal.owner.notifications.success',
  },
  notificationsError: {
    id: 'gofre.dialog.Delete.Modal.owner.notifications.error',
  },
});
