import { useState, useEffect, FC } from 'react';

import useTranslations from 'modules/I18n/hooks/useTranslations';
import { Box } from 'modules/Ui';

import { shopifyForm } from '../../messages';
import { IntegrationFormToogle } from '../IntegrationFormToogle';

interface Props {
  isMarked?: boolean;
}

export const ShopifyFormOptions: FC<Props> = ({ isMarked = false }) => {
  const { t } = useTranslations();
  const [includeReference, setIncludeReference] = useState(isMarked);
  const [documentSend, setDocumentSend] = useState(isMarked);
  useEffect(() => {
    setIncludeReference(isMarked);
  }, [isMarked]);
  useEffect(() => {
    setDocumentSend(isMarked);
  }, [isMarked]);
  return (
    <Box
      border="none"
      marginBottom={{ _: '64px', sm: '48px' }}
      padding="0"
      tag="fieldset"
    >
      <Box
        color="primary300"
        fontSize={12}
        lineHeight={22}
        marginBottom="8px"
        tag="legend"
        textTransform="uppercase"
      >
        {t(shopifyForm.seriesOptionLegend)}
      </Box>
      <IntegrationFormToogle
        id="includeOrderReference"
        marginBottom={{ _: '32px', sm: '24px' }}
        name="includeOrderReference"
        onChange={() => {
          setIncludeReference(!includeReference);
        }}
        text={t(shopifyForm.seriesToogle01label)}
        textDescription={t(shopifyForm.seriesToogle01Desc)}
        value={includeReference}
      />
      <IntegrationFormToogle
        id="sendInvoiceMailToCustomers"
        name="sendInvoiceMailToCustomers"
        onChange={() => {
          setDocumentSend(!documentSend);
        }}
        text={t(shopifyForm.seriesToogle02label)}
        textDescription={t(shopifyForm.seriesToogle02Desc)}
        value={documentSend}
      />
    </Box>
  );
};
