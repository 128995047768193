import { StatusInfoUIPayments } from 'modules/Documents/models/statusInfo';
import useTheme from 'modules/Theme/hooks/useTheme';

import messages from './messages';

const usePaymentStatusMapping = (status: string) => {
  const theme = useTheme();

  if (
    status === StatusInfoUIPayments.LINKED ||
    status === StatusInfoUIPayments.NOT_LINKED
  ) {
    return {
      message: messages.complete,
      color: theme.colors.tagsConfirmationAccesible,
      backgroundColor: theme.colors.tagsConfirmationA10,
    };
  }

  if (status === StatusInfoUIPayments.PARTIALLY_LINKED) {
    return {
      message: messages.partial,
      color: theme.colors.pending,
      backgroundColor: theme.colors.warningBackground,
    };
  }

  return {
    message: null,
    color: '',
    backgroundColor: '',
  };
};

export default usePaymentStatusMapping;
