import { FC, createElement } from 'react';
import { Hidden } from 'react-grid-system';
import { useDispatch, useSelector } from 'react-redux';

import { Formik } from 'formik';

import { isDuplicatedVatNumberOnPostBusiness } from 'modules/Business/Create/services/createBusiness';
import { getBusiness } from 'modules/Business/Current/actions';
import { selectCurrentBusiness } from 'modules/Business/Current/selectors';
import businessMessages from 'modules/Business/Update/messages';
import updateBusiness from 'modules/Business/Update/services/updateBusiness';
import { shouldFill140Document } from 'modules/Documents/components/TwoStepsForm/FirstStep/components/TicketBaiOptions/helpers';
import useTranslations from 'modules/I18n/hooks/useTranslations';
import { useCloseModal, MODAL_WIDTH_DEFAULT, Dialog } from 'modules/Modals';
import { createNotification } from 'modules/Notifications/actions';
import { NotificationType } from 'modules/Notifications/models';
import { Box, Button, FormikField, Text, Warning } from 'modules/Ui';
import requestErrorHandler from 'utils/requestErrorHandler';

import { ChangeVatNumberModalProps } from './ChangeVatNumberModalProps';
import messages from './messages';

const ChangeVatNumberModal: FC<ChangeVatNumberModalProps> = ({ vatNumber }) => {
  const business = useSelector(selectCurrentBusiness);
  const dispatch = useDispatch();
  const { t } = useTranslations();
  const closeModal = useCloseModal();
  const initialValues = {
    vatNumber,
  };
  return (
    <Dialog
      {...{
        type: 'fullScreen',
        dialogWidth: { sm: MODAL_WIDTH_DEFAULT },
        content: (
          <>
            <Warning
              message={t(messages.warning, {
                bold: (chunk) =>
                  createElement(Text, {
                    children: chunk,
                    color: 'inherit',
                    fontSize: 'inherit',
                    lineHeight: 'inherit',
                    tag: 'strong',
                  }),
              })}
              marginBottom="16px"
            />
            <Formik
              onSubmit={async (values, actions) => {
                try {
                  const { address, fiscalName, phoneNumber } = business;
                  const normalized = {
                    address,
                    fiscalName,
                    phoneNumber,
                    vatNumber: values.vatNumber,
                  };
                  const updatedBusiness = await updateBusiness(normalized);
                  const shouldFill140 = shouldFill140Document(
                    updatedBusiness.vatNumber,
                    updatedBusiness.electronicDocumentProvider
                  );
                  dispatch(getBusiness.request());
                  if (shouldFill140) {
                    dispatch(
                      createNotification({
                        type: NotificationType.SUCCESS,
                        message: t(messages.successShouldFill140),
                      })
                    );
                  } else {
                    dispatch(
                      createNotification({
                        type: NotificationType.SUCCESS,
                        message: t(messages.successShouldNotFill140),
                      })
                    );
                  }
                  closeModal();
                } catch (e: any) {
                  const errorHandled = requestErrorHandler(e);
                  const { isNetworkError, networkErrorTranslationKey } =
                    errorHandled;
                  const duplicatedVatNumberOnPostBusiness =
                    isDuplicatedVatNumberOnPostBusiness(errorHandled);
                  const message = duplicatedVatNumberOnPostBusiness
                    ? businessMessages.updateVatNumberError.id
                    : businessMessages.koMessage.id;

                  if (duplicatedVatNumberOnPostBusiness) {
                    actions.setFieldError(
                      'vatNumber',
                      t(messages.updateVatNumberErrorShort)
                    );
                  } else {
                    dispatch(
                      createNotification({
                        type: NotificationType.ERROR,
                        message: isNetworkError
                          ? networkErrorTranslationKey
                          : message,
                      })
                    );
                  }
                }
              }}
              validateOnChange={false}
              validateOnBlur={false}
              enableReinitialize
              initialValues={initialValues}
              //   validationSchema={validationSchema}
            >
              {(formik) => (
                <Box
                  noValidate
                  onSubmit={formik.handleSubmit}
                  tag="form"
                  id="change-vat-number-form"
                >
                  <FormikField
                    id="vatNumber"
                    label={t(messages.vatNumber)}
                    maxLength={9}
                    name="vatNumber"
                    required
                    width={{ _: '100%', sm: '50%' }}
                  />
                </Box>
              )}
            </Formik>
          </>
        ),
        id: 'document-vat-number-change',
        title: t(messages.title),
        onClose: closeModal,
        buttonPrimary: (
          <Button
            data-testid="document-vat-number-change-submit"
            variant="secondary"
            type="submit"
            form="change-vat-number-form"
          >
            {t(messages.submit)}
          </Button>
        ),
        buttonSecondary: (
          <Hidden xs>
            <Button
              data-testid="document-vat-number-change-cancel"
              variant="stroke"
              type="button"
              onClick={closeModal}
            >
              {t(messages.cancel)}
            </Button>
          </Hidden>
        ),
      }}
    />
  );
};

export default ChangeVatNumberModal;
