import { FC, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import useTranslations from 'modules/I18n/hooks/useTranslations';
import { useCloseModal } from 'modules/Modals';
import { showModal } from 'modules/Modals/actions';
import { ModalTypes } from 'modules/Modals/constants';
import useTheme from 'modules/Theme/hooks/useTheme';
import { Button } from 'modules/Ui';
import { IconTrash } from 'modules/Ui/Icons';
import history from 'utils/history';

import messages from '../messages';

interface ButtonDeleteProps {
  paymentId: string;
  onClose?: () => void;
  inMemory?: boolean;
  onDelete?: () => void;
}

export const ButtonDelete: FC<ButtonDeleteProps> = ({
  paymentId,
  onClose,
  inMemory,
  onDelete,
}) => {
  const { t } = useTranslations();
  const dispatch = useDispatch();
  const closeModal = useCloseModal();
  const openDeleteModal = useCallback(() => {
    if (inMemory) {
      onDelete?.();
      closeModal();
      return;
    }
    dispatch(
      showModal({
        type: ModalTypes.DELETE_PAYMENTS,
        ids: [paymentId],
        excludeIds: false,
        onClose,
      })
    );
  }, [dispatch, paymentId, history]);
  const theme = useTheme();
  return (
    <Button
      color={theme.colors.error}
      icon={<IconTrash />}
      onClick={openDeleteModal}
      marginBottom={{ sm: '24px' }}
      data-testid="delete-payment-button"
    >
      {t(messages.delete)}
    </Button>
  );
};
