import { getConfig } from 'modules/App/config';
import buildBaseIdsQuery from 'modules/App/queries/buildBaseIdsQuery';
import {
  CONTACTS_QUERY,
  DASHBOARD_QUERY,
  DOCUMENTS_QUERY,
  DOCUMENT_TRACKING_QUERY,
  PAYMENTS_QUERY,
} from 'modules/App/queries/queries';
import { buildTortillaBaseQuery } from 'utils/query/buildTortillaBaseQuery';
import request from 'utils/request';

import { Payment, PaymentFilters, PaymentSort } from '../models';

export default async function deletePayments({
  excludeIds,
  page,
  pageSize,
  sortBy,
  filters,
}: {
  excludeIds: boolean;
  page?: number;
  pageSize?: number;
  sortBy?: PaymentSort;
  searchTerm?: string;
  filters?: PaymentFilters;
}): Promise<{ items: Payment[]; count: number }> {
  const endpoint = (await getConfig()).apiPaymentsService;

  let query = buildTortillaBaseQuery({
    page,
    pageSize,
    sort: [
      {
        field: sortBy?.field,
        order: sortBy?.order,
      },
    ],
  });

  if (filters?.documentId) {
    query += `&query[documents][$in][0]=${filters?.documentId}`;
  }

  if (filters?.ids) {
    query += buildBaseIdsQuery(filters?.ids, excludeIds);
  }

  const response = await request<any>(`${endpoint}?${query}`, {
    method: 'DELETE',
  });
  return response?.data?.data as { items: Payment[]; count: number };
}

export const invalidateQueries = [
  PAYMENTS_QUERY,
  DOCUMENTS_QUERY,
  DASHBOARD_QUERY,
  DOCUMENT_TRACKING_QUERY,
  CONTACTS_QUERY,
];
