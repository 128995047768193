import { FC, ReactElement, useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import { identity, some, values } from 'lodash';

import { UserPermissions } from 'modules/Auth/constants';
import useHasUserScope from 'modules/Auth/hooks/checkUsersPermissions';
import { ExpenseTab } from 'modules/Documents/models/expenses';
import useTranslations from 'modules/I18n/hooks/useTranslations';
import { ValidGenders } from 'modules/I18n/types/validGenders';
import { min, max } from 'modules/Theme/breakpoints';
import ellipsis from 'modules/Theme/mixins/ellipsis';
import styled from 'modules/Theme/styled-components';
import { Box, Spinner } from 'modules/Ui';
import DataTable from 'modules/Ui/DataTable/DataTable';
import {
  DataTableAction,
  DataTableColumn,
} from 'modules/Ui/DataTable/DataTable.models';
import DataTableFiltersWrapper from 'modules/Ui/DataTable/DataTableFiltersWrapper';

import {
  DocumentTable,
  DocumentFilters,
  DocumentType,
  DocumentPaidStatus,
} from '../../../models/document';
import useExpenseListConfig from '../../hooks/expenses/useExpenseListConfig';
import {
  CustomExpensesBulkActionsProps,
  useExpensesBulkActions,
} from '../../hooks/expenses/useExpensesBulkActions';
import useDocumentListDataTable from '../../hooks/useDocumentListDataTable';
import { filter, zeroResults } from '../../messages';
import { ExpensesQueryParamsState } from '../../types';
import ExpensesZeroState from '../DocumentsZeroState/ExpensesZeroState';
import DocumentsFilterForm from '../Filter/DocumentsFilterForm';
import TagList from '../Filter/Tags/TagList';

interface ExpensesListProps {
  allowSelectAll?: boolean;
  className?: string;
  filtersActive: boolean;
  closeFilters: () => void;
  setFiltersDisabled: (status: boolean) => void;
  updateQueryParams: (event: DocumentFilters) => void;
  queryParamsState: ExpensesQueryParamsState;
  searchTerm: string;
  CustomZeroState?: ReactElement;
  customBulkActions?: (
    props: CustomExpensesBulkActionsProps
  ) => DataTableAction<DocumentTable>[];
  customHandleSelectionChange?: (newSelected: DocumentTable[]) => void;
  extraQuery?: string;
  customOnSelect?: () => void;
  customColumns?: DataTableColumn<DocumentTable, keyof DocumentTable>[];
  preSelectedDocuments?: DocumentTable[];
}

const StyledExpensesTable = styled(Box)`
  @media ${max(767)} {
    .data__table {
      &--code { grid-area: code;}
      &--provider { grid-area: provider;}
      &--issuedDate { grid-area: issuedDate;}
      &--total {grid-area: total;}
      &--status { grid-area: status;}
      &--category { grid-area: category;}
      &--dueDate { display: none }
    }

    tbody tr {
      grid-template-columns: 1fr auto;
      grid-template-rows: auto auto 24px;
      grid-template-areas:
        "code      issuedDate"
        "provider  total"
        "category  status";
    }
  }

  .data__table--fiscalName,
  .data__table--category {
    ${ellipsis}
  }

  @media ${min(768)} and ${max(1023)} {
    .data__table--category {
      display: none;
    }
  }

  @media ${max(1279)} {
    .data__table--dueDate {
      display: none;
    }
  }
`;

const ExpensesList: FC<ExpensesListProps> = ({
  allowSelectAll,
  className,
  filtersActive,
  closeFilters,
  setFiltersDisabled,
  queryParamsState,
  updateQueryParams,
  searchTerm,
  CustomZeroState,
  customBulkActions,
  customHandleSelectionChange,
  extraQuery,
  customOnSelect,
  customColumns,
  preSelectedDocuments,
}) => {
  const currentTab = ExpenseTab.UPLOADED;
  const { t } = useTranslations();
  const history = useHistory();
  const { hasUserScope } = useHasUserScope();
  const [isSomeFreezedSelected, setIsSomeFreezedSelected] = useState(false);
  const isUserEditor = hasUserScope(UserPermissions.SUBSCRIPTION_EDITOR);
  const isUserReader = hasUserScope(UserPermissions.SUBSCRIPTION_BASE);
  const [showBigLoading, setShowBigLoading] = useState(true);
  const [isSomeNegativeSelected, setIsSomeNegativeSelected] = useState(false);
  const [isSomePaidSelected, setIsSomePaidSelected] = useState(false);
  const [selectionInverted, setSelectionInverted] = useState(false);

  const {
    isLoading,
    data,
    pagination,
    sortBy,
    filters,
    onPageSizeChange,
    onPageChange,
    setSortBy,
    setFilters,
    deleteDocuments,
    downloadDocument,
    downloadZip,
    downloadExcel,
    editDocument,
    sortOutDocument,
    copyDocument,
    createPayment,
    createRemittance,
  } = useDocumentListDataTable({
    isExpensesList: true,
    queryParams: queryParamsState[currentTab],
    searchTerm,
    updateQueryParams,
    extraQuery,
  });

  const { columns } = useExpenseListConfig();

  const handleInvertedSelectionChange = useCallback(
    (newInvertedStatus) => {
      setSelectionInverted(newInvertedStatus);
    },
    [setSelectionInverted]
  );

  const handleSelectionChangeCallback: (newSelected: DocumentTable[]) => void =
    useCallback(
      (newSelection) => {
        setIsSomeFreezedSelected(newSelection.some((xs) => xs.isFreezed));
        setIsSomePaidSelected(
          newSelection.some((xs) => xs.paidStatus === DocumentPaidStatus.PAID)
        );
        setIsSomeNegativeSelected(
          newSelection.some((xs) => xs.totals.total < 0)
        );
      },
      [setIsSomeFreezedSelected, setIsSomePaidSelected]
    );

  const handleSelectionChange =
    customHandleSelectionChange ?? handleSelectionChangeCallback;

  const bulkActions = useExpensesBulkActions({
    t,
    filters,
    isUserEditor: !!isUserEditor,
    isUserReader: !!isUserReader,
    isSomeFreezedSelected,
    isSomePaidSelected,
    isSomeNegativeSelected,
    selectionInverted,
    deleteDocuments,
    editDocument,
    copyDocument,
    downloadZip,
    createPayment,
    downloadExcel,
    downloadDocument,
    sortOutDocument,
    customBulkActions,
    createRemittance,
  });

  function handleChangeSearch(event: DocumentFilters) {
    handleTagsChanged({ ...filters, ...event, searchTerm });
    closeFilters();
  }

  function handleTagsChanged(event: DocumentFilters) {
    setFilters({ ...event, searchTerm });
  }
  const { counterparty, ...mainFilters } = filters;
  const hasFilters = some(values(mainFilters), identity);
  const showZeroState = data && !data.count && !isLoading && !hasFilters;

  useEffect(() => {
    setFiltersDisabled(Boolean(showZeroState));
  }, [showZeroState, setFiltersDisabled]);

  useEffect(() => {
    setShowBigLoading(false);
  }, [data, setShowBigLoading, isLoading]);

  if (isLoading && showBigLoading) {
    return (
      <Box position="relative" height="440px">
        <Spinner />
      </Box>
    );
  }

  if (showZeroState) {
    setFiltersDisabled(true);
    return CustomZeroState || <ExpensesZeroState />;
  }

  return (
    <>
      <DataTableFiltersWrapper
        modalTitle={t(filter.dialogDescription)}
        open={filtersActive}
        closeFilters={closeFilters}
        id="expensesFilter"
      >
        <DocumentsFilterForm
          searchTerm={filters.searchTerm || ''}
          statusInfo={filters.statusInfo}
          issuedDateFrom={filters.issuedDateFrom}
          issuedDateTo={filters.issuedDateTo}
          minAmount={filters.minAmount}
          maxAmount={filters.maxAmount}
          dueDate={filters.dueDate}
          onSubmit={handleChangeSearch}
          onCancel={closeFilters}
          documentType={DocumentType.EXPENSE}
        />
      </DataTableFiltersWrapper>
      <TagList
        {...{
          onChangeSearch: handleTagsChanged,
          filters,
          documentGender: ValidGenders.M,
          documentType: DocumentType.EXPENSE,
        }}
      />
      <StyledExpensesTable>
        <DataTable<DocumentTable, keyof DocumentTable>
          allowSelectAll={allowSelectAll}
          multiple
          keyName="id"
          className={className}
          columns={customColumns ?? columns}
          actions={bulkActions}
          data={data?.items || []}
          page={pagination.page}
          pageSize={pagination.pageSize}
          totalCount={data?.count || 0}
          sortBy={sortBy}
          loading={isLoading}
          emptyDataTitle={t(zeroResults.title)}
          emptyDataSubTitle={t(zeroResults.subtitle)}
          filters={filters}
          onInvertedChange={handleInvertedSelectionChange}
          onSelect={(d) =>
            customOnSelect ??
            history.push({
              pathname: `/documents/expenses/${d.id}`,
              state: queryParamsState,
            })
          }
          onSelectionChange={handleSelectionChange}
          onPageSizeChange={onPageSizeChange}
          onPageChange={onPageChange}
          onSortChange={(p) => {
            setSortBy(p);
          }}
          initialSelection={preSelectedDocuments}
        />
      </StyledExpensesTable>
    </>
  );
};

export default ExpensesList;
