import { createAction } from 'typesafe-actions';

import ActionTypes, { BASQUE, SPANISH } from './constants';

const localeMapper: { [key: string]: string | undefined } = {
  ES: SPANISH,
  EU: BASQUE,
};

export const setLocale = createAction(
  ActionTypes.SET_LOCALE,
  (locale: string) => localeMapper[String(locale).toUpperCase()] ?? locale
)();
