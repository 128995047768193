import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router';

import { LocationDescriptor } from 'history';

export const SlowRedirect: React.FC<{
  key: number;
  to: LocationDescriptor<unknown>;
}> = ({ key, to }) => {
  const [activateRedirect, setActivateRedirect] = useState(false);
  useEffect(() => {
    const interval = setTimeout(() => setActivateRedirect(true), 100);
    return () => clearInterval(interval);
  }, []);

  if (!activateRedirect) {
    return null;
  }
  return <Redirect key={key} to={to} />;
};
