import { FC, useCallback } from 'react';

import { FormikProps } from 'formik';

import { DocumentSendInfo } from 'modules/Documents/models/document';
import useTranslations from 'modules/I18n/hooks/useTranslations';
import useTheme from 'modules/Theme/hooks/useTheme';
import {
  Box,
  FormikField,
  TextAreaFormikField,
  CheckFormikField,
  Hr,
  FileAttachment,
} from 'modules/Ui';
import ErrorListener from 'modules/Ui/Formik/ErrorListener';
import { IconPdf } from 'modules/Ui/Icons';
import { preventDefaultOnEnterKeyPress } from 'utils/events';

import { CarbonCopyBlock } from '../../CarbonCopyBlock';
import { MAX_CHARACTERS_CONTENT_SEND } from '../../constants';
import messages from './messages';

interface Props {
  formik: FormikProps<DocumentSendInfo>;
  userName: string;
  customerName: string;
}

const Form: FC<Props> = ({ formik, customerName, userName }) => {
  const { t } = useTranslations();
  const theme = useTheme();
  const subjectPlaceholder = t(messages.subject, { name: userName });
  const onKeyPress = useCallback(preventDefaultOnEnterKeyPress, []);

  return (
    <Box tag="section" paddingBottom="16px">
      <ErrorListener notifyErrors={false} />
      <form
        onSubmit={formik.handleSubmit}
        noValidate
        id="config-send-recurrent-form"
        data-testid="config-send-recurrent-form"
      >
        <FormikField
          id="to"
          data-testid="to"
          label={t(messages.to)}
          required
          name="to"
          onKeyPress={onKeyPress}
          placeholder={t(messages.emailPlaceholder)}
          maxLength={100}
        />
        <CarbonCopyBlock />
        <FormikField
          id="subject"
          data-testid="subject"
          placeholder={subjectPlaceholder}
          name="subject"
          onKeyPress={onKeyPress}
          label={t(messages.subjectLabel)}
          maxLength={200}
          disabled
        />
        <TextAreaFormikField
          label={t(messages.content)}
          id="content"
          name="content"
          rows={{ mobile: 4, desktop: 3 }}
          placeholder={t(messages.contentPlaceholder, { name: customerName })}
          maxLength={MAX_CHARACTERS_CONTENT_SEND}
        />
        <CheckFormikField
          id="showDetails"
          name="showDetails"
          marginBottom="16px"
          label={t(messages.showDetailsLabel)}
        />
      </form>
      <Hr
        color={theme.colors.primary200}
        margin={{ _: '0 -16px 20px', sm: '0 -32px 24px' }}
        width={{ _: 'calc(100% + 32px)', sm: 'calc(100% + 64px)' }}
      />
      <FileAttachment
        clickable={false}
        icon={<IconPdf color="brand500" />}
        marginBottom="32px"
        text={t(messages.invoiceNumberMessage)}
      />
    </Box>
  );
};

export default Form;
