import { defineMessages } from 'react-intl';

export default defineMessages({
  button: { id: 'gofre.Documents.Recurrent.ConfigSend.button' },
  cancel: { id: 'gofre.Documents.Recurrent.ConfigSend.cancel' },
  content: { id: 'gofre.Documents.Recurrent.ConfigSend.content' },
  contentPlaceholder: {
    id: 'gofre.Documents.Recurrent.ConfigSend.content-placeholder',
  },
  emailPlaceholder: {
    id: 'gofre.Documents.Recurrent.ConfigSend.email-placeholder',
  },
  invoiceNumberMessage: {
    id: 'gofre.Documents.Recurrent.ConfigSend.invoice-number-message',
  },
  showDetailsLabel: {
    id: 'gofre.Documents.Recurrent.ConfigSend.show-details-label',
  },
  subject: { id: 'gofre.Documents.Recurrent.ConfigSend.subject' },
  subjectLabel: { id: 'gofre.Documents.Recurrent.ConfigSend.subject-label' },
  title: { id: 'gofre.Documents.Recurrent.ConfigSend.title' },
  to: { id: 'gofre.Documents.Recurrent.ConfigSend.to' },
});
