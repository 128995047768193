import request from 'utils/request';

export type EnoughAssetsResponse = {
  data: {
    enoughAssets: boolean;
    required: string[];
  };
};

export const requestHasEnoughAssets = async (
  url: string
): Promise<EnoughAssetsResponse> => {
  const response = await request<EnoughAssetsResponse>(
    `${url}?hasEnoughAssets=`,
    {
      method: 'POST',
    }
  );

  return response.data;
};
