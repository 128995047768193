import { FC } from 'react';
import { Hidden } from 'react-grid-system';

import useUpdateAccountingAccount from 'modules/AccountingAccounts/hooks/useUpdateAccountingAccount';
import useTranslations from 'modules/I18n/hooks/useTranslations';
import { useCloseModal, Dialog, MODAL_WIDTH_SMALL } from 'modules/Modals';
import { Button } from 'modules/Ui';

import { messages, update } from '../../messages';
import { UpdateForm, AccountingAccountForm } from './components';
import { UpdateAccountingAccountModalProps } from './types';

export const UpdateAccountingAccountModal: FC<
  UpdateAccountingAccountModalProps
> = ({ onUpdateAccountingAccount, onCancel, account }) => {
  const { t } = useTranslations();
  const closeModal = useCloseModal();
  const value = account.type;

  const { mutate: updateAccountingAccount } = useUpdateAccountingAccount(
    (data) => {
      if (onUpdateAccountingAccount) {
        onUpdateAccountingAccount(data.data);
      } else {
        closeModal();
      }
    }
  );
  return (
    <Dialog
      buttonPrimary={
        <Button
          data-testid="update-accountingAccount-submit"
          form={value}
          type="submit"
          variant="secondary"
        >
          {t(messages.updateConfirm)}
        </Button>
      }
      buttonSecondary={
        <Hidden xs>
          <Button
            data-testid="accountingAccount-cancel"
            onClick={onCancel ?? closeModal}
            variant="stroke"
          >
            {t(messages.cancel)}
          </Button>
        </Hidden>
      }
      content={
        <UpdateForm
          onSubmit={async (values: AccountingAccountForm) => {
            updateAccountingAccount({
              ...values,
              id: account.id,
            });
          }}
          account={account}
        />
      }
      dialogWidth={{ sm: MODAL_WIDTH_SMALL }}
      id="updateAccountingAccountDialog"
      onClose={onCancel ?? closeModal}
      title={t(update.title)}
      type="fullScreen"
    />
  );
};
