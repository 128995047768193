import { getConfig } from 'modules/App/config';
import { ShopConfig } from 'modules/Integrations/models';
import request from 'utils/request';

const fetchShopifyConfig = async (): Promise<ShopConfig> => {
  const endpoint = (await getConfig()).apiIntegrationShopifyConfig;

  const response = await request<any>(endpoint, {
    method: 'GET',
  });

  return response.data.data;
};

export default fetchShopifyConfig;
