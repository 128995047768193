import IconByPath, { IconByPathProps } from './Icon/IconType/IconByPath';

const IconActionsDelete = (props: IconByPathProps) => (
  <IconByPath
    {...props}
    clipRule="evenodd"
    fillRule="evenodd"
    path="M22 12c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10Zm1 0c0 6.075-4.925 11-11 11S1 18.075 1 12 5.925 1 12 1s11 4.925 11 11Zm-6.6-.55H7.6v1.1h8.8v-1.1Z"
    shapeRendering="geometricPrecision"
  />
);

export default IconActionsDelete;
