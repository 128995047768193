import IconByString, { IconByStringProps } from './Icon/IconType/IconByString';

const IconUpload = (props: IconByStringProps) => {
  const { ...rest } = props;
  return (
    <IconByString fill="none" {...rest}>
      <path
        d="M11.874 1h.252a6 6 0 0 1 5.712 4.164L18.428 7H19a4 4 0 0 1 0 8H5a4 4 0 0 1 0-8h.571l.59-1.836A6 6 0 0 1 11.875 1ZM6.3 8H5a3 3 0 0 0 0 6h14a3 3 0 1 0 0-6h-1.3l-.814-2.53A5 5 0 0 0 12.126 2h-.252a5 5 0 0 0-4.76 3.47L6.3 8Z"
        fill="currentColor"
        shapeRendering="geometricPrecision"
      />
      <path
        d="m16.24 10.243-.707.707L12.5 7.917l.021 3.998-1 .004-.02-4.007-3.039 3.038-.707-.707L11.998 6l4.242 4.243ZM11.521 21.92l-.023-7.504 1-.004.024 7.504-1 .003Z"
        fill="currentColor"
      />
    </IconByString>
  );
};

export default IconUpload;
