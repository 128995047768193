import { StripeError } from '@stripe/stripe-js';

import { StripePaymentStatus } from 'modules/Subscriptions/detail/constants';
import { TaxKey } from 'modules/Taxes';

import { SubscriptionProduct } from './subscriptionProduct';

interface SubscriptionInvoice {
  status: StripePaymentStatus;
  number: string;
  pdfUrl: string;
  active?: boolean;
}

interface StripeCard {
  brand: string;
  last4: string;
  exp_month: number;
  exp_year: number;
}

interface SubscriptionCommon {
  readonly active: boolean;
  readonly nextPaymentDate?: string;
  readonly price: number;
  readonly products: SubscriptionProduct[];
  readonly providerId: string;
  readonly requestedCancellationAt?: string;
  readonly requestedPauseAt?: string;
  readonly startDate: string;
  readonly taxKey: TaxKey;
  readonly taxPercentage: number;
  readonly partner?: string;
  readonly card?: StripeCard | null;
  nextInvoice?: NextInvoice;
  balance?: number;
  invoices?: SubscriptionInvoice[];
}

interface SubscriptionTrial {
  readonly endDate: string;
  provider: 'trial';
}

interface NextInvoice {
  amountDue?: number;
  appliedBalance: number;
  discount?: number;
  subtotal: number;
  tax: number;
  total: number;
}

interface SubscriptionPaid {
  readonly endDate?: string;
  provider: 'stripe';
}

interface SubscriptionExternal {
  readonly endDate?: string;
  provider: 'external';
}

export type Subscription = SubscriptionCommon &
  (SubscriptionTrial | SubscriptionPaid | SubscriptionExternal);

export class StripeFormError extends Error {
  stripeError: StripeError;

  constructor(stripeError: StripeError) {
    super(stripeError.message);
    this.stripeError = stripeError;
  }
}

export interface SubscriptionTaxes {
  basePrice: number;
  fullPriceWithTax: number;
  priceWithTax: number;
  tax: number;
  taxLabel: string;
  taxLabelReversed: string;
  taxPercentage: number;
  totalToPay: number;
}
