import { ReactNode } from 'react';

import theme from 'modules/Theme';
import hoverEffect from 'modules/Theme/mixins/hoverEffect';
import { css } from 'modules/Theme/styled-components';

import breakpoints, { min } from '../breakpoints';
import underlineHover from './effects/underlineHover';

type Props = {
  variant?:
    | 'primary'
    | 'secondary'
    | 'stroke'
    | 'strokeTransparent'
    | 'link'
    | 'ghost'
    | 'ghostDark'
    | 'light';
  disabled?: boolean;
  icon?: ReactNode;
};

export const buttonStyles = css<Props>`
  align-items : ${({ icon }) => icon && 'center'};
  background-color: var(--button-background);
  border-color: var(--button-border);
  border-radius: 34px;
  border-style: solid;
  border-width: ${({ variant }) => (variant !== 'link' ? '1px' : '0')};
  color: var(--button-color);
  font-size: ${({ variant }) =>
    variant === 'ghost' || variant === 'ghostDark'
      ? theme.fontSizes[14]
      : theme.fontSizes[16]};
  height: 48px;
  justify-content: ${({ icon }) => icon && 'center'};
  line-height: ${({ variant }) =>
    variant !== 'link' && `${theme.lineHeights[28]}`};
  padding: ${({ variant }) =>
    variant === 'primary' || variant === 'secondary' || variant === 'stroke'
      ? '0 40px'
      : '0 14px'};
  position: ${({ variant }) => variant !== 'link' && 'relative'};
  text-align: center;
  text-decoration: none;

  @media ${min(breakpoints.md)} {
    height: 40px;
  }

  svg {
    position: relative;
  }

  ${hoverEffect('34px')}

  @media(hover : hover) {
    &:hover {
      background-color: var(--button-hover-background);
      border-color: var(--button-hover-border);
      color: var(--button-hover-color);
      cursor: ${({ disabled }) => disabled && 'text'};
      svg {
        color: var(--button-hover-color);
        position: relative;
      }
    }
  }

  &:focus {
    background-color: var(--button-focus-background);
    border-color: var(--button-focus-border);
    color: var(--button-focus-color);

    svg {
      color: var(--button-focus-color);
      position: relative;
    }
  }
`;

export const buttonLinkStyles = css<Props>`
  background-color: var(--button-background);
  border-width: 0;
  color: var(--button-color);
  font-size: ${(props) => props.theme.fontSizes[14]};
  line-height: ${(props) => props.theme.fontSizes[22]};
  text-decoration: underline;

  &::first-letter {
    text-transform: none;
  }

  @media (hover: hover) {
    &:hover {
      color: ${(props) => props.theme.colors.brand700};
    }
  }

  ${underlineHover}

  &:disabled {
    @media(hover : hover) {
      &:hover {
        text-decoration: none;
        cursor: text;
      }
    }
  }
`;

export const buttonCustomIconTextStyles = css`
  align-items: center;
  background-color: transparent;
  border-color: transparent;
  border-radius: 34px;
  border-style: solid;
  border-width: 1px;
  color: var(--button-color);
  font-size: ${(props) => props.theme.fontSizes[14]};
  height: 40px;
  justify-content: center;
  line-height: ${(props) => props.theme.lineHeights[22]};
  padding: 0 16px 0 12px;
  text-align: left;

  @media ${min(breakpoints.md)} {
    height: 40px;
  }

  @media(hover : hover) {
    &:hover {
      border-color: var(--button-hover-border);
      text-decoration: none;
    }
  }

  &:focus {
    border-color: var(--button-focus-border);
  }
`;

export default buttonStyles;
