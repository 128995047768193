import { createContext, FC, ReactNode, useContext, useMemo } from 'react';

const DocumentTogglesContext = createContext<
  DocumentTogglesContextValues | undefined
>(undefined);

export interface DocumentTogglesContextValues {
  isCopying?: boolean;
  isDraft?: boolean;
  isEditing?: boolean;
  isIssuing?: boolean;
  isProforma?: boolean;
  isDeliveryNote?: boolean;
  isQuote?: boolean;
  isRecurrent: boolean;
  isTest?: boolean;
  isCorrective?: boolean;
  isCorrected?: boolean;
  isReceipt?: boolean;
  isPartialCorrective?: boolean;
  isReplacing?: boolean;
  isReplaced?: boolean;
  isSubstitute?: boolean;
  isReplacement?: boolean;
  isFromApi?: boolean;
  isIssuingFromDeliveryNote?: boolean;
  isIssuingFromProforma?: boolean;
  isIssuingMultiple?: boolean;
}

interface DocumentTogglesProviderProps {
  children: ReactNode;
  isCopying?: boolean;
  isDraft?: boolean;
  isEditing?: boolean;
  isIssuing?: boolean;
  isProforma?: boolean;
  isDeliveryNote?: boolean;
  isQuote?: boolean;
  isRecurrent?: boolean;
  isTest?: boolean;
  isCorrective?: boolean;
  isCorrected?: boolean;
  isReceipt?: boolean;
  isPartialCorrective?: boolean;
  isReplacing?: boolean;
  isReplaced?: boolean;
  isSubstitute?: boolean;
  isReplacement?: boolean;
  isFromApi?: boolean;
  isIssuingFromDeliveryNote?: boolean;
  isIssuingFromProforma?: boolean;
  isIssuingMultiple?: boolean;
}

export const DocumentTogglesProvider: FC<DocumentTogglesProviderProps> = (
  props
) => {
  const {
    children,
    isCopying = false,
    isDraft = false,
    isEditing = false,
    isIssuing = false,
    isProforma = false,
    isDeliveryNote = false,
    isQuote = false,
    isRecurrent = false,
    isTest = false,
    isCorrective = false,
    isCorrected = false,
    isReceipt = false,
    isPartialCorrective = false,
    isReplacing = false,
    isReplaced = false,
    isSubstitute = false,
    isReplacement = false,
    isFromApi = false,
    isIssuingFromDeliveryNote = false,
    isIssuingFromProforma = false,
    isIssuingMultiple = false,
  } = props;

  const value = useMemo<DocumentTogglesContextValues>(
    () => ({
      isCopying,
      isDraft,
      isEditing,
      isIssuing,
      isProforma,
      isDeliveryNote,
      isQuote,
      isRecurrent,
      isTest,
      isCorrective,
      isCorrected,
      isReceipt,
      isPartialCorrective,
      isReplacing,
      isReplaced,
      isSubstitute,
      isReplacement,
      isFromApi,
      isIssuingFromDeliveryNote,
      isIssuingFromProforma,
      isIssuingMultiple,
    }),
    [
      isCopying,
      isDraft,
      isEditing,
      isIssuing,
      isProforma,
      isDeliveryNote,
      isQuote,
      isRecurrent,
      isTest,
      isCorrective,
      isCorrected,
      isReceipt,
      isPartialCorrective,
      isReplacing,
      isReplaced,
      isSubstitute,
      isReplacement,
      isFromApi,
      isIssuingFromDeliveryNote,
      isIssuingFromProforma,
      isIssuingMultiple,
    ]
  );

  return (
    <DocumentTogglesContext.Provider value={value}>
      {children}
    </DocumentTogglesContext.Provider>
  );
};

export const useDocumentToggles = (): DocumentTogglesContextValues => {
  const context = useContext(DocumentTogglesContext);
  if (context === undefined) {
    return { isRecurrent: false, isTest: false, isEditing: false };
  }
  return context;
};
