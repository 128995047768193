import { IntlFormatters, MessageDescriptor, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { format as formatFns, isValid } from 'date-fns';
import es from 'date-fns/locale/es/index';
import { FormatXMLElementFn, Options, PrimitiveType } from 'intl-messageformat';

import { useFeatureFlag } from 'modules/FeatureFlags';
import {
  PRINCIPAL_CURRENCIES,
  getCurrencyLocale,
} from 'utils/currency/currencies.utils';

import { SPANISH } from '../constants';
import { formatHelpers } from '../formatHelpers';
import {
  selectCurrency,
  selectCurrencySymbol,
  selectLocale,
} from '../selectors';
import { FormatHelper } from '../types';

export type TranslationFunction = <T, R>(
  descriptor: Record<string, string> | MessageDescriptor,
  values?: Record<string, PrimitiveType | FormatXMLElementFn<T, R> | T>
) => string;

export type FormatCurrencyFunction = (
  amount?: number,
  currency?: string,
  fractionDigits?: number
) => string;

export type UseTranslationsReturnType = {
  t: TranslationFunction;
  formatNumberToParts: IntlFormatters['formatNumberToParts'];
  formatCurrency: FormatCurrencyFunction;
  formatPercentage: (number?: number, separator?: string) => string;
  formatNumber: (number?: number) => string;
  formatInteger: (number: number) => string;
  formatNumberAndReturnNumber: (
    number: number,
    maximumFractionDigits?: number
  ) => number;
  formatMaxTwoDecimals: (number?: number) => string;
  currencySymbol: string;
  formatBytes: (bytes: number, decimals?: number) => string;
  formatDate: (date: Date | string, format: string) => string;
  formatHelpers: FormatHelper;
};

const CURRENCY_STYLE = 'currency';
const PERCENT_STYLE = 'percent';
export const DECIMAL = 'decimal';

interface TranslationsProps {
  defaultCurrency?: string;
}

const useTranslations = (
  props?: TranslationsProps
): UseTranslationsReturnType => {
  const { defaultCurrency } = props || {};
  const showI18nKeys = useFeatureFlag('showI18nKeys');
  const intl = useIntl();
  const currencyStore = useSelector(selectCurrency);
  const currencySymbol = useSelector(selectCurrencySymbol);
  const locale = useSelector(selectLocale);

  const formatMessage = (
    message: MessageDescriptor,
    values?: Record<string, PrimitiveType | FormatXMLElementFn<string, string>>,
    opts?: Options
  ) => {
    if (showI18nKeys) {
      return `${message.id} - ${intl.formatMessage(message, values, opts)}`;
    }
    return intl.formatMessage(message, values, opts);
  };

  const formatNumberAndPercent = (
    parts: Intl.NumberFormatPart[],
    separator: string
  ) =>
    parts
      .map((part) => (part.type !== 'literal' ? part.value : separator))
      .join('');

  const formatCurrency = (
    amount: number = 0,
    currencyParam?: string,
    fractionDigits: number = 2
  ) => {
    const currency = currencyParam || defaultCurrency || currencyStore;
    if (locale === SPANISH) {
      let forcedLocale = 'de-DE';
      if (PRINCIPAL_CURRENCIES.includes(currency)) {
        forcedLocale = getCurrencyLocale(currency);
      }
      // We are required to show 2.346 € for es-ES locale
      return new Intl.NumberFormat(forcedLocale, {
        style: CURRENCY_STYLE,
        currency,
        maximumFractionDigits: 2,
        minimumFractionDigits: fractionDigits,
      }).format(amount);
    }
    return intl.formatNumber(amount, {
      style: CURRENCY_STYLE,
      currency,
      maximumFractionDigits: 2,
      minimumFractionDigits: fractionDigits,
    });
  };

  const formatNumber = (number?: number) =>
    typeof number === 'number' ? intl.formatNumber(number) : '';

  const formatNumberAndReturnNumber = (
    number: number,
    maximumFractionDigits?: number
  ) => {
    const formattedNumber = new Intl.NumberFormat('en-US', {
      style: 'decimal',
      maximumFractionDigits: maximumFractionDigits || 2,
    })
      .format(number)
      .replace(/,/g, '');
    return Number(formattedNumber);
  };

  const formatInteger = (number: number) => {
    return new Intl.NumberFormat('de-DE', {
      style: 'decimal',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(number);
  };

  const formatPercentage = (amount: number = 0, separator: string = '') => {
    const parts = intl.formatNumberToParts(amount / 100, {
      style: PERCENT_STYLE,
      maximumFractionDigits: 2,
    });

    return formatNumberAndPercent(parts, separator);
  };

  const formatMaxTwoDecimals = (number?: number) => {
    return typeof number === 'number'
      ? intl.formatNumber(number, { maximumFractionDigits: 2 })
      : '';
  };

  const formatBytes = (bytes: number, decimals = 2) => {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
  };

  const formatDate = (date: Date | string, format: string) => {
    let realDate: Date;
    if (typeof date === 'string') {
      realDate = new Date(date);
    } else {
      realDate = date;
    }
    if (isValid(realDate)) {
      if (locale === SPANISH) {
        return formatFns(realDate, format, { locale: es });
      }
      return formatFns(realDate, format);
    }
    return 'xx/xx/xxxx';
  };

  return {
    t: formatMessage as typeof intl.formatMessage,
    formatNumberToParts: intl.formatNumberToParts,
    formatPercentage,
    formatNumber,
    formatInteger,
    formatCurrency,
    formatMaxTwoDecimals,
    formatNumberAndReturnNumber,
    currencySymbol,
    formatBytes,
    formatDate,
    formatHelpers,
  };
};

export default useTranslations;
