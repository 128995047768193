import * as Yup from 'yup';

import { TranslationFunction } from 'modules/I18n/hooks/useTranslations';
import passwordRegex from 'utils/regex/password';

import { formErrors } from './messages';

const getValidationSchema = (t: TranslationFunction) =>
  Yup.object().shape({
    currentPassword: Yup.string().required(
      t({ ...formErrors.currentPassword })
    ),
    newPassword: Yup.string()
      .required(t({ ...formErrors.newPassword }))
      .matches(passwordRegex, t({ ...formErrors.newPasswordInvalid })),
    newPasswordConfirmation: Yup.string()
      .required(t({ ...formErrors.newPasswordConfirmation }))
      .oneOf(
        [Yup.ref('newPassword')],
        t({ ...formErrors.newPasswordConfirmationInvalid })
      ),
  });

export default getValidationSchema;
