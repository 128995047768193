import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import analyticsService from 'modules/Analytics/services/analyticsService';

import { selectProps } from '../selectors';

const clickEventHandlerForModalType: (
  type: string
) => (this: Window, ev: MouseEvent) => void = (type: string) => {
  return (event: MouseEvent): void => {
    if (!event.target) {
      return;
    }
    const element: HTMLElement = event.target as HTMLElement;
    if (element.tagName !== 'BUTTON') {
      return;
    }

    const buttonText = (event?.target as any)?.innerHTML;
    const dataTestId = element.getAttribute?.('data-testid') ?? undefined;
    if (!buttonText && !dataTestId) {
      return;
    }

    analyticsService.trackModal('click', {
      type,
      buttonText,
      dataTestId,
    });
  };
};

function useAnalyticsModals() {
  const { type, ...props } = useSelector(selectProps);

  const lastModalType = useRef(type);
  const lastModalStateType = useRef(type);

  lastModalType.current = type ?? lastModalType.current;

  useEffect(() => {
    if (lastModalStateType.current !== type) {
      let action:
        | Parameters<typeof analyticsService.trackModal>[0]
        | undefined = type ? 'open' : undefined;
      if (!type && lastModalStateType.current) {
        action = 'close';
      }
      if (action) {
        analyticsService.trackModal(action, {
          type: type ?? lastModalStateType.current,
          ...props,
        });
      }
      lastModalStateType.current = type;
    }
  }, [type]);

  useEffect(() => {
    if (!type) {
      return undefined;
    }
    const callback = clickEventHandlerForModalType(type);
    window.addEventListener('mouseup', callback);
    return () => {
      window.removeEventListener('mouseup', callback);
    };
  }, [type]);
}

export default useAnalyticsModals;
