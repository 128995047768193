import Box, { BoxProps } from 'modules/Ui/Box/Box';

interface TabPanelProps extends BoxProps {
  index: string | number;
  value: string | number;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;
  return (
    <Box
      {...other}
      aria-labelledby={`tab-${index}`}
      id={`tabpanel-${index}`}
      role="tabpanel"
      fadeIn
    >
      <Box hidden={value !== index}>{value === index && <>{children}</>}</Box>
    </Box>
  );
};

export default TabPanel;
