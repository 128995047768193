import { ReactNode } from 'react';

import {
  flexbox,
  FlexboxProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
} from 'styled-system';

import theme from 'modules/Theme';
import styled from 'modules/Theme/styled-components';

import Box from '../Box';
import IconInfo from '../Icons/IconInfo';
import Text from '../Text';

export enum BaiStatus {
  PRESENTED_ERRORS = 'PRESENTED_ERRORS',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED',
}
interface Props extends FlexboxProps, SpaceProps, TypographyProps {
  iconSize?: number;
  message: string | ReactNode;
  type?: BaiStatus;
}

const bgColors: Record<BaiStatus, string> = {
  [BaiStatus.PRESENTED_ERRORS]: theme.colors.warningBackground,
  [BaiStatus.PENDING]: theme.colors.auxiliary100A30,
  [BaiStatus.REJECTED]: theme.colors.errorBackground,
};

const colors: Record<BaiStatus, string> = {
  [BaiStatus.PRESENTED_ERRORS]: theme.colors.rejected,
  [BaiStatus.PENDING]: theme.colors.gray800,
  [BaiStatus.REJECTED]: theme.colors.rejected,
};

const StyledBaiStatus = styled(Box)`
  ${flexbox}
  ${space}
  ${typography}
`;

const DocumentBaiError: React.FC<Props> = ({
  message,
  type = BaiStatus.PRESENTED_ERRORS,
  iconSize = 20,
  ...rest
}) => {
  const backgroundColor = bgColors[type];
  const color = colors[type];
  return (
    <StyledBaiStatus
      alignItems="flex-start"
      backgroundColor={backgroundColor}
      display="flex"
      marginBottom="2px"
      tag="li"
      {...rest}
    >
      <Box tag="span" width={`${iconSize}px`} height={`${iconSize}px`}>
        <IconInfo color={color} size={iconSize} />
      </Box>
      <Text
        color={color}
        fontSize="inherit"
        lineHeight="inherit"
        marginLeft="8px"
        tag="span"
        flex="1"
      >
        {message}
      </Text>
    </StyledBaiStatus>
  );
};

StyledBaiStatus.defaultProps = {
  fontSize: 16,
  lineHeight: 24,
  padding: '8px 16px 8px',
};

export default DocumentBaiError;
