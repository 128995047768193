import { createElement, useContext } from 'react';

import { HelpContext } from 'modules/Help/HelpContext';
import useTranslations from 'modules/I18n/hooks/useTranslations';
import { Box, Text, Link } from 'modules/Ui';
import FaqsExpandable from 'modules/Ui/CustomExpandable/components/FaqsExpandable';

import getLink from './helpers/getLink';
import { helpBai } from './messages';

function getIsIniallyExpanded(i: number, preOpenedId?: number) {
  if (preOpenedId) {
    return i === preOpenedId;
  }
  return i === 0;
}

const NUMBER_OF_FAQS = 19;
const BaiFaqs: React.FC = () => {
  const {
    t,
    formatHelpers: { br },
  } = useTranslations();
  const helpContext = useContext(HelpContext);

  const faqs = Array(NUMBER_OF_FAQS)
    .fill(NUMBER_OF_FAQS)
    .map((_, i) => {
      const link = getLink(i);
      const title = t(helpBai[`faqBai${i + 1}`]);
      return (
        <FaqsExpandable
          id={`faq-bai-item-${i}`}
          key={title}
          tag="li"
          title={title}
          content={
            <Text marginBottom="8px" tag="div">
              {t(helpBai[`faqBai${i + 1}Answer`], {
                br,
                bold: (chunk) =>
                  createElement(Text, {
                    color: 'gray800',
                    fontWeight: '600',
                    tag: 'strong',
                    textSize: 'm',
                    children: chunk,
                  }),
                link: (chunks: any) => (
                  <Link isExternal to={link}>
                    {chunks}
                  </Link>
                ),
              })}
            </Text>
          }
          initiallyExpanded={getIsIniallyExpanded(
            i,
            helpContext?.preOpenedFaqId
          )}
          isLastItem={i + 1 === NUMBER_OF_FAQS}
        />
      );
    });
  return (
    <Box marginBottom={{ _: '48px', sm: 'initial' }} tag="ul">
      {faqs}
    </Box>
  );
};

export default BaiFaqs;
