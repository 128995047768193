import { getConfig } from 'modules/App/config';
import { Coupon } from 'modules/Subscriptions/Create/types';
import request from 'utils/request';

interface Params {
  code: string;
  productId: string;
}

interface Response {
  data: Coupon;
}
export const checkCoupon = async ({
  code,
  productId,
}: Params): Promise<Coupon> => {
  const endpoint = (await getConfig()).apiCouponsService;

  const response = await request<Response>(
    `${endpoint}/${code}/preview/${productId}`,
    {
      method: 'GET',
    }
  );
  return response.data.data;
};
