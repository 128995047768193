import React from 'react';

import EmbedViewer from 'modules/Documents/components/FileVisor/EmbedViewer';
import useDocumentBlobFileUrl from 'modules/Documents/hooks/useDocumentBlobFileUrl';
import { useHoldBooleanTrueAtLeast } from 'modules/Documents/hooks/useHoldBooleanTrueAtLeast';

import { Spinner } from '..';
import BaseViewer from './BaseViewer';
import { PdfViewerProps } from './types';

const PdfViewer: React.FC<PdfViewerProps> = ({ data, ...rest }) => {
  const { url, loading, contentType } = useDocumentBlobFileUrl(data);
  const holdedLoading = useHoldBooleanTrueAtLeast(loading, 300);

  return (
    <BaseViewer {...rest}>
      {holdedLoading ? (
        <Spinner />
      ) : (
        <EmbedViewer dataUri={url} fileType={contentType} fullHeight />
      )}
    </BaseViewer>
  );
};

export default PdfViewer;
