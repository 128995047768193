import { FC } from 'react';

import { space, SpaceProps } from 'styled-system';

import useTranslations from 'modules/I18n/hooks/useTranslations';
import { terms } from 'modules/Register/messages';
import { underlineHover } from 'modules/Theme/mixins';
import styled from 'modules/Theme/styled-components';

import Text from '../Text';
import TextMarkdown from '../TextMarkdown';

interface TermsAndPrivacyProps extends SpaceProps {
  textFontSize?: string | number;
  textLineHeight?: string | number;
}

const StyledText = styled(Text)`
  a {
    ${space}
    font-size: ${(props) => props.theme.fontSizes[11]};
    line-height:${(props) => props.theme.lineHeights[13]};
    ${underlineHover}
  }
`;

export const TermsAndPrivacy: FC<TermsAndPrivacyProps> = ({
  textFontSize,
  textLineHeight,
  ...rest
}) => {
  const { t } = useTranslations();
  return (
    <StyledText
      fontSize={textFontSize || 12}
      lineHeight={textLineHeight || 16}
      {...rest}
    >
      <TextMarkdown linkTarget="_blank" text={t(terms.terms)} />
    </StyledText>
  );
};

StyledText.defaultProps = {
  marginBottom: { sm: '145px', md: '24px' },
};
