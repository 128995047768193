import IconByString, { IconByStringProps } from './Icon/IconType/IconByString';

const IconDownload = (props: IconByStringProps) => {
  return (
    <IconByString {...props}>
      <path
        d="m12.502 12.001-.024-4.503-1 .004.024 4.503 1-.004Z"
        fill="currentColor"
      />
      <path
        d="M12.126 1h-.252a6 6 0 0 0-5.712 4.164L5.572 7H5a4 4 0 1 0 0 8h6.48l.02 6.502-3.033-3.034-.707.707 4.242 4.242 4.243-4.242-.707-.707-3.037 3.037L12.48 15H19a4 4 0 0 0 0-8h-.571l-.59-1.836A6 6 0 0 0 12.125 1ZM5 8h1.3l.814-2.53A5 5 0 0 1 11.874 2h.252a5 5 0 0 1 4.76 3.47L17.7 8H19a3 3 0 1 1 0 6H5a3 3 0 1 1 0-6Z"
        fill="currentColor"
        shapeRendering="geometricPrecision"
      />
    </IconByString>
  );
};

export default IconDownload;
