import IconByPath, { IconByPathProps } from './Icon/IconType/IconByPath';

const IconSearch = (props: IconByPathProps) => (
  <IconByPath
    {...props}
    path="M14 18a8 8 0 1 0-5.908-2.606L2 21.274l.726.726 6.106-5.893A7.968 7.968 0 0 0 14 18Zm0-1a7 7 0 1 1 0-14 7 7 0 0 1 0 14Z"
  />
);

export default IconSearch;
