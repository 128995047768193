import { useCallback, FC } from 'react';

import { UserPermissions } from 'modules/Auth/constants';
import useHasCustomerSupport from 'modules/Help/hooks/useHasCustomerSupport';
import useTranslations from 'modules/I18n/hooks/useTranslations';
import { Dialog, MODAL_WIDTH_SMALL } from 'modules/Modals';
import { Button, Text } from 'modules/Ui';

import { ScopeTypes } from '../services';
import messages from './messages';
import { ChangePlanErrorModalProps } from './types';

interface MapErrors {
  [key: string]: ({ id: string } | undefined)[];
}

const mapErrors: MapErrors = {
  'wrong-periodicity': [messages.wrongPeriodicity, messages.wrongPeriodicityB],
  'wrong-scope': [messages.downgrade, messages.downgradeNotAllowedB],
  'generic-scope-error': [undefined, messages.genericScopeError],
  'same-plan': [messages.samePlan, messages.samePlanB],
  'max-plan-changes-reached': [
    messages.maxChangesReached,
    messages.maxChangesReachedB,
  ],
  'recurrents-not-allowed': [messages.recurrents, messages.recurrents01],
  'products-limit': [messages.products],
  'ocr-scans': [undefined, messages.ocr],
  'wrong-shopify-plan-compatibility': [
    messages.shopifyTitle,
    messages.shopifySubtitle,
  ],
  'wrong-api-public-plan-compatibility': [
    messages.apiPublicTitle,
    messages.apiPublicSubtitle,
  ],
  'expense-recurrents-not-allowed': [
    messages.expenseRecurrents,
    messages.expenseRecurrents01,
  ],
  'product-stock': [messages.productStockSubtitle, messages.productStockText],
};

const scopeError = (
  error: string,
  failedScopes: ScopeTypes[] = [],
  warningScopes?: ScopeTypes[]
) => {
  const hasIntegrationsError =
    error === 'wrong-shopify-plan-compatibility' ||
    error === 'wrong-api-public-plan-compatibility';
  const isGenericError =
    failedScopes.length > 1 ||
    (failedScopes.length && warningScopes?.length) ||
    (hasIntegrationsError && warningScopes?.length);

  if (isGenericError) {
    return mapErrors['generic-scope-error'];
  }
  if (failedScopes.includes(UserPermissions.RECURRENT_CREATE)) {
    return mapErrors['recurrents-not-allowed'];
  }
  if (failedScopes.includes(UserPermissions.EXPENSES_RECURRENT_CREATE)) {
    return mapErrors['expense-recurrents-not-allowed'];
  }
  if (failedScopes.includes(UserPermissions.PRODUCTS_CREATE)) {
    return mapErrors['products-limit'];
  }
  if (failedScopes.includes(UserPermissions.OCR_SCANS)) {
    return mapErrors['ocr-scans'];
  }

  if (failedScopes.includes(UserPermissions.PRODUCTS_STOCK)) {
    return mapErrors['product-stock'];
  }

  return mapErrors[error];
};

const ChangePlanErrorModal: FC<ChangePlanErrorModalProps> = ({
  error,
  failedScopes,
  warningScopes,
  onClose,
  planId,
}) => {
  const { t } = useTranslations();
  const { hasPhoneSupport } = useHasCustomerSupport();
  const closeModal = useCallback(() => {
    onClose();
  }, [onClose]);

  const [msg, msg2] = scopeError(error, failedScopes, warningScopes);

  return (
    <Dialog
      id="changePlanErrorModal"
      onClose={closeModal}
      title={t(messages.title)}
      buttonPrimary={
        <Button
          data-testid="changePlanErrorModal-confirm"
          variant="secondary"
          onClick={closeModal}
        >
          {t(messages.confirm)}
        </Button>
      }
      subtitle={msg ? t(msg) : ''}
      dialogWidth={{ sm: MODAL_WIDTH_SMALL }}
    >
      {msg2 && (
        <Text lineHeight={22}>
          {t(msg2, { phoneSupport: hasPhoneSupport, planId })}
        </Text>
      )}
    </Dialog>
  );
};
export default ChangePlanErrorModal;
