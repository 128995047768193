import IconByPath, { IconByPathProps } from './Icon/IconType/IconByPath';

const IconSubstitutionRectification = (props: IconByPathProps) => (
  <IconByPath
    {...props}
    path="M8.25 1h-.207L1.25 7.793V23h8.5v-1h-7.5V8.207L2.457 8H8.25V2.207L8.457 2h9.793v9l1-.75V1h-11ZM3.457 7 6.3 4.157l.95-.743V7H3.457ZM23 13.667 20.333 11 11 20.333V23h2.667L23 13.667Zm-11 7.08 8.333-8.333 1.252 1.252L13.251 22H12v-1.253Z"
  />
);

export default IconSubstitutionRectification;
