import { useEffect } from 'react';
import { useHistory } from 'react-router';

import { Location } from 'history';

import { ROUTES } from 'modules/Help/routesMap';

function isInFaqs(location: Location) {
  return location.pathname.indexOf(ROUTES.MAIN_PATH) !== -1;
}

const useScollToTopOnNavigate = () => {
  const history = useHistory();
  useEffect(
    () =>
      history.listen((location) => {
        if (!isInFaqs(location)) {
          setTimeout(() => window.scrollTo({ top: 0, behavior: 'smooth' }));
        }
      }),
    []
  );
};

export default useScollToTopOnNavigate;
