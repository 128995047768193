import React, { ChangeEvent, useCallback } from 'react';

import { useField } from 'formik';

import RadioButtonIcon, {
  RadioButtonIconProps,
} from 'modules/Ui/Form/RadioButtonIcon';

import useFocusHandler from './useFocusHandler';

const RadioButtonIconFormik: React.FC<RadioButtonIconProps> = ({
  onChange,
  ...props
}) => {
  const { icon, name, id } = props;
  const [field, meta, helpers] = useField(name);
  const onChangeHandler = useCallback(
    ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
      onChange && onChange(value);
      helpers.setTouched(true);
      return helpers.setValue(value);
    },
    [helpers, onChange]
  );
  const onFocusHandler = useFocusHandler(name || id);

  return (
    <RadioButtonIcon
      {...field}
      checked={meta.value === props.value}
      icon={icon}
      {...props}
      onChange={onChangeHandler}
      onFocus={onFocusHandler}
    />
  );
};
export default RadioButtonIconFormik;
