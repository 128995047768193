import { FC, ReactNode } from 'react';
import { useSelector } from 'react-redux';

import { layout, position } from 'styled-system';

import { selectCurrentBusiness } from 'modules/Business/Current/selectors';
import useTheme from 'modules/Theme/hooks/useTheme';
import { expandableAnimation } from 'modules/Theme/mixins';
import styled from 'modules/Theme/styled-components';
import { Box, Text, Hr } from 'modules/Ui';
import { BoxProps } from 'modules/Ui/Box/Box';
import { zIndex } from 'modules/Ui/zIndex';
import { selectUser } from 'modules/Users/selectors';
import { getFullName } from 'modules/Users/userUtils';

import UserRole from '../../../Components/UserRole';

interface ExpandableBoxProps extends BoxProps {
  children?: string | ReactNode;
  hide?: boolean;
  open?: boolean;
}

const Popup = styled(Box)`
  ${layout}
  ${position}
  ${expandableAnimation}
`;

const ExpandableBox: FC<ExpandableBoxProps> = ({
  open,
  children,
  hide,
  ...rest
}) => {
  const userData = useSelector(selectUser);
  const fullName = getFullName(userData);
  const { fiscalName: businessName } = useSelector(selectCurrentBusiness);
  const theme = useTheme();
  return (
    <Popup
      backgroundColor="gray0"
      borderRadius="8px"
      boxShadow={`0px 8px 12px 0px  ${theme.colors.shadow}`}
      className="expandable__content"
      contentVisibility={open ? 'auto' : 'hidden'}
      height={!open ? '0' : 'auto'}
      id="menu-expandable"
      opacity={open ? '1' : '0'}
      open={open}
      position="absolute"
      right="0"
      role="region"
      width="clamp(236px, 100%, 280px)"
      zIndex={`${zIndex.ARROWBOX}`}
      willChange={open ? 'opacity, height, content-visibility' : ''}
      {...rest}
    >
      <Box display="block" padding="12px 16px" tag="span">
        <Text
          color="primary500"
          display="block"
          fontSize="16px"
          fontWeight="600"
          lineHeight="20px"
          marginBottom="4px"
          tag="span"
          wordBreak="break-word"
        >
          {businessName}
        </Text>
        <Box
          alignItems="center"
          display="flex"
          justifyContent="space-between"
          tag="span"
        >
          {fullName && (
            <Text textSize="xs" marginRight="4px" hasEllipsis tag="span">
              {fullName}
            </Text>
          )}
          {userData.role && <UserRole />}
        </Box>
      </Box>
      <Hr />
      <Box tag="ul" id="submenu-expandable" role="menu">
        {children}
      </Box>
    </Popup>
  );
};

export default ExpandableBox;
