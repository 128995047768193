import { useCallback, FC } from 'react';
import { Hidden } from 'react-grid-system';

import useTranslations from 'modules/I18n/hooks/useTranslations';
import { Dialog, useCloseModal } from 'modules/Modals';
import { Button, Text } from 'modules/Ui';

import { changeBusinessDialog } from './messages';
import { ChangeDataBusinnessModalProps } from './types';

const ChangeDataBusinessModal: FC<ChangeDataBusinnessModalProps> = ({
  onSubmit,
}) => {
  const closeModal = useCloseModal();

  const handleSubmit = useCallback(() => {
    onSubmit();
    closeModal();
  }, [closeModal]);
  const { t } = useTranslations();
  return (
    <Dialog
      buttonPrimary={
        <Button
          data-testid="document-change-myAccount-confirm"
          form="fix-vat-number-form"
          onClick={handleSubmit}
          variant="secondary"
        >
          {t(changeBusinessDialog.confirm)}
        </Button>
      }
      buttonSecondary={
        <Hidden xs>
          <Button
            data-testid="document-change-myAccount-cancel"
            onClick={closeModal}
            type="button"
            variant="stroke"
          >
            {t(changeBusinessDialog.cancel)}
          </Button>
        </Hidden>
      }
      id="document-change-myAccount"
      onClose={closeModal}
      subtitle={t(changeBusinessDialog.subtitle)}
      title={t(changeBusinessDialog.title)}
    >
      <Text marginBottom="16px">{t(changeBusinessDialog.text01)}</Text>
      <Text marginBottom={{ sm: '28px' }}>
        {t(changeBusinessDialog.text02)}
      </Text>
    </Dialog>
  );
};

export default ChangeDataBusinessModal;
