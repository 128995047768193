import IconByString, { IconByStringProps } from './Icon/IconType/IconByString';

const IconCreditCard = (props: IconByStringProps) => {
  const { ...rest } = props;
  return (
    <IconByString {...rest}>
      <path fill="currentColor" d="M4 15h5v1H4v-1ZM16 15h-5v1h5v-1Z" />
      <path
        fill="currentColor"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 7a2 2 0 0 1 2-2h18a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V7Zm1 4V9h20v2H2Zm0 1v6a1 1 0 0 0 1 1h18a1 1 0 0 0 1-1v-6H2Zm20-4H2V7a1 1 0 0 1 1-1h18a1 1 0 0 1 1 1v1Z"
      />
    </IconByString>
  );
};

export default IconCreditCard;
