import { FC } from 'react';
import { Visible, Hidden } from 'react-grid-system';

import ListDesktop, {
  ItemDesktop,
} from 'modules/Contacts/SearchContactDialog/components/ContactList/ListDesktop';
import ListMobile from 'modules/Contacts/SearchContactDialog/components/ContactList/ListMobile';
import { ItemMobile } from 'modules/Contacts/SearchContactDialog/components/ContactList/ListMobileItem';
import useTranslations from 'modules/I18n/hooks/useTranslations';
import { Dialog } from 'modules/Modals';
import { Button, Box } from 'modules/Ui';

import Business from '../models/business';
import { modal as messages } from './messages';

interface Props {
  [key: string]: any;
}

function mapItems(businesses: Business[]): ItemMobile[] {
  return businesses.map(({ id, fiscalName, vatNumber }) => ({
    id: id as string,
    column1: fiscalName,
    column2: vatNumber,
  }));
}

function mapItemsDesktop(businesses: Business[]): ItemDesktop[] {
  return businesses.map(({ id, fiscalName, vatNumber, address }) => ({
    id: id as string,
    column1: vatNumber,
    column2: fiscalName,
    column3: address?.postalCode,
  }));
}

const ChangeBusinessModal: FC<Props> = ({
  businesses = [],
  closeModal,
  onClickChangeBusiness,
  selectedBusiness,
  onSelectBusiness,
}: Props) => {
  const { t } = useTranslations();
  const mobileItems = mapItems(businesses);
  const desktopItems = mapItemsDesktop(businesses);
  return (
    <Dialog
      buttonPrimary={
        <Button
          onClick={() => onClickChangeBusiness(selectedBusiness)}
          variant="secondary"
        >
          {t(messages.confirm)}
        </Button>
      }
      buttonSecondary={
        <Hidden xs>
          <Button onClick={closeModal} variant="stroke">
            {t(messages.cancel)}
          </Button>
        </Hidden>
      }
      id="changeBusinessDialog"
      onClose={closeModal}
      title={t(messages.title)}
      type="fullScreen"
      backdropIsScrollable={false}
      content={
        <>
          <Visible xs>
            <ListMobile
              {...{
                margin: '34px 0 20px 0',
                items: mobileItems,
                selectedItemId: selectedBusiness,
                onSelectRow: (id: string) => {
                  onClickChangeBusiness(id);
                },
              }}
            />
          </Visible>
          <Hidden xs>
            <Box
              {...{
                marginBottom: { _: '20px', sm: '24px' },
                maxHeight: { sm: '295px' },
                overflowY: 'auto',
              }}
            >
              <ListDesktop
                {...{
                  items: desktopItems,
                  selectedItemId: selectedBusiness,
                  onChange: onSelectBusiness,
                }}
              />
            </Box>
          </Hidden>
        </>
      }
    />
  );
};

export default ChangeBusinessModal;
