import { FC, ReactNode } from 'react';

import { space, typography, layout, background, border } from 'styled-system';

import styled from 'modules/Theme/styled-components';

import BasePill from '../BasePill';
import { BasePillProps } from '../BasePill/BasePill';

interface Props extends BasePillProps {
  children: ReactNode;
}

const StyledSmallPill = styled(BasePill)<Props>`
  ${background}
  ${border}
  ${layout}
  ${space}
  ${typography}
`;

const SmallPill: FC<Props> = ({
  children,
  color,
  fontSize,
  lineHeight,
  ...rest
}) => {
  return (
    <StyledSmallPill
      color={color || 'gray0'}
      fontSize={fontSize || 10}
      lineHeight={lineHeight || 12}
      {...rest}
    >
      {children}
    </StyledSmallPill>
  );
};

StyledSmallPill.defaultProps = {
  borderRadius: '2px',
  fontWeight: 'normal',
  height: 'auto',
  marginBottom: '12px',
  padding: '2px 6px',
  textTransform: 'uppercase',
  width: 'auto',
};

export default SmallPill;
