import { defineMessages } from 'react-intl';

const messages = defineMessages({
  goBack: { id: 'gofre.Bookkeeper.BusinessSearch.goBack' },
  changeClient: { id: 'gofre.Bookkeeper.BusinessSearch.changeClient' },
  searchField: { id: 'gofre.Bookkeeper.BusinessSearch.searchField' },
  searchFieldPlaceholder: {
    id: 'gofre.Bookkeeper.BusinessSearch.searchField.placeholder',
  },
});

export default messages;
