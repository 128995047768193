import { getConfig } from 'modules/App/config';
import { REMITTANCES_QUERY } from 'modules/App/queries/queries';
import request from 'utils/request';

import { EditRemittanceDto, RemittanceAnswerDto } from '../types';

export interface EditRemittanceResponse {
  data: RemittanceAnswerDto;
}

const editRemittance = async (options: {
  id?: string;
  remittanceDto: EditRemittanceDto;
}): Promise<EditRemittanceResponse> => {
  const { id, remittanceDto } = options;
  const endpoint = (await getConfig()).apiRemittancesService;
  const response = await request<EditRemittanceResponse>(`${endpoint}/${id}`, {
    method: 'PUT',
    data: remittanceDto,
  });

  return response.data;
};

export const invalidateQueries = [REMITTANCES_QUERY];

export default editRemittance;
