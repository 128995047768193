import IconByPath, { IconByPathProps } from './Icon/IconType/IconByPath';

const IconTemplate = (props: IconByPathProps) => (
  <IconByPath
    clipRule="evenodd"
    fillRule="evenodd"
    path="M16 3H4v4h12V3ZM4 2a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V5.5h2.5v3.36l-9.341 3.393a1 1 0 0 0-.659.94V15H8v6a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1v-6h-1.5v-1.807L19.841 9.8a1 1 0 0 0 .659-.94V5.5a1 1 0 0 0-1-1H17V3a1 1 0 0 0-1-1H4Zm7 14H9v5h2v-5Z"
    {...props}
  />
);

export default IconTemplate;
