import useTheme from 'modules/Theme/hooks/useTheme';

import IconByString, { IconByStringProps } from './Icon/IconType/IconByString';

const IconCreateRecurrentInvoice = (props: IconByStringProps) => {
  const { innerPathColor, ...rest } = props;
  const theme = useTheme();
  return (
    <IconByString {...rest}>
      <path
        d="M18 23V1H2v22h16ZM3 2h14v20H3V2Z"
        fill="currentColor"
        fillRule="evenodd"
      />
      <path
        d="M7 8a3 3 0 1 0 6 0 3 3 0 0 0-6 0Zm3-4a4 4 0 1 0 0 8 4 4 0 0 0 0-8Z"
        fill="currentColor"
        fillRule="evenodd"
        shapeRendering="geometricPrecision"
      />
      <path d="M6 15h8v1H6v-1ZM6 19h8v1H6v-1Z" fill="currentColor" />
      <circle
        cx="18"
        cy="18"
        fill={innerPathColor || theme.colors.brand500}
        r="6"
        shapeRendering="geometricPrecision"
      />
      <path
        d="M18.462 14.77h-.924v3.692h3.693v-.924h-2.769V14.77Z"
        fill={theme.colors.gray0}
        fillRule="evenodd"
      />
    </IconByString>
  );
};

export default IconCreateRecurrentInvoice;
