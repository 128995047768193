import { FC } from 'react';

import theme from 'modules/Theme';
import { Box } from 'modules/Ui';
import { NotificationBadge } from 'modules/Ui/Menu/Components/NotificationBadge';

import IconNotifications from './IconNotifications';

interface Props {
  active: boolean;
  number: number;
  isBookkeeper?: boolean;
}

const getBorderColor = (isBookeeper?: boolean) =>
  isBookeeper ? theme.colors.brand500 : theme.colors.gray0;

const IconNotificationsBadge: FC<Props> = ({
  number,
  active,
  isBookkeeper,
}) => (
  <Box tag="span" position={number !== 0 ? 'relative' : undefined}>
    <IconNotifications active={active} type={isBookkeeper ? 'light' : 'dark'} />
    <NotificationBadge
      border={`1px solid ${getBorderColor(isBookkeeper)}`}
      left="50%"
      number={number}
      position="absolute"
      top="-1px"
    />
  </Box>
);

export default IconNotificationsBadge;
