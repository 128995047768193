import { FC } from 'react';

import useTranslations from 'modules/I18n/hooks/useTranslations';
import { MODAL_WIDTH_MEDIUM, Dialog, useCloseModal } from 'modules/Modals';
import { Box, Button, DocumentTrackingRow } from 'modules/Ui';

import { DocumentTrackingData } from '../../../models/documentTracking';
import { useDocumentTracking } from '../../hooks/useDocumentTracking';
import { modal as messages } from '../messages';
import { DocumentTrackingModalProps } from './types';

const DocumentTrackingModal: FC<DocumentTrackingModalProps> = ({ items }) => {
  const { t } = useTranslations();
  const { voidActionIndex } = useDocumentTracking(items);

  const handleCancel = useCloseModal();

  return (
    <Dialog
      {...{
        id: 'document-tracking',
        onClose: handleCancel,
        title: t(messages.title),
        dialogWidth: { _: 'calc(92%)', sm: MODAL_WIDTH_MEDIUM },
        buttonSecondary: (
          <Button
            data-testid="document-tracking-submit"
            variant="secondary"
            onClick={handleCancel}
          >
            {t(messages.accept)}
          </Button>
        ),
      }}
    >
      <Box
        flexGrow="1"
        marginBottom={{ _: '24px', sm: '28px' }}
        maxHeight={{ _: 'calc(100vh - 266px)' }}
        minHeight="0"
        overflow="auto"
        tag="ol"
      >
        {items.map((item: DocumentTrackingData, index: number) => (
          <DocumentTrackingRow
            key={item.id}
            {...item}
            isMobile={false}
            isFirstItem={index === 0}
            isLastItem={index + 1 === items.length}
            renderVoidTag={index < voidActionIndex}
          />
        ))}
      </Box>
    </Dialog>
  );
};

export default DocumentTrackingModal;
