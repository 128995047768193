import * as Yup from 'yup';

import { formText } from 'modules/Forms/messages';
import { TranslationFunction } from 'modules/I18n/hooks/useTranslations';

import messages from './messages';

interface ValidationSchemaArgs {
  t: TranslationFunction;
}

const DESCRIPTION_MAX_LENGTH = 250;

export const getValidationSchema = ({ t }: ValidationSchemaArgs) => {
  return Yup.object().shape({
    operationDate: Yup.date().required(t(formText.required)),
    amount: Yup.number()
      .required(t(formText.required))
      .positive(t(messages.minAmount)),
    description: Yup.string().max(DESCRIPTION_MAX_LENGTH),
  });
};
