import { css } from 'modules/Theme/styled-components';

type Props = {
  autoSize?: boolean;
  error?: string;
  disabled?: boolean;
};

const disabledStyles = css`
  background-color: ${(props) => props.theme.colors.gray200};
  --textarea-border: ${(props) => props.theme.colors.gray300};
`;

const errorStyles = css`
  --textarea-border: ${(props) => props.theme.colors.error};
`;

const textAreaStyles = css<Props>`
  border-color: var(
    --textarea-border,
    ${(props) => props.theme.colors.primary200}
  );
  border-style: solid;
  border-width: 1px;
  box-sizing: border-box;
  font-family: inherit;
  font-size: ${(props) => props.theme.fontSizes[16]};
  line-height: ${(props) => props.theme.lineHeights[26]};
  padding: 8px 16px;
  resize: none;
  transition: border-color 0.2s ease-in;
  width: 100%;
  -webkit-appearance: none;
  color:  ${(props) => props.theme.colors.gray600};

  &::placeholder {
    color: ${(props) => props.theme.colors.gray400};
  }

  &:focus {
    --textarea-border: ${(props) => props.theme.colors.brand500};
    outline: none;
  }

  @supports (field-sizing: content) {
    ${({ autoSize }) => autoSize && `field-sizing: content;`}
  }

  ${({ error }) => error && errorStyles};
  ${({ disabled }) => disabled && disabledStyles}
`;

export default textAreaStyles;
