import { FC } from 'react';

import { Plans } from 'modules/Subscriptions/components';
import Create from 'modules/Subscriptions/Create/Create';
import { useCreateSubscriptionModal } from 'modules/Subscriptions/hooks';
import MenuMobile from 'modules/Ui/Menu/MenuMobile';

const CreatePlan: FC = () => {
  const { onSelectPlan, selectedPlan, setSelectedPlan } =
    useCreateSubscriptionModal();

  if (!selectedPlan) {
    return (
      <>
        <MenuMobile />
        <Plans {...{ onSelectPlan }} />
      </>
    );
  }

  return (
    <Create
      {...{
        selectedPlan,
        onCancelPurchase: () => {
          setSelectedPlan(null);
        },
      }}
    />
  );
};

export default CreatePlan;
