import React, { lazy, Suspense } from 'react';
import { RedirectProps, Route, RouteProps, Switch } from 'react-router';

import AuthenticatedRoute from 'modules/Auth/components/AuthenticatedRoute';

import { SlowRedirect } from './SlowRedirect';

function getShowAuthenticatedRoute(route: {
  requiresEditor?: boolean;
  requiresAdmin?: boolean;
  requiresAuth?: boolean;
  requiredFeatureFlag?: string;
}) {
  const { requiresAuth, requiresEditor, requiresAdmin, requiredFeatureFlag } =
    route;

  return requiresAuth || requiresEditor || requiresAdmin || requiredFeatureFlag;
}
export type RouteDefinition =
  | (RouteProps & {
      lazyComponent?: () => Promise<{ default: any }>;
      requiresEditor?: boolean;
      requiresAdmin?: boolean;
      requiresAuth?: boolean;
      requiredFeatureFlag?: string;
    })
  | RedirectProps;

const buildLazyRoutes = (routes: RouteDefinition[]) => {
  return routes.map((route, index) => {
    if ('to' in route) {
      // eslint-disable-next-line react/no-array-index-key
      return <SlowRedirect key={index} to={route.to} />;
    }
    let component;
    if (route.lazyComponent) {
      component = getLazyComponent(route.lazyComponent);
    } else {
      component = route.component;
    }
    let RouteComponent = Route as any;

    const showAuthenticatedRoute = getShowAuthenticatedRoute(route);
    if (showAuthenticatedRoute) {
      RouteComponent = AuthenticatedRoute as unknown as typeof Route;
    }

    const key = (
      Array.isArray(route.path)
        ? route.path.join('-')
        : route.path || `${index}`
    ) as string;

    return (
      <RouteComponent
        key={key}
        {...route}
        component={component}
        requiresEditor={route.requiresEditor}
        requiresAdmin={route.requiresAdmin}
        requiredFeatureFlag={route.requiredFeatureFlag}
      />
    );
  });
};

type LazyComponentsSwitchProps = {
  // Routes must be constant
  routes: RouteDefinition[];
};

export const LazyComponentsSwitch: React.FC<LazyComponentsSwitchProps> = ({
  routes,
}) => {
  const renderRoutes = React.useRef(buildLazyRoutes(routes));
  return (
    <Suspense fallback={null}>
      <Switch>{renderRoutes.current}</Switch>
    </Suspense>
  );
};

export const getLazyComponent = <T extends React.ComponentType<any>>(
  importFunc: () => Promise<{ default: T }>
) => {
  const LazyComponent = lazy(importFunc);

  return (props: React.ComponentProps<T>): JSX.Element => (
    <LazyComponent {...props} />
  );
};
