import { useLocation } from 'react-router';

import useTranslations from 'modules/I18n/hooks/useTranslations';
import { CommercialNotifications } from 'modules/Notifications/components/CommercialNotifications';
import useTheme from 'modules/Theme/hooks/useTheme';
import {
  IconMenuHelp,
  IconMenuBookkeeper,
  IconMenuContactBookkeepper,
} from 'modules/Ui/Icons';
import { IconNotificationsBadge } from 'modules/Ui/Icons/Menu';
import { bookkeeperRegexMap, regexMap } from 'modules/Ui/Menu/regex';
import { useUserNotifications } from 'modules/UserNotifications/hooks/useUserNotifications';
import { ROUTES as ROUTES_NOTIFICATIONS } from 'modules/UserNotifications/routesMap';
import { ROUTES } from 'pages/bookkeepers/routesMap';

import MenuLink from '../../Components/MenuLink';
import { menu, menuBookkeeper } from '../../messages';
import Expandable from '../Components/Expandable';
import MenuWrapper from '../MenuWrapper';

const Menu = () => {
  const theme = useTheme();
  const location = useLocation();
  const url = location.pathname;
  const { pathname } = location;
  const { t } = useTranslations();
  const { data: notificationResponse } = useUserNotifications();
  const notificationLength =
    (notificationResponse && notificationResponse.unread) || 0;
  const isBookkeeperHomePage = url === ROUTES.HOME;
  return (
    <MenuWrapper
      linksNumber={2}
      links={
        <>
          <MenuLink
            active={bookkeeperRegexMap.clients.test(pathname)}
            icon={
              <IconMenuContactBookkeepper
                color={theme.colors.gray0}
                fillColor={theme.colors.gray0}
                active={bookkeeperRegexMap.clients.test(pathname)}
              />
            }
            text={t(menuBookkeeper.clients)}
            to={ROUTES.BOOKKEEPER_CLIENTS}
            data-testid="navbar-bookkeeper-clients"
            color={theme.colors.gray0}
            menuStyle="light"
            isBookkeeper
          />
          <MenuLink
            active={bookkeeperRegexMap.profile.test(pathname)}
            icon={
              <IconMenuBookkeeper
                color={theme.colors.gray0}
                fillColor={theme.colors.gray0}
                active={bookkeeperRegexMap.profile.test(pathname)}
              />
            }
            text={t(menuBookkeeper.profile)}
            to={ROUTES.BOOKKEEPER_PROFILE}
            data-testid="navbar-bookkeeper-profile"
            color={theme.colors.gray0}
            menuStyle="light"
            isBookkeeper
          />
        </>
      }
      notificationLength={notificationLength}
      iconedLinks={
        <>
          <MenuLink
            active={bookkeeperRegexMap.help.test(pathname)}
            color={theme.colors.gray0}
            icon={
              <IconMenuHelp
                type="light"
                active={bookkeeperRegexMap.help.test(pathname)}
              />
            }
            onlyIcon
            text={t(menu.help)}
            to="/bookkeeper/help"
            menuStyle="light"
            isBookkeeper
            rel="help"
          />
          <MenuLink
            active={regexMap.notifications.test(pathname)}
            color={theme.colors.gray0}
            icon={
              <IconNotificationsBadge
                number={notificationLength}
                active={regexMap.notifications.test(pathname)}
                isBookkeeper
              />
            }
            onlyIcon={true}
            text={t(menu.notifications)}
            to={ROUTES_NOTIFICATIONS.NOTIFICATIONS}
            menuStyle="light"
            isBookkeeper
            commercialNotification={
              isBookkeeperHomePage && <CommercialNotifications />
            }
          />
          <Expandable isBookkeeper />
        </>
      }
    />
  );
};

export default Menu;
