import { defineMessages } from 'react-intl';

export default defineMessages({
  chooseContact: {
    id: 'gofre.Documents.CreateContactDialog.choose-contat',
  },
  noMatches: {
    id: 'gofre.Documents.CreateContactDialog.no-matches',
  },
  noContactCreated: {
    id: 'gofre.Documents.CreateContactDialog.no-contact-created',
  },
  loadingContacts: {
    id: 'gofre.Documents.CreateContactDialog.loading',
  },
  clientLabel: {
    id: 'gofre.Documents.SearchContactInput.label',
  },
});
