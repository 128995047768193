import { useDispatch, useSelector } from 'react-redux';

import { removeCommercialNotification } from '../actions';
import { selectCommercialNotifications } from '../selectors';

const useCommercialNotifications = () => {
  const dispatch = useDispatch();
  const commercialNotifications = useSelector(selectCommercialNotifications);

  return {
    remove: (id: string) => dispatch(removeCommercialNotification(id)),
    commercialNotifications,
  };
};

export default useCommercialNotifications;
