import { getConfig } from 'modules/App/config';
import history from 'utils/history';

import featureFlags from './featureFlags';
import { ffStrToObj } from './helpers/featureFlags.helpers';
import {
  ConfigCatSource,
  CookiesSource,
  CustomEventSource,
  ReactRouterDOMSource,
} from './sources';

export default async function initFeatureFlags() {
  const reactRouterDOMSource = new ReactRouterDOMSource(window, history);
  const cookiesSource = new CookiesSource(window);
  const customEventSource = new CustomEventSource({
    root: window,
    options: {
      eventName: 'gofre:flags:update',
      onChange: () => {
        // Example:
        // window['dataLayer'].push({ event: 'optimize.activate' })
      },
    },
  });
  const configCatSource = new ConfigCatSource({
    options: {
      SDKKey: (await getConfig()).configCatSDKKey,
      defaultFlags: {},
    },
  });

  featureFlags.init({
    defaultFeatureFlags: ffStrToObj(''),
    debug: false,
  });

  featureFlags.initSource('QUERY_PARAMS', reactRouterDOMSource);
  featureFlags.initSource('COOKIES', cookiesSource);
  featureFlags.initSource('CUSTOM_EVENT', customEventSource);
  featureFlags.initSource('REMOTE_FLAGGER', configCatSource);
  return featureFlags;
}
