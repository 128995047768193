import { ReactNode } from 'react';

import { Box, Text } from 'modules/Ui';

interface Props {
  additionalText?: ReactNode;
  borderBottomColor?: string;
  borderWidth?: string;
  concept: string;
  conceptColor?: string;
  conceptFontWeight?: string;
  currency: string;
  currencyColor?: string;
  currencyFontWeight?: string;
  fadeIn?: boolean;
}

const PaymentResumeListItem: React.FC<Props> = ({
  additionalText,
  borderBottomColor,
  borderWidth,
  concept,
  conceptColor,
  conceptFontWeight,
  currency,
  currencyColor,
  currencyFontWeight,
  fadeIn,
}) => {
  return (
    <>
      <Box
        alignItems="center"
        borderBottomColor={borderBottomColor || 'transparent'}
        borderBottomStyle="solid"
        borderBottomWidth={borderWidth || '1px'}
        display="flex"
        flexWrap="wrap"
        justifyContent="space-between"
        padding="10px 0"
        {...{ fadeIn }}
      >
        <Text
          color={conceptColor || 'gray800'}
          fontSize={16}
          fontWeight={conceptFontWeight}
          hasEllipsis
          lineHeight={26}
          maxWidth="65%"
          tag="dt"
        >
          {concept}
        </Text>
        <Box
          color={currencyColor || 'gray800'}
          fontSize={18}
          fontWeight={currencyFontWeight || '600'}
          tag="dd"
        >
          {currency}
        </Box>
        {additionalText}
      </Box>
    </>
  );
};

export default PaymentResumeListItem;
