import { ActionType } from 'typesafe-actions';

import * as actions from './actions';
import ActionTypes from './constants';

export const initialState: string = 'es-ES';

const reducer = (
  state: string = initialState,
  action: ActionType<typeof actions>
): string => {
  switch (action.type) {
    case ActionTypes.SET_LOCALE:
      return action.payload;

    default:
      return state;
  }
};

export default reducer;
