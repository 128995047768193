import { ReactNode, FC, useState } from 'react';
import { Visible } from 'react-grid-system';

import styled from 'styled-components';
import { space, SpaceProps } from 'styled-system';

import Box from '../Box';
import CustomExpandable from '../CustomExpandable';
import { IconArrow } from '../Icons';
import Text from '../Text';

interface Props extends SpaceProps {
  labelExpanded?: string;
  labelCollapsed?: string;
  children?: ReactNode;
}

const StyledCustomExpandable = styled(CustomExpandable)`
  ${space}
`;

const ChartCustomExpandable: FC<Props> = ({
  children,
  labelExpanded,
  labelCollapsed,
  ...rest
}) => {
  const [expanded, setExpanded] = useState(false);
  return (
    <Visible xs>
      <StyledCustomExpandable
        id="mobile-chart"
        onChange={setExpanded}
        titleAtTheEnd
        {...rest}
        title={
          <>
            <Box
              alignItems="center"
              display="flex"
              height="24px"
              justifyContent="center"
              marginTop={expanded && '16px'}
              tag="span"
            >
              <Text
                color="brand500"
                fontSize={16}
                lineHeight={28}
                marginRight="8px"
                tag="span"
              >
                {expanded ? labelExpanded : labelCollapsed}
              </Text>
              <IconArrow
                color="brand500"
                transition
                transform={expanded ? 'rotate(-180deg)' : 'rotate(0)'}
              />
            </Box>
          </>
        }
        content={children}
      />
    </Visible>
  );
};

StyledCustomExpandable.defaultProps = {
  marginTop: '24px',
};

export default ChartCustomExpandable;
