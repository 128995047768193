import { defineMessages } from 'react-intl';

import { DefineMessages } from 'modules/I18n/types';

export const helpBai: DefineMessages = defineMessages({
  tabTitle: { id: 'gofre.Help.Tabs.Bai.title' },
  faqBai1: { id: 'gofre.Help.Bai.faq.1' },
  faqBai1Answer: { id: 'gofre.Help.Bai.faq.1.answer' },
  faqBai2: { id: 'gofre.Help.Bai.faq.2' },
  faqBai2Answer: { id: 'gofre.Help.Bai.faq.2.answer' },
  faqBai3: { id: 'gofre.Help.Bai.faq.3' },
  faqBai3Answer: { id: 'gofre.Help.Bai.faq.3.answer' },
  faqBai4: { id: 'gofre.Help.Bai.faq.4' },
  faqBai4Answer: { id: 'gofre.Help.Bai.faq.4.answer' },
  faqBai5: { id: 'gofre.Help.Bai.faq.5' },
  faqBai5Answer: { id: 'gofre.Help.Bai.faq.5.answer' },
  faqBai6: { id: 'gofre.Help.Bai.faq.6' },
  faqBai6Answer: { id: 'gofre.Help.Bai.faq.6.answer' },
  faqBai7: { id: 'gofre.Help.Bai.faq.7' },
  faqBai7Answer: { id: 'gofre.Help.Bai.faq.7.answer' },
  faqBai8: { id: 'gofre.Help.Bai.faq.8' },
  faqBai8Answer: { id: 'gofre.Help.Bai.faq.8.answer' },
  faqBai9: { id: 'gofre.Help.Bai.faq.9' },
  faqBai9Answer: { id: 'gofre.Help.Bai.faq.9.answer' },
  faqBai10: { id: 'gofre.Help.Bai.faq.10' },
  faqBai10Answer: { id: 'gofre.Help.Bai.faq.10.answer' },
  faqBai11: { id: 'gofre.Help.Bai.faq.11' },
  faqBai11Answer: { id: 'gofre.Help.Bai.faq.11.answer' },
  faqBai12: { id: 'gofre.Help.Bai.faq.12' },
  faqBai12Answer: { id: 'gofre.Help.Bai.faq.12.answer' },
  faqBai13: { id: 'gofre.Help.Bai.faq.13' },
  faqBai13Answer: { id: 'gofre.Help.Bai.faq.13.answer' },
  faqBai14: { id: 'gofre.Help.Bai.faq.14' },
  faqBai14Answer: { id: 'gofre.Help.Bai.faq.14.answer' },
  faqBai15: { id: 'gofre.Help.Bai.faq.15' },
  faqBai15Answer: { id: 'gofre.Help.Bai.faq.15.answer' },
  faqBai16: { id: 'gofre.Help.Bai.faq.16' },
  faqBai16Answer: { id: 'gofre.Help.Bai.faq.16.answer' },
  faqBai17: { id: 'gofre.Help.Bai.faq.17' },
  faqBai17Answer: { id: 'gofre.Help.Bai.faq.17.answer' },
  faqBai18: { id: 'gofre.Help.Bai.faq.18' },
  faqBai18Answer: { id: 'gofre.Help.Bai.faq.18.answer' },
  faqBai19: { id: 'gofre.Help.Bai.faq.19' },
  faqBai19Answer: { id: 'gofre.Help.Bai.faq.19.answer' },
});
