import { FC } from 'react';
import { Hidden } from 'react-grid-system';
import { useSelector } from 'react-redux';

import { AnalyticsEvent } from 'modules/Analytics';
import analyticsService from 'modules/Analytics/services/analyticsService';
import { selectIsMobile } from 'modules/App/store/selectors';
import { UserPermissions } from 'modules/Auth/constants';
import useHasUserScope from 'modules/Auth/hooks/checkUsersPermissions';
import useTranslations from 'modules/I18n/hooks/useTranslations';
import { useOpenModal } from 'modules/Modals';
import { ModalTypes } from 'modules/Modals/constants';
import {
  selectHasUnlimitedPlan,
  selectIsMySubsciptionTrial,
} from 'modules/Subscriptions/detail/selectors';
import { PLANS } from 'modules/Subscriptions/models';
import { ROUTES } from 'modules/Subscriptions/routesMap';
import { Box, Button, Link } from 'modules/Ui';
import DataTableZeroState from 'modules/Ui/DataTable/DataTableZeroState';

import { zeroState } from '../messages';

export const RemittancesZeroState: FC = () => {
  const openModal = useOpenModal();
  const { t } = useTranslations();
  const isMobile = useSelector(selectIsMobile);
  const hasUnlimitedPlan = useSelector(selectHasUnlimitedPlan);
  const isTrial = useSelector(selectIsMySubsciptionTrial);
  const hasUnlimitedAccess = hasUnlimitedPlan || isTrial;
  const zeroStateSubtitle = isMobile
    ? t(zeroState.zeroStateSubiitleMobile)
    : t(zeroState.zeroStateSubitle);
  const subtitleText = hasUnlimitedAccess
    ? zeroStateSubtitle
    : t(zeroState.zeroStateSubiitleNoUnlimited);
  const { hasUserScope } = useHasUserScope();
  const canCreate = hasUserScope(UserPermissions.SUBSCRIPTION_EDITOR);
  return (
    <Box backgroundColor="gray0">
      <DataTableZeroState
        height="100%"
        img="/assets/empty-remittances.svg"
        subtitle={subtitleText || zeroStateSubtitle}
        title={t(zeroState.zeroStateTitle)}
      >
        {canCreate && (
          <Hidden xs>
            {hasUnlimitedAccess ? (
              <Button
                data-testid="remittances-create"
                marginTop="16px"
                padding={{ sm: '0 40px' }}
                onClick={() => {
                  openModal({
                    type: ModalTypes.ADD_REMITTANCE_MODAL,
                  });
                }}
                variant="secondary"
              >
                {t(zeroState.zeroStateButton)}
              </Button>
            ) : (
              <Link
                data-testid="update-plan"
                marginTop="16px"
                padding={{ sm: '0 40px' }}
                to={`${ROUTES.SUBSCRIPTIONS_CHANGE}/${PLANS.UNLIMITED[1]}`}
                variant="stroke"
                onClick={() => {
                  analyticsService.track(
                    AnalyticsEvent.REMITTANCE_BAIT_UPDATE_PLAN
                  );
                }}
              >
                {t(zeroState.zeroStateLink)}
              </Link>
            )}
          </Hidden>
        )}
      </DataTableZeroState>
    </Box>
  );
};
