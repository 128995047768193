import { defineMessages } from 'react-intl';

const messages = defineMessages({
  createError: { id: 'gofre.Payments.Create.create-error' },
  createMultipleError: { id: 'gofre.Payments.Create.create-multiple-error' },
  createSuccess: { id: 'gofre.Payments.Create.create-success' },
  createMultipleSuccess: {
    id: 'gofre.Payments.Create.create-multiple-success',
  },
  createFromListSuccess: {
    id: 'gofre.Payments.Create.fromList.create-success',
  },
  createMultiplePartial: {
    id: 'gofre.Payments.Create.create-multiple-partial',
  },
  editSuccess: { id: 'gofre.Payments.Create.edit-success' },
  editError: { id: 'gofre.Payments.Create.edit-error' },
  fetchingError: { id: 'gofre.Payments.Fetch.fetch-error' },
  deletingErrors: { id: 'gofre.Payments.Delete.delete-error' },
  limitExceeded: { id: 'gofre.Payments.Create.limit-exceeded' },
});

export default messages;
