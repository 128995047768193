import { NewDocumentForm } from '../models/form';
import { normalizeRecurrentValues } from './normalizeRecurrentValues';
import { normalizeRegularValues } from './normalizeRegularValues';

type NormalizeValues = {
  values: NewDocumentForm;
  isRecurrent?: boolean;
  hasElectronicProvider?: boolean;
  isQuote?: boolean;
  isDraft?: boolean;
  isTest?: boolean;
  isProforma?: boolean;
  isCorrective?: boolean;
  isReceipt?: boolean;
  forceFixedDate?: boolean;
  isReplacing?: boolean;
  isDeliveryNote?: boolean;
};

export const normalizeValues = ({
  values,
  isRecurrent,
  hasElectronicProvider,
  isQuote,
  isDraft,
  isTest,
  isProforma,
  isCorrective,
  isReceipt,
  forceFixedDate,
  isReplacing,
  isDeliveryNote,
}: NormalizeValues) => {
  const isNotBillable = !!isQuote || !!isProforma || !!isDeliveryNote;
  return isRecurrent
    ? normalizeRecurrentValues(values, hasElectronicProvider)
    : normalizeRegularValues({
        values,
        hasElectronicProvider,
        isDraft,
        isTest,
        isCorrective,
        isReceipt,
        forceFixedDate,
        isReplacing,
        isNotBillable,
      });
};
