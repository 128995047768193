import { NewDocumentForm } from '../models/form';

// if not defined no data will be sent
export function processBizkaiaData({
  naturalPerson,
  epigraphIAE,
  lastEpigraphsIAE,
  chargingCriterionIRPF,
  customIRPF,
}: NewDocumentForm) {
  return {
    ...(naturalPerson && { naturalPerson }),
    ...(epigraphIAE && { epigraphIAE }),
    ...(lastEpigraphsIAE && { lastEpigraphsIAE }),
    ...(naturalPerson && { chargingCriterionIRPF: !!chargingCriterionIRPF }),
    ...(customIRPF && { customIRPF }),
  };
}
