import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { selectIsMobile } from 'modules/App/store/selectors';
import useTranslations from 'modules/I18n/hooks/useTranslations';
import useTheme from 'modules/Theme/hooks/useTheme';
import { tooltipVisibleContent } from 'modules/Theme/mixins';
import styled from 'modules/Theme/styled-components';
import { Tooltip, Text, Box } from 'modules/Ui';

import messages from '../messages';

interface RectifyTooltipProps {
  href: string;
  id?: string;
}

const StyledContent = styled(Box)`
  .identifier {
    display: block;
    text-decoration: underline;
  }
  ${tooltipVisibleContent}
`;

const RectifyTooltip = (props: RectifyTooltipProps) => {
  const theme = useTheme();
  const history = useHistory();
  const { href, id } = props;
  const { t } = useTranslations();
  const isMobile = useSelector(selectIsMobile);
  const handleOnClick = useCallback(
    (e: MouseEvent) => {
      e.stopPropagation();
      history.push(href);
    },
    [href, history]
  );

  return (
    <Tooltip
      delayHide={500}
      id={id}
      marginLeft="4px"
      place="bottom"
      tooltipBackgroundColor={theme.colors.primary400}
      tooltipContentPadding="8px 21px"
      disable={!!isMobile}
      tooltipButton={
        <Text
          alignItems="center"
          border={`1px solid ${theme.colors.brand500}`}
          borderRadius="1px"
          color="brand500"
          display="flex"
          fontSize={8}
          justifyContent="center"
          size={12}
          tag="span"
        >
          R
        </Text>
      }
      tooltipContent={
        <StyledContent
          color="gray0"
          data-testid={`${id}-test`}
          display="block"
          fontSize={12}
          lineHeight={14}
          fontWeight="600"
          onClick={handleOnClick}
          padding="0"
          textAlign="center"
        >
          {t(messages.correctiveTooltip)}
          <Box
            color={theme.colors.gray0}
            display="block"
            fontSize="inherit"
            lineHeight="inherit"
            textAlign="center"
            className="identifier"
            tag="span"
          >
            {id}
          </Box>
        </StyledContent>
      }
    />
  );
};

export default RectifyTooltip;
