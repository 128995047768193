import { differenceInDays } from 'date-fns';

import Business from 'modules/Business/models/business';
import { taxOptions } from 'modules/Taxes';
import { getVatKey } from 'modules/Taxes/getVatKey';
import { VODAFONE_PARTNER } from 'utils/vodafone/constants/vodafone.constant';

import { Coupon } from './Create/types';
import {
  SubscriptionPlans,
  DAYS_TO_EXPIRE,
  PAYMENT_PERIODS,
  DURATION,
  Subscription,
  SubscriptionTaxes,
} from './models';

export const isTrial = (subscription: Subscription) =>
  subscription.provider === SubscriptionPlans.TRIAL;

export const isActive = (subscription: Subscription) => subscription.active;

export const isPaused = (subscription: Subscription) =>
  subscription.requestedPauseAt != null && !isActive(subscription);

export const isTrialEnded = (subscription: Subscription) =>
  isTrial(subscription) && !isActive(subscription);

export const isTrialNearToExpire = (subscription: Subscription) => {
  if (!subscription.endDate) return false;
  const expirationDate = new Date(subscription.endDate);
  return (
    isTrial(subscription) &&
    differenceInDays(expirationDate, new Date()) <= DAYS_TO_EXPIRE
  );
};

export const subscriptionCancelledAt = (subscription: Subscription) =>
  subscription.endDate;

export const hasCancellationBeenRequested = (subscription: Subscription) =>
  subscription.requestedCancellationAt &&
  subscription.endDate &&
  new Date(subscription?.endDate) > new Date();

export const mustShowReactivationBanner = (subscription: Subscription) => {
  if (!subscription || !subscription.endDate) return false;

  const today = Date.now();
  const endDate = new Date(subscription?.endDate).getTime();
  const maxRangeInMillis = 60 * 24 * 60 * 60 * 1000; // 60 days

  return (
    subscription.requestedCancellationAt &&
    subscription.endDate &&
    endDate > today &&
    endDate - today < maxRangeInMillis
  );
};

export const isMySubsciptionNearToBeCancelled = (subscription: Subscription) =>
  !isTrial(subscription) &&
  isActive(subscription) &&
  hasCancellationBeenRequested(subscription);

export const hasSubscription = (subscription: Subscription) =>
  subscription.products &&
  (subscription.products[0]?.productId !== SubscriptionPlans.TRIAL ||
    subscription.partner === VODAFONE_PARTNER);

export const getPlanId = (subscription: Subscription) =>
  subscription.products && subscription.products[0]?.productId;

export const getPlanName = (subscription: Subscription) =>
  subscription.products && subscription.products[0]?.marketName;

export const getPeriodicity = (duration: string): string => {
  const periodicity = duration.slice(-1);
  if (periodicity === DURATION.MONTHLY) {
    return PAYMENT_PERIODS.MONTHLY;
  }
  return PAYMENT_PERIODS.YEARLY;
};

export function generateSubscriptionTaxes(
  business: Business,
  price: number,
  coupon?: Coupon
): SubscriptionTaxes {
  const province = business?.address?.province;
  const {
    amount: taxPercentage,
    label: taxLabel,
    type: taxType,
  } = taxOptions[getVatKey(province)];
  const taxLabelReversed = `${taxType} (${taxPercentage}%)`;

  const discount =
    coupon && coupon?.absoluteDiscount ? coupon.absoluteDiscount / 100 : 0;

  const subtotal =
    coupon && coupon.absoluteDiscount && coupon.productPrice
      ? coupon.productPrice / 100
      : price;

  const taxBase = subtotal - discount;
  const taxes = (taxBase * taxPercentage) / 100;

  const total = subtotal - discount + taxes;
  const totalToPay = total > 0 ? total : 0;

  const taxesAfterDiscountEnd = (subtotal * taxPercentage) / 100;
  const priceAfterDiscountEnd = subtotal + taxesAfterDiscountEnd;

  return {
    basePrice: subtotal,
    fullPriceWithTax: priceAfterDiscountEnd,
    priceWithTax: totalToPay,
    tax: taxes,
    taxLabel,
    taxLabelReversed,
    taxPercentage,
    totalToPay,
  };
}
