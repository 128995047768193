import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { selectCurrentBusiness } from 'modules/Business/Current/selectors';
import { selectUser } from 'modules/Users/selectors';

import { getConfig } from '../config';

export const useSmartLookConfig = () => {
  const [isInitilized, setIsInitilized] = useState(false);
  const { id: currentBusinessId, vatNumber } = useSelector(
    selectCurrentBusiness
  );
  const { userId, email } = useSelector(selectUser);

  useEffect(() => {
    (async () => {
      const { smartlookKey } = await getConfig();
      if (smartlookKey) {
        (window as any).smartlook('init', smartlookKey);
        setIsInitilized(true);
      }
    })();
  }, []);

  useEffect(() => {
    if (currentBusinessId && isInitilized) {
      const { smartlook } = window as any;
      smartlook('consentAPI', 'consentAPI');
      smartlook('consentForms', 'consentForms');
      smartlook('consentIP', 'consentIP');
      smartlook('identify', currentBusinessId, {
        vatNumber,
        userId,
        email,
      });
    }
  }, [isInitilized, currentBusinessId, vatNumber, userId, email]);
};
