import IconByPath, { IconByPathProps } from './Icon/IconType/IconByPath';

const IconProfileSector = (props: IconByPathProps) => {
  return (
    <IconByPath
      {...props}
      fillRule="evenodd"
      path="M15 2H6v21H3v-1h2V1h11v10h4v11h2v1h-3V12h-4V2ZM9 4H8v3h1V4Zm0 5H8v3h1V9Zm-1 5h1v3H8v-3Zm1 5H8v3h1v-3Zm3-15h1v3h-1V4Zm1 5h-1v3h1V9Zm-1 5h1v3h-1v-3Zm5 0h-1v3h1v-3Zm-5 5h1v3h-1v-3Zm5 0h-1v3h1v-3Z"
    />
  );
};

export default IconProfileSector;
