import { DetailedHTMLProps, InputHTMLAttributes } from 'react';

import { LayoutProps, TypographyProps } from 'styled-system';

import HtmlComponent from './HtmlComponent';
import Props from './Props';

const validProps = [
  'autoFocus',
  'checked',
  'disabled',
  'form',
  'formnovalidate',
  'max',
  'maxLength',
  'min',
  'minlength',
  'name',
  'pattern',
  'placeholder',
  'readonly',
  'required',
  'type',
  'value',
  'autoComplete',
];

const Input = (props: Props & TypographyProps) => (
  <HtmlComponent tag="input" {...{ validProps }} {...props} />
);

// TODO: kill it with fire
export interface HTMLInputElementLayoutProps
  extends Omit<
      Omit<
        Omit<
          DetailedHTMLProps<
            InputHTMLAttributes<HTMLInputElement>,
            HTMLInputElement
          >,
          'width'
        >,
        'height'
      >,
      'size'
    >,
    LayoutProps {}

export default Input;
