import IconByPath, { IconByPathProps } from './Icon/IconType/IconByPath';

const IconInfo = (props: IconByPathProps) => {
  return (
    <IconByPath
      {...props}
      clipRule="evenodd"
      fillRule="evenodd"
      path="M1 12C1 5.925 5.925 1 12 1s11 4.925 11 11-4.925 11-11 11S1 18.075 1 12Zm21 0c0-5.523-4.477-10-10-10S2 6.477 2 12s4.477 10 10 10 10-4.477 10-10ZM11 6h2v2h-2V6Zm.5 4h1v8h-1v-8Z"
      shapeRendering="geometricPrecision"
    />
  );
};
export default IconInfo;
