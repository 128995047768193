import styled, { keyframes } from 'modules/Theme/styled-components';

import Path from '../../Html/Path';

interface PathProps {
  animationDuration?: number;
}

const animationStep2 = keyframes`
  55% {
    stroke-dashoffset: 61;
  }
  65% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: 0;
  }
`;

// prettier-ignore
export const StyledPath = styled(Path)<PathProps>`
  animation: ${animationStep2}
  ${({ animationDuration }) => animationDuration && `${animationDuration}ms`} ease 0ms infinite,  animationFade
  ${({ animationDuration }) => animationDuration && `${animationDuration}ms`} linear 0ms infinite;
  stroke-dasharray: 60 62;
  stroke-dashoffset: 61;
`;

const SecondAnimation = (props: PathProps) => {
  const { animationDuration } = props;
  return (
    <>
      <StyledPath
        {...{ animationDuration }}
        d="M45.0082718,63.5161685L74.9618869,63.5161685L74.9618869,93.4273331"
        transform="translate(60, 78) scale(-1, -1) rotate(31) translate(-60, -78.4)"
        shapeRendering="geometricPrecision"
      />
    </>
  );
};

export default SecondAnimation;
