import { useCallback } from 'react';

import { useField } from 'formik';

import DayPickerField from 'modules/Ui/Form/DayPickerField';

import { FormikFieldProps } from '../FormikFieldProps';
import useFocusHandler from '../useFocusHandler';

const DayPickerFormikFieldDesktop = (props: FormikFieldProps) => {
  const {
    helpText,
    hidden,
    id,
    label = '',
    labelHidden,
    name,
    margin,
    required,
    width,
    disabledDays,
    ...rest
  } = props;

  const [field, meta, helpers] = useField(name || id);
  const { setTouched, setValue } = helpers;

  const onChange = useCallback((value: any) => {
    setTouched(true);
    return setValue(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFocusHandler = useFocusHandler(name || id);

  return hidden ? null : (
    <DayPickerField
      {...field}
      {...{
        helpText,
        id,
        name,
        label,
        labelHidden,
        margin,
        required,
        width,
        disabledDays,
        onChange,
        ...rest,
      }}
      error={meta.touched && meta.error ? meta.error : undefined}
      onFocus={onFocusHandler}
    />
  );
};

export default DayPickerFormikFieldDesktop;
