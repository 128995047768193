import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  title: { id: 'gofre.remittances.documentsAlreadyInARemittanceModal.title' },
  subtitle: {
    id: 'gofre.remittances.documentsAlreadyInARemittanceModal.subtitle',
  },
  finalText: {
    id: 'gofre.remittances.documentsAlreadyInARemittanceModal.finalText',
  },
});
