import { loadStripe, Stripe } from '@stripe/stripe-js';

import { reloadHeadScript } from 'utils/reloadHeadScript';

export async function getStripe(stripeApiKey: string): Promise<Stripe | null> {
  let stripe: Stripe | null = null;
  /* eslint-disable no-await-in-loop */
  do {
    if (window.Stripe) {
      stripe = (window.Stripe as any)(stripeApiKey);
      break;
    } else {
      try {
        stripe = await loadStripe(stripeApiKey);
      } catch (e) {
        reloadHeadScript('script[src*="stripe.com"]');
      }
    }
    if (!stripe) {
      await new Promise((res) => setTimeout(() => res(undefined), 5000));
    }
  } while (!stripe);
  /* eslint-enable no-await-in-loop */
  return stripe;
}
