import { useState } from 'react';
import { useSelector } from 'react-redux';

import { differenceInDays } from 'date-fns';

import useTranslations from 'modules/I18n/hooks/useTranslations';
import {
  selectSubscriptionCancellationDate,
  getSubscription,
} from 'modules/Subscriptions/detail/selectors';
import { ROUTES } from 'modules/Subscriptions/routesMap';
import { defaultResponsiveConfig } from 'modules/Theme/breakpoints';
import useTheme from 'modules/Theme/hooks/useTheme';
import { Box, Text, Button, Link } from 'modules/Ui';
import { IconCross } from 'modules/Ui/Icons';
import { menuSizes } from 'modules/Ui/Menu/MenuMobile/menuSizes';
import formatDate from 'utils/dates/formatDate';

import messages from './messages';

interface ProductInfoBannerProps {
  subsciptionNearToBeCancelled: boolean;
}

const BannerEndTrial: React.FC<ProductInfoBannerProps> = ({
  subsciptionNearToBeCancelled,
}) => {
  const { t } = useTranslations();
  const theme = useTheme();
  const cancellationDate = useSelector(selectSubscriptionCancellationDate);
  const subscription = useSelector(getSubscription);
  const navigateTo = subsciptionNearToBeCancelled
    ? ROUTES.SUBSCRIPTIONS
    : ROUTES.SUBSCRIPTIONS_PLANS;
  const remainingDays = differenceInDays(
    new Date(subscription?.endDate ?? ''),
    new Date()
  );
  const [isShown, setIsShown] = useState(true);
  const text = subsciptionNearToBeCancelled
    ? t(messages.trialNearToBeCancelledMsg, {
        date: formatDate(cancellationDate),
      })
    : t(messages.trialNearToExpireMsg, {
        strong: (chunks) => (
          <Text marginLeft="2px" tag="span" fontWeight={600} color="gray0">
            {chunks}
          </Text>
        ),
        remainingDays,
      });
  const button = subsciptionNearToBeCancelled
    ? t(messages.trialNearToBeCancelledCta)
    : t(messages.trialNearToExpireCta);
  return (
    <>
      {isShown ? (
        <Box
          backgroundColor="brand700"
          className="banner__end__trial"
          boxSizing="border-box"
          height={{
            _: `${menuSizes.BANNER_END_TRIAL_MOBILE}px`,
            sm: `${menuSizes.BANNER_END_TRIAL_DESKTOP}px`,
          }}
          padding={{ _: '16px', sm: '0 16px' }}
        >
          <Box
            gridTemplateColumns="1fr auto"
            columnsGap="16px"
            alignItems="center"
            height={{ sm: '100%' }}
            display="grid"
            margin={{ md: '0 auto' }}
            width={{
              lg: defaultResponsiveConfig.containerWidths[3],
              xl: defaultResponsiveConfig.containerWidths[4],
            }}
          >
            <Text color="gray0" fontSize={14} lineHeight={16}>
              <Box
                display={{ _: 'block', sm: 'initial' }}
                marginBottom={{ _: '8px', sm: 'initial' }}
                tag="span"
              >
                {text}
              </Box>
              <Link
                fontSize={theme.fontSizes[14]}
                height={{ _: '28px', md: '28px' }}
                lineHeight={theme.lineHeights[26]}
                marginLeft={{ sm: '10px' }}
                onClick={() => setIsShown(false)}
                padding="0 24px"
                to={navigateTo}
                variant="secondary"
              >
                {button}
              </Link>
            </Text>
            <Button
              alignSelf={{ _: 'start', sm: 'initial' }}
              aria-label={t(messages.close)}
              backgroundColor="transparent"
              onClick={() => setIsShown(false)}
            >
              <IconCross color="gray0" />
            </Button>
          </Box>
        </Box>
      ) : null}
    </>
  );
};

export default BannerEndTrial;
