import { AxiosResponse } from 'axios';

import { getConfig } from 'modules/App/config';
import {
  DASHBOARD_QUERY,
  DOCUMENTS_QUERY,
  DOCUMENT_TRACKING_QUERY,
  CONTACTS_QUERY,
  PRODUCT_QUERY,
} from 'modules/App/queries/queries';
import { Document } from 'modules/Documents/models/document';
import request from 'utils/request';

const voidDocument = async (id: string): Promise<AxiosResponse<Document>> => {
  const config = await getConfig();
  const endpoint = config.apiDocumentsService;
  const fullEndpoint = `${endpoint}/${id}/void`;

  const response = await request<any>(fullEndpoint, {
    method: 'POST',
  });
  return {
    ...response.data,
  };
};

export const invalidateQueries = [
  DOCUMENTS_QUERY,
  DASHBOARD_QUERY,
  DOCUMENT_TRACKING_QUERY,
  CONTACTS_QUERY,
  PRODUCT_QUERY,
];

export default voidDocument;
