import { Contact, VatNumberType } from 'modules/Contacts/models';

export function getInitialValues(contact: Contact) {
  const { fiscalName, vatNumber, address } = contact;

  const { postalAddress, postalCode, city, province, country } = address;

  return {
    fiscalName,
    vatNumber: vatNumber || '',
    vatNumberType: VatNumberType.NIF,
    address: {
      postalAddress: postalAddress || '',
      postalCode: postalCode || '',
      city: city || '',
      province: province || '',
      country: country || '',
    },
  };
}
