import { dotFieldToQuery } from './dotFieldToQuery';

type PaginationSortItem = {
  field?: string;
  order?: string;
};

type PaginationSortType = {
  page?: number;
  pageSize?: number;
  sort?: PaginationSortItem[];
  search?: string;
};

export function buildTortillasSortQuery(sort?: PaginationSortItem[]) {
  return (sort ?? []).reduce((currentQuery, sortEntry) => {
    if (!sortEntry?.field || !sortEntry?.order) {
      return currentQuery;
    }
    return `${currentQuery}&sort${dotFieldToQuery(sortEntry.field)}=${
      sortEntry.order
    }`;
  }, '');
}

/**
 * Takes standard params that come from list (useDataTablePagination)
 * and returns the corresponding tortilla query with pagination,
 * search term and sort by field
 * @param page current page
 * @param pageSize items per page
 * @param sort field to sort by
 * @param search text to search by
 * @returns
 */
export function buildTortillaBaseQuery({
  page,
  pageSize,
  sort,
  search,
}: PaginationSortType): string {
  let buildQuery =
    page !== undefined && pageSize !== undefined
      ? `offset=${page * pageSize}&limit=${pageSize}`
      : '';

  buildQuery += buildTortillasSortQuery(sort);
  if (search?.length && search?.length > 1) {
    buildQuery = `${buildQuery}&search=${search}`;
  }

  return buildQuery;
}
