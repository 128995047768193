import { useCallback } from 'react';
import { Visible } from 'react-grid-system';
import { useDispatch } from 'react-redux';

import { space, SpaceProps } from 'styled-system';
import { Action } from 'typesafe-actions';

import { down } from 'modules/Theme/breakpoints';
import styled from 'modules/Theme/styled-components';
import { Box } from 'modules/Ui';
import Limits from 'types/limits';

import useTranslations from '../../I18n/hooks/useTranslations';
import { dataTableMessages } from '../DataTable/messages';
import Button from '../Form/Button';
import Text from '../Text';
import calculatePages from './calculatePages';
import ItemsPerPage from './ItemsPerPage';
import Pages from './Pages';

const StyledPagination = styled(Box)`
  ${down('sm')} {
    [id^=ItemsPerPage-] {
      display: none;
    }
  }
  ${space}
`;

interface PaginationProps extends SpaceProps {
  allowSelectAll?: boolean;
  className?: string;
  changeLimit?(l: Limits): Action | null | undefined | void;
  changeOffset(o: number): Action | null | undefined | void;
  count: number;
  id?: string;
  limit: Limits;
  offset: number;
  selected?: object[];
  selectionInverted?: boolean;
  onToggleInvertedSelection?: () => void;
  totalCount: number;
  showEmptySpace?: boolean;
  selectSmall?: boolean;
  disallowChangeLimit?: boolean;
}

export const Pagination = ({
  selectSmall,
  allowSelectAll = true,
  className,
  id = 'pagination',
  changeLimit,
  changeOffset,
  count,
  limit,
  offset,
  selected = [],
  selectionInverted,
  totalCount,
  onToggleInvertedSelection,
  showEmptySpace,
  disallowChangeLimit,
  ...rest
}: PaginationProps) => {
  const { t } = useTranslations();
  const dispatch = useDispatch();

  const { activePage, pages } = calculatePages({ offset, limit, count });

  const onChangePage = useCallback(
    (selectedPage: number) => () => {
      const actionType = changeOffset((selectedPage - 1) * limit);
      if (actionType) {
        return dispatch(actionType);
      }
      return null;
    },
    [changeOffset, dispatch, limit]
  );

  const onChangeLimit = useCallback(
    (selectedLimit: Limits) => {
      const actionType = changeLimit?.(selectedLimit);
      if (actionType) {
        return dispatch(actionType);
      }
      return null;
    },
    [changeLimit, dispatch]
  );

  const actualCount = selectionInverted
    ? totalCount - selected.length
    : selected.length;

  const selectItemsLabel = selectionInverted
    ? t(dataTableMessages.unSelectAllItems, {
        count: totalCount - selected.length,
      })
    : t(dataTableMessages.selectAllItems, {
        count: totalCount,
      });

  return (
    <StyledPagination
      className={className}
      padding={{
        _: '0 16px',
        md: showEmptySpace ? '0 24px' : '0',
        lg: showEmptySpace ? '0 32px' : '0',
      }}
      {...rest}
    >
      <Box justifySelf="flex-start">
        {actualCount ? (
          <>
            <Text textSize="xs">
              {t(dataTableMessages.selected, {
                count: actualCount,
              })}
            </Text>

            {allowSelectAll ? (
              <Button
                data-testid="select-all-count"
                onClick={onToggleInvertedSelection}
                variant="link"
              >
                {selectItemsLabel}
              </Button>
            ) : null}
          </>
        ) : null}
      </Box>
      {!disallowChangeLimit && (
        <Visible md lg xl xxl>
          <Box flexGrow="2" />
          <ItemsPerPage {...{ selectSmall, id, limit, onChangeLimit }} />
        </Visible>
      )}
      <Pages {...{ id, activePage, pages, onChangePage }} />
    </StyledPagination>
  );
};

StyledPagination.defaultProps = {
  alignItems: 'center',
  display: 'flex',
  height: '44px',
  justifyContent: { _: 'center', sm: 'flex-end' },
  marginTop: '24px',
};
