import { getLoadedConfig } from 'modules/App/config';
import { TranslationFunction } from 'modules/I18n/hooks/useTranslations';
import { TemplateLanguages } from 'modules/I18n/types/templateLanguages';
import {
  TemplateSettings,
  TemplateType,
  LogoSize,
} from 'modules/Settings/Theme/types';
import theme from 'modules/Theme';
import messages from 'modules/Ui/SearchTemplateInput/messages';

import { INTER, SOURCE_SERIF_PRO } from '../constants';

const defaultColors = {
  [TemplateType.DEFAULT]: {
    primary: theme.colors.brand300,
  },
  [TemplateType.SIMPLE]: {
    primary: theme.colors.brand200,
    secondary: theme.colors.gray800,
    terciary: theme.colors.gray200,
  },
  [TemplateType.ZEBRA]: {
    primary: theme.colors.brand400,
    secondary: theme.colors.gray0,
    terciary: theme.colors.brand200,
  },
  [TemplateType.CLASSIC]: {
    primary: theme.colors.gray400,
    secondary: theme.colors.primary300,
  },
};

export const NEW_TEMPLATE = 'NEW_TEMPLATE';

export const getTemplateName = (
  t: TranslationFunction,
  number: number,
  template?: TemplateSettings
) =>
  template?.name ||
  t(messages.templateName, {
    templateNumber: number,
  });

export function buildLogoURL(
  configTemplateId: string,
  businessId: string,
  logoVersion?: string
) {
  const apiDomain =
    getLoadedConfig().request?.apiDomain ?? getLoadedConfig().apiDomain;
  const endpoint = getLoadedConfig().apiTemplatesConfig;

  let logoUrl = `${apiDomain}${endpoint}/${configTemplateId}/${businessId}/logo`;
  if (logoVersion) {
    logoUrl = `${logoUrl}?version=${logoVersion}`;
  }
  return logoUrl;
}

function getInitialValues({
  businessId,
  t,
  templatesNumber,
  template,
  isBai = false,
}: {
  businessId: string;
  t: TranslationFunction;
  templatesNumber: number;
  template?: TemplateSettings;
  isBai?: boolean;
}) {
  const defaultLanguage = isBai ? TemplateLanguages.EUES : TemplateLanguages.ES;
  const templateName = getTemplateName(t, templatesNumber, template);
  const defaultValues = {
    name: templateName,
    logoSize: LogoSize.LARGE,
    templateType: TemplateType.DEFAULT,
    typography: INTER,
    colors: defaultColors,
    templateId: NEW_TEMPLATE,
    language: defaultLanguage,
  };

  if (!template) {
    return defaultValues;
  }
  const { id, hasLogo, logoVersion, language, ...rest } = template;
  const logoUrl = buildLogoURL(id as string, businessId, logoVersion);
  const templateClassicLanguage =
    template.templateType === TemplateType.CLASSIC
      ? TemplateLanguages.ES
      : defaultLanguage;

  const templateClassicTypography =
    template.templateType === TemplateType.CLASSIC ? SOURCE_SERIF_PRO : INTER;

  return {
    templateId: id,
    ...rest,
    logoUrl: hasLogo ? logoUrl : undefined,
    name: templateName,
    // Build a colors object with an array of colors for each template type:
    // the template type applied to this configuration with have the colors saved by the user
    // and the rest of template types will have the default colors
    colors: { ...defaultColors, [template.templateType]: template.colors },
    language: template.language || templateClassicLanguage,
    typography: template.typography || templateClassicTypography,
  };
}

export default getInitialValues;
