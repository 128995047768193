import { useCallback } from 'react';

import { useInvalidateAndUpdate } from 'modules/App/hooks/useInvalidateAndUpdate';
import messages from 'modules/Contacts/Create/hooks/useCreateContact/messages';
import { Errors } from 'modules/Contacts/Create/services/createContact';
import updateContact, {
  invalidateQueries,
} from 'modules/Contacts/Update/services/updateContact/updateContact';
import { ModalTypes } from 'modules/Modals/constants';
import useOpenModal from 'modules/Modals/hooks/useOpenModal';
import useNotifications from 'modules/Notifications/hooks/useNotifications';
import { ResponseError } from 'types/response-error.type';
import requestErrorHandler from 'utils/requestErrorHandler';
import { useBlockingMutation } from 'utils/useBlockingMutation';

import { Contact } from '../models';

function useResurrectContact(): {
  resurrectContact: (contact: Contact, callbackFunction?: () => void) => void;
  isLoading: boolean;
} {
  const invalidate = useInvalidateAndUpdate();
  const notifications = useNotifications();
  const openModal = useOpenModal({
    type: ModalTypes.LIMIT_CONTACTS,
    navigation: 'do-nothing',
  });

  const { mutate, isLoading } = useBlockingMutation(updateContact, {
    onSuccess: () => {
      invalidate({
        invalidateQueries,
      });
      notifications.success(messages.createSuccess.id, { ttl: 5000 });
    },
    onError: (error: ResponseError) => {
      const { responseMessage, isNetworkError, networkErrorTranslationKey } =
        requestErrorHandler(error);
      if (responseMessage === Errors.CREATE_NOT_AVAILABLE) {
        return openModal();
      }
      return notifications.error(
        isNetworkError ? networkErrorTranslationKey : messages.createError.id
      );
    },
  });

  const resurrectContact = useCallback(
    (contact: Contact, callbackFunction?: () => void) => {
      if (contact.id) {
        mutate({
          ...contact,
          recover: true,
          callback: callbackFunction,
        });
      } else {
        notifications.error(messages.createError.id);
      }
    },
    [mutate]
  );
  return { resurrectContact, isLoading };
}

export default useResurrectContact;
