import { FC } from 'react';

import { resendInvitationModal } from 'modules/Bookkeeper/components/messages';
import { ResendInvitationModalProps } from 'modules/Bookkeeper/components/ResendInvitationModal/types';
import useTranslations from 'modules/I18n/hooks/useTranslations';
import { DialogConfirm, useCloseModal } from 'modules/Modals';
import useNotifications from 'modules/Notifications/hooks/useNotifications';
import resendBookkeeperInvitation from 'modules/UserInvitations/services/resendBookkeeperInvitation';
import { useBlockingMutation } from 'utils/useBlockingMutation';

import messages from './messages';

const ResendInvitationModal: FC<ResendInvitationModalProps> = ({
  ids,
  excludeIds,
}) => {
  const { t } = useTranslations();
  const closeModal = useCloseModal();
  const notifications = useNotifications();
  const { mutate } = useBlockingMutation(resendBookkeeperInvitation, {
    onSuccess: () => {
      notifications.success(messages.success.id);
    },
    onError: () => {
      notifications.error(messages.error.id);
    },
  });

  const handleConfirm = async () => {
    mutate({ ids, excludeIds });
    closeModal();
  };

  return (
    <DialogConfirm
      id="resendInvitation"
      title={t(resendInvitationModal.title, { count: ids.length })}
      text={t(resendInvitationModal.text, { count: ids.length })}
      confirmText={t(resendInvitationModal.confirm, { count: ids.length })}
      onConfirm={handleConfirm}
    />
  );
};

export default ResendInvitationModal;
