import {
  SpaceProps,
  space,
  position,
  PositionProps,
  border,
  BorderProps,
  margin,
  MarginProps,
} from 'styled-system';

import styled from 'modules/Theme/styled-components';
import { Box } from 'modules/Ui';

export interface NotificationBadgeProps
  extends SpaceProps,
    PositionProps,
    BorderProps,
    MarginProps {
  number?: number;
  label?: string;
}

const StyledBadge = styled(Box)`
  ${position}
  ${space}
  ${border}
  ${margin}
`;

export const NotificationBadge: React.FC<NotificationBadgeProps> = ({
  number,
  label,
  ...rest
}) => {
  if (number === undefined || !(number > 0)) {
    return null;
  }
  return (
    <StyledBadge
      alignItems="center"
      backgroundColor="accent500"
      borderRadius="9px"
      boxSizing="border-box"
      color="gray0"
      display="flex"
      fontSize={11}
      fontWeight="600"
      height="18px"
      justifyContent="center"
      lineHeight={14}
      minWidth="17px"
      padding={{
        _: '0 8px',
        sm: number < 10 ? '0' : '2px',
      }}
      tag="span"
      {...rest}
    >
      {number > 99 ? '+99' : number} {label}
    </StyledBadge>
  );
};
