type AuthData = {
  userId: string;
  currentToken: string;
  expireOn: number;
  businessId: string | undefined;
  tenant?: string;
};

export const { setAuthData, getAuthData, expireToken } = (() => {
  let authData: Partial<AuthData> = {};
  return {
    setAuthData: (auth: AuthData): void => {
      authData = auth;
    },
    getAuthData: (): Partial<AuthData> => authData,
    expireToken: (): void => {
      authData.expireOn = 0;
    },
  };
})();
