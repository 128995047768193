import useTranslations from 'modules/I18n/hooks/useTranslations';
import { FormikField } from 'modules/Ui';

import messages from '../messages';
import SubFormBox from './SubFormBox';

interface Props {
  isEditing: boolean;
  forContact?: boolean;
}

const BizumSubForm: React.FC<Props> = ({ isEditing = false }) => {
  const { t } = useTranslations();

  return (
    <SubFormBox isEditing={isEditing}>
      <FormikField
        id="data.phoneNumber"
        label={t(messages.phoneNumber)}
        required
        data-testid="phoneNumber-input"
      />
    </SubFormBox>
  );
};

export default BizumSubForm;
