import { getConfig } from 'modules/App/config';
import { CreateSubscription } from 'modules/Subscriptions/Create/types';
import { StripeInvoice } from 'modules/Subscriptions/hooks/useCreateStripeSubscription/types';
import request from 'utils/request';

export interface CreateSubscriptionData {
  id: string;
  status: string;
  latest_invoice: StripeInvoice;
  coupon?: string;
}

const createSubscription = async ({
  paymentMethodId,
  subscriptionProductId,
  billingEmail,
  coupon,
}: CreateSubscription): Promise<CreateSubscriptionData> => {
  const endpoint = (await getConfig()).apiStripeService;

  const response = await request<{ data: CreateSubscriptionData }>(
    `${endpoint}/subscriptions`,
    {
      method: 'POST',
      data: {
        paymentMethodId,
        subscriptionProductId,
        billingEmail,
        coupon,
      },
    }
  );
  return response.data.data;
};

export default createSubscription;
