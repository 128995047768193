import { Remittance, CreateRemittanceDto } from '../types';

export const normalizeRemittanceValues = (
  values: Remittance
): CreateRemittanceDto => {
  return {
    name: values.remittanceConcept,
    type: values.type,
    directDebitType: values.remittanceSepa,
    dueDate: new Date(values.remittanceExpiration),
    businessAccountingAccountId: values.accountingAccountId,
    bankId: values.remittanceId,
  };
};
