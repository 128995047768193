import IconByPath, { IconByPathProps } from './Icon/IconType/IconByPath';

const IconEnlargeInside = (props: IconByPathProps) => (
  <IconByPath
    {...props}
    path="M7.364 6.657V2.364h1v6h-6v-1h4.293L2 2.707 2.707 2l4.657 4.657ZM21.297 2 16.64 6.657V2.364h-1v6h6v-1h-4.293l4.657-4.657L21.297 2ZM16.64 21.64v-4.293l4.657 4.657.707-.707-4.657-4.657h4.293v-1h-6v6h1Zm-13.933.364 4.657-4.657v4.293h1v-6h-6v1h4.293L2 21.297l.707.707Z"
  />
);

export default IconEnlargeInside;
