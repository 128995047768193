import { getConfig } from 'modules/App/config';
import { REMITTANCES_QUERY } from 'modules/App/queries/queries';
import request from 'utils/request';

import { RemittanceStatus } from '../types';

export default async function changeRemittanceStatus({
  id,
  status,
}: {
  id: string;
  status: RemittanceStatus;
}): Promise<{ message: string }> {
  const endpoint = (await getConfig()).apiRemittancesService;
  const data = { status };
  const response = await request<{ message: string }>(
    `${endpoint}/${id}/set-status`,
    {
      method: 'PUT',
      data,
    }
  );
  return response?.data;
}

export const invalidateQueries = [REMITTANCES_QUERY];
