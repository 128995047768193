import { FC, useMemo } from 'react';

import useTranslations from 'modules/I18n/hooks/useTranslations';
import { Text } from 'modules/Ui';

import { shopifySuccess } from '../../messages';

interface ShopifyConfigurationSuccessBaiTextProps {
  needsToConfigureBai: boolean;
  needsToConnectBai: boolean;
}

export const ShopifyConfigurationSuccessBaiText: FC<
  ShopifyConfigurationSuccessBaiTextProps
> = (props) => {
  const { needsToConfigureBai, needsToConnectBai } = props;
  const { t } = useTranslations();
  const text = useMemo(() => {
    if (needsToConfigureBai) {
      return t(shopifySuccess.shopifyConfigSuccessTextConfigureBai);
    }

    if (needsToConnectBai) {
      return t(shopifySuccess.shopifyConfigSuccessTextConnectBai);
    }

    return t(shopifySuccess.shopifyConfigSuccessText);
  }, [needsToConfigureBai, needsToConnectBai]);

  return (
    <Text color="gray600" fontSize={16} lineHeight={22}>
      {text}
    </Text>
  );
};
