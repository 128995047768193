import IconByString, { IconByStringProps } from './Icon/IconType/IconByString';

const IconSpanishTaxAgency = (props: IconByStringProps) => {
  const { size, ...rest } = props;
  return (
    <IconByString {...rest} size={size || 32} viewBox="0 0 33 32">
      <path
        clipRule="evenodd"
        d="M4.476 2.783H16.66l15.258 25.75H19.733L4.476 2.784Z"
        fill="#00549A"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="m7.335 11.846 1.325 2.63c-1.575 1.425-2.35 2.383-3.45 4.178-1.476 2.408-2.076 3.982-2.526 6.782L.708 22.29c.65-2.014 1.15-3.12 2.226-4.963 1.4-2.336 2.4-3.59 4.4-5.481 0 0-2 1.892 0 0Z"
        fill="#D12920"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="m3.484 26.912 1.6 2.433c14.23-.786 25.634-12.24 26.734-20.227l-1.802-2.212C27.691 17.82 13.412 26.25 3.484 26.912Z"
        fill="#ECBA0B"
        fillRule="evenodd"
      />
    </IconByString>
  );
};

export default IconSpanishTaxAgency;
