import HtmlComponent from './HtmlComponent';
import Props from './Props';

const validProps = ['disabled', 'value'];

const Fieldset = (props: Props) => (
  <HtmlComponent tag="fieldset" {...{ validProps }} {...props} />
);

export default Fieldset;
