import { FC } from 'react';

import { ModalTypes } from 'modules/Modals/constants';
import { useOpenModal } from 'modules/Modals/hooks';
import useTheme from 'modules/Theme/hooks/useTheme';
import styled from 'modules/Theme/styled-components';
import { Box, Button, Text } from 'modules/Ui';
import { IconCheck } from 'modules/Ui/Icons';

export interface Props {
  baiConnection?: boolean;
  isActive?: boolean;
  text: string;
}

const StyledNumber = styled(Box)``;
const StyledButton = styled(Button)``;
const StyledItem = styled(Box)`
  ${StyledNumber} {
    &:before {
      content: ${({ isActive }) => !isActive && 'counter(my-counter)'};
    }
  }
  ${StyledButton} {
    @media (hover: hover) {
      &:hover {
        color: ${(props) => props.theme.colors.brand500};
      }
    }
    &:focus {
      color: ${(props) => props.theme.colors.brand500};
    }
  }
`;

const IntegrationStepsItem: FC<Props> = ({ baiConnection, text, isActive }) => {
  const theme = useTheme();
  const itemActive = isActive
    ? `${theme.colors.confirmation}`
    : `${theme.colors.primary300}`;
  const openModal = useOpenModal();
  return (
    <StyledItem
      alignItems="center"
      counterIncrement="my-counter"
      display="grid"
      gap="10px"
      gridTemplateColumns="32px 1fr"
      isActive={isActive}
      tag="li"
    >
      <StyledNumber
        alignItems="center"
        backgroundColor={isActive ? 'confirmation' : 'gray0'}
        border={`1px solid ${itemActive}`}
        borderRadius="50%"
        color="primary300"
        display="flex"
        fontSize={14}
        justifyContent="center"
        lineHeight={16}
        size={32}
        tag="span"
      >
        {isActive && <IconCheck color="gray0" size={16} />}
      </StyledNumber>
      {baiConnection && !isActive ? (
        <StyledButton
          color={theme.colors.gray800}
          fontSize={14}
          lineHeight={16}
          onClick={() => {
            openModal({
              type: ModalTypes.BAI_CONNECTION,
            });
          }}
          textAlign="left"
        >
          {text}
        </StyledButton>
      ) : (
        <Text color="gray800" fontSize={14} lineHeight={16} tag="span">
          {text}
        </Text>
      )}
    </StyledItem>
  );
};

export default IntegrationStepsItem;
