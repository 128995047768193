export const ROUTES = {
  MAIN_PATH: '/quotes',
  QUOTES_CREATE: '/quotes/create',
  QUOTES_DETAIL: '/quotes/:id',
  QUOTES_ISSUE: '/quotes/:id/issue',
  QUOTES_UPDATE: '/quotes/:id/edit',
  PROFORMAS_PATH: '/proformas',
  PROFORMA_ISSUE: '/proformas/:id/issue',
  PROFORMAS_DETAIL: '/proformas/:id',
  PROFORMAS_CREATE: '/proformas/create',
  PROFORMAS_UPDATE: '/proformas/:id/edit',
  PROFORMAS_COPY: '/proformas/:id/copy',
  QUOTES_COPY: '/quotes/:id/copy',
  DELIVERY_NOTE_PATH: '/delivery-notes',
  DELIVERY_NOTE_CREATE: '/delivery-notes/create',
  DELIVERY_NOTE_DETAIL: '/delivery-notes/:id',
  DELIVERY_NOTE_ISSUE: '/delivery-notes/issue',
  DELIVERY_NOTE_ISSUE_ONE: '/delivery-notes/:id/issue',
  DELIVERY_NOTE_UPDATE: '/delivery-notes/:id/edit',
};
