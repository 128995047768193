import { useSelector } from 'react-redux';

import { selectCurrentBusiness } from 'modules/Business/Current/selectors';
import {
  ElectronicDocumentProviders,
  BaiProviders,
} from 'modules/Business/models/business';
import useTranslations from 'modules/I18n/hooks/useTranslations';
import theme from 'modules/Theme';
import { IconBizkaia, IconGipuzkoa, IconAlava } from 'modules/Ui/Icons';
import { IconByPathProps } from 'modules/Ui/Icons/Icon/IconType/IconByPath';

import { baiNames } from './messages';

export interface ImgInfo {
  src: string;
  srcSet?: string;
}

export interface BaiInfo {
  logo?: React.FC<IconByPathProps>; // Add missing province Icons
  color?: string;
  detailLogo: ImgInfo;
  name: string;
}

const noInfo = {
  detailLogo: { src: '' },
  name: '',
};

export const gipuzkoaAssets = {
  logo: IconGipuzkoa,
  detailLogo: {
    src: '/assets/BAI/gipuzkoa-horizontal.svg',
  },
  color: theme.colors.gipuzkoaLogoEnabledColor,
};

export const bizkaiaAssets = {
  logo: IconBizkaia,
  detailLogo: {
    src: '/assets/BAI/bizkaia-horizontal.svg',
  },
  color: theme.colors.bizkaiaLogoEnabledColor,
};

export const alavaAssets = {
  logo: IconAlava,
  detailLogo: {
    src: '/assets/BAI/alava-horizontal.svg',
  },
  color: theme.colors.alavaLogoEnabledColor,
};

export const useBaiAssetsInfo = (): BaiInfo => {
  const { electronicDocumentProvider: baiProvider } = useSelector(
    selectCurrentBusiness
  );
  const { t } = useTranslations();
  const BAI_NONE = 'BAI-NONE';

  const baiInfo: Record<
    ElectronicDocumentProviders | typeof BAI_NONE,
    BaiInfo
  > = {
    [BaiProviders.SS]: {
      ...gipuzkoaAssets,
      name: t(baiNames.gipuzkoaName),
    },
    [BaiProviders.VI]: {
      ...alavaAssets,
      name: t(baiNames.bizkaiaName),
    },
    [BaiProviders.BI]: {
      ...bizkaiaAssets,
      name: t(baiNames.bizkaiaName),
    },
    [BAI_NONE]: noInfo,
  };

  return baiInfo[baiProvider || BAI_NONE];
};
