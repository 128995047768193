import IconByPath, { IconByPathProps } from './Icon/IconType/IconByPath';

const IconEstimateTaxes = (props: IconByPathProps) => (
  <IconByPath
    {...props}
    fillRule="evenodd"
    path="M5 3h14v6H5V3Zm4 7H5v3h4v-3Zm0 4H5v3h4v-3Zm1 3v-3h4v3h-4Zm-1 1H5v3h4v-3Zm1 3h4v-3h-4v3Zm0-8v-3h4v3h-4Zm9 8h-4V10h4v11ZM4 3a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v18a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V3Z"
  />
);

export default IconEstimateTaxes;
