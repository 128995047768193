import { FC } from 'react';

import useTranslations, {
  TranslationFunction,
} from 'modules/I18n/hooks/useTranslations';
import { Text } from 'modules/Ui';

import texts from '../../../messages';
import { PLANS } from '../../../models';

interface Props {
  productId: string;
  isActive?: boolean;
}

function getProductIdItems(t: TranslationFunction, productId: string) {
  if (PLANS.BASIC.includes(productId)) {
    return t(texts.basicDesc);
  }
  if (PLANS.PRO.includes(productId)) {
    return t(texts.proDesc);
  }
  return t(texts.unlimitedDesc);
}

const DescItems: FC<Props> = ({ productId, isActive }) => {
  const { t } = useTranslations();
  const text = getProductIdItems(t, productId);
  return (
    <Text
      color={isActive ? 'gray0' : 'gray800'}
      fontSize={16}
      height={{ _: '40px', md: '46px' }}
      key={text}
      lineHeight={20}
    >
      {text}
    </Text>
  );
};

export default DescItems;
