import { ReactNode } from 'react';

import { space, SpaceProps } from 'styled-system';

import styled from 'modules/Theme/styled-components';

import Box from '../../Box';

interface TooltipButtonProps extends SpaceProps {
  children: ReactNode;
  id?: string;
  isClickable?: boolean;
  label?: string;
  onClick?: (value: any) => void;
  ref?: any;
}

const StyledButtonTooltip = styled(Box)`
  ${space}

  &:focus {
    outline: none;
  }

  @media (hover: hover) {
    cursor: pointer;
    &[id$="tooltip-click"] {
      display: none;
    }
  }

  @media (hover: none) {
    &[id$="tooltip-hover"] {
      display: none;
    }
  }
`;

const TooltipButton = (props: TooltipButtonProps) => {
  const { children, ref, label, isClickable, id, onClick, ...rest } = props;
  return (
    <StyledButtonTooltip
      aria-describedby={`${id}-tooltip`}
      aria-label={label}
      className="tooltip-button"
      data-event={isClickable && 'click focus'}
      data-for={`${id}-tooltip`}
      data-testid={id}
      data-tip
      display="flex"
      onClick={onClick}
      ref={ref}
      id={isClickable ? `${id}-tooltip-click` : `${id}-tooltip-hover`}
      role="button"
      tabIndex={!isClickable ? '0' : undefined} // If the tooltip is not clickable, it should be focusable for accesibility reasons
      tag="span"
      {...{ ...rest }}
    >
      {children}
    </StyledButtonTooltip>
  );
};

export default TooltipButton;
