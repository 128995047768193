import {
  space,
  SpaceProps,
  layout,
  LayoutProps,
  flexbox,
  FlexboxProps,
  position,
  PositionProps,
} from 'styled-system';

import styled from 'modules/Theme/styled-components';
import { PillBeta, Box, Img } from 'modules/Ui';

export interface ShopifyBannerProps
  extends SpaceProps,
    LayoutProps,
    FlexboxProps,
    PositionProps {
  height?: number;
  ilustraWidth?: number;
  isBeta?: boolean;
  width?: number;
  withConfetti?: boolean;
}

const StyledWrapper = styled(Box)<ShopifyBannerProps>`
  ${flexbox}
  ${layout}
  ${position}
  ${space}
`;

const ShopifyIlustration = (props: ShopifyBannerProps) => {
  const { height, ilustraWidth, isBeta, width, withConfetti, ...rest } = props;
  return (
    <StyledWrapper
      height={height}
      position={isBeta || withConfetti ? 'relative' : undefined}
      width={width}
      {...rest}
    >
      {isBeta && (
        <Box
          alignItems="center"
          display="flex"
          justifyContent="center"
          position="absolute"
          tag="span"
        >
          <PillBeta />
        </Box>
      )}
      <Img
        alt=""
        aria-hidden="true"
        height={height}
        loading="eager"
        src="/assets/shopify/shopify-ilustration.svg"
        width={ilustraWidth}
      />
      {withConfetti && (
        <Img
          alt=""
          aria-hidden="true"
          height={height}
          left="0"
          loading="eager"
          position="absolute"
          src="/assets/shopify/shopify-ilustration-confetti.svg"
          width={width}
        />
      )}
    </StyledWrapper>
  );
};

export default ShopifyIlustration;
