import * as Yup from 'yup';

import { formText } from 'modules/Forms/messages';
import { TranslationFunction } from 'modules/I18n/hooks/useTranslations';

import messages from './messages';

const getValidationSchema = (t: TranslationFunction) =>
  Yup.object().shape({
    email: Yup.string()
      .email(t(messages.emailFormatError))
      .required(t(formText.mandatory)),
  });

export default getValidationSchema;
