import { getConfig } from 'modules/App/config';
import request from 'utils/request';

interface Params {
  stripeSubscriptionId: string;
}

export enum SubscriptionStatus {
  OK = 'ok',
  PENDING = 'pending',
  KO = 'ko',
}

interface SubscriptionStatusResponse {
  status: SubscriptionStatus;
}

const fetchSubscriptionStatus = async ({
  stripeSubscriptionId,
}: Params): Promise<SubscriptionStatusResponse> => {
  const endpoint = (await getConfig()).apiStripeService;

  const response = await request<{ data: SubscriptionStatusResponse }>(
    `${endpoint}/subscriptions/${stripeSubscriptionId}/status`,
    {
      method: 'GET',
    }
  );

  return response.data.data;
};

export default fetchSubscriptionStatus;
