import { FC } from 'react';

import { Box, Text, Link } from 'modules/Ui';
import { IconEmail } from 'modules/Ui/Icons';

interface Props {
  email: string;
  icon?: JSX.Element;
  text: string;
}

const Help: FC<Props> = ({ icon, email, text }) => {
  const mailIcon = icon || <IconEmail color="brand500" size={24} />;

  return (
    <Box
      alignItems="center"
      display="grid"
      gap={{ _: '28px', md: '24px' }}
      gridTemplateColumns="24px 1fr"
    >
      {mailIcon}
      <Text color="gray800" fontSize={14} lineHeight={18}>
        <Box display="block" tag="span">
          {text}
        </Box>
        <Link
          color="inherit"
          fontSize="inherit"
          fontWeight="600"
          hoverUnderline
          isExternal
          lineHeight="inherit"
          target="blank"
          to={`mailto:${email}`}
        >
          {email}
        </Link>
      </Text>
    </Box>
  );
};

export default Help;
