import { getConfig } from 'modules/App/config';
import request from 'utils/request';

export const updatePaymentMethod = async (
  paymentMethodId: string
): Promise<string> => {
  const endpoint = (await getConfig()).apiStripeService;

  const payload = {
    paymentMethodId,
  };
  const response = await request<{ data: string }>(
    `${endpoint}/update-payment-method`,
    {
      method: 'PUT',
      data: payload,
    }
  );

  return response.data.data;
};
