import IconByPath, { IconByPathProps } from './Icon/IconType/IconByPath';

const IconDragAndDrop = (props: IconByPathProps) => (
  <IconByPath
    {...props}
    clipRule="evenodd"
    fillRule="evenodd"
    path="M16.98 4.177a1.49 1.49 0 1 1-2.98 0 1.49 1.49 0 0 1 2.98 0Zm0 5.215a1.49 1.49 0 1 1-2.98 0 1.49 1.49 0 0 1 2.98 0Zm-1.49 6.706a1.49 1.49 0 1 0 0-2.98 1.49 1.49 0 0 0 0 2.98Zm1.49 3.725a1.49 1.49 0 1 1-2.98 0 1.49 1.49 0 0 1 2.98 0ZM10 4.177a1.49 1.49 0 1 1-2.98 0 1.49 1.49 0 0 1 2.98 0Zm0 5.215a1.49 1.49 0 1 1-2.98 0 1.49 1.49 0 0 1 2.98 0Zm-1.49 6.706a1.49 1.49 0 1 0 0-2.98 1.49 1.49 0 0 0 0 2.98ZM10 19.823a1.49 1.49 0 1 1-2.98 0 1.49 1.49 0 0 1 2.98 0Z"
    shapeRendering="geometricPrecision"
  />
);

export default IconDragAndDrop;
