import { layout, space, SpaceProps, LayoutProps } from 'styled-system';

import theme from 'modules/Theme';
import breakpoints, { min } from 'modules/Theme/breakpoints';
import styled, { keyframes, css } from 'modules/Theme/styled-components';
import { Box } from 'modules/Ui';
import { Circle as HTMLCircle } from 'modules/Ui/Html';
import HtmlSvg from 'modules/Ui/Html/Svg';
import { IconCheck } from 'modules/Ui/Icons';

interface Props extends LayoutProps, SpaceProps {
  animate?: boolean;
  duration: number;
  isTest?: boolean;
  progress?: number;
  size?: number;
}

const circleChartFill = keyframes`
  to { stroke-dasharray: 0 100}
`;

const circleChartBackground = keyframes`
  0% {
    opacity: 0;
    fill: none;
    z-index: 0;
  }
  50% {
    fill: ${theme.colors.brand400};
    z-index: 1;
  }
  100% {
    fill: ${theme.colors.brand400};
    opacity: 1;
  }
`;

const checkDisplay = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
    z-index: 2;
  }
`;

/* Step 01 - Graph color fill animation - Duration 80% of available time */
const circleAnimation = (props: { duration: number }) =>
  css`
  @media ${min(breakpoints.sm)} {
    --step01-time: ${props.duration * 0.01}ms;
  }
  animation:  ${circleChartFill} var(--step01-time, ${
    props.duration * 0.8
  }ms) reverse;
  `;

/* Step 02 - Graph background animation - Duration 10% */
const animationBackground = (props: { duration: number }) =>
  css`
  @media ${min(breakpoints.sm)} {
    --step02-time: ${props.duration * 0.49}ms;
  }
  animation:  ${circleChartBackground} var(--step02-time, ${
    props.duration * 0.1
  }ms) ease-in-out ${props.duration * 0.8}ms forwards;
  `;

/* Step 03 - Check of Graph paints - Duration 10% */
const animationCheck = (props: { duration: number }) =>
  css`
  @media ${min(breakpoints.sm)} {
    --step03-time: ${props.duration * 0.5}ms;
  }
  animation:  ${checkDisplay} var(--step03-time, ${
    props.duration * 0.1
  }ms) ease-in-out ${props.duration * 0.9}ms forwards;
  `;

const Wrapper = styled(Box)`
  ${space}
`;

const Graph = styled(HtmlSvg)<Props>`
  ${layout}
  ${space}
  ${({ progress }) =>
    progress === 100 ? 'z-index: 1; position: relative' : undefined};
  .circular__graph--background {
    ${(props) => (props.animate ? animationBackground : undefined)};
  }
  + .circular__graph--check {
    ${({ progress, animate }) =>
      progress === 100 && !animate ? 'opacity: 1; z-index: 2' : 'opacity: 0'};
    ${(props) => (props.animate ? animationCheck : undefined)};
  }
`;

const Circle = styled(HTMLCircle)<Props>`
  ${(props) => (props.animate ? circleAnimation : undefined)};
  transform: rotate(-90deg);
  @media ${min(breakpoints.sm)} {
    transform: rotate(-175deg);
  }
  transform-origin: center;
`;

const ProgressGraph = ({
  animate,
  duration,
  progress,
  size = 48,
  isTest,
  ...rest
}: Props) => {
  const checkPosition = (pos: number) => `${pos / 4}px`;
  return (
    <Wrapper
      className="circular__graph"
      height={size}
      position="relative"
      width={size}
      {...{ ...rest }}
    >
      <Graph
        {...{ animate, progress, duration }}
        width={size}
        height={size}
        viewBox="0 0 34 34"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          {...{ animate, progress }}
          cx="17"
          className="circular__graph--background"
          cy="17"
          fill={
            progress === 100 && !animate ? `${theme.colors.brand400}` : 'none'
          }
          r="15"
          stroke={`${theme.colors.primary200}`}
          strokeWidth="3"
          strokeDasharray={isTest ? '6 6' : undefined}
        />
        {progress !== 0 && (
          <Circle
            {...{ animate, progress, duration }}
            cx="17"
            cy="17"
            fill="none"
            r="15"
            stroke={`${theme.colors.brand400}`}
            strokeDasharray={`${progress},100`}
            strokeLinecap="round"
            strokeWidth="3"
          />
        )}
      </Graph>
      <IconCheck
        {...{ animate, progress }}
        className="circular__graph--check"
        color="gray0"
        left={{ _: '22px', sm: checkPosition(size) }}
        position="absolute"
        top={{ _: '22px', sm: checkPosition(size) }}
      />
    </Wrapper>
  );
};

export default ProgressGraph;
