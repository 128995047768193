import { createSelector } from 'reselect';

import { ApplicationRootState } from 'types';

import { State } from './types';

const selectDocumentsState = (rootState: ApplicationRootState) =>
  rootState.documents;

export const selectShowBannerBai = createSelector(
  selectDocumentsState,
  (state: State) => state.showBanner
);
