import { getConfig } from 'modules/App/config';
import { DEFAULT_VALUES_QUERY } from 'modules/App/queries/queries';
import { requestBusinessToken } from 'modules/Auth/services/authBusiness';
import request from 'utils/request';

import { DefaultValuesResource } from '../enums';
import { DefaultValuesOptions } from '../models/default-values-options';

const updateDefaultValues = async <T>(
  resource: DefaultValuesResource,
  payload: T,
  options?: DefaultValuesOptions
) => {
  const config = await getConfig();
  const endpoint = config.apiDefaultValuesService;
  const token = options?.businessId
    ? await requestBusinessToken(options.businessId)
    : undefined;
  const response = await request<any>(
    `${endpoint}/${resource}`,
    {
      method: 'PUT',
      data: payload,
    },
    {
      token,
      requireAuth: true,
      retries: 2,
    }
  );

  return response.data.data;
};

export const invalidateQueries = [DEFAULT_VALUES_QUERY];

export default updateDefaultValues;
