import { FC } from 'react';
import { Hidden } from 'react-grid-system';
import { useSelector } from 'react-redux';

import { selectCurrentBusiness } from 'modules/Business/Current/selectors';
import useTranslations from 'modules/I18n/hooks/useTranslations';
import { MODAL_WIDTH_MEDIUM, Dialog, useCloseModal } from 'modules/Modals';
import { Button } from 'modules/Ui';

import DataElectronicForm from './DataElectronicForm';
import messages from './messages';
import { DocumentsDataElectronicModalProps } from './types';

const DataElectronicModal: FC<DocumentsDataElectronicModalProps> = ({
  document,
  onSubmit,
}) => {
  const { t } = useTranslations();
  const handleClose = useCloseModal();
  const business = useSelector(selectCurrentBusiness);
  return (
    <Dialog
      id="dataElectronicModal"
      dialogWidth={{ sm: MODAL_WIDTH_MEDIUM }}
      onClose={handleClose}
      title={t(messages.title)}
      type="fullScreen"
      content={
        <DataElectronicForm
          {...{ document, business, onSubmit, handleClose }}
        />
      }
      buttonPrimary={
        <Button
          data-testid="dowload-electronic-method-modal"
          variant="secondary"
          form="download-electronic-method-modal-form"
          type="submit"
        >
          {t(messages.confirm)}
        </Button>
      }
      buttonSecondary={
        <Hidden xs>
          <Button onClick={handleClose} variant="stroke">
            {t(messages.cancel)}
          </Button>
        </Hidden>
      }
    />
  );
};

export default DataElectronicModal;
