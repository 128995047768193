import { useContext } from 'react';
import { MessageDescriptor } from 'react-intl';

import { HeightProps } from 'styled-system';

import { HelpContext } from 'modules/Help/HelpContext';
import useTranslations from 'modules/I18n/hooks/useTranslations';
import { Box, MarkDown, Text } from 'modules/Ui';
import FaqsExpandable from 'modules/Ui/CustomExpandable/components/FaqsExpandable';

interface Props {
  numberOfFaqs: number;
  messages: Record<string, MessageDescriptor>;
  minHeight?: HeightProps;
  plan?: string; // pro | unlimited | basic
}

function getIsIniallyExpanded(i: number, preOpenedId?: number) {
  if (preOpenedId) {
    return i === preOpenedId;
  }
  return i === 0;
}

const BaseFaqs: React.FC<Props> = ({
  minHeight,
  numberOfFaqs,
  messages,
  plan,
}) => {
  const { t } = useTranslations();
  const helpContext = useContext(HelpContext);

  return (
    <>
      <Box
        marginBottom={{ _: '48px', sm: 'initial' }}
        minHeight={minHeight}
        tag="ul"
      >
        {Array(numberOfFaqs)
          .fill(numberOfFaqs)
          .map((_, i) => {
            const title = t(messages[`faq${i + 1}`]);
            const translateOptions = { plan };
            return (
              <FaqsExpandable
                id={`faq-item-${i}`}
                key={title}
                tag="li"
                title={title}
                initiallyExpanded={getIsIniallyExpanded(
                  i,
                  helpContext?.preOpenedFaqId
                )}
                isLastItem={i + 1 === numberOfFaqs}
                content={
                  <Text textSize="s" marginBottom="8px" tag="div">
                    <MarkDown
                      text={t(messages[`faq${i + 1}Answer`], translateOptions)}
                      linkTarget="_blank"
                    />
                  </Text>
                }
              />
            );
          })}
      </Box>
    </>
  );
};
export default BaseFaqs;
