import useTheme from 'modules/Theme/hooks/useTheme';

import IconByString, { IconByStringProps } from './Icon/IconType/IconByString';

export interface IconAddProps extends IconByStringProps {
  iconCrossColor?: string;
}

const IconAddContact = (props: IconAddProps) => {
  const { iconCrossColor, innerPathColor, ...rest } = props;
  const theme = useTheme();
  return (
    <IconByString {...rest}>
      <>
        <path
          d="M19 19V1H3v22h16v-4ZM4 2v16l.172-.172a9.657 9.657 0 0 1 13.656 0L18 18V2H4Zm0 20v-2.586l.879-.879a8.657 8.657 0 0 1 12.242 0l.879.88V22H4Zm7-16c1.657 0 3 1.175 3 2.625v1.75C14 11.825 12.657 13 11 13s-3-1.175-3-2.625v-1.75C8 7.175 9.343 6 11 6Zm0 1c-1.105 0-2 .746-2 1.667v1.666c0 .92.895 1.667 2 1.667s2-.746 2-1.667V8.667C13 7.747 12.105 7 11 7Z"
          fill="currentColor"
          fillRule="evenodd"
        />
        <circle
          cx="18"
          cy="18"
          fill={innerPathColor ? `${innerPathColor}` : theme.colors.brand500}
          r="6"
          shapeRendering="geometricPrecision"
        />
        <path
          d="M18.514 14.513 17.5 14.5v3h-3v1h3v3h1v-3h3v-1h-3v-3l.014.013z"
          fill={iconCrossColor ? `${iconCrossColor}` : theme.colors.gray0}
          shapeRendering="crispEdges"
        />
      </>
    </IconByString>
  );
};

export default IconAddContact;
