import { AxiosRequestConfig, AxiosResponse } from 'axios';

import analyticsService from 'modules/Analytics/services/analyticsService';
import { getConfig } from 'modules/App/config';
import { store } from 'modules/App/store';
import {
  logout as logoutAction,
  setScopes as setScopesAction,
} from 'modules/Auth/actions';
import { clearCurrentUser, clearFeatureFlagIdentifiers } from 'utils/storage';

import { getCurrentBusinessId } from './currentBusinessUtil';
import { BillinRequestOptions } from './models/billinRequestOptions';
import { setAuthData } from './request-auth-data';
import { TokenResponse } from './token-response';

async function refreshToken(
  request: <T>(
    url: string,
    options: AxiosRequestConfig,
    billinRequestOptions: BillinRequestOptions
  ) => Promise<AxiosResponse<T>>
) {
  const endpoint = (await getConfig()).apiAuthService;

  const currentBusinessId = getCurrentBusinessId();

  const refreshResponse = await request<TokenResponse>(
    `${endpoint}/refresh`,
    { method: 'GET', withCredentials: true },
    { requireAuth: false }
  );

  const { auth: refreshAuth } = refreshResponse.data.data;
  if (!currentBusinessId) {
    setAuthData({
      currentToken: refreshAuth.accessToken,
      expireOn: refreshAuth.expireIn + Date.now(),
      businessId: currentBusinessId,
      userId: refreshAuth.userId,
      tenant: refreshAuth.tenant,
    });
  } else {
    const response = await request<TokenResponse>(
      `${endpoint}/${currentBusinessId}`,
      { method: 'GET' },
      {
        requireAuth: false,
        token: refreshAuth.accessToken,
      }
    );

    const { auth } = response.data.data;

    setAuthData({
      currentToken: auth.accessToken,
      expireOn: auth.expireIn + Date.now(),
      businessId: auth.businessId,
      userId: auth.userId,
      tenant: auth.tenant,
    });

    analyticsService.identify(auth.businessId, auth.userId);

    store.dispatch(setScopesAction(auth.scopes));
  }
}

const logout = () => {
  store.dispatch(logoutAction());
  clearCurrentUser();
  clearFeatureFlagIdentifiers();
  window.location.href = '/';
};

type PromiseCallback = (value?: void | undefined) => void;
function getPromise(): [Promise<void>, PromiseCallback, PromiseCallback] {
  let res: PromiseCallback = () => {};
  let rej: PromiseCallback = () => {};
  const promise = new Promise<void>((_res, _rej) => {
    res = _res;
    rej = _rej;
  });
  return [promise, res, rej];
}

// Closures currentRetryStrategy promise
export const retryStrategy = (() => {
  // currentRetryStrategy allows only one refresh token process at a time
  let currentRetryStrategy: Promise<void> | undefined;
  return async <T>(
    request: <R>(
      url: string,
      options: AxiosRequestConfig,
      billinRequestOptions: BillinRequestOptions
    ) => Promise<AxiosResponse<R>>,
    url: string,
    options: AxiosRequestConfig,
    billinRequestOptions: BillinRequestOptions
  ): Promise<AxiosResponse<T>> => {
    const retries = billinRequestOptions.retries ?? 0;
    if (!billinRequestOptions.requireAuth) {
      logout();
      throw new Error();
    }
    if (retries < 1) {
      throw new Error();
    }
    if (currentRetryStrategy) {
      await currentRetryStrategy;
    } else {
      const [newCurrentRetryStrategy, resolve, reject] = getPromise();
      currentRetryStrategy = newCurrentRetryStrategy;
      try {
        await refreshToken(request);
        currentRetryStrategy = undefined;
        resolve();
      } catch (e: any) {
        currentRetryStrategy = undefined;
        if (e?.response?.status === 401) {
          logout();
        }
        reject(e);
        throw e;
      }
    }
    return request(url, options, {
      ...billinRequestOptions,
      retries: retries - 1,
    });
  };
})();
