import { Link } from 'react-router-dom';

import useTranslations from 'modules/I18n/hooks/useTranslations';
import { Text, Box } from 'modules/Ui';
import IconCloseSession from 'modules/Ui/Icons/IconCloseSession';
import { menuSizes } from 'modules/Ui/Menu/MenuMobile/menuSizes';
import { ROUTES } from 'pages/auth/routesMap';

import { menu } from '../../messages';

const Logout = () => {
  const { t } = useTranslations();
  return (
    <Box
      alignItems="center"
      display="flex"
      height={`${menuSizes.HEADER_HEIGHT_MOBILE}px`}
      padding="0 24px 0 16px"
      tag="p"
    >
      <Link to={ROUTES.LOGOUT} color="primary300">
        <Text display="flex" tag="span" textSize="s">
          <IconCloseSession color="inherit" marginRight="8px" />
          {t(menu.logout)}
        </Text>
      </Link>
    </Box>
  );
};

export default Logout;
