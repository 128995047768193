import IconByString, { IconByStringProps } from './Icon/IconType/IconByString';

export interface IconAddProps extends IconByStringProps {
  iconCrossColor?: string;
}

const IconRemittances = (props: IconAddProps) => {
  const { iconCrossColor, innerPathColor, ...rest } = props;
  return (
    <IconByString {...rest}>
      <>
        <path
          clipRule="evenodd"
          d="M14 3v4h-1V3H9V2h5v1Z"
          fillRule="evenodd"
          fill="currentColor"
        />
        <path
          clipRule="evenodd"
          d="M12.645 2.648 9.064 6.263l.71.705 3.58-3.616-.709-.704Z"
          fillRule="evenodd"
          fill="currentColor"
        />
        <path
          clipRule="evenodd"
          d="m0 8 8-8h8v24H0V8Zm1.414 0L1 8.414V23h14V1H8.414L8 1.414V8H1.414ZM7 2.414 2.414 7H7V2.414ZM20 4h-1v20h1V4Zm3 4h1v16h-1V8Z"
          fillRule="evenodd"
          fill="currentColor"
        />
      </>
    </IconByString>
  );
};

export default IconRemittances;
