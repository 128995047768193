import { DefaultValuesResource } from 'modules/DefaultValues/enums';
import { getNaturalPerson } from 'modules/DefaultValues/helpers/naturalPerson';
import updateDefaultValues from 'modules/DefaultValues/services/updateDefaultValues';

import { DocumentSettings } from '../models';

const updateDocumentSettings = async (data: { values: DocumentSettings }) => {
  const { values } = data;
  const commonValues = {};

  const updatedInvoiceValues = await updateDefaultValues(
    DefaultValuesResource.Invoice,
    {
      ...commonValues,
      showReference: values.showReference,
      hasSalesEqualizationTax: values.hasSalesEqualizationTax || false,
      comments: values.invoiceComments,
      showComments: values.showInvoiceComments,
      retentionPercentage: values.hasRetentions
        ? values.retentionPercentage
        : null,
      showProductReference: values.showProductReference,
      epigraphIAE: values.epigraphIAE,
      taxRegime: values.taxRegime,
      operationDescription: values.operationDescription,
      naturalPerson: getNaturalPerson(values.naturalPersonType),
      hasCustomIRPF: values.hasCustomIRPF,
      chargingCriterionIRPF: values.chargingCriterionIRPF,
      showLinesWithoutTaxes: values.showLinesWithoutTaxesInInvoice || false,
    }
  );

  const updatedProformaValues = await updateDefaultValues(
    DefaultValuesResource.Proforma,
    {
      hasSalesEqualizationTax: values.hasSalesEqualizationTax || null,
      showReference: values.showProformaReference,
      comments: values.proformaComments,
      showComments: values.showProformaComments,
      retentionPercentage: values.hasRetentions
        ? values.retentionPercentage
        : null,
      showProductReference: values.showProductReference,
      taxRegime: values.taxRegime,
      showLinesWithoutTaxes: values.showLinesWithoutTaxesInProformas || false,
    }
  );

  const updatedQuoteValues = await updateDefaultValues(
    DefaultValuesResource.Quote,
    {
      ...commonValues,
      showReference: values.showQuoteReference,
      hasSalesEqualizationTax: values.hasSalesEqualizationTax || null,
      comments: values.quoteComments,
      showComments: values.showQuoteComments,
      retentionPercentage: values.hasRetentions
        ? values.retentionPercentage
        : null,
      showProductReference: values.showProductReference,
      taxRegime: values.taxRegime,
      showLinesWithoutTaxes: values.showLinesWithoutTaxesInQuotes || false,
    }
  );

  const updatedReceiptValues = await updateDefaultValues(
    DefaultValuesResource.Receipt,
    {
      ...commonValues,
      showReference: values.showQuoteReference,
      showComments: values.showInvoiceComments,
      epigraphIAE: values.epigraphIAE,
      taxRegime: values.taxRegime,
      operationDescription: values.operationDescription,
      naturalPerson: getNaturalPerson(values.naturalPersonType),
      hasCustomIRPF: values.hasCustomIRPF,
      chargingCriterionIRPF: values.chargingCriterionIRPF,
      showLinesWithoutTaxes: values.showLinesWithoutTaxesInInvoice || false,
    }
  );

  const updatedDeliveryNoteValues = await updateDefaultValues(
    DefaultValuesResource.DeliveryNote,
    {
      ...commonValues,
      showReference: values.showDeliveryNoteReference,
      hasSalesEqualizationTax: values.hasSalesEqualizationTax || null,
      comments: values.deliveryNoteComments,
      showComments: values.showDeliveryNoteComments,
      retentionPercentage: values.hasRetentions
        ? values.retentionPercentage
        : null,
      taxRegime: values.taxRegime,
      showProductReference: values.showProductReference,
      showLinesWithoutTaxes:
        values.showLinesWithoutTaxesInDeliveryNotes || false,
    }
  );

  const updatedExpensesValues = await updateDefaultValues(
    DefaultValuesResource.Expense,
    {
      ...commonValues,
    }
  );

  return {
    count: 6,
    items: [
      updatedInvoiceValues,
      updatedProformaValues,
      updatedQuoteValues,
      updatedReceiptValues,
      updatedDeliveryNoteValues,
      updatedExpensesValues,
      updatedDeliveryNoteValues,
    ],
  };
};

export default updateDocumentSettings;
