import { layout, LayoutProps, space, SpaceProps } from 'styled-system';

import useTheme from 'modules/Theme/hooks/useTheme';
import styled from 'modules/Theme/styled-components';
import Label from 'modules/Ui/Html/Label';

import Box from '../../Box';
import IconCheck from '../../Icons/IconCheck';
import Text from '../../Text';
import Input from './commons/Input';

export interface RadioButtonIconProps extends LayoutProps, SpaceProps {
  checked?: boolean;
  icon?: React.ReactNode;
  id: string;
  name: string;
  onChange?: (value: any) => void;
  onClick?: (value: any) => void;
  onFocus?: (event: any) => void;
  text: string;
  value: any;
}

const StyledLabel = styled(Label)<RadioButtonIconProps>`
  ${layout}
  ${space}

  @media (hover: hover) {
    &:hover {
      .radioButtonIcon {
        border: 1px solid ${(props) => props.theme.colors.brand500};
      }
    }
  }
`;

const RadioButtonIcon = (props: RadioButtonIconProps) => {
  const { checked, icon, id, name, onChange, onClick, text, value, ...rest } =
    props;
  const theme = useTheme();
  return (
    <StyledLabel htmlFor={id} onClick={onClick} {...rest}>
      <Input
        aria-labelledby={`${id}-label`}
        checked={checked}
        data-testid={`${id}-radio`}
        id={id}
        name={name}
        onChange={onChange}
        type="radio"
        value={value}
        {...rest}
      />
      <Box
        alignItems="center"
        backgroundColor="gray0"
        border={`1px solid ${theme.colors.gray300}`}
        borderRadius="8px"
        className="radioButtonIcon"
        display="flex"
        padding="0 16px 0 20px"
        height="100%"
      >
        {icon}
        <Text
          aria-hidden="true"
          className="radioButtonIcon--text"
          color="primary400"
          flex="1"
          fontSize={16}
          id={`${id}-label`}
          lineHeight={19}
          margin="0 10px 0 12px"
          tag="span"
        >
          {text}
        </Text>
        <IconCheck className="iconChecked" />
      </Box>
    </StyledLabel>
  );
};

StyledLabel.defaultProps = {
  height: '56px',
};

export default RadioButtonIcon;
