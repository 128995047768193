import styled from 'modules/Theme/styled-components';
import { Box } from 'modules/Ui';

// prettier-ignore
const Radio = styled(Box)`
  &:after {
    background-color: ${(props) => props.theme.colors.brand500};
    border-radius: 50%;
    content: '';
    display: block;
    height: 14px;
    opacity: ${props => props.checked ? '1' : '0'};
    transition: opacity 0.2s linear;
    width: 14px;
  }
`;

export default Radio;
