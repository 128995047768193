import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  account: { id: 'gofre.remittances.header.account' },
  amount: { id: 'gofre.remittances.header.amount' },
  expiration: { id: 'gofre.remittances.header.expiration' },
  concept: { id: 'gofre.remittances.header.concept' },
  id: { id: 'gofre.remittances.header.id' },
  type: { id: 'gofre.remittances.header.type' },
  edit: { id: 'gofre.remittances.header.edit' },
});
