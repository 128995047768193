import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: { id: 'gofre.Documents.Dialog.InvalidTaxRegime.title' },
  text01: { id: 'gofre.Documents.Dialog.InvalidTaxRegime.content.text01' },
  text02: { id: 'gofre.Documents.Dialog.InvalidTaxRegime.content.text02' },
  accept: { id: 'gofre.Documents.Dialog.InvalidTaxRegime.accept' },
});

export default messages;
