import { defineMessages } from 'react-intl';

export default defineMessages({
  actionDelete: {
    id: 'gofre.Contacts.delete',
  },
  actionEdit: {
    id: 'gofre.Contacts.edit',
  },
  actionDownloadExcel: {
    id: 'gofre.Contacts.downloadExcel',
  },
  add: {
    id: 'gofre.Contacts.add',
  },
  create: {
    id: 'gofre.Contacts.create',
  },
  import: {
    id: 'gofre.Contacts.import',
  },
  fieldNif: {
    id: 'gofre.Contacts.fieldNif',
  },
  fieldFiscalName: {
    id: 'gofre.Contacts.fieldFiscalName',
  },
  fieldFiscalContactType: {
    id: 'gofre.Contacts.fieldFiscalContactType',
  },
  fieldLastDocumentDate: {
    id: 'gofre.Contacts.fieldLastDocumentDate',
  },
  fieldOwedAmount: {
    id: 'gofre.Contacts.fieldOwedAmount',
  },
  newFieldOwedAmount: {
    id: 'gofre.Contacts.newFieldOwedAmount',
  },
  fieldBilledAmount: {
    id: 'gofre.Contacts.fieldBilledAmount',
  },
  newFieldBilledAmount: {
    id: 'gofre.Contacts.newFieldBilledAmount',
  },
  title: {
    id: 'gofre.Contacts.title',
  },
  typeCustomer: {
    id: 'gofre.Contacts.typeCustomer',
  },
  typeProvider: {
    id: 'gofre.Contacts.typeProvider',
  },
  filterPlaceholder: {
    id: 'gofre.Contacts.filter.placeholder',
  },
  lastUsedContacts: {
    id: 'gofre.Contacts.lastUsedContacts',
  },
  customers: {
    id: 'gofre.Contacts.customers',
  },
  providers: {
    id: 'gofre.Contacts.providers',
  },
  yearInCourse: {
    id: 'gofre.Contacts.yearInCourse',
  },
});

export const filter = defineMessages({
  legend: {
    id: 'gofre.Contacts.List.Filter.legend',
  },
  searchTermLabel: {
    id: 'gofre.Contacts.List.Filter.searchTermLabel',
  },
  searchTermPlaceholder: {
    id: 'gofre.Contacts.List.Filter.searchTermPlaceholder',
  },
  cancelDialog: {
    id: 'gofre.Contacts.List.Filter.cancelDialog',
  },
  button: {
    id: 'gofre.Contacts.List.Filter.button',
  },
  searchTermMinLength: {
    id: 'gofre.Contacts.List.Filter.searchTermMinLength',
  },
  clientTypeLabel: {
    id: 'gofre.Contacts.List.Filter.clientTypeLabel',
  },
  clientTypePlaceholder: {
    id: 'gofre.Contacts.List.Filter.clientTypePlaceholder',
  },
  clientTypeCustomer: {
    id: 'gofre.Contacts.List.Filter.clientTypeCustomer',
  },
  clientTypeProvider: {
    id: 'gofre.Contacts.List.Filter.clientTypeProvider',
  },
  clientTypeBoth: {
    id: 'gofre.Contacts.List.Filter.clientTypeBoth',
  },
  totalAmountFrom: {
    id: 'gofre.Contacts.List.Filter.totalAmountFrom',
  },
  totalAmountTo: {
    id: 'gofre.Contacts.List.Filter.totalAmountTo',
  },
  totalAmount: {
    id: 'gofre.Contacts.List.Filter.totalAmount',
  },
  totalAmountTablet: {
    id: 'gofre.Contacts.List.Filter.totalAmountTablet',
  },
  dateFrom: {
    id: 'gofre.Contacts.List.Filter.dateFrom',
  },
  dateTo: {
    id: 'gofre.Contacts.List.Filter.dateTo',
  },
  toBeforeFrom: {
    id: 'gofre.Contacts.List.Filter.toBeforeFrom',
  },
  minBiggerThanMax: {
    id: 'gofre.Contacts.List.Filter.minBiggerThanMax',
  },
});

export const filterCustomer = defineMessages({
  onlyDebt: {
    id: 'gofre.Contacts.List.Filter.Customer.onlyDebt',
  },
});

export const filterProvider = defineMessages({
  onlyDebt: {
    id: 'gofre.Contacts.List.Filter.Provider.onlyDebt',
  },
});
