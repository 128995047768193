import { getConfig } from 'modules/App/config';
import {
  CONTACTS_QUERY,
  DASHBOARD_QUERY,
  DOCUMENTS_QUERY,
  DOCUMENT_TRACKING_QUERY,
  PAYMENTS_QUERY,
} from 'modules/App/queries/queries';
import {
  CreateDocumentPaymentDto,
  CreatePaymentDto,
  Payment,
} from 'modules/Payments/models';
import request from 'utils/request';

export interface CreatePaymentResponse {
  data: Payment;
}

const createPayment = async (
  payment: CreateDocumentPaymentDto | CreatePaymentDto
): Promise<CreatePaymentResponse> => {
  const endpoint = (await getConfig()).apiPaymentsService;

  const response = await request<CreatePaymentResponse>(endpoint, {
    method: 'POST',
    data: payment,
  });
  return response.data;
};

export const invalidateQueries = [
  PAYMENTS_QUERY,
  DOCUMENTS_QUERY,
  DASHBOARD_QUERY,
  DOCUMENT_TRACKING_QUERY,
  CONTACTS_QUERY,
];

export default createPayment;
