import IconByPath, { IconByPathProps } from './Icon/IconType/IconByPath';

const IconWorld = (props: IconByPathProps) => (
  <IconByPath
    {...props}
    fillRule="evenodd"
    path="M12 22c1.12 0 2.353-.846 3.365-2.7.217-.399.417-.833.598-1.3H8.037c.18.467.381.901.598 1.3C9.647 21.153 10.881 22 12 22Zm-4.305-5c-.398-1.33-.646-2.857-.689-4.5h9.988c-.043 1.643-.29 3.17-.69 4.5H7.696Zm9.335 1c-.52 1.46-1.222 2.679-2.049 3.548A10.017 10.017 0 0 0 20.001 18H17.03Zm3.632-1h-3.316c.38-1.36.608-2.885.648-4.5h3.994a9.943 9.943 0 0 1-1.326 4.5ZM6.654 17H3.338a9.943 9.943 0 0 1-1.326-4.5h3.994c.04 1.615.269 3.14.648 4.5ZM4 18H6.97c.52 1.46 1.222 2.679 2.049 3.548A10.018 10.018 0 0 1 3.999 18Zm3.007-6.5c.043-1.642.29-3.17.69-4.5h8.609c.398 1.33.646 2.858.689 4.5H7.006Zm1.63-6.8c-.218.399-.418.833-.599 1.3h7.926c-.18-.467-.381-.901-.598-1.3C14.353 2.847 13.119 2 12 2c-1.12 0-2.353.846-3.365 2.7Zm9.358 6.8a18.53 18.53 0 0 0-.648-4.5h3.316a9.943 9.943 0 0 1 1.326 4.5h-3.994ZM17.03 6H20a10.018 10.018 0 0 0-5.019-3.548c.827.87 1.53 2.089 2.049 3.548ZM4 6h2.97c.52-1.46 1.222-2.679 2.049-3.548A10.018 10.018 0 0 0 3.999 6Zm2.654 1H3.338a9.943 9.943 0 0 0-1.326 4.5h3.994c.04-1.615.269-3.14.648-4.5Zm16.335 4.5C22.727 5.657 17.907 1 12 1 5.925 1 1 5.925 1 12s4.925 11 11 11c5.907 0 10.727-4.657 10.989-10.5H23v-1h-.011Z"
    shapeRendering="geometricPrecision"
  />
);
export default IconWorld;
