import { Subscription } from '../models';
import { ActionTypes } from './constants';
import { Actions, State } from './types';

const emptySubscription = {} as Subscription;

export const initialState: State = {
  loading: false,
  subscription: emptySubscription,
};

const reducer = (state: State = initialState, action: Actions): State => {
  switch (action.type) {
    case ActionTypes.FETCH_SUBSCRIPTIONS_REQUEST:
      return {
        loading: true,
        subscription: emptySubscription,
      };
    case ActionTypes.FETCH_SUBSCRIPTIONS_FAILURE:
      return {
        loading: false,
        subscription: emptySubscription,
      };
    case ActionTypes.FETCH_SUBSCRIPTIONS_SUCCESS:
      return {
        loading: false,
        subscription: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
