import { companyNifLetters, dniControlLetters, nieNumbers } from './constants';
import {
  civilSocietyRegex,
  companyRegex,
  dniRegex,
  goodsCommunityRegex,
  nieRegex,
  nifRegex,
  notPersonalCompanyRegex,
  publicEntityOrOtherRegex,
} from './regex';

export const isNif = (value: string): boolean => {
  return (
    nifRegex.test(value) ||
    (process.env.ENABLE_TEST_NIF === 'true' &&
      value?.[0]?.toUpperCase() === 'T')
  );
};

export const isNie = (value: string): boolean => nieRegex.test(value);
export const isDni = (value: string): boolean => dniRegex.test(value);
export const isCompany = (value: string): boolean => companyRegex.test(value);
export const isNotPersonalCompany = (value: string): boolean =>
  notPersonalCompanyRegex.test(value);

export const isCivilSociety = (value: string): boolean =>
  civilSocietyRegex.test(value);

export const isGoodsCommunity = (value: string): boolean =>
  goodsCommunityRegex.test(value);

export const isSelfEmployed = (vatNumber: string): boolean =>
  isDni(vatNumber) || isNie(vatNumber);

export const isPublicEntityOrOther = (value: string): boolean =>
  publicEntityOrOtherRegex.test(value);

export const isNaturalPerson = (value: string): boolean =>
  isNie(value) ||
  isDni(value) ||
  isCivilSociety(value) ||
  isGoodsCommunity(value);

export const getDniLetter = (value: string): string => {
  return dniControlLetters[parseInt(value.substring(0, 8), 10) % 23];
};

const validateDni = (value: string): boolean => {
  return getDniLetter(value) === value[8];
};

const validateNie = (value: string): boolean => {
  const modNie: any[] = value.split('');
  modNie[0] = nieNumbers[modNie[0] as 'X' | 'Y' | 'Z'];
  return validateDni(modNie.join(''));
};

const validateNieDni = (value: string): boolean => {
  if (!isDni(value) && !isNie(value)) {
    return false;
  }
  if (isNie(value)) {
    return validateNie(value);
  }
  return validateDni(value);
};

const getDigitsArray = (digits: number[]) => {
  const evenDigits: number[] = [];
  const oddDigits: number[] = [];
  for (let i = 0; i < digits.length; i++) {
    if (i % 2 !== 0) {
      evenDigits.push(digits[i]);
    } else {
      oddDigits.push(digits[i]);
    }
  }
  return { evenDigits, oddDigits };
};

const getControlDigit = (sumEvenDigits: number, sumOddDigits: number) =>
  (sumEvenDigits + sumOddDigits) % 10 !== 0
    ? 10 - ((sumEvenDigits + sumOddDigits) % 10)
    : 0;

// Docs:
// https://www.mapa.gob.es/app/materialvegetal/docs/CIF.pdf
// https://www.juntadeandalucia.es/servicios/madeja/contenido/libro-pautas/196#:~:text=Para%20validar%20un%20NIF%20distinto,del%20resultado%20almacen%C3%A1ndolo%20en%20B
const validateCompanyNif = (value: string): boolean => {
  if (!isCompany(value?.toUpperCase())) {
    return false;
  }
  const digits = value.slice(1, -1);
  const lastDigit = isNaN(Number(value.slice(-1)))
    ? value.slice(-1)
    : Number(value.slice(-1));
  const modNif: number[] = digits.split('').map(Number);
  const { evenDigits, oddDigits } = getDigitsArray(modNif);
  const sumEvenDigits = evenDigits.reduce((acc, val) => acc + val, 0);
  const sumOddDigits = oddDigits.reduce((acc, val) => {
    const oddDigit = (val * 2).toString().split('').map(Number);
    return acc + oddDigit.reduce((a, b) => a + b, 0);
  }, 0);

  const controlDigit = getControlDigit(sumEvenDigits, sumOddDigits);

  return typeof lastDigit === 'string'
    ? lastDigit === companyNifLetters[controlDigit]
    : lastDigit === controlDigit;
};

export const validateNif = (value: string): boolean => {
  return (
    validateCompanyNif(value?.toUpperCase()) ||
    validateNieDni(value?.toUpperCase()) ||
    (process.env.ENABLE_TEST_NIF === 'true' &&
      value?.[0]?.toUpperCase() === 'T')
  );
};
