import { FC, ReactNode } from 'react';

import useTranslations from 'modules/I18n/hooks/useTranslations';

import Box from '../../Box';
import Img from '../../Img';
import Text from '../../Text';
import messages from './messages';

interface BannerDiscountProps {
  image: ReactNode;
  imageHeight?: number | string;
  imageWidth?: number;
}

export const DiscountContentBanner: FC<BannerDiscountProps> = ({
  image,
  imageWidth,
  imageHeight,
}) => {
  const { t } = useTranslations();
  return (
    <Box
      display="grid"
      gridTemplateColumns={`${imageWidth}px 1fr`}
      columnGap={{ _: '8px', sm: '10px' }}
    >
      <Img
        ariaHidden={true}
        height={imageHeight}
        src={`/assets/myBookkeeper/Home/${image}.svg`}
        width={imageWidth}
      />
      <Box>
        <Text
          color="primary400"
          fontSize={14}
          fontWeight="600"
          lineHeight={16}
          marginBottom="4px"
        >
          {t(messages.title)}
        </Text>
        <Text
          color="primary400"
          fontSize={11}
          lineHeight={14}
          marginBottom={{ _: '8px', md: '12px' }}
        >
          {t(messages.subtitle)}
        </Text>
        <Text
          alignItems="center"
          backgroundColor="gray0"
          boxSizing="border-box"
          color="brand500"
          display="flex"
          fontSize={12}
          fontWeight={600}
          height="22px"
          lineHeight={14}
          padding="1px 8px"
          tag="span"
          width="100%"
        >
          {t(messages.pill)}
        </Text>
      </Box>
    </Box>
  );
};
