import { FC } from 'react';

import useTranslations from 'modules/I18n/hooks/useTranslations';
import { Dialog, useCloseModal } from 'modules/Modals';
import { Link } from 'modules/Ui';

import messages from './messages';

const OldAppModal: FC = () => {
  const { t } = useTranslations();
  const closeModal = useCloseModal();

  return (
    <Dialog
      title={t(messages.title)}
      onClose={closeModal}
      id="old-app-modal"
      buttonPrimary={
        <Link
          data-testid="old-app-modal-submit"
          variant="stroke"
          to="https://play.google.com/store/apps/details?id=com.myexpenses.billin"
          isExternal
          target="blank"
        >
          {t(messages.androidButton)}
        </Link>
      }
      buttonSecondary={
        <Link
          data-testid="old-app-modal-submit"
          variant="stroke"
          to="https://apps.apple.com/es/app/billin-crea-tus-facturas/id1287363144?l=en"
          isExternal
          target="blank"
        >
          {t(messages.iosButton)}
        </Link>
      }
    >
      {t(messages.text)}
    </Dialog>
  );
};

export default OldAppModal;
