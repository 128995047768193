import { defineMessages } from 'react-intl';

export default defineMessages({
  close: { id: 'gofre.banners.endTrial.close' },
  button: { id: 'gofre.banners.endTrial.button' },
  trialNearToExpireMsg: {
    id: 'gofre.banners.endTrial.trial-near-to-expire.message',
  },
  trialNearToExpireCta: {
    id: 'gofre.banners.endTrial.trial-near-to-expire.cta',
  },
  trialNearToBeCancelledMsg: {
    id: 'gofre.banners.endTrial.trial-near-to-be-cancelled.message',
  },
  trialNearToBeCancelledCta: {
    id: 'gofre.banners.endTrial.trial-near-to-be-cancelled.cta',
  },
});
