import { useCallback } from 'react';

import { useField } from 'formik';

import { FormikFieldProps } from 'modules/Ui/Formik/FormikFieldProps';
import useFocusHandler from 'modules/Ui/Formik/useFocusHandler';

import TagsField from '../TagsField';

export interface FieldProps extends FormikFieldProps {}

const TagsFormikField: React.FC<FieldProps> = ({ onChange, id, ...rest }) => {
  const [field, meta, helpers] = useField(id);
  const [inputField, , inputHelpers] = useField(`${id}Input`);
  const onChangeHandler = useCallback(
    (tags: string[]) => {
      onChange && onChange(tags);
      helpers.setTouched(true);
      return helpers.setValue(tags);
    },
    [helpers, onChange]
  );

  const onChangeInput = (value: string) => {
    // Sets formik value for the internal text input
    inputHelpers.setTouched(true);
    inputHelpers.setValue(value);
  };

  const error = meta.touched && meta.error ? meta.error : undefined;

  const onFocusHandler = useFocusHandler(id);

  return (
    <TagsField
      {...{
        id,
        value: field.value,
        inputValue: inputField.value,
        inputField,
        onChangeHandler,
        onChangeInput,
        error,
        onFocusHandler,
        ...rest,
      }}
    />
  );
};

export default TagsFormikField;
