import React, { FC, useState } from 'react';

import useTranslations from 'modules/I18n/hooks/useTranslations';
import { PAYMENT_PERIODS, Plan } from 'modules/Subscriptions/models';
import accesibleHide from 'modules/Theme/mixins/accessibleHidden';
import styled from 'modules/Theme/styled-components';
import { Box, Text } from 'modules/Ui';
import Label from 'modules/Ui/Form/Commons/Label';
import Input from 'modules/Ui/Html/Input';
import ToggleInput from 'modules/Ui/Toggle/Components/ToggleInput';

import { usePlansHaveDiscount } from '../../../hooks';
import messages from '../../../messages';

interface Props {
  periodicity: string;
  onChange?: (event: React.FormEvent<HTMLDivElement>, value: any) => void;
  value?: string;
  isChecked?: boolean;
  centered?: boolean;
  plans: Plan[];
}

const PlanPeriodicity: FC<Props> = (props) => {
  const { periodicity, onChange, value, isChecked, centered } = props;
  const [checked, setChecked] = useState(isChecked);
  const { t } = useTranslations();
  const shouldShowDiscountText = usePlansHaveDiscount(periodicity);

  const StyledInput = styled(Input)`
    ${accesibleHide}
    &:checked + ${Box} .toogle__switcher {
      justify-content: flex-end;
    }
`;

  return (
    <Label
      alignItems="center"
      display="flex"
      htmlFor="choose-plan"
      justifyContent={centered ? 'center' : 'flex-end'}
      marginBottom={{ _: '16px', sm: '30px', lg: '16px' }}
    >
      <StyledInput
        id="choose-plan"
        type="checkbox"
        {...{ onChange, value, checked }}
      />
      <Box alignItems="center" display="flex" tag="span">
        <Box
          alignItems={{ _: 'center' }}
          display={{ _: 'flex' }}
          marginRight={{ _: '8px', sm: '12px' }}
          tag="span"
        >
          {shouldShowDiscountText && (
            <Text
              color="accent500"
              fontSize={14}
              fontWeight="600"
              lineHeight="normal"
              marginRight={{ _: '8px', sm: '12px' }}
              tag="span"
              textAlign={{ _: 'center', sm: 'right' }}
              textTransform="uppercase"
            >
              {t(messages.annual)}
            </Text>
          )}
          <Text
            color="gray800"
            fontSize={18}
            fontWeight={
              periodicity === PAYMENT_PERIODS.YEARLY ? '600' : 'normal'
            }
            lineHeight="normal"
            tag="span"
          >
            {t(messages.radioAnnual)}
          </Text>
        </Box>
        <ToggleInput
          onClick={() => {
            setChecked(!checked);
          }}
          {...{ onChange, checked }}
          checkedColor="brand500"
          uncheckedColor="brand500"
        />
        <Text
          color="gray800"
          fontSize={18}
          fontWeight={
            periodicity === PAYMENT_PERIODS.MONTHLY ? '600' : 'normal'
          }
          lineHeight="normal"
          marginLeft={{ _: '8px', sm: '12px' }}
          tag="span"
        >
          {t(messages.radioMonthly)}
        </Text>
      </Box>
    </Label>
  );
};

export default PlanPeriodicity;
