import { AccountingAccount } from 'modules/AccountingAccounts/models';
import { Entity } from 'modules/App/models';
import { Contact } from 'modules/Contacts/models';
import {
  Document,
  DocumentStatus,
  DocumentType,
} from 'modules/Documents/models/document';
import { StatusInfoUI } from 'modules/Documents/models/statusInfo';
import { DataTableSort } from 'modules/Ui/DataTable/DataTable.models';

export enum PaymentMethodEnum {
  BIZUM = 'BIZUM',
  CASH = 'CASH',
  CREDIT_CARD = 'CREDIT_CARD',
  DIRECT_DEBIT = 'DIRECT_DEBIT',
  PROMISSORY_NOTE = 'PROMISSORY_NOTE',
  TRANSFER = 'TRANSFER',
  OTHER = 'OTHER',
  CONFIRMING = 'CONFIRMING',
  NOT_CONFIRMED = 'NOT_CONFIRMED',
}

export enum PaymentTypeEnum {
  INCOME = 'INCOME',
  EXPENSE = 'EXPENSE',
}

export enum PaymentStatusEnum {
  LINKED = 'LINKED',
  NOT_LINKED = 'NOT_LINKED',
  PARTIALLY_LINKED = 'PARTIALLY_LINKED',
}

export interface Payment extends Partial<Entity> {
  readonly type: PaymentTypeEnum;
  readonly operationDate: Date;
  readonly amount: number;
  readonly method: PaymentMethodEnum;
  readonly documents: Pick<
    Document,
    'contact' | 'identifier' | 'id' | 'receiptStatus'
  >[];
  readonly accountingAccount: Pick<AccountingAccount, 'alias' | 'id'> | null;
  readonly contact: Pick<Contact, 'id' | 'fiscalName'> | null;
  readonly status: PaymentStatusEnum;
  readonly createdAt: Date;
  readonly remittance?: Remittance;
}

export interface CreateDocumentPaymentDto {
  readonly operationDate: Date;
  readonly amount: number;
  readonly method?: PaymentMethodEnum;
  readonly documentsIds: string[];
  readonly contactId?: string;
  readonly type: PaymentTypeEnum;
  readonly accountingAccountId?: string;
}

export interface CreatePaymentDto {
  readonly operationDate: Date;
  readonly amount: number;
  readonly contactId?: string;
  readonly type: PaymentTypeEnum;
  readonly accountingAccountId?: string;
  readonly description?: string;
  readonly method?: PaymentMethodEnum;
}

export interface CreateMultiplePaymentDto {
  readonly operationDate: Date;
  readonly method?: PaymentMethodEnum;
  readonly accountingAccountId?: string;
  multipleDeleteParams?: {
    documentTypes?: DocumentType[];
    ids: string[];
    status?: DocumentStatus;
  };
}

export type EditPaymentDto = Pick<
  CreateDocumentPaymentDto,
  'amount' | 'method' | 'operationDate' | 'accountingAccountId'
> & { id: string };

export interface PaymentTable extends Payment {
  accountingAccount: Pick<AccountingAccount, 'alias' | 'id'> | null;
  contact: Pick<Contact, 'id' | 'fiscalName'> | null;
  paymentMethod: string;
  type: PaymentTypeEnum;
  description: string;
}

export enum RemittanceStatus {
  PENDING = 'PENDING',
  COMPLETED = 'COMPLETED',
}

export interface Remittance extends Entity {
  readonly name: string;
  readonly status: RemittanceStatus;
  readonly createdAt: Date;
  readonly dueDate: string;
  readonly amount: number;
  readonly type: RemittanceType;
  readonly remittanceDocumentsCount: number;
  readonly businessAccountingAccountAlias: string;
}

export enum RemittanceType {
  CREDIT_TRANSFER = 'CREDIT_TRANSFER',
  DIRECT_DEBIT = 'DIRECT_DEBIT',
}

export interface RemittanceTable extends Remittance {}

export type RemittanceSort = DataTableSort<
  RemittanceTable,
  keyof RemittanceTable
>;

export enum SortableFields {
  OPERATION_DATE = 'operationDate',
}

export type PaymentSort = DataTableSort<PaymentTable, keyof PaymentTable>;

export interface PaymentFilters {
  id?: string;
  ids?: string[];
  documentId?: string;
  documentIds?: string[];
  searchTerm?: string;
  dateFrom?: string;
  dateTo?: string;
  minAmount?: number | string;
  maxAmount?: number | string;
  linked?: PaymentStatusEnum;
  paidStatus?: StatusInfoUI;
}

export enum PaymentTypes {
  LIST = 'LIST',
  BANKS = 'BANKS',
  REMITTANCES = 'REMITTANCES',
}
