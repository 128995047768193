import styled from 'modules/Theme/styled-components';

import Box from '../../Box';

interface Props {
  checked?: boolean;
  checkedColor?: string;
  uncheckedColor?: string;
  isChecked?: boolean;
  isDisabled?: boolean;
  onClick?: () => void;
  inputHeight?: string;
  inputWidth?: string;
  sliderWidth?: string;
}

const StyledToggle = styled(Box)`
  transition: background-color 200ms linear;

  @media (hover: hover) {
    &:hover {
      cursor: ${({ isDisabled }) => (isDisabled ? 'hand' : 'pointer')};
    }
  }

  &:before {
    background-color: ${(props) => props.theme.colors.gray0};
    border-radius: 50%;
    content: '';
    height: ${({ sliderWidth }) => sliderWidth || '24px'};
    width: ${({ sliderWidth }) => sliderWidth || '24px'};
  }
`;

const ToggleInput: React.FC<Props> = (props) => {
  const {
    checked,
    checkedColor,
    isChecked = false,
    isDisabled,
    onClick,
    uncheckedColor,
    inputWidth = '42px', // 48px in total: inputWidth + left and right padding
    inputHeight = '24px',
    sliderWidth = '18px',
    ...rest
  } = props;

  return (
    <StyledToggle
      alignItems="center"
      aria-hidden="true"
      backgroundColor={checked ? checkedColor : uncheckedColor}
      borderRadius="16px"
      className="toogle__switcher"
      display="flex"
      justifyContent="flex-start"
      padding="0 0.2rem"
      tag="span"
      height={inputHeight}
      width={inputWidth}
      minWidth={inputWidth}
      sliderWidth={sliderWidth}
      {...{ checked, onClick, isDisabled, isChecked, ...rest }}
    />
  );
};

export default ToggleInput;
