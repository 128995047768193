export enum RouteNames {
  BOOKKEEPER = 'BOOKKEEPER',
  ADD_CLIENT_INDIVIDUALLY = 'ADD_CLIENT_INDIVIDUALLY',
  ADD_CLIENT = 'ADD_CLIENT',
  BOOKKEEPER_CLIENTS = 'BOOKKEEPER_CLIENTS',
  CLIENT_DETAIL = 'CLIENT_DETAIL',
  UPDATE_CLIENT = 'UPDATE_CLIENT',
  UPDATE_CLIENT_INVITATION = 'UPDATE_CLIENT_INVITATION',
  BOOKKEEPER_PROFILE = 'BOOKKEEPER_PROFILE',
  BOOKKEEPER_PROFILE_EDIT = 'BOOKKEEPER_PROFILE_EDIT',
  BOOKKEEPER_MYACCOUNT = 'BOOKKEEPER_MYACCOUNT',
  EXPORTER = 'EXPORTER',
  HELP = 'HELP',
  MY_BOOKKEEPER = 'MY_BOOKKEEPER',
  HOME = 'HOME',
  INTEGRATIONS = 'INTEGRATIONS',
}

export const ROUTES: Record<RouteNames, string> = {
  BOOKKEEPER: '/bookkeeper',
  ADD_CLIENT_INDIVIDUALLY: '/bookkeeper/clients/add?type=INDIVIDUALLY',
  ADD_CLIENT: '/bookkeeper/clients/add',
  BOOKKEEPER_CLIENTS: '/bookkeeper/clients',
  CLIENT_DETAIL: '/bookkeeper/clients/:id',
  UPDATE_CLIENT: '/bookkeeper/clients/:id/edit',
  UPDATE_CLIENT_INVITATION: '/bookkeeper/client-invitations/:id/edit',
  BOOKKEEPER_PROFILE: '/bookkeeper/profile',
  BOOKKEEPER_PROFILE_EDIT: '/bookkeeper/profile/edit',
  BOOKKEEPER_MYACCOUNT: '/bookkeeper/my-account',
  EXPORTER: '/bookkeeper/exporter',
  HELP: '/bookkeeper/help',
  MY_BOOKKEEPER: '/bookkeeper/my-bookkeeper',
  HOME: '/bookkeeper',
  INTEGRATIONS: '/integrations',
};

export function buildUri(
  key: keyof typeof ROUTES
): ((id: string) => string) | (() => string) {
  switch (key) {
    case RouteNames.UPDATE_CLIENT:
      return (id: string): string => ROUTES.UPDATE_CLIENT.replace(':id', id);
    case RouteNames.CLIENT_DETAIL:
      return (id: string): string => ROUTES.CLIENT_DETAIL.replace(':id', id);
    default:
      return () => ROUTES[key];
  }
}
