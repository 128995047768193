import IconByPath, { IconByPathProps } from './Icon/IconType/IconByPath';

const IconAttachment = (props: IconByPathProps) => (
  <IconByPath
    {...props}
    path="M16 10v3h-1v-3a1 1 0 1 0-2 0v4.5a2.5 2.5 0 0 0 5 0V6H2v16h20V6h-3v8.5a3.5 3.5 0 1 1-7 0V10a2 2 0 1 1 4 0Zm3-5h4v18H1V5h11v-.5a3.5 3.5 0 1 1 7 0V5Zm-1 0v-.5a2.5 2.5 0 0 0-5 0V5h5Z"
  />
);

export default IconAttachment;
