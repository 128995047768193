import { useMemo } from 'react';

import useTranslations from 'modules/I18n/hooks/useTranslations';
import SelectFormikField from 'modules/Ui/Formik/SelectFormikField';

import {
  INTER,
  SOURCE_SERIF_PRO,
  WORK_SANS,
  IBM_PLEX_SANS,
  BARLOW,
  MONTSERRAT,
  ROBOTO_SLAB,
} from '../../constants';
import { TemplateType } from '../../types';
import messages from './messages';

interface Props {
  templateType?: TemplateType;
}

const TemplateFonts: React.FC<Props> = ({ templateType }) => {
  const { t } = useTranslations();
  const label = t(messages.label);

  const options = useMemo(() => {
    return [
      {
        value: INTER,
        label: `${INTER} ${
          templateType !== TemplateType.CLASSIC
            ? `(${t(messages.default)})`
            : ''
        }`,
      },
      {
        value: SOURCE_SERIF_PRO,
        label: `${SOURCE_SERIF_PRO}  ${
          templateType === TemplateType.CLASSIC
            ? `(${t(messages.default)})`
            : ''
        }`,
      },
      { value: WORK_SANS, label: WORK_SANS },
      { value: IBM_PLEX_SANS, label: IBM_PLEX_SANS },
      { value: BARLOW, label: BARLOW },
      { value: MONTSERRAT, label: MONTSERRAT },
      { value: ROBOTO_SLAB, label: ROBOTO_SLAB },
    ];
  }, [t, templateType]);
  return (
    <SelectFormikField
      {...{
        id: 'typography',
        options,
        label,
      }}
    />
  );
};

export default TemplateFonts;
