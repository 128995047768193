import { ReactNode } from 'react';
import { IntlProvider } from 'react-intl';
import { connect } from 'react-redux';

import { createStructuredSelector } from 'reselect';

import translations from 'translations/messages.json';
import { ApplicationRootState } from 'types';

// TODO: code splitting?
import { SPANISH, BASQUE, COLOMBIAN } from './constants';
import { selectLocale } from './selectors';

interface ConnectedStateProps {
  locale: string;
}
interface IntlProviderProps {
  children: ReactNode;
  locale: string;
}
export interface Translation {
  [key: string]: string;
}
export interface Messages {
  [key: string]: Translation;
}
const messages: Messages = {
  [SPANISH]: translations.es,
  [COLOMBIAN]: translations['es-co'],
  [BASQUE]: translations.eu,
};

export const IntlProviderWrapper = ({
  locale,
  children,
}: IntlProviderProps) => {
  return (
    <IntlProvider
      {...{
        locale,
        messages: messages[locale],
      }}
    >
      {children}
    </IntlProvider>
  );
};

const I18nProvider = connect(
  createStructuredSelector<ApplicationRootState, ConnectedStateProps>({
    locale: selectLocale,
  })
)(IntlProviderWrapper);

export default I18nProvider;
