import {
  isNifIvaCountryValid,
  validateNifIva as sharedValidateNifIva,
} from '../shared/nifiva/validations';

export const getSanitizedCountryCode = (code?: string) => {
  if (!code) {
    return code;
  }
  if (code.toLowerCase() === 'el') {
    return 'GR';
  }

  return code.toUpperCase();
};

export const validateNifIva = (value: string | null | undefined) => {
  if (!value) {
    return false;
  }
  const countryCode = value.substring(0, 2);
  const country = getSanitizedCountryCode(countryCode);
  if (!isNifIvaCountryValid(country)) {
    return false;
  }

  return sharedValidateNifIva(value, country as string);
};
