import { taxOptions } from 'modules/Taxes';

import { Concept } from '../../models/document';
import { TortillaConcept } from './types';

export const translateConceptToTortilla = (
  concept: Concept,
  hasSalesEqualizationTax: boolean,
  forceV1Calcs?: boolean
): TortillaConcept => {
  const subtotal = (concept.unitPrice ?? 0) * (concept.quantity ?? 0);
  const discountPercentage = concept.discountPercentage ?? 0;
  const discount = subtotal * (discountPercentage / 100);
  const taxBase = subtotal - discount;
  const conceptTax = concept.tax ?? taxOptions[concept.taxKey];

  const salesEqTaxPercentage = conceptTax?.salesEqTaxPercentage ?? 0;
  const totalAmount = concept.totalAmount ?? concept.total;
  const defaultTotalAmount = forceV1Calcs ? null : 0;
  return {
    quantity: concept.quantity ?? 0,
    unitPrice: concept.unitPrice ?? 0,
    discountPercentage,
    subtotal,
    discount,
    taxKey: concept.taxKey,
    taxBase,
    taxPercentage: conceptTax?.amount,
    salesEqTaxPercentage: hasSalesEqualizationTax ? salesEqTaxPercentage : 0,
    fixedTaxAmountByClient: concept.fixedTaxAmountByClient ?? undefined,
    totalAmount: totalAmount ?? defaultTotalAmount,
  };
};
