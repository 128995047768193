import { createElement } from 'react';
import { useSelector } from 'react-redux';
import { matchPath, useLocation } from 'react-router-dom';

import { selectIsMobile } from 'modules/App/store/selectors';
import useElectronicDocumentProvider from 'modules/Business/hooks/useElectronicDocumentProvider';
import { selectShowBannerBai } from 'modules/Documents/selectors';
import useTranslations from 'modules/I18n/hooks/useTranslations';
import { ThemeInterface } from 'modules/Theme';
import useTheme from 'modules/Theme/hooks/useTheme';
import { Text } from 'modules/Ui';
import { ROUTES } from 'pages/documents/routesMap';
import { ROUTES as QUOTES } from 'pages/quotes/routesMap';

import messages from './messages';

const useBannerBai = () => {
  const location = useLocation();
  const {
    hasElectronicProvider,
    canSendElectronicDocument,
    isGipuzkoa,
    isBizkaia,
  } = useElectronicDocumentProvider();
  const shouldShowBanner = useSelector(selectShowBannerBai);
  const { t } = useTranslations();
  const isMobile = useSelector(selectIsMobile);
  const theme = useTheme();

  const isPathToShowBaiBanner = !!matchPath(location.pathname, [
    ROUTES.DOCUMENTS_CREATE,
    ROUTES.DOCUMENTS_EDIT,
    ROUTES.DOCUMENTS_CREATE_RECURRENT,
    ROUTES.DOCUMENTS_RECURRENT_EDIT,
    ROUTES.DOCUMENTS_CREATE_TEST,
    ROUTES.RECEIPTS_CREATE,
    ROUTES.DOCUMENTS_COPY,
    QUOTES.QUOTES_ISSUE,
  ]);

  const { message, color } = getBannerInfo({
    canSendElectronicDocument,
    isMobile,
    isGipuzkoa,
    isBizkaia,
    theme,
  });

  return {
    showBai: hasElectronicProvider && shouldShowBanner && isPathToShowBaiBanner,
    message: t(message, {
      b: (chunks) =>
        createElement(Text, {
          children: chunks,
          color: 'gray800',
          fontSize: 'inherit',
          fontWeight: '600',
          lineHeight: 'inherit',
          tag: 'strong',
        }),
    }),
    color,
  };
};

const getBannerInfo = ({
  canSendElectronicDocument,
  isMobile,
  isBizkaia,
  theme,
}: {
  canSendElectronicDocument: boolean;
  isMobile: boolean;
  isGipuzkoa: boolean;
  isBizkaia: boolean;
  theme: ThemeInterface;
}) => {
  return canSendElectronicDocument
    ? {
        message: isMobile ? messages.connectedMobile : messages.connected,
        color: 'brand200',
      }
    : {
        message: isMobile ? messages.notConnectedMobile : messages.notConnected,
        color: isBizkaia ? 'primary100' : theme.colors.error100A20,
      };
};

export default useBannerBai;
