import { FC } from 'react';

import useScrollToTop from 'modules/Ui/hooks/useScrollToTop';

function withScrollTop(Component: FC<any>) {
  return (props: any) => {
    // Scroll to top (needed for components that we can navigate to but that do not have a route)
    useScrollToTop();
    return <Component {...props} />;
  };
}

export default withScrollTop;
