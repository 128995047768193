import { FC } from 'react';
import { useLocation } from 'react-router';

import useIsBookkeeperUser from 'modules/Bookkeeper/hooks/useIsBookkeeperUser';
import useTranslations from 'modules/I18n/hooks/useTranslations';
import { ROUTES as INTEGRATIONS } from 'modules/Integrations/routesMap';
import { SETTINGS_PATH } from 'modules/Settings/paths';
import { ROUTES as SUBSCRIPTIONS_ROUTES } from 'modules/Subscriptions/routesMap';
import { NotificationBadge } from 'modules/Ui/Menu/Components/NotificationBadge';
import { useShouldRenderSection } from 'modules/Ui/Menu/hooks/useShouldRenderSection';
import { menu, menuNotifications } from 'modules/Ui/Menu/messages';
import { businessSectionRegexMap, regexMap } from 'modules/Ui/Menu/regex';
import { useUserNotifications } from 'modules/UserNotifications/hooks/useUserNotifications';
import { ROUTES as ROUTES_NOTIFICATIONS } from 'modules/UserNotifications/routesMap';
import { ROUTES as USERS_PATH } from 'modules/Users/routesMap';
import { ROUTES } from 'pages/bookkeepers/routesMap';
import { ROUTES as BUSINESS_PATH } from 'pages/business/routesMap';

import StyledSubmenuLink from '../SubmenuLink';
import Section from './Section';

export interface BusinessSectionProps {
  closeMenu: () => void;
  open: boolean;
}

const BusinessSection: FC<BusinessSectionProps> = (props) => {
  const { open, closeMenu } = props;
  const location = useLocation();
  const { t } = useTranslations();
  const { pathname } = location;
  const {
    shouldRenderMyBookkeeperSection,
    shouldRenderPlansSection,
    shouldRenderSettingsSection,
    shouldRenderBusinessSection,
    shouldRenderSubscriptionsSection,
  } = useShouldRenderSection();
  const { data: notificationResponse } = useUserNotifications();
  const isBookkeeper = useIsBookkeeperUser();
  return (
    <Section {...{ open }}>
      <>
        {isBookkeeper && (
          <StyledSubmenuLink
            active={regexMap.myAccount.test(pathname)}
            onClick={closeMenu}
            text={t(menu.myAccount)}
            to={ROUTES.BOOKKEEPER_MYACCOUNT}
          />
        )}
        <StyledSubmenuLink
          onClick={closeMenu}
          active={regexMap.notifications.test(pathname)}
          text={t(menu.notifications)}
          to={ROUTES_NOTIFICATIONS.NOTIFICATIONS}
          notification={
            <NotificationBadge
              number={notificationResponse?.unread}
              label={t(menuNotifications.notificationsNumber, {
                count: notificationResponse?.unread || 0,
              })}
              padding="0 4px"
            />
          }
        />
        {shouldRenderBusinessSection && (
          <>
            <StyledSubmenuLink
              onClick={closeMenu}
              active={businessSectionRegexMap.business.test(pathname)}
              text={t(menu.business)}
              to={BUSINESS_PATH.BUSINESS}
            />
            <StyledSubmenuLink
              onClick={closeMenu}
              active={businessSectionRegexMap.users.test(pathname)}
              text={t(menu.users)}
              to={USERS_PATH.USERS}
            />
          </>
        )}

        {shouldRenderSettingsSection && (
          <StyledSubmenuLink
            onClick={closeMenu}
            active={businessSectionRegexMap.settings.test(pathname)}
            text={t(menu.settings)}
            to={SETTINGS_PATH}
          />
        )}

        {shouldRenderSubscriptionsSection && (
          <StyledSubmenuLink
            onClick={closeMenu}
            active={businessSectionRegexMap.subscriptions.test(pathname)}
            text={t(menu.subscriptions)}
            to={SUBSCRIPTIONS_ROUTES.SUBSCRIPTIONS}
          />
        )}
        {shouldRenderPlansSection && (
          <StyledSubmenuLink
            onClick={closeMenu}
            active={businessSectionRegexMap.plans.test(pathname)}
            text={t(menu.plans)}
            to={SUBSCRIPTIONS_ROUTES.SUBSCRIPTIONS_PLANS}
          />
        )}
        <StyledSubmenuLink
          onClick={closeMenu}
          active={businessSectionRegexMap.integrations.test(pathname)}
          text={t(menu.integrations)}
          to={INTEGRATIONS.INTEGRATIONS}
          isBeta
        />
        {shouldRenderMyBookkeeperSection && (
          <StyledSubmenuLink
            onClick={closeMenu}
            active={businessSectionRegexMap.myBookkeeper.test(pathname)}
            text={t(menu.myBookkeeper)}
            to={ROUTES.MY_BOOKKEEPER}
          />
        )}
      </>
    </Section>
  );
};

export default BusinessSection;
