export enum CorrectiveCause {
  R1 = 'R1', // Modificación de la base imponible
  R2 = 'R2', // Concurso
  R3 = 'R3', // Deuda Incobrable
  R4 = 'R4', // Rest
  R5 = 'R5', // Factura rectificativa simplificada
}

export enum CorrectiveType {
  S = 'S',
  I = 'I',
}
