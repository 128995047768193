import { createElement, useMemo } from 'react';
import { Hidden, Visible } from 'react-grid-system';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { selectIsMobile } from 'modules/App/store/selectors';
import useTranslations, {
  TranslationFunction,
} from 'modules/I18n/hooks/useTranslations';
import { PLANS } from 'modules/Subscriptions/models';
import { ROUTES } from 'modules/Subscriptions/routesMap';
import { HelpModule, Box, Text, Link, HeadingWithBack } from 'modules/Ui';
import { menuSizes } from 'modules/Ui/Menu/MenuMobile/menuSizes';
import NavigationBar from 'modules/Ui/NavigationBar';

import { apiPublicZeroState } from '../../messages';
import { ApiPublicIlustration } from '../ApiPublicIlustration';

const getTexts = (t: TranslationFunction) => {
  return {
    subtitle: t<Element, JSX.Element>(apiPublicZeroState.zeroStateText, {
      bold: (chunk) =>
        createElement(Text, {
          color: 'gray800',
          fontWeight: '600',
          textSize: 'xs',
          tag: 'strong',
          children: chunk,
        }),
    }),
    button: t(apiPublicZeroState.zeroStateUpgradeButton),
    redirectUrl: `${ROUTES.SUBSCRIPTIONS_CHANGE}/${PLANS.UNLIMITED[1]}`,
  };
};

export const ApiPublicZeroState = () => {
  const { t } = useTranslations();
  const isMobile = useSelector(selectIsMobile);
  const history = useHistory();

  const { subtitle, button, redirectUrl } = useMemo(() => getTexts(t), []);

  return (
    <>
      <NavigationBar
        title={t(apiPublicZeroState.zeroStateBackTitle)}
        onClickBack={() => history.push('/integrations')}
      />
      <Hidden xs>
        <HeadingWithBack
          title={t(apiPublicZeroState.zeroStateBackTitle)}
          marginBottom="20px"
          onClick={() => history.push('/integrations')}
        />
      </Hidden>
      <Box
        boxSizing={isMobile ? 'border-box' : undefined}
        display="grid"
        gap={{ md: '28px 16px', lg: '28px 80px' }}
        gridTemplateColumns={{ md: '1fr 288px', lg: '1fr 366px' }}
        gridTemplateRows={{ _: '1fr 80px', sm: 'initial' }}
        height={
          isMobile && `calc(100vh - ${menuSizes.HEADER_HEIGHT_DESKTOP}px)`
        }
        padding={{ _: '32px 16px', sm: '50px 32px', md: '50px 32px' }}
        tag="section"
        backgroundColor="gray0"
      >
        <Box textAlign="center" overflowY={{ _: 'scroll', sm: 'initial' }}>
          <ApiPublicIlustration
            height={170}
            margin={{ _: '0 auto 30px', sm: '0 auto 24px' }}
            width={171}
          />
          <Text
            color="brand500"
            fontSize={24}
            lineHeight={30}
            marginBottom={{ _: '16px', sm: '12px' }}
            tag="h3"
          >
            {t(apiPublicZeroState.zeroStateTitle)}
          </Text>
          <Text
            color="gray600"
            fontSize={{ _: 16, sm: 14 }}
            lineHeight={{ _: 22, sm: 18 }}
            marginBottom={{ sm: '16px' }}
          >
            {subtitle}
          </Text>
          <Hidden xs>
            <Link to={redirectUrl} variant="stroke" width="255px">
              {button}
            </Link>
          </Hidden>
        </Box>
        <Box
          backgprundColor={{ _: 'gray0', sm: 'initial' }}
          bottom={{ _: '16px', sm: 'auto' }}
          position={{ _: 'fixed', sm: 'initial' }}
          tag="aside"
          width={{ _: 'calc(100% - 32px)', sm: 'auto' }}
        >
          <Visible xs>
            <Link to={redirectUrl} variant="stroke" width="100%">
              {button}
            </Link>
          </Visible>
          <Hidden xs sm md>
            <HelpModule />
          </Hidden>
        </Box>
      </Box>
    </>
  );
};
