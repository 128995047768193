import * as Yup from 'yup';

import { TranslationFunction } from 'modules/I18n/hooks/useTranslations';

import { messages } from './messages';

export const getValidationSchema = (t: TranslationFunction) =>
  Yup.object().shape({
    contactIBAN: Yup.string()
      .required(t(messages.mandatory))
      .ibanFormat(t(messages.format)),
  });
