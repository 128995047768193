import { FC, useCallback, useMemo } from 'react';
import { Visible } from 'react-grid-system';
import { useDispatch, useSelector } from 'react-redux';

import { Formik } from 'formik';

import { isDuplicatedVatNumberOnPostBusiness } from 'modules/Business/Create/services/createBusiness';
import { updateBusinessVatNumber } from 'modules/Business/Current/actions';
import { selectCurrentBusiness } from 'modules/Business/Current/selectors';
import businessMessages from 'modules/Business/Update/messages';
import updateBusiness from 'modules/Business/Update/services/updateBusiness';
import useTranslations from 'modules/I18n/hooks/useTranslations';
import { MODAL_WIDTH_DEFAULT, Dialog, useCloseModal } from 'modules/Modals';
import { createNotification } from 'modules/Notifications/actions';
import { NotificationType } from 'modules/Notifications/models';
import { Button, FormikField, Warning } from 'modules/Ui';
import requestErrorHandler from 'utils/requestErrorHandler';
import { useBlockingMutation } from 'utils/useBlockingMutation';

import { FixVatNumberModalProps } from './FixVatNumberModalProps';
import messages from './messages';
import getSchema from './validation';

const FixVatNumberModal: FC<FixVatNumberModalProps> = (props) => {
  const { onAccept, onCancel, vatNumber } = props;
  const { t } = useTranslations();
  const dispatch = useDispatch();
  const business = useSelector(selectCurrentBusiness);
  const closeModal = useCloseModal();
  const handleCancel = useCallback(() => {
    onCancel && onCancel();
    closeModal();
  }, []);

  const schema = useMemo(() => getSchema(t), [t]);

  const { mutateAsync } = useBlockingMutation(updateBusiness, {
    onSuccess: (data) => {
      dispatch(updateBusinessVatNumber(data.vatNumber));
    },
  });

  return (
    <Dialog
      type="fullScreen"
      id="fix-vat-number-modal"
      title={t(messages.title)}
      onClose={handleCancel}
      dialogWidth={{ md: MODAL_WIDTH_DEFAULT }}
      content={
        <>
          <Warning message={t(messages.text)} />
          <Formik
            validateOnChange={false}
            validateOnBlur={false}
            onSubmit={async (values, actions) => {
              try {
                const { address, fiscalName, phoneNumber } = business;
                await mutateAsync({
                  address,
                  fiscalName,
                  phoneNumber,
                  vatNumber: values.vatNumber,
                });
              } catch (e: any) {
                const errorHandled = requestErrorHandler(e);
                const { isNetworkError, networkErrorTranslationKey } =
                  errorHandled;
                const duplicatedVatNumberOnPostBusiness =
                  isDuplicatedVatNumberOnPostBusiness(errorHandled);
                const message = duplicatedVatNumberOnPostBusiness
                  ? businessMessages.updateVatNumberError.id
                  : businessMessages.koMessage.id;

                dispatch(
                  createNotification({
                    type: NotificationType.ERROR,
                    message: isNetworkError
                      ? networkErrorTranslationKey
                      : message,
                  })
                );
                return;
              }

              closeModal();
              onAccept && onAccept(values);
              actions.setSubmitting(false);
            }}
            initialValues={{
              vatNumber,
            }}
            validationSchema={schema}
          >
            {(formik) => (
              <form
                onSubmit={formik.handleSubmit}
                noValidate
                id="fix-vat-number"
              >
                <FormikField
                  label={t(messages.vatNumber)}
                  id="vatNumber"
                  maxLength={20}
                  width={{ _: '100%', sm: 'calc(50% - 12px)' }}
                  required
                  marginBottom="20px"
                />
              </form>
            )}
          </Formik>
        </>
      }
      buttonSecondary={
        <Visible sm md lg xl xxl>
          <Button type="button" variant="stroke" onClick={handleCancel}>
            {t(messages.buttonSecondary)}
          </Button>
        </Visible>
      }
      buttonPrimary={
        <Button form="fix-vat-number" variant="secondary" type="submit">
          {t(messages.buttonPrimary)}
        </Button>
      }
    />
  );
};

export default FixVatNumberModal;
