import { defineMessages } from 'react-intl';

const messages = defineMessages({
  success: { id: 'gofre.Bookkeeper.Invitations.Cancel.success' },
  error: { id: 'gofre.Bookkeeper.Invitations.Cancel.error' },
  title: { id: 'gofre.Bookkeeper.Invitations.Cancel.title' },
  text: { id: 'gofre.Bookkeeper.Invitations.Cancel.text' },
  confirm: { id: 'gofre.Bookkeeper.Invitations.Cancel.confirm' },
});

export default messages;
