import { useInvalidateAndUpdate } from 'modules/App/hooks/useInvalidateAndUpdate';
import useNotifications from 'modules/Notifications/hooks/useNotifications';
import editPayment, {
  EditPaymentResponse,
  invalidateQueries,
} from 'modules/Payments/services/editPayment'; // invalidateQueries,
import { ResponseError } from 'types/response-error.type';
import requestErrorHandler from 'utils/requestErrorHandler';
import { useBlockingMutation } from 'utils/useBlockingMutation';

import messages from './messages';

function useEditPayment(
  callback?: (response: EditPaymentResponse) => void,
  showSuccessNotification: boolean = true
) {
  const notifications = useNotifications();
  const invalidateAndUpdate = useInvalidateAndUpdate();
  const { mutate, mutateAsync } = useBlockingMutation(editPayment, {
    onSuccess: async (response): Promise<EditPaymentResponse> => {
      if (showSuccessNotification) {
        notifications.success(messages.editSuccess.id, { ttl: 5000 });
      }
      invalidateAndUpdate({ invalidateQueries, isAsync: true });
      callback?.(response);
      return response;
    },
    onError: (error: ResponseError) => {
      const { isNetworkError, networkErrorTranslationKey } =
        requestErrorHandler(error);
      return notifications.error(
        isNetworkError ? networkErrorTranslationKey : messages.editError.id
      );
    },
  });
  return { mutate, mutateAsync };
}

export default useEditPayment;
