import { css } from 'modules/Theme/styled-components';

const hoverEffect = (borderRadius?: string) => css`
    &:before {
      border-radius: ${borderRadius};
      background-color: var(--button-hover-background);
      content: "";
      display: block;
      height: 100%;
      left: 0;
      opacity: 0;
      position: absolute;
      top: 0;
      transition: opacity 0.3s ease-in-out;
      width: 100%;
  }

  @media(hover : hover) {
    &:hover {
      &:before {
        opacity: 1;
        border-radius: ${borderRadius};
      }
    }
  }

  &:focus {
    &:before {
      opacity: 1;
      border-radius: ${borderRadius};
    }
  }
`;

export default hoverEffect;
