import { FC, ReactNode } from 'react';

import theme from 'modules/Theme';
import { circleHover, tooltipStyles } from 'modules/Theme/mixins';
import styled from 'modules/Theme/styled-components';
import { Box, Tooltip } from 'modules/Ui';

interface Props {
  id: string;
  label?: string;
  noBullet?: boolean;
  onClick?: (value: any) => void;
  place?: 'bottom' | 'top' | 'left' | 'right' | undefined;
  tooltipButton: ReactNode;
  tooltipContent: string | ReactNode;
}

const StyledButton = styled(Box)`
  /*Hover effect size, hover icon color*/
  ${circleHover('36px', `${theme.colors.brand600}`)}
`;

const StyledTooltip = styled(Box)`
  ${tooltipStyles}
`;

const ButtonIconTooltip: FC<Props> = ({
  id,
  label,
  noBullet = true,
  onClick,
  place = 'bottom',
  tooltipButton,
  tooltipContent,
}) => (
  <Tooltip
    id={id}
    label={label}
    noBullet={noBullet}
    onClick={onClick}
    place={place}
    tooltipBackgroundColor={theme.colors.primary400}
    tooltipContentPadding="4px 10px 6px"
    tooltipButton={
      <StyledButton
        alignItems="center"
        borderRadius="50%"
        display="flex"
        height="36px"
        justifyContent="center"
        position="relative"
        width="36px"
        tag="span"
      >
        {tooltipButton}
      </StyledButton>
    }
    tooltipContent={
      <StyledTooltip width="90px" tag="span">
        {tooltipContent}
      </StyledTooltip>
    }
    tooltipTextColor={theme.colors.gray0}
  />
);

export default ButtonIconTooltip;
