import { CreatedPaymentMethod } from 'modules/PaymentMethods/models/paymentMethods';

const normalizeValues = ({
  type,
  id,
  data,
  futureDocuments,
}: CreatedPaymentMethod) => {
  return { type, id, data, futureDocuments };
};

export default normalizeValues;
