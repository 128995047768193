import { errors } from 'modules/Ui/Errors/messages';
import { ResponseError } from 'types/response-error.type';

export type RequestErrorHandled = {
  responseCode?: string;
  responseMessage?: string;
  responseDeveloperDescriptor?: string;
  responseStatus?: number;
  errorMessage?: string;
  isNetworkError: boolean;
  isUnknownError?: boolean;
  networkErrorTranslationKey: string;
  genericErrorTranslationKey: string;
};

function requestErrorHandler(err: any): RequestErrorHandled {
  if (!err) {
    // eslint-disable-next-line no-console
    console.error('Falsy error throwed', err, new Error());
    return {
      isNetworkError: false,
      isUnknownError: true,
      networkErrorTranslationKey: errors.networkError.id,
      genericErrorTranslationKey: errors.generic.id,
    };
  }
  const error: ResponseError = err;
  const errorMessage = error.message;
  let responseCode;
  let responseMessage;
  let responseDeveloperDescriptor;
  let responseStatus;
  if ('response' in error) {
    responseCode = error.response?.data?.code;
    responseMessage = error.response?.data?.message;
    responseDeveloperDescriptor = error.response?.data?.developerDescriptor;
    responseStatus = error.response?.status;
  }

  const isNetworkError = error.message === 'Network Error';

  return {
    responseCode,
    responseMessage,
    responseDeveloperDescriptor,
    responseStatus,
    errorMessage,
    isNetworkError,
    networkErrorTranslationKey: errors.networkError.id,
    genericErrorTranslationKey: errors.generic.id,
  };
}

export function requestErrorHandlerExtra(err: any) {
  if (!err) {
    // eslint-disable-next-line no-console
    console.error('Falsy error throwed', err, new Error());
    return err;
  }
  const error: ResponseError = err;
  let responseExtra;
  if ('response' in error) {
    responseExtra = error.response?.data?.extra;
  }

  return responseExtra;
}
export default requestErrorHandler;
