import React from 'react';
import { Visible } from 'react-grid-system';

import { useStripe } from '@stripe/react-stripe-js';

import useTranslations from 'modules/I18n/hooks/useTranslations';
import Button from 'modules/Ui/Form/Button';

import messages from './messages';

interface Props {
  onCancel(): void;
  inProgress: boolean;
}

export const PaymentFormActions: React.FC<Props> = ({
  onCancel,
  inProgress,
}) => {
  const stripe = useStripe();
  const { t } = useTranslations();
  return (
    <>
      <Visible md lg xl xxl>
        <Button
          form="payment-form"
          marginBottom={{ _: '16px', sm: '0' }}
          onClick={onCancel}
          variant="stroke"
        >
          {t(messages.cancel)}
        </Button>
      </Visible>
      <Button
        disabled={!stripe || inProgress}
        form="payment-form"
        type="submit"
        variant="secondary"
      >
        {t(messages.submit)}
      </Button>
    </>
  );
};
