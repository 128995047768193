import { FC } from 'react';
import { useLocation } from 'react-router';

import { useFeatureFlag } from 'modules/FeatureFlags';
import useTranslations from 'modules/I18n/hooks/useTranslations';
import { menu } from 'modules/Ui/Menu/messages';
import { paymentsSectionRegexMap } from 'modules/Ui/Menu/regex';
import { PAYMENT_ROUTES } from 'pages/payments/routesMap';

import StyledSubmenuLink from '../SubmenuLink';
import { BusinessSectionProps } from './BusinessSection';
import Section from './Section';

const PaymentSection: FC<BusinessSectionProps> = (props) => {
  const { open, closeMenu } = props;
  const isRemittancesFlagActive = useFeatureFlag('showRemittances');
  const location = useLocation();
  const { t } = useTranslations();
  const { pathname } = location;
  return (
    <Section {...{ open }}>
      <>
        <StyledSubmenuLink
          onClick={closeMenu}
          active={paymentsSectionRegexMap.payments.test(pathname)}
          text={t(menu.submenuPayments)}
          to={PAYMENT_ROUTES.LIST}
        />
        <StyledSubmenuLink
          onClick={closeMenu}
          active={paymentsSectionRegexMap.banks.test(pathname)}
          text={t(menu.submenuBanks)}
          to={PAYMENT_ROUTES.BANKS}
        />

        {isRemittancesFlagActive && (
          <StyledSubmenuLink
            active={paymentsSectionRegexMap.remittances.test(pathname)}
            onClick={closeMenu}
            text={t(menu.submenuRemittances)}
            to={PAYMENT_ROUTES.REMITTANCES}
          />
        )}
      </>
    </Section>
  );
};

export default PaymentSection;
