import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  cancel: { id: 'gofre.dialog.RemittanceContact.cancel' },
  submit: { id: 'gofre.dialog.RemittanceContact.submit' },
  title: { id: 'gofre.dialog.RemittanceContact.title' },
  subtitle: { id: 'gofre.dialog.RemittanceContact.subtitle' },
  ibanLabel: { id: 'gofre.dialog.RemittanceContact.form-iba.label' },
  mandatory: { id: 'gofre.Form.field.mandatory' },
  format: { id: 'gofre.Form.field.format' },
});
