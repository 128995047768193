import { useHistory } from 'react-router-dom';

import useHasSeveralBusinesses from 'modules/Business/hooks/useHasSeveralBusinesses';
import useTranslations from 'modules/I18n/hooks/useTranslations';
import { ModalTypes } from 'modules/Modals/constants';
import useOpenModal from 'modules/Modals/hooks/useOpenModal';
import useTheme from 'modules/Theme/hooks/useTheme';
import { Box, Button } from 'modules/Ui';
import isNativeApp from 'utils/native/isNativeApp';

import Logo from '../Components/Logo';
import messages from '../messages';

const MenuDesktopEndTrial: React.FC = () => {
  const hasSeveralBusinesses = useHasSeveralBusinesses();
  const history = useHistory();
  const { t } = useTranslations();
  const theme = useTheme();

  function onCloseSessionClick() {
    history.push('/auth/logout');
  }

  const onChangeBusinessClick = useOpenModal({
    type: ModalTypes.CHANGE_BUSINESS,
  });

  if (isNativeApp()) return null;

  return (
    <Box
      alignItems="center"
      display="flex"
      height="100%"
      justifyContent="space-between"
      margin="0 auto"
      maxWidth={{
        xs: theme.maxWidth.xs,
        sm: theme.maxWidth.sm,
        md: theme.maxWidth.md,
        lg: theme.maxWidth.lg,
      }}
      padding="0 2.8rem"
    >
      <Logo />
      <Box display="flex" justifyContent="flex-end" flex="1">
        <>
          <Button onClick={onCloseSessionClick} variant="stroke">
            {t(messages.closeSession)}
          </Button>

          {hasSeveralBusinesses && (
            <Button
              marginLeft="16px"
              onClick={onChangeBusinessClick}
              variant="secondary"
            >
              {t(messages.change)}
            </Button>
          )}
        </>
      </Box>
    </Box>
  );
};

export default MenuDesktopEndTrial;
