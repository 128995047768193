import {
  setCurrentUser,
  getCurrentUser as getCurrentUserStorage,
} from 'utils/storage';

export const storeCurrentUser = (currentUser: {
  userId: string;
  businessId: string;
}) => {
  setCurrentUser(JSON.stringify(currentUser));
};

export const getCurrentUser = (): {
  userId: string;
  businessId: string;
} | null => {
  const currentUser = getCurrentUserStorage();
  if (!currentUser) return null;
  return JSON.parse(currentUser);
};
