import IconByString, { IconByStringProps } from './Icon/IconType/IconByString';

const IconSave = (props: IconByStringProps) => (
  <IconByString {...props}>
    <path
      d="m21.268 6.06-3.329-3.328A2.481 2.481 0 0 0 16.172 2H3.5A1.5 1.5 0 0 0 2 3.5v17A1.5 1.5 0 0 0 3.5 22h17a1.5 1.5 0 0 0 1.5-1.5V7.829a2.484 2.484 0 0 0-.732-1.768ZM15.5 3v4.5h-8V3h8Zm-9 18v-6h11v6h-11Zm14.5-.5v.5h-2.5v-6a1 1 0 0 0-1-1h-11a1 1 0 0 0-1 1v6H3V3h3.5v4.5a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V3.037c.277.06.531.2.732.4l3.329 3.328A1.49 1.49 0 0 1 21 7.829V20.5Z"
      fill="currentColor"
    />
    <path
      d="M14 6.5a.5.5 0 0 0 .5-.5V4.5a.5.5 0 0 0-1 0V6a.5.5 0 0 0 .5.5Z"
      fill="currentColor"
    />
  </IconByString>
);

export default IconSave;
