import { Switch } from 'react-router-dom';

import {
  LazyComponentsSwitch,
  RouteDefinition,
} from 'modules/App/components/LazyComponentsSwitch';
import { SETTINGS_PATH } from 'modules/Settings/paths';
import { ROUTES as SUBSCRIPTION_ROUTES } from 'modules/Subscriptions/routesMap';
import { USER_DETAIL } from 'modules/Users/routesMap';
import { ROUTES } from 'pages/bookkeepers/routesMap';
import { ROUTES as BUSINESS_ROUTES } from 'pages/business/routesMap';

const BusinessSection = () => import('./Sections/BusinessSection');

const routes: RouteDefinition[] = [
  {
    path: USER_DETAIL,
    lazyComponent: BusinessSection,
  },
  {
    path: BUSINESS_ROUTES.BUSINESS,
    lazyComponent: BusinessSection,
  },
  {
    path: SUBSCRIPTION_ROUTES.SUBSCRIPTIONS,
    lazyComponent: BusinessSection,
  },
  {
    path: SETTINGS_PATH,
    lazyComponent: BusinessSection,
    requiresAdmin: true,
  },
  {
    path: ROUTES.MY_BOOKKEEPER,
    lazyComponent: BusinessSection,
  },
  {
    path: ROUTES.INTEGRATIONS,
    lazyComponent: BusinessSection,
  },
];

export const SubMenuBusiness = () => {
  return (
    <Switch>
      <LazyComponentsSwitch routes={routes} />
    </Switch>
  );
};
