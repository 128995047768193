import accesibleHide from 'modules/Theme/mixins/accessibleHidden';
import styled from 'modules/Theme/styled-components';
import Input from 'modules/Ui/Html/Input';

import Card from './Card';
import Radio from './Radio';

const RadioInput = styled(Input)`
  ${accesibleHide}
  &:checked {
    ~  ${Card} {
      border-color: ${(props) => props.theme.colors.brand500};
      &:before {
        opacity: 1;
      }
      .radioCard-text {
          color: ${(props) => props.theme.colors.brand500};
        }
      &:hover,
      &:focus {
        border: ${(props) => `1px solid ${props.theme.colors.brand400}`};
        ${Radio} {
          &:after {
            background-color: ${(props) => props.theme.colors.brand400};
          }
        }
      }
      ${Radio} {
        background-color: ${(props) => props.theme.colors.gray0};
        &:after {
          opacity: 1;
        }
      }
    }
  }
  &:focus ~ ${Radio} {
    border-color: ${(props) => props.theme.colors.gray800};
    will-change: transition;
  }
  &:hover ~ ${Card} {
    border-color: ${(props) => props.theme.colors.gray600};
    will-change: transition;
  }

`;

export default RadioInput;
