import React from 'react';

import { format, isDate } from 'date-fns';
import { useField } from 'formik';

import Field from 'modules/Ui/Form/Field';
import IconCalendar from 'modules/Ui/Icons/IconCalendar';

import { FormikFieldProps } from '../FormikFieldProps';

function parse(value: string) {
  return (isDate(value) && format(new Date(value), 'yyyy-MM-dd')) || value;
}

const DayPickerFormikFieldMobile: React.FC<FormikFieldProps> = (props) => {
  const { name, id } = props;
  const [field, meta] = useField(name || id);
  return (
    <Field
      {...field}
      {...props}
      type="date"
      value={parse(field.value)}
      icon={<IconCalendar color="brand500" />}
      error={meta.touched && meta.error ? meta.error : undefined}
    />
  );
};

export default DayPickerFormikFieldMobile;
