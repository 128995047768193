import { FC, useCallback } from 'react';

import { Formik } from 'formik';

import {
  AccountingAccount,
  AccountingAccountTypeEnum,
} from 'modules/AccountingAccounts/models';
import useTranslations from 'modules/I18n/hooks/useTranslations';
import { Box, FormikField } from 'modules/Ui';

import { formText } from '../../messages';
import { ALIAS_MAX_LENGTH } from '../AccountingAccountModal/constants';
import { getValidationSchema } from './validation';

export interface AccountingAccountCashRegisterForm {
  type: AccountingAccountTypeEnum.CASH_REGISTER;
  alias: string;
}

interface CashRegisterFormProps {
  handleSubmit: (values: AccountingAccountCashRegisterForm) => void;
  account?: AccountingAccount;
  isEditing?: boolean;
}

export const CashRegisterForm: FC<CashRegisterFormProps> = ({
  handleSubmit,
  account,
  isEditing = false,
}) => {
  const { t } = useTranslations();
  const validationSchema = getValidationSchema(t);
  const onSubmit = useCallback(
    (values: Pick<AccountingAccountCashRegisterForm, 'alias'>) => {
      handleSubmit({
        type: AccountingAccountTypeEnum.CASH_REGISTER,
        ...values,
      });
    },
    []
  );
  return (
    <Formik
      enableReinitialize
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      initialValues={{
        alias: account?.alias || '',
      }}
      validateOnBlur={false}
      validateOnChange={false}
    >
      {(formik) => (
        <Box
          autoComplete="off"
          id={AccountingAccountTypeEnum.CASH_REGISTER}
          marginBottom={!isEditing && { sm: '24px' }}
          noValidate
          onSubmit={formik.handleSubmit}
          tag="form"
        >
          <FormikField
            id="cash-alias"
            label={t(formText.cashRegisterAlias)}
            maxLength={ALIAS_MAX_LENGTH}
            name="alias"
            placeholder={t(formText.cashRegisterAliasPlaceholder)}
            required
          />
        </Box>
      )}
    </Formik>
  );
};
