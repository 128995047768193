import IconByPath, { IconByPathProps } from './Icon/IconType/IconByPath';

const IconEmployees = (props: IconByPathProps) => (
  <IconByPath
    {...props}
    fillRule="evenodd"
    clipRule="evenodd"
    path="M10.5 2.5A3.5 3.5 0 0 1 14 6v2.333a3.5 3.5 0 0 1-7 0V6a3.5 3.5 0 0 1 3.5-3.5Zm0 1.167A2.333 2.333 0 0 0 8.167 6v2.333a2.333 2.333 0 1 0 4.666 0V6A2.333 2.333 0 0 0 10.5 3.667ZM2 22v-4.25l1.245-1.245a10.26 10.26 0 0 1 14.51 0L19 17.75V22H2Zm15.004-4.744a9.198 9.198 0 0 0-13.008 0l-.933.934v2.747h14.874V18.19l-.933-.934Zm4.896.612c-.799-.792-2.2-2.118-4.092-2.906l.384-.923c2.206.918 3.78 2.489 4.552 3.258l.156.156V21.5h-1v-3.632ZM19 9v2a1.5 1.5 0 0 1-3 0V9a1.5 1.5 0 0 1 3 0Zm-4 0a2.5 2.5 0 0 1 5 0v2a2.5 2.5 0 0 1-5 0V9Z"
  />
);

export default IconEmployees;
