import { FC } from 'react';
import { Hidden } from 'react-grid-system';

import { UserPermissions } from 'modules/Auth/constants';
import useHasUserScope from 'modules/Auth/hooks/checkUsersPermissions';
import useTranslations from 'modules/I18n/hooks/useTranslations';
import DataTableZeroState from 'modules/Ui/DataTable/DataTableZeroState';
import Link from 'modules/Ui/Link';
import { ROUTES } from 'pages/documents/routesMap';

import { zeroStateExpenses } from '../../messages';

interface ExpensesZeroStateProps {
  subtitle?: string;
  title?: string;
  link?: string;
  showCTA?: boolean;
}

const ExpensesZeroState: FC<ExpensesZeroStateProps> = ({
  subtitle,
  title,
  link,
  showCTA = true,
}) => {
  const { t } = useTranslations();
  const { hasUserScope } = useHasUserScope();
  const canUserCreate =
    hasUserScope(UserPermissions.SUBSCRIPTION_EDITOR) && showCTA;

  return (
    <DataTableZeroState
      img="/assets/empty-expenses.svg"
      subtitle={subtitle ?? t(zeroStateExpenses.subtitle)}
      title={title ?? t(zeroStateExpenses.title)}
    >
      <Hidden xs>
        {canUserCreate && (
          <Link
            marginTop="16px"
            variant="secondary"
            to={ROUTES.DOCUMENTS_EXPENSES_UPLOAD}
          >
            {link ?? t(zeroStateExpenses.link)}
          </Link>
        )}
      </Hidden>
    </DataTableZeroState>
  );
};

export default ExpensesZeroState;
