import { FC } from 'react';
import { useSelector } from 'react-redux';

import {
  hasElectronicDocumentProvider,
  shouldSendElectronicDocument,
} from 'modules/Business/businessUtils';
import { selectCurrentBusiness } from 'modules/Business/Current/selectors';
import useTranslations from 'modules/I18n/hooks/useTranslations';
import useDocumentSettings from 'modules/Settings/hooks/useDocumentSettings';
import useTheme from 'modules/Theme/hooks/useTheme';
import { Box, Img, Text } from 'modules/Ui';
import { IconStateDone } from 'modules/Ui/Icons';
import { menuSizes } from 'modules/Ui/Menu/MenuMobile/menuSizes';

import { shopifySuccess } from '../../messages';
import { ShopifyConfigurationSuccessActions } from './ShopifyConfigurationSuccessActions';
import { ShopifyConfigurationSuccessBaiSubtitle } from './ShopifyConfigurationSuccessBaiSubtitle';
import { ShopifyConfigurationSuccessBaiText } from './ShopifyConfigurationSuccessBaiText';

export const ShopifyConfigurationSuccessBody: FC = () => {
  const { t } = useTranslations();
  const theme = useTheme();
  const business = useSelector(selectCurrentBusiness);
  const isBai = hasElectronicDocumentProvider(business);
  const sendsBaiDocuments = shouldSendElectronicDocument(business);
  const { isFetching, data: settings } = useDocumentSettings();

  const needsToConfigureBai = isBai && !settings.operationDescription;
  const needsToConnectBai = isBai && !sendsBaiDocuments;

  if (isFetching) {
    return null;
  }
  return (
    <Box
      backgroundColor="gray0"
      border={`1px solid ${theme.colors.primary100}`}
      boxSizing="border-box"
      display={{ _: 'grid', sm: 'block' }}
      gridTemplateRows={{ _: '1fr auto' }}
      minHeight={{
        _: `calc(100vh - ${menuSizes.HEADER_HEIGHT_MOBILE}px)`,
        sm: '824px',
        md: '600px',
      }}
      padding={{ _: '0 16px', sm: '0 64px', md: '0 180px' }}
      tag="section"
      textAlign="center"
    >
      <Box
        marginBottom={{ sm: '24px' }}
        paddingTop={{ _: '127px', sm: '216px', md: '105px' }}
        textAlign="inherit"
      >
        <Box
          alignItems="flex-end"
          display="flex"
          justifyContent="center"
          marginBottom={{ _: '32px', sm: '24px', md: '32px' }}
          position="relative"
        >
          <Img
            alt={t(shopifySuccess.shopifyConfigSuccessAlt)}
            data-testid="subscription-success"
            height={83}
            src="/assets/logos/shopify-logo.svg"
            width={73}
          />
          <IconStateDone size={36} transform="translateX(-15px)" />
        </Box>
        <Text
          color="primary500"
          fontSize={22}
          fontWeight="600"
          lineHeight={26}
          marginBottom={{ _: '16px', sm: '8px' }}
          tag="h2"
        >
          {t(shopifySuccess.shopifyConfigSuccessTitle)}
        </Text>
        <ShopifyConfigurationSuccessBaiSubtitle
          needsToConfigureBai={needsToConfigureBai}
          needsToConnectBai={needsToConnectBai}
        />
        <ShopifyConfigurationSuccessBaiText
          needsToConfigureBai={needsToConfigureBai}
          needsToConnectBai={needsToConnectBai}
        />
      </Box>
      <ShopifyConfigurationSuccessActions
        needsToConfigureBai={needsToConfigureBai}
        needsToConnectBai={needsToConnectBai}
      />
    </Box>
  );
};
