import { FC } from 'react';
import { useHistory } from 'react-router-dom';

import useTranslations from 'modules/I18n/hooks/useTranslations';
import { HeadingWithBack } from 'modules/Ui';
import SectionTitleWrapper from 'modules/Ui/SectionTitle/SectionTitleWrapper';

import { ROUTES } from '../../../../pages/documents/routesMap';
import { PAYMENT_ROUTES } from '../../../../pages/payments/routesMap';
import { remittanceDocumentListMessage } from '../messages';

interface RemittanceAddDocumentsHeaderProps {
  isCreditTransfer: boolean;
  isFromList?: boolean;
}

export const RemittanceAddDocumentsHeader: FC<
  RemittanceAddDocumentsHeaderProps
> = ({ isCreditTransfer, isFromList }) => {
  const { t } = useTranslations();
  const history = useHistory();

  const goBack = () => {
    if (isFromList) {
      if (!isCreditTransfer) {
        history.push(ROUTES.DOCUMENTS);
      } else {
        history.push(ROUTES.DOCUMENTS_EXPENSES);
      }
      return;
    }
    history.push(PAYMENT_ROUTES.REMITTANCES);
  };

  return (
    <SectionTitleWrapper>
      <HeadingWithBack
        title={t(
          isCreditTransfer
            ? remittanceDocumentListMessage.title
            : remittanceDocumentListMessage.titleDebit
        )}
        onClick={goBack}
        marginBottom="0"
      />
    </SectionTitleWrapper>
  );
};

export default RemittanceAddDocumentsHeader;
