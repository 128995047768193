import IconByPath, { IconByPathProps } from './Icon/IconType/IconByPath';

const IconEuro = (props: IconByPathProps) => (
  <IconByPath
    {...props}
    path="M16.435 9.314H9.494c.643-2.598 2.302-3.919 4.393-3.919 1.473 0 2.666.652 3.614 1.465l.999-2.142C17.264 3.618 15.69 3 13.887 3 10.349 3 7.674 5.345 6.87 9.314H5.135L4.5 11.058h2.15a14.205 14.205 0 0 0 0 1.693H5.135L4.5 14.51h2.37c.804 3.97 3.479 6.298 7.017 6.298 1.735 0 3.369-.559 4.57-1.642l-1.024-2.133c-.854.71-2.056 1.38-3.546 1.38-2.09 0-3.75-1.312-4.393-3.903h4.604l.822-1.76H9.232a13.793 13.793 0 0 1 0-1.693h6.399l.804-1.744Z"
  />
);

export default IconEuro;
