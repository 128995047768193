import useTranslations from 'modules/I18n/hooks/useTranslations';
import { Text, Box } from 'modules/Ui';
import { IconBalance } from 'modules/Ui/Icons';

import { paymentsMethod as messages } from '../messages';

const PaymentsMethodZeroState = () => {
  const { t } = useTranslations();
  return (
    <Box
      border="1px solid"
      borderColor="primary200"
      fadeIn
      marginBottom="56px"
      padding={{ _: '43px 16px 22px', sm: '42px 0' }}
      textAlign="center"
      data-testid="payments-method-zero-state"
    >
      <Box
        alignItems="center"
        backgroundColor="auxiliary100"
        borderRadius="50%"
        display="flex"
        justifyContent="center"
        margin="0 auto 12px"
        size={64}
        tag="span"
      >
        <IconBalance color="brand500" />
      </Box>
      <Text fontSize={14} lineHeight={{ _: 18, sm: 17 }}>
        {t(messages.tableTitle)}
      </Text>
    </Box>
  );
};

export default PaymentsMethodZeroState;
