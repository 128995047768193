import { FC } from 'react';

import { space, SpaceProps } from 'styled-system';

import { formText } from 'modules/Forms/messages';
import useTranslations from 'modules/I18n/hooks/useTranslations';
import styled from 'modules/Theme/styled-components';
import { Text, Box } from 'modules/Ui';

interface RequiredTextProps extends SpaceProps {
  isMultiple?: boolean;
}

const StyledText = styled(Text)<RequiredTextProps>`
  ${space}
`;

const MandatoryText: FC<RequiredTextProps> = ({ isMultiple, ...rest }) => {
  const { t } = useTranslations();
  return (
    <StyledText fontSize={12} lineHeight={16} {...rest}>
      <Box tag="span" color="error" marginRight="4px">
        *
      </Box>
      {isMultiple ? t(formText.mandatoryFields) : t(formText.mandatory)}
    </StyledText>
  );
};
export default MandatoryText;
