import { AxiosResponse } from 'axios';

import { useInvalidateAndUpdate } from 'modules/App/hooks/useInvalidateAndUpdate';
import useTranslations from 'modules/I18n/hooks/useTranslations';
import useCloseModal from 'modules/Modals/hooks/useCloseModal';
import useNotifications from 'modules/Notifications/hooks/useNotifications';
import updatePaymentMethod, {
  invalidateQueries,
} from 'modules/PaymentMethods/services/updatePaymentMethod';
import { useBlockingMutation } from 'utils/useBlockingMutation';

import { ERRORS } from '../errors';
import { messages } from '../messages';
import { PaymentMethod } from '../models/paymentMethods';

type UseUpdatePaymentMethodProps = {
  onSuccess?: (paymentMethod: PaymentMethod) => void;
};

const useUpdatePaymentMethod = (props: UseUpdatePaymentMethodProps) => {
  const { onSuccess } = props;
  const { t } = useTranslations();
  const notifications = useNotifications();
  const invalidate = useInvalidateAndUpdate();

  const closeModal = useCloseModal();

  const { mutate } = useBlockingMutation(updatePaymentMethod, {
    onSuccess: (response) => {
      invalidate({ invalidateQueries });
      onSuccess && onSuccess(response);
      notifications.success(t(messages.updateSuccess), { ttl: 3000 });
      closeModal();
    },
    onError: (error: {
      response?: AxiosResponse<{
        code?: string;
        message?: string;
      }>;
    }) => {
      if (error?.response?.data?.code === ERRORS.BLACKLIST_FOUND) {
        notifications.error(t(messages.blacklistFound));
        closeModal();
      }
    },
  });

  return { mutate };
};

export default useUpdatePaymentMethod;
