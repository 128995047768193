import { ffStrToObj } from '../helpers/featureFlags.helpers';
import { FeatureFlagsSource, Flags } from '../model';
import BaseSource from './BaseSource';

export default class CookiesSource
  extends BaseSource
  implements FeatureFlagsSource
{
  root: Window;

  key: string;

  flags: Flags;

  constructor(root: Window, key = 'featureFlags') {
    super();
    this.root = root;
    this.key = key;
    this.flags = {};
  }

  init() {
    const ffStr = this.getCookieValue();
    if (!ffStr) return;

    this.flags = {
      ...this.flags,
      ...ffStrToObj(ffStr),
    };
    this.update(this.flags);
  }

  getCookieValue() {
    const value = this.root.document?.cookie.match(
      `(^|[^;]+)\\s*${this.key}\\s*=\\s*([^;]+)`
    );
    return value ? value.pop() : '';
  }

  getFlags() {
    return this.flags;
  }
}
