import { Document } from 'modules/Documents/models/document';

import { CreateFacturaeMethod } from '../../models/createFacturaeMethod';

export type DocumentsDataElectronicModalProps = {
  onSubmit: (response: CreateFacturaeMethod) => void;
  document: Document;
};

export enum DataElectronicRequestType {
  ADDRESSEMIT = 'ADDRESS_EMIT',
  ADDRESSCLIENT = 'ADDRESS_CLIENT',
  DIR3CLIENT = 'DIR3_CLIENT',
}

export interface SubForm {
  validNif?: boolean;
  validNifClient?: boolean;
  validAddressClient?: boolean;
  validAddressEmit?: boolean;
}

export interface DataElectronicItem {
  type: DataElectronicRequestType;
  subform: React.FC<SubForm>;
}

export interface RequestedForm {
  hasAddressEmit: boolean;
  hasAddressClient: boolean;
  hasDir3Client: boolean;
}
