import { FC } from 'react';
import { Hidden } from 'react-grid-system';
import { useSelector } from 'react-redux';

import { selectCurrentBusinessPhone } from 'modules/Business/Current/selectors';
import useTranslations from 'modules/I18n/hooks/useTranslations';
import { MODAL_WIDTH_MEDIUM, Dialog, useCloseModal } from 'modules/Modals';
import { Button } from 'modules/Ui';

import AddPaymentMethodForm from './AddPaymentMethodForm';
import messages from './messages';
import { AddPaymentMethodModalProps } from './types';

const AddPaymentMethodModal: FC<AddPaymentMethodModalProps> = ({
  onSuccess,
  paymentMethods,
  forContact,
}) => {
  const { t } = useTranslations();
  const defaultPhone = useSelector(selectCurrentBusinessPhone);

  const closeModal = useCloseModal();

  return (
    <Dialog
      dialogWidth={{ sm: MODAL_WIDTH_MEDIUM }}
      id="addPaymentMethodModal"
      onClose={closeModal}
      title={t(forContact ? messages.titleForContact : messages.title)}
      type="fullScreen"
      buttonSecondary={
        <Hidden xs>
          <Button onClick={closeModal} variant="stroke">
            {t(messages.cancel)}
          </Button>
        </Hidden>
      }
      buttonPrimary={
        <Button
          data-testid="add-payment-method-modal"
          form="add-payment-method-modal-form"
          type="submit"
          variant="secondary"
        >
          {t(messages.confirm)}
        </Button>
      }
      content={
        <AddPaymentMethodForm
          {...{ onSubmit: onSuccess, defaultPhone, paymentMethods, forContact }}
        />
      }
    />
  );
};

export default AddPaymentMethodModal;
