import IconByPath, { IconByPathProps } from './Icon/IconType/IconByPath';

const IconEdit = (props: IconByPathProps) => (
  <IconByPath
    {...props}
    clipRule="evenodd"
    fillRule="evenodd"
    path="M22 12c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10Zm1 0c0 6.075-4.925 11-11 11S1 18.075 1 12 5.925 1 12 1s11 4.925 11 11Zm-7.667-6L18 8.667 8.667 18H6v-2.667L15.333 6Zm0 1.414L7 15.747V17h1.251l8.334-8.334-1.252-1.252Z"
    shapeRendering="geometricPrecision"
  />
);

export default IconEdit;
