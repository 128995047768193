import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import { REMITTANCES_QUERY } from 'modules/App/queries/queries';
import useNotifications from 'modules/Notifications/hooks/useNotifications';
import { RemittanceSort } from 'modules/Payments/models';
import fetchRemittances from 'modules/Remittances/services/fetchRemittances';
import { DataTableSortOrder } from 'modules/Ui/DataTable/DataTable.models';
import useDataTablePagination from 'modules/Ui/DataTable/useDataTablePagination';

import { notificationsMessages } from '../messages';

export function useRemittanceListDataTable() {
  const notifications = useNotifications();

  const { pagination, onPageSizeChange, onPageChange } =
    useDataTablePagination();

  const [sortBy, setSortBy] = useState<RemittanceSort | undefined>({
    field: 'createdAt',
    order: DataTableSortOrder.DESC,
  });

  const response = useQuery(
    [
      REMITTANCES_QUERY,
      {
        ...pagination,
        sortBy,
      },
    ],
    () => fetchRemittances({ ...pagination, sortBy }),
    {
      onError: () => {
        notifications.error(notificationsMessages.error.id);
      },
    }
  );

  const { refetch } = response;

  useEffect(() => {
    refetch();
  }, [refetch, pagination, sortBy]);

  const showZeroState = !response.data?.items.length && !response.isFetching;

  return {
    ...response,
    pagination,
    sortBy,
    showZeroState,
    setSortBy,
    onPageSizeChange,
    onPageChange,
  };
}
