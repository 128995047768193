import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';
import { createResponsiveStateReducer } from 'redux-responsive';

import app from 'modules/App/reducer';
import auth from 'modules/Auth/reducer';
import discountBanner from 'modules/Bookkeeper/Home/DiscountBanner/reducer';
import business from 'modules/Business/Current/reducer';
import documents from 'modules/Documents/reducer';
import locale from 'modules/I18n/reducer';
import modals from 'modules/Modals/reducer';
import notifications from 'modules/Notifications/reducer';
import subscriptions from 'modules/Subscriptions/detail/reducer';
import breakpoints from 'modules/Theme/breakpoints';
import ui from 'modules/Ui/store/reducer';
import user from 'modules/Users/reducer';
import history from 'utils/history';

const createReducer = (injectedReducers = {}): any => {
  const appReducer = combineReducers({
    app,
    auth,
    browser: createResponsiveStateReducer({
      small: breakpoints.sm - 1,
      medium: breakpoints.md - 1,
      large: breakpoints.lg - 1,
      extraLarge: breakpoints.xl - 1,
    }),
    business,
    modals,
    notifications,
    locale,
    subscriptions,
    router: connectRouter(history),
    user,
    ui,
    discountBanner,
    documents,
    ...injectedReducers,
  });

  const rootReducer = (state: any, action: any) => {
    if (action.type === 'gofre/auth/LOGOUT') {
      // eslint-disable-next-line no-param-reassign
      state = {
        browser: state.browser,
      };
    }

    return appReducer(state, action);
  };

  return rootReducer;
};

export default createReducer;
