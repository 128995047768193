import { FC, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';

import { RemittanceTable } from 'modules/Payments/models';
import breakpoints, { max, min } from 'modules/Theme/breakpoints';
import { ellipsis } from 'modules/Theme/mixins';
import styled from 'modules/Theme/styled-components';
import { Box, Spinner } from 'modules/Ui';
import DataTable from 'modules/Ui/DataTable/DataTable';

import { useRemittanceListActions } from '../hooks';
import { useRemittanceListConfig } from '../hooks/useRemittanceListConfig';
import { useRemittanceListDataTable } from '../hooks/useRemittanceListDataTable';
import { RemittancesZeroState } from './RemittancesZeroState';

interface RemittanceListProps {}

const StyledRemittanceTable = styled(Box)`
    @media ${min(768)} {
      tbody tr td  {
        @media (hover: hover) {
          &:hover {
            cursor: pointer;
          }
        }
      }
    }

    @media ${max(767)} {
      .data__table {
        &--type { grid-area: type;}
        &--name { grid-area: name;}
        &--businessAccountingAccountAlias { grid-area: alias;}
        &--dueDate {grid-area: total;}
        &--remittanceDocumentsCount {display: none}
        &--amount {grid-area: remittanceDocumentsCount;}
        &--status {grid-area: status;}
      }

      tbody tr {
        align-items: center;
        grid-template-columns: 1fr auto;
        grid-template-areas:
          "name type"
          "alias total"
          "status remittanceDocumentsCount";
      }
    }

    .data__table {
      &--businessAccountingAccountAlias {
        @media ${min(breakpoints.sm)} {
          ${ellipsis}
        }
      }
      &--dueDate,
      &--remittanceDocumentsCount {
        @media ${max(1023)} {
          display: none;
        }
      }
    }
  `;

export const RemittanceList: FC<RemittanceListProps> = () => {
  const [showBigLoading, setShowBigLoading] = useState(true);
  const {
    data,
    pagination,
    sortBy,
    isLoading,
    onPageSizeChange,
    onPageChange,
    setSortBy,
    showZeroState,
  } = useRemittanceListDataTable();
  const history = useHistory();
  const [selection, setSelection] = useState<RemittanceTable[]>([]);
  const actions = useRemittanceListActions();
  const { bulkActions, columns } = useRemittanceListConfig({
    actions,
    selection,
  });
  const items = useMemo((): RemittanceTable[] => {
    return (
      data?.items.map((item: any) => ({
        ...item,
      })) || []
    );
  }, [data]);

  useEffect(() => {
    setShowBigLoading(false);
  }, [data, setShowBigLoading, isLoading]);

  if (showZeroState) {
    return <RemittancesZeroState />;
  }

  if (isLoading && showBigLoading) {
    return (
      <Box position="relative" height="440px">
        <Spinner />
      </Box>
    );
  }

  return (
    <StyledRemittanceTable>
      <DataTable<RemittanceTable, keyof RemittanceTable>
        multiple={true}
        keyName="id"
        columns={columns}
        actions={bulkActions}
        data={items}
        page={pagination.page}
        pageSize={pagination.pageSize}
        onSelectionChange={setSelection}
        totalCount={data?.count || 0}
        sortBy={sortBy}
        loading={isLoading}
        onPageSizeChange={onPageSizeChange}
        onPageChange={onPageChange}
        onSortChange={setSortBy}
        data-testid="remittances-list"
        onSelect={(d) =>
          history.push({
            pathname: `/payments/remittances/${d.id}/detail`,
          })
        }
      />
    </StyledRemittanceTable>
  );
};
