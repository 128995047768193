import { getConfig } from 'modules/App/config';
import request from 'utils/request';

import { BusinessSegmentationInfo } from '../models/businessSegmentationInfo';

const fetchCurrentBusinessesAnalytics =
  async (): Promise<BusinessSegmentationInfo> => {
    const endpoint = (await getConfig()).apiBusinessesService;
    const response = await request<{
      data: BusinessSegmentationInfo;
    }>(`${endpoint}/current/analytics`, {
      method: 'GET',
    });
    return response.data.data;
  };

export default fetchCurrentBusinessesAnalytics;
