import { FC } from 'react';
import { Hidden } from 'react-grid-system';

import { space, SpaceProps } from 'styled-system';

import useTranslations from 'modules/I18n/hooks/useTranslations';
import styled from 'modules/Theme/styled-components';
import history from 'utils/history';

import Box from '../Box';
import ButtonBack from '../ButtonBack';
import SectionTitle from '../SectionTitle';
import messages from './messages';

interface ButtonBackProps extends SpaceProps {
  onClick?: () => void;
  title: string;
  hiddenBackButton?: boolean;
}

const Wrapper = styled(Box)`
  ${space}
`;

const HeadingWithBack: FC<ButtonBackProps> = ({
  onClick = history.goBack,
  title,
  marginBottom = '28px',
  hiddenBackButton,
  ...rest
}) => {
  const { t } = useTranslations();
  return (
    <Wrapper
      gridTemplateColumns={!hiddenBackButton && '48px 1fr'}
      display={!hiddenBackButton && 'grid'}
      marginBottom={marginBottom}
      {...rest}
    >
      {!hiddenBackButton && (
        <Hidden xs>
          <Box placeSelf="center center">
            <ButtonBack
              onClick={onClick}
              accessibleText={t(messages.buttonBack)}
            />
          </Box>
        </Hidden>
      )}
      <SectionTitle marginBottom="0">{title}</SectionTitle>
    </Wrapper>
  );
};

export default HeadingWithBack;
