import { FC } from 'react';
import { Hidden, Visible } from 'react-grid-system';

import useTranslations from 'modules/I18n/hooks/useTranslations';
import { Coupon } from 'modules/Subscriptions/Create/types';
import { Plan, PAYMENT_PERIODS } from 'modules/Subscriptions/models';
import useTheme from 'modules/Theme/hooks/useTheme';
import { Box } from 'modules/Ui';

import { useTotalSubscriptionCalcs } from '../../hooks';
import { resume as texts } from '../messages';
import ExpandedPaymentResume from './ExpandedPaymentResume';

interface Props {
  coupon?: Coupon;
  currentStep: number;
  marketName: string;
  periodicity: string;
  price: number;
  selectedPlan?: Plan;
  setCoupon?: (result?: Coupon) => void;
}

const PaymentResume: FC<Props> = ({
  coupon,
  currentStep,
  marketName,
  periodicity,
  price,
  selectedPlan,
  setCoupon,
}) => {
  const { t } = useTranslations();
  const {
    basePrice,
    priceWithTax,
    tax,
    taxLabel,
    taxLabelReversed,
    totalToPay,
  } = useTotalSubscriptionCalcs(price, coupon);
  const explanationText =
    periodicity === PAYMENT_PERIODS.MONTHLY
      ? texts.monthlyAmount
      : texts.yearlyAmount;
  const theme = useTheme();
  return (
    <Box
      border={{ sm: `1px solid ${theme.colors.primary200}` }}
      borderRadius={{ sm: '8px' }}
      display={{ sm: 'flex', md: 'block' }}
      flexWrap={{ sm: 'wrap' }}
      justifyContent={{ sm: 'space-between' }}
      marginBottom={{ sm: '24px', md: '0' }}
      padding={{
        _: '24px 16px 0 16px',
        sm: '22px 32px',
        md: '25px 28px 24px 16px',
      }}
    >
      {currentStep === 0 && (
        <>
          <ExpandedPaymentResume
            {...{
              basePrice,
              coupon,
              currentStep,
              marketName,
              periodicity,
              price,
              priceWithTax,
              selectedPlan,
              setCoupon,
              tax,
              taxLabelReversed,
              title: t(texts.paymentResumeTitle),
              totalToPay,
            }}
          />
        </>
      )}
      {currentStep === 1 && (
        <>
          <Visible xs>
            <Box
              borderBottom={`4px solid ${theme.colors.brand500}`}
              color="brand500"
              display="flex"
              fontSize={20}
              fontWeight="600"
              justifyContent="space-between"
              lineHeight={26}
            >
              <Box tag="dt">{marketName}</Box>
              <Box tag="dd">
                <h4>
                  {price} {t(explanationText)}
                </h4>
              </Box>
            </Box>
          </Visible>
          <Hidden xs>
            <ExpandedPaymentResume
              title={t(texts.paymentResumeTitle)}
              {...{
                basePrice,
                coupon,
                currentStep,
                marketName,
                periodicity,
                price,
                priceWithTax,
                selectedPlan,
                setCoupon,
                tax,
                taxLabel,
                taxLabelReversed,
                totalToPay,
              }}
            />
          </Hidden>
        </>
      )}
    </Box>
  );
};

export default PaymentResume;
