import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'gofre.Expenses.toReview.delete.single.title',
  },
  cancel: {
    id: 'gofre.Expenses.toReview.delete.single.cancel',
  },
  subtitle: {
    id: 'gofre.Expenses.toReview.delete.single.subtitle',
  },
  text: {
    id: 'gofre.Expenses.toReview.delete.single.text',
  },
});
