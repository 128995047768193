import { FC, useCallback } from 'react';

import { Formik } from 'formik';

import {
  AccountingAccount,
  AccountingAccountTypeEnum,
} from 'modules/AccountingAccounts/models';
import useTranslations from 'modules/I18n/hooks/useTranslations';
import { Box, FormikField } from 'modules/Ui';
import MaskedFormikField from 'modules/Ui/Formik/MaskedFormikField';
import removeWhitespaces from 'utils/removeWhitespaces';

import { formText } from '../../messages';
import { ALIAS_MAX_LENGTH } from '../AccountingAccountModal/constants';
import { getValidationSchema } from './validation';

export interface AccountingAccountBankAccountForm {
  type: AccountingAccountTypeEnum.BANK_ACCOUNT;
  alias: string;
  IBAN: string;
  swiftCode?: string;
  id?: string;
}

interface AppBankAccountFormProps {
  handleSubmit: (values: AccountingAccountBankAccountForm) => void;
  account?: AccountingAccount;
  isEditing?: boolean;
}

export const BankAccountForm: FC<AppBankAccountFormProps> = ({
  handleSubmit,
  account,
  isEditing = false,
}) => {
  const { t } = useTranslations();
  const validationSchema = getValidationSchema(t);
  const onSubmit = useCallback(
    (
      values: Pick<
        AccountingAccountBankAccountForm,
        'alias' | 'IBAN' | 'swiftCode'
      >
    ) => {
      handleSubmit({
        type: AccountingAccountTypeEnum.BANK_ACCOUNT,
        ...values,
        IBAN: removeWhitespaces(values.IBAN),
        swiftCode: values.swiftCode
          ? removeWhitespaces(values.swiftCode)
          : undefined,
      });
    },
    []
  );
  return (
    <Formik
      enableReinitialize
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      initialValues={{
        alias: account?.alias ?? '',
        IBAN: account?.IBAN ?? '',
        swiftCode: account?.swiftCode ?? '',
      }}
      validateOnBlur={false}
      validateOnChange={false}
    >
      {(formik) => (
        <Box
          autoComplete="off"
          display={{ sm: 'grid' }}
          gridTemplateColumns={{ sm: '230px 1fr' }}
          id={AccountingAccountTypeEnum.BANK_ACCOUNT}
          marginBottom={!isEditing && { sm: '24px' }}
          noValidate
          onSubmit={formik.handleSubmit}
          tag="form"
        >
          <FormikField
            gridArea={{ sm: '1 / 1 / 2 / 3' }}
            id="alias"
            data-testid="alias"
            label={t(formText.bankAccountAlias)}
            maxLength={ALIAS_MAX_LENGTH}
            name="alias"
            placeholder={t(formText.bankAccountAliasPlaceholder)}
            required
          />
          <MaskedFormikField
            autoTrim
            data-testid="iban-input"
            gridArea={{ sm: '2 / 1 / 3 / 3' }}
            id="IBAN"
            label={t(formText.bankAccountIban)}
            mask="AA11 1111 1111 1111 1111 1111"
            placeholder="ES"
            placeholderChar=" "
            required
          />
          <MaskedFormikField
            autoTrim
            data-testid="swiftCode-input"
            id="swiftCode"
            label={t(formText.bankAccountBic)}
            mask="AAAA AA ## ###"
            placeholder=""
            placeholderChar=" "
          />
        </Box>
      )}
    </Formik>
  );
};
