import {
  API_CONFIGURATION,
  SHOPIFY_CONFIGURATION,
  SHOPIFY_CONFIGURATION_UPDATE,
} from './paths';

export const ROUTES = {
  INTEGRATIONS: '/integrations',
  SHOPIFY: SHOPIFY_CONFIGURATION,
  SHOPIFY_SUCCESS: SHOPIFY_CONFIGURATION_UPDATE,
  API_PUBLIC: API_CONFIGURATION,
};
