import { FC, ReactNode } from 'react';
import { useSelector } from 'react-redux';

import {
  GridProps,
  gridTemplateAreas,
  gridTemplateColumns,
  gridTemplateRows,
  space,
  SpaceProps,
  system,
  layout,
  LayoutProps,
} from 'styled-system';

import { selectIsMobile } from 'modules/App/store/selectors';
import DocumentHr from 'modules/Documents/components/TwoStepsForm/components/DocumentHr';
import styled from 'modules/Theme/styled-components';
import { Box } from 'modules/Ui';

interface Props extends SpaceProps, GridProps, LayoutProps {
  actions?: ReactNode;
  aside?: ReactNode;
  id: string;
  main: ReactNode;
  contain?:
    | 'content'
    | 'layout'
    | 'none'
    | 'paint'
    | 'size'
    | 'strict'
    | 'style';
}

const StyledLayout = styled(Box)<Props>`
  ${gridTemplateAreas}
  ${gridTemplateColumns}
  ${gridTemplateRows}
  ${space}
  ${layout}
  ${system({
    gap: {
      property: 'gap',
      transform: (value) => `${value}`,
    },
  })}
  `;

export const SettingsLayout: FC<Props> = (props: Props) => {
  const { id, actions, aside, main, contain = 'layout', ...rest } = props;
  const isMobile = useSelector(selectIsMobile);
  return (
    <>
      {aside ? (
        <StyledLayout
          data-testid={id}
          display={{ md: 'grid' }}
          gap={{ md: '28px 24px' }}
          gridTemplateAreas={{
            md: "'main aside' 'actions actions'",
          }}
          gridTemplateColumns={{ md: '1fr 366px' }}
          gridTemplateRows={{ md: '1fr 56px' }}
          padding={{ md: '32px' }}
          tag="section"
          {...rest}
        >
          <Box
            contain={{ md: contain }}
            gridArea={{ md: 'aside' }}
            marginBottom={{ _: '24px', sm: '28px', md: '0' }}
            marginTop={!aside ? { _: '16px', sm: '32px' } : undefined}
            tag="aside"
            transform={{ _: 'translateX(-16px)', sm: 'initial' }}
            width={{ _: '100%', sm: 'initial' }}
          >
            {aside}
          </Box>
          <Box
            className="settings__main"
            fadeIn
            gridArea={{ md: 'main' }}
            padding={{ _: '0 16px', sm: 'initial' }}
            tag="article"
          >
            {main}
          </Box>
          <Box
            backgroundColor={{ _: 'gray0', sm: 'initial' }}
            bottom={{ _: '0', sm: 'initial' }}
            gridArea={{ md: 'actions' }}
            position={{ _: 'sticky', sm: 'initial' }}
          >
            <DocumentHr
              fullscreen={!isMobile && true}
              marginBottom={{ sm: '16px' }}
            />
            <Box
              className="settings__actions"
              display={{ sm: 'flex' }}
              justifyContent={{ sm: 'space-between' }}
              padding={{ _: '16px', sm: 'initial' }}
            >
              {actions}
            </Box>
          </Box>
        </StyledLayout>
      ) : (
        <StyledLayout
          data-testid={id}
          padding={{ _: '16px 16px 0', sm: '0' }}
          tag="section"
          {...rest}
        >
          {main}
          {actions && (
            <>
              <Box
                backgroundColor={{ _: 'gray0', sm: 'initial' }}
                bottom={{ _: '0', sm: 'initial' }}
                position={{ _: 'sticky', sm: 'initial' }}
              >
                <DocumentHr fullscreen marginBottom={{ sm: '16px' }} />
                <Box
                  className="settings__actions"
                  display={{ sm: 'flex' }}
                  justifyContent={{ sm: 'space-between' }}
                  padding={{ _: '16px 0', sm: 'initial' }}
                >
                  {actions}
                </Box>
              </Box>
            </>
          )}
        </StyledLayout>
      )}
    </>
  );
};
