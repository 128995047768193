import { useSelector } from 'react-redux';

import { useIsBookkeeping } from 'modules/Bookkeeper/hooks/useIsBookkeeperUser';
import { shouldFill140Document } from 'modules/Documents/components/TwoStepsForm/FirstStep/components/TicketBaiOptions/helpers';
import {
  DigitalDocumentStatus,
  Document,
  DocumentStatus,
  DocumentType,
} from 'modules/Documents/models/document';

import {
  hasElectronicDocumentProvider,
  shouldSendElectronicDocument,
} from '../businessUtils';
import { selectCurrentBusiness } from '../Current/selectors';
import { BaiProviders } from '../models/business';

const useElectronicDocumentProvider = () => {
  const business = useSelector(selectCurrentBusiness);
  const hasElectronicProvider = hasElectronicDocumentProvider(business);
  const provider = business.electronicDocumentProvider;
  const canSendElectronicDocument = shouldSendElectronicDocument(business);
  const isBookkeeping = useIsBookkeeping();
  const shouldFill140 =
    isBookkeeping ||
    shouldFill140Document(
      business.vatNumber,
      business.electronicDocumentProvider
    );

  const canVoidDocument = (document: Document) => {
    const isRejectedNotBizkaia =
      document.digitalDocument?.status === DigitalDocumentStatus.REJECTED &&
      business.electronicDocumentProvider !== BaiProviders.BI;

    const isPresentedOrRejected =
      document.digitalDocument?.status === DigitalDocumentStatus.PRESENTED ||
      isRejectedNotBizkaia;

    const canVoid = canSendElectronicDocument
      ? isPresentedOrRejected
      : hasElectronicProvider;
    return (
      canVoid &&
      document.status === DocumentStatus.ISSUED &&
      document.documentType !== DocumentType.EXPENSE
    );
  };

  return {
    canSendElectronicDocument,
    canVoidDocument,
    hasElectronicProvider,
    shouldFill140,
    isAlava: provider === BaiProviders.VI,
    isBizkaia: provider === BaiProviders.BI,
    isGipuzkoa: provider === BaiProviders.SS,
    provider,
  };
};

export default useElectronicDocumentProvider;
