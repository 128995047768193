import fadein from 'modules/Theme/mixins/fadeIn';
import { css } from 'modules/Theme/styled-components';

const backdrop = () => css`
  &:after {
    animation: ${fadein} 0.3s ease-in;
    background-color: ${(props) => props.theme.colors.backdropDark};
    content: "";
    height: 10vh;
    left: 0;
    position: absolute;
    top: 0;
    transform-origin: left top;
    transform: scale(10);
    width: 10vw;
    will-change: transform;
    z-index: -1;
  }
`;

export default backdrop;
