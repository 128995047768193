import React, { FC, ReactNode, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router';
import { useClickAway } from 'react-use';

import useConfig from 'modules/App/hooks/useConfig';
import useTheme from 'modules/Theme/hooks/useTheme';
import styled from 'modules/Theme/styled-components';
import { Box, Text } from 'modules/Ui';
import { regexMap } from 'modules/Ui/Menu/regex';
import { useUserNotifications } from 'modules/UserNotifications/hooks/useUserNotifications';
import isNativeApp, { getNativeAppVersion } from 'utils/native/isNativeApp';
import { getCurrentUrl, isPublicDomain } from 'utils/url/url.helper';

import { SubmenuSections } from '../constants';
import useIsBusinessBlocked from '../hooks/useIsBusinessBlocked';
import backdrop from '../styles/backdrop';
import BusinessButton from './Components/BusinessButton';
import Logout from './Components/Logout';
import SubMenuHeader from './Components/SubMenu/SubMenuHeader';
import LinksSelector from './LinksSelector';
import { menuSizes } from './menuSizes';
import menuAnimation from './styles/menuAnimation';

interface MenuProps {
  open?: boolean;
  active?: boolean;
  closeMenu: () => void;
  openSubMenu: (section: SubmenuSections) => () => void;
  closeSubmenu: () => void;
  isSubMenuOpen: boolean;
  isBookkeeper?: boolean;
  children?: ReactNode;
}

const MenuWrapper = styled(Box)<MenuProps>`
  ${menuAnimation}
  &:before {
    ${({ open }) => (open ? backdrop(`${menuSizes.MENU_WIDTH}px`) : undefined)};
    top: 0;
  }
`;

const Menu: FC<MenuProps> = ({
  open = false,
  children,
  closeMenu,
  isSubMenuOpen,
  isBookkeeper,
  openSubMenu,
  closeSubmenu,
}) => {
  const location = useLocation();
  const theme = useTheme();
  const { pathname } = location;
  const { config } = useConfig();
  const isBusinessBlocked = useIsBusinessBlocked();
  const {
    isLoading,
    data: notificationResponse,
    refetch,
  } = useUserNotifications();

  const showNotifications = !isLoading && notificationResponse;

  useEffect(() => {
    refetch();
  }, [refetch]);

  const appVersion = useMemo(
    () =>
      isNativeApp() && !!getNativeAppVersion()
        ? `v${getNativeAppVersion()}/${config.version}`
        : `${config.version}`,
    [config.version]
  );

  const ref = React.useRef(null);
  useClickAway(ref, () => {
    open && closeMenu && closeMenu();
  });

  return (
    <MenuWrapper
      ref={ref}
      {...{ open }}
      aria-hidden={open ? undefined : true}
      aria-labelledby="menubutton submenuBack"
      contain={open && 'layout'}
      contentVisibility={!open ? 'hidden' : undefined}
      height="100%"
      id="menu"
      left="0"
      position="fixed"
      tag="nav"
      top={0}
    >
      <SubMenuHeader onClick={closeSubmenu} open={isSubMenuOpen} />
      <Box
        aria-hidden={isSubMenuOpen && 'true'}
        backgroundColor="gray0"
        borderTop={`1px solid ${theme.colors.gray200}`}
        boxSizing="border-box"
        height="100%"
        position="absolute"
        top="0"
        width={`${menuSizes.MENU_WIDTH}px`}
        zIndex={isSubMenuOpen && '-1'}
      >
        <Box display="flex" flexDirection="column" height="100%">
          <BusinessButton
            {...{ openSubMenu }}
            aria-hidden={isSubMenuOpen && 'true'}
            active={regexMap.business.test(pathname)}
            disabled={isBusinessBlocked}
            badgeCount={
              (showNotifications && notificationResponse.unread) || undefined
            }
          />
          <Box
            borderTop={`1px solid ${theme.colors.gray200}`}
            className="mainMenu"
            overflowY="auto"
            flex="1"
            tag="ul"
          >
            <LinksSelector
              openSubMenu={openSubMenu}
              closeMenu={closeMenu}
              {...{ isBookkeeper }}
            />
          </Box>
          <Box mr={2} mb={1} display="flex" justifyContent="flex-end">
            <Text textSize="xxs">{appVersion}</Text>
          </Box>
          <Box mr={2} display="flex" justifyContent="flex-end">
            {isNativeApp() && !isPublicDomain() && (
              <Text textSize="xxs">{getCurrentUrl()}</Text>
            )}
          </Box>
          <Box borderTop={`1px solid ${theme.colors.gray200}`}>
            <Logout />
          </Box>
        </Box>
      </Box>
      {children}
    </MenuWrapper>
  );
};

export default Menu;
