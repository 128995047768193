import { useDispatch, useSelector } from 'react-redux';

import { getBusiness } from 'modules/Business/Current/actions';
import { selectCurrentBusiness } from 'modules/Business/Current/selectors';
import { useCloseModal } from 'modules/Modals';
import { createNotification } from 'modules/Notifications/actions';
import { NotificationType } from 'modules/Notifications/models';
import texts from 'modules/Settings/hooks/useUpdateTemplateConfig/messages';
import { useBlockingMutation } from 'utils/useBlockingMutation';

import updateBillingEmail from '../../services/updateBillingEmail';
import ChangeEmailModal from './ChangeEmailModal';

export default function ChangeEmail() {
  const dispatch = useDispatch();
  const business = useSelector(selectCurrentBusiness);
  const closeModal = useCloseModal();

  const { mutateAsync: changeBillingEmail, isLoading: isUpdating } =
    useBlockingMutation(updateBillingEmail);

  const onChangeBillingEmail = async (billingEmail: string) => {
    try {
      await changeBillingEmail({ billingEmail });

      dispatch(getBusiness.success({ ...business, billingEmail }));

      closeModal();

      dispatch(
        createNotification({
          type: NotificationType.SUCCESS,
          message: texts.updateSuccess.id,
        })
      );
    } catch (e) {
      dispatch(
        createNotification({
          type: NotificationType.ERROR,
          message: texts.updateError.id,
        })
      );
    }
  };

  return (
    <ChangeEmailModal
      {...{
        closeModal,
        onChangeBillingEmail,
        billingEmail: business.billingEmail as string,
        isUpdating,
      }}
    />
  );
}
