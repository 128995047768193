import { ReactNode } from 'react';

import { space, SpaceProps, typography, TypographyProps } from 'styled-system';

import styled from 'modules/Theme/styled-components';

import Text from '../Text';

interface Props extends SpaceProps, TypographyProps {
  children: ReactNode | string;
  id?: string;
}

const StyledTitle = styled(Text)<Props>`
  ${space}
  ${typography}
`;

const SectionTitle: React.FC<Props> = ({ id, children, ...rest }) => (
  <StyledTitle
    color="brand500"
    data-testid={id}
    fontSize={{ _: 24, sm: 28 }}
    fontWeight="600"
    lineHeight={48}
    paddingTop={{ _: '8px', sm: 0 }}
    paddingLeft={{ _: '40px', xs: 0 }}
    tag="h2"
    {...rest}
  >
    {children}
  </StyledTitle>
);

SectionTitle.defaultProps = {
  marginBottom: { sm: '16px' },
};

export default SectionTitle;
