import { useDispatch } from 'react-redux';

import { Dispatch } from 'redux';

import { AnalyticsEvent } from 'modules/Analytics';
import analyticsService from 'modules/Analytics/services/analyticsService';
import { fetchAppData } from 'modules/App/actions';
import { useAuthBusiness } from 'modules/Business/Change/hooks/useAuthBusiness';
import Business from 'modules/Business/models/business';
import useTranslations, {
  TranslationFunction,
} from 'modules/I18n/hooks/useTranslations';
import { createNotification } from 'modules/Notifications/actions';
import { NotificationType } from 'modules/Notifications/models';
import { ROUTES } from 'pages/documents/routesMap';
import requestErrorHandler from 'utils/requestErrorHandler';

import { messages } from './messages';

export default function useBookkeeperChangeBusiness() {
  const authBusiness = useAuthBusiness();
  const dispatch = useDispatch();
  const { t } = useTranslations();

  async function onChangeBusiness(business: Business) {
    if (!business.subscription?.active) {
      handleInactive({ t, dispatch, business });
      return;
    }
    try {
      // This needs to go before auth because identify with logged business will be triggered, losing reference of bookkeeper
      await sendEvent(business);
      await authBusiness(business.id);
      dispatch(
        fetchAppData.request({
          redirectUrl: ROUTES.DOCUMENTS,
        })
      );
    } catch (e: any) {
      handleError(e, dispatch);
    }
  }

  return { onChangeBusiness };
}

function handleInactive(options: {
  t: TranslationFunction;
  dispatch: Dispatch;
  business: Business;
}): void {
  const {
    dispatch,
    t,
    business: { isDummy },
  } = options;
  dispatch(
    createNotification({
      type: NotificationType.ERROR,
      title: t(
        isDummy
          ? messages.noActiveSubscriptionDummyTitle
          : messages.noActiveSubscriptionTitle
      ),
      message: isDummy
        ? messages.noActiveSubscriptionDummy.id
        : messages.noActiveSubscription.id,
    })
  );
}

async function sendEvent(business: Business): Promise<void> {
  await analyticsService.track(AnalyticsEvent.BOOKKEEPER_LOG_INTO_BUSINESS, {
    loggedBusinessId: business.id,
    isDummy: business.isDummy,
  });
}

function handleError(error: any, dispatch: Dispatch) {
  const { responseCode, isNetworkError, networkErrorTranslationKey } =
    requestErrorHandler(error);
  dispatch(
    createNotification({
      type: NotificationType.ERROR,
      message: isNetworkError ? networkErrorTranslationKey : responseCode,
    })
  );
}
