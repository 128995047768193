import useTheme from 'modules/Theme/hooks/useTheme';

import IconByString, { IconByStringProps } from './Icon/IconType/IconByString';

export interface IconScanProps extends IconByStringProps {
  isAnimate?: boolean;
}

const IconScan = (props: IconScanProps) => {
  const { isAnimate, fillColor, ...rest } = props;
  const theme = useTheme();
  return (
    <IconByString {...rest}>
      <path
        clipRule="evenodd"
        d="M4.5 4H9V3H4.5A1.5 1.5 0 0 0 3 4.5V9h1V4.5a.5.5 0 0 1 .5-.5ZM4.5 20H9v1H4.5A1.5 1.5 0 0 1 3 19.5V15h1v4.5a.5.5 0 0 0 .5.5ZM19.5 4H15V3h4.5A1.5 1.5 0 0 1 21 4.5V9h-1V4.5a.5.5 0 0 0-.5-.5ZM19.5 20H15v1h4.5a1.5 1.5 0 0 0 1.5-1.5V15h-1v4.5a.5.5 0 0 1-.5.5Z"
        fillRule="evenodd"
      />
      <rect
        className="rect-animate-path"
        fill={fillColor || theme.colors.brand500}
        height="2"
        width="24"
        x="1"
        y={isAnimate ? '3' : '12'}
      >
        {isAnimate && (
          <animateTransform
            attributeName="transform"
            attributeType="XML"
            begin="1s"
            calcMode="linear"
            dur="1.4s"
            repeatCount="indefinite"
            type="translate"
            values="0,2; 0,14; 0,2;"
          />
        )}
      </rect>
    </IconByString>
  );
};

export default IconScan;
