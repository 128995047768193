import { FC } from 'react';

import { Formik } from 'formik';

import useTranslations from 'modules/I18n/hooks/useTranslations';
import { MODAL_WIDTH_DEFAULT, Dialog, useCloseModal } from 'modules/Modals';
import { Box, Button, Text } from 'modules/Ui';
import MaskedFormikField from 'modules/Ui/Formik/MaskedFormikField';

import { messages as defaultMessages } from './messages';
import { RemittanceContactModalProps } from './types';
import { getValidationSchema } from './validationSchema';

const RemittanceContactModal: FC<RemittanceContactModalProps> = ({
  onSubmit,
  messages,
}) => {
  const { t } = useTranslations();
  const closeModal = useCloseModal();
  const validationSchema = getValidationSchema(t);
  return (
    <Dialog
      id="remittance-contact-iban-modal"
      title={t(defaultMessages.title)}
      onClose={closeModal}
      width={MODAL_WIDTH_DEFAULT}
      buttonPrimary={
        <Button
          data-testid="remittance-contact-iban-modal-submit"
          variant="secondary"
          form="remittance-contact-iban-form"
          type="submit"
        >
          {t(defaultMessages.submit)}
        </Button>
      }
      buttonSecondary={
        <Button
          data-testid="remittance-contact-iban-modal-cancel"
          onClick={closeModal}
          variant="stroke"
        >
          {t(defaultMessages.cancel)}
        </Button>
      }
    >
      <Text lineHeight={22} marginBottom="16px">
        {t(messages?.subtitle || defaultMessages.subtitle)}
      </Text>
      <Formik
        onSubmit={({ contactIBAN }) => {
          onSubmit(contactIBAN);
          closeModal();
        }}
        validateOnChange={false}
        validateOnBlur={false}
        validationSchema={validationSchema}
        initialValues={{
          contactIBAN: '',
        }}
      >
        {(formik) => (
          <Box
            autoComplete="off"
            id="remittance-contact-iban-form"
            noValidate
            onSubmit={formik.handleSubmit}
            tag="form"
          >
            <MaskedFormikField
              autoTrim
              data-testid="iban-input"
              id="contactIBAN"
              label={t(defaultMessages.ibanLabel)}
              mask="AA11 1111 1111 1111 1111 1111"
              placeholder="ES"
              placeholderChar=" "
              required
            />
          </Box>
        )}
      </Formik>
    </Dialog>
  );
};

export default RemittanceContactModal;
