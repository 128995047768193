import useTheme from 'modules/Theme/hooks/useTheme';

import IconByString, { IconByStringProps } from './Icon/IconType/IconByString';

export interface IconAddProps extends IconByStringProps {
  iconCrossColor?: string;
}

const IconAddExpense = (props: IconAddProps) => {
  const { iconCrossColor, fillColor, innerPathColor, ...rest } = props;
  const theme = useTheme();
  return (
    <IconByString {...rest}>
      <>
        <path
          d="M3.176 7H2V4h1.5L5 1h12l1.5 3H20v3h-1.177L16 23H6L3.176 7ZM3 5h16v1H3V5Zm1.192 2 2.647 15h8.322l2.647-15H4.192Zm13.19-3H4.618l1-2h10.764l1 2Z"
          fill={fillColor ? `${fillColor}` : 'currentColor'}
          fillRule="evenodd"
        />
        <path
          d="M9 13a2 2 0 1 0 4 0 2 2 0 0 0-4 0Zm2-3a3 3 0 1 0 0 6 3 3 0 0 0 0-6Z"
          fill={fillColor ? `${fillColor}` : 'currentColor'}
          fillRule="evenodd"
          shapeRendering="geometricPrecision"
        />
        <circle
          cx="18"
          cy="18"
          fill={innerPathColor ? `${innerPathColor}` : theme.colors.brand500}
          r="6"
          shapeRendering="geometricPrecision"
        />
        <path
          d="M18.514 14.513 17.5 14.5v3h-3v1h3v3h1v-3h3v-1h-3v-3l.014.013z"
          fill={iconCrossColor ? `${iconCrossColor}` : theme.colors.gray0}
          shapeRendering="crispEdges"
        />
      </>
    </IconByString>
  );
};

export default IconAddExpense;
