import { defineMessages } from 'react-intl';

const messages = defineMessages({
  goToForm: { id: 'gofre.Notifications.autosaveNotifications.goToForm' },
  quoteFormInProgress: {
    id: 'gofre.Notifications.autosaveNotifications.quoteFormInProgress',
  },
  proformaFormInProgress: {
    id: 'gofre.Notifications.autosaveNotifications.proformaFormInProgress',
  },
  deliveryNoteFormInProgress: {
    id: 'gofre.Notifications.autosaveNotifications.deliveryNoteFormInProgress',
  },
  recurrentExpenseFormInProgress: {
    id: 'gofre.Notifications.autosaveNotifications.recurrentExpenseFormInProgress',
  },
  recurrentFormInProgress: {
    id: 'gofre.Notifications.autosaveNotifications.recurrentFormInProgress',
  },
  documentFormInProgress: {
    id: 'gofre.Notifications.autosaveNotifications.documentFormInProgress',
  },
  uploadExpenseFormInProgress: {
    id: 'gofre.Notifications.autosaveNotifications.uploadExpenseFormInProgress',
  },
  uploadDocumentFormInProgress: {
    id: 'gofre.Notifications.autosaveNotifications.uploadDocumentFormInProgress',
  },
  receiptFormInProgress: {
    id: 'gofre.Notifications.autosaveNotifications.receiptFormInProgress',
  },
});

export default messages;
