import { FC } from 'react';

import { SpaceProps } from 'styled-system';

import Text from '../../Text';

interface DetailTitleProps extends SpaceProps {
  title: string;
}

export const DetailTitlePreviewLayout: FC<DetailTitleProps> = ({
  title,
  marginBottom,
}) => {
  return (
    <Text
      color="gray800"
      fontSize={18}
      fontWeight="600"
      lineHeight={{ _: 22, sm: 20 }}
      marginBottom={marginBottom ?? { _: '8px', sm: '20px' }}
      tag="h3"
    >
      {title}
    </Text>
  );
};
