import React from 'react';
import { Hidden, Visible } from 'react-grid-system';

import useTranslations from 'modules/I18n/hooks/useTranslations';
import { Dialog } from 'modules/Modals';
import { Button } from 'modules/Ui';

import { FilterExpandable } from '../Filter';
import { dataTableMessages } from './messages';

interface DataTableFiltersWrapperProps {
  modalTitle: string;
  open?: boolean;
  closeFilters: () => void;
  id: string;
}

const DataTableFiltersWrapper: React.FC<DataTableFiltersWrapperProps> = ({
  children,
  modalTitle,
  open,
  closeFilters,
  id,
}) => {
  const { t } = useTranslations();
  return (
    <>
      <Hidden xs sm>
        <FilterExpandable
          open={!!open}
          buttonId={`${id}-button`}
          id="filter-expandable"
        >
          {open ? children : null}
        </FilterExpandable>
      </Hidden>
      {open && (
        <Visible xs sm>
          <Dialog
            type="fullScreen"
            id="filter-modal"
            data-testid="filter-modal"
            onClose={closeFilters}
            title={modalTitle}
            content={children}
            buttonPrimary={
              <Button
                data-testid="filter-submit"
                form="filter-form"
                type="submit"
                variant="secondary"
              >
                {t(dataTableMessages.filtersButtonMobile)}
              </Button>
            }
          />
        </Visible>
      )}
    </>
  );
};

export default DataTableFiltersWrapper;
