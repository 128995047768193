import { defineMessages } from 'react-intl';

export const formText = defineMessages({
  mandatory: { id: 'gofre.Form.field.mandatory' },
  mandatoryFields: {
    id: 'gofre.Form.mandatory',
  },
  required: {
    id: 'gofre.Form.field.required',
  },
  maxAmount: {
    id: 'gofre.Form.field.maxAmount',
  },
  recaptchaRequired: { id: 'gofre.Documents.Send.error.recaptcha' },
});
