import { useQuery } from 'react-query';

import { PAYMENT_METHODS } from 'modules/App/queries/queries';
import fetchPaymentMethods from 'modules/PaymentMethods/services/fetchPaymentMethods';

function useDefaultPaymentMethods() {
  const {
    data: paymentMethods,
    isLoading,
    refetch,
  } = useQuery(PAYMENT_METHODS, fetchPaymentMethods);

  return {
    paymentMethods: paymentMethods?.items,
    isLoading,
    refetch,
  };
}

export default useDefaultPaymentMethods;
