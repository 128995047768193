import IconByPath, { IconByPathProps } from './Icon/IconType/IconByPath';

const IconUpdate = (props: IconByPathProps) => (
  <IconByPath
    {...props}
    path="m13.778 4.85-3.535-3.535-.707.707L12.514 5H3.5A1.5 1.5 0 0 0 2 6.5v11A1.5 1.5 0 0 0 3.5 19H8v-1H3.5a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5h9.129L9.536 9.093l.707.707 4.242-4.243-.707-.707Zm7.207 12.65a.5.5 0 0 1-.5.5h-8.77l2.992-2.993L14 14.3l-4.243 4.243.707.707L14 22.785l.707-.707L11.63 19h8.856a1.5 1.5 0 0 0 1.5-1.5v-11a1.5 1.5 0 0 0-1.5-1.5h-4.5v1h4.5a.5.5 0 0 1 .5.5v11Z"
  />
);

export default IconUpdate;
