import * as Yup from 'yup';

import { formText } from 'modules/Forms/messages';
import { TranslationFunction } from 'modules/I18n/hooks/useTranslations';

import { formErrors } from './messages';

const getSchema = (t: TranslationFunction) => {
  return Yup.object().shape({
    vatNumber: Yup.string()
      .required(t(formText.required))
      .validateNifRules51to53(t(formErrors.vatNumberInvalid), true),
  });
};

export default getSchema;
