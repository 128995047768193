import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';

import { setIsBodyScrollable } from '../store/actions';
import { selectIsBodyScrollable } from '../store/selectors';

function useSetScrollableBody() {
  const dispatch = useDispatch();
  const location = useLocation();
  const isBodyScrollable = useSelector(selectIsBodyScrollable);

  useEffect(() => {
    dispatch(setIsBodyScrollable(true));
  }, [location]);

  useEffect(() => {
    document.body.style.overflow = isBodyScrollable ? 'unset' : 'hidden';
    document.body.style.height = isBodyScrollable ? 'auto' : '100%';
  }, [isBodyScrollable]);
}

export default useSetScrollableBody;
