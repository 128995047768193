import { call, takeLatest, put } from 'redux-saga/effects';

import { setLocale } from 'modules/I18n/actions';
import { createNotification } from 'modules/Notifications/actions';
import { NotificationType } from 'modules/Notifications/models';
import requestErrorHandler from 'utils/requestErrorHandler';

import * as actions from './actions';
import { GET_USER_REQUEST } from './constants';
import { User } from './models/user';
import fetchMe from './services/fetchMe';

export function* getUserSaga() {
  try {
    const result: User = yield call(fetchMe);
    yield put(actions.getUser.success(result));
    if (result.language) {
      yield put(setLocale(result.language));
    }
  } catch (e: any) {
    yield put(actions.getUser.failure(e));
    const { responseMessage, isNetworkError, networkErrorTranslationKey } =
      requestErrorHandler(e);

    yield put(
      createNotification({
        type: NotificationType.ERROR,
        message: isNetworkError ? networkErrorTranslationKey : responseMessage,
      })
    );
  }
}

export default function* userSaga() {
  yield takeLatest(GET_USER_REQUEST, getUserSaga);
}
