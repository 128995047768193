import { DocumentFilters, DocumentKind } from '../models/document';
import { ExpenseTab } from '../models/expenses';

export type DocumentKindQueryParamsState = {
  [key in DocumentKind]?: DocumentFilters;
};

export type QuoteQueryParamsState = {
  [key in DocumentKind]?: DocumentFilters;
};

export type ExpensesQueryParamsState = {
  [key in ExpenseTab]?: DocumentFilters;
};

export const isDocumentQueryParamsState = (
  queryParamState:
    | DocumentKindQueryParamsState
    | ExpensesQueryParamsState
    | QuoteQueryParamsState
): queryParamState is DocumentKindQueryParamsState => {
  for (const key in DocumentKind) {
    if (key in queryParamState) {
      return true;
    }
  }
  return false;
};

export const isExpenseQueryParamsState = (
  queryParamState:
    | DocumentKindQueryParamsState
    | ExpensesQueryParamsState
    | QuoteQueryParamsState
): queryParamState is ExpensesQueryParamsState => {
  for (const key in ExpenseTab) {
    if (key in queryParamState) {
      return true;
    }
  }
  return false;
};
