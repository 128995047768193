import IconByPath, { IconByPathProps } from './Icon/IconType/IconByPath';

const PictogramStatistics = (props: IconByPathProps) => (
  <IconByPath
    clipRule="evenodd"
    fillRule="evenodd"
    stroke="none"
    path="M2 2h40v31h-4V18h-2v15h-3V21h-2v12h-3v-9h-2v9h-3V23h-2v10h-3v-7h-2v7h-3v-3h-2v3H8v-4H6v4H2V2ZM0 2a2 2 0 0 1 2-2h40a2 2 0 0 1 2 2v31a2 2 0 0 1-2 2H29l4 12h-2l-4-12h-9l-4 12h-2l4-12H2a2 2 0 0 1-2-2V2Zm9 4.17A3.001 3.001 0 1 0 12.83 10H9V6.17Zm2 0V8h1.83A3.008 3.008 0 0 0 11 6.17ZM5 9a5 5 0 1 1 10 0A5 5 0 0 1 5 9Zm29.586-1H31V6h7v7h-2V9.414l-11 11-3-3-8.5 8.5-3-3-3.793 3.793-1.414-1.414 5.207-5.207 3 3 8.5-8.5 3 3L34.586 8Z"
    strokeWidth="1"
    viewBox="0 0 44 47"
    {...props}
  />
);

export default PictogramStatistics;
