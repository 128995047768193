import { FC, useCallback } from 'react';
import { Hidden } from 'react-grid-system';
import { useHistory } from 'react-router';

import { Box, Button } from 'modules/Ui';

interface Props {
  id?: string;
  cancel: string;
  submit: string;
  onCancel?: () => void;
}

export const IntegrationActions: FC<Props> = (props: Props) => {
  const { id, submit, cancel, onCancel } = props;
  const history = useHistory();
  const handleNavigation = useCallback(() => {
    (onCancel && onCancel()) || history.push('/');
  }, [history]);
  return (
    <Box
      display={{ sm: 'flex' }}
      justifyContent={{ sm: 'space-between' }}
      tag="ul"
      width={{ sm: '100%' }}
    >
      <Hidden xs>
        <Box tag="li">
          <Button onClick={handleNavigation} variant="stroke">
            {cancel}
          </Button>
        </Box>
      </Hidden>
      <Box tag="li">
        <Button
          form={id}
          type="submit"
          variant="secondary"
          width={{ _: '100%', sm: 'auto' }}
        >
          {submit}
        </Button>
      </Box>
    </Box>
  );
};
