import { css } from 'modules/Theme/styled-components';

const multilineEllipsis = (LinesNumber: number | string) => css`
  -webkit-box-orient: vertical;
  -webkit-line-clamp: ${LinesNumber};
  display: block;
  display: -webkit-box;
  height: calc(var(--text-line-height) * ${LinesNumber});
  overflow: hidden;
  text-overflow: ellipsis;
`;

export default multilineEllipsis;
