/* eslint-disable import/no-duplicates */
import {
  addMonths,
  format as formatFns,
  formatISO as formatISOFns,
  parse,
  formatRelative,
} from 'date-fns';
import { es } from 'date-fns/locale';

// TO DO => Support different locale

export const DATE_TIME_FORMAT = "dd/MM/yyyy HH:mm'h'";
const DEFAULT_TIME_FORMAT = 'dd/MM/yyyy';
// ISO date string or Date object
const formatDate = (
  date?: Date | string,
  format: string = DEFAULT_TIME_FORMAT
) => {
  if (!date) return '';

  if (typeof date === 'string') {
    return formatFns(new Date(date), format, {
      locale: es,
    });
  }
  return formatFns(date, format);
};

export const formatDateAndTime = (
  date?: Date | string,
  format: string = DATE_TIME_FORMAT
) => {
  return formatDate(date, format);
};

export const formatDateinXMonths = (
  date?: Date,
  months?: number,
  format: string = DEFAULT_TIME_FORMAT
) => {
  if (!date || !months) return '';

  return formatDate(addMonths(date, months), format);
};

export const formatISO = (date?: Date | string): string => {
  if (!date) {
    return '';
  }
  const localdate =
    date instanceof Date
      ? date
      : parse(date, 'yyyy-MM-dd', new Date(Date.now()));

  return formatISOFns(localdate);
};

export const formatRelativeDate = (date?: Date | string) => {
  if (!date) return '';
  const today = new Date();

  if (typeof date === 'string') {
    return formatRelative(new Date(date), today, { locale: es });
  }
  return formatRelative(date, today, { locale: es });
};

export default formatDate;
