import { defineMessages } from 'react-intl';

const bannerBai = defineMessages({
  title: { id: 'gofre.Documents.BannerBai.title' },
  mobileText: { id: 'gofre.Documents.Mobile.BannerBai.text' },
  connected: { id: 'gofre.Documents.BannerBai.Connected' },
  connectedForReal: { id: 'gofre.Documents.BannerBai.ConnectedForReal' },
  notConnected: {
    id: 'gofre.Documents.BannerBai.NotConnected',
  },
  connectedMobile: { id: 'gofre.Documents.Mobile.BannerBai.Connected' },
  notConnectedMobile: {
    id: 'gofre.Documents.Mobile.BannerBai.NotConnected',
  },
  close: { id: 'gofre.Documents.BannerBai.close' },
});

export default bannerBai;
