import { FC } from 'react';

import { useCloseModal } from 'modules/Modals';

import CreatePlanErrorDialog from './CreatePlanErrorDialog';
import { CreatePlanErrorProps } from './CreatePlanErrorProps';

const CreatePlanErrorModal: FC<CreatePlanErrorProps> = ({
  failedScopes,
  warningScopes,
  productId,
  error,
}) => {
  const onClose = useCloseModal();

  return (
    <CreatePlanErrorDialog
      {...{
        onClose,
        failedScopes,
        warningScopes,
        selected: productId,
        error,
      }}
    />
  );
};

export default CreatePlanErrorModal;
