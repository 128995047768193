import { FC } from 'react';

import ListDesktopItem from './ListDesktopItem';

export interface ItemDesktop {
  id: string;
  column1?: string | JSX.Element;
  column2?: string | JSX.Element;
  column3?: string | JSX.Element;
  column4?: string | JSX.Element;
}

interface ListDesktopProps {
  items: ItemDesktop[];
  onChange(id?: string): void;
  selectedItemId?: string;
  resetStyles?: boolean;
}

const ListDesktop: FC<ListDesktopProps> = ({
  items,
  onChange,
  selectedItemId,
  resetStyles,
}) => {
  return (
    <>
      {items.map(({ id, column1, column2, column3, column4 }) => {
        return (
          <ListDesktopItem
            resetStyles={resetStyles}
            key={id}
            {...{
              checked: selectedItemId === id,
              column1,
              column2,
              column3,
              column4,
              id,
              onChange: () => onChange(id),
              value: selectedItemId,
            }}
          />
        );
      })}
    </>
  );
};

export default ListDesktop;
