import { useField } from 'formik';

import PasswordField from 'modules/Ui/Form/PasswordField';

import { FormikFieldProps } from './FormikFieldProps';
import useFocusHandler from './useFocusHandler';

const PasswordFormikField = (props: FormikFieldProps) => {
  const {
    helpText,
    hidden,
    id,
    label = '',
    name,
    margin,
    placeholder,
    required,
    width,
    ...rest
  } = props;

  const [field, meta] = useField(name || id);

  const onFocusHandler = useFocusHandler(name || id);

  return hidden ? null : (
    <PasswordField
      {...{
        helpText,
        id,
        label,
        margin,
        placeholder,
        required,
        width,
        ...rest,
      }}
      {...field}
      error={meta.touched && meta.error ? meta.error : undefined}
      name={name || id}
      onFocus={onFocusHandler}
    />
  );
};

export default PasswordFormikField;
