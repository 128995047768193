import { useEffect, FC, useState } from 'react';

import { dot, pick } from 'dot-object';
import { useFormikContext, FormikErrors } from 'formik';
import { isEmpty, keys, omitBy, isUndefined } from 'lodash';

import useNotifications from 'modules/Notifications/hooks/useNotifications';

import messages from './messages';

interface ErrorListenerProps {
  onError?: (errors: FormikErrors<any>) => void;
  scrollToError?: boolean;
  notifyErrors?: boolean;
  // TODO: investigate when and error with string[] is thrown
  errorMessage?: string | string[];
}

const extractErrorNames = (errors: FormikErrors<any>) => {
  return keys(omitBy(dot(errors), (obj) => isUndefined(obj) || isEmpty(obj)));
};

const findErrorInput = (errorName: string) => {
  const input = document.getElementsByName(errorName)[0];

  if (input && input.getAttribute('type') !== 'hidden') {
    return input;
  }
  // React-Select input is hidden and won't scroll to it so we need its wrapper div
  return document.getElementById(errorName);
};

const ErrorListener: FC<ErrorListenerProps> = ({
  onError,
  scrollToError = true,
  notifyErrors = true,
  errorMessage = messages.hasError.id,
}) => {
  const [postValidate, setPostValidate] = useState(false);
  const formik = useFormikContext();
  const notifications = useNotifications();

  useEffect(() => {
    if (formik.isValidating) {
      formik.setErrors({}); // Flush errors
      setPostValidate(true);
    }
  }, [formik.isValidating]);

  useEffect(() => {
    if (!isEmpty(formik.errors) && postValidate && formik.isSubmitting) {
      const errorNames = extractErrorNames(formik.errors);

      if (!isEmpty(errorNames)) {
        const firstErrorName = keys(omitBy(dot(formik.errors), isUndefined))[0];
        const firstError = pick(firstErrorName, formik.errors);
        const firstErrorInput = findErrorInput(firstErrorName);
        if (firstErrorInput && scrollToError) {
          setTimeout(
            () =>
              firstErrorInput.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
              }),
            50
          );
        }
        if (typeof firstError === 'string') {
          if (notifyErrors) {
            notifications.error(errorMessage, { ttl: 3000 });
          }
          if (onError) {
            onError(formik.errors);
          }
        }
      }
      setPostValidate(false);
    }
  }, [postValidate, formik.errors, formik.isValidating]);

  return null;
};

export default ErrorListener;
