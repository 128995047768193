import { Visible } from 'react-grid-system';

import useTranslations from 'modules/I18n/hooks/useTranslations';
import Button from 'modules/Ui/Form/Button';

import messages from '../messages';

interface Props {
  onCancel(): void;
}

const PaymentFormFooter: React.FC<Props> = ({ onCancel }) => {
  const { t } = useTranslations();
  return (
    <>
      <Visible md lg xl xxl>
        <Button
          marginBottom={{ _: '16px', sm: '0' }}
          form="payment-options-form"
          onClick={onCancel}
          variant="stroke"
        >
          {t(messages.step1Cancel)}
        </Button>
      </Visible>
      <Button
        data-testid="submit"
        form="payment-options-form"
        type="submit"
        variant="secondary"
      >
        {t(messages.step1Continue)}
      </Button>
    </>
  );
};

export default PaymentFormFooter;
