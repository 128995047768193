import createAccountingAccount, {
  invalidateQueries,
} from 'modules/AccountingAccounts/services/createAccountingAccount'; // invalidateQueries,
import { useInvalidateAndUpdate } from 'modules/App/hooks/useInvalidateAndUpdate';
import useNotifications from 'modules/Notifications/hooks/useNotifications';
import { ResponseError } from 'types/response-error.type';
import requestErrorHandler from 'utils/requestErrorHandler';
import { useBlockingMutation } from 'utils/useBlockingMutation';

import { AccountingAccount } from '../models';
import messages from './messages';

function useCreateAccountingAccount(
  callback?: (response: AccountingAccount) => void
) {
  const notifications = useNotifications();
  const invalidateAndUpdate = useInvalidateAndUpdate();
  const { mutate, mutateAsync } = useBlockingMutation(createAccountingAccount, {
    onSuccess: async (response): Promise<AccountingAccount> => {
      invalidateAndUpdate({ invalidateQueries });
      callback?.(response.data);
      return response.data;
    },
    onError: (error: ResponseError) => {
      const { isNetworkError, networkErrorTranslationKey } =
        requestErrorHandler(error);
      return notifications.error(
        isNetworkError ? networkErrorTranslationKey : messages.createError.id
      );
    },
  });
  return { mutate, mutateAsync };
}

export default useCreateAccountingAccount;
