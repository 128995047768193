import { retryStrategy } from 'modules/Auth/request-auth-refresh';
import { setRequestRetryStrategy } from 'modules/Auth/request-strategy';
import featureFlags from 'modules/FeatureFlags';
import initFeatureFlags from 'modules/FeatureFlags/initFeatureFlags';
import theme from 'modules/Theme';
import { getStripe } from 'utils/getStripe';

import { AppConfig } from './config';

export default function initApp(config: AppConfig) {
  const stripe = getStripe(config.stripeApiKey);

  // All your app initialization tasks here
  setRequestRetryStrategy(retryStrategy);

  initFeatureFlags();

  setTimeout(() => {
    const excludedEnv = ['staging', 'production'];
    if (!config.environment || excludedEnv.includes(config.environment)) {
      return;
    }
    const apiDomainFF = (
      (featureFlags.getFlag('apiDomain') as string) || ''
    ).trim();

    if (apiDomainFF !== '') {
      document.cookie = `featureFlags=apiDomain=${apiDomainFF}; path=/`;
      addEnvironmentBanner(apiDomainFF);
    } else {
      document.cookie = `featureFlags=apiDomain=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
      config.environment === 'support' && addEnvironmentBanner('SUPPORT');
    }
  }, 1000);

  return { config, stripe };
}

function addEnvironmentBanner(text: string): void {
  const div = createBanner(text);
  const a = createLink();
  div.appendChild(a);
  document.getElementsByTagName('body')[0].appendChild(div);
}

function createBanner(text: string): HTMLDivElement {
  const div = document.createElement('div');
  div.innerHTML = `Entorno: ${text}`;
  div.style.position = 'fixed';
  div.style.top = '0';
  div.style.right = '2%';
  div.style.textAlign = 'center';
  div.style.backgroundColor = '#6599ff1a';
  div.style.color = theme.colors.brand400;
  div.style.zIndex = '9999';
  div.style.fontSize = '12px';
  return div;
}

function createLink(): HTMLAnchorElement {
  const a = document.createElement('a');
  a.href = `${document.location.protocol}//${document.location.host}${document.location.pathname}`;
  a.onclick = () => {
    document.cookie = `featureFlags=apiDomain=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
  };
  a.innerHTML = ' ❌';
  return a;
}
