import { FC } from 'react';

import useTranslations from 'modules/I18n/hooks/useTranslations';

import SmallPill from '../SmallPill';
import messages from './messages';

const PillNew: FC = () => {
  const { t } = useTranslations();
  return (
    <SmallPill
      backgroundColor="confirmation"
      borderColor="confirmation"
      color="gray0"
      padding="2px 6px"
    >
      {t(messages.text)}
    </SmallPill>
  );
};

export default PillNew;
