import * as Yup from 'yup';

import { TranslationFunction } from 'modules/I18n/hooks/useTranslations';

import { formText } from '../../messages';
import {
  ALIAS_MIN_LENGTH,
  ALIAS_MAX_LENGTH,
} from '../AccountingAccountModal/constants';

export const getValidationSchema = (t: TranslationFunction) =>
  Yup.object().shape(
    {
      alias: Yup.string()
        .required(t(formText.mandatory))
        .min(ALIAS_MIN_LENGTH, t(formText.aliasFormatValidation))
        .max(ALIAS_MAX_LENGTH, t(formText.aliasFormatValidationMax)),
      IBAN: Yup.string()
        .required(t(formText.mandatory))
        .ibanFormat(t(formText.format)),
      swiftCode: Yup.string().when('swiftCode', (val) => {
        if (val?.length > 0) {
          return Yup.string().swiftFormat(t(formText.format));
        }
        return Yup.string().notRequired();
      }),
    },
    [['swiftCode', 'swiftCode']]
  );
