import accesibleHide from 'modules/Theme/mixins/accessibleHidden';
import styled from 'modules/Theme/styled-components';
import Input from 'modules/Ui/Html/Input';

import Radio from './Radio';
import RadioLabelText from './RadioLabelText';

const RadioInput = styled(Input)`
  ${accesibleHide}

  &:checked {
    ~ ${RadioLabelText} {
      color: ${(props) => props.theme.colors.gray800};
    }

    &:disabled ~ ${Radio} {
      &:after {
        background-color: ${(props) => props.theme.colors.primary200};
      }
    }
  }

  &:focus {
    ~ ${RadioLabelText} {
      color: ${(props) => props.theme.colors.gray800};
    }
  }

  &:disabled {
    ~ ${RadioLabelText} {
      color: ${(props) => props.theme.colors.gray400};
    }

    ~ ${Radio} {
        background-color: ${(props) => props.theme.colors.auxiliary};
    }
  }

  &.error {
    ~ ${RadioLabelText} {
      color: ${(props) => props.theme.colors.error};
    }

    ~ ${Radio} {
      border-color: ${(props) => props.theme.colors.error};
    }
  }
`;

export default RadioInput;
