import { FC } from 'react';

import useTranslations from 'modules/I18n/hooks/useTranslations';
import useTheme from 'modules/Theme/hooks/useTheme';
import { colorOpacity } from 'modules/Theme/mixins';
import { Box, Text } from 'modules/Ui';

import messages from '../../../messages';
import { IncludedItem } from './IncludedItem';

interface IncludedProps {
  plural: boolean;
}

export const Included: FC<IncludedProps> = ({ plural }) => {
  const { t } = useTranslations();
  const theme = useTheme();
  const items = [
    {
      text: t(messages.customerSupport),
      description: t(messages.customerSupportDesc),
    },
    {
      text: t(messages.security),
      description: t(messages.securityDesc),
    },
    {
      text: t(messages.mobile),
      description: t(messages.mobileDesc),
    },
    {
      text: t(messages.invoices),
      description: t(messages.invoicesDesc),
    },
    {
      text: t(messages.products),
      description: t(messages.productsDesc),
    },
    {
      text: t(messages.tbai),
      description: t(messages.tbaiDesc),
    },
    {
      text: t(messages.expenses),
      description: t(messages.expensesDesc),
    },
    {
      text: t(messages.agenda),
      description: t(messages.agendaDesc),
    },
    {
      text: t(messages.accountant),
      description: t(messages.accountantDesc),
    },
  ];
  return (
    <Box
      borderBottom={`1px solid ${theme.colors.gray200}`}
      marginBottom="48px"
      paddingBottom="48px"
      tag="article"
    >
      <Text
        color="gray800"
        fontSize={24}
        lineHeight={28}
        fontWeight="600"
        margin={{ _: '0 16px 24px', sm: '0 0 24px' }}
        tag="h3"
      >
        {t(messages.allPlansIncludes, { plural })}
      </Text>
      <Box
        backgroundColor={colorOpacity(theme.colors.primary100, 27)}
        columnGap={{ sm: '44px', md: '92px' }}
        display="grid"
        gridTemplateColumns={{ sm: 'repeat(2, 1fr)', md: 'repeat(3, 1fr)' }}
        padding={{
          _: '32px 8px',
          sm: '40px 24px',
          md: '56px 32px',
        }}
        rowGap="24px"
        tag="ul"
      >
        {items.map((item) => (
          <IncludedItem key={item.text} {...item} />
        ))}
      </Box>
    </Box>
  );
};
