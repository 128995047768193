import IconByPath, { IconByPathProps } from './Icon/IconType/IconByPath';

const IconArrowSecondary = (props: IconByPathProps) => (
  <IconByPath
    {...props}
    path="m12.91 2.667-1.46 1.439 6.005 6.004H1.904v2.083h15.55l-6.004 5.985 1.458 1.458 8.485-8.484-8.485-8.485Z"
  />
);

export default IconArrowSecondary;
