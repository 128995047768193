import { FC } from 'react';

import { tabs, messages } from 'modules/AccountingAccounts/messages';
import { AccountingAccountTypeEnum } from 'modules/AccountingAccounts/models';
import useTranslations from 'modules/I18n/hooks/useTranslations';
import useTheme from 'modules/Theme/hooks/useTheme';
import { Tabs, Text } from 'modules/Ui';
import TabPanel from 'modules/Ui/Tabs/Components/TabPanel';

import {
  BankAccountForm,
  AccountingAccountBankAccountForm,
} from '../../BankAccountForm';
import {
  CashRegisterForm,
  AccountingAccountCashRegisterForm,
} from '../../CashRegisterForm';
import {
  CreditCardForm,
  AccountingAccountCreditCardForm,
} from '../../CreditCardForm';
import { Tab } from './Tab';

export type AccountingAccountForm =
  | AccountingAccountBankAccountForm
  | AccountingAccountCashRegisterForm
  | AccountingAccountCreditCardForm;

interface Props {
  value: AccountingAccountTypeEnum;
  onChange: (event: any, value: AccountingAccountTypeEnum) => void;
  onSubmit: (accountingAccount: AccountingAccountForm) => void;
  allowedTabs?: AccountingAccountTypeEnum[];
}

export const AddAccountTabs: FC<Props> = ({
  value,
  onChange,
  onSubmit,
  allowedTabs = [
    AccountingAccountTypeEnum.BANK_ACCOUNT,
    AccountingAccountTypeEnum.CREDIT_CARD,
    AccountingAccountTypeEnum.CASH_REGISTER,
  ],
}) => {
  const theme = useTheme();

  if (!allowedTabs.includes(value)) {
    onChange(null, allowedTabs[0]);
  }
  const tabsAllowedNumber = allowedTabs.length;

  const { t } = useTranslations();
  return (
    <>
      {tabsAllowedNumber > 1 && (
        <>
          <Text
            color="gray800"
            fontSize={16}
            lineHeight={26}
            marginBottom={{ _: '24px', sm: '8px' }}
          >
            {t(messages.subtitle)}
          </Text>
          <Tabs
            display="grid"
            gridTemplateColumns={{ _: '40% 30% 30%', sm: 'repeat(3, 1fr)' }}
            marginBottom="16px"
            onChange={onChange}
            overflow="hidden"
            value={value}
          >
            {allowedTabs.includes(AccountingAccountTypeEnum.BANK_ACCOUNT) && (
              <Tab
                border={`1px solid ${theme.colors.primary200}`}
                borderRight={
                  !allowedTabs.includes(AccountingAccountTypeEnum.CREDIT_CARD)
                    ? `1px solid ${theme.colors.primary200}`
                    : 'none'
                }
                borderRadius="4px 0 0 4px"
                label={t(tabs.tabBankAccount)}
                value={AccountingAccountTypeEnum.BANK_ACCOUNT}
              />
            )}
            {allowedTabs.includes(AccountingAccountTypeEnum.CREDIT_CARD) && (
              <Tab
                border={`1px solid ${theme.colors.primary200}`}
                label={t(tabs.tabCreditCard)}
                value={AccountingAccountTypeEnum.CREDIT_CARD}
              />
            )}
            {allowedTabs.includes(AccountingAccountTypeEnum.CASH_REGISTER) && (
              <Tab
                border={`1px solid ${theme.colors.primary200}`}
                borderLeft={
                  !allowedTabs.includes(AccountingAccountTypeEnum.CREDIT_CARD)
                    ? `1px solid ${theme.colors.primary200}`
                    : 'none'
                }
                borderRadius="0 4px 4px 0"
                label={t(tabs.tabCashRegister)}
                value={AccountingAccountTypeEnum.CASH_REGISTER}
              />
            )}
          </Tabs>
        </>
      )}
      <TabPanel value={value} index={AccountingAccountTypeEnum.BANK_ACCOUNT}>
        <BankAccountForm handleSubmit={onSubmit} />
      </TabPanel>
      <TabPanel value={value} index={AccountingAccountTypeEnum.CREDIT_CARD}>
        <CreditCardForm handleSubmit={onSubmit} />
      </TabPanel>
      <TabPanel value={value} index={AccountingAccountTypeEnum.CASH_REGISTER}>
        <CashRegisterForm handleSubmit={onSubmit} />
      </TabPanel>
    </>
  );
};
