import IconByString, { IconByStringProps } from '../Icon/IconType/IconByString';
import FirstAnimation from './FirstAnimation';
import SecondAnimation from './SecondAnimation';

interface SpinnerSvgProps extends IconByStringProps {
  animationDuration?: number;
}

const aspectRatio = 119.77;
const IconSpinner = (props: SpinnerSvgProps) => {
  const {
    animationDuration = 2500,
    color,
    height = `${Math.floor((86 / 100) * aspectRatio)}px`,
    size = 86,
    strokeWidth = '4',
    ...rest
  } = props;

  return (
    <IconByString
      color={color || 'inherit'}
      height={height}
      size={size}
      viewBox="0 0 86 103"
      {...{ strokeWidth, ...rest }}
    >
      <g fill="none">
        <g
          stroke="currentColor"
          strokeWidth={strokeWidth}
          transform="translate(-469, -964)"
        >
          <g transform="translate(464, 963)">
            <g transform="translate(49, 54.5) rotate(186) translate(-49, -54.5) translate(6, 5)">
              <FirstAnimation {...{ animationDuration }} />
              <SecondAnimation {...{ animationDuration }} />
            </g>
          </g>
        </g>
      </g>
    </IconByString>
  );
};

export default IconSpinner;
