import useTheme from 'modules/Theme/hooks/useTheme';

import IconByString, { IconByStringProps } from '../Icon/IconType/IconByString';

const IconMenuQuote = (props: IconByStringProps) => {
  const { active, ...rest } = props;
  const theme = useTheme();
  return (
    <IconByString {...{ active, ...rest }}>
      <path
        d="M15 3a2 2 0 0 1-2 2H9a2 2 0 0 1-2-2H4v19h14V3h-3Zm0-1h4v21H3V2h4V1h8v1Zm-1 0H8v1a1 1 0 0 0 .883.993L9 4h4a1 1 0 0 0 .993-.883L14 3V2ZM7 15h8v1H7v-1Zm8 4H7v1h8v-1Zm6-15h1v19h-1V4Z"
        fill="currentColor"
      />
      <path
        d="M7 7h8v5H7z"
        fill={active ? theme.colors.brand500 : 'transparent'}
        className="icon-hover-effect"
      />
      <path
        className="icon-hover-effect"
        clipRule="evenodd"
        d="M14 8H8v3h6V8ZM7 7v5h8V7H7Z"
        fill={active ? theme.colors.brand500 : 'currentColor'}
        fillRule="evenodd"
      />
    </IconByString>
  );
};

export default IconMenuQuote;
