import * as Yup from 'yup';

import { formText } from 'modules/Forms/messages';
import { TranslationFunction } from 'modules/I18n/hooks/useTranslations';

import { formErrors } from './messages';

const getValidationSchema = (t: TranslationFunction) => {
  return Yup.object().shape({
    vatNumber: Yup.string()
      .trim()
      .required(t(formText.required))
      .validateNif(t(formErrors.vatNumberInvalid), false),
  });
};

export default getValidationSchema;
