import IconByPath, { IconByPathProps } from './Icon/IconType/IconByPath';

const IconPlay = (props: IconByPathProps) => (
  <IconByPath
    {...props}
    path="M5 21.207V3.793a1 1 0 0 1 1.525-.85l14.097 8.706a1 1 0 0 1 0 1.702L6.525 22.058A1 1 0 0 1 5 21.208Z"
  />
);

export default IconPlay;
