import { FeatureFlagsSource, Flags } from '../model';
import BaseSource from './BaseSource';

interface CustomEventSourceProps {
  root: Window;
  options?: EventOptions;
}

interface EventOptions {
  eventName?: string;
  onChange?: EmptyFn;
}

interface CustomEvent extends Event {
  detail: Flags;
}

type EmptyFn = () => void;

export default class CustomEventSource
  extends BaseSource
  implements FeatureFlagsSource
{
  root: Window;

  flags: Flags;

  eventName: string;

  onChange?: EmptyFn;

  constructor(props: CustomEventSourceProps) {
    super();
    this.root = props.root;
    this.eventName = props.options?.eventName || 'flags:update';
    this.onChange = props.options?.onChange;
    this.flags = {};
  }

  init() {
    this.root.addEventListener(this.eventName, (e: Event) => {
      const { detail } = e as CustomEvent;

      this.flags = {
        ...this.flags,
        ...detail,
      };

      this.update(this.flags);

      if (this.onChange) {
        this.onChange();
      }
    });
  }

  getFlags() {
    return this.flags;
  }
}
