import { useSelector } from 'react-redux';

import { LeftProps, SpaceProps, WidthProps } from 'styled-system';

import { selectIsMobile } from 'modules/App/store/selectors';
import { Hr } from 'modules/Ui';

interface DocumentHrProps extends SpaceProps, WidthProps, LeftProps {
  fullscreen?: boolean; // it goes to the edge of the Box beyond the padding
  dashed?: boolean;
  color?: string;
}

const DocumentHr: React.FC<DocumentHrProps> = ({
  color,
  fullscreen = false,
  dashed = false,
  marginBottom = { _: '21px', sm: '24px' },
  ...rest
}) => {
  const isMobile = useSelector(selectIsMobile);
  const negativeLeft = isMobile ? '-16px' : '-32px';
  const expanseProps = fullscreen
    ? {
        width: {
          _: 'calc(100% + 30px)',
          sm: 'calc(100% + 64px)',
        },
        transform: `translateX(${negativeLeft})`,
      }
    : {};

  return (
    <Hr
      {...(dashed && { borderStyle: 'dashed' })}
      {...expanseProps}
      {...{ color, marginBottom, ...rest }}
    />
  );
};

export default DocumentHr;
