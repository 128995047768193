import { createAction } from 'typesafe-actions';

import ActionTypes, { ModalProps } from './constants';

export const showModal = createAction(
  ActionTypes.SHOW_MODAL,
  (props: ModalProps) => ({ props })
)();

export const hideModal = createAction(ActionTypes.HIDE_MODAL)();
