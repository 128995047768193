import { AxiosRequestConfig, AxiosResponse } from 'axios';

import { BillinRequestOptions } from './models/billinRequestOptions';

export const { setRequestRetryStrategy, getRequestRetryStrategy } = (() => {
  let currentStrategy: <R>(
    request: (
      url: string,
      options: AxiosRequestConfig,
      billinRequestOptions: BillinRequestOptions
    ) => Promise<AxiosResponse<R>>,
    url: string,
    options: AxiosRequestConfig,
    billinRequestOptions: BillinRequestOptions
  ) => Promise<AxiosResponse<R>>;
  return {
    setRequestRetryStrategy: (strategy: any) => {
      currentStrategy = strategy;
    },
    getRequestRetryStrategy: () => currentStrategy,
  };
})();
