import * as Yup from 'yup';

import { TranslationFunction } from 'modules/I18n/hooks/useTranslations';
import { filter } from 'modules/Payments/components/List/messages';

const getValidationSchema = (t: TranslationFunction) => {
  return Yup.object().shape({
    searchTerm: Yup.string().min(2, t({ ...filter.searchTermMinLength })),
    dateTo: Yup.date().when('dateFrom', (dateFrom: Date, yup: any) => {
      if (dateFrom) {
        return yup.min(dateFrom, t({ ...filter.issueDateToBeforeFromDate }));
      }
      return yup.nullable();
    }),
    minAmount: Yup.number().when('maxAmount', (maxAmount: number, yup: any) => {
      if (maxAmount !== undefined) {
        return yup.max(
          maxAmount,
          t({ ...filter.minAmountBiggerThanMaxAmount })
        );
      }
      return yup.nullable();
    }),
  });
};
export default getValidationSchema;
