import { FC } from 'react';
import { Hidden } from 'react-grid-system';
import { useSelector } from 'react-redux';
import { TransitionGroup } from 'react-transition-group';

import useTheme from 'modules/Theme/hooks/useTheme';
import { Box } from 'modules/Ui';
import { zIndex } from 'modules/Ui/zIndex';

import { selectBubbleNotifications } from '../../selectors';
import BubbleNotification from '../BubbleNotification';

const BubbleNotifications: FC = () => {
  const theme = useTheme();
  const bubbleNotifications = useSelector(selectBubbleNotifications);
  return (
    <Hidden xs>
      {bubbleNotifications ? (
        <Box
          maxWidth={{ md: theme.maxWidth.md, lg: theme.maxWidth.lg }}
          position="relative"
          margin="0 auto"
          role="alert"
        >
          <Box
            display="grid"
            position="absolute"
            right="0"
            rowGap="8px"
            tag="ul"
            top="-6px"
            width="344px"
            zIndex={zIndex.NOTIFICATION_ALERT}
          >
            <TransitionGroup enter exit appear component={null}>
              {bubbleNotifications.map((bubbleNotification) => (
                <BubbleNotification
                  key={bubbleNotification.id}
                  message={bubbleNotification.message}
                  id={bubbleNotification.id}
                  payload={bubbleNotification.payload}
                />
              ))}
            </TransitionGroup>
          </Box>
        </Box>
      ) : undefined}
    </Hidden>
  );
};

export default BubbleNotifications;
