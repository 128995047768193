import { getConfig } from 'modules/App/config';
import request from 'utils/request';

import { Document } from '../../models/document';

const fetchDocument = async (id: string): Promise<Document> => {
  const endpoint = (await getConfig()).apiDocumentsService;

  const response = await request<{ data: Document }>(`${endpoint}/${id}`, {
    method: 'GET',
  });

  return { ...response.data.data };
};

export default fetchDocument;
