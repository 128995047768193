import { FC } from 'react';
import { Visible } from 'react-grid-system';

import useTranslations from 'modules/I18n/hooks/useTranslations';
import { MODAL_WIDTH_SMALL, Dialog } from 'modules/Modals';
import { Box, Text, Button } from 'modules/Ui';

import ChangeBillingEmailForm from './ChangeEmailForm';
import { changeEmailModal as messages, changeEmail } from './messages';

interface Props {
  closeModal: () => void;
  onChangeBillingEmail: (email: string) => Promise<void>;
  billingEmail: string;
  isUpdating: boolean;
}

const ChangeEmailModal: FC<Props> = ({
  closeModal,
  onChangeBillingEmail,
  billingEmail,
  isUpdating,
}: Props) => {
  const { t } = useTranslations();
  return (
    <Dialog
      type="fullScreen"
      buttonPrimary={
        <Button
          data-testid="update billin-email"
          disabled={isUpdating}
          form="changeBillingEmail"
          type="submit"
          variant="secondary"
        >
          {isUpdating ? t(messages.updating) : t(messages.acceptButton)}
        </Button>
      }
      buttonSecondary={
        <Visible md lg xl xxl>
          <Button onClick={closeModal} variant="stroke">
            {t(messages.cancelButton)}
          </Button>
        </Visible>
      }
      content={
        <>
          <Text marginBottom="12px">{t(changeEmail.emailDialogText)}</Text>
          <Box
            backgroundColor="stateBg"
            padding="16px 24px 8px"
            margin={{ _: '0 -16px', sm: '0 0 32px' }}
          >
            <ChangeBillingEmailForm
              billingEmail={billingEmail}
              onSubmit={onChangeBillingEmail}
            />
          </Box>
        </>
      }
      dialogWidth={{ md: MODAL_WIDTH_SMALL }}
      id="changeBillingEmailDialog"
      onClose={closeModal}
      title={t(messages.title)}
    />
  );
};

export default ChangeEmailModal;
