import React from 'react';
import { useBottomScrollListener } from 'react-bottom-scroll-listener';
import { useInterval } from 'react-use';

import styled, { css } from 'modules/Theme/styled-components';

import Box from '../Box';
import Spinner from '../Spinner';

export interface InfiniteListProps<T> {
  height?: number;
  entries: T[];
  endElement?: React.ReactNode;
  hasNextPage?: boolean;
  isFetchingNextPage: boolean;
  isLoading: boolean;
  scrollTransparentEffect?: boolean;
  fetchNext: () => void;
  entryRender: (item: T, index: number) => JSX.Element;
  isScrollbarVisible?: boolean;
}

const scrollTransparentEffectAfter = css`
  &:after {
    background: linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
    bottom: 0;
    content: "";
    display: block;
    height: 30px;
    position: absolute;
    width: 100%;
  }
`;

const scrollbarNotVisibleEffect = css`
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const StyledWrapper = styled(Box)`
  overflow-anchor: none;
  ${({ scrollTransparentEffect }) =>
    scrollTransparentEffect && `${scrollTransparentEffectAfter};`}
`;

const StyledList = styled(Box)`
  ${({ isScrollbarVisible }) =>
    !isScrollbarVisible && `${scrollbarNotVisibleEffect};`}
`;

function hasScroll(element: HTMLElement): boolean {
  return element.scrollHeight > element.clientHeight;
}

function InfiniteList<T>({
  entries,
  isLoading,
  hasNextPage,
  isFetchingNextPage,
  height = 239,
  scrollTransparentEffect = true,
  fetchNext,
  endElement,
  entryRender,
  isScrollbarVisible = false,
}: React.PropsWithChildren<InfiniteListProps<T>>) {
  const callbackFetchNext = React.useCallback(() => {
    hasNextPage && fetchNext();
  }, [hasNextPage, fetchNext]);

  const ref = useBottomScrollListener(callbackFetchNext, {
    offset: height / 2,
  });

  useInterval(() => {
    if (
      hasNextPage &&
      ref.current &&
      !isFetchingNextPage &&
      !hasScroll(ref.current)
    ) {
      callbackFetchNext();
    }
  }, 500);

  const showEndElement = !hasNextPage && !isLoading && entries.length;
  const showSpinner = isFetchingNextPage || isLoading;

  return (
    <StyledWrapper
      height={height}
      position="relative"
      scrollTransparentEffect={scrollTransparentEffect}
    >
      <StyledList
        maxHeight={height}
        contentVisibility="auto"
        overflowX="hidden"
        overflowY="auto"
        ref={ref}
        isScrollbarVisible={isScrollbarVisible}
        tag="ul"
        fadeIn
      >
        {entries.map((entry, index) => entryRender(entry, index))}
        {showSpinner && (
          <Box height="32px" position="relative" margin="8px 0">
            <Spinner color="brand500" size={32} />
          </Box>
        )}
        {showEndElement ? endElement : null}
      </StyledList>
    </StyledWrapper>
  );
}

export default InfiniteList;
