import { useEffect, useState } from 'react';

import { getConfig } from '../config';
import { AppConfig } from '../config/models';

export default function useConfig() {
  const [config, setConfig] = useState<Partial<AppConfig>>({});

  async function fetchConfig() {
    setConfig(await getConfig());
  }

  useEffect(() => {
    fetchConfig();
  }, []);

  return {
    config,
  };
}
