import IconByPath, { IconByPathProps } from './Icon/IconType/IconByPath';

const IconCross = (props: IconByPathProps) => (
  <IconByPath
    path="M12 11.151 4.924 4.076l-.848.848L11.152 12l-7.076 7.076.848.848L12 12.85l7.076 7.075.848-.848L12.85 12l7.075-7.076-.848-.848L12 11.15Z"
    {...props}
  />
);

export default IconCross;
