import { FC, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { deleteButton } from 'modules/AccountingAccounts/messages';
import { AccountingAccount } from 'modules/AccountingAccounts/models';
import useTranslations from 'modules/I18n/hooks/useTranslations';
import { showModal } from 'modules/Modals/actions';
import { ModalTypes } from 'modules/Modals/constants';
import useTheme from 'modules/Theme/hooks/useTheme';
import { Button } from 'modules/Ui';
import { IconFile } from 'modules/Ui/Icons';
import history from 'utils/history';

interface ButtonDeleteProps {
  account: AccountingAccount;
}

export const ButtonDelete: FC<ButtonDeleteProps> = ({ account }) => {
  const { t } = useTranslations();
  const { id } = account;
  const dispatch = useDispatch();
  const openDeleteModal = useCallback(() => {
    dispatch(
      showModal({
        type: ModalTypes.DELETE_ACCOUNT,
        id: id ?? '',
        callback: () => history.push('/banks'),
        expectedToRemove: 1,
      })
    );
  }, [dispatch, id, history]);
  const theme = useTheme();
  return (
    <Button
      color={theme.colors.error}
      icon={<IconFile color="error" />}
      onClick={openDeleteModal}
      marginBottom="16px"
    >
      {t(deleteButton.deleteText)}
    </Button>
  );
};
