import { UseQueryResult, useQuery, useQueryClient } from 'react-query';

import { DEFAULT_VALUES_QUERY } from 'modules/App/queries/queries';
import { getCurrentUser } from 'modules/Auth/currentUserUtil';
import { DefaultValuesResource } from 'modules/DefaultValues/enums';
import fetchDefaultValues from 'modules/DefaultValues/services/fetchDefaultValues';
import updateDefaultValues from 'modules/DefaultValues/services/updateDefaultValues';
import { useBlockingMutation } from 'utils/useBlockingMutation';
import { useHookConditionally } from 'utils/useHookConditionally';

export enum ModalFlag {
  HIDE_BAI_SENDING_WARNING = 'hideBaiSendingWarning',
  HIDE_OCR_INFO = 'hideOcrInfo',
  HIDE_OCR_INFO_MOBILE = 'hideOcrInfoMobile',
  HIDE_TEST_INVOICE_WARNING = 'hideTestInvoiceWarning',
  HIDE_SHOPIFY_CONFIG_MODAL = 'hideShopifyConfigModal',
  HIDE_AUTOSAVING_DOCUMENTS_WARNING = 'hideAutosavingDocumentsWarning',
}

interface ModalFlags {
  [ModalFlag.HIDE_BAI_SENDING_WARNING]: boolean;
  [ModalFlag.HIDE_OCR_INFO]: boolean;
  [ModalFlag.HIDE_OCR_INFO_MOBILE]: boolean;
  [ModalFlag.HIDE_TEST_INVOICE_WARNING]: boolean;
  [ModalFlag.HIDE_SHOPIFY_CONFIG_MODAL]: boolean;
  [ModalFlag.HIDE_AUTOSAVING_DOCUMENTS_WARNING]: boolean;
}

interface MutationVariables {
  flag: ModalFlag;
  status: boolean;
}

const useModalFlags = () => {
  const currentUser = getCurrentUser();
  const queryClient = useQueryClient();

  const response = useHookConditionally<
    UseQueryResult,
    Parameters<typeof useQuery>
  >({
    condition: !!currentUser,
    hook: useQuery,
    props: [
      [
        DEFAULT_VALUES_QUERY,
        { resource: DefaultValuesResource.Modal, currentUser },
      ],
      () => fetchDefaultValues<ModalFlags>(DefaultValuesResource.Modal),
      {
        cacheTime: 1000 * 60 * 60,
        refetchOnMount: false,
      },
    ],
    fallback: {
      data: [],
      isFetching: false,
    } as UseQueryResult,
  });

  const { data, isFetching } = response as UseQueryResult<ModalFlags>;
  const { mutate } = useBlockingMutation(
    (values: MutationVariables) => {
      return updateDefaultValues<Partial<ModalFlags>>(
        DefaultValuesResource.Modal,
        {
          ...data,
          [values.flag]: values.status,
        }
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          DEFAULT_VALUES_QUERY,
          { resource: DefaultValuesResource.Modal, currentUser },
        ]);
      },
    }
  );

  return {
    getFlag: (flag: ModalFlag) => {
      if (!data || isFetching) {
        return undefined;
      }
      return !!data[flag];
    },

    setFlag: mutate,
  };
};

export default useModalFlags;
