import { NifIvaCountryCodes } from './constants';

type NifIvaCountryInfo = {
  countryCode: NifIvaCountryCodes;
  format: string;
  regex: RegExp;
};

export const nifIvaPerCountry: { [key: string]: NifIvaCountryInfo } = {
  DE: {
    countryCode: NifIvaCountryCodes.DE,
    format: 'DE999999999',
    regex: /^(DE)[0-9]{9}$/,
  },
  AT: {
    countryCode: NifIvaCountryCodes.AT,
    format: 'ATU99999999',
    regex: /^(AT)U[0-9]{8}$/,
  },
  BE: {
    countryCode: NifIvaCountryCodes.BE,
    format: 'BE0999999999',
    regex: /^(BE)0[0-9]{9}$/,
  },
  BG: {
    countryCode: NifIvaCountryCodes.BG,
    format: 'BG999999999',
    regex: /^(BG)[0-9]{9,10}$/,
  },
  CZ: {
    countryCode: NifIvaCountryCodes.CZ,
    format: 'CZ999999999',
    regex: /^(CZ)[0-9]{8,10}$/,
  },
  CY: {
    countryCode: NifIvaCountryCodes.CY,
    format: 'CY99999999L',
    regex: /^(CY)[0-9]{8}L$/,
  },
  HR: {
    countryCode: NifIvaCountryCodes.HR,
    format: 'HR99999999999',
    regex: /^(HR)[0-9]{11}$/,
  },
  DK: {
    countryCode: NifIvaCountryCodes.DK,
    format: 'DK99999999',
    regex: /^(DK)[0-9]{8}$/,
  },
  SK: {
    countryCode: NifIvaCountryCodes.SK,
    format: 'SK9999999999',
    regex: /^(SK)[0-9]{10}$/,
  },
  SI: {
    countryCode: NifIvaCountryCodes.SI,
    format: 'SI99999999',
    regex: /^(SI)[0-9]{8}$/,
  },
  ES: {
    countryCode: NifIvaCountryCodes.ES,
    format: 'ESX9999999X',
    regex: /^(ES)([0-9]|[A-Z])[0-9]{7}([0-9]|[A-Z])$/,
  },
  EE: {
    countryCode: NifIvaCountryCodes.EE,
    format: 'EE999999999',
    regex: /^(EE)[0-9]{9}$/,
  },
  FI: {
    countryCode: NifIvaCountryCodes.FI,
    format: 'FI99999999',
    regex: /^(FI)[0-9]{8}$/,
  },
  FR: {
    countryCode: NifIvaCountryCodes.FR,
    format: 'FR99999999999',
    regex: /^(FR)([0-9]|[A-Z]){2}[0-9]{9}$/,
  },
  HU: {
    countryCode: NifIvaCountryCodes.HU,
    format: 'HU99999999',
    regex: /^(HU)[0-9]{8}$/,
  },
  IE: {
    countryCode: NifIvaCountryCodes.IE,
    format: 'IE9999999XX',
    regex: /^(IE)[0-9]{7}[A-Z]{1,2}$/,
  },
  IT: {
    countryCode: NifIvaCountryCodes.IT,
    format: 'IT99999999999',
    regex: /^(IT)[0-9]{11}$/,
  },
  LV: {
    countryCode: NifIvaCountryCodes.LV,
    format: 'LV99999999999',
    regex: /^(LV)[0-9]{11}$/,
  },
  LT: {
    countryCode: NifIvaCountryCodes.LT,
    format: 'LT999999999',
    regex: /^(LT)[0-9]{9,12}$/,
  },
  LU: {
    countryCode: NifIvaCountryCodes.LU,
    format: 'LU99999999',
    regex: /^(LU)[0-9]{8}$/,
  },
  MT: {
    countryCode: NifIvaCountryCodes.MT,
    format: 'MT99999999',
    regex: /^(MT)[0-9]{8}$/,
  },
  NL: {
    countryCode: NifIvaCountryCodes.NL,
    format: 'NL999999999B99',
    regex: /^(NL)([0-9]|[A-Z]){12}$/,
  },
  PL: {
    countryCode: NifIvaCountryCodes.PL,
    format: 'PL9999999999',
    regex: /^(PL)[0-9]{10}$/,
  },
  PT: {
    countryCode: NifIvaCountryCodes.PT,
    format: 'PT999999999',
    regex: /^(PT)[0-9]{9}$/,
  },
  RO: {
    countryCode: NifIvaCountryCodes.RO,
    format: 'RO9999999999',
    regex: /^(RO)[0-9]{2,10}$/,
  },
  SE: {
    countryCode: NifIvaCountryCodes.SE,
    format: 'SE999999999999',
    regex: /^(SE)[0-9]{12}$/,
  },
  XI: {
    countryCode: NifIvaCountryCodes.XI,
    format: 'XI999999999999',
    regex: /^(XI)[0-9]{5,12}$/,
  },
  GR: {
    countryCode: NifIvaCountryCodes.GR,
    format: 'EL999999999',
    regex: /^(EL)[0-9]{9}$/,
  },
};
