import IconByPath, { IconByPathProps } from './Icon/IconType/IconByPath';

const IconChange = (props: IconByPathProps) => (
  <IconByPath
    {...props}
    viewBox="0 0 20 20"
    path="M6.69 11.164l-1.156-1.157h7.809a1.15 1.15 0 010 2.299h-.575v1.15h.575a2.3 2.3 0 000-4.599H5.534l1.157-1.156.707-.708-.106-.105-.601-.602-.106-.105-.707.707-1.732 1.731-.105.106-.602.601-.106.106.708.707 1.837 1.837.707.707.106-.105.601-.602.106-.105-.707-.707z"
  />
);

export default IconChange;
