import { useSelector } from 'react-redux';

import { selectIsMobile } from 'modules/App/store/selectors';
import useTranslations from 'modules/I18n/hooks/useTranslations';
import { Button, Box, Text } from 'modules/Ui';
import { IconStateDone, IconStateError } from 'modules/Ui/Icons';

import { useShopifyConfiguration } from '../../hooks';
import { shopifyForm } from '../../messages';

const ShopifyIntegrationModule = () => {
  const {
    data,
    activateShopify,
    deactivateShopify,
    isActivating,
    isDeactivating,
  } = useShopifyConfiguration();
  const { t } = useTranslations();
  const isMobile = useSelector(selectIsMobile);
  if (!data) {
    return null;
  }
  return (
    <Box
      backgroundColor="gray0"
      display={{ sm: 'grid', md: 'block' }}
      fadeIn
      columnGap={{ sm: '10px', md: 'initial' }}
      gridTemplateColumns={{ sm: '1fr 190px', md: 'initial' }}
      marginBottom={{ _: '20px', sm: '16px', md: '20px' }}
      padding="8px 16px 16px"
    >
      <Box marginBottom={{ _: '24px', sm: 'initial' }}>
        <Text
          color="primary300"
          fontSize={12}
          lineHeight={18}
          marginBottom="12px"
          textTransform="uppercase"
        >
          {t(shopifyForm.ShopifyIntegrationShop)}
        </Text>
        <Text alignItems="center" display="flex" marginBottom={{ md: '24px' }}>
          {data.isActive ? (
            <IconStateDone size={13} />
          ) : (
            <IconStateError size={13} />
          )}
          <Text
            color="gray800"
            fontSize={14}
            fontWeight="600"
            lineHeight={18}
            marginLeft="6px"
            tag="span"
            wordBreak={isMobile ? 'break-all' : undefined}
          >
            {data.name}
          </Text>
        </Text>
      </Box>
      {data.isActive ? (
        <Button
          alignSelf={{ sm: 'flex-end', md: 'initial' }}
          height="40px"
          variant="stroke"
          onClick={deactivateShopify}
          disabled={isDeactivating}
          width="100%"
        >
          {t(shopifyForm.ShopifyIntegrationButtonActive)}
        </Button>
      ) : (
        <Button
          alignSelf={{ sm: 'flex-end', md: 'initial' }}
          height="40px"
          variant="primary"
          onClick={activateShopify}
          disabled={isActivating}
          width="100%"
        >
          {t(shopifyForm.ShopifyIntegrationButtonNoActive)}
        </Button>
      )}
    </Box>
  );
};

export default ShopifyIntegrationModule;
