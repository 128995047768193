import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

import { getConfig } from 'modules/App/config';
import { UserPermissions } from 'modules/Auth/constants';
import { BillinRequestOptions } from 'modules/Auth/models/billinRequestOptions';
import { getAuthData } from 'modules/Auth/request-auth-data';
import { getRequestRetryStrategy } from 'modules/Auth/request-strategy';

import { getApiDomain } from './apiDomain';
import { requestMethodOverride } from './request-method-override';
import requestErrorHandler from './requestErrorHandler';

function shouldRetry(status?: number, requireAuth?: boolean) {
  return status === 401 && requireAuth;
}

export default async function request<T>(
  url: string,
  options: AxiosRequestConfig = {},
  billinRequestOptions: BillinRequestOptions = {
    requireAuth: true,
    retries: 2,
  }
): Promise<AxiosResponse<T>> {
  const customToken = billinRequestOptions.token;
  const { currentToken, expireOn } = getAuthData();

  if (
    !customToken &&
    (!currentToken || !expireOn || expireOn < Date.now()) &&
    billinRequestOptions.requireAuth
  ) {
    const retryStrategy = getRequestRetryStrategy();
    if (retryStrategy) {
      return retryStrategy<T>(request, url, options, billinRequestOptions);
    }
  }

  const requestToken = customToken || currentToken;

  const authorization = requestToken
    ? { Authorization: `Bearer ${requestToken}` }
    : {};

  const appConfig = await getConfig();

  const apiDomain = getApiDomain(appConfig);

  const config: AxiosRequestConfig = {
    ...options,
    headers: {
      ...options?.headers,
      ...authorization,
    },
    baseURL: apiDomain,
    url,
  };

  try {
    const result = await axios.request<T>(requestMethodOverride(config));
    return result;
  } catch (error: any) {
    const { responseStatus, responseMessage } = requestErrorHandler(error);
    if (responseStatus === 403) {
      if (
        responseMessage &&
        responseMessage.includes(UserPermissions.SUBSCRIPTION)
      ) {
        // eslint-disable-next-line no-console
        console.log(`[Error]: ${responseMessage}`);
      }
    } else if (shouldRetry(responseStatus, billinRequestOptions.requireAuth)) {
      const retryStrategy = getRequestRetryStrategy();
      if (retryStrategy) {
        return retryStrategy<T>(request, url, options, billinRequestOptions);
      }
    }
    throw error;
  }
}

const globalWindow = window as any;
if (globalWindow.Cypress) {
  globalWindow.billinTools = globalWindow.billinTools || {};
  globalWindow.billinTools.request = request;
}
