import { defineMessages } from 'react-intl';

export default defineMessages({
  close: { id: 'gofre.Ui.NewFeatureBanner.close' },
  new: { id: 'gofre.Ui.NewFeatureBanner.new' },
});

export const detaislNewFeatureBanner = defineMessages({
  title: { id: 'gofre.newFeatureBanner.title' },
  subtitle: { id: 'gofre.newFeatureBanner.subtitle' },
});
