import { useLocation } from 'react-router';

import { HOME_ROUTES } from 'modules/Home/routes';
import { ROUTES as CONTACT_ROUTES } from 'pages/contacts/routesMap';
import { ROUTES as DOCUMENT_ROUTES } from 'pages/documents/routesMap';
import { PAYMENT_ROUTES } from 'pages/payments/routesMap';
import { PRODUCTS_ROUTES } from 'pages/products/routesMap';
import { ROUTES as ROUTES_QUOTES } from 'pages/quotes/routesMap';

export default function useBookkeeperWidgetLocation(): boolean {
  const location = useLocation();
  const { pathname } = location;
  const bookkeeperWidgetLocation = [
    DOCUMENT_ROUTES.DOCUMENTS,
    CONTACT_ROUTES.CONTACTS,
    PRODUCTS_ROUTES.BASE,
    ROUTES_QUOTES.MAIN_PATH,
    DOCUMENT_ROUTES.DOCUMENTS_EXPENSES,
    HOME_ROUTES.MAIN_PATH,
    ROUTES_QUOTES.PROFORMAS_PATH,
    ROUTES_QUOTES.DELIVERY_NOTE_PATH,
    PAYMENT_ROUTES.LIST,
    PAYMENT_ROUTES.BANKS,
    PAYMENT_ROUTES.REMITTANCES,
  ];
  const isBookkeeperWidgettVisible =
    bookkeeperWidgetLocation.includes(pathname);
  return Boolean(isBookkeeperWidgettVisible);
}
