import { FC, useCallback, useState } from 'react';

import { DocumentType } from 'modules/Documents/models/document';
import useTranslations from 'modules/I18n/hooks/useTranslations';
import { MODAL_WIDTH_SMALL, Dialog } from 'modules/Modals';
import { useCloseModal } from 'modules/Modals/hooks';
import useTheme from 'modules/Theme/hooks/useTheme';
import { IconStateDone, IconSpinner, IconStateWarning } from 'modules/Ui/Icons';

import useOpenDocumentZip from '../../hooks/useOpenDocumentZip';
import messages from './messages';
import ModalContent from './ModalContent';
import { DocumentsDownloadZipModalProps } from './types';

const DocumentsDownloadZipModal: FC<DocumentsDownloadZipModalProps> = ({
  documentType,
  taskId,
}) => {
  const { t } = useTranslations();
  const theme = useTheme();
  const [url, setUrl] = useState('');
  const [isDownloaded, setIsDownloaded] = useState(false);
  const [isTimedOut, setIsTimedOut] = useState(false);
  const title =
    documentType === DocumentType.EXPENSE
      ? t(messages.expensesTitle)
      : t(messages.title);
  const closeModal = useCloseModal();
  const onSuccess = useCallback(
    (zipUrl: string) => {
      setUrl(zipUrl);
      setIsDownloaded(true);
    },
    [setIsDownloaded]
  );
  const onError = useCallback(() => {
    setIsTimedOut(true);
  }, [setIsTimedOut]);

  const cancelRequests = useOpenDocumentZip({
    taskIds: [taskId],
    onSuccess,
    onError,
  });

  const onClose = useCallback(() => {
    closeModal();
    cancelRequests();
  }, []);

  return (
    <Dialog
      title={title}
      content={
        <>
          {isDownloaded ? (
            <ModalContent
              Icon={IconStateDone}
              linkText={t(messages.finishedLink, { count: 1 })}
              subtitle={t(messages.finishedSubtitle, { current: 1, count: 1 })}
              text={t(messages.finishedText, { count: 1 })}
              zip={url}
            />
          ) : (
            <>
              <ModalContent
                Icon={isTimedOut ? IconStateWarning : IconSpinner}
                iconColor={
                  isTimedOut ? theme.colors.error : theme.colors.brand500
                }
                iconStroke={isTimedOut ? undefined : 6}
                subtitle={
                  isTimedOut
                    ? t(messages.errorSubtitle)
                    : t(messages.notFinishedSubtitle)
                }
                text={
                  isTimedOut
                    ? t(messages.errorText)
                    : t(messages.notFinishedText)
                }
              />
            </>
          )}
        </>
      }
      dialogWidth={MODAL_WIDTH_SMALL}
      onClose={onClose}
    />
  );
};

export default DocumentsDownloadZipModal;
