import { useQuery } from 'react-query';

import { COUNTRIES } from 'modules/App/queries/queries';
import CacheTimes from 'utils/query/cacheTimes';

import fetchCountries from '../services/fetchCountries';

const useCountries = () => {
  const { data: countries } = useQuery(COUNTRIES, fetchCountries, {
    staleTime: CacheTimes.INFINITY,
    refetchOnMount: false,
  });
  return {
    countries,
  };
};

export default useCountries;
