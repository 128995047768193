import { defineMessages } from 'react-intl';

export const zeroStateMessages = defineMessages({
  zeroStateTitle: { id: 'gofre.remittances.zeroState.title' },
  zeroStateSubiitle: { id: 'gofre.remittances.zeroState.subtitle' },
  zeroStateButton: { id: 'gofre.remittances.zeroState.button' },
});

export const remittanceDocumentListMessage = defineMessages({
  title: { id: 'gofre.remittances.add.title' },
  titleDebit: { id: 'gofre.remittances.add.titleDebit' },
  bulkActionLabel: { id: 'gofre.remittances.add.bulkActionLabel' },
});

export const remittanceMessages = defineMessages({
  title: { id: 'gofre.remittances.title' },
  addRemittance: { id: 'gofre.Payments.buttonRemittances.title' },
});

export const zeroStateExpenses = defineMessages({
  subtitle: { id: 'gofre.remittance-expenses.zeroState.subtitle' },
  title: { id: 'gofre.remittance-expenses.zeroState.title' },
  link: { id: 'gofre.remittance-expenses.zeroState.link' },
});

export const zeroStateDocuments = defineMessages({
  subtitle: { id: 'gofre.remittance-documents.zeroState.subtitle' },
  title: { id: 'gofre.remittance-documents.zeroState.title' },
  link: { id: 'gofre.remittance-documents.zeroState.link' },
});

export const tablesColumns = defineMessages({
  totalToPay: { id: 'gofre.remittances.table.totalToPay' },
  totalToBePaid: { id: 'gofre.remittances.table.totalToBePaid' },
});
