import { FC } from 'react';

import { space } from 'styled-system';

import styled from 'modules/Theme/styled-components';
import { PillRecommended, Text } from 'modules/Ui';
import ToggleField from 'modules/Ui/Formik/ToggleField';
import { ToggleProps } from 'modules/Ui/Toggle/Toggle';

export interface IntegrationToggleProps extends ToggleProps {
  textDescription?: string;
  isRecommended?: boolean;
}

const StyledToogle = styled(ToggleField)<IntegrationToggleProps>`
  ${space}
`;

export const IntegrationFormToogle: FC<IntegrationToggleProps> = ({
  id,
  isRecommended,
  name,
  text,
  textDescription,
  ...rest
}) => {
  return (
    <StyledToogle id={id} name={name} toogleAlinement="start" {...rest}>
      <Text
        alignItems={isRecommended ? { sm: 'center' } : undefined}
        color="gray800"
        display={isRecommended ? { sm: 'flex' } : undefined}
        flexWrap={isRecommended ? { sm: 'wrap' } : undefined}
        fontSize={16}
        fontWeight="600"
        lineHeight={22}
        marginRight="32px"
        tag="span"
      >
        {text}
        {isRecommended && <PillRecommended marginLeft="8px" />}
        <Text
          color="gray600"
          display="block"
          fontSize={14}
          fontWeight="500"
          lineHeight={18}
          marginTop={isRecommended ? '8px' : undefined}
          tag="span"
        >
          {textDescription}
        </Text>
      </Text>
    </StyledToogle>
  );
};
