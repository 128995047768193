import IconByPath, { IconByPathProps } from './Icon/IconType/IconByPath';

const IconArrowBack = (props: IconByPathProps) => (
  <IconByPath
    {...props}
    path="m13.961 16.646-.707.708-5.304-5.305 5.403-5.403.707.708-4.696 4.695 4.597 4.598Z"
  />
);

export default IconArrowBack;
