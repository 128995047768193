import IconByPath, { IconByPathProps } from './Icon/IconType/IconByPath';

const IconExcel = (props: IconByPathProps) => (
  <IconByPath
    {...props}
    fillRule="evenodd"
    path="M10 1 3 8v15h18V1H10ZM4 8.414 4.414 8H10V2.414L10.414 2H20v20H4V8.414Zm5-5L5.414 7H9V3.414Zm6.117 15.914c.272.267.629.4 1.072.4.442 0 .8-.136 1.072-.408.272-.277.408-.643.408-1.096 0-.25-.046-.467-.136-.648a1.683 1.683 0 0 0-.384-.496 8.641 8.641 0 0 0-.736-.592c-.32-.235-.536-.419-.648-.552a.756.756 0 0 1-.16-.488c0-.203.05-.36.152-.472a.494.494 0 0 1 .392-.176c.17 0 .306.064.408.192.106.123.16.288.16.496v.208c0 .027.008.05.024.072a.117.117 0 0 0 .072.024h.72a.087.087 0 0 0 .064-.024.1.1 0 0 0 .032-.072v-.192c0-.453-.136-.816-.408-1.088-.272-.277-.63-.416-1.072-.416-.438 0-.79.13-1.056.392-.267.261-.4.613-.4 1.056 0 .277.053.523.16.736.112.208.248.387.408.536.165.15.38.32.648.512.234.17.405.301.512.392.106.09.189.187.248.288a.707.707 0 0 1 .088.36.706.706 0 0 1-.16.48.527.527 0 0 1-.408.176.508.508 0 0 1-.408-.192c-.102-.128-.152-.296-.152-.504v-.16a.076.076 0 0 0-.032-.064.076.076 0 0 0-.064-.032h-.728a.1.1 0 0 0-.072.032.086.086 0 0 0-.024.064v.208c0 .432.136.781.408 1.048Zm-8.537.336c-.037 0-.061-.01-.072-.032-.01-.021-.01-.048 0-.08l1.096-2.664a.074.074 0 0 0 0-.048l-1.096-2.664a.112.112 0 0 1-.008-.048c0-.043.027-.064.08-.064h.76c.053 0 .088.027.104.08l.64 1.736c.005.016.013.024.024.024.01 0 .019-.008.024-.024l.632-1.736c.016-.053.05-.08.104-.08h.752c.037 0 .061.01.072.032.01.021.01.048 0 .08L8.596 16.84a.074.074 0 0 0 0 .048l1.096 2.664c.005.01.008.027.008.048 0 .043-.027.064-.08.064h-.744c-.059 0-.096-.027-.112-.08l-.632-1.728c-.005-.016-.013-.024-.024-.024-.01 0-.019.008-.024.024l-.64 1.728c-.016.053-.053.08-.112.08H6.58Zm4.488 0a.117.117 0 0 1-.072-.024.117.117 0 0 1-.024-.072V14.16c0-.027.008-.048.024-.064a.1.1 0 0 1 .072-.032h.736c.026 0 .048.01.064.032a.076.076 0 0 1 .032.064v4.664c0 .027.013.04.04.04h1.728c.026 0 .048.01.064.032a.076.076 0 0 1 .032.064v.608a.1.1 0 0 1-.032.072.086.086 0 0 1-.064.024h-2.6Z"
  />
);

export default IconExcel;
