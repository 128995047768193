import IconByPath, { IconByPathProps } from './Icon/IconType/IconByPath';

const IconPassword = (props: IconByPathProps) => (
  <IconByPath
    {...props}
    fillRule="evenodd"
    path="M1.64 12c2.077 3.589 5.955 6 10.396 6 4.44 0 8.318-2.411 10.394-6a11.995 11.995 0 0 0-10.394-6c-4.44 0-8.32 2.411-10.395 6Zm21.931 0c-2.168-4.16-6.52-7-11.535-7C7.02 5 2.668 7.84.5 12c2.168 4.16 6.52 7 11.536 7 5.015 0 9.367-2.84 11.535-7ZM16.5 12a4.5 4.5 0 1 1-9 0 4.5 4.5 0 0 1 9 0Zm-8 0a3.5 3.5 0 1 0 7 0 3.5 3.5 0 0 0-7 0Zm3.5 2a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z"
  />
);

export default IconPassword;
