import { defineMessages } from 'react-intl';

import { DefineMessages } from 'modules/I18n/types';

const messages: DefineMessages = defineMessages({
  helpCallUs: { id: 'gofre.Ui.Help.callUs' },
  helpEmail: { id: 'gofre.Ui.Help.email' },
  helpEmailOther: { id: 'gofre.Ui.Help.email.Other.REGULAR' },
  helpEmailOtherREGULAR: { id: 'gofre.Ui.Help.email.Other.REGULAR' },
  helpEmailOtherBOOKKEEPER: { id: 'gofre.Ui.Help.email.Other.BOOKKEEPER' },
  helpEmailREGULAR: { id: 'gofre.Ui.Help.email.REGULAR' },
  helpEmailBOOKKEEPER: { id: 'gofre.Ui.Help.email.BOOKKEEPER' },
  helpPhone: { id: 'gofre.Ui.Help.phone' },
  helpPhoneLabel: { id: 'gofre.Ui.Help.phoneLabel' },
  helpSchedule01: { id: 'gofre.Ui.Help.helpSchedule01' },
  helpSchedule02: { id: 'gofre.Ui.Help.helpSchedule02' },
  helpTitle: { id: 'gofre.Ui.Help.title' },
  helpWriteUs: { id: 'gofre.Ui.Help.writelUs.REGULAR' },
  helpWriteUsREGULAR: { id: 'gofre.Ui.Help.writelUs.REGULAR' },
  helpWriteUsBOOKKEEPER: { id: 'gofre.Ui.Help.writelUs.BOOKKEEPER' },
  helpWriteUsOther: { id: 'gofre.Ui.Help.writelUs.Other.REGULAR' },
  helpWriteUsOtherREGULAR: { id: 'gofre.Ui.Help.writelUs.Other.REGULAR' },
  helpWriteUsOtherBOOKKEEPER: { id: 'gofre.Ui.Help.writelUs.Other.BOOKKEEPER' },
  helpBookkeeperEmail: { id: 'gofre.Ui.Help.Bookkeeper.email' },
});

export default messages;
