import { FC } from 'react';

import {
  gridArea,
  GridAreaProps,
  gridTemplateColumns,
  GridTemplateColumnsProps,
  layout,
  LayoutProps,
  space,
  SpaceProps,
  system,
} from 'styled-system';

import useTheme from 'modules/Theme/hooks/useTheme';
import styled from 'modules/Theme/styled-components';
import { Box } from 'modules/Ui';

import { ChartResumeItem } from './components';

interface ReportItem {
  id: string;
  value: string | number;
  label: string;
  color?: string;
}

interface Props
  extends GridAreaProps,
    SpaceProps,
    LayoutProps,
    GridTemplateColumnsProps {
  data: ReportItem[];
  gap?: {} | string;
}

const StyledList = styled(Box)`
  ${gridTemplateColumns}
  ${gridArea}
  ${layout}
  ${space}
  ${system({
    gap: {
      property: 'gap',
      transform: (value) => `${value}`,
    },
  })}
`;

export const ChartResume: FC<Props> = ({ data, ...rest }) => {
  const theme = useTheme();
  return (
    <StyledList
      alignSelf={{ sm: 'start' }}
      borderTop={{ md: `1px solid  ${theme.colors.primary200}` }}
      marginTop="16px"
      tag="ul"
      {...rest}
    >
      {data.map((item) => {
        return (
          <ChartResumeItem
            color={item.color}
            key={item.id}
            label={item.label}
            value={item.value}
          />
        );
      })}
    </StyledList>
  );
};

export default ChartResume;
