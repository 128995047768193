import { AxiosResponse } from 'axios';

import { getConfig } from 'modules/App/config';
import {
  CONTACTS_QUERY,
  DOCUMENTS_RECURRENTS_QUERY,
} from 'modules/App/queries/queries';
import { Contact } from 'modules/Contacts/models';
import { getSource } from 'utils/getSource';
import request from 'utils/request';

import normalizePayload from '../../helpers/normalizePayload';

const updateContact = async ({
  id,
  ...data
}: Contact & {
  recover?: boolean;
  callback?: () => void;
}): Promise<Contact> => {
  const endpoint = (await getConfig()).apiContactsService;
  const payload = normalizePayload({ ...data, lastSource: getSource() });
  const response = await request<AxiosResponse<Contact>>(`${endpoint}/${id}`, {
    method: 'PUT',
    data: payload,
  });

  if (response.status === 200) {
    data.callback?.();
  }

  return {
    id,
    ...response.data.data,
  };
};

export const invalidateQueries = [DOCUMENTS_RECURRENTS_QUERY, CONTACTS_QUERY];

export default updateContact;
