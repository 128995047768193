import useTranslations from 'modules/I18n/hooks/useTranslations';
import useTheme from 'modules/Theme/hooks/useTheme';
import { Box, Text } from 'modules/Ui';

import { paymentsMethod as messages } from '../../messages';

const PaymentMethodHeader: React.FC = () => {
  const { t } = useTranslations();
  const theme = useTheme();
  return (
    <Box
      borderBottom={`1px solid ${theme.colors.gray200}`}
      borderTop={`1px solid ${theme.colors.gray200}`}
      padding="0"
      tag="tr"
      textAlign="left"
    >
      <Box scope="col" tag="th">
        <Text
          color="gray800"
          fontSize={13}
          fontWeight="600"
          lineHeight={22}
          textTransform="uppercase"
        >
          {t(messages.tableHeadType)}
        </Text>
      </Box>
      <Box scope="col" tag="th" textTransform="uppercase">
        <Text
          color="gray800"
          fontSize={13}
          fontWeight="600"
          lineHeight={22}
          tag="span"
          textTransform="uppercase"
        >
          {t(messages.tableHeadText)}
        </Text>
      </Box>
      <Box padding="18px 0 15px" scope="col" tag="th" textTransform="uppercase">
        <Text
          color="gray800"
          fontSize={13}
          fontWeight="600"
          lineHeight={22}
          tag="span"
          textTransform="uppercase"
        >
          {t(messages.tableHeadAlias)}
        </Text>
      </Box>
      <Box tag="th" scope="col">
        <Text tag="span" hide>
          {t(messages.tableHeadEdit)}
        </Text>
      </Box>
      <Box tag="th" scope="col">
        <Text tag="span" hide>
          {t(messages.tableHeadDelete)}
        </Text>
      </Box>
    </Box>
  );
};

export default PaymentMethodHeader;
