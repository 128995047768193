import { useSelector } from 'react-redux';

import { selectIsTablet } from 'modules/App/store/selectors';
import useTranslations from 'modules/I18n/hooks/useTranslations';
import { TemplateSettings } from 'modules/Settings/Theme/types';
import { Option } from 'modules/Ui/Form/SelectCommon/types';
import { SelectFormikField } from 'modules/Ui/Formik';

import Text from '../Text';
import OptionLabel from './components/OptionLabel';
import messages from './messages';

interface Props {
  templates: any;
  canCreateOption?: boolean;
  labelAlignLeft?: boolean;
  labelHide?: boolean;
  onCreateOption?: () => void;
  onSelect?: (id: string) => void;
}

const SearchTemplateInput: React.FC<Props> = ({
  canCreateOption = true,
  labelAlignLeft = false,
  templates,
  labelHide = false,
  onSelect,
  onCreateOption,
}) => {
  const isTablet = useSelector(selectIsTablet);
  const { t } = useTranslations();

  const getNoOptionsMessageComponent = () => {
    return <Text textAlign="left">{t(messages.noMatches)}</Text>;
  };

  const options: Option[] | undefined = templates?.map(
    (template: TemplateSettings, index: number) => {
      const option: Option = {
        value: template.id || '',
        label:
          template.name ||
          t(messages.templateName, {
            templateNumber: index + 1,
          }),
        additionalData: template,
      };
      return option;
    }
  );

  const handleOnSelect = (id: string) => {
    if (onSelect) {
      onSelect(id);
    }
  };

  const handleOnAddOption = () => {
    if (!onCreateOption) {
      throw new Error(
        'onCreateOption prop is required when canCreate option is true'
      );
    }
    onCreateOption();
  };

  return (
    <SelectFormikField
      label={
        isTablet ? t(messages.templateLabelTablet) : t(messages.templateLabel)
      }
      labelHide={labelHide}
      data-testid="create-template-select"
      canCreateOption={canCreateOption}
      isClearable={false}
      formatOptionLabel={(option) => {
        return <OptionLabel {...{ option }} />;
      }}
      id="templateId"
      noOptionsMessage={getNoOptionsMessageComponent}
      shouldRenderMobileSelect={false}
      options={options}
      onAddOption={handleOnAddOption}
      onChange={handleOnSelect}
      loadingMessage={() => t(messages.loadingTemplates)}
      menuListText={t(messages.optionLabelAddTemplate)}
      labelAlignLeft={labelAlignLeft}
      width={{ _: '100%', sm: '424px', md: '100%' }}
    />
  );
};

export default SearchTemplateInput;
