// *.app.billin.net o app.billin.net
const publicDomains = [
  // eslint-disable-next-line max-len
  /^https:\/\/(?:[\w\-_]+\.)+(?:)(app\.billin\.net$|app\.billin\.net\/|app\.billin\.net\?)/,
  /^https:\/\/(app\.billin\.net$|app\.billin\.net\/|app\.billin\.net\?)/,
];

export const getCurrentUrl = () => window.location.href;

export const isPublicDomain = () =>
  publicDomains.some((d) => d.test(getCurrentUrl()));
