import { getConfig } from 'modules/App/config';
import { buildTortillaBaseQuery } from 'utils/query/buildTortillaBaseQuery';
import request from 'utils/request';

import { CreatedBusiness } from '../../models/business';

const fetchBusinesses = async (
  {
    type,
    page,
    pageSize,
  }: {
    type: 'regular' | 'bookkeeping' | 'both';
    page: number;
    pageSize: number;
  } = { type: 'regular', page: 0, pageSize: 100 }
): Promise<{ items: CreatedBusiness[]; count: number }> => {
  const pageQuery = buildTortillaBaseQuery({ page, pageSize });
  let searchQuery = '';
  switch (type) {
    case 'regular':
      searchQuery = 'query[users][role][name][$ne]=bookkeeper';
      break;
    case 'bookkeeping':
      searchQuery = 'query[users][role][name]=bookkeeper';
      break;
  }

  const query = [searchQuery, pageQuery].filter(Boolean).join('&');

  const endpoint = (await getConfig()).apiBusinessesService;
  const response = await request<{
    data:
      | CreatedBusiness[]
      | {
          items: CreatedBusiness[];
          count: number;
        };
  }>(`${endpoint}/me?${query}`, {
    method: 'GET',
  });

  if (Array.isArray(response.data.data)) {
    return {
      items: response.data.data,
      count: response.data.data.length,
    };
  }

  return response.data.data;
};
export default fetchBusinesses;
