import { useInvalidateAndUpdate } from 'modules/App/hooks/useInvalidateAndUpdate';
import useTranslations from 'modules/I18n/hooks/useTranslations';
import useNotifications from 'modules/Notifications/hooks/useNotifications';
import { ResponseError } from 'types/response-error.type';
import requestErrorHandler from 'utils/requestErrorHandler';
import { useBlockingMutation } from 'utils/useBlockingMutation';

import createMultiplePayment, {
  invalidateQueries,
} from '../services/createMultiplePayment';
import messages from './messages';

function useCreateMultiplePayment() {
  const notifications = useNotifications();
  const { t } = useTranslations();
  const invalidateAndUpdate = useInvalidateAndUpdate();
  const { mutate } = useBlockingMutation(createMultiplePayment, {
    onSuccess: (response): void => {
      if (response.data.count === 0) {
        notifications.warning(t(messages.createMultiplePartial, { count: 0 }));
      } else if (response.data.count === response.data.requestedCount) {
        notifications.success(messages.createMultipleSuccess.id, { ttl: 5000 });
      } else {
        notifications.success(
          t(messages.createMultiplePartial, { count: response.data.count }),
          { ttl: 5000 }
        );
      }

      invalidateAndUpdate({ invalidateQueries, isAsync: true });
    },
    onError: (error: ResponseError) => {
      const { isNetworkError, networkErrorTranslationKey, responseCode } =
        requestErrorHandler(error);
      if (responseCode === 'limit-exceeded') {
        return notifications.error(messages.limitExceeded.id);
      }
      return notifications.error(
        isNetworkError
          ? networkErrorTranslationKey
          : messages.createMultipleError.id
      );
    },
  });
  return { mutate };
}

export default useCreateMultiplePayment;
