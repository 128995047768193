import { setConfiguration } from 'react-grid-system';

export type Breakpoint = 'xs' | 'sm' | 'md' | 'lg' | 'xl';
export type Breakpoints = {
  [key in Breakpoint]: number;
};

/*
value         |0px     768px    1024px   1280px   1366px
key           |xs      sm       md       lg       xl
screen width  |--------|--------|--------|--------|-------->
range         |   xs   |   sm   |   md   |   lg   |   xl
*/

const breakpoints: Breakpoints = {
  xs: 0, // mobile
  sm: 768, // tablet
  md: 1024, // laptop
  lg: 1280, // laptopL
  xl: 1366, // wide
};

export const breakpointGutters: Breakpoints = {
  xs: 16, // mobile
  sm: 16, // tablet
  md: 16, // laptop
  lg: 24, // laptopL
  xl: 32, // wide
};

export default breakpoints;

export const defaultResponsiveConfig = {
  breakpoints: Object.keys(breakpoints).map((key) => (breakpoints as any)[key]),
  containerWidths: [736, 959, 1208, 1270, 1270],
  defaultScreenClass: 'lg' as Breakpoint,
  maxScreenClass: 'xl' as Breakpoint,
};

setConfiguration(defaultResponsiveConfig);

export function min(breakpoint: number) {
  return `(min-width: ${breakpoint}px)`;
}

export function max(breakpoint: number) {
  return `(max-width: ${breakpoint}px)`;
}

export const up = (key: Breakpoint) => `@media ${min(breakpoints[key])}`;

export const down = (key: Breakpoint) => `@media ${max(breakpoints[key])}`;
