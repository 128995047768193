import { FC } from 'react';

import useTheme from 'modules/Theme/hooks/useTheme';
import { Box } from 'modules/Ui';
import { menuSizes } from 'modules/Ui/Menu/MenuMobile/menuSizes';
import { zIndex } from 'modules/Ui/zIndex';

interface Props {
  isBookkeeper?: boolean;
}

const Toolbar: FC<Props> = ({ children, isBookkeeper }) => {
  const theme = useTheme();
  return (
    <Box
      alignItems="center"
      backgroundColor={isBookkeeper ? 'brand500' : 'gray0'}
      borderBottom={`1px solid ${theme.colors.gray200}`}
      boxSizing="border-box"
      contain="layout"
      display="grid"
      gap="8px"
      gridTemplateColumns="24px 1fr 24px"
      height={`${menuSizes.HEADER_HEIGHT_MOBILE}px`}
      padding="0 16px"
      position="fixed"
      top="0"
      width="100%"
      zIndex={zIndex.MENU_MOBILE}
    >
      {children}
    </Box>
  );
};

export default Toolbar;
