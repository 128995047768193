import { getConfig } from 'modules/App/config';
import buildBaseIdsQuery from 'modules/App/queries/buildBaseIdsQuery';
import {
  DASHBOARD_QUERY,
  DOCUMENTS_QUERY,
  DOCUMENTS_RECURRENTS_QUERY,
  DOCUMENT_TRACKING_QUERY,
  CONTACTS_QUERY,
} from 'modules/App/queries/queries';
import buildFiltersQuery from 'modules/Documents/helpers/buildFiltersQuery';
import {
  DocumentFilters,
  ExpenseCategories,
} from 'modules/Documents/models/document';
import request from 'utils/request';

interface Params {
  ids: string[];
  excludeIds: boolean;
  filters?: DocumentFilters;
  category?: ExpenseCategories;
}

interface Response {
  data: {
    count: number;
  };
}

const updateCategoryDocumentsRecurrents = async ({
  excludeIds,
  ids,
  filters,
  category,
}: Params) => {
  const documentRecurrentEndpoint = (await getConfig())
    .apiDocumentsRecurrentService;

  let query = buildBaseIdsQuery(ids, excludeIds);

  if (excludeIds && filters) {
    query += buildFiltersQuery(filters);
  }

  const response = await request<Response>(
    `${documentRecurrentEndpoint}/update-category?${query}`,
    {
      method: 'PUT',
      data: { category },
    }
  );

  if (response.status < 200 || response.status >= 400) {
    throw new Error('Error updating recurring expense category');
  }

  return response.data;
};

export const invalidateQueries = [
  DOCUMENTS_QUERY,
  DOCUMENTS_RECURRENTS_QUERY,
  DASHBOARD_QUERY,
  DOCUMENT_TRACKING_QUERY,
  CONTACTS_QUERY,
];

export default updateCategoryDocumentsRecurrents;
