import React, { useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { useDebounce } from 'react-use';

import styled from 'styled-components';
import { layout, system } from 'styled-system';

import { PDF_PREVIEW } from 'modules/App/queries/queries';
import { selectIsMobile } from 'modules/App/store/selectors';
import { DefaultValuesResource } from 'modules/DefaultValues/enums';
import useDefaultValues from 'modules/Settings/hooks/useDefaultValues';
import { LoadingIndicator, Box } from 'modules/Ui';

import { fetchTemplateConfigPreview } from '../../services';
import { normalizeValuesForPreview } from '../../Theme/helpers/normalizeValues';
import { TemplateSettingsForm } from '../../Theme/types';

const zoom = system({
  zoom: true,
  transform: true,
});

const Frame = styled<any>('iframe')`
  ${layout};
  ${zoom};
  padding: 0 0 48px 0;
  box-sizing: border-box;
  background: ${(props) => props.theme.colors.gray0};
  transform-origin: 0 0;
  border: none;
`;

interface TemplateSettingsFormExtended extends TemplateSettingsForm {
  logoUrl?: string;
  createTemplateSelect?: string;
  showProductReference?: boolean;
  showLinesWithoutTaxes?: boolean;
}
interface Props {
  templateSettings: TemplateSettingsFormExtended;
}

export const TemplatePreview: React.FC<Props> = ({ templateSettings }) => {
  const [htmlBlob, setHtmlBlob] = React.useState<string>();
  const { resourceDefaultValues } = useDefaultValues();

  const defaultValues = resourceDefaultValues(DefaultValuesResource.Invoice);

  const showProductReference = defaultValues?.showProductReference ?? false;
  const showLinesWithoutTaxes = defaultValues?.showLinesWithoutTaxes ?? false;

  const [nextSettings, setNextSettings] = useState({
    ...templateSettings,
    showProductReference,
    showLinesWithoutTaxes,
  });
  useDebounce(
    () =>
      setNextSettings({
        ...templateSettings,
        showProductReference,
        showLinesWithoutTaxes,
      }),
    300,
    [templateSettings]
  );

  const normalizedSettings = useMemo(
    () => normalizeValuesForPreview(nextSettings),
    [nextSettings]
  );

  const isMobile = useSelector(selectIsMobile);
  const { isLoading, refetch } = useQuery(
    [PDF_PREVIEW, nextSettings],
    () => fetchTemplateConfigPreview(normalizedSettings),
    {
      onSuccess: (response) => {
        const oldHtmlBlob = htmlBlob;
        const parsed = isMobile
          ? response.replace('overflow: hidden;', '')
          : response;
        const blob = new Blob([parsed], { type: 'text/html' });
        const source = URL.createObjectURL(blob);
        setHtmlBlob(source);
        setTimeout(() => oldHtmlBlob && URL.revokeObjectURL(oldHtmlBlob), 60);
      },
    }
  );

  useEffect(() => {
    if (templateSettings.createTemplateSelect) {
      refetch();
    }
  }, [templateSettings.createTemplateSelect]);

  if (isLoading && !htmlBlob) return <LoadingIndicator />;

  return (
    <Box
      backgroundColor="gray200"
      boxSizing="border-box"
      height={{ md: '100%' }}
      padding="12px"
    >
      <Frame
        // https://www.aleksandrhovhannisyan.com/blog/react-iframes-back-navigation-bug/
        key={htmlBlob}
        src={htmlBlob}
        // compensating the zoom when iframe is shown inside popup
        // when we show the template preview in a pop up,
        // we have to adjust the size in order to make it fully visible
        templateType={templateSettings.templateType}
        height={{ _: '2400px', sm: '1450px', md: '1110px' }}
        width={{ _: '200%', sm: '133.1%', md: '100%' }}
        zoom={{ _: '0.5', sm: '0.75', md: '1' }}
        transform={{ _: 'scale(0.5)', sm: 'scale(0.75)', md: 'scale(1)' }}
      />
    </Box>
  );
};
