import useTheme from 'modules/Theme/hooks/useTheme';

import IconByString, { IconByStringProps } from '../Icon/IconType/IconByString';

const IconMenuInvoice = (props: IconByStringProps) => {
  const { active, ...rest } = props;
  const theme = useTheme();
  return (
    <IconByString {...{ active, ...rest }}>
      <path
        clipRule="evenodd"
        d="M19 23V1H3v22h16ZM4 2h14v20H4V2Z"
        fill="currentColor"
        fillRule="evenodd"
      />
      <rect
        className="icon-hover-effect"
        fill={active ? theme.colors.brand500 : 'transparent'}
        height="8"
        rx="4"
        width="8"
        x="7"
        y="4"
      />
      <path fill="currentColor" d="M7 15h8v1H7zM7 19h8v1H7zM21 3h1v20h-1z" />
      <path
        className="icon-hover-effect"
        clipRule="evenodd"
        d="M8 8a3 3 0 1 0 6 0 3 3 0 0 0-6 0Zm3-4a4 4 0 1 0 0 8 4 4 0 0 0 0-8Z"
        fill={active ? theme.colors.brand500 : 'currentColor'}
        fillRule="evenodd"
        shapeRendering="geometricPrecision"
      />
    </IconByString>
  );
};

export default IconMenuInvoice;
