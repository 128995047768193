import { FC } from 'react';
import { Hidden, Visible } from 'react-grid-system';

import styled from 'styled-components';

import useTranslations from 'modules/I18n/hooks/useTranslations';
import useTheme from 'modules/Theme/hooks/useTheme';
import { Box, Text, Img, Link } from 'modules/Ui';
import { IconArrowSecondary } from 'modules/Ui/Icons';
import { CommercialNotificationCategoryType } from 'modules/UserNotifications/models/UserNotificationPaylodType';
import { ROUTES } from 'modules/UserNotifications/routesMap';

import messages from './messages';
import { useVisualsOfType } from './useVisualsOfType';

interface NotificationsContentProps {
  body?: string;
  category: CommercialNotificationCategoryType;
  imageUrl?: string;
  title: string;
  onClose: () => void;
}

const StyledLink = styled(Link)`
  @media (hover: hover) {
    &:hover {
      color: ${(props) => props.theme.colors.brand700};
    }
  }
`;

export const NotificationsContent: FC<NotificationsContentProps> = ({
  body,
  category,
  imageUrl,
  title,
  onClose,
}) => {
  const theme = useTheme();
  const { t } = useTranslations();
  const visualsOfType = useVisualsOfType(category);
  return (
    <>
      <Hidden xs>
        {imageUrl && (
          <Box
            alignItems="center"
            display="flex"
            height="72px"
            justifyContent="center"
            marginTop="10px"
            overflow="hidden"
            width="90px"
          >
            <Img maxHeight="100%" width="100%" src={`${imageUrl}`} />
          </Box>
        )}
      </Hidden>
      <Visible xs>
        <Box
          alignItems="center"
          backgroundColor={visualsOfType.bgColor}
          borderRadius="50%"
          display="flex"
          justifyContent="center"
          size="40px"
        >
          {visualsOfType.icon}
        </Box>
      </Visible>
      <Box overflow="hidden">
        <Box
          backgroundColor={visualsOfType.bgColor}
          color={visualsOfType.color}
          display="block"
          fontSize={10}
          fontWeight="600"
          height="16px"
          lineHeight={17}
          marginBottom="6px"
          padding="1px 4px"
          textTransform="uppercase"
          width="fit-content"
        >
          {visualsOfType.label}
        </Box>
        <Text color="primary500" fontSize={14} lineHeight={16} fontWeight={600}>
          {title}
        </Text>
        {body && (
          <Text
            ellipsisLines={2}
            fontSize={13}
            hasEllipsis
            lineHeight={18}
            whiteSpace="normal"
          >
            {body}
          </Text>
        )}
        <Text textAlign="right">
          <StyledLink
            alignItems="center"
            color={theme.colors.brand500}
            columnGap="6px"
            display="grid"
            fontSize={theme.fontSizes[13]}
            gridTemplateColumns="1fr 20px"
            hoverUnderline
            textDecoration="underline"
            to={ROUTES.NOTIFICATIONS_NEW_FEATURE}
            onClick={onClose}
          >
            {t(messages.seeMore)}
            <IconArrowSecondary size={14} color="inherit" />
          </StyledLink>
        </Text>
      </Box>
    </>
  );
};
