export enum StatusInfo {
  PAID = 'PAID',
  ISSUED = 'ISSUED',
  DUE = 'DUE',
  DRAFT = 'DRAFT',
  VOID = 'VOID',
  TEST = 'TEST',
  CORRECTED = 'CORRECTED',
  CORRECTIVE = 'CORRECTIVE',
  REPLACED = 'REPLACED',
}

export enum StatusInfoUI {
  PAID = 'PAID',
  PARTIALLY_PAID = 'PARTIALLY_PAID',
  NOT_PAID = 'NOT_PAID',
  ISSUED = 'ISSUED',
  DUE = 'DUE',
  DRAFT = 'DRAFT',
  PENDING = 'PENDING',
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
  PAID_EXPENSE = 'PAID_EXPENSE',
  PARTIALLY_PAID_EXPENSE = 'PARTIALLY_PAID_EXPENSE',
  RECEIVED = 'RECEIVED',
  VOID = 'VOID',
  TEST = 'TEST',
  TO_REVIEW = 'TO_REVIEW',
  CORRECTIVE = 'CORRECTIVE',
  CORRECTED = 'CORRECTED',
  REPLACED = 'REPLACED',
  INVOICED = 'INVOICED',
  NOINVOICED = 'NOINVOICED',
  CANCELLED = 'CANCELLED',
  DELIVERED = 'DELIVERED',
}

export enum StatusInfoUIPayments {
  LINKED = 'LINKED',
  PARTIALLY_LINKED = 'PARTIALLY_LINKED',
  NOT_LINKED = 'NOT_LINKED',
}
