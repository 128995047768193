import { FC, ReactNode } from 'react';

import { color, border, flexbox, space, layout } from 'styled-system';

import styled from 'modules/Theme/styled-components';

import { ButtonProps } from '../Form/Button/Button';
import HtmlButton from '../Html/Button';
import IconCross from '../Icons/IconCross';
import Text from '../Text';

interface TagProps extends ButtonProps {
  children: ReactNode;
  ellipsis?: boolean;
  iconColor?: string;
  iconSize?: number;
  onClick?: () => void;
}

const StyledTag = styled(HtmlButton)`
  ${color}
  ${flexbox}
  ${space}
  ${border}
  ${layout}

  @media (hover: hover) {
    &:hover {
      color: ${(props) => props.theme.colors.gray800};
    }
  }
  &:focus {
    color: ${(props) => props.theme.colors.gray800};
  }
`;

const Tag: FC<TagProps> = ({
  accessibleText,
  children,
  ellipsis,
  iconColor,
  iconSize,
  onClick,
  ...rest
}) => {
  return (
    <StyledTag
      {...rest}
      accessibleText={accessibleText}
      alignItems="center"
      backgroundColor="auxiliary100"
      color="gray600"
      display="flex"
      height="32px"
      justifyContent="center"
      onClick={onClick}
    >
      <Text
        aria-hidden="true"
        color="inherit"
        flex="1"
        fontSize={12}
        lineHeight={16}
        hasEllipsis={ellipsis}
        tag="span"
      >
        {children}
      </Text>
      <IconCross
        color={iconColor ?? 'inherit'}
        marginLeft="8px"
        size={iconSize ?? 16}
      />
    </StyledTag>
  );
};

StyledTag.defaultProps = {
  padding: '8px',
};

export default Tag;
