import { useSelector } from 'react-redux';

import { useFormikContext } from 'formik';

import { hasElectronicDocumentProvider } from 'modules/Business/businessUtils';
import { selectCurrentBusiness } from 'modules/Business/Current/selectors';
import useVatNumberTypeOptions from 'modules/Documents/hooks/useVatNumberTypeOptions';
import useTranslations from 'modules/I18n/hooks/useTranslations';
import { FormikField, SelectFormikField, Text } from 'modules/Ui';
import Fieldset from 'modules/Ui/Form/Fieldset';
import CountryFormikField from 'modules/Ui/Formik/CountryFormikField';
import ProvinceFormikField from 'modules/Ui/Formik/ProvinceFormikField';

import messages from '../messages';
import { SubForm } from '../types';

const AddressClientForm: React.FC<SubForm> = ({
  validNifClient = false,
  validAddressClient = false,
}) => {
  const { t } = useTranslations();
  const formik = useFormikContext();
  const vatNumberOptions = useVatNumberTypeOptions();
  const business = useSelector(selectCurrentBusiness);
  const hasElectronicProvider = hasElectronicDocumentProvider(business);

  return (
    <Fieldset
      display={{ sm: 'flex' }}
      flexWrap={{ sm: 'wrap' }}
      id="contact-data-address"
      justifyContent={{ sm: 'space-between' }}
      legend={t(messages.dataClient)}
      legendMarginBottom="0"
    >
      <Text marginBottom="20px" lineHeight={20} fontSize={16}>
        {t(messages.infoDataClient)}
      </Text>
      {!validNifClient && (
        <>
          <SelectFormikField
            required
            id="contact.vatNumberType"
            data-testid="contact.vatNumberType"
            name="contact.vatNumberType"
            label={t(messages.vatNumberType)}
            options={vatNumberOptions}
            width={{ sm: 'calc(50% - 12px)' }}
          />
          <FormikField
            id="contact.vatNumber"
            data-testid="contact.vatNumber"
            name="contact.vatNumber"
            required
            label={t(messages.nif)}
            width={{ sm: 'calc(50% - 12px)' }}
          />
        </>
      )}
      {!validAddressClient && (
        <>
          <FormikField
            data-testid="contact.address.postalAddress"
            required
            disabled={
              hasElectronicProvider &&
              (formik.initialValues as any).contact.address.postalAddress !== ''
            }
            id="contact.address.postalAddress"
            label={t(messages.postalAddress)}
            placeholder={t(messages.postalAddress)}
            name="contact.address.postalAddress"
            width={{ sm: '66.6%' }}
          />
          <FormikField
            data-testid="contact.address.postalCode"
            id="contact.address.postalCode"
            required
            disabled={
              hasElectronicProvider &&
              (formik.initialValues as any).contact.address.postalCode !== ''
            }
            label={t(messages.postalCode)}
            placeholder={t(messages.postalCode)}
            name="contact.address.postalCode"
            maxLength={20}
            width={{ sm: 'calc(33.3% - 20px)' }}
          />
          <FormikField
            data-testid="contact.address.city"
            required
            disabled={
              hasElectronicProvider &&
              (formik.initialValues as any).contact.address.city !== ''
            }
            id="contact.address.city"
            label={t(messages.city)}
            name="contact.address.city"
            width={{ sm: '33.3%' }}
          />
          <ProvinceFormikField
            data-testid="contact.address.province"
            id="contact.address.province"
            isSearchable
            label={t(messages.province)}
            name="contact.address.province"
            placeholder={t(messages.provincePlaceholder)}
            enabled={
              !hasElectronicProvider ||
              (formik.initialValues as any).contact.address.province === ''
            }
            required
            width={{
              sm: 'calc(33.3% - 20px)',
            }}
            country={(formik.values as any).contact.address.country || 'ES'}
          />
          <CountryFormikField
            id="contact.address.country"
            data-testid="contact.address.country"
            enabled={
              !hasElectronicProvider ||
              (formik.initialValues as any).contact.address.country === ''
            }
            required
            label={t(messages.country)}
            onChange={() =>
              formik.setFieldValue('contact.address.province', '')
            }
            name="contact.address.country"
            width={{
              sm: 'calc(33.3% - 20px)',
            }}
          />
        </>
      )}
    </Fieldset>
  );
};

export default AddressClientForm;
