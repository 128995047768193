import { FC } from 'react';

import { UserPermissions } from 'modules/Auth/constants';
import useTranslations from 'modules/I18n/hooks/useTranslations';
import { Dialog } from 'modules/Modals';
import { Button, Text } from 'modules/Ui';

import { ScopeTypes } from '../services';
import messages from './messages';
import { ChangePlanWarningModalProps } from './types';

function warningMessageKeys(warningScopes?: ScopeTypes[]): {
  subtitle?: { id: string };
  text: { id: string };
} {
  const remittanceCreationWarning =
    warningScopes?.length === 1 &&
    warningScopes.includes(UserPermissions.REMITTANCES_CREATE);
  if (remittanceCreationWarning) {
    return {
      subtitle: messages.remittanceCreationWarningSubtitle,
      text: messages.remittanceCreationWarningText,
    };
  }
  return {
    subtitle: messages.downgrade,
    text: messages.downgradeB,
  };
}

const ChangePlanWarningModal: FC<ChangePlanWarningModalProps> = ({
  warningScopes,
  onClose,
  onConfirm,
}) => {
  const { t } = useTranslations();

  const warningMessages = warningMessageKeys(warningScopes);
  const subtitle = warningMessages.subtitle
    ? t(warningMessages.subtitle)
    : undefined;
  const text = t(warningMessages.text);

  return (
    <Dialog
      id="changePlanWarningModal"
      onClose={onClose}
      title={t(messages.title)}
      subtitle={subtitle}
      buttonPrimary={
        <Button
          data-testid="changePlanWarningModal-submit"
          marginBottom={{ _: '16px', sm: '0' }}
          onClick={onClose}
          variant="secondary"
        >
          {t(messages.cancel)}
        </Button>
      }
      buttonSecondary={
        <Button
          data-testid="changePlanWarningModal-cancel"
          onClick={() => {
            onConfirm();
            onClose();
          }}
          variant="stroke"
        >
          {t(messages.continue)}
        </Button>
      }
    >
      <Text lineHeight={22}>{text}</Text>
    </Dialog>
  );
};
export default ChangePlanWarningModal;
