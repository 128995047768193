import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';

import { CHANGE_PLAN_PREVIEW_QUERY } from 'modules/App/queries/queries';
import useNotifications from 'modules/Notifications/hooks/useNotifications';
import { selectPlanType } from 'modules/Subscriptions/detail/selectors';
import { changePlanPreview } from 'modules/Subscriptions/services';

import { texts } from './messages';

export function useChangePlanPreview(productId: string) {
  const notifications = useNotifications();
  const currentPlan = useSelector(selectPlanType);
  const { isFetching, data } = useQuery(
    [CHANGE_PLAN_PREVIEW_QUERY, productId],
    () => changePlanPreview({ productId }),
    {
      onError: () => {
        notifications.error(texts.error.id);
      },
      cacheTime: 1,
      retry: 0,
    }
  );

  return { isFetching, data, currentPlan };
}
