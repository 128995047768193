import { FC, useEffect } from 'react';

import { FormikProps } from 'formik';

import useTranslations from 'modules/I18n/hooks/useTranslations';
import { PasswordFormikField, MandatoryText } from 'modules/Ui';

import { messages } from './messages';

interface FormProps {
  formik: FormikProps<any>;
  errors: {
    field: string;
    message: string;
  };
}

const ChangePasswordForm: FC<FormProps> = ({ formik, errors }) => {
  const { t } = useTranslations();

  useEffect(() => {
    if (errors.message !== '') {
      formik.setFieldError(errors.field, errors.message);
    }
  }, [errors]);

  return (
    <>
      <form id="changePassWordForm" noValidate onSubmit={formik.handleSubmit}>
        <PasswordFormikField
          id="currentPassword"
          label={t(messages.current)}
          name="currentPassword"
          placeholder={t(messages.current)}
          error={errors.message}
          required
        />
        <PasswordFormikField
          helpText={t(messages.rules)}
          id="newPassword"
          label={t(messages.new)}
          marginBottom="6px"
          name="newPassword"
          placeholder={t(messages.new)}
          required
        />
        <PasswordFormikField
          id="newPasswordConfirmation"
          label={t(messages.newConfirmation)}
          name="newPasswordConfirmation"
          placeholder={t(messages.newConfirmation)}
          required
        />
        <MandatoryText marginBottom="24px" />
      </form>
    </>
  );
};

export default ChangePasswordForm;
