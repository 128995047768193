import { getConfig } from 'modules/App/config';
import buildBaseIdsQuery from 'modules/App/queries/buildBaseIdsQuery';
import UserInvitation from 'modules/UserInvitations/models/UserInvitation';
import request from 'utils/request';

interface Response {
  data: UserInvitation;
}

interface ResendInvitationParams {
  ids: string[];
  excludeIds: boolean;
}

const cancelUserInvitation = async (params: ResendInvitationParams) => {
  const config = await getConfig();
  const { excludeIds, ids } = params;

  const query = buildBaseIdsQuery(ids, excludeIds);

  const endpoint = config.apiUserInvitationsService;
  const response = await request<Response>(`${endpoint}?${query}`, {
    method: 'DELETE',
  });
  return response.data.data;
};

export default cancelUserInvitation;
