import { useDispatch } from 'react-redux';

import { fetchAppData, setIsLoading } from 'modules/App/actions';
import { useInvalidateAndUpdate } from 'modules/App/hooks/useInvalidateAndUpdate';
import useNotifications from 'modules/Notifications/hooks/useNotifications';
import {
  reactivateSubscription,
  invalidateQueries,
} from 'modules/Subscriptions/services';
import { useBlockingMutation } from 'utils/useBlockingMutation';

import messages from './messages';

export function useReactivateSubscription() {
  const dispatch = useDispatch();
  const notifications = useNotifications();
  const invalidateAndUpdate = useInvalidateAndUpdate();

  const { mutate } = useBlockingMutation(reactivateSubscription, {
    onSuccess: async () => {
      dispatch(setIsLoading({ isLoading: true }));
      setTimeout(() => {
        dispatch(fetchAppData.request());
        dispatch(setIsLoading({ isLoading: false }));
        notifications.success(messages.success.id);
        invalidateAndUpdate({ invalidateQueries });
      }, 2000);
    },
    onError: () => {
      notifications.error(messages.error.id);
    },
  });
  return mutate;
}
