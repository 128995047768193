import { css } from 'modules/Theme/styled-components';

import breakpoints, { min } from '../breakpoints';

const Wrapper = css`
  background-color: ${(props) => props.theme.colors.gray0};
  padding: var(
    --documents-wrapper-padding,
    calc(${(props) => props.theme.baseNumber} * 2)
      ${(props) => props.theme.baseNumber}
  );
  transform: translateX(calc(${(props) => props.theme.baseNumber} * -2));
  width: calc(100% + ${(props) => props.theme.baseNumber} * 2);

  @media ${min(breakpoints.sm)} {
    --documents-wrapper-padding:  calc(${(props) =>
      props.theme.baseNumber} * 3);
    margin: 0 0 calc(${(props) => props.theme.baseNumber} * 5);
    transform: initial;
    width: initial;
  }

  @media ${min(breakpoints.md)} {
    --documents-wrapper-padding: calc(${(props) => props.theme.baseNumber} * 3)
  }

  @media ${min(breakpoints.lg)} {
    --documents-wrapper-padding: calc(${(props) => props.theme.baseNumber} * 3)
      calc(${(props) => props.theme.baseNumber} * 4)
  }

  @media ${min(breakpoints.xl)} {
    ${(props) => props.theme.baseNumber}
    --documents-wrapper-padding: calc(${(props) => props.theme.baseNumber} * 3)
      calc(${(props) => props.theme.baseNumber} * 5);
  }
`;

export default Wrapper;
