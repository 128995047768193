import { useSelector } from 'react-redux';

import { Roles } from 'modules/Users/models/role';
import { selectUser } from 'modules/Users/selectors';

export default function useIsBookkeeperUser(): boolean | undefined {
  const { isBookkeeper, email } = useSelector(selectUser);
  if (email === undefined) {
    return undefined;
  }
  return isBookkeeper;
}

export function useIsBookkeeperLoggedInClient(): boolean {
  const isBookkeeper = useIsBookkeeperUser();
  const { role } = useSelector(selectUser);
  return Boolean(isBookkeeper) && role?.name === Roles.BOOKKEEPER;
}

export function useIsBookkeeping(): boolean | undefined {
  const isBookkeeper = useIsBookkeeperUser();
  const loggedInClient = useIsBookkeeperLoggedInClient();
  return isBookkeeper && !loggedInClient;
}
