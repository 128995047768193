import { Text } from 'modules/Ui';

interface ButtonArrowProps {
  active?: boolean;
  number?: number | string;
}

const Number = (props: ButtonArrowProps) => {
  const { number, active } = props;
  return (
    <Text
      color={active ? 'gray800' : undefined}
      display="block"
      tag={active ? 'strong' : 'span'}
      textAlign="center"
      width="24px"
      {...{ active }}
    >
      {number}
    </Text>
  );
};

export default Number;
