import { useQuery } from 'react-query';

import { PROVINCES } from 'modules/App/queries/queries';
import CacheTimes from 'utils/query/cacheTimes';

import { Provinces } from '../models/provinces';
import fetchProvinces from '../services/fetchProvinces';

const useProvinces = (countryCode?: string) => {
  const { data } = useQuery(
    [PROVINCES, countryCode],
    () => {
      return fetchProvinces(countryCode);
    },
    {
      enabled: !!countryCode,
      staleTime: CacheTimes.INFINITY,
      refetchOnMount: false,
    }
  );

  const response: Provinces | undefined = data;
  return {
    provinces: response,
  };
};

export default useProvinces;
