import useTheme from 'modules/Theme/hooks/useTheme';

import IconByString, { IconByStringProps } from '../Icon/IconType/IconByString';

const IconMenuProducts = (props: IconByStringProps) => {
  const { active, ...rest } = props;
  const theme = useTheme();
  return (
    <IconByString {...{ active, ...rest }}>
      <path
        clipRule="evenodd"
        d="M19 2H5v1h14V2ZM3 5h18v1H3V5ZM2 9.071h20V21.93H2V9.07ZM1 23V8h22v15H1Zm7.5-12a2.5 2.5 0 0 0 2.5 2.5h2a2.5 2.5 0 0 0 2.5-2.5h-1a1.5 1.5 0 0 1-1.5 1.5h-2A1.5 1.5 0 0 1 9.5 11h-1Z"
        fill="currentColor"
        fillRule="evenodd"
      />
      <rect
        className="icon-hover-effect"
        fill={active ? theme.colors.brand500 : 'transparent'}
        height="3"
        width="20"
        x="2"
        y="19"
      />
      <rect
        className="icon-hover-effect"
        fill={active ? theme.colors.brand500 : 'currentColor'}
        height="1"
        width="20"
        x="2"
        y="18"
      />
    </IconByString>
  );
};

export default IconMenuProducts;
