import { FC } from 'react';

import { useField } from 'formik';

import useTranslations from 'modules/I18n/hooks/useTranslations';
import { Box, Text } from 'modules/Ui';
import ColorFormikField from 'modules/Ui/Formik/ColorFormikField';

import messages, { templateColors } from '../../messages';

const TemplateColors: FC<{}> = () => {
  const { t } = useTranslations();
  const [field] = useField('templateType');
  const templateType = field.value;
  return (
    <Box fadeIn>
      <Text
        color="gray800"
        fontSize={18}
        fontWeight="600"
        lineHeight={20}
        marginBottom="4px"
        tag="h3"
      >
        {t(messages.templatePersonalization)}
      </Text>
      <Box
        aria-labelledby="template-colors"
        border="none"
        marginBottom="24px"
        padding="0"
        tag="fieldset"
      >
        <Text
          fontSize={14}
          id="template-colors"
          lineHeight={18}
          marginBottom="24px"
        >
          {t(messages.templatePersonalizationText)}
        </Text>
        <Box display="flex" alignItems="center" marginBottom="16px">
          <ColorFormikField name={`colors.${templateType}.primary`} label="" />
          <Text fontSize={14} lineHeight={17} color="gray800">
            {t(templateColors[`${templateType}-primary`])}
          </Text>
        </Box>
        {`${templateType}-secondary` in templateColors && (
          <Box display="flex" alignItems="center" marginBottom="16px">
            <ColorFormikField
              name={`colors.${templateType}.secondary`}
              label=""
            />
            <Text fontSize={14} lineHeight={17} color="gray800">
              {t(templateColors[`${templateType}-secondary`])}
            </Text>
          </Box>
        )}

        {`${templateType}-tertiary` in templateColors && (
          <Box display="flex" alignItems="center">
            <ColorFormikField
              name={`colors.${templateType}.terciary`}
              label=""
            />
            <Text fontSize={14} lineHeight={17} color="gray800">
              {t(templateColors[`${templateType}-tertiary`])}
            </Text>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default TemplateColors;
