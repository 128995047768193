import { useSelector } from 'react-redux';

import { selectCurrentBusiness } from 'modules/Business/Current/selectors';
import { Document } from 'modules/Documents/models/document';

export enum SuggestionsFields {
  NombreCliente = 'NombreCliente',
  NumeroDocumento = 'NumeroDocumento',
  NombreFiscalEmisor = 'NombreFiscalEmisor',
}

export enum SuggestionsFieldsType {
  name = 'contactName',
  invoiceNumber = 'invoiceNumber',
  fiscalName = 'fiscalName',
}

type SuggestionMapType = {
  [key: string]: string;
};

export const SuggestionMap: SuggestionMapType = {
  [SuggestionsFields.NombreCliente]: SuggestionsFieldsType.name,
  [SuggestionsFields.NumeroDocumento]: SuggestionsFieldsType.invoiceNumber,
  [SuggestionsFields.NombreFiscalEmisor]: SuggestionsFieldsType.fiscalName,
};

export default function useSuggestionsData(document: Document) {
  const { fiscalName } = useSelector(selectCurrentBusiness);

  return [
    {
      id: document.contact.fiscalName,
      display: SuggestionsFields.NombreCliente,
    },
    {
      id: document.identifier,
      display: SuggestionsFields.NumeroDocumento,
    },
    {
      id: fiscalName,
      display: SuggestionsFields.NombreFiscalEmisor,
    },
  ];
}
