enum ActionTypes {
  SET_LOCALE = 'gofre/app/SET_LOCALE',
}

export const SPANISH = 'es-ES';
export const BASQUE = 'es-EU';
export const COLOMBIAN = 'es-CO';

export const EURO = 'EUR';
export const DOLLAR = 'USD';
export const EURO_SYMBOL = '€';
export const DOLLAR_SYMBOL = '$';

export default ActionTypes;
