import { FC, useCallback } from 'react';
import { Hidden } from 'react-grid-system';

import useTranslations from 'modules/I18n/hooks/useTranslations';
import {
  useCloseModal,
  Dialog,
  MODAL_WIDTH_SMALLER,
  useOpenModal,
} from 'modules/Modals';
import { ModalTypes } from 'modules/Modals/constants';
import useEditPayment from 'modules/Payments/hooks/useEditPayment';
import { Box, Button } from 'modules/Ui';

import DocumentPaymentForm, {
  FormPayment,
} from '../DocumentPaymentForm/DocumentPaymentForm';
import { ButtonDelete } from './components/ButtonDelete';
import messages from './messages';
import { EditPaymentModalProps } from './types';

const EditPaymentModal: FC<EditPaymentModalProps> = (props) => {
  const {
    payment: {
      id: paymentId,
      amount,
      method,
      operationDate,
      accountingAccount,
      type,
      documents,
    },
    pendingAmount,
    inMemory,
    onSuccess,
    onDelete,
  } = props;
  const { t } = useTranslations();
  const closeModal = useCloseModal();
  const openModal = useOpenModal();
  const { mutate } = useEditPayment();

  const onCreateAccount = useCallback(
    (values: any) => {
      const currentProps = {
        ...props,
        amount: values.amount ? Number(values.amount) : 0,
        operationDate: values.operationDate,
        paymentMethod: values.method,
        accountingAccountId: values.accountingAccountId,
      };
      openModal({
        type: ModalTypes.ACCOUNTING_ACCOUNT,
        onCancel: () => {
          openModal({
            type: ModalTypes.EDIT_PAYMENT,
            ...currentProps,
          });
        },
        onCreateAccountingAccount: (newAccountingAccount) => {
          openModal({
            type: ModalTypes.EDIT_PAYMENT,
            ...currentProps,
            accountingAccountId: newAccountingAccount.id,
          });
        },
      });
    },
    [props]
  );
  const onCancelDelete = useCallback(() => {
    openModal({
      type: ModalTypes.EDIT_PAYMENT,
      ...props,
    });
  }, [props]);

  const cancelButton = (
    <Hidden xs>
      <Button
        data-testid="edit-payment-cancel"
        marginBottom={{ _: '16px', sm: '0' }}
        onClick={closeModal}
        variant="stroke"
      >
        {t(messages.cancel)}
      </Button>
    </Hidden>
  );

  const editButton = (
    <Button
      data-testid="edit-payment-submit"
      variant="secondary"
      type="submit"
      form="update-payment-method"
    >
      {t(messages.confirmText)}
    </Button>
  );

  const hasDocumentAssociated = documents?.length > 0;
  return (
    <Dialog
      id="edit-payment-modal"
      dialogWidth={{ sm: MODAL_WIDTH_SMALLER }}
      type="fullScreen"
      title={t(messages.title)}
      onClose={closeModal}
      buttonPrimary={editButton}
      buttonSecondary={cancelButton}
      content={
        <Box
          display="grid"
          gridTemplateRows={{ _: '1fr 48px', sm: 'initial' }}
          height={{ _: '100%', sm: 'initial' }}
        >
          <DocumentPaymentForm
            {...{
              handleSubmit: async (values: FormPayment) => {
                if (!values.method) {
                  throw new Error('Method is required');
                }
                if (!inMemory) {
                  mutate({
                    id: paymentId,
                    amount: values.amount ?? 0,
                    method: values.method,
                    operationDate: values.operationDate,
                    accountingAccountId: values.accountingAccountId,
                  });
                }
                if (onSuccess) {
                  onSuccess({
                    accountingAccountAlias: values.accountingAccountAlias,
                    amount: values.amount ?? 0,
                    paymentMethod: values.method,
                    operationDate: values.operationDate,
                    accountingAccountId: values.accountingAccountId,
                  });
                }
                closeModal();
              },
              onCreateAccount,
              maxAmount: hasDocumentAssociated
                ? amount + pendingAmount
                : undefined,
              initialValues: {
                amount,
                method,
                operationDate: operationDate
                  ? new Date(operationDate)
                  : new Date(),
                accountingAccountId: accountingAccount?.id,
                type,
                accountingAccountAlias: accountingAccount?.alias,
              },
            }}
          />
          <Box justifySelf={{ _: 'center', sm: 'initial' }}>
            <ButtonDelete
              paymentId={paymentId}
              onClose={onCancelDelete}
              inMemory={inMemory}
              onDelete={onDelete}
            />
          </Box>
        </Box>
      }
    />
  );
};

export default EditPaymentModal;
