import { salesEqualizationTax, TaxKey } from '../../Taxes';

export function getSalesEqualizationTax(key?: TaxKey): number {
  switch (key) {
    case TaxKey.IVA_21:
      return salesEqualizationTax[TaxKey.IVA_21];
    case TaxKey.IVA_10:
      return salesEqualizationTax[TaxKey.IVA_10];
    case TaxKey.IVA_7_5:
      return salesEqualizationTax[TaxKey.IVA_7_5];
    case TaxKey.IVA_5:
      return salesEqualizationTax[TaxKey.IVA_5];
    case TaxKey.IVA_4:
      return salesEqualizationTax[TaxKey.IVA_4];
    case TaxKey.IVA_2:
      return salesEqualizationTax[TaxKey.IVA_2];
    default:
      return 0;
  }
}
