import { useQuery } from 'react-query';

import { CONTACTS_QUERY } from 'modules/App/queries/queries';
import { DataTableSortOrder } from 'modules/Ui/DataTable/DataTable.models';
import CacheTimes from 'utils/query/cacheTimes';

import fetchContacts from '../List/services/fetchContacts';
import { ContactType } from '../models';

const useLastUsedContacts = (searchTerm: string) => {
  const { isLoading, data } = useQuery(
    [CONTACTS_QUERY, { searchTerm }],
    () =>
      fetchContacts({
        page: 0,
        pageSize: 3,
        searchTerm,
        clientType: ContactType.CUSTOMER,
        sortBy: {
          order: DataTableSortOrder.DESC,
          field: 'lastUsageAt',
        },
      }),
    {
      refetchOnMount: false,
      staleTime: CacheTimes.FIFTEEN_MINUTES,
    }
  );

  return { isLoading, contacts: data?.items };
};

export default useLastUsedContacts;
