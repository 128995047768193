import { FC } from 'react';

import { MarkDown } from 'modules/Ui';
import SmallPill from 'modules/Ui/SmallPill/SmallPill';

interface Props {
  item: string;
}

const PlansInfoItem: FC<Props> = ({ item }) => {
  const regextTag = /\[.+\]/;
  const hasCustomTag = regextTag.test(item);
  return (
    <MarkDown
      className={hasCustomTag ? 'new-functionality' : ''}
      color="gray600"
      fontSize={14}
      lineHeight={18}
      text={item}
      components={{
        strong: (props: any) => {
          const match = props.children[0].match(regextTag);
          const customMessage =
            match && match[0] && match[0].replace(/\[|\]/g, '');

          return customMessage ? (
            <SmallPill
              backgroundColor="brand500"
              fontSize={10}
              lineHeight={16}
              fontWeight={600}
              borderRadius={4}
            >
              {customMessage}
            </SmallPill>
          ) : (
            <strong>{props.children}</strong>
          );
        },
      }}
    />
  );
};

export default PlansInfoItem;
