import { FC } from 'react';

import {
  AccountingAccount,
  AccountingAccountTypeEnum,
} from 'modules/AccountingAccounts/models';

import {
  BankAccountForm,
  AccountingAccountBankAccountForm,
} from '../../BankAccountForm';
import {
  CashRegisterForm,
  AccountingAccountCashRegisterForm,
} from '../../CashRegisterForm';
import {
  CreditCardForm,
  AccountingAccountCreditCardForm,
} from '../../CreditCardForm';
import { ButtonDelete } from './ButtonDelete';

export type AccountingAccountForm =
  | AccountingAccountBankAccountForm
  | AccountingAccountCashRegisterForm
  | AccountingAccountCreditCardForm;

interface Props {
  onSubmit: (accountingAccount: AccountingAccountForm) => void;
  account: AccountingAccount;
}

export const UpdateForm: FC<Props> = ({ onSubmit, account }) => {
  return (
    <>
      {account.type === AccountingAccountTypeEnum.BANK_ACCOUNT && (
        <BankAccountForm handleSubmit={onSubmit} account={account} isEditing />
      )}
      {account.type === AccountingAccountTypeEnum.CREDIT_CARD && (
        <CreditCardForm handleSubmit={onSubmit} account={account} isEditing />
      )}
      {account.type === AccountingAccountTypeEnum.CASH_REGISTER && (
        <CashRegisterForm handleSubmit={onSubmit} account={account} isEditing />
      )}
      {account?.id && <ButtonDelete account={account} />}
    </>
  );
};
