import * as Yup from 'yup';

import { formText } from 'modules/Forms/messages';
import { TranslationFunction } from 'modules/I18n/hooks/useTranslations';

import messages from './messages';

interface ValidationSchemaArgs {
  t: TranslationFunction;
  maxAmount?: number;
  multiple?: boolean;
}

export const getValidationSchema = ({
  t,
  maxAmount,
  multiple,
}: ValidationSchemaArgs) => {
  if (maxAmount && !multiple) {
    return Yup.object().shape({
      operationDate: Yup.date().required(t(formText.required)),
      amount: Yup.number()
        .required(t(formText.required))
        .positive(t(messages.minAmount))
        .max(maxAmount, t(messages.maxAmount)),
    });
  }

  return Yup.object().shape({
    operationDate: Yup.date().required(t(formText.required)),
  });
};
