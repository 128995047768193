import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectBrowser } from 'modules/App/store/selectors';
import { setIsBodyScrollable } from 'modules/Ui/store/actions';
import { selectIsBodyScrollable } from 'modules/Ui/store/selectors';

import { SubmenuSections } from '../constants';

const useMenuMobile = () => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [isSubMenuOpen, setSubMenuOpen] = useState<boolean>(false);
  const [subMenuSection, setSubMenuSection] = useState<SubmenuSections | null>(
    null
  );
  const mainContent = document.querySelector('#main') as HTMLElement;
  const browser = useSelector(selectBrowser);
  const isBodyScrollable = useSelector(selectIsBodyScrollable);
  const bigDevice = browser.greaterThan.medium || browser.is.medium;

  const closeMenu = useCallback(() => {
    dispatch(setIsBodyScrollable(true));

    document.body.removeAttribute('style');
    if (mainContent) {
      mainContent.style.zIndex = 'initial';
      mainContent.setAttribute('aria-hidden', 'false');
    }
    setSubMenuSection(null);
    setSubMenuOpen(false);
    setIsOpen(false);
  }, [setIsOpen, mainContent, setSubMenuSection, setSubMenuOpen]);

  const toggleMenu = useCallback(() => {
    !isBodyScrollable && dispatch(setIsBodyScrollable(isOpen));

    if (mainContent) {
      mainContent.style.zIndex = isOpen ? 'initial' : '-10';
      mainContent.setAttribute('aria-hidden', isOpen ? 'false' : 'true');
    }
    setSubMenuSection(null);
    setSubMenuOpen(false);
    setIsOpen(!isOpen);
  }, [isOpen, setIsOpen, mainContent, setSubMenuSection, setSubMenuOpen]);

  useEffect(() => {
    if (bigDevice) {
      closeMenu();
    }
  }, [bigDevice, closeMenu]);

  const openSubMenu = useCallback(
    (section: SubmenuSections) => () => {
      setSubMenuSection(section);
      setSubMenuOpen(true);
    },
    [setSubMenuOpen, setSubMenuSection]
  );
  const closeSubmenu = useCallback(() => {
    setSubMenuOpen(false);
    setSubMenuSection(null);
  }, [setSubMenuOpen, setSubMenuSection]);

  return {
    isSubMenuOpen,
    isOpen,
    closeSubmenu,
    closeMenu,
    openSubMenu,
    toggleMenu,
    subMenuSection,
  };
};

export default useMenuMobile;
