import useBookkeeperChangeBusiness from 'modules/Bookkeeper/hooks/useBookkeeperChangeBusiness';
import Business from 'modules/Business/models/business';

import Box from '../Box';
import Button from '../Form/Button';
import InfiniteList from '../InfiniteList/InfiniteList';
import Text from '../Text';

interface Props {
  isLoading: boolean;
  hasNextPage?: boolean;
  isFetchingNextPage: boolean;
  customers: Business[];
  fetchNext: () => void;
}

const BookkeeperCustomers: React.FC<Props> = ({
  customers,
  isLoading,
  hasNextPage,
  isFetchingNextPage,
  fetchNext,
}) => {
  const elementHeight = 48;
  const { onChangeBusiness } = useBookkeeperChangeBusiness();

  return (
    <InfiniteList
      entries={customers}
      isLoading={isLoading}
      isFetchingNextPage={isFetchingNextPage}
      hasNextPage={hasNextPage}
      scrollTransparentEffect
      fetchNext={fetchNext}
      entryRender={(business) => (
        <Box tag="li" height={elementHeight} key={business.id}>
          <Button
            borderBottom="1px solid"
            borderBottomColor="primary100"
            boxSizing="border-box"
            display="flex"
            flexDirection="column"
            height="100%"
            justifyContent="center"
            onClick={() => onChangeBusiness(business)}
            textAlign="left"
            width="100%"
          >
            <Text
              color="brand500"
              display="block"
              fontSize={14}
              fontWeight="600"
              hasEllipsis
              lineHeight={16}
              marginBottom="4px"
              tag="span"
            >
              {business.fiscalName}
            </Text>
            <Text
              color="gray600"
              display="block"
              fontSize={12}
              lineHeight={16}
              tag="span"
            >
              {business.vatNumber}
            </Text>
          </Button>
        </Box>
      )}
    />
  );
};

export default BookkeeperCustomers;
