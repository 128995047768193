import { useSelector } from 'react-redux';

import { AnalyticsEvent } from 'modules/Analytics';
import analyticsService from 'modules/Analytics/services/analyticsService';
import useHasSeveralBusinesses from 'modules/Business/hooks/useHasSeveralBusinesses';
import useTranslations from 'modules/I18n/hooks/useTranslations';
import { ModalTypes } from 'modules/Modals/constants';
import useOpenModal from 'modules/Modals/hooks/useOpenModal';
import { selectHasTrialEnded } from 'modules/Subscriptions/detail/selectors';
import { ROUTES } from 'modules/Subscriptions/routesMap';
import useTheme from 'modules/Theme/hooks/useTheme';
import { Text, Link, Img, Box, Button } from 'modules/Ui';
import { IconCloseSession } from 'modules/Ui/Icons';

import { actionBlocked, trialEnds } from './messages';

export default function ActionBlocked() {
  const { t } = useTranslations();
  const theme = useTheme();
  const isTrialEnded = useSelector(selectHasTrialEnded);

  const hasSeveralBusinesses = useHasSeveralBusinesses();

  const onChangeBusinesssClick = useOpenModal({
    type: ModalTypes.CHANGE_BUSINESS,
  });

  return (
    <Box
      display="grid"
      boxSizing="border-box"
      height="100vh"
      gridTemplateRows="1fr auto"
      padding="90px 24px 56px"
    >
      <Box textAlign="center">
        <Img
          ariaHidden
          height={157}
          width={196}
          marginBottom="16px"
          src="/assets/action-blocked.svg"
        />
        <Text
          tag="h1"
          fontSize={16}
          lineHeight={22}
          color="gray800"
          marginBottom={12}
        >
          {t(isTrialEnded ? trialEnds.header : actionBlocked.header)}
        </Text>
        <Text fontSize={16} lineHeight={22} color="gray600" marginBottom={24}>
          {t<Element, JSX.Element>(
            isTrialEnded ? trialEnds.message : actionBlocked.message,
            {
              email: (chunk: (string | Element)[]) => (
                <Link
                  isExternal
                  to={`mailto:${t(
                    isTrialEnded ? trialEnds.email : actionBlocked.email
                  )}`}
                  color="brand500"
                  fontWeight="600"
                  fontSize={theme.fontSizes[14]}
                  lineHeight={theme.fontSizes[20]}
                >
                  {chunk}
                </Link>
              ),
              phone: (chunk: (string | Element)[]) => (
                <Link
                  isExternal
                  to={`tel:${t(
                    isTrialEnded ? trialEnds.phone : actionBlocked.phone
                  )}`}
                  color="brand500"
                  fontWeight="600"
                  fontSize={theme.fontSizes[14]}
                  lineHeight={theme.fontSizes[20]}
                  display="inline-block"
                >
                  {chunk}
                </Link>
              ),
            }
          )}
        </Text>
        <Link
          to={ROUTES.SUBSCRIPTIONS_CANCELLED_PLANS}
          variant="secondary"
          width={1}
          marginTop="16px"
          onClick={() => {
            analyticsService.track(AnalyticsEvent.APP_SHOW_PLANS);
          }}
        >
          {t(
            isTrialEnded ? trialEnds.primaryAction : actionBlocked.primaryAction
          )}
        </Link>
        {hasSeveralBusinesses && (
          <>
            <Button
              width="100%"
              variant="stroke"
              marginTop="16px"
              onClick={onChangeBusinesssClick}
            >
              {t(
                isTrialEnded
                  ? trialEnds.tertiaryAction
                  : actionBlocked.tertiaryAction
              )}
            </Button>
          </>
        )}
      </Box>
      <Link
        to="/auth/logout"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        {t(
          isTrialEnded
            ? trialEnds.secondaryAction
            : actionBlocked.secondaryAction
        )}
        <IconCloseSession marginLeft={2} />
      </Link>
    </Box>
  );
}
