import { css, keyframes } from 'modules/Theme/styled-components';
import { zIndex } from 'modules/Ui/zIndex';

import { menuSizes } from '../menuSizes';

export interface SubMenuProps {
  open?: boolean;
}

const fadeOutZIndex = keyframes`
  from {
    z-index: ${zIndex.MENU_MOBILE_OPEN};
  }
  to {
    z-index: -1;
  }
`;

const fadeOutMenu = css`
  animation: ${fadeOutZIndex} 0.4s ease-in-out;
`;

const menuAnimation = css<SubMenuProps>`
  transform: translateX(${({ open }) =>
    open ? '0' : `-${menuSizes.MENU_WIDTH}px`});
  transition: transform 0.2s ease;
  ${({ open }) => open && 'will-change: transform'};
  ${({ open }) => (open ? `z-index: ${zIndex.MENU_MOBILE_OPEN}` : fadeOutMenu)};
`;

export default menuAnimation;
