import { useEffect } from 'react';
import { useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { Redirect, useLocation } from 'react-router';

import { AnalyticsEvent } from 'modules/Analytics';
import analyticsService from 'modules/Analytics/services/analyticsService';
import { logout } from 'modules/Auth/actions';
import Window from 'modules/Zendesk/models';
import { clearCurrentUser, clearFeatureFlagIdentifiers } from 'utils/storage';

const Logout = () => {
  const location = useLocation<{
    redirectUrl: string;
  }>();
  const url =
    location.state && location.state.redirectUrl
      ? location.state.redirectUrl
      : '/';
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  useEffect(() => {
    clearCurrentUser();
    analyticsService.track(AnalyticsEvent.LOGOUT);
    analyticsService.reset();
    dispatch(logout());
    if (context.zE) {
      context.zE('messenger', 'hide');
      context.zE('messenger', 'logoutUser');
    }
    queryClient.invalidateQueries();
    clearFeatureFlagIdentifiers();
  }, [dispatch]);
  const context = window as Window;

  return <Redirect to={url} />;
};

export default Logout;
