import React, { useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';

const container = document.getElementById('root') as HTMLElement;

const Drawer: React.FC = ({ children }) => {
  const elRef = useRef(document.createElement('aside'));

  useEffect(() => {
    document.body.insertBefore(elRef.current, container);
    return () => {
      document.body.removeChild(elRef.current);
    };
  }, []);

  return createPortal(children, elRef.current);
};

export default Drawer;
