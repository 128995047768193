import { useState, useRef, useEffect } from 'react';

type Timer = ReturnType<typeof setTimeout>;

export const useHoldBooleanTrueAtLeast = (
  value: boolean,
  time: number
): boolean => {
  const [holdOn, setHoldOn] = useState(false);
  const timeoutRef = useRef<Timer>();

  const cleanInterval = (): void => {
    if (timeoutRef.current) {
      clearInterval(timeoutRef.current);
    }
  };

  // Clean timeout in unmount
  useEffect(() => cleanInterval, []);

  useEffect(() => {
    if (value) {
      cleanInterval();
      setHoldOn(true);
      timeoutRef.current = setTimeout(() => {
        setHoldOn(false);
        timeoutRef.current = undefined;
      }, time);
    }
  }, [time, value]);

  return value || holdOn;
};
