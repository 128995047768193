import React, { useCallback } from 'react';

import { useField } from 'formik';

import useTranslations from 'modules/I18n/hooks/useTranslations';
import { ModalTypes } from 'modules/Modals/constants';
import { useOpenModal } from 'modules/Modals/hooks';
import { DocumentSerie, TargetDocument } from 'modules/Settings/models';
import { SelectProps } from 'modules/Ui/Form/SelectCommon/types';
import SelectFormikField from 'modules/Ui/Formik/SelectFormikField';

import { useDocumentToggles } from '../DocumentTogglesProvider/DocumentTogglesProvider';
import { useSeries } from '../SeriesProvider/SeriesProvider';
import messages from './messages';

interface SerialFormikFieldProps {
  name: string;
  onChangeSerie?: (value: string | DocumentSerie) => void;
  targetDocumentType?: TargetDocument;
}

const SelectSerieFormikField: React.FC<SerialFormikFieldProps & SelectProps> = (
  props
) => {
  const { isQuote, isTest, isProforma, isCorrective, isDeliveryNote } =
    useDocumentToggles();

  const { info, onChangeSerie, options = [], name, disabled, ...rest } = props;

  const [, , helpers] = useField(name);

  const { addSerie, series } = useSeries();
  const { t } = useTranslations();

  const openModal = useOpenModal();

  const handleAcceptNewSerie = (serie: DocumentSerie) => {
    addSerie(serie);
    helpers.setValue(serie.id);
    onChangeSerie && onChangeSerie(serie);
  };
  const handleCancel = useCallback(() => {
    if (!series[0]) {
      return;
    }
    helpers.setValue(series[0].id);
    onChangeSerie && onChangeSerie(series[0].id);
  }, [onChangeSerie, series]);

  const openCreateSerieModal = () => {
    let initialSelectValue = TargetDocument.INVOICE;
    if (isQuote) {
      initialSelectValue = TargetDocument.QUOTE;
    }
    if (isProforma) {
      initialSelectValue = TargetDocument.PROFORMA;
    }
    if (isCorrective) {
      initialSelectValue = TargetDocument.CORRECTIVE;
    }
    if (isDeliveryNote) {
      initialSelectValue = TargetDocument.DELIVERY_NOTE;
    }
    openModal({
      type: ModalTypes.CREATE_SERIE_MODAL,
      initialSelectValue,
      onAccept: handleAcceptNewSerie,
      onCancel: handleCancel,
      targetDocumentType: props.targetDocumentType,
    });
  };

  return (
    <SelectFormikField
      {...{
        ...rest,
      }}
      name={name}
      info={info}
      onChange={onChangeSerie}
      canCreateOption={true}
      options={options}
      onAddOption={openCreateSerieModal}
      shouldRenderMobileSelect={false}
      menuListText={t(messages.newSerie)}
      isDisabled={isTest || disabled}
      id="select-serie"
    />
  );
};

export default SelectSerieFormikField;
