import { FC } from 'react';

import {
  layout,
  LayoutProps,
  space,
  SpaceProps,
  flexbox,
  FlexboxProps,
  position,
  PositionProps,
  gridArea,
  GridAreaProps,
} from 'styled-system';

import { fadeInAnimation } from 'modules/Theme/mixins/fadeIn';
import styled from 'modules/Theme/styled-components';

import Icon from '../Icon';

export interface IconByPathProps
  extends SpaceProps,
    FlexboxProps,
    PositionProps,
    LayoutProps,
    GridAreaProps {
  className?: string;
  clipRule?: 'nonzero' | 'evenodd' | 'inherit';
  color?: string;
  fadeIn?: boolean;
  fill?: string;
  fillRule?: 'nonzero' | 'evenodd';
  height?: string | number;
  path?: string;
  size?: string | number;
  shapeRendering?:
    | 'auto'
    | 'optimizeSpeed'
    | 'crispEdges'
    | 'geometricPrecision';
  stroke?: string;
  strokeWidth?: string | number;
  transform?: string;
  transition?: boolean;
  /**  Defines the position and dimension, in user space, of an SVG viewport */
  viewBox?: string;
}

const StyledIcon = styled(Icon)<IconByPathProps>`
  fill: ${({ fill }) => (fill ? `${fill}` : 'currentColor')};
  stroke: ${({ stroke }) => (stroke ? `${stroke}` : 'currentColor')};
  ${layout}
  ${flexbox}
  ${gridArea}
  ${space}
  ${position}
  ${({ transform }) => transform && `transform: ${transform};`}
  ${({ transition }) => transition && 'transition: transform 0.3s ease-in;'}
  ${({ fadeIn }) => fadeIn && fadeInAnimation}
`;

const IconByPath: FC<IconByPathProps> = ({
  className,
  clipRule,
  color,
  fadeIn,
  fill,
  fillRule,
  height,
  path,
  shapeRendering,
  size,
  stroke,
  strokeWidth = '0',
  transform,
  transition,
  viewBox,
  ...rest
}) => {
  return (
    <StyledIcon
      className={className}
      clipRule={clipRule}
      color={color}
      fadeIn={fadeIn}
      fill={fill}
      fillRule={fillRule}
      height={height}
      size={size}
      stroke={stroke}
      strokeWidth={strokeWidth}
      transform={transform}
      transition={transition}
      viewBox={viewBox}
      {...{
        ...rest,
      }}
    >
      <path
        clipRule={clipRule}
        d={path}
        fill={color}
        fillRule={fillRule}
        shapeRendering={shapeRendering}
      />
    </StyledIcon>
  );
};

export default IconByPath;
