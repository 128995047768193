import { AxiosResponse } from 'axios';

import { getConfig } from 'modules/App/config';
import { PAYMENT_METHODS } from 'modules/App/queries/queries';
import request from 'utils/request';

import { CreatedPaymentMethod } from '../models/paymentMethods';

const updatePaymentMethod = async ({ id, ...data }: CreatedPaymentMethod) => {
  const endpoint = (await getConfig()).apiPaymentMethodsService;

  const response = await request<AxiosResponse<CreatedPaymentMethod>>(
    `${endpoint}/${id}`,
    {
      method: 'PUT',
      data,
    }
  );
  return response.data.data;
};

export const invalidateQueries = [PAYMENT_METHODS];

export default updatePaymentMethod;
