import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import messages from 'modules/App/messages';
import {
  selectCurrentCampaignActive,
  selectHasBookkeeperUsers,
} from 'modules/Business/Current/selectors';
import { ModalTypes } from 'modules/Modals/constants';
import useOpenModal from 'modules/Modals/hooks/useOpenModal';
import useNotifications from 'modules/Notifications/hooks/useNotifications';
import {
  selectHasSubscription,
  selectSubscriptionName,
} from 'modules/Subscriptions/detail/selectors';
import withScrollTop from 'modules/Ui/hocs/withScrollTop';
import LoadingIndicator from 'modules/Ui/LoadingIndicator';

import { useFetchPlans } from '../../../hooks';
import { Plan } from '../../../models';
import Plans from './Plans';

interface Props {
  onSelectPlan(data: Plan): void;
}

// Scroll to top (needed if we arrive here outside the standard navigation, i.e. Subscription not active)
const PlansContainer: React.FC<Props> = withScrollTop(({ onSelectPlan }) => {
  // Data
  const { isLoading, data } = useFetchPlans();

  // Behaviour
  const onChangeBusinessClick = useOpenModal({
    type: ModalTypes.CHANGE_BUSINESS,
  });

  const notifications = useNotifications();
  useEffect(() => {
    notifications.delete(messages.notificationNearToExpire.id);
  }, []);

  const activePlanName = useSelector(selectSubscriptionName);

  const hasBookkeeperUsers = useSelector(selectHasBookkeeperUsers);
  const hasSubscription = useSelector(selectHasSubscription);
  const hasCampaign = useSelector(selectCurrentCampaignActive);

  return (
    <>
      {isLoading || !data ? (
        <LoadingIndicator />
      ) : (
        <Plans
          onSelectPlan={onSelectPlan}
          onChangeBusinessClick={onChangeBusinessClick}
          plans={data}
          hasBookkeeper={hasBookkeeperUsers}
          hasCampaign={hasCampaign}
          hasSubscription={hasSubscription}
          activePlan={activePlanName}
        />
      )}
    </>
  );
});

export default PlansContainer;
