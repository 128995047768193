import { useCallback } from 'react';
import { useHistory } from 'react-router';

type History = ReturnType<typeof useHistory>;
type AnchorState = {
  returnTo: string;
  search: string;
};

const getRouteState = (history: History): AnchorState | any =>
  history.location.state;

const navigate = (
  path: string,
  params: string | { [id: string]: string },
  history: History,
  state?: { [key: string]: string }
) => {
  const searchParams = new URLSearchParams(params);
  history.push(`${path}?${searchParams}`, state);
};

const buildReturnAnchorState = (history: History): AnchorState | undefined => {
  if (history.location.pathname.length > 1) {
    return {
      returnTo: history.location.pathname,
      search: history.location.search,
    };
  }
  return undefined;
};

export function useRouteAnchorState() {
  const history = useHistory();

  const navigateStoringAnchorState = useCallback(
    (path: string) => {
      navigate(path, '', history, buildReturnAnchorState(history));
    },
    [history]
  );

  const routerHasReturnAnchorState = useCallback(() => {
    const state = getRouteState(history);
    return state && 'returnTo' in state && 'search' in state;
  }, [history]);

  const navigateToReturnAnchorState = useCallback(() => {
    const state = getRouteState(history);
    navigate(state.returnTo, state.search, history);
  }, [history]);

  return {
    navigateStoringAnchorState,
    routerHasReturnAnchorState,
    navigateToReturnAnchorState,
  };
}
