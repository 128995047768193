import { FC } from 'react';

import useTranslations from 'modules/I18n/hooks/useTranslations';
import useTheme from 'modules/Theme/hooks/useTheme';
import messages from 'modules/Ui/Pagination/messages';

import Box from '../Box';
import { OnlyIconButton } from '../Form';
import { IconArrowNext, IconArrowBack } from '../Icons';
import Text from '../Text';
import carrouselMessages from './messages';
import { CarouselProps } from './model';

interface CarouselHeaderProps extends CarouselProps {}

export const CarouselHeader: FC<CarouselHeaderProps> = ({
  id,
  pages,
  selectedPage,
  setSelectedPage,
  title,
}) => {
  const { t } = useTranslations();
  const theme = useTheme();
  return (
    <Box
      alignItems="center"
      display="grid"
      gridTemplateColumns="1fr auto"
      marginBottom="8px"
    >
      <Text
        fontSize={12}
        fontWeight="normal"
        id="carrousel-title"
        lineHeight={14}
        tag="h3"
        textTransform="uppercase"
      >
        <Box tag="span" accessibleHidden>
          {t(carrouselMessages.title)}
        </Box>
        {title}
      </Text>
      {pages > 1 && (
        <Box display="grid" gap="8px" gridTemplateColumns="30px 30px" tag="ul">
          <Box tag="li">
            <OnlyIconButton
              ariaLabel={t(messages.prevPage)}
              icon={<IconArrowBack />}
              iconHoverColor={theme.colors.brand500}
              id={`prev-${id}-carousel-page`}
              onClick={() => setSelectedPage(selectedPage - 1)}
              size={30}
              disabled={selectedPage === 1}
              variant="light"
            />
          </Box>
          <Box tag="li">
            <OnlyIconButton
              ariaLabel={t(messages.nextPage)}
              icon={<IconArrowNext />}
              iconHoverColor={theme.colors.brand500}
              id={`next-${id}-carousel-page`}
              onClick={() => setSelectedPage(selectedPage + 1)}
              size={30}
              disabled={selectedPage === pages}
              variant="light"
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};
