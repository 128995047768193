import { Route, RouteProps, Redirect } from 'react-router-dom';

import { getCurrentUser } from '../currentUserUtil';

interface GuestRouteProps extends RouteProps {}

const GuestRoute = (props: GuestRouteProps) => {
  const currentUser = getCurrentUser();

  if (currentUser) {
    return <Redirect to="/" />;
  }

  return <Route {...props} />;
};

export default GuestRoute;
