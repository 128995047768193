import { Visible } from 'react-grid-system';
import { useSelector } from 'react-redux';

import { BaiInfo, useBaiAssetsInfo } from 'hooks/bai/useBaiAssetsInfo';
import { shouldSendElectronicDocument } from 'modules/Business/businessUtils';
import { selectCurrentBusiness } from 'modules/Business/Current/selectors';
import { Contact } from 'modules/Contacts/models';
import DigitalStatusTag from 'modules/Documents/BaiSubmission/components/Resume/DigitalStatusTag';
import SentStatusIcon from 'modules/Documents/components/SentStatusIcon';
import { listDocumentHeader } from 'modules/Documents/List/messages';
import { sentStatus } from 'modules/Documents/messages';
import {
  DigitalDocumentStatus,
  DocumentKind,
  DocumentStatus as Status,
  DocumentTable,
  DocumentType,
  DocumentSendStatus,
} from 'modules/Documents/models/document';
import { ReceiptStatus } from 'modules/Documents/models/receiptStatus';
import { StatusInfo } from 'modules/Documents/models/statusInfo';
import RectifyTooltip from 'modules/Documents/Rectify/components/RectifyTooltip';
import useTranslations, {
  TranslationFunction,
} from 'modules/I18n/hooks/useTranslations';
import useTheme from 'modules/Theme/hooks/useTheme';
import { Box } from 'modules/Ui';
import { DataTableColumn } from 'modules/Ui/DataTable/DataTable.models';
import DocumentStatus from 'modules/Ui/DocumentStatus';
import {
  IconBlocked,
  IconCheck,
  IconEmail,
  IconReceived,
  IconSpam,
} from 'modules/Ui/Icons';
import formatDate from 'utils/dates/formatDate';

function getStatusTag(
  statusInfo: StatusInfo,
  digitalDocument?: DigitalDocumentStatus
) {
  return (
    <>
      <DocumentStatus state={statusInfo} />
      {digitalDocument && (
        <Visible xs>
          <DigitalStatusTag marginLeft="4px" status={digitalDocument} />
        </Visible>
      )}
    </>
  );
}

function getLogo({ logo: Icon }: BaiInfo, props: object) {
  return Icon ? <Icon {...props} /> : null;
}

function getDigitalStatusTag(
  baiInfo: BaiInfo,
  statusInfo?: DigitalDocumentStatus
) {
  return statusInfo ? (
    <Box
      tag="span"
      alignItems={{ _: 'center', sm: 'inherit' }}
      display={{ _: 'flex', sm: 'initial' }}
    >
      <Visible xs>
        {getLogo(baiInfo, {
          width: '15px',
          height: '18px',
          color: 'primary500',
        })}
      </Visible>
      <DigitalStatusTag
        marginBottom={{ _: '4px', sm: 'initial' }}
        status={statusInfo}
      />
    </Box>
  ) : (
    <></>
  );
}

function formatNameValue(
  t: TranslationFunction,
  contact: Contact,
  receiptStatus?: ReceiptStatus
) {
  if (receiptStatus === ReceiptStatus.IS_RECEIPT) {
    return t(listDocumentHeader.receipt);
  }
  return contact.fiscalName;
}

export default function useDocumentListConfig(options: {
  isRemittanceList?: boolean;
  documentKind?: DocumentKind;
}) {
  const { isRemittanceList = false, documentKind } = options || {};
  const { t, formatCurrency } = useTranslations();
  const theme = useTheme();
  const business = useSelector(selectCurrentBusiness);
  const hasElectronicDocument = shouldSendElectronicDocument(business);
  const baiInfo = useBaiAssetsInfo();
  const isDraft = documentKind === DocumentKind.DRAFT;
  const columns: DataTableColumn<DocumentTable, keyof DocumentTable>[] = [
    {
      name: t(listDocumentHeader.code),
      field: 'completeSerialCode',
      sortField: 'code',
      className: 'code',
      width: { sm: '104px', md: '128px', lg: '139px' },
      value: ({
        identifier,
        completeSerialCode,
        code,
        documentType,
        correctedDocuments,
        correctiveData,
      }) => {
        const paintValue = identifier || completeSerialCode || `${code}`;
        let correctedPaintValue = '';
        const isCorrective =
          documentType === DocumentType.CORRECTIVE &&
          !correctiveData?.isReplacementFromReceipt &&
          correctedDocuments?.length;
        if (correctedDocuments?.length) {
          const correctedDocument = correctedDocuments[0];
          const {
            identifier: correctedIdentifier,
            completeSerialCode: correctedCompleteSerialCode,
            code: correctedCode,
          } = correctedDocument;

          correctedPaintValue =
            correctedIdentifier ||
            correctedCompleteSerialCode ||
            `${correctedCode}`;
        }

        return (
          <Box
            alignItems={isCorrective && 'center'}
            display={isCorrective && 'flex'}
            tag="span"
          >
            {paintValue}
            {isCorrective && (
              <RectifyTooltip
                id={correctedPaintValue}
                href={`documents/issued/${correctedDocuments[0].id}`}
              />
            )}
          </Box>
        );
      },
    },
    {
      name: t(listDocumentHeader.customer),
      // Virtual field with custom accessor
      field: 'contact.fiscalName' as any,
      sortField: (isDraft
        ? 'counterparty.fiscalName'
        : 'contact.fiscalName') as any,
      maxWidth: { sm: '200px', md: '299px' },
      color: theme.colors.gray800,
      className: 'fiscalName',
      fontSize: `${theme.fontSizes[16]}`,
      lineHeight: `${theme.lineHeights[20]}`,
      value: ({ contact, receiptStatus }) =>
        formatNameValue(t, contact, receiptStatus),
    },
    {
      name: t(listDocumentHeader.reference),
      field: 'reference',
      sortField: 'reference',
      width: { md: '170px' },
      className: 'reference',
      value: ({ reference }) => reference || '-',
    },
    {
      name: t(sentStatus.title),
      field: 'lastSentStatus.status' as any,
      className: 'send',
      value: (document) => {
        switch (document.lastSentStatus?.status) {
          case DocumentSendStatus.READ: {
            return (
              <SentStatusIcon
                text={t(sentStatus.read, {
                  date: formatDate(document.lastSentStatus?.date),
                })}
                icon={<IconReceived size={20} color="confirmation" />}
                id={`read-${document.id}`}
              />
            );
          }
          case DocumentSendStatus.RECEIVED: {
            return (
              <SentStatusIcon
                text={t(sentStatus.received, {
                  date: formatDate(document.lastSentStatus?.date),
                })}
                icon={<IconReceived size={20} color="primary200" />}
                id={`received-${document.id}`}
              />
            );
          }
          case DocumentSendStatus.SENT: {
            return (
              <SentStatusIcon
                text={t(sentStatus.sent, {
                  date: formatDate(document.lastSentStatus?.date),
                })}
                icon={<IconCheck size={20} color="primary200" />}
                id={`sent-${document.id}`}
              />
            );
          }
          case DocumentSendStatus.SPAM:
            return (
              <SentStatusIcon
                text={t(sentStatus.spam, {
                  date: formatDate(document.lastSentStatus?.date),
                })}
                icon={<IconSpam size={20} color="accent400" />}
                id={`spam-${document.id}`}
              />
            );
          case DocumentSendStatus.BLOCKED:
            return (
              <SentStatusIcon
                icon={<IconBlocked size={20} />}
                text={t(sentStatus.blocked, {
                  date: formatDate(document.lastSentStatus?.date),
                })}
                id={`blocked-${document.id}`}
              />
            );
          case DocumentSendStatus.BASIC_PLAN_SENT:
            return (
              <SentStatusIcon
                text={t(sentStatus.sent, {
                  date: formatDate(document.lastSentStatus?.date),
                })}
                icon={<IconEmail size={20} />}
                id={`sent-${document.id}`}
              />
            );
          default:
            return '';
        }
      },
      sortField: 'lastSentStatus.status' as any,
      hidden: isDraft || isRemittanceList,
      width: { sm: '45px' },
    },
    {
      name: t(listDocumentHeader.issuedDate),
      field: isDraft ? 'updatedAt' : 'issuedDate',
      textAlign: { _: 'right', sm: 'initial' },
      sortField: isDraft ? 'updatedAt' : 'issuedDateTime',
      value: ({ issuedDate }) => formatDate(issuedDate),
      width: { sm: '106px', md: '132px', lg: '152px' },
      className: 'issuedDate',
    },
    {
      name: t(listDocumentHeader.dueDate),
      field: 'dueDate',
      sortField: 'dueDate',
      value: ({ dueDate }) => formatDate(dueDate),
      width: { md: '131px', lg: '155px' },
      className: 'dueDate',
      fontSize: `${theme.fontSizes[16]}`,
      lineHeight: `${theme.lineHeights[20]}`,
    },
    {
      name: t(listDocumentHeader.total),
      // Virtual field with custom accessor
      field: 'totals.total' as any,
      alignSelf: 'center',
      headerTextAlign: 'right',
      fontWeight: 600,
      textAlign: 'right',
      color: theme.colors.gray800,
      value: ({ totals, currency }) => formatCurrency(totals.total, currency),
      width: { sm: '67px', lg: '71px' },
      className: 'total',
      fontSize: `${theme.fontSizes[16]}`,
      lineHeight: `${theme.lineHeights[20]}`,
    },
    {
      name: t(listDocumentHeader.status),
      field: 'status',
      sortField: 'statusInfo',
      headerTextAlign: 'right',
      textAlign: { sm: 'right' },
      value: ({ statusInfo = StatusInfo.TEST, status, digitalDocument }) => {
        let tag = statusInfo;

        if (status === Status.VOID) {
          tag = StatusInfo.VOID;
        }
        if (status === Status.DRAFT) {
          tag = StatusInfo.DRAFT;
        }
        return getStatusTag(tag, digitalDocument?.status);
      },
      width: { sm: '78px', md: '104px' },
      className: 'status',
      disableSort: true,
    },
    {
      key: t(listDocumentHeader.tbai),
      name: (
        <Box
          alignItems="center"
          color="primary500"
          display="flex"
          fontSize={11}
          tag="span"
          justifyContent="flex-end"
        >
          {t(listDocumentHeader.tbai)}
          {getLogo(baiInfo, {
            width: '15px',
            height: '18px',
            color: 'primary500',
            marginLeft: '4px',
          })}
        </Box>
      ),
      field: 'digitalDocument',
      headerTextAlign: 'right',
      textAlign: 'right',
      value: ({ digitalDocument }) =>
        getDigitalStatusTag(baiInfo, digitalDocument?.status),
      width: { sm: '78px', md: '108px' },
      className: 'digitalDocument',
      disableSort: true,
      hidden: !hasElectronicDocument,
    },
  ];

  return {
    columns: columns.filter((xs) => !xs.hidden),
  };
}
