import { forwardRef, Ref } from 'react';

import {
  background,
  BackgroundProps,
  border,
  BorderProps,
  space,
  SpaceProps,
} from 'styled-system';

import useTheme from 'modules/Theme/hooks/useTheme';
import styled from 'modules/Theme/styled-components';
import { Box, Button } from 'modules/Ui';

interface Props
  extends React.ComponentPropsWithoutRef<'button'>,
    SpaceProps,
    BorderProps,
    BackgroundProps {
  $active?: boolean;
  label?: React.ReactNode;
  onChange?: (event: any, value?: string) => void;
  onClick?: (value: any) => void;
  selected?: boolean;
  value?: string;
}

const StyledButton = styled(Button)<Props>`
  ${background}
  ${space}
  ${border}
  @media (hover: hover) {
    &:hover {
      color: ${(props) =>
        props.$active
          ? props.theme.colors.brand500
          : props.theme.colors.gray800};
      cursor: ${({ $active }) => $active && 'text'};
    }
  }

  &:focus {
    color: ${(props) =>
      props.$active ? props.theme.colors.brand500 : props.theme.colors.gray800};
  }
`;

export const Tab = forwardRef((props: Props, ref: Ref<HTMLButtonElement>) => {
  const { $active, label, onChange, onClick, selected, value, ...rest } = props;
  const theme = useTheme();
  const handleClick = (event: any) => {
    if (!selected && onChange) {
      onChange(event, value);
    }
    if (onClick) {
      onClick(event);
    }
  };

  return (
    <Box tag="li">
      <StyledButton
        $active={!!selected}
        aria-controls={`tabpanel-${value}`}
        aria-label={`${label}`}
        aria-selected={selected}
        backgroundColor={
          selected ? 'initial' : `${theme.colors.auxiliary100A30}`
        }
        border="none"
        color={
          selected ? `${theme.colors.brand500}` : `${theme.colors.primary300}`
        }
        data-testid={`accountingAccount-${value}-dialog`}
        fontWeight={selected ? '600' : 'initial'}
        id={`tab-${value}`}
        fontSize={14}
        lineHeight={24}
        onClick={handleClick}
        padding="8px 0"
        ref={ref}
        role="tab"
        selected={!!selected}
        width="100%"
        {...rest}
      >
        {label}
      </StyledButton>
    </Box>
  );
});
