import React from 'react';

import useTranslations from 'modules/I18n/hooks/useTranslations';
import {
  Dialog,
  DialogConfirm,
  MODAL_WIDTH_SMALL,
  useCloseModal,
} from 'modules/Modals';
import { Button, Text } from 'modules/Ui';

import { useDeleteRemittanceDocuments } from '../../hooks/useDeleteRemittanceDocuments';
import messages from './messages';
import { RemittanceDocumentsDeleteModalProps } from './types';

const RemittanceDocumentsDeleteModal: React.FC<
  RemittanceDocumentsDeleteModalProps
> = ({ remittanceId, documentIds, inverted, callback, totalCount }) => {
  const { t } = useTranslations();
  const closeModal = useCloseModal();
  const deleteRemittanceDocuments = useDeleteRemittanceDocuments(callback);

  const tryToDeleteAll = documentIds.length === totalCount || inverted;
  const handleDelete = () => {
    if (tryToDeleteAll) {
      return;
    }
    deleteRemittanceDocuments({
      remittanceId,
      documentIds,
      excludeIds: Boolean(inverted),
    });
  };

  if (tryToDeleteAll) {
    return (
      <Dialog
        id="remittance-documents-delete"
        onClose={closeModal}
        title={t(messages.title, { count: documentIds.length })}
        buttonPrimary={
          <Button
            data-testid="remittance-documents-delete-confirm"
            variant="secondary"
            onClick={closeModal}
          >
            {t(messages.confirm)}
          </Button>
        }
        subtitle={t(messages.subtitle, { count: documentIds.length })}
        dialogWidth={{ sm: MODAL_WIDTH_SMALL }}
      >
        <Text lineHeight={22}>
          {t(messages.textAll, { count: documentIds.length })}
        </Text>
      </Dialog>
    );
  }
  return (
    <DialogConfirm
      id="remittance-documents-delete"
      onConfirm={handleDelete}
      text={t(messages.text, { count: documentIds.length })}
      title={t(messages.title, { count: documentIds.length })}
    />
  );
};

export default RemittanceDocumentsDeleteModal;
