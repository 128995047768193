import { createSelector } from 'reselect';

import { BookkeeperSoftwareTypes } from 'modules/Bookkeeper/models/sofware-type';
import { Roles } from 'modules/Users/models/role';
import { ApplicationRootState } from 'types';

import { hasElectronicDocumentProvider } from '../businessUtils';
import { BusinessType } from '../models/business';

const selectBusinessState = (state: ApplicationRootState) => state.business;

export const selectCurrentBusinessLoaded = createSelector(
  selectBusinessState,
  (state) => {
    return Object.keys(state?.business || {}).length > 0;
  }
);

export const selectCurrentBusiness = createSelector(
  selectBusinessState,
  (state) => {
    return state?.business;
  }
);

export const selectIsCurrentBusinessBookkeeping = createSelector(
  selectBusinessState,
  (state): undefined | boolean => {
    return state?.business?.type === BusinessType.BOOKKEEPING;
  }
);

export const selectCurrentBusinessWithoutUsers = createSelector(
  selectBusinessState,
  (state) => {
    const { users, ...rest } = state.business;
    return { ...rest };
  }
);

export const selectHasDocumentProvider = createSelector(
  selectBusinessState,
  ({ business }) => {
    return hasElectronicDocumentProvider(business);
  }
);
export const selectLoading = createSelector(selectBusinessState, (state) => {
  return state?.loading;
});

export const selectCurrentBusinessPhone = createSelector(
  selectBusinessState,
  (state) => {
    return state?.business.phoneNumber;
  }
);

export const selectCurrentCampaign = createSelector(
  selectBusinessState,
  ({ business }) => {
    return business?.campaign;
  }
);
export const selectCurrentCampaignActive = createSelector(
  selectBusinessState,
  ({ business }) => {
    return business?.campaign?.active ?? false;
  }
);
export const selectIsA3 = createSelector(selectBusinessState, (state) => {
  return state?.business.software === BookkeeperSoftwareTypes.A3;
});

export const selectIsDiamacon = createSelector(selectBusinessState, (state) => {
  return state?.business.software === BookkeeperSoftwareTypes.DIAMACON;
});

export const selectHasBookkeeperUsers = createSelector(
  selectBusinessState,
  (state) => {
    return !!state?.business.users?.filter(
      (u) => u.role.name === Roles.BOOKKEEPER
    ).length;
  }
);
