import { useDispatch, useSelector } from 'react-redux';

import { fetchAppData } from 'modules/App/actions';
import { useAuthBusiness } from 'modules/Business/Change/hooks/useAuthBusiness';
import { BusinessType } from 'modules/Business/models/business';
import { HOME_ROUTES } from 'modules/Home/routes';
import { createNotification } from 'modules/Notifications/actions';
import { NotificationType } from 'modules/Notifications/models';
import { BusinessUser } from 'modules/Users/models/user';
import { selectUser } from 'modules/Users/selectors';
import requestErrorHandler from 'utils/requestErrorHandler';

export default function useGoBackToBookkeeper() {
  const authBusiness = useAuthBusiness();
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const businessUser = user.businesses.find(
    (business: BusinessUser) =>
      business.business.type === BusinessType.BOOKKEEPING
  );

  return async () => {
    try {
      await authBusiness(businessUser?.business.id);
      dispatch(
        fetchAppData.request({
          redirectUrl: HOME_ROUTES.MAIN_PATH,
        })
      );
    } catch (e: any) {
      const { responseCode, isNetworkError, networkErrorTranslationKey } =
        requestErrorHandler(e);
      dispatch(
        createNotification({
          type: NotificationType.ERROR,
          message: isNetworkError ? networkErrorTranslationKey : responseCode,
        })
      );
    }
  };
}
