import React from 'react';
import ReactDOM from 'react-dom';

import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { install } from 'resize-observer';

import App from 'modules/App';
import { getConfig } from 'modules/App/config';
import initApp from 'modules/App/initApp';

import { version as release } from '../package.json';
import * as serviceWorker from './serviceWorker';

const MOUNT_NODE = document.getElementById('root') as HTMLElement;

if (!window.ResizeObserver) install();

getConfig().then((config) => {
  const { environment, sentryDsn } = config;

  Sentry.init({
    dsn: sentryDsn,
    integrations: [new BrowserTracing()],
    release,
    environment,
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1,
  });
  // expose config for testing
  window.getConfig = () => config;
  const appDeps = initApp(config);

  ReactDOM.render(
    <React.StrictMode>
      <App {...appDeps} />
    </React.StrictMode>,
    MOUNT_NODE
  );
});

// If you want your App to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
