import { Plan } from '../models';

export interface StepProps {
  setPaymentState?: (newPlan: any) => void;
  onSubmit: (data?: CreateSubscriptionForm, coupon?: Coupon) => void;
  inProgress: boolean;
  onCancel: () => void;
  data: CreateSubscriptionForm;
  currentStep: number;
  setCoupon?: (result?: Coupon) => void;
  coupon?: Coupon;
  setSelectedPlan?: (newPlan: Plan) => void;
  selectedPlan: Plan;
}

export interface Step {
  component: React.FC<StepProps>;
  onSubmit: (data?: CreateSubscriptionForm, coupon?: Coupon) => void;
  onCancel: () => void;
}

export interface CreateSubscription {
  paymentMethodId?: string;
  subscriptionProductId: string;
  billingEmail: string;
  coupon?: string;
}

export interface CreateSubscriptionForm {
  periodicity: string;
  productId: string;
  marketName: string;
  price: number;
  billingEmail?: string;
  coupon?: Coupon;
}

export enum CouponDuration {
  forever = 'forever',
  once = 'once',
  repeating = 'repeating',
}

export interface Coupon {
  // in cents!
  absoluteDiscount?: number;
  active: boolean;
  amountOff?: number;
  couponMetadata?: object;
  duration?: CouponDuration;
  durationInMonths?: number;
  percentOff?: number;
  // in cents!
  productPrice?: number;
  promotionCode: string;
  promotionMetadata?: object;
  valid: boolean;
  validCustomer?: boolean;
  validProduct?: boolean;
}
