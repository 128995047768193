export enum ActionTypes {
  FETCH_APP_DATA_FAILURE = 'gofre/app/FETCH_APP_DATA_FAILURE',
  FETCH_APP_DATA_REQUEST = 'gofre/app/FETCH_APP_DATA_REQUEST',
  FETCH_APP_DATA_SUCCESS = 'gofre/app/FETCH_APP_DATA_SUCCESS',
  SET_IS_LOADING = 'gofre/app/SET_IS_LOADING',
}

export enum DigitLimits {
  INTEGER_DIGITS = 7,
  DECIMAL_DIGITS = 2,
  AMOUNT_DECIMAL_DIGITS = 5,
  MAX_LENGTH = 15,
  EXPENSE_CODE_MAX_LENGTH = 30,
}

export const E2E_RUNNING = !!(window as any).Cypress;
