import { useCallback } from 'react';

import { useField } from 'formik';

import { useOpenModal } from 'modules/Modals';
import { ModalTypes } from 'modules/Modals/constants';

export const useApiConfigurationForm = ({
  isBaiConnected,
  sendAutomatically,
  setSendAutomatically,
}: {
  isBaiConnected: boolean;
  sendAutomatically: boolean;
  setSendAutomatically: (value: boolean) => void;
}) => {
  const openModal = useOpenModal();
  const [, , helper] = useField('sendAutomatically');

  const handleSendAutomaticallyChange = useCallback(() => {
    if (!isBaiConnected) {
      openModal({
        type: ModalTypes.BAI_CONNECTION,
      });
      setSendAutomatically(false);
      helper.setValue(false);
    } else {
      setSendAutomatically(!sendAutomatically);
    }
  }, [isBaiConnected]);

  return {
    handleSendAutomaticallyChange,
  };
};
