import { SubmenuSections } from '../../../constants';
import BusinessSection from './Sections/BusinessSection';
import PaymentSection from './Sections/PaymentSection';

export interface SubMenuProps {
  closeMenu: () => void;
  open: boolean;
  section: SubmenuSections | null;
}

const sectionMapping = {
  [SubmenuSections.BUSINESS]: BusinessSection,
  [SubmenuSections.PAYMENTS]: PaymentSection,
};

const SubmenuContent = (props: SubMenuProps) => {
  const { section, open, closeMenu } = props;
  const Section = section && sectionMapping[section];

  if (!open) {
    return null;
  }

  return <>{Section && <Section {...{ section, open, closeMenu }} />}</>;
};

export default SubmenuContent;
