import { defineMessages } from 'react-intl';

export const paymentMethodsMessages = defineMessages({
  transfer: { id: 'gofre.Payments.create.method.transfer' },
  bizum: { id: 'gofre.Payments.create.method.bizum' },
  creditCard: { id: 'gofre.Payments.create.method.creditCard' },
  promisoryNote: { id: 'gofre.Payments.create.method.promisoryNote' },
  cash: { id: 'gofre.Payments.create.method.cash' },
  directDebit: { id: 'gofre.Payments.create.method.directDebit' },
  confirming: { id: 'gofre.Payments.create.method.confirming' },
  other: { id: 'gofre.Payments.create.method.other' },
  notConfirmed: { id: 'gofre.Payments.create.method.notConfirmed' },
  notIndicated: { id: 'gofre.Payments.create.method.notIndicated' },
});
