import useTheme from 'modules/Theme/hooks/useTheme';

import IconByString, { IconByStringProps } from '../Icon/IconType/IconByString';

const IconMenuExpenses = (props: IconByStringProps) => {
  const { active, ...rest } = props;
  const theme = useTheme();
  return (
    <IconByString {...{ active, ...rest }}>
      <rect
        className="icon-hover-effect"
        fill={active ? theme.colors.brand500 : 'transparent'}
        height="6"
        rx="3"
        width="6"
        x="9"
        y="10"
      />
      <path
        className="icon-hover-effect"
        clipRule="evenodd"
        d="M10 13a2 2 0 1 0 4 0 2 2 0 0 0-4 0Zm2-3a3 3 0 1 0 0 6 3 3 0 0 0 0-6Z"
        fill={active ? theme.colors.brand500 : 'currentColor'}
        fillRule="evenodd"
        shapeRendering="geometricPrecision"
      />
      <path
        clipRule="evenodd"
        d="M4 5h16v1H4V5Zm.176 2H3V4h1.5L6 1h12l1.5 3H21v3h-1.177L17 23H7L4.176 7Zm1.016 0 2.647 15h8.322l2.647-15H5.192Zm13.19-3H5.618l1-2h10.764l1 2Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </IconByString>
  );
};

export default IconMenuExpenses;
