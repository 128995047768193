import { Entity } from 'modules/App/models';
import { DocumentTable } from 'modules/Documents/models/document';
import { DataTableSort } from 'modules/Ui/DataTable/DataTable.models';
import { Currency } from 'types/currency';

export enum RemittanceType {
  CREDIT_TRANSFER = 'CREDIT_TRANSFER',
  DIRECT_DEBIT = 'DIRECT_DEBIT',
}

export type AddNewRemittanceModalProps = {
  accountingAccountId?: string;
  accountingAccountAlias?: string;
  remittance?: Remittance;
  preSelectedDocuments?: DocumentTable[];
  totalCount?: number;
  totalAmount?: number;
  remittanceType?: RemittanceType;
};

export type EditRemittanceModalProps = Omit<
  AddNewRemittanceModalProps,
  'remittance'
> & {
  remittance: Remittance;
};

export enum DirectDebitType {
  CORE = 'CORE',
  B2B = 'B2B',
}

export enum RemittanceStatus {
  PENDING = 'PENDING',
  COMPLETED = 'COMPLETED',
}

export interface Remittance extends Partial<Entity> {
  remittanceConcept: string;
  remittanceExpiration: Date | string;
  remittanceId: string;
  remittanceSepa: DirectDebitType;
  type: RemittanceType;
  accountingAccountId: string;
  accountingAccountAlias: string;
  preSelectedDocuments?: DocumentTable[];
  totalAmount?: number;
}

export interface CreateRemittanceDto {
  readonly name: string;
  readonly type: RemittanceType;
  readonly directDebitType?: DirectDebitType;
  readonly dueDate: Date;
  readonly businessAccountingAccountId: string;
  readonly bankId: string;
}

export interface EditRemittanceDto extends CreateRemittanceDto {}

export interface RemittanceAnswerDto {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  name: string;
  status: RemittanceStatus;
  type: RemittanceType;
  directDebitType: DirectDebitType;
  dueDate: string;
  businessAccountingAccountId: string;
  businessAccountingAccountAlias: string;
  businessFiscalName: string;
  remittanceDocumentsCount: number;
  businessBankId: string;
  amount: number;
}

export interface RemittanceDocumentDto {
  id: string;
  createdAt: Date;
  udpdatedAt: Date;
  remittance: string;
  document: string;
  documentIdentifier: string;
  amount: number;
  contact: string;
  contactFiscalName: string;
  contactIBAN: string;
  currency: Currency;
}

export type RemittanceDocumentTable = Omit<
  RemittanceDocumentDto,
  'remittance' | 'id' | 'updatedAt'
>;

export type RemittanceDocumentSort = DataTableSort<
  RemittanceDocumentTable,
  keyof RemittanceDocumentTable
>;

export interface RemittanceFilters {
  searchTerm?: string;
}
