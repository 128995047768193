import SubFormBox from './SubFormBox';

interface Props {
  isEditing: boolean;
  forContact?: boolean;
}

const EmptySubForm: React.FC<Props> = ({ isEditing = false }) => {
  return (
    <SubFormBox isEditing={isEditing}>
      <></>
    </SubFormBox>
  );
};

export default EmptySubForm;
