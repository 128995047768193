import { FC } from 'react';
import { Visible } from 'react-grid-system';

import useTranslations from 'modules/I18n/hooks/useTranslations';
import { MODAL_WIDTH_SMALL, Dialog } from 'modules/Modals';
import { useCloseModal } from 'modules/Modals/hooks';
import { Text, Link, Button } from 'modules/Ui';
import { ROUTES } from 'pages/documents/routesMap';

import { actionIsReacepitModal } from '../../messages';

const ActionsIsReceiptModal: FC = () => {
  const { t } = useTranslations();
  const handleClose = useCloseModal();
  return (
    <Dialog
      type="fullScreen"
      title={t(actionIsReacepitModal.title)}
      content={
        <>
          <Text marginBottom="16px">{t(actionIsReacepitModal.text01)}</Text>
          <Text marginBottom={{ sm: '28px' }}>
            {t(actionIsReacepitModal.text02)}
          </Text>
        </>
      }
      dialogWidth={{ md: MODAL_WIDTH_SMALL }}
      mobileBorderHeader
      onClose={handleClose}
      buttonSecondary={
        <Visible sm md lg xl xxl>
          <Button
            onClick={() => {
              handleClose();
            }}
            variant="stroke"
          >
            {t(actionIsReacepitModal.buttonCancel)}
          </Button>
        </Visible>
      }
      buttonPrimary={
        <Link
          data-testid="accept-dialog-button"
          onClick={handleClose}
          to={ROUTES.RECEIPTS_CREATE}
          type="button"
          variant="secondary"
        >
          {t(actionIsReacepitModal.buttonAction)}
        </Link>
      }
    />
  );
};

export default ActionsIsReceiptModal;
