import { useSelector } from 'react-redux';

import useTranslations from 'modules/I18n/hooks/useTranslations';
import useTheme from 'modules/Theme/hooks/useTheme';
import { Box, Text } from 'modules/Ui';
import { userDetail } from 'modules/Users/components/Form/messages';
import { selectUser } from 'modules/Users/selectors';

const UserRole = () => {
  const userData = useSelector(selectUser);
  const { t } = useTranslations();
  const theme = useTheme();
  return (
    <Box
      alignItems="center"
      border={`1px solid ${theme.colors.accent500}`}
      borderRadius="2px"
      display={{ _: 'inline-flex', sm: 'flex' }}
      height="20px"
      tag="span"
    >
      <Text
        color="accent500"
        fontSize="13px"
        lineHeight="20px"
        padding="0 6px"
        tag="span"
      >
        {userData.role ? t(userDetail[userData.role.name]) : ''}
      </Text>
    </Box>
  );
};

export default UserRole;
