import { getConfig } from 'modules/App/config';
import request from 'utils/request';

const activateShopify = async () => {
  const endpoint = (await getConfig()).apiIntegrationShopifyConfigWebhooks;

  const response = await request<any>(`${endpoint}/enable-connection`, {
    method: 'PUT',
  });

  return response.data.data;
};

export default activateShopify;
