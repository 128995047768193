import { flexbox } from 'styled-system';

import styled from 'modules/Theme/styled-components';
import { FormikFieldProps } from 'modules/Ui/Formik/FormikFieldProps';
import IconCalendar from 'modules/Ui/Icons/IconCalendar';

import Description from '../Commons/Description';
import InputWrapper from '../Commons/InputWrapper';
import Label from '../Commons/Label';
import LabelHide from '../Commons/Label/LabelHide';
import LabelText from '../Commons/LabelText';
import DayPicker from './DayPicker';

interface DayPickerFieldProps extends FormikFieldProps {}

const StyledInputWrapper = styled(InputWrapper)`
  overflow: initial;
  ${flexbox}
`;

const DayPickerField = (props: DayPickerFieldProps) => {
  const {
    className,
    disabled,
    disabledDays,
    error,
    helpText,
    id,
    label,
    labelHidden = false,
    onChange,
    required,
    value,
    info,
    gridColumnStart,
    gridColumnEnd,
    ...rest
  } = props;

  return (
    <Label {...{ gridColumnStart, gridColumnEnd, info, id, ...rest }}>
      <LabelText
        aria-hidden={info ? true : undefined}
        hidden={labelHidden}
        id={`label-${id}`}
        info={info}
        required={required}
      >
        {label}
      </LabelText>
      <LabelHide id={id} label={label} info={info} />
      <StyledInputWrapper
        hasIcon
        {...{
          error,
          disabled,
        }}
      >
        <DayPicker
          {...{
            disabled,
            disabledDays,
            required,
            onChange,
            id,
            error,
            helpText,
            value,
          }}
        />
        <IconCalendar color="brand500" />
      </StyledInputWrapper>
      <Description id={`date-description-${id}`} hasError={!!error}>
        {error ?? helpText}
      </Description>
    </Label>
  );
};

export default DayPickerField;
