import IconByPath, { IconByPathProps } from './Icon/IconType/IconByPath';

const IconCloseSession = (props: IconByPathProps) => (
  <IconByPath
    {...props}
    path="M16 12.5V22H2V2h14v9.5h5.086L18.05 8.464l.707-.707 3.536 3.536L23 12l-4.243 4.243-.707-.707 3.036-3.036H16ZM15 3H3v18h12V3Zm-2 9.5H7v-1h6v1Z"
  />
);

export default IconCloseSession;
