import { useMemo } from 'react';

import {
  Actions,
  DocumentTrackingData,
} from 'modules/Documents/models/documentTracking';

export const useDocumentTracking = (items: DocumentTrackingData[]) => {
  const voidActionIndex = useMemo(
    () =>
      items.findIndex((element) => element.action === Actions.VOID_DOCUMENT),
    [items]
  );

  return { voidActionIndex };
};
