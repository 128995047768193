import { Source } from 'types/source';

import { platformIsMobile } from './browser';
import isNativeApp from './native/isNativeApp';

export const getSource = (): Source => {
  if (isNativeApp()) {
    return Source.App;
  }
  if (platformIsMobile) {
    return Source.WebMobile;
  }

  return Source.Web;
};
