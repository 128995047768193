import { defineMessages } from 'react-intl';

export default defineMessages({
  acceptedPaymentMethods: {
    id: 'gofre.Documents.AcceptedPaymentMethods',
  },
  addPaymentMethod: {
    id: 'gofre.Documents.AddPaymentMethod',
  },
  cash: {
    id: 'gofre.Documents.AddPaymentMethod.added.cash',
  },
  bizum: {
    id: 'gofre.Documents.AddPaymentMethod.added.bizum',
  },
  cashOnDelivery: {
    id: 'gofre.Documents.AddPaymentMethod.added.cashOnDelivery',
  },
  directDebit: {
    id: 'gofre.Documents.AddPaymentMethod.added.directDebit',
  },
  other: {
    id: 'gofre.Documents.AddPaymentMethod.added.other',
  },
  transfer: {
    id: 'gofre.Documents.AddPaymentMethod.added.transfer-text',
  },
  transferType: {
    id: 'gofre.Documents.AddPaymentMethod.transfer',
  },
  bizumType: { id: 'gofre.Documents.AddPaymentMethod.bizum' },
  cashType: { id: 'gofre.Documents.AddPaymentMethod.cash' },
  directDebitType: { id: 'gofre.Documents.AddPaymentMethod.direct_debit' },
  cashOnDeliveryType: {
    id: 'gofre.Documents.AddPaymentMethod.cash_on_delivery',
  },
  bank_account_iban: {
    id: 'gofre.Documents.AddPaymentMethod.bank_account_iban',
  },
  phoneText: {
    id: 'gofre.Business.Create.Form.phone',
  },
});
