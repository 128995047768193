import { FC, ReactNode } from 'react';

import {
  SpaceProps,
  space,
  color,
  ColorProps,
  layout,
  LayoutProps,
  BackgroundProps,
  BorderProps,
  position,
  PositionProps,
  borderRadius,
  flexbox,
  FlexboxProps,
} from 'styled-system';

import styled from 'modules/Theme/styled-components';

import { Box } from '..';

interface Props
  extends SpaceProps,
    ColorProps,
    BackgroundProps,
    PositionProps,
    BorderProps,
    LayoutProps,
    FlexboxProps {
  ariaLabel?: string;
  children?: ReactNode;
  id?: string;
  onClick?: () => void;
}
const StyledDot = styled(Box)`
  ${color};
  ${layout}
  ${space}
  ${position}
  ${borderRadius}
  ${flexbox}
`;

const Dot: FC<Props> = ({ ariaLabel, children, id, ...rest }) => (
  <StyledDot
    aria-label={ariaLabel}
    borderRadius="50%"
    id={id}
    tag={rest.onClick ? 'button' : 'span'}
    {...rest}
  >
    {children}
  </StyledDot>
);

export default Dot;
