import { some, pickBy } from 'lodash';

import Props from './Props';

export const isValidProp = (
  validKeys: (string | RegExp)[],
  propKey: string
) => {
  return some(validKeys, (validKey) =>
    validKey instanceof RegExp ? validKey.test(propKey) : validKey === propKey
  );
};

export const pickValidKeys =
  (validKeys: (string | RegExp)[]) =>
  (props: Props): Props =>
    pickBy(props, (_, key) => isValidProp(validKeys, key));
