import { FC } from 'react';
import { MessageDescriptor } from 'react-intl';

import { SpaceProps, LayoutProps } from 'styled-system';

import { DigitalDocumentStatus } from 'modules/Documents/models/document';
import useTranslations from 'modules/I18n/hooks/useTranslations';
import theme from 'modules/Theme';
import { BasePill, Box, Tooltip } from 'modules/Ui';
import { IconInfo } from 'modules/Ui/Icons';

import { digitalStatusTag } from '../messages';

interface Props extends SpaceProps, LayoutProps {
  status: DigitalDocumentStatus;
}

const labels: Record<DigitalDocumentStatus, MessageDescriptor> = {
  [DigitalDocumentStatus.PRESENTED]: digitalStatusTag.presented,
  [DigitalDocumentStatus.PRESENTED_WITH_ERRORS]:
    digitalStatusTag.presentedWithErrors,
  [DigitalDocumentStatus.REJECTED]: digitalStatusTag.rejected,
  [DigitalDocumentStatus.IN_PROGRESS]: digitalStatusTag.pending,
  [DigitalDocumentStatus.FAIL_TO_CONTACT]: digitalStatusTag.pending,
};

const colors: Record<DigitalDocumentStatus, string> = {
  [DigitalDocumentStatus.PRESENTED]: theme.colors.confirmationAccesible,
  [DigitalDocumentStatus.PRESENTED_WITH_ERRORS]:
    theme.colors.confirmationAccesible,
  [DigitalDocumentStatus.REJECTED]: theme.colors.rejected,
  [DigitalDocumentStatus.IN_PROGRESS]: theme.colors.pending,
  [DigitalDocumentStatus.FAIL_TO_CONTACT]: theme.colors.pending,
};

const borderColors: Record<DigitalDocumentStatus, string> = {
  [DigitalDocumentStatus.PRESENTED]: theme.colors.confirmation,
  [DigitalDocumentStatus.PRESENTED_WITH_ERRORS]:
    theme.colors.confirmationAccesible,
  [DigitalDocumentStatus.REJECTED]: theme.colors.error,
  [DigitalDocumentStatus.IN_PROGRESS]: theme.colors.warning,
  [DigitalDocumentStatus.FAIL_TO_CONTACT]: theme.colors.warning,
};

const DigitalStatusTag: FC<Props> = ({
  status,
  width = { _: '92px', sm: '64px', md: '92px' },
  ...rest
}) => {
  const { t } = useTranslations();
  const color = colors[status];
  const borderColor = borderColors[status];
  return (
    <Box
      tag="span"
      display="flex"
      alignItems="center"
      justifyContent="flex-end"
    >
      <BasePill
        backgroundColor="transparent"
        borderColor={borderColor}
        color={color}
        fontWeight="normal"
        width={width}
        {...rest}
      >
        {t(labels[status])}
      </BasePill>

      {status === DigitalDocumentStatus.PRESENTED_WITH_ERRORS && (
        <Tooltip
          id="tooltip-presented-with-errors"
          marginLeft="4px"
          noBullet
          place="bottom"
          tooltipBackgroundColor={theme.colors.primary400}
          tooltipButton={
            <IconInfo
              color={theme.colors.rejected}
              size={16}
              transform="rotate(180deg)"
            />
          }
          tooltipContent={t(digitalStatusTag.presentedWithErrorsTooltip)}
          tooltipContentPadding="8px 12px"
          tooltipTextColor={theme.colors.gray0}
        />
      )}
    </Box>
  );
};

export default DigitalStatusTag;
