import { FC, useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import useTranslations from 'modules/I18n/hooks/useTranslations';
import { useCloseModal, Dialog } from 'modules/Modals';
import { Button, Text } from 'modules/Ui';

import useDeleteDocumentToReview from '../../../List/hooks/expenses/useDeleteDocumentToReview';
import messages from './messages';
import { DeletePendingDocumentModalProps } from './type';

const DeletePendingDocumentModal: FC<DeletePendingDocumentModalProps> = ({
  id,
}) => {
  const closeModal = useCloseModal();
  const history = useHistory();
  const deleteDocument = useDeleteDocumentToReview();
  const { t } = useTranslations();
  const onDelete = useCallback(() => {
    deleteDocument(id);
    history.push('/documents/expenses?expenseType=TO_REVIEW');
    closeModal();
  }, [deleteDocument, id, history, closeModal]);

  return (
    <Dialog
      id="delete-pending-document"
      title={t(messages.title)}
      onClose={closeModal}
      subtitle={t(messages.subtitle)}
      buttonPrimary={
        <Button variant="secondary" onClick={onDelete}>
          {t(messages.title)}
        </Button>
      }
      buttonSecondary={
        <Button onClick={closeModal} type="button" variant="stroke">
          {t(messages.cancel)}
        </Button>
      }
    >
      <Text
        color={{ _: 'gray600', sm: 'gray800' }}
        fontSize={16}
        lineHeight={22}
        marginTop="16px"
      >
        {t(messages.text)}
      </Text>
    </Dialog>
  );
};

export default DeletePendingDocumentModal;
