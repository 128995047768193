export const ROUTES = {
  HOME: '/',
  LOGIN: '/auth/login',
  LOGOUT: '/auth/logout',
  SIGNUP: '/auth/signup',
  REGISTER: '/auth/register',
  BOOKKEEPER: '/auth/register-bookkeeper',
  RESET: '/auth/reset/:token',
  RECOVER_PASSWORD: '/auth/recover-password',
  VODAFONE_PASSWORD: '/auth/vodafone-password/:resetToken',
  API_PASSWORD: '/auth/api-tenant-user-password/:resetToken',
  API_REGISTER: '/auth/api-register',
  SHOPIFY: '/auth/shopify',
  SHOPIFY_CALLBACK: '/auth/shopify/callback',
};
