import { useSelector } from 'react-redux';

import { selectCurrentBusiness } from 'modules/Business/Current/selectors';
import { Coupon } from 'modules/Subscriptions/Create/types';

import { generateSubscriptionTaxes } from '../subscription.helpers';

export function useTotalSubscriptionCalcs(price: number, coupon?: Coupon) {
  const business = useSelector(selectCurrentBusiness);
  return generateSubscriptionTaxes(business, price, coupon);
}
