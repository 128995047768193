import IconByPath, { IconByPathProps } from './Icon/IconType/IconByPath';

const IconBack = (props: IconByPathProps) => (
  <IconByPath
    {...props}
    path="m10.39 2.667 1.458 1.439-6.003 6.004h15.549v2.083H5.844l6.004 5.985-1.458 1.458-8.485-8.484 8.485-8.485Z"
  />
);

export default IconBack;
