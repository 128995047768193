import { FC } from 'react';

import { space, SpaceProps, color, ColorProps } from 'styled-system';

import useTranslations from 'modules/I18n/hooks/useTranslations';
import useTheme from 'modules/Theme/hooks/useTheme';
import styled from 'modules/Theme/styled-components';

import Box from '../Box';
import Link from '../Link';
import Text from '../Text';
import messages from './messages';

interface AuthorizationProps extends SpaceProps, ColorProps {}

const StyledWrapper = styled(Box)`
  ${space}
  ${color}
`;

export const Authorization: FC<AuthorizationProps> = ({ ...rest }) => {
  const { t } = useTranslations();
  const theme = useTheme();
  return (
    <StyledWrapper marginTop="16px" {...rest}>
      <Text fontSize={11} lineHeight={13}>
        {t<Element, JSX.Element>(messages.infoText, {
          link: (chunks) => (
            <Link
              isExternal
              target="blank"
              to="https://www.billin.net/autorizacion-representacion-bai"
              fontSize={theme.fontSizes[12]}
              lineHeight={theme.lineHeights[14]}
            >
              {chunks}
            </Link>
          ),
        })}
      </Text>
    </StyledWrapper>
  );
};
