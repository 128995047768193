import IconByString, { IconByStringProps } from './Icon/IconType/IconByString';

const IconElectronic = (props: IconByStringProps) => {
  const { ...rest } = props;
  return (
    <IconByString fill="none" {...rest}>
      <path
        clipRule="evenodd"
        d="M14 4h-4v1H9v5h1V8h5V5h-1V4Zm0 3h-4V5h4v2Z"
        fill="currentColor"
      />
      <path d="M10 10h4v1h-4v-1ZM15 9h-1v1h1V9Z" fill="currentColor" />
      <path
        clipRule="evenodd"
        d="M20 23V1H4v22h16ZM5 2h14v20H5V2Z"
        fill="currentColor"
      />
      <path d="M8 15h8v1H8v-1ZM8 19h8v1H8v-1Z" fill="currentColor" />
    </IconByString>
  );
};

export default IconElectronic;
