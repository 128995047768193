import IconByPath, { IconByPathProps } from './Icon/IconType/IconByPath';

const IconBillinFavicon = (props: IconByPathProps) => (
  <IconByPath
    {...props}
    path="M16.746 0l3.234 3.235-12.302 12.32H20L19.97 20H0V.011h4.475l-.03 12.31L16.746 0z"
  />
);

export default IconBillinFavicon;
