import { ActionType } from 'typesafe-actions';

import * as actions from './actions';
import {
  GET_USER_REQUEST,
  GET_USER_SUCCESS,
  GET_USER_FAILURE,
} from './constants';
import { User } from './models/user';

const emptyUser = {} as User;

export interface State {
  user: User;
  loading: boolean;
}

export const initialState: State = {
  user: emptyUser,
  loading: false,
};

const reducer = (
  state: State = initialState,
  action: ActionType<typeof actions>
): State => {
  switch (action.type) {
    case GET_USER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload,
      };
    case GET_USER_FAILURE:
      return {
        ...state,
        loading: false,
        user: emptyUser,
      };
    default:
      return state;
  }
};

export default reducer;
